import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginModel } from '../model/login';
import { RestResponseModel } from '../model/response';
import { ForgotPasswordService } from '../services/forgot.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  restResponseModel:RestResponseModel;
  isError = false;
  errormessage : String;
  constructor(private route: ActivatedRoute,private forgotservice: ForgotPasswordService,private router: Router) { }

  ngOnInit(): void {
  }

  sendEmail(){
    let email = (<HTMLInputElement>document.getElementById('emailaddress')).value;
    let login = new LoginModel(0,email,"","");
    if(email !==""){
      this.forgotservice.verifyEmail(login).subscribe( data => {
        this.restResponseModel = data;
        if(data.flag==1){
          this.router.navigate(['/forgot-password-otp-verification'],{ queryParams: { emailId: email} });
        }else{
          this.isError = true;
          this.errormessage = data.message;
        }
      });
    }else{
      this.isError = true;
      this.errormessage = "Please Enter Email Address";
    }
  }

}
