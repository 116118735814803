<div class="mainbody">
  <div class="row m-0">
    <div class="col-12">
      <div class="titleofthepage">
        Refund Policy
      </div>
	  </div>
	  </div>
<div class="row m-0">
<div class="col-sm-12 m-auto ">
      <div class="detaildescription">
        <div align="justify" class="content">
          <span>1.Fuel charges - Full refund</span><br>
        <span>2.Delivery charges -</span><br>
      <span style="margin-left: 30px;">A.If Partner cancellation – 100% refund</span><br>
      <span style="margin-left: 30px;">B.If Customer cancellation –</span><br>
    <span style="margin-left: 60px;">i.0% refund if delivery vehicle started</span><br>
                   <span style="margin-left: 60px;">ii.100% refund if cancelled before 3 hrs of delivery time</span><br>
                   <span style="margin-left: 60px;">iii.Other cases - 50% refund</span><br>
          <span>3.Platform fee -</span><br>
              <span style="margin-left: 30px;">A.If Partner cancellation – 100% refund</span><br>
              <span style="margin-left: 30px;">B.If Customer cancellation – No refund</span><br>
        </div>
      </div>
    </div>
  </div>
</div>
