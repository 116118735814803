
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { FuelRefillHistoryModel } from '../model/fuelrefill';
import { GenericResponseModel } from '../model/genricresponse';
import { VehicleFuelService } from '../services/vehivle.service';

@Component({
  selector: 'app-fuel-price-reset-history',
  templateUrl: './fuel-price-reset-history.component.html',
  styleUrls: ['./fuel-price-reset-history.component.css']
})
export class FuelPriceResetHistoryComponent implements OnInit  {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

 fuelMarkerHistory:GenericResponseModel; 

  asas:FuelRefillHistoryModel[]; 

  active=1;
closeResult = '';

  constructor(private modalService: NgbModal,private vehicleService: VehicleFuelService,private route: ActivatedRoute,private spinnerService:NgxSpinnerService) {}

  ngOnInit(): void {
    this.spinnerService.show();
    this.asas=[];
    this.vehicleService.getMarketPrice().subscribe(data=>{
      this.fuelMarkerHistory = data;
      this.spinnerService.hide();
    },
    error => {
      const errStr = JSON.stringify(error);
      console.log(errStr); 
  }    );
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  

}
