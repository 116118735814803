
<app-header></app-header>
    <div class="page-container " *ngIf="viewPage">
      <!-- START PAGE CONTENT WRAPPER -->
      <div class="page-content-wrapper ">
        <!-- START PAGE CONTENT -->
        <div class="content sm-gutter">
          <!-- START BREADCRUMBS -->
          <div class="bg-white">
            <div class="container">
              <ol class="breadcrumb breadcrumb-alt">
                <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Home</a></li>
                <li class="breadcrumb-item active">Partner Payment Mode Mapping </li>
                <div class="btn-section-wrapper">
                  <button (click)="create()" type="button"
                  class="label label-info" style="margin-top: -10px; margin-right: 16px;"><i class="fa fa-plus" aria-hidden="true"></i>
                        Add New</button>
            </div>
                <!-- <li class="back_page"><button class="back_page" type="button" class="label label-info" routerLink="/role-menu-mapping-create"><i class="fa fa-plus" aria-hidden="true"></i> Assign Menu</button></li> -->
              </ol>
            </div>
          </div>
          <!-- END BREADCRUMBS -->
          <!-- START CONTAINER FLUID -->

          <!-- START CONTAINER FLUID -->
          <div class=" no-padding    container-fixed-lg bg-white">
            <div class="container">
              
              <!-- START card -->
              <div class="card card-transparent">
                <div style="display: none;">{{partnerMappingModel.responseMessage}}</div>
                <div class="card-body">                  
                  <div class="table-responsive" style="margin-top: 30px;">
                    <table datatable class="ch-table">
                      <thead>
                        <tr>
                          <th style="text-align: center;">Sl No</th>
                          <th  style="text-align: left;">Partner Name</th>
                          <th  style="text-align: left;">Mobile Number</th>
                          <th  style="text-align: left;">Email Address</th>
                          <th  style="text-align: center;">Payment Modes</th>
                          <th  style="text-align: center;">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let partnerModel of partnerMappingModel.response; let i=index">
                          <td align="center" class="v-align-middle ">{{i + 1}}</td>
                          <td align="left"  class="v-align-middle">{{partnerModel.partnerName}}</td> 
                          <td align="left"  class="v-align-middle">{{partnerModel.partnerMobileNumber}}</td>
                          <td align="left"  class="v-align-middle">{{partnerModel.partnerEmail}}</td>                             
                          <td align="center" class="v-align-middle">
                            <button title="View" style="margin-left: 10px;" class="label label-info" 
                            (click)="open(content,partnerModel.partnerId)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                          </td>
                          <td align="center" class="v-align-middle">
                            <button title="Edit" class="label label-success" (click)="edit(partnerModel.partnerName,partnerModel.partnerId)" >
                              <i class="fa fa-pencil-square" aria-hidden="true"></i></button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- END card -->
            </div>
          </div>      
     
        </div>      
      </div>
      <!-- END PAGE CONTENT WRAPPER -->
    </div>    

    <div class="page-container " *ngIf="createPage">
    
        <!-- START PAGE CONTENT WRAPPER -->
        <div class="page-content-wrapper "> 
          <!-- START PAGE CONTENT -->
          <div class="content sm-gutter"> 
            <!-- START BREADCRUMBS -->
            <div class="bg-white">
              <div class="container">
                <ol class="breadcrumb breadcrumb-alt">
                  <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Home</a></li>
                  <li class="breadcrumb-item active">Role Menu Mapping </li>
                </ol>
              </div>
            </div>
            <!-- END BREADCRUMBS -->
      </div>
      
      
      <!-- START CONTAINER FLUID --> 
      
      <!-- START CONTAINER FLUID -->
      
      <!-- END CONTAINER FLUID --> 
      
    </div>


<div class="container">
	<div class="card role-menu-mapping-edit">
		<div class="group-element" style="margin-top: 10px;">
			<label><b>Select Partner</b></label> 
		</div>
	
      <div class="ch-form-group" style="margin-left: 10px;">
           <select id="partnerId"  name="partnerId" class="form-control">
            <option *ngFor="let partner of partners.response" value={{partner.userId}}>{{partner.firstName}} [{{partner.userCode}} ]</option>
          </select> 
          </div>
      </div>

	<div class="card menu-selection">
    <h5>PAYMENT MODE</h5>
    <br>
     
     <div *ngFor="let paymentMode of paymentModes.response;">
      <ul class="menu">
        <p ><li>
          <input  type="checkbox" value=" {{paymentMode.id}}" (change)="assign(paymentMode.id,$event)">
          <label for="tall">{{paymentMode.paymentMode}}</label></li></p>
            
      </ul>
     
</div> 
<div class="btn-section-wrapper"><button type="button" (click)="submit()"  class="btn btn-success"><i aria-hidden="true" class="fa fa-save"></i>&nbsp; Submit</button></div>

</div>

    </div>   
</div>
<div class="page-container " *ngIf="editPage">
    
        <!-- START PAGE CONTENT WRAPPER -->
        <div class="page-content-wrapper "> 
          <!-- START PAGE CONTENT -->
          <div class="content sm-gutter"> 
            <!-- START BREADCRUMBS -->
            <div class="bg-white">
              <div class="container">
                <ol class="breadcrumb breadcrumb-alt">
                  <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Home</a></li>
                  <li class="breadcrumb-item active">Payment Mode </li>
                </ol>
              </div>
            </div>
            <!-- END BREADCRUMBS -->
      </div>
      
      
      <!-- START CONTAINER FLUID --> 
      
      <!-- START CONTAINER FLUID -->
      
      <!-- END CONTAINER FLUID --> 
      
    </div>


<div class="container">
	<div class="card role-menu-mapping-edit">
		<div class="group-element" style="margin-top: 10px;">
			<label><b>Select Partner</b></label> 
		</div>
	
      <div class="ch-form-group" style="margin-left: 10px;">
        <input type="text" id="role" disabled  name="role" class="form-control" [(ngModel)]="partnerName">
          </div>
      </div>

	<div class="card menu-selection">
    <h5>PAYMENT MODE</h5>
    <br>
     
     <div *ngFor="let paymentMode of paymentModes.response;">
      <ul class="menu">
        <p ><li *ngIf="paymentMode.flag==1">
          <input  type="checkbox" value=" {{paymentMode.id}}" (change)="assign(paymentMode.id,$event)" checked>
          <label for="tall">{{paymentMode.paymentMode}}</label></li>
        
          <li *ngIf="paymentMode.flag==0">
            <input  type="checkbox" value=" {{paymentMode.id}}" (change)="assign(paymentMode.id,$event)">
            <label for="tall">{{paymentMode.paymentMode}}</label></li>
        </p>
     </ul>
     
</div> 
    <div class="btn-section-wrapper">
      <button type="button" (click)="update()"  class="btn btn-success"><i aria-hidden="true" class="fa fa-save"></i>&nbsp; Update</button></div>

</div>

    </div>   
</div>
    <ng-template #content let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">View Payment Modes</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
      </div>
      <div class="modal-body">       
        <div *ngFor="let paymentmode of availableMappingModel.response;">         
              <ul>
                <li>{{paymentmode.paymentModeName}}</li>
              </ul>                
           </div>        
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
      </div>
    </ng-template>
    
<router-outlet></router-outlet>