import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FuelTypeModel } from '../model/fueltype';
import { FuelTypeService } from '../services/fueltype.service';
import { GenericResponseModel } from '../model/genricresponse';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router } from '@angular/router';

@Component({
  selector: 'app-master-fuel-type',
  templateUrl: './master-fuel-type.component.html',
  styleUrls: ['./master-fuel-type.component.css']
})
export class MasterFuelTypeComponent implements OnInit{
 

 public isCollapsed = false;
 active = 1;
 response:GenericResponseModel;
 fuelType:FuelTypeModel;
 errorFlag:boolean;
 errorFlag1:boolean;
  errorMessage:String;
  deleteUserId:Number;

closeResult = '';

  constructor(private modalService: NgbModal,private fueltypeservice:FuelTypeService,private spinnerService:NgxSpinnerService,private router:Router) {}

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open1(content1,id) {
      this.fueltypeservice.getFuelTypeById(id).subscribe(data=>{
        this.fuelType = data;
      })
    this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open2(reject,viewId){
    this.deleteUserId = viewId;
    this.modalService.open(reject, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
    this.fueltypeservice.getFuelTypes().subscribe(data=>{
    this.response = data;
    },err => {
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }else if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });
  }

  delete(){
    let userId = this.deleteUserId;
      this.fueltypeservice.deleteFuelType(userId).subscribe(data=>{
        if(data.flag==1){
          Swal.fire({
            html: '<pre>' + data.message + '</pre>',
            customClass: {
              popup: 'format-pre'
            }
          }).then((value) => {
            window.location.reload();
          });; ;
        }
      });    
  }

  addFuel(){
    this.errorFlag = false;
    let fuelTypeName = (<HTMLInputElement>document.getElementById('fueltype')).value;
    let status = (<HTMLInputElement>document.getElementById('fuelstatus')).value;
    if(fuelTypeName!=null && fuelTypeName!=="" && status!=null && status!==""){
      let formData= {
        fuelTypeName:fuelTypeName,
        fuelTypeStatus:Number(status),
        modifiedBy:sessionStorage.getItem("username")
      }
      this.fueltypeservice.addFuelType(formData).subscribe(data=>{
        if(data.flag==1){
          this.errorFlag = false;
          this.modalService.dismissAll();
          Swal.fire({
            html: '<pre>' + data.message + '</pre>',
            customClass: {
              popup: 'format-pre'
            }
          }).then((value) => {
            window.location.reload();
          });; ; 
        }else{
          this.errorFlag= true;
          this.errorMessage = data.message;
          setTimeout(function() {
            this.errorFlag = false;
        }.bind(this), 3000);
        }
      },err => {
        if(err.error.statusCode==406){
          this.router.navigate(['/login']);
        }else if(err.error.statusCode==500){
          this.router.navigate(['/page-error']);
        }else if(err.error.statusCode==404){
          this.router.navigate(['/pagenotfound']);
        }
     });
    }else{
      this.errorFlag= true;
      this.errorMessage = "Please Enter Fuel Type Name!";
      setTimeout(function() {
        this.errorFlag = false;
    }.bind(this), 3000);
    }

    
  }

  updateFuel(){
    this.errorFlag = false;
    if(this.fuelType.fuelTypeName!=null && this.fuelType.fuelTypeName!=="" && this.fuelType.fuelTypeStatus!=null){
      let formData= {
        id:this.fuelType.id,
        fuelTypeName:this.fuelType.fuelTypeName,
        fuelTypeStatus:this.fuelType.fuelTypeStatus,
        modifiedBy:sessionStorage.getItem("username")
      }
      this.fueltypeservice.updateFuelType(formData).subscribe(data=>{
        if(data.flag==1){
          this.errorFlag = false;
          this.modalService.dismissAll();
          Swal.fire({
            html: '<pre>' + data.message + '</pre>',
            customClass: {
              popup: 'format-pre'
            }
          }).then((value) => {
            window.location.reload();
          });; ; 
        }else{
          this.errorFlag1= true;
          this.errorMessage = data.message;
          setTimeout(function() {
            this.errorFlag1 = false;
        }.bind(this), 3000);
        }
      },err => {
        if(err.error.statusCode==406){
          this.router.navigate(['/login']);
        }else if(err.error.statusCode==500){
          this.router.navigate(['/page-error']);
        }else if(err.error.statusCode==404){
          this.router.navigate(['/pagenotfound']);
        }
     });
    }else{
      this.errorFlag1= true;
      this.errorMessage = "Please Enter Fuel Type Name!";
      setTimeout(function() {
        this.errorFlag1 = false;
    }.bind(this), 3000);
    }
  }

}
