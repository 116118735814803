import { Component, OnInit ,ViewChild,ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { GenericResponseModel } from '../model/genricresponse';
import { OrderTransactionService } from '../services/ordertran.service';
import { ReportServices } from '../services/reports.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { Workbook } from 'exceljs';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataTableDirective } from 'angular-datatables';
import { empty, EMPTY, Subject } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import {DataTablesModule} from 'angular-datatables';
import Swal from 'sweetalert2/dist/sweetalert2.js';


@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.css']
})


export class TransactionComponent {
  @ViewChild('epltable', { static: false }) epltable: ElementRef;
  symbole:String; 
 public isCollapsed = false;
 active = 1;
 transactionDetail:GenericResponseModel;
 transactionfilter1Detail:GenericResponseModel;
 transactionfilter1DetailNoRecord:GenericResponseModel;
 transactionfilter2Detail:GenericResponseModel;
 transactionfilter2DetailNoRecord:GenericResponseModel;
 transactionfilter3Detail:GenericResponseModel;
 transactionfilter4Detail:GenericResponseModel;
 transactionfilter5Detail:GenericResponseModel;
 transactionfilter3DetailNoRecord:GenericResponseModel;
 transactionfilter4DetailNoRecord:GenericResponseModel;
 transactionfilter5DetailNoRecord:GenericResponseModel;
 settlementDetail:GenericResponseModel;
 settlementFilterDetail:GenericResponseModel;
 settlementViewDetail:GenericResponseModel;
closeResult = '';
  errorFlag:boolean;
  errorMessage:String;
  refreshflag:boolean;
settlementInfoModel:GenericResponseModel;
vehiclePaymentInfoModel:GenericResponseModel;
fileName:string;
responseDataList = [];
excelexportchecktransaction:boolean = false;
responseDataListnew = [];
responseDataListnewfilter = [];
accountStatus:Number;
startDate:String;
endDate:String;
searchStatus:Number;
defaultTransaction:boolean=true;
filterTransaction1:boolean=false;
filterTransaction2:boolean=false;
filterTransaction3:boolean=false;
filterTransaction4:boolean=false;
filterTransaction5:boolean=false;
poppupalert:boolean = false;
searchByCusorPart:Number;
searchByonboardedby:Number;
decimal_value: number = 5.123456789;
id:Number;
approvalStatus:String;
paymentReferenceNo:String;
paymentModeId:Number;
title:String;
chequeClaimStatus:String;
receivedAmount:Number;
totalPayableAmount:Number;
file: File = null;
electedFiles: FileList;
selectedFiles: FileList;
currentFile: File;
progress = 0;
mes = '';
@ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  filteronesearch:boolean= false;
  filtertwosearch:boolean= false;
  filterthreesearch:boolean= false;
  filterfoursearch:boolean= false;
  filterfivesearch:boolean= false;
  onboardedby:String;
  deleteUserId:Number;
  customerPaymentStatus:GenericResponseModel;
  partnerPaymentStatus:GenericResponseModel;
  imageToShowProof: any;
  bakImage:boolean = false;
  imageUpload:boolean=true;
  pdfUpload:boolean=false;
  successStatus:boolean=false;
  bounceStatus:boolean=false;
  constructor(private modalService: NgbModal,private ordertransactionservice: OrderTransactionService,
    private route: ActivatedRoute,private router: Router,private reposrtservice:ReportServices,private spinnerService:NgxSpinnerService,private _decimalPipe: DecimalPipe) {}

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true
    };
    var nowdate = new Date();
    var monthStartDay = new Date(nowdate.getFullYear(), nowdate.getMonth(), 1);
    var monthEndDay = new Date(nowdate.getFullYear(), nowdate.getMonth() + 1, 0);
    let startDate = moment(new Date(monthStartDay)).format("yyyy-MM-DD").toString();
    let endDate = moment(new Date(monthEndDay)).format("yyyy-MM-DD").toString();
    this.startDate = startDate;
    this.endDate = endDate;
    this.searchStatus = 1;
    this.searchByCusorPart = 1;
    let formData= {
      startDate:startDate,
      endDate:endDate,
      flag:1,
      searchStatus:1,
      onboardedby:0
    }
    this.reposrtservice.getTransactionReportByDateRange(formData).subscribe(data=>{
      this.accountStatus = data.accountStatus;
      this.transactionDetail=data;
      if(data.response.length>0){
        this.excelexportchecktransaction = true;
      }else{
        this.excelexportchecktransaction = false;
      }
    },err => {
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }else if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });
  }

  open1(reject,viewId){
    this.deleteUserId = viewId;
    this.modalService.open(reject, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open2(content5,transactionId,id){
    let formData= {
      orderprimaryId:id,
      transactionId:transactionId,
      submittedBy:sessionStorage.getItem("username")
    }
    this.reposrtservice.getCustomerPaymentStatus(formData).subscribe(data=>{
      this.customerPaymentStatus = data;
      Swal.fire({
        html: '<pre>' + data.responseMessage + '</pre>',
        customClass: {
          popup: 'format-pre'
        }
      }).then((value) => {
        window.location.reload();
      });; ;
    });

    // this.modalService.open(content5, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    // });
  }

  open3(content6,transactionId){
    this.reposrtservice.getPartnerPaymentStatus(transactionId).subscribe(data=>{
      this.partnerPaymentStatus = data;
      Swal.fire({
        html: '<pre>' + data.responseMessage + '</pre>',
        customClass: {
          popup: 'format-pre'
        }
      }).then((value) => {
        window.location.reload();
      });; ;
    });

    // this.modalService.open(content6, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    // });
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

    
  view(content1,orderId){
    sessionStorage.setItem("orderId",String(orderId));
    this.router.navigate(['/orderdetails']);
  }

  // view(content1,id){
  //   this.reposrtservice.orderinfo(id).subscribe(data=>{
  //     this.settlementInfoModel = data;
  //   });

  //   this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
  //     this.closeResult = `Closed with: ${result}`;
  //   }, (reason) => {
  //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //   });
  // }

  view1(content2,id){
    this.reposrtservice.vehiclepaymentinfo(id).subscribe(data=>{
      this.vehiclePaymentInfoModel = data;
      
    });

   


    this.modalService.open(content2, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  

  viewproof(id,filename,contentproof,flag){
  if(flag==1){
    this.title = "View Cheque & DD";
  }else{
    this.title = "View Receipt";
  }
    let dataType ='';
    this.reposrtservice.viewproof(id,flag).subscribe(data=>{
      this.bakImage = true;
      if(data.type ==='application/pdf'){
        FileSaver.saveAs(data, filename);
      }else{
        dataType= data.type;
        this.createImageFromBlob(data);
      }
    }, error => {
        console.log(error);
    });

    this.modalService.open(contentproof, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
    
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.imageToShowProof = reader.result
     }, false);

    if (image) {
      reader.readAsDataURL(image);
   }else{
   }
  }

  div1:boolean=true;
  div2:boolean=false;
  divtracfilter:boolean=false;
  divsettlementfilter:boolean=false;
   
    div1Function(){
        this.div1=true;
        this.div2=false;
        
    }

    div2Function(){
        this.div2=true;
        this.div1=false;
        
    }
    
    search(){
      this.defaultTransaction = false;
      this.refreshflag = true;
      let fromdate = (<HTMLInputElement>document.getElementById('fromdate')).value;
    let todate = (<HTMLInputElement>document.getElementById('todate')).value;
    let accountstatus = (<HTMLInputElement>document.getElementById('accountstatus')).value;
    let searchBy = (<HTMLInputElement>document.getElementById('searchBy')).value;
    let onboarded = (<HTMLInputElement>document.getElementById('onboarded')).value;
    if(searchBy=='1'){
      if(todate < fromdate){
        this.errorFlag = true;
        this.errorMessage = "End Date should be greater than Start Date!";
      }else{
        this.errorFlag = false;
        let formData= {
          startDate:fromdate,
          endDate:todate,
          flag:Number(accountstatus),
          searchStatus:Number(searchBy),
          onboardedby:0
        }
        this.reposrtservice.getTransactionReportByDateRange(formData).subscribe(data=>{
          this.transactionfilter1Detail = new GenericResponseModel;
          this.filterTransaction1 = true;
          this.filterTransaction2 = false;
          this.filterTransaction3 = false;
          this.filterTransaction4 = false;
          this.filterTransaction5 = false;
          this.accountStatus = data.accountStatus;
          let response = data;
          if(data.response.length>0){
            this.filteronesearch = true;
            this.transactionfilter1Detail= response;
          }else{
            this.filteronesearch = false;
            this.transactionfilter1DetailNoRecord = data
          }
          this.startDate = data.fromDate;
          this.endDate = data.toDate;
          this.searchStatus = data.searchStatus;
          this.searchByCusorPart = data.accountStatus;
          if(data.response.length>0){
            this.excelexportchecktransaction = true;
          }else{
            this.excelexportchecktransaction = false;
          }
        });
      }
    }else if(searchBy=='2'){
      if(todate < fromdate){
        this.errorFlag = true;
        this.errorMessage = "End Date should be greater than Start Date!";
      }else{
        this.errorFlag = false;
        let formData= {
          startDate:fromdate,
          endDate:todate,
          flag:Number(accountstatus),
          searchStatus:Number(searchBy),
          onboardedby:0
        }
        this.reposrtservice.getTransactionReportByDateRange(formData).subscribe(data=>{
          this.transactionfilter2Detail = new GenericResponseModel;
          this.filterTransaction1 = false;
          this.filterTransaction2 = true;
          this.filterTransaction4 = false;
          this.filterTransaction5 = false;
          this.filterTransaction3 = false;
          this.accountStatus = data.accountStatus;
          if(data.response.length>0){
            this.filtertwosearch = true;
            this.transactionfilter2Detail = data;
          }else{
            this.filtertwosearch = false;
            this.transactionfilter2DetailNoRecord = data
          }
         
          this.startDate = data.fromDate;
          this.endDate = data.toDate;
          this.searchStatus = data.searchStatus;
          this.searchByCusorPart = data.accountStatus;
          if(data.response.length>0){
            this.excelexportchecktransaction = true;
          }else{
            this.excelexportchecktransaction = false;
          }
        });
      }
    }else if(searchBy=='3'){
      this.errorFlag = false;
      let formData= {
        startDate:null,
        endDate:null,
        flag:Number(accountstatus),
        searchStatus:Number(searchBy),
        onboardedby:0
      }
      this.reposrtservice.getTransactionReportByDateRange(formData).subscribe(data=>{
        this.transactionfilter3Detail = new GenericResponseModel;
        this.filterTransaction1 = false;
        this.filterTransaction2 = false;
        this.filterTransaction4 = false;
        this.filterTransaction5 = false;
        this.filterTransaction3 = true;
        this.accountStatus = data.accountStatus;
        
        if(data.response.length>0){
          this.filterthreesearch = true;
          this.transactionfilter3Detail = data;
        }else{
          this.filterthreesearch = false;
          this.transactionfilter3DetailNoRecord = data;
        }
        this.searchStatus = data.searchStatus;
        this.searchByCusorPart = data.accountStatus;
        if(data.response.length>0){
          this.excelexportchecktransaction = true;
        }else{
          this.excelexportchecktransaction = false;
        }
      });
    }else if(searchBy=='4'){
      
      this.filterTransaction1 = false;
      this.filterTransaction2 = false;
      this.filterTransaction3 = false;
      if(onboarded=='1'){
        this.onboardedby = 'Cinch Fuel';
        this.filterTransaction5 = false;
        this.filterTransaction4 = true;
        let formData= {
          startDate:null,
          endDate:null,
          flag:Number(accountstatus),
          searchStatus:Number(searchBy),
          onboardedby:Number(onboarded)
        }
        this.reposrtservice.getTransactionReportByDateRange(formData).subscribe(data=>{
          this.accountStatus = data.accountStatus;
          this.searchStatus = data.searchStatus;
          this.searchByCusorPart = data.accountStatus;
          this.searchByonboardedby = data.agentcount;
          if(data.response.length>0){
            this.filterfoursearch = true;
            this.excelexportchecktransaction = true;
            this.transactionfilter4Detail = data;
          }else{
            this.filterfoursearch = false;
            this.excelexportchecktransaction = false;
            this.transactionfilter4DetailNoRecord = data;
          }
        });

      }else if(onboarded=='2'){
        this.onboardedby = 'Others';
        this.filterTransaction4 = false;
        this.filterTransaction5 = true;
        let formData= {
          startDate:null,
          endDate:null,
          flag:Number(accountstatus),
          searchStatus:Number(searchBy),
          onboardedby:Number(onboarded)
        }
        this.reposrtservice.getTransactionReportByDateRange(formData).subscribe(data=>{
          this.accountStatus = data.accountStatus;
          this.searchStatus = data.searchStatus;
          this.searchByCusorPart = data.accountStatus;
          this.searchByonboardedby = data.agentcount;
          if(data.response.length>0){
            this.filterfivesearch = true;
            this.excelexportchecktransaction = true;
            this.transactionfilter5Detail = data;
          }else{
            this.filterfivesearch = false;
            this.excelexportchecktransaction = false;
            this.transactionfilter5DetailNoRecord = data;
          }
        });
      }
    }
    }
    refresh(){
      this.refreshflag = false;
      window.location.reload();
    }

    exportToExcelTransaction() {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let sno = 1;

      if(this.defaultTransaction){
        this.responseDataListnew = [];
        if(this.accountStatus==1){
          this.transactionDetail.response.forEach(element => {
            let vehicle = element['vehicleId'];
            let partner = vehicle['userId'];
            let customer = element['customerId'];
            let date = element['paymentDatetime'];
            let convertedDate;
            if(date==null || date==='null'){
              convertedDate = "N/A";
            }else{
              let dates = new Date(element['paymentDatetime']);
              convertedDate = moment(new Date(dates)).format("DD-MM-yyyy hh:mm:ss a").toString();
            }
            let inia = new Date(element['initiatedDatetime']);
            let  iniatedDate = moment(new Date(inia)).format("DD-MM-yyyy hh:mm:ss a").toString();
            var response = [];
            response.push(sno);
            response.push(element['orderId']);
            if(element['transactionId']==null || element['transactionId']==='null'){
              response.push("N/A");
            }else{
              response.push(element['transactionId']);
            }
            response.push(customer['firstName'] +' '+ customer['lastName']);
            response.push(customer['mobileNumber']);
            response.push(partner['firstName'] +' '+ partner['lastName']);
            response.push(partner['mobileNumber']);
            response.push(element['fuelType']);
            response.push(element['quantity']);
            response.push("₹ "+ this._decimalPipe.transform(element['totalPayableAmount'],"1.2-2"));
            response.push(element['paymentStatus']);
            response.push(iniatedDate);
            response.push(convertedDate);
            this.responseDataListnew.push(response);
            sno++;
          });
        }else if(this.accountStatus==2){
          this.transactionDetail.response.forEach(element => {
            let vehicle = element['vehicleId'];
            let partner = vehicle['userId'];
            let date = element['transactionDatetime'];
            let convertedDate;
            if(date==null || date==='null'){
              convertedDate = "N/A";
            }else{
              let dates = new Date(element['transactionDatetime']);
              convertedDate = moment(new Date(dates)).format("DD-MM-yyyy hh:mm:ss a").toString();
            }
            let inia = new Date(element['initiateDatetime']);
            let  iniatedDate = moment(new Date(inia)).format("DD-MM-yyyy hh:mm:ss a").toString();
            var response = [];
            response.push(sno);
            if(element['paymentId']==null || element['paymentId']==='null'){
              response.push("N/A");
            }else{
              response.push(element['paymentId']);
            }
            response.push(partner['firstName'] +' '+ partner['lastName']);
            response.push(partner['mobileNumber']);
            response.push("₹ "+ this._decimalPipe.transform(element['totalPayableAmount'],"1.2-2"));
            response.push(element['paymentStatus']);
            response.push(iniatedDate);
            response.push(convertedDate);
            this.responseDataListnew.push(response);
            sno++;
          });
        }
      }else if(this.filterTransaction1){
        this.responseDataListnew = [];
        if(this.accountStatus==1){
          this.transactionfilter1Detail.response.forEach(element => {
            let vehicle = element['vehicleId'];
            let partner = vehicle['userId'];
            let customer = element['customerId'];
            let date = element['paymentDatetime'];
            let convertedDate;
            if(date==null || date==='null'){
              convertedDate = "N/A";
            }else{
              let dates = new Date(element['paymentDatetime']);
              convertedDate = moment(new Date(dates)).format("DD-MM-yyyy hh:mm:ss a").toString();
            }
            let inia = new Date(element['initiatedDatetime']);
            let  iniatedDate = moment(new Date(inia)).format("DD-MM-yyyy hh:mm:ss a").toString();
            var response = [];
            response.push(sno);
            response.push(element['orderId']);
            if(element['transactionId']==null || element['transactionId']==='null'){
              response.push("N/A");
            }else{
              response.push(element['transactionId']);
            }
            response.push(customer['firstName'] +' '+ customer['lastName']);
            response.push(customer['mobileNumber']);
            response.push(partner['firstName'] +' '+ partner['lastName']);
            response.push(partner['mobileNumber']);
            response.push(element['fuelType']);
            response.push(element['quantity']);
            response.push("₹ "+ this._decimalPipe.transform(element['totalPayableAmount'],"1.2-2"));
            response.push(element['paymentStatus']);
            response.push(iniatedDate);
            response.push(convertedDate);
            this.responseDataListnew.push(response);
            sno++;
          });
        }else if(this.accountStatus==2){
          this.transactionfilter1Detail.response.forEach(element => {
            let vehicle = element['vehicleId'];
            let partner = vehicle['userId'];
            let date = element['transactionDatetime'];
            let convertedDate;
            if(date==null || date==='null'){
              convertedDate = "N/A";
            }else{
              let dates = new Date(element['transactionDatetime']);
              convertedDate = moment(new Date(dates)).format("DD-MM-yyyy hh:mm:ss a").toString();
            }
            let inia = new Date(element['initiateDatetime']);
            let  iniatedDate = moment(new Date(inia)).format("DD-MM-yyyy hh:mm:ss a").toString();
            var response = [];
            response.push(sno);
            if(element['paymentId']==null || element['paymentId']==='null'){
              response.push("N/A");
            }else{
              response.push(element['paymentId']);
            }
            response.push(partner['firstName'] +' '+ partner['lastName']);
            response.push(partner['mobileNumber']);
            response.push("₹ "+ this._decimalPipe.transform(element['totalPayableAmount'],"1.2-2"));
            response.push(element['paymentStatus']);
            response.push(iniatedDate);
            response.push(convertedDate);
            this.responseDataListnew.push(response);
            sno++;
          });
        }
      }else if(this.filterTransaction2){
        this.responseDataListnew = [];
        if(this.accountStatus==1){
          this.transactionfilter2Detail.response.forEach(element => {
            let vehicle = element['vehicleId'];
            let partner = vehicle['userId'];
            let customer = element['customerId'];
            let date = element['paymentDatetime'];
            let convertedDate;
            if(date==null || date==='null'){
              convertedDate = "N/A";
            }else{
              let dates = new Date(element['paymentDatetime']);
              convertedDate = moment(new Date(dates)).format("DD-MM-yyyy hh:mm:ss a").toString();
            }
            let inia = new Date(element['initiatedDatetime']);
            let  iniatedDate = moment(new Date(inia)).format("DD-MM-yyyy hh:mm:ss a").toString();
            var response = [];
            response.push(sno);
            response.push(element['orderId']);
            if(element['transactionId']==null || element['transactionId']==='null'){
              response.push("N/A");
            }else{
              response.push(element['transactionId']);
            }
            response.push(customer['firstName'] +' '+ customer['lastName']);
            response.push(customer['mobileNumber']);
            response.push(partner['firstName'] +' '+ partner['lastName']);
            response.push(partner['mobileNumber']);
            response.push(element['fuelType']);
            response.push(element['quantity']);
            response.push("₹ "+ this._decimalPipe.transform(element['totalPayableAmount'],"1.2-2"));
            response.push(element['paymentStatus']);
            response.push(iniatedDate);
            response.push(convertedDate);
            this.responseDataListnew.push(response);
            sno++;
          });
        }else if(this.accountStatus==2){
          this.transactionfilter2Detail.response.forEach(element => {
            let vehicle = element['vehicleId'];
            let partner = vehicle['userId'];
            let date = element['transactionDatetime'];
            let convertedDate;
            if(date==null || date==='null'){
              convertedDate = "N/A";
            }else{
              let dates = new Date(element['transactionDatetime']);
              convertedDate = moment(new Date(dates)).format("DD-MM-yyyy hh:mm:ss a").toString();
            }
            let inia = new Date(element['initiateDatetime']);
            let  iniatedDate = moment(new Date(inia)).format("DD-MM-yyyy hh:mm:ss a").toString();
            var response = [];
            response.push(sno);
            if(element['paymentId']==null || element['paymentId']==='null'){
              response.push("N/A");
            }else{
              response.push(element['paymentId']);
            }
            response.push(partner['firstName'] +' '+ partner['lastName']);
            response.push(partner['mobileNumber']);
            response.push("₹ "+ this._decimalPipe.transform(element['totalPayableAmount'],"1.2-2"));
            response.push(element['paymentStatus']);
            response.push(iniatedDate);
            response.push(convertedDate);
            this.responseDataListnew.push(response);
            sno++;
          });
        }
      }else if(this.filterTransaction3){
        this.responseDataListnew = [];
        if(this.accountStatus==1){
          this.transactionfilter3Detail.response.forEach(element => {
            let vehicle = element['vehicleId'];
            let partner = vehicle['userId'];
            let customer = element['customerId'];
            let date = element['paymentDatetime'];
            let convertedDate;
            if(date==null || date==='null'){
              convertedDate = "N/A";
            }else{
              let dates = new Date(element['paymentDatetime']);
              convertedDate = moment(new Date(dates)).format("DD-MM-yyyy hh:mm:ss a").toString();
            }
            let inia = new Date(element['initiatedDatetime']);
            let  iniatedDate = moment(new Date(inia)).format("DD-MM-yyyy hh:mm:ss a").toString();
            var response = [];
            response.push(sno);
            response.push(element['orderId']);
            if(element['transactionId']==null || element['transactionId']==='null'){
              response.push("N/A");
            }else{
              response.push(element['transactionId']);
            }
            response.push(customer['firstName'] +' '+ customer['lastName']);
            response.push(customer['mobileNumber']);
            response.push(partner['firstName'] +' '+ partner['lastName']);
            response.push(partner['mobileNumber']);
            response.push(element['fuelType']);
            response.push(element['quantity']);
            response.push("₹ "+ this._decimalPipe.transform(element['totalPayableAmount'],"1.2-2"));
            response.push(element['paymentStatus']);
            response.push(iniatedDate);
            response.push(convertedDate);
            this.responseDataListnew.push(response);
            sno++;
          });
        }else if(this.accountStatus==2){
          this.transactionfilter3Detail.response.forEach(element => {
            let vehicle = element['vehicleId'];
            let partner = vehicle['userId'];
            let date = element['transactionDatetime'];
            let convertedDate;
            if(date==null || date==='null'){
              convertedDate = "N/A";
            }else{
              let dates = new Date(element['transactionDatetime']);
              convertedDate = moment(new Date(dates)).format("DD-MM-yyyy hh:mm:ss a").toString();
            }
            let inia = new Date(element['initiateDatetime']);
            let  iniatedDate = moment(new Date(inia)).format("DD-MM-yyyy hh:mm:ss a").toString();
            var response = [];
            response.push(sno);
            if(element['paymentId']==null || element['paymentId']==='null'){
              response.push("N/A");
            }else{
              response.push(element['paymentId']);
            }
            response.push(partner['firstName'] +' '+ partner['lastName']);
            response.push(partner['mobileNumber']);
            response.push("₹ "+ this._decimalPipe.transform(element['totalPayableAmount'],"1.2-2"));
            response.push(element['paymentStatus']);
            response.push(iniatedDate);
            response.push(convertedDate);
            this.responseDataListnew.push(response);
            sno++;
          });
        }
      }else if(this.filterTransaction4){
        this.responseDataListnew = [];
        if(this.accountStatus==1){
          this.transactionfilter4Detail.response.forEach(element => {
            let vehicle = element['vehicleId'];
            let partner = vehicle['userId'];
            let customer = element['customerId'];
            let date = element['paymentDatetime'];
            let convertedDate;
            if(date==null || date==='null'){
              convertedDate = "N/A";
            }else{
              let dates = new Date(element['paymentDatetime']);
              convertedDate = moment(new Date(dates)).format("DD-MM-yyyy hh:mm:ss a").toString();
            }
            let inia = new Date(element['initiatedDatetime']);
            let  iniatedDate = moment(new Date(inia)).format("DD-MM-yyyy hh:mm:ss a").toString();
            var response = [];
            response.push(sno);
            response.push(element['orderId']);
            if(element['transactionId']==null || element['transactionId']==='null'){
              response.push("N/A");
            }else{
              response.push(element['transactionId']);
            }
            response.push(customer['firstName'] +' '+ customer['lastName']);
            response.push(customer['mobileNumber']);
            response.push(partner['firstName'] +' '+ partner['lastName']);
            response.push(partner['mobileNumber']);
            response.push(element['fuelType']);
            response.push(element['quantity']);
            response.push("₹ "+ this._decimalPipe.transform(element['totalPayableAmount'],"1.2-2"));
            response.push(element['paymentStatus']);
            response.push(iniatedDate);
            response.push(convertedDate);
            this.responseDataListnew.push(response);
            sno++;
          });
        }else if(this.accountStatus==2){
          this.transactionfilter4Detail.response.forEach(element => {
            let vehicle = element['vehicleId'];
            let partner = vehicle['userId'];
            let date = element['transactionDatetime'];
            let convertedDate;
            if(date==null || date==='null'){
              convertedDate = "N/A";
            }else{
              let dates = new Date(element['transactionDatetime']);
              convertedDate = moment(new Date(dates)).format("DD-MM-yyyy hh:mm:ss a").toString();
            }
            let inia = new Date(element['initiateDatetime']);
            let  iniatedDate = moment(new Date(inia)).format("DD-MM-yyyy hh:mm:ss a").toString();
            var response = [];
            response.push(sno);
            if(element['paymentId']==null || element['paymentId']==='null'){
              response.push("N/A");
            }else{
              response.push(element['paymentId']);
            }
            response.push(partner['firstName'] +' '+ partner['lastName']);
            response.push(partner['mobileNumber']);
            response.push("₹ "+ this._decimalPipe.transform(element['totalPayableAmount'],"1.2-2"));
            response.push(element['paymentStatus']);
            response.push(iniatedDate);
            response.push(convertedDate);
            this.responseDataListnew.push(response);
            sno++;
          });
        }
      }else if(this.filterTransaction5){
        this.responseDataListnew = [];
        if(this.accountStatus==1){
          this.transactionfilter5Detail.response.forEach(element => {
            let vehicle = element['vehicleId'];
            let partner = vehicle['userId'];
            let customer = element['customerId'];
            let date = element['paymentDatetime'];
            let convertedDate;
            if(date==null || date==='null'){
              convertedDate = "N/A";
            }else{
              let dates = new Date(element['paymentDatetime']);
              convertedDate = moment(new Date(dates)).format("DD-MM-yyyy hh:mm:ss a").toString();
            }
            let inia = new Date(element['initiatedDatetime']);
            let  iniatedDate = moment(new Date(inia)).format("DD-MM-yyyy hh:mm:ss a").toString();
            var response = [];
            response.push(sno);
            response.push(element['orderId']);
            if(element['transactionId']==null || element['transactionId']==='null'){
              response.push("N/A");
            }else{
              response.push(element['transactionId']);
            }
            response.push(customer['firstName'] +' '+ customer['lastName']);
            response.push(customer['mobileNumber']);
            response.push(partner['firstName'] +' '+ partner['lastName']);
            response.push(partner['mobileNumber']);
            response.push(element['fuelType']);
            response.push(element['quantity']);
            response.push("₹ "+ this._decimalPipe.transform(element['totalPayableAmount'],"1.2-2"));
            response.push(element['paymentStatus']);
            response.push(iniatedDate);
            response.push(convertedDate);
            this.responseDataListnew.push(response);
            sno++;
          });
        }else if(this.accountStatus==2){
          this.transactionfilter5Detail.response.forEach(element => {
            let vehicle = element['vehicleId'];
            let partner = vehicle['userId'];
            let date = element['transactionDatetime'];
            let convertedDate;
            if(date==null || date==='null'){
              convertedDate = "N/A";
            }else{
              let dates = new Date(element['transactionDatetime']);
              convertedDate = moment(new Date(dates)).format("DD-MM-yyyy hh:mm:ss a").toString();
            }
            let inia = new Date(element['initiateDatetime']);
            let  iniatedDate = moment(new Date(inia)).format("DD-MM-yyyy hh:mm:ss a").toString();
            var response = [];
            response.push(sno);
            if(element['paymentId']==null || element['paymentId']==='null'){
              response.push("N/A");
            }else{
              response.push(element['paymentId']);
            }
            response.push(partner['firstName'] +' '+ partner['lastName']);
            response.push(partner['mobileNumber']);
            response.push("₹ "+ this._decimalPipe.transform(element['totalPayableAmount'],"1.2-2"));
            response.push(element['paymentStatus']);
            response.push(iniatedDate);
            response.push(convertedDate);
            this.responseDataListnew.push(response);
            sno++;
          });
        }
      }

      this.excelexportTransaction();
      const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.responseDataList);
      const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'buffer' });
      const data: Blob = new Blob([excelBuffer], {
        type: EXCEL_TYPE
      });
     }


     excelexportTransaction(){
      const title = 'Transaction Report';
      let header;
      if(this.accountStatus==1){
      header = ["S.No","Order ID","Transaction ID" ,"Customer Name", "Customer Mobile", "Partner Name", "Partner Mobile","Fuel", "Quantity","Transaction Amount","Payment Status","Initiated Date & Time","Transaction Date & Time"]
      }else if(this.accountStatus==2){
        header = ["S.No","Transaction ID" ,"Partner Name", "Partner Mobile","Transaction Amount","Payment Status","Initiated Date & Time","Transaction Date & Time"]
      }
      // const header = ["S.No","Order ID","Transaction ID" ,"Customer Name", "Customer Mobile", "Partner Name", "Partner Mobile","Fuel", "Quantity","Transaction Amount","Payment Status","Transaction Date & Time"]
      let data = [];
      data = this.responseDataListnew;
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet('Transaction Report');
  // Add new row
  let titleRow = worksheet.addRow([title]);
  // Set font, size and style in title row.
  titleRow.font = { name: 'Times New Roman', family: 4, size: 16, bold: true };
  
  // Blank Row
  worksheet.addRow([]);
  if(this.searchStatus==1){
    let subTitleRow0 = worksheet.addRow(['Search By Initiated Date & Time ' ]);
    subTitleRow0.font = { name: 'Times New Roman', family: 4, size: 12, bold: true };
    let subTitleRow = worksheet.addRow(['Start Date  : ' + this.startDate]);
    subTitleRow.font = { name: 'Times New Roman', family: 4, size: 12, bold: true };
    let subTitleRow1 = worksheet.addRow(['End Date  : ' + this.endDate]);
    subTitleRow1.font = { name: 'Times New Roman', family: 4, size: 12, bold: true };
  }else if(this.searchStatus==2){
    let subTitleRow0 = worksheet.addRow(['Search By Transaction Date & Time ']);
    subTitleRow0.font = { name: 'Times New Roman', family: 4, size: 12, bold: true };
    let subTitleRow = worksheet.addRow(['Start Date  : ' + this.startDate]);
    subTitleRow.font = { name: 'Times New Roman', family: 4, size: 12, bold: true };
    let subTitleRow1 = worksheet.addRow(['End Date  : ' + this.endDate]);
    subTitleRow1.font = { name: 'Times New Roman', family: 4, size: 12, bold: true };
  }
 
  if(this.searchByCusorPart==1){
    let subTitleRow2 = worksheet.addRow(['User : ' + "Customer"]);
    subTitleRow2.font = { name: 'Times New Roman', family: 4, size: 12, bold: true };
  }else if(this.searchByCusorPart==2){
    let subTitleRow2 = worksheet.addRow(['User : ' + "Partner"]);
    subTitleRow2.font = { name: 'Times New Roman', family: 4, size: 12, bold: true };
  }

  if(this.searchStatus==4){
    let subTitleRow2 = worksheet.addRow(['Onboarded By : ' + this.onboardedby]);
    subTitleRow2.font = { name: 'Times New Roman', family: 4, size: 12, bold: true };
  }
  
  worksheet.addRow([]);
  //Add row with current date
  // let subTitleRow = worksheet.addRow(['Date : ' + this.datePipe.transform(new Date(), 'medium')]);
  //Add Header Row
  let headerRow = worksheet.addRow(header);
  headerRow.font = {bold: true };
  // Cell Style : Fill and Border
  headerRow.eachCell((cell, number) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFFFFFF' },
      bgColor: { argb: 'FF0000FF' },
    }
    cell.border = {  top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  });
  
  data.forEach(d => {
    if(this.accountStatus==1){
      let row = worksheet.addRow(d);
      let qty = row.getCell(1);
      let qty1 = row.getCell(2);
      let qty2 = row.getCell(3);
      let qty3 = row.getCell(4);
      let qty4 = row.getCell(5);
      let qty5 = row.getCell(6);
      let qty6 = row.getCell(7);
      let qty7 = row.getCell(8);
      let qty8 = row.getCell(9);
      let qty9 = row.getCell(10);
      let qty10 = row.getCell(11);
      let qty11= row.getCell(12);
      let qty12= row.getCell(12);
      let qty13= row.getCell(13);
      qty.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      qty1.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      qty2.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      qty3.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      qty4.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      qty5.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      qty6.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      qty7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      qty8.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      qty9.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      qty10.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      qty11.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      qty12.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      qty13.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    }else if(this.accountStatus==2){
      let row = worksheet.addRow(d);
      let qty = row.getCell(1);
      let qty1 = row.getCell(2);
      let qty2 = row.getCell(3);
      let qty3 = row.getCell(4);
      let qty4 = row.getCell(5);
      let qty5 = row.getCell(6);
      let qty6 = row.getCell(7);
      let qty7 = row.getCell(8);
      qty.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      qty1.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      qty2.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      qty3.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      qty4.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      qty5.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      qty6.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      qty7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    }
  }
  );
  workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    FileSaver.saveAs(blob, 'Transaction Report.xlsx');
  });
     }

     callsearch(event){
       if(event.target.value==4){
        $('#fromdate').hide();
        $('#todate').hide();
        $('#onboarded').show();
       }else if(event.target.value==3){
        $('#fromdate').hide();
        $('#todate').hide();
        $('#onboarded').hide();
      }else if(event.target.value==2){
        $('#fromdate').show();
        $('#todate').show();
        $('#onboarded').hide();
      }else if(event.target.value==1){
        $('#fromdate').show();
        $('#todate').show();
        $('#onboarded').hide();
      }
    }  

    delete(){
      this.spinnerService.show();
      let userId = this.deleteUserId;
      this.reposrtservice.deletetransaction(userId).subscribe(data=>{
        if(data.statusCode==200){
          this.spinnerService.hide();
          Swal.fire({
            html: '<pre>' + data.responseMessage + '</pre>',
            customClass: {
              popup: 'format-pre'
            }
          }).then((value) => {
            window.location.reload();
          });; ;
        }
      },err => {
        if(err.error.statusCode==406){
          this.router.navigate(['/login']);
        }else if(err.error.statusCode==500){
          this.router.navigate(['/page-error']);
        }else if(err.error.statusCode==404){
          this.router.navigate(['/pagenotfound']);
        }
     });
    }
    update(content1,id,i,approvalStatus,receivedAmount,paymentReferenceNo,totalPayableAmount){
      this.id=id;
      this.paymentModeId=i;
      this.approvalStatus = approvalStatus;
      this.paymentReferenceNo=paymentReferenceNo;
      this.receivedAmount=receivedAmount;
      this.totalPayableAmount=totalPayableAmount
      this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  
    approve(i,totalPayableAmount){
    
        let form;
        let paymentDate=null;
        let remarks=null;
        let formatDate = null;
          if(i==2){ // COD
            let receivedAmount = (<HTMLInputElement>document.getElementById('receivedAmount')).value;
            let approvalStatus = (<HTMLInputElement>document.getElementById('approvalStatus')).value;
            if(approvalStatus=='Success'){
              paymentDate = (<HTMLInputElement>document.getElementById('paymentDate')).value;
              formatDate = moment(new Date(paymentDate)).format("DD/MM/YYYY HH:mm a").toString();
              remarks = (<HTMLInputElement>document.getElementById('remarks')).value;
            
            }
          
            form={
              receivedAmount:receivedAmount,
              approvalStatus:approvalStatus,
              approvalDate:formatDate ,
              approvalRemarks:remarks,
              paymentModeId:i,
              submittedBy:sessionStorage.getItem("username"),
              chequeClaimStatus:null
            }
            if(receivedAmount=='' || approvalStatus==''){
              this.poppupalert = true;
              return false;
            }
          }else if(i==5){ //CHEQUE /DD
            
            let approvalStatus = (<HTMLInputElement>document.getElementById('approvalStatus')).value;
            let paymentReferenceNo = (<HTMLInputElement>document.getElementById('paymentReferenceNo')).value;
           
            if(approvalStatus=='Success'){
              paymentDate = (<HTMLInputElement>document.getElementById('paymentDate')).value;
              formatDate = moment(new Date(paymentDate)).format("DD/MM/YYYY HH:mm a").toString();
              remarks = (<HTMLInputElement>document.getElementById('remarks')).value;
            
            }
            form={
              paymentReferenceNo:paymentReferenceNo,
              approvalStatus:approvalStatus,
              chequeClaimStatus:'Pending',
              approvalDate:formatDate ,
              approvalRemarks:remarks,
              paymentModeId:i,
              receivedAmount:totalPayableAmount,
              submittedBy:sessionStorage.getItem("username")
            }
            if(paymentReferenceNo=='' || approvalStatus==''){
              this.poppupalert = true;
              return false;
            }

          }else if(i==3 || i==8){   // POS
            let approvalStatus = (<HTMLInputElement>document.getElementById('approvalStatus')).value;
            let paymentReferenceNo = (<HTMLInputElement>document.getElementById('paymentReferenceNo')).value;

            if(approvalStatus=='Success'){
              paymentDate = (<HTMLInputElement>document.getElementById('paymentDate')).value;
              formatDate = moment(new Date(paymentDate)).format("DD/MM/YYYY HH:mm a").toString();
              remarks = (<HTMLInputElement>document.getElementById('remarks')).value;
            }

            form={
              paymentReferenceNo:paymentReferenceNo,
              approvalStatus:approvalStatus,
              approvalDate:formatDate ,
              approvalRemarks:remarks,
              paymentModeId:i,
              submittedBy:sessionStorage.getItem("username"),
              chequeClaimStatus:null,
              receivedAmount:totalPayableAmount,
            }
            if(paymentReferenceNo=='' || approvalStatus==''){
              this.poppupalert = true;
              return false;
            }
          }else if(i==4){   // Pay Later
            let approvalStatus = (<HTMLInputElement>document.getElementById('approvalStatus')).value;
           // let receivedAmount = (<HTMLInputElement>document.getElementById('receivedAmount')).value;

            if(approvalStatus=='Success'){
              paymentDate = (<HTMLInputElement>document.getElementById('paymentDate')).value;
              formatDate = moment(new Date(paymentDate)).format("DD/MM/YYYY HH:mm a").toString();
              remarks = (<HTMLInputElement>document.getElementById('remarks')).value;
            }
            form={
              approvalStatus:approvalStatus,
              approvalDate:formatDate ,
              approvalRemarks:remarks,
              paymentModeId:i,
              submittedBy:sessionStorage.getItem("username"),
              chequeClaimStatus:null,
              receivedAmount:this.totalPayableAmount,
            }
            if(approvalStatus==''){
              this.poppupalert = true;
              return false;
            }
          }
          this.poppupalert = false;
          this.reposrtservice.updateStatus(this.id,form).subscribe(data=>{
            this.modalService.dismissAll();
            Swal.fire({
              html: '<pre>' + 'Order has been updated successfully' + '</pre>',
              customClass: {
                popup: 'format-pre'
              }
            }).then((value) => {
              window.location.reload();
            });; ; 
           // this.transactionDetail = data;
            setTimeout(function() {
          }.bind(this), 3000);
          },err => {
            if(err.error.statusCode==406){
              this.router.navigate(['/login']);
            }else if(err.error.statusCode==500){
              this.router.navigate(['/page-error']);
            }else if(err.error.statusCode==404){
              this.router.navigate(['/pagenotfound']);
            }
         });
       
  }

  updateClaim(content1,id,paymentReferenceNo,approvalStatus,claimStatus){
    this.id=id;
    this.paymentModeId=5;
    this.paymentReferenceNo = paymentReferenceNo;
    this.approvalStatus = approvalStatus;
    this.chequeClaimStatus=claimStatus;
    this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  claimApprove(){
    
      let bounceCharges = null;
      let bounceDate = null;
      let formatDate = null;
        let chequeClaimStatus = (<HTMLInputElement>document.getElementById('chequeClaimStatus')).value;
        if(chequeClaimStatus=='Bounced'){
          bounceCharges = (<HTMLInputElement>document.getElementById('bounceCharges')).value;
          bounceDate = (<HTMLInputElement>document.getElementById('bounceDate')).value;
          formatDate = moment(new Date(bounceDate)).format("DD/MM/YYYY HH:mm a").toString();

        }
      
        let form={
          paymentReferenceNo:this.paymentReferenceNo,
          approvalStatus:this.approvalStatus,
          chequeClaimStatus:chequeClaimStatus,
          chequeBounceCharges:bounceCharges,
          chequeBounceDate:formatDate,
          paymentModeId:this.paymentModeId
        }
     
      this.reposrtservice.updateStatus(this.id,form).subscribe(data=>{
        this.modalService.dismissAll();
        Swal.fire({
          html: '<pre>' + 'Order has been updated successfully' + '</pre>',
          customClass: {
            popup: 'format-pre'
          }
        }).then((value) => {
          window.location.reload();
        });; ; 
       // this.transactionDetail = data;
        setTimeout(function() {
      }.bind(this), 3000);
      },err => {
        if(err.error.statusCode==406){
          this.router.navigate(['/login']);
        }else if(err.error.statusCode==500){
          this.router.navigate(['/page-error']);
        }else if(err.error.statusCode==404){
          this.router.navigate(['/pagenotfound']);
        }
     });
   
}

selectFile(event) {
  this.selectedFiles = event.target.files;
}
approval(event){
  if(event.target.value=='Success'){
    this.successStatus=true;
  }else{
    this.successStatus=false;
  }
}

bounce(event){
  if(event.target.value=='Bounced'){
    this.bounceStatus=true;
  }else{
    this.bounceStatus=false;
  }
}
uploadCheque(id){
  
  let chequeFile = (<HTMLInputElement>document.getElementById('file')).value;
  let approvalStatus = (<HTMLInputElement>document.getElementById('approvalStatus')).value;
  let paymentReferenceNo = (<HTMLInputElement>document.getElementById('paymentReferenceNo')).value;
  
  let file='';
    
    if(chequeFile!==null && chequeFile!=='' && approvalStatus!='' && paymentReferenceNo!=''){
      this.progress = 0;
     
      this.currentFile = this.selectedFiles.item(0);
      file = this.currentFile.type;
      let arr =  file.split("/");
      let fileType = arr[1];
      fileType = 'Image';
    
      let input = new FormData();
    
      if(this.imageUpload){
        let kycfile1 = (<HTMLInputElement>document.getElementById('file')).value;
      
        if(kycfile1!=null && kycfile1!==''){
          input.append('uploadCheque',this.selectedFiles.item(0));
        }else{
          input.append('uploadCheque',this.currentFile);
        }
     
      }else if(this.pdfUpload){
        let kycFile = this.currentFile;
        input.append('uploadCheque',this.currentFile);
      }
      
      input.append('id',id);
      input.append('paymentReferenceNo',paymentReferenceNo);
      input.append('approvalStatus',approvalStatus);
      input.append('chequeClaimStatus','Pending');
      
      this.reposrtservice.upload(input).subscribe(data=>{
          if(data.response.flag==1){
            this.modalService.dismissAll();
            Swal.fire({
              html: '<pre>' + data.responseMessage + '</pre>',
              customClass: {
                popup: 'format-pre'
              }
            }).then((value) => {
              window.location.reload();
            });; ; 
            setTimeout(function() {
          }.bind(this), 3000);
          }else{
            
            setTimeout(function() {
              this.kycresponseString = data.responseMessage;
              this.kycalert = false;
              window.location.reload();
          }.bind(this), 3000);
          }
      },err => {
        if(err.error.statusCode==406){
          this.router.navigate(['/login']);
        }else if(err.error.statusCode==500){
          this.router.navigate(['/page-error']);
        }else if(err.error.statusCode==404){
          this.router.navigate(['/pagenotfound']);
        }
     });

    }else{
     
      this.poppupalert = true;
     // this.emptyCheckResponse = "Please select file!";
      setTimeout(function() {
        this.emptyCheck = false;
    }.bind(this), 3000);

    }
}

}
