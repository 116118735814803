import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { RestResponseModel } from '../model/response';
import { environment } from 'src/environments/environment';
import { MarketPrizeModel } from '../model/marketprize';
import { GenericResponseModel } from '../model/genricresponse';
import { FuelTypeModel } from '../model/fueltype';

@Injectable({
    providedIn: "root"
  })
  export class FuelRateMasterService {

    constructor(private httpClient:HttpClient) {}

  private baseUrl = environment.devurl;
  private auth = environment.headers;

    public getAllMarketPrice(){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/fuelratemaster/view",{headers});
  }

  public getAllMarketPriceByVehicle(id){
   // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/fuelratemaster/viewByVehicle"+"/"+id,{headers});
    }

    public getCurrentFuelRate(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<MarketPrizeModel>(this.baseUrl+"/fuelratemaster/getCurrentFuelRate"+"/"+id,{headers});
    }

  public deleteMarket(id){
   // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.delete<GenericResponseModel>(this.baseUrl+"/fuelratemaster/delete"+"/"+id,{headers});
  }

  public addMarket(market){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.post<RestResponseModel>(this.baseUrl+"/fuelratemaster/add",market,{headers});
  }

  public getFuelTypes(){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<FuelTypeModel[]>(this.baseUrl+"/fuelratemaster/getFuelTypes",{headers});
}

  }