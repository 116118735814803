<app-header></app-header>

<!-- START PAGE-CONTAINER -->
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
  </ngx-spinner> 
<div class="page-container "> 
  <!-- START PAGE CONTENT WRAPPER -->
  <div class="page-content-wrapper "> 
    <!-- START PAGE CONTENT -->
    <div class="content sm-gutter"> 
      <!-- START BREADCRUMBS -->
      <div class="bg-white">
        <div class="container">
          <ol class="breadcrumb breadcrumb-alt">
            <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Home</a></li>
            <li class="breadcrumb-item active"><span class="font-montserrat fs-11 all-caps">Partner Settlements</span></li>
          </ol>
        </div>
      </div>

      <!-- START CONTAINER FLUID -->
      <div class=" no-padding    container-fixed-lg bg-white">
        <div class="container"> 
          <div class="card card-transparent">
            <div>
            
            <select class="select-form-style" id="readyStatus"  style="margin-left: 10px; margin-top: 5px;">
              <option value="22" selected>Settlement Initiated</option>
              <option value="23" >Settlement Completed</option>
          </select>  

        <input type="date" style="margin-left: 10px; margin-top: 5px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
        <input type="date" style="margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
        <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
        <button title="Refresh" *ngIf="refreshFlag" (click)="refresh()"style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button> 
        <span *ngIf="errorFlag" style="color: red;">{{errorMessage}}</span>
      </div> 
            <div class="btn-section-wrapper">
              <button *ngIf="excelexportcheck" (click)="exportToExcel()" title="Excel Export" style="margin-left: 10px; margin-top: 5px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
            </div>
            <div *ngIf="!filterpartner" class="card-body">
              <div *ngIf="readytoSettle || status==22">
                <div class="btn-section-wrapper" style="margin-top: -10px;">
                  <button (click)="upload(content9)"  id="upload" class="label label-warning">Upload File</button>

                  <button (click)="markasSettle()"  id="refresh" class="label label-success">Mark as Settled</button>
                </div>
              </div>


                <div style="display: none;">{{partnerSettlementreportModel.responseMessage}}</div>
              <div class="table-responsive" style="margin-top: 20px;">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.1</th>
                      <th style="text-align: center;" *ngIf="status==22">&nbsp;</th> 
                      <th style="text-align: left;">Order Info</th>
                      <th style="text-align: left;">Customer Info</th>
                      <th style="text-align: left;width: 50px;">Partner Info</th>
                      <th style="text-align: left;width: 50px;">Payment Info</th>
                      <th style="text-align: left;">Payment Mode</th> 
                      <th style="text-align: left;">Status</th>
                      <th style="text-align: left;">Settlement Info</th>
                      <th style="text-align: center; width: 120px;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let partner of partnerSettlementreportModel.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td align="center" class="v-align-middle " *ngIf="status==22"><span style="width:80px">
                        <input *ngIf="partner.endOrderOtpStatus==1" type="checkbox" value=" {{partner.id}}" (change)="onChange(partner.id,$event)">
                      </span></td>
                      <td *ngIf="partner.orderId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 10px;"><b>{{partner.orderId}}</b></span><br>
                        <span *ngIf="partner.trackId!=null"style="font-size: 10px;"><b>Track Id :</b>{{partner.trackId}}</span>
                        <span *ngIf="partner.trackId==null"style="font-size: 10px;"><b>Track Id :</b>N/A</span>
                       </td>
                      <td *ngIf="partner.orderId==null"align="left" class="v-align-middle">N/A</td>

                      <td *ngIf="partner.customerId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 12px;">{{partner.customerId.firstName}} {{partner.customerId.lastName}}</span><br>
                        <span style="font-size: 12px;"> {{partner.customerId.mobileNumber}}</span>
                      </td>
                      <td *ngIf="partner.customerId==null"align="left" class="v-align-middle">N/A</td>

                      <td *ngIf="partner.vehicleId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 12px;">{{partner.vehicleId.userId.firstName}} {{partner.vehicleId.userId.lastName}}</span><br>
                        <span style="font-size: 12px;">{{partner.vehicleId.userId.mobileNumber}}</span>
                      </td>
                      <td *ngIf="partner.vehicleId==null"align="left" class="v-align-middle">N/A</td>

                      <td>
                        <span *ngFor="let obj of partner.transactionList;">
                            <span *ngIf="obj.paymentStatus=='Success'">
                              <span *ngIf="obj.paymentId!=null"style="font-size: 10px;"><b>Txn Id :</b>{{obj.paymentId}}</span>
                              <span *ngIf="obj.paymentId==null"style="font-size: 10px;"><b>Txn Id :</b>N/A</span><br/>
                              <span *ngIf="obj.paymentDatetime!=null"style="font-size: 10px;"> <b>Date:</b>{{obj.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}<br/></span>
                              <span *ngIf="partner.totalPayableAmount!=null"style="font-size: 10px;"> <b>Amount:</b>{{partner.totalPayableAmount | number : '1.2-2'}}</span>
                            </span>
                        </span>
                     
                      </td>

                      <td *ngIf="partner.paymentModeId!=null" align="left" class="v-align-middle">{{partner.paymentModeId.paymentMode}}</td>
                      <td *ngIf="partner.paymentModeId==null" align="left" class="v-align-middle">N/A</td>

                        <td align="center" *ngIf="partner.paymentStatus!=null && partner.paymentStatus==='Success' "class="v-align-middle ht-active">{{partner.paymentStatus}}</td>
                        <td align="center" *ngIf="partner.paymentStatus!=null && partner.paymentStatus==='Failure' " style="color: red;">Failed</td>
                        <td align="center" *ngIf="partner.paymentStatus!=null && partner.paymentStatus==='cancelled' " style="color: red;">{{partner.paymentStatus}}</td>
                        <td align="center" *ngIf="partner.paymentStatus!=null && partner.paymentStatus==='Cancelled' " style="color: red;">{{partner.paymentStatus}}</td>
                        <td align="center" *ngIf="partner.paymentStatus!=null && partner.paymentStatus==='Initiated' " style="color: blue;">{{partner.paymentStatus}}</td>

                      <!-- <td *ngIf="partner.paymentDatetime!=null" align="left" class="v-align-middle">{{partner.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                      <td *ngIf="partner.paymentDatetime==null" align="left" class="v-align-middle">N/A</td>

                      <td *ngIf="partner.bookedDate!=null" align="left" class="v-align-middle">{{partner.bookedDate | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                      <td *ngIf="partner.bookedDate==null" align="left" class="v-align-middle">N/A</td>

                      <td *ngIf="partner.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{partner.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                      <td *ngIf="partner.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td> -->
                      <td>
                        <span style="font-size: 11px;" *ngIf="partner.utrNumber!=null">UTR No: {{partner.utrNumber}}</span>
                        <span style="font-size: 11px;" *ngIf="partner.utrNumber==null">UTR No: N/A</span>
                        <br/>
                        <span style="font-size: 11px;" *ngIf="partner.settledAmount!=null">Amt {{partner.settledAmount | number : '1.2-2'}}</span>
                        <span style="font-size: 11px;" *ngIf="partner.settledAmount==null">Amt: N/A</span>
                        <br/>
                        <span style="font-size: 11px;" *ngIf="partner.settledDatetime!=null">Date: {{partner.settledDatetime | date:'dd/MM/yyyy'}}</span>
                        <span style="font-size: 11px;" *ngIf="partner.settledDatetime==null">Date: N/A</span>
                      </td>
                      <td align="center" class="v-align-middle">
                        <button title="Manual Settlement" *ngIf="status==22" style="margin-left: 5px;" class="label label-success" (click)="updateSettlement(partner.id,content8)" ><i class="fa fa-pencil" aria-hidden="true"></i></button>

                       <button style="margin-left: 5px;" title="view" class="label label-info" (click)="view(content6,partner.id)"><i class="fa fa-eye" aria-hidden="true"></i></button>

                        <!-- <span *ngIf="partner.endOrderOtpStatus==1">N/A</span>  -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div *ngIf="filterpartner" class="card-body">
              <div *ngIf="filteronecheck"> 
               
                <div *ngIf="status==22">
                  <div class="btn-section-wrapper" style="margin-top: -40px;">
                    <button (click)="upload(content9)"  id="upload" class="label label-warning">Upload File</button>

                    <button (click)="markasSettle()"  id="refresh" class="label label-success">Mark as Settled</button>
                  </div>
                </div>

                <div style="display: none;">{{filterpartnerSettlementreportModel.responseMessage}}</div>
                <div class="table-responsive" style="margin-top: 40px;">
                  <table datatable class="ch-table">
                    <thead>
                      <tr> 
                        <th style="text-align: center;">Sl.No.{{status}}</th>
                        <th style="text-align: center;">&nbsp;</th> 
                        <th style="text-align: left;">Order Info</th>
                        <th style="text-align: left;">Customer Info</th>
                        <th style="text-align: left;width: 50px;">Partner Info</th>
                        <th style="text-align: left;width: 50px;">Payment Info</th>
                        <th style="text-align: left;">Payment Mode</th> 
                        <th style="text-align: left;">Status</th>
                        <th style="text-align: left;">Settlement Info</th>
                        <th style="text-align: center; width: 120px;">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let partner of filterpartnerSettlementreportModel.response; let i=index">
                        <td align="center" class="v-align-middle ">{{i + 1}}</td>
                        <td align="center" class="v-align-middle " ><span style="width:80px">
                          <input *ngIf="status==22" type="checkbox" value=" {{partner.id}}" (change)="onChange(partner.id,$event)">
                        </span></td>
                        <td *ngIf="partner.orderId!=null"align="left" class="v-align-middle">
                          <span style="font-size: 10px;"><b>{{partner.orderId}}</b></span><br>
                          <span *ngIf="partner.trackId!=null"style="font-size: 10px;"><b>Track Id :</b>{{partner.trackId}}</span>
                          <span *ngIf="partner.trackId==null"style="font-size: 10px;"><b>Track Id :</b>N/A</span>
                         </td>
                        <td *ngIf="partner.orderId==null"align="left" class="v-align-middle">N/A</td>
  
                        <td *ngIf="partner.customerId!=null"align="left" class="v-align-middle">
                          <span style="font-size: 12px;">{{partner.customerId.firstName}} {{partner.customerId.lastName}}</span><br>
                          <span style="font-size: 12px;"> {{partner.customerId.mobileNumber}}</span>
                        </td>
                        <td *ngIf="partner.customerId==null"align="left" class="v-align-middle">N/A</td>
  
                        <td *ngIf="partner.vehicleId!=null"align="left" class="v-align-middle">
                          <span style="font-size: 12px;">{{partner.vehicleId.userId.firstName}} {{partner.vehicleId.userId.lastName}}</span><br>
                          <span style="font-size: 12px;"> {{partner.vehicleId.userId.mobileNumber}}</span>
                        </td>
                        <td *ngIf="partner.vehicleId==null"align="left" class="v-align-middle">N/A</td>
  
                        <td>
                          <span *ngFor="let obj of partner.transactionList;">
                              <span *ngIf="obj.paymentStatus=='Success'">
                                <span *ngIf="obj.paymentId!=null"style="font-size: 10px;"><b>Txn Id :</b>{{obj.paymentId}}</span>
                                <span *ngIf="obj.paymentId==null"style="font-size: 10px;"><b>Txn Id :</b>N/A</span><br/>
                                <span *ngIf="obj.paymentDatetime!=null"style="font-size: 10px;"> <b>Date:</b>{{obj.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}<br/></span>
                                <span *ngIf="partner.totalPayableAmount!=null"style="font-size: 10px;"> <b>Amount:</b>{{partner.totalPayableAmount | number : '1.2-2'}}</span>
                              </span>
                          </span>
                       
                        </td>
  
                        <td *ngIf="partner.paymentModeId!=null" align="left" class="v-align-middle">{{partner.paymentModeId.paymentMode}}</td>
                        <td *ngIf="partner.paymentModeId==null" align="left" class="v-align-middle">N/A</td>
  
                          <td align="center" *ngIf="partner.paymentStatus!=null && partner.paymentStatus==='Success' "class="v-align-middle ht-active">{{partner.paymentStatus}}</td>
                          <td align="center" *ngIf="partner.paymentStatus!=null && partner.paymentStatus==='Failure' " style="color: red;">Failed</td>
                          <td align="center" *ngIf="partner.paymentStatus!=null && partner.paymentStatus==='cancelled' " style="color: red;">{{partner.paymentStatus}}</td>
                          <td align="center" *ngIf="partner.paymentStatus!=null && partner.paymentStatus==='Cancelled' " style="color: red;">{{partner.paymentStatus}}</td>
                          <td align="center" *ngIf="partner.paymentStatus!=null && partner.paymentStatus==='Initiated' " style="color: blue;">{{partner.paymentStatus}}</td>
  
                        <!-- <td *ngIf="partner.paymentDatetime!=null" align="left" class="v-align-middle">{{partner.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                        <td *ngIf="partner.paymentDatetime==null" align="left" class="v-align-middle">N/A</td>
  
                        <td *ngIf="partner.bookedDate!=null" align="left" class="v-align-middle">{{partner.bookedDate | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                        <td *ngIf="partner.bookedDate==null" align="left" class="v-align-middle">N/A</td>
  
                        <td *ngIf="partner.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{partner.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                        <td *ngIf="partner.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td> -->
                        <td>
                          <span style="font-size: 11px;" *ngIf="partner.utrNumber!=null">UTR No: {{partner.utrNumber}}</span>
                          <span style="font-size: 11px;" *ngIf="partner.utrNumber==null">UTR No: N/A</span>
                          <br/>
                          <span style="font-size: 11px;" *ngIf="partner.settledAmount!=null">Amt {{partner.settledAmount | number : '1.2-2'}}</span>
                          <span style="font-size: 11px;" *ngIf="partner.settledAmount==null">Amt: N/A</span>
                          <br/>
                          <span style="font-size: 11px;" *ngIf="partner.settledDatetime!=null">Date: {{partner.settledDatetime | date:'dd/MM/yyyy'}}</span>
                          <span style="font-size: 11px;" *ngIf="partner.settledDatetime==null">Date: N/A</span>
                        </td>
                        <td align="center" class="v-align-middle">
                          <button title="Manual Settlement" *ngIf="status==22" style="margin-left: 5px;" class="label label-success" (click)="updateSettlement(partner.id,content8)" ><i class="fa fa-pencil" aria-hidden="true"></i></button>
                          <button style="margin-left: 5px;" title="view" class="label label-info" (click)="view(content6,partner.id)"><i class="fa fa-eye" aria-hidden="true"></i></button>
                           <!-- <span *ngIf="partner.endOrderOtpStatus==1">N/A</span>  -->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>
             <div *ngIf="!filteronecheck">
            
              <div *ngIf="status==22">
                <div class="btn-section-wrapper" style="margin-top: -40px;">
                  <button (click)="upload(content9)"  id="upload" class="label label-warning">Upload File</button>

                  <button (click)="markasSettle()"  id="refresh" class="label label-success">Mark as Settled</button>
                </div>
              </div>

                <div style="display: none;">{{filterNoRecordpartnerSettlementreportModel.responseMessage}}</div>
                <div class="table-responsive" style="margin-top: 40px;">
                  <table datatable class="ch-table">
                    <thead>
                      <tr> 
                        <th style="text-align: center;">Sl.No. 3</th>
                        <th style="text-align: center;" *ngIf="status===22">&nbsp;</th> 
                        <th style="text-align: left;">Order Info</th>
                        <th style="text-align: left;">Customer Info</th>
                        <th style="text-align: left;width: 50px;">Partner Info</th>
                        <th style="text-align: left;width: 50px;">Payment Info</th>
                        <th style="text-align: left;">Payment Mode</th> 
                        <th style="text-align: left;">Status</th>
                        <th style="text-align: left;">Settlement Info</th>
                        <th style="text-align: center; width: 120px;">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let partner of filterNoRecordpartnerSettlementreportModel.response; let i=index">
                        <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td align="center"  *ngIf="status==22"  class="v-align-middle "><span style="width:80px">
                        <input type="checkbox" value=" {{partner.id}}" (change)="onChange(partner.id,$event)">
                      </span></td>
                      <td *ngIf="partner.orderId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 10px;"><b>{{partner.orderId}}</b></span><br>
                        <span *ngIf="partner.trackId!=null"style="font-size: 10px;"><b>Track Id :</b>{{partner.trackId}}</span>
                        <span *ngIf="partner.trackId==null"style="font-size: 10px;"><b>Track Id :</b>N/A</span>
                       </td>
                      <td *ngIf="partner.orderId==null"align="left" class="v-align-middle">N/A</td>

                      <td *ngIf="partner.customerId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 12px;">{{partner.customerId.firstName}} {{partner.customerId.lastName}}</span><br>
                        <span style="font-size: 12px;">{{partner.customerId.mobileNumber}}</span>
                      </td>
                      <td *ngIf="partner.customerId==null"align="left" class="v-align-middle">N/A</td>

                      <td *ngIf="partner.vehicleId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 12px;">{{partner.vehicleId.userId.firstName}} {{partner.vehicleId.userId.lastName}}</span><br>
                        <span style="font-size: 12px;"> {{partner.vehicleId.userId.mobileNumber}}</span>
                      </td>
                      <td *ngIf="partner.vehicleId==null"align="left" class="v-align-middle">N/A</td>

                      <td>
                        <span *ngFor="let obj of partner.transactionList;">
                            <span *ngIf="obj.paymentStatus=='Success'">
                              <span *ngIf="obj.paymentId!=null"style="font-size: 10px;"><b>Txn Id :</b>{{obj.paymentId}}</span>
                              <span *ngIf="obj.paymentId==null"style="font-size: 10px;"><b>Txn Id :</b>N/A</span><br/>
                              <span *ngIf="obj.paymentDatetime!=null"style="font-size: 10px;"> <b>Date:</b>{{obj.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}<br/></span>
                              <span *ngIf="partner.totalPayableAmount!=null"style="font-size: 10px;"> <b>Amount:</b>{{partner.totalPayableAmount | number : '1.2-2'}}</span>
                            </span>
                        </span>
                     
                      </td>

                      <td *ngIf="partner.paymentModeId!=null" align="left" class="v-align-middle">{{partner.paymentModeId.paymentMode}}</td>
                      <td *ngIf="partner.paymentModeId==null" align="left" class="v-align-middle">N/A</td>

                        <td align="center" *ngIf="partner.paymentStatus!=null && partner.paymentStatus==='Success' "class="v-align-middle ht-active">{{partner.paymentStatus}}</td>
                        <td align="center" *ngIf="partner.paymentStatus!=null && partner.paymentStatus==='Failure' " style="color: red;">Failed</td>
                        <td align="center" *ngIf="partner.paymentStatus!=null && partner.paymentStatus==='cancelled' " style="color: red;">{{partner.paymentStatus}}</td>
                        <td align="center" *ngIf="partner.paymentStatus!=null && partner.paymentStatus==='Cancelled' " style="color: red;">{{partner.paymentStatus}}</td>
                        <td align="center" *ngIf="partner.paymentStatus!=null && partner.paymentStatus==='Initiated' " style="color: blue;">{{partner.paymentStatus}}</td>

                      <!-- <td *ngIf="partner.paymentDatetime!=null" align="left" class="v-align-middle">{{partner.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                      <td *ngIf="partner.paymentDatetime==null" align="left" class="v-align-middle">N/A</td>

                      <td *ngIf="partner.bookedDate!=null" align="left" class="v-align-middle">{{partner.bookedDate | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                      <td *ngIf="partner.bookedDate==null" align="left" class="v-align-middle">N/A</td>

                      <td *ngIf="partner.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{partner.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                      <td *ngIf="partner.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td> -->
                      <td>
                        <span style="font-size: 11px;" *ngIf="partner.utrNumber!=null">UTR No: {{partner.utrNumber}}</span>
                        <span style="font-size: 11px;" *ngIf="partner.utrNumber==null">UTR No: N/A</span>
                        <br/>
                        <span style="font-size: 11px;" *ngIf="partner.settledAmount!=null">Amt {{partner.settledAmount | number : '1.2-2'}}</span>
                        <span style="font-size: 11px;" *ngIf="partner.settledAmount==null">Amt: N/A</span>
                        <br/>
                        <span style="font-size: 11px;" *ngIf="partner.settledDatetime!=null">Date: {{partner.settledDatetime | date:'dd/MM/yyyy'}}</span>
                        <span style="font-size: 11px;" *ngIf="partner.settledDatetime==null">Date: N/A</span>
                      </td>
                      <td align="center" class="v-align-middle">
                        <button title="Manual Settlement" *ngIf="readyStatus==22" style="margin-left: 5px;" class="label label-success" (click)="updateSettlement(partner.id,content8)" ><i class="fa fa-pencil" aria-hidden="true"></i></button>
                        <button style="margin-left: 5px;" title="view" class="label label-info" (click)="view(content6,partner.id)"><i class="fa fa-eye" aria-hidden="true"></i></button>
                         <!-- <span *ngIf="partner.endOrderOtpStatus==1">N/A</span>  -->
                      </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
             </div>
              
            
          </div>



          </div>
          <!-- END card --> 
        </div>
      </div>

      <ng-template #content5 let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Reason for cancellation of Order</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
        </div>
        <hr class="beautiful-line">
        <div class="modal-body">
        <form>
            <br>
            <div class="ch-form-group">
              <div class="dropdown" style="width: 450px; margin-left: -3px;">
                <select id="cancelmessage"  name="cancelmessage" class="form-control">
                  <option *ngFor="let cancel of cancelMessageModel.response" value={{cancel.id}}>{{cancel.cancellationMessage}}</option>
                </select></div>
            </div>	
            <br>
            <div class="ch-form-group">
                 <textarea placeholder="Should not exceed more than 150 characters" class="form-control textarea" name="cancelremarks" maxlength="150" id="cancelremarks" rows="3"></textarea>
            </div>
            <br>
          <b>Note</b> : <span style="color: grey;">Should not exceed more than 150 characters</span> 
            </form>
            <br>
            <div *ngIf="errorFlag1" style="color: red;">{{errorMessage}}</div>
          </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" style="color: green;" (click)="cancelOrder()">Submit</button>
        </div>
      </ng-template>

      <!-- <ng-template #content6 let-modal>
        <div class="modal-header">
          <h6>View Partner Settlement Details</h6>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
        </div>
        <hr class="beautiful-line">
        <h5 style="margin-left: 15px; text-align: left; color: grey;">Order Details</h5>
        <div class="container"
          fxLayout
        >
        <div class="item item-1">
          <p class="card-title"><b>OrderID</b><br>
            <span>{{viewpartnerSettlementModel.response.orderId}}</span>
          </p>
        </div>
        <div class="item item-2" style="margin-left: 40px;">
          <p class="card-title"><b>Customer Info</b><br>
            <span>
            Name : {{viewpartnerSettlementModel.response.customerId.firstName}}<br>
            Mobile No: {{viewpartnerSettlementModel.response.customerId.mobileNumber}}
          </span>
        </p>
        </div>
        <div class="item item-3" style="margin-left: 40px;">
          <p class="card-title"><b>Partner Info </b>  <br><span>
            Name : {{viewpartnerSettlementModel.response.vehicleId.userId.firstName}}<br>
            Mobile No: {{viewpartnerSettlementModel.response.vehicleId.userId.mobileNumber}}
          </span>
        </p>
        </div>
        <div class="item item-4" style="margin-left: 40px;">
          <p class="card-title"><b> Transaction ID </b> <br>
            <span *ngIf="viewpartnerSettlementModel.response.transactionId!=null">{{viewpartnerSettlementModel.response.transactionId}}</span>
            <span *ngIf="viewpartnerSettlementModel.response.transactionId==null">N/A</span>
          </p>
        </div>
        <div class="item item-1" style="margin-left: 40px;">
          <p class="card-title"><b>Fuel</b> 
            <br><span>{{viewpartnerSettlementModel.response.fuelType}}</span></p>
        </div>
        <div class="item item-2" style="margin-left: 40px;">
          <p class="card-title"><b>Quantity in Ltrs</b>
            <br>
            <span>{{viewpartnerSettlementModel.response.quantity}}</span> 
           </p>
        </div>
        <div class="item item-2" style="margin-left: 40px;">
          <p class="card-title"><b>Payment Mode</b>
            <br>
            <span>{{viewpartnerSettlementModel.response.paymentModeId.paymentMode}}</span> 
           </p>
        </div>
        <div class="item item-7" style="margin-left: 40px;">
          <p class="card-title"><b>Status</b>  <br>
            <span *ngIf="viewpartnerSettlementModel.response.paymentStatus!=null && viewpartnerSettlementModel.response.paymentStatus==='Success' "class="v-align-middle ht-active">{{viewpartnerSettlementModel.response.paymentStatus}}</span>
            <span *ngIf="viewpartnerSettlementModel.response.paymentStatus!=null && viewpartnerSettlementModel.response.paymentStatus==='Failure' " style="color: red;">Failed</span>
            <span *ngIf="viewpartnerSettlementModel.response.paymentStatus!=null && viewpartnerSettlementModel.response.paymentStatus==='Cancelled' " style="color: red;">{{viewpartnerSettlementModel.response.paymentStatus}}</span>
            <span *ngIf="viewpartnerSettlementModel.response.paymentStatus!=null && viewpartnerSettlementModel.response.paymentStatus==='cancelled' " style="color: red;">{{viewpartnerSettlementModel.response.paymentStatus}}</span>
            <span *ngIf="viewpartnerSettlementModel.response.paymentStatus!=null && viewpartnerSettlementModel.response.paymentStatus==='Initiated' " style="color: blue;">{{viewpartnerSettlementModel.response.paymentStatus}}</span>
          </p>
        </div>
       
        </div>
        <br>
       
        <div class="container"
        fxLayout
      >
      <div class="item item-8" >
        <p class="card-title"><b>Transaction Date & Time </b> <br><span>{{viewpartnerSettlementModel.response.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span></p>
      </div>
      <div class="item item-8" style="margin-left: 40px;">
        <p class="card-title"><b>Booked Date & Time </b> <br><span>{{viewpartnerSettlementModel.response.bookedDate | date:'dd/MM/yyyy hh:mm:ss a'}}</span></p>
      </div>
      <div class="item item-8" style="margin-left: 40px;">
        <p class="card-title"><b>Delivery Date & Time </b> <br><span>{{viewpartnerSettlementModel.response.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</span></p>
      </div>

        </div>
        <hr class="beautiful-line">
        <br>
        <h5 style="margin-left: 15px; text-align: left; color: grey;">Payment Description</h5>
        <div class="container"
        fxLayout
      >
      
      <div class="item item-3">
        <p class="card-title"><b>Total Fuel Cost (In Rs.)</b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{viewpartnerSettlementModel.response.totalfuelcost | number : '1.2-2'}}</span></p>
      </div>
      <!-- <div class="item item-6" style="margin-left: 40px;">
        <p class="card-title"><b>Coupon Info </b> <br>
          <span *ngIf="viewpartnerSettlementModel.response.couponCode!=null">Coupon Code : {{viewpartnerSettlementModel.response.couponCode}}</span>
          <span *ngIf="viewpartnerSettlementModel.response.couponCode==null">Coupon Code : N/A</span><br>
          <span *ngIf="viewpartnerSettlementModel.response.couponDiscountFlat!=null && viewpartnerSettlementModel.response.couponCode!=null">
            <span *ngIf="viewpartnerSettlementModel.response.couponDiscountFlat==1">
              <span>Discount Percentage : {{viewpartnerSettlementModel.response.couponDiscountPrice}} % </span>
            </span>
            <span *ngIf="viewpartnerSettlementModel.response.couponDiscountFlat==2">
              Discount Prise :  <i class="fa fa-inr" aria-hidden="true"></i>{{viewpartnerSettlementModel.response.couponDiscountPrice | number : '1.2-2' }}
            </span>
          </span>
          <span *ngIf="viewpartnerSettlementModel.response.couponCode==null">DisCount : N/A</span>
        </p>
      </div> -->
      <!-- <div *ngIf="viewpartnerSettlementModel.response.couponCode!=null" class="item item-6" style="margin-left: 40px;">
        <p class="card-title"><b>Final Fuel Cost </b> <br>
          <span><i class="fa fa-inr" aria-hidden="true"></i>{{viewpartnerSettlementModel.response.finaltotalfuelCost | number : '1.2-2' }}</span>
      </div>
      <div class="item item-4" style="margin-left: 20px;">
        <p class="card-title"><b>Delivery Charge(In Rs.) </b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{viewpartnerSettlementModel.response.deliveryCharges | number : '1.2-2'}}</span></p>
      </div>
      <div class="item item-5" style="margin-left: 20px;">
        <p class="card-title"><b>Tax (In Rs.) </b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{viewpartnerSettlementModel.response.tax | number : '1.2-2'}}</span></p>  
      </div>
      <div class="item item-6" style="margin-left: 20px;">
        <p class="card-title"><b>Platform Charge (In Rs.) </b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{viewpartnerSettlementModel.response.internetHandlingFee | number : '1.2-2' }}</span></p>
      </div>
      <div class="item item-6" style="margin-left: 20px;">
        <p class="card-title"><b>Total Paid Amount (In Rs.) </b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{viewpartnerSettlementModel.response.totalPayableAmount | number : '1.2-2' }}</span></p>
      </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
        </div>
      </ng-template> --> -->


      <ng-template #content6 let-modal>
        <div class="modal-header">
          <h6>View Ready To Settle Details</h6>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
        </div>
        <hr class="beautiful-line">
        <h5 style="margin-left: 15px; text-align: left; color: grey;">Order Details</h5>
        <div class="container"
          fxLayout
        >
        <div class="item item-1">
          <p class="card-title"><b>OrderID</b><br>
            <span>{{readyToSettlementreportModelById.response.orderId}}</span>
          </p>
        </div>
        <div class="item item-2" style="margin-left: 40px;">
          <p class="card-title"><b>Customer Info</b><br>
            <span>
            Name : {{readyToSettlementreportModelById.response.customerName}}<br>
            Mobile No: {{readyToSettlementreportModelById.response.customerMobile}}
          </span>
        </p>
        </div>
        <div class="item item-4" style="margin-left: 30px;">
          <p class="card-title"><b> Transaction ID </b> <br>
            <span *ngIf="readyToSettlementreportModelById.response.transactionId!=null">{{readyToSettlementreportModelById.response.transactionId}}</span>
            <span *ngIf="readyToSettlementreportModelById.response.transactionId==null">N/A</span>
          </p>
        </div>
        <div class="item item-6" style="margin-left: 20px;">
          <p class="card-title"><b>Transaction Amount </b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.totalPayableAmount | number : '1.2-2' }}</span></p>
        </div>
        <div class="item item-1" style="margin-left: 40px;">
          <p class="card-title"><b>Fuel</b> 
            <br><span>{{readyToSettlementreportModelById.response.fuelType}}</span></p>
        </div>
        <div class="item item-2" style="margin-left: 40px;">
          <p class="card-title"><b>Quantity </b>
            <br>
            <span>{{readyToSettlementreportModelById.response.quantity}}</span> 
           </p>
        </div>

        <div class="item item-2" style="margin-left: 40px;">
          <p class="card-title"><b>Payment Mode </b>
            <br>
            <span>{{readyToSettlementreportModelById.response.paymentMode}}</span> 
           </p>
        </div>
        
        </div>
        <br>
        <div class="container"
        fxLayout
      >
      <div class="item item-7" >
        <p class="card-title"><b>Transaction Status</b>  <br>
          <span *ngIf="readyToSettlementreportModelById.response.paymentStatus!=null && readyToSettlementreportModelById.response.paymentStatus==='Success' "class="v-align-middle ht-active">{{readyToSettlementreportModelById.response.paymentStatus}}</span>
          <span *ngIf="readyToSettlementreportModelById.response.paymentStatus!=null && readyToSettlementreportModelById.response.paymentStatus==='Failure' " style="color: red;">Failed</span>
          <span *ngIf="readyToSettlementreportModelById.response.paymentStatus!=null && readyToSettlementreportModelById.response.paymentStatus==='Cancelled' " style="color: red;">{{readyToSettlementreportModelById.response.paymentStatus}}</span>
          <span *ngIf="readyToSettlementreportModelById.response.paymentStatus!=null && readyToSettlementreportModelById.response.paymentStatus==='cancelled' " style="color: red;">{{readyToSettlementreportModelById.response.paymentStatus}}</span>
          <span *ngIf="readyToSettlementreportModelById.response.paymentStatus!=null && readyToSettlementreportModelById.response.paymentStatus==='Initiated' " style="color: blue;">{{readyToSettlementreportModelById.response.paymentStatus}}</span>
        </p>
      </div>

      <div class="item item-8" style="margin-left: 40px;">
        <p class="card-title"><b>Payment Date & Time </b> <br><span>{{readyToSettlementreportModelById.response.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span></p>
      </div>
      <div class="item item-8" style="margin-left: 40px;">
        <p class="card-title"><b>Delivery Date & Time </b> <br><span>{{readyToSettlementreportModelById.response.deliveryDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span></p>
      </div>
      <div class="item item-8" style="margin-left: 40px;">
        <p class="card-title"><b>Settlement Status </b> <br>
          <span *ngIf="readyToSettlementreportModelById.response.readytoSettle==22" class="v-align-middle ht-Pending">Initiated</span>
          <span *ngIf="readyToSettlementreportModelById.response.readytoSettle==23" class="v-align-middle ht-active">Completed</span>
        </p>
      </div>
      <div class="item item-8" style="margin-left: 40px;">
        <p class="card-title"><b>Ready To Settle Date Time </b> <br>
          <span *ngIf="readyToSettlementreportModelById.response.readytosettledDateTime!=null">{{readyToSettlementreportModelById.response.readytosettledDateTime | date:'dd/MM/yyyy hh:mm:ss a' }}</span>
          <span *ngIf="readyToSettlementreportModelById.response.readytosettledDateTime==null">N/A</span>
        </p>
      </div>
      <div class="item item-8" style="margin-left: 40px;">
        <p class="card-title"><b>Settled Date Time  </b> <br>
          <span *ngIf="readyToSettlementreportModelById.response.settledDateTime!=null">{{readyToSettlementreportModelById.response.settledDateTime | date:'dd/MM/yyyy hh:mm:ss a' }}</span>
          <span *ngIf="readyToSettlementreportModelById.response.settledDateTime==null">N/A</span>
        </p>
      </div>
      </div>
      <hr class="beautiful-line">
      <h5 style="margin-left: 15px; text-align: left; color: grey;">Settlement To Partner</h5>
        <div class="container"
        fxLayout
      >
      <div class="item item-3">
        <p class="card-title"><b>Partner Name</b> <br>
          <span *ngIf="readyToSettlementreportModelById.response.partnerBank!=null">{{readyToSettlementreportModelById.response.partnerBank.customerPartnerId.firstName}} {{readyToSettlementreportModelById.response.partnerBank.customerPartnerId.lastName}}</span>
          <span *ngIf="readyToSettlementreportModelById.response.partnerBank==null">N/A</span>
        </p>
      </div>

      <div class="item item-3" style="margin-left: 40px;">
        <p class="card-title"><b>Partner Mobile</b> <br>
          <span *ngIf="readyToSettlementreportModelById.response.partnerBank!=null">{{readyToSettlementreportModelById.response.partnerBank.customerPartnerId.mobileNumber}}</span>
          <span *ngIf="readyToSettlementreportModelById.response.partnerBank==null">N/A</span>
        </p>
      </div>
      <div class="item item-3" style="margin-left: 40px;">
        <p class="card-title"><b>Total Fuel Cost </b> <br>
          <span *ngIf="readyToSettlementreportModelById.response.partnerBank!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.partnertotalfuelcost | number : '1.2-2'}}</span>
          <span *ngIf="readyToSettlementreportModelById.response.partnerBank==null">N/A</span>
        </p>
      </div>

      <div class="item item-3" style="margin-left: 40px;">
        <p class="card-title"><b>Delivery Charges </b> <br>
          <span *ngIf="readyToSettlementreportModelById.response.plattformflag==1"><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.deliveryCharges | number : '1.2-2'}}</span>
          <span *ngIf="readyToSettlementreportModelById.response.plattformflag==0">N/A</span>
        </p>
      </div>
      <div class="item item-3" style="margin-left: 40px;">
        <p class="card-title"><b>CGST({{readyToSettlementreportModelById.response.cgstPercent}}%) </b> <br>
          <span *ngIf="readyToSettlementreportModelById.response.plattformflag==1"><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.partnercgst | number : '1.2-2'}}</span>
          <span *ngIf="readyToSettlementreportModelById.response.plattformflag==0">N/A</span>
        </p>
      </div>
      <div class="item item-3" style="margin-left: 40px;">
        <p class="card-title"><b>SGST({{readyToSettlementreportModelById.response.sgstPercent}}%) </b> <br>
          <span *ngIf="readyToSettlementreportModelById.response.plattformflag==1"><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.partnersgst | number : '1.2-2'}}</span>
          <span *ngIf="readyToSettlementreportModelById.response.plattformflag==0">N/A</span>
        </p>
      </div>
      <div class="item item-3" style="margin-left: 40px;">
        <p class="card-title"><b>Additional Charges </b> <br>
          <span *ngIf="readyToSettlementreportModelById.response.additionalCharges!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.additionalCharges | number : '1.2-2'}}</span>
          <span *ngIf="readyToSettlementreportModelById.response.additionalCharges==null">N/A</span>
        </p>
      </div>

      <div class="item item-3" style="margin-left: 40px;">
        <p class="card-title"><b>Total Amount </b> <br>
          <span *ngIf="readyToSettlementreportModelById.response.partnerBank!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.totalfuelcost | number : '1.2-2'}}</span>
          <span *ngIf="readyToSettlementreportModelById.response.partnerBank==null">N/A</span>
        </p>
      </div>

      <div class="item item-3" style="margin-left: 40px;">
        <p class="card-title"><b>Account Number </b> <br>
          <span *ngIf="readyToSettlementreportModelById.response.partnerBank!=null">{{readyToSettlementreportModelById.response.partnerBank.accountNumber}}</span>
          <span *ngIf="readyToSettlementreportModelById.response.partnerBank==null">N/A</span>
        </p>
      </div>
      <div class="item item-3" style="margin-left: 40px;">
        <p class="card-title"><b>IFSC Code</b> <br>
          <span *ngIf="readyToSettlementreportModelById.response.partnerBank!=null">{{readyToSettlementreportModelById.response.partnerBank.ifscCode}}</span>
          <span *ngIf="readyToSettlementreportModelById.response.partnerBank==null">N/A</span>
        </p>
      </div>
      <div class="item item-3" style="margin-left: 40px;">
        <p class="card-title"><b>Bank Status</b> <br>
          <span *ngIf="readyToSettlementreportModelById.response.partnerBank!=null">
            <span *ngIf="readyToSettlementreportModelById.response.partnerBank.approvalStatus==10" >
              <!-- <button title="Pending" class="label label-info"><i class="fa fa-warning " ></i></button> -->
              <img src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
          </span> 
          <span *ngIf="readyToSettlementreportModelById.response.partnerBank.approvalStatus==11" >
            <!-- <button title="Approved" class="label label-info"><i class="fa fa-check" aria-hidden="true"></i></button> -->
            <img src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
        </span> 
        <span *ngIf="readyToSettlementreportModelById.response.partnerBank.approvalStatus==12" >
          <img src="assets/img/remove.svg" alt="logo" data-src="assets/img/remove.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
          <!-- <button title="Rejected" class="label label-info"><i class="fa fa-remove"></i></button> -->
      </span>
          </span>
        </div>
      </div>
      <hr *ngIf="readyToSettlementreportModelById.response.vehicleBank!=null" class="beautiful-line">
      <h5 *ngIf="readyToSettlementreportModelById.response.vehicleBank!=null" style="margin-left: 15px; text-align: left; color: grey;">Settlement To Vehicle</h5>
      <div *ngIf="readyToSettlementreportModelById.response.vehicleBank!=null" class="container"
      fxLayout
    >
    <div class="item item-3">
      <p class="card-title"><b>Vehicle Number</b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleNumber!=null">{{readyToSettlementreportModelById.response.vehicleNumber}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleNumber==null">N/A</span>
      </p>
    </div>

      <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>Model Name</b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleName!=null">{{readyToSettlementreportModelById.response.vehicleName}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleName==null">N/A</span>
      </p>
    </div>
     <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>Delivery Charges </b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleBank!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.deliveryCharges | number : '1.2-2'}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleBank==null">N/A</span>
      </p>
    </div>
    <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>CGST({{readyToSettlementreportModelById.response.cgstPercent}}%) </b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleBank!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.partnercgst | number : '1.2-2'}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleBank==null">N/A</span>
      </p>
    </div>
    <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>SGST({{readyToSettlementreportModelById.response.sgstPercent}}%) </b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleBank!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.partnersgst | number : '1.2-2'}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleBank==null">N/A</span>
      </p>
    </div>

    <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>Total Amount </b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleBank!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.partneramount | number : '1.2-2'}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleBank==null">N/A</span>
      </p>
    </div>

    <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>Account Number </b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleBank!=null">{{readyToSettlementreportModelById.response.vehicleBank.accountNumber}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleBank==null">N/A</span>
      </p>
    </div>

     <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>IFSC Code</b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleBank!=null">{{readyToSettlementreportModelById.response.vehicleBank.ifscCode}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleBank==null">N/A</span>
      </p>
    </div>
    <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>Bank Status</b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleBank!=null">
          <span *ngIf="readyToSettlementreportModelById.response.vehicleBank.approvalStatus==10" >
            <img src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
        </span> 
        <span *ngIf="readyToSettlementreportModelById.response.vehicleBank.approvalStatus==11" >
          <img src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
      </span> 
      <span *ngIf="readyToSettlementreportModelById.response.vehicleBank.approvalStatus==12" >
        <img src="assets/img/remove.svg" alt="logo" data-src="assets/img/remove.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    </span>
        </span>
      </div>
    </div>
    <hr class="beautiful-line">
    <h5 style="margin-left: 15px; text-align: left; color: grey;">Settlement To Cinch</h5>
      <div class="container"
      fxLayout
    >
    <div class="item item-3">
      <p class="card-title"><b>Platform Charges</b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.internetHandlingFee | number : '1.2-2'}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank==null">N/A</span>
      </p>
    </div>

    <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>CGST(9%)</b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.platformcgst | number : '1.2-2'}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank==null">N/A</span>
      </p>
    </div>

    <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>SGST(9%)</b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.platformsgst | number : '1.2-2'}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank==null">N/A</span>
      </p>
    </div>

    <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>Total Amount</b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.platformtotalamount | number : '1.2-2'}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank==null">N/A</span>
      </p>
    </div>

      <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>Account Holder Name</b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank!=null">{{readyToSettlementreportModelById.response.adminSettlementBank.beneficiaryName}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank==null">N/A</span>
      </p>
    </div>
     <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>Account No </b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank!=null">{{readyToSettlementreportModelById.response.adminSettlementBank.accountNumber}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank==null">N/A</span>
      </p>
    </div>

    <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>IFSC Code</b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank!=null">{{readyToSettlementreportModelById.response.adminSettlementBank.ifscCode}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank==null">N/A</span>
      </p>
    </div>

    <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>Bank Status</b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank!=null">
          <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank.approvalStatus==10" >
            <img src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
            <!-- <button title="Pending" class="label label-info"><i class="fa fa-warning " ></i></button> -->
        </span> 
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank.approvalStatus==11" >
          <img src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
          <!-- <button title="Approved" class="label label-info"><i class="fa fa-check" aria-hidden="true"></i></button> -->
      </span> 
      <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank.approvalStatus==12" >
        <!-- <button title="Rejected" class="label label-info"><i class="fa fa-remove"></i></button> -->
        <img src="assets/img/remove.svg" alt="logo" data-src="assets/img/remove.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
      </span>
        </span>
      </div>
    </div>
        
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
        </div>
      </ng-template>

      <ng-template #content8 let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Manual Settlement</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
        </div>
        <hr class="beautiful-line">
        <div class="modal-body">
          <form>
           
            <div class="form-group">
              <label for="dateOfBirth">UTR Number *</label>
                  <input type="text" class="select-form-style" style="margin-left: 10px; " name="urtNumber" id="urtNumber" maxlength="30">
            </div>
            <div class="form-group">
              <label for="dateOfBirth">Settled Amount *</label>
                  <input type="text" class="select-form-style" style="margin-left: 10px; " name="settledAmount" id="settledAmount" maxlength="11">
            </div>
           
            <div class="form-group" >
              <label for="dateOfBirth">Settled Date *</label>
              <input type="date" class="select-form-style" id="settledDate" name="settledDate" aria-describedby="emailHelp" placeholder="Settled Date" required>
            </div>
      
            
      
          </form>
          <br>
          <div *ngIf="poppupalert">
            <div style="color: red;">Mandatory fields are required!</div>
          </div>
        </div>
        <hr class="beautiful-line">
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" (click)="update()">Submit</button>
          <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
        </div>
      </ng-template>

      <ng-template #content9 let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Upload CSV File</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
        </div>
        <hr class="beautiful-line">
        <div class="modal-body">
          <form>
            <div class="form-group" >
              <label for="dateOfBirth">Upload File *</label>
            
              <div  class="form-group">
                <input #imageInput
                id="file"
                name="file"
                 type="file"
                 accept=".csv"
                 data-max-size="2048"
                 (change)="selectFile($event)">
            </div>
      
            </div>
            
      
          </form>
          <br>
          <div>
            <span><b>Note</b></span><br/>
            <span>1.Kindly upload .csv file format.</span><br/>
            <span>2.The format should be following order</span><br/>
            <div class="row">
              <table class="table table-bordered" style="width: 30%">
                <tr>
                  <th>orderId</th>
                  <th>utrNo</th>
                  <th>Amount</th>
                  <th>SettlementDate</th>
                </tr>
                <tr>
                  <td>09685676878658</td>
                  <td>xyz00001</td>
                  <td>100.30</td>
                  <td>27/05/2022</td>
                </tr>
              </table>
            </div>
            
          </div>
          <div *ngIf="poppupalert">
            <div style="color: red;">Please Select file!</div>
          </div>
        </div>
        <hr class="beautiful-line">
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" (click)="uploadSettlement()">Submit</button>
          <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
        </div>
      </ng-template>