import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { GenericResponseModel } from '../model/genricresponse';
import { ReportServices } from '../services/reports.service';
import * as FileSaver from 'file-saver';
import { Workbook } from 'exceljs';
import { NgxSpinnerService } from 'ngx-spinner';
import { GenericResponse } from '../model/genricmodelresponse';
import { DecimalPipe } from '@angular/common';
import { Router } from '@angular/router';
@Component({
  selector: 'app-report-partner-monthly-invoice',
  templateUrl: './report-partner-monthly-invoice.component.html',
  styleUrls: ['./report-partner-monthly-invoice.component.css']
})

export class ReportPartnerMonthlyInvoiceComponent implements OnInit {
  symbole:String; 
 public isCollapsed = false;
 active = 1;
 transactionDetail:GenericResponseModel;
 transactionfilterDetail:GenericResponseModel;
 settlementDetail:GenericResponseModel;
 settlementViewDetail:GenericResponseModel;
closeResult = '';
  errorFlag:boolean;
  errorMessage:String;
  refreshflag:boolean;
  responseDataList = [];
  revenuereportModel:GenericResponseModel;
  revenuefilterreportModel:GenericResponseModel;
  normalrevenue:boolean=true;
  excelexportcheck : boolean = false;
  excelexportcheck1 : boolean = false;
  responseDataListnew = [];
  responseDataListnew1 = [];
  refundreportModel:GenericResponseModel;
  filterrefundreportModel:GenericResponseModel;
  filterrefund:boolean=false;
  refreshFlag:boolean=false;
  refundSettlementReportModel:GenericResponse;
  errorflag:boolean=false;
  totalpaidAmount:DoubleRange;
  totalrefundAmount:DoubleRange;
  status:Number;
  refundAmount:Number;
  today=new Date();
  dateCheck:String;
  filteronesearch:boolean= false;
  filteronesearch1:boolean= false;
  reportfilter1DetailNoRecord:GenericResponseModel;
  reportfilter1DetailNoRecord1:GenericResponseModel;
  yearsList:String[];
  monthsList:String[];
  currentYear:String;
  currentMonth:String;
  searchMonth:String;
  constructor(private modalService: NgbModal,private reportservice:ReportServices,private spinnerService:NgxSpinnerService,private _decimalPipe: DecimalPipe,private router:Router) { }

  ngOnInit(): void {
    let month = new Date().getMonth()+1;
    if(month==1){
      this.currentMonth = "January";
    }else if(month==2){
      this.currentMonth = "February";
    }else if(month==3){
      this.currentMonth = "March";
    }else if(month==4){
      this.currentMonth = "April";
    }else if(month==5){
      this.currentMonth = "May";
    }else if(month==6){
      this.currentMonth = "June";
    }else if(month==7){
      this.currentMonth = "July";
    }else if(month==8){
      this.currentMonth = "August";
    }else if(month==9){
      this.currentMonth = "September";
    }else if(month==10){
      this.currentMonth = "October";
    }else if(month==11){
      this.currentMonth = "November";
    }else if(month==12){
      this.currentMonth = "December";
    }
    let year = new Date().getFullYear();
    this.currentYear = String(year);

    let formData= {
      month:String(month),
      year:String(year),
     }
     this.reportservice.partnerMonthlyInvoice(formData).subscribe(data=>{
      if(data.statusCode==200){
        this.excelexportcheck = true;
       }else{
        this.excelexportcheck = false;
       }
       this.refundreportModel = data;
     })

      this.reportservice.getyearsList().subscribe(data=>{
        this.yearsList = data ;
      });

      this.reportservice.getmonthList().subscribe(data=>{
        this.monthsList = data ;
      });
  }

  invoice(year,month,partnerId){
    sessionStorage.setItem('year',year);
    sessionStorage.setItem('month',month);
    sessionStorage.setItem('partnerId',partnerId);
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['/invoice-generation-partner-monthly'])
      );
      window.open(url, '_blank');
  }

  search(){
    this.refreshFlag = true;
    let year = (<HTMLInputElement>document.getElementById('year')).value;
    let month = (<HTMLInputElement>document.getElementById('month')).value;
    this.currentYear = year;
    this.currentMonth = month;

    if(month=='January'){
      this.searchMonth = '1';
    }else if(month=='February'){
      this.searchMonth = '2';
    }else if(month=='March'){
      this.searchMonth = '3';
    }else if(month=='April'){
      this.searchMonth = '4';
    }else if(month=='May'){
      this.searchMonth = '5';
    }else if(month=='June'){
      this.searchMonth = '6';
    }else if(month=='July'){
      this.searchMonth = '7';
    }else if(month=='August'){
      this.searchMonth = '8';
    }else if(month=='September'){
      this.searchMonth = '9';
    }else if(month=='October'){
      this.searchMonth = '10';
    }else if(month=='November'){
      this.searchMonth = '11';
    }else if(month=='December'){
      this.searchMonth = '12';
    }

    let formData= {
      month:this.searchMonth,
      year:String(year),
     }
     this.reportservice.partnerMonthlyInvoice(formData).subscribe(data=>{
      this.filterrefund = true;
          if(data.statusCode==200){
            this.filteronesearch= true;
            this.excelexportcheck = true;
            this.filterrefundreportModel = data;
           }else{
            this.filteronesearch= false;
            this.excelexportcheck = false;
            this.reportfilter1DetailNoRecord = data;
           }
     });
  }

  

  exportToExcel(){
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let sno = 1;
    if(this.filterrefund){
      this.responseDataListnew = [];
      this.filterrefundreportModel.response.forEach(element => {
        let vehicle = element['vehicleId'];
        let partner = vehicle['userId'];
        var response = [];
        response.push(sno);
        response.push(partner['firstName']+partner['lastName']);
        response.push(partner['mobileNumber']);
        response.push(partner['emailAddress']);
        response.push(element['totalNumberOfOrderDelivered']);
        response.push(element['totalNumberOfQuantityDelivered']+" Ltrs");
        response.push(this._decimalPipe.transform(element['totalNumberOfKilometerTravelled'],"1.2-2"));
        response.push(element['totalNumberOfOrderCancelled']);
        this.responseDataListnew.push(response);
        sno++;
      });
    }else{
      this.refundreportModel.response.forEach(element => {
        let vehicle = element['vehicleId'];
        let partner = vehicle['userId'];
        var response = [];
        response.push(sno);
        response.push(partner['firstName']+partner['lastName']);
        response.push(partner['mobileNumber']);
        response.push(partner['emailAddress']);
        response.push(element['totalNumberOfOrderDelivered']);
        response.push(element['totalNumberOfQuantityDelivered']+" Ltrs");
        response.push(this._decimalPipe.transform(element['totalNumberOfKilometerTravelled'],"1.2-2"));
        response.push(element['totalNumberOfOrderCancelled']);
        this.responseDataListnew.push(response);
        sno++;
      });
    }
    this.excelexport();
  }


  excelexport(){
    const title = 'Partner Monthly Invoice';
    const header = ["S.No","Partner Name", "Partner Mobile", "Partner Email", "Total No Of Orders Delivered","Total Quantity Delivered","Total No Of Kms Travelled","Total No Of Orders Cancelled"]
    const data = this.responseDataListnew;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Partner Monthly Invoice');
// Add new row
let titleRow = worksheet.addRow([title]);
// Set font, size and style in title row.
titleRow.font = { name: 'Times New Roman', family: 4, size: 16, bold: true };

// Blank Row
worksheet.addRow([]);
  let subTitleRow = worksheet.addRow(['Month  : ' + this.currentMonth]);
  subTitleRow.font = { name: 'Times New Roman', family: 4, size: 12, bold: true };

  let subTitleRow1 = worksheet.addRow(['Year  : ' + this.currentYear]);
  subTitleRow1.font = { name: 'Times New Roman', family: 4, size: 12, bold: true };
  worksheet.addRow([]);

let headerRow = worksheet.addRow(header);
headerRow.font = {bold: true };
headerRow.eachCell((cell, number) => {
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFFFF' },
    bgColor: { argb: 'FF0000FF' },
  }
  cell.border = {  top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
});

data.forEach(d => {
  let row = worksheet.addRow(d);
  let qty = row.getCell(1);
  let qty1 = row.getCell(2);
  let qty2 = row.getCell(3);
  let qty3 = row.getCell(4);
  let qty4 = row.getCell(5);
  let qty5 = row.getCell(6);
  let qty6 = row.getCell(7);
  let qty7 = row.getCell(8);
  qty.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty1.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty2.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty3.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty4.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty5.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty6.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
}
);
workbook.xlsx.writeBuffer().then((data) => {
  let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  FileSaver.saveAs(blob, 'Partner Monthly Invoice.xlsx');
});
   }

   refresh(){
     this.refreshFlag = false;
     window.location.reload();
   }
   
   view(id,content){
    this.reportservice.getRefundSettlementReport(id).subscribe(data=>{
      this.refundSettlementReportModel = data;
      this.totalpaidAmount = data.response.refund.totalPayableAmount;
      this.totalrefundAmount = data.response.refund.totalrefundamount;
      this.refundAmount = Number(this.totalpaidAmount) -  Number(this.totalrefundAmount);
    });

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
   }

   private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
