<app-header></app-header>

<!-- START PAGE-CONTAINER -->
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
  </ngx-spinner> 
<div class="page-container "> 
  <!-- START PAGE CONTENT WRAPPER -->
  <div class="page-content-wrapper "> 
    <!-- START PAGE CONTENT -->
    <div class="content sm-gutter"> 
      <!-- START BREADCRUMBS -->
      <div class="bg-white">
        <div class="container">
          <ol class="breadcrumb breadcrumb-alt">
            <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Home</a></li>
            <li class="breadcrumb-item active"><span class="font-montserrat fs-11 all-caps">Ready to Settle</span></li>
          </ol>
        </div>
      </div>

      <!-- START CONTAINER FLUID -->
      <div class=" no-padding    container-fixed-lg bg-white">
        <div class="container"> 
          <div class="card card-transparent">
            <div>
          <select class="select-form-style"id="status" style="margin-left: 10px; margin-top: 5px;">
            <option value="22" selected>Initiated</option>
            <option value="23" >Completed</option>
        </select>  
        <input type="date" style="margin-left: 10px; margin-top: 5px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
        <input type="date" style="margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
        <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
        <button title="Refresh" *ngIf="refreshFlag" (click)="refresh()"style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button> 
        <span *ngIf="errorFlag" style="color: red;">{{errorMessage}}</span>
      </div> 
            <div class="btn-section-wrapper">
              <button *ngIf="excelexportcheck" (click)="exportToExcel()" title="Excel Export" style="margin-left: 10px; margin-top: 5px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
            </div>
            <div *ngIf="!filterpartner" class="card-body">
              <div *ngIf="readyToSettlementreportModel.accountStatus==22">
                <div class="btn-section-wrapper">
                  <button (click)="markasSettle()"  id="refresh" class="label label-success">Mark as Settled</button>
                  </div>
              </div>
              <br>
                <div style="display: none;">{{readyToSettlementreportModel.responseMessage}}</div>
              <div class="table-responsive" style="margin-top: 20px;">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: center;">&nbsp;</th> 
                      <th style="text-align: left;">Order Info</th>
                      <th style="text-align: left;">Payment Info</th>
                      <th style="text-align: left;">Customer Info</th>
                      <th style="text-align: left;">Partner Info</th>
                      <th style="text-align: left;">Vehicle Info</th>
                      <!-- <th style="text-align: left;">Cinch Info</th> -->
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: left;">Ready To Settle <br>Date & Time </th>
                      <th style="text-align: left;">Settled <br>Date & Time </th>
                      <th style="text-align: center;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ready of readyToSettlementreportModel.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td align="center" class="v-align-middle ">
                        <span style="width:80px">
                          <span *ngIf="ready.settlementBankStatus==2">
                            <span *ngIf="ready.partnerBank!=null && ready.vehicleBank!=null && ready.adminSettlementBank!=null">
                              <span *ngIf="ready.partnerBank.approvalStatus==11 && ready.vehicleBank.approvalStatus==11 && ready.adminSettlementBank.approvalStatus==11">
                                <span *ngIf="ready.partnerBank.vendorCode!=null && ready.vehicleBank.vendorCode!=null && ready.adminSettlementBank.vendorCode!=null">
                                  <input *ngIf="ready.readytoSettle==22" type="checkbox" value=" {{ready.settlementId}}" (change)="onChange(ready.settlementId,$event)">
                                </span>
                              </span>
                            </span>
                          </span>
                          <span *ngIf="ready.settlementBankStatus==1">
                            <span *ngIf="ready.partnerBank!=null && ready.adminSettlementBank!=null">
                              <span *ngIf="ready.partnerBank.approvalStatus==11 && ready.adminSettlementBank.approvalStatus==11">
                                <span *ngIf="ready.partnerBank.vendorCode!=null && ready.adminSettlementBank.vendorCode!=null">
                                  <input *ngIf="ready.readytoSettle==22" type="checkbox" value=" {{ready.settlementId}}" (change)="onChange(ready.settlementId,$event)">
                                </span>
                              </span>
                            </span>
                          </span>
                      </span></td>
                      <td align="left" class="v-align-middle">
                        <span style="font-size: 10px;"><b>Order ID : </b> {{ready.orderId}}</span><br>
                        <span style="font-size: 10px;"><b>Track ID : </b> {{ready.trackId}}</span>
                        <!-- <p style="font-size: 10px;"><b>Fuel : </b> {{ready.fuelType}}</p>
                        <p style="font-size: 10px;"><b>Quantity : </b> {{ready.quantity}}</p>
                        <p style="font-size: 10px;"><b>Delivery Date Date : </b> {{ready.deliveryDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</p> -->
                      </td>

                      <td align="left" class="v-align-middle">
                        <span style="font-size: 10px;"><b>Txn ID : </b> 
                          <span *ngIf="ready.transactionId!=null">{{ready.transactionId}}</span>
                          <span *ngIf="ready.transactionId==null">N/A</span>
                        </span><br/>
                        <span style="font-size: 10px;"><b>Mode : </b> 
                          <span *ngIf="ready.paymentMode!=null">{{ready.paymentMode}}</span>
                          <span *ngIf="ready.paymentMode==null">N/A</span>
                        </span>
                        <!-- <p style="font-size: 10px;"><b>Txn Amount : </b> 
                        <span><i class="fa fa-inr" aria-hidden="true"></i> {{ready.totalPayableAmount | number : '1.2-2' }}</span>  
                        </p> -->

                        <!-- <p style="font-size: 10px;"><b>Status : </b> 
                          <span align="center" *ngIf="ready.paymentStatus!=null && ready.paymentStatus==='Success' "class="v-align-middle ht-active">{{ready.paymentStatus}}</span>
                        <span align="center" *ngIf="ready.paymentStatus!=null && ready.paymentStatus==='Failure' " style="color: red;">Failed</span>
                        <span align="center" *ngIf="ready.paymentStatus!=null && ready.paymentStatus==='cancelled' " style="color: red;">{{ready.paymentStatus}}</span>
                        <span align="center" *ngIf="ready.paymentStatus!=null && ready.paymentStatus==='Cancelled' " style="color: red;">{{ready.paymentStatus}}</span>
                        <span align="center" *ngIf="ready.paymentStatus!=null && ready.paymentStatus==='Initiated' " style="color: blue;">{{ready.paymentStatus}}</span>
                          </p>
                          <p style="font-size: 10px;"><b>Txn Date Date : </b> {{ready.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</p> -->
                      </td>

                      <td align="left" class="v-align-middle">
                        <span style="font-size: 10px;">{{ready.customerName}}</span><br>
                        <span style="font-size: 10px;">{{ready.customerMobile}}</span>
                      </td>

                      <td align="left" class="v-align-middle">
                        <span *ngIf="ready.partnerBank!=null">
                          <span style="font-size: 10px;">{{ready.partnerBank.customerPartnerId.firstName}} {{ready.partnerBank.customerPartnerId.lastName}}</span><br>
                          <span style="font-size: 10px;">{{ready.partnerBank.customerPartnerId.mobileNumber}}</span>
                        </span>
                        <span *ngIf="ready.partnerBank==null">
                          N/A
                        </span>
                        <!-- <p style="font-size: 10px;"><b>Name : </b>
                          <span *ngIf="ready.partnerBank!=null">{{ready.partnerBank.customerPartnerId.firstName}} {{ready.partnerBank.customerPartnerId.lastName}}</span>
                          <span *ngIf="ready.partnerBank==null">N/A</span>
                        </p>
                        <p style="font-size: 10px;"><b>Mobile No : </b>
                          <span *ngIf="ready.partnerBank!=null">{{ready.partnerBank.customerPartnerId.mobileNumber}}</span>
                          <span *ngIf="ready.partnerBank==null">N/A</span>
                        </p> -->
                        <!-- <p style="font-size: 10px;"><b>Payout Amount : </b>
                          <span *ngIf="ready.partnerBank!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{ready.totalfuelcost | number : '1.2-2'}}</span>
                          <span *ngIf="ready.partnerBank==null">N/A</span>
                        </p>
                        <p style="font-size: 10px;"><b>Account No : </b>
                          <span *ngIf="ready.partnerBank!=null">{{ready.partnerBank.accountNumber}}  
                            <span *ngIf="ready.partnerBank.approvalStatus==10" >
                              <button title="Pending" class="label label-info"><i class="fa fa-warning " ></i></button>
                          </span> 
                          <span *ngIf="ready.partnerBank.approvalStatus==11" >
                            <button title="Approved" class="label label-info"><i class="fa fa-check" aria-hidden="true"></i></button>
                        </span> 
                        <span *ngIf="ready.partnerBank.approvalStatus==12" >
                          <button title="Rejected" class="label label-info"><i class="fa fa-remove"></i></button>
                      </span>
                          </span>
                          <span *ngIf="ready.partnerBank==null">N/A</span>
                        </p>
                        <p style="font-size: 10px;"><b>IFSC Code : </b>
                          <span *ngIf="ready.partnerBank!=null">{{ready.partnerBank.ifscCode}}</span>
                          <span *ngIf="ready.partnerBank==null">N/A</span>
                        </p> -->
                      </td>

                      <td align="left" class="v-align-middle">
                        <span style="font-size: 10px;"><b>Veh No : </b>
                          <span *ngIf="ready.vehicleNumber!=null">{{ready.vehicleNumber}}</span>
                          <span *ngIf="ready.vehicleNumber==null">N/A</span>
                        </span>
                        <!-- <p style="font-size: 10px;"><b>Model Name : </b>
                          <span *ngIf="ready.vehicleName!=null">{{ready.vehicleName}}</span>
                          <span *ngIf="ready.vehicleName==null">N/A</span>
                        </p>
                        <p style="font-size: 10px;"><b>Payout Amount : </b>
                          <span *ngIf="ready.vehicleBank!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{ready.partneramount | number : '1.2-2'}}</span>
                          <span *ngIf="ready.vehicleBank==null">N/A</span>
                        </p>
                        <p style="font-size: 10px;"><b>Account No : </b>
                          <span *ngIf="ready.vehicleBank!=null">{{ready.vehicleBank.accountNumber}}
                            <span *ngIf="ready.vehicleBank.approvalStatus==10" >
                              <button title="Pending" class="label label-info"><i class="fa fa-warning " ></i></button>
                          </span> 
                          <span *ngIf="ready.vehicleBank.approvalStatus==11" >
                            <button title="Approved" class="label label-info"><i class="fa fa-check" aria-hidden="true"></i></button>
                        </span> 
                        <span *ngIf="ready.vehicleBank.approvalStatus==12" >
                          <button title="Rejected" class="label label-info"><i class="fa fa-remove"></i></button>
                      </span>
                          </span>
                          <span *ngIf="ready.vehicleBank==null">N/A</span>
                        </p>
                        <p style="font-size: 10px;"><b>IFSC Code : </b>
                          <span *ngIf="ready.vehicleBank!=null">{{ready.vehicleBank.ifscCode}}</span>
                          <span *ngIf="ready.vehicleBank==null">N/A</span>
                        </p> -->
                      </td>

                      <!-- <td align="left" class="v-align-middle">
                        <p style="font-size: 10px;"><b>Payout Amount : </b>
                          <span *ngIf="ready.adminSettlementBank!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{ready.internetHandlingFee | number : '1.2-2'}}</span>
                          <span *ngIf="ready.adminSettlementBank==null">N/A</span>
                        </p>
                        <p style="font-size: 10px;"><b>Account Holder Name : </b>
                          <span *ngIf="ready.adminSettlementBank!=null">{{ready.adminSettlementBank.beneficiaryName}}</span>
                          <span *ngIf="ready.adminSettlementBank==null">N/A</span>
                        </p>
                        <p style="font-size: 10px;"><b>Account No : </b>
                          <span *ngIf="ready.adminSettlementBank!=null">{{ready.adminSettlementBank.accountNumber}}
                            <span *ngIf="ready.adminSettlementBank.approvalStatus==10" >
                              <button title="Pending" class="label label-info"><i class="fa fa-warning " ></i></button>
                          </span> 
                          <span *ngIf="ready.adminSettlementBank.approvalStatus==11" >
                            <button title="Approved" class="label label-info"><i class="fa fa-check" aria-hidden="true"></i></button>
                        </span> 
                        <span *ngIf="ready.adminSettlementBank.approvalStatus==12" >
                          <button title="Rejected" class="label label-info"><i class="fa fa-remove"></i></button>
                      </span>
                          </span>
                          <span *ngIf="ready.adminSettlementBank==null">N/A</span>
                        </p>
                        <p style="font-size: 10px;"><b>IFSC Code : </b>
                          <span *ngIf="ready.adminSettlementBank!=null">{{ready.adminSettlementBank.ifscCode}}</span>
                          <span *ngIf="ready.adminSettlementBank==null">N/A</span>
                        </p>
                      </td> -->
                      <td align="center" class="v-align-middle">
                        <span *ngIf="ready.readytoSettle==22" class="v-align-middle ht-Pending">Initiated</span>
                        <span *ngIf="ready.readytoSettle==23" class="v-align-middle ht-active">Completed</span>
                      </td>
                      <td align="left" class="v-align-middle">
                        <span *ngIf="ready.readytosettledDateTime!=null">{{ready.readytosettledDateTime | date:'dd/MM/yyyy hh:mm:ss a' }}</span>
                        <span *ngIf="ready.readytosettledDateTime==null">N/A</span>
                      </td>
                      <td align="left" class="v-align-middle">
                        <span *ngIf="ready.settledDateTime!=null">{{ready.settledDateTime | date:'dd/MM/yyyy hh:mm:ss a' }}</span>
                        <span *ngIf="ready.settledDateTime==null">N/A</span>
                      </td>
                      <td align="center" class="v-align-middle">
                        <button title="view" class="label label-info" (click)="view(content6,ready.settlementId)"><i class="fa fa-eye" aria-hidden="true"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div *ngIf="filterpartner" class="card-body">
              <div *ngIf="filteronecheck">
                <div *ngIf="filterreadyToSettlementreportModel.accountStatus==22">
                  <div class="btn-section-wrapper" style="margin-top: -30px;">
                    <button (click)="markasSettle()"  id="refresh" class="label label-success">Mark as Settled</button>
                    </div>
                </div>
                <div style="display: none;">{{filterreadyToSettlementreportModel.responseMessage}}</div>
                <div class="table-responsive" style="margin-top: 40px;">
                  <table datatable class="ch-table">
                    <thead>
                      <tr> 
                        <th style="text-align: center;">Sl.No.</th>
                          <th style="text-align: center;">&nbsp;</th> 
                          <th style="text-align: left;">Order Info</th>
                          <th style="text-align: left;">Payment Info</th>
                          <th style="text-align: left;">Customer Info</th>
                          <th style="text-align: left;">Partner Info</th>
                          <th style="text-align: left;">Vehicle Info</th>
                          <!-- <th style="text-align: left;">Cinch Info</th> -->
                          <th style="text-align: left;">Status</th>
                          <th style="text-align: left;">Ready To Settle Date & Time </th>
                          <th style="text-align: left;">Settled Date & Time </th>
                          <th style="text-align: center;">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let ready of filterreadyToSettlementreportModel.response; let i=index">
                        <td align="center" class="v-align-middle ">{{i + 1}}</td>
                        <td align="center" class="v-align-middle ">
                          <span style="width:80px">
                            <span *ngIf="ready.settlementBankStatus==2">
                              <span *ngIf="ready.partnerBank!=null && ready.vehicleBank!=null && ready.adminSettlementBank!=null">
                                <span *ngIf="ready.partnerBank.approvalStatus==11 && ready.vehicleBank.approvalStatus==11 && ready.adminSettlementBank.approvalStatus==11">
                                  <span *ngIf="ready.partnerBank.vendorCode!=null && ready.vehicleBank.vendorCode!=null && ready.adminSettlementBank.vendorCode!=null">
                                    <input *ngIf="ready.readytoSettle==22" type="checkbox" value=" {{ready.settlementId}}" (change)="onChange(ready.settlementId,$event)">
                                  </span>
                                </span>
                              </span>
                            </span>
                            <span *ngIf="ready.settlementBankStatus==1">
                              <span *ngIf="ready.partnerBank!=null && ready.adminSettlementBank!=null">
                                <span *ngIf="ready.partnerBank.approvalStatus==11 && ready.adminSettlementBank.approvalStatus==11">
                                  <span *ngIf="ready.partnerBank.vendorCode!=null && ready.adminSettlementBank.vendorCode!=null">
                                    <input *ngIf="ready.readytoSettle==22" type="checkbox" value=" {{ready.settlementId}}" (change)="onChange(ready.settlementId,$event)">
                                  </span>
                                </span>
                              </span>
                            </span>
                        </span></td>
                        <td align="left" class="v-align-middle">
                          <span style="font-size: 10px;"><b>Order ID : </b> {{ready.orderId}}</span><br>
                        <span style="font-size: 10px;"><b>Track ID : </b> {{ready.trackId}}</span>
                          <!-- <p style="font-size: 10px;"><b>Fuel : </b> {{ready.fuelType}}</p>
                          <p style="font-size: 10px;"><b>Quantity : </b> {{ready.quantity}}</p>
                          <p style="font-size: 10px;"><b>Delivery Date Date : </b> {{ready.deliveryDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</p> -->
                        </td>
  
                        <td align="left" class="v-align-middle">
                          <span style="font-size: 10px;"><b>Txn ID : </b> 
                            <span *ngIf="ready.transactionId!=null">{{ready.transactionId}}</span>
                            <span *ngIf="ready.transactionId==null">N/A</span>
                          </span><br/>
                          <span style="font-size: 10px;"><b>Mode : </b> 
                            <span *ngIf="ready.paymentMode!=null">{{ready.paymentMode}}</span>
                            <span *ngIf="ready.paymentMode==null">N/A</span>
                          </span>
                          <!-- <p style="font-size: 10px;"><b>Txn Amount : </b> 
                          <span><i class="fa fa-inr" aria-hidden="true"></i> {{ready.totalPayableAmount | number : '1.2-2' }}</span>  
                          </p> -->
  
                          <!-- <p style="font-size: 10px;"><b>Status : </b> 
                            <span align="center" *ngIf="ready.paymentStatus!=null && ready.paymentStatus==='Success' "class="v-align-middle ht-active">{{ready.paymentStatus}}</span>
                          <span align="center" *ngIf="ready.paymentStatus!=null && ready.paymentStatus==='Failure' " style="color: red;">Failed</span>
                          <span align="center" *ngIf="ready.paymentStatus!=null && ready.paymentStatus==='cancelled' " style="color: red;">{{ready.paymentStatus}}</span>
                          <span align="center" *ngIf="ready.paymentStatus!=null && ready.paymentStatus==='Cancelled' " style="color: red;">{{ready.paymentStatus}}</span>
                          <span align="center" *ngIf="ready.paymentStatus!=null && ready.paymentStatus==='Initiated' " style="color: blue;">{{ready.paymentStatus}}</span>
                            </p>
                            <p style="font-size: 10px;"><b>Txn Date Date : </b> {{ready.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</p> -->
                        </td>
  
                        <td align="left" class="v-align-middle">
                          <span style="font-size: 10px;">{{ready.customerName}}</span><br>
                          <span style="font-size: 10px;">{{ready.customerMobile}}</span>
                        </td>
  
                        <td align="left" class="v-align-middle">
                          <span *ngIf="ready.partnerBank!=null">
                            <span style="font-size: 10px;">{{ready.partnerBank.customerPartnerId.firstName}} {{ready.partnerBank.customerPartnerId.lastName}}</span><br>
                            <span style="font-size: 10px;">{{ready.partnerBank.customerPartnerId.mobileNumber}}</span>
                          </span>
                          <span *ngIf="ready.partnerBank==null">
                            N/A
                          </span>
                          <!-- <p style="font-size: 10px;"><b>Name : </b>
                            <span *ngIf="ready.partnerBank!=null">{{ready.partnerBank.customerPartnerId.firstName}} {{ready.partnerBank.customerPartnerId.lastName}}</span>
                            <span *ngIf="ready.partnerBank==null">N/A</span>
                          </p>
                          <p style="font-size: 10px;"><b>Mobile No : </b>
                            <span *ngIf="ready.partnerBank!=null">{{ready.partnerBank.customerPartnerId.mobileNumber}}</span>
                            <span *ngIf="ready.partnerBank==null">N/A</span>
                          </p> -->
                          <!-- <p style="font-size: 10px;"><b>Payout Amount : </b>
                            <span *ngIf="ready.partnerBank!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{ready.totalfuelcost | number : '1.2-2'}}</span>
                            <span *ngIf="ready.partnerBank==null">N/A</span>
                          </p>
                          <p style="font-size: 10px;"><b>Account No : </b>
                            <span *ngIf="ready.partnerBank!=null">{{ready.partnerBank.accountNumber}}  
                              <span *ngIf="ready.partnerBank.approvalStatus==10" >
                                <button title="Pending" class="label label-info"><i class="fa fa-warning " ></i></button>
                            </span> 
                            <span *ngIf="ready.partnerBank.approvalStatus==11" >
                              <button title="Approved" class="label label-info"><i class="fa fa-check" aria-hidden="true"></i></button>
                          </span> 
                          <span *ngIf="ready.partnerBank.approvalStatus==12" >
                            <button title="Rejected" class="label label-info"><i class="fa fa-remove"></i></button>
                        </span>
                            </span>
                            <span *ngIf="ready.partnerBank==null">N/A</span>
                          </p>
                          <p style="font-size: 10px;"><b>IFSC Code : </b>
                            <span *ngIf="ready.partnerBank!=null">{{ready.partnerBank.ifscCode}}</span>
                            <span *ngIf="ready.partnerBank==null">N/A</span>
                          </p> -->
                        </td>
  
                        <td align="left" class="v-align-middle">
                          <span style="font-size: 10px;"><b>Veh No : </b>
                            <span *ngIf="ready.vehicleNumber!=null">{{ready.vehicleNumber}}</span>
                            <span *ngIf="ready.vehicleNumber==null">N/A</span>
                          </span>
                          <!-- <p style="font-size: 10px;"><b>Model Name : </b>
                            <span *ngIf="ready.vehicleName!=null">{{ready.vehicleName}}</span>
                            <span *ngIf="ready.vehicleName==null">N/A</span>
                          </p>
                          <p style="font-size: 10px;"><b>Payout Amount : </b>
                            <span *ngIf="ready.vehicleBank!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{ready.partneramount | number : '1.2-2'}}</span>
                            <span *ngIf="ready.vehicleBank==null">N/A</span>
                          </p>
                          <p style="font-size: 10px;"><b>Account No : </b>
                            <span *ngIf="ready.vehicleBank!=null">{{ready.vehicleBank.accountNumber}}
                              <span *ngIf="ready.vehicleBank.approvalStatus==10" >
                                <button title="Pending" class="label label-info"><i class="fa fa-warning " ></i></button>
                            </span> 
                            <span *ngIf="ready.vehicleBank.approvalStatus==11" >
                              <button title="Approved" class="label label-info"><i class="fa fa-check" aria-hidden="true"></i></button>
                          </span> 
                          <span *ngIf="ready.vehicleBank.approvalStatus==12" >
                            <button title="Rejected" class="label label-info"><i class="fa fa-remove"></i></button>
                        </span>
                            </span>
                            <span *ngIf="ready.vehicleBank==null">N/A</span>
                          </p>
                          <p style="font-size: 10px;"><b>IFSC Code : </b>
                            <span *ngIf="ready.vehicleBank!=null">{{ready.vehicleBank.ifscCode}}</span>
                            <span *ngIf="ready.vehicleBank==null">N/A</span>
                          </p> -->
                        </td>
  
                        <!-- <td align="left" class="v-align-middle">
                          <p style="font-size: 10px;"><b>Payout Amount : </b>
                            <span *ngIf="ready.adminSettlementBank!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{ready.internetHandlingFee | number : '1.2-2'}}</span>
                            <span *ngIf="ready.adminSettlementBank==null">N/A</span>
                          </p>
                          <p style="font-size: 10px;"><b>Account Holder Name : </b>
                            <span *ngIf="ready.adminSettlementBank!=null">{{ready.adminSettlementBank.beneficiaryName}}</span>
                            <span *ngIf="ready.adminSettlementBank==null">N/A</span>
                          </p>
                          <p style="font-size: 10px;"><b>Account No : </b>
                            <span *ngIf="ready.adminSettlementBank!=null">{{ready.adminSettlementBank.accountNumber}}
                              <span *ngIf="ready.adminSettlementBank.approvalStatus==10" >
                                <button title="Pending" class="label label-info"><i class="fa fa-warning " ></i></button>
                            </span> 
                            <span *ngIf="ready.adminSettlementBank.approvalStatus==11" >
                              <button title="Approved" class="label label-info"><i class="fa fa-check" aria-hidden="true"></i></button>
                          </span> 
                          <span *ngIf="ready.adminSettlementBank.approvalStatus==12" >
                            <button title="Rejected" class="label label-info"><i class="fa fa-remove"></i></button>
                        </span>
                            </span>
                            <span *ngIf="ready.adminSettlementBank==null">N/A</span>
                          </p>
                          <p style="font-size: 10px;"><b>IFSC Code : </b>
                            <span *ngIf="ready.adminSettlementBank!=null">{{ready.adminSettlementBank.ifscCode}}</span>
                            <span *ngIf="ready.adminSettlementBank==null">N/A</span>
                          </p>
                        </td> -->
                        <td align="center" class="v-align-middle">
                          <span *ngIf="ready.readytoSettle==22" class="v-align-middle ht-Pending">Initiated</span>
                          <span *ngIf="ready.readytoSettle==23" class="v-align-middle ht-active">Completed</span>
                        </td>
                        <td align="left" class="v-align-middle">
                          <span *ngIf="ready.readytosettledDateTime!=null">{{ready.readytosettledDateTime | date:'dd/MM/yyyy hh:mm:ss a' }}</span>
                          <span *ngIf="ready.readytosettledDateTime==null">N/A</span>
                        </td>
                        <td align="left" class="v-align-middle">
                          <span *ngIf="ready.settledDateTime!=null">{{ready.settledDateTime | date:'dd/MM/yyyy hh:mm:ss a' }}</span>
                          <span *ngIf="ready.settledDateTime==null">N/A</span>
                        </td>
                        <td align="center" class="v-align-middle">
                          <button title="view" class="label label-info" (click)="view(content6,ready.settlementId)"><i class="fa fa-eye" aria-hidden="true"></i></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div *ngIf="!filteronecheck">
                <div *ngIf="filterNoReceordreadyToSettlementreportModel.accountStatus==22">
                  <div class="btn-section-wrapper">
                    <button (click)="markasSettle()"  id="refresh" class="label label-success">Mark as Settled</button>
                    </div>
                </div>
                <div style="display: none;">{{filterNoReceordreadyToSettlementreportModel.responseMessage}}</div>
                <div class="table-responsive" style="margin-top: 40px;">
                  <table datatable class="ch-table">
                    <thead>
                      <tr> 
                        <th style="text-align: center;">Sl.No.</th>
                          <th style="text-align: center;">&nbsp;</th> 
                          <th style="text-align: left;">Order Info</th>
                          <th style="text-align: left;">Payment Info</th>
                          <th style="text-align: left;">Customer Info</th>
                          <th style="text-align: left;">Partner Info</th>
                          <th style="text-align: left;">Vehicle Info</th>
                          <!-- <th style="text-align: left;">Cinch Info</th> -->
                          <th style="text-align: left;">Status</th>
                          <th style="text-align: left;">Ready To Settle Date & Time </th>
                          <th style="text-align: left;">Settled Date & Time </th>
                          <th style="text-align: center;">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let ready of filterNoReceordreadyToSettlementreportModel.response; let i=index">
                        <td align="center" class="v-align-middle ">{{i + 1}}</td>
                        <td align="center" class="v-align-middle ">
                          <span style="width:80px">
                            <span *ngIf="ready.settlementBankStatus==2">
                              <span *ngIf="ready.partnerBank!=null && ready.vehicleBank!=null && ready.adminSettlementBank!=null">
                                <span *ngIf="ready.partnerBank.approvalStatus==11 && ready.vehicleBank.approvalStatus==11 && ready.adminSettlementBank.approvalStatus==11">
                                  <span *ngIf="ready.partnerBank.vendorCode!=null && ready.vehicleBank.vendorCode!=null && ready.adminSettlementBank.vendorCode!=null">
                                    <input *ngIf="ready.readytoSettle==22" type="checkbox" value=" {{ready.settlementId}}" (change)="onChange(ready.settlementId,$event)">
                                  </span>
                                </span>
                              </span>
                            </span>
                            <span *ngIf="ready.settlementBankStatus==1">
                              <span *ngIf="ready.partnerBank!=null && ready.adminSettlementBank!=null">
                                <span *ngIf="ready.partnerBank.approvalStatus==11 && ready.adminSettlementBank.approvalStatus==11">
                                  <span *ngIf="ready.partnerBank.vendorCode!=null && ready.adminSettlementBank.vendorCode!=null">
                                    <input *ngIf="ready.readytoSettle==22" type="checkbox" value=" {{ready.settlementId}}" (change)="onChange(ready.settlementId,$event)">
                                  </span>
                                </span>
                              </span>
                            </span>
                        </span></td>
                        <td align="left" class="v-align-middle">
                          <span style="font-size: 10px;"><b>Order ID : </b> {{ready.orderId}}</span><br>
                          <span style="font-size: 10px;"><b>Track ID : </b> {{ready.trackId}}</span>
                          <!-- <p style="font-size: 10px;"><b>Fuel : </b> {{ready.fuelType}}</p>
                          <p style="font-size: 10px;"><b>Quantity : </b> {{ready.quantity}}</p>
                          <p style="font-size: 10px;"><b>Delivery Date Date : </b> {{ready.deliveryDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</p> -->
                        </td>
  
                        <td align="left" class="v-align-middle">
                          <span style="font-size: 10px;"><b>Txn ID : </b> 
                            <span *ngIf="ready.transactionId!=null">{{ready.transactionId}}</span>
                            <span *ngIf="ready.transactionId==null">N/A</span>
                          </span><br/>
                          <span style="font-size: 10px;"><b>Mode : </b> 
                            <span *ngIf="ready.paymentMode!=null">{{ready.paymentMode}}</span>
                            <span *ngIf="ready.paymentMode==null">N/A</span>
                          </span>
                          <!-- <p style="font-size: 10px;"><b>Txn Amount : </b> 
                          <span><i class="fa fa-inr" aria-hidden="true"></i> {{ready.totalPayableAmount | number : '1.2-2' }}</span>  
                          </p> -->
  
                          <!-- <p style="font-size: 10px;"><b>Status : </b> 
                            <span align="center" *ngIf="ready.paymentStatus!=null && ready.paymentStatus==='Success' "class="v-align-middle ht-active">{{ready.paymentStatus}}</span>
                          <span align="center" *ngIf="ready.paymentStatus!=null && ready.paymentStatus==='Failure' " style="color: red;">Failed</span>
                          <span align="center" *ngIf="ready.paymentStatus!=null && ready.paymentStatus==='cancelled' " style="color: red;">{{ready.paymentStatus}}</span>
                          <span align="center" *ngIf="ready.paymentStatus!=null && ready.paymentStatus==='Cancelled' " style="color: red;">{{ready.paymentStatus}}</span>
                          <span align="center" *ngIf="ready.paymentStatus!=null && ready.paymentStatus==='Initiated' " style="color: blue;">{{ready.paymentStatus}}</span>
                            </p>
                            <p style="font-size: 10px;"><b>Txn Date Date : </b> {{ready.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</p> -->
                        </td>
  
                        <td align="left" class="v-align-middle">
                          <span style="font-size: 10px;">{{ready.customerName}}</span><br>
                          <span style="font-size: 10px;">{{ready.customerMobile}}</span>
                        </td>
  
                        <td align="left" class="v-align-middle">
                          <span *ngIf="ready.partnerBank!=null">
                            <span style="font-size: 10px;">{{ready.partnerBank.customerPartnerId.firstName}} {{ready.partnerBank.customerPartnerId.lastName}}</span><br>
                            <span style="font-size: 10px;">{{ready.partnerBank.customerPartnerId.mobileNumber}}</span>
                          </span>
                          <span *ngIf="ready.partnerBank==null">
                            N/A
                          </span>
                          <!-- <p style="font-size: 10px;"><b>Name : </b>
                            <span *ngIf="ready.partnerBank!=null">{{ready.partnerBank.customerPartnerId.firstName}} {{ready.partnerBank.customerPartnerId.lastName}}</span>
                            <span *ngIf="ready.partnerBank==null">N/A</span>
                          </p>
                          <p style="font-size: 10px;"><b>Mobile No : </b>
                            <span *ngIf="ready.partnerBank!=null">{{ready.partnerBank.customerPartnerId.mobileNumber}}</span>
                            <span *ngIf="ready.partnerBank==null">N/A</span>
                          </p> -->
                          <!-- <p style="font-size: 10px;"><b>Payout Amount : </b>
                            <span *ngIf="ready.partnerBank!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{ready.totalfuelcost | number : '1.2-2'}}</span>
                            <span *ngIf="ready.partnerBank==null">N/A</span>
                          </p>
                          <p style="font-size: 10px;"><b>Account No : </b>
                            <span *ngIf="ready.partnerBank!=null">{{ready.partnerBank.accountNumber}}  
                              <span *ngIf="ready.partnerBank.approvalStatus==10" >
                                <button title="Pending" class="label label-info"><i class="fa fa-warning " ></i></button>
                            </span> 
                            <span *ngIf="ready.partnerBank.approvalStatus==11" >
                              <button title="Approved" class="label label-info"><i class="fa fa-check" aria-hidden="true"></i></button>
                          </span> 
                          <span *ngIf="ready.partnerBank.approvalStatus==12" >
                            <button title="Rejected" class="label label-info"><i class="fa fa-remove"></i></button>
                        </span>
                            </span>
                            <span *ngIf="ready.partnerBank==null">N/A</span>
                          </p>
                          <p style="font-size: 10px;"><b>IFSC Code : </b>
                            <span *ngIf="ready.partnerBank!=null">{{ready.partnerBank.ifscCode}}</span>
                            <span *ngIf="ready.partnerBank==null">N/A</span>
                          </p> -->
                        </td>
  
                        <td align="left" class="v-align-middle">
                          <span style="font-size: 10px;"><b>Veh No : </b>
                            <span *ngIf="ready.vehicleNumber!=null">{{ready.vehicleNumber}}</span>
                            <span *ngIf="ready.vehicleNumber==null">N/A</span>
                          </span>
                          <!-- <p style="font-size: 10px;"><b>Model Name : </b>
                            <span *ngIf="ready.vehicleName!=null">{{ready.vehicleName}}</span>
                            <span *ngIf="ready.vehicleName==null">N/A</span>
                          </p>
                          <p style="font-size: 10px;"><b>Payout Amount : </b>
                            <span *ngIf="ready.vehicleBank!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{ready.partneramount | number : '1.2-2'}}</span>
                            <span *ngIf="ready.vehicleBank==null">N/A</span>
                          </p>
                          <p style="font-size: 10px;"><b>Account No : </b>
                            <span *ngIf="ready.vehicleBank!=null">{{ready.vehicleBank.accountNumber}}
                              <span *ngIf="ready.vehicleBank.approvalStatus==10" >
                                <button title="Pending" class="label label-info"><i class="fa fa-warning " ></i></button>
                            </span> 
                            <span *ngIf="ready.vehicleBank.approvalStatus==11" >
                              <button title="Approved" class="label label-info"><i class="fa fa-check" aria-hidden="true"></i></button>
                          </span> 
                          <span *ngIf="ready.vehicleBank.approvalStatus==12" >
                            <button title="Rejected" class="label label-info"><i class="fa fa-remove"></i></button>
                        </span>
                            </span>
                            <span *ngIf="ready.vehicleBank==null">N/A</span>
                          </p>
                          <p style="font-size: 10px;"><b>IFSC Code : </b>
                            <span *ngIf="ready.vehicleBank!=null">{{ready.vehicleBank.ifscCode}}</span>
                            <span *ngIf="ready.vehicleBank==null">N/A</span>
                          </p> -->
                        </td>
  
                        <!-- <td align="left" class="v-align-middle">
                          <p style="font-size: 10px;"><b>Payout Amount : </b>
                            <span *ngIf="ready.adminSettlementBank!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{ready.internetHandlingFee | number : '1.2-2'}}</span>
                            <span *ngIf="ready.adminSettlementBank==null">N/A</span>
                          </p>
                          <p style="font-size: 10px;"><b>Account Holder Name : </b>
                            <span *ngIf="ready.adminSettlementBank!=null">{{ready.adminSettlementBank.beneficiaryName}}</span>
                            <span *ngIf="ready.adminSettlementBank==null">N/A</span>
                          </p>
                          <p style="font-size: 10px;"><b>Account No : </b>
                            <span *ngIf="ready.adminSettlementBank!=null">{{ready.adminSettlementBank.accountNumber}}
                              <span *ngIf="ready.adminSettlementBank.approvalStatus==10" >
                                <button title="Pending" class="label label-info"><i class="fa fa-warning " ></i></button>
                            </span> 
                            <span *ngIf="ready.adminSettlementBank.approvalStatus==11" >
                              <button title="Approved" class="label label-info"><i class="fa fa-check" aria-hidden="true"></i></button>
                          </span> 
                          <span *ngIf="ready.adminSettlementBank.approvalStatus==12" >
                            <button title="Rejected" class="label label-info"><i class="fa fa-remove"></i></button>
                        </span>
                            </span>
                            <span *ngIf="ready.adminSettlementBank==null">N/A</span>
                          </p>
                          <p style="font-size: 10px;"><b>IFSC Code : </b>
                            <span *ngIf="ready.adminSettlementBank!=null">{{ready.adminSettlementBank.ifscCode}}</span>
                            <span *ngIf="ready.adminSettlementBank==null">N/A</span>
                          </p>
                        </td> -->
                        <td align="center" class="v-align-middle">
                          <span *ngIf="ready.readytoSettle==22" class="v-align-middle ht-Pending">Initiated</span>
                          <span *ngIf="ready.readytoSettle==23" class="v-align-middle ht-active">Completed</span>
                        </td>
                        <td align="left" class="v-align-middle">
                          <span *ngIf="ready.readytosettledDateTime!=null">{{ready.readytosettledDateTime | date:'dd/MM/yyyy hh:mm:ss a' }}</span>
                          <span *ngIf="ready.readytosettledDateTime==null">N/A</span>
                        </td>
                        <td align="left" class="v-align-middle">
                          <span *ngIf="ready.settledDateTime!=null">{{ready.settledDateTime | date:'dd/MM/yyyy hh:mm:ss a' }}</span>
                          <span *ngIf="ready.settledDateTime==null">N/A</span>
                        </td>
                        <td align="center" class="v-align-middle">
                          <button title="view" class="label label-info" (click)="view(content6,ready.settlementId)"><i class="fa fa-eye" aria-hidden="true"></i></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
          </div>
          </div>
        </div>
      </div>


      <ng-template #content6 let-modal>
        <div class="modal-header">
          <h6>View Ready To Settle Details</h6>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
        </div>
        <hr class="beautiful-line">
        <h5 style="margin-left: 15px; text-align: left; color: grey;">Order Details</h5>
        <div class="container"
          fxLayout
        >
        <div class="item item-1">
          <p class="card-title"><b>OrderID</b><br>
            <span>{{readyToSettlementreportModelById.response.orderId}}</span>
          </p>
        </div>
        <div class="item item-2" style="margin-left: 40px;">
          <p class="card-title"><b>Customer Info</b><br>
            <span>
            Name : {{readyToSettlementreportModelById.response.customerName}}<br>
            Mobile No: {{readyToSettlementreportModelById.response.customerMobile}}
          </span>
        </p>
        </div>
        <div class="item item-4" style="margin-left: 30px;">
          <p class="card-title"><b> Transaction ID </b> <br>
            <span *ngIf="readyToSettlementreportModelById.response.transactionId!=null">{{readyToSettlementreportModelById.response.transactionId}}</span>
            <span *ngIf="readyToSettlementreportModelById.response.transactionId==null">N/A</span>
          </p>
        </div>
        <div class="item item-6" style="margin-left: 20px;">
          <p class="card-title"><b>Transaction Amount </b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.totalPayableAmount | number : '1.2-2' }}</span></p>
        </div>
        <div class="item item-1" style="margin-left: 40px;">
          <p class="card-title"><b>Fuel</b> 
            <br><span>{{readyToSettlementreportModelById.response.fuelType}}</span></p>
        </div>
        <div class="item item-2" style="margin-left: 40px;">
          <p class="card-title"><b>Quantity </b>
            <br>
            <span>{{readyToSettlementreportModelById.response.quantity}}</span> 
           </p>
        </div>

        <div class="item item-2" style="margin-left: 40px;">
          <p class="card-title"><b>Payment Mode </b>
            <br>
            <span>{{readyToSettlementreportModelById.response.paymentMode}}</span> 
           </p>
        </div>
        
        </div>
        <br>
        <div class="container"
        fxLayout
      >
      <div class="item item-7" >
        <p class="card-title"><b>Transaction Status</b>  <br>
          <span *ngIf="readyToSettlementreportModelById.response.paymentStatus!=null && readyToSettlementreportModelById.response.paymentStatus==='Success' "class="v-align-middle ht-active">{{readyToSettlementreportModelById.response.paymentStatus}}</span>
          <span *ngIf="readyToSettlementreportModelById.response.paymentStatus!=null && readyToSettlementreportModelById.response.paymentStatus==='Failure' " style="color: red;">Failed</span>
          <span *ngIf="readyToSettlementreportModelById.response.paymentStatus!=null && readyToSettlementreportModelById.response.paymentStatus==='Cancelled' " style="color: red;">{{readyToSettlementreportModelById.response.paymentStatus}}</span>
          <span *ngIf="readyToSettlementreportModelById.response.paymentStatus!=null && readyToSettlementreportModelById.response.paymentStatus==='cancelled' " style="color: red;">{{readyToSettlementreportModelById.response.paymentStatus}}</span>
          <span *ngIf="readyToSettlementreportModelById.response.paymentStatus!=null && readyToSettlementreportModelById.response.paymentStatus==='Initiated' " style="color: blue;">{{readyToSettlementreportModelById.response.paymentStatus}}</span>
        </p>
      </div>

      <div class="item item-8" style="margin-left: 40px;">
        <p class="card-title"><b>Payment Date & Time </b> <br><span>{{readyToSettlementreportModelById.response.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span></p>
      </div>
      <div class="item item-8" style="margin-left: 40px;">
        <p class="card-title"><b>Delivery Date & Time </b> <br><span>{{readyToSettlementreportModelById.response.deliveryDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span></p>
      </div>
      <div class="item item-8" style="margin-left: 40px;">
        <p class="card-title"><b>Settlement Status </b> <br>
          <span *ngIf="readyToSettlementreportModelById.response.readytoSettle==22" class="v-align-middle ht-Pending">Initiated</span>
          <span *ngIf="readyToSettlementreportModelById.response.readytoSettle==23" class="v-align-middle ht-active">Completed</span>
        </p>
      </div>
      <div class="item item-8" style="margin-left: 40px;">
        <p class="card-title"><b>Ready To Settle Date Time </b> <br>
          <span *ngIf="readyToSettlementreportModelById.response.readytosettledDateTime!=null">{{readyToSettlementreportModelById.response.readytosettledDateTime | date:'dd/MM/yyyy hh:mm:ss a' }}</span>
          <span *ngIf="readyToSettlementreportModelById.response.readytosettledDateTime==null">N/A</span>
        </p>
      </div>
      <div class="item item-8" style="margin-left: 40px;">
        <p class="card-title"><b>Settled Date Time  </b> <br>
          <span *ngIf="readyToSettlementreportModelById.response.settledDateTime!=null">{{readyToSettlementreportModelById.response.settledDateTime | date:'dd/MM/yyyy hh:mm:ss a' }}</span>
          <span *ngIf="readyToSettlementreportModelById.response.settledDateTime==null">N/A</span>
        </p>
      </div>
      </div>
      <hr class="beautiful-line">
      <h5 style="margin-left: 15px; text-align: left; color: grey;">Settlement To Partner</h5>
        <div class="container"
        fxLayout
      >
      <div class="item item-3">
        <p class="card-title"><b>Partner Name</b> <br>
          <span *ngIf="readyToSettlementreportModelById.response.partnerBank!=null">{{readyToSettlementreportModelById.response.partnerBank.customerPartnerId.firstName}} {{readyToSettlementreportModelById.response.partnerBank.customerPartnerId.lastName}}</span>
          <span *ngIf="readyToSettlementreportModelById.response.partnerBank==null">N/A</span>
        </p>
      </div>

      <div class="item item-3" style="margin-left: 40px;">
        <p class="card-title"><b>Partner Mobile</b> <br>
          <span *ngIf="readyToSettlementreportModelById.response.partnerBank!=null">{{readyToSettlementreportModelById.response.partnerBank.customerPartnerId.mobileNumber}}</span>
          <span *ngIf="readyToSettlementreportModelById.response.partnerBank==null">N/A</span>
        </p>
      </div>
      <div class="item item-3" style="margin-left: 40px;">
        <p class="card-title"><b>Total Fuel Cost </b> <br>
          <span *ngIf="readyToSettlementreportModelById.response.partnerBank!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.partnertotalfuelcost | number : '1.2-2'}}</span>
          <span *ngIf="readyToSettlementreportModelById.response.partnerBank==null">N/A</span>
        </p>
      </div>

      <div class="item item-3" style="margin-left: 40px;">
        <p class="card-title"><b>Delivery Charges </b> <br>
          <span *ngIf="readyToSettlementreportModelById.response.plattformflag==1"><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.deliveryCharges | number : '1.2-2'}}</span>
          <span *ngIf="readyToSettlementreportModelById.response.plattformflag==0">N/A</span>
        </p>
      </div>
      <div class="item item-3" style="margin-left: 40px;">
        <p class="card-title"><b>CGST({{readyToSettlementreportModelById.response.cgstPercent}}%) </b> <br>
          <span *ngIf="readyToSettlementreportModelById.response.plattformflag==1"><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.partnercgst | number : '1.2-2'}}</span>
          <span *ngIf="readyToSettlementreportModelById.response.plattformflag==0">N/A</span>
        </p>
      </div>
      <div class="item item-3" style="margin-left: 40px;">
        <p class="card-title"><b>SGST({{readyToSettlementreportModelById.response.sgstPercent}}%) </b> <br>
          <span *ngIf="readyToSettlementreportModelById.response.plattformflag==1"><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.partnersgst | number : '1.2-2'}}</span>
          <span *ngIf="readyToSettlementreportModelById.response.plattformflag==0">N/A</span>
        </p>
      </div>
      <div class="item item-3" style="margin-left: 40px;">
        <p class="card-title"><b>Additional Charges </b> <br>
          <span *ngIf="readyToSettlementreportModelById.response.additionalCharges!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.additionalCharges | number : '1.2-2'}}</span>
          <span *ngIf="readyToSettlementreportModelById.response.additionalCharges==null">N/A</span>
        </p>
      </div>

      <div class="item item-3" style="margin-left: 40px;">
        <p class="card-title"><b>Total Amount </b> <br>
          <span *ngIf="readyToSettlementreportModelById.response.partnerBank!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.totalfuelcost | number : '1.2-2'}}</span>
          <span *ngIf="readyToSettlementreportModelById.response.partnerBank==null">N/A</span>
        </p>
      </div>

      <div class="item item-3" style="margin-left: 40px;">
        <p class="card-title"><b>Account Number </b> <br>
          <span *ngIf="readyToSettlementreportModelById.response.partnerBank!=null">{{readyToSettlementreportModelById.response.partnerBank.accountNumber}}</span>
          <span *ngIf="readyToSettlementreportModelById.response.partnerBank==null">N/A</span>
        </p>
      </div>
      <div class="item item-3" style="margin-left: 40px;">
        <p class="card-title"><b>IFSC Code</b> <br>
          <span *ngIf="readyToSettlementreportModelById.response.partnerBank!=null">{{readyToSettlementreportModelById.response.partnerBank.ifscCode}}</span>
          <span *ngIf="readyToSettlementreportModelById.response.partnerBank==null">N/A</span>
        </p>
      </div>
      <div class="item item-3" style="margin-left: 40px;">
        <p class="card-title"><b>Bank Status</b> <br>
          <span *ngIf="readyToSettlementreportModelById.response.partnerBank!=null">
            <span *ngIf="readyToSettlementreportModelById.response.partnerBank.approvalStatus==10" >
              <!-- <button title="Pending" class="label label-info"><i class="fa fa-warning " ></i></button> -->
              <img src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
          </span> 
          <span *ngIf="readyToSettlementreportModelById.response.partnerBank.approvalStatus==11" >
            <!-- <button title="Approved" class="label label-info"><i class="fa fa-check" aria-hidden="true"></i></button> -->
            <img src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
        </span> 
        <span *ngIf="readyToSettlementreportModelById.response.partnerBank.approvalStatus==12" >
          <img src="assets/img/remove.svg" alt="logo" data-src="assets/img/remove.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
          <!-- <button title="Rejected" class="label label-info"><i class="fa fa-remove"></i></button> -->
      </span>
          </span>
        </div>
      </div>
      <hr *ngIf="readyToSettlementreportModelById.response.vehicleBank!=null" class="beautiful-line">
      <h5 *ngIf="readyToSettlementreportModelById.response.vehicleBank!=null" style="margin-left: 15px; text-align: left; color: grey;">Settlement To Vehicle</h5>
      <div *ngIf="readyToSettlementreportModelById.response.vehicleBank!=null" class="container"
      fxLayout
    >
    <div class="item item-3">
      <p class="card-title"><b>Vehicle Number</b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleNumber!=null">{{readyToSettlementreportModelById.response.vehicleNumber}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleNumber==null">N/A</span>
      </p>
    </div>

      <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>Model Name</b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleName!=null">{{readyToSettlementreportModelById.response.vehicleName}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleName==null">N/A</span>
      </p>
    </div>
     <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>Delivery Charges </b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleBank!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.deliveryCharges | number : '1.2-2'}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleBank==null">N/A</span>
      </p>
    </div>
    <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>CGST({{readyToSettlementreportModelById.response.cgstPercent}}%) </b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleBank!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.partnercgst | number : '1.2-2'}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleBank==null">N/A</span>
      </p>
    </div>
    <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>SGST({{readyToSettlementreportModelById.response.sgstPercent}}%) </b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleBank!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.partnersgst | number : '1.2-2'}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleBank==null">N/A</span>
      </p>
    </div>

    <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>Total Amount </b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleBank!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.partneramount | number : '1.2-2'}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleBank==null">N/A</span>
      </p>
    </div>

    <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>Account Number </b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleBank!=null">{{readyToSettlementreportModelById.response.vehicleBank.accountNumber}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleBank==null">N/A</span>
      </p>
    </div>

     <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>IFSC Code</b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleBank!=null">{{readyToSettlementreportModelById.response.vehicleBank.ifscCode}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleBank==null">N/A</span>
      </p>
    </div>
    <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>Bank Status</b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.vehicleBank!=null">
          <span *ngIf="readyToSettlementreportModelById.response.vehicleBank.approvalStatus==10" >
            <img src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
        </span> 
        <span *ngIf="readyToSettlementreportModelById.response.vehicleBank.approvalStatus==11" >
          <img src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
      </span> 
      <span *ngIf="readyToSettlementreportModelById.response.vehicleBank.approvalStatus==12" >
        <img src="assets/img/remove.svg" alt="logo" data-src="assets/img/remove.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    </span>
        </span>
      </div>
    </div>
    <hr class="beautiful-line">
    <h5 style="margin-left: 15px; text-align: left; color: grey;">Settlement To Cinch</h5>
      <div class="container"
      fxLayout
    >
    <div class="item item-3">
      <p class="card-title"><b>Platform Charges</b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.internetHandlingFee | number : '1.2-2'}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank==null">N/A</span>
      </p>
    </div>

    <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>CGST(9%)</b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.platformcgst | number : '1.2-2'}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank==null">N/A</span>
      </p>
    </div>

    <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>SGST(9%)</b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.platformsgst | number : '1.2-2'}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank==null">N/A</span>
      </p>
    </div>

    <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>Total Amount</b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{readyToSettlementreportModelById.response.platformtotalamount | number : '1.2-2'}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank==null">N/A</span>
      </p>
    </div>

      <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>Account Holder Name</b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank!=null">{{readyToSettlementreportModelById.response.adminSettlementBank.beneficiaryName}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank==null">N/A</span>
      </p>
    </div>
     <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>Account No </b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank!=null">{{readyToSettlementreportModelById.response.adminSettlementBank.accountNumber}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank==null">N/A</span>
      </p>
    </div>

    <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>IFSC Code</b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank!=null">{{readyToSettlementreportModelById.response.adminSettlementBank.ifscCode}}</span>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank==null">N/A</span>
      </p>
    </div>

    <div class="item item-3" style="margin-left: 40px;">
      <p class="card-title"><b>Bank Status</b> <br>
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank!=null">
          <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank.approvalStatus==10" >
            <img src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
            <!-- <button title="Pending" class="label label-info"><i class="fa fa-warning " ></i></button> -->
        </span> 
        <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank.approvalStatus==11" >
          <img src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
          <!-- <button title="Approved" class="label label-info"><i class="fa fa-check" aria-hidden="true"></i></button> -->
      </span> 
      <span *ngIf="readyToSettlementreportModelById.response.adminSettlementBank.approvalStatus==12" >
        <!-- <button title="Rejected" class="label label-info"><i class="fa fa-remove"></i></button> -->
        <img src="assets/img/remove.svg" alt="logo" data-src="assets/img/remove.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
      </span>
        </span>
      </div>
    </div>
        
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
        </div>
      </ng-template>