import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { BankModel } from '../model/approvebank';
import { GenericResponseModel } from '../model/genricresponse';
import { KycDocumentsModel } from '../model/kycdocument';
import { UserModel } from '../model/user';
import { CustomerService } from '../services/customer.service';
import { PartnersService } from '../services/partners.service';
import { UserService } from '../services/user.service';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { Observable } from 'rxjs';
import { data } from 'jquery';
import { VehicleFuelService } from '../services/vehivle.service';
import { GenericResponse } from '../model/genricmodelresponse';
// import { AgmCoreModule } from '@agm/core';
import * as moment from 'moment';

@Component({
  selector: 'app-partners-kyc-vehicle-view',
  templateUrl: './partners-kyc-vehicle-view.component.html',
  styleUrls: ['./partners-kyc-vehicle-view.component.css']
})
export class PartnersKycVehicleViewComponent {
  lat:Number;
  lan:Number;
 public isCollapsed = false;
 active = 1;
 kycdocumentmodel:GenericResponseModel;
 kycdocumentmodelPending:GenericResponseModel;
 kycdocumentmodelApprove:GenericResponseModel;
 kycdocumentmodelReject:GenericResponseModel;
 pendingkyc:boolean;
 approvekyc:boolean;
 rejectkyc:boolean;
 allkyc:boolean = true;

kycId:Number;
kycresponse:GenericResponseModel;
kycapprove:boolean;
message:String;
userId:Number;
poppup:boolean;
poppupalert:boolean;
checkcondition:Number;
kycDocument:Object;

kycdocumentss : KycDocumentsModel[];
li:any; 
  lis=[]; 
 usermodel:UserModel;
  resultText=[];  
  values:string;  
 count:number=0;  
 errorMsg:string;  
 companyName:string;
closeResult = '';
selectedAll: any;
imageToShow: any;
  isImageLoading: boolean;
  isImageLoading1: boolean;
  newWindow:any;
  isImageLoadingPartner: boolean;
  imageToShowPartner: any;
  imageToShowkyc: any;
isCheck:boolean = false;
imageSrc: string;
imageError: string;
isImageSaved: boolean;
cardImageBase64: string;
selectedFiles: FileList;
currentFile: File;
progress = 0;
mes = '';
fileInfos: Observable<any>;
latitude:any;
  langitude:any;
  kycpendinglist:GenericResponseModel;
  kycalert:boolean;
  kycresponseString:String;
  emptyCheckResponse:String;
  emptyCheck:boolean;
  vehicleResponse:GenericResponse;
  vehicleResponseFilter:GenericResponse;
  vehicleResponseFilterNoRecordFound:GenericResponse;
  filteronesearch:boolean= false;
  startDate:String = null;
  filterflag:boolean = false;
endDate:String = null;
refreshFlag:boolean = false;
errorFlag:boolean = false;
  errorFlag1:boolean = false;
  errorMessage:String;
myForm = new FormGroup({
  name: new FormControl('', [Validators.required, Validators.minLength(3)]),
  file: new FormControl('', [Validators.required]),
  fileSource: new FormControl('', [Validators.required])
});
  constructor(private modalService: NgbModal,private customerService: CustomerService,private userservice:UserService, private route: ActivatedRoute,private partnerservice: PartnersService,private router: Router,private http : HttpClient,private vehicleService: VehicleFuelService) {
    if (navigator)
    {
    navigator.geolocation.getCurrentPosition( pos => {
        this.langitude = +pos.coords.longitude;
        this.latitude = +pos.coords.latitude;
      });
    }
  }

  ngOnInit() : void {
    var nowdate = new Date();
    var monthStartDay = new Date(nowdate.getFullYear(), nowdate.getMonth(), 1);
    var monthEndDay = new Date(nowdate.getFullYear(), nowdate.getMonth() + 1, 0);
    let startDate = moment(new Date(monthStartDay)).format("yyyy-MM-DD").toString();
    let endDate = moment(new Date(monthEndDay)).format("yyyy-MM-DD").toString();
    this.startDate = startDate;
    this.endDate = endDate;
    this.vehicleService.getVehicleList(startDate,endDate).subscribe(data=>{
      this.vehicleResponse = data;
    })

    this.poppup = false;
    this.route.queryParams.subscribe(params => {
      let parkyc = Number(sessionStorage.getItem("kyc"));

      // this.partnerservice.getVehicleInfo(parkyc).subscribe(data=>{
      //   this.vehicleResponse = data;
      // });

      this.userservice.getUserImage(parkyc).subscribe(data=>{
        this.isImageLoadingPartner = true;
        this.createImageFromBlob1(data);
        this.isImageLoadingPartner = false;
      });  

      this.userId = parkyc;
      this.partnerservice.getPartnersById(parkyc).subscribe(data=>{
        this.usermodel=data;
      });
      this.partnerservice.getKycInfo(parkyc).subscribe(data=>{
        this.kycdocumentmodel = data;
        this.kycDocument = data.response;
      });
    });
  }

  onChange(id:Number,event) {  
    this.errorMsg="";  
     const checked = event.target.checked;  
      if (checked) {  
        this.resultText.push(id);  
         } else {  
           const index = this.resultText.indexOf(id);  
           this.resultText.splice(index, 1);  
       }  
       this.values=this.resultText.toString();  
       const count=this.resultText.length;  
       this.count=count;  
    }  

  get f(){
    return this.myForm.controls;
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
  }

  uploadKyc(){
    let kycdocument = (<HTMLInputElement>document.getElementById('kycdocument')).value;
    let kycfile = (<HTMLInputElement>document.getElementById('kycfile')).value;
    let file='';
    if(kycfile!==null && kycfile!==''){
      this.progress = 0;
      this.currentFile = this.selectedFiles.item(0);
      file = this.currentFile.type;
      let arr =  file.split("/");
      let fileType = arr[1];
      fileType = 'Image';
      let lat = this.latitude;
      let lan = this.langitude;
      let username = sessionStorage.getItem('username');
      let partnerId = Number(sessionStorage.getItem("kyc"));
      let kycTypeId = kycdocument;
      let kycFile = this.currentFile;

      let input = new FormData();
      const formData1 = new FormData();
      input.append('partnerId',JSON.stringify(partnerId));
      input.append('kycTypeId',kycTypeId);
      input.append('userName',sessionStorage.getItem('username'));
      input.append('fileType','Image');
      input.append('latitude',lat);
      input.append('longitude',lan);
      input.append('kycFile',kycFile);
      this.partnerservice.uploadkyc(input).subscribe(data=>{
          if(data.response.flag==1){
            this.kycalert = true;
            this.kycresponseString = data.responseMessage;
            this.closeResult = `Dismissed ${this.getDismissReason('Save click')}`;
            setTimeout(function() {
              this.kycalert = false;
              window.location.reload();
          }.bind(this), 3000);
          }else{
            this.kycalert = false;
            setTimeout(function() {
              this.kycresponseString = data.responseMessage;
              this.kycalert = false;
              window.location.reload();
          }.bind(this), 3000);
          }
      });

    }else{
      this.emptyCheck = true;
      this.emptyCheckResponse = "Please select file!";
      setTimeout(function() {
        this.emptyCheck = false;
    }.bind(this), 3000);

    }
  }

    createImageFromBlob(image: Blob) {
      let reader = new FileReader();
      reader.addEventListener("load", () => {
         this.imageToShow = reader.result;
      }, false);
   
      if (image) {
         reader.readAsDataURL(image);
         console.log(image)
      }
   }

   upload(content3) {
    let parkyc = Number(sessionStorage.getItem("kyc"));
    this.partnerservice.getPendingKycDocument(parkyc).subscribe(data=>{
      this.kycpendinglist = data;
    });
      this.modalService.open(content3, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  onFileChange(event) {
    const reader = new FileReader();
    
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
   
        this.imageSrc = reader.result as string;
     
        this.myForm.patchValue({
          fileSource: reader.result
        });
      };
    }
  }

  open(content) {
    if(this.resultText.length>0){
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }else{
    }
  }

  open1(reject) {
    if(this.resultText.length>0){
      this.modalService.open(reject, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }else{
    }
  }

  open2(content1) {
      this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  open3(content2) {
    this.modalService.open(content2, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
}

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  kycdocument(id){
    this.isImageLoading = true;
    this.isImageLoading1 = true;
    this.partnerservice.getKycImage(id,2).subscribe(data=>{
      this.createImageFromBlob2(data);
      this.isImageLoading1 = false;
}, error => {
  this.isImageLoading1 = false;
  console.log(error);
});

    this.partnerservice.getKycImage(id,1).subscribe(data=>{
      this.createImageFromBlob(data);
      this.isImageLoading = false;
}, error => {
  this.isImageLoading = false;
  console.log(error);
});
  }

  location(lat,lan){
    this.lat = Number(lat);
    this.lan = Number(lan);
  }

  createImageFromBlob1(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
       this.imageToShowPartner = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
   }
  }

  createImageFromBlob2(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
       this.imageToShowkyc = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
   }
  }

  CheckUncheckAll(event){
    const checked = event.target.checked;  
    if(checked){
      this.checkcondition = 1;
      this.isCheck = true;
     }else {
      this.isCheck = false;
      this.checkcondition = 0;
     }
    }

    pending(){
      let parkyc = Number(sessionStorage.getItem("kyc"));
      this.pendingkyc = true;
      this.approvekyc = false;
      this.rejectkyc = false;
      this.allkyc = false;
      this.partnerservice.getPartnerPending(parkyc).subscribe(data=>{
        this.kycdocumentmodelPending = data;
        console.log(data);
      });
    }

    approveS(){
      this.pendingkyc = false;
      this.approvekyc = true;
      this.rejectkyc = false;
      this.allkyc = false;
      let parkyc = Number(sessionStorage.getItem("kyc"));
      this.partnerservice.getPartnerApprove(parkyc).subscribe(data=>{
        this.kycdocumentmodelApprove = data;
        console.log(data);
      });
    }

    rejectkycclick(){
      this.pendingkyc = false;
      this.approvekyc = false;
      this.rejectkyc = true;
      this.allkyc = false;
      let parkyc = Number(sessionStorage.getItem("kyc"));
      this.partnerservice.getPartnerReject(parkyc).subscribe(data=>{
          this.kycdocumentmodelReject = data;
      });
    }

    view(id){
      sessionStorage.setItem("vehicleId",id);
    this.router.navigate(['/partners-kyc-view']); 
    }

    search(){
      this.refreshFlag = true;
      let fromdate = (<HTMLInputElement>document.getElementById('fromdate')).value;
      let todate = (<HTMLInputElement>document.getElementById('todate')).value;
      if(fromdate==''||todate==''){
        this.errorFlag = true;
        this.errorMessage = "Please select Dates!";
      }else if(todate < fromdate){
        this.errorFlag = true;
        this.errorMessage = "End Date should be greater than Start Date!";
      }else{
        this.errorFlag = false;
        this.filterflag = true;
        this.vehicleService.getVehicleList(fromdate,todate).subscribe(data=>{
          if(data.statusCode==200){
            this.filteronesearch = false;
            this.vehicleResponseFilter = data;
          }else{
            this.filteronesearch = true;
            this.vehicleResponseFilterNoRecordFound = data;
          }
          
        })
      }
    }

    refresh(){
      this.refreshFlag = false;
      window.location.reload();
    }
}

