import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { GenericResponseModel } from '../model/genricresponse';
import { OrderTransactionService } from '../services/ordertran.service';
import { ReportServices } from '../services/reports.service';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { Workbook } from 'exceljs';
import { NgxSpinnerService } from 'ngx-spinner';
import { DecimalPipe } from '@angular/common';
import { GenericResponse } from '../model/genricmodelresponse';
@Component({
  selector: 'app-report-revenue',
  templateUrl: './report-revenue.component.html',
  styleUrls: ['./report-revenue.component.css']
})
export class ReportRevenueComponent implements OnInit {
  symbole:String; 
 public isCollapsed = false;
 active = 1;
 transactionDetail:GenericResponseModel;
 transactionfilterDetail:GenericResponseModel;
 settlementDetail:GenericResponseModel;
 settlementViewDetail:GenericResponseModel;
closeResult = '';
  errorFlag:boolean;
  errorMessage:String;
  refreshflag:boolean;
  responseDataList = [];
  revenuereportModel:GenericResponse;
  revenuefilterreportModel:GenericResponse;
  filterrevenue:boolean=false;
  normalrevenue:boolean=true;
  excelexportcheckrevenue : boolean = false;
  responseDataListnew = [];
  startDate:String;
  endDate:String;
  filteronesearch:boolean= false;
  revenueFilterNoRecordModel:GenericResponse;

  constructor(private modalService: NgbModal,private ordertransactionservice: OrderTransactionService,private route: ActivatedRoute,private router: Router,private reportservice:ReportServices,private spinnerService:NgxSpinnerService,private _decimalPipe: DecimalPipe) { }

  ngOnInit(): void {
    var nowdate = new Date();
    var monthStartDay = new Date(nowdate.getFullYear(), nowdate.getMonth(), 1);
    var monthEndDay = new Date(nowdate.getFullYear(), nowdate.getMonth() + 1, 0);
    let startDate = moment(new Date(monthStartDay)).format("yyyy-MM-DD").toString();
    let endDate = moment(new Date(monthEndDay)).format("yyyy-MM-DD").toString();
    this.startDate = startDate;
    this.endDate = endDate;
    let formData= {
      orderId:null,
      startDate:startDate,
      endDate:endDate,
    }

     this.reportservice.getRevenueReport(formData).subscribe(data=>{
      this.revenuereportModel = data;
      this.startDate = data.response.startdate;
      this.endDate = data.response.enddate;
      if(data.statusCode==200){
        this.excelexportcheckrevenue = true;
      }else{
        this.excelexportcheckrevenue = false;
      }
     },err => {
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }else if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });
  }

  search(){
    this.refreshflag = true;
    this.normalrevenue = false;
    let fromdate = (<HTMLInputElement>document.getElementById('fromdate')).value;
    let todate = (<HTMLInputElement>document.getElementById('todate')).value;
    var stdate = fromdate.split("-");
    var endate = todate.split("-");
    var st = new Date(Number(stdate[2]),Number(stdate[1]),Number(stdate[0]));
    var en = new Date(Number(endate[2]),Number(endate[1]),Number(endate[0]));
    if(todate < fromdate){
      this.errorFlag = true;
      this.errorMessage = "End Date should be greater than Start Date!";
      this.spinnerService.hide();
    }else if(fromdate!=null && fromdate!=='' && todate!=null && todate!==''){
      this.errorFlag = false;

      let formData= {
        orderId:null,
        startDate:fromdate,
        endDate:todate,
      }
      this.reportservice.getRevenueReport(formData).subscribe(data=>{
        this.filterrevenue = true;
        this.startDate = data.response.startdate;
        this.endDate = data.response.enddate;
        if(data.response.statusCode==200){
          this.normalrevenue = false;
          this.filteronesearch = true;
          this.excelexportcheckrevenue = true;
          this.revenuefilterreportModel = data;
        }else{
          this.normalrevenue = false;
          this.filteronesearch = false;
          this.excelexportcheckrevenue = false;
          this.revenueFilterNoRecordModel= data;
        }
       });
    }else{
      this.errorFlag = true;
      this.errorMessage = "Please select Dates!";
    }
  }

  refresh(){
    this.refreshflag = false;
    window.location.reload();
  }

  exportToExcelRevenue(){
    let sno = 1;
    this.revenuereportModel.response.revenuelist.forEach(element => {
      let vehicle = element['vehicleId'];
      let partner = vehicle['userId'];
      let customer = element['customerId']
      let dates = new Date(element['settledDatetime'])
      let convertedDate = moment(new Date(dates)).format("DD-MM-yyyy hh:mm:ss a").toString();
      var response = [];
      response.push(sno);
      response.push(element['orderId']);
      response.push(customer['firstName'] + customer['lastName']);
      response.push(customer['mobileNumber']);
      response.push(partner['firstName'] + partner['lastName']);
      response.push(partner['mobileNumber']);
      response.push(element['fuelType']);
      response.push(element['quantity']);
      response.push("₹ "+ this._decimalPipe.transform(element['totalfuelcost'],"1.2-2"));
      response.push("₹ "+ this._decimalPipe.transform(element['deliveryCharges'],"1.2-2"));
      response.push("₹ "+ this._decimalPipe.transform(element['tax'],"1.2-2"));
      response.push("₹ "+ this._decimalPipe.transform(element['internetHandlingFee'],"1.2-2"));
      response.push("₹ "+ this._decimalPipe.transform(element['totalPayableAmount'],"1.2-2"));
      response.push(convertedDate);
      this.responseDataListnew.push(response);
      sno++;
    });
    var response = [];
    response.push("");
    response.push("");
    response.push("");
    response.push("");
    response.push("");
    response.push("");
    response.push("");
    response.push("Total ");
    response.push("₹ "+this._decimalPipe.transform(this.revenuereportModel.response.totalSumFuelPrize,"1.2-2"));
    response.push("₹ "+this._decimalPipe.transform(this.revenuereportModel.response.totalSumDeliveryCharge,"1.2-2"));
    response.push("₹ "+this._decimalPipe.transform(this.revenuereportModel.response.totalSumTax,"1.2-2"));
    response.push("₹ "+this._decimalPipe.transform(this.revenuereportModel.response.totalSumPlatformCharge,"1.2-2"));
    response.push("₹ "+this._decimalPipe.transform(this.revenuereportModel.response.totalSumPayoutAmount,"1.2-2"));
    response.push("");
    this.responseDataListnew.push(response);
    this.excelexport();
  }

  exportToExcelFilterRevenue(){
    let sno = 1;
    this.revenuefilterreportModel.response.revenuelist.forEach(element => {
      let vehicle = element['vehicleId'];
      let partner = vehicle['userId'];
      let customer = element['customerId']

      let paymentda = element['settledDatetime'];
        let payDate;
        if(paymentda!=null && paymentda!==''){
          let pay = new Date(element['settledDatetime'])
          payDate = moment(new Date(pay)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }else{
          payDate = "N/A";
        } 
      var response = [];
      response.push(sno);
      response.push(element['orderId']);
      response.push(customer['firstName'] + customer['lastName']);
      response.push(customer['mobileNumber']);
      response.push(partner['firstName'] + partner['lastName']);
      response.push(partner['mobileNumber']);
      response.push(element['fuelType']);
      response.push(element['quantity']);
      response.push("₹ "+ this._decimalPipe.transform(element['totalfuelcost'],"1.2-2"));
      response.push("₹ "+ this._decimalPipe.transform(element['deliveryCharges'],"1.2-2"));
      response.push("₹ "+ this._decimalPipe.transform(element['tax'],"1.2-2"));
      response.push("₹ "+ this._decimalPipe.transform(element['internetHandlingFee'],"1.2-2"));
      response.push("₹ "+ this._decimalPipe.transform(element['totalPayableAmount'],"1.2-2"));
      response.push(payDate);
      this.responseDataListnew.push(response);
      sno++;
    });
    var response = [];
    response.push("");
    response.push("");
    response.push("");
    response.push("");
    response.push("");
    response.push("");
    response.push("");
    response.push("Total ");
    response.push("₹ "+this._decimalPipe.transform(this.revenuefilterreportModel.response.totalSumFuelPrize,"1.2-2"));
    response.push("₹ "+this._decimalPipe.transform(this.revenuefilterreportModel.response.totalSumDeliveryCharge,"1.2-2"));
    response.push("₹ "+this._decimalPipe.transform(this.revenuefilterreportModel.response.totalSumTax,"1.2-2"));
    response.push("₹ "+this._decimalPipe.transform(this.revenuefilterreportModel.response.totalSumPlatformCharge,"1.2-2"));
    response.push("₹ "+this._decimalPipe.transform(this.revenuefilterreportModel.response.totalSumPayoutAmount,"1.2-2"));
    response.push("");
    this.responseDataListnew.push(response);
    this.excelexport();
  }

  excelexport(){
    const title = 'Revenue Report';
    const header = ["S.No","Order ID", "Customer Name", "Customer Mobile", "Partner Name", "Partner Mobile", "Fuel","Quantity","Fuel Price","Delivery Charge","Tax","Platform Charge","Total Charge","Settled Date & Time"]
    const data = this.responseDataListnew;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Revenue Report');
// Add new row
let titleRow = worksheet.addRow([title]);
// Set font, size and style in title row.
titleRow.font = { name: 'Times New Roman', family: 4, size: 16, bold: true };

// Blank Row
worksheet.addRow([]);
let subTitleRow = worksheet.addRow(['Start Date  : ' + this.startDate]);
  subTitleRow.font = { name: 'Times New Roman', family: 4, size: 12, bold: true };
  let subTitleRow1 = worksheet.addRow(['End Date  : ' + this.endDate]);
  subTitleRow1.font = { name: 'Times New Roman', family: 4, size: 12, bold: true };
  worksheet.addRow([]);

//Add row with current date
// let subTitleRow = worksheet.addRow(['Date : ' + this.datePipe.transform(new Date(), 'medium')]);
//Add Header Row
let headerRow = worksheet.addRow(header);
headerRow.font = {bold: true };
// Cell Style : Fill and Border
headerRow.eachCell((cell, number) => {
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFFFF' },
    bgColor: { argb: 'FF0000FF' },
  }
  cell.border = {  top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
});

data.forEach(d => {
  let row = worksheet.addRow(d);
  let qty = row.getCell(1);
  let qty1 = row.getCell(2);
  let qty2 = row.getCell(3);
  let qty3 = row.getCell(4);
  let qty4 = row.getCell(5);
  let qty5 = row.getCell(6);
  let qty6 = row.getCell(7);
  let qty7 = row.getCell(8);
  let qty8 = row.getCell(9);
  let qty9 = row.getCell(10);
  let qty10 = row.getCell(11);
  let qty11= row.getCell(12);
  let qty12 = row.getCell(13);
  let qty13 = row.getCell(14);
  qty.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty1.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty2.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty3.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty4.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty5.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty6.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty8.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty9.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty10.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty11.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty12.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty13.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }

}
);
workbook.xlsx.writeBuffer().then((data) => {
  let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  FileSaver.saveAs(blob, 'Revenue Report.xlsx');
});
   }
}
