<app-header></app-header>

<div class="page-container "> 
   <!-- START PAGE CONTENT WRAPPER -->
   <div class="page-content-wrapper "> 
     <!-- START PAGE CONTENT -->
     <div class="content sm-gutter"> 
       <!-- START BREADCRUMBS -->
       <div class="bg-white">
         <div class="container">
           <ol class="breadcrumb breadcrumb-alt">
             <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Home</a></li>
             <li class="breadcrumb-item active">USER PROFILE </li>
           </ol>
         </div>
       </div>
       <!-- END BREADCRUMBS -->
       
          
       
       <!-- START CONTAINER FLUID --> 
       
       <!-- START CONTAINER FLUID -->
       <div class=" no-padding    container-fixed-lg bg-white">
         <div class="container"> 
           <!-- START card -->
            <div class="col-md-6">
                     <!-- form card change password -->
                     <div class="col1 flex">
                        <div class=" profile images flex">
                           <img src="assets/img/profile.jpg" alt="logo" data-src="assets/img/profile.jpg" data-src-retina="assets/img/profile.jpg" width="150px" >
                           <!-- <img class="modal-content"  [src]="imageToShow"> -->
                        </div>
                        <div class="profile-info flex">
                           <div class="card-body profile">
                              <p class="card-title"><b>User Name</b> <br><span><b>{{userModel.firstName}}</b></span></p>
                              
                           </div>
                            <div class="card-body profile">
                              <p class="card-title"><i class="fa fa-mobile" aria-hidden="true"></i><b>Mobile Number</b> 
                                 <br><span><b>{{userModel.mobileNumber}}</b></span>
                              </p>
                              <p class="card-title"><i class="fa fa-envelope" aria-hidden="true"></i><b>  Email Address</b> 
                                 <br><span><b>{{userModel.emailAddress}}</b></span>
                              </p>
                           </div>
               
                           <div class="card-body profile">
                              <p *ngIf="userModel.roleName!=null"class="card-title"><b> Role </b> <br><span><b>{{userModel.roleName}}</b></span></p>
                              <p *ngIf="userModel.roleName==null"class="card-title"><b> Role </b> <br><span><b>N/A</b></span></p>
                           </div>
                        </div>
                     </div>
                     <!-- /form card change password -->
 
                 </div>
           <!-- END card --> 
         </div>
       </div>
       <!-- END CONTAINER FLUID --> 
       
     </div>
     <!-- END PAGE CONTENT --> 
     <!-- START COPYRIGHT --> 
     <!-- START CONTAINER FLUID --> 
     <!-- START CONTAINER FLUID -->
   <!--   <div class=" container   container-fixed-lg footer">
       <div class="copyright sm-text-center">
         <p class="small-text no-margin pull-left sm-pull-reset"> ©2014-2020 All Rights Reserved. Pages® and/or its subsidiaries or affiliates are registered trademark of Revox Ltd. <span class="hint-text m-l-15">Pages v05.23 20201105.r.190</span> </p>
         <p class="small no-margin pull-right sm-pull-reset"> Hand-crafted <span class="hint-text">&amp; made with Love</span> </p>
         <div class="clearfix"></div>
       </div>
     </div> -->
     <!-- END COPYRIGHT --> 
   </div>
   <!-- END PAGE CONTENT WRAPPER --> 
 </div>
 <!-- END PAGE CONTAINER --> 
 <!--START QUICKVIEW -->
 
 <!-- END QUICKVIEW--> 
 <!-- START OVERLAY -->
 
 <!-- END OVERLAY -->
 
 
 
  
 <router-outlet></router-outlet>
