<app-header></app-header>

<!-- START PAGE-CONTAINER -->
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
  </ngx-spinner> 
<div class="page-container "> 
  <!-- START PAGE CONTENT WRAPPER -->
  <div class="page-content-wrapper "> 
    <!-- START PAGE CONTENT -->
    <div class="content sm-gutter"> 
      <!-- START BREADCRUMBS -->
      <div class="bg-white">
        <div class="container">
          <ol class="breadcrumb breadcrumb-alt">
            <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Home</a></li>
            <li class="breadcrumb-item active"><span class="font-montserrat fs-11 all-caps">Refund Report</span></li>
          </ol>
        </div>
      </div>

      <!-- START CONTAINER FLUID -->
      <div class=" no-padding    container-fixed-lg bg-white">
        <div *ngIf="div1">
          <button class="btn-transaction" (click)="div1Function()" style="margin-left: 500px; margin-top: 10px;">
            <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked>
            <label class="form-check-label" for="exampleRadios1"> Order Cancellation </label>
          </div>
          </button>
          <button class="btn-transaction" (click)="div2Function()">
          <div class="form-check">
          <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2">
          <label class="form-check-label" for="exampleRadios2"> Refund </label>
        </div>
        </button>
        </div>
        <div *ngIf="div2">
          <button class="btn-transaction" (click)="div1Function()" style="margin-left: 500px; margin-top: 10px;">
            <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" >
            <label class="form-check-label" for="exampleRadios1"> Order Cancellation </label>
          </div>
          </button>
          <button class="btn-transaction" (click)="div2Function()">
          <div class="form-check">
          <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" checked>
          <label class="form-check-label" for="exampleRadios2"> Refund </label>
        </div>
        </button>
        </div>
        <!-- Order Cancelled  -->
        <div *ngIf="div1" class="container"> 
          <div class="card card-transparent">
            <div>
          <label >Search By Status</label>  
          <select class="select-form-style"id="status" style="margin-left: 10px; margin-top: 5px;">
            <option value="20" selected>Initiated</option>  
            <option value="21" >Completed</option>
        </select>  
        <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
        <button title="Refresh" *ngIf="refreshFlag" (click)="refresh()"style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button> 
      </div> 
            <div class="btn-section-wrapper">
              <button  *ngIf="excelexportcheck" (click)="exportToExcel()" title="Excel Export" style="margin-left: 10px; margin-top: 5px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
            </div>
            <div *ngIf="!filterrefund" class="card-body">
                <div style="display: none;">{{refundreportModel.responseMessage}}</div>
              <div class="table-responsive" style="margin-top: 20px;">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Order ID</th>
                      <th style="text-align: left;">Customer Name</th>
                      <th style="text-align: left;">Mobile Number</th>
                      <th style="text-align: left;">Fuel</th>
                      <th style="text-align: left;">Quantity</th>
                      <th style="text-align: left;">Booked Date & Time</th>
                      <th style="text-align: left;">Cancelled Date & Time</th>
                      <th style="text-align: right;">Paid Amount</th>
                      <th style="text-align: right;">Refund Amount</th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: center;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let refund of refundreportModel.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="refund.orderId!=null"align="left" class="v-align-middle">{{refund.orderId}}</td>
                      <td *ngIf="refund.orderId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="refund.customerId!=null"align="left" class="v-align-middle">{{refund.customerId.firstName}} {{refund.customerId.lastName}}</td>
                      <td *ngIf="refund.customerId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="refund.customerId!=null"align="left" class="v-align-middle">{{refund.customerId.mobileNumber}}</td>
                      <td *ngIf="refund.customerId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="refund.vehicleId!=null"align="left" class="v-align-middle">{{refund.vehicleId.fuelType.fuelTypeName}}</td>
                      <td *ngIf="refund.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="refund.quantity!=null"align="center" class="v-align-middle">{{refund.quantity}}</td>
                      <td *ngIf="refund.quantity==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="refund.orderDatetime!=null"align="left" class="v-align-middle">{{refund.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                      <td *ngIf="refund.orderDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="refund.orderCancellationDatetime!=null" align="left" class="v-align-middle">{{refund.orderCancellationDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                      <td *ngIf="refund.orderCancellationDatetime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="refund.totalPayableAmount!=null" align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{refund.totalPayableAmount | number : '1.2-2'}}</td>
                      <td *ngIf="refund.totalPayableAmount==null" align="right" class="v-align-middle">N/A</td>
                      <td *ngIf="refund.refundAmount!=null" align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{refund.refundAmount | number : '1.2-2'}}</td>
                      <td *ngIf="refund.refundAmount==null" align="right" class="v-align-middle">N/A</td>
                      <td *ngIf="refund.refundStatus!=null && refund.refundStatus==20" align="center" class="v-align-middle ht-Pending">Initiated</td>
                      <td *ngIf="refund.refundStatus!=null && refund.refundStatus==21" align="center" class="v-align-middle ht-Success">Completed</td>
                      <td *ngIf="refund.refundStatus==0" align="center" class="v-align-middle">N/A</td>
                      <td align="center" class="v-align-middle"><button title="View" class="label label-info" (click)="view(refund.id,content)"><i class="fa fa-eye" aria-hidden="true"></i></button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div *ngIf="filterrefund" class="card-body">
              <div *ngIf="filteronesearch">
                <div style="display: none;">{{filterrefundreportModel.responseMessage}}</div>
                <div class="table-responsive" style="margin-top: 20px;">
                  <table datatable class="ch-table">
                    <thead>
                      <tr> 
                        <th style="text-align: center;">Sl.No.</th>
                        <th style="text-align: left;">Order ID</th>
                        <th style="text-align: left;">Customer Name</th>
                        <th style="text-align: left;">Mobile Number</th>
                        <th style="text-align: left;">Fuel</th>
                        <th style="text-align: left;">Quantity </th>
                        <th style="text-align: left;">Booked Date & Time</th>
                        <th style="text-align: left;">Cancelled Date & Time</th>
                        <th style="text-align: right;">Paid Amount</th>
                        <th style="text-align: right;">Refund Amount</th>
                        <th style="text-align: center;">Status</th>
                        <th style="text-align: center;">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let refund of filterrefundreportModel.response; let i=index">
                        <td align="center" class="v-align-middle ">{{i + 1}}</td>
                        <td *ngIf="refund.orderId!=null"align="left" class="v-align-middle">{{refund.orderId}}</td>
                        <td *ngIf="refund.orderId==null"align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.customerId!=null"align="left" class="v-align-middle">{{refund.customerId.firstName}} {{refund.customerId.lastName}}</td>
                        <td *ngIf="refund.customerId==null"align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.customerId!=null"align="left" class="v-align-middle">{{refund.customerId.mobileNumber}}</td>
                        <td *ngIf="refund.customerId==null"align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.vehicleId!=null"align="left" class="v-align-middle">{{refund.vehicleId.fuelType.fuelTypeName}}</td>
                        <td *ngIf="refund.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.quantity!=null"align="center" class="v-align-middle">{{refund.quantity}}</td>
                        <td *ngIf="refund.quantity==null"align="center" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.orderDatetime!=null"align="left" class="v-align-middle">{{refund.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                        <td *ngIf="refund.orderDatetime==null"align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.orderCancellationDatetime!=null" align="left" class="v-align-middle">{{refund.orderCancellationDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                        <td *ngIf="refund.orderCancellationDatetime==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.totalPayableAmount!=null" align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{refund.totalPayableAmount | number : '1.2-2'}}</td>
                        <td *ngIf="refund.totalPayableAmount==null" align="right" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.refundAmount!=null" align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{refund.refundAmount | number : '1.2-2'}}</td>
                        <td *ngIf="refund.refundAmount==null" align="right" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.refundStatus!=null && refund.refundStatus==20" align="center" class="v-align-middle ht-Pending">Initiated</td>
                        <td *ngIf="refund.refundStatus!=null && refund.refundStatus==21" align="center" class="v-align-middle ht-Success">Completed</td>
                        <td *ngIf="refund.refundStatus==0" align="center" class="v-align-middle">N/A</td>
                        <td align="center" class="v-align-middle"><button title="View" class="label label-info" (click)="view(refund.id,content)"><i class="fa fa-eye" aria-hidden="true"></i></button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div *ngIf="!filteronesearch">
                <div style="display: none;">{{reportfilter1DetailNoRecord.responseMessage}}</div>
                <div class="table-responsive" style="margin-top: 20px;">
                  <table datatable class="ch-table">
                    <thead>
                      <tr> 
                        <th style="text-align: center;">Sl.No.</th>
                        <th style="text-align: left;">Order ID</th>
                        <th style="text-align: left;">Customer Name</th>
                        <th style="text-align: left;">Mobile Number</th>
                        <th style="text-align: left;">Fuel</th>
                        <th style="text-align: left;">Quantity </th>
                        <th style="text-align: left;">Booked Date & Time</th>
                        <th style="text-align: left;">Cancelled Date & Time</th>
                        <th style="text-align: right;">Paid Amount</th>
                        <th style="text-align: right;">Refund Amount</th>
                        <th style="text-align: center;">Status</th>
                        <th style="text-align: center;">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let refund of reportfilter1DetailNoRecord.response; let i=index">
                        <td align="center" class="v-align-middle ">{{i + 1}}</td>
                        <td *ngIf="refund.orderId!=null"align="left" class="v-align-middle">{{refund.orderId}}</td>
                        <td *ngIf="refund.orderId==null"align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.customerId!=null"align="left" class="v-align-middle">{{refund.customerId.firstName}} {{refund.customerId.lastName}}</td>
                        <td *ngIf="refund.customerId==null"align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.customerId!=null"align="left" class="v-align-middle">{{refund.customerId.mobileNumber}}</td>
                        <td *ngIf="refund.customerId==null"align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.vehicleId!=null"align="left" class="v-align-middle">{{refund.vehicleId.fuelType.fuelTypeName}}</td>
                        <td *ngIf="refund.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.quantity!=null"align="center" class="v-align-middle">{{refund.quantity}}</td>
                        <td *ngIf="refund.quantity==null"align="center" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.orderDatetime!=null"align="left" class="v-align-middle">{{refund.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                        <td *ngIf="refund.orderDatetime==null"align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.orderCancellationDatetime!=null" align="left" class="v-align-middle">{{refund.orderCancellationDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                        <td *ngIf="refund.orderCancellationDatetime==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.totalPayableAmount!=null" align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{refund.totalPayableAmount | number : '1.2-2'}}</td>
                        <td *ngIf="refund.totalPayableAmount==null" align="right" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.refundAmount!=null" align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{refund.refundAmount | number : '1.2-2'}}</td>
                        <td *ngIf="refund.refundAmount==null" align="right" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.refundStatus!=null && refund.refundStatus==20" align="center" class="v-align-middle ht-Pending">Initiated</td>
                        <td *ngIf="refund.refundStatus!=null && refund.refundStatus==21" align="center" class="v-align-middle ht-Success">Completed</td>
                        <td *ngIf="refund.refundStatus==0" align="center" class="v-align-middle">N/A</td>
                        <td align="center" class="v-align-middle"><button title="View" class="label label-info" (click)="view(refund.id,content)"><i class="fa fa-eye" aria-hidden="true"></i></button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
          </div>
          </div>
        </div>

        
        <!-- Refund Flow  -->
        <div *ngIf="div2" class="container"> 
          <div class="card card-transparent">
            <div>
          <label >Search By Status</label>  
          <select class="select-form-style"id="status" style="margin-left: 10px; margin-top: 5px;">
            <option value="20" selected>Initiated</option>  
            <option value="21" >Completed</option>
        </select>  
        <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search1()" type="button" >Go</button> 
        <button title="Refresh" *ngIf="refreshFlag1" (click)="refresh1()"style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button> 
      </div> 
            <div class="btn-section-wrapper">
              <button  *ngIf="excelexportcheck" (click)="exportToExcel1()" title="Excel Export" style="margin-left: 10px; margin-top: 5px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
            </div>
            <div *ngIf="!filterrefund1" class="card-body">
                <div style="display: none;">{{refundreportModel1.responseMessage}}</div>
              <div class="table-responsive" style="margin-top: 20px;">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Order ID</th>
                      <th style="text-align: left;">Customer Info</th>
                      <!-- <th style="text-align: left;">Mobile Number</th> -->
                      <!-- <th style="text-align: left;">Fuel</th> -->
                      <th style="text-align: left;">Price per ltr</th>
                      <th style="text-align: left;">Quantity in Ltrs</th>
                      <!-- <th style="text-align: left;">Booked Date & Time</th> -->
                      <th style="text-align: left;">Refund Submitted Date & Time</th>
                      <th style="text-align: right;">Paid Amount</th>
                      <th style="text-align: right;">Refund Amount</th>
                      <th style="text-align: left;">Refund ID</th>
                      <th style="text-align: left;">Refund Completed Date & Time</th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: center;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let refund of refundreportModel1.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="refund.orderId!=null"align="left" class="v-align-middle">{{refund.orderId}}</td>
                      <td *ngIf="refund.orderId==null"align="left" class="v-align-middle">N/A</td>

                      <td>
                        <span *ngIf="refund.customerId!=null"> {{refund.customerId.firstName}} {{refund.customerId.lastName}}</span>
                        <br/>

                        {{refund.customerId.mobileNumber}} 
                      </td>

                      <td *ngIf="refund.fuelPrice!=null" align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{refund.fuelPrice | number : '1.2-2'}}</td>
                      <td *ngIf="refund.fuelPrice==null" align="right" class="v-align-middle">N/A</td>
                      <td *ngIf="refund.quantity!=null"align="center" class="v-align-middle">
                        <b>Ordered:</b>{{refund.quantity}}<br/>
                        <b>Delivered:</b>{{refund.orderDeliveredLt}}
                      </td>
                      <td *ngIf="refund.quantity==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="refund.refundSubmittedDateTime!=null"align="left" class="v-align-middle">{{refund.refundSubmittedDateTime | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                      <td *ngIf="refund.refundSubmittedDateTime==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="refund.totalPayableAmount!=null" align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{refund.totalPayableAmount | number : '1.2-2'}}</td>
                      <td *ngIf="refund.totalPayableAmount==null" align="right" class="v-align-middle">N/A</td>
                      <td *ngIf="refund.refundAmount!=null" align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{refund.refundAmount | number : '1.2-2'}}</td>
                      <td *ngIf="refund.refundAmount==null" align="right" class="v-align-middle">N/A</td>
                    
                      <td *ngIf="refund.refundReferenceId!=null"align="left" class="v-align-middle">{{refund.refundReferenceId }}</td>
                      <td *ngIf="refund.refundReferenceId==null"align="left" class="v-align-middle">N/A</td>
                      
                      <td *ngIf="refund.refundDateTime!=null"align="left" class="v-align-middle">{{refund.refundDateTime | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                      <td *ngIf="refund.refundDateTime==null"align="left" class="v-align-middle">N/A</td>
                                          
                      <td *ngIf="refund.refundStatus!=null && refund.refundStatus==20" align="center" class="v-align-middle ht-Pending">Initiated</td>
                      <td *ngIf="refund.refundStatus!=null && refund.refundStatus==21" align="center" class="v-align-middle ht-Success">Completed</td>
                      <td *ngIf="refund.refundStatus==0 || refund.refundStatus==21" align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="refund.refundStatus==20" align="center" class="v-align-middle" style="width:7%;">
                        <button  title="Automatic Refund" class="label label-info" (click)="view1(refund.id)"><i class="fa fa-money" aria-hidden="true"></i></button>
                       &nbsp; <button  title="Manual Refund" class="label label-info" (click)="manualrefund(refund.id,content9)"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div *ngIf="filterrefund1" class="card-body">
              <div *ngIf="filteronesearch1">
                <div style="display: none;">{{filterrefundreportModel1.responseMessage}}</div>
                <div class="table-responsive" style="margin-top: 20px;">
                  <table datatable class="ch-table">
                    <thead>
                      <tr> 
                        <th style="text-align: center;">Sl.No.</th>
                        <th style="text-align: left;">Order ID</th>
                        <th style="text-align: left;">Customer Info</th>
                        <!-- <th style="text-align: left;">Mobile Number</th> -->
                        <!-- <th style="text-align: left;">Fuel</th> -->
                        <th style="text-align: left;">Price per ltr</th>
                        <th style="text-align: left;">Quantity in Ltrs</th>
                        <!-- <th style="text-align: left;">Booked Date & Time</th> -->
                        <th style="text-align: left;">Refund Submitted Date & Time</th>
                        <th style="text-align: right;">Paid Amount</th>
                        <th style="text-align: right;">Refund Amount</th>
                        <th style="text-align: left;">Refund ID</th>
                        <th style="text-align: left;">Refund Completed Date & Time</th>
                        <th style="text-align: center;">Status</th>
                        <th style="text-align: center;">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let refund of filterrefundreportModel1.response; let i=index">
                        <td align="center" class="v-align-middle ">{{i + 1}}</td>
                        <td *ngIf="refund.orderId!=null"align="left" class="v-align-middle">{{refund.orderId}}</td>
                        <td *ngIf="refund.orderId==null"align="left" class="v-align-middle">N/A</td>
  
                        <td>
                          <span *ngIf="refund.customerId!=null"> {{refund.customerId.firstName}} {{refund.customerId.lastName}}</span>
                          <br/>
  
                          {{refund.customerId.mobileNumber}} 
                        </td>
  
                        <td *ngIf="refund.fuelPrice!=null" align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{refund.fuelPrice | number : '1.2-2'}}</td>
                        <td *ngIf="refund.fuelPrice==null" align="right" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.quantity!=null"align="center" class="v-align-middle">
                          <b>Ordered:</b>{{refund.quantity}}<br/>
                          <b>Delivered:</b>{{refund.orderDeliveredLt}}
                        </td>
                        <td *ngIf="refund.quantity==null"align="center" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.refundSubmittedDateTime!=null"align="left" class="v-align-middle">{{refund.refundSubmittedDateTime | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                        <td *ngIf="refund.refundSubmittedDateTime==null"align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.totalPayableAmount!=null" align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{refund.totalPayableAmount | number : '1.2-2'}}</td>
                        <td *ngIf="refund.totalPayableAmount==null" align="right" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.refundAmount!=null" align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{refund.refundAmount | number : '1.2-2'}}</td>
                        <td *ngIf="refund.refundAmount==null" align="right" class="v-align-middle">N/A</td>
                      
                        <td *ngIf="refund.refundReferenceId!=null"align="left" class="v-align-middle">{{refund.refundReferenceId }}</td>
                        <td *ngIf="refund.refundReferenceId==null"align="left" class="v-align-middle">N/A</td>
                        
                        <td *ngIf="refund.refundDateTime!=null"align="left" class="v-align-middle">{{refund.refundDateTime | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                        <td *ngIf="refund.refundDateTime==null"align="left" class="v-align-middle">N/A</td>
                                            
                        <td *ngIf="refund.refundStatus!=null && refund.refundStatus==20" align="center" class="v-align-middle ht-Pending">Initiated</td>
                        <td *ngIf="refund.refundStatus!=null && refund.refundStatus==21" align="center" class="v-align-middle ht-Success">Completed</td>
                        <td *ngIf="refund.refundStatus==0 || refund.refundStatus==21" align="center" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.refundStatus==20" align="center" class="v-align-middle" style="width:7%;">
                          <button  title="Automatic Refund" class="label label-info" (click)="view1(refund.id)"><i class="fa fa-money" aria-hidden="true"></i></button>
                         &nbsp; <button  title="Manual Refund" class="label label-info" (click)="manualrefund(refund.id,content9)"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div *ngIf="!filteronesearch1">
                <div style="display: none;">{{reportfilter1DetailNoRecord1.responseMessage}}</div>
                <div class="table-responsive" style="margin-top: 20px;">
                  <table datatable class="ch-table">
                    <thead>
                      <tr> 
                        <th style="text-align: center;">Sl.No.</th>
                        <th style="text-align: left;">Order ID</th>
                        <th style="text-align: left;">Customer Info</th>
                        <!-- <th style="text-align: left;">Mobile Number</th> -->
                        <!-- <th style="text-align: left;">Fuel</th> -->
                        <th style="text-align: left;">Price per ltr</th>
                        <th style="text-align: left;">Quantity in Ltrs</th>
                        <!-- <th style="text-align: left;">Booked Date & Time</th> -->
                        <th style="text-align: left;">Refund Submitted Date & Time</th>
                        <th style="text-align: right;">Paid Amount</th>
                        <th style="text-align: right;">Refund Amount</th>
                        <th style="text-align: left;">Refund ID</th>
                        <th style="text-align: left;">Refund Completed Date & Time</th>
                        <th style="text-align: center;">Status</th>
                        <th style="text-align: center;">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let refund of reportfilter1DetailNoRecord1.response; let i=index">
                        <td align="center" class="v-align-middle ">{{i + 1}}</td>
                        <td *ngIf="refund.orderId!=null"align="left" class="v-align-middle">{{refund.orderId}}</td>
                        <td *ngIf="refund.orderId==null"align="left" class="v-align-middle">N/A</td>
  
                        <td>
                          <span *ngIf="refund.customerId!=null"> {{refund.customerId.firstName}} {{refund.customerId.lastName}}</span>
                          <br/>
  
                          {{refund.customerId.mobileNumber}} 
                        </td>
  
                        <td *ngIf="refund.fuelPrice!=null" align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{refund.fuelPrice | number : '1.2-2'}}</td>
                        <td *ngIf="refund.fuelPrice==null" align="right" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.quantity!=null"align="center" class="v-align-middle">
                          <b>Ordered:</b>{{refund.quantity}}<br/>
                          <b>Delivered:</b>{{refund.orderDeliveredLt}}
                        </td>
                        <td *ngIf="refund.quantity==null"align="center" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.refundSubmittedDateTime!=null"align="left" class="v-align-middle">{{refund.refundSubmittedDateTime | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                        <td *ngIf="refund.refundSubmittedDateTime==null"align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.totalPayableAmount!=null" align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{refund.totalPayableAmount | number : '1.2-2'}}</td>
                        <td *ngIf="refund.totalPayableAmount==null" align="right" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.refundAmount!=null" align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{refund.refundAmount | number : '1.2-2'}}</td>
                        <td *ngIf="refund.refundAmount==null" align="right" class="v-align-middle">N/A</td>
                      
                        <td *ngIf="refund.refundReferenceId!=null"align="left" class="v-align-middle">{{refund.refundReferenceId }}</td>
                        <td *ngIf="refund.refundReferenceId==null"align="left" class="v-align-middle">N/A</td>
                        
                        <td *ngIf="refund.refundDateTime!=null"align="left" class="v-align-middle">{{refund.refundDateTime | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                        <td *ngIf="refund.refundDateTime==null"align="left" class="v-align-middle">N/A</td>
                                            
                        <td *ngIf="refund.refundStatus!=null && refund.refundStatus==20" align="center" class="v-align-middle ht-Pending">Initiated</td>
                        <td *ngIf="refund.refundStatus!=null && refund.refundStatus==21" align="center" class="v-align-middle ht-Success">Completed</td>
                        <td *ngIf="refund.refundStatus==0 || refund.refundStatus==21" align="center" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.refundStatus==20" align="center" class="v-align-middle" style="width:7%;">
                          <button  title="Automatic Refund" class="label label-info" (click)="view1(refund.id)"><i class="fa fa-money" aria-hidden="true"></i></button>
                         &nbsp; <button  title="Manual Refund" class="label label-info" (click)="manualrefund(refund.id,content9)"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
          </div>

          </div>
          <!-- END card --> 
        </div>


      </div>

      <ng-template #content let-modal>
        <div class="modal-header">
          <h6>Refund View Page</h6>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
        </div>
        <hr class="beautiful-line">
        <h5 style="margin-left: 15px; text-align: left; color: grey;">Order Info</h5>
        <div class="container"
        fxLayout
      >
      <div class="item item-1">
      <p><b>Order ID</b></p>
      <span>{{refundSettlementReportModel.response.refund.orderId}}</span>
      </div>
      <div class="item item-2" style="margin-left: 30px;">
        <p><b>Customer Name</b></p>
        <span *ngIf="refundSettlementReportModel.response.refund.customerId!=null" style="word-wrap: break-word;">{{refundSettlementReportModel.response.refund.customerId.firstName}} {{refundSettlementReportModel.response.refund.customerId.lastName}}</span>
            <span *ngIf="refundSettlementReportModel.response.refund.customerId==null">N/A</span>
      </div>
      <div class="item item-3" style="margin-left: 30px;">
        <p><b>Customer Mobile</b></p>
        <span *ngIf="refundSettlementReportModel.response.refund.customerId!=null">{{refundSettlementReportModel.response.refund.customerId.mobileNumber}}</span>
            <span *ngIf="refundSettlementReportModel.response.refund.customerId==null">N/A</span>
      </div>
      <div class="item item-5" style="margin-left: 30px;">
        <p><b>Quantity in Ltrs</b></p>
        <span *ngIf="refundSettlementReportModel.response.refund.quantity!=null">{{refundSettlementReportModel.response.refund.quantity}}</span>
            <span *ngIf="refundSettlementReportModel.response.refund.quantity==null">N/A</span>
      </div>
      <div class="item item-3" style="margin-left: 30px;">
        <p><b>Fuel</b></p>
        <span *ngIf="refundSettlementReportModel.response.refund.fuelType!=null">{{refundSettlementReportModel.response.refund.fuelType}}</span>
            <span *ngIf="refundSettlementReportModel.response.refund.fuelType==null">N/A</span>
      </div>
      <div class="item item-4" style="margin-left: 30px;">
        <p><b>Booking Date & Time</b></p>
        <span *ngIf="refundSettlementReportModel.response.refund.orderDatetime!=null">{{refundSettlementReportModel.response.refund.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span>
            <span *ngIf="refundSettlementReportModel.response.refund.orderDatetime==null">N/A</span>
      </div>
      <div class="item item-9" style="margin-left: 30px;">
        <p><b>Cancelled By</b></p>
        <span *ngIf="refundSettlementReportModel.response.refund.cancelledBy!=null">
          <span style="font-size: 10px;">Name :{{refundSettlementReportModel.response.refund.cancelledBy.firstName}} {{refundSettlementReportModel.response.refund.cancelledBy.lastName}}</span><br>
          <span style="font-size: 10px;">Mobile :{{refundSettlementReportModel.response.refund.cancelledBy.mobileNumber}}</span>
        </span>
        <span *ngIf="refundSettlementReportModel.response.refund.cancelledBy==null">N/A</span>
      </div>
      <div class="item item-9" style="margin-left: 30px;">
        <p><b>Reason for Cancelling</b></p>
        <span *ngIf="refundSettlementReportModel.response.refund.cancellationId!=null" style="word-wrap: break-word;">{{refundSettlementReportModel.response.refund.cancellationId.cancellationMessage}}</span>
        <span *ngIf="refundSettlementReportModel.response.refund.cancellationId==null">N/A</span>
      </div>
      <div class="item item-4" style="margin-left: 30px;">
        <p><b>Cancelled Date & Time</b></p>
        <span *ngIf="refundSettlementReportModel.response.refund.orderCancellationDatetime!=null">{{refundSettlementReportModel.response.refund.orderCancellationDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span>
            <span *ngIf="refundSettlementReportModel.response.refund.orderCancellationDatetime==null">N/A</span>
      </div>
      <div class="item item-4" style="margin-left: 30px;">
        <p><b>Paid Amount</b></p>
        <span *ngIf="refundSettlementReportModel.response.refund.totalPayableAmount!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{refundSettlementReportModel.response.refund.totalPayableAmount | number : '1.2-2'}}</span>
            <span *ngIf="refundSettlementReportModel.response.refund.totalPayableAmount==null">N/A</span>
      </div>
        </div>
        <br>
        <div class="container"
        fxLayout
      >
        <div class="item item-4">
          <p><b>Delivery Agent Info</b></p>
          <span *ngIf="refundSettlementReportModel.response.refund.vehicleId!=null">
            {{refundSettlementReportModel.response.refund.vehicleId.userId.firstName}} {{refundSettlementReportModel.response.refund.vehicleId.userId.lastName}}<br>
            {{refundSettlementReportModel.response.refund.vehicleId.userId.mobileNumber}}
          </span>
              <span *ngIf="refundSettlementReportModel.response.refund.vehicleId==null">N/A</span>
        </div>
        <div class="item item-4" style="margin-left: 30px;">
          <p><b>Vehicle Number</b></p>
          <span *ngIf="refundSettlementReportModel.response.refund.vehicleId!=null">
            {{refundSettlementReportModel.response.refund.vehicleId.vehicleNumber}}
          </span>
              <span *ngIf="refundSettlementReportModel.response.refund.vehicleId==null">N/A</span>
        </div>
        <div class="item item-4" style="margin-left: 30px;">
          <p><b>Delivery Location</b></p>
          <span *ngIf="refundSettlementReportModel.response.refund.deliveryAddress!=null">
            {{refundSettlementReportModel.response.refund.deliveryAddress}}
          </span>
              <span *ngIf="refundSettlementReportModel.response.refund.deliveryAddress==null">N/A</span>
        </div>  
        </div>
        <hr class="beautiful-line">
        <h5 style="margin-left: 15px; text-align: left; color: grey;">Payment Info</h5>
        <div class="container"
        fxLayout
      >
        <div class="item item-4">
          <p><b>Transaction ID</b></p>  
          <span *ngIf="refundSettlementReportModel.response.refund.transactionId!=null">
            {{refundSettlementReportModel.response.refund.transactionId}}
          </span>
              <span *ngIf="refundSettlementReportModel.response.refund.transactionId==null">N/A</span>
        </div>
        <div class="item item-4" style="margin-left: 30px;">
          <p><b>Transaction Date & Time</b></p>
          <span *ngIf="refundSettlementReportModel.response.refund.paymentDatetime!=null">
            {{refundSettlementReportModel.response.refund.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}
          </span>
              <span *ngIf="refundSettlementReportModel.response.refund.paymentDatetime==null">N/A</span>
        </div>
        <div class="item item-4" style="margin-left: 30px;">
          <p><b>Total Fuel Cost</b></p>
          <span *ngIf="refundSettlementReportModel.response.refund.totalfuelcost!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{refundSettlementReportModel.response.refund.totalfuelcost | number : '1.2-2'}}</span>
              <span *ngIf="refundSettlementReportModel.response.refund.totalfuelcost==null">N/A</span>
        </div>
        <div class="item item-4" style="margin-left: 30px;">
          <p><b>Delivery Charge</b></p>
          <span *ngIf="refundSettlementReportModel.response.refund.deliveryCharges!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{refundSettlementReportModel.response.refund.deliveryCharges | number : '1.2-2'}}</span>
              <span *ngIf="refundSettlementReportModel.response.refund.deliveryCharges==null">N/A</span>
        </div>
        <div class="item item-4" style="margin-left: 30px;">
          <p><b>SGST (9%)</b></p>
          <span *ngIf="refundSettlementReportModel.response.refund.sgst!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{refundSettlementReportModel.response.refund.sgst | number : '1.2-2'}}</span>
              <span *ngIf="refundSettlementReportModel.response.refund.sgst==null">N/A</span>
        </div>
        <div class="item item-4" style="margin-left: 30px;">
          <p><b>CGST (9%)</b></p>
          <span *ngIf="refundSettlementReportModel.response.refund.cgst!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{refundSettlementReportModel.response.refund.cgst | number : '1.2-2'}}</span>
              <span *ngIf="refundSettlementReportModel.response.refund.cgst==null">N/A</span>
        </div>
        <div class="item item-4" style="margin-left: 30px;">
          <p><b>Platform Charge</b></p>
          <span *ngIf="refundSettlementReportModel.response.refund.internetHandlingFee!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{refundSettlementReportModel.response.refund.internetHandlingFee | number : '1.2-2'}}</span>
              <span *ngIf="refundSettlementReportModel.response.refund.internetHandlingFee==null">N/A</span>
        </div>
        <div class="item item-4" style="margin-left: 30px;">
          <p><b>Total Paid Amount</b></p>
          <span *ngIf="refundSettlementReportModel.response.refund.totalPayableAmount!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{refundSettlementReportModel.response.refund.totalPayableAmount | number : '1.2-2'}}</span>
              <span *ngIf="refundSettlementReportModel.response.refund.totalPayableAmount==null">N/A</span>
        </div>
        <div class="item item-3" style="margin-left: 30px;">
          <p><b>Payment Status</b></p>
          <p *ngIf="refundSettlementReportModel.response.refund.paymentStatus==='Success'" class="card-title"><span class="ht-Success"><b>{{refundSettlementReportModel.response.refund.paymentStatus}}</b></span></p>
                <p *ngIf="refundSettlementReportModel.response.refund.paymentStatus==='Pending'" class="card-title"><span class="ht-Pending"><b>{{refundSettlementReportModel.response.refund.paymentStatus}}</b></span></p>
                <p *ngIf="refundSettlementReportModel.response.refund.paymentStatus==='Failure'" class="card-title"><span style="color: red;"><b>Failed</b></span></p>
                <p *ngIf="refundSettlementReportModel.response.refund.paymentStatus==='Initiated'" class="card-title"><span class="ht-Pending"><b>Pending</b></span></p>
                <p *ngIf="refundSettlementReportModel.response.refund.paymentStatus==='Cancelled'" class="card-title"><span class="ht-Pending"><b>Cancelled</b></span></p>
        </div>
        </div> 
        <hr class="beautiful-line">
        <h5 style="margin-left: 15px; text-align: left; color: grey;">Refund Info</h5>
        <div *ngIf="refundSettlementReportModel.response.refund.refundStatus==20"class="container"
        fxLayout
      >
      <div class="item item-3">
      <p><b>Transaction ID</b></p>
      <div class="ch-form-group">
      <input type="text" class="form-control" id="transactionid" name="transactionid" placeholder="Transaction ID">
    </div>
      </div>
      <div class="item item-3" style="margin-left: 30px;">
      <p><b>Transaction Date & Time</b></p>
      <div class="ch-form-group">
        <input type="datetime-local" class="form-control" id="date" name="date" [max]="dateCheck" aria-describedby="emailHelp" placeholder="Select Role" required>
      </div>
      </div>
      <div class="item item-3" style="margin-left: 30px;">
        <p><b>Deduction Amount</b></p>
        <div class="ch-form-group">
          <input style="color: black;" type="text" class="form-control" id="amount" name="amount"  disabled [(ngModel)]="totalrefundAmount" (change)="OncClick()" (keypress)="allowNumericDigitsOnlyOnKeyUp($event)" placeholder="Amount">
        </div>
        </div>
        <div class="item item-3" style="margin-left: 30px;">
          <p><b>Refund Amount</b></p>
          <div class="ch-form-group">
            <span *ngIf="refundAmount!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{refundAmount | number : '1.2-2' }}</span>
          </div>
          </div>
          <div class="item item-3" style="margin-left: 30px;">
            <p><b>Cancelled Stage</b></p>
            <div class="ch-form-group">
              <span> {{refundSettlementReportModel.response.refund.refundState}}</span>
            </div>
            </div>
        </div>
        <div *ngIf="refundSettlementReportModel.response.refund.refundStatus==21"class="container"
        fxLayout
      >
      <div class="item item-3">
        <p><b>Transaction ID</b></p>
          <span>
            {{refundSettlementReportModel.response.refund.refundRemarks}}
          </span>
        </div>
      <div class="item item-3" style="margin-left: 30px;">
      <p><b>Refund Status</b></p>
        <span class="ht-active">
          Completed
        </span>
      </div>
      <div class="item item-3" style="margin-left: 30px;">
      <p><b>Refund Deduction Amount</b></p>
      <span><i class="fa fa-inr" aria-hidden="true"></i> {{refundSettlementReportModel.response.refund.refundDeductionAmount | number : '1.2-2'}}</span>
      </div>
      <div class="item item-3" style="margin-left: 30px;">
        <p><b>Refund Amount</b></p>
        <span><i class="fa fa-inr" aria-hidden="true"></i> {{refundSettlementReportModel.response.refund.refundAmount | number : '1.2-2'}}</span>
        </div>
        <div class="item item-3" style="margin-left: 30px;">
          <p><b>Cancelled Stage</b></p>
          <div class="ch-form-group">
            <span> {{refundSettlementReportModel.response.refund.refundState}}</span>
          </div>
          </div>
        <div class="item item-3" style="margin-left: 30px;">
          <p><b>Transaction Date & Time</b></p>
          <span *ngIf="refundSettlementReportModel.response.refund.refundDateTime!=null">{{refundSettlementReportModel.response.refund.refundDateTime | date:'dd/MM/yyyy hh:mm:ss a' }}</span>
          <span *ngIf="refundSettlementReportModel.response.refund.refundDateTime==null">N/A</span>
          </div>
          <div class="item item-3" style="margin-left: 30px;">
          <p><b>Refund Date & Time</b></p>
          <span *ngIf="refundSettlementReportModel.response.refund.refundSubmittedDateTime!=null">{{refundSettlementReportModel.response.refund.refundSubmittedDateTime | date:'dd/MM/yyyy hh:mm:ss a' }}</span>
          <span *ngIf="refundSettlementReportModel.response.refund.refundSubmittedDateTime==null">N/A</span>
          </div>
        </div>
        <br>
        <div *ngIf='errorflag' style="color: red;margin-left: 10px;">{{errorMessage}}</div>
        <div class="modal-footer">
          <button *ngIf="refundSettlementReportModel.response.refund.refundStatus==20" type="button" class="btn btn-outline-dark" (click)="refund(refundSettlementReportModel.response.refund.id)">Submit</button>
          <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
        </div>
      </ng-template>    
      
      
<ng-template #content9 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Refund Status</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
    <form>
      
    
      <div class="form-group">
        <label for="dateOfBirth">Refund ID *</label>
            <input type="text" class="select-form-style" style="margin-left: 10px; " name="refundRefNo" id="refundRefNo" required/>
      </div>
     
      <div class="form-group">
        <label for="dateOfBirth">Refund Date & Time*</label>
        <input type="date" class="select-form-style" id="refundDate" name="refundDate" aria-describedby="emailHelp"  required>
      </div>

      

    </form>
    <br>
    <div *ngIf="poppupalert">
      <div style="color: red;">Mandatory fields are required!</div>
    </div>
  </div>
  <hr class="beautiful-line">
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="updateRefund(refundId)">Submit</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
  </div>
</ng-template>