import { Component, OnInit, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { GenericResponseModel } from '../model/genricresponse';
import { OrderTransactionService } from '../services/ordertran.service';
import { ReportServices } from '../services/reports.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { Workbook } from 'exceljs';
// import { CurrencyPipe} from '@angular/common';

@Component({
  selector: 'app-settlement',
  templateUrl: './settlement.component.html',
  styleUrls: ['./settlement.component.css']
})
export class SettlementComponent {
 public isCollapsed = false;
 active = 1;
 div2:boolean=true;
 divsettlementfilter:boolean=false;
 settlementDetail:GenericResponseModel;
 settlementFilterDetail:GenericResponseModel;
 settlementViewDetail:GenericResponseModel;
 excelexportchecksettlement:boolean = false;
 fileName:string;
 errorFlag:boolean;
  errorMessage:String;
  refreshflag:boolean;
  responseDataListnew = [];
  responseDataListnewfilter = [];
  responseDataList = [];
closeResult = '';
cgst:Number;
sgst:Number;
totalCost:Number;
toalfuelcost:Number;
settlementInfoModel:GenericResponseModel;

  constructor(private modalService: NgbModal,private ordertransactionservice: OrderTransactionService,private route: ActivatedRoute,private router: Router,private reposrtservice:ReportServices,private spinnerService:NgxSpinnerService) {}

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnInit(): void {
    let formData= {
      startDate:null,
      endDate:null,
    }
  this.reposrtservice.settlementreport(formData).subscribe(data=>{
    this.settlementDetail = data;
    if(data.statusCode==200){
      this.excelexportchecksettlement = true;
    }else{
      this.excelexportchecksettlement = false;
    }
    this.spinnerService.hide();
    },err => {
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }else if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   }); 
  }    

  refresh(){
    window.location.reload();
  }

  searchsettlement(){
    this.spinnerService.show();
    this.refreshflag = true;
    let fromdate = (<HTMLInputElement>document.getElementById('fromdate')).value;
    let todate = (<HTMLInputElement>document.getElementById('todate')).value;
    if(todate < fromdate){
      this.errorFlag = true;
      this.errorMessage = "End Date should be greater than Start Date!";
      this.spinnerService.hide();
    }else if(fromdate!=null && fromdate!=='' && todate!=null && todate!==''){
      this.errorFlag = false;
      let formData= {
        startDate:fromdate,
        endDate:todate,
      }
      this.reposrtservice.settlementreport(formData).subscribe(data=>{
        if(data.statusCode==200){
          this.excelexportchecksettlement = true;
        }else{
          this.excelexportchecksettlement = false;
        }
      this.settlementFilterDetail = data;
      this.divsettlementfilter = true;
      this.div2 = false;
      this.refreshflag = true;
      this.fileName = 'Settlement Report.xlsx';
      });
      this.spinnerService.hide();
    }else{
      this.errorFlag = true;
      this.errorMessage = "Please select Dates!";
      this.spinnerService.hide();
    }
  }

  exportToExcelsettlement(){
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    let sno = 1;
    this.settlementDetail.response.forEach(element => {
      let vehicle = element['vehicleId'];
      let partner = vehicle['userId'];
      let customer = element['customerId'];
      let dates = new Date(element['paymentDatetime'])
      let convertedDate = moment(new Date(dates)).format("yyyy-MM-DD hh:mm:ss a").toString();
      var response = [];
    response.push(sno);
    response.push(element['orderId']);
    response.push(customer['firstName'] + customer['lastName']);
    response.push(customer['mobileNumber']);
    response.push(partner['firstName'] + partner['lastName']);
    response.push(partner['mobileNumber']);
    response.push(element['paymentId']);
    response.push(element['totalPayableAmount']);
    response.push(element['paymentStatus']);
    response.push(element['bankAccountHolderName']);
    response.push(element['accountNumber']);
    response.push(element['ifsc']);
    response.push(convertedDate);
    this.responseDataListnew.push(response);
    sno++;
      var  responseData =
        {
          "Order ID": element['orderId'],
          "Customer Name": customer['firstName'] + customer['lastName'],
          "Customer Mobile": customer['mobileNumber'],
          "Partner Name": partner['firstName'] + partner['lastName'],
          "Partner Mobile": partner['mobileNumber'],
          "Transaction ID":element['paymentId'],
          "Total Paid Amount":element['totalPayableAmount'],
          "Status":element['paymentStatus'],
          "Account Holder":element['bankAccountHolderName'],
          "Account Number":element['accountNumber'],
          "IFSC Code":element['ifsc'],
          "Transaction Date & Time" :convertedDate
        };
        this.responseDataList.push(responseData);
    });
    this.excelexportSettlement();
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.responseDataList);
    const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], {
      type: EXCEL_TYPE
    });
    // FileSaver.saveAs(data, 'Settlement Report' + '_exported'+ EXCEL_EXTENSION);
   }

   exportToExcelFiltersettlement(){
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    let sno = 1;
    this.settlementFilterDetail.response.forEach(element => {
      let vehicle = element['vehicleId'];
      let partner = vehicle['userId'];
      let customer = element['customerId'];
      let dates = new Date(element['paymentDatetime'])
    let convertedDate = moment(new Date(dates)).format("yyyy-MM-DD hh:mm:ss a").toString();
    var response = [];
    response.push(sno);
    response.push(element['orderId']);
    response.push(customer['firstName'] + customer['lastName']);
    response.push(customer['mobileNumber']);
    response.push(partner['firstName'] + partner['lastName']);
    response.push(partner['mobileNumber']);
    response.push(element['paymentId']);
    response.push(element['totalPayableAmount']);
    response.push(element['paymentStatus']);
    response.push(element['bankAccountHolderName']);
    response.push(element['accountNumber']);
    response.push(element['ifsc']);
    response.push(convertedDate);
    this.responseDataListnewfilter.push(response);
    sno++;
      var  responseData =
        {
          "Order ID": element['orderId'],
          "Customer Name": customer['firstName'] + customer['lastName'],
          "Customer Mobile": customer['mobileNumber'],
          "Partner Name": partner['firstName'] + partner['lastName'],
          "Partner Mobile": partner['mobileNumber'],
          "Transaction ID":element['paymentId'],
          "Total Paid Amount":element['totalPayableAmount'],
          "Status":element['paymentStatus'],
          "Account Holder":element['bankAccountHolderName'],
          "Account Number":element['accountNumber'],
          "IFSC Code":element['ifsc'],
          "Transaction Date & Time" :convertedDate
        };
        this.responseDataList.push(responseData);
    });
    this.excelexportSettlement();
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.responseDataList);
    const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], {
      type: EXCEL_TYPE
    });
    // FileSaver.saveAs(data, 'Settlement Report' + '_exported'+ EXCEL_EXTENSION);
   }

   excelexportSettlement(){
    const title = 'Settlement Report';
    const header = ["S.No","Order ID", "Customer Name", "Customer Mobile", "Partner Name", "Partner Mobile", "Transaction ID","Total Paid Amount","Status","Account Holder","Account Number","IFSC Code","Transaction Date & Time"]
    let data= [];
    if(this.divsettlementfilter){
      data = this.responseDataListnewfilter;
    }else if(this.div2){
      data = this.responseDataListnew;
    }
    
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Settlement Report');
// Add new row
let titleRow = worksheet.addRow([title]);
// Set font, size and style in title row.
titleRow.font = { name: 'Times New Roman', family: 4, size: 16, bold: true };

// Blank Row
worksheet.addRow([]);

//Add row with current date
// let subTitleRow = worksheet.addRow(['Date : ' + this.datePipe.transform(new Date(), 'medium')]);
//Add Header Row
let headerRow = worksheet.addRow(header);
headerRow.font = {bold: true };
// Cell Style : Fill and Border
headerRow.eachCell((cell, number) => {
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFFFF' },
    bgColor: { argb: 'FF0000FF' },
  }
  cell.border = {  top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
});

data.forEach(d => {
  let row = worksheet.addRow(d);
  let qty = row.getCell(1);
  let qty1 = row.getCell(2);
  let qty2 = row.getCell(3);
  let qty3 = row.getCell(4);
  let qty4 = row.getCell(5);
  let qty5 = row.getCell(6);
  let qty6 = row.getCell(7);
  let qty7 = row.getCell(8);
  let qty8 = row.getCell(9);
  let qty9 = row.getCell(10);
  let qty10 = row.getCell(11);
  let qty11= row.getCell(12);
  let qty12 = row.getCell(13);
  qty.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty1.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty2.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty3.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty4.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty5.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty6.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty8.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty9.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty10.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty11.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty12.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
}
);
workbook.xlsx.writeBuffer().then((data) => {
  let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  FileSaver.saveAs(blob, 'Settlement Report.xlsx');
});
   }
   view(content1,id){
    this.reposrtservice.orderinfo(id).subscribe(data=>{
      this.settlementInfoModel = data;
    });
    this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
}
