
<app-header></app-header>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
</ngx-spinner> 
<!-- START PAGE-CONTAINER -->

<div class="page-container "> 
  <!-- START PAGE CONTENT WRAPPER -->
  <div class="page-content-wrapper "> 
    <!-- START PAGE CONTENT -->
    <div class="content sm-gutter"> 
      <!-- START BREADCRUMBS -->
      <div class="bg-white">
        <div class="container">
          <ol class="breadcrumb breadcrumb-alt">
            <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Home</a></li>
            <li class="breadcrumb-item active">ADD USER</li>
            <div class="btn-section-wrapper">
              <button routerLink="/add-user" type="button"
              class="label label-info" style="margin-right: 16px; margin-top: -5px;"><i class="fa fa-plus" aria-hidden="true"></i>
          Add User</button>
          <button *ngIf="excelexportflag" title="Excel Export" title="Excel Export" (click)="excelexport()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
        </div>
            <!-- <li class="back_page"><button class="label label-info" routerLink="/add-user" ><i class="fa fa-plus" aria-hidden="true"></i>Add user</button></li> -->
          </ol>
        </div>
      </div>
      <!-- END BREADCRUMBS -->
      <!-- <div class="alert alert-success box-msg" role="alert"
            *ngIf="successflag">{{message}}</div> -->
      <div class="container Customers-top-notification">
        <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 420px; margin-left: 5px;">
          <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                  <div _ngcontent-tgd-c17="" class="card-title"><span _ngcontent-tgd-c17="" class="font-montserrat fs-11 all-caps">Total No. of Users<i _ngcontent-tgd-c17="" class="fa fa-chevron-right"></i></span></div>
                  <div _ngcontent-tgd-c17="" class="card-controls">
                    <ul _ngcontent-tgd-c17="">
                      <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                  <h3 *ngIf="userDetails.statusCode==200"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{userDetails.response.totalUsers}}</h3>
                  <h3 *ngIf="userDetails.statusCode!=200"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                  <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% </span> -->
                </div>
              </div>
            </div>
            <!-- <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-bottom">
                <div _ngcontent-tgd-c17="" class="progress progress-small m-b-0">
                  <div _ngcontent-tgd-c17="" class="progress-bar progress-bar-primary" style="width: 71%;"></div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 430px; margin-left: 20px;">
          <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                  <div _ngcontent-tgd-c17="" class="card-title"><span _ngcontent-tgd-c17="" class="font-montserrat fs-11 all-caps">Total No. of Active Users<i _ngcontent-tgd-c17="" class="fa fa-chevron-right"></i></span></div>
                  <div _ngcontent-tgd-c17="" class="card-controls">
                    <ul _ngcontent-tgd-c17="">
                      <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                  <h3 *ngIf="userDetails.statusCode==200" _ngcontent-tgd-c17="" class="no-margin p-b-5">{{userDetails.response.verfiedUsers}}</h3>
                  <h3 *ngIf="userDetails.statusCode!=200" _ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                  <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71%</span> -->
                </div>
              </div>
            </div>
            <!-- <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-bottom">
                <div _ngcontent-tgd-c17="" class="progress progress-small m-b-0">
                  <div _ngcontent-tgd-c17="" class="progress-bar progress-bar-primary" style="width: 71%;"></div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 440px; margin-left: 45px;">
          <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                  <div _ngcontent-tgd-c17="" class="card-title"><span _ngcontent-tgd-c17="" class="font-montserrat fs-11 all-caps">Total No. of InActive Users<i _ngcontent-tgd-c17="" class="fa fa-chevron-right"></i></span></div>
                  <div _ngcontent-tgd-c17="" class="card-controls">
                    <ul _ngcontent-tgd-c17="">
                      <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                  <h3 *ngIf="userDetails.statusCode==200"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{userDetails.response.notVerifyUsers}}</h3>
                  <h3 *ngIf="userDetails.statusCode!=200"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                  <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                </div>
              </div>
            </div>
            <!-- <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-bottom">
                <div _ngcontent-tgd-c17="" class="progress progress-small m-b-0">
                  <div _ngcontent-tgd-c17="" class="progress-bar progress-bar-primary" style="width: 71%;"></div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
       
        
      </div>
       <!-- <div class="container search-section">

        <div class="Customers-search-section">
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Search By Status
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="#">Search By Status</a>
			<a class="dropdown-item" href="#">Search By Name</a>
              <a class="dropdown-item" href="#">Search By Mobile</a>
              <a class="dropdown-item" href="#">Search By Email</a>
              <a class="dropdown-item" href="#">Account Status</a>
              <a class="dropdown-item" href="#">Role</a>
            </div>
          </div>
        <div class="form-group">
           <input type="email" class="form-control" placeholder="Search By Status" required="">
         </div>
<div class="search-btn-wrraper">
         <button class="btn btn-primary btn-cons">Search</button></div>
       </div>
         </div> -->
      
      
      <!-- START CONTAINER FLUID --> 
      
      <!-- START CONTAINER FLUID -->
      <div class=" no-padding    container-fixed-lg bg-white">
        <div class="container"> 
          <!-- START card -->
          <div class="card card-transparent">
            <div class="card-body">
              <!-- <div class="btn-section-wrapper">
                <button routerLink="/add-user" type="button"
                class="btn btn-success" style="margin-top: -10px; margin-right: 16px;"><i class="fa fa-plus" aria-hidden="true"></i>
            Add User</button>
          </div> -->
              <div class="table-responsive" style="margin-top: 30px;"> 
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;" >Sl No</th>
                      <th style="text-align: left;" >User ID</th>
                      <th style="text-align: left;" >Role</th>
                      <th style="text-align: left;" >Employee Name </th>
                      <th style="text-align: left;" >Mobile No</th>
                      <th style="text-align: left;" >Email ID</th>
                      <th style="text-align: center;" >Status</th>
                      <th style="text-align: left;" >Created Date Time </th>
                      <th style="text-align: center;" >Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let users of userDetails.response.users; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="users.userCode!=null"align="left" class="v-align-middle" >{{users.userCode}}</td>
                      <td *ngIf="users.userCode==null"align="left" class="v-align-middle" >N/A</td>
                      <td align="left" class="v-align-middle">{{users.roleName}}</td>
                      <td align="left" class="v-align-middle">{{users.firstName}} {{users.lastName}}</td>
                      <td align="left" class="v-align-middle">{{users.mobileNumber}}</td>
                      <td align="left" class="v-align-middle">{{users.emailAddress}}</td>
                      <td align="center" *ngIf="users.accountStatus===1" class="v-align-middle ht-Success">Active</td>
                      <td align="center" *ngIf="users.accountStatus===2" class="v-align-middle ht-Pending">InActive</td>
                      <td align="left" class="v-align-middle">{{users.createdDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td align="center" class="v-align-middle">
                        <button title="view" class="label label-info" (click)="view(content,users.userId)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                        <button title="edit"class="label label-success" (click)="edit(users.userId)"><i class="fa fa-pencil-square" aria-hidden="true"></i></button>&nbsp;
                        <button title="delete"class="label label-danger" (click)="open(reject,users.userId)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                        <button title="inactive" *ngIf="users.accountStatus===2" class="label label-warning" (click)="callActiveOrInactive(users.userId,1)"><i class="fa fa-thumbs-down" aria-hidden="true"></i></button>&nbsp;
                        <button title="active"*ngIf="users.accountStatus===1" class="label label-warning" (click)="callActiveOrInactive(users.userId,2)"><i class="fa fa-thumbs-o-up" aria-hidden="true"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- END card --> 
        </div>
      </div>
      <!-- END CONTAINER FLUID --> 
      
    </div>
    <!-- END PAGE CONTENT --> 
    <!-- START COPYRIGHT --> 
    <!-- START CONTAINER FLUID --> 
    <!-- START CONTAINER FLUID -->
  <!--   <div class=" container   container-fixed-lg footer">
      <div class="copyright sm-text-center">
        <p class="small-text no-margin pull-left sm-pull-reset"> ©2014-2020 All Rights Reserved. Pages® and/or its subsidiaries or affiliates are registered trademark of Revox Ltd. <span class="hint-text m-l-15">Pages v05.23 20201105.r.190</span> </p>
        <p class="small no-margin pull-right sm-pull-reset"> Hand-crafted <span class="hint-text">&amp; made with Love</span> </p>
        <div class="clearfix"></div>
      </div>
    </div> -->
    <!-- END COPYRIGHT --> 
  </div>
  <!-- END PAGE CONTENT WRAPPER --> 
</div>
<!-- END PAGE CONTAINER --> 
<!--START QUICKVIEW -->
<!-- END QUICKVIEW--> 
<!-- START OVERLAY -->
<ng-template  #content let-modal>
  <div class="modal-header">
    <h6>View User Details</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="container"
  fxLayout
>
<div class="item item-1">
  <p class="card-title"><b>Employee Name</b><br>
    <span>{{userModel.firstName}} {{userModel.lastName}}</span>
  </p>
</div>
<div class="item item-2" style="margin-left: 40px;">
  <p class="card-title"><b>Mobile Number</b><br>
    <span>{{userModel.mobileNumber}}</span>
  </p>
</div>
<div class="item item-3" style="margin-left: 40px;">
  <p class="card-title"><b>Email Address</b><br>
    <span>{{userModel.emailAddress}}</span>
  </p>
</div>
<div class="item item-4" style="margin-left: 40px;">
  <p class="card-title"><b>Role</b><br>
    <span *ngIf="userModel.roleName!=null">  {{userModel.roleName}}</span>
    <span *ngIf="userModel.roleName==null">  N/A</span>
  </p>
</div>
<div class="item item-5" style="margin-left: 40px;">
  <p class="card-title"><b>Account Status</b></p>
    <div *ngIf="userModel.accountStatus===1" style="margin-top: -10px;">
      <div class="form-check no-pad-left">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" (click)="callActiveOrInactive(userModel.userId,1)" (click)="modal.close('Save click')" value="option1" checked>
        <label class="form-check-label" for="exampleRadios1">
        Active
        </label>
     </div>
     <div class="form-check no-pad-left">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" (click)="callActiveOrInactive(userModel.userId,2)" (click)="modal.close('Save click')" value="option2">
        <label class="form-check-label" for="exampleRadios2">
        Inactive
        </label>
     </div>
     </div>
     <div *ngIf="userModel.accountStatus===2" style="margin-top: -10px;">
      <div class="form-check no-pad-left">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" (click)="callActiveOrInactive(userModel.userId,1)" (click)="modal.close('Save click')" value="option1">
        <label class="form-check-label" for="exampleRadios1">
        Active
        </label>
     </div>
     <div class="form-check no-pad-left">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" (click)="callActiveOrInactive(userModel.userId,2)" (click)="modal.close('Save click')" value="option2" checked>
        <label class="form-check-label" for="exampleRadios2">
        Inactive
        </label>
     </div>
     </div>  
  
</div>
<div class="item item-6" style="margin-left: 40px;">
  <p class="card-title"><b>Date Of Joining</b><br>
    <span *ngIf="userModel.dateOfJoining!=null">  {{userModel.dateOfJoining | date:'dd/MM/yyyy'}}</span>
        <span *ngIf="userModel.dateOfJoining==null">  N/A</span>
  </p>
</div>
<div class="item item-6" style="margin-left: 40px;">
  <p class="card-title"><b>Last Logged In</b><br>
    <span *ngIf="userModel.lastLoggedOn!=null">  {{userModel.lastLoggedOn | date:'dd/MM/yyyy hh:mm:ss a'}}</span>
        <span *ngIf="userModel.lastLoggedOn==null">  N/A</span>
  </p>
</div>
</div>
  <!-- <div class="modal-body">
    <div class="wrapper">
    	<div class="flex extradiv">
    		<p class="card-title"><span><b>Employee Name</b></span></p>
    		<p class="card-title"><span><b>Mobile Number</b></span></p>
    		<p class="card-title"><span><b>Email Address</b></span></p>
    		<p class="card-title"><span><b>Role</b></span></p>
        <p class="card-title"><span><b>Account Status</b></span></p>
        <br>
        <br>
        <p class="card-title"><span><b>Last Logged In</b></span></p>
    	</div>
    	<div class="flex extradiv">
    		<p>  {{userModel.firstName}}</p>
        <p>  {{userModel.mobileNumber}}</p>
        <p>  {{userModel.emailAddress}}</p>
        <p *ngIf="userModel.roleName!=null">  {{userModel.roleName}}</p>
        <p *ngIf="userModel.roleName==null">  N/A</p>
          <div *ngIf="userModel.accountStatus===1">
            <div class="form-check no-pad-left">
              <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" (click)="callActiveOrInactive(userModel.userId,1)" (click)="modal.close('Save click')" value="option1" checked>
              <label class="form-check-label" for="exampleRadios1">
              Active
              </label>
           </div>
           <div class="form-check no-pad-left">
              <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" (click)="callActiveOrInactive(userModel.userId,2)" (click)="modal.close('Save click')" value="option2">
              <label class="form-check-label" for="exampleRadios2">
              Inactive
              </label>
           </div>
           </div>
           <div *ngIf="userModel.accountStatus===2">
            <div class="form-check no-pad-left">
              <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" (click)="callActiveOrInactive(userModel.userId,1)" (click)="modal.close('Save click')" value="option1">
              <label class="form-check-label" for="exampleRadios1">
              Active
              </label>
           </div>
           <div class="form-check no-pad-left">
              <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" (click)="callActiveOrInactive(userModel.userId,2)" (click)="modal.close('Save click')" value="option2" checked>
              <label class="form-check-label" for="exampleRadios2">
              Inactive
              </label>
           </div>
           </div>  
        <p *ngIf="userModel.lastLoggedOn!=null">  {{userModel.lastLoggedOn | date:'dd/MM/yyyy hh:mm:ss a'}}</p>
        <p *ngIf="userModel.lastLoggedOn==null">  N/A</p>
    	</div>
    </div>
  </div> -->
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
  </div>
</ng-template>

<ng-template #reject let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Do you want delete the user?</label>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="delete()" (click)="modal.close('Save click')">yes</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">no</button>
  </div>
</ng-template>
