import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { BankMasterModel } from '../model/bankmaster';
import { GenericResponseModel } from '../model/genricresponse';
import { BankMasterService } from '../services/bank.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router } from '@angular/router';

@Component({
  selector: 'app-master-bank',
  templateUrl: './master-bank.component.html',
  styleUrls: ['./master-bank.component.css']
})
export class MasterBankComponent {
 public isCollapsed = false;
 active = 1;

 bankmaster : GenericResponseModel;
 bankMasterModel:BankMasterModel;
  closeResult = '';
  errorFlag:boolean;
  errorFlag1:boolean;
  errorMessage:String;
  deleteUserId:Number;
  constructor(private modalService: NgbModal,private bankmasterservice:BankMasterService,private spinnerService:NgxSpinnerService,private router:Router) {}

  ngOnInit(): void {
    this.bankmasterservice.getBankMaster().subscribe(data=>{
    this.bankmaster = data;
    },err => {
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }else if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open1(content1,id) {
    this.bankmasterservice.getBankMasterById(id).subscribe(data=>{
      this.bankMasterModel=data;
    })
    this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open2(reject,viewId){
    this.deleteUserId = viewId;
    this.modalService.open(reject, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  addBank(){
    let bankName = (<HTMLInputElement>document.getElementById('bankName')).value;
    let bankCode = (<HTMLInputElement>document.getElementById('bankCode')).value;
    let bankStatus = (<HTMLInputElement>document.getElementById('bankStatus')).value;
   
    if(bankName!=null && bankCode!=null && bankStatus!=null && bankName!=="" && bankCode!=="" && bankStatus!==""){
      let formData= {
        bankName:bankName,
        bankCode:bankCode,
        bankStatus:Number(bankStatus),
        modifiedBy:sessionStorage.getItem("username")
      }
      this.bankmasterservice.addBankMaster(formData).subscribe(data=>{
        if(data.flag==1){
          this.modalService.dismissAll();
          Swal.fire({
            html: '<pre>' + data.message + '</pre>',
            customClass: {
              popup: 'format-pre'
            }
          }).then((value) => {
            window.location.reload();
          });; ; 
        }else{
          this.errorFlag= true;
      this.errorMessage = data.message;
      setTimeout(function() {
        this.errorFlag = false;
    }.bind(this), 3000);
        }
      },err => {
        if(err.error.statusCode==406){
          this.router.navigate(['/login']);
        }else if(err.error.statusCode==500){
          this.router.navigate(['/page-error']);
        }else if(err.error.statusCode==404){
          this.router.navigate(['/pagenotfound']);
        }
     });
    }else{
      this.errorFlag= true;
      this.errorMessage = "Please enter mandatory fields!";
      this.spinnerService.hide();
      setTimeout(function() {
        this.errorFlag = false;
    }.bind(this), 3000);
    }
  }

  delete(){
    let userId = this.deleteUserId;
    this.bankmasterservice.deleteBankMaster(userId).subscribe(data=>{
      if(data.statusCode==200){
        Swal.fire({
          html: '<pre>' + data.message + '</pre>',
          customClass: {
            popup: 'format-pre'
          }
        }).then((value) => {
          window.location.reload();
        });; ;
      }
    });
  }

  updateBank(){

      if(this.bankMasterModel.bankName!=null && this.bankMasterModel.bankName!=="" && this.bankMasterModel.bankCode!=null && this.bankMasterModel.bankCode!=="" && this.bankMasterModel.bankStatus!=null){
        let formData= {
          id:this.bankMasterModel.id,
          bankName:this.bankMasterModel.bankName,
          bankCode:this.bankMasterModel.bankCode,
          bankStatus:this.bankMasterModel.bankStatus,
          modifiedBy:sessionStorage.getItem("username")
        }
        this.bankmasterservice.updateBankMaster(formData).subscribe(data=>{
          if(data.flag==1){
            this.modalService.dismissAll();
            Swal.fire({
              html: '<pre>' + data.message + '</pre>',
              customClass: {
                popup: 'format-pre'
              }
            }).then((value) => {
              window.location.reload();
            });; ; 
          }else{
            this.errorFlag1= true;
        this.errorMessage = data.message;
        setTimeout(function() {
          this.errorFlag1 = false;
      }.bind(this), 3000);
          }
        },err => {
          if(err.error.statusCode==406){
            this.router.navigate(['/login']);
          }else if(err.error.statusCode==500){
            this.router.navigate(['/page-error']);
          }else if(err.error.statusCode==404){
            this.router.navigate(['/pagenotfound']);
          }
       });
      }else{
        this.errorFlag1= true;
        this.errorMessage = "Please Enter Mandatory Fileds!";
        setTimeout(function() {
          this.errorFlag1 = false;
      }.bind(this), 3000);
      }
  }
}