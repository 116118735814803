<app-header></app-header>

<!-- START PAGE-CONTAINER -->
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
  </ngx-spinner> 
<div class="page-container "> 
  <!-- START PAGE CONTENT WRAPPER -->
  <div class="page-content-wrapper "> 
    <!-- START PAGE CONTENT -->
    <div class="content sm-gutter"> 
      <!-- START BREADCRUMBS -->
      <div class="bg-white">
        <div class="container">
          <ol class="breadcrumb breadcrumb-alt">
            <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Home</a></li>
            <li class="breadcrumb-item active"><span class="font-montserrat fs-11 all-caps">Partner Monthly Invoice</span></li>
          </ol>
        </div>
      </div>

      <!-- START CONTAINER FLUID -->
      <div class=" no-padding    container-fixed-lg bg-white">
          <div class="card card-transparent">
            <div>
          <label >Search By Month & Year</label>  
          <select class="select-form-style" id="year"  name="year" [(ngModel)]="currentYear" style="margin-left: 10px; margin-top: 5px;">
            <option *ngFor="let year of yearsList" value={{year}}>{{year}}</option>
        </select>  
        <select class="select-form-style" id="month"  name="month" [(ngModel)]="currentMonth" style="margin-left: 10px; margin-top: 5px;">
          <option *ngFor="let month of monthsList" value={{month}}>{{month}}</option>
      </select> 
        <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
        <button title="Refresh" *ngIf="refreshFlag" (click)="refresh()"style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button> 
      </div> 
            <div class="btn-section-wrapper">
              <button  *ngIf="excelexportcheck" (click)="exportToExcel()" title="Excel Export" style="margin-left: 10px; margin-top: 5px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
            </div>
            <div *ngIf="!filterrefund" class="card-body">
                <div style="display: none;">{{refundreportModel.responseMessage}}</div>
              <div class="table-responsive" style="margin-top: 20px;">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Partner Info</th>
                      <th style="text-align: center;">T.No.of Orders Delivered</th>
                      <th style="text-align: center;">T.Qty Delivered</th>
                      <th style="text-align: center;">T.No.of Kms Delivered</th>
                      <th style="text-align: center;">T.No.of Orders Cancelled</th>
                      <th style="text-align: center;">Invoice</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let refund of refundreportModel.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="refund.vehicleId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 12;">{{refund.vehicleId.userId.firstName}} {{refund.vehicleId.userId.lastName}}</span><br>
                        <span style="font-size: 12;">{{refund.vehicleId.userId.mobileNumber}} </span><br>
                        <span style="font-size: 12;">{{refund.vehicleId.userId.emailAddress}} </span>
                      </td>
                      <td *ngIf="refund.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="refund.totalNumberOfOrderDelivered!=null"align="center" class="v-align-middle">{{refund.totalNumberOfOrderDelivered}}</td>
                      <td *ngIf="refund.totalNumberOfOrderDelivered==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="refund.totalNumberOfQuantityDelivered!=null"align="center" class="v-align-middle">{{refund.totalNumberOfQuantityDelivered}} Ltrs</td>
                      <td *ngIf="refund.totalNumberOfQuantityDelivered==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="refund.totalNumberOfKilometerTravelled!=null"align="center" class="v-align-middle">{{refund.totalNumberOfKilometerTravelled | number : '1.2-2' }}</td>
                      <td *ngIf="refund.totalNumberOfKilometerTravelled==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="refund.totalNumberOfOrderCancelled!=null"align="center" class="v-align-middle">{{refund.totalNumberOfOrderCancelled}}</td>
                      <td *ngIf="refund.totalNumberOfOrderCancelled==null"align="center" class="v-align-middle">N/A</td>
                      <td align="center" class="v-align-middle">
                        <button title="View Invoice" style="margin-left: 5px;" (click)="invoice(refund.year,refund.month,refund.vehicleId.userId.userId)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div *ngIf="filterrefund" class="card-body">
              <div *ngIf="filteronesearch">
                <div style="display: none;">{{filterrefundreportModel.responseMessage}}</div>
                <div class="table-responsive" style="margin-top: 20px;">
                  <table datatable class="ch-table">
                    <thead>
                      <tr> 
                        <th style="text-align: center;">Sl.No.</th>
                        <th style="text-align: left;">Partner Info</th>
                        <th style="text-align: center;">T.No.of Orders Delivered</th>
                        <th style="text-align: center;">T.Qty Delivered</th>
                        <th style="text-align: center;">T.No.of Kms Delivered</th>
                        <th style="text-align: center;">T.No.of Orders Cancelled</th>
                        <th style="text-align: center;">Invoice</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let refund of filterrefundreportModel.response; let i=index">
                        <td align="center" class="v-align-middle ">{{i + 1}}</td>
                        <td *ngIf="refund.vehicleId!=null"align="left" class="v-align-middle">
                          <span style="font-size: 12;">{{refund.vehicleId.userId.firstName}} {{refund.vehicleId.userId.lastName}}</span><br>
                          <span style="font-size: 12;">{{refund.vehicleId.userId.mobileNumber}} </span><br>
                          <span style="font-size: 12;">{{refund.vehicleId.userId.emailAddress}} </span>
                        </td>
                        <td *ngIf="refund.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.totalNumberOfOrderDelivered!=null"align="center" class="v-align-middle">{{refund.totalNumberOfOrderDelivered}}</td>
                        <td *ngIf="refund.totalNumberOfOrderDelivered==null"align="center" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.totalNumberOfQuantityDelivered!=null"align="center" class="v-align-middle">{{refund.totalNumberOfQuantityDelivered}} Ltrs</td>
                        <td *ngIf="refund.totalNumberOfQuantityDelivered==null"align="center" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.totalNumberOfKilometerTravelled!=null"align="center" class="v-align-middle">{{refund.totalNumberOfKilometerTravelled | number : '1.2-2' }}</td>
                        <td *ngIf="refund.totalNumberOfKilometerTravelled==null"align="center" class="v-align-middle">N/A</td>
                        <td *ngIf="refund.totalNumberOfOrderCancelled!=null"align="center" class="v-align-middle">{{refund.totalNumberOfOrderCancelled}}</td>
                        <td *ngIf="refund.totalNumberOfOrderCancelled==null"align="center" class="v-align-middle">N/A</td>
                      <td align="center" class="v-align-middle">
                        <button title="View Invoice" style="margin-left: 5px;" (click)="invoice(refund.year,refund.month,refund.vehicleId.userId.userId)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
                      </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div *ngIf="!filteronesearch">
                <div style="display: none;">{{reportfilter1DetailNoRecord.responseMessage}}</div>
                <div class="table-responsive" style="margin-top: 20px;">
                  <table datatable class="ch-table">
                    <thead>
                      <tr> 
                        <th style="text-align: center;">Sl.No.</th>
                        <th style="text-align: left;">Partner Info</th>
                        <th style="text-align: center;">T.No.of Orders Delivered</th>
                        <th style="text-align: center;">T.Qty Delivered</th>
                        <th style="text-align: center;">T.No.of Kms Delivered</th>
                        <th style="text-align: center;">T.No.of Orders Cancelled</th>
                        <th style="text-align: center;">Invoice</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let refund of reportfilter1DetailNoRecord.response; let i=index">
                        <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="refund.vehicleId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 12;">{{refund.vehicleId.userId.firstName}} {{refund.vehicleId.userId.lastName}}</span><br>
                        <span style="font-size: 12;">{{refund.vehicleId.userId.mobileNumber}} </span><br>
                        <span style="font-size: 12;">{{refund.vehicleId.userId.emailAddress}} </span><br>
                      </td>
                      <td *ngIf="refund.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="refund.totalNumberOfOrderDelivered!=null"align="center" class="v-align-middle">{{refund.totalNumberOfOrderDelivered}}</td>
                      <td *ngIf="refund.totalNumberOfOrderDelivered==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="refund.totalNumberOfQuantityDelivered!=null"align="center" class="v-align-middle">{{refund.totalNumberOfQuantityDelivered}} Ltrs</td>
                      <td *ngIf="refund.totalNumberOfQuantityDelivered==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="refund.totalNumberOfKilometerTravelled!=null"align="center" class="v-align-middle">{{refund.totalNumberOfKilometerTravelled | number : '1.2-2' }}</td>
                      <td *ngIf="refund.totalNumberOfKilometerTravelled==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="refund.totalNumberOfOrderCancelled!=null"align="center" class="v-align-middle">{{refund.totalNumberOfOrderCancelled}}</td>
                      <td *ngIf="refund.totalNumberOfOrderCancelled==null"align="center" class="v-align-middle">N/A</td>
                      <td align="center" class="v-align-middle">
                        <button title="View Invoice" style="margin-left: 5px;" (click)="invoice(refund.year,refund.month,refund.vehicleId.userId.userId)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
                      </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
          </div>
          </div>
      </div>
