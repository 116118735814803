
<app-header></app-header>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
</ngx-spinner>
<!-- START PAGE-CONTAINER -->

<div class="page-container "> 
  <!-- START PAGE CONTENT WRAPPER -->
  <div class="page-content-wrapper "> 
    <!-- START PAGE CONTENT -->
    <div class="content sm-gutter"> 
      <!-- START BREADCRUMBS -->
      <div class="bg-white">
        <div class="container">
          <ol class="breadcrumb breadcrumb-alt">
            <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Home</a></li>
            <li class="breadcrumb-item active">ACTIVATE VEHICLE</li>
          </ol>
        </div>
      </div>
      <!-- END BREADCRUMBS -->
      <!-- <div class="alert alert-danger" role="alert"
 *ngIf="failureflag">{{message}}</div> -->
 <!-- <div class="alert alert-success box-msg" role="alert"
            *ngIf="successflag">{{message}}</div> -->
            <div class="container Customers-top-notification card4view">
              <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: 5px;">
                <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                        <div _ngcontent-tgd-c17="" class="card-title1"><span _ngcontent-tgd-c17="" class="font-montserrat fs-11 all-caps">Total No. of Vehicles</span></div>
                        <div _ngcontent-tgd-c17="" class="card-controls">
                          <ul _ngcontent-tgd-c17="">
                            <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                        <h3 *ngIf="vehicleFuleModel.statusCode==200 && vehicleFuleModel.response.totalVehicle!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{vehicleFuleModel.response.totalVehicle}}</h3>
                  <h3 *ngIf="vehicleFuleModel.statusCode!=200"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                        <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% </span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -60px;">
                <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                        <div _ngcontent-tgd-c17="" class="card-title1"><span _ngcontent-tgd-c17="" class="font-montserrat fs-11 all-caps">Total No. of Online Vehicles</span></div>
                        <div _ngcontent-tgd-c17="" class="card-controls">
                          <ul _ngcontent-tgd-c17="">
                            <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                        <h3 *ngIf="vehicleFuleModel.statusCode==200 && vehicleFuleModel.response.noofVehicleOnline!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{vehicleFuleModel.response.noofVehicleOnline}}</h3>
                        <h3 *ngIf="vehicleFuleModel.statusCode!=200"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                        <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71%</span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 320px; margin-left: -125px;">
                <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                        <div _ngcontent-tgd-c17="" class="card-title1"><span _ngcontent-tgd-c17="" class="font-montserrat fs-11 all-caps">Total No. of Offline Vehicles</span></div>
                        <div _ngcontent-tgd-c17="" class="card-controls">
                          <ul _ngcontent-tgd-c17="">
                            <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                        <h3 *ngIf="vehicleFuleModel.statusCode==200 && vehicleFuleModel.response.noofVehicleOffline!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{vehicleFuleModel.response.noofVehicleOffline}}</h3>
                        <h3 *ngIf="vehicleFuleModel.statusCode!=200"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                        <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 300px; margin-top: -110px; margin-left: 1020px;">
                <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                        <div _ngcontent-tgd-c17="" class="card-title1"><span _ngcontent-tgd-c17="" class="font-montserrat fs-11 all-caps">Total fuel available in vehicle (Ltrs)</span></div>
                        <div _ngcontent-tgd-c17="" class="card-controls">
                          <ul _ngcontent-tgd-c17="">
                            <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                        <h3 *ngIf="vehicleFuleModel.statusCode==200 && vehicleFuleModel.response.totalFuelStock!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{vehicleFuleModel.response.totalFuelStock}}</h3>
                        <h3 *ngIf="vehicleFuleModel.statusCode!=200"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                        <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>     
            <div class="container Customers-top-notification card4view" style="margin-top: -10px;">
              <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: 5px;">
                <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                        <div _ngcontent-tgd-c17="" class="card-title1"><span _ngcontent-tgd-c17="" class="font-montserrat fs-11 all-caps">Total No. of Activated Vehicles</span></div>
                        <div _ngcontent-tgd-c17="" class="card-controls">
                          <ul _ngcontent-tgd-c17="">
                            <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                        <h3 *ngIf="vehicleFuleModel.statusCode==200 && vehicleFuleModel.response.noofActivatedVehicle!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{vehicleFuleModel.response.noofActivatedVehicle}}</h3>
                        <h3 *ngIf="vehicleFuleModel.statusCode!=200"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                        <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% </span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -60px;">
                <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                        <div _ngcontent-tgd-c17="" class="card-title1"><span _ngcontent-tgd-c17="" class="font-montserrat fs-11 all-caps">Total No. of Activatoin Pending Vehicles</span></div>
                        <div _ngcontent-tgd-c17="" class="card-controls">
                          <ul _ngcontent-tgd-c17="">
                            <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                        <h3 *ngIf="vehicleFuleModel.statusCode==200 && vehicleFuleModel.response.activationPendingVehicle!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{vehicleFuleModel.response.activationPendingVehicle}}</h3>
                        <h3 *ngIf="vehicleFuleModel.statusCode!=200"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                        <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71%</span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 320px; margin-left: -125px;">
                <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                        <div _ngcontent-tgd-c17="" class="card-title1"><span _ngcontent-tgd-c17="" class="font-montserrat fs-11 all-caps">Total No. of Active Vehicles</span></div>
                        <div _ngcontent-tgd-c17="" class="card-controls">
                          <ul _ngcontent-tgd-c17="">
                            <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                        <h3 *ngIf="vehicleFuleModel.statusCode==200 && vehicleFuleModel.response.activeVehicle!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{vehicleFuleModel.response.activeVehicle}}</h3>
                        <h3 *ngIf="vehicleFuleModel.statusCode!=200"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                        <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 300px; margin-top: -110px; margin-left: 1020px;">
                <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                        <div _ngcontent-tgd-c17="" class="card-title1"><span _ngcontent-tgd-c17="" class="font-montserrat fs-11 all-caps">Total of Inactive Vehicles</span></div>
                        <div _ngcontent-tgd-c17="" class="card-controls">
                          <ul _ngcontent-tgd-c17="">
                            <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                        <h3 *ngIf="vehicleFuleModel.statusCode==200 && vehicleFuleModel.response.inactiveVehicle!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{vehicleFuleModel.response.inactiveVehicle}}</h3>
                        <h3 *ngIf="vehicleFuleModel.statusCode!=200"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                        <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>    
            
      <!-- START CONTAINER FLUID --> 
      
      <!-- START CONTAINER FLUID -->
      <div class=" no-padding    container-fixed-lg bg-white">
        <div *ngIf="successalert" class="header">
        <div  style="height: 30px; text-align: center;" class="alert-success">
          {{successmessage}}
          </div>
        </div>
        <br>
        <!-- <button class="btn-transaction" (click)="activeVehicle()" style="margin-left: 550px;">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios5" value="option5" checked>
            <label class="form-check-label" for="exampleRadios5">
             Active
            </label>
          </div>
          </button>
        <button class="btn-transaction" (click)="inactiveVehicle()">
          <div class="form-check">
   <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1">
   <label class="form-check-label" for="exampleRadios1">
     InActive
   </label>
 </div>
 </button> -->
      <!-- <select class="select-form-style" style="margin-left: 20px;" id="searchBy">  
        <option value="2">Search By Date Range</option>  
    </select>  -->
    <!-- <input type="date" style="margin-left: 10px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
    <input type="date" style="margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" > -->
  <!-- <button style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
  <button *ngIf="refreshFlag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button> -->
  <!-- <div *ngIf="errorFlag" style="color: red; margin-left: 20px;">{{errorMessage}}</div> -->
        <div class="container"> 
          <!-- START card -->

          <div class="card card-transparent">
            <div *ngIf="!filterflag" class="card-body">
              <div style="display: none;">{{vehicleFuleModel.responseMessage}}</div>
              <div class="table-responsive">
                <table class="ch-table" datatable>
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Partner Name</th>
                      <th style="text-align: left;">Vehicle ID</th>
                      <th style="text-align: left;">Vehicle Number</th>
                      <th style="text-align: left;">Owner Name</th>
                      <th style="text-align: left;">Model Name</th>
                      <th style="text-align: left;">Fuel</th>
                      <th style="text-align: center;">Tank Capacity in Litre</th>
                      <th style="text-align: center;">Available Fuel in Litre</th>
                      <th style="text-align: left;">AMC/MMC Type</th>
                      <th style="text-align: left;">Added  Date Time </th>
                      <th style="text-align: left;">DSA Name</th>
                      <th style="text-align: center;">Vehicle Status</th>
                      <th style="text-align: center;">Vehicle Online/Offline Status</th>
                      <th style="text-align: center;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let vehicle of vehicleFuleModel.response.vehicleList; let i=index">
                      <td *ngIf="vehicle.vehicleonboardingBy==1" style="color: green;" align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="vehicle.vehicleonboardingBy!=1" align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="vehicle.partnerName!=null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle">{{vehicle.partnerName}}</td>
                      <td *ngIf="vehicle.partnerName!=null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">{{vehicle.partnerName}}</td>
                      <td *ngIf="vehicle.vehicleCode!=null " align="left" class="v-align-middle">{{vehicle.vehicleCode}}</td>
                      <td *ngIf="vehicle.vehicleCode==null " align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.vehicleNumber!=null && vehicle.vehicleonboardingBy==1" style="color: green;"align="left" class="v-align-middle">{{vehicle.vehicleNumber}}</td>
                      <td *ngIf="vehicle.vehicleNumber!=null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">{{vehicle.vehicleNumber}}</td>
                      <td *ngIf="vehicle.vehicleNumber==null && vehicle.vehicleonboardingBy==1"style="color: green;" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.vehicleNumber==null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.vehicleNumber==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.vehicleName!=null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle">{{vehicle.vehicleName}}</td>
                      <td *ngIf="vehicle.vehicleName!=null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">{{vehicle.vehicleName}}</td>
                      <td *ngIf="vehicle.vehicleName==null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.vehicleName==null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">N/A</td>
                     
                      <td *ngIf="vehicle.vehicleOwnerName!=null " align="left" class="v-align-middle">{{vehicle.vehicleOwnerName}}</td>
                      <td *ngIf="vehicle.vehicleOwnerName==null" align="left" class="v-align-middle">N/A</td>
                     
                     
                      <td *ngIf="vehicle.fuelType.fuelTypeName!=null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle">{{vehicle.fuelType.fuelTypeName}}</td>
                      <td *ngIf="vehicle.fuelType.fuelTypeName!=null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">{{vehicle.fuelType.fuelTypeName}}</td>
                      <td *ngIf="vehicle.fuelType==null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.fuelType==null && vehicle.vehicleonboardingBy!=1"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.tankCapacity!=null && vehicle.vehicleonboardingBy==1" style="color: green;" align="center" class="v-align-middle ">{{vehicle.tankCapacity}}</td>
                      <td *ngIf="vehicle.tankCapacity!=null && vehicle.vehicleonboardingBy!=1" align="center" class="v-align-middle ">{{vehicle.tankCapacity}}</td>
                      <td *ngIf="vehicle.tankCapacity==null && vehicle.vehicleonboardingBy==1" style="color: green;" align="center" class="v-align-middle ">N/A</td>
                      <td *ngIf="vehicle.tankCapacity==null && vehicle.vehicleonboardingBy!=1"align="center" class="v-align-middle ">N/A</td>
                      <td *ngIf="vehicle.fuelStock!=null && vehicle.vehicleonboardingBy==1" style="color: green;" align="center" class="v-align-middle ">{{vehicle.fuelStock}}</td>
                      <td *ngIf="vehicle.fuelStock!=null && vehicle.vehicleonboardingBy!=1" align="center" class="v-align-middle ">{{vehicle.fuelStock}}</td>
                      <td *ngIf="vehicle.fuelStock==null && vehicle.vehicleonboardingBy==1" style="color: green;" align="center" class="v-align-middle ">N/A</td>
                      <td *ngIf="vehicle.fuelStock==null && vehicle.vehicleonboardingBy!=1"align="center" class="v-align-middle ">N/A</td>
                      <td *ngIf="vehicle.amcMmcType!=null && vehicle.vehicleonboardingBy==1"style="color: green;" align="left" class="v-align-middle ">{{vehicle.amcMmcType}}</td>
                      <td *ngIf="vehicle.amcMmcType!=null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle ">{{vehicle.amcMmcType}}</td>
                      <td *ngIf="vehicle.amcMmcType==null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle ">N/A</td>
                      <td *ngIf="vehicle.amcMmcType==null && vehicle.vehicleonboardingBy!=1"align="left" class="v-align-middle ">N/A</td>
                      <td *ngIf="vehicle.submittedDatetime!=null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle">{{vehicle.submittedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="vehicle.submittedDatetime!=null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">{{vehicle.submittedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="vehicle.submittedDatetime==null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.submittedDatetime==null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.assignDSA!=null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle">{{vehicle.assignDSA.firstName}} {{vehicle.assignDSA.lastName}}</td>
                      <td *ngIf="vehicle.assignDSA!=null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">{{vehicle.assignDSA.firstName}} {{vehicle.assignDSA.lastName}}</td>
                      <td *ngIf="vehicle.assignDSA==null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.assignDSA==null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">N/A</td>
                      <td  *ngIf="vehicle.vehicleonboardingBy==1" style="color: green;" align="center" class="v-align-middle">
                        <button title="Vehicle InActive" *ngIf="vehicle.vehicleStatus==4" class="label label-success" (click)="vehicleactiveinactiveStatus(vehicle.vehicleId,3)"><i class="fa fa-toggle-off" aria-hidden="true"></i></button>
                        <button title="Vehicle Active" *ngIf="vehicle.vehicleStatus==3" class="label label-success" (click)="vehicleactiveinactiveStatus(vehicle.vehicleId,4)"><i class="fa fa-toggle-on" aria-hidden="true"></i></button>
                      </td>
                      <td  *ngIf="vehicle.vehicleonboardingBy!=1" align="center" class="v-align-middle">
                        <button title="Vehicle InActive" *ngIf="vehicle.vehicleStatus==4" class="label label-success" (click)="vehicleactiveinactiveStatus(vehicle.vehicleId,3)"><i class="fa fa-toggle-off" aria-hidden="true"></i></button>
                        <button title="Vehicle Active" *ngIf="vehicle.vehicleStatus==3" class="label label-success" (click)="vehicleactiveinactiveStatus(vehicle.vehicleId,4)"><i class="fa fa-toggle-on" aria-hidden="true"></i></button>
                      </td>
                      <td  *ngIf="vehicle.vehicleonboardingBy==1" style="color: green;" align="center" class="v-align-middle">
                      <button title="Vehicle Off" *ngIf="vehicle.vehicleonoffStatus==0" class="label label-success" (click)="vehicleOnOffStatus(vehicle.vehicleId,1,vehicle.fuelCurrentPrice)"><i class="fa fa-toggle-off" aria-hidden="true"></i></button>
                      <button title="Vehicle On" *ngIf="vehicle.vehicleonoffStatus==1" class="label label-success" (click)="vehicleOnOffStatus(vehicle.vehicleId,0,vehicle.fuelCurrentPrice)"><i class="fa fa-toggle-on" aria-hidden="true"></i></button>
                    </td>
                    <td  *ngIf="vehicle.vehicleonboardingBy!=1" align="center" class="v-align-middle">
                      <button title="Vehicle Off" *ngIf="vehicle.vehicleonoffStatus==0" class="label label-success" (click)="vehicleOnOffStatus(vehicle.vehicleId,1)"><i class="fa fa-toggle-off" aria-hidden="true"></i></button>
                      <button title="Vehicle On" *ngIf="vehicle.vehicleonoffStatus==1" class="label label-success" (click)="vehicleOnOffStatus(vehicle.vehicleId,0)"><i class="fa fa-toggle-on" aria-hidden="true"></i></button>
                    </td>
                      <td *ngIf="vehicle.vehicleonboardingBy==1" style="width: 10%;color: green;" class="v-align-middle">
                        <button title="View" class="label label-info" (click)="open(content,vehicle.vehicleId)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                        <button title="Vehicle Activate" *ngIf="vehicle.vehicleActiveStatus===0"class="label label-warning" (click)="callActive(content1,vehicle.vehicleId)"><i class="fa fa-thumbs-o-up" aria-hidden="true"></i></button>&nbsp;
                        <button title="Assign Dsa" *ngIf="vehicle.onboardingBy===2" class="label label-info" (click)="open1(content2,vehicle.vehicleId)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                    </td>
                    <td *ngIf="vehicle.vehicleonboardingBy!=1"style="width: 10%;" class="v-align-middle">
                      <button title="View" class="label label-info" (click)="open(content,vehicle.vehicleId)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                      <button title="Vehicle Activate" *ngIf="vehicle.vehicleActiveStatus===0"class="label label-warning" (click)="callActive(content1,vehicle.vehicleId)"><i class="fa fa-thumbs-o-up" aria-hidden="true"></i></button>&nbsp;
                      <button title="Assign Dsa" *ngIf="vehicle.onboardingBy===2" class="label label-info" (click)="open1(content2,vehicle.vehicleId)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                  </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div *ngIf="filterflag && !filteronesearch" class="card-body">
              <div style="display: none;">{{vehicleResponseFilter.responseMessage}}</div>
              <div class="table-responsive">
                <table class="ch-table" datatable>
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Partner Name</th>
                      <th style="text-align: left;">Vehicle ID</th>
                      <th style="text-align: left;">Vehicle Number</th>
                      <th style="text-align: left;">Owner Name</th>
                      <th style="text-align: left;">Model Name</th>
                      <th style="text-align: left;">Fuel</th>
                      <th style="text-align: center;">Tank Capacity in Litre</th>
                      <th style="text-align: center;">Available Fuel in Litre</th>
                      <th style="text-align: left;">AMC/MMC Type</th>
                      <th style="text-align: left;">Added  Date Time </th>
                      <th style="text-align: left;">DSA Name</th>
                      <th style="text-align: center;">Vehicle Status</th>
                      <th style="text-align: center;">Vehicle Online/Offline Status</th>
                      <th style="text-align: center;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let vehicle of vehicleResponseFilter.response.vehicleList; let i=index">
                      <td *ngIf="vehicle.vehicleonboardingBy==1" style="color: green;" align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="vehicle.vehicleonboardingBy!=1" align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="vehicle.partnerName!=null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle">{{vehicle.partnerName}}</td>
                      <td *ngIf="vehicle.partnerName!=null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">{{vehicle.partnerName}}</td>
                      <td *ngIf="vehicle.vehicleCode!=null " align="left" class="v-align-middle">{{vehicle.vehicleCode}}</td>
                      <td *ngIf="vehicle.vehicleCode==null " align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.vehicleNumber!=null && vehicle.vehicleonboardingBy==1" style="color: green;"align="left" class="v-align-middle">{{vehicle.vehicleNumber}}</td>
                      <td *ngIf="vehicle.vehicleNumber!=null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">{{vehicle.vehicleNumber}}</td>
                      <td *ngIf="vehicle.vehicleNumber==null && vehicle.vehicleonboardingBy==1"style="color: green;" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.vehicleNumber==null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.vehicleNumber==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.vehicleName!=null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle">{{vehicle.vehicleName}}</td>
                      <td *ngIf="vehicle.vehicleName!=null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">{{vehicle.vehicleName}}</td>
                      <td *ngIf="vehicle.vehicleName==null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.vehicleName==null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">N/A</td>
                      
                      <td *ngIf="vehicle.vehicleOwnerName!=null " align="left" class="v-align-middle">{{vehicle.vehicleOwnerName}}</td>
                      <td *ngIf="vehicle.vehicleOwnerName==null" align="left" class="v-align-middle">N/A</td>
                     
                      <td *ngIf="vehicle.fuelType.fuelTypeName!=null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle">{{vehicle.fuelType.fuelTypeName}}</td>
                      <td *ngIf="vehicle.fuelType.fuelTypeName!=null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">{{vehicle.fuelType.fuelTypeName}}</td>
                      <td *ngIf="vehicle.fuelType==null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.fuelType==null && vehicle.vehicleonboardingBy!=1"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.tankCapacity!=null && vehicle.vehicleonboardingBy==1" style="color: green;" align="center" class="v-align-middle ">{{vehicle.tankCapacity}}</td>
                      <td *ngIf="vehicle.tankCapacity!=null && vehicle.vehicleonboardingBy!=1" align="center" class="v-align-middle ">{{vehicle.tankCapacity}}</td>
                      <td *ngIf="vehicle.tankCapacity==null && vehicle.vehicleonboardingBy==1" style="color: green;" align="center" class="v-align-middle ">N/A</td>
                      <td *ngIf="vehicle.tankCapacity==null && vehicle.vehicleonboardingBy!=1"align="center" class="v-align-middle ">N/A</td>
                      <td *ngIf="vehicle.fuelStock!=null && vehicle.vehicleonboardingBy==1" style="color: green;" align="center" class="v-align-middle ">{{vehicle.fuelStock}}</td>
                      <td *ngIf="vehicle.fuelStock!=null && vehicle.vehicleonboardingBy!=1" align="center" class="v-align-middle ">{{vehicle.fuelStock}}</td>
                      <td *ngIf="vehicle.fuelStock==null && vehicle.vehicleonboardingBy==1" style="color: green;" align="center" class="v-align-middle ">N/A</td>
                      <td *ngIf="vehicle.fuelStock==null && vehicle.vehicleonboardingBy!=1"align="center" class="v-align-middle ">N/A</td>
                      <td *ngIf="vehicle.amcMmcType!=null && vehicle.vehicleonboardingBy==1"style="color: green;" align="left" class="v-align-middle ">{{vehicle.amcMmcType}}</td>
                      <td *ngIf="vehicle.amcMmcType!=null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle ">{{vehicle.amcMmcType}}</td>
                      <td *ngIf="vehicle.amcMmcType==null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle ">N/A</td>
                      <td *ngIf="vehicle.amcMmcType==null && vehicle.vehicleonboardingBy!=1"align="left" class="v-align-middle ">N/A</td>
                      <td *ngIf="vehicle.submittedDatetime!=null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle">{{vehicle.submittedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="vehicle.submittedDatetime!=null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">{{vehicle.submittedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="vehicle.submittedDatetime==null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.submittedDatetime==null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.assignDSA!=null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle">{{vehicle.assignDSA.firstName}} {{vehicle.assignDSA.lastName}}</td>
                      <td *ngIf="vehicle.assignDSA!=null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">{{vehicle.assignDSA.firstName}} {{vehicle.assignDSA.lastName}}</td>
                      <td *ngIf="vehicle.assignDSA==null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.assignDSA==null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">N/A</td>
                      <td  *ngIf="vehicle.vehicleonboardingBy==1" style="color: green;" align="center" class="v-align-middle">
                        <button title="Vehicle InActive" *ngIf="vehicle.vehicleStatus==4" class="label label-success" (click)="vehicleactiveinactiveStatus(vehicle.vehicleId,3)"><i class="fa fa-toggle-off" aria-hidden="true"></i></button>
                        <button title="Vehicle Active" *ngIf="vehicle.vehicleStatus==3" class="label label-success" (click)="vehicleactiveinactiveStatus(vehicle.vehicleId,4)"><i class="fa fa-toggle-on" aria-hidden="true"></i></button>
                      </td>
                      <td  *ngIf="vehicle.vehicleonboardingBy!=1" align="center" class="v-align-middle">
                        <button title="Vehicle InActive" *ngIf="vehicle.vehicleStatus==4" class="label label-success" (click)="vehicleactiveinactiveStatus(vehicle.vehicleId,3)"><i class="fa fa-toggle-off" aria-hidden="true"></i></button>
                        <button title="Vehicle Active" *ngIf="vehicle.vehicleStatus==3" class="label label-success" (click)="vehicleactiveinactiveStatus(vehicle.vehicleId,4)"><i class="fa fa-toggle-on" aria-hidden="true"></i></button>
                      </td>
                      <td  *ngIf="vehicle.vehicleonboardingBy==1" style="color: green;" align="center" class="v-align-middle">
                      <button title="Vehicle Off" *ngIf="vehicle.vehicleonoffStatus==0" class="label label-success" (click)="vehicleOnOffStatus(vehicle.vehicleId,1)"><i class="fa fa-toggle-off" aria-hidden="true"></i></button>
                      <button title="Vehicle On" *ngIf="vehicle.vehicleonoffStatus==1" class="label label-success" (click)="vehicleOnOffStatus(vehicle.vehicleId,0)"><i class="fa fa-toggle-on" aria-hidden="true"></i></button>
                    </td>
                    <td  *ngIf="vehicle.vehicleonboardingBy!=1" align="center" class="v-align-middle">
                      <button title="Vehicle Off" *ngIf="vehicle.vehicleonoffStatus==0" class="label label-success" (click)="vehicleOnOffStatus(vehicle.vehicleId,1)"><i class="fa fa-toggle-off" aria-hidden="true"></i></button>
                      <button title="Vehicle On" *ngIf="vehicle.vehicleonoffStatus==1" class="label label-success" (click)="vehicleOnOffStatus(vehicle.vehicleId,0)"><i class="fa fa-toggle-on" aria-hidden="true"></i></button>
                    </td>
                      <td *ngIf="vehicle.vehicleonboardingBy==1" style="width: 10%;color: green;" class="v-align-middle">
                        <button title="View" class="label label-info" (click)="open(content,vehicle.vehicleId)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                        <button title="Vehicle Activate" *ngIf="vehicle.vehicleActiveStatus===0"class="label label-warning" (click)="callActive(content1,vehicle.vehicleId)"><i class="fa fa-thumbs-o-up" aria-hidden="true"></i></button>&nbsp;
                        <button title="Assign Dsa" *ngIf="vehicle.onboardingBy===2" class="label label-info" (click)="open1(content2,vehicle.vehicleId)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                    </td>
                    <td *ngIf="vehicle.vehicleonboardingBy!=1" style="width: 10%;" class="v-align-middle">
                      <button title="View" class="label label-info" (click)="open(content,vehicle.vehicleId)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                      <button title="Vehicle Activate" *ngIf="vehicle.vehicleActiveStatus===0"class="label label-warning" (click)="callActive(content1,vehicle.vehicleId)"><i class="fa fa-thumbs-o-up" aria-hidden="true"></i></button>&nbsp;
                      <button title="Assign Dsa" *ngIf="vehicle.onboardingBy===2" class="label label-info" (click)="open1(content2,vehicle.vehicleId)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                  </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div *ngIf="filterflag && filteronesearch" class="card-body">
              <div style="display: none;">{{vehicleResponseFilterNoRecordFound.responseMessage}}</div>
              <div class="table-responsive">
                <table class="ch-table" datatable>
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Partner Name</th>
                      <th style="text-align: left;">Vehicle ID</th>
                      <th style="text-align: left;">Vehicle Number</th>
                      <th style="text-align: left;">Owner Name</th>
                      <th style="text-align: left;">Model Name</th>
                      <th style="text-align: left;">Fuel</th>
                      <th style="text-align: center;">Tank Capacity in Litre</th>
                      <th style="text-align: center;">Available Fuel in Litre</th>
                      <th style="text-align: left;">AMC/MMC Type</th>
                      <th style="text-align: left;">Added  Date Time </th>
                      <th style="text-align: left;">DSA Name</th>
                      <th style="text-align: center;">Vehicle Online/Offline Status</th>
                      <th style="text-align: center;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let vehicle of vehicleResponseFilterNoRecordFound.response.vehicleList; let i=index">
                      <td *ngIf="vehicle.vehicleonboardingBy==1" style="color: green;" align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="vehicle.vehicleonboardingBy!=1" align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="vehicle.partnerName!=null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle">{{vehicle.partnerName}}</td>
                      <td *ngIf="vehicle.partnerName!=null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">{{vehicle.partnerName}}</td>
                      <td *ngIf="vehicle.vehicleCode!=null " align="left" class="v-align-middle">{{vehicle.vehicleCode}}</td>
                      <td *ngIf="vehicle.vehicleCode==null " align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.vehicleNumber!=null && vehicle.vehicleonboardingBy==1" style="color: green;"align="left" class="v-align-middle">{{vehicle.vehicleNumber}}</td>
                      <td *ngIf="vehicle.vehicleNumber!=null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">{{vehicle.vehicleNumber}}</td>
                      <td *ngIf="vehicle.vehicleNumber==null && vehicle.vehicleonboardingBy==1"style="color: green;" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.vehicleNumber==null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.vehicleNumber==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.vehicleName!=null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle">{{vehicle.vehicleName}}</td>
                      <td *ngIf="vehicle.vehicleName!=null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">{{vehicle.vehicleName}}</td>
                      <td *ngIf="vehicle.vehicleName==null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.vehicleName==null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">N/A</td>
                     
                      <td *ngIf="vehicle.vehicleOwnerName!=null " align="left" class="v-align-middle">{{vehicle.vehicleOwnerName}}</td>
                      <td *ngIf="vehicle.vehicleOwnerName==null" align="left" class="v-align-middle">N/A</td>
                     
                      <td *ngIf="vehicle.fuelType.fuelTypeName!=null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle">{{vehicle.fuelType.fuelTypeName}}</td>
                      <td *ngIf="vehicle.fuelType.fuelTypeName!=null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">{{vehicle.fuelType.fuelTypeName}}</td>
                      <td *ngIf="vehicle.fuelType==null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.fuelType==null && vehicle.vehicleonboardingBy!=1"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.tankCapacity!=null && vehicle.vehicleonboardingBy==1" style="color: green;" align="center" class="v-align-middle ">{{vehicle.tankCapacity}}</td>
                      <td *ngIf="vehicle.tankCapacity!=null && vehicle.vehicleonboardingBy!=1" align="center" class="v-align-middle ">{{vehicle.tankCapacity}}</td>
                      <td *ngIf="vehicle.tankCapacity==null && vehicle.vehicleonboardingBy==1" style="color: green;" align="center" class="v-align-middle ">N/A</td>
                      <td *ngIf="vehicle.tankCapacity==null && vehicle.vehicleonboardingBy!=1"align="center" class="v-align-middle ">N/A</td>
                      <td *ngIf="vehicle.fuelStock!=null && vehicle.vehicleonboardingBy==1" style="color: green;" align="center" class="v-align-middle ">{{vehicle.fuelStock}}</td>
                      <td *ngIf="vehicle.fuelStock!=null && vehicle.vehicleonboardingBy!=1" align="center" class="v-align-middle ">{{vehicle.fuelStock}}</td>
                      <td *ngIf="vehicle.fuelStock==null && vehicle.vehicleonboardingBy==1" style="color: green;" align="center" class="v-align-middle ">N/A</td>
                      <td *ngIf="vehicle.fuelStock==null && vehicle.vehicleonboardingBy!=1"align="center" class="v-align-middle ">N/A</td>
                      <td *ngIf="vehicle.amcMmcType!=null && vehicle.vehicleonboardingBy==1"style="color: green;" align="left" class="v-align-middle ">{{vehicle.amcMmcType}}</td>
                      <td *ngIf="vehicle.amcMmcType!=null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle ">{{vehicle.amcMmcType}}</td>
                      <td *ngIf="vehicle.amcMmcType==null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle ">N/A</td>
                      <td *ngIf="vehicle.amcMmcType==null && vehicle.vehicleonboardingBy!=1"align="left" class="v-align-middle ">N/A</td>
                      <td *ngIf="vehicle.submittedDatetime!=null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle">{{vehicle.submittedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="vehicle.submittedDatetime!=null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">{{vehicle.submittedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="vehicle.submittedDatetime==null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.submittedDatetime==null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.assignDSA!=null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle">{{vehicle.assignDSA.firstName}} {{vehicle.assignDSA.lastName}}</td>
                      <td *ngIf="vehicle.assignDSA!=null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">{{vehicle.assignDSA.firstName}} {{vehicle.assignDSA.lastName}}</td>
                      <td *ngIf="vehicle.assignDSA==null && vehicle.vehicleonboardingBy==1" style="color: green;" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.assignDSA==null && vehicle.vehicleonboardingBy!=1" align="left" class="v-align-middle">N/A</td>
                      <td  *ngIf="vehicle.vehicleonboardingBy==1" style="color: green;" align="center" class="v-align-middle">
                        <button title="Vehicle InActive" *ngIf="vehicle.vehicleStatus==4" class="label label-success" (click)="vehicleactiveinactiveStatus(vehicle.vehicleId,3)"><i class="fa fa-toggle-off" aria-hidden="true"></i></button>
                        <button title="Vehicle Active" *ngIf="vehicle.vehicleStatus==3" class="label label-success" (click)="vehicleactiveinactiveStatus(vehicle.vehicleId,4)"><i class="fa fa-toggle-on" aria-hidden="true"></i></button>
                      </td>
                      <td  *ngIf="vehicle.vehicleonboardingBy!=1" align="center" class="v-align-middle">
                        <button title="Vehicle InActive" *ngIf="vehicle.vehicleStatus==4" class="label label-success" (click)="vehicleactiveinactiveStatus(vehicle.vehicleId,3)"><i class="fa fa-toggle-off" aria-hidden="true"></i></button>
                        <button title="Vehicle Active" *ngIf="vehicle.vehicleStatus==3" class="label label-success" (click)="vehicleactiveinactiveStatus(vehicle.vehicleId,4)"><i class="fa fa-toggle-on" aria-hidden="true"></i></button>
                      </td>
                      <td  *ngIf="vehicle.vehicleonboardingBy==1" style="color: green;" align="center" class="v-align-middle">
                      <button title="Vehicle Off" *ngIf="vehicle.vehicleonoffStatus==0" class="label label-success" (click)="vehicleOnOffStatus(vehicle.vehicleId,1)"><i class="fa fa-toggle-off" aria-hidden="true"></i></button>
                      <button title="Vehicle On" *ngIf="vehicle.vehicleonoffStatus==1" class="label label-success" (click)="vehicleOnOffStatus(vehicle.vehicleId,0)"><i class="fa fa-toggle-on" aria-hidden="true"></i></button>
                    </td>
                    <td  *ngIf="vehicle.vehicleonboardingBy!=1" align="center" class="v-align-middle">
                      <button title="Vehicle Off" *ngIf="vehicle.vehicleonoffStatus==0" class="label label-success" (click)="vehicleOnOffStatus(vehicle.vehicleId,1)"><i class="fa fa-toggle-off" aria-hidden="true"></i></button>
                      <button title="Vehicle On" *ngIf="vehicle.vehicleonoffStatus==1" class="label label-success" (click)="vehicleOnOffStatus(vehicle.vehicleId,0)"><i class="fa fa-toggle-on" aria-hidden="true"></i></button>
                    </td>
                      <td *ngIf="vehicle.vehicleonboardingBy==1" style="width: 10%;color: green;" class="v-align-middle">
                        <button title="View" class="label label-info" (click)="open(content,vehicle.vehicleId)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                        <button title="Vehicle Activate" *ngIf="vehicle.vehicleActiveStatus===0"class="label label-warning" (click)="callActive(content1,vehicle.vehicleId)"><i class="fa fa-thumbs-o-up" aria-hidden="true"></i></button>&nbsp;
                        <button title="Assign Dsa" *ngIf="vehicle.onboardingBy===2" class="label label-info" (click)="open1(content2,vehicle.vehicleId)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                    </td>
                    <td *ngIf="vehicle.vehicleonboardingBy!=1" style="width: 10%;"class="v-align-middle">
                      <button title="View" class="label label-info" (click)="open(content,vehicle.vehicleId)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                      <button title="Vehicle Activate" *ngIf="vehicle.vehicleActiveStatus===0"class="label label-warning" (click)="callActive(content1,vehicle.vehicleId)"><i class="fa fa-thumbs-o-up" aria-hidden="true"></i></button>&nbsp;
                      <button title="Assign Dsa" *ngIf="vehicle.onboardingBy===2" class="label label-info" (click)="open1(content2,vehicle.vehicleId)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                  </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- ---- Active ------- -->
            <!-- <div *ngIf="activateVehicle" class="card-body">
              <div style="display: none;">{{activatevehicleFuleModel.responseMessage}}</div>
              <div class="table-responsive">
                <table class="ch-table" datatable>
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Partner Name</th>
                      <th style="text-align: left;">Vehicle Number</th>
                      <th style="text-align: left;">Model Name</th>
                      <th style="text-align: left;">Fuel</th>
                      <th style="text-align: center;">Tank Capacity in Litre</th>
                      <th style="text-align: center;">Available Fuel in Litre</th>
                      <th style="text-align: left;">AMC/MMC Type</th>
                      <th style="text-align: left;">Added  Date Time </th>
                      <th style="text-align: left;">DSA Name</th>
                      <th style="text-align: center;">Vehicle Online/Offline Status</th>
                      <th style="text-align: center;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let vehicle of activatevehicleFuleModel.response.vehicleList; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="vehicle.partnerName!=null"align="left" class="v-align-middle">{{vehicle.partnerName}}</td>
                      <td *ngIf="vehicle.partnerName==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.vehicleNumber!=null"align="left" class="v-align-middle">{{vehicle.vehicleNumber}}</td>
                      <td *ngIf="vehicle.vehicleNumber==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.vehicleName!=null"align="left" class="v-align-middle">{{vehicle.vehicleName}}</td>
                      <td *ngIf="vehicle.vehicleName==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.fuelType.fuelTypeName!=null"align="left" class="v-align-middle">{{vehicle.fuelType.fuelTypeName}}</td>
                      <td *ngIf="vehicle.fuelType==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.tankCapacity!=null"align="center" class="v-align-middle ">{{vehicle.tankCapacity}}</td>
                      <td *ngIf="vehicle.tankCapacity==null"align="center" class="v-align-middle ">N/A</td>
                      <td *ngIf="vehicle.fuelStock!=null"align="center" class="v-align-middle ">{{vehicle.fuelStock}}</td>
                      <td *ngIf="vehicle.fuelStock==null"align="center" class="v-align-middle ">N/A</td>
                      <td *ngIf="vehicle.amcMmcType!=null"align="left" class="v-align-middle ">{{vehicle.amcMmcType}}</td>
                      <td *ngIf="vehicle.amcMmcType==null"align="left" class="v-align-middle ">N/A</td>
                      <td *ngIf="vehicle.submittedDatetime!=null"align="left" class="v-align-middle">{{vehicle.submittedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="vehicle.submittedDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.assignDSA!=null"align="left" class="v-align-middle">{{vehicle.assignDSA.firstName}} {{vehicle.assignDSA.lastName}}</td>
                      <td *ngIf="vehicle.assignDSA==null"align="left" class="v-align-middle">N/A</td>
                      <td  align="center" class="v-align-middle">
                        <button title="Vehicle Off" *ngIf="vehicle.vehicleonoffStatus==0" class="label label-success" (click)="vehicleOnOffStatus(vehicle.vehicleId,1)"><i class="fa fa-toggle-off" aria-hidden="true"></i></button>
                      <button title="Vehicle On" *ngIf="vehicle.vehicleonoffStatus==1" class="label label-success" (click)="vehicleOnOffStatus(vehicle.vehicleId,0)"><i class="fa fa-toggle-on" aria-hidden="true"></i></button>
                      </td>
                      <td style="width: 10%;" class="v-align-middle">
                        <button title="View" class="label label-info" (click)="open(content,vehicle.vehicleId)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                        <button title="Vehicle Activate" *ngIf="vehicle.vehicleActiveStatus===0"class="label label-warning" (click)="callActive(content1,vehicle.vehicleId)"><i class="fa fa-thumbs-o-up" aria-hidden="true"></i></button>&nbsp;
                        <button title="Assign Dsa" *ngIf="vehicle.onboardingBy===2" class="label label-info" (click)="open1(content2,vehicle.vehicleId)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                    </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> -->
            <!-- ---------In activate Vehicle --------- -->
            <!-- <div *ngIf="inactivateVehicle" class="card-body">
              <div style="display: none;">{{inactivatevehicleFuleModel.responseMessage}}</div>
              <div class="table-responsive">
                <table class="ch-table" datatable>
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Partner Name</th>
                      <th style="text-align: left;">Vehicle Number</th>
                      <th style="text-align: left;">Model Name</th>
                      <th style="text-align: left;">Fuel</th>
                      <th style="text-align: center;">Tank Capacity in Litre</th>
                      <th style="text-align: center;">Available Fuel in Litre</th>
                      <th style="text-align: left;">AMC/MMC Type</th>
                      <th style="text-align: left;">Added  Date Time </th>
                      <th style="text-align: left;">DSA Name</th>
                      <th style="text-align: center;">Vehicle Online/Offline Status</th>
                      <th style="text-align: center;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let vehicle of inactivatevehicleFuleModel.response.vehicleList; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="vehicle.partnerName!=null"align="left" class="v-align-middle">{{vehicle.partnerName}}</td>
                      <td *ngIf="vehicle.partnerName==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.vehicleNumber!=null"align="left" class="v-align-middle">{{vehicle.vehicleNumber}}</td>
                      <td *ngIf="vehicle.vehicleNumber==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.vehicleName!=null"align="left" class="v-align-middle">{{vehicle.vehicleName}}</td>
                      <td *ngIf="vehicle.vehicleName==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.fuelType.fuelTypeName!=null"align="left" class="v-align-middle">{{vehicle.fuelType.fuelTypeName}}</td>
                      <td *ngIf="vehicle.fuelType==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.tankCapacity!=null"align="center" class="v-align-middle ">{{vehicle.tankCapacity}}</td>
                      <td *ngIf="vehicle.tankCapacity==null"align="center" class="v-align-middle ">N/A</td>
                      <td *ngIf="vehicle.fuelStock!=null"align="center" class="v-align-middle ">{{vehicle.fuelStock}}</td>
                      <td *ngIf="vehicle.fuelStock==null"align="center" class="v-align-middle ">N/A</td>
                      <td *ngIf="vehicle.amcMmcType!=null"align="left" class="v-align-middle ">{{vehicle.amcMmcType}}</td>
                      <td *ngIf="vehicle.amcMmcType==null"align="left" class="v-align-middle ">N/A</td>
                      <td *ngIf="vehicle.paymentMode!=null"align="left" class="v-align-middle ">{{vehicle.paymentMode}}</td>
                      <td *ngIf="vehicle.paymentMode==null"align="left" class="v-align-middle ">N/A</td>
                      <td *ngIf="vehicle.submittedDatetime!=null"align="left" class="v-align-middle">{{vehicle.submittedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="vehicle.submittedDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.assignDSA!=null"align="left" class="v-align-middle">{{vehicle.assignDSA.firstName}} {{vehicle.assignDSA.lastName}}</td>
                      <td *ngIf="vehicle.assignDSA==null"align="left" class="v-align-middle">N/A</td>
                      <td  align="center" class="v-align-middle">
                        <button title="Vehicle Off" *ngIf="vehicle.vehicleonoffStatus==0" class="label label-success" (click)="vehicleOnOffStatus(vehicle.vehicleId,1)"><i class="fa fa-toggle-off" aria-hidden="true"></i></button>
                      <button title="Vehicle On" *ngIf="vehicle.vehicleonoffStatus==1" class="label label-success" (click)="vehicleOnOffStatus(vehicle.vehicleId,0)"><i class="fa fa-toggle-on" aria-hidden="true"></i></button>
                      </td>
                      <td style="width: 10%;" class="v-align-middle">
                        <button title="View" class="label label-su" (click)="open(content,vehicle.vehicleId)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                        <button title="Vehicle Activate" *ngIf="vehicle.vehicleActiveStatus===0"class="label label-warning" (click)="callActive(content1,vehicle.vehicleId)"><i class="fa fa-thumbs-o-up" aria-hidden="true"></i></button>&nbsp;
                        <button title="Assign Dsa" *ngIf="vehicle.onboardingBy===2" class="label label-info" (click)="open1(content2,vehicle.vehicleId)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                    </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> -->


          </div>
          <!-- END card --> 
        </div>
      </div>
      <!-- END CONTAINER FLUID --> 
      
    </div>
    <!-- END PAGE CONTENT --> 
    <!-- START COPYRIGHT --> 
    <!-- START CONTAINER FLUID --> 
    <!-- START CONTAINER FLUID -->
  <!--   <div class=" container   container-fixed-lg footer">
      <div class="copyright sm-text-center">
        <p class="small-text no-margin pull-left sm-pull-reset"> Ã¯Â¿Â½2014-2020 All Rights Reserved. PagesÃ¯Â¿Â½ and/or its subsidiaries or affiliates are registered trademark of Revox Ltd. <span class="hint-text m-l-15">Pages v05.23 20201105.r.190</span> </p>
        <p class="small no-margin pull-right sm-pull-reset"> Hand-crafted <span class="hint-text">&amp; made with Love</span> </p>
        <div class="clearfix"></div>
      </div>
    </div> -->
    <!-- END COPYRIGHT --> 
  </div>
  <!-- END PAGE CONTENT WRAPPER --> 
</div>
<!-- END PAGE CONTAINER --> 
<!--START QUICKVIEW -->

<!-- END OVERLAY -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h6>Vehicle View Page</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <h5 style="margin-left: 15px; text-align: left; color: grey;">Vehicle Details</h5>
  <div class="container"
  fxLayout
>
<div class="item item-1">
  <p class="card-title"><b>Partner Name</b><br>
    <span *ngIf="vehiclePaymentModel.response.id!=null">{{vehiclePaymentModel.response.vehicleId.partnerName}}</span>
    <span *ngIf="vehiclePaymentModel.response.id==null">{{vehiclePaymentModel.response.partnerName}}</span>
  </p>
</div>
<div class="item item-2" style="margin-left: 40px;">
  <p class="card-title"><b>Vehicle Number</b><br>
    <span *ngIf="vehiclePaymentModel.response.id!=null">{{vehiclePaymentModel.response.vehicleId.vehicleNumber}}</span>
    <span *ngIf="vehiclePaymentModel.response.id==null">{{vehiclePaymentModel.response.vehicleNumber}}</span>
</p>
</div>
<div class="item item-2" style="margin-left: 40px;">
  <p class="card-title"><b>Owner Name</b><br>
    <span *ngIf="vehiclePaymentModel.response.vehicleOwnerName!=null">{{vehiclePaymentModel.response.vehicleOwnerName}}</span>
    <span *ngIf="vehiclePaymentModel.response.vehicleOwnerName==null">{{vehiclePaymentModel.response.vehicleOwnerName}}</span>
</p>
</div>
<div class="item item-3" style="margin-left: 40px;">
  <p class="card-title"><b>Model Name</b>  <br>
    <span *ngIf="vehiclePaymentModel.response.id!=null">{{vehiclePaymentModel.response.vehicleId.vehicleName}}</span>
    <span *ngIf="vehiclePaymentModel.response.id==null">{{vehiclePaymentModel.response.vehicleName}}</span>
</p>
</div>
<div class="item item-4" style="margin-left: 40px;">
  <p class="card-title"><b>Fuel</b> <br>
    <span *ngIf="vehiclePaymentModel.response.id!=null">{{vehiclePaymentModel.response.vehicleId.fuelType.fuelTypeName}}</span>
    <span *ngIf="vehiclePaymentModel.response.id==null">{{vehiclePaymentModel.response.fuelType.fuelTypeName}}</span>
  </p>
</div>
<div class="item item-5" style="margin-left: 40px;">
  <p class="card-title"><b>Tank Capacity in litre</b> <br>
    <span *ngIf="vehiclePaymentModel.response.id!=null">{{vehiclePaymentModel.response.vehicleId.tankCapacity}}</span>
    <span *ngIf="vehiclePaymentModel.response.id==null">{{vehiclePaymentModel.response.tankCapacity}}</span>
  </p>
</div>
<div class="item item-6" style="margin-left: 40px;">
  <p class="card-title"><b>Available Fuel in litre</b><br>
    <span *ngIf="vehiclePaymentModel.response.id!=null">{{vehiclePaymentModel.response.vehicleId.fuelStock}}</span>
    <span *ngIf="vehiclePaymentModel.response.id==null">{{vehiclePaymentModel.response.fuelStock}}</span>
  </p>
</div>
<div class="item item-7" style="margin-left: 40px;">
  <p class="card-title"><b>Added Date & Time</b><br>
    <span *ngIf="vehiclePaymentModel.response.id!=null">{{vehiclePaymentModel.response.vehicleId.submittedDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</span>
    <span *ngIf="vehiclePaymentModel.response.id==null">{{vehiclePaymentModel.response.submittedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span>
  </p> 
</div>
<div class="item item-8" style="margin-left: 40px;">
  <p class="card-title"><b>Status</b><br>
    <span *ngIf="vehiclePaymentModel.response.id!=null">
      <span *ngIf="vehiclePaymentModel.response.vehicleId.vehicleStatus===3" class="v-align-middle ht-active">Active</span>
           <span *ngIf="vehiclePaymentModel.response.vehicleId.vehicleStatus===4" class="v-align-middle ht-inactive">InActive</span>
    </span>
    <span *ngIf="vehiclePaymentModel.response.id==null">
      <span *ngIf="vehiclePaymentModel.response.vehicleStatus===3" class="v-align-middle ht-active">Active</span>
            <span *ngIf="vehiclePaymentModel.response.vehicleStatus===4" class="v-align-middle ht-inactive">InActive</span>
    </span>
  </p> 
</div>
<div class="item item-9" style="margin-left: 40px;">
  <p class="card-title"><b>AMC/MMC Type</b><br>
    <span *ngIf="vehiclePaymentModel.response.id!=null">
      <span *ngIf="vehiclePaymentModel.response.vehicleId.amcMmcType!=null">{{vehiclePaymentModel.response.vehicleId.amcMmcType}}</span>
      <span *ngIf="vehiclePaymentModel.response.vehicleId.amcMmcType==null">N/A</span>
    </span>
    <span *ngIf="vehiclePaymentModel.response.id==null">
      <span *ngIf="vehiclePaymentModel.response.amcMmcType!=null">{{vehiclePaymentModel.response.amcMmcType}}</span>
      <span *ngIf="vehiclePaymentModel.response.amcMmcType==null">N/A</span>
    </span>
  </p> 
</div>
</div>
<div class="container"
  fxLayout
>
<div class="item item-10">
  <p class="card-title"><b>DSA Name</b><br>
    <span *ngIf="vehiclePaymentModel.response.id!=null">
      <span *ngIf="vehiclePaymentModel.response.vehicleId.assignDSA!=null">{{vehiclePaymentModel.response.vehicleId.assignDSA.firstName}} {{vehiclePaymentModel.response.vehicleId.assignDSA.lastName}}</span>
      <span *ngIf="vehiclePaymentModel.response.vehicleId.assignDSA==null">N/A</span>
    </span>
    <span *ngIf="vehiclePaymentModel.response.id==null">
      <span *ngIf="vehiclePaymentModel.response.assignDSA!=null">{{vehiclePaymentModel.response.assignDSA.firstName}} {{vehiclePaymentModel.response.assignDSA.lastName}}</span>
      <span *ngIf="vehiclePaymentModel.response.assignDSA==null">N/A</span>
    </span>
  </p> 
</div>
<div class="item item-11" style="margin-left: 40px;">
  <p class="card-title"><b>Activation Date</b><br>
    <span *ngIf="vehiclePaymentModel.response.id!=null">
      <span *ngIf="vehiclePaymentModel.response.vehicleId.activationDate!=null">{{vehiclePaymentModel.response.vehicleId.activationDate | date:'dd/MM/yyyy'}}</span>
      <span *ngIf="vehiclePaymentModel.response.vehicleId.activationDate==null">N/A</span>
    </span>
    <span *ngIf="vehiclePaymentModel.response.id==null">
      <span *ngIf="vehiclePaymentModel.response.activationDate!=null">{{vehiclePaymentModel.response.activationDate | date:'dd/MM/yyyy'}}</span>
      <span *ngIf="vehiclePaymentModel.response.activationDate==null">N/A</span>
    </span>
  </p> 
</div>
<div class="item item-11" style="margin-left: 40px;">
  <p class="card-title"><b>Vehicle On/Off Status</b><br>
    <span *ngIf="vehiclePaymentModel.response.id!=null">
      <span *ngIf="vehiclePaymentModel.response.vehicleId.vehicleonoffStatus!=null">
        <span *ngIf="vehiclePaymentModel.response.vehicleId.vehicleonoffStatus==1">On</span>
        <span *ngIf="vehiclePaymentModel.response.vehicleId.vehicleonoffStatus==0">Off</span>
      </span>
      <span *ngIf="vehiclePaymentModel.response.vehicleId.vehicleonoffStatus==null">N/A</span>
    </span>
    <span *ngIf="vehiclePaymentModel.response.id==null">
      <span *ngIf="vehiclePaymentModel.response.vehicleonoffStatus!=null">
        <span *ngIf="vehiclePaymentModel.response.vehicleonoffStatus==1">On</span>
        <span *ngIf="vehiclePaymentModel.response.vehicleonoffStatus==0">Off</span>
      </span>
      <span *ngIf="vehiclePaymentModel.response.vehicleonoffStatus==null">N/A</span>
    </span>
  </p> 
</div>
<div class="item item-7" style="margin-left: 40px;">
  <p class="card-title"><b>GPS Vehicle Number</b><br>
    <span *ngIf="vehiclePaymentModel.response.id!=null">
      <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsVehicleNumber!=null">{{vehiclePaymentModel.response.vehicleId.gpsVehicleNumber}}</span>
      <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsVehicleNumber==null">N/A</span>
    </span>
    <span *ngIf="vehiclePaymentModel.response.id==null">
      <span *ngIf="vehiclePaymentModel.response.gpsVehicleNumber!=null">{{vehiclePaymentModel.response.gpsVehicleNumber}}</span>
      <span *ngIf="vehiclePaymentModel.response.gpsVehicleNumber==null">N/A</span>
    </span>
  </p> 
</div>
<div class="item item-7" style="margin-left: 40px;">
  <p class="card-title"><b>GPS Serial Number</b><br>
    <span *ngIf="vehiclePaymentModel.response.id!=null">
      <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsSerialNumber!=null">{{vehiclePaymentModel.response.vehicleId.gpsSerialNumber}}</span>
      <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsSerialNumber==null">N/A</span>
    </span>
    <span *ngIf="vehiclePaymentModel.response.id==null">
      <span *ngIf="vehiclePaymentModel.response.gpsSerialNumber!=null">{{vehiclePaymentModel.response.gpsSerialNumber}}</span>
      <span *ngIf="vehiclePaymentModel.response.gpsSerialNumber==null">N/A</span>
      </span>
  </p> 
</div>
<div class="item item-7" style="margin-left: 40px;">
  <p class="card-title"><b>GPS Sim Number</b><br>
    <span *ngIf="vehiclePaymentModel.response.id!=null">
      <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsSimNumber!=null">{{vehiclePaymentModel.response.vehicleId.gpsSimNumber}}</span>
      <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsSimNumber==null">N/A</span>
    </span>
    <span *ngIf="vehiclePaymentModel.response.id==null">
      <span *ngIf="vehiclePaymentModel.response.gpsSimNumber!=null">{{vehiclePaymentModel.response.gpsSimNumber}}</span>
      <span *ngIf="vehiclePaymentModel.response.gpsSimNumber==null">N/A</span>
    </span>
  </p> 
</div>
<div class="item item-7" style="margin-left: 40px;">
  <p class="card-title"><b>GPS Unique ID</b><br>
    <span *ngIf="vehiclePaymentModel.response.id!=null">
      <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsUniqueId!=null"> {{vehiclePaymentModel.response.vehicleId.gpsUniqueId}}</span>
      <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsUniqueId==null"> N/A</span>
    </span>
    <span *ngIf="vehiclePaymentModel.response.id==null">
      <span *ngIf="vehiclePaymentModel.response.gpsUniqueId!=null">{{vehiclePaymentModel.response.gpsUniqueId}}</span>
      <span *ngIf="vehiclePaymentModel.response.gpsUniqueId==null">N/A</span>
    </span>
  </p> 
</div>
<div class="item item-7" style="margin-left: 40px;">
  <p class="card-title"><b>GPS Client ID</b><br>
    <span *ngIf="vehiclePaymentModel.response.id!=null">
      <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsClientId!=null">
        {{vehiclePaymentModel.response.vehicleId.gpsClientId}}
      </span>
      <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsClientId==null">N/A</span>
    </span>
    <span *ngIf="vehiclePaymentModel.response.id==null">
      <span *ngIf="vehiclePaymentModel.response.gpsClientId!=null">{{vehiclePaymentModel.response.gpsClientId}}</span>
      <span *ngIf="vehiclePaymentModel.response.gpsClientId==null">N/A</span>
    </span>
  </p> 
</div>
<div class="item item-7" style="margin-left: 40px;">
  <p class="card-title"><b>GPS Status</b><br>
    <span *ngIf="vehiclePaymentModel.response.id!=null">
      <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsStatus!=null">
      <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsStatus==11" class="ht-Success">Active</span>
      <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsStatus==12" class="ht-Pending">InActive</span>
      <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsStatus==0">N/A</span>
    </span>
    <span  *ngIf="vehiclePaymentModel.response.vehicleId.gpsStatus==null">N/A</span>
    </span>
    <span *ngIf="vehiclePaymentModel.response.id==null">
      <span *ngIf="vehiclePaymentModel.response.gpsStatus!=null">
      <span *ngIf="vehiclePaymentModel.response.gpsStatus==11" class="ht-Success" >Active</span>
      <span *ngIf="vehiclePaymentModel.response.gpsStatus==12" class="ht-Pending" >InActive</span>
      <span *ngIf="vehiclePaymentModel.response.gpsStatus==0">N/A</span>
    </span>
    <span  *ngIf="vehiclePaymentModel.response.gpsStatus==null">N/A</span>
    </span>
  </p> 
</div>
</div>
<h5 style="margin-left: 15px; text-align: left; color: grey;">Payment Details </h5>
  <div class="container"
  fxLayout
>
<!-- <div class="item item-1">
  <p class="card-title"><b>Payment Mode</b><br>
    <span *ngIf="vehiclePaymentModel.response.id!=null">
      <span *ngIf="vehiclePaymentModel.response.paymentMode!=null">{{vehiclePaymentModel.response.paymentMode}}</span>
      <span *ngIf="vehiclePaymentModel.response.paymentMode==null">N/A</span>
    </span>
    <span *ngIf="vehiclePaymentModel.response.id==null">
      N/A
    </span>
  </p>
</div> -->
<div class="item item-2">
  <p class="card-title"><b>Payment Amount</b><br>
    <span *ngIf="vehiclePaymentModel.response.id!=null">
      <span><i class="fa fa-inr" aria-hidden="true"></i>{{vehiclePaymentModel.response.totalPayableAmount | number : '1.2-2'}}</span>
    </span>
    <span *ngIf="vehiclePaymentModel.response.id==null">
      N/A
    </span>
  </p>
</div>
<div class="item item-3" style="margin-left: 40px;">
  <p class="card-title"><b>Transaction ID</b><br>
    <span *ngIf="vehiclePaymentModel.response.id!=null">
      <span *ngIf="vehiclePaymentModel.response.paymentStatus==='cancelled'; else ifNotShow" >N/A</span>
      <ng-template #ifNotShow>
        <span *ngIf="vehiclePaymentModel.response.paymentId==='null'">N/A</span>
        <span *ngIf="vehiclePaymentModel.response.paymentId!=null">{{vehiclePaymentModel.response.paymentId}}</span>
        <span *ngIf="vehiclePaymentModel.response.paymentId==null">N/A</span>
      </ng-template>
    </span>
    <span *ngIf="vehiclePaymentModel.response.id==null">
      N/A
    </span>
  </p>
</div>
<div class="item item-4" style="margin-left: 40px;">
  <p class="card-title"><b>Payment Status</b><br>
    <span *ngIf="vehiclePaymentModel.response.id!=null">
      <span class="v-align-middle ht-Success" *ngIf="vehiclePaymentModel.response.id!=null">
        <span *ngIf="vehiclePaymentModel.response.paymentStatus!=null">
          <span *ngIf="vehiclePaymentModel.response.paymentStatus==='Success' "class="v-align-middle ht-active">{{vehiclePaymentModel.response.paymentStatus}}</span>
          <span *ngIf="vehiclePaymentModel.response.paymentStatus==='Failure' " style="color: red;">Failed</span>
          <span *ngIf="vehiclePaymentModel.response.paymentStatus==='Initiated' "class="v-align-middle ht-Pending">{{vehiclePaymentModel.response.paymentStatus}}</span>
          <span *ngIf="vehiclePaymentModel.response.paymentStatus==='Pending' "class="v-align-middle ht-Pending">{{vehiclePaymentModel.response.paymentStatus}}</span>
          <span *ngIf="vehiclePaymentModel.response.paymentStatus==='cancelled' " style="color: red;">{{vehiclePaymentModel.response.paymentStatus}}</span>
        </span>
        <span *ngIf="vehiclePaymentModel.response.paymentStatus==null">N/A</span>        
      </span>
    </span>
    <span *ngIf="vehiclePaymentModel.response.id==null">
      N/A
    </span>
  </p>
</div>
<div class="item item-5" style="margin-left: 40px;">
  <p class="card-title"><b>Transaction Date & Time</b><br>
    <span *ngIf="vehiclePaymentModel.response.id!=null">
      <span *ngIf="vehiclePaymentModel.response.transactionDatetime!=null">{{vehiclePaymentModel.response.transactionDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span>
      <span *ngIf="vehiclePaymentModel.response.transactionDatetime==null">N/A</span>
    </span>
    <span *ngIf="vehiclePaymentModel.response.id==null">N/A</span>
  </p>
</div>
  </div>
  <!-- <div class="modal-body">
    <div class="wrapper">
    	<div class="flex extradiv">
        <h5>Vehicle Details </h5>
         <p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title">Partner Name<br><span><b>{{vehiclePaymentModel.response.vehicleId.partnerName}} </b></span></p>
         <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title">Partner Name<br><span><b>{{vehiclePaymentModel.response.partnerName}}</b></span></p>
         <p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title">Vehicle Number   <br><span><b>{{vehiclePaymentModel.response.vehicleId.vehicleNumber}}</b></span></p>
         <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title">Vehicle Number   <br><span><b>{{vehiclePaymentModel.response.vehicleNumber}}</b></span></p>
   		  <p  *ngIf="vehiclePaymentModel.response.id!=null" class="card-title">Model Name  
           <br>
           <span><b>{{vehiclePaymentModel.response.vehicleId.vehicleName}}</b></span></p>
           <p  *ngIf="vehiclePaymentModel.response.id==null" class="card-title">Model Name  
            <br>
            <span><b>{{vehiclePaymentModel.response.vehicleName}}</b></span></p>

         <p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title">Fuel  <br><span><b>{{vehiclePaymentModel.response.vehicleId.fuelType.fuelTypeName}}</b></span></p>
         <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title">Fuel  <br><span><b>{{vehiclePaymentModel.response.fuelType.fuelTypeName}}</b></span></p>
         
   		  <p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title">Tank Capacity in litre <br>
           <span><b>{{vehiclePaymentModel.response.vehicleId.tankCapacity}}</b></span></p>
           <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title">Tank Capacity in litre <br>
            <span><b>{{vehiclePaymentModel.response.tankCapacity}}</b></span></p>  

			<p class="card-title">Available Fuel in litre <br>
   		    <span><b>-</b></span></p>
			<p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title">Added Date & Time <br>
           <span><b>{{vehiclePaymentModel.response.vehicleId.submittedDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</b></span></p>
           <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title">Added Date & Time <br>
            <span><b>{{vehiclePaymentModel.response.submittedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</b></span></p>

			<p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title">Status <br>
           <span *ngIf="vehiclePaymentModel.response.vehicleId.vehicleStatus===3" class="v-align-middle ht-active"><b>Active</b></span>
           <span *ngIf="vehiclePaymentModel.response.vehicleId.vehicleStatus===4" class="v-align-middle ht-inactive"><b>InActive</b></span>
          </p>
           <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title">Status <br>
            <span *ngIf="vehiclePaymentModel.response.vehicleStatus===3" class="v-align-middle ht-active"><b>Active</b></span>
            <span *ngIf="vehiclePaymentModel.response.vehicleStatus===4" class="v-align-middle ht-inactive"><b>InActive</b></span>
          </p>
          <p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title">AMC/MMC Type <br>
            <span *ngIf="vehiclePaymentModel.response.vehicleId.amcMmcType!=null"><b>{{vehiclePaymentModel.response.vehicleId.amcMmcType}}</b></span>
            <span *ngIf="vehiclePaymentModel.response.vehicleId.amcMmcType==null"><b>N/A</b></span>
          </p>
            <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title">AMC/MMC Type <br>
             <span *ngIf="vehiclePaymentModel.response.amcMmcType!=null"><b>{{vehiclePaymentModel.response.amcMmcType}}</b></span>
             <span *ngIf="vehiclePaymentModel.response.amcMmcType==null"><b>N/A</b></span>
            </p>  
            <p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title">DSA Name <br>
              <span *ngIf="vehiclePaymentModel.response.vehicleId.assignDSA!=null"><b>{{vehiclePaymentModel.response.vehicleId.assignDSA.firstName}} {{vehiclePaymentModel.response.vehicleId.assignDSA.lastName}}</b></span>
              <span *ngIf="vehiclePaymentModel.response.vehicleId.assignDSA==null"><b>N/A</b></span>
            </p>
              <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title">DSA Name <br>
               <span *ngIf="vehiclePaymentModel.response.assignDSA!=null"><b>{{vehiclePaymentModel.response.assignDSA.firstName}} {{vehiclePaymentModel.response.assignDSA.lastName}}</b></span>
               <span *ngIf="vehiclePaymentModel.response.assignDSA==null"><b>N/A</b></span>
              </p>  
    	</div>
    	<div class="flex extradiv">
        <h5>Payment Details </h5>
        <p class="card-title">Payment Mode   <br>
          <span *ngIf="vehiclePaymentModel.response.id!=null">
            <b *ngIf="vehiclePaymentModel.response.paymentMode!=null">{{vehiclePaymentModel.response.paymentMode}}</b>
            <b *ngIf="vehiclePaymentModel.response.paymentMode==null">N/A</b>
          </span>
          <span *ngIf="vehiclePaymentModel.response.id==null"><b>-</b></span>
        </p>
        <p class="card-title">Payment Amount   <br>
          <span *ngIf="vehiclePaymentModel.response.id!=null"><b><i class="fa fa-inr" aria-hidden="true"></i> {{vehiclePaymentModel.response.totalPayableAmount | number : '1.2-2'}}</b></span>
          <span *ngIf="vehiclePaymentModel.response.id==null"><b>-</b></span>
        </p>
        <p class="card-title">Transaction ID  <br>
          <span *ngIf="vehiclePaymentModel.response.id!=null"><b>{{vehiclePaymentModel.response.paymentId}}</b></span>
          <span *ngIf="vehiclePaymentModel.response.id==null"><b>-</b></span>
        </p>
        <p class="card-title">Payment Status   <br>
          <span class="v-align-middle ht-Success" *ngIf="vehiclePaymentModel.response.id!=null"><b>{{vehiclePaymentModel.response.paymentStatus}}</b></span>
          <span *ngIf="vehiclePaymentModel.response.id==null"><b>-</b></span>
        </p> 
			<p class="card-title">Transaction Date & Time   <br>
        <b *ngIf="vehiclePaymentModel.response.id!=null">{{vehiclePaymentModel.response.transactionDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</b>
        <b *ngIf="vehiclePaymentModel.response.id==null">-</b>
      </p>
    	</div>
    </div>
  </div> -->
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>

<ng-template #content1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Vehicle Activation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <div class="modal-body master-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Api Key</label>
        <span class="required-asterisk">*</span>
        <input type="text" id="apiKey" name="apiKey" class="form-control"  placeholder="">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Salt Key</label>
        <span class="required-asterisk">*</span>
        <input type="text" id="saltKey" name="saltKey" class="form-control"  placeholder="">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Bank Code</label>
        <span class="required-asterisk">*</span>
        <input type="text" id="bankCode" name="bankCode" class="form-control"  placeholder="">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">MID</label>
        <span class="required-asterisk">*</span>
        <input type="text" id="mid" name="mid" class="form-control"  placeholder="">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">TID</label>
        <span class="required-asterisk">*</span>
        <input type="text" id="tid" name="tid" class="form-control"  placeholder="">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">GPS Vehicle Number</label>
        <input type="text" id="gpsVehicleNumber" name="gpsVehicleNumber" class="form-control"  placeholder="">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">GPS Serial Number</label>
        <input type="text" id="gpsSerialNumber" name="gpsSerialNumber" class="form-control"  placeholder="">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">GPS Sim Number</label>
        <input type="text" id="gpsSimNumber" name="gpsSimNumber" class="form-control"  placeholder="">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">GPS Unique ID</label>
        <input type="text" id="gpsUniqueId" name="gpsUniqueId" class="form-control"  placeholder="">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">GPS Client ID</label>
        <input type="text" id="gpsClientId" name="gpsClientId" class="form-control"  placeholder="">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">GPS Status</label>
    <select id="gpsStatus" name="gpsStatus" class="form-control">
      <option value=11>Active</option>
      <option value=12 selected>Inactive</option>
  </select>
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Activation Date</label>
        <span class="required-asterisk">*</span>
        <input type="Date" class="form-control" id="date" formControlName="date" name="date" aria-describedby="emailHelp" placeholder="Select Role" required>
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Onboarded By</label>
        <span class="required-asterisk">*</span>
    <select id="vehicleonboardedby" name="vehicleonboardedby" class="form-control">
      <option value=0 >Select</option>
      <option value=1 >CinchFuel</option>
      <option value=2 >Others</option>
  </select>
      </div>
    </form>
    <div class="alert alert-danger" role="alert"
 *ngIf="alertFlag">{{message}}</div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activate()">Activate</button>
  </div>
</ng-template>

<ng-template #content2 let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Assign DSA</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">&times;</span> </button>
    </div>
    <div class="modal-body master-body">
    <form [formGroup]="myForm">
        <div class="ch-form-group">
          <label><b>Select DSA</b></label>
          <div class="dropdown">
            <select id="dsaId"  name="dsaId" class="form-control" #mySelect
            (change)='onOptionsSelected(mySelect.value)'>
              <option *ngFor="let dsa of dsalistModel.response" value={{dsa.userId}}>{{dsa.firstName}} {{dsa.lastName}}</option>
            </select></div>
        </div>
        <br>
        <div class="ch-form-group">
          <label><b>Mobile Number</b></label>
          <p *ngIf="dsa!=null">{{dsa.mobileNumber}}</p>
          <p *ngIf="dsa==null">{{dsaMobile}}</p>
        </div>
        <div class="ch-form-group">
          <label><b>Email Address</b></label>
          <p *ngIf="dsa!=null">{{dsa.emailAddress}}</p>
          <p *ngIf="dsa==null">{{dsaName}}</p>
        </div>
        </form>
        <br>
        <div *ngIf="emptyCheck" style="color: red;">{{emptyCheckResponse}}</div>
      </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" style="color: green;" (click)="assignDsa()">Assign DSA</button>
    </div>
</ng-template>

<router-outlet></router-outlet>
