import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericResponseModel } from '../model/genricresponse';
import { VehicleFuelService } from '../services/vehivle.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { VehicleAttachModel } from '../model/vehicleModel';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { UserService } from '../services/user.service';
import { UserModel } from '../model/user';
import { GenericResponse } from '../model/genricmodelresponse';
import * as moment from 'moment';

@Component({
  selector: 'app-vehicles-fuels',
  templateUrl: './vehicles-fuels.component.html',
  styleUrls: ['./vehicles-fuels.component.css']
})
export class VehiclesFuelsComponent implements OnInit {
  vehicleFuleModel:GenericResponse;
  vehicleFuleModelOne:GenericResponse;
  vehiclePaymentModel:GenericResponseModel;
  public isCollapsed = false;
  failureflag:boolean;
  alertFlag:boolean;
  message:String;
  vehicleModel:VehicleAttachModel;
  vehicleId:Number;
  successflag:boolean;
  userId:Number;
  active = 1;
  defaultvehicle : boolean = false;
  activateVehicle : boolean = true;
  inactivateVehicle : boolean = false;
  activatevehicleFuleModel:GenericResponseModel;
  inactivatevehicleFuleModel:GenericResponseModel;
  dsalistModel:GenericResponseModel;
  vehicleid:Number;
  dsaModel:UserModel;
  dsaName:String;
  dsaMobile:Number;
  emptyCheck:boolean;
  emptyCheckResponse:String;
  dsa:Object;
  successalert:boolean = false;
  successmessage:String;
closeResult = '';
startDate:String = null;
  filterflag:boolean = false;
endDate:String = null;
refreshFlag:boolean = false;
errorFlag:boolean = false;
  errorFlag1:boolean = false;
  errorMessage:String;
  vehicleResponseFilter:GenericResponse;
  vehicleResponseFilterNoRecordFound:GenericResponse;
  filteronesearch:boolean= false;
  constructor(private modalService: NgbModal,private vehicleService: VehicleFuelService,private route: ActivatedRoute,private router: Router,private userservice:UserService) { }

  ngOnInit(): void {
    var nowdate = new Date();
    var monthStartDay = new Date(nowdate.getFullYear(), nowdate.getMonth(), 1);
    var monthEndDay = new Date(nowdate.getFullYear(), nowdate.getMonth() + 1, 0);
    let startDate = moment(new Date(monthStartDay)).format("yyyy-MM-DD").toString();
    let endDate = moment(new Date(monthEndDay)).format("yyyy-MM-DD").toString();
    this.startDate = startDate;
    this.endDate = endDate;

    this.vehicleService.getVehicleLists().subscribe(data=>{
      this.vehicleFuleModel = data;
    });

    this.vehicleService.getVehicleList(startDate,endDate).subscribe(data=>{
      this.vehicleFuleModelOne = data;
    })
    this.vehicleService.getvehicleActive().subscribe(data=>{
      this.activatevehicleFuleModel = data;
    },err => {
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }else if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });

    
    this.route.queryParams.subscribe(params => {
      let value = params.success;
        if(value){
          this.successflag=true;
          this.message = params.message;
        }
        setTimeout(function() {
          this.successflag = false;
          console.log(this.successflag);
      }.bind(this), 5000);
     
    });
  }
  
  open(content,id) {
    this.router.navigate(['/vehicles-view']);
    sessionStorage.setItem("vehicleid",id);
      this.vehicleService.getVehicleByPayment(id).subscribe(data=>{
        this.vehiclePaymentModel= data;
        console.log(this.vehiclePaymentModel);
      })
    // this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    // });
  }

  vehicleOnOffStatus(id,status,price){
    if(Number(price==0)){
      Swal.fire({
        html: '<pre>' + "Fuel price is not updated for current day" + '</pre>',
        customClass: {
          popup: 'format-pre'
        }
      }).then((value) => {
        window.location.reload();
      });; ; 
    }else{
      this.vehicleService.vehicleonoffstatus(id,status,sessionStorage.getItem("username")).subscribe(data=>{
        if(data.statusCode==200){
          Swal.fire({
            html: '<pre>' + data.responseMessage + '</pre>',
            customClass: {
              popup: 'format-pre'
            }
          }).then((value) => {
            window.location.reload();
          });; ; 
        }
      },err => {
        if(err.error.statusCode==406){
          this.router.navigate(['/login']);
        }else if(err.error.statusCode==500){
          this.router.navigate(['/page-error']);
        }else if(err.error.statusCode==404){
          this.router.navigate(['/pagenotfound']);
        }
     });   
    }
    
  }

  vehicleactiveinactiveStatus(id,status){
    this.vehicleService.vehicleactiveinactivestatus(id,status,sessionStorage.getItem("username")).subscribe(data=>{
      if(data.statusCode==200){
        Swal.fire({
          html: '<pre>' + data.responseMessage + '</pre>',
          customClass: {
            popup: 'format-pre'
          }
        }).then((value) => {
          window.location.reload();
        });; ; 
      }
    },err => {
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }else if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });
  }

  open1(content2,id) {
    this.vehicleid = id ;
      this.vehicleService.getDSA().subscribe(data=>{
        this.dsalistModel = data;
        this.dsa =  data.response[0];
      });
  this.modalService.open(content2, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy(): void {
  }

  callActive(content1,id){
    this.vehicleId = id;
    this.vehicleService.getVehicleById(this.vehicleId).subscribe(data=>{
      this.vehicleModel = data;
      this.userId = this.vehicleModel.userId.userId;
    },err => {
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }else if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });
    this.vehicleService.checkActivation(id).subscribe(data=>{
      if(data.response.flag==1){
        this.vehicleService.getVehicleApiKey(id).subscribe(respons=>{
          if(respons.flag==2){
            this.failureflag = false;
            this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
            var date = new Date().toISOString().slice(0,10);
      document.getElementById('date').setAttribute('min', date);
          }else{
            this.failureflag = true;
            this.message = respons.message;
            Swal.fire({
              html: '<pre>' + this.message + '</pre>',
              customClass: {
                popup: 'format-pre'
              }
            });
            setTimeout(function() {
              this.failureflag = false;
          }.bind(this), 5000);
          }
        });
      }else{
        this.failureflag = true;
        this.message = data.response.message;
        Swal.fire({
          html: '<pre>' + this.message + '</pre>',
          customClass: {
            popup: 'format-pre'
          }
        }).then((value) => {
          window.location.reload();
        });; ; 
      }
    })
  }

  activate(){
    let date = (<HTMLInputElement>document.getElementById('date')).value;
    let apiKey = (<HTMLInputElement>document.getElementById('apiKey')).value;
    let saltKey = (<HTMLInputElement>document.getElementById('saltKey')).value;
    let bankCode = (<HTMLInputElement>document.getElementById('bankCode')).value;
    let mid = (<HTMLInputElement>document.getElementById('mid')).value;
    let tid = (<HTMLInputElement>document.getElementById('tid')).value;

    let gpsVehicleNumber = (<HTMLInputElement>document.getElementById('gpsVehicleNumber')).value;
    let gpsSerialNumber = (<HTMLInputElement>document.getElementById('gpsSerialNumber')).value;
    let gpsSimNumber = (<HTMLInputElement>document.getElementById('gpsSimNumber')).value;
    let gpsUniqueId = (<HTMLInputElement>document.getElementById('gpsUniqueId')).value;
    let gpsClientId = (<HTMLInputElement>document.getElementById('gpsClientId')).value;
    let gpsStatus = (<HTMLInputElement>document.getElementById('gpsStatus')).value;

    let vehicleonboardedby = (<HTMLInputElement>document.getElementById('vehicleonboardedby')).value;

    if(date!=="" && apiKey!=="" && saltKey!=="" && bankCode!=="" && mid!=="" && tid!=="" && vehicleonboardedby!=null && vehicleonboardedby!==""){

      if(vehicleonboardedby=='0'){
        this.alertFlag = true;
        this.message="Please select onboarded by!";
        setTimeout(function() {
          this.alertFlag = false;
      }.bind(this), 5000);
      }else{
        this.alertFlag = false;
        let formData= {
          activateDate:date,
          apiKey:apiKey,
          saltKey:saltKey,
          bankCode:bankCode,
          modifiedBy:sessionStorage.getItem("username"),
          vehicleId:this.vehicleId,
          partnerId:this.userId,
          mid:mid,
          tid:tid,
          gpsVehicleNumber:gpsVehicleNumber,
          gpsSerialNumber:gpsSerialNumber,
          gpsSimNumber:gpsSimNumber,
          gpsUniqueId:gpsUniqueId,
          gpsClientId:gpsClientId,
          gpsStatus:gpsStatus,
          vehicleonboardedby:vehicleonboardedby
        }
       
          this.vehicleService.activateVehicle(formData).subscribe(data=>{
            if(data.response.flag==1){
              this.modalService.dismissAll();
              Swal.fire({
                html: '<pre>' + data.response.message + '</pre>',
                customClass: {
                  popup: 'format-pre'
                }
              }).then((value) => {
                window.location.reload();
              });; ; 
            }else{
            }
          },err => {
            if(err.error.statusCode==406){
              this.router.navigate(['/login']);
            }else if(err.error.statusCode==500){
              this.router.navigate(['/page-error']);
            }else if(err.error.statusCode==404){
              this.router.navigate(['/pagenotfound']);
            }
         });
      }
    }else{
      this.alertFlag = true;
      this.message="Please enter mandatory fields!";
      setTimeout(function() {
        this.alertFlag = false;
    }.bind(this), 5000);
    }
  }

  activeVehicle(){
    this.defaultvehicle = false;
    this.activateVehicle = true;
    this.inactivateVehicle = false;

    this.vehicleService.getvehicleActive().subscribe(data=>{
      this.activatevehicleFuleModel = data;
    });
  }

  inactiveVehicle(){
    this.defaultvehicle = false;
    this.activateVehicle = false;
    this.inactivateVehicle = true;
    this.vehicleService.getvehicleInactive().subscribe(data=>{
      this.inactivatevehicleFuleModel = data;
    });
  }

  assignDsa(){
    let dsaId = (<HTMLInputElement>document.getElementById('dsaId')).value;
    if(dsaId!=null && dsaId!==''){
      this.vehicleService.assinDSA(this.vehicleid,dsaId).subscribe(data=>{
        this.modalService.dismissAll();
            Swal.fire({
              html: '<pre>' + data.responseMessage + '</pre>',
              customClass: {
                popup: 'format-pre'
              }
            });
        setTimeout(function() {
          window.location.reload();
      }.bind(this), 3000);
      },err => {
        if(err.error.statusCode==406){
          this.router.navigate(['/login']);
        }else if(err.error.statusCode==500){
          this.router.navigate(['/page-error']);
        }else if(err.error.statusCode==404){
          this.router.navigate(['/pagenotfound']);
        }
     });
    }else{
        this.emptyCheck = true;
        this.emptyCheckResponse = "Please select dsa!";
        setTimeout(function() {
          this.emptyCheck = false;
      }.bind(this), 3000);
    }
  }

  onOptionsSelected(value:Number){
    this.dsa = null
    this.userservice.getUserInfor(value).subscribe(data=>{
      this.dsaModel = data;
      this.dsaName = this.dsaModel.emailAddress;
      this.dsaMobile = this.dsaModel.mobileNumber;
    })
  }

  search(){
    this.refreshFlag = true;
    let fromdate = (<HTMLInputElement>document.getElementById('fromdate')).value;
    let todate = (<HTMLInputElement>document.getElementById('todate')).value;
    if(fromdate==''||todate==''){
      this.errorFlag = true;
      this.errorMessage = "Please select Dates!";
    }else if(todate < fromdate){
      this.errorFlag = true;
      this.errorMessage = "End Date should be greater than Start Date!";
    }else{
      this.errorFlag = false;
      this.filterflag = true;
      this.vehicleService.getVehicleList(fromdate,todate).subscribe(data=>{
        if(data.statusCode==200){
          this.filteronesearch = false;
          this.vehicleResponseFilter = data;
        }else{
          this.filteronesearch = true;
          this.vehicleResponseFilterNoRecordFound = data;
        }
        
      })
    }
  }

  refresh(){
    this.refreshFlag = false;
    window.location.reload();
  }

}
