import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { data } from 'jquery';
import { ChangePasswordModel } from '../model/changepassword';
import { ChangePasswordService } from '../services/changepassword.service';
import { ForgotPasswordService } from '../services/forgot.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  isError = false;
  errormessage : String;
  email : String;
  fieldTextType: boolean;
  fieldTextType1: boolean;
  constructor(private route: ActivatedRoute,private forgotpassword: ForgotPasswordService,private router: Router) { }

  ngOnInit(): void {

    let useremail = String(sessionStorage.getItem('useremail'));
    if(useremail!=null && useremail!==''){
      this.email = useremail;
    }else{
      this.route.queryParams.subscribe(params => {
        this.email= params.emailId;
      });
    }

    
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleFieldTextType1() {
    this.fieldTextType1 = !this.fieldTextType1;
  }

  changePassword(){
    let newpassword = (<HTMLInputElement>document.getElementById('newpassword')).value;
    let confirmpassword = (<HTMLInputElement>document.getElementById('confirmpassword')).value;
    let change = new ChangePasswordModel("",newpassword,"web","","",this.email);
    if(newpassword !=="" && confirmpassword){
       if(newpassword === confirmpassword){
          this.forgotpassword.changePassword(change).subscribe(data=>{
            if(data.flag==1){
              this.router.navigate(['/login']);
            }else{
              this.isError = true;
              this.errormessage = data.message;      
            }
          });
       }else{
        this.isError = true;
        this.errormessage = "New Password and confirm password Not Same";
       }

    }else{
      this.isError = true;
      this.errormessage = "Please Enter New Password && Confirm Password Fields";
    }

  }

}
