export class OrderAssignModel{
    assignedTo: number;
    orderAssignedBy: String;
    vehicleLatitude:String;
    vehicleLongitude:String;
    constructor(assignedTo: number, orderAssignedBy: String,vehicleLatitude:String,vehicleLongitude:String){
    this.assignedTo = assignedTo;
    this.orderAssignedBy = orderAssignedBy;
    this.vehicleLatitude= vehicleLatitude;
    this.vehicleLongitude= vehicleLongitude;
    }
    }