export class BankModel{
    approvalStatus: number;
    approvalComments: String;
    approvedBy:String;
    approvalBy:String;
    kycexpiryDate:Date;
    constructor(approvalStatus: number, approvalComments: String,approvedBy:String,approvalBy:String,kycexpiryDate:Date){
    this.approvalStatus = approvalStatus;
    this.approvalComments = approvalComments;
    this.approvedBy= approvedBy;
    this.approvalBy= approvalBy;
    this.kycexpiryDate = kycexpiryDate;
    }
    }