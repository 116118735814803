import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChangePasswordService } from '../services/changepassword.service';

@Component({
  selector: 'app-profile-change-password',
  templateUrl: './profile-change-password.component.html',
  styleUrls: ['./profile-change-password.component.css']
})
export class ProfileChangePasswordComponent implements OnInit {

  check:boolean;
  errorflag:boolean;
  successflag:boolean;
  errorMessage:String;
  fieldTextType: boolean;

  constructor(private router: Router,private changepasswordservice:ChangePasswordService) {}

  ngOnInit(): void {
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  
  onSubmit(){
    let currentpassowrd = (<HTMLInputElement>document.getElementById('currentpassowrd')).value;
    let newpassword = (<HTMLInputElement>document.getElementById('newpassword')).value;
    let confirmpassword = (<HTMLInputElement>document.getElementById('confirmpassword')).value;
    if(currentpassowrd!=="" && newpassword!=="" && confirmpassword!=="" ){
      if(newpassword===confirmpassword){
        this.check = false;
      }else{
        this.check = true;
        setTimeout(function() {
          this.check = false;
      }.bind(this), 5000);
      }

      if(!this.check){
        let id = Number(sessionStorage.getItem('id'));
        let formData= {
          userPassword:currentpassowrd,
          newPassword:newpassword,
          deviceType:"Web",
          modifiedBy:sessionStorage.getItem("username")
        }
        this.changepasswordservice.changePassword(id,formData).subscribe(data=>{
          if(data.flag===1){
            this.errorflag=false;
            this.successflag = true;
            this.errorMessage = data.message;
            this.router.navigate(['/login']);
            setTimeout(function() {
              this.successflag = false;
          }.bind(this), 5000);
  
          }else{
            this.errorflag=true;
            this.errorMessage = data.message;
            this.successflag = false;
  
            setTimeout(function() {
              this.errorflag = false;
          }.bind(this), 5000);
          }
        });
      }
    }else{
      this.errorflag=true;
      this.errorMessage = "Mandatory Fields are Required!";
      setTimeout(function() {
        this.errorflag = false;
    }.bind(this), 5000);
    }
  }

}
