import { HTTP_INTERCEPTORS ,HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from "rxjs";
import { TokenStorageService } from './token-storage.service';
import { environment } from 'src/environments/environment';
 
const TOKEN_HEADER_KEY = 'X_ACCESS_TOKEN';
 
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
 
    constructor(private token: TokenStorageService) { }
    // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //     throw new Error('Method not implemented.');
    // }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(req.url.match('adminlogin/web')){
            let authReq = req;
                authReq = req.clone({ headers: req.headers.set('Authorization', 'Basic ' + btoa("cinchfuel" + ':' + "cinch@2020"))});
            return next.handle(req);
        }else{
            const token = this.token.getToken();
            if (token != null) {
                // authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
                req = req.clone({
                    setHeaders: {
                        'X_ACCESS_TOKEN': `${token}`
                    }
                  });
            }
            return next.handle(req);
        }
    }
}
 
export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];