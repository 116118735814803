<!-- START PAGE-CONTAINER -->
<div class="header p-r-0 bg-primary">
  <div class="header-inner header-md-height"> <a href="#" class="btn-link toggle-sidebar d-lg-none text-white sm-p-l-0 btn-icon-link" data-toggle="horizontal-menu"> <i class="pg-icon">menu</i> </a>
    <div class="">
      <div class="brand inline no-border d-sm-inline-block"> 
        <img src="assets/img/cinch_fuel_white_logo.png" alt="logo" data-src="assets/img/cinch_fuel_white_logo.png" data-src-retina="assets/img/cinch_fuel_white_logo.png" style="width: 125px" > 
        <!-- <img class="modal-content"  [src]="imageToShow" style="width: 125px" alt="Place image title"> -->
      </div>
      <!-- START NOTIFICATION LIST -->
      <ul class="d-lg-inline-block d-none  notification-list no-margin b-grey b-l b-r no-style p-l-30 p-r-20">
        <li class="p-r-10 inline">
          <div class="dropdown"> <a href="javascript:;" id="notification-center" class="header-icon btn-icon-link" data-toggle="dropdown"> <i class="pg-icon">world</i> <span class="bubble"></span> </a> 
            <!-- START Notification Dropdown -->
            <div class="dropdown-menu notification-toggle" role="menu" aria-labelledby="notification-center"> 
              <!-- START Notification -->
              <div class="notification-panel"> 
                <!-- START Notification Body-->
                <div class="notification-body scrollable"> 
                  <!-- START Notification Item-->
                  <div class="notification-item unread clearfix"> 
                    <!-- START Notification Item-->
                    <div class="heading open"> <a href="#" class="text-complete pull-left d-flex align-items-center"> <i class="pg-icon m-r-10">map</i> <span class="bold">Carrot Design</span> <span class="fs-12 m-l-10">Welocme Super Admin</span> </a>
                      <div class="pull-right">
                        <div class="thumbnail-wrapper d16 circular inline m-t-15 m-r-10 toggle-more-details">
                          <div><i class="pg-icon">chevron_down</i> </div>
                        </div>
                        <span class=" time">few sec ago</span> </div>
                      <div class="more-details">
                        <div class="more-details-inner">
                          <h5 class="semi-bold fs-16">“Apple’s Motivation - Innovation <br>
                            distinguishes between <br>
                            A leader and a follower.”</h5>
                          <p class="small hint-text"> Commented on john Smiths wall. <br>
                            via pages framework. </p>
                        </div>
                      </div>
                    </div>
                    <!-- END Notification Item--> 
                    <!-- START Notification Item Right Side-->
                    <div class="option" data-toggle="tooltip" data-placement="left" title="mark as read"> <a href="#" class="mark"></a> </div>
                    <!-- END Notification Item Right Side--> 
                  </div>
                  <!-- START Notification Body--> 
                  <!-- START Notification Item-->
                  <div class="notification-item  clearfix">
                    <div class="heading"> <a href="#" class="text-danger pull-left"> <i class="pg-icon m-r-10">alert_warning</i> <span class="bold">98% Server Load</span> <span class="fs-12 m-l-10">Take Action</span> </a> <span class="pull-right time">2 mins ago</span> </div>
                    <!-- START Notification Item Right Side-->
                    <div class="option"> <a href="#" class="mark"></a> </div>
                    <!-- END Notification Item Right Side--> 
                  </div>
                  <!-- END Notification Item--> 
                  <!-- START Notification Item-->
                  <div class="notification-item  clearfix">
                    <div class="heading"> <a href="#" class="text-warning pull-left"> <i class="pg-icon m-r-10">alert_warning</i> <span class="bold">Warning Notification</span> <span class="fs-12 m-l-10">Buy Now</span> </a> <span class="pull-right time">yesterday</span> </div>
                    <!-- START Notification Item Right Side-->
                    <div class="option"> <a href="#" class="mark"></a> </div>
                    <!-- END Notification Item Right Side--> 
                  </div>
                  <!-- END Notification Item--> 
                  <!-- START Notification Item-->
                  <div class="notification-item unread clearfix">
                    <div class="heading">
                      <div class="thumbnail-wrapper d24 circular b-white m-r-5 b-a b-white m-t-10 m-r-10"> <img width="30" height="30" data-src-retina="assets/img/profiles/1x.jpg" data-src="assets/img/profiles/1.jpg" alt="" src="assets/img/profiles/1.jpg"> </div>
                      <a href="#" class="text-complete pull-left"> <span class="bold">Revox Design Labs</span> <span class="fs-12 m-l-10">Owners</span> </a> <span class="pull-right time">11:00pm</span> </div>
                    <!-- START Notification Item Right Side-->
                    <div class="option" data-toggle="tooltip" data-placement="left" title="mark as read"> <a href="#" class="mark"></a> </div>
                    <!-- END Notification Item Right Side--> 
                  </div>
                  <!-- END Notification Item--> 
                </div>
                <!-- END Notification Body--> 
                <!-- START Notification Footer-->
                <div class="notification-footer text-center"> <a href="#" class="">Read all notifications</a> <a data-toggle="refresh" class="portlet-refresh text-black pull-right" href="#"> <i class="pg-refresh_new"></i> </a> </div>
                <!-- START Notification Footer--> 
              </div>
              <!-- END Notification --> 
            </div>
            <!-- END Notification Dropdown --> 
          </div>
        </li>
        <li class="p-r-10 inline"> <a href="#" class="header-icon btn-icon-link"> <i class="pg-icon">link_alt</i> </a> </li>
        <li class="p-r-10 inline"> <a href="#" class="header-icon btn-icon-link"> <i class="pg-icon">grid_alt</i> </a> </li>
      </ul>
      <!-- END NOTIFICATIONS LIST --> 
      <a href="#" class="search-link d-lg-inline-block d-none" data-toggle="search"><i class="pg-icon">search</i>Type
      anywhere to <span class="bold">search</span></a> </div>
    <div class="d-flex align-items-center"> 
      <!-- START User Info-->
      <div class="pull-left p-r-10 fs-14 d-lg-inline-block d-none text-white"> <span class="semi-bold">Welcome {{name}}</span> </div>
      <div class="dropdown pull-right d-lg-block">
        <button class="profile-dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-label="profile dropdown"> <span class="thumbnail-wrapper d32 circular inline"> 
          <img src="assets/img/profile.jpg" alt="logo" data-src="assets/img/profile.jpg" data-src-retina="assets/img/profile.jpg" width="32" height="32"> 
          <!-- <img class="modal-content"  [src]="imageToShow" width="40" height="40"> -->
        </span> </button>
        <div class="dropdown-menu dropdown-menu-right profile-dropdown" ng-hide="hideSubmenu" role="menu"> 
          <a class="dropdown-item"><span>Signed in as <br />
          <b>{{name}}</b></span></a>
          <div class="dropdown-divider"></div>
          <a href="#" routerLink="/user-management-view" class="dropdown-item">Your Profile</a> <!-- <a href="#" class="dropdown-item">Your Activity</a> <a href="#" class="dropdown-item">Your Archive</a> -->
          <div class="dropdown-divider"></div>
          <a href="#" routerLink="/profile-change-password"  class="dropdown-item">Change Password</a> 
          <a href="#" (click)="logout()" class="dropdown-item">Logout</a>
          <div class="dropdown-divider"></div>
         </div>
      </div>
      <!-- END User Info--> 
      <!-- <a href="#" class="header-icon m-l-10 sm-no-margin sm-p-r-0 d-inline-block" data-toggle="quickview" data-toggle-element="#quickview"> <i class="pg-icon btn-icon-link">menu_add</i> </a>  -->
    </div>
  </div>
  <div class="bg-white"  ng-hide="hideSubmenu" >
    <div *ngIf="roleId==1" class="container">
      <div class="menu-bar header-sm-height" data-pages-init='horizontal-menu' data-hide-extra-li="4"> 
        <a href="#" class="btn-link header-icon toggle-sidebar d-lg-none" data-toggle="horizontal-menu"> 
          <i class="pg-icon">close</i> </a>
            <ul >
              <li routerLinkActive="active"> 
                <a href="#"  routerLink="/dashboard" class="fa fa-dashboard"></a> 
              </li>
              <li routerLinkActive="active"> 
                <a href="javascript:;"><span class="title">App Users </span> <span class=" arrow"></span></a>
                <ul class="">
                  <li class="" routerLinkActive="active"> <a href="#" routerLink="/user-management">Add Users</a> </li>
                  <li class="" routerLinkActive="active"> <a href="#"  routerLink="/role-menu-mapping">Role Menu Mapping</a> </li>
                </ul>
              </li>
              <li routerLinkActive="active"> 
                <a href="javascript:;"><span class="title">Business Users </span> <span class=" arrow"></span></a>
                <ul class="">
                  <li routerLinkActive="active"> <a href="javascript:;" routerLink="/customers"><span class="title">Customers</span> </a>
                    <li class="" routerLinkActive="active"> <a href="#" routerLink="/partners">Partners</a> </li>
                    <li routerLinkActive="active"> <a href="#" routerLink="/agents"> <span class="title">Agents</span>  </a>
                </ul>
              </li>
              <li routerLinkActive="active"> 
                <a href="javascript:;"><span class="title">Approvals</span> <span class=" arrow"></span></a>
                <ul class="">
                  <li class="" routerLinkActive="active"> <a  routerLink="/partners-kyc-vehicle-view">Vehicle KYC</a> </li>
                  <li class="" routerLinkActive="active"> <a  routerLink="/partners-bank">Vehicle Bank</a> </li>
                  <li routerLinkActive="active"> <a href="#" routerLink="/vehicles-fuels"> <span class="title">Activate Vehicles</span> </a> </li>
                  <li routerLinkActive="active"> <a href="#" routerLink="/partners-kyc-bank"> <span class="title">Partner Bank</span> </a> </li>
                  <li routerLinkActive="active"> <a href="#"  routerLink="/customers-kyc-bank"> <span class="title">Customer Bank</span> </a> </li>
                </ul>
              </li>
              <li routerLinkActive="active"> <a href="#" routerLink="/order" routerLinkActive="active"> <span class="title">Orders</span> </a> </li>
              <li routerLinkActive="active"> <a href="javascript:;" routerLink="/transaction"><span class="title">Transactions</span> </a></li>
              <li routerLinkActive="active"> <a href="javascript:;" routerLink="/assettracking"><span class="title">Asset Tracking</span> </a></li>

              <!-- <li > <a href="javascript:;"><span class="title">Settlement</span> <span class=" arrow"></span></a>
                <ul class="">
                  <li routerLinkActive="active"> <a href="javascript:;" routerLink="/report-partner-settlements"><span class="title">Partner Settlements</span> </a></li>    
                  <li routerLinkActive="active"> <a href="javascript:;" routerLink="/report-readyto-settlements"><span class="title">Ready to Settle</span> </a></li>    
                </ul>
              </li> -->
              <li routerLinkActive="active"> <a href="#" routerLink="/report-partner-settlements"> <span class="title">Settlements</span>  </a>

               <li routerLinkActive="active"> <a href="#" routerLink="/review-rating"> <span class="title">Ratings</span>  </a>
              </li>
              <li > <a href="javascript:;"><span class="title">Reports</span> <span class=" arrow"></span></a>
                <ul class="">
                  <li class="" routerLinkActive="active"> <a href="#" routerLink="/report-revenue">Revenue Report </a> </li>
                  <li class="" routerLinkActive="active"> <a href="#" routerLink="/settled-report">Settled Report </a> </li>
                  <li class="" routerLinkActive="active"> <a href="#" routerLink="/report-stock">Stock Report </a> </li>
                  <li class="" routerLinkActive="active"> <a href="#" routerLink="/report-refund">Refund Report </a> </li>
                  <li class="" routerLinkActive="active"> <a href="#" routerLink="/report-partner-monthly-invoice">Partner Monthly Invoice </a> </li>
                  <li class="" routerLinkActive="active"> <a href="#" routerLink="/report-platform-invoice">Platform Invoice </a> </li>
                </ul>
              </li>
              <li routerLinkActive="active"> <a href="javascript:;" ><span class="title">Settings</span> <span class=" arrow"></span></a>
                <ul class="">
                  <li class="" routerLinkActive="active"> <a href="#"  routerLink="/master-kyc-document">KYC Document Master</a> </li>
                  <li class="" routerLinkActive="active"> <a href="#" routerLink="/master-bank">Bank Master </a> </li>
                  <li class="" routerLinkActive="active"> <a href="#" routerLink="/master-fuel-type">Fuel Type  </a> </li>
                  <li class="" routerLinkActive="active"> <a href="#" routerLink="/master-fuel-rate-list">Fuel Price Reset History </a> </li>
                  <li class="" routerLinkActive="active"> <a href="#" routerLink="/master-charges-configuration">Charges Configuration   </a> </li>
                  <li class="" routerLinkActive="active"> <a href="#" routerLink="/master-coupon">Coupon Master </a> </li>
                  <li class="" routerLinkActive="active"> <a href="#" routerLink="/master-promotions">Promotions </a> </li>
                  <li class="" routerLinkActive="active"> <a href="#" routerLink="/master-hsncode">HSN Code Master</a> </li>
                  <li class="" routerLinkActive="active"> <a href="#" routerLink="/paymentmode">Payment Mode</a> </li>
                  <li class="" routerLinkActive="active"> <a href="#"  routerLink="/paymentmode-partners">Partner Payment Modes</a> </li>

                  
                </ul>
              </li>
            </ul>   
        <a href="#" class="search-link d-flex justify-content-between align-items-center d-lg-none" data-toggle="search">Tap here to search <i class="pg-search float-right"></i></a> </div>
    </div>
    <div *ngIf="roleId!=1" class="container">
      <div class="menu-bar header-sm-height" data-pages-init='horizontal-menu' data-hide-extra-li="4"> 
        <a href="#" class="btn-link header-icon toggle-sidebar d-lg-none" data-toggle="horizontal-menu"> 
          <i class="pg-icon">close</i> </a>
              <ul *ngFor="let menu of adminmenurole.response;">
                <li *ngIf="menu.menuId.menuName=='Dashboard'" routerLinkActive="active"> 
                  <a href="#"  routerLink="/dashboard" class="fa fa-dashboard"></a>
                </li>
                <li *ngIf="menu.menuId.menuName=='App Users'" routerLinkActive="active"> 
                  <a href="javascript:;"><span class="title">App Users </span> <span class=" arrow"></span></a>
                  <ul >
                    <li *ngIf="addUser" routerLinkActive="active"> <a href="#" routerLink="/user-management">Add Users</a> </li>
                    <li *ngIf="rolemenu" routerLinkActive="active"> <a  href="#"  routerLink="/role-menu-mapping">Role Menu Mapping</a> </li>
                  </ul>
                </li>
                <li *ngIf="menu.menuId.menuName=='Business Users'" routerLinkActive="active"> 
                  <a href="javascript:;"><span class="title">Business Users </span> <span class=" arrow"></span></a>
                  <ul class="">
                    <li *ngIf="customer" routerLinkActive="active"> <a href="javascript:;" routerLink="/customers"><span class="title">Customers</span> </a></li>
                      <li *ngIf="partner" class="" routerLinkActive="active"> <a href="#" routerLink="/partners">Partners</a> </li>
                      <li *ngIf="agent" routerLinkActive="active"> <a href="#" routerLink="/agents"> <span class="title">Agents</span>  </a></li>
                  </ul>
                </li>
                <li *ngIf="menu.menuId.menuName=='Approvals'" routerLinkActive="active"> 
                  <a href="javascript:;"><span class="title">Approvals</span> <span class=" arrow"></span></a>
                  <ul class="">
                    <li *ngIf="vehiclekyc"  class="" routerLinkActive="active"> <a  routerLink="/partners-kyc-vehicle-view">Vehicle KYC</a> </li>
                    <li *ngIf="vehiclebank" class="" routerLinkActive="active"> <a  routerLink="/partners-bank">Vehicle Bank</a> </li>
                    <li *ngIf="activatevehicle"  routerLinkActive="active"> <a href="#" routerLink="/vehicles-fuels"> <span class="title">Activate Vehicles</span> </a> </li>
                    <li *ngIf="partnerbank"  routerLinkActive="active"> <a href="#" routerLink="/partners-kyc-bank"> <span class="title">Partner Bank</span> </a> </li>
                    <li *ngIf="customerbank"  routerLinkActive="active"> <a href="#"  routerLink="/customers-kyc-bank"> <span class="title">Customer Bank</span> </a> </li>
                  </ul>
                </li>
                <li *ngIf="menu.menuId.menuName=='Orders'" routerLinkActive="active"> <a href="#" routerLink="/order" routerLinkActive="active"> <span class="title">Orders</span> </a> </li>
                <li *ngIf="menu.menuId.menuName=='Transactions'" routerLinkActive="active"> <a href="javascript:;" routerLink="/transaction"><span class="title">Transactions</span> </a></li>
                <!-- <li *ngIf="menu.menuId.menuName=='Settlement'"> <a href="javascript:;"><span class="title">Settlement</span> <span class=" arrow"></span></a>
                  <ul class="">
                    <li *ngIf="partnersettlement" routerLinkActive="active"> <a href="javascript:;" routerLink="/report-partner-settlements"><span class="title">Partner Settlements</span> </a></li>    
                  </ul>
                </li> -->
                <li *ngIf="menu.menuId.menuName=='Settlements'" routerLinkActive="active"> <a href="#" routerLink="/report-partner-settlements"> <span class="title">Settlements</span>  </a>

                 <li *ngIf="menu.menuId.menuName=='Ratings'" routerLinkActive="active"> <a href="#" routerLink="/review-rating"> <span class="title">Ratings</span>  </a>
                </li>
                <li *ngIf="menu.menuId.menuName=='Reports'" > <a href="javascript:;"><span class="title">Reports</span> <span class=" arrow"></span></a>
                  <ul class="">
                    <li *ngIf="reveneureport" class="" routerLinkActive="active"> <a href="#" routerLink="/report-revenue">Revenue Report </a> </li>
                    <li *ngIf="settledreport" class="" routerLinkActive="active"> <a href="#" routerLink="/settled-report">Settled Report </a> </li>
                    <li *ngIf="stokreport" class="" routerLinkActive="active"> <a href="#" routerLink="/report-stock">Stock Report </a> </li>
                    <li *ngIf="refundreport" class="" routerLinkActive="active"> <a href="#" routerLink="/report-refund">Refund Report </a> </li>
                  </ul>
                </li>
                <li *ngIf="menu.menuId.menuName=='Settings'" routerLinkActive="active"> <a href="javascript:;" routerLink="#"><span class="title">Settings</span> <span class=" arrow"></span></a>
                  <ul class="">
                    <li *ngIf="kycdocument" class="" routerLinkActive="active"> <a href="#"  routerLink="/master-kyc-document">KYC Document Master</a> </li>
                    <li *ngIf="bankmaster" class="" routerLinkActive="active"> <a href="#" routerLink="/master-bank">Bank Master </a> </li>
                    <li *ngIf="fueltypemaster" class="" routerLinkActive="active"> <a href="#" routerLink="/master-fuel-type">Fuel Type  </a> </li>
                    <li *ngIf="fuelprisemaster" class="" routerLinkActive="active"> <a href="#" routerLink="/master-fuel-rate-list">Fuel Price Reset History </a> </li>
                    <li *ngIf="charges" class="" routerLinkActive="active"> <a href="#" routerLink="/master-charges-configuration">Charges Configuration   </a> </li>
                    <li *ngIf="coupon" class="" routerLinkActive="active"> <a href="#" routerLink="/master-coupon">Coupon Master </a> </li>
                    <li *ngIf="promotions" class="" routerLinkActive="active"> <a href="#" routerLink="/master-promotions">Promotions </a> </li>
                    <li *ngIf="hsncode" class="" routerLinkActive="active"> <a href="#" routerLink="/master-hsncode">HSN Code Master</a> </li>
                    <li *ngIf="paymentmode" class="" routerLinkActive="active"> <a href="#" routerLink="/paymentmode">Payment Mode</a> </li>
                    <li *ngIf="partnerPaymentmode" class="" routerLinkActive="active"> <a href="#"  routerLink="/paymentmode-partners">Partner Payment Modes</a> </li>

                  </ul>
                </li>
              </ul>
        <a href="#" class="search-link d-flex justify-content-between align-items-center d-lg-none" data-toggle="search">Tap here to search <i class="pg-search float-right"></i></a> </div>
    </div>
  </div>
</div>
