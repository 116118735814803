import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { GenericResponseModel} from '../model/genricresponse';
import { UserModel } from '../model/user';
import { CustomerOrderModel } from '../model/customerorder';
import { VehicleAttachModel } from '../model/vehicleModel';
import { KycDocumentsModel } from '../model/kycdocument';
import { PartnersBankModel } from '../model/partnerbank';
import { MarketPrizeModel } from '../model/marketprize';
import { BankModel } from '../model/approvebank';
import { FuelRefillHistoryModel } from '../model/fuelrefill';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { GenericResponse } from '../model/genricmodelresponse';

@Injectable({
    providedIn: "root"
  })
  export class PartnersService {

    constructor(private httpClient:HttpClient) {}

  private baseUrl = environment.devurl;
  private auth = environment.headers;

  public getAllPartners(){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/partnerinfo/view",{headers});
  }

  public getPartnersById(id){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<UserModel>(this.baseUrl+"/partnerinfo/getPartnerId"+"/"+id,{headers});
  }

  public getAllOrders(id){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/partnerinfo/orders"+"/"+id,{headers});
  }

  public getAllVehicles(id){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<VehicleAttachModel[]>(this.baseUrl+"/partnerinfo/vehicleinfo"+"/"+id,{headers});
  }

  public getAllKYC(id){
      // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<KycDocumentsModel[]>(this.baseUrl+"/partnerinfo/getkyc"+"/"+id,{headers});
  }

  public getBankinfo(id){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<PartnersBankModel[]>(this.baseUrl+"/partnerinfo/getBankinfo"+"/"+id,{headers});
  }

  public getMarketPrice(id){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<MarketPrizeModel[]>(this.baseUrl+"/partnerinfo/marketprice"+"/"+id,{headers});
  }

  public getAllTransactions(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/partnerinfo/transaction"+"/"+id,{headers});
  }

  public getOrderById(id){
    const headers = this.auth;
    return this.httpClient.get<CustomerOrderModel>(this.baseUrl+"/partnerinfo/orderbyId"+"/"+id,{headers});
  }
  
  public getAllPartnersKyc(){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/adminkyc/view",{headers});
  }

  public getKycInfo(id){
      // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/adminkyc/kycinfo"+"/"+id,{headers});
  }

  public getAllBank(){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/adminkyc/viewbank",{headers});
  }

  public getBankInfoById(id){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/adminkyc/bankinfo"+"/"+id,{headers});
  }

  public approveBank(id,bank:BankModel){
      // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.put<GenericResponseModel>(this.baseUrl+"/adminkyc/bankapproval"+"/"+id,bank,{headers});
  }
  
  public getAllSettlements(id){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<CustomerOrderModel[]>(this.baseUrl+"/partnerinfo/settlements"+"/"+id,{headers});
  }

  public approveKyc(id,bank:BankModel){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.put<GenericResponseModel>(this.baseUrl+"/adminkyc/approvereject"+"/"+id,bank,{headers});
  }

  public fuelRefillHistory(id){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<FuelRefillHistoryModel[]>(this.baseUrl+"/partnerinfo/fuelByPartners"+"/"+id,{headers});
  }

  public getAllActivepartner(){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/partnerinfo/activepartner",{headers});
  }

  public getKycImage(id,flag): Observable<Blob>{
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<any>(this.baseUrl+"/adminkyc/kycImage"+"/"+id+"/"+flag,{headers,responseType: 'Blob' as 'json'});
  }

  public getVehicleByPartner(id){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<VehicleAttachModel>(this.baseUrl+"/partnerinfo/getVehicleByPartner"+"/"+id,{headers});
  }

  public viewAgents(id){
      // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/partnerinfo/viewagents"+"/"+id,{headers});
  }

  public getPartnerApprove(id){
      // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/adminkyc/getApproveStatus"+"/"+id,{headers});
  }

  public getPartnerPending(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/adminkyc/getPendingStatus"+"/"+id,{headers});
  }

  public getPartnerReject(id){
      // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/adminkyc/getRejectStatus"+"/"+id,{headers});
  }

  public getPartnerBankApprove(){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/adminkyc/getBankApproveStatus",{headers});
  }

  public getPartnerBankPending(){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/adminkyc/getBankPendingStatus",{headers});
  }

  public getPartnerBankReject(){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/adminkyc/getBankRejectStatus",{headers});
  }

  public getPartnerBankdaterange(start,end){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
   return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/adminkyc/getvehiclebank"+"/"+start+"/"+end,{headers});
 }

  public getAssetsByPartner(id){
      // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/partnerinfo/getasset"+"/"+id,{headers});
  }

  public getAssetsImage(id): Observable<Blob>{
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<any>(this.baseUrl+"/partnerinfo/assestimg"+"/"+id,{headers,responseType: 'Blob' as 'json'});
  }

  public getPendingKycDocument(id){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/adminkyc/getpendingkyc"+"/"+id,{headers});
  }

  public uploadkyc(kyc:any){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.post<GenericResponse>(this.baseUrl+"/adminkyc/uploadkyc",kyc,{headers});
  }

  public getVehicleInfo(id){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/adminkyc/getVehicleInfo"+"/"+id,{headers});
  }

  public reuploadkyc(kyc:any){
      // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.post<GenericResponse>(this.baseUrl+"/adminkyc/reuploadkyc",kyc,{headers});
  }

  public getPartnerBankInfoById(id){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/partnerinfo/banksettlement"+"/"+id,{headers});
  }


}