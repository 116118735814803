import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GenericResponseModel } from '../model/genricresponse';
import { KycMasterModel } from '../model/kycmaster';
import { GenericResponse } from '../model/genricmodelresponse';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: "root"
  })
  export class PromotionsService {

    constructor(private httpClient:HttpClient) {}

  private baseUrl = environment.devurl;
  private auth = environment.headers;

    public getAllPromotions(){
       // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/promotions/view",{headers});
  }

  public addAdvertiesment(ad:any){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.post<GenericResponseModel>(this.baseUrl+"/promotions/postads",ad,{headers});
  }

  public getAds(id): Observable<Blob>{
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<any>(this.baseUrl+"/promotions/ads"+"/"+id,{headers,responseType: 'Blob' as 'json'});
  }

  public Update(ad:any){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.put<GenericResponseModel>(this.baseUrl+"/promotions/update",ad,{headers});
  }

  public updatePromotion(promotion){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
     return this.httpClient.post<GenericResponseModel>(this.baseUrl+"/promotions/updatepromotions",promotion,{headers});
 }

  public delete(id){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/promotions/delete"+"/"+id,{headers});
  }

  }