import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { KycMasterModel } from '../model/kycmaster';
import { GenericResponseModel } from '../model/genricresponse';
import { VehicleAttachModel } from '../model/vehicleModel';
import { KycDocumentService } from '../services/kycdocument.service';
import { PartnersService } from '../services/partners.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-master-kyc-document',
  templateUrl: './master-kyc-document.component.html',
  styleUrls: ['./master-kyc-document.component.css']
})
export class MasterKycDocumentComponent {
 
  active = 1;
  closeResult = '';
  public isCollapsed = false;
  kycdocument:GenericResponseModel;
  kycMasterModel:KycMasterModel;
  vehicleAttachModel:VehicleAttachModel[];
  errorFlag:boolean;
  errorFlag1:boolean;
  errorMessage:String;
  deleteUserId:Number;
  constructor(private router: Router,private modalService: NgbModal,private kycdocumentservice:KycDocumentService,private partnerservice:PartnersService,private spinnerService:NgxSpinnerService) {}

  ngOnInit() : void {
      this.kycdocumentservice.getAllKyc().subscribe(data=>{
        this.kycdocument=data;
      },err => {
        if(err.error.statusCode==406){
          this.router.navigate(['/login']);
        }else if(err.error.statusCode==500){
          this.router.navigate(['/page-error']);
        }else if(err.error.statusCode==404){
          this.router.navigate(['/pagenotfound']);
        }
     });  

      this.partnerservice.getAllVehicles(15).subscribe(data=>{
        this.vehicleAttachModel=data;  
        },err => {
          console.log(err.error.statusCode);
          if(err.error.statusCode==406){
            this.router.navigate(['/login']);
          }
       });   
  }

  delete(){
    let userId = this.deleteUserId;
    this.kycdocumentservice.deleteKyc(userId).subscribe(data=>{
      if(data.statusCode==200){
        Swal.fire({
          html: '<pre>' + data.responseMessage + '</pre>',
          customClass: {
            popup: 'format-pre'
          }
        }).then((value) => {
          window.location.reload();
        });; ;
      }
    },err => {
      if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });
  }

  addKyc(){
    let kycName = (<HTMLInputElement>document.getElementById('kycName')).value;
    let kycStatus = (<HTMLInputElement>document.getElementById('kycStatus')).value;
    if(kycName!=="" && kycStatus!=="" && kycName!=null && kycStatus!=null){
      let formData= {
        kycName:kycName,
        kycStatus:Number(kycStatus),
        modifiedBy:sessionStorage.getItem("username")
      }
      this.kycdocumentservice.addkyc(formData).subscribe(data=>{
        if(data.statusCode==1){
          this.modalService.dismissAll();
          Swal.fire({
            html: '<pre>' + data.responseMessage + '</pre>',
            customClass: {
              popup: 'format-pre'
            }
          }).then((value) => {
            window.location.reload();
          });; ; 
        }else{
          this.errorFlag= true;
          this.errorMessage = data.responseMessage;
          this.spinnerService.hide();
          setTimeout(function() {
            this.errorFlag = false;
        }.bind(this), 3000);
        }
      },err => {
        if(err.error.statusCode==406){
          this.router.navigate(['/login']);
        }else if(err.error.statusCode==500){
          this.router.navigate(['/page-error']);
        }else if(err.error.statusCode==404){
          this.router.navigate(['/pagenotfound']);
        }
     });
    }else{
      this.errorFlag= true;
      this.errorMessage = "Please Enter Document Name!";
      this.spinnerService.hide();
      setTimeout(function() {
        this.errorFlag = false;
    }.bind(this), 3000);
    }
  }

  updateKyc(){

    if(this.kycMasterModel.kycName!==""  && this.kycMasterModel.kycName!=null && this.kycMasterModel.kycStatus!=null){
      let formData= {
        kycName:this.kycMasterModel.kycName,
        kycStatus:this.kycMasterModel.kycStatus,
        modifiedBy:sessionStorage.getItem("username")
      }
      this.kycdocumentservice.update(this.kycMasterModel.kycId,formData).subscribe(data=>{
        if(data.statusCode==1){
          this.modalService.dismissAll();
          Swal.fire({
            html: '<pre>' + data.responseMessage + '</pre>',
            customClass: {
              popup: 'format-pre'
            }
          }).then((value) => {
            window.location.reload();
          });; ; 
        }else{
          this.errorFlag1= true;
          this.errorMessage = data.responseMessage;
          this.spinnerService.hide();
          setTimeout(function() {
            this.errorFlag1 = false;
        }.bind(this), 3000);
        }
      },err => {
        if(err.error.statusCode==406){
          this.router.navigate(['/login']);
        }else if(err.error.statusCode==500){
          this.router.navigate(['/page-error']);
        }else if(err.error.statusCode==404){
          this.router.navigate(['/pagenotfound']);
        }
     });
    }else{
      this.errorFlag1= true;
      this.errorMessage = "Please Enter Document Name!";
      this.spinnerService.hide();
      setTimeout(function() {
        this.errorFlag1 = false;
    }.bind(this), 3000);
    }

  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open1(content1,id) {
    this.kycdocumentservice.getKycId(id).subscribe(data=>{
    this.kycMasterModel = data;
    });
    this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open2(reject,viewId){
    this.deleteUserId = viewId;
    this.modalService.open(reject, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
