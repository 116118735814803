<app-header></app-header>

<!-- START PAGE-CONTAINER -->
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
  </ngx-spinner> 
<div class="page-container "> 
  <!-- START PAGE CONTENT WRAPPER -->
  <div class="page-content-wrapper "> 
    <!-- START PAGE CONTENT -->
    <div class="content sm-gutter"> 
      <!-- START BREADCRUMBS -->
      <div class="bg-white">
        <div class="container">
          <ol class="breadcrumb breadcrumb-alt">
            <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Home</a></li>
            <li class="breadcrumb-item active"><span class="font-montserrat fs-11 all-caps">Settled Report</span></li>
          </ol>
        </div>
      </div>

      <!-- START CONTAINER FLUID -->
      <div *ngIf="normalsettled" class=" no-padding    container-fixed-lg bg-white">
        <div class="container"> 
          <!-- START card -->
          <div class="card card-transparent">
            <!-- <div class="card-header "> -->
              <div class="card role-menu-mapping-edit">
                <div class="group-element" style="margin-right: 20px; margin-top: 10px;" >
                  <label><b>Search By Settlement Date</b></label> 
                </div>
                <div class="ch-form-group" style="margin-right: 20px;">
                  <input type="date" class="form-control" id="fromdate" name="fromdate" aria-describedby="emailHelp" placeholder="Select Role" required>
                </div>
                <div class="ch-form-group" style="margin-right: 20px;">
                  <input type="date" class="form-control" id="todate" name="todate" aria-describedby="emailHelp" placeholder="Select Role" required>
                </div>
                <div class="ch-form-group" style="margin-right: 20px;">
                  <button type="button" (click)="search()" class="btn save-btn">Search</button>
                </div>
                <div class="ch-form-group" style="margin-right: 20px;">
                  <button *ngIf="refreshflag" title="refresh" class="btn reset-btn" (click)="refresh()">Refresh</button>
                </div>
                <div class="ch-form-group" style="margin-right: 20px;">
                <span  *ngIf="errorFlag" style="color: red;">{{errorMessage}}</span>
                </div>
                <div class="ch-form-group">
                  <button *ngIf="excelexportchecksettled" style="height: 35px;" class="label label-info" (click)="exportToExcelSettled()">Excel Export</button>
                  </div>
                  </div>
            <!-- </div> -->
            <div class="card-body">
                <div style="display: none;">{{settledreportModel.responseMessage}}</div>
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th >Sl.No.</th>
                      <th>Settlement ID</th>
                      <th style="width: 150px;">Partner Info</th>
                      <th >Account Holder Name</th>
                      <th >Account Number</th>
                      <th >IFSC Code</th>
                      <th >Bank Ref No.</th>
                      <th >Payout Amount</th>
                      <th >Settlement Date & Time</th>
                      <th >Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let settled of settledreportModel.response; let i=index">
                      <td align="center" class="v-align-middle "><p>{{i + 1}}</p></td>
                      <td *ngIf="settled.settlementApi!=null"align="left" class="v-align-middle"><p>{{settled.settlementApi}}</p></td>
                      <td *ngIf="settled.settlementApi==null"align="left" class="v-align-middle"><p>N/A</p></td>
                      <td *ngIf="settled.vehicleId!=null"align="left" class="v-align-middle">
                          <p style="font-size: 12px;"><b>Name :</b> {{settled.vehicleId.userId.firstName}} {{settled.vehicleId.userId.lastName}} </p>
                          <p style="font-size: 12px;"><b>Mobile :</b> {{settled.vehicleId.userId.mobileNumber}}</p>
                        </td>
                      <td *ngIf="settled.vehicleId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                      <td *ngIf="settled.accountName!=null"align="left" class="v-align-middle"><p >{{settled.accountName}}</p></td>
                      <td *ngIf="settled.accountName==null"align="left" class="v-align-middle"><p>N/A</p></td>
                      <td *ngIf="settled.accountNumber!=null"align="left" class="v-align-middle">{{settled.accountNumber}}</td>
                      <td *ngIf="settled.accountNumber==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="settled.ifscCode!=null"align="center" class="v-align-middle">{{settled.ifscCode}}</td>
                      <td *ngIf="settled.ifscCode==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="settled.bankReference!=null"align="center" class="v-align-middle">{{settled.bankReference}}</td>
                      <td *ngIf="settled.bankReference==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="settled.payoutAmount!=null" align="left" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{settled.payoutAmount | number : '1.2-2' }}</td>
                      <td *ngIf="settled.payoutAmount==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="settled.settlementDatetime!=null" align="left" class="v-align-middle">{{settled.settlementDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="settled.settlementDatetime==null" align="left" class="v-align-middle">N/A</td>
                      <td class="v-align-middle"><button class="label label-info" (click)="view(settled.settlementId)"><i class="fa fa-eye" aria-hidden="true"></i></button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- END card --> 
        </div>
      </div>

      <!-- START CONTAINER FLUID -->
      <div *ngIf="filtersettled" class=" no-padding    container-fixed-lg bg-white">
        <div class="container"> 
          <!-- START card -->
          <div class="card card-transparent">
            <!-- <div class="card-header "> -->
              <div class="card role-menu-mapping-edit">
                <div class="group-element" style="margin-right: 20px; margin-top: 10px;" >
                  <label><b>Search By Paid Date</b></label> 
                </div>
                <div class="ch-form-group" style="margin-right: 20px;">
                  <input type="date" class="form-control" id="fromdate" [(ngModel)]="settledfilterreportModel.fromDate" name="fromdate" aria-describedby="emailHelp" placeholder="Select Role" required>
                </div>
                <div class="ch-form-group" style="margin-right: 20px;">
                  <input type="date" class="form-control" id="todate" [(ngModel)]="settledfilterreportModel.toDate" name="todate" aria-describedby="emailHelp" placeholder="Select Role" required>
                </div>
                <div class="ch-form-group" style="margin-right: 20px;">
                  <button type="button" (click)="search()" class="btn save-btn">Search</button>
                </div>
                <div class="ch-form-group" style="margin-right: 20px;">
                  <button *ngIf="refreshflag" title="refresh" class="btn reset-btn" (click)="refresh()">Refresh</button>
                </div>
                <div class="ch-form-group" style="margin-right: 20px;">
                <span  *ngIf="errorFlag" style="color: red;">{{errorMessage}}</span>
                </div>
                <div class="ch-form-group">
                  <button *ngIf="excelexportchecksettled" style="height: 35px;" class="label label-info" (click)="exportToExcelFilterSettled()">Excel Export</button>
                  </div>
                  </div>
            <!-- </div> -->
            <div class="card-body">
                <div style="display: none;">{{settledfilterreportModel.responseMessage}}</div>
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th >Sl.No.</th>
                      <th>Settlement ID</th>
                      <th style="width: 150px;">Partner Info</th>
                      <th >Account Holder Name</th>
                      <th >Account Number</th>
                      <th >IFSC Code</th>
                      <th >Bank Ref No.</th>
                      <th >Payout Amount</th>
                      <th >Settlement Date & Time</th>
                      <th >Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let settled of settledfilterreportModel.response; let i=index">
                      <td align="center" class="v-align-middle "><p>{{i + 1}}</p></td>
                      <td *ngIf="settled.settlementApi!=null"align="left" class="v-align-middle"><p>{{settled.settlementApi}}</p></td>
                      <td *ngIf="settled.settlementApi==null"align="left" class="v-align-middle"><p>N/A</p></td>
                      <td *ngIf="settled.vehicleId!=null"align="left" class="v-align-middle">
                          <p style="font-size: 12px;"><b>Name :</b> {{settled.vehicleId.userId.firstName}} {{settled.vehicleId.userId.lastName}} </p>
                          <p style="font-size: 12px;"><b>Mobile :</b> {{settled.vehicleId.userId.mobileNumber}}</p>
                        </td>
                      <td *ngIf="settled.vehicleId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                      <td *ngIf="settled.accountName!=null"align="left" class="v-align-middle"><p >{{settled.accountName}}</p></td>
                      <td *ngIf="settled.accountName==null"align="left" class="v-align-middle"><p>N/A</p></td>
                      <td *ngIf="settled.accountNumber!=null"align="left" class="v-align-middle">{{settled.accountNumber}}</td>
                      <td *ngIf="settled.accountNumber==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="settled.ifscCode!=null"align="center" class="v-align-middle">{{settled.ifscCode}}</td>
                      <td *ngIf="settled.ifscCode==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="settled.bankReference!=null"align="center" class="v-align-middle">{{settled.bankReference}}</td>
                      <td *ngIf="settled.bankReference==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="settled.payoutAmount!=null" align="left" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{settled.payoutAmount | number : '1.2-2' }}</td>
                      <td *ngIf="settled.payoutAmount==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="settled.settlementDatetime!=null" align="left" class="v-align-middle">{{settled.settlementDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="settled.settlementDatetime==null" align="left" class="v-align-middle">N/A</td>
                      <td class="v-align-middle"><button class="label label-info" (click)="view(user)"><i class="fa fa-eye" aria-hidden="true"></i></button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- END card --> 
        </div>
      </div>