import { Component,  OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { CustomerOrderModel } from '../model/customerorder';
import { FuelRefillHistoryModel } from '../model/fuelrefill';
import { KycDocumentsModel } from '../model/kycdocument';
import { MarketPrizeModel } from '../model/marketprize';
import { PartnersBankModel } from '../model/partnerbank';
import { GenericResponseModel } from '../model/genricresponse';
import { UserModel } from '../model/user';
import { VehicleAttachModel } from '../model/vehicleModel';
import { PartnersService } from '../services/partners.service';
import { UserService } from '../services/user.service';
import { VehicleFuelService } from '../services/vehivle.service';
import { OrderTransactionService } from '../services/ordertran.service';
import { AgentService } from '../services/agent.service';
import { data } from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { GenericResponse } from '../model/genricmodelresponse';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { OrderAssignModel } from '../model/orderassign';
import { CustomerService } from '../services/customer.service';
import { PartnerPaymentModeService } from '../services/partnerpaymentmode.service';

@Component({
  selector: 'app-partners-view-page',
  templateUrl: './partners-view-page.component.html',
  styleUrls: ['./partners-view-page.component.css']
})
export class PartnersViewPageComponent  {
  userModel:UserModel;
  customerOrderModel:GenericResponseModel;
  vehicleAttachModel:VehicleAttachModel[];
  kycDocumentModel:KycDocumentsModel[];
  bankinfoModel:PartnersBankModel[];
  marketPrizeModel:MarketPrizeModel[];
  transactionDetails:GenericResponseModel;
  settlementDetails: CustomerOrderModel[];
  fuelRefillHistoryDetails: FuelRefillHistoryModel[];
  vehiclePaymentModel:GenericResponseModel;
  agentModel:GenericResponseModel;
  partnerBankInfo:GenericResponseModel;
 active = 1;
  closeResult = '';
  message : String;
  successflag:boolean;
  imageToShow: any;
  imageasset: any;
  isImageassetLoading : boolean;
  isImageLoading: boolean;
  lat:Number;
  lan:Number;
  vehicleModel:VehicleAttachModel;
  settlementViewDetail:GenericResponseModel;
  viewSettlement:GenericResponseModel;
  agantModel:UserModel;
  isImageLoadingpartner: boolean;
  imageToShowpartner: any;
  isImageLoadingvehicle1: boolean;
  imageToShowvehicle1: any;
  isImageLoadingvehicle12: boolean;
  imageToShowvehicle2: any;
  isImageLoadingvehicle3: boolean;
  imageToShowvehicle3: any;
  isImageLoadingvehicle4: boolean;
  imageToShowvehicle4: any;

  assetsResponseModel:GenericResponseModel;
  ratingStar:Number;
  orderDetails:GenericResponseModel;
  orderTrackDetails:GenericResponse;
  cancelMessageModel:GenericResponseModel;
  errorFlag:boolean = false;
  errorFlag1:boolean = false;
  errorMessage:String;
  orderID:Number;
  activeVehicle:GenericResponseModel;
    activevehi:Object;
  vehicleModels : CustomerOrderModel;
check:String = null;
vehicle:VehicleAttachModel;
orderId:Number;
failedStatus:String;
  failedCount:Number;
  editUserId:Number;
  paymentModes:GenericResponseModel;
  partnerId:Number;
  resultText=[];
  values:string;  
  partnerMappingModel:GenericResponseModel;
  constructor(private modalService: NgbModal,private route: ActivatedRoute,private partnerservice: PartnersService,private router: Router
    ,private vehicleservice:VehicleFuelService,private userservice:UserService,
    private ordertranservice:OrderTransactionService,private agentservice:AgentService,
    private ordertransactionservice: OrderTransactionService,private ngxspinner:NgxSpinnerService,
    private spinnerService:NgxSpinnerService,private customerService: CustomerService, private pgmodeService:PartnerPaymentModeService) {}

  ngOnInit() : void {
    this.ngxspinner.show();
    this.route.queryParams.subscribe(params => {

      let value = params.success;
      if(value){
        this.successflag=true;
        this.message = params.message;
      }else{
      }

      setTimeout(function() {
        this.successflag = false;
        console.log(this.successflag);
    }.bind(this), 5000);


      let parId=Number(sessionStorage.getItem("patId"));

      this.userservice.getUserImage(parId).subscribe(data=>{
        if(data.type==='text/xml'){
          this.isImageLoadingpartner = false;
        }else{
          this.isImageLoadingpartner = true;
        }
        this.createImageFromBlob1(data);
      });  


      this.partnerservice.getPartnersById(parId)
      .subscribe(data => {
        let rating = String(data.ratings);
        if(data.ratings==0){
          this.ratingStar = 0;
        }
        else if(rating.match('1')){
          if(rating.match('1.5')||rating.match('1.6')||rating.match('1.7')||rating.match('1.8')||rating.match('1.9')){
            this.ratingStar = 1.5;
          }else{
            this.ratingStar = 1;
          }
          
        }else if(rating.match('2')){
          if(rating.match('2.5')||rating.match('2.6')||rating.match('2.7')||rating.match('2.8')||rating.match('2.9')){
            this.ratingStar = 2.5;
          }else{
            this.ratingStar = 2;
          }
        }else if(rating.match('3')){
          if(rating.match('3.5')||rating.match('3.6') || rating.match('3.7')||rating.match('3.8')||rating.match('3.9')){
            this.ratingStar = 3.5;
          }else{
            this.ratingStar = 3;
          }
        }else if(rating.match('4')){
          if(rating.match('4.5')||rating.match('4.6')||rating.match('4.7')||rating.match('4.8')||rating.match('4.9')){
            this.ratingStar = 4.5;
          }else{
            this.ratingStar = 4;
          }
        }else if(rating.match('5')){
          this.ratingStar = 5;
        }
       this.userModel = data;
       this.failedCount = this.userModel.failedCount;
          if(this.userModel.failedCount==6){
            this.failedStatus = "Block" ;
          }else{
            this.failedStatus = "Not Block" ;
          }
      }, error => console.log(error));


      this.partnerservice.getAllOrders(parId).subscribe(data=>{
      this.customerOrderModel=data;
      this.ngxspinner.hide();
      });

      this.ngxspinner.show();
      this.partnerservice.getAllVehicles(parId).subscribe(data=>{
      this.vehicleAttachModel=data;  
      this.ngxspinner.hide();
      });
      this.ngxspinner.show();
      this.partnerservice.getAllKYC(parId).subscribe(data=>{
      this.kycDocumentModel=data;
      this.ngxspinner.hide();
      });
      this.ngxspinner.show();
      this.partnerservice.getBankinfo(parId).subscribe(data=>{
      this.bankinfoModel= data;
      this.ngxspinner.hide();
        });
        this.ngxspinner.show();
      this.partnerservice.getMarketPrice(parId).subscribe(data=>{
        this.marketPrizeModel=data;
        this.ngxspinner.hide();
      });  
      this.ngxspinner.show();
      this.partnerservice.getAllTransactions(parId).subscribe(data=>{
        this.transactionDetails = data;
        this.ngxspinner.hide();
      })
      this.ngxspinner.show();
      this.partnerservice.viewAgents(parId).subscribe(data=>{
        this.agentModel = data;
        this.ngxspinner.hide();
      });

      this.partnerservice.getAssetsByPartner(parId).subscribe(data=>{
        this.assetsResponseModel = data;
      });

        let formData= {
          userId:parId,
          userName:sessionStorage.getItem("username")
        }
        this.ngxspinner.show();
        this.ordertranservice.getsettlementByPartner(formData).subscribe(data=>{
          this.settlementViewDetail = data;
          this.ngxspinner.hide();
        })
        this.ngxspinner.show();
      this.partnerservice.getAllSettlements(parId).subscribe(data=>{
        this.settlementDetails =data;
        this.ngxspinner.hide();
      });
      this.ngxspinner.show();
      this.partnerservice.fuelRefillHistory(parId).subscribe(data=>{
        this.fuelRefillHistoryDetails = data;
        this.ngxspinner.hide();
      })
      this.ngxspinner.show();
        this.partnerservice.getPartnerBankInfoById(parId).subscribe(data=>{
          this.partnerBankInfo = data;
          this.ngxspinner.hide();
        })

        this.pgmodeService.defaultMethod(parId).subscribe(data=>{
          this.partnerMappingModel = data;
        },err => {
          if(err.error.statusCode==406){
            this.router.navigate(['/login']);
          }else if(err.error.statusCode==500){
            this.router.navigate(['/page-error']);
          }else if(err.error.statusCode==404){
            this.router.navigate(['/pagenotfound']);
          }
       });
    });

   
  }

  open(content,id) {

    this.ordertransactionservice.getOrderById(id).subscribe(data=>{
      this.orderDetails=data;
    });
    
    this.ordertransactionservice.tracking(id).subscribe(data=>{
      this.orderTrackDetails = data;
    });

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open10(reject,viewId){
    this.editUserId = viewId;
    this.modalService.open(reject, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  unblock(){
    this.spinnerService.show();
    let userId = this.editUserId;
    this.customerService.unblock(userId).subscribe(data=>{
      if(data.statusCode==200){
        this.spinnerService.hide();
        Swal.fire({
          html: '<pre>' + data.responseMessage + '</pre>',
          customClass: {
            popup: 'format-pre'
          }
        }).then((value) => {
          window.location.reload();
        });; ;
      }
    },err => {
      if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });
  }

  cancelPopup(content7,id){
    this.orderID = id;
    this.ordertransactionservice.cancellmessage().subscribe(data=>{
      this.cancelMessageModel = data;
    });
    this.modalService.open(content7, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  cancelOrder(){
    let desc = (<HTMLInputElement>document.getElementById('cancelremarks')).value;
    let cancelmessage = (<HTMLInputElement>document.getElementById('cancelmessage')).value;
    if(desc!=null && desc!==''){
      this.errorFlag1 = false;
      let formData= {
        userId:Number(sessionStorage.getItem('id')),
        cancellationId:cancelmessage,
        orderRemarks:desc,
      }
      this.ordertransactionservice.cancelorder(this.orderID,formData).subscribe(data=>{
        if(data.statusCode==200){
          Swal.fire({
            html: '<pre>' + data.responseMessage + '</pre>',
            customClass: {
              popup: 'format-pre'
            }
          }).then((value) => {
            window.location.reload();
          });; ; 
        }else{
          Swal.fire({
            html: '<pre>' + data.responseMessage + '</pre>',
            customClass: {
              popup: 'format-pre'
            }
          }).then((value) => {
            window.location.reload();
          });; ; 
        }
      });
      this.modalService.dismissAll();
    }else{
      this.errorFlag1 = true;
      this.errorMessage = "Please enter remarks!";
      setTimeout(function() {
        this.errorFlag1 = false;
    }.bind(this), 3000);

    }
  }

  invoice(id){
    this.ordertransactionservice.download(id,3).subscribe(data => {
      
      var file = new Blob([data], {type: 'application/pdf'});
       var fileURL = URL.createObjectURL(file);
       window.open(fileURL);

  }, error => {

  });
 
  }

  download(id,filename){
    this.ordertransactionservice.download(id,3).subscribe(result => {
      let dataType = 'application/pdf';
      let binaryData = [];
      binaryData.push(result);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
      downloadLink.setAttribute('download', filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();

  });
  }

  sendMail(id){
    this.ordertransactionservice.sendMail(id,3).subscribe(data => {
      
      if(data.statusCode==200){
        Swal.fire({
          html: '<pre>' + data.responseMessage + '</pre>',
          customClass: {
            popup: 'format-pre'
          }
        }).then((value) => {
          window.location.reload();
        });; ;
      }
    },err => {
      if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });
 
  }
  open1(content1,id) {
    if(id !=='undefined'){
    this.vehicleservice.getVehicleByPayment(id).subscribe(data=>{
      this.vehiclePaymentModel= data;
    });

    this.vehicleservice.getVehicleImage(id,1).subscribe(data=>{
      this.createImageFromBlob2(data);
    });

    this.vehicleservice.getVehicleImage(id,2).subscribe(data=>{
      this.createImageFromBlob3(data);
    });
    this.vehicleservice.getVehicleImage(id,3).subscribe(data=>{
      this.createImageFromBlob4(data);
    });
    this.vehicleservice.getVehicleImage(id,4).subscribe(data=>{
      this.createImageFromBlob5(data);
    });

  }
  this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open2(content2) {

    this.modalService.open(content2, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open3(content3) {
    this.modalService.open(content3, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open4(content4,id) {
    this.agentservice.viewAgentById(id).subscribe(data=>{
        this.agantModel = data;
    });

    this.modalService.open(content4, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open5(content6,id) {
    this.partnerservice.getAssetsImage(id).subscribe(data=>{
      this.isImageassetLoading = true;
        this.createImageFromBlob6(data);
        this.isImageassetLoading = false;
  }, error => {
    this.isImageassetLoading = false;
  });

    this.modalService.open(content6, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open6(content8,id) {
    this.orderId = id;

    this.ordertransactionservice.getActivatevehicle().subscribe(data=>{
      this.activeVehicle = data;
      this.activevehi = this.activeVehicle.response[0];
    });

    this.partnerservice.getOrderById(id).subscribe(data=>{
      this.vehicleModels = data;
    })

    this.ordertransactionservice.getOrderById(id).subscribe(data=>{
      this.orderDetails=data;
    })
    this.modalService.open(content8, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }

      open7(content10) {
        this.modalService.open(content10, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }   

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

public isCollapsed = false;

callActiveOrInactive(status){
  let parId=Number(sessionStorage.getItem("patId"));
  let username = sessionStorage.getItem("username");
  this.userservice.activeOrInactive(parId,status,username).subscribe(data=>{
    if(data.flag===1){
      this.router.navigate(['/partners-view-page'],{ queryParams: { success: true ,message:data.message}});
    }
  })
}

kycdocument(id){
  this.isImageLoading = true;
  this.partnerservice.getKycImage(id,1).subscribe(data=>{
    this.createImageFromBlob(data);
    this.isImageLoading = false;
}, error => {
this.isImageLoading = false;
});
}

createImageFromBlob(image: Blob) {
  let reader = new FileReader();
  reader.addEventListener("load", () => {
     this.imageToShow = reader.result;
  }, false);

  if (image) {
     reader.readAsDataURL(image);
  }
}

view(content5,id){
  let formData= {
    settlementId:id,
    userName:sessionStorage.getItem("username")
  }

    this.ordertransactionservice.getSettlementById(formData).subscribe(data=>{
        this.viewSettlement = data;
    });

  this.modalService.open(content5, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

location(lat,lan){
  this.lat = Number(lat);
  this.lan = Number(lan);
}

createImageFromBlob1(image: Blob) {
  let reader = new FileReader();
  reader.addEventListener("load", () => {
     this.imageToShowpartner = reader.result;
  }, false);

  if (image) {
    reader.readAsDataURL(image);
 }
}

createImageFromBlob2(image: Blob) {
  let reader = new FileReader();
  reader.addEventListener("load", () => {
     this.imageToShowvehicle1 = reader.result;
  }, false);

  if (image) {
    reader.readAsDataURL(image);
 }
}

createImageFromBlob3(image: Blob) {
  let reader = new FileReader();
  reader.addEventListener("load", () => {
     this.imageToShowvehicle2 = reader.result;
  }, false);

  if (image) {
    reader.readAsDataURL(image);
 }
}

createImageFromBlob4(image: Blob) {
  let reader = new FileReader();
  reader.addEventListener("load", () => {
     this.imageToShowvehicle3 = reader.result;
  }, false);

  if (image) {
    reader.readAsDataURL(image);
 }
}

createImageFromBlob5(image: Blob) {
  let reader = new FileReader();
  reader.addEventListener("load", () => {
     this.imageToShowvehicle4 = reader.result;
  }, false);

  if (image) {
    reader.readAsDataURL(image);
 }
}

createImageFromBlob6(image: Blob) {
  let reader = new FileReader();
  reader.addEventListener("load", () => {
     this.imageasset = reader.result;
  }, false);

  if (image) {
    reader.readAsDataURL(image);
 }
}

assign(){
  let id = (<HTMLInputElement>document.getElementById('countryFormControl')).value;
  let latitude = this.vehicleModels.vehicleId.vehicleRegLatitude;
  let langitude = this.vehicleModels.vehicleId.vehicleRegLongtitude;
  let assign = new OrderAssignModel(Number(id),sessionStorage.getItem('username'),latitude,langitude);
  this.ordertransactionservice.assignto(this.orderId,assign).subscribe(data=>{
    Swal.fire({
      html: '<pre>' + data.responseMessage + '</pre>',
      customClass: {
        popup: 'format-pre'
      }
    }).then((value) => {
      window.location.reload();
    });; ; 
  });
}

onOptionsSelected(value:Number){
  this.vehicleservice.getVehicleById(value).subscribe(data=>{
    this.vehicle = data;
    this.check = data.vehicleName;
  });
}
 

create(content,userId){
  
  this.partnerId=userId;
  this.pgmodeService.paymentmode().subscribe(data=>{
    this.paymentModes = data;
  });

  this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

assignPayment(id:Number,event) {  
  const checked = event.target.checked;  
   if (checked) {  
     this.resultText.push(id);  
      } else {  
        const index = this.resultText.indexOf(id);  
        this.resultText.splice(index, 1);  
    }  
    this.values=this.resultText.toString();  
    const count=this.resultText.length;  
  }  

addpaymentmethod(){
  if(this.resultText.length>0){
   
      let formData= {
        partnerId:this.partnerId,
        paymentModeIds:this.values,
        defaultPaymentMode:1,
        createdBy:sessionStorage.getItem("username")
      }

      this.pgmodeService.add(formData).subscribe(data=>{
        if(data.response.flag==1){
         // sessionStorage.setItem("menustatus",'1');
          sessionStorage.setItem("menumessage","Payment Mode has been assigned against partner");
          window.location.reload();
        }
      },err => {
        if(err.error.statusCode==406){
          this.router.navigate(['/login']);
        }else if(err.error.statusCode==500){
          this.router.navigate(['/page-error']);
        }else if(err.error.statusCode==404){
          this.router.navigate(['/pagenotfound']);
        }
     });
   
  }else{

  }
}
}




