import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { KycMasterModel } from '../model/kycmaster';
import { GenericResponseModel } from '../model/genricresponse';
import { VehicleAttachModel } from '../model/vehicleModel';
import { KycDocumentService } from '../services/kycdocument.service';
import { PartnersService } from '../services/partners.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as moment from 'moment';
import { PromotionsService } from '../services/promotions.service';

@Component({
  selector: 'app-master-promotions',
  templateUrl: './master-promotions.component.html',
  styleUrls: ['./master-promotions.component.css']
})
export class MasterPromotionsComponent {
 
  active = 1;
  closeResult = '';
  public isCollapsed = false;
  kycdocument:GenericResponseModel;
  promotionsModel:GenericResponseModel;
  kycMasterModel:KycMasterModel;
  vehicleAttachModel:VehicleAttachModel[];
  errorFlag:boolean;
  errorFlag1:boolean;
  errorMessage:String;
  refreshflag:boolean;
  dateCheck:String;
  today=new Date();
  selectedFiles: FileList;
  currentFile: File;
  imageToShowkyc: any;
  isImageLoading1: boolean;
  effeDate:String;
  validDate:String;
  bannerStatus:Number;
  promotionId:Number;
  bannerImage:String;
  deleteUserId:Number;
  constructor(private router: Router,private modalService: NgbModal,private kycdocumentservice:KycDocumentService,private partnerservice:PartnersService,private spinnerService:NgxSpinnerService,private promotionsservice:PromotionsService) {}

  ngOnInit() : void {
    let date  = moment(new Date(this.today)).format("yyyy-MM-DDTHH:MM").toString();
    this.dateCheck = date; 

    this.promotionsservice.getAllPromotions().subscribe(data=>{
      this.promotionsModel = data;
    },err => {
      if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });
  }

  open2(reject,viewId){
    this.deleteUserId = viewId;
    this.modalService.open(reject, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  delete(){
    let userId = this.deleteUserId;
    this.promotionsservice.delete(userId).subscribe(data=>{
      if(data.statusCode==200){
        Swal.fire({
          html: '<pre>' + data.responseMessage + '</pre>',
          customClass: {
            popup: 'format-pre'
          }
        }).then((value) => {
          window.location.reload();
        });; ;
      }
    },err => {
      if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
    })
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
  }

  addpromotion(){
      let effdate = (<HTMLInputElement>document.getElementById('effdate')).value;
      let validdate = (<HTMLInputElement>document.getElementById('validdate')).value;
      let status = (<HTMLInputElement>document.getElementById('status')).value;
      let bannerfile = (<HTMLInputElement>document.getElementById('bannerfile')).value;
      if(effdate!=null && effdate!=='' && validdate!=null && validdate!=='' && status!=null && status!=='' && bannerfile!=null && bannerfile!==''){
        let filename = this.selectedFiles.item(0).name;
        if(filename.match(".jpeg") || filename.match(".jpg")){ 
        if(validdate < effdate){
          this.errorFlag= true;
          this.errorMessage = "Validate Date should be greater than effective date!";
          this.spinnerService.hide();
          setTimeout(function() {
            this.errorFlag = false;
        }.bind(this), 3000); 
        }else{
          this.currentFile = this.selectedFiles.item(0);
        let d = effdate.concat(":00");
        let effDate=new Date(d);
  
        let d1 = validdate.concat(":00");
        let vaiDate=new Date(d1);
        let input = new FormData();
        console.log(this.currentFile);
        input.append('bannerImage',this.currentFile);
        input.append('effectiveDate',JSON.stringify(effDate.getTime()));
        input.append('validUptoDate',JSON.stringify(vaiDate.getTime()));
        input.append('createdBy',sessionStorage.getItem('username'));
        input.append('userId',sessionStorage.getItem('id'));
        input.append('status',JSON.stringify(Number(status)));
  
        this.promotionsservice.addAdvertiesment(input).subscribe(data=>{
          if(data.statusCode==200){
            this.modalService.dismissAll();
            Swal.fire({
              html: '<pre>' + 'Promotions added successfully' + '</pre>',
              customClass: {
                popup: 'format-pre'
              }
            }).then((value) => {
              window.location.reload();
            });; ;   
          }else{
            this.errorFlag= true;
            this.errorMessage = data.responseMessage;
            this.spinnerService.hide();
            setTimeout(function() {
              this.errorFlag = false;
          }.bind(this), 3000);    
          }
        },err => {
         if(err.error.statusCode==500){
            this.router.navigate(['/page-error']);
          }else if(err.error.statusCode==404){
            this.router.navigate(['/pagenotfound']);
          }
       });
        }
      }else{
        this.errorFlag= true;
        this.errorMessage = "Please select jpeg/jpg format!";
        setTimeout(function() {
          this.errorFlag = false;
      }.bind(this), 3000);
      }
    }else{
      this.errorFlag= true;
      this.errorMessage = "Please Enter mandatory fields!";
      setTimeout(function() {
        this.errorFlag = false;
    }.bind(this), 3000);
    }
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open1(content1,id,validdate,effDate,bannerStatus,bannerImage) {
    let effdate  = moment(new Date(effDate)).format("yyyy-MM-DDThh:mm").toString();
    let validate  = moment(new Date(validdate)).format("yyyy-MM-DDThh:mm").toString();
    this.bannerStatus = bannerStatus;
    this.effeDate = effdate;
    this.validDate = validate;
    this.promotionId = id;
    this.bannerImage = bannerImage;
    this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open3(content2,id){
    this.promotionsservice.getAds(id).subscribe(data=>{
      this.createImageFromBlob2(data);
    this.isImageLoading1 = false;
}, error => {
this.isImageLoading1 = false;
this.imageToShowkyc= null;
});
    this.modalService.open(content2, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  createImageFromBlob2(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
       this.imageToShowkyc = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
   }else{
   }
  }

  updateAdvertiement(){
    let effdate = (<HTMLInputElement>document.getElementById('effdate')).value;
      let validdate = (<HTMLInputElement>document.getElementById('validdate')).value;
      let status = (<HTMLInputElement>document.getElementById('status')).value;
      let bannerfile = (<HTMLInputElement>document.getElementById('bannerfile')).value;
      if(effdate!=null && effdate!=='' && validdate!=null && validdate!=='' && status!=null && status!=='' && bannerfile!=null && bannerfile!==''){
        let filename = this.selectedFiles.item(0).name;
        if(filename.match(".jpeg") || filename.match(".jpg")){ 
        if(validdate < effdate){
          this.errorFlag= true;
          this.errorMessage = "Validate Date should be greater than effective date!";
          this.spinnerService.hide();
          setTimeout(function() {
            this.errorFlag = false;
        }.bind(this), 3000); 
        }else{
          this.currentFile = this.selectedFiles.item(0);
          let d = effdate.concat(":00");
          let effDate=new Date(d);
    
          let d1 = validdate.concat(":00");
          let vaiDate=new Date(d1);
          let input = new FormData();
          input.append('bannerImage',this.currentFile);
          input.append('effectiveDate',JSON.stringify(effDate.getTime()));
          input.append('validUptoDate',JSON.stringify(vaiDate.getTime()));
          input.append('modifiedBy',sessionStorage.getItem('username'));
          input.append('userId',sessionStorage.getItem('id'));
          input.append('status',JSON.stringify(Number(status)));
          input.append('id',JSON.stringify(this.promotionId));
          this.promotionsservice.Update(input).subscribe(data=>{
            if(data.statusCode==200){
              this.modalService.dismissAll();
              Swal.fire({
                html: '<pre>' + 'Promotions updated successfully' + '</pre>',
                customClass: {
                  popup: 'format-pre'
                }
              }).then((value) => {
                window.location.reload();
              });; ;   
            }else{
              this.errorFlag= true;
              this.errorMessage = data.responseMessage;
              this.spinnerService.hide();
              setTimeout(function() {
                this.errorFlag = false;
            }.bind(this), 3000);        
            }
          },err => {
            if(err.error.statusCode==500){
              this.router.navigate(['/page-error']);
            }else if(err.error.statusCode==404){
              this.router.navigate(['/pagenotfound']);
            }
         });
        }
      }else{
        this.errorFlag= true;
        this.errorMessage = "Please select jpeg/jpg format!";
        setTimeout(function() {
          this.errorFlag = false;
      }.bind(this), 3000);
      }
      }else{
        if(validdate < effdate){
          this.errorFlag= true;
          this.errorMessage = "Validate Date should be greater than effective date!";
          this.spinnerService.hide();
          setTimeout(function() {
            this.errorFlag = false;
        }.bind(this), 3000); 
        }else{
          let d = effdate.concat(":00");
          let effDate=new Date(d);
    
          let d1 = validdate.concat(":00");
          let vaiDate=new Date(d1);

          let formData= {
            effectiveDate:effDate.getTime(),
            validUptoDate:vaiDate.getTime(),
            modifiedBy:sessionStorage.getItem("username"),
            createdBy:sessionStorage.getItem("username"),
            bannerStatus:Number(status),
            id:this.promotionId
          }
          this.promotionsservice.updatePromotion(formData).subscribe(data=>{
            if(data.statusCode==200){
              this.modalService.dismissAll();
              Swal.fire({
                html: '<pre>' + 'Promotions updated successfully' + '</pre>',
                customClass: {
                  popup: 'format-pre'
                }
              }).then((value) => {
                window.location.reload();
              });; ;   
            }else{
              this.errorFlag= true;
              this.errorMessage = data.responseMessage;
              this.spinnerService.hide();
              setTimeout(function() {
                this.errorFlag = false;
            }.bind(this), 3000);        
            }
          },err => {
            if(err.error.statusCode==500){
              this.router.navigate(['/page-error']);
            }else if(err.error.statusCode==404){
              this.router.navigate(['/pagenotfound']);
            }
         });
        }
      }
  }
}
