import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { GenericResponseModel } from '../model/genricresponse';
import { ReportServices } from '../services/reports.service';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { Workbook } from 'exceljs';
import { NgxSpinnerService } from 'ngx-spinner';
import { GenericResponse } from '../model/genricmodelresponse';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { OrderTransactionService } from '../services/ordertran.service';
import { DecimalPipe } from '@angular/common';
import { Router } from '@angular/router';
@Component({
  selector: 'app-report-readyto-settlements',
  templateUrl: './report-readyto-settlements.component.html',
  styleUrls: ['./report-readyto-settlements.component.css']
})
export class ReportReadytoSettlementsComponent implements OnInit {
  symbole:String; 
 public isCollapsed = false;
 active = 1;
 transactionDetail:GenericResponseModel;
 transactionfilterDetail:GenericResponseModel;
 settlementDetail:GenericResponseModel;
 settlementViewDetail:GenericResponseModel;
closeResult = '';
  errorFlag:boolean;
  errorMessage:String;
  refreshflag:boolean;
  responseDataList = [];
  revenuereportModel:GenericResponseModel;
  revenuefilterreportModel:GenericResponseModel;
  normalrevenue:boolean=true;
  excelexportcheck : boolean = false;
  responseDataListnew = [];
  readyToSettlementreportModel:GenericResponseModel;
  filterreadyToSettlementreportModel:GenericResponseModel;
  filterrefundreportModel:GenericResponseModel;
  filterpartner:boolean=false;
  filteronecheck:boolean= false;
  filterNoReceordreadyToSettlementreportModel:GenericResponseModel;
  readyToSettlementreportModelById:GenericResponseModel;
  refreshFlag:boolean=false;
  refundSettlementReportModel:GenericResponse;
  errorflag:boolean=false;
  startDate:String;
  endDate:String;
  resultText=[];  
  values:string;  
  orderID:Number;
  errorFlag1:boolean = false;
  cancelMessageModel:GenericResponseModel;
  status:Number;
  constructor(private modalService: NgbModal,private reportservice:ReportServices,private spinnerService:NgxSpinnerService,private ordertransactionservice: OrderTransactionService,private _decimalPipe: DecimalPipe,private router:Router) { }

  ngOnInit(): void {
    var nowdate = new Date();
    var monthStartDay = new Date(nowdate.getFullYear(), nowdate.getMonth(), 1);
    var monthEndDay = new Date(nowdate.getFullYear(), nowdate.getMonth() + 1, 0);
    let startDate = moment(new Date(monthStartDay)).format("yyyy-MM-DD").toString();
    let endDate = moment(new Date(monthEndDay)).format("yyyy-MM-DD").toString();
    this.startDate = startDate;
    this.endDate = endDate;
    this.status = 22;
    let formData= {
      startDate:startDate,
      endDate:endDate,
      flag:22
    }
     this.reportservice.settlementinitiate(formData).subscribe(data=>{
      if(data.statusCode==200){
        this.excelexportcheck = true;
       }else{
        this.excelexportcheck = false;
       }
       this.readyToSettlementreportModel = data;
     },err => {
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }else if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });
  }

  view(content6,id){
    this.reportservice.settlementinitiateById(id).subscribe(data=>{
      this.readyToSettlementreportModelById = data;
      console.log(this.readyToSettlementreportModelById);
    });
      this.modalService.open(content6, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
   }

  search(){
    this.refreshFlag = true;
    let fromdate = (<HTMLInputElement>document.getElementById('fromdate')).value;
    let todate = (<HTMLInputElement>document.getElementById('todate')).value;
    let status = (<HTMLInputElement>document.getElementById('status')).value;
    if(todate < fromdate){
      this.errorFlag = true;
      this.errorMessage = "End Date should be greater than Start Date!";
    }else{
      this.errorFlag = false;
      let formData= {
        startDate:fromdate,
        endDate:todate,
        flag:status
      }
      this.reportservice.settlementinitiate(formData).subscribe(data=>{
        this.status = Number(status);
       
        this.filterpartner = true;
        if(data.statusCode==200){
         this.filteronecheck = true;
          this.excelexportcheck = true;
          this.filterreadyToSettlementreportModel = data;
         }else{
           this.filteronecheck = false;
           this.filterNoReceordreadyToSettlementreportModel = data;
          this.excelexportcheck = false;
         }
       
       }); 
    }
  }

  exportToExcel(){
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let sno = 1;
    if(this.filterpartner==true){
      this.filterreadyToSettlementreportModel.response.forEach(element => {

        let partnerinfo = element['partnerBank'];  
        let vehicleinfo = element['vehicleBank'];  
        let admininfo = element['adminSettlementBank'];  
        let partner;
        if(partnerinfo!=null && partnerinfo!=='null'){
          partner = partnerinfo['customerPartnerId'];
        }else{
          partner = "N/A";
        }
        
        let vehicle;
        if(vehicleinfo!=null && vehicleinfo!=='null'){
          vehicle = vehicleinfo['vehicleId'];
        }else{
          vehicle = "N/A";
        }
        let orderDelivery = element['deliveryDatetime'];
        let orderdeliveryDate;
        if(orderDelivery!=null && orderDelivery!==''){
          let orderdatess = new Date(element['deliveryDatetime'])
          orderdeliveryDate = moment(new Date(orderdatess)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }else{
          orderdeliveryDate = "N/A";
        }
        
        let paymentda = element['paymentDatetime'];
        let payDate;
        if(paymentda!=null && paymentda!==''){
          let pay = new Date(element['paymentDatetime'])
          payDate = moment(new Date(pay)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }else{
          payDate = "N/A";
        } 
        
        let set = element['settledDateTime'];
        let setDate;
        if(set!=null && set!=='null'){
          let book = new Date(element['settledDateTime'])
          setDate = moment(new Date(book)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }else{
          setDate ="N/A";
        }

        let read = element['readytosettledDateTime'];
        let readDate;
        if(read!=null && read!==''){
          let book = new Date(element['readytosettledDateTime'])
          readDate = moment(new Date(book)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }else{
          readDate ="N/A";
        }
        
        var response = [];
        response.push(sno);
        
        response.push(element['orderId']);
        response.push(element['fuelType']);
        response.push(element['quantity']);
        response.push(orderdeliveryDate);

        if(element['transactionId']!=null && element['transactionId']!=='null'){
          response.push(element['transactionId']);
        }else{
          response.push("N/A");
        }
        response.push("₹ "+ this._decimalPipe.transform(element['totalPayableAmount'],"1.2-2"));
        response.push(element['paymentStatus']);
        response.push(payDate);

        response.push(element['customerName']);
        response.push(element['customerMobile']);

        if(partner!=='N/A'){
          response.push(partner['firstName']+partner['lastName']);
          response.push(partner['mobileNumber']);
          response.push("₹ "+ this._decimalPipe.transform(element['totalfuelcost'],"1.2-2"));
          response.push(partnerinfo['accountNumber']);
          response.push(partnerinfo['ifscCode']);
        }else{
          response.push("N/A");
          response.push("N/A");
          response.push("N/A");
          response.push("N/A");
          response.push("N/A");
        }

        if(vehicle!=='N/A'){
          response.push(element['vehicleNumber']);
          response.push(element['vehicleName']);
          response.push("₹ "+ this._decimalPipe.transform(element['partneramount'],"1.2-2"));
          response.push(vehicleinfo['accountNumber']);
          response.push(vehicleinfo['ifscCode']);
        }else{
          response.push(element['vehicleNumber']);
          response.push(element['vehicleName']);
          response.push("N/A");
          response.push("N/A");
          response.push("N/A");
        }
        
        response.push("₹ "+ this._decimalPipe.transform(element['internetHandlingFee'],"1.2-2"));
        response.push(admininfo['beneficiaryName']);
        response.push(admininfo['accountNumber']);
        response.push(admininfo['ifscCode']);

        if(element['readytoSettle']==22){
          response.push("Initiated");
        }else if(element['readytoSettle']==23){
          response.push("Completed");
        }
        response.push(readDate);
        response.push(setDate);
        this.responseDataListnew.push(response);
        sno++;
      });
    }else{
      this.readyToSettlementreportModel.response.forEach(element => {
        let partnerinfo = element['partnerBank'];  
        let vehicleinfo = element['vehicleBank'];  
        let admininfo = element['adminSettlementBank'];  
        let partner;
        if(partnerinfo!=null && partnerinfo!=='null'){
          partner = partnerinfo['customerPartnerId'];
        }else{
          partner = "N/A";
        }
        
        let vehicle;
        if(vehicleinfo!=null && vehicleinfo!=='null'){
          vehicle = vehicleinfo['vehicleId'];
        }else{
          vehicle = "N/A";
        }

        
        let delivdate = element['deliveryDatetime'];
        let deliveryDate;
        if(delivdate!=null && delivdate!=='null'){
          let datess = new Date(element['deliveryDatetime']);
        deliveryDate = moment(new Date(datess)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }else{
          deliveryDate = "N/A"
        }
        
        let paymentda = element['paymentDatetime'];
        let payDate;
        if(paymentda!=null && paymentda!=='null'){
          let pay = new Date(element['paymentDatetime'])
          payDate = moment(new Date(pay)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }else{
          payDate = "N/A";
        } 
        
        let set = element['settledDateTime'];
        let setDate;
        if(set!=null && set!=='null'){
          let book = new Date(element['settledDateTime'])
          setDate = moment(new Date(book)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }else{
          setDate ="N/A";
        }

        let read = element['readytosettledDateTime'];
        let readDate;
        if(read!=null && read!=='null'){
          let book = new Date(element['readytosettledDateTime'])
          readDate = moment(new Date(book)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }else{
          readDate ="N/A";
        }
        
        var response = [];
        response.push(sno);
        
        response.push(element['orderId']);
        response.push(element['fuelType']);
        response.push(element['quantity']);
        response.push(deliveryDate);

        if(element['transactionId']!=null && element['transactionId']!=='null'){
          response.push(element['transactionId']);
        }else{
          response.push("N/A");
        }
        response.push("₹ "+ this._decimalPipe.transform(element['totalPayableAmount'],"1.2-2"));
        response.push(element['paymentStatus']);
        response.push(payDate);

        response.push(element['customerName']);
        response.push(element['customerMobile']);

        if(partner!=='N/A'){
          response.push(partner['firstName']+partner['lastName']);
          response.push(partner['mobileNumber']);
          response.push("₹ "+ this._decimalPipe.transform(element['totalfuelcost'],"1.2-2"));
          response.push(partnerinfo['accountNumber']);
          response.push(partnerinfo['ifscCode']);
        }else{
          response.push("N/A");
          response.push("N/A");
          response.push("N/A");
          response.push("N/A");
          response.push("N/A");
        }

        if(vehicle!=='N/A'){
          response.push(element['vehicleNumber']);
          response.push(element['vehicleName']);
          response.push("₹ "+ this._decimalPipe.transform(element['partneramount'],"1.2-2"));
          response.push(vehicleinfo['accountNumber']);
          response.push(vehicleinfo['ifscCode']);
        }else{
          response.push(element['vehicleNumber']);
          response.push(element['vehicleName']);
          response.push("N/A");
          response.push("N/A");
          response.push("N/A");
        }
        
        response.push("₹ "+ this._decimalPipe.transform(element['internetHandlingFee'],"1.2-2"));
        response.push(admininfo['beneficiaryName']);
        response.push(admininfo['accountNumber']);
        response.push(admininfo['ifscCode']);

        if(element['readytoSettle']==22){
          response.push("Initiated");
        }else if(element['readytoSettle']==23){
          response.push("Completed");
        }
        response.push(readDate);
        response.push(setDate);
        this.responseDataListnew.push(response);
        sno++;
      });
    }
    this.excelexport();
  }
  excelexport(){
    const title = 'Ready To Settlements Report';
    const header = ["S.No","Order ID","Fuel","Quantity","Delivery Date & Time","Transaction ID", "Transaction Amount","Transaction Status", "Transaction Date & Time","Customer Name", "Customer Mobile" ,"Partner Name","Partner Mobile","Partner Payout Amount", "Partner Account Number","Partner IFSC Code","Vehicle Number","Vehicle Name","Vehicle Payout Amount","Vehicle Account Number","Vehicle IFSC Code","Cinch Payout Amount","Cinch Account Holder name","Cinch Account Number","Cinch IFSC Code","Status","Ready To Settle Date & Time","Settled Date & Time"]
    const data = this.responseDataListnew;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Ready To Settlements Report');
// Add new row
let titleRow = worksheet.addRow([title]);
// Set font, size and style in title row.
titleRow.font = { name: 'Times New Roman', family: 4, size: 16, bold: true };

// Blank Row
worksheet.addRow([]);
let subTitleRow = worksheet.addRow(['Start Date  : ' + this.startDate]);
  subTitleRow.font = { name: 'Times New Roman', family: 4, size: 12, bold: true };
  let subTitleRow1 = worksheet.addRow(['End Date  : ' + this.endDate]);
  subTitleRow1.font = { name: 'Times New Roman', family: 4, size: 12, bold: true };
  if(this.status==22){
    let subTitleRow2 = worksheet.addRow(['Status : ' + "Initiated"]);
    subTitleRow2.font = { name: 'Times New Roman', family: 4, size: 12, bold: true };
  }else if(this.status==23){
    let subTitleRow2 = worksheet.addRow(['Status : ' + "Completed"]);
    subTitleRow2.font = { name: 'Times New Roman', family: 4, size: 12, bold: true };
  }
  worksheet.addRow([]);

//Add row with current date
// let subTitleRow = worksheet.addRow(['Date : ' + this.datePipe.transform(new Date(), 'medium')]);
//Add Header Row
let headerRow = worksheet.addRow(header);
headerRow.font = {bold: true };
// Cell Style : Fill and Border
headerRow.eachCell((cell, number) => {
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFFFF' },
    bgColor: { argb: 'FF0000FF' },
  }
  cell.border = {  top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
});

data.forEach(d => {
  let row = worksheet.addRow(d);
  let qty = row.getCell(1);
  let qty1 = row.getCell(2);
  let qty2 = row.getCell(3);
  let qty3 = row.getCell(4);
  let qty4 = row.getCell(5);
  let qty5 = row.getCell(6);
  let qty6 = row.getCell(7);
  let qty7 = row.getCell(8);
  let qty8 = row.getCell(9);
  let qty9 = row.getCell(10);
  let qty10 = row.getCell(11);
  let qty11 = row.getCell(12);
  let qty12 = row.getCell(13);
  let qty13 = row.getCell(14);
  let qty14 = row.getCell(15);
  let qty15 = row.getCell(16);
  let qty16 = row.getCell(17);
  let qty17 = row.getCell(18);
  let qty18 = row.getCell(19);
  let qty19 = row.getCell(20);
  let qty20 = row.getCell(21);
  let qty21 = row.getCell(22);
  let qty22 = row.getCell(23);
  let qty23 = row.getCell(24);
  let qty24 = row.getCell(25);
  let qty25 = row.getCell(26);
  let qty26 = row.getCell(27);
  let qty27 = row.getCell(28);
  qty.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty1.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty2.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty3.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty4.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty5.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty6.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty8.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty9.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty10.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty11.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty12.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty13.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty14.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty15.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty16.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty17.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty18.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty19.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty20.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty21.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty22.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty23.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty24.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty25.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty26.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty27.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }

}
);
workbook.xlsx.writeBuffer().then((data) => {
  let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  FileSaver.saveAs(blob, 'Ready To Settlements Report.xlsx');
});
   }

   refresh(){
     this.refreshFlag = false;
     window.location.reload();
   } 

   private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  allowNumericDigitsOnlyOnKeyUpWithoutdot(e) {		
    const charCode = e.which ? e.which : e.keyCode;
    if(charCode > 31 && (charCode < 48 || charCode > 57)){
        e.preventDefault();
    }else{
      return true;
    }
  }

  refund(id){
    let transactionid = (<HTMLInputElement>document.getElementById('transactionid')).value;
    let date = (<HTMLInputElement>document.getElementById('date')).value;
    let amount = (<HTMLInputElement>document.getElementById('amount')).value;
    if(transactionid!=null && transactionid!=='' && date!=null && date!=='' && amount!=null && amount!==''){
      this.errorflag = false;
      let d = date.concat(":00");
      let currentDate=new Date(d);
      let time = currentDate.getTime();
      let formData= {
        deductionAmount:Number(amount),
        refundRemarks:transactionid,
        refundDateTime:time
      }

      this.reportservice.getRefundCompleted(id,formData).subscribe(data=>{
        this.modalService.dismissAll();
        Swal.fire({
          html: '<pre>' + data.responseMessage + '</pre>',
          customClass: {
            popup: 'format-pre'
          }
        }).then((value) => {
          window.location.reload();
        });; ; 
      });

    }else{
      this.errorflag = true;
      this.errorMessage = 'Please enter all fields!';
    }
  }

  onChange(id:Number,event) {  
   const checked = event.target.checked;  
   if (checked) {  
   this.values = String(id);
   }
      if (checked) {  
        this.resultText.push(id);  
         } else {  
           const index = this.resultText.indexOf(id);  
           this.resultText.splice(index, 1);  
       }  
       this.values=this.resultText.toString();  
    }

    sendOtp(id){
          this.reportservice.generateClosingOTP(id).subscribe(data=>{
            if(data.statusCode==200){
              Swal.fire({
                html: '<pre>' + data.responseMessage + '</pre>',
                customClass: {
                  popup: 'format-pre'
                }
              }).then((value) => {
                window.location.reload();
              });; ; 
            }else{
              Swal.fire({
                html: '<pre>' + data.responseMessage + '</pre>',
                customClass: {
                  popup: 'format-pre'
                }
              }).then((value) => {
                window.location.reload();
              });; ; 
            }
          });
    }

    markasSettle(){
      alert();
      alert(this.resultText.length);
      if(this.resultText.length>0){
        this.errorFlag= false;
        alert(this.values);
        this.reportservice.settlementCompleted(this.values).subscribe(data=>{
          if(data.statusCode==200){
            Swal.fire({
              html: '<pre>' + data.responseMessage + '</pre>',
              customClass: {
                popup: 'format-pre'
              }
            }).then((value) => {
              window.location.reload();
            });; ; 
          }else{
            Swal.fire({
              html: '<pre>' + data.responseMessage + '</pre>',
              customClass: {
                popup: 'format-pre'
              }
            }).then((value) => {
              window.location.reload();
            });; ; 
          }
        });
      }else{
        this.errorFlag= true;
        this.errorMessage="Please select atleast one record!";
      }
      
    }
}
