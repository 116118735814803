<app-header></app-header>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
</ngx-spinner>
<ol class="breadcrumb breadcrumb-alt" style="margin-top:100px; width: auto;" >
  <li class="breadcrumb-item" style="margin-left: 15px;"><a href="#" routerLink="/dashboard">Home</a></li>
  <li class="breadcrumb-item active">COUPON MASTER</li>
  <div class="btn-section-wrapper">
      <button style="margin-top: -10px;" type="button" class="label label-info" (click)="open(content)"><i class="fa fa-plus" aria-hidden="true"></i>
        Add Coupon</button>
</div>
</ol>
<div class="container  container-top-dashboard-section">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
	 <!-- <div class="btn-section-wrapper">
     <button style="margin-top: -10px;" type="button" class="btn btn-success" (click)="open(content)"><i class="fa fa-plus" aria-hidden="true"></i>
 Add Coupon</button>
</div> -->
<li [ngbNavItem]="1" style="margin-top: 30px;">
 <div style="display: none;">{{couponModel.statusCode}}</div>
 <ng-template ngbNavContent>
   <div class="card-body" >
           <div class="table-responsive">
             <table datatable class="ch-table">
               <thead>
                 <tr> 
                   <th style="width: 40px; text-align: center;">Sl.No.</th>
                   <th style="text-align: left;">Coupon Code</th>
                   <th style="text-align: right;">Coupon Offer Price</th>
                   <th style="text-align: right;">Min.Purchase Amount</th>
                   <th style="text-align: center;">Status</th>
                   <th style="text-align: left;">Created Date & Time</th>
                   <th style="text-align: left;">Effective From</th>
                   <th style="text-align: left;">Valid Upto</th>
                   <th style="text-align: center;">Action</th>
                 </tr>
               </thead>
               <tbody>
                 <tr *ngFor="let coupon of couponModel.response; let i=index">
                   <td align="center" class="v-align-middle ">{{i + 1}}</td>
                   <td align="left" class="v-align-middle">{{coupon.couponCode}}</td>
                   <!-- <td align="center" class="v-align-middle">
                     <span *ngIf="coupon.couponpercentAmount==1">{{coupon.couponAmount}} %</span>
                     <span *ngIf="coupon.couponpercentAmount!=1">N/A</span>
                   </td> -->
                   <td align="right" class="v-align-middle">
                    <span *ngIf="coupon.couponpercentAmount==2"><i class="fa fa-inr" aria-hidden="true"></i> {{coupon.couponAmount | number : '1.2-2'}}</span>
                    <span *ngIf="coupon.couponpercentAmount!=2">N/A</span>
                  </td>
                  <td align="right" class="v-align-middle">
                    <span *ngIf="coupon.minPurchaseAmount!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{coupon.minPurchaseAmount | number : '1.2-2'}}</span>
                    <span *ngIf="coupon.minPurchaseAmount==null">N/A</span>
                  </td>
                   <td align="center" *ngIf="coupon.couponStatus===1" class="v-align-middle ht-active">Active</td>
                   <td align="center" *ngIf="coupon.couponStatus===2" class="v-align-middle ht-inactive">InActive</td>
                   <td align="left" class="v-align-middle">{{coupon.createdDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                   <td align="left" class="v-align-middle">{{coupon.effectiveFrom | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                   <td align="left" class="v-align-middle">{{coupon.couponValidUpto | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                   <td align="center" class="v-align-middle">
                     <button title="Edit" (click)="open1(content1,coupon.id,coupon.couponCode,coupon.couponAmount,coupon.minPurchaseAmount,coupon.couponStatus,coupon.effectiveFrom,coupon.couponValidUpto,coupon.couponpercentAmount)" class="label label-success"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;
                     <!-- <button title="delete"class="label label-danger" (click)="open2(reject,coupon.id)"><i class="fa fa-trash" aria-hidden="true"></i></button> -->
                  </td>
                 </tr>
               </tbody>
             </table>
       </div>
         </div>
 </ng-template>
</li>
</ul>
<div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Coupon</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <div class="modal-body master-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Coupon Code *</label>
        <input type="text" id="couponcode" (keypress)="restrictwhitespace($event)" name="couponcode" class="form-control"  placeholder="Enter Coupon Code">
      </div>
      <!-- <div class="form-group">
        <input style="margin-left: 20px;" (click)="check(1)" class="form-check-input" type="radio" name="exampleRadios" id="imgradio" value="1" checked>
            <label style="margin-left: 40px;" class="form-check-label" for="offerinper">
              Offer in % *
            </label>
            <input style="margin-left: 40px;" (click)="check(2)" class="form-check-input"  type="radio" name="exampleRadios" id="pdfradio" value="2">
            <label style="margin-left: 60px;" class="form-check-label" for="offerinrs">
              Offer in Rs *
            </label>     
      </div> -->
      <div class="form-group">
        <label for="dateOfBirth">Coupon Offer Price *</label>
        <!-- <input *ngIf="!radioCheck" type="text" id="otherinfo" (keypress)="allowOnlyNumber($event)" [(ngModel)]="defaultValue" (change)="handleChange()" name="otherinfo" class="form-control"  placeholder="Enter Other Info"> -->
        <input type="text" id="otherinfo"  (keypress)="allowNumericDigitsOnlyOnKeyUp($event)" name="otherinfo" class="form-control"  placeholder="Enter Coupon Offer Price">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Min Purchase Amount</label>
        <input type="text" id="minpuramt" name="minpuramt" (keypress)="allowNumericDigitsOnlyOnKeyUp($event)" class="form-control"  placeholder="Enter Min Purchase Amount">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Effective From *</label>
        <input type="datetime-local" class="form-control" id="effdate" name="effdate" [min]="dateCheck" aria-describedby="emailHelp" placeholder="Select Role" required>
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Valid Upto *</label>
        <input type="datetime-local" class="form-control" id="validdate" name="validdate" [min]="dateCheck" aria-describedby="emailHelp" placeholder="Select Role" required>
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Status *</label>
        <div class="dropdown">
  <select id="status" name="status" value="status" class="form-control">
    <option value=1>Active</option>
    <option value=2>Inactive</option>
</select>
</div>
      </div>
      <div *ngIf="errorFlag">
        <div class="error-style">{{errorMessage}}</div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="addCoupon()" >Add Coupon</button>
  </div>
</ng-template>


<ng-template #content1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Coupon</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <div class="modal-body master-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Coupon Code *</label>
        <input type="text" id="couponcode" disabled (keypress)="restrictwhitespace($event)" name="couponcode" class="form-control" [(ngModel)]="couponcode" placeholder="Enter Coupon Code">
      </div>

      <!-- <div *ngIf="checkperamt==1"class="form-group">
        <input  style="margin-left: 20px;" (click)="check(1)" class="form-check-input" type="radio" name="exampleRadios" id="imgradio" value="1" checked>
        <label style="margin-left: 40px;" class="form-check-label" for="offerinper">
          Offer in % *
        </label>
        <input style="margin-left: 40px;" (click)="check(2)" class="form-check-input"  type="radio" name="exampleRadios" id="pdfradio" value="2">
            <label style="margin-left: 60px;" class="form-check-label" for="offerinrs">
              Offer in Rs *
            </label>     
      </div> -->
      <!-- <div *ngIf="checkperamt==2"class="form-group">
        <input  style="margin-left: 20px;" (click)="check(1)" class="form-check-input" type="radio" name="exampleRadios" id="imgradio" value="1">
        <label style="margin-left: 40px;" class="form-check-label" for="offerinper">
          Offer in %
        </label>
        <input style="margin-left: 40px;" (click)="check(2)" class="form-check-input"  type="radio" name="exampleRadios" id="pdfradio" value="2" checked>
        <label style="margin-left: 60px;" class="form-check-label" for="offerinrs">
          Offer in Rs
        </label>   
      </div> -->
      <div class="form-group">
        <label for="dateOfBirth">Coupon Offer Price *</label>
      <!-- <input *ngIf="checkperamt==1"  type="text" id="otherinfo" (keypress)="allowOnlyNumber($event)" [(ngModel)]="couponamt" (change)="handleChange()" name="otherinfo" class="form-control"  placeholder="Enter Other Info"> -->
      <input type="text" id="otherinfo"  (keypress)="allowNumericDigitsOnlyOnKeyUp($event)"name="otherinfo" [(ngModel)]="couponamt" class="form-control"  placeholder="Enter Coupon Offer Price">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Min Purchase Amount</label>
        <input type="text" id="minpuramt" name="minpuramt" (keypress)="allowNumericDigitsOnlyOnKeyUp($event)" [(ngModel)]="minpuramt" class="form-control"  placeholder="Enter Min Purchase Amount">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Effective From *</label>
        <input type="datetime-local" class="form-control" id="effdate" name="effdate" [(ngModel)]="effeDate" [min]="dateCheck" aria-describedby="emailHelp" placeholder="Select Role" required>
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Valid Upto *</label>
        <input type="datetime-local" class="form-control" id="validdate" name="validdate"  [(ngModel)]="validDate" [min]="dateCheck" aria-describedby="emailHelp" placeholder="Select Role" required>
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Status *</label>
        <div class="dropdown">
  <select id="status" name="status"  [(ngModel)]="status" value="status" class="form-control">
    <option value=1>Active</option>
    <option value=2>Inactive</option>
</select>
</div>
      </div>
      <div *ngIf="errorFlag">
        <div class="error-style">{{errorMessage}}</div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="updateCoupon()" >Update Coupon</button>
  </div>
</ng-template>

<ng-template #reject let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Do you want delete the coupon?</label>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="delete()" (click)="modal.close('Save click')">yes</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">no</button>
  </div>
</ng-template>

<router-outlet></router-outlet>


