import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgOtpInputModule } from 'ng-otp-input';
import { LoginModel } from '../model/login';
import { UserModel } from '../model/user';
import { ForgotPasswordService } from '../services/forgot.service';


@Component({
  selector: 'app-forgot-password-otp-verification',
  templateUrl: './forgot-password-otp-verification.component.html',
  styleUrls: ['./forgot-password-otp-verification.component.css'],
     
})
export class ForgotPasswordOtpVerificationComponent implements OnInit{

otp: String; 
email:String;
showOtpComponent = true; 
isError = false;
errormessage : String;
timeLeft: number = 180;
interval;

constructor(private route: ActivatedRoute,private forgotservice: ForgotPasswordService,private router: Router) { }        
onOtpChange(otp) {   
this.otp = otp; 
}  

ngOnInit() : void {
  this.interval = setInterval(() => {
    if(this.timeLeft > 0) {
      this.timeLeft--;
    } else {
      this.timeLeft = 180;
    }
  },1000)
  this.route.queryParams.subscribe(params => {
    this.email= params.emailId;
  });
}

sendOTP(){
  let verifyOtp= new LoginModel(0,this.email,"",this.otp);
  this.forgotservice.verifyOTP(verifyOtp).subscribe( data => {
    if(data.flag==1){
      this.router.navigate(['/change-password'],{ queryParams: { emailId: this.email} });
    }else{
      this.isError = true;
      this.errormessage = data.message;
    }
  });
}

resendOTP(){
  let resendOtp= new LoginModel(0,this.email,"","");
  this.forgotservice.resendOtp(resendOtp).subscribe( data => {
    if(data.flag==1){
      this.router.navigate(['/change-password'],{ queryParams: { emailId: this.email} });
    }else{
      this.isError = true;
      this.errormessage = data.message;
    }
  });
}

}
