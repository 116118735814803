import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { GenericResponse } from '../model/genricmodelresponse';
import { OrderTransactionService } from '../services/ordertran.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-invoice-generation',
  templateUrl: './invoice-generation.component.html',
  styleUrls: ['./invoice-generation.component.css']
})
export class InvoiceGenerationComponent implements OnInit {
  
  invoiceModel:GenericResponse;
  address:String;
  address1:String;
  address2:String;
  address3:String;
  address4:String;
  address5:String;
  address6:String;
  address7:String;
  address8:String;
  address9:String;
  address10:String;
  length:Number;
  closeResult = '';
  selectedFiles: FileList;
  errorFlag:boolean;
  errorFlag1:boolean;
  errorMessage:String;
  mailflag:boolean = false;
  currentFile: File;
  roundofftotalamount:Number;
  roundoffamount:Number;
  constructor(private ordertransactionservice: OrderTransactionService,private modalService: NgbModal,private router:Router) { }

  ngOnInit(): void {

    let orderID = sessionStorage.getItem("invoice");
    this.ordertransactionservice.generateInvoice(Number(orderID)).subscribe(data=>{
      this.invoiceModel = data;
      this.roundofftotalamount = Math.round(Number(this.invoiceModel.response.invoice.totalPayableAmount));
      this.roundoffamount = Number(this.roundofftotalamount) - Number(this.invoiceModel.response.invoice.totalPayableAmount);
      let cusAddress = this.invoiceModel.response.invoice.deliveryAddress;
      let arr = cusAddress.split(",");
      this.length = Number(arr.length);

      if(this.length==2){
        this.address = (arr[0]);
        this.address1 = (arr[1]);
      }else if(this.length==3){
        this.address = (arr[0]);
        this.address1 = (arr[1]);
        this.address2 = (arr[2]);
      }else if(this.length==4){
        this.address = (arr[0]);
        this.address1 = (arr[1]);
        this.address2 = (arr[2]);
        this.address3 = (arr[3]);
      }else if(this.length==5){
        this.address = (arr[0]);
        this.address1 = (arr[1]);
        this.address2 = (arr[2]);
        this.address3 = (arr[3]);
        this.address4 = (arr[4]);
      }else if(this.length==6){
        this.address = (arr[0]);
        this.address1 = (arr[1]);
        this.address2 = (arr[2]);
        this.address3 = (arr[3]);
        this.address4 = (arr[4]);
        this.address5 = (arr[5]);
      }else if(this.length==7){
        this.address = (arr[0]);
        this.address1 = (arr[1]);
        this.address2 = (arr[2]);
        this.address3 = (arr[3]);
        this.address4 = (arr[4]);
        this.address5 = (arr[5]);
        this.address6 = (arr[6]);
      }else if(this.length==8){
        this.address = (arr[0]);
        this.address1 = (arr[1]);
        this.address2 = (arr[2]);
        this.address3 = (arr[3]);
        this.address4 = (arr[4]);
        this.address5 = (arr[5]);
        this.address6 = (arr[6]);
        this.address7 = (arr[7]);
      }else if(this.length==9){
        this.address = (arr[0]);
        this.address1 = (arr[1]);
        this.address2 = (arr[2]);
        this.address3 = (arr[3]);
        this.address4 = (arr[4]);
        this.address5 = (arr[5]);
        this.address6 = (arr[6]);
        this.address7 = (arr[7]);
        this.address8 = (arr[8]);
      }else if(this.length==10){
        this.address = (arr[0]);
        this.address1 = (arr[1]);
        this.address2 = (arr[2]);
        this.address3 = (arr[3]);
        this.address4 = (arr[4]);
        this.address5 = (arr[5]);
        this.address6 = (arr[6]);
        this.address7 = (arr[7]);
        this.address8 = (arr[8]);
        this.address9 = (arr[9]);
      }else if(this.length==11){
        this.address = (arr[0]);
        this.address1 = (arr[1]);
        this.address2 = (arr[2]);
        this.address3 = (arr[3]);
        this.address4 = (arr[4]);
        this.address5 = (arr[5]);
        this.address6 = (arr[6]);
        this.address7 = (arr[7]);
        this.address8 = (arr[8]);
        this.address9 = (arr[9]);
        this.address10 = (arr[10]);
      }
    });
  }

  printComponent() {
    window.print();
    this.mailflag = true;
}

  email(){
    let orderID = Number(sessionStorage.getItem("invoice"));
    this.ordertransactionservice.sendInvoiceEmail(orderID).subscribe(data=>{
      if(data.statusCode==200){
        Swal.fire({
          html: '<pre>' + data.responseMessage + '</pre>',
          customClass: {
            popup: 'format-pre'
          }
        }).then((value) => {
          window.location.reload();
        });; ; 
      }else{
        Swal.fire({
          html: '<pre>' + data.responseMessage + '</pre>',
          customClass: {
            popup: 'format-pre'
          }
        }).then((value) => {
          window.location.reload();
        });; ; 
      }
    },err => {
        if(err.error.statusCode==406){
          this.router.navigate(['/login']);
        }else if(err.error.statusCode==500){
          this.router.navigate(['/page-error']);
        }else if(err.error.statusCode==404){
          this.router.navigate(['/pagenotfound']);
        }
     });
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
