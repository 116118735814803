export class LoginModel{
    userId: number;
    emailAddress: String;
    userPassword:String;
    otpCode:String;
    constructor(userId: number, emailAddress: String,userPassword:String, otpCode:String){
    this.userId = userId;
    this.emailAddress = emailAddress;
    this.userPassword= userPassword;
    this.otpCode = otpCode;
    }
    }