import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { RestResponseModel } from '../model/response';
import { ChangePasswordModel } from '../model/changepassword';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: "root"
  })
  export class ChangePasswordService {

    constructor(private httpClient:HttpClient) {}

  private baseUrl = environment.devurl;
  private auth = environment.headers;

  public changePassword(id,changePassword){
 // const headers = this.auth;
 let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
 const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.put<RestResponseModel>(this.baseUrl+"/changepasswordweb/view"+"/"+id,changePassword,{headers});
  }

  }