import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { FuelTypeModel } from '../model/fueltype';
import { RestResponseModel } from '../model/response';
import { environment } from 'src/environments/environment';
import { GenericResponseModel } from '../model/genricresponse';
import { KycMasterModel } from '../model/kycmaster';

@Injectable({
    providedIn: "root"
  })
  export class KycDocumentService {

    constructor(private httpClient:HttpClient) {}

  private baseUrl = environment.devurl;
  private auth = environment.headers;

    public getAllKyc(){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/kycdocument/view",{headers});
  }

  public deleteKyc(id){
   // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/kycdocument/delete"+"/"+id,{headers});
  }

  public addkyc(kyc){
      // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.post<GenericResponseModel>(this.baseUrl+"/kycdocument/add",kyc,{headers});
  }

  public getKycId(id){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<KycMasterModel>(this.baseUrl+"/kycdocument/viewKyc"+"/"+id,{headers});
  }

  public update(id,kyc){
      // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.put<GenericResponseModel>(this.baseUrl+"/kycdocument/upadte"+"/"+id,kyc,{headers});
  }

  }