<app-header></app-header>

<!-- START PAGE-CONTAINER -->

<div class="page-container "> 
  <!-- START PAGE CONTENT WRAPPER -->
  <div class="page-content-wrapper "> 
    <!-- START PAGE CONTENT -->
    <div class="content sm-gutter"> 
      <!-- START BREADCRUMBS -->
      <div class="bg-white">
        <div class="container">
          <ol class="breadcrumb breadcrumb-alt">
            <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Home</a></li>
            <li class="breadcrumb-item active">Asset Tracking </li>
          
          </ol>
        </div>
      </div>
      <!-- END BREADCRUMBS -->
      
      <!-- START CONTAINER FLUID --> 
      
      <!-- START CONTAINER FLUID -->
      <div class=" no-padding    container-fixed-lg bg-white">
        <div class="container"> 
          <!-- START card -->
          <div class="card card-transparent">
             <div class="card-header ">
            
            <label >Search By Status</label>  
            <select class="select-form-style"id="requestStatus" name="requestStatus" style="margin-left: 10px; margin-top: 5px;">
              <option value="1" selected>Request Pending</option>  
              <option value="2" >Request Processed</option>
          </select>  
          <button title="Search" style="margin-left: 10px;"  class="but-style" (click)="search()" type="button" >Go</button> 
        </div> 
          <div class="card-body">
              <div class="table-responsive">
                <table id="excel-table" datatable  class="ch-table" >
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left; word-wrap: break-word;">Customer Name</th>
                      <th style="text-align: left;">Asset Name</th>
                      <th style="text-align: left;">Authorized Person Name</th>
                      <th style="text-align: left;">DG KVA</th>
                      <th style="text-align: center;">Asset Tracking ID</th>
                      <th style="text-align: center;">Payment Status</th>
                      <th style="text-align: center;">Device ID</th>
                      <th style="text-align: left;">Created  Date Time </th>
                      <th style="text-align: center;">Action</th>
                    </tr>
                  </thead>
                  <tbody *ngIf="assetList!=undefined">
                    <tr *ngFor="let asset of assetList.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td align="left" class="v-align-middle">{{asset.customerId.firstName}} {{asset.customerId.lastName}} </td>
                      <td align="left" class="v-align-middle">{{asset.assetName}} </td>
                      <td align="left" class="v-align-middle">{{asset.authorizedPersonName}} </td>
                      <td align="left" class="v-align-middle">{{asset.dgKVA}}</td>
                      <td align="left" class="v-align-middle">{{asset.assetTrackId}}</td>
                      <td align="left" class="v-align-middle" *ngIf="asset.paymentStatus!=null">{{asset.paymentStatus}}</td>
                      <td align="left" class="v-align-middle" *ngIf="asset.paymentStatus==null">N/A</td>

                      <td align="left" class="v-align-middle" *ngIf="asset.assetDeviceId!=null">{{asset.assetDeviceId}}</td>
                      <td align="left" class="v-align-middle" *ngIf="asset.assetDeviceId==null">N/A</td>

                      <td align="left" class="v-align-middle">{{asset.createdDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td align="center" class="v-align-middle">
                        <button title="view" class="label label-info" (click)="view(viewasset,asset)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                        <button title="Update" *ngIf="asset.assetDeviceId==null" class="label label-success" (click)="view(updateasset,asset)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;
                        <button title="Update Device ID" *ngIf="asset.assetDeviceId==null" class="label label-success" (click)="device(content1,asset.id)"><i class="fa fa-truck" aria-hidden="true"></i></button>&nbsp;

                        
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- END card --> 
        </div>
      </div>
      <!-- END CONTAINER FLUID --> 
      
    </div>
   


    <ng-template #viewasset let-modal>
      <div class="modal-header">
        <h6>View Asset & Payment Details</h6>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
      </div>
      <hr class="beautiful-line">
      <h5 style="margin-left: 15px; text-align: left; color: grey;">Asset Details</h5>
      <div class="container"
        fxLayout
      >
      <div class="item item-1">
        <p class="card-title"><b>Asset Name</b><br>
          <span>{{assetModel.assetName}}</span>
        </p>
      </div>
      <div class="item item-2" style="margin-left: 40px;">
        <p class="card-title"><b>Track ID</b><br>
          <span *ngIf="assetModel.assetTrackId!=null">{{assetModel.assetTrackId}}</span>
          <span *ngIf="assetModel.assetTrackId==null">N/A</span>
        </p>
      </div>
      <div class="item item-2" style="margin-left: 40px;">
        <p class="card-title"><b>Customer Info</b><br>
          <span>
          Name : {{assetModel.customerId.firstName}}<br>
          Mobile No: {{assetModel.customerId.mobileNumber}}
        </span>
      </p>
      </div>
      <div class="item item-3" style="margin-left: 40px;">
        <p class="card-title"><b>Partner Info </b>  <br>
          <span *ngIf="assetModel.userId!=null">
            Name : {{assetModel.userId.firstName}}<br>
            Mobile No: {{assetModel.userId.mobileNumber}}
        </span>
        <span *ngIf="assetModel.userId===null">
        N/A
      </span>
      </p>
      </div>
      <div class="item item-4" style="margin-left: 40px;">
        <p class="card-title"><b> Authorized Person Name </b> <br>
          <span *ngIf="assetModel.authorizedPersonName!=null">{{assetModel.authorizedPersonName}}</span>
          <span *ngIf="assetModel.authorizedPersonName==null">N/A</span>
        </p>
      </div>
      <div class="item item-4" style="margin-left: 40px;">
        <p class="card-title"><b> DG KVA </b> <br>
          <span *ngIf="assetModel.dgKVA!=null">{{assetModel.dgKVA}}</span>
          <span *ngIf="assetModel.dgKVA==null">N/A</span>
        </p>
      </div>
      <div class="item item-1" style="margin-left: 40px;">
        <p class="card-title"><b>Asset Make</b> 
          <br><span>{{assetModel.assestMake}}</span></p>
      </div>
      <div class="item item-1" style="margin-left: 40px;">
        <p class="card-title"><b>Asset Model</b> 
          <br><span>{{assetModel.assestModel}}</span></p>
      </div>
    
      </div>
      <div class="container"
        fxLayout
      >
      <div class="item item-2" >
        <p class="card-title"><b>Mobile Number</b>
          <br>
          <span>{{assetModel.contactMobileNumber}}</span> 
         </p>
      </div>

      <div class="item item-2" style="margin-left: 40px;">
        <p class="card-title"><b>Fuel Type</b>
          <br>
          <span>{{assetModel.fuelType.fuelTypeName}}
           
          </span> 
         </p>
      </div>
      <div class="item item-2" style="margin-left: 40px;">
        <p class="card-title"><b>Tank Capacity</b>
          <br>
          <span>{{assetModel.tankCapacity}}
           
          </span> 
         </p>
      </div>
      <div class="item item-2" style="margin-left: 40px;">
        <p class="card-title"><b>Payment Track Id</b>
          <br>
          <span>{{assetModel.paymentTrackId}}
           
          </span> 
         </p>
      </div>
      <div class="item item-2" style="margin-left: 40px;">
        <p class="card-title"><b>Location</b>
          <br>
          <span>{{assetModel.location}}
           
          </span> 
         </p>
      </div>
      <div class="item item-2" style="margin-left: 40px;">
        <p class="card-title"><b>CGST (%)</b>
          <br>
          <span>{{assetModel.cgstPercentage}}
           
          </span> 
         </p>
      </div>
      <div class="item item-2" style="margin-left: 40px;">
        <p class="card-title"><b>SGST(%)</b>
          <br>
          <span>{{assetModel.sgstPercentage}}
           
          </span> 
         </p>
      </div>
      <div class="item item-2" style="margin-left: 40px;">
        <p class="card-title"><b>Total Payable Amount</b>
          <br>
          <span>{{assetModel.sgstPercentage}}
           
          </span> 
         </p>
      </div>
      <div class="item item-2" style="margin-left: 40px;">
        <p class="card-title"><b>Device ID</b>
          <br>
          <span *ngIf="assetModel.assetDeviceId!=null">{{assetModel.assetDeviceId}}</span> 
          <span *ngIf="assetModel.assetDeviceId==null">N/A</span> 
          
         </p>
      </div>

      </div>
     
      <hr class="beautiful-line">
      <h5 style="margin-left: 15px; text-align: left; color: grey;">Payment Details</h5>
      <div class="container"
        fxLayout
      >
      <div class="table-responsive">
        <table  class="ch-table" style="width: 100%;">
          <thead>
            <tr> 
              <th style="text-align: center;">Sl.No.</th>
              <th style="text-align: left; word-wrap: break-word;">Payment Mode</th>
              <th style="text-align: left;">Payment Id</th>
              <th style="text-align: left;">Payable Amount</th>
              <th style="text-align: left;">Payment Status</th>
              <th style="text-align: left;">Payment  Date Time </th>
              <th style="text-align: center;">Reference No</th>
              <th style="text-align: center;">Received Amount</th>
              <th style="text-align: center;">Cheque Status</th>
              
             
             
            </tr>
          </thead>
          <tbody >
            <tr *ngFor="let payment of assetModel.paymentList; let i=index">
              <td align="center" class="v-align-middle ">{{i + 1}}</td>
              <td align="left" class="v-align-middle" *ngIf="payment.paymentModeId!=null">{{payment.paymentModeId.paymentMode}} </td>
              <td align="left" class="v-align-middle" *ngIf="payment.paymentModeId==null">N/A </td>

              <td align="left" class="v-align-middle">{{payment.paymentId}} </td>
              
              <td align="left" class="v-align-middle">{{payment.paidAmount}}</td>
              <td align="left" class="v-align-middle" *ngIf="payment.paymentModeId.id==1">{{payment.paymentStatus}} </td>
              <td align="left" class="v-align-middle" *ngIf="payment.paymentModeId.id!=1">{{payment.approvalStatus}} </td>

              <td align="left" class="v-align-middle" *ngIf="payment.paymentModeId.id==1">{{payment.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
              <td align="left" class="v-align-middle" *ngIf="payment.paymentModeId.id!=1">{{payment.approvalDateTime | date:'dd/MM/yyyy hh:mm:ss a'}} </td>

              <td align="left" class="v-align-middle">{{payment.paymentReferenceNo}}</td>
              <td align="left" class="v-align-middle">{{payment.paymentStatus.receivedAmount}}</td>
              <td align="left" class="v-align-middle">{{payment.chequeClaimStatus}}</td>
            
            </tr>
          </tbody>
        </table>
      </div>
      
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
      </div>
    </ng-template>

    
<ng-template #content1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Device</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
    <form>
      
      <div class="form-group">
        <label for="dateOfBirth">Enter Device ID *</label>
        <input type="text" class="select-form-style" style="margin-left: 10px; " name="deviceId" id="deviceId" />

      </div>
    </form>
    <br>
    <div *ngIf="poppupalert">
      <div style="color: red;">Please enter device ID!</div>
    </div>
  </div>
  <hr class="beautiful-line">
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="updateDevice(id)">Submit</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
  </div>
</ng-template>



    
<ng-template #updateasset let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Asset Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
    <form>
      <div class="row">
         <div class="col-sm-4">
            <div class="form-group">
              <label for="dateOfBirth" >Authorized Person Name *</label>
              <input type="text" class="form-control" name="authorizedPersonName" id="authorizedPersonName" maxlength="40" [value]="assetModel.authorizedPersonName"/>
            </div>
          </div>
          <div class="col-sm-4" style="margin-left: 10px;">
            <div class="form-group">
              <label for="dateOfBirth">Asset Name*</label>
              <input type="text" class="form-control" name="assetName" id="assetName" maxlength="40" [value]="assetModel.assetName"/>
            </div>
      
          </div>
       
      </div>
      <div class="row">
        <div class="col-sm-4">
          <div class="form-group">
            <label for="dateOfBirth">DG KVA *</label>
            <input type="text" class="form-control" name="dgKVA" id="dgKVA" maxlength="40" [value]="assetModel.dgKVA" />
          </div>
         </div>
         <div class="col-sm-4" style="margin-left: 10px;">
          <div class="form-group">
            <label for="dateOfBirth">Asset Make *</label>
            <input type="text" class="form-control" name="assestMake" id="assestMake" maxlength="40" [value]="assetModel.assestMake"/>
          </div>
         </div>
      
     </div>
     <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label for="dateOfBirth">Asset Model *</label>
          <input type="text" class="form-control"  name="assestModel" id="assestModel" maxlength="40" [value]="assetModel.assestModel"/>
        </div>
       </div>
       <div class="col-sm-4" style="margin-left: 10px;">
        <div class="form-group">
          <label for="dateOfBirth">Contact Number *</label>
          <input type="text" class="form-control" name="contactMobileNumber" id="contactMobileNumber" maxlength="40" [value]="assetModel.contactMobileNumber"/>
        </div>
  
       </div>
    
   </div>
     
   <div class="row">
    <div class="col-sm-4">
      <div class="form-group">
        <label for="dateOfBirth">Fuel Type*</label>
        <select id="fuelType"  name="fuelType" class="form-control" [value]="assetModel.fuelType.id">
          <option *ngFor="let fuel of fuelType.Data" value={{fuel.id}}>{{fuel.fuelTypeName}}</option>
        </select>
      </div>
     </div>
     <div class="col-sm-4" style="margin-left: 10px;">
      <div class="form-group">
        <label for="dateOfBirth">Tank Capacity*</label>
        <input type="text" class="form-control" name="tankCapacity" id="tankCapacity" maxlength="40" [value]="assetModel.tankCapacity"/>
      </div>

     </div>
   </div>

   <div class="row">
    <div class="col-sm-4">
      <div class="form-group">
        <label for="dateOfBirth">Location *</label>
        <input type="text" class="form-control" name="location" id="location" maxlength="80" [value]="assetModel.location"/>
      </div>
     </div>
     <div class="col-sm-4" style="margin-left: 10px;">
      <div class="form-group" >
        <label for="dateOfBirth">Upload Asset Image *</label>
        <div *ngIf="imageUpload" class="form-group">
          <input #imageInput
          id="file"
          name="file"
           type="file"
           accept=".jpeg,.jpg"
           data-max-size="2048"
           (change)="selectFile($event)">
          <br>
          
      </div>
  
        <div *ngIf="pdfUpload" class="form-group">
          <input #imageInput
          id="file"
          name="file"
           type="file"
           accept=".pdf,.jpeg,.jpg"
           data-max-size="2048"
           (change)="selectFile($event)">
      </div>
  
     </div>
   </div>
     </div>
      
    </form>
    <br>
    <div *ngIf="poppupalert">
      <div style="color: red;">Mandatory fields are required!</div>
    </div>
  </div>
  <hr class="beautiful-line">
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="upload(assetModel.id,assetModel.latitude,assetModel.longitude)">Submit</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
  </div>
</ng-template>



<router-outlet></router-outlet>
