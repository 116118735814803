<app-header></app-header>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
</ngx-spinner>
<ol class="breadcrumb breadcrumb-alt" style="margin-top:100px; width: auto;" >
  <li class="breadcrumb-item" style="margin-left: 15px;"><a href="#" routerLink="/dashboard">Home</a></li>
  <li class="breadcrumb-item active">Payment Mode</li>
  
</ol>
<div class="container  container-top-dashboard-section">
<ul ngbNav #nav="ngbNav" class="nav-tabs">
	 <!-- <div class="btn-section-wrapper"><button type="button" class="btn btn-success" (click)="open(content)"><i class="fa fa-plus" aria-hidden="true"></i>
 Add Bank</button>
</div> -->


  <li [ngbNavItem]="1" style="margin-top: 40px;">
    <!-- <a ngbNavLink>Bank Master</a> -->
    <ng-template ngbNavContent>
      <div class="card-body">
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="width: 40px; text-align: center;">Sl.No.</th>
                      <th align="center">Payment Mode</th>
                      <th align="center">Status</th>
                      <th align="center">Created At</th>
                     
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let payments of paymentMode.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td align="left" class="v-align-middle">{{payments.paymentMode}}</td>
                      <td align="left" class="v-align-middle" *ngIf="payments.status==1"><span class="label label-success">Active</span> </td>
                      <td align="left" class="v-align-middle" *ngIf="payments.status==2"><span class="label label-danger">Inactive</span></td>
                      <td align="left" class="v-align-middle">{{payments.createdAt | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                     
                    </tr>
                  </tbody>
                </table>
          </div>
            </div>
    </ng-template>
  </li>

</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>


</div>



