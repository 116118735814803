<app-header></app-header>
<div class="container  container-top-dashboard-section" style="margin-top: 110px">
  <div class="alert alert-danger" role="alert"
 *ngIf="failureflag">{{message}}</div>
 <div class="alert alert-danger" role="alert"
 *ngIf="successflag">{{message}}</div>
<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
	 <div class="btn-section-wrapper"><button type="button" class="btn btn-success" (click)="open(content)"><i class="fa fa-plus" aria-hidden="true"></i>
 Add Role</button>
</div>

<tr style="display: none;" *ngFor="let role of roleDetails.response; let i=index">
  <td align="center" class="v-align-middle ">{{i + 1}}</td>
    <td align="left" class="v-align-middle">{{role.roleName}}</td>
    </tr>
  <li [ngbNavItem]="1">
    <a ngbNavLink>Role Master</a>
    <ng-template ngbNavContent>
      <div class="card-body">
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr>
                      <th align="center" >Sl.No.</th>
                      <th align="left" >Role Name</th>
                      <th align="left" >Role Code</th>
                      <th align="left">Created By</th>
                      <th align="left" >Created Date Time</th>
                      <th align="center" >Status</th>
                      <th align="center" >Action</th>
                      <!-- <th align="center" >Edit</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let role of roleDetails.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                        <td align="left" class="v-align-middle">{{role.roleName}}</td>
                        <td align="left" class="v-align-middle">{{role.roleCode}}</td>
                        <td align="left" class="v-align-middle">{{role.createdBy}}</td>
                        <td align="left" class="v-align-middle">{{role.createdDateTime | date:'dd/MM/yyyy hh:mm:ss'}}</td>
                        <td align="center" *ngIf="role.roleStatus===1" class="v-align-middle ht-active">Active</td>
                        <td align="center" *ngIf="role.roleStatus===0 " class="v-align-middle ht-inactive">InActive</td>
                        <!-- <td class="v-align-middle"><button (click)="deleteRole(role.roleId)"class="btn btn-danger"><i class="fa fa-trash-o" aria-hidden="true"></i></button></td> -->
                        <td class="v-align-middle"><button (click)="open1(content1,role.roleId)" class="btn btn-info"><i class="fa fa-pencil" aria-hidden="true"></i></button></td>
                    </tr>
                  </tbody>
                </table>
          </div>
            </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="3" style="display: none;">
    <a ngbNavLink>Three</a>
    <ng-template ngbNavContent>
      <p>Sed commodo, leo at suscipit dictum, quam est porttitor sapien, eget sodales nibh elit id diam. Nulla facilisi.
        Donec egestas ligula vitae odio interdum aliquet. Duis lectus turpis, luctus eget tincidunt eu, congue et odio.
        Duis pharetra et nisl at faucibus. Quisque luctus pulvinar arcu, et molestie lectus ultrices et. Sed diam urna,
        egestas ut ipsum vel, volutpat volutpat neque. Praesent fringilla tortor arcu. Vivamus faucibus nisl enim, nec
        tristique ipsum euismod facilisis. Morbi ut bibendum est, eu tincidunt odio. Orci varius natoque penatibus et
        magnis dis parturient montes, nascetur ridiculus mus. Mauris aliquet odio ac lorem aliquet ultricies in eget
        neque. Phasellus nec tortor vel tellus pulvinar feugiat.</p>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>


</div>



<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Role</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body master-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Role Name</label>
        <span class="required-asterisk">*</span>
        <input type="text" id="roleName" name="roleName" class="form-control"  placeholder="">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Role Code</label>
        <span class="required-asterisk">*</span>
        <input type="text" id="roleCode" name="roleCode" class="form-control"  placeholder="">
      </div>

      <div class="form-group">
        <label for="dateOfBirth">Status</label>
        <span class="required-asterisk">*</span>
<select id="roleStatus" name="roleStatus" value="roleStatus" class="form-control">
  <option value=1>Active</option>
  <option value=0>Inactive</option>
</select>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="addRole()" (click)="modal.close('Save click')">Add Role</button>
  </div>
</ng-template>


<ng-template #content1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Role</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body master-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Role Name</label>
        <span class="required-asterisk">*</span>
        <input type="text" id="roleName" name="roleName" [(ngModel)]="roleModel.roleName" class="form-control"  placeholder="">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Role Code</label>
        <span class="required-asterisk">*</span>
        <input type="text" id="roleCode" name="roleCode" [(ngModel)]="roleModel.roleCode" class="form-control"  placeholder="">
      </div>

      <div class="form-group">
        <label for="dateOfBirth">Status</label>
        <span class="required-asterisk">*</span>
<select id="roleStatus" name="roleStatus" [(ngModel)]="roleModel.roleStatus" class="form-control">
  <option value=1>Active</option>
  <option value=0>Inactive</option>
</select>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="updateRole()" (click)="modal.close('Save click')">update Role</button>
  </div>
</ng-template>