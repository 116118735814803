import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserModel } from '../model/user';
import { Subject } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { GenericResponseModel } from '../model/genricresponse';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { Workbook } from 'exceljs';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataTableDirective } from 'angular-datatables';
import { DecimalPipe } from '@angular/common';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit, OnDestroy {
  userModel:UserModel[];
  dtOptions: DataTables.Settings = {};
  responseDataListnew = [];
  dtTrigger: Subject<any> = new Subject();
  excelexportflag:boolean
  customerResponseModel:GenericResponseModel;
  statusCode:Number;
  deleteUserId:Number;
  closeResult = '';
  constructor(private router: Router,
    private customerservice: CustomerService,private _decimalPipe: DecimalPipe,private modalService: NgbModal,private spinnerService:NgxSpinnerService) {
    }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true
    };
    this.customerservice.getAllCustomer()
        .subscribe( data => {
          this.statusCode = data.statusCode;
          if(data.statusCode==200){
            this.excelexportflag  = true;
          }else{
            this.excelexportflag  = false;
          }
          this.customerResponseModel = data;
          this.dtTrigger.next();
        },err => {
          if(err.error.statusCode==406){
            this.router.navigate(['/login']);
          }else if(err.error.statusCode==500){
            this.router.navigate(['/page-error']);
          }else if(err.error.statusCode==404){
            this.router.navigate(['/pagenotfound']);
          }
       });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  view(user:UserModel){
    sessionStorage.setItem("cusId",String(user.userId));
    this.router.navigate(['/customers-view']);
  }

  open(reject,viewId){
    this.deleteUserId = viewId;
    this.modalService.open(reject, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  delete(){
    this.spinnerService.show();
    let userId = this.deleteUserId;
    this.customerservice.delete(userId).subscribe(data=>{
      if(data.statusCode==200){
        this.spinnerService.hide();
        Swal.fire({
          html: '<pre>' + data.responseMessage + '</pre>',
          customClass: {
            popup: 'format-pre'
          }
        }).then((value) => {
          window.location.reload();
        });; ;
      }
    },err => {
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }else if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  excelexport(){
      let sno = 1;
      this.responseDataListnew = [];
      this.customerResponseModel.response.forEach(element => {
        let date = element['createdDatetime'];
        let convertedDate;
        if(date==null || date==='null'){
          convertedDate = "N/A";
        }else{
          let dates = new Date(element['createdDatetime']);
          convertedDate = moment(new Date(dates)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }
        var response = [];
        response.push(sno);
        response.push(element['firstName'] +' '+ element['lastName']);
        response.push(element['mobileNumber']);
        response.push(element['emailAddress']);
        let otpstatus = element['otpVerificationStatus'];
        if(otpstatus==1){
          response.push('Success');
        }else if(otpstatus==0){
          response.push('Pending');
        }
        let activestatus = element['accountStatus'];
        if(activestatus==1){
          response.push('Active');
        }else if(activestatus==2){
          response.push('InActive');
        }
        response.push(convertedDate);
        this.responseDataListnew.push(response);
        sno++;
      });
      this.excelexportCustomer();
  }

  excelexportCustomer(){
    const title = 'Customers Report';
    const header = ["S.No","Customer Name", "Mobile Number", "Email Id","OTP Verification Status","Account Status","Onboarded Date Time"]
    const data = this.responseDataListnew;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Customers Report');
// Add new row
let titleRow = worksheet.addRow([title]);
// Set font, size and style in title row.
titleRow.font = { name: 'Times New Roman', family: 4, size: 16, bold: true };

// Blank Row
worksheet.addRow([]);

//Add row with current date
// let subTitleRow = worksheet.addRow(['Date : ' + this.datePipe.transform(new Date(), 'medium')]);
//Add Header Row
let headerRow = worksheet.addRow(header);
headerRow.font = {bold: true };
// Cell Style : Fill and Border
headerRow.eachCell((cell, number) => {
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFFFF' },
    bgColor: { argb: 'FF0000FF' },
  }
  cell.border = {  top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
});

data.forEach(d => {
  let row = worksheet.addRow(d);
  let qty = row.getCell(1);
  let qty1 = row.getCell(2);
  let qty2 = row.getCell(3);
  let qty3 = row.getCell(4);
  let qty4 = row.getCell(5);
  let qty5 = row.getCell(6);
  let qty6 = row.getCell(7);
  qty.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty1.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty2.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty3.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty4.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty5.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty6.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
}
);
workbook.xlsx.writeBuffer().then((data) => {
  let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  FileSaver.saveAs(blob, 'Customers Report.xlsx');
});
   }

}


