import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { GenericResponseModel } from '../model/genricresponse';
import { ReportServices } from '../services/reports.service';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { Workbook } from 'exceljs';
import { NgxSpinnerService } from 'ngx-spinner';
import { GenericResponse } from '../model/genricmodelresponse';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { OrderTransactionService } from '../services/ordertran.service';
import { DecimalPipe } from '@angular/common';
import { Router } from '@angular/router';
import { FlexStyleBuilder } from '@angular/flex-layout';
@Component({
  selector: 'app-report-partner-settlements',
  templateUrl: './report-partner-settlements.component.html',
  styleUrls: ['./report-partner-settlements.component.css']
})
export class ReportPartnerSettlementsComponent implements OnInit {
  symbole:String; 
 public isCollapsed = false;
 active = 1;
 transactionDetail:GenericResponseModel;
 transactionfilterDetail:GenericResponseModel;
 settlementDetail:GenericResponseModel;
 settlementViewDetail:GenericResponseModel;
 filterreadyToSettlementreportModel:GenericResponseModel;
 filterNoReceordreadyToSettlementreportModel:GenericResponseModel;
closeResult = '';
  errorFlag:boolean;
  errorMessage:String;
  refreshflag:boolean;
  responseDataList = [];
  revenuereportModel:GenericResponseModel;
  revenuefilterreportModel:GenericResponseModel;
  normalrevenue:boolean=true;
  excelexportcheck : boolean = false;
  responseDataListnew = [];
  partnerSettlementreportModel:GenericResponseModel;
  filterpartnerSettlementreportModel:GenericResponseModel;
  filterrefundreportModel:GenericResponseModel;
  filterpartner:boolean=false;
  filteronecheck:boolean=false;
  filtertwocheck:boolean=false;
  filterNoRecordpartnerSettlementreportModel:GenericResponseModel;
  refreshFlag:boolean=false;
  refundSettlementReportModel:GenericResponse;
  viewpartnerSettlementModel:GenericResponseModel;
  errorflag:boolean=false;
  startDate:String;
  endDate:String;
  resultText=[];  
  values:string;  
  orderID:Number;
  errorFlag1:boolean = false;
  cancelMessageModel:GenericResponseModel;
  status:Number;
  partnerSettlement:boolean=true;
  readytoSettle:boolean=false;
  poppupalert:boolean=false;
  readyToSettlementreportModelById:GenericResponseModel;
  selectedFiles: FileList;
  currentFile: File;
  progress = 0;
  constructor(private modalService: NgbModal,private reportservice:ReportServices,private spinnerService:NgxSpinnerService,private ordertransactionservice: OrderTransactionService,private _decimalPipe: DecimalPipe,private router:Router) { }

  ngOnInit(): void {
    this.readytoSettle=true;
    var nowdate = new Date();
    var monthStartDay = new Date(nowdate.getFullYear(), nowdate.getMonth(), 1);
    var monthEndDay = new Date(nowdate.getFullYear(), nowdate.getMonth() + 1, 0);
    let startDate = moment(new Date(monthStartDay)).format("yyyy-MM-DD").toString();
    let endDate = moment(new Date(monthEndDay)).format("yyyy-MM-DD").toString();
    this.startDate = startDate;
    this.endDate = endDate;
    let formData= {
      startDate:startDate,
      endDate:endDate,
      flag:22
    }
    this.status=22;
        this.errorFlag = false;
        this.reportservice.settlementinitiate(formData).subscribe(data=>{
          if(data.statusCode==200){
            this.excelexportcheck = true;
           }else{
            this.excelexportcheck = false;
           }
           this.partnerSettlementreportModel = data;
          },err => {
            if(err.error.statusCode==406){
              this.router.navigate(['/login']);
            }else if(err.error.statusCode==500){
              this.router.navigate(['/page-error']);
            }else if(err.error.statusCode==404){
              this.router.navigate(['/pagenotfound']);
            }
         }); 

   
  }

  search(){
    this.refreshFlag = true;
    let fromdate = (<HTMLInputElement>document.getElementById('fromdate')).value;
    let todate = (<HTMLInputElement>document.getElementById('todate')).value;
    if(todate < fromdate){
      this.errorFlag = true;
      this.errorMessage = "End Date should be greater than Start Date!";
    }else{

    
    /*  if(Number(settlementStatus)===1){
        let status = (<HTMLInputElement>document.getElementById('status')).value;
   
        this.status = Number(status) ;
        this.errorFlag = false;
        let formData= {
          startDate:fromdate,
          endDate:todate,
          flag:Number(status)
        }
        this.reportservice.partnerdeliveryreport(formData).subscribe(data=>{
          this.filterpartner = true;
          if(data.statusCode==200){
            this.filteronecheck = true;
            this.excelexportcheck = true;
            this.filterpartnerSettlementreportModel = data;
           }else{
            this.excelexportcheck = false;
            this.filteronecheck = false;
            this.filterNoRecordpartnerSettlementreportModel = data;
           }
          
         }); 
      }else{*/
        let status = (<HTMLInputElement>document.getElementById('readyStatus')).value;
        this.errorFlag = false;
        let formData= {
          startDate:fromdate,
          endDate:todate,
          flag:status
        }
        this.status = Number(status);
        this.reportservice.settlementinitiate(formData).subscribe(data=>{
         
          this.filterpartner = true;
          if(data.statusCode==200){
           this.filteronecheck = true;
            this.excelexportcheck = true;
            this.filterpartnerSettlementreportModel = data;
           }else{
             this.filteronecheck = false;
             this.filterNoRecordpartnerSettlementreportModel = data;
            this.excelexportcheck = false;
           }
         }); 
     // }
      
    }
    
  }

  settlementProcess(){
    let settlementStatus = (<HTMLInputElement>document.getElementById('settlementStatus')).value;
    if(Number(settlementStatus)==1){
      this.partnerSettlement=true;
      this.readytoSettle=false;
    }else{
      this.readytoSettle=true;
      this.partnerSettlement=false;
    }
  }
  // view(content1,id){
  //   this.reposrtservice.orderinfo(id).subscribe(data=>{
  //     this.settlementInfoModel = data;
  //   });

  //   this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
  //     this.closeResult = `Closed with: ${result}`;
  //   }, (reason) => {
  //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //   });
  // }

  exportToExcel(){
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let sno = 1;
    if(this.filterpartner==true){
      this.responseDataListnew = [];
      this.filterpartnerSettlementreportModel.response.forEach(element => {
        let vehicle = element['vehicleId'];
        let customer = element['customerId'];
        let partner = vehicle['userId'];
        let fuel    = vehicle['fuelType']; 
        
        let delivdate = element['orderDeliveredDateTime'];
        let deliveryDate;
        if(delivdate!=null && delivdate!=='null'){
          let datess = new Date(element['orderDeliveredDateTime'])
        deliveryDate = moment(new Date(datess)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }else{
          deliveryDate = "N/A"
        }
        
        let paymentda = element['paymentDatetime'];
        let payDate;
        if(paymentda!=null && paymentda!=='null'){
          let pay = new Date(element['paymentDatetime'])
          payDate = moment(new Date(pay)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }else{
          payDate = "N/A";
        } 
        
        let bookda = element['bookedDate'];
        let bookDate;
        if(bookda!=null && bookda!=='null'){
          let book = new Date(element['bookedDate'])
          bookDate = moment(new Date(book)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }else{
          bookDate ="N/A";
        }
        
        var response = [];
        response.push(sno);
        response.push(element['orderId']);
        response.push(fuel['fuelTypeName']);
        response.push(element['quantity']);
        response.push("₹ "+ this._decimalPipe.transform(element['totalPayableAmount'],"1.2-2"));
        response.push(customer['firstName']+customer['lastName']);
        response.push(customer['mobileNumber']);
        response.push(partner['firstName']+partner['lastName']);
        response.push(partner['mobileNumber']);
        response.push("₹ "+ this._decimalPipe.transform(element['totalfuelcost'],"1.2-2"));
        response.push("₹ "+ this._decimalPipe.transform(element['deliveryCharges'],"1.2-2"));
        response.push("₹ "+ this._decimalPipe.transform(element['tax'],"1.2-2"));
        response.push("₹ "+ this._decimalPipe.transform(element['internetHandlingFee'],"1.2-2"));
        if(element['transactionId']!=null && element['transactionId']!=='null'){
          response.push(element['transactionId']);
        }else{
          response.push("N/A");
        }
        response.push(element['paymentStatus']);
        response.push(payDate);
        response.push(bookDate);
        response.push(deliveryDate);
        this.responseDataListnew.push(response);
        sno++;
      });
    }else{
      this.responseDataListnew = [];
      this.partnerSettlementreportModel.response.forEach(element => {
        let vehicle = element['vehicleId'];
        let customer = element['customerId'];
        let partner = vehicle['userId'];
        let fuel    = vehicle['fuelType']; 
        
        let delivdate = element['orderDeliveredDateTime'];
        let deliveryDate;
        if(delivdate!=null && delivdate!=='null'){
          let datess = new Date(element['orderDeliveredDateTime'])
        deliveryDate = moment(new Date(datess)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }else{
          deliveryDate = "N/A"
        }
        
        let paymentda = element['paymentDatetime'];
        let payDate;
        if(paymentda!=null && paymentda!=='null'){
          let pay = new Date(element['paymentDatetime'])
          payDate = moment(new Date(pay)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }else{
          payDate = "N/A";
        } 
        
        let bookda = element['bookedDate'];
        let bookDate;
        if(bookda!=null && bookda!=='null'){
          let book = new Date(element['bookedDate'])
          bookDate = moment(new Date(book)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }else{
          bookDate ="N/A";
        }
        
        var response = [];
        response.push(sno);
        response.push(element['orderId']);
        response.push(fuel['fuelTypeName']);
        response.push(element['quantity']);
        response.push("₹ "+ this._decimalPipe.transform(element['totalPayableAmount'],"1.2-2"));
        response.push(customer['firstName']+customer['lastName']);
        response.push(customer['mobileNumber']);
        response.push(partner['firstName']+partner['lastName']);
        response.push(partner['mobileNumber']);
        response.push("₹ "+ this._decimalPipe.transform(element['totalfuelcost'],"1.2-2"));
        response.push("₹ "+ this._decimalPipe.transform(element['deliveryCharges'],"1.2-2"));
        response.push("₹ "+ this._decimalPipe.transform(element['tax'],"1.2-2"));
        response.push("₹ "+ this._decimalPipe.transform(element['internetHandlingFee'],"1.2-2"));
        if(element['transactionId']!=null && element['transactionId']!=='null'){
          response.push(element['transactionId']);
        }else{
          response.push("N/A");
        }
        response.push(element['paymentStatus']);
        response.push(payDate);
        response.push(bookDate);
        response.push(deliveryDate);
        this.responseDataListnew.push(response);
        sno++;
      });
    }
    this.excelexport();
  }
  excelexport(){
    const title = 'Partner Settlements Report';
    const header = ["S.No","Order ID","Fuel","Quantity","Paid Amount","Customer Name", "Customer Mobile", "Partner Name","Partner Mobile" , "Fuel Charges","Delivery Charges", "Tax" ,"Platform Charges","Transaction ID","Status","Transaction Date & Time", "Booked Date & Time","Delivery Date & Time"]
    const data = this.responseDataListnew;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Partner Settlements Report');
// Add new row
let titleRow = worksheet.addRow([title]);
// Set font, size and style in title row.
titleRow.font = { name: 'Times New Roman', family: 4, size: 16, bold: true };

// Blank Row
worksheet.addRow([]);
  
  let subTitleRow = worksheet.addRow(['Start Date  : ' + this.startDate]);
  subTitleRow.font = { name: 'Times New Roman', family: 4, size: 12, bold: true };
  let subTitleRow1 = worksheet.addRow(['End Date  : ' + this.endDate]);
  subTitleRow1.font = { name: 'Times New Roman', family: 4, size: 12, bold: true };
  if(this.status==0){
    let subTitleRow2 = worksheet.addRow(['Status : ' + "Not Delivered"]);
    subTitleRow2.font = { name: 'Times New Roman', family: 4, size: 12, bold: true };
  }else if(this.status==1){
    let subTitleRow2 = worksheet.addRow(['Status : ' + "Delivered"]);
    subTitleRow2.font = { name: 'Times New Roman', family: 4, size: 12, bold: true };
  }

  worksheet.addRow([]);

//Add row with current date
// let subTitleRow = worksheet.addRow(['Date : ' + this.datePipe.transform(new Date(), 'medium')]);
//Add Header Row
let headerRow = worksheet.addRow(header);
headerRow.font = {bold: true };
// Cell Style : Fill and Border
headerRow.eachCell((cell, number) => {
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFFFF' },
    bgColor: { argb: 'FF0000FF' },
  }
  cell.border = {  top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
});

data.forEach(d => {
  let row = worksheet.addRow(d);
  let qty = row.getCell(1);
  let qty1 = row.getCell(2);
  let qty2 = row.getCell(3);
  let qty3 = row.getCell(4);
  let qty4 = row.getCell(5);
  let qty5 = row.getCell(6);
  let qty6 = row.getCell(7);
  let qty7 = row.getCell(8);
  let qty8 = row.getCell(9);
  let qty9 = row.getCell(10);
  let qty10 = row.getCell(11);
  let qty11 = row.getCell(12);
  let qty12 = row.getCell(13);
  let qty13 = row.getCell(14);
  let qty14 = row.getCell(15);
  let qty15 = row.getCell(16);
  let qty16 = row.getCell(17);
  let qty17 = row.getCell(18);
  let qty18 = row.getCell(19);
  qty.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty1.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty2.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty3.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty4.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty5.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty6.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty8.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty9.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty10.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty11.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty12.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty13.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty14.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty15.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty16.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty17.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty18.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
}
);
workbook.xlsx.writeBuffer().then((data) => {
  let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  FileSaver.saveAs(blob, 'Partner Settlements Report.xlsx');
});
   }

   refresh(){
     this.refreshFlag = false;
     window.location.reload();
   } 

   view(content6,id){
    this.reportservice.settlementinitiateById(id).subscribe(data=>{
      this.readyToSettlementreportModelById = data;
    });

      this.modalService.open(content6, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

   }

   private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  allowNumericDigitsOnlyOnKeyUpWithoutdot(e) {		
    const charCode = e.which ? e.which : e.keyCode;
    if(charCode > 31 && (charCode < 48 || charCode > 57)){
        e.preventDefault();
    }else{
      return true;
    }
  }

  refund(id){
    let transactionid = (<HTMLInputElement>document.getElementById('transactionid')).value;
    let date = (<HTMLInputElement>document.getElementById('date')).value;
    let amount = (<HTMLInputElement>document.getElementById('amount')).value;
    if(transactionid!=null && transactionid!=='' && date!=null && date!=='' && amount!=null && amount!==''){
      this.errorflag = false;
      let d = date.concat(":00");
      let currentDate=new Date(d);
      let time = currentDate.getTime();
      let formData= {
        deductionAmount:Number(amount),
        refundRemarks:transactionid,
        refundDateTime:time
      }

      this.reportservice.getRefundCompleted(id,formData).subscribe(data=>{
        this.modalService.dismissAll();
        Swal.fire({
          html: '<pre>' + data.responseMessage + '</pre>',
          customClass: {
            popup: 'format-pre'
          }
        }).then((value) => {
          window.location.reload();
        });; ; 
      });

    }else{
      this.errorflag = true;
      this.errorMessage = 'Please enter all fields!';
    }
  }

  onChange(id:Number,event) {  
   const checked = event.target.checked;  
   if (checked) {  
   this.values = String(id);
   }
      if (checked) {  
        this.resultText.push(id);  
         } else {  
           const index = this.resultText.indexOf(id);  
           this.resultText.splice(index, 1);  
       }  
       this.values=this.resultText.toString();  
    }

    sendOtp(id){
          this.reportservice.generateClosingOTP(id).subscribe(data=>{
            if(data.statusCode==200){
              Swal.fire({
                html: '<pre>' + data.responseMessage + '</pre>',
                customClass: {
                  popup: 'format-pre'
                }
              }).then((value) => {
                window.location.reload();
              });; ; 
            }else{
              Swal.fire({
                html: '<pre>' + data.responseMessage + '</pre>',
                customClass: {
                  popup: 'format-pre'
                }
              }).then((value) => {
                window.location.reload();
              });; ; 
            }
          });
    }

    cancelPopup(content5,id){
      this.orderID = id;
      this.ordertransactionservice.cancellmessage().subscribe(data=>{
        this.cancelMessageModel = data;
      });
      this.modalService.open(content5, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

    cancelOrder(){
      let desc = (<HTMLInputElement>document.getElementById('cancelremarks')).value;
      let cancelmessage = (<HTMLInputElement>document.getElementById('cancelmessage')).value;
      if(desc!=null && desc!==''){
        this.errorFlag1 = false;
        let formData= {
          userId:Number(sessionStorage.getItem('id')),
          cancellationId:cancelmessage,
          orderRemarks:desc,
        }
        this.orderID
        this.ordertransactionservice.cancelorder(this.orderID,formData).subscribe(data=>{
          if(data.statusCode==200){
            Swal.fire({
              html: '<pre>' + data.responseMessage + '</pre>',
              customClass: {
                popup: 'format-pre'
              }
            }).then((value) => {
              window.location.reload();
            });; ; 
          }else{
            Swal.fire({
              html: '<pre>' + data.responseMessage + '</pre>',
              customClass: {
                popup: 'format-pre'
              }
            }).then((value) => {
              window.location.reload();
            });; ; 
          }
        });
        this.modalService.dismissAll();
      }else{
        this.errorFlag1 = true;
        this.errorMessage = "Please enter remarks!";
        setTimeout(function() {
          this.errorFlag1 = false;
      }.bind(this), 3000);
  
      }
    }

    readyToSettle(){
      if(this.resultText.length>0){
        this.errorFlag = false;
        this.reportservice.readytoSettle(this.values).subscribe(data=>{
          if(data.statusCode==200){
            Swal.fire({
              html: '<pre>' + data.responseMessage + '</pre>',
              customClass: {
                popup: 'format-pre'
              }
            }).then((value) => {
              window.location.reload();
            });; ; 
          }else{
            Swal.fire({
              html: '<pre>' + data.responseMessage + '</pre>',
              customClass: {
                popup: 'format-pre'
              }
            }).then((value) => {
              window.location.reload();
            });; ; 
          }
        });
      }else{
        this.errorFlag = true;
        this.errorMessage="Please select atleast one record!";
      }
    }

    updateSettlement(id,content){
      this.orderID=id;
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

    markasSettle(){
      if(this.resultText.length>0){
        this.errorFlag= false;
        this.reportservice.settlementCompleted(this.values).subscribe(data=>{
          if(data.statusCode==200){
            Swal.fire({
              html: '<pre>' + data.responseMessage + '</pre>',
              customClass: {
                popup: 'format-pre'
              }
            }).then((value) => {
              window.location.reload();
            });; ; 
          }else{
            Swal.fire({
              html: '<pre>' + data.responseMessage + '</pre>',
              customClass: {
                popup: 'format-pre'
              }
            }).then((value) => {
              window.location.reload();
            });; ; 
          }
        });
      }else{
        this.errorFlag= true;
        this.errorMessage="Please select atleast one record!";
      }
      
    }

    update(){
      let formatDate = null;
        let urtNumber = (<HTMLInputElement>document.getElementById('urtNumber')).value;
        let  settledDate = (<HTMLInputElement>document.getElementById('settledDate')).value;
        let  settledAmount = (<HTMLInputElement>document.getElementById('settledAmount')).value;
        formatDate = moment(new Date(settledDate)).format("DD/MM/YYYY HH:mm a").toString();
      
        if(urtNumber=='' || formatDate=='' || settledAmount==''){
          this.poppupalert=true;
        }else{
              let form={
                utrNumber:urtNumber,
                startDate:formatDate,
                settledAmount:settledAmount
              }
          
            this.reportservice.manualSettlement(this.orderID,form).subscribe(data=>{
              this.modalService.dismissAll();
              Swal.fire({
                html: '<pre>' + data.responseMessage + '</pre>',
                customClass: {
                  popup: 'format-pre'
                }
              }).then((value) => {
                window.location.reload();
              });; ; 
            // this.transactionDetail = data;
              setTimeout(function() {
            }.bind(this), 3000);
            },err => {
              if(err.error.statusCode==406){
                this.router.navigate(['/login']);
              }else if(err.error.statusCode==500){
                this.router.navigate(['/page-error']);
              }else if(err.error.statusCode==404){
                this.router.navigate(['/pagenotfound']);
              }
          });
        }
   
       
    }


    uploadSettlement(){
  
      let uploadFile = (<HTMLInputElement>document.getElementById('file')).value;

      let file='';
        
        if(uploadFile!==null && uploadFile!=='' ){
          this.progress = 0;
         
          this.currentFile = this.selectedFiles.item(0);
          file = this.currentFile.type;
          let arr =  file.split("/");
          let fileType = arr[1];
          fileType = 'Image';
        
          let input = new FormData();
        
        /*  if(this.imageUpload){
            let kycfile1 = (<HTMLInputElement>document.getElementById('file')).value;
          
            if(kycfile1!=null && kycfile1!==''){
              input.append('uploadCheque',this.selectedFiles.item(0));
            }else{
              input.append('uploadCheque',this.currentFile);
            }
         
          }else if(this.pdfUpload){
            let kycFile = this.currentFile;
            input.append('settleCsv',this.currentFile);
          }*/

          input.append('settleCsv',this.currentFile);
          this.reportservice.uploadSettlement(input).subscribe(data=>{
              if(data.response.flag==1){
                this.modalService.dismissAll();
                Swal.fire({
                  html: '<pre>' + data.responseMessage + '</pre>',
                  customClass: {
                    popup: 'format-pre'
                  }
                }).then((value) => {
                  window.location.reload();
                });; ; 
                setTimeout(function() {
              }.bind(this), 3000);
              }else{
                Swal.fire({
                  html: '<pre>' + data.responseMessage + '</pre>',
                  customClass: {
                    popup: 'format-pre'
                  }
                }).then((value) => {
                  window.location.reload();
                });; ; 
                setTimeout(function() {
               
                  window.location.reload();
              }.bind(this), 3000);
              }
          },err => {
            if(err.error.statusCode==406){
              this.router.navigate(['/login']);
            }else if(err.error.statusCode==500){
              this.router.navigate(['/page-error']);
            }else if(err.error.statusCode==404){
              this.router.navigate(['/pagenotfound']);
            }
         });
    
        }else{
         
          this.poppupalert = true;
         // this.emptyCheckResponse = "Please select file!";
          setTimeout(function() {
            this.emptyCheck = false;
        }.bind(this), 3000);
    
        }
    }

    selectFile(event) {
      this.selectedFiles = event.target.files;
    }

    upload(content){
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

}
