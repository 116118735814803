<app-header></app-header>

<!-- START CONTAINER FLUID -->
<div class="container  container-top-dashboard-section" style="margin-top: 120px;"  >
  <!-- class=" no-padding    container-fixed-lg bg-white" style="margin-top:30px " -->
  <!-- <marquee behavior="scroll" direction="left">Diesel Price Now/Ltrs - 83.46 </marquee> -->
            <!-- START WIDGET widget_weekly_sales_card-->
                <div class="card no-border" style="height: 90px; width: 235px; margin-left: 5px;" >
                  <div class="container-xs-height full-height">
                    <div class="row-xs-height">
                      <div class="col-xs-height col-top">
                        <div class="card-header  top-left top-right">
                          <div class="card-title1">
                            <span class="font-montserrat fs-11 all-caps">Total Transactions 
                                </span>
                          </div>
                          <div class="card-controls">
                            <ul>
                              <li><a href="#" class="portlet-refresh text-black" data-toggle="refresh"><i class="portlet-icon portlet-icon-refresh"></i></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row-xs-height">
                      <div class="col-xs-height col-top">
                        <div class="p-l-20 p-t-50 p-b-40 p-r-20">

                          <h3 *ngIf="dashboardDetails.statusCode==200" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i> {{dashboardDetails.response.transactionAmount | number : '1.2-2' }}</h3>
                          <h3 *ngIf="dashboardDetails.statusCode!=200"class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i> 0</h3>
                          <!-- <span class="small hint-text pull-left">71% of total goal</span> -->
                          <!-- <span class="pull-right small text-primary">$23,000</span> -->
                        </div>
                      </div>
                    </div>
                    <!-- <div class="row-xs-height">
                      <div class="col-xs-height col-bottom">
                        <div class="progress progress-small m-b-0">
                          <div class="progress-bar progress-bar-primary" style="width:71%"></div>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
                <!-- END WIDGET -->
                 <!-- START WIDGET widget_weekly_sales_card-->
                <div class="card no-border" style="height: 90px; width: 280px; margin-left: -30px;">
                  <div class="container-xs-height full-height">
                    <div class="row-xs-height">
                      <div class="col-xs-height col-top">
                        <div class="card-header  top-left top-right">
                          <div class="card-title1">
                            <span class="font-montserrat fs-11 all-caps">Total Settled
                                </span>
                          </div>
                          <div class="card-controls">
                            <ul>
                              <li><a href="#" class="portlet-refresh text-black" data-toggle="refresh"><i class="portlet-icon portlet-icon-refresh"></i></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row-xs-height">
                      <div class="col-xs-height col-top">
                        <div class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3  *ngIf="dashboardDetails.statusCode==200 && dashboardDetails.response.settlementAmount!=null"class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i> {{dashboardDetails.response.settlementAmount | number : '1.2-2' }}</h3>
                          <h3  *ngIf="dashboardDetails.statusCode!=200"class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i> 0</h3>
                          <!-- <span class="small hint-text pull-left">71% of total goal</span>
                          <span class="pull-right small text-primary">$23,000</span> -->
                        </div>
                      </div>
                    </div>
                    <!-- <div class="row-xs-height">
                      <div class="col-xs-height col-bottom">
                        <div class="progress progress-small m-b-0">
                          <div class="progress-bar progress-bar-primary" style="width:71%"></div>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
                <!-- END WIDGET -->
                 <!-- START WIDGET widget_weekly_sales_card-->
                <div class="card no-border" style="height: 90px; width: 270px; margin-left: -20px;">
                  <div class="container-xs-height full-height">
                    <div class="row-xs-height">
                      <div class="col-xs-height col-top">
                        <div class="card-header  top-left top-right">
                          <div class="card-title1">
                            <span class="font-montserrat fs-11 all-caps">Total Revenue
                                </span>
                          </div>
                          <div class="card-controls">
                            <ul>
                              <li><a href="#" class="portlet-refresh text-black" data-toggle="refresh"><i class="portlet-icon portlet-icon-refresh"></i></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row-xs-height">
                      <div class="col-xs-height col-top">
                        <div class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="dashboardDetails.statusCode==200 && dashboardDetails.response.orderRevenue!=null"class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i> {{dashboardDetails.response.orderRevenue | number : '1.2-2' }}</h3>
                          <h3 *ngIf="dashboardDetails.statusCode!=200" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i>0</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END WIDGET -->
                 <!-- START WIDGET widget_weekly_sales_card-->
                <div class="card no-border" style="height: 90px; width: 270px; margin-left: -20px;">
                  <div class="container-xs-height full-height">
                    <div class="row-xs-height">
                      <div class="col-xs-height col-top">
                        <div class="card-header  top-left top-right">
                          <div class="card-title1">
                            <span class="font-montserrat fs-11 all-caps">Orders Received 
                                </span>
                          </div>
                          <div class="card-controls">
                            <ul>
                              <li><a href="#" class="portlet-refresh text-black" data-toggle="refresh"><i class="portlet-icon portlet-icon-refresh"></i></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row-xs-height">
                      <div class="col-xs-height col-top">
                        <div class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="dashboardDetails.statusCode==200 && dashboardDetails.response.orderreceivedCount!=null" class="no-margin p-b-5">{{dashboardDetails.response.orderreceivedCount}}</h3>
                          <h3 *ngIf="dashboardDetails.statusCode!=200" class="no-margin p-b-5">0</h3>
                          <!-- <span class="small hint-text pull-left">71% of total goal</span>
                          <span class="pull-right small text-primary">$23,000</span> -->
                        </div>
                      </div>
                    </div>
                    <!-- <div class="row-xs-height">
                      <div class="col-xs-height col-bottom">
                        <div class="progress progress-small m-b-0">
                          <div class="progress-bar progress-bar-primary" style="width:71%"></div>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
                <!-- END WIDGET -->
                 <!-- START WIDGET widget_weekly_sales_card-->
                <div class="card no-border" style="height: 90px; width: 250px; margin-left: -20px;">
                  <div class="container-xs-height full-height">
                    <div class="row-xs-height">
                      <div class="col-xs-height col-top">
                        <div class="card-header  top-left top-right">
                          <div class="card-title1">
                            <span class="font-montserrat fs-11 all-caps">Order Pending
                                </span>
                          </div>
                          <div class="card-controls">
                            <ul>
                              <li><a href="#" class="portlet-refresh text-black" data-toggle="refresh"><i class="portlet-icon portlet-icon-refresh"></i></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row-xs-height">
                      <div class="col-xs-height col-top">
                        <div class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3  *ngIf="dashboardDetails.statusCode==200 && dashboardDetails.response.orderPendingCount!=null" class="no-margin p-b-5">{{dashboardDetails.response.orderPendingCount}}</h3>
                          <h3  *ngIf="dashboardDetails.statusCode!=200" class="no-margin p-b-5">0</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END WIDGET -->
          </div>

          <div class="container  container-top-dashboard-section">
            <!-- START WIDGET widget_weekly_sales_card-->
                <div class="card no-border" style="height: 90px; width: 235px; margin-left: 5px; margin-top: -20px;">
                  <div class="container-xs-height full-height">
                    <div class="row-xs-height">
                      <div class="col-xs-height col-top">
                        <div class="card-header  top-left top-right">
                          <div class="card-title1">
                            <span class="font-montserrat fs-11 all-caps">Orders in progress 
                                </span>
                          </div>
                          <div class="card-controls">
                            <ul>
                              <li><a href="#" class="portlet-refresh text-black" data-toggle="refresh"><i class="portlet-icon portlet-icon-refresh"></i></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row-xs-height">
                      <div class="col-xs-height col-top">
                        <div class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="dashboardDetails.statusCode==200 && dashboardDetails.response.orderinprogressCount!=null" class="no-margin p-b-5">{{dashboardDetails.response.orderinprogressCount}}</h3>
                          <h3 *ngIf="dashboardDetails.statusCode!=200" class="no-margin p-b-5">0</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END WIDGET -->
                 <!-- START WIDGET widget_weekly_sales_card-->
                <div class="card no-border" style="height: 90px; width: 280px; margin-left: -30px;  margin-top: -20px; ">
                  <div class="container-xs-height full-height">
                    <div class="row-xs-height">
                      <div class="col-xs-height col-top">
                        <div class="card-header  top-left top-right">
                          <div class="card-title1">
                            <span class="font-montserrat fs-11 all-caps">Orders completed
                                </span>
                          </div>
                          <div class="card-controls">
                            <ul>
                              <li><a href="#" class="portlet-refresh text-black" data-toggle="refresh"><i class="portlet-icon portlet-icon-refresh"></i></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row-xs-height">
                      <div class="col-xs-height col-top">
                        <div class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="dashboardDetails.statusCode==200 && dashboardDetails.response.ordercompletedCount!=null"class="no-margin p-b-5">{{dashboardDetails.response.ordercompletedCount}}</h3>
                          <h3 *ngIf="dashboardDetails.statusCode!=200" class="no-margin p-b-5">0</h3>
                          <!-- <span class="small hint-text pull-left">71% of total goal</span>
                          <span class="pull-right small text-primary">$23,000</span> -->
                        </div>
                      </div>
                    </div>
                    <!-- <div class="row-xs-height">
                      <div class="col-xs-height col-bottom">
                        <div class="progress progress-small m-b-0">
                          <div class="progress-bar progress-bar-primary" style="width:71%"></div>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
                <!-- END WIDGET -->
                 <!-- START WIDGET widget_weekly_sales_card-->
                <div class="card no-border" style="height: 90px; width: 190px;  margin-top: -20px; width: 270px; margin-left: -20px;">
                  <div class="container-xs-height full-height">
                    <div class="row-xs-height">
                      <div class="col-xs-height col-top">
                        <div class="card-header  top-left top-right">
                          <div class="card-title1">
                            <span class="font-montserrat fs-11 all-caps">Orders Cancelled
                                </span>
                          </div>
                          <div class="card-controls">
                            <ul>
                              <li><a href="#" class="portlet-refresh text-black" data-toggle="refresh"><i class="portlet-icon portlet-icon-refresh"></i></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row-xs-height">
                      <div class="col-xs-height col-top">
                        <div class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="dashboardDetails.statusCode==200 && dashboardDetails.response.ordercancelledCount!=null" class="no-margin p-b-5">{{dashboardDetails.response.ordercancelledCount}}</h3>
                          <h3 *ngIf="dashboardDetails.statusCode!=200" class="no-margin p-b-5">0</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END WIDGET -->
                 <!-- START WIDGET widget_weekly_sales_card-->
                <div class="card no-border" style="height: 90px; margin-top: -20px; width: 270px; margin-left: -20px;">
                  <div class="container-xs-height full-height">
                    <div class="row-xs-height">
                      <div class="col-xs-height col-top">
                        <div class="card-header  top-left top-right">
                          <div class="card-title1">
                            <span class="font-montserrat fs-11 all-caps">Total vehicle / Driver 
                                </span>
                          </div>
                          <div class="card-controls">
                            <ul>
                              <li><a href="#" class="portlet-refresh text-black" data-toggle="refresh"><i class="portlet-icon portlet-icon-refresh"></i></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row-xs-height">
                      <div class="col-xs-height col-top">
                        <div class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="dashboardDetails.statusCode==200" class="no-margin p-b-5">{{dashboardDetails.response.vehicleCount}} / {{dashboardDetails.response.agentCount}}</h3>
                          <h3 *ngIf="dashboardDetails.statusCode!=200" class="no-margin p-b-5">0 / 0</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END WIDGET -->
                 <!-- START WIDGET widget_weekly_sales_card-->
                <div class="card no-border" style="height: 90px; margin-top: -20px; width: 250px; margin-left: -20px;">
                  <div class="container-xs-height full-height">
                    <div class="row-xs-height">
                      <div class="col-xs-height col-top">
                        <div class="card-header  top-left top-right">
                          <div class="card-title1">
                            <span class="font-montserrat fs-11 all-caps">Total Customer
                                </span>
                          </div>
                          <div class="card-controls">
                            <ul>
                              <li><a href="#" class="portlet-refresh text-black" data-toggle="refresh"><i class="portlet-icon portlet-icon-refresh"></i></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row-xs-height">
                      <div class="col-xs-height col-top">
                        <div class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="dashboardDetails.statusCode==200 && dashboardDetails.response.customerCount!=null" class="no-margin p-b-5">{{dashboardDetails.response.customerCount}}</h3>
                          <h3 *ngIf="dashboardDetails.statusCode!=200" class="no-margin p-b-5">0</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END WIDGET -->
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="card ">
                <div class="card-header ">
                  <h5 class="card-title">Fuel Availability</h5>
                  <p class="card-category">Total Available Fuel in Vehicles</p>
                </div>
                <div class="card-body ">
                  <canvas id="chartEmail"></canvas>
                </div>
                <div class="card-footer ">
                  <div class="legend">
                    <i class="fa fa-circle" style="color: #e3e3e3;"></i> Total Refill  
                    <i class="fa fa-circle" style="color: #4acccd;"></i> Available Fuel 
                    <i class="fa fa-circle" style="color: #ef8157;"></i> Delivered Fuel 
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="card card-chart">
                <div class="card-header">
                  <h5 class="card-title">Order Statistics</h5>
                  <p class="card-category">Overall Order Statistics</p>
                </div>
                <div class="card-body">
                  <canvas id="speedChart" width="400" height="100"></canvas>
                </div>
                <div class="card-footer">
                  <div class="chart-Bar">
                    <i class="fa fa-circle text-info"></i> Total Orders
                    <i class="fa fa-circle " style="color: green"></i> Completed Orders
                    <i class="fa fa-circle" style="color: #fd7e14;"></i> Pending Orders
                    <i class="fa fa-circle" style="color: blue;"></i> Inprogress Orders
                    <i class="fa fa-circle" style="color: red;"></i> Cancelled Orders
                  </div>
                </div>
              </div>
            </div>
          </div>   
          
          <div class="container ">
            <highcharts-chart
            [Highcharts] = "highcharts" 
            [options] = "chartOptions" 
            style = "width: 100%; height: 400px; display: block;">
            </highcharts-chart>
         <br>
            <!-- <div class="row">
              <div class="col-md-12">
                <div class="card ">
                  <div class="card-header ">
                    <h5 class="card-title">Fuel Delivery</h5>
                    <p class="card-category">Quantity in Ltrs Delivered Successfully</p>
                  </div>
                  <div class="card-body ">
                    <canvas id=chartHours width="400" height="100"></canvas>
                  </div>
                  <div class="card-footer ">
                    <hr>
                    <div class="stats">
                      <i class="fa fa-history"></i> Updated 3 minutes ago
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            
  </div>

          <div class=" no-padding    container-fixed-lg bg-white" style="margin-top:30px ">
        <div class="container"> 
          <!-- START card -->
          
          <div class="card card-transparent">
            <label style="font-size: 20px; margin-top: 10px;"><b>Recent Orders</b></label>
            <div class="btn-section-wrapper">
            <a href="#" routerLink="/order" style="margin-top: 10px;" class="back_page"><span class="label label-info"><i class="back_page"></i>View All</span></a>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Order ID </th>
                      <th style="text-align: left;">Customer Name</th>
                      <th style="text-align: left;">Customer Mobile</th>
                      <th style="text-align: left;">Vehicle Number</th>
                      <th style="text-align: left;">Delivery Agent Name</th>
                      <th style="text-align: left;">Fuel</th>
                      <th style="text-align: center;">Quantity  in Ltrs</th>
                      <th style="text-align: left;">Booked  Date Time </th>
                      <th style="text-align: left;">Delivered  Date Time </th>
                      <th style="text-align: center;">Status</th>
                      <th style="width: 100px;text-align: center;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let order of dashboardDetails.response.orderList; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="order.orderId!=null"align="left" class="v-align-middle">{{order.orderId}}</td>
                      <td *ngIf="order.orderId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.customerId.firstName!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.customerId.firstName}} {{order.customerId.lastName}}</td>
                      <td *ngIf="order.customerId.firstName==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.customerId.mobileNumber!=null" align="left" class="v-align-middle">{{order.customerId.mobileNumber}}</td>
                      <td *ngIf="order.customerId.mobileNumber==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.vehicleId.vehicleNumber!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
                      <td *ngIf="order.vehicleId.vehicleNumber==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
                      <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.fuelType!=null" align="left" class="v-align-middle">{{order.fuelType}}</td>
                      <td *ngIf="order.fuelType==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
                      <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                      <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                      <td *ngIf="order.status==='cancelled'" align="center" style="color: red;">{{order.status}}</td>
                      <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                      <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                      <td align="left" class="v-align-middle">
                        <button  title="View" class="label label-info" (click)="open(content,order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                        <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                        <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.cancelOrder===1" class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h6>Order View Page</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <h5 style="margin-left: 15px; text-align: left; color: grey;">Order Details</h5>
  <div class="container"
  fxLayout
>
<div class="item item-1">
<p><b>Order ID</b></p>
<span>{{orderDetails.response.orderId}}</span>
</div>
<div class="item item-2" style="margin-left: 30px;">
  <p><b>Customer Name</b></p>
  <span *ngIf="orderDetails.response.customerId!=null" style="word-wrap: break-word;">{{orderDetails.response.customerId.firstName}} {{orderDetails.response.customerId.lastName}}</span>
      <span *ngIf="orderDetails.response.customerId==null">N/A</span>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p><b>Customer Mobile</b></p>
  <span *ngIf="orderDetails.response.customerId!=null">{{orderDetails.response.customerId.mobileNumber}}</span>
      <span *ngIf="orderDetails.response.customerId==null">N/A</span>
</div>
<div class="item item-4" style="margin-left: 30px;">
  <p><b>Booking Date & Time</b></p>
  <span *ngIf="orderDetails.response.bookedDate!=null">{{orderDetails.response.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span>
      <span *ngIf="orderDetails.response.bookedDate==null">N/A</span>
</div>
<div class="item item-5" style="margin-left: 30px;">
  <p><b>Quantity in Ltrs</b></p>
  <span *ngIf="orderDetails.response.quantity!=null">{{orderDetails.response.quantity}}</span>
      <span *ngIf="orderDetails.response.quantity==null">N/A</span>
</div>
<div class="item item-7" style="margin-left: 30px;">
  <p><b>Order Type</b></p>
<span *ngIf="orderDetails.response.bookLaterTime===''; else ifNotShow">
  Immediate
</span>
<ng-template #ifNotShow>
  <span>Scheduled</span>
</ng-template>
</div>

<div class="item item-5" style="margin-left: 30px;" >
  <p><b>Payment Mode</b></p>
  <span *ngIf="orderDetails.response.paymentModeId!=null">{{orderDetails.response.paymentModeId.paymentMode}}</span>
      <span *ngIf="orderDetails.response.paymentModeId==null">N/A</span>
</div>


<div *ngIf="orderDetails.response.status==='Cancelled'" class="item item-8" style="margin-left: 30px;">
  <p><b>Cancelled Date & Time</b></p>
  <span >{{orderDetails.response.orderCancellationDatetime |date:'dd/MM/yyyy hh:mm:ss a'}}</span>
</div>
<div *ngIf="orderDetails.response.status==='Cancelled'" class="item item-9" style="margin-left: 30px;">
  <p><b>Cancelled By</b></p>
  <span *ngIf="orderDetails.response.cancelledBy!=null">
    <span style="font-size: 10px;">Name :{{orderDetails.response.cancelledBy.firstName}} {{orderDetails.response.cancelledBy.lastName}}</span><br>
    <span style="font-size: 10px;">Mobile :{{orderDetails.response.cancelledBy.mobileNumber}}</span>
  </span>
  <span *ngIf="orderDetails.response.cancelledBy==null">N/A</span>
</div>
<div *ngIf="orderDetails.response.status==='Cancelled'" class="item item-9" style="margin-left: 30px;">
  <p><b>Reason for Cancelling</b></p>
  <span *ngIf="orderDetails.response.cancellationId!=null" style="word-wrap: break-word;">{{orderDetails.response.cancellationId.cancellationMessage}}</span>
  <span *ngIf="orderDetails.response.cancellationId==null">N/A</span>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p><b>Status</b></p>
  <span *ngIf="orderDetails.response.status==='Pending'"><span class="ht-Pending"><b>{{orderDetails.response.status}}</b></span></span>
      <span *ngIf="orderDetails.response.status==='Cancelled'"><span style="color: red;"><b>{{orderDetails.response.status}}</b></span></span>
      <span *ngIf="orderDetails.response.status==='Inprogress'"><span style="color: blue;"><b>{{orderDetails.response.status}}</b></span></span>
      <span *ngIf="orderDetails.response.status==='Delivered'"><span class="ht-active"><b>{{orderDetails.response.status}}</b></span></span>
</div>

  </div>

  <br>
  <div class="container"
  fxLayout
>
<div class="item item-3" >
  <p><b>Coupon Code</b></p>
  <span *ngIf="orderDetails.response.couponCode!=null">{{orderDetails.response.couponCode}}</span>
  <span *ngIf="orderDetails.response.couponCode==null">N/A</span>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p><b>Coupon Discount Price</b></p>
  <span *ngIf="orderDetails.response.couponCode!=null">
  <span *ngIf="orderDetails.response.couponDiscountFlat==1">
    <span>{{orderDetails.response.couponDiscountPrice}} % </span>
  </span>
  <span *ngIf="orderDetails.response.couponDiscountFlat==2">
    <span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.couponDiscountPrice | number : '1.2-2'}}</span>
  </span>
  </span>
  <span *ngIf="orderDetails.response.couponCode==null">N/A</span>
</div>
</div>
  <hr class="beautiful-line">
<br>
  <h5 style="margin-left: 15px; text-align: left; color: grey;">Delivery Agent Info</h5>
  <div class="container"
  fxLayout
>
<div class="item item-1">
<p><b>Delivery Agent Name</b></p>
<span *ngIf="orderDetails.response.orderAcceptedBy!=null" style="word-wrap: break-word;">{{orderDetails.response.orderAcceptedBy.firstName}} {{orderDetails.response.orderAcceptedBy.lastName}}</span>
      <span *ngIf="orderDetails.response.orderAcceptedBy==null">N/A</span>
</div>
<div class="item item-2" style="margin-left: 40px;">
  <p><b>Delivery Agent Number</b></p>
  <span *ngIf="orderDetails.response.orderAcceptedBy!=null">{{orderDetails.response.orderAcceptedBy.mobileNumber}}</span>
      <span *ngIf="orderDetails.response.orderAcceptedBy==null">N/A</span>
</div>
<div class="item item-4" style="margin-left: 40px;">
  <p><b>Vehicle Number</b></p>
  <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.vehicleNumber}}</span>
      <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
</div>
<div class="item item-5" style="margin-left: 40px;">
  <p><b>Fuel</b></p>
  <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.fuelType.fuelTypeName}}</span>
      <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
</div>
<div *ngIf="orderDetails.response.status==='Completed'" class="item item-6" style="margin-left: 40px;">
  <p><b>Delivered  Date Time</b></p>
  <span >{{orderDetails.response.orderDeliveredDateTime |date:'dd/MM/yyyy hh:mm:ss a' }}</span>
</div>
<div class="item item-7" style="margin-left: 40px;">
  <p><b>Delivery location</b></p>
  <span *ngIf="orderDetails.response.deliveryAddress!=null" style="word-wrap: break-word;">{{orderDetails.response.deliveryAddress}}</span>
      <span *ngIf="orderDetails.response.deliveryAddress==null">N/A</span>
</div>
  </div>
  <br>
  <div class="container"
  fxLayout
>
<div class="item item-1" >
  <p><b>Order Placed location</b></p>
  <span *ngIf="orderDetails.response.reqAddress!=null">{{orderDetails.response.reqAddress}}</span>
      <span *ngIf="orderDetails.response.reqAddress==null">N/A</span>
</div>
</div>

  <br>
  <h5 *ngIf="orderDetails.response.status==='Completed'" style="margin-left: 15px; text-align: left; color: grey;">Transaction Details</h5>
  <div *ngIf="orderDetails.response.status==='Completed'" class="container"
  fxLayout
>
<div class="item item-1">
  <p *ngIf="orderDetails.response.totalfuelcost!=null"class="card-title"><b>Total Fuel Cost</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.totalfuelcost | number : '1.2-2'}}</span></p>
  <p *ngIf="orderDetails.response.totalfuelcost==null"class="card-title"><b>Total Fuel Cost</b><br><span>N/A</span></p>
</div>
<div class="item item-1" style="margin-left: 30px;">
  <p *ngIf="orderDetails.response.deliveryCharges!=null"class="card-title"><b>Delivery Charges</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.deliveryCharges | number : '1.2-2'}}</span></p>
          <p *ngIf="orderDetails.response.deliveryCharges==null"class="card-title"><b>Delivery Charges</b><br><span>N/A</span></p>
  </div>
  <div class="item item-1" style="margin-left: 30px;">
    <p *ngIf="orderDetails.response.sgst!=null"class="card-title"><b>SGST</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.sgst | number : '1.2-2'}}</span></p>
            <p *ngIf="orderDetails.response.sgst==null"class="card-title"><b>SGST</b><br><span>N/A</span></p>
    </div>
    <div class="item item-1" style="margin-left: 30px;">
      <p *ngIf="orderDetails.response.cgst!=null"class="card-title"><b>CGST</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.cgst | number : '1.2-2'}}</span></p>
              <p *ngIf="orderDetails.response.cgst==null"class="card-title"><b>CGST</b><br><span>N/A</span></p>
      </div>
      <div class="item item-1" style="margin-left: 30px;">
        <p *ngIf="orderDetails.response.internetHandlingFee!=null"class="card-title"><b>Platform Charges</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.internetHandlingFee | number : '1.2-2'}}</span></p>
                <p *ngIf="orderDetails.response.internetHandlingFee==null"class="card-title"><b>Platform Charges</b><br><span>N/A</span></p>
        </div>    
        <div class="item item-1" style="margin-left: 30px;">
                  <p *ngIf="orderDetails.response.totalPayableAmount!=null"class="card-title"><b>Total Paid Amount</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.totalPayableAmount | number : '1.2-2'}}</span></p>
                  <p *ngIf="orderDetails.response.totalPayableAmount==null"class="card-title"><b>Total Paid Amount</b><br><span>N/A</span></p>

          </div>     
<div class="item item-2" style="margin-left: 30px;">
  <p *ngIf="orderDetails.response.transactionId!=null"class="card-title"><b>Transaction ID</b><br><span>{{orderDetails.response.transactionId}}</span></p>
          <p *ngIf="orderDetails.response.transactionId==null"class="card-title"><b>Transaction ID</b><br><span>N/A</span></p>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p *ngIf="orderDetails.response.paymentStatus==='Success'" class="card-title"><b>Payment Status </b><br><span class="ht-Success"><b>{{orderDetails.response.paymentStatus}}</b></span></p>
        <p *ngIf="orderDetails.response.paymentStatus==='Pending'" class="card-title"><b>Payment Status </b><br><span class="ht-Pending"><b>{{orderDetails.response.paymentStatus}}</b></span></p>
        <p *ngIf="orderDetails.response.paymentStatus==='Failure'" class="card-title"><b>Payment Status </b><br><span style="color: red;"><b>Failed</b></span></p>
        <p *ngIf="orderDetails.response.paymentStatus==='Initiated'" class="card-title"><b>Payment Status</b> <br><span class="ht-Pending"><b>Pending</b></span></p>
</div>
<div class="item item-4" style="margin-left: 30px;">
  <p class="card-title"><b>Transaction Date & Time</b> <br><span>{{orderDetails.response.initiatedDatetime |date:'dd/MM/yyyy hh:mm:ss a'}}</span></p>
</div>
  </div>
<hr class="beautiful-line">
<h5 style="margin-left: 15px; text-align: left; color: grey;">Refund Info</h5>
  <br>
  <div class="container" fxLayout>
    <div class="item item-1">
      <p><b>Quantity in Ltrs Ordered</b></p>
      <span *ngIf="orderDetails.response.refundFlag==1">
        <span *ngIf="orderDetails.response.quantity!=null">{{orderDetails.response.quantity}}</span>
        <span *ngIf="orderDetails.response.quantity==null">N/A</span>
      </span>
      <span *ngIf="orderDetails.response.refundFlag==0">
        N/A
      </span>
    </div>

    <div class="item item-1" style="margin-left: 30px;">
      <p><b>Amount is Rs. / Ltr</b></p>
      <span *ngIf="orderDetails.response.refundFlag==1">
        <span *ngIf="orderDetails.response.fuelPrice!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.fuelPrice | number : '1.2-2' }}</span>
        <span *ngIf="orderDetails.response.fuelPrice==null">N/A</span>
      </span>
      <span *ngIf="orderDetails.response.refundFlag==0">
        N/A
      </span>
    </div>

    <div class="item item-1" style="margin-left: 30px;">
      <p><b>Quantity in Ltrs Delivered</b></p>
      <span *ngIf="orderDetails.response.refundFlag==1">
        <span *ngIf="orderDetails.response.orderDeliveredLt!=null">{{orderDetails.response.orderDeliveredLt}}</span>
        <span *ngIf="orderDetails.response.orderDeliveredLt==null">N/A</span>
      </span>
      <span *ngIf="orderDetails.response.refundFlag==0">
        N/A
      </span>
    </div>

    <div class="item item-1" style="margin-left: 30px;">
      <p><b>Total Paid Amount in Rs.</b></p>
      <span *ngIf="orderDetails.response.refundFlag==1">
        <span *ngIf="orderDetails.response.totalPayableAmount!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.totalPayableAmount | number : '1.2-2' }}</span>
        <span *ngIf="orderDetails.response.totalPayableAmount==null">N/A</span>
      </span>
      <span *ngIf="orderDetails.response.refundFlag==0">
        N/A
      </span>
    </div>

    <div class="item item-1" style="margin-left: 30px;">
      <p><b>Amount in Rs. refunded</b></p>
      <span *ngIf="orderDetails.response.refundFlag==1">
        <span *ngIf="orderDetails.response.refundAmount!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.refundAmount | number : '1.2-2' }}</span>
        <span *ngIf="orderDetails.response.refundAmount==null">N/A</span>
      </span>
      <span *ngIf="orderDetails.response.refundFlag==0">
        N/A
      </span>
    </div>

    <div class="item item-1" style="margin-left: 30px;">
      <p><b>Refund Status</b></p>
      <span *ngIf="orderDetails.response.refundFlag==1">
        <span *ngIf="orderDetails.response.refundStatus==20" style="color: orange;"> Initiated</span>
        <span *ngIf="orderDetails.response.refundStatus==21" style="color: green;"> Completed</span>
      </span>
      <span *ngIf="orderDetails.response.refundFlag==0">
        N/A
      </span>
    </div>

    <div class="item item-1" style="margin-left: 30px;">
      <p><b>Refund Submitted Date & Time</b></p>
      <span *ngIf="orderDetails.response.refundFlag==1">
        <span *ngIf="orderDetails.response.refundSubmittedDateTime!=null">
          {{orderDetails.response.refundSubmittedDateTime |date:'dd/MM/yyyy hh:mm:ss a'}}
        </span>
        <span *ngIf="orderDetails.response.refundSubmittedDateTime==null">
          N/A
        </span>
      </span>
      <span *ngIf="orderDetails.response.refundFlag==0">
        N/A
      </span>
    </div>
  </div>
  <br>
  <div class="container" fxLayout>
    <div class="item item-1">
      <p><b>Refunded Completed Date & Time</b></p>
      <span *ngIf="orderDetails.response.refundFlag==1">
        <span *ngIf="orderDetails.response.refundDateTime!=null">
          {{orderDetails.response.refundDateTime |date:'dd/MM/yyyy hh:mm:ss a'}}
        </span>
        <span *ngIf="orderDetails.response.refundDateTime==null">
          N/A
        </span>
      </span>
      <span *ngIf="orderDetails.response.refundFlag==0">
        N/A
      </span>
    </div>
    </div>

<hr *ngIf="orderDetails.response.status=='Cancelled'" class="beautiful-line">
<h5 *ngIf="orderDetails.response.status=='Cancelled'" style="margin-left: 15px; text-align: left; color: grey;">Order Track Info</h5>
<br>
<div *ngIf="orderDetails.response.status=='Cancelled'"class="container"
  fxLayout
>
<div class="item item-1" style="margin-left: 20px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderPlaced==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderPlaced==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Placed</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderPlacedDateTime!=null" style="text-align: center;">
    {{orderTrackDetails.response.orderPlacedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderPlacedDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-2" style="margin-left: -80px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-3" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderAccepted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderAccepted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>    
  </span>
  <span style="margin-bottom: 10px;"><b>Agent Accepted</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderAcceptedDateTime!=null">
    {{orderTrackDetails.response.orderAcceptedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span style="color: red;" *ngIf="orderTrackDetails.response.orderAcceptedDateTime==null">
    Order Cancelled
  </span>
</div>
<div class="item item-4" style="margin-left: -50px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-5" style="margin-left: 15px;">
  <img *ngIf="orderTrackDetails.response.paymentCompleted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.paymentCompleted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>    
    <span style="margin-bottom: 10px; width: 30px;"><b>Payment Completed</b></span><br>
    <span style="color: red;">
      Order Cancelled
    </span>    
</div>
<div class="item item-6" style="margin-left: -60px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-7" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderInitiated==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderInitiated==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Initiated</b></span><br>
  <span style="color: red;">
    Order Cancelled
  </span>  
</div>
<div class="item item-8" style="margin-left: -40px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-9" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.refillOtp==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.refillOtp==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Refill OTP Verified</b></span><br>
  <span style="color: red;">
    Order Cancelled
  </span>  
</div>
<div class="item item-10" style="margin-left: -60px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-11" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.endOrderOtp==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.endOrderOtp==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>END Order OTP Verified</b></span><br>
  <span style="color: red;">
    Order Cancelled
  </span>  
</div>
<div class="item item-12" style="margin-left: -80px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-13" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderCompleted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderCompleted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Completed</b></span><br>
  <span style="color: red;">
    Order Cancelled
  </span>  
</div>
<div class="item item-14" style="margin-left: -40px;">
  <b><hr width="60px" color="pink"></b>
</div>
<div class="item item-14" style="margin-left: 15px;">
  <span>
    <img src="assets/img/remove.svg" alt="logo" data-src="assets/img/remove.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Cancelled</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderPlacedDateTime!=null" style="text-align: center;">
    {{orderTrackDetails.response.orderCancelledDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>  
</div>
  </div>


  <hr *ngIf="orderDetails.response.status!=='Cancelled'" class="beautiful-line">
  <h5 *ngIf="orderDetails.response.status!=='Cancelled'" style="margin-left: 15px; text-align: left; color: grey;">Order Track Info</h5>
  <br>
  <div *ngIf="orderDetails.response.status!=='Cancelled'"class="container"
  fxLayout
>
<div class="item item-1" style="margin-left: 20px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderPlaced==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderPlaced==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Placed</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderPlacedDateTime!=null" style="text-align: center;">
    {{orderTrackDetails.response.orderPlacedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderPlacedDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-2" style="margin-left: -80px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-3" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderAccepted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderAccepted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>    
  </span>
  <span style="margin-bottom: 10px;"><b>Agent Accepted</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderAcceptedDateTime!=null">
    {{orderTrackDetails.response.orderAcceptedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderAcceptedDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-4" style="margin-left: -50px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-5" style="margin-left: 15px;">
  <img *ngIf="orderTrackDetails.response.paymentCompleted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.paymentCompleted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>    
    <span style="margin-bottom: 10px;"><b>Payment Completed</b></span><br>
    <span *ngIf="orderTrackDetails.response.paymentCompletedDateTime!=null">
      {{orderTrackDetails.response.paymentCompletedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
    </span>
    <span *ngIf="orderTrackDetails.response.paymentCompletedDateTime==null">
      Will be Updated
    </span>    
</div>
<div class="item item-6" style="margin-left: -60px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-7" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderInitiated==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderInitiated==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Initiated</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderInitiatedDateTime!=null">
    {{orderTrackDetails.response.orderInitiatedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderInitiatedDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-8" style="margin-left: -40px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-9" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.refillOtp==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.refillOtp==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Refill OTP Verified</b></span><br>
  <span *ngIf="orderTrackDetails.response.refillDateTime!=null">
    {{orderTrackDetails.response.refillDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.refillDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-10" style="margin-left: -60px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-11" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.endOrderOtp==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.endOrderOtp==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>END Order OTP Verified</b></span><br>
  <span *ngIf="orderTrackDetails.response.endOrderDateTime!=null">
    {{orderTrackDetails.response.endOrderDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.endOrderDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-12" style="margin-left: -80px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-13" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderCompleted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderCompleted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Completed</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderCompletedDateTime!=null">
    {{orderTrackDetails.response.orderCompletedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderCompletedDateTime==null">
    Will be Updated
  </span>
</div>
  </div> 
<br>
  <div *ngIf="orderDetails.response.status!=='Cancelled'"class="container"
  fxLayout
>
<div class="item item-1" style="margin-left: 20px;">
  <span>
    <img *ngIf="orderTrackDetails.response.refundstatus==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.refundstatus==20" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.refundstatus==21" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <br>
  </span>
  <span style="margin-bottom: 10px;"><b>Refund Submitted Date & Time</b></span><br>
  <span *ngIf="orderTrackDetails.response.refundsubmittedDateTime!=null" style="text-align: center;">
    {{orderTrackDetails.response.refundsubmittedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.refundsubmittedDateTime==null">
    Will be Updated
  </span>
</div>
</div>
  
  <!-- <div class="modal-body"> -->
    <!-- <div class="wrapper">
      <div class="flex extradiv">
        <h5>Order View Page</h5>
        <p class="card-title">Order ID <br><span><b>{{orderDetails.response.orderId}}</b></span></p>
      <p *ngIf="orderDetails.response.customerId!=null"class="card-title">Customer Name  <br><span><b>{{orderDetails.response.customerId.firstName}} {{orderDetails.response.customerId.lastName}}</b></span></p>
      <p *ngIf="orderDetails.response.customerId==null"class="card-title">Customer Name  <br><span><b>N/A</b></span></p>
      <p *ngIf="orderDetails.response.customerId!=null" class="card-title">Customer Mobile  <br><span><b>{{orderDetails.response.customerId.mobileNumber}}</b></span></p>
      <p *ngIf="orderDetails.response.customerId==null" class="card-title">Customer Mobile  <br><span><b>N/A</b></span></p>
        <p *ngIf="orderDetails.response.bookedDate!=null"class="card-title">Booking Date & Time  <br><span><b>{{orderDetails.response.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</b></span></p>
        <p *ngIf="orderDetails.response.bookedDate==null"class="card-title">Booking Date & Time  <br><span><b>N/A</b></span></p>
        <p *ngIf="orderDetails.response.quantity!=null"class="card-title">Quantity in Ltrs   <br><span><b>{{orderDetails.response.quantity}}</b></span></p>
        <p *ngIf="orderDetails.response.quantity==null"class="card-title">Quantity in Ltrs   <br><span><b>N/A</b></span></p>
        <p *ngIf="orderDetails.response.status==='Completed'"class="card-title">Delivered  Date Time  <br><span><b>{{orderDetails.response.orderDeliveredDateTime |date:'dd/MM/yyyy hh:mm:ss a' }}</b></span></p>
        <p *ngIf="orderDetails.response.bookLaterTime!=null && orderDetails.response.bookLaterTime!==''"class="card-title">Order Type  <br><span><b>Scheduled</b></span></p>
        <p *ngIf="orderDetails.response.bookLaterTime==null || orderDetails.response.bookLaterTime===''"class="card-title">Order Type  <br><span><b>Immediate</b></span></p>
        <p *ngIf="orderDetails.response.status==='Cancelled'"class="card-title">Cancelled Date & Time  <br><span><b>{{orderDetails.response.orderCancellationDatetime |date:'dd/MM/yyyy hh:mm:ss a'}}</b></span></p>
      </div>
      <div class="flex extradiv">
        <br>
        <br>
        <p *ngIf="orderDetails.response.orderAcceptedBy!=null"class="card-title">Delivery Agent Name <br><span><b>{{orderDetails.response.orderAcceptedBy.firstName}} {{orderDetails.response.orderAcceptedBy.lastName}}</b> </span> </p>
        <p *ngIf="orderDetails.response.orderAcceptedBy==null"class="card-title">Delivery Agent Name <br><span><b>N/A</b> </span> </p>
        <p *ngIf="orderDetails.response.orderAcceptedBy!=null"class="card-title">Delivery Agent Number <br><span><b>{{orderDetails.response.orderAcceptedBy.mobileNumber}}</b> </span> </p>
        <p *ngIf="orderDetails.response.orderAcceptedBy==null"class="card-title">Delivery Agent Number <br><span><b>N/A</b> </span> </p>
        <p *ngIf="orderDetails.response.status==='Pending'" class="card-title">Status <br><span class="ht-Pending"><b>{{orderDetails.response.status}}</b></span></p>
        <p *ngIf="orderDetails.response.status==='Cancelled'" class="card-title">Status <br><span class="ht-inactive"><b>{{orderDetails.response.status}}</b></span></p>
        <p *ngIf="orderDetails.response.status==='Inprogress'" class="card-title">Status <br><span class="ht-active"><b>{{orderDetails.response.status}}</b></span></p>
        <p *ngIf="orderDetails.response.status==='Completed'" class="card-title">Status <br><span class="ht-active"><b>{{orderDetails.response.status}}</b></span></p>
        <p *ngIf="orderDetails.response.vehicleId!=null"class="card-title">Vehicle Number <br><span><b>{{orderDetails.response.vehicleId.vehicleNumber}}</b></span></p>
        <p *ngIf="orderDetails.response.vehicleId==null"class="card-title">Vehicle Number <br><span><b>N/A</b></span></p>
        <p *ngIf="orderDetails.response.vehicleId!=null"class="card-title">Fuel <br><span><b>{{orderDetails.response.vehicleId.fuelType.fuelTypeName}}</b></span></p>
        <p *ngIf="orderDetails.response.vehicleId==null"class="card-title">Fuel <br><span><b>N/A</b></span></p>
      <p *ngIf="orderDetails.response.deliveryAddress!=null"class="card-title">Delivery location  <br><span><b>{{orderDetails.response.deliveryAddress}}</b></span></p>
      <p *ngIf="orderDetails.response.deliveryAddress==null"class="card-title">Delivery location  <br><span><b>N/A</b></span></p>
      </div>
    </div> -->
    <!-- <div *ngIf="orderDetails.response.status==='Completed'">
      <h6>
        Transaction Details
      </h6>
      <div>
        <p *ngIf="orderDetails.response.totalPayableAmount!=null"class="card-title">Total Paid Amount<br><span><b><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.totalPayableAmount | number : '1.2-2'}}</b></span></p>
        <p *ngIf="orderDetails.response.totalPayableAmount==null"class="card-title">Total Paid Amount<br><span><b>N/A</b></span></p>
          <p *ngIf="orderDetails.response.trackId!=null"class="card-title">Transaction ID<br><span><b>{{orderDetails.response.paymentId}}</b></span></p>
          <p *ngIf="orderDetails.response.trackId==null"class="card-title">Transaction ID<br><span><b>N/A</b></span></p>
            <p *ngIf="orderDetails.response.paymentStatus==='Success'" class="card-title">Payment Status <br><span class="ht-Success"><b>{{orderDetails.response.paymentStatus}}</b></span></p>
        <p *ngIf="orderDetails.response.paymentStatus==='Pending'" class="card-title">Payment Status <br><span class="ht-Pending"><b>{{orderDetails.response.paymentStatus}}</b></span></p>
        <p *ngIf="orderDetails.response.paymentStatus==='Failure'" class="card-title">Payment Status <br><span class="ht-inactive"><b>{{orderDetails.response.paymentStatus}}</b></span></p>
        <p *ngIf="orderDetails.response.paymentStatus==='Initiated'" class="card-title">Payment Status <br><span class="ht-Pending"><b>Pending</b></span></p>
              <p class="card-title">Transaction Date & Time <br><span><b>{{orderDetails.response.initiatedDatetime |date:'dd/MM/yyyy hh:mm:ss a'}} </b></span></p>
      </div>
    </div> -->
    <!-- <div *ngIf="orderDetails.response.status==='Cancelled'">
      <h6>
        Reason For Cancellation
      </h6>
      <div>
        <p *ngIf="orderDetails.response.orderRemarks!=null" class="card-title"><br><span><b>{{orderDetails.response.orderRemarks}}</b></span></p>
        <p *ngIf="orderDetails.response.orderRemarks==null" class="card-title"><br><span><b>N/A</b></span></p>
      </div>
    </div> -->
  <!-- </div> -->
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
  </div>
</ng-template>
      
      <ng-template #content1 let-modal>
        <div class="modal-header">
          <h5>Assign Vehicle</h5>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
        </div>
        <hr class="beautiful-line">
        <div class="container"
        fxLayout
      >
      
      <div class="item item-1">
        <p><b>Order ID</b></p>
        <span>{{orderDetails.response.orderId}}</span>
      </div>
      <div class="item item-2" style="margin-left: 25px;">
        <p><b>Customer Name</b></p>
        <span style="word-wrap: break-word;">{{orderDetails.response.customerId.firstName}} {{orderDetails.response.customerId.lastName}}</span>
      </div>
      <div class="item item-3" style="margin-left: 25px;">
        <p><b>Customer Mobile</b></p>
        <span>{{orderDetails.response.customerId.mobileNumber}}</span>
      </div>
      <div class="item item-4" style="margin-left: 25px;">
        <p><b>Status</b></p>
        <span *ngIf="orderDetails.response.status==='Pending'"><span class="ht-Pending"><b>{{orderDetails.response.status}}</b></span></span>
        <span *ngIf="orderDetails.response.status==='Cancelled'"><span style="color: red;"><b>{{orderDetails.response.status}}</b></span></span>
        <span *ngIf="orderDetails.response.status==='Inprogress'"><span style="color: blue;"><b>{{orderDetails.response.status}}</b></span></span>
        <span *ngIf="orderDetails.response.status==='Completed'"><span class="ht-active"><b>{{orderDetails.response.status}}</b></span></span>
      </div>
      <div class="item item-5" style="margin-left: 25px;">
        <p><b>Booking Date & Time</b></p>
        <span>{{orderDetails.response.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span>
      </div>
      <div class="item item-6" style="margin-left: 25px;">
        <p><b>Delivery Date & Time</b></p>
        <span>N/A</span>
      </div>
      <div class="item item-7" style="margin-left: 25px;">
        <p><b>Fuel</b></p>
        <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.fuelType.fuelTypeName}}</span>
            <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
      </div>
      <div class="item item-8" style="margin-left: 25px;">
        <p><b>Quantity in Ltrs</b></p>
        <span >{{orderDetails.response.quantity}}</span>
      </div>
      <br>
        </div>
        <br>
        <div class="container"
        fxLayout
      >
        <div class="item item-1">
          <p><b>Vehicle Name</b></p>
          <div class="ch-form-group">
            <select id="countryFormControl" value="countryFormControl" class="form-control" #mySelect (change)='onOptionsSelected(mySelect.value)'>
              <option *ngFor="let vehicle of activeVehicle.response" value={{vehicle.vehicleId}}>{{vehicle.vehicleNumber}} ({{vehicle.userId.firstName}} {{vehicle.userId.lastName}})</option>
            </select>
        </div>
        </div>
        <div class="item item-2" style="margin-left: 25px;">
          <p><b>Delivery Agent Mobile</b></p>
          <span *ngIf="check==null">
            <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.userId.firstName}} {{orderDetails.response.vehicleId.userId.lastName}}</span>
            <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
          </span>
          <span *ngIf="check!=null">{{vehicle.userId.mobileNumber}}</span>
        </div>
        <div class="item item-3" style="margin-left: 25px;">
          <p><b>Delivery Agent Email</b></p>
          <span *ngIf="check==null">
            <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.userId.mobileNumber}}</span>
                <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
          </span>
          <span *ngIf="check!=null">{{vehicle.userId.emailAddress}}</span>
        </div>
        <div class="item item-4" style="margin-left: 25px;">
          <p><b>Vehicle Number</b></p>
          <span *ngIf="check==null">{{orderDetails.response.vehicleId.vehicleNumber}}</span>
          <span *ngIf="check!=null">{{vehicle.vehicleNumber}}</span>
        </div>
        <div class="item item-5" style="margin-left: 25px;">
          <p><b>Delivery location</b></p>
          <span>{{orderDetails.response.deliveryAddress}}</span>
        </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" (click)="assign()" (click)="modal.close('Save click')">Assign</button>
        </div>
      </ng-template>

      <ng-template #content5 let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Reason for cancellation of Order</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
        </div>
        <hr class="beautiful-line">
        <div class="modal-body">
        <form>
            <br>
            <div class="ch-form-group">
              <div class="dropdown" style="width: 450px; margin-left: -3px;">
                <select id="cancelmessage"  name="cancelmessage" class="form-control">
                  <option *ngFor="let cancel of cancelMessageModel.response" value={{cancel.id}}>{{cancel.cancellationMessage}}</option>
                </select></div>
            </div>	
            <br>
            <div class="ch-form-group">
                 <textarea placeholder="Should not exceed more than 150 characters" class="form-control textarea" name="cancelremarks" maxlength="150" id="cancelremarks" rows="3"></textarea>
            </div>
            <br>
          <b>Note</b> : <span style="color: grey;">Should not exceed more than 150 characters</span> 
            </form>
            <br>
            <div *ngIf="errorFlag1" style="color: red;">{{errorMessage}}</div>
          </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" style="color: green;" (click)="cancelOrder()">Submit</button>
        </div>
      </ng-template>
