<app-header></app-header>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
</ngx-spinner>
<ol class="breadcrumb breadcrumb-alt" style="margin-top:100px; width: auto;" >
  <li class="breadcrumb-item" style="margin-left: 15px;"><a href="#" routerLink="/dashboard">Home</a></li>
  <li class="breadcrumb-item active">PROMOTIONS</li>
  <div class="btn-section-wrapper">
      <button style="margin-top: -10px;" type="button" class="label label-info" (click)="open(content)"><i class="fa fa-plus" aria-hidden="true"></i>
        Add Promotions</button>
</div>
</ol>
<div class="container  container-top-dashboard-section">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
	 <!-- <div class="btn-section-wrapper">
     <button style="margin-top: -10px;" type="button" class="btn btn-success" (click)="open(content)"><i class="fa fa-plus" aria-hidden="true"></i>
 Add Promotions</button>
</div> -->
<li [ngbNavItem]="1" style="margin-top: 30px;">
 <!-- <a  ngbNavLink>KYC Document Master</a> -->
 <ng-template ngbNavContent>
   <div class="card-body" >
     <div style="display: none;">{{promotionsModel.statusCode}}</div>
           <div class="table-responsive">
             <table datatable class="ch-table">
               <thead>
                 <tr> 
                   <th style="width: 40px; text-align: center;">Sl.No.</th>
                   <th style="text-align: center;">Image</th>
                   <th style="text-align: left;">Effective From</th>
                   <th style="text-align: left;">Valid Upto</th>
                   <th style="text-align: center;">Status</th>
                   <th style="text-align: left;">Updated Date & Time</th>
                   <th style="text-align: center;">Action</th>
                 </tr>
               </thead>
               <tbody>
                 <tr *ngFor="let promotion of promotionsModel.response; let i=index">
                   <td align="center" class="v-align-middle ">{{i + 1}}</td>
                   <td align="center" class="v-align-middle">
                    <button type="button" title="Image" class="label label-success" (click)="open3(content2,promotion.id)">
                      <span aria-hidden="true"></span>
                      <i class="fa fa-image"></i>
                    </button>
                  </td>
                  <td align="left" class="v-align-middle">{{promotion.effectiveDate | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                   <td align="left" class="v-align-middle">{{promotion.validUptoDate | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                   <td align="center" *ngIf="promotion.bannerStatus===1" class="v-align-middle ht-active">Active</td>
                   <td align="center" *ngIf="promotion.bannerStatus===2" class="v-align-middle ht-inactive">InActive</td>
                   <td align="left" class="v-align-middle">{{promotion.createdDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                   <td align="center" class="v-align-middle">
                     <button title="Edit" (click)="open1(content1,promotion.id,promotion.validUptoDate,promotion.effectiveDate,promotion.bannerStatus,promotion.bannerImage)" class="label label-success"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;
                     <!-- <button title="delete"class="label label-danger" (click)="open2(reject,promotion.id)"><i class="fa fa-trash" aria-hidden="true"></i></button> -->
                  </td>
                 </tr>
               </tbody>
             </table>
       </div>
         </div>
 </ng-template>
</li>
</ul>
<div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Promotions</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <div class="modal-body master-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Upload Banner Image *</label>
        <div class="form-group">
          <input style="margin-left: 10px;" #imageInput
          id="bannerfile"
          name="bannerfile"
           type="file"
           accept=".jpeg,.jpg"
           data-max-size="2048"
           placeholder="Front"
           (change)="selectFile($event)">
          <br>
      </div>
      <b>Note</b> : Files can be .jpeg and .jpg format which should not exceed more than 2 MB
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Status *</label>
        <div class="dropdown">
  <select id="status" name="status" value="status" class="form-control">
    <option value=1>Active</option>
    <option value=2>Inactive</option>
</select>
</div>
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Effective Date & Time *</label>
        <input type="datetime-local" class="form-control" id="effdate" name="effdate" [min]="dateCheck" aria-describedby="emailHelp" placeholder="Select Role" required>
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Valid Upto *</label>
        <input type="datetime-local" class="form-control" id="validdate" name="validdate" [min]="dateCheck" aria-describedby="emailHelp" placeholder="Select Role" required>
      </div>
      <div *ngIf="errorFlag">
        <div class="error-style">{{errorMessage}}</div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="addpromotion()" >Add Promotions</button>
  </div>
</ng-template>


<ng-template #content1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Promotions</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <div class="modal-body master-body">
    <form>

      <div class="form-group">
        <label for="dateOfBirth">Upload Banner Image *</label>
        <br>
        <span>Banner Name : {{bannerImage}}</span>
        <div class="form-group">
          <br>
          <input style="margin-left: 10px;" #imageInput
          id="bannerfile"
          name="bannerfile"
           type="file"
           accept=".jpeg,.jpg"
           data-max-size="2048"
           placeholder="Front"
           (change)="selectFile($event)">
          <br>
      </div>
      <b>Note</b> : Files can be .jpeg and .jpg format which should not exceed more than 2 MB
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Status *</label>
        <div class="dropdown">
  <select id="status" name="status" value="status" [(ngModel)]="bannerStatus" class="form-control">
    <option value=1>Active</option>
    <option value=2>Inactive</option>
</select>
</div>
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Effective Date & Time *</label>
        <input type="datetime-local" class="form-control" id="effdate" name="effdate" [(ngModel)]="effeDate"  [min]="dateCheck" aria-describedby="emailHelp" placeholder="Select Role" required>
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Valid Upto *</label>
        <input type="datetime-local" class="form-control" id="validdate" name="validdate" [(ngModel)]="validDate" [min]="dateCheck" aria-describedby="emailHelp" placeholder="Select Role" required>
      </div>
      <div *ngIf="errorFlag">
        <div class="error-style">{{errorMessage}}</div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="updateAdvertiement()">Update Promotions</button>
  </div>
</ng-template>
<ng-template #content2 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">View Banner</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
    </div>
    <hr class="beautiful-line">
    <div>
      <img class="modal-content"  [src]="imageToShowkyc" width="auto" height="auto">
    </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">close</button>
  </div>
</ng-template>

<ng-template #reject let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Do you want delete the promotion?</label>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="delete()" (click)="modal.close('Save click')">yes</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">no</button>
  </div>
</ng-template>

<router-outlet></router-outlet>