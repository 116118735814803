<div class="mainbody">
  <div class="row m-0">
    <div class="col-12">
      <div class="titleofthepage">
        Partner Declaration
      </div>
	  </div>
	  </div>
<div class="row m-0">
<div class="col-sm-12 m-auto ">
      <div class="detaildescription">
        <div align="justify" class="content">
            Hereby confirm that all information given to 'Cinch Fuel Private Ltd' under the branch name 'Cinch Fuel' in this form is correct and accurate and wish to have an exclusive acquiring relationship. I/ We agree that Cinch Fuel Private Ltd shall at its sole discretion, may reject/ accept the application at any processing stage. In case there is any violation or misuse in our system, partner shall be detained. I/ We understand and agree that use of Cinch Fuel Private Limited shall be deemed to be unconditional and irrevocable by acceptance of the terms and conditions mentioned in the agreement. Setup fees is non refundable. Terms and conditions may be changed at any time without prior notice. At the time of closure, assets belongs to 'Cinch Fuel Private Limited' must be surrendered in working condition otherwise, charges will be applicable & deducted from final settlement.
        </div>
      </div>
    </div>
  </div>
</div>
