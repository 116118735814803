import { Component, OnInit } from '@angular/core';
import {NgbAccordionConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-faq-qr',
  templateUrl: './faq-qr.component.html',
  styleUrls: ['./faq-qr.component.css']
})


export class FaqQrComponent {
  

  constructor(config: NgbAccordionConfig) {
    // customize default values of accordions used by this component tree
    config.closeOthers = true;
    config.type = 'info';
  }
}