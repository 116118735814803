import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GenericResponseModel } from '../model/genricresponse';
import { MarketPrizeModel } from '../model/marketprize';
import { FuelRateMasterService } from '../services/fuelratemaster.service';

@Component({
  selector: 'app-master-fuel-rate-list',
  templateUrl: './master-fuel-rate-list.component.html',
  styleUrls: ['./master-fuel-rate-list.component.css']
})
export class MasterFuelRateListComponent implements OnInit {

  public isCollapsed = false;
 active = 1;

closeResult = '';
marketPrizeDetails:GenericResponseModel;

  constructor(private fuelrateservice:FuelRateMasterService,private router:Router) { }

  ngOnInit(): void {
    this.fuelrateservice.getAllMarketPrice().subscribe(data=>{
      this.marketPrizeDetails= data;
    },err => {
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }else if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });
  }

  viewVehicle(id){
    sessionStorage.setItem("vehicleId",id);
    this.router.navigate(['/master-fuel-rate']);
  }

}
