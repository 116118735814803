import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerOrderModel } from '../model/customerorder';
import { GenericResponseModel } from '../model/genricresponse';
import { DashboardService } from '../services/dashboard.service';
import { OrderTransactionService } from '../services/ordertran.service';
import { PartnersService } from '../services/partners.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { OrderAssignModel } from '../model/orderassign';
import { GenericResponse } from '../model/genricmodelresponse';
import { UserModel } from '../model/user';
import { UserService } from '../services/user.service';
import { VehicleFuelService } from '../services/vehivle.service';
import { VehicleAttachModel } from '../model/vehicleModel';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import Chart from 'chart.js';
import * as HighCharts from 'highcharts';
import * as Highcharts from 'highcharts';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})


export class DashboardComponent{
  title = 'myHighchart';
    public  highcharts = Highcharts;
    public chartOptions = {   
        
      };
    
  public monthArray:any;
  public quantityArray:any;    

  public canvas : any;
  public ctx;
  public chartColor;
  public chartEmail;
  public chartHours;

    symbole : String;
   dashboardDetails:GenericResponse;
   fuelDeliverModel:GenericResponse;
   fuelStockModel:GenericResponse;
   orderStackModel:GenericResponse;
   public isCollapsed = false;
  active = 1;
closeResult = '';
orderDetail:GenericResponseModel;
activePartner:GenericResponseModel;
vehicleModel : CustomerOrderModel;
orderId:Number;
orderDetails:GenericResponseModel;
activepart:Object;
  activeparmodel:UserModel;
  activemobile:Number;
  activeemila:String;
  orderTrackDetails:GenericResponse;
  activeVehicle:GenericResponseModel;
  activevehi:Object;
  check:String = null;
vehicle:VehicleAttachModel;
orderID:Number;
cancelMessageModel:GenericResponseModel;
errorFlag:boolean = false;
  errorFlag1:boolean = false;
  errorMessage:String;
  currentYear:Date;
  checkCondition:boolean;
  vehicleRegLatitude:any;
vehicleRegLongtitude:any;
  constructor(private dashboardservice: DashboardService,private modalService: NgbModal,private ordertransactionservice: OrderTransactionService,private partnerservice:PartnersService,private route: ActivatedRoute,private router: Router,private userservice:UserService,private vehicleservice:VehicleFuelService) { }

  ngOnInit(): void {
    this.dashboardservice.view().subscribe(data=>{
      
      if(data.response.orderList.length==0){
        this.checkCondition = true;
      }else{
        this.checkCondition = false;
      }
      this.dashboardDetails = data;
    });

   this.checkCondition = true;
    this.dashboardservice.getFuelDelivery((new Date()).getFullYear()).subscribe(data=>{
      this.fuelDeliverModel = data;
      this.monthArray = data.response.month;
      this.quantityArray = data.response.quantitys;
      let data1 = [{
        data: data.response.quantitys
     }];
     let  highcharts = Highcharts;  
     
     this.fuelQuantityGrph(data.response.month,data1);
    });
    
    this.dashboardservice.getFuelStock().subscribe(data=>{
      this.fuelStockModel = data;

      new Chart(document.getElementById("chartEmail"), {
      // this.canvas = document.getElementById("chartEmail");
      // this.ctx = this.canvas.getContext("2d");
      // this.chartEmail = new Chart(this.ctx, {
        type: 'pie',
        data: {
          labels: ["Total Refill", "Available Fuel", "Delivered Fuel"],
          datasets: [{
            label: "Fuel Stock",
            backgroundColor: [
              '#e3e3e3',
              '#4acccd',
              '#ef8157'
            ],
            data: data.response.stock
          }]
        },

        options: {
          legend: {
            display: false
          },
          pieceLabel: {
            render: 'percentage',
            fontColor: ['white'],
            precision: 2
          },

          tooltips: {
            enabled: true
          },

          scales: {
            yAxes: [{

              ticks: {
                display: false
              },
              gridLines: {
                drawBorder: false,
                zeroLineColor: "transparent",
                color: 'rgba(255,255,255,0.05)'
              }

            }],

            xAxes: [{
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: 'rgba(255,255,255,0.1)',
                zeroLineColor: "transparent"
              },
              ticks: {
                display: false,
              }
            }]
          },
        }
      });
    });
      
      this.dashboardservice.getOrderStatistics((new Date()).getFullYear()).subscribe(data=>{
          this.orderStackModel = data;
          var speedCanvas = document.getElementById("speedChart");

      var dataFirst = {
        data: data.response.totalOrder,
        fill: true,
        borderColor: '#17a2b8',
        backgroundColor: '#17a2b8',
        pointBorderColor: '#17a2b8',
        pointRadius: 4,
        pointHoverRadius: 4,
        pointBorderWidth: 8,
      };  

      var dataSecond = {
        data: data.response.completedOrder,
        fill: true,
        borderColor: '#32CD32',
        backgroundColor: '#32CD32',
        pointBorderColor: '#32CD32',
        pointRadius: 4,
        pointHoverRadius: 4,
        pointBorderWidth: 8
      };

      var dataThird = {
        data: data.response.pendingOrder,
        fill: true,
        borderColor: '#fd7e14',
        backgroundColor: '#fd7e14',
        pointBorderColor: '#fd7e14',
        pointRadius: 4,
        pointHoverRadius: 4,
        pointBorderWidth: 8
      };

      var dataFourth = {
        data: data.response.inprogressOrder,
        fill: true,
        borderColor: '#0000FF',
        backgroundColor: '#0000FF',
        pointBorderColor: '#0000FF',
        pointRadius: 4,
        pointHoverRadius: 4,
        pointBorderWidth: 8
      };

      var dataFive = {
        data: data.response.cancelledOrder,
        fill: true,
        borderColor: '#FF0000',
        backgroundColor: '#FF0000',
        pointBorderColor: '#FF0000',
        pointRadius: 4,
        pointHoverRadius: 4,
        pointBorderWidth: 8
      };

      var speedData = {
        labels: data.response.month,
        datasets: [dataFirst, dataSecond,dataThird,dataFourth,dataFive]
      };

      var chartOptions = {
        legend: {  
          display: false  
        },  
        scales: {  
          xAxes: [{  
            display: true
          }],  
          yAxes: [{  
            display: true,
            ticks: {
              beginAtZero: true
            }
          }],  
        }  
      };

      var lineChart = new Chart(speedCanvas, {
        type: 'bar',
        hover: false,
        data: speedData,
        options: chartOptions
      });
      });
  }  

  open(content,id) {
    this.ordertransactionservice.getOrderById(id).subscribe(data=>{
      this.orderDetails=data;
    });

    this.ordertransactionservice.tracking(id).subscribe(data=>{
      this.orderTrackDetails = data;
    });

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
    
      open1(content1,id) {
    
        this.orderId = id;
        this.partnerservice.getAllActivepartner().subscribe(data=>{
          this.activePartner = data;
        });

        this.ordertransactionservice.getActivatevehicle().subscribe(data=>{
          this.activeVehicle = data;
          this.activevehi = this.activeVehicle.response[0];
        });
    
        this.partnerservice.getOrderById(id).subscribe(data=>{
          this.vehicleModel = data;
          this.vehicleRegLatitude = this.vehicleModel.vehicleId.vehicleRegLatitude;
          this.vehicleRegLongtitude = this.vehicleModel.vehicleId.vehicleRegLongtitude;
        });
    
        this.ordertransactionservice.getOrderById(id).subscribe(data=>{
          this.orderDetails=data;
        });

        this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
          }

          private getDismissReason(reason: any): string {
            if (reason === ModalDismissReasons.ESC) {
              return 'by pressing ESC';
            } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
              return 'by clicking on a backdrop';
            } else {
              return `with: ${reason}`;
            }
          }

          assign(){
            let id = (<HTMLInputElement>document.getElementById('countryFormControl')).value;
            let latitude = this.vehicleRegLatitude;
            let langitude = this.vehicleRegLongtitude;
            let assign = new OrderAssignModel(Number(id),sessionStorage.getItem('username'),latitude,langitude);
            this.ordertransactionservice.assignto(this.orderId,assign).subscribe(data=>{
              Swal.fire({
                html: '<pre>' + data.responseMessage + '</pre>',
                customClass: {
                  popup: 'format-pre'
                }
              }).then((value) => {
                window.location.reload();
              });; ; 
            });
          }
          onOptionsSelected(value:Number){
            this.vehicleservice.getVehicleById(value).subscribe(data=>{
              this.vehicle = data;
              this.check = data.vehicleName;
            },err => {
              if(err.error.statusCode==406){
                this.router.navigate(['/login']);
              }
           });
          }

          invoice(id){
            sessionStorage.setItem('invoice',id);
              const url = this.router.serializeUrl(
                this.router.createUrlTree(['/invoice-generation'])
              );
              window.open(url, '_blank');
          }

          cancelPopup(content5,id){
            this.orderID = id;
            this.ordertransactionservice.cancellmessage().subscribe(data=>{
              this.cancelMessageModel = data;
            });
            this.modalService.open(content5, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
          }

          cancelOrder(){
            let desc = (<HTMLInputElement>document.getElementById('cancelremarks')).value;
            let cancelmessage = (<HTMLInputElement>document.getElementById('cancelmessage')).value;
            if(desc!=null && desc!==''){
              this.errorFlag1 = false;
              let formData= {
                userId:Number(sessionStorage.getItem('id')),
                cancellationId:cancelmessage,
                orderRemarks:desc,
              }
              this.orderID
              this.ordertransactionservice.cancelorder(this.orderID,formData).subscribe(data=>{
                if(data.statusCode==200){
                  Swal.fire({
                    html: '<pre>' + data.responseMessage + '</pre>',
                    customClass: {
                      popup: 'format-pre'
                    }
                  }).then((value) => {
                    window.location.reload();
                  });; ; 
                }else{
                  Swal.fire({
                    html: '<pre>' + data.responseMessage + '</pre>',
                    customClass: {
                      popup: 'format-pre'
                    }
                  }).then((value) => {
                    window.location.reload();
                  });; ; 
                }
              });
              this.modalService.dismissAll();
            }else{
              this.errorFlag1 = true;
              this.errorMessage = "Please enter remarks!";
              setTimeout(function() {
                this.errorFlag1 = false;
            }.bind(this), 3000);
        
            }
          }

          public fuelQuantityGrph(month,quantity){
            this.chartOptions = {   
              chart: {
              type: "area"
            },
            title: {
            text: "FUEL DELIVERY"
              },
              subtitle: {
                text: 'Quantity in Ltrs Delivered Successfully'
            },
              xAxis:{
            categories:month,
              },
              yAxis: {          
            title:{
              text:"Quantity"
            } 
              },
              legend: {
            enabled: false
              },
              plotOptions: {
              series: {
                borderWidth: 0,
                dataLabels: {
              enabled: true,
          }
      }
      },
            tooltip: {
            headerFormat: '<span style="font-size:11px">Quantity</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b> <br/>'
            },
            series: quantity
            }; 
          }
                   
}
