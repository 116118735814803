import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GenericResponseModel } from '../model/genricresponse';
import { KycMasterModel } from '../model/kycmaster';
import { GenericResponse } from '../model/genricmodelresponse';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: "root"
  })
  export class CouponService {

    constructor(private httpClient:HttpClient) {}

  private baseUrl = environment.devurl;
  private auth = environment.headers;

    public getAllCoupons(){
      // const headers = this.auth;
      let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
      const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/coupon/view",{headers});
  }

  public addcoupon(coupon){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.post<GenericResponseModel>(this.baseUrl+"/coupon/addcoupon",coupon,{headers});
  }

  public update(id,coupon){
   // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.put<GenericResponseModel>(this.baseUrl+"/coupon/update"+"/"+id,coupon,{headers});
  }

  public deleteCoupon(id){
   // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/coupon/delete"+"/"+id,{headers});
  }

  }