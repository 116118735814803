import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericResponseModel } from '../model/genricresponse';
import { UserModel } from '../model/user';
import { PartnersService } from '../services/partners.service';

@Component({
  selector: 'app-partners-kyc',
  templateUrl: './partners-kyc.component.html',
  styleUrls: ['./partners-kyc.component.css']
})
export class PartnersKycComponent implements OnInit {

  kycdocumentsmodel : GenericResponseModel;
  constructor(private route: ActivatedRoute,private partnerservice: PartnersService,private router: Router) { }

  ngOnInit(): void {
    this.partnerservice.getAllPartnersKyc().subscribe(data=>{
      this.kycdocumentsmodel = data;
    },err => {
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }else if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });
  }

  viewKyc(user,id){
    sessionStorage.setItem("kyc",String(user));
    this.router.navigate(['/partners-kyc-vehicle-view']); 
  }

}
