import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { GenericResponseModel } from '../model/genricresponse';
import { OrderTransactionService } from '../services/ordertran.service';
import { ReportServices } from '../services/reports.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { Workbook } from 'exceljs';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-report-stock',
  templateUrl: './report-stock.component.html',
  styleUrls: ['./report-stock.component.css']
})
export class ReportStockComponent implements OnInit {
  symbole:String; 
 public isCollapsed = false;
 active = 1;
 transactionDetail:GenericResponseModel;
 transactionfilterDetail:GenericResponseModel;
 settlementDetail:GenericResponseModel;
 settlementViewDetail:GenericResponseModel;
closeResult = '';
  errorFlag:boolean;
  errorMessage:String;
  refreshflag:boolean;
  responseDataList = [];
  revenuereportModel:GenericResponseModel;
  revenuefilterreportModel:GenericResponseModel;
  filterrevenue:boolean=false;
  normalrevenue:boolean=true;
  excelexportcheck : boolean = false;
  responseDataListnew = [];
  vehicleFuelStockModel:GenericResponseModel;

  constructor(private modalService: NgbModal,private ordertransactionservice: OrderTransactionService,private route: ActivatedRoute,private router: Router,private reportservice:ReportServices,private spinnerService:NgxSpinnerService) { }

  ngOnInit(): void {
     this.reportservice.getFuelStockReport().subscribe(data=>{
       if(data.statusCode==200){
        this.excelexportcheck = true;
       }else{
        this.excelexportcheck = false;
       }
      this.vehicleFuelStockModel = data;
     },err => {
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }else if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });
  }

  search(){
    this.spinnerService.show();
    this.refreshflag = true;
    let fromdate = (<HTMLInputElement>document.getElementById('fromdate')).value;
    let todate = (<HTMLInputElement>document.getElementById('todate')).value;
    var stdate = fromdate.split("-");
    var endate = todate.split("-");
    var st = new Date(Number(stdate[2]),Number(stdate[1]),Number(stdate[0]));
    var en = new Date(Number(endate[2]),Number(endate[1]),Number(endate[0]));
    if(todate < fromdate){
      this.errorFlag = true;
      this.errorMessage = "End Date should be greater than Start Date!";
      this.spinnerService.hide();
    }else if(fromdate!=null && fromdate!=='' && todate!=null && todate!==''){
      this.errorFlag = false;
    }else{
      this.errorFlag = true;
      this.errorMessage = "Please select Dates!";
      this.spinnerService.hide();
    }
  }

  refresh(){
    window.location.reload();
  }

  exportToExcel(){
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let sno = 1;
    this.vehicleFuelStockModel.response.forEach(element => {
      let vehicle = element['vehicleId'];
      let partner = vehicle['userId'];
      let fuel    = vehicle['fuelType']; 
      var response = [];
      response.push(sno);
      response.push(vehicle['vehicleNumber']);
      response.push(partner['firstName'] + partner['lastName']);
      response.push(partner['mobileNumber']);
      response.push(vehicle['vehicleName']);
      response.push(fuel['fuelTypeName']);
      response.push(vehicle['tankCapacity']);
      response.push(element['fuelStock']);
      response.push(element['stockHold']);
      response.push(element['dispenseFuel']);
      response.push(element['availableltrs']);
      this.responseDataListnew.push(response);
      sno++;
    });
    this.excelexport();
  }
  excelexport(){
    const title = 'Fuel Stock Report';
    const header = ["S.No","Vehicle Number", "Partner Name", "Partner Mobile", "Model Name","Fuel","Tank Capacity(Ltrs)","Total Refill(Ltrs)","Hold(Ltrs)","Delivered(LTrs)","Available (Ltrs)"]
    const data = this.responseDataListnew;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Fuel Stock Report');
// Add new row
let titleRow = worksheet.addRow([title]);
// Set font, size and style in title row.
titleRow.font = { name: 'Times New Roman', family: 4, size: 16, bold: true };

// Blank Row
worksheet.addRow([]);

//Add row with current date
// let subTitleRow = worksheet.addRow(['Date : ' + this.datePipe.transform(new Date(), 'medium')]);
//Add Header Row
let headerRow = worksheet.addRow(header);
headerRow.font = {bold: true };
// Cell Style : Fill and Border
headerRow.eachCell((cell, number) => {
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFFFF' },
    bgColor: { argb: 'FF0000FF' },
  }
  cell.border = {  top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
});

data.forEach(d => {
  let row = worksheet.addRow(d);
  let qty = row.getCell(1);
  let qty1 = row.getCell(2);
  let qty2 = row.getCell(3);
  let qty3 = row.getCell(4);
  let qty4 = row.getCell(5);
  let qty5 = row.getCell(6);
  let qty6 = row.getCell(7);
  let qty7 = row.getCell(8);
  let qty8 = row.getCell(9);
  let qty9 = row.getCell(10);
  let qty10 = row.getCell(11);
  qty.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty1.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty2.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty3.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty4.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty5.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty6.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty8.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty9.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty10.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
}
);
workbook.xlsx.writeBuffer().then((data) => {
  let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  FileSaver.saveAs(blob, 'Fuel Stock Report.xlsx');
});
   }
}
