import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { FuelTypeModel } from '../model/fueltype';
import { GenericResponseModel } from '../model/genricresponse';
import { MarketPrizeModel } from '../model/marketprize';
import { FuelRateMasterService } from '../services/fuelratemaster.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router } from '@angular/router';

@Component({
  selector: 'app-master-fuel-rate',
  templateUrl: './master-fuel-rate.component.html',
  styleUrls: ['./master-fuel-rate.component.css']
})
export class MasterFuelRateComponent {
 

 public isCollapsed = false;
 active = 1;

closeResult = '';
  marketPrizeModel:MarketPrizeModel;
  marketPrizeDetails:GenericResponseModel;
  fuelTypesModel:FuelTypeModel[];
  errorFlag:boolean;
  errorMessage:String;
  successflag:boolean;
  message:String;
  deleteUserId:Number;

  constructor(private modalService: NgbModal,private fuelrateservice:FuelRateMasterService,private spinnerService:NgxSpinnerService,private router:Router) {}

  ngOnInit(): void {

    let id = Number(sessionStorage.getItem("vehicleId"));

    this.fuelrateservice.getFuelTypes().subscribe(data=>{
      this.fuelTypesModel = data;
    },err => {
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }else if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });

    this.fuelrateservice.getAllMarketPriceByVehicle(id).subscribe(data=>{
      this.marketPrizeDetails = data;
    },err => {
      console.log(err.error.statusCode);
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }
   });

    this.fuelrateservice.getCurrentFuelRate(id).subscribe(data=>{
      this.marketPrizeModel= data;
    },err => {
      console.log(err.error.statusCode);
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }
   });
  }

  open1(reject,viewId){
    this.deleteUserId = viewId;
    this.modalService.open(reject, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  delete(){
    this.spinnerService.show();
    let userId = this.deleteUserId;
    this.fuelrateservice.deleteMarket(userId).subscribe(data=>{
      if(data.statusCode==200){
        this.spinnerService.hide();
        Swal.fire({
          html: '<pre>' + data.responseMessage + '</pre>',
          customClass: {
            popup: 'format-pre'
          }
        }).then((value) => {
          window.location.reload();
        });; ;
      }
    },err => {
      if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });
  }

  addMarket(){
    let effective = (<HTMLInputElement>document.getElementById('effedate')).value;
    if(effective==''){
      this.errorFlag= true;
      this.errorMessage = "Please Enter Effective Date!";
      setTimeout(function() {
        this.errorFlag = false;
    }.bind(this), 3000);
    }else if(this.marketPrizeModel.price!=null && this.marketPrizeModel.price!==""){
      let d = effective.concat(":00");
     let currentDate=new Date(d);
      let formData= {
        partnerVehicleId:this.marketPrizeModel.vehicleId.vehicleId,
        partnerVehicleIdStatus:this.marketPrizeModel.vehicleId.vehicleStatus,
        price:this.marketPrizeModel.price,
        fuelType:this.marketPrizeModel.fuelType,
        submittedBy:sessionStorage.getItem("username"),
        modifiedBy:sessionStorage.getItem("username"),
        effectiveDatetime:currentDate.getTime()
      }
      this.fuelrateservice.addMarket(formData).subscribe(data=>{
        if(data.flag==1){
          this.modalService.dismissAll();
          Swal.fire({
            html: '<pre>' + data.message + '</pre>',
            customClass: {
              popup: 'format-pre'
            }
          }).then((value) => {
            window.location.reload();
          });; ; 
        }else if(data.flag==3){
      this.errorFlag= true;
      this.errorMessage = data.message;
      setTimeout(function() {
        this.errorFlag = false;
    }.bind(this), 3000); 
        }
      },err => {
        if(err.error.statusCode==406){
          this.router.navigate(['/login']);
        }else if(err.error.statusCode==500){
          this.router.navigate(['/page-error']);
        }else if(err.error.statusCode==404){
          this.router.navigate(['/pagenotfound']);
        }
     });
    }else{
      this.errorFlag= true;
      this.errorMessage = "Please Enter Fuel Prize!";
      setTimeout(function() {
        this.errorFlag = false;
    }.bind(this), 3000);
    }
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  allowNumericDigitsOnlyOnKeyUp(e) {		
    const charCode = e.which ? e.which : e.keyCode;
    if(charCode==46){
      return true;
    }else if(charCode > 31 && (charCode < 48 || charCode > 57)){
      if(charCode==46){
        return true;
      }else{
        e.preventDefault();
      }
    }else{
      return true;
    }
	}

}


