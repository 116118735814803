<app-header></app-header>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
</ngx-spinner>
<div class="container  container-top-dashboard-section" style="margin-top: 110px">
  <a href="#" routerLink="/partners" class="back_page"><span class="label label-info"><i class="back_page"></i>Back</span></a>
   <br>
   <div class="alert alert-success box-msg" role="alert"
            *ngIf="successflag">{{message}}</div>
   <div class="card mb-3" style="margin-top: 20px; " #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
   <div class="flex">
      <div class="col1 flex">
         <div class=" profile images flex">
            <img *ngIf="!isImageLoadingpartner" src="assets/img/profile.jpg" alt="logo" data-src="assets/img/profile.jpg" data-src-retina="assets/img/profile.jpg" width="150px" >
            <img *ngIf="isImageLoadingpartner" class="modal-content1"  [src]="imageToShowpartner">
         </div>
         <div class="profile-info flex">
            <div class="card-body profile">
              <p class="card-title">Partner Name <br><span style="word-wrap: break-word;"><b>{{userModel.firstName}} {{userModel.lastName}}</b></span></p>
              <p class="card-title">Rating<br>
                <span *ngIf="ratingStar===0" class="v-align-middle ht-ratting"><span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></span>
                <span *ngIf="ratingStar===1" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></span>
                <span *ngIf="ratingStar===1.5" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-half-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></span>
                <span *ngIf="ratingStar===2" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></span>
                <span *ngIf="ratingStar===2.5" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-half-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></span>
                <span *ngIf="ratingStar===3" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></span>
                <span *ngIf="ratingStar===3.5" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-half-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></span>
                <span *ngIf="ratingStar===4" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></span>
                <span *ngIf="ratingStar===4.5" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-half-o" aria-hidden="true"></i> </span></span>
                <span *ngIf="ratingStar===5" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span></span>
              </p>
              <p class="card-title" *ngIf="userModel.gstNumber!=null">GST Number <br><span style="word-wrap: break-word;"><b>{{userModel.gstNumber}}</b></span></p>
              <p class="card-title" *ngIf="userModel.gstNumber==null">GST Number <br><span style="word-wrap: break-word;"><b >N/A</b></span></p>
              <p class="card-title">Partner ID  <br>
                <span *ngIf="userModel.userCode!=null"><b>{{userModel.userCode}}</b></span>
                <span *ngIf="userModel.userCode==null"><b>N/A</b></span>
              </p>
            </div>
             <div class="card-body profile">
               <p class="card-title"><i class="fa fa-mobile" aria-hidden="true"></i> Mobile
                  <br><span><b>{{userModel.mobileNumber}}</b></span>
               </p>
               <p class="card-title"><i class="fa fa-envelope" aria-hidden="true"></i> Email
                  <br><span><b>{{userModel.emailAddress}}</b></span>
               </p>
<p class="card-title"><i class="fa fa-location-arrow" aria-hidden="true"></i>
Address for Communication
                  <br>
                  <span *ngIf="userModel.address!=null" style="word-wrap: break-word;"><b>{{userModel.address}}</b></span>
                  <span *ngIf="userModel.address==null"><b>N/A</b></span>
               </p>
            </div>
            <div class="card-body profile">
               <p class="card-title">Onboarded Date & Time  <br><span><b>{{userModel.createdDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</b></span></p>
               <p class="card-title">OTP verification status <br>
               <span *ngIf="userModel.otpVerificationStatus===1" class="v-align-middle ht-Success"><b>Success</b></span>
                <span *ngIf="userModel.otpVerificationStatus===0" class="v-align-middle ht-Pending"><b>Pending</b></span>
                </p>
                <p class="card-title">Business Category<br>
                  <span *ngIf="userModel.businessCategoryId!=null" ><b>{{userModel.businessCategoryId.businessCategoryName}}</b></span>
                   <span *ngIf="userModel.businessCategoryId==null" ><b>N/A</b></span>
                   </p>
            </div>
            <div class="card-body profile">
               <p>Account Status</p>
               <div *ngIf="userModel.accountStatus===1">
                <div class="form-check no-pad-left">
                  <input class="form-check-input" type="radio" name="exampleRadios" (click)="callActiveOrInactive(1)" id="exampleRadios1" value="option1" checked>
                  <label class="form-check-label" for="exampleRadios1">
                  Active
                  </label>
               </div>
               <div class="form-check no-pad-left">
                  <input class="form-check-input" type="radio" name="exampleRadios" (click)="callActiveOrInactive(2)" id="exampleRadios2" value="option2">
                  <label class="form-check-label" for="exampleRadios2">
                  Inactive
                  </label>
               </div>
               </div>
               <div *ngIf="userModel.accountStatus===2">
                <div class="form-check no-pad-left">
                  <input class="form-check-input" type="radio" name="exampleRadios" (click)="callActiveOrInactive(1)" id="exampleRadios1" value="option1">
                  <label class="form-check-label" for="exampleRadios1">
                  Active
                  </label>
               </div>
               <div class="form-check no-pad-left">
                  <input class="form-check-input" type="radio" name="exampleRadios" (click)="callActiveOrInactive(2)" id="exampleRadios2" value="option2" checked>
                  <label class="form-check-label" for="exampleRadios2">
                  Inactive
                  </label>
               </div>
               </div>
               <p class="card-title">Business Type<br>
                <span *ngIf="userModel.businessTypeId!=null" ><b>{{userModel.businessTypeId.typesOfBusiness}}</b></span>
                 <span *ngIf="userModel.businessTypeId==null" ><b>N/A</b></span>
                 </p>
                 <p class="card-title">Failed Status<br>
                  <span><b>{{failedStatus}}</b></span>
                </p>
            </div>
            <div class="card-body profile">
              <p class="card-title">Fire Base Enable   <br>
                <span *ngIf="userModel.firebaseId!=null"><i class="fa fa-check" aria-hidden="true"></i></span>
                <span *ngIf="userModel.firebaseId==null"><i class="fa fa-times" aria-hidden="true"></i></span>
              </p>
              <p class="card-title">IP Address <br>
                <span *ngIf="userModel.useripaddress!=null"><b>{{userModel.useripaddress}}</b></span>
                <span *ngIf="userModel.useripaddress==null"><b>N/A</b></span>
              </p>
              <p class="card-title">Current IP Address <br>
                <span *ngIf="userModel.currentIpaddress!=null"><b>{{userModel.currentIpaddress}}</b></span>
                <span *ngIf="userModel.currentIpaddress==null"><b>N/A</b></span>
              </p>
              <p class="card-title">Failed Count<br>
                <span>{{failedCount}}</span>
                <span style="margin-left: 10px;" *ngIf="failedCount==6 || failedCount>=6">
                  <button title="Unblock" class="label label-info" (click)="open10(reject,userModel.userId)" ><i class="fa fa-unlock" aria-hidden="true"></i></button>
                </span>
              </p>
            </div>
            <div class="card-body profile">

              <button title="Add Payment Methods" class="label label-info" (click)="create(content9,userModel.userId)" >Add Payment Method</button>

            </div>
            <!-- <div class="card-body profile">
              <p class="card-title">Device Name <br>
               <span *ngIf="userModel.userdevicename!=null"><b>{{userModel.userdevicename}}</b></span>
               <span *ngIf="userModel.userdevicename==null"><b>N/A</b></span>
             </p>
             <p class="card-title">Device Model <br>
              <span *ngIf="userModel.userdevicemodel!=null"><b>{{userModel.userdevicemodel}}</b></span>
              <span *ngIf="userModel.userdevicemodel==null"><b>N/A</b></span>
            </p>
            <p class="card-title">Device Type <br>
              <span *ngIf="userModel.userdevicetype!=null"><b>{{userModel.userdevicetype}}</b></span>
              <span *ngIf="userModel.userdevicetype==null"><b>N/A</b></span>
            </p>
            <p class="card-title">Device Number <br>
              <span *ngIf="userModel.userdevicenumber!=null"><b>{{userModel.userdevicenumber}}</b></span>
              <span *ngIf="userModel.userdevicenumber==null"><b>N/A</b></span>
            </p>
           </div> -->
         </div>
      </div>
   </div>
</div>

<div style="display: none;" *ngIf="customerOrderModel.responseMessage"></div>

<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
  <li [ngbNavItem]="1">
    <a ngbNavLink>Orders</a>
    <ng-template ngbNavContent>
      <div class="card-body">
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th align="center" style="width:80px; text-align: center;">Sl.No.</th>
                      <th style="text-align: left;"  >Order ID </th>
                      <th style="text-align: left;" >Customer Name</th>
                      <th style="text-align: left;" >Vehicle number</th>
                      <th style="text-align: left;">Fuel</th>
                      <th style="text-align: center;">Quantity in Ltrs</th>
                      <th style="text-align: left;">Booked Date Time </th>
                      <th style="text-align: left;">Delivery Date Time</th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: center;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let order of customerOrderModel.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td align="left" class="v-align-middle">{{order.orderId}}</td>
                      <td *ngIf="order.customerId!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.customerId.firstName}} {{order.customerId.lastName}}</td>
                      <td *ngIf="order.customerId==null" align="left" class="v-align-middle">N/A</td>
                      <td align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
                      <td align="left" class="v-align-middle">{{order.fuelType}}</td>
                      <td align="center" class="v-align-middle">{{order.quantity}}</td>
                      <td *ngIf="order.orderDatetime!=null"align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                        <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                        <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                        <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                      <td align="left" class="v-align-middle">
                        <button title="View" class="label label-info" (click)="open(content,order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                        <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.status==='Pending' "class="label label-info" (click)="open6(content8,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                        <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.status==='Pending' || order.status==='Inprogress'" class="label label-info" (click)="cancelPopup(content7,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                          <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered' && order.partnerFilename!=null" (click)="invoice(order.id)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
                          <button title="Download Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered' && order.partnerFilename!=null" (click)="download(order.id,order.partnerFilename)" class="label label-success" ><i class="fa fa-download" aria-hidden="true"></i></button>
                          <button title="Send Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered' && order.partnerFilename!=null" (click)="sendMail(order.id)" class="label label-success" ><i class="fa fa-envelope" aria-hidden="true"></i></button>

                        </td>
                    </tr>
                  </tbody>
                </table>
          </div>
        </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink> Agents </a>
    <ng-template ngbNavContent>
      <div class="card-body">
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr>  
                       <th style="text-align: center;">Sl.No.</th>
                      <th style="width: 5px;text-align: left" >Agent Name</th>
                      <th style="text-align: left;"  >Mobile Number</th>
                      <th style="text-align: left;"  >Email Address</th>
                      <th style="text-align: left;"  >Vehicle Number</th>
                      <th style="text-align: center;" >Vehicle Status</th>
                      <th style="text-align: left;" >License Number</th>
                      <th style="text-align: left;"  >License Expiry Date</th>
                      <th style="text-align: left;" >Created Date Time</th>
                      <th style="text-align: center;" >Status</th>
                      <th style="text-align: center;" >Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let agant of agentModel.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="agant.firstName!=null"align="left" style="word-wrap: break-word;" class="v-align-middle">{{agant.firstName}} {{agant.lastName}}</td>
                      <td *ngIf="agant.firstName==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="agant.mobileNumber!=null"align="left" class="v-align-middle">{{agant.mobileNumber}}</td>
                      <td *ngIf="agant.mobileNumber==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="agant.emailAddress!=null"align="left" class="v-align-middle">{{agant.emailAddress}}</td>
                      <td *ngIf="agant.emailAddress==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="agant.agentVehicleMapping[0]!=null && agant.agentVehicleMapping[0].vehicleId.vehicleNumber!=null"align="left" class="v-align-middle">{{agant.agentVehicleMapping[0].vehicleId.vehicleNumber}}</td>
                      <td *ngIf="agant.agentVehicleMapping[0]==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="agant.agentVehicleMapping[0]!=null && agant.agentVehicleMapping[0].vehicleId.vehicleonoffStatus!=null && agant.agentVehicleMapping[0].vehicleId.vehicleonoffStatus===true"align="center" class="v-align-middle ht-Success">Active</td>
                      <td *ngIf="agant.agentVehicleMapping[0]!=null && agant.agentVehicleMapping[0].vehicleId.vehicleonoffStatus!=null && agant.agentVehicleMapping[0].vehicleId.vehicleonoffStatus===false"align="center" class="v-align-middle ht-Pending">InActive</td>
                      <td *ngIf="agant.agentVehicleMapping[0]==null" align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="agant.agentVehicleMapping[0]!=null && agant.agentVehicleMapping[0].licenceNo!=null"align="left" class="v-align-middle">{{agant.agentVehicleMapping[0].licenceNo}}</td>
                      <td *ngIf="agant.agentVehicleMapping[0]==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="agant.agentVehicleMapping[0]!=null && agant.agentVehicleMapping[0].licenceExpiryDate!=null"align="left" class="v-align-middle">{{agant.agentVehicleMapping[0].licenceExpiryDate | date:'dd/MM/yyyy'}}</td>
                      <td *ngIf="agant.agentVehicleMapping[0]==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="agant.agentVehicleMapping[0]!=null && agant.agentVehicleMapping[0].createdDateTime!=null"align="left" class="v-align-middle">{{agant.agentVehicleMapping[0].createdDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="agant.agentVehicleMapping[0]==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="agant.accountStatus!=null && agant.accountStatus===1"align="center" class="v-align-middle ht-Success">Active</td>
                      <td *ngIf="agant.accountStatus!=null && agant.accountStatus===2"align="center" class="v-align-middle ht-Pending">InActive</td>
                      <td *ngIf="agant.accountStatus==null && agant.accountStatus===2"align="center" class="v-align-middle ht-Pending">N/A</td>
                      <td align="center" class="v-align-middle"><button class="label label-info" (click)="open4(content4,agant.userId)" ><i class="fa fa-eye" aria-hidden="true"></i></button></td>
                    </tr>
                  </tbody>
                </table>
          </div>
        </div>
    </ng-template>
  </li>

  <tr style="display: none;" *ngFor="let vehicle of vehicleAttachModel; let i=index">
    <td align="center" class="v-align-middle ">{{i + 1}}</td>
    <td *ngIf="vehicle.vehicleNumber!=null"align="left" class="v-align-middle">{{vehicle.vehicleNumber}}</td>
  </tr>

  <li [ngbNavItem]="3">
    <a ngbNavLink>Vehicles</a>
    <ng-template ngbNavContent>
      <div class="card-body">
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Vehicle No.</th>
                      <th style="text-align: left;"><span>Vehicle</span> Name</th>
                      <th style="text-align: center;">Owner Name</th>
                      <th style="text-align: center;">Tank Capacity (Ltrs)</th>
                      <th style="text-align: left;">Fuel Type</th>
                      <th style="text-align: left;">Vehicle Insurance No</th>
                      <th style="text-align: left;">AMC/MMC Type</th>
                      <!-- <th align="center">Payment Mode</th> -->
                      <th style="text-align: left;">Insurance Expiry Date</th>
                      <th style="text-align: left;">Created Date Time</th>
                      <th style="text-align: left;">DSA name</th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: center;">Action</th>
                    </tr>
                  </thead>
                  <tbody> 
                    <tr *ngFor="let vehicle of vehicleAttachModel; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="vehicle.vehicleNumber!=null"align="left" class="v-align-middle">{{vehicle.vehicleNumber}}</td>
                      <td *ngIf="vehicle.vehicleNumber==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.vehicleName!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{vehicle.vehicleName}}</td>
                      <td *ngIf="vehicle.vehicleName==null" align="left" class="v-align-middle">N/A</td>  
                     
                      <td *ngIf="vehicle.vehicleOwnerName!=null"align="left" class="v-align-middle" >{{vehicle.vehicleOwnerName}}</td>
                      <td *ngIf="vehicle.vehicleOwnerName==null"align="left" class="v-align-middle" >N/A</td>
                      
                      <td *ngIf="vehicle.tankCapacity!=null"align="center" class="v-align-middle">{{vehicle.tankCapacity}}</td>
                      <td *ngIf="vehicle.tankCapacity==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.fuelType!=null" align="left" class="v-align-middle">{{vehicle.fuelType.fuelTypeName}}</td>
                      <td *ngIf="vehicle.fuelType==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.vehicleInsuranceNumber!=null"align="left" class="v-align-middle">{{vehicle.vehicleInsuranceNumber}}</td>
                      <td *ngIf="vehicle.vehicleInsuranceNumber==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.amcMmcType!=null"align="left" class="v-align-middle">{{vehicle.amcMmcType}}</td>
                      <td *ngIf="vehicle.amcMmcType==null"align="left" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="vehicle.paymentMode!=null"align="center" class="v-align-middle">{{vehicle.paymentMode}}</td>
                      <td *ngIf="vehicle.paymentMode==null"align="center" class="v-align-middle">N/A</td> -->
                      <td *ngIf="vehicle.insuranceExpiryDate!=null"align="left" class="v-align-middle">{{vehicle.insuranceExpiryDate | date:'dd/MM/yyyy' }}</td>
                      <td *ngIf="vehicle.insuranceExpiryDate==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.submittedDatetime!=null"align="left" class="v-align-middle">{{vehicle.submittedDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                      <td *ngIf="vehicle.submittedDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.assignDSA!=null"align="left" class="v-align-middle">{{vehicle.assignDSA.firstName}} {{vehicle.assignDSA.lastName}}</td>
                      <td *ngIf="vehicle.assignDSA==null"align="left" class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="vehicle.vehicleStatus==3"class="v-align-middle ht-active">Active</td>
                      <td align="center" *ngIf="vehicle.vehicleStatus==4"class="v-align-middle ht-inactive">InActive</td>
                      <td  align="center" class="v-align-middle"><button class="label label-info" (click)="open1(content1,vehicle.vehicleId)" ><i class="fa fa-eye" aria-hidden="true"></i></button></td>
                    </tr>
                  </tbody>
                </table>
          </div>
        </div>
    </ng-template>
  </li>

  <tr style="display: none;" *ngFor="let fuel of fuelRefillHistoryDetails; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="fuel.vehicleId!=null"align="left" class="v-align-middle">{{fuel.vehicleId.vehicleNumber}}</td>
                      </tr>

  <li [ngbNavItem]="4">
    <a ngbNavLink>Refill History </a>
    <ng-template ngbNavContent>
       <div class="card-body">
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Vehicle No.</th>
                      <th style="text-align: left;">Model Name</th>
                      <th style="text-align: left;">Fuel</th>
                      <th style="text-align: center;">Tank Capacity (Ltrs)</th>
                      <!-- <th style="text-align: center;">Available Fuel in Ltrs</th> -->
                      <th style="text-align: left;">Refilled Date Time </th>
                      <th style="text-align: center;">Refilled Vol in Ltrs</th>
                      <th style="text-align: right;">Paid Amount</th>
                      <th style="text-align: left;">Petrol Bunk Info</th>
                      <th style="text-align: left;">Payment Mode</th>
                      <th style="text-align: left;">Buy Rate</th>
                      <th style="text-align: left;">OMC  Detail</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let fuel of fuelRefillHistoryDetails; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="fuel.vehicleId!=null"align="left" class="v-align-middle">{{fuel.vehicleId.vehicleNumber}}</td>
                      <td *ngIf="fuel.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="fuel.vehicleId!=null" align="left" class="v-align-middle">{{fuel.vehicleId.vehicleName}}</td>
                      <td *ngIf="fuel.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="fuel.vehicleId!=null" align="left" class="v-align-middle">{{fuel.vehicleId.fuelType.fuelTypeName}}</td>
                      <td *ngIf="fuel.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="fuel.vehicleId!=null" align="center" class="v-align-middle">{{fuel.vehicleId.tankCapacity}}</td>
                      <td *ngIf="fuel.vehicleId==null"align="center" class="v-align-middle">N/A</td>
                      <!-- <td align="center" class="v-align-middle">-</td> -->
                      <td *ngIf="fuel.submittedDatetime!=null" align="left" class="v-align-middle">{{fuel.submittedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="fuel.submittedDatetime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="fuel.volumeInLtrs!=null"align="center" class="v-align-middle">{{fuel.volumeInLtrs}}</td>
                      <td *ngIf="fuel.volumeInLtrs==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="fuel.paidAmount!=null"align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{fuel.paidAmount | number : '1.2-2' }}</td>
                      <td *ngIf="fuel.paidAmount==null"align="right" class="v-align-middle">N/A</td>
                      <td *ngIf="fuel.petrolBunkInfo!=null"align="left" class="v-align-middle">{{fuel.petrolBunkInfo}}</td>
                      <td *ngIf="fuel.petrolBunkInfo==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="fuel.paymentMode!=null"align="left" class="v-align-middle">{{fuel.paymentMode}}</td>
                      <td *ngIf="fuel.paymentMode==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="fuel.buyRate!=null"align="left" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i>{{fuel.buyRate | number : '1.2-2'}}</td>
                      <td *ngIf="fuel.buyRate==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="fuel.omcId!=null"align="left" class="v-align-middle">{{fuel.omcId.omcName}}</td>
                      <td *ngIf="fuel.omcId==null"align="left" class="v-align-middle">N/A</td>
                    </tr>
                  </tbody>
                </table>
          </div>
        </div>
    </ng-template>
  </li>

  <li [ngbNavItem]="5">
    <a ngbNavLink>Transaction History </a>
    <div style="display: none;">{{transactionDetails.responseMessage}}</div>
    <ng-template ngbNavContent>
      <div class="card-body">
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;" >Sl.No.</th>
                      <th  style="text-align: left;" >Order ID </th>
                      <th  style="text-align: left;" >Payment Mode</th>
                      <th style="text-align: left;" >Transaction ID </th>
                      <th  style="text-align: left;">Transaction Date Time </th>
                      <th  style="text-align: left;">Fuel</th>
                      <th style="text-align: center;">Quantity in Ltrs</th>
                      <th  style="text-align: right;">Transaction Amount</th>
                      <th style="text-align: center;">Status</th>
                    </tr>
                  </thead>
                  <tbody> 
                    <tr *ngFor="let transaction of transactionDetails.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="transaction.orderId!=null" align="left" class="v-align-middle">{{transaction.orderId}}</td>
                      <td *ngIf="transaction.orderId==null" align="left" class="v-align-middle">N/A</td>
                     
                      <td *ngIf="transaction.paymentMode!=null"align="left" class="v-align-middle">{{transaction.paymentMode}}</td>                   
                      <td *ngIf="transaction.paymentMode==null"align="left" class="v-align-middle">N/A</td>

                      <td *ngIf="transaction.paymentId!=null"align="left" class="v-align-middle">{{transaction.paymentId}}</td>                   
                      <td *ngIf="transaction.paymentId==null"align="left" class="v-align-middle">N/A</td>

                      <td *ngIf="transaction.paymentDatetime!=null"align="left" class="v-align-middle">{{transaction.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.paymentDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.fuelType!=null" align="left" class="v-align-middle">{{transaction.fuelType}}</td>
                      <td *ngIf="transaction.fuelType==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.quantity!=null"align="center" class="v-align-middle">{{transaction.quantity}}</td>
                      <td *ngIf="transaction.quantity==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paidAmount!=null"align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.paidAmount | number : '1.2-2'}}</td>
                      <td *ngIf="transaction.paidAmount==null"align="right" class="v-align-middle">N/A</td>
                      <td align="center"  *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</td>
                      <td align="center"  *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' " style="color: red;">Failed</td>
                      <td align="center"  *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</td>
                      <td align="center"  *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' " style="color: blue;">{{transaction.paymentStatus}}</td>
                    </tr>
                  </tbody>
                </table>
          </div>
        </div>
    </ng-template>
  </li>

  <tr style="display: none;" *ngFor="let settlement of settlementDetails; let i=index">
    <td align="center" class="v-align-middle ">{{i + 1}}</td>
    <td *ngIf="settlement.orderId!=null"align="left" class="v-align-middle">{{settlement.orderId}}</td>
  </tr>

  <li [ngbNavItem]="6">
    <a ngbNavLink>Settlement History</a>
     <ng-template ngbNavContent>
      <div class="card-body">
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                        <th >Sl.No.</th>
                        <th >Partner Name</th>
                        <th >Vehicle Number</th>
                        <th >Bank Reference</th>
                        <th >Settlement Id</th>
                        <th >Settlement Status</th>
                        <th >Settlement Amount</th>
                        <th >UPI Id</th>
                        <th >Settlement Date Time</th>
                        <th >Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let settlement of settlementViewDetail.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="settlement.userId!=null && settlement.userId.firstName!=null" align="left" class="v-align-middle">{{settlement.userId.firstName}} {{settlement.userId.lastName}}</td>
                      <td *ngIf="settlement.userId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="settlement.vehicleId!=null && settlement.vehicleId.vehicleNumber!=null"align="left" class="v-align-middle">{{settlement.vehicleId.vehicleNumber}}</td>
                      <td *ngIf="settlement.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="settlement.bankReference!=null"align="left" class="v-align-middle">{{settlement.bankReference}}</td>
                      <td *ngIf="settlement.bankReference==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="settlement.settlementApi!=null"align="left" class="v-align-middle">{{settlement.settlementApi}}</td>
                      <td *ngIf="settlement.settlementApi==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="settlement.completed!=null && settlement.completed==='y' "align="left" class="v-align-middle ht-active">Completed</td>
                      <td *ngIf="settlement.completed!=null && settlement.completed==='n' "align="left" class="v-align-middle ht-Pending">Pending</td>
                      <td *ngIf="settlement.completed==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="settlement.payoutAmount!=null"align="center" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{settlement.payoutAmount | number : '1.2-2' }}</td>
                      <td *ngIf="settlement.payoutAmount==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="settlement.upiId!=null"align="center" class="v-align-middle">{{settlement.upiId}}</td>
                      <td *ngIf="settlement.upiId==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="settlement.settlementDatetime!=null"align="center" class="v-align-middle">{{settlement.settlementDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="settlement.settlementDatetime==null"align="center" class="v-align-middle">N/A</td>
                      <td class="v-align-middle">
                        <button class="label label-info" (click)="view(content5,settlement.settlementId)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                      </td>
                    </tr>
                  </tbody>
                </table>
          </div>
        </div>
    </ng-template>
  </li>
  <tr style="display: none;" *ngFor="let kyc of kycDocumentModel; let i=index">
    <td align="center" class="v-align-middle ">{{i + 1}}</td>
    <td align="left" class="v-align-middle">{{kyc.kycId.kycName}}</td>
  </tr>

  <li [ngbNavItem]="7" >
    <a ngbNavLink>KYC</a>
    <ng-template ngbNavContent>
      <div class="card-body">
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;" >Sl.No.</th>
                      <th style="text-align: left;">Vehicle Number</th>
                      <th style="text-align: left;">KYC Name</th>
                      <th style="text-align: center;">Approval Status</th>
                      <th style="text-align: left;">Approved By</th>
                      <th style="text-align: left;">Comments</th>
                      <th style="text-align: center;">PDF / Images</th>
                      <th style="text-align: center;">Location</th>
                      <th style="text-align: left;">Submitted Date Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let kyc of kycDocumentModel; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="kyc.vehicleId!=null && kyc.vehicleId.vehicleNumber!=null" align="left" class="v-align-middle">{{kyc.vehicleId.vehicleNumber}}</td>
                      <td *ngIf="kyc.vehicleId==null" align="center" class="v-align-middle">N/A</td>
                      <td align="left" class="v-align-middle">{{kyc.kycId.kycName}}</td>
                      <td align="center" *ngIf="kyc.approvalStatus==10" class="v-align-middle ht-Pending">Pending</td>
                      <td align="center" *ngIf="kyc.approvalStatus==11" class="v-align-middle ht-active">Approved</td>
                      <td align="center" *ngIf="kyc.approvalStatus==12" class="v-align-middle ht-inactive">Rejected</td>
                      <td *ngIf="kyc.approvalBy!=null"align="left" class="v-align-middle">{{kyc.approvalBy}}</td>
                      <td *ngIf="kyc.approvalBy==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="kyc.approvalComments!=null"align="left" class="v-align-middle">{{kyc.approvalComments}}</td>
                      <td *ngIf="kyc.approvalComments==null"align="left" class="v-align-middle">N/A</td>
                      <td align="center" class="v-align-middle">
                        <button type="button" class="label label-success" (click)="open2(content2)" (click)="kycdocument(kyc.id)">
                          <span aria-hidden="true"></span>
                          <i class="fa fa-image"></i>
                        </button>
                      </td>
                      <td align="center" class="v-align-middle">
                        <button type="button" class="label label-info" (click)="location(kyc.latitude,kyc.longitude)" (click)="open3(content3)">
                          <span aria-hidden="true"></span>
                          <i class="fa fa-location-arrow"></i>
                        </button>
                      </td>
                      <td align="left" class="v-align-middle"><span class="v-align-middle ">{{kyc.createdDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span></td>
                    </tr>
                  </tbody>
                </table>
          </div>
        </div>
    </ng-template>
  </li>

  <tr style="display: none;" *ngFor="let bank of bankinfoModel; let i=index">
    <td align="center" class="v-align-middle ">{{i + 1}}</td>
    <td *ngIf="bank.beneficiaryName!=null"align="left" class="v-align-middle">{{bank.beneficiaryName}}</td>
    </tr>

  <li [ngbNavItem]="8" >
    <a ngbNavLink>Vehicle Settlement Bank Info</a>
    <ng-template ngbNavContent>
      <div class="card-body">
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Account Holder Name</th>
                      <th style="text-align: left;">Account Number</th>
                      <th style="text-align: left;">Account Type</th>
                      <th style="text-align: left;" >Bank name</th>
                      <th style="text-align: left;" >Branch Name</th>
                      <th style="text-align: left;">IFSC Code</th>
                      <th style="text-align: left;">Approved Date Time</th>
                      <th style="text-align: center;">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let bank of bankinfoModel; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="bank.beneficiaryName!=null"align="left" class="v-align-middle">{{bank.beneficiaryName}}</td>
                      <td *ngIf="bank.beneficiaryName==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.accountNumber!=null"align="left" class="v-align-middle">{{bank.accountNumber}}</td>
                      <td *ngIf="bank.accountNumber==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.accountType!=null"align="left" class="v-align-middle">{{bank.accountType}}</td>
                      <td *ngIf="bank.accountType==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.bankId!=null && bank.bankId.bankName!=null"align="left" class="v-align-middle">{{bank.bankId.bankName}}</td>
                      <td *ngIf="bank.bankId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.branchName!=null"align="left" class="v-align-middle">{{bank.branchName}}</td>
                      <td *ngIf="bank.branchName==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.ifscCode!=null"align="left" class="v-align-middle">{{bank.ifscCode}}</td>
                      <td *ngIf="bank.ifscCode==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.approvedDatetime!=null"align="left" class="v-align-middle">{{bank.approvedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="bank.approvedDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="bank.approvalStatus==11" class="v-align-middle ht-active">Approved</td>
                      <td align="center" *ngIf="bank.approvalStatus==12" style="color: red;">Rejected</td>
                      <td align="center" *ngIf="bank.approvalStatus==10" class="v-align-middle ht-Pending">Pending</td>
                    </tr>
                  </tbody>
                </table>
          </div>
        </div>
    </ng-template>
  </li>
  <tr style="display: none;" *ngFor="let market of marketPrizeModel; let i=index">
    <td align="center" class="v-align-middle ">{{i + 1}}</td>
    <td *ngIf="market.vehicleId!=null"align="left" class="v-align-middle">{{market.vehicleId.partnerName}}</td>
    </tr>

  <li [ngbNavItem]="9" >
    <a ngbNavLink>Price Reset History</a>
    <ng-template ngbNavContent>
      <div class="card-body">
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                        <th style="text-align: center;">Sl.No.</th>
                        <th style="text-align: left;">Partner Name</th>
                        <th style="text-align: left;" >Vehicle Number</th>
                        <th style="text-align: left;" >Model Name</th>
                        <th style="text-align: left;" >Fuel</th>
                        <th style="text-align: right;">Prize Ltrs<br></th>
                        <th style="text-align: left;">Updated Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let market of marketPrizeModel; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="market.vehicleId!=null"align="left" class="v-align-middle">{{market.vehicleId.partnerName}}</td>
                      <td *ngIf="market.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="market.vehicleId!=null"align="left" class="v-align-middle" >{{market.vehicleId.vehicleNumber}}</td>
                      <td *ngIf="market.vehicleId==null"align="left" class="v-align-middle" >N/A</td>
                      <td *ngIf="market.vehicleId!=null"align="left" class="v-align-middle">{{market.vehicleId.vehicleName}}</td>
                      <td *ngIf="market.vehicleId==null"align="left" class="v-align-middle" >N/A</td>
                      <td *ngIf="market.fuelType!=null && market.fuelType.fuelTypeName!=null"align="left" class="v-align-middle">{{market.fuelType.fuelTypeName}}</td>
                      <td *ngIf="market.fuelType==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="market.price!=null"align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{market.price | number : '1.2-2'}}</td>
                      <td *ngIf="market.price==null"align="right" class="v-align-middle">N/A</td>
                      <td *ngIf="market.currentDatetime!=null"align="left" class="v-align-middle">{{market.currentDatetime | date:'dd/MM/yyyy'}}</td>
                      <td *ngIf="market.currentDatetime==null"align="left" class="v-align-middle">N/A</td>
                    </tr>
                  </tbody>
                </table>
          </div>
        </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="10" >
    <a ngbNavLink>Partner Settlement Bank Info</a>
    <div style="display: none;">{{partnerBankInfo.responseMessage}}</div>
    <ng-template ngbNavContent>
      <div class="card-body">
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Account Holder Name</th>
                      <th style="text-align: left;">Account Number</th>
                      <th style="text-align: left;">Account Type</th>
                      <th style="text-align: left;" >Bank name</th>
                      <th style="text-align: left;" >Branch Name</th>
                      <th style="text-align: left;">IFSC Code</th>
                      <th style="text-align: left;">Approved Date Time</th>
                      <th style="text-align: center;">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let bank of partnerBankInfo.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="bank.beneficiaryName!=null"align="left" class="v-align-middle">{{bank.beneficiaryName}}</td>
                      <td *ngIf="bank.beneficiaryName==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.accountNumber!=null"align="left" class="v-align-middle">{{bank.accountNumber}}</td>
                      <td *ngIf="bank.accountNumber==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.accountType!=null"align="left" class="v-align-middle">{{bank.accountType}}</td>
                      <td *ngIf="bank.accountType==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.bankId!=null && bank.bankId.bankName!=null"align="left" class="v-align-middle">{{bank.bankId.bankName}}</td>
                      <td *ngIf="bank.bankId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.branchName!=null"align="left" class="v-align-middle">{{bank.branchName}}</td>
                      <td *ngIf="bank.branchName==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.ifscCode!=null"align="left" class="v-align-middle">{{bank.ifscCode}}</td>
                      <td *ngIf="bank.ifscCode==null"align="left" class="v-align-middle">N/A</td>
                      <td align="left" *ngIf="bank.approvalStatus==10" class="v-align-middle">N/A</td>
                      <td align="left" *ngIf="bank.approvalStatus==11" class="v-align-middle">{{bank.approvedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td align="left" *ngIf="bank.approvalStatus==12" class="v-align-middle">{{bank.approvedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td align="center" *ngIf="bank.approvalStatus==11" class="v-align-middle ht-active">Approved</td>
                      <td align="center" *ngIf="bank.approvalStatus==12" style="color: red;">Rejected</td>
                      <td align="center" *ngIf="bank.approvalStatus==10" class="v-align-middle ht-Pending">Pending</td>
                    </tr>
                  </tbody>
                </table>
          </div>
        </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="11">
    <div style="display: none;">{{assetsResponseModel.responseMessage}}</div>
    <a ngbNavLink> Assets </a>
    <ng-template ngbNavContent>
      <div class="card-body">
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th align="center" >Sl.No.</th>
                      <th align="left" >Authorized Person </th>
                      <th align="left" >DG KVA</th>
                      <th align="center" >Make</th>
                      <th align="center" >Contact Number</th>
                      <th align="center" >Model</th>
                      <th align="center" >Fuel Type</th>
                      <th align="center" >Fuel Tank Capaticy</th>
                      <th align="center" >Image</th>
                      <th align="center">Location</th>
                      <th align="center" >Onboarded by</th>
                      <th align="center" >Onboarded Date & Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let asset of assetsResponseModel.response; let i=index">
                      <td align="left" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="asset.authorizedPersonName!=null" align="left" class="v-align-middle">{{asset.authorizedPersonName}}</td>
                      <td *ngIf="asset.authorizedPersonName==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="asset.dgKVA!=null"align="left" class="v-align-middle">{{asset.dgKVA}}</td>
                      <td *ngIf="asset.dgKVA==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="asset.assestMake!=null" align="left" class="v-align-middle">{{asset.assestMake}}</td>
                      <td *ngIf="asset.assestMake==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="asset.contactMobileNumber!=null" align="left" class="v-align-middle">{{asset.contactMobileNumber}}</td>
                      <td *ngIf="asset.contactMobileNumber==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="asset.assestModel!=null" align="left" class="v-align-middle">{{asset.assestModel}}</td>
                      <td *ngIf="asset.assestModel==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="asset.fuelType!=null" align="left" class="v-align-middle">{{asset.fuelType.fuelTypeName}}</td>
                      <td *ngIf="asset.fuelType==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="asset.tankCapacity!=null" align="left" class="v-align-middle">{{asset.tankCapacity}}</td>
                      <td *ngIf="asset.tankCapacity==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="asset.assestImage!=null" align="left" class="v-align-middle"><button class="label label-info" (click)="open5(content6,asset.id)"><span aria-hidden="true"></span>
                        <i class="fa fa-image"></i></button></td>
                        <td align="center" class="v-align-middle">
                          <button type="button" title="Location" class="label label-info" (click)="location(asset.latitude,asset.longitude)" (click)="open7(content10)">
                            <span aria-hidden="true"></span>
                            <i class="fa fa-location-arrow"></i>
                          </button>
                        </td>  
                      <td *ngIf="asset.assestImage==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="asset.createdBy!=null" align="left" class="v-align-middle">{{asset.createdBy}}</td>
                      <td *ngIf="asset.createdBy==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="asset.createdDatetime!=null" align="left" class="v-align-middle">{{asset.createdDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="asset.createdDatetime==null" align="left" class="v-align-middle">N/A</td>
                    </tr>
                  </tbody>
                </table>
          </div>
        </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="12">
    <a ngbNavLink>Device Information</a>
    <ng-template ngbNavContent>
      <div class="card-body">
        <div class="table-responsive">
          <table datatable class="ch-table">
            <thead>
              <tr> 
                <th style="text-align: center;">Sl.No.</th>
                <th style="text-align: left;">Device Name</th>
                <th style="text-align: left;">Device Number</th>
                <th style="text-align: left;">Device Type</th>
                <th style="text-align: left;" >Device Model</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td align="center" class="v-align-middle ">1</td>
                <td align="left" class="v-align-middle">{{userModel.userdevicename}}</td>
                <td align="left" class="v-align-middle">{{userModel.userdevicenumber}}</td>
                <td align="left" class="v-align-middle">{{userModel.userdevicetype}}</td>
                <td align="left" class="v-align-middle">{{userModel.userdevicemodel}}</td>
              </tr>
            </tbody>
          </table>
    </div>
  </div>
    </ng-template>
  </li>
  
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h6>Order View Page</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <h5 style="margin-left: 15px; text-align: left; color: grey;">Order Details</h5>
  <div class="container"
  fxLayout
>
<div class="item item-1">
<p><b>Order ID</b></p>
<span>{{orderDetails.response.orderId}}</span>
</div>
<div class="item item-2" style="margin-left: 30px;">
  <p><b>Customer Name</b></p>
  <span *ngIf="orderDetails.response.customerId!=null" style="word-wrap: break-word;">{{orderDetails.response.customerId.firstName}} {{orderDetails.response.customerId.lastName}}</span>
      <span *ngIf="orderDetails.response.customerId==null">N/A</span>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p><b>Customer Mobile</b></p>
  <span *ngIf="orderDetails.response.customerId!=null">{{orderDetails.response.customerId.mobileNumber}}</span>
      <span *ngIf="orderDetails.response.customerId==null">N/A</span>
</div>
<div class="item item-4" style="margin-left: 30px;">
  <p><b>Booking Date & Time</b></p>
  <span *ngIf="orderDetails.response.bookedDate!=null">{{orderDetails.response.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span>
      <span *ngIf="orderDetails.response.bookedDate==null">N/A</span>
</div>
<div class="item item-5" style="margin-left: 30px;">
  <p><b>Quantity in Ltrs</b></p>
  <span *ngIf="orderDetails.response.quantity!=null">{{orderDetails.response.quantity}}</span>
      <span *ngIf="orderDetails.response.quantity==null">N/A</span>
</div>
<div class="item item-7" style="margin-left: 30px;">
  <p><b>Order Type</b></p>
<span *ngIf="orderDetails.response.bookLaterTime===''; else ifNotShow">
  Immediate
</span>
<ng-template #ifNotShow>
  <span>Scheduled</span>
</ng-template>
</div>
<div *ngIf="orderDetails.response.status==='Cancelled'" class="item item-8" style="margin-left: 30px;">
  <p><b>Cancelled Date & Time</b></p>
  <span >{{orderDetails.response.orderCancellationDatetime |date:'dd/MM/yyyy hh:mm:ss a'}}</span>
</div>
<div *ngIf="orderDetails.response.status==='Cancelled'" class="item item-9" style="margin-left: 30px;">
  <p><b>Cancelled By</b></p>
  <span *ngIf="orderDetails.response.cancelledBy!=null">
    <span style="font-size: 10px;">Name :{{orderDetails.response.cancelledBy.firstName}} {{orderDetails.response.cancelledBy.lastName}}</span><br>
    <span style="font-size: 10px;">Mobile :{{orderDetails.response.cancelledBy.mobileNumber}}</span>
  </span>
  <span *ngIf="orderDetails.response.cancelledBy==null">N/A</span>
</div>
<div *ngIf="orderDetails.response.status==='Cancelled'" class="item item-9" style="margin-left: 30px;">
  <p><b>Reason for Cancelling</b></p>
  <span *ngIf="orderDetails.response.cancellationId!=null" style="word-wrap: break-word;">{{orderDetails.response.cancellationId.cancellationMessage}}</span>
  <span *ngIf="orderDetails.response.cancellationId==null">N/A</span>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p><b>Status</b></p>
  <span *ngIf="orderDetails.response.status==='Pending'"><span class="ht-Pending"><b>{{orderDetails.response.status}}</b></span></span>
      <span *ngIf="orderDetails.response.status==='Cancelled'"><span style="color: red;"><b>{{orderDetails.response.status}}</b></span></span>
      <span *ngIf="orderDetails.response.status==='Inprogress'"><span style="color: blue;"><b>{{orderDetails.response.status}}</b></span></span>
      <span *ngIf="orderDetails.response.status==='Delivered'"><span class="ht-active"><b>{{orderDetails.response.status}}</b></span></span>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p><b>Coupon Code</b></p>
  <span *ngIf="orderDetails.response.couponCode!=null">{{orderDetails.response.couponCode}}</span>
  <span *ngIf="orderDetails.response.couponCode==null">N/A</span>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p><b>Coupon Discount Price</b></p>
  <span *ngIf="orderDetails.response.couponCode!=null">
  <span *ngIf="orderDetails.response.couponDiscountFlat==1">
    <span>{{orderDetails.response.couponDiscountPrice}} % </span>
  </span>
  <span *ngIf="orderDetails.response.couponDiscountFlat==2">
    <span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.couponDiscountPrice | number : '1.2-2'}}</span>
  </span>
  </span>
  <span *ngIf="orderDetails.response.couponCode==null">N/A</span>
</div>
  </div>
<br>
  <h5 style="margin-left: 15px; text-align: left; color: grey;">Delivery Agent Info</h5>
  <div class="container"
  fxLayout
>
<div class="item item-1">
<p><b>Delivery Agent Name</b></p>
<span *ngIf="orderDetails.response.orderAcceptedBy!=null" style="word-wrap: break-word;">{{orderDetails.response.orderAcceptedBy.firstName}} {{orderDetails.response.orderAcceptedBy.lastName}}</span>
      <span *ngIf="orderDetails.response.orderAcceptedBy==null">N/A</span>
</div>
<div class="item item-2" style="margin-left: 40px;">
  <p><b>Delivery Agent Number</b></p>
  <span *ngIf="orderDetails.response.orderAcceptedBy!=null">{{orderDetails.response.orderAcceptedBy.mobileNumber}}</span>
      <span *ngIf="orderDetails.response.orderAcceptedBy==null">N/A</span>
</div>
<div class="item item-4" style="margin-left: 40px;">
  <p><b>Vehicle Number</b></p>
  <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.vehicleNumber}}</span>
      <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
</div>
<div class="item item-5" style="margin-left: 40px;">
  <p><b>Fuel</b></p>
  <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.fuelType.fuelTypeName}}</span>
      <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
</div>
<div *ngIf="orderDetails.response.status==='Completed'" class="item item-6" style="margin-left: 40px;">
  <p><b>Delivered  Date Time</b></p>
  <span >{{orderDetails.response.orderDeliveredDateTime |date:'dd/MM/yyyy hh:mm:ss a' }}</span>
</div>
<div class="item item-7" style="margin-left: 40px;">
  <p><b>Delivery location</b></p>
  <span *ngIf="orderDetails.response.deliveryAddress!=null">{{orderDetails.response.deliveryAddress}}</span>
      <span *ngIf="orderDetails.response.deliveryAddress==null">N/A</span>
</div>
  </div>

  <br>
  <h5 *ngIf="orderDetails.response.status==='Completed'" style="margin-left: 15px; text-align: left; color: grey;">Transaction Details</h5>
  <div *ngIf="orderDetails.response.status==='Completed'" class="container"
  fxLayout
>
<div class="item item-1">
  <p *ngIf="orderDetails.response.totalfuelcost!=null"class="card-title"><b>Total Fuel Cost</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.totalfuelcost | number : '1.2-2'}}</span></p>
  <p *ngIf="orderDetails.response.totalfuelcost==null"class="card-title"><b>Total Fuel Cost</b><br><span>N/A</span></p>
</div>
<div class="item item-1" style="margin-left: 30px;">
  <p *ngIf="orderDetails.response.deliveryCharges!=null"class="card-title"><b>Delivery Charges</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.deliveryCharges | number : '1.2-2'}}</span></p>
          <p *ngIf="orderDetails.response.deliveryCharges==null"class="card-title"><b>Delivery Charges</b><br><span>N/A</span></p>
  </div>
  <div class="item item-1" style="margin-left: 30px;">
    <p *ngIf="orderDetails.response.sgst!=null"class="card-title"><b>SGST</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.sgst | number : '1.2-2'}}</span></p>
            <p *ngIf="orderDetails.response.sgst==null"class="card-title"><b>SGST</b><br><span>N/A</span></p>
    </div>
    <div class="item item-1" style="margin-left: 30px;">
      <p *ngIf="orderDetails.response.cgst!=null"class="card-title"><b>CGST</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.cgst | number : '1.2-2'}}</span></p>
              <p *ngIf="orderDetails.response.cgst==null"class="card-title"><b>CGST</b><br><span>N/A</span></p>
      </div>
      <div class="item item-1" style="margin-left: 30px;">
        <p *ngIf="orderDetails.response.internetHandlingFee!=null"class="card-title"><b>Platform Charges</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.internetHandlingFee | number : '1.2-2'}}</span></p>
                <p *ngIf="orderDetails.response.internetHandlingFee==null"class="card-title"><b>Platform Charges</b><br><span>N/A</span></p>
        </div>    
        <div class="item item-1" style="margin-left: 30px;">
                  <p *ngIf="orderDetails.response.totalPayableAmount!=null"class="card-title"><b>Total Paid Amount</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.totalPayableAmount | number : '1.2-2'}}</span></p>
                  <p *ngIf="orderDetails.response.totalPayableAmount==null"class="card-title"><b>Total Paid Amount</b><br><span>N/A</span></p>

          </div>     
<div class="item item-2" style="margin-left: 30px;">
  <p *ngIf="orderDetails.response.transactionId!=null"class="card-title"><b>Transaction ID</b><br><span>{{orderDetails.response.transactionId}}</span></p>
          <p *ngIf="orderDetails.response.transactionId==null"class="card-title"><b>Transaction ID</b><br><span>N/A</span></p>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p *ngIf="orderDetails.response.paymentStatus==='Success'" class="card-title"><b>Payment Status </b><br><span class="ht-Success"><b>{{orderDetails.response.paymentStatus}}</b></span></p>
        <p *ngIf="orderDetails.response.paymentStatus==='Pending'" class="card-title"><b>Payment Status </b><br><span class="ht-Pending"><b>{{orderDetails.response.paymentStatus}}</b></span></p>
        <p *ngIf="orderDetails.response.paymentStatus==='Failure'" class="card-title"><b>Payment Status </b><br><span style="color: red;"><b>Failed</b></span></p>
        <p *ngIf="orderDetails.response.paymentStatus==='Initiated'" class="card-title"><b>Payment Status</b> <br><span class="ht-Pending"><b>Pending</b></span></p>
</div>
<div class="item item-4" style="margin-left: 30px;">
  <p class="card-title"><b>Transaction Date & Time</b> <br><span>{{orderDetails.response.initiatedDatetime |date:'dd/MM/yyyy hh:mm:ss a'}}</span></p>
</div>
  </div>
  <hr *ngIf="orderDetails.response.status=='Cancelled'" class="beautiful-line">
<h5 *ngIf="orderDetails.response.status=='Cancelled'" style="margin-left: 15px; text-align: left; color: grey;">Order Track Info</h5>
<br>
<div *ngIf="orderDetails.response.status=='Cancelled'"class="container"
  fxLayout
>
<div class="item item-1" style="margin-left: 20px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderPlaced==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderPlaced==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Placed</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderPlacedDateTime!=null" style="text-align: center;">
    {{orderTrackDetails.response.orderPlacedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderPlacedDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-2" style="margin-left: -80px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-3" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderAccepted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderAccepted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>    
  </span>
  <span style="margin-bottom: 10px;"><b>Agent Accepted</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderAcceptedDateTime!=null">
    {{orderTrackDetails.response.orderAcceptedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span style="color: red;" *ngIf="orderTrackDetails.response.orderAcceptedDateTime==null">
    Order Cancelled
  </span>
</div>
<div class="item item-4" style="margin-left: -50px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-5" style="margin-left: 15px;">
  <img *ngIf="orderTrackDetails.response.paymentCompleted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.paymentCompleted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>    
    <span style="margin-bottom: 10px; width: 30px;"><b>Payment Completed</b></span><br>
    <span style="color: red;">
      Order Cancelled
    </span>    
</div>
<div class="item item-6" style="margin-left: -60px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-7" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderInitiated==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderInitiated==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Initiated</b></span><br>
  <span style="color: red;">
    Order Cancelled
  </span>  
</div>
<div class="item item-8" style="margin-left: -40px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-9" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.refillOtp==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.refillOtp==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Refill OTP Verified</b></span><br>
  <span style="color: red;">
    Order Cancelled
  </span>  
</div>
<div class="item item-10" style="margin-left: -60px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-11" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.endOrderOtp==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.endOrderOtp==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>END Order OTP Verified</b></span><br>
  <span style="color: red;">
    Order Cancelled
  </span>  
</div>
<div class="item item-12" style="margin-left: -80px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-13" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderCompleted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderCompleted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Completed</b></span><br>
  <span style="color: red;">
    Order Cancelled
  </span>  
</div>
<div class="item item-14" style="margin-left: -40px;">
  <b><hr width="60px" color="pink"></b>
</div>
<div class="item item-14" style="margin-left: 15px;">
  <span>
    <img src="assets/img/remove.svg" alt="logo" data-src="assets/img/remove.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Cancelled</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderPlacedDateTime!=null" style="text-align: center;">
    {{orderTrackDetails.response.orderCancelledDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>  
</div>
  </div>
  <hr *ngIf="orderDetails.response.status!=='Cancelled'" class="beautiful-line">
  <div *ngIf="orderDetails.response.status!=='Cancelled'"class="container"
  fxLayout
>
<div class="item item-1" style="margin-left: 20px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderPlaced==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderPlaced==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Placed</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderPlacedDateTime!=null" style="text-align: center;">
    {{orderTrackDetails.response.orderPlacedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderPlacedDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-2" style="margin-left: -80px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-3" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderAccepted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderAccepted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>    
  </span>
  <span style="margin-bottom: 10px;"><b>Agent Accepted</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderAcceptedDateTime!=null">
    {{orderTrackDetails.response.orderAcceptedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderAcceptedDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-4" style="margin-left: -50px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-5" style="margin-left: 15px;">
  <img *ngIf="orderTrackDetails.response.paymentCompleted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.paymentCompleted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>    
    <span style="margin-bottom: 10px;"><b>Payment Completed</b></span><br>
    <span *ngIf="orderTrackDetails.response.paymentCompletedDateTime!=null">
      {{orderTrackDetails.response.paymentCompletedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
    </span>
    <span *ngIf="orderTrackDetails.response.paymentCompletedDateTime==null">
      Will be Updated
    </span>    
</div>
<div class="item item-6" style="margin-left: -60px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-7" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderInitiated==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderInitiated==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Initiated</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderInitiatedDateTime!=null">
    {{orderTrackDetails.response.orderInitiatedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderInitiatedDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-8" style="margin-left: -40px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-9" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.refillOtp==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.refillOtp==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Refill OTP Verified</b></span><br>
  <span *ngIf="orderTrackDetails.response.refillDateTime!=null">
    {{orderTrackDetails.response.refillDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.refillDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-10" style="margin-left: -60px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-11" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.endOrderOtp==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.endOrderOtp==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>END Order OTP Verified</b></span><br>
  <span *ngIf="orderTrackDetails.response.endOrderDateTime!=null">
    {{orderTrackDetails.response.endOrderDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.endOrderDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-12" style="margin-left: -80px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-13" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderCompleted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderCompleted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Completed</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderCompletedDateTime!=null">
    {{orderTrackDetails.response.orderCompletedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderCompletedDateTime==null">
    Will be Updated
  </span>
</div>
  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
  </div>
</ng-template>

<ng-template #content1 let-modal>
  <div class="modal-header">
    <h5>Vehicle View Page </h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <h5 style="margin-left: 15px; text-align: left; color: grey;">Vehicle Info</h5>
  <div class="container"
  fxLayout
>
<div class="item item-1">
  <p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title"><b>Vehicle Number</b>   <br><span>{{vehiclePaymentModel.response.vehicleId.vehicleNumber}}</span></p>
         <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title"><b>Vehicle Number</b>   <br><span>{{vehiclePaymentModel.response.vehicleNumber}}</span></p>
</div>
<div class="item item-2" style="margin-left: 30px;">
  <p  *ngIf="vehiclePaymentModel.response.id!=null" class="card-title"><b>Vehicle Name  </b>
    <br>
    <span>{{vehiclePaymentModel.response.vehicleId.vehicleName}}</span></p>
    <p  *ngIf="vehiclePaymentModel.response.id==null" class="card-title"><b>Vehicle Name  </b>
     <br>
     <span>{{vehiclePaymentModel.response.vehicleName}}</span></p>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title"><b>Fuel</b>  <br><span>{{vehiclePaymentModel.response.vehicleId.fuelType.fuelTypeName}}</span></p>
         <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title"><b>Fuel</b>  <br><span>{{vehiclePaymentModel.response.fuelType.fuelTypeName}}</span></p>
</div>
<div class="item item-4" style="margin-left: 30px;">
  <p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title"><b>Tank Capacity in litre</b> <br>
    <span>{{vehiclePaymentModel.response.vehicleId.tankCapacity}}</span></p>
    <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title"><b>Tank Capacity in litre</b> <br>
     <span>{{vehiclePaymentModel.response.tankCapacity}}</span></p>  
</div>
<div class="item item-5" style="margin-left: 30px;">
  <p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title"><b>Vehicle RC Number</b> <br>
    <span>{{vehiclePaymentModel.response.vehicleId.vehicleRCNumber}}</span></p>
    <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title"><b>Vehicle RC Number</b> <br>
     <span *ngIf="vehiclePaymentModel.response.vehicleRCNumber!=null">{{vehiclePaymentModel.response.vehicleRCNumber}}</span>
     <span *ngIf="vehiclePaymentModel.response.vehicleRCNumber==null">N/A</span>
    </p>
</div>
<div class="item item-6" style="margin-left: 30px;">
  <p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title"><b>Vehicle Insurance Number</b> <br>
    <span>{{vehiclePaymentModel.response.vehicleId.vehicleInsuranceNumber}}</span></p>
    <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title"><b>Vehicle Insurance Number</b> <br>
     <span *ngIf="vehiclePaymentModel.response.vehicleInsuranceNumber!=null">{{vehiclePaymentModel.response.vehicleInsuranceNumber}}</span>
     <span *ngIf="vehiclePaymentModel.response.vehicleInsuranceNumber==null">N/A</span>
    </p>
</div>
<div class="item item-7" style="margin-left: 30px;">
  <p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title"><b>Vehicle Expiry Date</b><br>
    <span>{{vehiclePaymentModel.response.vehicleId.insuranceExpiryDate | date:'dd/MM/yyyy'}}</span></p>
    <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title"><b>Vehicle Expiry Date</b> <br>
     <span *ngIf="vehiclePaymentModel.response.insuranceExpiryDate!=null">{{vehiclePaymentModel.response.insuranceExpiryDate | date:'dd/MM/yyyy' }}</span>
     <span *ngIf="vehiclePaymentModel.response.insuranceExpiryDate==null">N/A</span>
    </p>
</div>
<div class="item item-8" style="margin-left: 30px;">
  <p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title"><b>AMC/MMC Type</b> <br>
    <span *ngIf="vehiclePaymentModel.response.vehicleId.amcMmcType!=null">{{vehiclePaymentModel.response.vehicleId.amcMmcType}}</span>
    <span *ngIf="vehiclePaymentModel.response.vehicleId.amcMmcType==null">N/A</span>
  </p>
    <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title"><b>AMC/MMC Type</b> <br>
     <span *ngIf="vehiclePaymentModel.response.amcMmcType!=null">{{vehiclePaymentModel.response.amcMmcType}}</span>
     <span *ngIf="vehiclePaymentModel.response.amcMmcType==null">N/A</span>
    </p>
</div>
  </div>
  <h5 style="margin-left: 15px; text-align: left; color: grey;">Vehicle Permnant Location Details</h5>
  <div class="container"
  fxLayout
>
<div class="item item-1">
  <p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title"><b>Address</b><br>
    <span>{{vehiclePaymentModel.response.vehicleId.address}}</span></p>
    <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title"><b>Address</b><br>
     <span *ngIf="vehiclePaymentModel.response.address!=null">{{vehiclePaymentModel.response.address}}</span>
     <span *ngIf="vehiclePaymentModel.response.address==null">N/A</span>
    </p>  
</div>
<div class="item item-2" style="margin-left: 40px;">
  <p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title"><b>State</b><br>
    <span>{{vehiclePaymentModel.response.vehicleId.stateName}}</span></p>
    <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title"><b>State</b><br>
     <span *ngIf="vehiclePaymentModel.response.stateName!=null">{{vehiclePaymentModel.response.stateName}}</span>
     <span *ngIf="vehiclePaymentModel.response.stateName==null">N/A</span>
    </p>  
</div>
<div class="item item-3" style="margin-left: 40px;">
  <p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title"><b>District</b><br>
    <span>{{vehiclePaymentModel.response.vehicleId.cityName}}</span></p>
    <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title"><b>District</b><br>
     <span *ngIf="vehiclePaymentModel.response.cityName!=null">{{vehiclePaymentModel.response.cityName}}</span>
     <span *ngIf="vehiclePaymentModel.response.cityName==null">N/A</span>
    </p>    
</div>
<div class="item item-4" style="margin-left: 40px;">
  <p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title"><b>Pincode</b><br>
    <span>{{vehiclePaymentModel.response.vehicleId.vehicleRunningPincode}}</span></p>
    <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title"><b>Pincode</b><br>
     <span *ngIf="vehiclePaymentModel.response.vehicleRunningPincode!=null">{{vehiclePaymentModel.response.vehicleRunningPincode}}</span>
     <span *ngIf="vehiclePaymentModel.response.vehicleRunningPincode==null">N/A</span>
    </p> 
</div>
  </div>

  <h5 style="margin-left: 15px; text-align: left; color: grey;">Payment Details</h5>
  <div class="container"
  fxLayout
>
<div class="item item-1">
  <p class="card-title"><b>Payment Amount </b>   <br>
    <span *ngIf="vehiclePaymentModel.response.id!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{vehiclePaymentModel.response.totalPayableAmount | number : '1.2-2'}}</span>
    <span *ngIf="vehiclePaymentModel.response.id==null">N/A</span>
  </p>
</div>
<div class="item item-2" style="margin-left: 40px;">
  <p class="card-title"><b>Transaction ID </b> <br>
    <span *ngIf="vehiclePaymentModel.response.id!=null">
      <span *ngIf="vehiclePaymentModel.response.paymentStatus==='cancelled'; else ifNotShow" >N/A</span>
      <ng-template #ifNotShow>
        <span *ngIf="vehiclePaymentModel.response.paymentId==='null'">N/A</span>
        <span *ngIf="vehiclePaymentModel.response.paymentId!=null">{{vehiclePaymentModel.response.paymentId}}</span>
        <span *ngIf="vehiclePaymentModel.response.paymentId==null">N/A</span>
      </ng-template>
    </span>
    <span *ngIf="vehiclePaymentModel.response.id==null">
      N/A
    </span>
  </p>
</div>
<div class="item item-3" style="margin-left: 40px;">
  <p class="card-title"><b>Payment Status </b>  <br>
    <span class="v-align-middle ht-active" *ngIf="vehiclePaymentModel.response.id!=null">{{vehiclePaymentModel.response.paymentStatus}}</span>
    <span *ngIf="vehiclePaymentModel.response.id==null">N/A</span>
  </p> 
</div>
<div class="item item-4" style="margin-left: 40px;">
  <p class="card-title"><b>Transaction Date & Time</b>   <br>
    <span *ngIf="vehiclePaymentModel.response.id!=null">{{vehiclePaymentModel.response.transactionDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span>
    <span *ngIf="vehiclePaymentModel.response.id==null">N/A</span>
  </p>
</div>
  </div>
  <h5 style="margin-left: 15px; text-align: left; color: grey;">Vehicle Image</h5>
  <div class="container"
  fxLayout
>
<div class="item item-1">
  <img class="modal-content-style"  [src]="imageToShowvehicle1">
      <img class="modal-content-style"  [src]="imageToShowvehicle2">
      <img class="modal-content-style"  [src]="imageToShowvehicle3">
      <img class="modal-content-style"  [src]="imageToShowvehicle4">
</div>
  </div>
  <!-- <div class="modal-body"> -->
    <!-- <div class="wrapper"> -->
    	<!-- <div class="flex extradiv">
        <h6>Vehicle Info </h6>
         <p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title">Vehicle Number   <br><span><b>{{vehiclePaymentModel.response.vehicleId.vehicleNumber}}</b></span></p>
         <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title">Vehicle Number   <br><span><b>{{vehiclePaymentModel.response.vehicleNumber}}</b></span></p>
   		  <p  *ngIf="vehiclePaymentModel.response.id!=null" class="card-title">Vehicle Name  
           <br>
           <span><b>{{vehiclePaymentModel.response.vehicleId.vehicleName}}</b></span></p>
           <p  *ngIf="vehiclePaymentModel.response.id==null" class="card-title">Vehicle Name  
            <br>
            <span><b>{{vehiclePaymentModel.response.vehicleName}}</b></span></p>
         <p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title">Fuel  <br><span><b>{{vehiclePaymentModel.response.vehicleId.fuelType.fuelTypeName}}</b></span></p>
         <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title">Fuel  <br><span><b>{{vehiclePaymentModel.response.fuelType.fuelTypeName}}</b></span></p>
         
   		  <p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title">Tank Capacity in litre <br>
           <span><b>{{vehiclePaymentModel.response.vehicleId.tankCapacity}}</b></span></p>
           <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title">Tank Capacity in litre <br>
            <span><b>{{vehiclePaymentModel.response.tankCapacity}}</b></span></p>  

            <p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title">Vehicle RC Number <br>
              <span><b>{{vehiclePaymentModel.response.vehicleId.vehicleRCNumber}}</b></span></p>
              <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title">Vehicle RC Number <br>
               <span *ngIf="vehiclePaymentModel.response.vehicleRCNumber!=null"><b>{{vehiclePaymentModel.response.vehicleRCNumber}}</b></span>
               <span *ngIf="vehiclePaymentModel.response.vehicleRCNumber==null"><b>N/A</b></span>
              </p>

              <p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title">Vehicle Insurance Number <br>
                <span><b>{{vehiclePaymentModel.response.vehicleId.vehicleInsuranceNumber}}</b></span></p>
                <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title">Vehicle Insurance Number <br>
                 <span *ngIf="vehiclePaymentModel.response.vehicleInsuranceNumber!=null"><b>{{vehiclePaymentModel.response.vehicleInsuranceNumber}}</b></span>
                 <span *ngIf="vehiclePaymentModel.response.vehicleInsuranceNumber==null"><b>N/A</b></span>
                </p>

                <p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title">Vehicle Expiry Date<br>
                  <span><b>{{vehiclePaymentModel.response.vehicleId.insuranceExpiryDate | date:'dd/MM/yyyy'}}</b></span></p>
                  <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title">Vehicle Expiry Date <br>
                   <span *ngIf="vehiclePaymentModel.response.insuranceExpiryDate!=null"><b>{{vehiclePaymentModel.response.insuranceExpiryDate | date:'dd/MM/yyyy' }}</b></span>
                   <span *ngIf="vehiclePaymentModel.response.insuranceExpiryDate==null"><b>N/A</b></span>
                  </p>

                  <p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title">AMC/MMC Type <br>
                    <span *ngIf="vehiclePaymentModel.response.vehicleId.amcMmcType!=null"><b>{{vehiclePaymentModel.response.vehicleId.amcMmcType}}</b></span>
                    <span *ngIf="vehiclePaymentModel.response.vehicleId.amcMmcType==null"><b>N/A</b></span>
                  </p>
                    <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title">AMC/MMC Type <br>
                     <span *ngIf="vehiclePaymentModel.response.amcMmcType!=null"><b>{{vehiclePaymentModel.response.amcMmcType}}</b></span>
                     <span *ngIf="vehiclePaymentModel.response.amcMmcType==null"><b>N/A</b></span>
                    </p>
                
                    <h6>Vehicle Permnant Location Details </h6>

                <p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title">Address<br>
                  <span><b>{{vehiclePaymentModel.response.vehicleId.address}}</b></span></p>
                  <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title">Address<br>
                   <span *ngIf="vehiclePaymentModel.response.address!=null"><b>{{vehiclePaymentModel.response.address}}</b></span>
                   <span *ngIf="vehiclePaymentModel.response.address==null"><b>N/A</b></span>
                  </p>   

                  <p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title">State<br>
                    <span><b>{{vehiclePaymentModel.response.vehicleId.stateId.stateName}}</b></span></p>
                    <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title">State<br>
                     <span *ngIf="vehiclePaymentModel.response.stateId!=null"><b>{{vehiclePaymentModel.response.stateId.stateName}}</b></span>
                     <span *ngIf="vehiclePaymentModel.response.stateId==null"><b>N/A</b></span>
                    </p>  

                    <p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title">District<br>
                      <span><b>{{vehiclePaymentModel.response.vehicleId.cityId.cityName}}</b></span></p>
                      <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title">District<br>
                       <span *ngIf="vehiclePaymentModel.response.cityId!=null"><b>{{vehiclePaymentModel.response.cityId.cityName}}</b></span>
                       <span *ngIf="vehiclePaymentModel.response.cityId==null"><b>N/A</b></span>
                      </p>    
                      <p *ngIf="vehiclePaymentModel.response.id!=null" class="card-title">Pincode<br>
                        <span><b>{{vehiclePaymentModel.response.vehicleId.vehicleRunningPincode}}</b></span></p>
                        <p *ngIf="vehiclePaymentModel.response.id==null" class="card-title">Pincode<br>
                         <span *ngIf="vehiclePaymentModel.response.vehicleRunningPincode!=null"><b>{{vehiclePaymentModel.response.vehicleRunningPincode}}</b></span>
                         <span *ngIf="vehiclePaymentModel.response.vehicleRunningPincode==null"><b>N/A</b></span>
                        </p> 
                             
			
    	</div> -->
    	<!-- <div class="flex extradiv">
        <h5>Payment Details </h5>
        <p class="card-title">Payment Mode   <br>
          <span *ngIf="vehiclePaymentModel.response.id!=null">
            <b *ngIf="vehiclePaymentModel.response.paymentMode!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{vehiclePaymentModel.response.paymentMode}}</b>
            <b *ngIf="vehiclePaymentModel.response.paymentMode==null">N/A</b>
          </span>
          <span *ngIf="vehiclePaymentModel.response.id==null"><b>-</b></span>
        </p>
        <p class="card-title">Payment Amount   <br>
          <span *ngIf="vehiclePaymentModel.response.id!=null"><b>{{vehiclePaymentModel.response.totalPayableAmount | number : '1.2-2'}}</b></span>
          <span *ngIf="vehiclePaymentModel.response.id==null"><b>-</b></span>
        </p>
        <p class="card-title">Transaction ID  <br>
          <span *ngIf="vehiclePaymentModel.response.id!=null"><b>{{vehiclePaymentModel.response.trackId}}</b></span>
          <span *ngIf="vehiclePaymentModel.response.id==null"><b>-</b></span>
        </p>
        <p class="card-title">Payment Status   <br>
          <span class="v-align-middle ht-active" *ngIf="vehiclePaymentModel.response.id!=null"><b>{{vehiclePaymentModel.response.paymentStatus}}</b></span>
          <span *ngIf="vehiclePaymentModel.response.id==null"><b>-</b></span>
        </p> 
			<p class="card-title">Transaction Date & Time   <br>
        <b *ngIf="vehiclePaymentModel.response.id!=null">{{vehiclePaymentModel.response.transactionDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</b>
        <b *ngIf="vehiclePaymentModel.response.id==null">-</b>
      </p>
      </div> -->
    <!-- </div> -->
    <!-- <h5>Vehicle Image </h5>
    <div class=" profile images flex">
      <img class="modal-content-style"  [src]="imageToShowvehicle1">
      <img class="modal-content-style"  [src]="imageToShowvehicle2">
      <img class="modal-content-style"  [src]="imageToShowvehicle3">
      <img class="modal-content-style"  [src]="imageToShowvehicle4">
    </div> -->
  <!-- </div> -->
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>

<ng-template #content2 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">View KYC Image</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
    </div>
    <hr class="beautiful-line">
    <div>
      <h6>Front Image</h6>
      <img class="modal-content"  [src]="imageToShow" width="auto" height="auto" alt="Place image title" *ngIf="!isImageLoading; else noImageFound">
    </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">close</button>
  </div>
</ng-template>

<ng-template #content3 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">View Location</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
    </div>
    <hr class="beautiful-line">
  <agm-map [latitude]="lat" [longitude]="lan" style="height: 300px">
    <agm-marker [latitude]="lat" [longitude]="lan"></agm-marker>
  </agm-map>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">close</button>
  </div>
</ng-template>

<ng-template #content4 let-modal>
  <div class="modal-header">
    <h6>Agent View Page</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="container"
  fxLayout
>
  <div class="item item-1">
    <p class="card-title"><b>Agent Name</b><br>
      <span *ngIf="agantModel.firstName!=null">{{agantModel.firstName}} {{agantModel.lastName}}</span>
        <span *ngIf="agantModel.firstName==null">  N/A</span>
    </p>
  </div>
  <div class="item item-2" style="margin-left: 40px;">
    <p class="card-title"><b>Mobile Number</b><br>
      <span *ngIf="agantModel.mobileNumber!=null">{{agantModel.mobileNumber}}</span>
        <span *ngIf="agantModel.mobileNumber==null">  N/A</span>
    </p>
  </div>
  <div class="item item-3" style="margin-left: 40px;">
    <p class="card-title"><b>Email Address</b><br>
      <span *ngIf="agantModel.emailAddress!=null">{{agantModel.emailAddress}}</span>
        <span *ngIf="agantModel.emailAddress==null">  N/A</span>
    </p>
  </div>
  <div class="item item-4" style="margin-left: 40px;">
    <p class="card-title"><b>Account Status</b><br>
        <span *ngIf="agantModel.accountStatus===1">Active</span>
        <span *ngIf="agantModel.accountStatus===2">InActive</span>
    </p>
  </div>
  <div class="item item-5" style="margin-left: 40px;">
    <p class="card-title"><b>DOB</b><br>
      <span *ngIf="agantModel.dateOfBirth!=null">  {{agantModel.dateOfBirth | date:'dd/MM/yyyy' }}</span>
        <span *ngIf="agantModel.dateOfBirth==null">  N/A</span>
  </p>
  </div>
  <div class="item item-6" style="margin-left: 40px;">
    <p class="card-title"><b>License Number</b><br>
      <span *ngIf="agantModel.agentVehicleMapping!=null && agantModel.agentVehicleMapping[0].licenceNo!=null">{{agantModel.agentVehicleMapping[0].licenceNo}}</span>
        <span *ngIf="agantModel.agentVehicleMapping==null">  N/A</span>
  </p>
  </div>
  <div class="item item-6" style="margin-left: 40px;">
    <p class="card-title"><b>GST Number</b><br>
      <span *ngIf="agantModel.gstNumber!=null">{{agantModel.gstNumber}}</span>
        <span *ngIf="agantModel.gstNumber==null">  N/A</span>
  </p>
  </div>
  <div class="item item-7" style="margin-left: 40px;">
    <p class="card-title"><b>License Expiry Date</b><br>
      <span *ngIf="agantModel.agentVehicleMapping!=null && agantModel.agentVehicleMapping[0].licenceExpiryDate!=null">{{agantModel.agentVehicleMapping[0].licenceExpiryDate |date:'dd/MM/yyyy' }}</span>
        <span *ngIf="agantModel.agentVehicleMapping==null">  N/A</span>
  </p>
  </div>
  <div class="item item-7" style="margin-left: 40px;">
    <p class="card-title"><b>Pan Number</b><br>
      <span *ngIf="agantModel.agentVehicleMapping!=null && agantModel.agentVehicleMapping[0].panNumber!=null">{{agantModel.agentVehicleMapping[0].panNumber}}</span>
        <span *ngIf="agantModel.agentVehicleMapping==null">  N/A</span>
  </p>
  </div>
  <div class="item item-7" style="margin-left: 40px;">
    <p class="card-title"><b>Aadhar Number</b><br>
      <span *ngIf="agantModel.agentVehicleMapping!=null && agantModel.agentVehicleMapping[0].aadharNumber!=null">{{agantModel.agentVehicleMapping[0].aadharNumber}}</span>
        <span *ngIf="agantModel.agentVehicleMapping==null">  N/A</span>
  </p>
  </div>
  </div>
  <!-- <div class="modal-body">
    <div class="wrapper">
    	<div class="flex extradiv">
    		<p class="card-title"><span><b>Agent Name</b></span></p>
    		<p class="card-title"><span><b>Mobile Number</b></span></p>
    		<p class="card-title1"><span><b>Email Address</b></span></p>
        <p class="card-title"><span><b>Account Status</b></span></p>
        <p class="card-title"><span><b>DOB</b></span></p>
        <p class="card-title"><span><b>License Number</b></span></p>
        <p class="card-title"><span><b>License Expiry Date</b></span></p>
    	</div>
    	<div class="flex extradiv">
        <p *ngIf="agantModel.firstName!=null">  {{agantModel.firstName}}</p>
        <p *ngIf="agantModel.firstName==null">  N/A</p>
        <p>  {{agantModel.mobileNumber}}</p>
        <p>  {{agantModel.emailAddress}}</p>
        <p *ngIf="agantModel.accountStatus===1">Active</p>
        <p *ngIf="agantModel.accountStatus===2">InActive</p>
        <p *ngIf="agantModel.dateOfBirth!=null">  {{agantModel.dateOfBirth | date:'dd/MM/yyyy' }}</p>
        <p *ngIf="agantModel.dateOfBirth==null">  N/A</p>
        <p *ngIf="agantModel.agentVehicleMapping!=null && agantModel.agentVehicleMapping[0].licenceNo!=null">{{agantModel.agentVehicleMapping[0].licenceNo}}</p>
        <p *ngIf="agantModel.agentVehicleMapping==null">  N/A</p>
        <p *ngIf="agantModel.agentVehicleMapping!=null && agantModel.agentVehicleMapping[0].licenceExpiryDate!=null">{{agantModel.agentVehicleMapping[0].licenceExpiryDate |date:'dd/MM/yyyy' }}</p>
        <p *ngIf="agantModel.agentVehicleMapping==null">  N/A</p>
    	</div>
    </div>
  </div> -->
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
  </div>
</ng-template>

<ng-template #content5 let-modal>
  <div class="modal-header">
    <h6>View Settlement Details</h6>
    <hr class="beautiful-line">
    <br>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="wrapper">
    	<div class="flex extradiv">
    		<p class="card-title"><span><b>Transaction Id</b></span></p>
    		<p class="card-title"><span><b>Order Id</b></span></p>
    		<p class="card-title1"><span><b>Settlement Date Time</b></span></p>
        <p class="card-title"><span><b>Payout Amount</b></span></p>
        <p class="card-title"><span><b>Bank Reference</b></span></p>
        <p class="card-title"><span><b>Description</b></span></p>
        <p class="card-title"><span><b>Gross Transaction Amount</b></span></p>
        <p class="card-title"><span><b>Applicable Tdr Percent</b></span></p>
        <p class="card-title"><span><b>Applicable Tdr FixedFee</b></span></p>
        <p class="card-title"><span><b>Percent Tdr PaidByMerchant</b></span></p>
        <p class="card-title"><span><b>TdrAmount</b></span></p>
        <p class="card-title"><span><b>TaxonTdrAmount</b></span></p>
        <p class="card-title"><span><b>AmountReimbursed</b></span></p>
        <p class="card-title1"><span><b>Payment Mode</b></span></p>
        <p class="card-title"><span><b>Payment Channel</b></span></p>
        <p class="card-title"><span><b>Receiver Type</b></span></p>
    	</div>
    	<div class="flex extradiv">
        <p  *ngIf="viewSettlement.response.transactionId!=null">  {{viewSettlement.response.transactionId}}</p>
        <p  *ngIf="viewSettlement.response.transactionId==null">  N/A</p>
        <p  *ngIf="viewSettlement.response.orderId!=null"> {{viewSettlement.response.orderId}}</p>
        <p  *ngIf="viewSettlement.response.orderId==null">N/A</p>
        <p  *ngIf="viewSettlement.response.settlementDatetime!=null"> {{viewSettlement.response.settlementDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</p>
        <p  *ngIf="viewSettlement.response.settlementDatetime==null"> N/A</p>
        <p  *ngIf="viewSettlement.response.grossTransactionAmount!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{viewSettlement.response.grossTransactionAmount | number : '1.2-2'}}</p>
        <p  *ngIf="viewSettlement.response.grossTransactionAmount==null"> N/A</p>
        <p *ngIf="viewSettlement.response.bankReference!=null"> {{viewSettlement.response.bankReference}}</p>
        <p  *ngIf="viewSettlement.response.bankReference==null">N/A</p>
        <p  *ngIf="viewSettlement.response.grossTransactionAmount!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{viewSettlement.response.grossTransactionAmount | number : '1.2-2'}}</p>
        <p  *ngIf="viewSettlement.response.grossTransactionAmount==null">N/A</p>
        <p  *ngIf="viewSettlement.response.applicableTdrPercent!=null"> {{viewSettlement.response.applicableTdrPercent}}</p>
        <p  *ngIf="viewSettlement.response.applicableTdrPercent==null">N/A</p>
        <p  *ngIf="viewSettlement.response.applicableTdrFixedFee!=null"> {{viewSettlement.response.applicableTdrFixedFee}}</p>
        <p  *ngIf="viewSettlement.response.applicableTdrFixedFee==null">N/A</p>
        <p  *ngIf="viewSettlement.response.percentTdrPaidByMerchant!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{viewSettlement.response.percentTdrPaidByMerchant | number : '1.2-2'}}</p>
        <p *ngIf="viewSettlement.response.percentTdrPaidByMerchant==null">N/A</p>
        <p  *ngIf="viewSettlement.response.tdrAmount!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{viewSettlement.response.tdrAmount | number : '1.2-2'}}</p>
        <p  *ngIf="viewSettlement.response.tdrAmount==null">N/A</p>
        <p  *ngIf="viewSettlement.response.taxonTdrAmount!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{viewSettlement.response.taxonTdrAmount | number : '1.2-2'}}</p>
        <p  *ngIf="viewSettlement.response.taxonTdrAmount==null">N/A</p>
        <p  *ngIf="viewSettlement.response.amountReimbursed!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{viewSettlement.response.amountReimbursed | number : '1.2-2'}}</p>
        <p  *ngIf="viewSettlement.response.amountReimbursed==null">N/A</p>
        <p  *ngIf="viewSettlement.response.paymentMode!=null"> {{viewSettlement.response.paymentMode}}</p>
        <p  *ngIf="viewSettlement.response.paymentMode==null">N/A</p>
        <p  *ngIf="viewSettlement.response.paymentChannel!=null"> {{viewSettlement.response.paymentChannel}}</p>  
        <p  *ngIf="viewSettlement.response.paymentChannel==null"> N/A</p>  
        <p  *ngIf="viewSettlement.response.recieverType!=null"> {{viewSettlement.response.recieverType}}</p>
        <p  *ngIf="viewSettlement.response.recieverType==null"> N/A</p>
    	</div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
  </div>
</ng-template>

<ng-template #content6 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Asset View Image</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <img class="modal-content"  [src]="imageasset" height="auto" width="auto" *ngIf="!isImageassetLoading; else noImageFound">
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">close</button>
  </div>
</ng-template>

<ng-template #content7 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Reason for cancellation of Order</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
  <form>
      <br>
      <div class="ch-form-group">
          <select id="cancelmessage"  name="cancelmessage" class="form-control" style="width: 100%;">
            <option *ngFor="let cancel of cancelMessageModel.response" value={{cancel.id}}>{{cancel.cancellationMessage}}</option>
          </select>
      </div>	
      <br>
      <div class="ch-form-group">
           <textarea placeholder="Should not exceed more than 150 characters" class="form-control textarea" name="cancelremarks" maxlength="150" id="cancelremarks" rows="3"></textarea>
      </div>
      <br>
    <b>Note</b> : <span style="color: grey;">Should not exceed more than 150 characters</span> 
      </form>
      <br>
      <div *ngIf="errorFlag1" style="color: red;">{{errorMessage}}</div>
    </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" style="color: green;" (click)="cancelOrder()">Submit</button>
  </div>
</ng-template>

<ng-template #content8 let-modal>
  <div class="modal-header">
    <h5>Assign Vehicle</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="container"
  fxLayout
>

<div class="item item-1">
  <p><b>Order ID</b></p>
  <span>{{orderDetails.response.orderId}}</span>
</div>
<div class="item item-2" style="margin-left: 25px;">
  <p><b>Customer Name</b></p>
  <span style="word-wrap: break-word;">{{orderDetails.response.customerId.firstName}} {{orderDetails.response.customerId.lastName}}</span>
</div>
<div class="item item-3" style="margin-left: 25px;">
  <p><b>Customer Mobile</b></p>
  <span>{{orderDetails.response.customerId.mobileNumber}}</span>
</div>
<div class="item item-4" style="margin-left: 25px;">
  <p><b>Status</b></p>
  <span *ngIf="orderDetails.response.status==='Pending'"><span class="ht-Pending"><b>{{orderDetails.response.status}}</b></span></span>
  <span *ngIf="orderDetails.response.status==='Cancelled'"><span style="color: red;"><b>{{orderDetails.response.status}}</b></span></span>
  <span *ngIf="orderDetails.response.status==='Inprogress'"><span style="color: blue;"><b>{{orderDetails.response.status}}</b></span></span>
  <span *ngIf="orderDetails.response.status==='Completed'"><span class="ht-active"><b>{{orderDetails.response.status}}</b></span></span>
</div>
<div class="item item-5" style="margin-left: 25px;">
  <p><b>Booking Date & Time</b></p>
  <span>{{orderDetails.response.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span>
</div>
<div class="item item-6" style="margin-left: 25px;">
  <p><b>Delivery Date & Time</b></p>
  <span>N/A</span>
</div>
<div class="item item-7" style="margin-left: 25px;">
  <p><b>Fuel</b></p>
  <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.fuelType.fuelTypeName}}</span>
      <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
</div>
<div class="item item-8" style="margin-left: 25px;">
  <p><b>Quantity in Ltrs</b></p>
  <span >{{orderDetails.response.quantity}}</span>
</div>
<br>
  </div>
  <br>
  <div class="container"
  fxLayout
>
  <div class="item item-1">
    <p><b>Vehicle Name</b></p>
    <div class="ch-form-group">
      <select id="countryFormControl" value="countryFormControl" class="form-control" #mySelect (change)='onOptionsSelected(mySelect.value)'>
        <option *ngFor="let vehicle of activeVehicle.response" value={{vehicle.vehicleId}}>{{vehicle.vehicleNumber}} ({{vehicle.userId.firstName}} {{vehicle.userId.lastName}})</option>
      </select>
  </div>
  </div>
  <div class="item item-2" style="margin-left: 25px;">
    <p><b>Delivery Agent Mobile</b></p>
    <span *ngIf="check==null">
      <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.userId.firstName}} {{orderDetails.response.vehicleId.userId.lastName}}</span>
      <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
    </span>
    <span *ngIf="check!=null">{{vehicle.userId.mobileNumber}}</span>
  </div>
  <div class="item item-3" style="margin-left: 25px;">
    <p><b>Delivery Agent Email</b></p>
    <span *ngIf="check==null">
      <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.userId.mobileNumber}}</span>
          <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
    </span>
    <span *ngIf="check!=null">{{vehicle.userId.emailAddress}}</span>
  </div>
  <div class="item item-4" style="margin-left: 25px;">
    <p><b>Vehicle Number</b></p>
    <span *ngIf="check==null">{{orderDetails.response.vehicleId.vehicleNumber}}</span>
    <span *ngIf="check!=null">{{vehicle.vehicleNumber}}</span>
  </div>
  <div class="item item-5" style="margin-left: 25px;">
    <p><b>Delivery location</b></p>
    <span>{{orderDetails.response.deliveryAddress}}</span>
  </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="assign()" (click)="modal.close('Save click')">Assign</button>
  </div>
</ng-template>

<ng-template #content10 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">View Location</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
    </div>
    <hr class="beautiful-line">
  <agm-map [latitude]="lat" [longitude]="lan" style="height: 300px">
    <agm-marker [latitude]="lat" [longitude]="lan"></agm-marker>
  </agm-map>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">close</button>
  </div>
</ng-template>

<ng-template #reject let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Do you want unblock the user?</label>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="unblock()" (click)="modal.close('Save click')">yes</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">no</button>
  </div>
</ng-template>

<ng-template #content9 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Default Payment Method</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
  <form>
      <br>
      <div class="form-group" >
        <div *ngFor="let paymentMode of paymentModes.response;">
          <ul class="menu">
            <p ><li>
              <input  type="checkbox" value=" {{paymentMode.id}}" (change)="assignPayment(paymentMode.id,$event)">
              <label for="tall">{{paymentMode.paymentMode}}</label></li></p>
                
          </ul>
         
    </div> 
      </div>
      <br>
    
      </form>
      <br>
      <div *ngIf="errorFlag1" style="color: red;">{{errorMessage}}</div>
    </div>
    <hr class="beautiful-line">
  <div class="modal-footer">{{partnerMappingModel.response | json}}
    <button type="button" class="btn btn-outline-dark" style="color: green;" (click)="addpaymentmethod()">Submit</button>
  </div>
</ng-template>
<ng-template #content6 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">View Additional Charges</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body" *ngFor="let charges of chargesResponse.response;">
  <form>
      <br>
      <div class="form-group" >
        <label for="dateOfBirth">Charges Title : </label>
        {{charges.chargesTitle}}
      </div>
      <br>
      <div class="form-group" >
        <label for="dateOfBirth">Additional Charges : </label>
        {{charges.additionalCharges}}
      </div>
    
      </form>
      <br>
    
    </div>
    <hr class="beautiful-line">
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" style="color: green;" (click)="openUpdateContent(content7,chargesResponse.response[0].chargesTitle,chargesResponse.response[0].additionalCharges,chargesResponse.response[0].id)">Update</button>
      <button type="button" class="btn btn-outline-dark" style="color: red;" (click)="delete(chargesResponse.response[0].id)">Delete</button>
    </div>
</ng-template>
<ng-template #content7 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Additional Charges</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
  <form>
      <br>
      <div class="form-group" >
        <label for="dateOfBirth">Charges Title *</label>
            <input type="text" class="select-form-style" style="margin-left: 10px; " name="chargesTitle" id="chargesTitle" [(ngModel)]="chargesTitle"/>
      </div>
      <br>
      <div class="form-group" >
        <label for="dateOfBirth">Additional Charges *</label>
            <input type="text" class="select-form-style" style="margin-left: 10px; " name="additionalCharges" id="additionalCharges" [(ngModel)]="additionalCharges" (keypress)="allowNumericDigitsOnlyOnKeyUp($event)"/>
      </div>
    
      </form>
      <br>
      <div *ngIf="errorFlag1" style="color: red;">{{errorMessage}}</div>
    </div>
    <hr class="beautiful-line">
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" style="color: green;" (click)="updateCharges(chargesId)">Submit</button>
  </div>
</ng-template>
