<app-header></app-header>
<div class="container  container-top-dashboard-section" style="margin-top: 110px">
  
   
<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
	 <div class="btn-section-wrapper"><button type="button" class="btn btn-success" (click)="open(content)"><i class="fa fa-plus" aria-hidden="true"></i>
 Add Department</button>
  	

</div>
  <li [ngbNavItem]="1">
    <a ngbNavLink>Department Master</a>
    <ng-template ngbNavContent>
      <div class="card-body">
              <div class="table-responsive">
                <table class="table table-hover" id="basicTable">
                  <thead>
                    <tr> 
                      <!-- NOTE * : Inline Style Width For Table Cell is Required as it may differ from user to user
                        Comman Practice Followed
                        -->
                      <th align="center" style="width:80px">Sl.No.</th>
                      <th align="left"  style="
   
">Department Name</th>
                      <th align="left"  style="
   
">Department Code</th>
                      <th align="left"  style="
   
">Created By</th>
                      <th width="200" align="left"  style="
   
">Created Date &amp; Time</th>
                      <th align="center" style="width:150">Status</th>
                      <th align="center" style="width:30"><span style="width:15%">Action</span></th>
                      <th align="center" style="width:25%">Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td align="center" class="v-align-middle "><p>1</p></td>
                      <td align="left" class="v-align-middle">Operation</td>
                      <td align="left" class="v-align-middle">001</td>
                      <td align="left" class="v-align-middle">Admin</td>
                      <td align="left" class="v-align-middle"><p>21/09/2020 &amp; 09:00 am</p></td>
                      <td class="v-align-middle ht-inactive"><p>Active</p></td>
                      <td class="v-align-middle"><button class="btn btn-danger"><i class="fa fa-trash-o" aria-hidden="true"></i>
</button></td>
                      <td class="v-align-middle"><button class="btn btn-info"><i class="fa fa-pencil" aria-hidden="true"></i>
</button></td>
                    </tr>
                    <tr>
                      <td align="center" class="v-align-middle "><p>2</p></td>
                      <td align="left" class="v-align-middle">Sales</td>
                      <td align="left" class="v-align-middle">002</td>
                      <td align="left" class="v-align-middle">Admin</td>
                      <td align="left" class="v-align-middle"><p>21/09/2020 &amp; 09:00 am</p></td>
                      <td class="v-align-middle ht-inactive"><p>Active</p></td>
                      <td class="v-align-middle"><button class="btn btn-danger"><i class="fa fa-trash-o" aria-hidden="true"></i> </button></td>
                      <td class="v-align-middle"><button class="btn btn-info"><i class="fa fa-pencil" aria-hidden="true"></i> </button></td>
                    </tr>
                    <tr>
                      <td align="center" class="v-align-middle "><p>3</p></td>
                      <td align="left" class="v-align-middle">Support</td>
                      <td align="left" class="v-align-middle">003</td>
                      <td align="left" class="v-align-middle">Admin</td>
                      <td align="left" class="v-align-middle"><p>21/09/2020 &amp; 09:00 am</p></td>
                      <td class="v-align-middle ht-inactive"><p>Active</p></td>
                      <td class="v-align-middle"><button class="btn btn-danger"><i class="fa fa-trash-o" aria-hidden="true"></i> </button></td>
                      <td class="v-align-middle"><button class="btn btn-info"><i class="fa fa-pencil" aria-hidden="true"></i> </button></td>
                    </tr>
                    <tr>
                      <td align="center" class="v-align-middle "><p>4</p></td>
                      <td align="left" class="v-align-middle">It</td>
                      <td align="left" class="v-align-middle">004</td>
                      <td align="left" class="v-align-middle">Admin</td>
                      <td align="left" class="v-align-middle"><p>21/09/2020 &amp; 09:00 am</p></td>
                      <td class="v-align-middle ht-inactive"><p>Active</p></td>
                      <td class="v-align-middle"><button class="btn btn-danger"><i class="fa fa-trash-o" aria-hidden="true"></i> </button></td>
                      <td class="v-align-middle"><button class="btn btn-info"><i class="fa fa-pencil" aria-hidden="true"></i> </button></td>
                    </tr>
                  </tbody>
                </table>
          </div>
            </div>



      
    </ng-template>
  </li>
  <li [ngbNavItem]="2" style="display: none;">
    <a ngbNavLink>Two</a>
    <ng-template ngbNavContent>
      <p>Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan four loko
        farm-to-table craft beer twee. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts
        ullamco ad vinyl cillum PBR. Homo nostrud organic, assumenda labore aesthetic magna delectus mollit. Keytar
        helvetica VHS salvia yr, vero magna velit sapiente labore stumptown. Vegan fanny pack odio cillum wes anderson
        8-bit, sustainable jean shorts beard ut DIY ethical culpa terry richardson biodiesel. Art party scenester
        stumptown, tumblr butcher vero sint qui sapiente accusamus tattooed echo park.</p>
    </ng-template>
  </li>
  <li [ngbNavItem]="3" style="display: none;">
    <a ngbNavLink>Three</a>
    <ng-template ngbNavContent>
      <p>Sed commodo, leo at suscipit dictum, quam est porttitor sapien, eget sodales nibh elit id diam. Nulla facilisi.
        Donec egestas ligula vitae odio interdum aliquet. Duis lectus turpis, luctus eget tincidunt eu, congue et odio.
        Duis pharetra et nisl at faucibus. Quisque luctus pulvinar arcu, et molestie lectus ultrices et. Sed diam urna,
        egestas ut ipsum vel, volutpat volutpat neque. Praesent fringilla tortor arcu. Vivamus faucibus nisl enim, nec
        tristique ipsum euismod facilisis. Morbi ut bibendum est, eu tincidunt odio. Orci varius natoque penatibus et
        magnis dis parturient montes, nascetur ridiculus mus. Mauris aliquet odio ac lorem aliquet ultricies in eget
        neque. Phasellus nec tortor vel tellus pulvinar feugiat.</p>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>


</div>



<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Department</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body master-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Department Name</label>
        <input type="text" class="form-control"  placeholder="">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Department Code</label>
        <input type="text" class="form-control"  placeholder="">
      </div>

      <div class="form-group">
        <label for="dateOfBirth">Status</label>
        <div class="dropdown">
  <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
Active  </button>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <a class="dropdown-item" href="#">Active</a>
    <a class="dropdown-item" href="#">Inactive</a>
    
  </div>
</div>
      </div>


     

    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Add Deapartment</button>
    <!-- <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">no</button> -->
  </div>
</ng-template>