
import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { GenericResponse } from '../model/genricmodelresponse';
import { GenericResponseModel } from '../model/genricresponse';
import { RoleModel } from '../model/role';
import { RoleService } from '../services/role.service';

@Component({
  selector: 'app-master-role',
  templateUrl: './master-role.component.html',
  styleUrls: ['./master-role.component.css']
})
export class MasterRoleComponent {

 public isCollapsed = false;
 active = 1;

closeResult = '';
roleDetails:GenericResponseModel;
deleteRolemodel:GenericResponse;
roleModel:RoleModel;
message:String;
failureflag:boolean;
successflag:boolean;

  constructor(private modalService: NgbModal,private roleservice:RoleService) {}

  ngOnInit() : void {
    this.roleservice.getAllRoles().subscribe(data=>{
      this.roleDetails = data;
    })
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open1(content1,id) {
    this.roleservice.getRoleById(id).subscribe(data=>{
      this.roleModel= data;
    });
    this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  deleteRole(id){
    this.roleservice.deleteRole(id).subscribe(data=>{
      this.deleteRolemodel = data;
      if(this.deleteRolemodel.response.flag==1){
        this.failureflag = false;
        this.successflag = true;
        this.message = data.responseMessage;
        setTimeout(function() {
          this.successflag = false;
          window.location.reload();
      }.bind(this), 5000);
        
      }else{
        this.successflag = false;
        this.failureflag = true;
        this.message = data.responseMessage;

        setTimeout(function() {
          this.failureflag = false;
          window.location.reload();
      }.bind(this), 5000);

      }
    })
  }

  addRole(){
    let roleName = (<HTMLInputElement>document.getElementById('roleName')).value;
    let roleCode = (<HTMLInputElement>document.getElementById('roleCode')).value;
    let roleStatus = (<HTMLInputElement>document.getElementById('roleStatus')).value;

    if(roleName!=null&& roleCode!=null&& roleStatus!=null && roleName!=="" && roleCode!=="" && roleStatus!==""){
      let formData= {
        roleName:roleName,
        roleCode:roleCode,
        roleStatus:Number(roleStatus),
        modifiedBy:sessionStorage.getItem("username"),
        createdBy:sessionStorage.getItem("username")
      }
      this.roleservice.saveRole(formData).subscribe(data=>{
        if(data.response.flag==1){
          this.failureflag = false;
          this.successflag = true;
          this.message = data.responseMessage;
          setTimeout(function() {
            this.successflag = false;
            window.location.reload();
        }.bind(this), 5000);
        }else{
          this.successflag = false;
          this.failureflag = true;
          this.message = data.responseMessage;
  
          setTimeout(function() {
            this.failureflag = false;
            window.location.reload();
        }.bind(this), 5000);
  
        }
      })
    }else{
      this.failureflag = true;
          this.message = "Please Enter Mandatory Fields!";

          setTimeout(function() {
            this.failureflag = false;
            window.location.reload();
        }.bind(this), 5000);
    }
  }

  updateRole(){

    if(this.roleModel.roleName!=null&& this.roleModel.roleCode!=null&& this.roleModel.roleStatus!=null && this.roleModel.roleName!=="" && this.roleModel.roleCode!==""){
      let formData= {
        roleName:this.roleModel.roleName,
        roleCode:this.roleModel.roleCode,
        roleStatus:this.roleModel.roleStatus,
        modifiedBy:sessionStorage.getItem("username"),
        createdBy:sessionStorage.getItem("username")
      }
      this.roleservice.upadte(this.roleModel.roleId,formData).subscribe(data=>{
        if(data.response.flag==1){
          this.failureflag = false;
          this.successflag = true;
          this.message = data.responseMessage;
          setTimeout(function() {
            this.successflag = false;
            window.location.reload();
        }.bind(this), 5000);
  
        }else{
          this.successflag = false;
          this.failureflag = true;
          this.message = data.responseMessage;
  
          setTimeout(function() {
            this.failureflag = false;
            window.location.reload();
        }.bind(this), 5000);
  
        }
      })
    }else{
      this.failureflag = true;
          this.message = "Please Enter Mandatory Fields!";

          setTimeout(function() {
            this.failureflag = false;
            window.location.reload();
        }.bind(this), 5000);
    }
    
  }


}