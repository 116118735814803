import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { KycMasterModel } from '../model/kycmaster';
import { GenericResponseModel } from '../model/genricresponse';
import { VehicleAttachModel } from '../model/vehicleModel';
import { KycDocumentService } from '../services/kycdocument.service';
import { PartnersService } from '../services/partners.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as moment from 'moment';
import { CouponService } from '../services/coupon.service';

@Component({
  selector: 'app-master-coupon',
  templateUrl: './master-coupon.component.html',
  styleUrls: ['./master-coupon.component.css']
})
export class MasterCouponComponent {
 
  active = 1;
  closeResult = '';
  public isCollapsed = false;
  kycdocument:GenericResponseModel;
  couponModel:GenericResponseModel;
  kycMasterModel:KycMasterModel;
  vehicleAttachModel:VehicleAttachModel[];
  errorFlag:boolean;
  errorFlag1:boolean;
  errorMessage:String;
  refreshflag:boolean;
  dateCheck:String;
  today=new Date();
  radioCheck:boolean = false;
  defaultValue:Number;
  status:any;
  effeDate:any;
  validDate:any;
  couponseid:any;
  couponcode:any;
  couponamt:any;
  minpuramt:any;
  checkperamt:any;
  deleteUserId:Number;
  constructor(private router: Router,private modalService: NgbModal,private kycdocumentservice:KycDocumentService,private partnerservice:PartnersService,private spinnerService:NgxSpinnerService,private couponservice:CouponService) {}

  ngOnInit() : void {
    let date  = moment(new Date(this.today)).format("yyyy-MM-DDTHH:MM").toString();
    this.dateCheck = date; 
      this.kycdocumentservice.getAllKyc().subscribe(data=>{
        this.kycdocument=data;
      },err => {
        if(err.error.statusCode==406){
          this.router.navigate(['/login']);
        }else if(err.error.statusCode==500){
          this.router.navigate(['/page-error']);
        }else if(err.error.statusCode==404){
          this.router.navigate(['/pagenotfound']);
        }
     });
      this.couponservice.getAllCoupons().subscribe(data=>{
        this.couponModel = data;
      },err => {
        console.log(err.error.statusCode);
        if(err.error.statusCode==406){
          this.router.navigate(['/login']);
        }
     })
  }

  delete(){
    let userId = this.deleteUserId;
    this.couponservice.deleteCoupon(userId).subscribe(data=>{
      if(data.statusCode==200){
        Swal.fire({
          html: '<pre>' + data.responseMessage + '</pre>',
          customClass: {
            popup: 'format-pre'
          }
        }).then((value) => {
          window.location.reload();
        });; ;
      }
    },err => {
      if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
    })
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open1(content1,id,couponcode,couponamount,minpuramt,status,efffrom,validupto,checkperamt) {
    let effdate  = moment(new Date(efffrom)).format("yyyy-MM-DDThh:mm").toString();
    let validate  = moment(new Date(validupto)).format("yyyy-MM-DDThh:mm").toString();
    this.status = status;
    this.effeDate = effdate;
    this.validDate = validate;
    this.couponseid = id;
    this.couponcode = couponcode;
    this.couponamt = couponamount;
    this.minpuramt = minpuramt;
    this.checkperamt = checkperamt
    this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open2(reject,viewId){
    this.deleteUserId = viewId;
    this.modalService.open(reject, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  restrictwhitespace(e){
    const charCode = e.which ? e.which : e.keyCode;
    if(charCode== 32)
    {
        e.preventDefault();
    }else{
      return true;
    }
  }

  check(id){
    if(id==1){
      this.radioCheck = false;
      this.checkperamt = 1;
    }else if(id==2){
      this.radioCheck = true;
      this.checkperamt = 2;
    }
  }

  allowOnlyNumber(e){
    const charCode = e.which ? e.which : e.keyCode;
    if(charCode > 31 && (charCode < 48 || charCode > 57)){
        e.preventDefault();
    }else{
      return true;
    }
  }

  handleChange(){
    let otherinfo = (<HTMLInputElement>document.getElementById('otherinfo')).value;
    if(Number(otherinfo)>100){
      this.defaultValue = 99;
      this.couponamt = 99;
    }else{
      this.defaultValue = Number(otherinfo);
      this.couponamt = Number(otherinfo);
    }
  }

  allowNumericDigitsOnlyOnKeyUp(e) {		
    const charCode = e.which ? e.which : e.keyCode;
    if(charCode==46){
      return true;
    }else if(charCode > 31 && (charCode < 48 || charCode > 57)){
      if(charCode==46){
        return true;
      }else{
        e.preventDefault();
      }
    }else{
      return true;
    }
  }

  addCoupon(){
    let couponcode = (<HTMLInputElement>document.getElementById('couponcode')).value;
    let otherinfo = (<HTMLInputElement>document.getElementById('otherinfo')).value;
    let minpuramt = (<HTMLInputElement>document.getElementById('minpuramt')).value;
    let effdate = (<HTMLInputElement>document.getElementById('effdate')).value;
    let validdate = (<HTMLInputElement>document.getElementById('validdate')).value;
    let status = (<HTMLInputElement>document.getElementById('status')).value;
    if(couponcode!=null && couponcode!=='' && otherinfo!=null && otherinfo!=='' && effdate!=null && effdate!==''&& validdate!=null && validdate!==''&& status!=null && status!==''){
      if(validdate < effdate){
        this.errorFlag= true;
        this.errorMessage = "Validate Date should be greater than effective date!";
        this.spinnerService.hide();
        setTimeout(function() {
          this.errorFlag = false;
      }.bind(this), 3000); 
      }else{
        let couponpercentAmount;
        let d = effdate.concat(":00");
        let effDate=new Date(d);
    
        let d1 = validdate.concat(":00");
        let vaiDate=new Date(d1);
        let formData= {
          couponCode:couponcode,
          couponpercentAmount:2,
          couponAmount:otherinfo,
          minPurchaseAmount:minpuramt,
          effectiveFrom:effDate.getTime(),
          couponValidUpto:vaiDate.getTime(),
          couponStatus:status,
          createdBy:sessionStorage.getItem("username")
        }
        this.couponservice.addcoupon(formData).subscribe(data=>{
          if(data.statusCode==200){
            this.modalService.dismissAll();
            Swal.fire({
              html: '<pre>' + 'Coupon added successfully' + '</pre>',
              customClass: {
                popup: 'format-pre'
              }
            }).then((value) => {
              window.location.reload();
            });; ;   
          }else{
            this.errorFlag= true;
            this.errorMessage = data.responseMessage;
            setTimeout(function() {
              this.errorFlag = false;
          }.bind(this), 3000);    
          }
        },err => {
          if(err.error.statusCode==406){
            this.router.navigate(['/login']);
          }else if(err.error.statusCode==500){
            this.router.navigate(['/page-error']);
          }else if(err.error.statusCode==404){
            this.router.navigate(['/pagenotfound']);
          }
       });
      }
    }else{
      this.errorFlag= true;
      this.errorMessage = "Please Enter mandatory fields!";
      this.spinnerService.hide();
      setTimeout(function() {
        this.errorFlag = false;
    }.bind(this), 3000);
    }
  }

  updateCoupon(){
    let couponcode = (<HTMLInputElement>document.getElementById('couponcode')).value;
    let otherinfo = (<HTMLInputElement>document.getElementById('otherinfo')).value;
    let minpuramt = (<HTMLInputElement>document.getElementById('minpuramt')).value;
    let effdate = (<HTMLInputElement>document.getElementById('effdate')).value;
    let validdate = (<HTMLInputElement>document.getElementById('validdate')).value;
    let status = (<HTMLInputElement>document.getElementById('status')).value;
    if(otherinfo!=null && otherinfo!=='' && effdate!=null && effdate!==''&& validdate!=null && validdate!==''&& status!=null && status!==''){
      if(validdate < effdate){
        this.errorFlag= true;
        this.errorMessage = "Validate Date should be greater than effective date!";
        this.spinnerService.hide();
        setTimeout(function() {
          this.errorFlag = false;
      }.bind(this), 3000); 
      }else{
        let couponpercentAmount;
      let d = effdate.concat(":00");
      let effDate=new Date(d);
  
      let d1 = validdate.concat(":00");
      let vaiDate=new Date(d1);
      let formData= {
        couponpercentAmount:2,
        couponAmount:otherinfo,
        minPurchaseAmount:minpuramt,
        effectiveFrom:effDate.getTime(),
        couponValidUpto:vaiDate.getTime(),
        couponStatus:status,
        modifiedBy:sessionStorage.getItem("username")
      }
      this.couponservice.update(this.couponseid,formData).subscribe(data=>{
        if(data.statusCode==200){
          this.modalService.dismissAll();
          Swal.fire({
            html: '<pre>' + 'Coupon updated successfully' + '</pre>',
            customClass: {
              popup: 'format-pre'
            }
          }).then((value) => {
            window.location.reload();
          });; ;   
        }else{
          this.errorFlag= true;
          this.errorMessage = data.responseMessage;
          setTimeout(function() {
            this.errorFlag = false;
        }.bind(this), 3000);    
        }
      },err => {
        if(err.error.statusCode==406){
          this.router.navigate(['/login']);
        }else if(err.error.statusCode==500){
          this.router.navigate(['/page-error']);
        }else if(err.error.statusCode==404){
          this.router.navigate(['/pagenotfound']);
        }
     });
      }
    }else{
      this.errorFlag= true;
      this.errorMessage = "Please Enter mandatory fields!";
      this.spinnerService.hide();
      setTimeout(function() {
        this.errorFlag = false;
    }.bind(this), 3000);
    }
  }
}
