import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from '../auth/token-storage.service';
import { LoginModel } from '../model/login';
import { UserModel } from '../model/user';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  hide = true;
  fieldTextType: boolean;
  isError = false;
  errormessage : String;
  userModel : UserModel;
  constructor(private router: Router,
  private userservice: UserService,private tokenStorage:TokenStorageService) { }

  ngOnInit(): void {
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  onSubmit(){
    let email = (<HTMLInputElement>document.getElementById('emailaddress')).value;
    let password = (<HTMLInputElement>document.getElementById('password')).value;
    let login = new LoginModel(0,email,password,"");
    if(email !=="" && password !==""){
      this.userservice.login(login).subscribe( data => {
        this.userModel = data;
        // sessionStorage.removeItem("jwttoken");
        // this.tokenStorage.saveToken(this.userModel.jwtResponse.X_ACCESS_TOKEN);
        if(data.flag===2){
          if(data.firstLoginCount==1 && data.roleId.roleId!=1){
            sessionStorage.setItem("jwttoken",this.userModel.jwtResponse.X_ACCESS_TOKEN);
            sessionStorage.setItem('username',data.firstName);
            sessionStorage.setItem('id',String(data.userId));
            sessionStorage.setItem('adminrole',String(data.roleId.roleId));
            sessionStorage.setItem('useremail',String(data.emailAddress));
            localStorage.setItem('isLoggedIn', "true");
            const currentTime = new Date().getTime();
            localStorage.setItem("accepted", String(currentTime));
            this.router.navigate(['/change-password']);
          }else{
            sessionStorage.setItem("jwttoken",this.userModel.jwtResponse.X_ACCESS_TOKEN);
            sessionStorage.setItem('username',data.firstName);
            sessionStorage.setItem('id',String(data.userId));
            sessionStorage.setItem('adminrole',String(data.roleId.roleId));
            localStorage.setItem('isLoggedIn', "true");
            const currentTime = new Date().getTime();
            localStorage.setItem("accepted", String(currentTime));
            this.router.navigate(['/dashboard']);
          }
          // sessionStorage.setItem("jwttoken",this.userModel.jwtResponse.X_ACCESS_TOKEN);
          // sessionStorage.setItem('username',data.firstName);
          // sessionStorage.setItem('id',String(data.userId));
          // sessionStorage.setItem('adminrole',String(data.roleId.roleId));
          // localStorage.setItem('isLoggedIn', "true");
          // const currentTime = new Date().getTime();
          // localStorage.setItem("accepted", String(currentTime));
          // this.router.navigate(['/dashboard']);
        }else{
          this.isError = true;
          this.errormessage = data.message;
        }
      },err => {
        if(err.error.statusCode==406){
          this.router.navigate(['/login']);
        }else if(err.error.statusCode==500){
          this.router.navigate(['/page-error']);
        }else if(err.error.statusCode==404){
          this.router.navigate(['/pagenotfound']);
        }
     });
    }
  }

}
