

<div class="mainbody">
  <div class="row m-0">
    <div class="col-sm-12 text-center">
      <div class="titleofthepage">
         Terms and Conditions
      </div>
	  </div>
	  </div>
      <div class="row m-0">
	  <div class="col-sm-12 m-auto">
      <div class="detaildescription">
          <div class="contentofthepage">
              <ul id=bulletlist1>
                <li class="liststyle"> Acceptance of Terms</li>
                <li class="liststyle">Privacy</li>
                <li class="liststyle">Miscellaneous</li>
                    <ul>
                      <li>
                          Intellectual Property
                      </li>
                      <li>Revisions of Data</li>
                    </ul>
               
              </ul>
            </div>
        <h5>Acceptance of the Terms</h5>
        <div class="content">
          This website is owned and operated by Tyche Payment Solutions Private Limited, Please read these terms of use before using this website.
By accessing and using this site, you accept these terms of use, the privacy statement, notices and disclaimers (collectively the <q>Terms of Use</q>) and your use of this site are subject to the Terms of Use. Where you represent another person or entity when accessing and using this site, you warrant that you are authorized to accept these Terms of Use on behalf of that other person or entity.

        </div>
        <div class="content">
          From time to time, Tyche Payment solutions may modify these Terms of Use by posting modified Terms of Use on this website. Any amendment is effective from the date it is posted on this website. Any terms and conditions proposed by you are expressly rejected by Tyche Payment Solutions and are of no force and effect.
        </div>
        <h5>Our commitment to privacy</h5>
        <div class="content">
          Protecting consumer privacy is very important to us. Our online Privacy Statement is available to inform you about our use and disclosure of personal information collected through this website on or after the effective date shown above.

        </div>
        <div class="content">
          We believe that information used responsibly benefits consumers and the economy, whether it is information we house on behalf of consumers conducting transactions or information we collect on behalf of our business clients.
        </div>
        <div class="content">
          For example, we use consumer information every day to ensure fast and accurate processing of thousands of electronic transactions. In addition, we strive to better serve our customers by offering goods and services that we believe fit their needs and interests. 
        </div>
        <div class="content">
          When identifying an opportunity, we may use aggregated consumer information in an effort to identify customers who may be interested in that opportunity. These policies guide us in the collection and use of information as well as setting our employee<span>&#8217;</span>s responsibilities in protecting your privacy. We use personal information about consumers, customers and users only in a manner consistent with these policies.
        </div>
        <h5>Cookies</h5>
        <div class="content">
          A <q>cookie</q> is an identifier that we transfer to users<span>&#8217;</span> computer hard drive through users<span>&#8217;</span> web browsers to 
          enable our systems to recognize users<span>&#8217;</span> browsers and interact more efficiently with customers<span>&#8217;</span> and users<span>&#8217;</span> computers.This website uses cookies only to aggregate information about the pages that users visit so that we can update and redesign this website, as necessary, in order to provide you with the most useful information.
          
          We do not disclose domain name or aggregate information to third parties other than related bodies corporate and vendors who assist us with this website and who are under strict confidentiality requirements. 
          
        </div>
        <h5>Collection of the Personal Information</h5>
        <div class="content">
          The nature of the personal information we collect about you online is generally comprised of your name, mailing address, e-mail address phone number, the company you represent and is strictly limited to the information that you voluntarily provide to us and that is collected by the use of Cookies as described above. We use the personal information you supply to us on this website to Contact you so that we can provide you with the information you have requested, assess and process any application you make for services, provide services to you and administer services provided to you, Perform our administrative operations, including accounting, risk management, record keeping, archiving, systems development and testing, credit scoring and staff training.
          
        </div>
        <h5>Validate the user<span>&#8217;</span>s rights to view the information provided. </h5>
      
         
            <div class="content">We also may use and hold personal information, which is provided to us in connection with services that you request, for the purposes (as relevant) of:</div>
            <ul class="listclass">
            <li>Complying with legislative and regulatory requirements or as otherwise permitted or authorized by law;</li>
            <li>Considering any other application you may make to us;<li>Managing our rights and obligations in relation to external payment systems eg credit card schemes and debit payment schemes;</li>
            <li>Conducting market and customers satisfaction research;</li>
            <li>If you provide incomplete or inaccurate information to us, we may not be able to provide you with the services you are seeking.</li>
          </ul>
          
          <h5>Use and disclosure of personal information</h5>
          <div class="content">
              Information collected by us in relation to electronic transactions is used to process those transactions and so will be disclosed to the parties to the transaction, their respective financial institutions and third parties involved in the transaction chain.
          </div>
          <div class="content">
              Other information is used to provide and administer the provision of services to you, managing our rights and obligations under contracts and at law, systems development and testing, in connection with alliance arrangements and identifying and marketing the products or services provided by us, our related bodies corporate or alliance partners that we think may be of interest to you and telling you about them. If you do not want to receive this information, please let us know.
          </div>
          <div class="content">
              In common with many other businesses, we use agents and other third parties to conduct certain activities on our behalf and provide certain services to us. For this purpose, we may need to provide these third parties with your personal information.
          </div>
          <div class="content">
              The types of organizations to which we may disclose customer<span>&#8217;</span>s, consumer<span>&#8217;</span>s or user<span>&#8217;</span>s personal information include:</div>
            <ul class="listclass">
            <li>Our agents, contractors and external advisers whom we engage from time to time to carry out, or advise on, our functions and activities and you authorize them to seek disclosure of your credit information for this purpose;</li>
            <li>Your agents and contractors, including your referees and your legal or financial advisor, executor, administrator, trustee, guardian or attorney if you authorize them in writing to seek disclosure of this information;</li>
            <li>In addition, we may be required or permitted by law to use and disclose your personal information, (such as disclosures to law enforcement bodies and government agencies to prevent fraud or other malicious activities). </li>
            </ul>
        <h5>Commercial Electronic Messages</h5> 
        <div class="content">
            By giving us your electronic address/account, you consent to us sending to you commercial electronic messages in order to provide you with information and tell you about products and services, including those of third parties, which we consider may be of interest to you. 
        </div>
        <div class="content">
            By giving us your electronic address/account, you consent to us sending to you commercial electronic messages in order to provide you with information and tell you about products and services, including those of third parties, which we consider may be of interest to you. 
        </div>
        <div class="content">
            We limit access to your personal information to those of our employees and employees of our related bodies corporate and our alliance partners who need your personal information to fulfill their business responsibilities. Such employees must adhere to our privacy policies. Employees violating these policies may be subject to disciplinary action, up to and including dismissal.
        </div>
        <div class="content">
            Our service providers and other outside contractors are subject to contractual requirements to ensure that your personal information is safeguarded.
        </div>
        <div class="content">
            We are committed to protecting the confidentiality of personal consumer information
        </div>
        <h5>Security safeguards</h5>
        <div class="content">
            Security is a priority. We employ appropriate measures to protect personal information against unauthorized access, disclosure, alteration or destruction.
        </div>
        <div class="content">
            These may include, among others, encryption, physical access security and other appropriate technologies. We continually review and enhance our security systems as necessary. As such, we may ask you to identify and authenticate yourself when accessing this website or when dealing with you through other media. We are not responsible for any information that may be lost if we terminate your browser session during prolonged periods of inactivity whilst accessing this website.
        </div>
        <h5>Privacy Content</h5>
        <div class="content">
            We may use and disclose personal information provided to us about users, customers and cardholders to third parties as described earlier in this Privacy Statement.
        </div>
        <div class="content">
            Where we collect information through this website, you will be asked whether you consent to the use and disclosure of your personal information as described earlier in this Privacy Statement. To change your selection, update your contact details or retrieve and change your registration information, please contact us using the contact details shown on this website. We hold ourselves accountable to our privacy principles.
        </div>
        <h5>We are committed to meeting the highest standards for our privacy program. 
        </h5>
        <div class="content">
            To that end, we have appointed a senior official, who serves as the company-wide officer with responsibility for the administration and implementation of our privacy program.
        </div>
        <div class="content">
            The privacy officer, working closely with individual business units, is responsible for:</div>
        <ul class="listclass">
          <li>Administering employee privacy training and employee compliance related to our privacy policies;

          </li>
          <li>Conducting periodic assessments of our compliance with our privacy policies, as well as specific implementation policies and procedures.</li>
        </ul>
        <p>The contact details for our privacy officer are:</p>
        <address ><i>
            Tyche Payment Solutions Pvt Ltd,Regd. <br> 
            Off: New No.9, Old.No.11, 1st Floor, <br>
            Palayakaran Street,<br>
            Kalaimagal Nagar, <br>
            Ekkaduthangal,<br>
            Chennai <span>&#8212;</span>600032.<br>
            Support@tychepayment.com &Phone : 044-22440939
          </i>  </address>
          <div class="content">
              We may alter this Privacy Statement as needed for certain products and services and to abide by local laws or regulations. We reserve the right to amend or modify this Privacy Statement from time to time. We urge you to review this Privacy Statement whenever you visit in order to obtain the most current statement.
          </div>
          <h5>Intellectual Property</h5>
          <div class="content">
              All present and future rights in and to trade secrets, patents, copyright, trademarks, service marks, know-how and other proprietary rights of any type arising at law, including rights in and to all applications and registrations relating to this website (including but not limited to all data contained on this website relating to you and your associated customer (<q>Data</q>), information, text, look and feel, material, graphics, software and advertisements) (the <q>Intellectual Property Rights</q>) shall, as between you and us, at all times be and remain the sole and exclusive property of Tyche Payment Services. You assign upon creation all Intellectual Property Rights to us.
          </div>
          <div class="content">
              You do not acquire any rights or licenses in or to the Intellectual Property Rights, this website and materials contained within this website other than the limited right to use this website in accordance with the Terms of Use.
          </div>
          <h5>Revisions of Data</h5>
          <div class="content">
              You and Your associated customer are responsible for all revisions to the Data executed by you. You accept responsibility as a representative of your Associated Customer and represent your Associated Customer in all access and use of this website. You acknowledge and agree that
          </div>
          <div class="content">
              Tyche Payment Solutions relies in good faith on any revisions to the Data executed by you and does not take any steps to verify the accuracy of any revisions to the Data executed by you.
          </div>
      </div>
   
        
      
    </div>
  </div>
</div>