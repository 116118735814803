import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
// import {NgxSpinnerService} from "ngx-spinner";
import { BnNgIdleService } from 'bn-ng-idle'; // import it to your component

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent implements OnInit {

title = 'ch-admin-panel';

  constructor(private bnIdle: BnNgIdleService,private router: Router) {
  }
 
  ngOnInit() {
    // this.bnIdle.startWatching(1800000).subscribe((isTimedOut: boolean) => {
    //   if (isTimedOut) {
    //     localStorage.setItem('isLoggedIn', "false");
    //     this.router.navigate(['/login']);
    //   }
    // });
    /** spinner starts on init */
    // this.spinner.show();
 
    // setTimeout(() => {
      /** spinner ends after 5 seconds */
      // this.spinner.hide();
    // }, 5000);
  }
  }