<app-header></app-header>

<div class="page-container "> 
   <div class="page-content-wrapper "> 
     <div class="content sm-gutter"> 
       <div class="bg-white">
         <div class="container">
           <ol class="breadcrumb breadcrumb-alt">
             <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Home</a></li>
             <li class="breadcrumb-item active">SETTLED REPORT </li>
             <a href="#" routerLink="/settled-report" style="margin-left: 330px;" style="margin-top: -25px;" class="back_page"><span style="margin-left: 355px;" class="label label-info"><i class="back_page"></i>Back</span></a>
           </ol>
         </div>
       </div>
       <div class=" no-padding    container-fixed-lg bg-white">
         <div class="container"> 
            <div class="col-md-6">
                     <div class="col1 flex">
                        <div class="profile-info flex">
                           <div class="card-body profile" style="margin-left: 350px;">
                               <h6 style="color: grey;"> Partner Info</h6>
                               <p class="card-title"><b>Partner Name : </b> 
                               <span><b>{{settledreport.vehicleId.userId.firstName}} {{settledreport.vehicleId.userId.lastName}}</b></span>
                             </p>
                          <p class="card-title"><b>Partner Mobile : </b> 
                            <span><b>{{settledreport.vehicleId.userId.mobileNumber}}</b></span>
                          </p>
                           </div>
                            <div class="card-body profile">
                                <h6 style="color: grey;">Settlement Bank Info</h6>
                              <p class="card-title"><b>Account Holder Name :</b> <span><b>{{settledreport.accountName}}</b></span></p>
                              <p class="card-title"><b>Account Number:</b> 
                                <span><b>{{settledreport.accountNumber}}</b></span>
                             </p>
                             <p class="card-title"><b>IFSC Code :</b> 
                                <span><b>{{settledreport.ifscCode}}</b></span>
                             </p>
                           </div>
                        </div>
                     </div>
 
                 </div>
         </div>
       </div>
     </div>
   </div>
 </div>

 <!-- START CONTAINER FLUID -->
 <div class=" no-padding    container-fixed-lg bg-white">
    <div class="container"> 
      <!-- START card -->
      <div class="card card-transparent">
        <div class="card-body">
            <div style="display: none;">{{settledreportmodel.responseMessage}}</div>
          <div class="table-responsive">
            <table datatable class="ch-table">
              <thead>
                <tr> 
                  <th >Sl.No.</th>
                  <th>Order Info</th>
                  <th style="width: 170px;">Customer Info</th>
                  <th style="width: 120px;">Settlement ID</th>
                  <th >Transaction ID</th>
                  <th >Bank Ref No</th>
                  <th style="width: 100px;">Payout Amount</th>
                  <th style="width: 100px;">TDR Amount</th>
                  <th style="width: 100px;">Tax on TDR Amount</th>
                  <th >Status</th>
                  <th >Date & Time</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let settled of settledreportmodel.response; let i=index">
                  <td align="center" class="v-align-middle "><p>{{i + 1}}</p></td>
                  <td align="left" class="v-align-middle">
                    <p *ngIf="settled.orderId!=null">{{settled.orderId}}</p>
                    <p *ngIf="settled.orderId==null">N/A</p>
                  
                  </td>
              
                  <td *ngIf="settled.customerName!=null"align="left" class="v-align-middle">
                      <p style="font-size: 12px;"><b>Name :</b> {{settled.customerName}}</p>
                      <p style="font-size: 12px;"><b>Mobile :</b> {{settled.customerPhone}}</p>
                    </td>
                  <td *ngIf="settled.customerName==null"align="left" class="v-align-middle"><p>N/A</p></td>
                  <td  align="left" class="v-align-middle"><p>{{settledreport.settlementApi}}</p></td>
                  <td *ngIf="settled.transactionId!=null"align="left" class="v-align-middle">{{settled.transactionId}}</td>
                  <td *ngIf="settled.transactionId==null"align="left" class="v-align-middle">N/A</td>
                  <td *ngIf="settled.bankReference!=null"align="center" class="v-align-middle">{{settled.bankReference}}</td>
                  <td *ngIf="settled.bankReference==null"align="center" class="v-align-middle">N/A</td>
                  <td *ngIf="settled.grossTransactionAmount!=null"align="center" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{settled.grossTransactionAmount | number : '1.2-2'}}</td>
                  <td *ngIf="settled.grossTransactionAmount==null"align="center" class="v-align-middle">N/A</td>
                  <td *ngIf="settled.tdrAmount!=null" align="left" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{settled.tdrAmount | number : '1.2-2' }}</td>
                  <td *ngIf="settled.tdrAmount==null" align="left" class="v-align-middle">N/A</td>
                  <td *ngIf="settled.taxonTdrAmount!=null" align="left" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{settled.taxonTdrAmount | number : '1.2-2'}}</td>
                  <td *ngIf="settled.taxonTdrAmount==null" align="left" class="v-align-middle">N/A</td>
                  <td *ngIf="settled.completed!=null && settled.completed=='Y'" align="left" class="v-align-middle active">Completed</td>
                  <td *ngIf="settled.completed!=null && settled.completed=='N'" align="left" class="v-align-middle pending">Pending</td>
                  <td *ngIf="settled.completed==null" align="left" class="v-align-middle">N/A</td>
                  <td *ngIf="settled.settlementDatetime!=null" align="left" class="v-align-middle">{{settled.settlementDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                  <td *ngIf="settled.settlementDatetime==null" align="left" class="v-align-middle">N/A</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- END card --> 
    </div>
  </div>