import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { data } from 'jquery';
import { CustomerRatingModel } from '../model/customerrating';
import { PartnerRatingModel } from '../model/partnerrating';
import { GenericResponseModel } from '../model/genricresponse';
import { RatingService } from '../services/rating.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-review-rating',
  templateUrl: './review-rating.component.html',
  styleUrls: ['./review-rating.component.css']
})
export class ReviewRatingComponent {
  customerRating : GenericResponseModel;
  partnerRating:GenericResponseModel;
  customerratingmodel:CustomerRatingModel;
  partnerratingmodel:PartnerRatingModel;
 public isCollapsed = false;
 active = 1;
closeResult = '';
  constructor(private modalService: NgbModal,private ratingService:RatingService,private spinnerService:NgxSpinnerService,private router:Router) {}

  open(content,id) {
    this.ratingService.viewCustomerRating(id).subscribe(data=>{
      this.customerratingmodel = data;
    })
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open1(content1,id) {
    this.ratingService.viewPartnerRating(id).subscribe(data=>{
      this.partnerratingmodel = data;
    })
    this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
    this.spinnerService.show();
    this.ratingService.getCustomerRatings().subscribe(data=>{
      this.customerRating= data;
      this.spinnerService.hide();
    },err => {
      console.log(err.error.statusCode);
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }
   });
    this.spinnerService.show();
    this.ratingService.getPartnerRatings().subscribe(data=>{
      this.partnerRating = data;
      this.spinnerService.hide();
    },err => {
      console.log(err.error.statusCode);
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }
   });
  }

  div1:boolean=true;
  div2:boolean=false;
   
    div1Function(){
        this.div1=true;
        this.div2=false;
    }

    div2Function(){
        this.div2=true;
        this.div1=false;
    }
}
