<app-header></app-header>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
</ngx-spinner>
<ol class="breadcrumb breadcrumb-alt" style="margin-top:100px; width: auto;" >
  <li class="breadcrumb-item" style="margin-left: 15px;"><a href="#" routerLink="/dashboard">Home</a></li>
  <li class="breadcrumb-item active">FUEL TYPE MASTER</li>
  <div class="btn-section-wrapper">
    <button type="button" class="label label-info" (click)="open(content)"><i class="fa fa-plus" aria-hidden="true"></i>
      Add Fuel Type</button>
</div>
</ol>
<div class="container  container-top-dashboard-section">
<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
	 <!-- <div class="btn-section-wrapper"><button type="button" class="btn btn-success" (click)="open(content)"><i class="fa fa-plus" aria-hidden="true"></i>
 Add Fuel Type</button>
</div> -->
<div style="display: none;" >{{response.responseMessage}}</div>
  <li [ngbNavItem]="1" style="margin-top: 40px;">
    <!-- <a ngbNavLink>Fuel Type Master</a> -->
    <ng-template ngbNavContent>
      <div class="card-body">
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="width: 40px; text-align: center;">Sl.No.</th>
                      <th align="center" >Fuel Type Name</th>
                      <th align="center">Created By</th>
                      <th align="center" >Created Date &amp; Time</th>
                      <th style="width: 60px; text-align: center;" >Status</th>
                      <th style="width: 60px; text-align: center;" >Action</th>
                      <!-- <th align="center" >Edit</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let fuel of response.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td align="left" class="v-align-middle">{{fuel.fuelTypeName}}</td>
                      <td align="left" class="v-align-middle">{{fuel.modifiedBy}}</td>
                      <td *ngIf="fuel.modifiedDatetime!=null" align="left" class="v-align-middle">{{fuel.modifiedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="fuel.modifiedDatetime==null" align="left" class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="fuel.fuelTypeStatus===1" class="v-align-middle ht-active">Active</td>
                      <td align="center" *ngIf="fuel.fuelTypeStatus===0" class="v-align-middle ht-inactive">InActive</td>
                      <td align="center" class="v-align-middle">
                        <button title="Edit" (click)="open1(content1,fuel.id)" class="label label-success"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;
                        <!-- <button title="delete"class="label label-danger" (click)="open2(reject,fuel.id)"><i class="fa fa-trash" aria-hidden="true"></i></button> -->
                      </td>
                    </tr>
                    </tbody>
                </table>
          </div>
            </div>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>



<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Fuel Type</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <div class="modal-body master-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Fuel Type Name</label>
        <input type="text" id="fueltype" name="fueltype" class="form-control"  placeholder="">
      </div>
      
      <div class="form-group">
        <label for="dateOfBirth">Status</label>
        <div class="dropdown">
  <select id="fuelstatus" name="fuelstatus" value="fuelstatus" class="form-control">
    <option value=1>Active</option>
    <option value=0>Inactive</option>
</select>
</div>
      </div>
      <div *ngIf="errorFlag">
        <div class="error-style">{{errorMessage}}</div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="addFuel()">Add Fuel Type</button>
  </div>
</ng-template>


<ng-template #content1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Fuel Type</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <div class="modal-body master-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Fuel Type Name</label>
        <input type="text" id="fuelTypeName" name="fuelTypeName" [(ngModel)]="fuelType.fuelTypeName" class="form-control"  placeholder="">
      </div>
      
      <div class="form-group">
        <label for="dateOfBirth">Status</label>
        <div class="dropdown">
  <select id="fuelTypeStatus" name="fuelTypeStatus" [(ngModel)]="fuelType.fuelTypeStatus" class="form-control">
    <option value=1>Active</option>
    <option value=0>Inactive</option>
</select>
</div>
      </div>
      <div *ngIf="errorFlag1">
        <div class="error-style">{{errorMessage}}</div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="updateFuel()">Update Fuel Type</button>
  </div>
</ng-template>

<div class="page-container " style="display: none;"> 
  <!-- START PAGE CONTENT WRAPPER -->
  <div class="page-content-wrapper "> 
    <!-- START PAGE CONTENT -->
    <div class="content sm-gutter"> 
      <!-- START BREADCRUMBS -->
      <div class="bg-white">
        <div class="container">
          <ol class="breadcrumb breadcrumb-alt">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item active">Customers </li>
          </ol>
        </div>
      </div>
      <!-- END BREADCRUMBS -->
      
      <div class="container Customers-top-notification">
        <div _ngcontent-tgd-c17="" class="card no-border widget-loader-bar m-b-10">
          <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                  <div _ngcontent-tgd-c17="" class="card-title"><span _ngcontent-tgd-c17="" class="font-montserrat fs-11 all-caps">Total No. of Customer<i _ngcontent-tgd-c17="" class="fa fa-chevron-right"></i></span></div>
                  <div _ngcontent-tgd-c17="" class="card-controls">
                    <ul _ngcontent-tgd-c17="">
                      <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                  <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% </span> -->
                  </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-bottom">
                <div _ngcontent-tgd-c17="" class="progress progress-small m-b-0">
                  <div _ngcontent-tgd-c17="" class="progress-bar progress-bar-primary" style="width: 71%;"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div _ngcontent-tgd-c17="" class="card no-border widget-loader-bar m-b-10">
          <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                  <div _ngcontent-tgd-c17="" class="card-title"><span _ngcontent-tgd-c17="" class="font-montserrat fs-11 all-caps">Total No. of Verified Customer<i _ngcontent-tgd-c17="" class="fa fa-chevron-right"></i></span></div>
                  <div _ngcontent-tgd-c17="" class="card-controls">
                    <ul _ngcontent-tgd-c17="">
                      <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                  <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71%</span> -->
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-bottom">
                <div _ngcontent-tgd-c17="" class="progress progress-small m-b-0">
                  <div _ngcontent-tgd-c17="" class="progress-bar progress-bar-primary" style="width: 71%;"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div _ngcontent-tgd-c17="" class="card no-border widget-loader-bar m-b-10">
          <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                  <div _ngcontent-tgd-c17="" class="card-title"><span _ngcontent-tgd-c17="" class="font-montserrat fs-11 all-caps">Total No. of Not Verified Customer<i _ngcontent-tgd-c17="" class="fa fa-chevron-right"></i></span></div>
                  <div _ngcontent-tgd-c17="" class="card-controls">
                    <ul _ngcontent-tgd-c17="">
                      <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                  <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-bottom">
                <div _ngcontent-tgd-c17="" class="progress progress-small m-b-0">
                  <div _ngcontent-tgd-c17="" class="progress-bar progress-bar-primary" style="width: 71%;"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
        
      </div>
       <!-- <div class="container search-section">

       	<div class="Customers-search-section">
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Search By Cust.ID
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="#">Search By Cust. Name</a>
              <a class="dropdown-item" href="#">Search By Cust. Mobile</a>
              <a class="dropdown-item" href="#">Search By Cust. Email</a>
              <a class="dropdown-item" href="#">Account Status</a>
              <a class="dropdown-item" href="#">OTP Verification Status</a>
            </div>
          </div> -->
       	<!-- <div class="form-group">
           <input type="email" class="form-control" placeholder="Search By Cust.ID" required="">
         </div> -->
         <!-- <div class="form-group">
           <input type="email" class="form-control" placeholder="Search By Cust. Name" required="">
         </div>
         <div class="form-group">
           <input type="email" class="form-control" placeholder="Search By Cust. Mobile" required="">
         </div>
         <div class="form-group">
           <input type="email" class="form-control" placeholder="Search By Cust. Email " required="">
         </div>
         <div class="form-group">
           <input type="email" class="form-control" placeholder="Account Status " required="">
         </div>
         <div class="form-group">
           <input type="email" class="form-control" placeholder="OTP Verification Status" required="">
         </div> -->
<!-- <div class="search-btn-wrraper">
         <button class="btn btn-primary btn-cons">Search</button></div>
       </div>
         </div> -->
			
      
      <!-- START CONTAINER FLUID --> 
      
      <!-- START CONTAINER FLUID -->
      <div class=" no-padding    container-fixed-lg bg-white">
        <div class="container"> 
          <!-- START card -->
          <div class="card card-transparent">
            <div class="card-header ">
              <!-- <div class="card-title">Role Menu Mapping </div> -->
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th align="center" >Sl.No.</th>
                      <th align="center" >Fuel Type Name</th>
                      <th align="center">Created By</th>
                      <th align="center" >Created Date &amp; Time</th>
                      <th align="center" >Status</th>
                      <th align="center" ><span>Action</span></th>
                      <th align="center" >Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let fuel of response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td align="center" class="v-align-middle">{{fuel.fuelTypeName}}</td>
                      <td align="center" class="v-align-middle">{{fuel.modifiedBy}}</td>
                      <td align="center" class="v-align-middle">{{fuel.modifiedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="fuel.fuelTypeStatus===1" align="center" class="v-align-middle ht-active">Active</td>
                      <td *ngIf="fuel.fuelTypeStatus===0" align="center" class="v-align-middle ht-inactive">InActive</td>
                      <td align="center" class="v-align-middle"><button (click)="deleteFuel(fuel.id)" class="btn btn-danger"><i class="fa fa-trash-o" aria-hidden="true"></i></button></td>
                      <td align="center" class="v-align-middle"><button (click)="open1(content1,fuel.id)"class="btn btn-info"><i class="fa fa-pencil" aria-hidden="true"></i></button></td>
                    </tr>
                    </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- END card --> 
        </div>
      </div>
      <!-- END CONTAINER FLUID --> 
      
    </div>
    <!-- END PAGE CONTENT --> 
    <!-- START COPYRIGHT --> 
    <!-- START CONTAINER FLUID --> 
    <!-- START CONTAINER FLUID -->
  <!--   <div class=" container   container-fixed-lg footer">
      <div class="copyright sm-text-center">
        <p class="small-text no-margin pull-left sm-pull-reset"> ©2014-2020 All Rights Reserved. Pages® and/or its subsidiaries or affiliates are registered trademark of Revox Ltd. <span class="hint-text m-l-15">Pages v05.23 20201105.r.190</span> </p>
        <p class="small no-margin pull-right sm-pull-reset"> Hand-crafted <span class="hint-text">&amp; made with Love</span> </p>
        <div class="clearfix"></div>
      </div>
    </div> -->
    <!-- END COPYRIGHT --> 
  </div>
  <!-- END PAGE CONTENT WRAPPER --> 
</div>
<!-- END PAGE CONTAINER --> 
<!--START QUICKVIEW -->

<ng-template #reject let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Do you want delete the fuel type?</label>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="delete()" (click)="modal.close('Save click')">yes</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">no</button>
  </div>
</ng-template>


<router-outlet></router-outlet>


