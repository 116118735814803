import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ChargesModel } from '../model/charges';
import { ChargesService } from '../services/charges.service';
import {NgxSpinnerService} from "ngx-spinner";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router } from '@angular/router';

@Component({
  selector: 'app-master-charges-configuration',
  templateUrl: './master-charges-configuration.component.html',
  styleUrls: ['./master-charges-configuration.component.css']
})
export class MasterChargesConfigurationComponent  {
 

 public isCollapsed = false;
 active = 1;
 chargesDetails:ChargesModel[];
 currentCharges:ChargesModel;
 charges:ChargesModel;
 vehicleFee:DoubleRange;
 igst:DoubleRange;
 cgst:DoubleRange;
 sgst:DoubleRange;
 internet:DoubleRange;
 amc:DoubleRange;
 mmc:DoubleRange;
 minquan:DoubleRange;
 maxquan:DoubleRange;
 deliverless:DoubleRange;
 deliverlessamt:DoubleRange;
 delivergrea:DoubleRange;
 delivergreaamt:DoubleRange;
 errorFlag:boolean;
 errorMessage:String;
 updateCondition:boolean;
delivergra:Number;
deliveryFlag:Number;
assetSetupFee:DoubleRange;
assetSubscriptionFee:DoubleRange;
closeResult = '';
now = new Date();
year = this.now.getFullYear();
month = this.now.getMonth();
day = this.now.getDay();
deleteUserId:Number;
minDate = { year: this.year - 100, month: this.month, day: this.day };
maxDate = { year: this.year - 16, month: this.month + 1, day: this.day };
deliveryChargeFlag:Number;
deliveryFixedFee:DoubleRange;
deliveryPerLtrCost:DoubleRange;

  constructor(private modalService: NgbModal,private chargesservice:ChargesService,private spinnerService:NgxSpinnerService,private router:Router) {}
  ngOnInit() : void {
  this.chargesservice.getAllCharges().subscribe(data=>{
    this.chargesDetails=data;
  },err => {
    if(err.error.statusCode==406){
      this.router.navigate(['/login']);
    }else if(err.error.statusCode==500){
      this.router.navigate(['/page-error']);
    }else if(err.error.statusCode==404){
      this.router.navigate(['/pagenotfound']);
    }
  });

this.chargesservice.getCurrentCharges().subscribe(data=>{
  this.currentCharges=data;
  this.charges = data;

  this.vehicleFee = this.charges.vehicleAttachmentFee;
  this.igst = this.charges.igstPercentage;
  this.cgst = this.charges.cgstPercentage;
  this.sgst = this.charges.sgstPercentage;
  this.internet = this.charges.internetHandlingFee;
  this.deliverless = this.charges.deliveryLessEqualKms;
  this.deliverlessamt = this.charges.deliveryLessEqualCharges;
  this.delivergrea = this.charges.deliveryGreaterEqualKms;
  this.delivergreaamt = this.charges.deliveryGreaterEqualCharges;
  this.amc=this.charges.amcFee;
  this.mmc=this.charges.mmcFee;
  this.minquan=this.charges.minOrderQuantity;
  this.maxquan=this.charges.maxOrderQuantity;
  this.deliveryChargeFlag=this.charges.deliveryChargeFlag;
  this.deliveryFixedFee=this.charges.deliveryFixedFee;
  this.deliveryPerLtrCost=this.charges.deliveryPerLtrCost;
  this.assetSubscriptionFee=this.charges.assetSubscriptionFee;
  this.assetSetupFee=this.charges.assetSetupFee;
});
  }

  open2(reject,viewId){
    this.deleteUserId = viewId;
    this.modalService.open(reject, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  delete(){
    let userId = this.deleteUserId;
    this.chargesservice.deleteCharges(userId).subscribe(data=>{
      if(data.flag==1){
        Swal.fire({
          html: '<pre>' + data.message + '</pre>',
          customClass: {
            popup: 'format-pre'
          }
        }).then((value) => {
          window.location.reload();
        });; ;
      }
    });
  }

  addCredit(){
    let vehicle = (<HTMLInputElement>document.getElementById('vehicle')).value;
    let inte = (<HTMLInputElement>document.getElementById('inte')).value;
    let sgst = (<HTMLInputElement>document.getElementById('sgst')).value;
    let cgst = (<HTMLInputElement>document.getElementById('cgst')).value;
    let igst = (<HTMLInputElement>document.getElementById('igst')).value;
    let effective = (<HTMLInputElement>document.getElementById('effedate')).value;
    let amcfe = (<HTMLInputElement>document.getElementById('amcfe')).value;
    let mmcfe = (<HTMLInputElement>document.getElementById('mmcfe')).value;
    let maxquan = (<HTMLInputElement>document.getElementById('maxquan')).value;
    let minquan = (<HTMLInputElement>document.getElementById('minquan')).value;
    let assetSubscriptionFee = (<HTMLInputElement>document.getElementById('assetSubscriptionFee')).value;
    let assetSetupFee = (<HTMLInputElement>document.getElementById('assetSetupFee')).value;
    
    let deliveryChargeFlag = $("input[type='radio'][name='deliveryChargeFlag']:checked").val();
    
    let deliveryLess = '0';
    let deliverylech = '0';
    let deliveryGreater = '0';
    let deliveryGrch = '0';
    let deliveryFixedFee = '0';
    let deliveryPerLtrCost = '0';
    if(deliveryChargeFlag=='1'){
       deliveryLess = (<HTMLInputElement>document.getElementById('deliveryLess')).value;
       deliverylech = (<HTMLInputElement>document.getElementById('deliverylech')).value;
       deliveryGreater = (<HTMLInputElement>document.getElementById('deliveryGreater')).value;
       deliveryGrch = (<HTMLInputElement>document.getElementById('deliveryGrch')).value;
    }else if(deliveryChargeFlag=='2'){
       deliveryFixedFee = (<HTMLInputElement>document.getElementById('deliveryFixedFee')).value;
    }else if(deliveryChargeFlag=='3'){
       deliveryPerLtrCost = (<HTMLInputElement>document.getElementById('deliveryPerLtrCost')).value;
    }
   
    if(amcfe=='' || mmcfe==''){
      this.errorFlag = true;
      this.errorMessage = "AMC | MMC quantity should be Mandatory!";
      setTimeout(function() {
        this.errorFlag = false;
    }.bind(this), 5000);
    }else if(maxquan=='' || minquan==''){
      this.errorFlag = true;
      this.errorMessage = "Minimum | Maximum quantity should be Mandatory!";
      setTimeout(function() {
        this.errorFlag = false;
    }.bind(this), 5000);
    }else if(maxquan=='0' || minquan=='0'){
      this.errorFlag = true;
      this.errorMessage = "Minimum | Maximum quantity should not be zero!";
      setTimeout(function() {
        this.errorFlag = false;
    }.bind(this), 5000);
    }else if(Number(minquan)>Number(maxquan)){
      this.errorFlag = true;
      this.errorMessage = "Maximum quantity should be greater than minimum quantity!";
      setTimeout(function() {
        this.errorFlag = false;
    }.bind(this), 5000);
    }else if(effective!=null && effective!==''){
      let d = effective.concat(":00");
      let currentDate=new Date(d);
      let formData= {
        vehicleAttachmentFee:vehicle,
        internetHandlingFee:inte,
        sgstPercentage:sgst,
        modifiedBy:sessionStorage.getItem("username"),
        deliveryLessEqualKms:deliveryLess,
        deliveryLessEqualCharges:deliverylech,
        deliveryGreaterEqualKms:deliveryGreater,
        deliveryGreaterEqualCharges:deliveryGrch,
        cgstPercentage:cgst,
        igstPercentage:igst,
        effectiveDate:currentDate.getTime(),
        amcFee:amcfe,
        mmcFee:mmcfe,
        minOrderQuantity:minquan,
        maxOrderQuantity:maxquan,
        deliveryFixedFee:deliveryFixedFee,
        deliveryPerLtrCost:deliveryPerLtrCost,
        deliveryChargeFlag:deliveryChargeFlag,
        assetSubscriptionFee:assetSubscriptionFee,
        assetSetupFee:assetSetupFee
     
      }
      this.chargesservice.addCharges(formData).subscribe(data=>{
        if(data.flag==1){
          this.modalService.dismissAll();
          Swal.fire({
            html: '<pre>' + data.message + '</pre>',
            customClass: {
              popup: 'format-pre'
            }
          }).then((value) => {
            window.location.reload();
          });; ; 
        }else{

        }
      },err => {
        if(err.error.statusCode==406){
          this.router.navigate(['/login']);
        }else if(err.error.statusCode==500){
          this.router.navigate(['/page-error']);
        }else if(err.error.statusCode==404){
          this.router.navigate(['/pagenotfound']);
        }
     });
    }else{
      this.errorFlag = true;
      this.errorMessage = "Please select Effective Date!";

      setTimeout(function() {
        this.errorFlag = false;
    }.bind(this), 5000);
    }
  }

  updateValue(){
    let deliveryLess = (<HTMLInputElement>document.getElementById('deliveryLess')).value;
    if(deliveryLess!=="" && deliveryLess!=null){
      let value = Number(deliveryLess);
      value = value+1;
      this.delivergra = Number(value);
      this.updateCondition = true;
    }
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  allowNumericDigitsOnlyOnKeyUp(e) {		
    const charCode = e.which ? e.which : e.keyCode;
    if(charCode==46){
      return true;
    }else if(charCode > 31 && (charCode < 48 || charCode > 57)){
      if(charCode==46){
        return true;
      }else{
        e.preventDefault();
      }
    }else{
      return true;
    }
  }
  
  allowNumericDigitsOnlyOnKeyUpWithoutdot(e) {		
    const charCode = e.which ? e.which : e.keyCode;
    if(charCode > 31 && (charCode < 48 || charCode > 57)){
        e.preventDefault();
    }else{
      return true;
    }
  }
  
  allowNumericDigitsOnlyOnKeyUpwithoutzero(e) {		
    const charCode = e.which ? e.which : e.keyCode;
    if(charCode==48){
      e.preventDefault();
    }else if(charCode > 31 && (charCode < 49 || charCode > 57)){
        e.preventDefault();
    }else{
      if(charCode==48){
        e.preventDefault();
      }else{
        return true;
      }
    }
  }

  eventCheck(event){
   if(event.target.checked){
     this.deliveryFlag = event.target.value;
   }
  }
}
