import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GenericResponseModel } from '../model/genricresponse';
import { SettlementModel } from '../model/settlement';
import { GenericResponse } from '../model/genricmodelresponse';

@Injectable({
    providedIn: "root"
  })
  export class ReportServices {

    constructor(private httpClient:HttpClient) {}

  private baseUrl = environment.devurl;
  private auth = environment.headers;

    public getRevenueReport(revenue){
       // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.post<GenericResponse>(this.baseUrl+"/reports/revenuereport",revenue,{headers});
   }

   public getSettledReport(settled){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.post<GenericResponseModel>(this.baseUrl+"/reports/settledreport",settled,{headers});
 }

 public getSettlementAndTransactionReport(settled){
      // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.post<GenericResponseModel>(this.baseUrl+"/reports/settlementtrnxreport",settled,{headers});
 }

 public settledreportById(id){
   // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
  return this.httpClient.get<SettlementModel>(this.baseUrl+"/reports/settledreportById"+"/"+id,{headers});
}

public settlementreport(settlement){
   // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
  return this.httpClient.post<GenericResponseModel>(this.baseUrl+"/reports/settlementreport",settlement,{headers});
}

public orderinfo(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
  return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/reports/orderinfo"+"/"+id,{headers});
}

public vehiclepaymentinfo(id){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
  return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/reports/vehiclepaymentinfo"+"/"+id,{headers});
}

public getFuelStockReport(){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
  return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/stockreport/fuel",{headers});
}

public getRefundReport(status){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
  return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/reports/refundreport"+"/"+status,{headers});
}

public getRefundReportCancel(status){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/reports/refundreportcancel"+"/"+status,{headers});
}

public getRefundSettlementReport(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
  return this.httpClient.get<GenericResponse>(this.baseUrl+"/reports/refundsettlementbank"+"/"+id,{headers});
}

public getRefundCompleted(id,refund){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
  return this.httpClient.put<GenericResponse>(this.baseUrl+"/reports/refundcomplete"+"/"+id,refund,{headers});
}

public deletetransaction(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
  return this.httpClient.delete<GenericResponseModel>(this.baseUrl+"/reports/deletetransaction"+"/"+id,{headers});
}

public getTransactionReportByDateRange(transaction){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
return this.httpClient.post<GenericResponseModel>(this.baseUrl+"/reports/tranxreport",transaction,{headers});
}

public partnerdeliveryreport(partner){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
  return this.httpClient.post<GenericResponseModel>(this.baseUrl+"/reports/partnerdeliveryreport",partner,{headers});
}

public partnerdeliveryreportById(id){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/reports/partnerdeliveryreportbyId"+"/"+id,{headers});
}

public generateClosingOTP(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
  return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/reports/generateotp"+"/"+id,{headers});
}

public readytoSettle(id){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
  return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/reports/readytosettle"+"/"+id,{headers});
}

public settlementinitiate(readyto){
   // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
  return this.httpClient.post<GenericResponseModel>(this.baseUrl+"/reports/settlementinitiate",readyto,{headers});
}

public settlementinitiateById(id){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
 return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/reports/settlementinitiatebyId"+"/"+id,{headers});
}

public settlementCompleted(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
  return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/reports/markassettled"+"/"+id,{headers});
}

public getCustomerPaymentStatus(any){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
return this.httpClient.post<GenericResponseModel>(this.baseUrl+"/reports/updatepayment",any,{headers});
}

public getPartnerPaymentStatus(id){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/reports/partnerpayment"+"/"+id,{headers});
}

public refundpayment(id){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/reports/refundpayment"+"/"+id,{headers});
}

public getyearsList(){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
  return this.httpClient.get<String[]>(this.baseUrl+"/reports/getyearsList",{headers});
}

public getmonthList(){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
  return this.httpClient.get<String[]>(this.baseUrl+"/reports/getmonthList",{headers});
}

public partnerMonthlyInvoice(any){
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
return this.httpClient.post<GenericResponseModel>(this.baseUrl+"/reports/partnermonthlyinvoice",any,{headers});
}

public viewproof(id,flag){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
  return this.httpClient.get<any>(this.baseUrl+"/reports/viewproof"+"/"+id+"/"+flag,{headers,responseType: 'Blob' as 'json'});

}

public updateStatus(id,form){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
return this.httpClient.put<GenericResponse>(this.baseUrl+"/reports/updatestatus"+"/"+id,form,{headers});
}

public upload(form:any){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
 return this.httpClient.post<GenericResponse>(this.baseUrl+"/reports/uploadcheque",form,{headers});
}

public manualRefund(id,form){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
return this.httpClient.put<GenericResponse>(this.baseUrl+"/reports/manualrefund/"+id,form,{headers});
}


public manualSettlement(id,form){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
return this.httpClient.put<GenericResponse>(this.baseUrl+"/reports/manualsettlement/"+id,form,{headers});
}


public uploadSettlement(form:any){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
 return this.httpClient.post<GenericResponse>(this.baseUrl+"/reports/readsettlement",form,{headers});
}
  }