import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { data } from 'jquery';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { GenericResponse } from '../model/genricmodelresponse';
import { UserModel } from '../model/user';
import { UserManagementService } from '../services/usermanagement.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { Workbook } from 'exceljs';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
  active = 1;
  userDetails:GenericResponse;
  userModel:UserModel;
  closeResult = '';
  message : String;
  successflag:boolean;
  div1:boolean=true;
  deleteUserId:Number;
  excelexportflag:boolean;
  responseDataListnew = [];
  constructor(private modalService: NgbModal,private usermanagementservice:UserManagementService,private router:Router,private route: ActivatedRoute,private spinnerService:NgxSpinnerService) { }

  ngOnInit(): void {
    let add = Number(sessionStorage.getItem("add"));
    let message = sessionStorage.getItem("message");
    if(add==1){
      Swal.fire({
        html: '<pre>' + message + '</pre>',
        customClass: {
          popup: 'format-pre'
        }
      }).then((value) => {
        window.location.reload();
      });; ;
      sessionStorage.setItem("add","0");
    }
    let update = Number(sessionStorage.getItem("update"));
    if(update==1){
        Swal.fire({
          html: '<pre>' + "User has been updated Successfully" + '</pre>',
          customClass: {
            popup: 'format-pre'
          }
        }).then((value) => {
          window.location.reload();
        });; ;
      sessionStorage.setItem("update","0");
    }

    this.route.queryParams.subscribe(params => {
      let value = Number(sessionStorage.getItem("successflag"));
        if(value==1){
          this.successflag=true;
          this.message = sessionStorage.getItem("message");
          sessionStorage.setItem("successflag","0");
        }
        setTimeout(function() {
          this.successflag = false;
      }.bind(this), 5000);
    });

    this.usermanagementservice.getUsers().subscribe(data=>{
      if(data.response.users.length>0){
        this.excelexportflag = true
      }else{
        this.excelexportflag = false;
      }
      this.userDetails = data;
    },err => {
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }else if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });
  }

  view(content,viewId){
    this.usermanagementservice.viewUserById(viewId).subscribe(data=>{
      this.userModel = data;
    },err => {
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }else if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open(reject,viewId){
    this.deleteUserId = viewId;
    this.modalService.open(reject, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  edit(editId){
    sessionStorage.setItem("editId",String(editId));
    this.router.navigate(['/edit-user']);
  }

  callActiveOrInactive(id,status){
    this.div1 = false;
    let username = sessionStorage.getItem("username");
    this.usermanagementservice.activeOrInactive(id,status,username).subscribe(data=>{
      if(data.flag===1){
        Swal.fire({
          html: '<pre>' + data.message + '</pre>',
          customClass: {
            popup: 'format-pre'
          }
        }).then((value) => {
          window.location.reload();
        });; ;
      }
    },err => {
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }else if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });
  }

  callActiveOrInactive1(id,status){
    let username = sessionStorage.getItem("username");
    this.usermanagementservice.activeOrInactive(id,status,username).subscribe(data=>{
      if(data.flag===1){
        Swal.fire({
          html: '<pre>' + data.message + '</pre>',
          customClass: {
            popup: 'format-pre'
          }
        }).then((value) => {
          window.location.reload();
        });; ;
      }
    },err => {
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }else if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });
  }

  delete(){
    let userId = this.deleteUserId;
    this.usermanagementservice.delete(userId).subscribe(data=>{
      if(data.flag==1){
        Swal.fire({
          html: '<pre>' + data.message + '</pre>',
          customClass: {
            popup: 'format-pre'
          }
        }).then((value) => {
          window.location.reload();
        });; ;
      }
    },err => {
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }else if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  excelexport(){
    let sno = 1;
    this.responseDataListnew = [];
    this.userDetails.response.users.forEach(element => {
      let date = element['createdDatetime'];
      let convertedDate;
      if(date==null || date==='null'){
        convertedDate = "N/A";
      }else{
        let dates = new Date(element['createdDatetime']);
        convertedDate = moment(new Date(dates)).format("DD-MM-yyyy hh:mm:ss a").toString();
      }
      var response = [];
      response.push(sno);
      response.push(element['roleName']);
      response.push(element['firstName']);
      response.push(element['mobileNumber']);
      response.push(element['emailAddress']);
      let activestatus = element['accountStatus'];
      if(activestatus==1){
        response.push('Active');
      }else if(activestatus==2){
        response.push('InActive');
      }
      response.push(convertedDate);
      this.responseDataListnew.push(response);
      sno++;
    });
    this.excelexportUsers();
}

excelexportUsers(){
  const title = 'Users Report';
  const header = ["S.No","Role","User Name", "Mobile Number", "Email Id", "Status","Onboarded Date Time"]
  const data = this.responseDataListnew;
  let workbook = new Workbook();
  let worksheet = workbook.addWorksheet('Users Report');
// Add new row
let titleRow = worksheet.addRow([title]);
// Set font, size and style in title row.
titleRow.font = { name: 'Times New Roman', family: 4, size: 16, bold: true };

// Blank Row
worksheet.addRow([]);

//Add row with current date
// let subTitleRow = worksheet.addRow(['Date : ' + this.datePipe.transform(new Date(), 'medium')]);
//Add Header Row
let headerRow = worksheet.addRow(header);
headerRow.font = {bold: true };
// Cell Style : Fill and Border
headerRow.eachCell((cell, number) => {
cell.fill = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: 'FFFFFFFF' },
  bgColor: { argb: 'FF0000FF' },
}
cell.border = {  top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
});

data.forEach(d => {
let row = worksheet.addRow(d);
let qty = row.getCell(1);
let qty1 = row.getCell(2);
let qty2 = row.getCell(3);
let qty3 = row.getCell(4);
let qty4 = row.getCell(5);
let qty5 = row.getCell(6);
let qty6 = row.getCell(7);
qty.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
qty1.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
qty2.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
qty3.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
qty4.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
qty5.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
qty6.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
}
);
workbook.xlsx.writeBuffer().then((data) => {
let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
FileSaver.saveAs(blob, 'Users Report.xlsx');
});
 }

}
