import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { BankMasterModel } from '../model/bankmaster';
import { GenericResponseModel } from '../model/genricresponse';
import { PaymentModeService } from '../services/paymentmode.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-paymentmode',
  templateUrl: './paymentmode.component.html',
  styleUrls: ['./paymentmode.component.css']
})
export class PaymentmodeComponent implements OnInit {
  paymentMode : GenericResponseModel;
  constructor(private paymentModeService:PaymentModeService,private router:Router) { }

  ngOnInit(): void {
    this.paymentModeService.getPage().subscribe(data=>{
      this.paymentMode = data;
      },err => {
        if(err.error.statusCode==406){
          this.router.navigate(['/login']);
        }else if(err.error.statusCode==500){
          this.router.navigate(['/page-error']);
        }else if(err.error.statusCode==404){
          this.router.navigate(['/pagenotfound']);
        }
     });
    }
  

}
