<app-header></app-header>

<div class="container top-mar main-argin-adj">
<div class="card">
  <div class="card-header">
    Edit User
  </div>
  
  <div class="card-body">
    <div class="alert alert-danger" *ngIf="mandetoryflag">{{message}}</div>
    <form [formGroup]="userForm">
      <div class="ch-colthree">
      <div class="ch-form-group">
        <label for="exampleInputEmail1">Select Role</label>
        <span class="required-asterisk"> *</span>
          <select id="role"  name="role" class="form-control" [ngModel]="userModel.roleId.roleId" formControlName="role">
            <option *ngFor="let roles of roleDetails" value={{roles.roleId}}>{{roles.roleName}}</option>
          </select>
      </div>
      <div class="ch-form-group">
        <label for="exampleInputEmail1">User Name</label>
        <span class="required-asterisk"> *</span>
        <input type="text" class="form-control" id="username" [(ngModel)]="userModel.firstName" formControlName="username" name="username" maxlength="15" aria-describedby="emailHelp" placeholder="Enter user name" required>
      </div>
      <div class="ch-form-group">
        <label for="exampleInputEmail1">Email address</label>
        <span class="required-asterisk"> *</span>
        <input type="email" class="form-control" id="email" [(ngModel)]="userModel.emailAddress" formControlName="email" name="email" maxlength="15" aria-describedby="emailHelp" placeholder="Enter email address" required>
      </div>
      <div class="ch-form-group">
        <label for="exampleInputEmail1">Mobile Number</label>
        <span class="required-asterisk"> *</span>
       <input type="number" class="form-control number" [(ngModel)]="userModel.mobileNumber" formControlName="mobile" id="mobile" name="mobile" autocomplete="off"  aria-describedby="emailHelp" placeholder="Enter Mobile Number" required>
      </div>
      <div class="ch-form-group">
        <label for="exampleInputEmail1">Date of Joining</label>
        <span class="required-asterisk"> *</span>
        <input type="date" class="form-control" id="date" [(ngModel)]="userModel.date" formControlName="date" name="date" aria-describedby="emailHelp" placeholder="Select Date" required>
      </div>
    </div>

      <div class="card-footer  top-margin-20">
        <div class="btn-wraper">
        <button type="submit" (click)="addUser()" class="btn save-btn">Update</button> 
        <button type="submit" (click)="clear()" class="btn reset-btn">Reset</button> 
        <button type="submit" routerLink="/user-management" class="btn cancel-btn">Cancel</button>
        </div>
  </div>
     
    </form>
  </div>
</div>
</div>