

<app-header></app-header>
    <div class="page-container ">
      <!-- START PAGE CONTENT WRAPPER -->
      <div class="page-content-wrapper ">
        <!-- START PAGE CONTENT -->
        <div class="content sm-gutter">
          <!-- START BREADCRUMBS -->
          <div class="bg-white">
            <div class="container">
              <ol class="breadcrumb breadcrumb-alt">
                <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Home</a></li>
                <li class="breadcrumb-item active">Role Menu Mapping </li>
                <div class="btn-section-wrapper">
                  <button routerLink="/role-menu-mapping-create" type="button"
                  class="label label-info" style="margin-top: -10px; margin-right: 16px;"><i class="fa fa-plus" aria-hidden="true"></i>
                        Assign Menu</button>
            </div>
                <!-- <li class="back_page"><button class="back_page" type="button" class="label label-info" routerLink="/role-menu-mapping-create"><i class="fa fa-plus" aria-hidden="true"></i> Assign Menu</button></li> -->
              </ol>
            </div>
          </div>
          <!-- END BREADCRUMBS -->
          <!-- START CONTAINER FLUID -->

          <!-- START CONTAINER FLUID -->
          <div class=" no-padding    container-fixed-lg bg-white">
            <div class="container">
              
              <!-- START card -->
              <div class="card card-transparent">
                <div style="display: none;">{{roleMenuModel.responseMessage}}</div>
                <div class="card-body">
                  <!-- <div class="btn-section-wrapper">
                    <button routerLink="/role-menu-mapping-create" type="button"
                    class="btn btn-success" style="margin-top: -10px; margin-right: 16px;"><i class="fa fa-plus" aria-hidden="true"></i>
                    Assign Menu</button>
              </div> -->
                  <div class="table-responsive" style="margin-top: 30px;">
                    <table datatable class="ch-table">
                      <thead>
                        <tr>
                          <th style="text-align: center;">Sl No</th>
                          <th  style="text-align: left;">Role Name</th>
                          <th  style="text-align: left;">Role Code</th>
                          <th  style="text-align: center;">View Assigned Menus</th>
                          <th  style="text-align: center;">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let rolemenu of roleMenuModel.response; let i=index">
                          <td align="center" class="v-align-middle ">{{i + 1}}</td>
                          <td *ngIf="rolemenu.roleId!=null" align="left"  class="v-align-middle">{{rolemenu.roleId.roleName}}</td>
                          <td *ngIf="rolemenu.roleId==null" align="left"  class="v-align-middle">N/A</td>
                          <td *ngIf="rolemenu.roleId!=null"align="left" class="v-align-middle">{{rolemenu.roleId.roleCode}}</td>
                          <td *ngIf="rolemenu.roleId==null"align="left" class="v-align-middle">N/A</td>
                          <td align="center" class="v-align-middle">
                            <button title="View" style="margin-left: 10px;" class="label label-info" (click)="open(content,rolemenu.roleId.roleId)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                          </td>
                          <td align="center" class="v-align-middle">
                            <button title="Edit" class="label label-success" (click)="edit(rolemenu.roleId.roleId,rolemenu.roleId.roleName)" ><i class="fa fa-pencil-square" aria-hidden="true"></i></button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- END card -->
            </div>
          </div>
          <!-- END CONTAINER FLUID -->

                   
     
        </div>
        <!-- END PAGE CONTENT -->
        <!-- START COPYRIGHT -->
        <!-- START CONTAINER FLUID -->
        <!-- START CONTAINER FLUID -->
        <!-- END COPYRIGHT -->
      </div>
      <!-- END PAGE CONTENT WRAPPER -->
    </div>
    <!-- END PAGE CONTAINER -->
    <!--START QUICKVIEW -->
    <!-- END QUICKVIEW-->
    <!-- START OVERLAY -->
    <ng-template #content let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Assigned Menus</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
      </div>
      <div class="modal-body">
        <h6>Menu list</h6>
        <div *ngFor="let menu of assignedMenuModel.response;">
          <form>
            <div *ngIf="menu.menuId.parentMenu==1 || menu.menuId.parentMenu==2">
              <p *ngIf="menu.menuId.parentMenu==1"><li>{{menu.menuId.menuName}}</li></p>
                <div *ngIf="menu.menuId.parentMenu==2">  
                <ul>
                    <li>{{menu.menuId.menuName}}</li>
                  </ul>
                </div>
            </div>
          </form>
        </div>
        <!-- <form>
          <ul>
      <li>User Management 
      <ul>
        <li>Role Menu Mapping </li>
        <li>User Management</li>
      </ul></li>
      <li>Customers</li>
      <li>Partners
        <ul>
          <li>View Partners</li>
          <li>Partners KYC</li>
          <li>Bank Info</li> 
        </ul>
      </li>
      <li>Vehicles & Fuel</li>
      <li>Orders</li>
      <li>Transactions</li>
      <li>Settings
        <ul>
          <li>Department Master</li>
          <li>Role Master</li>
        </ul>
      </li>
      <li>Report
        <ul>
          <li>Order Report </li>
          <li>Revenue Report </li>
          <li>Fuel Refill and Balance Report</li>
        </ul>
      </li>
    </ul>
        </form> -->
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
      </div>
    </ng-template>



    <router-outlet></router-outlet>