import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { RestResponseModel } from '../model/response';
import { BankMasterModel } from '../model/bankmaster';
import { environment } from 'src/environments/environment';
import { AgentResponseModel } from '../model/agentresponse';
import { GenericResponseModel } from '../model/genricresponse';
import { UserModel } from '../model/user';
import { GenericResponse } from '../model/genricmodelresponse';

@Injectable({
    providedIn: "root"
  })
  export class Assettracking {

    constructor(private httpClient:HttpClient) {}

  private baseUrl = environment.devurl;
  private auth = environment.headers;

    public getPage(track){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.post<GenericResponseModel>(this.baseUrl+"/assettrack/page",track,{headers});
    }

  public updateDevice(id,track){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.put<GenericResponseModel>(this.baseUrl+"/assettrack/device/"+id,track,{headers});
  }

  public upload(form:any){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
   return this.httpClient.post<GenericResponse>(this.baseUrl+"/assests/update",form,{headers});
  }
  
  
public getFuelType(){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
  return this.httpClient.get<String[]>(this.baseUrl+"/vehicle/fuelinfo",{headers});
}

  }