<app-header></app-header>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
</ngx-spinner>
<div class="container  container-top-dashboard-section" style="margin-top: 110px; float:right;">
 <div><a href="#" routerLink="/order" class="" style=" margin-left: 95% !important"><span class="label label-info"><i class="back_page"></i>Back</span></a></div> 
  
   <div class="card mb-3"  style="margin-top: 20px; " #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
   <div class="">&nbsp;&nbsp;<h4 style="margin-left: 12px;">Order & Delivery Info</h4><br/>
    <div class="container"  fxLayout>
      <div class="item item-1">
        <p><b>Order ID</b></p>
        <span>{{obj.response.orderId}}</span>
      </div>
      <div class="item item-2" style="margin-left: 25px;">
        <p><b>Customer Name</b></p>
        <span style="word-wrap: break-word;">{{obj.response.customerId.firstName}} {{obj.response.customerId.lastName}}</span>
      </div>
      <div class="item item-3" style="margin-left: 25px;">
        <p><b>Customer Mobile</b></p>
        <span>{{obj.response.customerId.mobileNumber}}</span>
      </div>
      <div class="item item-3" style="margin-left: 25px;">
        <p><b>Vehicle Number</b></p>
        <span>{{obj.response.vehicleId.vehicleNumber}}</span>
      </div>
      <div class="item item-3" style="margin-left: 25px;">
        <p><b>Vehicle Name</b></p>
        <span>{{obj.response.vehicleId.vehicleName}}</span>
      </div>
       
      <div class="item item-3" >
        <p><b>Fuel Type</b></p>
        <span>{{obj.response.fuelType}}</span>
      </div>
      <div class="item item-3" >
        <p><b>Quantity</b></p>
        <span>{{obj.response.quantity}}</span>
      </div>
      <div class="item item-3" >
        <p><b>Fuel Price Per Ltr</b></p>
        <span>{{obj.response.fuelPrice}}</span>
      </div>
      <div class="item item-3">
        <p><b>Fuel Amount</b></p>
        <span>{{obj.response.fuelAmount }}</span>
      </div>
    </div><br/>
   
    <div class="container"  fxLayout>
      <div class="item item-3">
        <p><b>Ordered Kms</b></p>
        <span>{{obj.response.orderKm}}</span>
      </div>
      <div class="item item-5" >
        <p><b>Booked Date & Time</b></p>
        <span>{{obj.response.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span>
      </div>

      <div class="item item-1">
        <p><b>Delivery Agent Name</b></p>
        <span>{{obj.response.orderAcceptedBy.firstName}}&nbsp;{{obj.response.orderAcceptedBy.lastName}}</span>
      </div>
      <div class="item item-2" style="margin-left: 25px;">
        <p><b>Delivery Agent Number</b></p>
        <span style="word-wrap: break-word;">{{obj.response.orderAcceptedBy.mobileNumber}}</span>
      </div>
      <div class="item item-3" style="margin-left: 25px;">
        <p><b>Delivery Location</b></p>
        <span>{{obj.response.deliveryAddress}}</span>
      </div>
    
      <div class="item item-3" style="margin-left: 25px;">
        <p><b>Delivered Quantity</b></p>
        <span>{{obj.response.dispensedFuelLt}}</span>
      </div>
       
      <div class="item item-3" >
        <p><b>Delivery Date & Time</b></p>
        <span>{{obj.response.orderDeliveredDateTime}}</span>
      </div>
       
      
      <div class="item item-4" >
        <p><b>Order Status</b></p>
        <span *ngIf="obj.response.orderStatus==='Pending'"><span class="ht-Pending"><b>{{obj.response.orderStatus}}</b></span></span>
        <span *ngIf="obj.response.orderStatus==='Cancelled'"><span style="color: red;"><b>{{obj.response.orderStatus}}</b></span></span>
        <span *ngIf="obj.response.orderStatus==='Inprogress'"><span style="color: blue;"><b>{{obj.response.orderStatus}}</b></span></span>
        <span *ngIf="obj.response.orderStatus==='Completed'"><span class="ht-active"><b>{{obj.response.orderStatus}}</b></span></span>
        <span *ngIf="obj.response.orderStatus==='Accepted'"><span style="color: blue;"><b>{{obj.response.orderStatus}}</b></span></span>

        
      </div>
      
   </div>
</div>
<br/>

<hr class="beautiful-line">
<div class=""><h4 style="margin-left: 12px;">Payment Info</h4><br/>
<div class="container"  fxLayout>
  <div class="item item-3" >
    <p><b>Payment Track ID</b></p>
    <span>{{obj.response.trackId}}</span>
  </div>
  <div class="item item-5" style="margin-left: 25px;">
    <p><b>Payment Mode</b></p>
    <span>{{obj.response.paymentModeId.paymentMode}}</span>
  </div>
 
  <div class="item item-3" style="margin-left: 25px;">
    <p><b>Delivery Charges</b></p>
    <span>Rs. {{obj.response.deliveryCharges | number : '1.2-2'}}</span>
  </div>
  <div class="item item-3" style="margin-left: 25px;">
    <p><b>Platform Charges</b></p>
    <span>Rs. {{obj.response.internetHandlingFee | number : '1.2-2'}}</span>
  </div>
  <div class="item item-5" style="margin-left: 25px;">
    <p><b>CGST ({{obj.response.cgstPercent}} %)</b></p>
    <span *ngIf="obj.response.gstFlag==1">Rs. {{((obj.response.deliveryCharges+obj.response.internetHandlingFee)*obj.response.cgstPercent)/100 | number : '1.2-2'}}</span>
    <span *ngIf="obj.response.gstFlag==0">Rs. {{(obj.response.internetHandlingFee*obj.response.cgstPercent)/100 | number : '1.2-2'}}</span>

  </div>
  <div class="item item-5" style="margin-left: 25px;">
    <p><b>SGST ({{obj.response.sgstPercent}}%)</b></p>
    <span *ngIf="obj.response.gstFlag==1">Rs. {{((obj.response.deliveryCharges+obj.response.internetHandlingFee)*obj.response.sgstPercent)/100 | number : '1.2-2'}}</span>
    <span *ngIf="obj.response.gstFlag==0">Rs. {{(obj.response.internetHandlingFee*obj.response.sgstPercent)/100 | number : '1.2-2'}}</span>
  </div>
  <div class="item item-5" style="margin-left: 25px;">
    <p><b>Total Payable Amount</b></p>
    <span>Rs. {{obj.response.totalPayableAmount | number : '1.2-2'}}</span>
  </div>
  
  <div class="item item-4" style="margin-left: 25px;" *ngIf="obj.response.paymentModeId.id==1">
    <p><b>Payment Status</b></p>
    <span *ngIf="obj.response.paymentStatus!=null && obj.response.paymentStatus==='Success' "class="v-align-middle ht-active">{{obj.response.paymentStatus}}</span>
        <span *ngIf="obj.response.paymentStatus!=null && obj.response.paymentStatus==='Failure' " style="color: red;">Failed</span>
        <span *ngIf="obj.response.paymentStatus!=null && obj.response.paymentStatus==='Cancelled' " style="color: red;">{{obj.response.paymentStatus}}</span>
        <span *ngIf="obj.response.paymentStatus!=null && obj.response.paymentStatus==='cancelled' " style="color: red;">{{obj.response.paymentStatus}}</span>
        <span *ngIf="obj.response.paymentStatus!=null && obj.response.paymentStatus==='Initiated' " style="color: blue;">{{obj.response.paymentStatus}}</span>
  </div>
  <div class="item item-4" style="margin-left: 25px;" *ngIf="obj.response.paymentModeId.id!=1">
    <p><b>Payment Status</b></p>
    <span *ngIf="obj.response.approvalStatus==='Pending' " class="v-align-middle ht-inactive">{{obj.response.approvalStatus}}</span> 
    <span *ngIf="obj.response.approvalStatus==='Submitted' "class="v-align-middle ht-active">
      <span *ngIf="obj.response.chequeClaimStatus=='Success'">Success</span> 
      <span *ngIf="obj.response.chequeClaimStatus!='Success'"> {{obj.response.approvalStatus}}</span> 
     
    </span> 

    <span *ngIf="obj.response.approvalStatus==='Success' || obj.response.approvalStatus==='Approved'"class="v-align-middle ht-active">Success</span> 
    <span *ngIf="obj.response.approvalStatus==='Failed' " style="color: red;">Failed</span> 
    <span *ngIfElse="obj.response.approvalStatus!=null " style="color: orange;">{{obj.response.approvalStatus}}</span> 

  </div>
 

</div><br/>
<div class="container"  fxLayout>
  <div class="item item-2" *ngIf="obj.response.paymentModeId.id==3 || obj.response.paymentModeId.id==5">
    <p class="card-title"><b> Reference Number</b>
      <br>
      <span *ngIf="obj.response.paymentReferenceNo!=null">
          {{obj.response.paymentReferenceNo}}
         
        </span> 
        <span *ngIf="obj.response.paymentReferenceNo==null">N/A</span>
     </p>
  </div>
  <div class="item item-2" *ngIf="obj.response.paymentModeId.id==2 || obj.response.paymentModeId.id==8">
    <p class="card-title"><b>Received Amount</b>
      <br>
      <span>{{obj.response.receivedAmount | number : '1.2-2'}}</span> 
     </p>
  </div>

  <div class="item item-2" *ngIf="obj.response.paymentModeId.id==5">
    <p class="card-title"><b>Cheque Claim Status</b>
      <br>
      <span *ngIf="obj.response.chequeClaimStatus!=null">
        <span *ngIf="obj.response.chequeClaimStatus=='Bounced'" style="color: red;">{{obj.response.chequeClaimStatus}}</span> 
        <span *ngIf="obj.response.chequeClaimStatus!='Bounced'" style="color: green;">{{obj.response.chequeClaimStatus}}</span> 

      </span> 
      <span *ngIf="obj.response.chequeClaimStatus==null">N/A</span>
    </p>
  </div>
  <div class="item item-2" *ngIf="obj.response.chequeClaimStatus=='Bounced'">
    <p class="card-title"><b>Cheque Bounced Charges</b>
      <br>
      <span *ngIf="obj.response.chequeBounceCharges!=null">{{obj.response.chequeBounceCharges}}</span> 
      <span *ngIf="obj.response.chequeBounceCharges==null">N/A</span>
    </p>
  </div>
  <div class="item item-2" *ngIf="obj.response.chequeClaimStatus=='Bounced'">
    <p class="card-title"><b>Cheque Bounced Date</b>
      <br>
      <span *ngIf="obj.response.chequeBounceDateTime!=null">{{obj.response.chequeBounceDateTime | date:'dd/MM/yyyy'}}</span> 
      <span *ngIf="obj.response.chequeBounceDateTime==null">N/A</span>
    </p>
  </div>
  <div class="item item-2" *ngIf="obj.response.chequeBounceFilename!=null">
    <p class="card-title"><b>Cheque/DD Bounce Invoice</b>
      <br>
      <span *ngIf="obj.response.chequeBounceFilename!=null">
        <button title="View Invoice" style="margin-left: 5px;" (click)="invoice(obj.response.id)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
        <button title="Download Invoice" style="margin-left: 5px;"  (click)="download(obj.response.id,obj.response.chequeBounceFilename)" class="label label-success" ><i class="fa fa-download" aria-hidden="true"></i></button>
        <button title="Send Invoice" style="margin-left: 5px;" (click)="sendMail(obj.response.id)" class="label label-success" ><i class="fa fa-envelope" aria-hidden="true"></i></button>

      </span> 
      <span *ngIf="obj.response.chequeBounceFilename==null">N/A</span>
    </p>
  </div>
  <div class="item item-8"  *ngIf="obj.response.paymentModeId.id==1">
    <p class="card-title"><b>Transaction Date & Time </b> <br><span>{{obj.response.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span></p>
  </div>
  <div class="item item-8" *ngIf="obj.response.paymentModeId.id!=1">
    <p class="card-title"><b>Remarks </b> <br><span>{{obj.response.approvalRemarks}}</span></p>
  </div>
  <div class="item item-8"  *ngIf="obj.response.paymentModeId.id!=1">
    <p class="card-title"><b>Payment Date & Time </b> <br><span>{{obj.response.approvalDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</span></p>
  </div>

</div>
<br/>
 
<hr class="beautiful-line">
<div class=""><h4 style="margin-left: 12px;">Asset Info</h4><br/>
  <div class="container"  fxLayout>
    <div class="item item-1">
      <p><b>Authorized Person</b></p>
      <span *ngIf="asstesInfo.response!=null">
        <span *ngIf="asstesInfo.response.assetId!=null">{{asstesInfo.response.assetId.authorizedPersonName}}</span>
        <span *ngIf="asstesInfo.response.assetId==null">N/A</span>
       </span> 
       <span *ngIf="asstesInfo.response==null">N/A</span>
     </div>
    <div class="item item-2" style="margin-left: 25px;">
      <p><b>DGKVA</b></p>
      <span *ngIf="asstesInfo.response!=null">
        <span *ngIf="asstesInfo.response.assetId!=null">{{asstesInfo.response.assetId.dgKVA}}</span>
        <span *ngIf="asstesInfo.response.assetId==null">N/A</span>
       </span> 
       <span *ngIf="asstesInfo.response==null">N/A</span>
    </div>
    <div class="item item-3" style="margin-left: 25px;">
      <p><b>Asset Make</b></p>
     <span *ngIf="asstesInfo.response!=null">
      <span *ngIf="asstesInfo.response.assetId!=null">{{asstesInfo.response.assetId.assestMake}}</span>
      <span *ngIf="asstesInfo.response.assetId==null">N/A</span>
     </span> 
     <span *ngIf="asstesInfo.response==null">N/A</span>
    </div>
    <div class="item item-3" style="margin-left: 25px;">
      <p><b>Asset Model</b></p>
      <span *ngIf="asstesInfo.response!=null">
       <span *ngIf="asstesInfo.response.assetId!=null">{{asstesInfo.response.assetId.assestModel}}</span>
       <span *ngIf="asstesInfo.response.assetId==null">N/A</span>
      </span> 
      <span *ngIf="asstesInfo.response==null">N/A</span>
    </div>
    <div class="item item-3" style="margin-left: 25px;">
      <p><b>Contact Mobile Number</b></p>
     <span *ngIf="asstesInfo.response!=null">
      <span *ngIf="asstesInfo.response.assetId!=null">{{asstesInfo.response.assetId.contactMobileNumber}}</span>
      <span *ngIf="asstesInfo.response.assetId==null">N/A</span>
     </span> 
     <span *ngIf="asstesInfo.response==null">N/A</span>
    </div>
     
    <div class="item item-3" >
      <p><b>Fuel Type</b></p>
     <span *ngIf="asstesInfo.response!=null">
      <span *ngIf="asstesInfo.response.assetId!=null">
        <span *ngIf="asstesInfo.response.assetId.fuelType!=null">
          {{asstesInfo.response.assetId.fuelType.fuelTypeName}}
        </span>
        <span *ngIf="asstesInfo.response.assetId.fuelType==null">N/A</span>
        </span>
      <span *ngIf="asstesInfo.response.assetId==null">N/A</span>
     </span> 
     <span *ngIf="asstesInfo.response==null">N/A</span>
    </div>
    <div class="item item-3" >
      <p><b>Tank Capacity</b></p>
      <span *ngIf="asstesInfo.response!=null">
       <span *ngIf="asstesInfo.response.assetId!=null">{{asstesInfo.response.assetId.tankCapacity}}</span>
       <span *ngIf="asstesInfo.response.assetId==null">N/A</span>
      </span> 
      <span *ngIf="asstesInfo.response==null">N/A</span>
    </div>
  </div>
</div>


<hr *ngIf="obj.response.status=='Cancelled'" class="beautiful-line">
<h5 *ngIf="obj.response.status=='Cancelled'" style="margin-left: 15px; text-align: left; color: grey;">Order Track Info</h5>
<br>
<div *ngIf="obj.response.status=='Cancelled'"class="container"
  fxLayout
>
<div class="item item-1" style="margin-left: 20px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderPlaced==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderPlaced==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Placed</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderPlacedDateTime!=null" style="text-align: center;">
    {{orderTrackDetails.response.orderPlacedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderPlacedDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-2" style="margin-left: -80px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-3" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderAccepted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderAccepted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>    
  </span>
  <span style="margin-bottom: 10px;"><b>Agent Accepted</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderAcceptedDateTime!=null">
    {{orderTrackDetails.response.orderAcceptedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span style="color: red;" *ngIf="orderTrackDetails.response.orderAcceptedDateTime==null">
    Order Cancelled
  </span>
</div>
<div class="item item-4" style="margin-left: -50px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-5" style="margin-left: 15px;">
  <img *ngIf="orderTrackDetails.response.paymentCompleted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.paymentCompleted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>    
    <span style="margin-bottom: 10px; width: 30px;"><b>Payment Completed</b></span><br>
    <span style="color: red;">
      Order Cancelled
    </span>    
</div>
<div class="item item-6" style="margin-left: -60px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-7" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderInitiated==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderInitiated==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Initiated</b></span><br>
  <span style="color: red;">
    Order Cancelled
  </span>  
</div>
<div class="item item-8" style="margin-left: -40px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-9" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.refillOtp==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.refillOtp==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Refill OTP Verified</b></span><br>
  <span style="color: red;">
    Order Cancelled
  </span>  
</div>
<div class="item item-10" style="margin-left: -60px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-11" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.endOrderOtp==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.endOrderOtp==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>END Order OTP Verified</b></span><br>
  <span style="color: red;">
    Order Cancelled
  </span>  
</div>
<div class="item item-12" style="margin-left: -80px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-13" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderCompleted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderCompleted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Completed</b></span><br>
  <span style="color: red;">
    Order Cancelled
  </span>  
</div>
<div class="item item-14" style="margin-left: -40px;">
  <b><hr width="60px" color="pink"></b>
</div>
<div class="item item-14" style="margin-left: 15px;">
  <span>
    <img src="assets/img/remove.svg" alt="logo" data-src="assets/img/remove.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Cancelled</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderPlacedDateTime!=null" style="text-align: center;">
    {{orderTrackDetails.response.orderCancelledDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>  
</div>
  </div>


  <hr *ngIf="obj.response.status!=='Cancelled'" class="beautiful-line">
  <h5 *ngIf="obj.response.status!=='Cancelled'" style="margin-left: 15px; text-align: left; color: grey;">Order Track Info</h5>
  <br>
  <div *ngIf="obj.response.status!=='Cancelled'"class="container"
  fxLayout
>
<div class="item item-1" style="margin-left: 20px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderPlaced==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderPlaced==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Placed</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderPlacedDateTime!=null" style="text-align: center;">
    {{orderTrackDetails.response.orderPlacedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderPlacedDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-2" style="margin-left: -80px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-3" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderAccepted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderAccepted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>    
  </span>
  <span style="margin-bottom: 10px;"><b>Agent Accepted</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderAcceptedDateTime!=null">
    {{orderTrackDetails.response.orderAcceptedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderAcceptedDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-4" style="margin-left: -50px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-5" style="margin-left: 15px;">
  <img *ngIf="orderTrackDetails.response.paymentCompleted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.paymentCompleted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>    
    <span style="margin-bottom: 10px;"><b>Payment Completed</b></span><br>
    <span *ngIf="orderTrackDetails.response.paymentCompletedDateTime!=null">
      {{orderTrackDetails.response.paymentCompletedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
    </span>
    <span *ngIf="orderTrackDetails.response.paymentCompletedDateTime==null">
      Will be Updated
    </span>    
</div>
<div class="item item-6" style="margin-left: -60px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-7" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderInitiated==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderInitiated==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Initiated</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderInitiatedDateTime!=null">
    {{orderTrackDetails.response.orderInitiatedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderInitiatedDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-8" style="margin-left: -40px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-9" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.refillOtp==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.refillOtp==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Refill OTP Verified</b></span><br>
  <span *ngIf="orderTrackDetails.response.refillDateTime!=null">
    {{orderTrackDetails.response.refillDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.refillDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-10" style="margin-left: -60px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-11" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.endOrderOtp==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.endOrderOtp==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>END Order OTP Verified</b></span><br>
  <span *ngIf="orderTrackDetails.response.endOrderDateTime!=null">
    {{orderTrackDetails.response.endOrderDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.endOrderDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-12" style="margin-left: -80px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-13" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderCompleted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderCompleted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Completed</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderCompletedDateTime!=null">
    {{orderTrackDetails.response.orderCompletedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderCompletedDateTime==null">
    Will be Updated
  </span>
</div>
  </div> 
 
<hr class="beautiful-line">

<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
  <!-- <li [ngbNavItem]="1">
  
    <a ngbNavLink>Transactions</a>
    <ng-template ngbNavContent>
      <br>
     
      <div  class="card-body">
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      
                      <th align="left" >Sl.No</th>
                      <th align="left" >Payment Mode</th>
                      <th align="left" >Transaction ID</th>
                      <th align="center" >Paid Amount</th>
                      <th style="text-align: right;">Payment Status</th>
                      <th align="left" >Payment Date Time </th>
                      
                    </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let order of obj.response.transactionList; let i=index">
                        <td align="center" class="v-align-middle ">{{i + 1}}</td>
                        <td align="left" class="v-align-middle">{{order.paymentModeId.paymentMode}}</td>
                        <td align="left" class="v-align-middle">{{order.paymentId}}</td>
                        <td align="left" class="v-align-middle">{{order.paidAmount | number : '1.2-2'}}</td>

                        <td *ngIf="order.paymentStatus==='Initiated'" align="center" style="color: orange;">Initiated</td>
                        <td *ngIf="order.paymentStatus==='Success'" align="center" style="color: green;">Success</td>
                        <td *ngIf="order.paymentStatus==='Failure'" align="center" style="color: red;">Failure</td>

                        <td *ngIf="order.paymentDatetime!=null" align="left" class="v-align-middle">{{order.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.paymentDatetime==null" align="left" class="v-align-middle">N/A</td>
                        
                      </tr>
                  </tbody>
                </table>
          </div>
        </div>
        </ng-template>
    </li> -->

    <li [ngbNavItem]="1">
    
      <a ngbNavLink>Excess Quantity</a>
      <ng-template ngbNavContent>
        <br>
       
       
        <div class="card-body" *ngIf="obj.response.paymentModeId.id==1">
                <div class="table-responsive">
                  <table datatable class="ch-table">
                    <thead>
                      <tr> 
                        <th style="text-align: center;">Sl.No.</th>
                        
                        <th align="left" >Excess Quantity</th>
                        <th align="center" >Excess Fuel Cost</th>
                        <th align="left" >Payment Mode</th>
                        <th align="left" >Transaction ID</th>
                        <th align="center" >Paid Amount</th>
                        <th style="text-align: right;">Payment Status</th>
                        <th align="left" >Payment Date Time </th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let trnx of  obj.response.excessQtyList;  let i=index">
                          <td align="center" class="v-align-middle ">{{obj.response.quantity-obj.response.dispensedFuelLt}}</td>

                          <td align="center" class="v-align-middle ">{{(obj.response.quantity-obj.response.dispensedFuelLt)*obj.response.obj.response.fuelPrice}}</td>
                          
                          <td align="left" class="v-align-middle">{{trnx.paymentModeId.paymentMode}}</td>
                          <td align="left" class="v-align-middle">{{trnx.paymentId}}</td>
                          <td align="left" class="v-align-middle">{{trnx.paidAmount  | number : '1.2-2'}}</td>
  
                          <td *ngIf="trnx.paymentStatus==='Initiated'" align="center" style="color: orange;">Initiated</td>
                          <td *ngIf="trnx.paymentStatus==='Success'" align="center" style="color: green;">Success</td>
                          <td *ngIf="trnx.paymentStatus==='Failure'" align="center" style="color: red;">Failure</td>
  
                          <td *ngIf="trnx.paymentDatetime!=null" align="left" class="v-align-middle">{{trnx.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                          <td *ngIf="trnx.paymentDatetime==null" align="left" class="v-align-middle">N/A</td>
                          
                        </tr>
                    </tbody>
                  </table>
            </div>
          </div>

          <div class="card-body" *ngIf="obj.response.paymentModeId.id!=1">
            <div class="container" *ngIf="obj.response.dispensedFuelLt>obj.response.quantity" fxLayout>
              <div class="item item-3" >
                <p><b>Payment Mode</b></p>
                <span>{{obj.response.paymentModeId.paymentMode}}</span>
              </div>
              <div class="item item-3" >
                <p><b>Excess Quantity</b></p>
                <span>{{obj.response.dispensedFuelLt-obj.response.quantity | number : '1.2-2'}}</span>
              </div>
              <div class="item item-3" >
                <p><b>Excess Fuel Amount</b></p>
                <span>{{(obj.response.dispensedFuelLt-obj.response.quantity)*obj.response.fuelPrice | number : '1.2-2'}}</span>
              </div>
          </div>
          <div class="container" *ngIf="obj.response.dispensedFuelLt<=obj.response.quantity" fxLayout>

            </div>
          </div>

          </ng-template>
      </li>

      <li [ngbNavItem]="2">
    
        <a ngbNavLink>Receipts</a>
        <ng-template ngbNavContent>
          <br>
         
         
          <div class="card-body">
                  <div class="table-responsive">
                    <table datatable class="ch-table">
                      <thead>
                        <tr> 
                          <th style="text-align: center;">Sl.No.</th>
                          <th align="center" >Receipts</th>
                          <th align="left" >Created Date Time </th>
                        </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let receipt of  obj.response.uploadReceiptList;  let i=index">
                            <td align="center" class="v-align-middle ">{{i+1}}</td>
  
                            <td align="center" class="v-align-middle ">
                              <span > 
                                <button title="View Receipts" class="label label-info" (click)="viewReceipts(receipt.receiptId,receipt.uploadreceipt,content1)"><i class="fa fa-money" aria-hidden="true"></i></button>&nbsp;
                               </span>
                             </td>
                            <td align="left" class="v-align-middle">{{receipt.createdDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                          
                          </tr>
                      </tbody>
                    </table>
              </div>
            </div>
            </ng-template>
        </li>

</ul>
<ng-template #content1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">View Receipts </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
    </div>
    <hr class="beautiful-line">
   
    <div *ngIf="bakImage">
      <br>
          <img class="modal-content"  [src]="imageToShowProof" width="100%" height="100%">
    </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">close</button>
  </div>
</ng-template>


<div [ngbNavOutlet]="nav" class="mt-2"></div>
</div> 

