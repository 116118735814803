import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { BankModel } from '../model/approvebank';
import { GenericResponseModel } from '../model/genricresponse';
import { KycDocumentsModel } from '../model/kycdocument';
import { UserModel } from '../model/user';
import { CustomerService } from '../services/customer.service';
import { PartnersService } from '../services/partners.service';
import { UserService } from '../services/user.service';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { Observable } from 'rxjs';
import { VehicleFuelService } from '../services/vehivle.service';
import { VehicleAttachModel } from '../model/vehicleModel';
import * as FileSaver from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-partners-kyc-view',
  templateUrl: './partners-kyc-view.component.html',
  styleUrls: ['./partners-kyc-view.component.css']
})
export class PartnersKycViewComponent {
  userForm = new FormGroup({
    date: new FormControl(),
}); 
  lat:Number;
  lan:Number;
 public isCollapsed = false;
 currentDate = new Date();
 date:String;
 
 active = 1;
 partnerId:number;
 kycdocumentmodel:GenericResponseModel;
 kycdocumentmodelPending:GenericResponseModel;
 kycdocumentmodelApprove:GenericResponseModel;
 kycdocumentmodelReject:GenericResponseModel;
 pendingkyc:boolean = true;
 approvekyc:boolean;
 rejectkyc:boolean;
 allkyc:boolean = false;
 kycPrimaryId:Number;
 bakImage:boolean = false;
  imageUpload:boolean=true;
  pdfUpload:boolean=false;
kycId:Number;
kycresponse:GenericResponseModel;
kycapprove:boolean;
message:String;
userId:Number;
poppup:boolean;
poppupalert:boolean;
checkcondition:Number;
kycDocument:Object;

kycdocumentss : KycDocumentsModel[];
li:any; 
  lis=[]; 
 usermodel:UserModel;
  resultText=[];  
  values:string;  
 count:number=0;  
 errorMsg:string;  
 companyName:string;
closeResult = '';
selectedAll: any;
imageToShow: any;
  isImageLoading: boolean;
  isImageLoading1: boolean;
  newWindow:any;
  isImageLoadingPartner: boolean;
  imageToShowPartner: any;
  imageToShowkyc: any;
isCheck:boolean = false;
imageSrc: string;
imageError: string;
isImageSaved: boolean;
cardImageBase64: string;
selectedFiles: FileList;
selectedFiles1: FileList;
currentFile: File;
progress = 0;
mes = '';
fileInfos: Observable<any>;
latitude:any;
  langitude:any;
  kycpendinglist:GenericResponseModel;
  kycalert:boolean;
  kycresponseString:String;
  emptyCheckResponse:String;
  emptyCheck:boolean;
  vehicleModel:VehicleAttachModel;
  popupshow:boolean;
  documentCheck:boolean;
  kycdocumentId:Number;
  kycdocumentcheck:boolean;
  pendingcheck:boolean = false;
myForm = new FormGroup({
  name: new FormControl('', [Validators.required, Validators.minLength(3)]),
  file: new FormControl('', [Validators.required]),
  fileSource: new FormControl('', [Validators.required])
});
  constructor(private modalService: NgbModal,private customerService: CustomerService,private userservice:UserService, private route: ActivatedRoute,private partnerservice: PartnersService,private router: Router,private http : HttpClient,private vehicleservice:VehicleFuelService,private spinnerService:NgxSpinnerService ) {
    if (navigator)
    {
    navigator.geolocation.getCurrentPosition( pos => {
        this.langitude = +pos.coords.longitude;
        this.latitude = +pos.coords.latitude;
      });
    }
  }
  ngOnInit() : void {

    let vehiId = Number(sessionStorage.getItem("vehicleId"));
    this.partnerservice.getPartnerPending(vehiId).subscribe(data=>{
      this.kycdocumentmodelPending = data;
    });

    this.poppup = false;
    this.route.queryParams.subscribe(params => {
      let parkyc = Number(sessionStorage.getItem("kyc"));

      let vehiId = Number(sessionStorage.getItem("vehicleId"));
      this.partnerservice.getPendingKycDocument(vehiId).subscribe(data=>{
        if(data.statusCode===200){
          this.pendingcheck = true;
        }else{
          this.pendingcheck = false;
        }
      });
      this.vehicleservice.getVehicleById(vehiId).subscribe(data=>{
        this.vehicleModel = data;
        this.partnerId = this.vehicleModel.userId.userId;
      });

      this.userservice.getUserImage(parkyc).subscribe(data=>{
        if(data.type==='text/xml'){
          this.isImageLoadingPartner = false;
        }else{
          this.isImageLoadingPartner = true;
        }
        this.createImageFromBlob1(data);
      });  

      this.userId = parkyc;
      this.partnerservice.getPartnersById(parkyc).subscribe(data=>{
        this.usermodel=data;
      });
      this.partnerservice.getKycInfo(vehiId).subscribe(data=>{
        this.kycdocumentmodel = data;
        this.kycDocument = data.response;
      });
    });
  }

  onChange(id:Number,event) {  
    this.errorMsg="";  
     const checked = event.target.checked;  
      if (checked) {  
        this.resultText.push(id);  
         } else {  
           const index = this.resultText.indexOf(id);  
           this.resultText.splice(index, 1);  
       }  
       this.values=this.resultText.toString();  
       const count=this.resultText.length;  
       this.count=count;  
    }  

  get f(){
    return this.myForm.controls;
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
  }

  selectFile1(event) {
    this.selectedFiles1 = event.target.files;
  }

  uploadKyc(){
    let kycfile = (<HTMLInputElement>document.getElementById('kycfile')).value;
    let file='';
    let kycTypeId;
      if(kycfile!==null && kycfile!==''){
        this.progress = 0;
        this.currentFile = this.selectedFiles.item(0);
        file = this.currentFile.type;
        let arr =  file.split("/");
        let fileType = arr[1];
        fileType = 'Image';
        let lat = this.latitude;
        let lan = this.langitude;
        let username = sessionStorage.getItem('username');
        let partnerId = Number(sessionStorage.getItem("kyc"));
        let vehiId = Number(sessionStorage.getItem("vehicleId"));
        let input = new FormData();
        if(this.kycdocumentcheck){
          kycTypeId = this.kycdocumentId;
        }else{
          let kycdocument = (<HTMLInputElement>document.getElementById('kycdocument')).value;
          kycTypeId = kycdocument;
        }
        if(this.imageUpload){
          let kycfile1 = (<HTMLInputElement>document.getElementById('kycfile1')).value;
          if(kycfile1!=null && kycfile1!==''){
            input.append('kycFile',this.selectedFiles1.item(0));
          }
          input.append('kycFile',this.currentFile);
        }else if(this.pdfUpload){
          let kycFile = this.currentFile;
          input.append('kycFile',this.currentFile);
        }
        
        input.append('partnerId',JSON.stringify(this.partnerId));
        input.append('kycTypeId',kycTypeId);
        input.append('userName',sessionStorage.getItem('username'));
        if(this.imageUpload){
          input.append('fileType','Image');
        }else if(this.pdfUpload){
        input.append('fileType','Pdf');
        }
        input.append('latitude',lat);
        input.append('longitude',lan);
        // input.append('kycFile',JSON.stringify(fileList));
        input.append('vehicleId',JSON.stringify(vehiId));
        this.partnerservice.uploadkyc(input).subscribe(data=>{
            if(data.response.flag==1){
              this.modalService.dismissAll();
              Swal.fire({
                html: '<pre>' + data.responseMessage + '</pre>',
                customClass: {
                  popup: 'format-pre'
                }
              }).then((value) => {
                window.location.reload();
              });; ; 
              setTimeout(function() {
            }.bind(this), 3000);
            }else{
              this.kycalert = false;
              setTimeout(function() {
                this.kycresponseString = data.responseMessage;
                this.kycalert = false;
                window.location.reload();
            }.bind(this), 3000);
            }
        },err => {
          if(err.error.statusCode==406){
            this.router.navigate(['/login']);
          }else if(err.error.statusCode==500){
            this.router.navigate(['/page-error']);
          }else if(err.error.statusCode==404){
            this.router.navigate(['/pagenotfound']);
          }
       });
  
      }else{
        this.emptyCheck = true;
        this.emptyCheckResponse = "Please select file!";
        setTimeout(function() {
          this.emptyCheck = false;
      }.bind(this), 3000);
  
      }
  }

  reuploadKyc(){
    let kycfile = (<HTMLInputElement>document.getElementById('kycfile')).value;
    let file='';
    let kycTypeId;
      if(kycfile!==null && kycfile!==''){
        this.progress = 0;
        this.currentFile = this.selectedFiles.item(0);
        file = this.currentFile.type;
        let arr =  file.split("/");
        let fileType = arr[1];
        fileType = 'Image';
        let lat = this.latitude;
        let lan = this.langitude;
        let input = new FormData();
        if(this.imageUpload){
          let kycfile1 = (<HTMLInputElement>document.getElementById('kycfile1')).value;
          if(kycfile1!=null && kycfile1!==''){
            input.append('kycFile',this.selectedFiles1.item(0));
          }
          input.append('kycFile',this.currentFile);
        }else if(this.pdfUpload){
          let kycFile = this.currentFile;
          input.append('kycFile',this.currentFile);
        }
        if(this.kycdocumentcheck){
          kycTypeId = this.kycdocumentId;
        }else{
          let kycdocument = (<HTMLInputElement>document.getElementById('kycdocument')).value;
          kycTypeId = kycdocument;
        }
        input.append('userName',sessionStorage.getItem('username'));
        if(this.imageUpload){
          input.append('fileType','Image');
        }else if(this.pdfUpload){
        input.append('fileType','Pdf');
        }
        input.append('latitude',lat);
        input.append('longitude',lan);
        input.append('id',JSON.stringify(this.kycPrimaryId));
        this.partnerservice.reuploadkyc(input).subscribe(data=>{
            if(data.response.flag==1){
              this.modalService.dismissAll();
              Swal.fire({
                html: '<pre>' + data.responseMessage + '</pre>',
                customClass: {
                  popup: 'format-pre'
                }
              }).then((value) => {
                window.location.reload();
              });; ; 
              setTimeout(function() {
            }.bind(this), 3000);
            }else{
              this.kycalert = false;
              setTimeout(function() {
                this.kycresponseString = data.responseMessage;
                this.kycalert = false;
                window.location.reload();
            }.bind(this), 3000);
            }
        },err => {
          if(err.error.statusCode==406){
            this.router.navigate(['/login']);
          }else if(err.error.statusCode==500){
            this.router.navigate(['/page-error']);
          }else if(err.error.statusCode==404){
            this.router.navigate(['/pagenotfound']);
          }
       });
  
      }else{
        this.emptyCheck = true;
        this.emptyCheckResponse = "Please select file!";
        setTimeout(function() {
          this.emptyCheck = false;
      }.bind(this), 3000);
  
      }
  }

    createImageFromBlob(image: Blob) {
      let reader = new FileReader();
      reader.addEventListener("load", () => {
        this.imageToShow = reader.result;
     }, false);
     if (image) {
        reader.readAsDataURL(image);
        console.log(image)
     }
      
      // FileSaver.saveAs(image, '.pdf');
      // reader.addEventListener("load", () => {
      //    this.imageToShow = reader.result;
      // }, false);
   
      // if (image) {
      //    reader.readAsDataURL(image);
      //    console.log(image)
      // }
   }

   upload(content3) {
    this.kycdocumentcheck = false;
    let vehiId = Number(sessionStorage.getItem("vehicleId"));
    this.partnerservice.getPendingKycDocument(vehiId).subscribe(data=>{
      if(data.statusCode==404){
        this.documentCheck = true;
      }else{
        this.documentCheck = false;
        this.kycpendinglist = data;
      }
        this.modalService.open(content3, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    });
  }

  reupload(content4,id) {
    this.kycPrimaryId = id;
    this.kycdocumentcheck = true;
        this.modalService.open(content4, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
  }

  onFileChange(event) {
    const reader = new FileReader();
    
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
   
        this.imageSrc = reader.result as string;
     
        this.myForm.patchValue({
          fileSource: reader.result
        });
      };
    }
  }

  open(content) {
    if(this.resultText.length>0){
     
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
      var date = new Date().toISOString().slice(0,10);
      document.getElementById('date').setAttribute('min', date);
    }else{
    }
  }

  open1(reject) {
    if(this.resultText.length>0){
      this.modalService.open(reject, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }else{
    }
  }

  open2(content1) {
      this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  open3(content2) {
    this.modalService.open(content2, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
}

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  kycdocument(id,content1,filename,fileType){
    this.isImageLoading = true;
    this.isImageLoading1 = true;
    let dataType ='';
    this.partnerservice.getKycImage(id,2).subscribe(data=>{
        this.bakImage = true;
      if(data.type ==='application/pdf'){
        FileSaver.saveAs(data, filename);
      }else{
        dataType= data.type;
        this.createImageFromBlob2(data);
      }
      this.isImageLoading1 = false;
}, error => {
  this.isImageLoading1 = false;
  this.bakImage = false;
  this.imageToShowkyc= null;
  console.log(error);
});
  
    this.partnerservice.getKycImage(id,1).subscribe(data=>{
      if(data.type ==='application/pdf'){
        FileSaver.saveAs(data, filename);
      }else{
        dataType = data.type;
        this.createImageFromBlob(data);
      }
      this.isImageLoading = false;
}, error => {
  this.isImageLoading = false;
  console.log(error);
});
if(fileType=='Image'){
  this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}
  }

  approve(id){
      if(id===1){
        let desc = (<HTMLInputElement>document.getElementById('kyctextarea')).value;
        let date = (<HTMLInputElement>document.getElementById('date')).value;
        let form;
        if(desc!=null && desc!==''){
          this.poppupalert = false;
          if(date!=null && date!==''){
            let currentDate=new Date(date);
            form={
              approvalStatus:11,
              approvalComments:desc,
              approvalBy:sessionStorage.getItem('username'),
              kycexpiryDate:currentDate
            }
          }else{
            form={
              approvalStatus:11,
              approvalComments:desc,
              approvalBy:sessionStorage.getItem('username'),
              kycexpiryDate:null
            }
          }
         
          this.partnerservice.approveKyc(this.values,form).subscribe(data=>{
            this.modalService.dismissAll();
            Swal.fire({
              html: '<pre>' + data.responseMessage + '</pre>',
              customClass: {
                popup: 'format-pre'
              }
            }).then((value) => {
              window.location.reload();
            });; ; 
            this.kycresponse = data;
            setTimeout(function() {
          }.bind(this), 3000);
          },err => {
            if(err.error.statusCode==406){
              this.router.navigate(['/login']);
            }else if(err.error.statusCode==500){
              this.router.navigate(['/page-error']);
            }else if(err.error.statusCode==404){
              this.router.navigate(['/pagenotfound']);
            }
         });
        }else{
          this.poppupalert = true;
          setTimeout(function() {
            this.poppupalert = false;
        }.bind(this), 3000);
        }
      }else if(id===2){
        let desc = (<HTMLInputElement>document.getElementById('kyctextrejectarea')).value;
        if(desc!=null && desc!==''){
          this.poppupalert = false;
          let kyc =new BankModel(12,desc,null,sessionStorage.getItem('username'),null);
          this.partnerservice.approveKyc(this.values,kyc).subscribe(data=>{
            this.modalService.dismissAll();
            Swal.fire({
              html: '<pre>' + data.responseMessage + '</pre>',
              customClass: {
                popup: 'format-pre'
              }
            }).then((value) => {
              window.location.reload();
            });; ; 
            setTimeout(function() {
          }.bind(this), 3000);
            this.kycresponse = data;
          },err => {
            if(err.error.statusCode==406){
              this.router.navigate(['/login']);
            }else if(err.error.statusCode==500){
              this.router.navigate(['/page-error']);
            }else if(err.error.statusCode==404){
              this.router.navigate(['/pagenotfound']);
            }
         });
        }else{
          this.poppupalert = true;
          setTimeout(function() {
            this.poppupalert = false;
        }.bind(this), 3000);
        }
      }
  }

  location(lat,lan){
    this.lat = Number(lat);
    this.lan = Number(lan);
  }

  createImageFromBlob1(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
       this.imageToShowPartner = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
   }
  }

  createImageFromBlob2(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
       this.imageToShowkyc = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
   }else{
   }
  }

  CheckUncheckAll(event){
    const checked = event.target.checked;  
    if(checked){
      this.checkcondition = 1;
      this.isCheck = true;
     }else {
      this.isCheck = false;
      this.checkcondition = 0;
     }
    }

    pending(){
      let vehiId = Number(sessionStorage.getItem("vehicleId"));
      this.pendingkyc = true;
      this.approvekyc = false;
      this.rejectkyc = false;
      this.allkyc = false;
      this.partnerservice.getPartnerPending(vehiId).subscribe(data=>{
        this.kycdocumentmodelPending = data;
        console.log(data);
      });
    }

    approveS(){
      this.pendingkyc = false;
      this.approvekyc = true;
      this.rejectkyc = false;
      this.allkyc = false;
      let vehiId = Number(sessionStorage.getItem("vehicleId"));
      this.partnerservice.getPartnerApprove(vehiId).subscribe(data=>{
        this.kycdocumentmodelApprove = data;
        console.log(data);
      });
    }

    rejectkycclick(){
      this.pendingkyc = false;
      this.approvekyc = false;
      this.rejectkyc = true;
      this.allkyc = false;
      let vehiId = Number(sessionStorage.getItem("vehicleId"));
      this.partnerservice.getPartnerReject(vehiId).subscribe(data=>{
          this.kycdocumentmodelReject = data;
      });
    }
    checkupload(id){
      if(id==1){
        this.imageUpload = true;
        this.pdfUpload = false;
      }else if(id==2){
        this.imageUpload = false;
        this.pdfUpload = true;
      }
    }
}

