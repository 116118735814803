import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { GenericResponseModel} from '../model/genricresponse';
import { OrderAssignModel } from '../model/orderassign';
import { environment } from 'src/environments/environment';
import { GenericResponse } from '../model/genricmodelresponse';
import { Observable } from 'rxjs';
import { RestResponseModel } from '../model/response';

@Injectable({
    providedIn: "root"
  })
  export class OrderTransactionService {

  constructor(private httpClient:HttpClient) {}

  private baseUrl = environment.devurl;
  private auth = environment.headers;

  public getTransactions(){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/ordertrnx/transactions",{headers});
  }

  public getAllOrders(start,end){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/ordertrnx/view"+"/"+start+"/"+end,{headers});
  }

  public getOrdersByOnboardedBy(onboardedid){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
   return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/ordertrnx/viewbyonboarded"+"/"+onboardedid,{headers});
 }
  
  public getOrderById(id){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/ordertrnx/orderById"+"/"+id,{headers});
  }

  public assignto(id,assignmodel:OrderAssignModel){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.put<GenericResponseModel>(this.baseUrl+"/ordertrnx/assignto"+"/"+id,assignmodel,{headers});
  }

  public getSettlement(){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/ordertrnx/settlements",{headers});
  } 

  public getSettlementById(settlement){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.post<GenericResponseModel>(this.baseUrl+"/ordertrnx/settlementById",settlement,{headers});
  } 

  public getsettlementByPartner(settlement){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.post<GenericResponseModel>(this.baseUrl+"/ordertrnx/settlementByPartner",settlement,{headers});
  } 

  public getAllPending(start,end){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/ordertrnx/pending"+"/"+start+"/"+end,{headers});
  }

  public getAllInprogress(start,end){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/ordertrnx/inprogress"+"/"+start+"/"+end,{headers});
  }

  public getAllCompletion(start,end){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/ordertrnx/completion"+"/"+start+"/"+end,{headers});
  }

  public getAllCancellation(start,end){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/ordertrnx/cancellation"+"/"+start+"/"+end,{headers});
  }

  public getTransactionFilter(fromDate,toDate){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/ordertrnx/transactionsFilter"+"/"+fromDate+"/"+toDate,{headers});
  }

  public tracking(id){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponse>(this.baseUrl+"/ordertrnx/tracking"+"/"+id,{headers});
  }

  public cancellmessage(){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/ordertrnx/cancellmessage",{headers});
  }

  public cancelorder(id,cancelorder){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.put<GenericResponseModel>(this.baseUrl+"/ordertrnx/cancelorder"+"/"+id,cancelorder,{headers});
  }

  public generateInvoice(id){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponse>(this.baseUrl+"/ordertrnx/invoice"+"/"+id,{headers});
  }

 public generateMonthlyInvoice(any){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
   return this.httpClient.post<GenericResponse>(this.baseUrl+"/reports/partnermonthlyinvoiceById",any,{headers});
 }

 
public getInvoice(id): Observable<Blob>{
 let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
 const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
  return this.httpClient.get<any>(this.baseUrl+"/ordertrnx/getinvoice"+"/"+id,{headers,responseType: 'blob' as 'json',});
}

  public getActivatevehicle(){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/ordertrnx/activatevehicle",{headers});
  }

  public getAssets(id){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/ordertrnx/getasset"+"/"+id,{headers});
  }

  public sendInvoiceEmail(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
   return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/ordertrnx/sendinvoiceemail"+"/"+id,{headers});
 }


public download(id,flag): Observable<Blob>{
   
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<any>(this.baseUrl+"/reports/download"+"/"+id+"/"+flag,{headers,responseType: 'Blob' as 'json'});
  }

public sendMail(id,flag){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
   return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/reports/sendmail/"+id+"/"+flag,{headers});
 }

  public addCharges(any){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
   return this.httpClient.post<GenericResponse>(this.baseUrl+"/ordertrnx/additionalcharges",any,{headers});
 }
 public viewCharges(id){
 
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
 return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/ordertrnx/viewcharges/"+id,{headers});
}

public updateCharges(id,model){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
 return this.httpClient.put<GenericResponseModel>(this.baseUrl+"/ordertrnx/updatecharges/"+id,model,{headers});
}
public delete(id){
 
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
 return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/ordertrnx/deletecharge/"+id,{headers});
}

public getReceipt(id): Observable<Blob>{
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
   return this.httpClient.get<any>(this.baseUrl+"/partnerorder/receipts/"+id,{headers,responseType: 'blob' as 'json',});
 }


}