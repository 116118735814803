import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { GenericResponseModel } from '../model/genricresponse';
import { PartnerPaymentModeService } from '../services/partnerpaymentmode.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router } from '@angular/router';

@Component({
  selector: 'app-paymentmode-partner-mapping',
  templateUrl: './paymentmode-partner-mapping.component.html',
  styleUrls: ['./paymentmode-partner-mapping.component.css']
})
export class PartnerPaymentModeComponent {
  closeResult = '';
  partnerMappingModel:GenericResponseModel;
  availableMappingModel:GenericResponseModel;
  viewPage : boolean = false;
  createPage : boolean = false;
  editPage : boolean = false;

  partners:GenericResponseModel;
  paymentModes:GenericResponseModel;
  assignedPaymentModes:GenericResponseModel;
  resultText=[];
  values:string;  
  partnerName:string;
  partnerId:Number;
  constructor(
      private modalService: NgbModal,
      private pgmodeService:PartnerPaymentModeService,
      private router:Router) {}

  ngOnInit(): void {
    let message = sessionStorage.getItem("menumessage");
    if(message!='null'){
      sessionStorage.setItem("menumessage",null);
      Swal.fire({
        html: '<pre>' + message + '</pre>',
        customClass: {
          popup: 'format-pre'
        }
      }).then((value) => {
       // window.location.reload();
      });; ;
    }

    this.viewPage=true;
    this.createPage=false;
      this.pgmodeService.view().subscribe(data=>{
        this.partnerMappingModel = data;
      },err => {
        if(err.error.statusCode==406){
          this.router.navigate(['/login']);
        }else if(err.error.statusCode==500){
          this.router.navigate(['/page-error']);
        }else if(err.error.statusCode==404){
          this.router.navigate(['/pagenotfound']);
        }
     });
  }

  create(){
    this.viewPage=false;
    this.createPage=true;

    this.pgmodeService.partners().subscribe(data=>{
      this.partners = data;
    });

    this.pgmodeService.paymentmode().subscribe(data=>{
      this.paymentModes = data;
    });

  }

  assign(id:Number,event) {  
    const checked = event.target.checked;  
     if (checked) {  
       this.resultText.push(id);  
        } else {  
          const index = this.resultText.indexOf(id);  
          this.resultText.splice(index, 1);  
      }  
      this.values=this.resultText.toString();  
      const count=this.resultText.length;  
    }  

    submit(){
     
      if(this.resultText.length>0){
        let partnerId = (<HTMLInputElement>document.getElementById('partnerId')).value;
        if(partnerId!=null&& partnerId!==''){
         
          let formData= {
            partnerId:Number(partnerId),
            paymentModeIds:this.values,
            createdBy:sessionStorage.getItem("username")
          }

          this.pgmodeService.add(formData).subscribe(data=>{
            if(data.response.flag==1){
             // sessionStorage.setItem("menustatus",'1');
              sessionStorage.setItem("menumessage","Payment Mode has been assigned against partner");
              window.location.reload();
            }
          },err => {
            if(err.error.statusCode==406){
              this.router.navigate(['/login']);
            }else if(err.error.statusCode==500){
              this.router.navigate(['/page-error']);
            }else if(err.error.statusCode==404){
              this.router.navigate(['/pagenotfound']);
            }
         });
        }else{

        }
      }else{

      }
    }

    edit(partnerName,id) {
      this.partnerId = id;
      this.partnerName=partnerName;
      this.viewPage=false;   
      this.createPage=false;   
      this.editPage=true;   

      this.pgmodeService.get(id).subscribe(data=>{
        this.paymentModes = data;

        data.response.forEach(element => {
          let id = element['id'];
          let checkflag = element['flag'];
          if(checkflag==1){
            this.resultText.push(Number(id));
          }
        });


      });

    }

    update(){
     
      if(this.resultText.length>0){
     
          let formData= {
            partnerId:this.partnerId,
            paymentModeIds:this.values,
            createdBy:sessionStorage.getItem("username")
          }

          this.pgmodeService.update(formData).subscribe(data=>{
            if(data.response.flag==1){
              sessionStorage.setItem("menumessage","Payment Mode has been assigned against partner");
              window.location.reload();
            }
          },err => {
            if(err.error.statusCode==406){
              this.router.navigate(['/login']);
            }else if(err.error.statusCode==500){
              this.router.navigate(['/page-error']);
            }else if(err.error.statusCode==404){
              this.router.navigate(['/pagenotfound']);
            }
         });
        }else{

        }
    
    }

  open(content,id) {
    this.pgmodeService.partnerpaymentmode(id).subscribe(data=>{
      this.availableMappingModel = data;      
    });

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


}
