<app-header></app-header>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
</ngx-spinner> 
<!-- START PAGE-CONTAINER -->

<div class="page-container "> 
  <!-- START PAGE CONTENT WRAPPER -->
  <div class="page-content-wrapper "> 
    <!-- START PAGE CONTENT -->
    <div class="content sm-gutter"> 
      <!-- START BREADCRUMBS -->
      <div class="bg-white">
        <div class="container">
          <ol class="breadcrumb breadcrumb-alt">
            <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Home</a></li>
            <li class="breadcrumb-item active">CUSTOMER BANK</li>
            <div class="btn-section-wrapper">
              <button type="button" (click)="open1(content0)" class="label label-success">Approve</button>
  	          <button type="button" (click)="open2(content1)"class="label label-danger">Reject</button>
        </div>
          </ol>
        </div>
      </div>
      <!-- END BREADCRUMBS -->
      <!-- START CONTAINER FLUID --> 
      
      <!-- START CONTAINER FLUID -->
      <div class=" no-padding    container-fixed-lg bg-white">
        <div class="container"> 
          <br>
          <select class="select-form-style" style="margin-left: 20px;" id="searchBy" (change)="callsearch($event)">  
            <option value="1">Search By Status</option>  
            <option value="2">Search By Date Range</option>  
        </select>  
        <select class="select-form-style" style="margin-left: 10px;" id="status">
          <option value="1">All</option>  
          <option value="2" selected>Pending</option>  
          <option value="3">Approved</option>  
          <option value="4">Rejected</option>  
      </select>  
      <input type="date" style="display: none; margin-left: 10px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
        <input type="date" style="display: none; margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
         
      <button style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
      <button *ngIf="refreshFlag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
      <div *ngIf="errorFlag" style="color: red; margin-left: 20px;">{{errorMessage}}</div>
          <!-- START card -->
          <div class="card card-transparent">
             <div class="btn-section-wrapper">
              <!-- <button (click)="pendingbank()" class="btn-transaction">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="exampleRadios" id="penradio" value="option1" checked>
                  <label class="form-check-label" for="penradio">
                    Pending
                  </label>
                </div>
                </button> -->
                <!-- <button (click)="approvebank()" class="btn-transaction1">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="exampleRadios" id="appradio" value="option1">
                    <label class="form-check-label" for="appradio">
                      Approved
                    </label>
                  </div>
                  </button> -->
                  <!-- <button (click)="rejectbank()" class="btn-transaction2">
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="exampleRadios" id="rejradio" value="option1">
                      <label class="form-check-label" for="rejradio">
                        Rejected
                      </label>
                    </div>
                    </button>        -->
</div>
<form>
 
            <div *ngIf="allBank"class="card-body">
              <div style="display: none;">{{customerbankmodel.responseMessage}}</div>
              <div style="display: none;">{{customerbankmodel.statusCode}}</div>
              <div class="table-responsive">
                <br>
                <table datatable class="ch-table">
                  <thead>
                    <tr>
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: center;">&nbsp;</th> 
                      <th style="text-align: left;">Partner Name</th>
                      <th style="text-align: left;">Account Holder Name</th>
                      <th style="text-align: left;">Account Number</th>
                      <th style="text-align: left;">IFSC Code</th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: left;">Submitted Date Time</th>
                      <th style="text-align: left;">Approved Date Time</th>
                      <th style="text-align: left;">Comments</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let bank of customerbankmodel.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td align="center" class="v-align-middle "><span style="width:80px">
                        <input *ngIf="bank.approvalStatus==10" type="checkbox" value=" {{bank.id}}" (change)="onChange(bank.id,$event)">
                      </span></td>
                      <td *ngIf="bank.customerPartnerId!=null && bank.customerPartnerId.firstName!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{bank.customerPartnerId.firstName}} {{bank.customerPartnerId.lastName}}</td>
                      <td *ngIf="bank.customerPartnerId==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.beneficiaryName!=null"align="left" class="v-align-middle">{{bank.beneficiaryName}}</td>
                      <td *ngIf="bank.beneficiaryName==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.accountNumber!=null"align="left" class="v-align-middle">{{bank.accountNumber}}</td>
                      <td *ngIf="bank.accountNumber==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.ifscCode!=null"align="left" class="v-align-middle">{{bank.ifscCode}}</td>
                      <td *ngIf="bank.ifscCode==null"align="left" class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="bank.approvalStatus==11" class="v-align-middle ht-active">Approved</td>
                      <td align="center" *ngIf="bank.approvalStatus==12" style="color: red;">Rejected</td>
                      <td align="center" *ngIf="bank.approvalStatus==10" class="v-align-middle ht-Pending">Pending</td>
                      <td *ngIf="bank.submittedDatetime!=null"align="left" class="v-align-middle">{{bank.submittedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="bank.submittedDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td align="left" *ngIf="bank.approvalStatus==10" class="v-align-middle">N/A</td>
                      <td align="left" *ngIf="bank.approvalStatus==11" class="v-align-middle">{{bank.approvedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td align="left" *ngIf="bank.approvalStatus==12" class="v-align-middle">{{bank.approvedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      
                      <td align="left" *ngIf="bank.approvalStatus==10" class="v-align-middle">N/A</td>
                      <td align="left" *ngIf="bank.approvalStatus==11" class="v-align-middle">{{bank.approvalComments}}</td>
                      <td align="left" *ngIf="bank.approvalStatus==12" class="v-align-middle">{{bank.approvalComments}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div *ngIf="pendingba"class="card-body">
              <div style="display: none;">{{pendingbankModel.responseMessage}}</div>
              <div style="display: none;">{{pendingbankModel.statusCode}}</div>
              <div class="table-responsive">
                <br>
                <table datatable class="ch-table">
                  <thead>
                    <tr>
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: center;">&nbsp;</th> 
                      <th style="text-align: left;">Partner Name</th>
                      <th style="text-align: left;">Account Holder Name</th>
                      <th style="text-align: left;">Account Number</th>
                      <th style="text-align: left;">IFSC Code</th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: left;">Submitted Date Time</th>
                      <th style="text-align: left;">Approved Date Time</th>
                      <th style="text-align: left;">Comments</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let bank of pendingbankModel.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td align="center" class="v-align-middle "><span style="width:80px">
                        <input *ngIf="bank.approvalStatus==10" type="checkbox" value=" {{bank.id}}" (change)="onChange(bank.id,$event)">
                      </span></td>
                      <td *ngIf="bank.customerPartnerId!=null && bank.customerPartnerId.firstName!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{bank.customerPartnerId.firstName}} {{bank.customerPartnerId.lastName}}</td>
                      <td *ngIf="bank.customerPartnerId==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.beneficiaryName!=null"align="left" class="v-align-middle">{{bank.beneficiaryName}}</td>
                      <td *ngIf="bank.beneficiaryName==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.accountNumber!=null"align="left" class="v-align-middle">{{bank.accountNumber}}</td>
                      <td *ngIf="bank.accountNumber==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.ifscCode!=null"align="left" class="v-align-middle">{{bank.ifscCode}}</td>
                      <td *ngIf="bank.ifscCode==null"align="left" class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="bank.approvalStatus==11" class="v-align-middle ht-active">Approved</td>
                      <td align="center" *ngIf="bank.approvalStatus==12" style="color: red;">Rejected</td>
                      <td align="center" *ngIf="bank.approvalStatus==10" class="v-align-middle ht-Pending">Pending</td>
                      <td *ngIf="bank.submittedDatetime!=null"align="left" class="v-align-middle">{{bank.submittedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="bank.submittedDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td align="left" *ngIf="bank.approvalStatus==10" class="v-align-middle">N/A</td>
                      <td align="left" *ngIf="bank.approvalStatus==11" class="v-align-middle">{{bank.approvedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td align="left" *ngIf="bank.approvalStatus==12" class="v-align-middle">{{bank.approvedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      
                      <td align="left" *ngIf="bank.approvalStatus==10" class="v-align-middle">N/A</td>
                      <td align="left" *ngIf="bank.approvalStatus==11" class="v-align-middle">{{bank.approvalComments}}</td>
                      <td align="left" *ngIf="bank.approvalStatus==12" class="v-align-middle">{{bank.approvalComments}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div *ngIf="approveba"class="card-body">
              <div style="display: none;">{{approvebankModel.responseMessage}}</div>
              <div style="display: none;">{{approvebankModel.statusCode}}</div>
              <div class="table-responsive">
                <br>
                <table datatable class="ch-table">
                  <thead>
                    <tr>
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: center;">&nbsp;</th> 
                      <th style="text-align: left;">Partner Name</th>
                      <th style="text-align: left;">Account Holder Name</th>
                      <th style="text-align: left;">Account Number</th>
                      <th style="text-align: left;">IFSC Code</th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: left;">Submitted Date Time</th>
                      <th style="text-align: left;">Approved Date Time</th>
                      <th style="text-align: left;">Comments</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let bank of approvebankModel.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td align="center" class="v-align-middle "><span style="width:80px">
                        <input *ngIf="bank.approvalStatus==10" type="checkbox" value=" {{bank.id}}" (change)="onChange(bank.id,$event)">
                      </span></td>
                      <td *ngIf="bank.customerPartnerId!=null && bank.customerPartnerId.firstName!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{bank.customerPartnerId.firstName}} {{bank.customerPartnerId.lastName}}</td>
                      <td *ngIf="bank.customerPartnerId==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.beneficiaryName!=null"align="left" class="v-align-middle">{{bank.beneficiaryName}}</td>
                      <td *ngIf="bank.beneficiaryName==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.accountNumber!=null"align="left" class="v-align-middle">{{bank.accountNumber}}</td>
                      <td *ngIf="bank.accountNumber==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.ifscCode!=null"align="left" class="v-align-middle">{{bank.ifscCode}}</td>
                      <td *ngIf="bank.ifscCode==null"align="left" class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="bank.approvalStatus==11" class="v-align-middle ht-active">Approved</td>
                      <td align="center" *ngIf="bank.approvalStatus==12" style="color: red;">Rejected</td>
                      <td align="center" *ngIf="bank.approvalStatus==10" class="v-align-middle ht-Pending">Pending</td>
                      <td *ngIf="bank.submittedDatetime!=null"align="left" class="v-align-middle">{{bank.submittedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="bank.submittedDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td align="left" *ngIf="bank.approvalStatus==10" class="v-align-middle">N/A</td>
                      <td align="left" *ngIf="bank.approvalStatus==11" class="v-align-middle">{{bank.approvedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td align="left" *ngIf="bank.approvalStatus==12" class="v-align-middle">{{bank.approvedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      
                      <td align="left" *ngIf="bank.approvalStatus==10" class="v-align-middle">N/A</td>
                      <td align="left" *ngIf="bank.approvalStatus==11" class="v-align-middle">{{bank.approvalComments}}</td>
                      <td align="left" *ngIf="bank.approvalStatus==12" class="v-align-middle">{{bank.approvalComments}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div *ngIf="rejectba"class="card-body">
              <div style="display: none;">{{rejectbankModel.responseMessage}}</div>
              <div style="display: none;">{{rejectbankModel.statusCode}}</div>
              <div class="table-responsive">
                <br>
                <table datatable class="ch-table">
                  <thead>
                    <tr>
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: center;">&nbsp;</th> 
                      <th style="text-align: left;">Partner Name</th>
                      <th style="text-align: left;">Account Holder Name</th>
                      <th style="text-align: left;">Account Number</th>
                      <th style="text-align: left;">IFSC Code</th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: left;">Submitted Date Time</th>
                      <th style="text-align: left;">Approved Date Time</th>
                      <th style="text-align: left;">Comments</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let bank of rejectbankModel.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td align="center" class="v-align-middle "><span style="width:80px">
                        <input *ngIf="bank.approvalStatus==10" type="checkbox" value=" {{bank.id}}" (change)="onChange(bank.id,$event)">
                      </span></td>
                      <td *ngIf="bank.customerPartnerId!=null && bank.customerPartnerId.firstName!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{bank.customerPartnerId.firstName}} {{bank.customerPartnerId.lastName}}</td>
                      <td *ngIf="bank.customerPartnerId==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.beneficiaryName!=null"align="left" class="v-align-middle">{{bank.beneficiaryName}}</td>
                      <td *ngIf="bank.beneficiaryName==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.accountNumber!=null"align="left" class="v-align-middle">{{bank.accountNumber}}</td>
                      <td *ngIf="bank.accountNumber==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.ifscCode!=null"align="left" class="v-align-middle">{{bank.ifscCode}}</td>
                      <td *ngIf="bank.ifscCode==null"align="left" class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="bank.approvalStatus==11" class="v-align-middle ht-active">Approved</td>
                      <td align="center" *ngIf="bank.approvalStatus==12" style="color: red;">Rejected</td>
                      <td align="center" *ngIf="bank.approvalStatus==10" class="v-align-middle ht-Pending">Pending</td>
                      <td *ngIf="bank.submittedDatetime!=null"align="left" class="v-align-middle">{{bank.submittedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="bank.submittedDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td align="left" *ngIf="bank.approvalStatus==10" class="v-align-middle">N/A</td>
                      <td align="left" *ngIf="bank.approvalStatus==11" class="v-align-middle">{{bank.approvedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td align="left" *ngIf="bank.approvalStatus==12" class="v-align-middle">{{bank.approvedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      
                      <td align="left" *ngIf="bank.approvalStatus==10" class="v-align-middle">N/A</td>
                      <td align="left" *ngIf="bank.approvalStatus==11" class="v-align-middle">{{bank.approvalComments}}</td>
                      <td align="left" *ngIf="bank.approvalStatus==12" class="v-align-middle">{{bank.approvalComments}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div *ngIf="filterflag && !filteronesearch"class="card-body">
              <div style="display: none;">{{filterpartnerbankModel.responseMessage}}</div>
              <div style="display: none;">{{filterpartnerbankModel.statusCode}}</div>
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr>
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: center;">&nbsp;</th> 
                      <th style="text-align: left;">Partner Name</th>
                      <th style="text-align: left;">Account Holder Name</th>
                      <th style="text-align: left;">Account Number</th>
                      <th style="text-align: left;">IFSC Code</th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: left;">Submitted Date Time</th>
                      <th style="text-align: left;">Approved Date Time</th>
                      <th style="text-align: left;">Comments</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let bank of filterpartnerbankModel.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td align="center" class="v-align-middle "><span style="width:80px">
                        <input *ngIf="bank.approvalStatus==10" type="checkbox" value=" {{bank.id}}" (change)="onChange(bank.id,$event)">
                      </span></td>
                      <td *ngIf="bank.customerPartnerId!=null && bank.customerPartnerId.firstName!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{bank.customerPartnerId.firstName}} {{bank.customerPartnerId.lastName}}</td>
                      <td *ngIf="bank.customerPartnerId==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.beneficiaryName!=null"align="left" class="v-align-middle">{{bank.beneficiaryName}}</td>
                      <td *ngIf="bank.beneficiaryName==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.accountNumber!=null"align="left" class="v-align-middle">{{bank.accountNumber}}</td>
                      <td *ngIf="bank.accountNumber==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.ifscCode!=null"align="left" class="v-align-middle">{{bank.ifscCode}}</td>
                      <td *ngIf="bank.ifscCode==null"align="left" class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="bank.approvalStatus==11" class="v-align-middle ht-active">Approved</td>
                      <td align="center" *ngIf="bank.approvalStatus==12" style="color: red;">Rejected</td>
                      <td align="center" *ngIf="bank.approvalStatus==10" class="v-align-middle ht-Pending">Pending</td>
                      <td *ngIf="bank.submittedDatetime!=null"align="left" class="v-align-middle">{{bank.submittedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="bank.submittedDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td align="left" *ngIf="bank.approvalStatus==10" class="v-align-middle">N/A</td>
                      <td align="left" *ngIf="bank.approvalStatus==11" class="v-align-middle">{{bank.approvedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td align="left" *ngIf="bank.approvalStatus==12" class="v-align-middle">{{bank.approvedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td align="left" *ngIf="bank.approvalStatus==10" class="v-align-middle">N/A</td>
                      <td align="left" *ngIf="bank.approvalStatus==11" class="v-align-middle">{{bank.approvalComments}}</td>
                      <td align="left" *ngIf="bank.approvalStatus==12" class="v-align-middle">{{bank.approvalComments}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div *ngIf="filterflag && filteronesearch"class="card-body">
              <div style="display: none;">{{filterpartnerbankModelNorecord.responseMessage}}</div>
              <div style="display: none;">{{filterpartnerbankModelNorecord.statusCode}}</div>
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr>
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: center;">&nbsp;</th> 
                      <th style="text-align: left;">Partner Name</th>
                      <th style="text-align: left;">Account Holder Name</th>
                      <th style="text-align: left;">Account Number</th>
                      <th style="text-align: left;">IFSC Code</th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: left;">Submitted Date Time</th>
                      <th style="text-align: left;">Approved Date Time</th>
                      <th style="text-align: left;">Comments</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let bank of filterpartnerbankModelNorecord.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td align="center" class="v-align-middle "><span style="width:80px">
                        <input *ngIf="bank.approvalStatus==10" type="checkbox" value=" {{bank.id}}" (change)="onChange(bank.id,$event)">
                      </span></td>
                      <td *ngIf="bank.customerPartnerId!=null && bank.customerPartnerId.firstName!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{bank.customerPartnerId.firstName}} {{bank.customerPartnerId.lastName}}</td>
                      <td *ngIf="bank.customerPartnerId==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.beneficiaryName!=null"align="left" class="v-align-middle">{{bank.beneficiaryName}}</td>
                      <td *ngIf="bank.beneficiaryName==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.accountNumber!=null"align="left" class="v-align-middle">{{bank.accountNumber}}</td>
                      <td *ngIf="bank.accountNumber==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.ifscCode!=null"align="left" class="v-align-middle">{{bank.ifscCode}}</td>
                      <td *ngIf="bank.ifscCode==null"align="left" class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="bank.approvalStatus==11" class="v-align-middle ht-active">Approved</td>
                      <td align="center" *ngIf="bank.approvalStatus==12" style="color: red;">Rejected</td>
                      <td align="center" *ngIf="bank.approvalStatus==10" class="v-align-middle ht-Pending">Pending</td>
                      <td *ngIf="bank.submittedDatetime!=null"align="left" class="v-align-middle">{{bank.submittedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="bank.submittedDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td align="left" *ngIf="bank.approvalStatus==10" class="v-align-middle">N/A</td>
                      <td align="left" *ngIf="bank.approvalStatus==11" class="v-align-middle">{{bank.approvedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td align="left" *ngIf="bank.approvalStatus==12" class="v-align-middle">{{bank.approvedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td align="left" *ngIf="bank.approvalStatus==10" class="v-align-middle">N/A</td>
                      <td align="left" *ngIf="bank.approvalStatus==11" class="v-align-middle">{{bank.approvalComments}}</td>
                      <td align="left" *ngIf="bank.approvalStatus==12" class="v-align-middle">{{bank.approvalComments}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

        </form>
          </div>
          <!-- END card --> 
        </div>
      </div>
      <!-- END CONTAINER FLUID --> 
      
    </div>
    <!-- END PAGE CONTENT --> 
    <!-- START COPYRIGHT --> 
    <!-- START CONTAINER FLUID --> 
    <!-- START CONTAINER FLUID -->
  <!--   <div class=" container   container-fixed-lg footer">
      <div class="copyright sm-text-center">
        <p class="small-text no-margin pull-left sm-pull-reset"> ©2014-2020 All Rights Reserved. Pages® and/or its subsidiaries or affiliates are registered trademark of Revox Ltd. <span class="hint-text m-l-15">Pages v05.23 20201105.r.190</span> </p>
        <p class="small no-margin pull-right sm-pull-reset"> Hand-crafted <span class="hint-text">&amp; made with Love</span> </p>
        <div class="clearfix"></div>
      </div>
    </div> -->
    <!-- END COPYRIGHT --> 
  </div>
  <!-- END PAGE CONTENT WRAPPER --> 
</div>
<!-- END PAGE CONTAINER --> 

  <!-- Tab panes -->

 
    



<ng-template #content let-modal>
  <div class="modal-header" style="margin-bottom: 10px">
    <h6 class="modal-title" id="modal-basic-title">View Bank Information Details </h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="container"
  fxLayout
>
<div class="item item-1">
  <p class="card-title"><b>Vehicle Number</b><br>
    <span>{{viewbankmodel.response.vehicleId.vehicleNumber}}</span>
  </p>
  </div>
<div class="item item-1" style="margin-left: 30px;">
  <p class="card-title"><b>Beneficiary name </b><br>
    <span>{{viewbankmodel.response.beneficiaryName}}</span>
  </p>
  </div>
  <div class="item item-2" style="margin-left: 30px;">
    <p class="card-title"><b>Account Number</b><br>
      <span>{{viewbankmodel.response.accountNumber}}</span>
    </p>
  </div>
  <div class="item item-3" style="margin-left: 30px;">
    <p class="card-title"><b>Account Type</b><br>
      <span>{{viewbankmodel.response.accountType}}</span>
    </p>
  </div>
  <div class="item item-4" style="margin-left: 30px;">
    <p class="card-title"><b>IFSC Code</b><br>
      <span>{{viewbankmodel.response.ifscCode}}</span>
    </p>
  </div>
  <div class="item item-5" style="margin-left: 30px;">
    <p class="card-title"><b>Bank Name</b><br>
      <span>{{viewbankmodel.response.bankId.bankName}}</span>
    </p>
  </div>
  <div class="item item-6" style="margin-left: 30px;">
    <p class="card-title"><b>Branch</b><br>
      <span>{{viewbankmodel.response.branchName}}</span>
    </p>
  </div>
  <div class="item item-7" style="margin-left: 30px;">
    <p class="card-title"><b>Approval Status</b><br>
      <span *ngIf="viewbankmodel.response.approvalStatus==10" class="v-align-middle ht-Pending">Pending</span> 
          <span *ngIf="viewbankmodel.response.approvalStatus==11" class="v-align-middle ht-active">Approved</span> 
          <span *ngIf="viewbankmodel.response.approvalStatus==12" style="color: red;">Rejected</span> 
    </p>
  </div>
  <div class="item item-8" style="margin-left: 30px;">
    <p class="card-title"><b>Approved By</b><br>
      <span>{{viewbankmodel.response.approvedBy}}</span>
    </p>
  </div>
  <div class="item item-8" style="margin-left: 30px;">
    <p class="card-title"><b>Comments</b><br>
      <span>{{viewbankmodel.response.approvalComments}}</span>
    </p>
  </div>
  </div>
 <!-- <div class="modal-body">
    <div class="wrapper">
    	<div class="flex extradiv">
    		<p class="card-title">Beneficiary name <br><span><b>{{viewbankmodel.response.beneficiaryName}}</b></span></p>
    		<p class="card-title">Account Number   <br><span><b>{{viewbankmodel.response.accountNumber}}</b></span></p>
    		<p class="card-title">Account Type   <br><span><b>{{viewbankmodel.response.accountType}}</b></span></p>
    		<p class="card-title">IFSC Code  <br><span><b>{{viewbankmodel.response.ifscCode}}</b></span></p>
    		<p class="card-title">Bank Name   <br><span><b>{{viewbankmodel.response.bankId.bankName}}</b></span></p>
    		<p class="card-title">Branch    <br><span><b>{{viewbankmodel.response.branchName}}</b></span></p>
    	</div>
    	<div class="flex extradiv">
    		
        <p class="card-title">Approval Status <br>
          <span *ngIf="viewbankmodel.response.approvalStatus==10" class="v-align-middle ht-Pending"><b>Pending</b> </span> 
          <span *ngIf="viewbankmodel.response.approvalStatus==11" class="v-align-middle ht-active"><b>Approved</b> </span> 
          <span *ngIf="viewbankmodel.response.approvalStatus==12" class="v-align-middle ht-inactive"><b>Rejected</b> </span> 
        </p>
    		<p class="card-title">Approved By  <br><span><b>{{viewbankmodel.response.approvedBy}}</b></span></p>
    		<p class="card-title">Comments<br><span><b>{{viewbankmodel.response.approvalComments}}</b></span></p>
    		
    	</div>
    </div>
  </div> -->
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
    
  </div>
</ng-template>

<ng-template #content0 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Do you want approve the Bank?</label>
           <textarea placeholder="comments" class="form-control textarea" name="kyctextarea" id="kyctextarea" rows="3"></textarea>
      </div>
    </form>
    <div *ngIf="descalert">
      <div style="color: red;">Please Enter comments!</div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="approveBank(1)">yes</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">no</button>
  </div>
</ng-template>

<ng-template #content1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Do you want reject the Bank?</label>
           <textarea placeholder="comments" class="form-control textarea" name="kyctextrejectarea" id="kyctextrejectarea" rows="3"></textarea>
      </div>
    </form>
    <div *ngIf="descalert">
      <div style="color: red;">Please Enter comments!</div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="approveBank(2)" >yes</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">no</button>
  </div>
</ng-template>
<router-outlet></router-outlet>
