import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { RestResponseModel } from '../model/response';
import { BankMasterModel } from '../model/bankmaster';
import { environment } from 'src/environments/environment';
import { AgentResponseModel } from '../model/agentresponse';
import { GenericResponseModel } from '../model/genricresponse';
import { UserModel } from '../model/user';

@Injectable({
    providedIn: "root"
  })
  export class AgentService {

    constructor(private httpClient:HttpClient) {}

  private baseUrl = environment.devurl;
  private auth = environment.headers;

    public getAgent(){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<AgentResponseModel>(this.baseUrl+"/adminagents/viewagents",{headers});
    }

  public viewAgentById(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<UserModel>(this.baseUrl+"/adminagents/agentinfo"+"/"+id,{headers});
  }

  public addBankMaster(bank){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.post<RestResponseModel>(this.baseUrl+"/api/addBankMaster",bank,{headers});
  }

  public getBankMasterById(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<BankMasterModel>(this.baseUrl+"/api/getBankMasterById"+"/"+id,{headers});
  }

  public updateBankMaster(bank){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.put<RestResponseModel>(this.baseUrl+"/api/updateBankMaster",bank,{headers});
  }

  public getOrders(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/adminagents/getOrders"+"/"+id,{headers});
  }

  public getTransactions(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/adminagents/getTransaction"+"/"+id,{headers});
  }

  public getPendingOrders(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/adminagents/getPendingOrders"+"/"+id,{headers});
  }

  public getInprogrssOrders(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/adminagents/getInprogressOrders"+"/"+id,{headers});
  }

  public getCompletedOrders(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/adminagents/getCompletedOrders"+"/"+id,{headers});
  }

  public getCancelledOrders(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/adminagents/getCancelledOrders"+"/"+id,{headers});
  }

  public getSuccessTransaction(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/adminagents/getSuccessTransaction"+"/"+id,{headers});
  }

  public getInitiatedTransaction(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/adminagents/getInitiatedTransaction"+"/"+id,{headers});
  }

  public getCancelledTransaction(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/adminagents/getCancelledTransaction"+"/"+id,{headers});
  }

  public getFailedTransaction(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/adminagents/getFailedTransaction"+"/"+id,{headers});
  }

  }