import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { GenericResponseModel } from '../model/genricresponse';
import { ReportServices } from '../services/reports.service';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { Workbook } from 'exceljs';
import { NgxSpinnerService } from 'ngx-spinner';
import { GenericResponse } from '../model/genricmodelresponse';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-report-refund',
  templateUrl: './report-refund.component.html',
  styleUrls: ['./report-refund.component.css']
})

export class ReportRefundComponent implements OnInit {
  symbole:String; 
 public isCollapsed = false;
 active = 1;
 transactionDetail:GenericResponseModel;
 transactionfilterDetail:GenericResponseModel;
 settlementDetail:GenericResponseModel;
 settlementViewDetail:GenericResponseModel;
closeResult = '';
  errorFlag:boolean;
  errorMessage:String;
  refreshflag:boolean;
  responseDataList = [];
  revenuereportModel:GenericResponseModel;
  revenuefilterreportModel:GenericResponseModel;
  normalrevenue:boolean=true;
  excelexportcheck : boolean = false;
  excelexportcheck1 : boolean = false;
  responseDataListnew = [];
  responseDataListnew1 = [];
  refundreportModel:GenericResponseModel;
  refundreportModel1:GenericResponseModel;
  filterrefundreportModel:GenericResponseModel;
  filterrefundreportModel1:GenericResponseModel;
  filterrefund:boolean=false;
  filterrefund1:boolean=false;
  refreshFlag:boolean=false;
  refreshFlag1:boolean=false;
  refundSettlementReportModel:GenericResponse;
  refundSettlementReportModel1:GenericResponse;
  errorflag:boolean=false;
  totalpaidAmount:DoubleRange;
  totalrefundAmount:DoubleRange;
  status:Number;
  refundAmount:Number;
  today=new Date();
  dateCheck:String;
  filteronesearch:boolean= false;
  filteronesearch1:boolean= false;
  reportfilter1DetailNoRecord:GenericResponseModel;
  reportfilter1DetailNoRecord1:GenericResponseModel;
  refundId:Number;
  constructor(private modalService: NgbModal,private reportservice:ReportServices,private spinnerService:NgxSpinnerService,private _decimalPipe: DecimalPipe,private router:Router) { }

  ngOnInit(): void {
    let date  = moment(new Date(this.today)).format("yyyy-MM-DDThh:mm").toString();
    this.dateCheck = date;
    this.status=20;
     this.reportservice.getRefundReportCancel(20).subscribe(data=>{
      if(data.statusCode==200){
        this.excelexportcheck = true;
       }else{
        this.excelexportcheck = false;
       }
       this.refundreportModel = data;
     },err => {
       if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });

   this.reportservice.getRefundReport(20).subscribe(data=>{
    if(data.statusCode==200){
      this.excelexportcheck1 = true;
     }else{
      this.excelexportcheck1 = false;
     }
     this.refundreportModel1 = data;
   },err => {
     if(err.error.statusCode==500){
      this.router.navigate(['/page-error']);
    }else if(err.error.statusCode==404){
      this.router.navigate(['/pagenotfound']);
    }
 });
  }

  invoice(id){
    sessionStorage.setItem('invoice',id);
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['/invoice-generation-refund'])
      );
      window.open(url, '_blank');
  }

  search(){
    this.refreshFlag = true;
    let status = (<HTMLInputElement>document.getElementById('status')).value;
    if(status!=null && status!==''){
      this.status=Number(status);
        this.reportservice.getRefundReportCancel(Number(status)).subscribe(data=>{
          this.filterrefund = true;
          if(data.statusCode==200){
            this.filteronesearch= true;
            this.excelexportcheck = true;
            this.filterrefundreportModel = data;
           }else{
            this.filteronesearch= false;
            this.excelexportcheck = false;
            this.reportfilter1DetailNoRecord = data;
           }
        });
    }
  }

  search1(){
    this.refreshFlag1 = true;
    let status = (<HTMLInputElement>document.getElementById('status')).value;
   
    if(status!=null && status!==''){
      this.status=Number(status);
        this.reportservice.getRefundReport(Number(status)).subscribe(data=>{
          this.filterrefund1 = true;
          if(data.statusCode==200){
            this.filteronesearch1= true;
            this.excelexportcheck1 = true;
            this.filterrefundreportModel1 = data;
           }else{
            this.filteronesearch1= false;
            this.excelexportcheck1 = false;
            this.reportfilter1DetailNoRecord1 = data;
           }
        });
    }
  }

  exportToExcel(){
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let sno = 1;
    if(this.filterrefund){
      this.responseDataListnew = [];
      this.filterrefundreportModel.response.forEach(element => {
        let vehicle = element['vehicleId'];
        let customer = element['customerId'];
        let fuel    = vehicle['fuelType']; 

        let paymentda = element['orderDatetime'];
        let payDate;
        if(paymentda!=null && paymentda!=='null'){
          let pay = new Date(element['orderDatetime'])
          payDate = moment(new Date(pay)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }else{
          payDate = "N/A";
        } 

        let paymentda1 = element['orderCancellationDatetime'];
        let payDate1;
        if(paymentda1!=null && paymentda1!=='null'){
          let pay1 = new Date(element['orderCancellationDatetime'])
          payDate1 = moment(new Date(pay1)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }else{
          payDate1 = "N/A";
        } 
        
        let refundStatus = element['refundStatus'];
        let status;
        if(Number(refundStatus)==20){
          status = 'Initiated';
        }else if(Number(refundStatus)==21){
          status = 'Completed';
        }
        var response = [];
        response.push(sno);
        response.push(element['orderId']);
        response.push(customer['firstName']+customer['lastName']);
        response.push(customer['mobileNumber']);
        response.push(fuel['fuelTypeName']);
        response.push(element['quantity']);
        response.push(payDate);
        response.push(payDate1);
        response.push("₹ "+ this._decimalPipe.transform(element['totalPayableAmount'],"1.2-2"));
        response.push("₹ "+ this._decimalPipe.transform(element['refundAmount'],"1.2-2"));
        response.push(status);
        this.responseDataListnew.push(response);
        sno++;
      });
    }else{
      this.refundreportModel.response.forEach(element => {
        let vehicle = element['vehicleId'];
        let customer = element['customerId'];
        let fuel    = vehicle['fuelType']; 
        let paymentda = element['orderDatetime'];
        let payDate;
        if(paymentda!=null && paymentda!=='null'){
          let pay = new Date(element['orderDatetime'])
          payDate = moment(new Date(pay)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }else{
          payDate = "N/A";
        } 

        let paymentda1 = element['orderCancellationDatetime'];
        let payDate1;
        if(paymentda1!=null && paymentda1!=='null'){
          let pay1 = new Date(element['orderCancellationDatetime'])
          payDate1 = moment(new Date(pay1)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }else{
          payDate1 = "N/A";
        } 
        let refundStatus = element['refundStatus'];
        let status;
        if(Number(refundStatus)==20){
          status = 'Initiated';
        }else if(Number(refundStatus)==21){
          status = 'Completed';
        }
        var response = [];
        response.push(sno);
        response.push(element['orderId']);
        response.push(customer['firstName']+customer['lastName']);
        response.push(customer['mobileNumber']);
        response.push(fuel['fuelTypeName']);
        response.push(element['quantity']);
        response.push(payDate);
        response.push(payDate1);
        response.push("₹ "+ this._decimalPipe.transform(element['totalPayableAmount'],"1.2-2"));
        response.push("₹ "+ this._decimalPipe.transform(element['refundAmount'],"1.2-2"));
        response.push(status);
        this.responseDataListnew.push(response);
        sno++;
      });
    }
    this.excelexport();
  }

  exportToExcel1(){
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let sno = 1;
    if(this.filterrefund1){
      this.responseDataListnew1 = [];
      this.filterrefundreportModel1.response.forEach(element => {
        let vehicle = element['vehicleId'];
        let customer = element['customerId'];
        let fuel    = vehicle['fuelType']; 
        let paymentda = element['orderDatetime'];
        let payDate;
        if(paymentda!=null && paymentda!=='null'){
          let pay = new Date(element['orderDatetime'])
          payDate = moment(new Date(pay)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }else{
          payDate = "N/A";
        } 

        let paymentda1 = element['orderCancellationDatetime'];
        let payDate1;
        if(paymentda1!=null && paymentda1!=='null'){
          let pay1 = new Date(element['orderCancellationDatetime'])
          payDate1 = moment(new Date(pay1)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }else{
          payDate1 = "N/A";
        } 
        
        let refundStatus = element['refundStatus'];
        let status;
        if(Number(refundStatus)==20){
          status = 'Initiated';
        }else if(Number(refundStatus)==21){
          status = 'Completed';
        }
        var response = [];
        response.push(sno);
        response.push(element['orderId']);
        response.push(customer['firstName']+customer['lastName']);
        response.push(customer['mobileNumber']);
        response.push(fuel['fuelTypeName']);
        response.push(element['quantity']);
        response.push(payDate);
        // response.push(payDate1);
        response.push("₹ "+ this._decimalPipe.transform(element['totalPayableAmount'],"1.2-2"));
        response.push("₹ "+ this._decimalPipe.transform(element['refundAmount'],"1.2-2"));
        response.push(status);
        this.responseDataListnew1.push(response);
        sno++;
      });
    }else{
      this.refundreportModel1.response.forEach(element => {
        let vehicle = element['vehicleId'];
        let customer = element['customerId'];
        let fuel    = vehicle['fuelType']; 
        let paymentda = element['orderDatetime'];
        let payDate;
        if(paymentda!=null && paymentda!=='null'){
          let pay = new Date(element['orderDatetime'])
          payDate = moment(new Date(pay)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }else{
          payDate = "N/A";
        } 

        let paymentda1 = element['orderCancellationDatetime'];
        let payDate1;
        if(paymentda1!=null && paymentda1!=='null'){
          let pay1 = new Date(element['orderCancellationDatetime'])
          payDate1 = moment(new Date(pay1)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }else{
          payDate1 = "N/A";
        } 
        let refundStatus = element['refundStatus'];
        let status;
        if(Number(refundStatus)==20){
          status = 'Initiated';
        }else if(Number(refundStatus)==21){
          status = 'Completed';
        }
        var response = [];
        response.push(sno);
        response.push(element['orderId']);
        response.push(customer['firstName']+customer['lastName']);
        response.push(customer['mobileNumber']);
        response.push(fuel['fuelTypeName']);
        response.push(element['quantity']);
        response.push(payDate);
        // response.push(payDate1);
        response.push("₹ "+ this._decimalPipe.transform(element['totalPayableAmount'],"1.2-2"));
        response.push("₹ "+ this._decimalPipe.transform(element['refundAmount'],"1.2-2"));
        response.push(status);
        this.responseDataListnew1.push(response);
        sno++;
      });
    }
    this.excelexport1();
  }

  excelexport(){
    const title = 'Refund Report for Order Cancel';
    const header = ["S.No","Order ID", "Customer Name", "Customer Mobile", "Fuel","Quantity(Ltrs)","Booked Date & Time","Cancelled Date & Time","Paid Amount","Refund Amount","Status"]
    const data = this.responseDataListnew;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Refund Report for Order Cancel');
// Add new row
let titleRow = worksheet.addRow([title]);
// Set font, size and style in title row.
titleRow.font = { name: 'Times New Roman', family: 4, size: 16, bold: true };

// Blank Row
worksheet.addRow([]);
if(this.status==20){
  let subTitleRow = worksheet.addRow(['Status  : ' + 'Initiated']);
  subTitleRow.font = { name: 'Times New Roman', family: 4, size: 12, bold: true };
}else if(this.status==21){
  let subTitleRow = worksheet.addRow(['Status  : ' + 'Completed']);
  subTitleRow.font = { name: 'Times New Roman', family: 4, size: 12, bold: true };
}
  worksheet.addRow([]);

//Add row with current date
// let subTitleRow = worksheet.addRow(['Date : ' + this.datePipe.transform(new Date(), 'medium')]);
//Add Header Row
let headerRow = worksheet.addRow(header);
headerRow.font = {bold: true };
// Cell Style : Fill and Border
headerRow.eachCell((cell, number) => {
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFFFF' },
    bgColor: { argb: 'FF0000FF' },
  }
  cell.border = {  top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
});

data.forEach(d => {
  let row = worksheet.addRow(d);
  let qty = row.getCell(1);
  let qty1 = row.getCell(2);
  let qty2 = row.getCell(3);
  let qty3 = row.getCell(4);
  let qty4 = row.getCell(5);
  let qty5 = row.getCell(6);
  let qty6 = row.getCell(7);
  let qty7 = row.getCell(8);
  let qty8 = row.getCell(9);
  let qty9 = row.getCell(10);
  let qty10 = row.getCell(11);
  qty.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty1.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty2.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty3.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty4.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty5.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty6.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty8.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty9.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty10.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
}
);
workbook.xlsx.writeBuffer().then((data) => {
  let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  FileSaver.saveAs(blob, 'Refund Report Order Cancel.xlsx');
});
   }

   excelexport1(){
    const title = 'Refund Report';
    const header = ["S.No","Order ID", "Customer Name", "Customer Mobile", "Fuel","Quantity(Ltrs)","Booked Date & Time","Paid Amount","Refund Amount","Status"]
    const data = this.responseDataListnew1;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Refund Report');
// Add new row
let titleRow = worksheet.addRow([title]);
// Set font, size and style in title row.
titleRow.font = { name: 'Times New Roman', family: 4, size: 16, bold: true };

// Blank Row
worksheet.addRow([]);
if(this.status==20){
  let subTitleRow = worksheet.addRow(['Status  : ' + 'Initiated']);
  subTitleRow.font = { name: 'Times New Roman', family: 4, size: 12, bold: true };
}else if(this.status==21){
  let subTitleRow = worksheet.addRow(['Status  : ' + 'Completed']);
  subTitleRow.font = { name: 'Times New Roman', family: 4, size: 12, bold: true };
}
  worksheet.addRow([]);

//Add row with current date
// let subTitleRow = worksheet.addRow(['Date : ' + this.datePipe.transform(new Date(), 'medium')]);
//Add Header Row
let headerRow = worksheet.addRow(header);
headerRow.font = {bold: true };
// Cell Style : Fill and Border
headerRow.eachCell((cell, number) => {
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFFFF' },
    bgColor: { argb: 'FF0000FF' },
  }
  cell.border = {  top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
});

data.forEach(d => {
  let row = worksheet.addRow(d);
  let qty = row.getCell(1);
  let qty1 = row.getCell(2);
  let qty2 = row.getCell(3);
  let qty3 = row.getCell(4);
  let qty4 = row.getCell(5);
  let qty5 = row.getCell(6);
  let qty6 = row.getCell(7);
  let qty7 = row.getCell(8);
  let qty8 = row.getCell(9);
  let qty9 = row.getCell(10);
  // let qty10 = row.getCell(11);
  qty.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty1.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty2.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty3.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty4.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty5.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty6.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty8.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty9.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  // qty10.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
}
);
workbook.xlsx.writeBuffer().then((data) => {
  let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  FileSaver.saveAs(blob, 'Refund Report.xlsx');
});
   }

   refresh(){
     this.refreshFlag = false;
     window.location.reload();
   }
   
   refresh1(){
    this.refreshFlag1 = false;
    window.location.reload();
  }

   view(id,content){
    this.reportservice.getRefundSettlementReport(id).subscribe(data=>{
      this.refundSettlementReportModel = data;
      this.totalpaidAmount = data.response.refund.totalPayableAmount;
      this.totalrefundAmount = data.response.refund.totalrefundamount;
      this.refundAmount = Number(this.totalpaidAmount) -  Number(this.totalrefundAmount);
    });

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
   }

   view1(id){
    this.reportservice.refundpayment(id).subscribe(data=>{
      Swal.fire({
        html: '<pre>' + data.responseMessage + '</pre>',
        customClass: {
          popup: 'format-pre'
        }
      }).then((value) => {
        window.location.reload();
      });; ; 
    });
   }

   private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  allowNumericDigitsOnlyOnKeyUp(e) {		
    const charCode = e.which ? e.which : e.keyCode;
    if(charCode==46){
      return true;
    }else if(charCode > 31 && (charCode < 48 || charCode > 57)){
      if(charCode==46){
        return true;
      }else{
        e.preventDefault();
      }
    }else{
      return true;
    }
	}

  refund(id){
    let transactionid = (<HTMLInputElement>document.getElementById('transactionid')).value;
    let date = (<HTMLInputElement>document.getElementById('date')).value;
    let amount = (<HTMLInputElement>document.getElementById('amount')).value;
    if(transactionid!=null && transactionid!=='' && date!=null && date!=='' && amount!=null && amount!==''){

      if(Number(amount)>Number(this.totalpaidAmount)){
        this.errorflag = true;
        this.errorMessage = 'Refund amount less than Paid amount!';
      }else{
        this.errorflag = false;
        let d = date.concat(":00");
        let currentDate=new Date(d);
        let time = currentDate.getTime();
        let formData= {
          deductionAmount:Number(amount),
          refundRemarks:transactionid,
          refundDateTime:time
        }
  
        this.reportservice.getRefundCompleted(id,formData).subscribe(data=>{
          this.modalService.dismissAll();
          Swal.fire({
            html: '<pre>' + data.responseMessage + '</pre>',
            customClass: {
              popup: 'format-pre'
            }
          }).then((value) => {
            window.location.reload();
          });; ; 
        },err => {
          if(err.error.statusCode==406){
            this.router.navigate(['/login']);
          }else if(err.error.statusCode==500){
            this.router.navigate(['/page-error']);
          }else if(err.error.statusCode==404){
            this.router.navigate(['/pagenotfound']);
          }
       });
      }

      

    }else{
      this.errorflag = true;
      this.errorMessage = 'Please enter all fields!';
    }
  }
  manualrefund(refundId,content){
    this.refundId=refundId;
 
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  OncClick(){
    let amount = (<HTMLInputElement>document.getElementById('amount')).value;
    this.refundAmount = Number(this.totalpaidAmount) -  Number(amount) ;
  }

  div1:boolean=true;
  div2:boolean=false;
   
    div1Function(){
        this.div1=true;
        this.div2=false;
    }

    div2Function(){
        this.div2=true;
        this.div1=false;
    }

    updateRefund(id){
      let formatDate = null;
        let refundRefNo = (<HTMLInputElement>document.getElementById('refundRefNo')).value;
        let  refundDate = (<HTMLInputElement>document.getElementById('refundDate')).value;
        formatDate = moment(new Date(refundDate)).format("DD/MM/YYYY HH:mm a").toString();


      
      
        let form={
          refundReferenceId:refundRefNo,
          approvalDate:formatDate,
        }
     
      this.reportservice.manualRefund(id,form).subscribe(data=>{
        this.modalService.dismissAll();
        Swal.fire({
          html: '<pre>' + data.responseMessage + '</pre>',
          customClass: {
            popup: 'format-pre'
          }
        }).then((value) => {
          window.location.reload();
        });; ; 
       // this.transactionDetail = data;
        setTimeout(function() {
      }.bind(this), 3000);
      },err => {
        if(err.error.statusCode==406){
          this.router.navigate(['/login']);
        }else if(err.error.statusCode==500){
          this.router.navigate(['/page-error']);
        }else if(err.error.statusCode==404){
          this.router.navigate(['/pagenotfound']);
        }
     });
    }
}
