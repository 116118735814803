import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { UserModel } from '../model/user';
import { CustomerOrderModel } from '../model/customerorder';
import { GenericResponseModel } from '../model/genricresponse';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { GenericResponse } from '../model/genricmodelresponse';
import { RestResponseModel } from '../model/response';
import { ChargesModel } from '../model/charges';

@Injectable({
    providedIn: "root"
  })
  export class CustomerService {

    constructor(private httpClient:HttpClient) {}

  private baseUrl = environment.devurl;
  private auth = environment.headers;

  public getAllCustomer(){
   // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/customersweb/getAllCustomers",{headers});
  }

  public getCustomerByID(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<UserModel>(this.baseUrl+"/customersweb/getUsersId"+"/"+id,{headers});
  }

  public getCustomerOrders(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/customersweb/getOrders"+"/"+id,{headers});
  }

  public getCustomerTransanctions(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/customersweb/getTransactions"+"/"+id,{headers});
}

  public getOrderById(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<CustomerOrderModel>(this.baseUrl+"/customersweb/getCustomerById"+"/"+id,{headers});
  }

  public getCustomerOrdersdaterange(start,end,id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/customersweb/getorderswithdaterange"+"/"+start+"/"+end+"/"+id,{headers});
  }

  public getCustomerTransactionsdaterange(start,end,id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/customersweb/gettransactionwithdaterange"+"/"+start+"/"+end+"/"+id,{headers});
  }

  public getCustomerPendingOrders(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/customersweb/pending"+"/"+id,{headers});
  }

  public getCustomerInprogressOrders(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/customersweb/inprogress"+"/"+id,{headers});
  }

  public getCustomerCompletedsOrders(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/customersweb/completed"+"/"+id,{headers});
  }

  public getCustomerCancelledOrders(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/customersweb/cancelled"+"/"+id,{headers});
  }

  public getSuccessTransactions(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/customersweb/getSuccessTransactions"+"/"+id,{headers});
  }

  public getFailedTransactions(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/customersweb/getFailedTransactions"+"/"+id,{headers});
  }

  public getCancelledTransactions(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/customersweb/getCancelledTransactions"+"/"+id,{headers});
  }

  public getInitiatedTransactions(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/customersweb/getInitiatedTransactions"+"/"+id,{headers});
  }

  public getAssetsByCustomer(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/customersweb/getasset"+"/"+id,{headers});
  }

  public getAssetsImage(id): Observable<Blob>{
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<any>(this.baseUrl+"/customersweb/assestimg"+"/"+id,{headers,responseType: 'Blob' as 'json'});
  }

  public banksettlement(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/customersweb/banksettlement"+"/"+id,{headers});
  }

  public delete(id){
    // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
  return this.httpClient.delete<GenericResponse>(this.baseUrl+"/customersweb/deletecustomer"+"/"+id,{headers});
  }

  public unblock(id){
    // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
  return this.httpClient.get<GenericResponse>(this.baseUrl+"/customersweb/unblock"+"/"+id,{headers});
  }

  public getChargesByCustomer(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/charges/getAllCharges/"+id,{headers});
  }

  public addCharges(charges){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.post<RestResponseModel>(this.baseUrl+"/charges/add",charges,{headers});
  }

  
  public getCurrentChargesByCustomer(customerId){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
     return this.httpClient.get<ChargesModel>(this.baseUrl+"/charges/currentcharges/"+customerId,{headers});
 }
 
 
 public assignPartner(form){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
   return this.httpClient.post<RestResponseModel>(this.baseUrl+"/customersweb/assignpartner",form,{headers});
}


public viewAssignedPartner(id){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/customersweb/viewpartner/"+id,{headers});
}


public assignedPartner(id){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/customersweb/partners/"+id,{headers});
}


public updatePartner(form){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
   return this.httpClient.post<RestResponseModel>(this.baseUrl+"/customersweb/updatepartner",form,{headers});
}

public addPaymentMode(form){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
   return this.httpClient.post<RestResponseModel>(this.baseUrl+"/paymentmode/addcustomerpaymentmode",form,{headers});
}
  
public viewPaymentMode(id){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/paymentmode/bycustomer/"+id,{headers});
}

public assignedPaymentMode(id){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/paymentmode/assignedmode/"+id,{headers});
}

public updatePaymentMode(form){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
   return this.httpClient.post<RestResponseModel>(this.baseUrl+"/paymentmode/updatecustomerpaymentmode",form,{headers});
}

  }