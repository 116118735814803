
export class GenericResponseModel{
    responseMessage:String;
    statusCode:Number;
    response:Object[];
    totalCount:Number;
    inactiveCount:Number;
    activeCount:Number;
    pendingCount:Number;
    cancellationCount:Number;
    amount:DoubleRange;
    initiatedCount:Number;
    revenue:DoubleRange;
    agentcount:Number;
    settlementamount:DoubleRange;
    totalResultCount:Number;
    totalFuelStock:DoubleRange;
    accountStatus:Number;
    fromDate:String;
    toDate:String;
    searchStatus:Number;
}