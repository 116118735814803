import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GenericResponseModel } from '../model/genricresponse';
import { CustomerRatingModel } from '../model/customerrating';
import { PartnerRatingModel } from '../model/partnerrating';

@Injectable({
    providedIn: "root"
  })
  export class RatingService {

    constructor(private httpClient:HttpClient) {}

  private baseUrl = environment.devurl;
  private auth = environment.headers;

    public getCustomerRatings(){
        // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/rating/customerRatings",{headers});
   }

  public getPartnerRatings(){
      // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/rating/partnerRatings",{headers});
   }

    public viewCustomerRating(id){
       const headers = this.auth;
       return this.httpClient.get<CustomerRatingModel>(this.baseUrl+"/rating/viewCustomerRating"+"/"+id,{headers});
   }

    public viewPartnerRating(id){
       // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<PartnerRatingModel>(this.baseUrl+"/rating/viewPartnerRating"+"/"+id,{headers});
   }

  }