import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GenericResponseModel } from '../model/genricresponse';

@Injectable({
    providedIn: "root"
  })
  export class PaymentModeService {

    constructor(private httpClient:HttpClient) {}

  private baseUrl = environment.devurl;
  private auth = environment.headers;

    public getPage(){
      let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
      const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/paymentmode/page",{headers});
  }

  }