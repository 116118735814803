import {Injectable, Type} from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GenericResponseModel } from '../model/genricresponse';
import { GenericResponse } from '../model/genricmodelresponse';

@Injectable({
    providedIn: "root"
  })
  export class RoleMenuMappingService {

    constructor(private httpClient:HttpClient) {}

  private baseUrl = environment.devurl;

  private auth = environment.headers;

  public view(){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/rolemenu/view", {headers});
  }

  public assignedmenu(id){
      // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/rolemenu/assignedmenu"+"/"+id, {headers});
  }

  public getMenusAndRoleList(){
      // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponse>(this.baseUrl+"/rolemenu/menusroles", {headers});
  }

  public addmenu(menu){
      // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.post<GenericResponse>(this.baseUrl+"/rolemenu/addmenu",menu,{headers});
  }

  public editmenu(roleid){
    // const headers = this.auth;
 let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
 const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
  return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/rolemenu/editmenu"+"/"+roleid,{headers});
}

public update(menu){
  // const headers = this.auth;
let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
return this.httpClient.post<GenericResponse>(this.baseUrl+"/rolemenu/update",menu,{headers});
}

public getAssignedMenuByRole(roleId){
  // const headers = this.auth;
let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
return this.httpClient.get<GenericResponse>(this.baseUrl+"/rolemenu/adminmenu"+"/"+roleId, {headers});
}

public getAssignedMenuByRoleAdmin(roleId){
  // const headers = this.auth;
let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/rolemenu/adminmenu"+"/"+roleId, {headers});
}

  }