import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GenericResponseModel } from '../model/genricresponse';
import { GenericResponse } from '../model/genricmodelresponse';
// import { TokenStorageService } from '../auth/token-storage.service';

@Injectable({
    providedIn: "root"
  })
  export class DashboardService {

  constructor(private httpClient:HttpClient) {}

  private baseUrl = environment.devurl;
  private auth = environment.headers;
    public view(){
      // const headers = this.auth;
      let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
      const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponse>(this.baseUrl+"/dashboard/view",{headers});
  }

  public getFuelDelivery(year){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponse>(this.baseUrl+"/dashboard/fueldelivery"+"/"+year,{headers});
}

public getFuelStock(){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
  return this.httpClient.get<GenericResponse>(this.baseUrl+"/dashboard/fuelstock",{headers});
}

public getOrderStatistics(year){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
  return this.httpClient.get<GenericResponse>(this.baseUrl+"/dashboard/ordercount"+"/"+year,{headers});
}
  }