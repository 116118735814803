<app-header></app-header>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
</ngx-spinner> 
<div class="page-container "> 
  <div class="page-content-wrapper "> 
    <div class="content sm-gutter"> 
      <div class="bg-white">
        <div class="container">
          <ol class="breadcrumb breadcrumb-alt">
            <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Home</a></li>
            <li class="breadcrumb-item active"><span class="font-montserrat fs-11 all-caps">Settlements</span></li>
          </ol>
        </div>
      </div>
<div *ngIf="div2">
  <div style="display: none;">{{settlementDetail.responseMessage}}</div>
      <div class=" no-padding    container-fixed-lg bg-white">
        <div class="container"> 
          <div class="card card-transparent">
            <div class="flex">
              <label style="margin-top: 10px; margin-left: 550px;"><b>Search By Date Range</b></label> 
            </div>
        <div class="flex">
        <div class="ch-form-group" style="margin-left: 420px;">
          <input type="date" class="form-control" id="fromdate" name="fromdate" aria-describedby="emailHelp" placeholder="Select Role" required>
        </div>
        <div class="ch-form-group" style="margin-left: 10px;">
          <input type="date" class="form-control" id="todate" name="todate" aria-describedby="emailHelp" placeholder="Select Role" required>
        </div>
        <div class="ch-form-group" style="margin-left: 10px;">
          <button type="button" (click)="searchsettlement()" class="form-control">Go</button>
        </div>
        <div class="ch-form-group" style="margin-left: 10px; margin-top: 10px;">
        <span  *ngIf="errorFlag" style="color: red;">{{errorMessage}}</span>
      </div>
        <div class="ch-form-group" style="margin-left: 10px;">
          <button  style="height: 33px; width: 33px;" *ngIf="refreshflag" class="label label-success" (click)="refresh()"><i class="fa fa-refresh" aria-hidden="true"></i></button>
        </div>
        <div class="ch-form-group" style="margin-left: 10px;">
        <button title="Excel Export" style="height: 33px;width: 33px; float: right;" *ngIf="excelexportchecksettlement" class="label label-success" (click)="exportToExcelsettlement()"><i class= "fa fa-file-excel-o" aria-hidden= "true" ></i></button>
      </div>
      </div>
            <div class="card-body">
              <div class="table-responsive" #epltable>
                <table id="excel-table" datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Order ID</th>
                      <th style="text-align: left;">Customer Info</th>
                      <th style="text-align: left;">Partner Info</th>
                      <th style="text-align: left;">Transaction ID</th>
                      <th style="text-align: right;">Total Paid Amount (Rs.)</th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: left;">Account Holder</th>
                      <th style="text-align: left;">Account Number</th>
                      <th style="text-align: left;">IFSC Code</th>
                      <th style="text-align: left;">Transaction Date & Time</th>
                      <th style="text-align: center;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let settlement of settlementDetail.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="settlement.orderId!=null" align="left" class="v-align-middle">{{settlement.orderId}}</td>
                      <td *ngIf="settlement.orderId==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="settlement.customerId!=null"align="left" class="v-align-middle">
                        <p style="font-size: 12px;"><b>Name :</b> {{settlement.customerId.firstName}} {{settlement.customerId.lastName}} </p>
                        <p style="font-size: 12px;"><b>Mobile :</b> {{settlement.customerId.mobileNumber}}</p>
                      </td>
                      <td *ngIf="settlement.customerId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                      <td *ngIf="settlement.vehicleId!=null"align="left" class="v-align-middle">
                          <p style="font-size: 12px;"><b>Name :</b> {{settlement.vehicleId.userId.firstName}} {{settlement.vehicleId.userId.lastName}}</p>
                          <p style="font-size: 12px;"><b>Mobile :</b> {{settlement.vehicleId.userId.mobileNumber}}</p>
                        </td>
                      <td *ngIf="settlement.vehicleId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                      <td *ngIf="settlement.transactionId!=null" align="left" class="v-align-middle">{{settlement.transactionId}}</td>
                      <td *ngIf="settlement.transactionId==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="settlement.totalPayableAmount!=null"align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{settlement.totalPayableAmount | number : '1.2-2' }}</td>
                      <td *ngIf="settlement.totalPayableAmount==null"align="right" class="v-align-middle">N/A</td>
                      <td *ngIf="settlement.paymentStatus!=null && settlement.paymentStatus==='Success' "class="v-align-middle ht-active">{{settlement.paymentStatus}}</td>
                      <td *ngIf="settlement.paymentStatus!=null && settlement.paymentStatus==='Failed' " style="color: red;">{{settlement.paymentStatus}}</td>
                      <td *ngIf="settlement.paymentStatus!=null && settlement.paymentStatus==='Cancelled' " style="color: red;">{{settlement.paymentStatus}}</td>
                      <td *ngIf="settlement.paymentStatus!=null && settlement.paymentStatus==='Initiated' " style="color: blue;">{{settlement.paymentStatus}}</td>
                      <td *ngIf="settlement.bankAccountHolderName!=null"align="center" class="v-align-middle">{{settlement.bankAccountHolderName}}</td>
                      <td *ngIf="settlement.bankAccountHolderName==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="settlement.accountNumber!=null"align="center" class="v-align-middle">{{settlement.accountNumber}}</td>
                      <td *ngIf="settlement.accountNumber==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="settlement.ifsc!=null"align="center" class="v-align-middle">{{settlement.ifsc}}</td>
                      <td *ngIf="settlement.ifsc==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="settlement.paymentDatetime!=null"align="left" class="v-align-middle">{{settlement.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="settlement.paymentDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td class="v-align-middle">
                        <button align="center" class="label label-info" (click)="view(content,settlement.id)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
</div>

<div *ngIf="divsettlementfilter">
  <div style="display: none;">{{settlementFilterDetail.responseMessage}}</div>
      <div class=" no-padding    container-fixed-lg bg-white">
        <div class="container"> 
          <div class="card card-transparent">
            <div class="flex">
              <label style="margin-top: 10px; margin-left: 550px;"><b>Search By Date Range</b></label> 
            </div>
        <div class="flex">
        <div class="ch-form-group" style="margin-left: 420px;">
          <input type="date" class="form-control" [(ngModel)]="settlementFilterDetail.fromDate" id="fromdate" name="fromdate" aria-describedby="emailHelp" placeholder="Select Role" required>
        </div>
        <div class="ch-form-group" style="margin-left: 10px;">
          <input type="date" class="form-control" [(ngModel)]="settlementFilterDetail.toDate" id="todate" name="todate" aria-describedby="emailHelp" placeholder="Select Role" required>
        </div>
        <div class="ch-form-group" style="margin-left: 10px;">
          <button type="button" (click)="searchsettlement()" class="form-control">Go</button>
        </div>
        <div class="ch-form-group" style="margin-left: 10px; margin-top: 10px;">
        <span  *ngIf="errorFlag" style="color: red;">{{errorMessage}}</span>
      </div>
        <div class="ch-form-group" style="margin-left: 10px;">
          <button  style="height: 33px; width: 33px;" *ngIf="refreshflag" class="label label-success" (click)="refresh()"><i class="fa fa-refresh" aria-hidden="true"></i></button>
        </div>
        <div class="ch-form-group" style="margin-left: 10px;">
        <button title="Excel Export" style="height: 33px; width: 33px; float:right;" *ngIf="excelexportchecksettlement" class="label label-success" (click)="exportToExcelFiltersettlement()"><i class= "fa fa-file-excel-o" aria-hidden= "true" ></i></button>
      </div>
      </div>
            <div class="card-body">
              <div class="table-responsive" #epltable>
                <table id="excel-table" datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Order ID</th>
                      <th style="text-align: left;">Customer Info</th>
                      <th style="text-align: left;">Partner Info</th>
                      <th style="text-align: left;">Transaction ID</th>
                      <th style="text-align: right;">Total Paid Amount (Rs.)</th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: left;">Account Holder</th>
                      <th style="text-align: left;">Account Number</th>
                      <th style="text-align: left;">IFSC Code</th>
                      <th style="text-align: left;">Transaction Date & Time</th>
                      <th style="text-align: center;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let settlement of settlementFilterDetail.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="settlement.orderId!=null" align="left" class="v-align-middle">{{settlement.orderId}}</td>
                      <td *ngIf="settlement.orderId==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="settlement.customerId!=null"align="left" class="v-align-middle">
                        <p style="font-size: 12px;"><b>Name :</b> {{settlement.customerId.firstName}} {{settlement.customerId.lastName}} </p>
                        <p style="font-size: 12px;"><b>Mobile :</b> {{settlement.customerId.mobileNumber}}</p>
                      </td>
                      <td *ngIf="settlement.customerId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                      <td *ngIf="settlement.vehicleId!=null"align="left" class="v-align-middle">
                          <p style="font-size: 12px;"><b>Name :</b> {{settlement.vehicleId.userId.firstName}} {{settlement.vehicleId.userId.lastName}}</p>
                          <p style="font-size: 12px;"><b>Mobile :</b> {{settlement.vehicleId.userId.mobileNumber}}</p>
                        </td>
                      <td *ngIf="settlement.vehicleId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                      <td *ngIf="settlement.transactionId!=null" align="left" class="v-align-middle">settlement.transactionId</td>
                      <td *ngIf="settlement.transactionId==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="settlement.totalPayableAmount!=null"align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{settlement.totalPayableAmount | number : '1.2-2' }}</td>
                      <td *ngIf="settlement.totalPayableAmount==null"align="right" class="v-align-middle">N/A</td>
                      <td *ngIf="settlement.paymentStatus!=null && settlement.paymentStatus==='Success' "class="v-align-middle ht-active">{{settlement.paymentStatus}}</td>
                      <td *ngIf="settlement.paymentStatus!=null && settlement.paymentStatus==='Failed' " style="color: red;">{{settlement.paymentStatus}}</td>
                      <td *ngIf="settlement.paymentStatus!=null && settlement.paymentStatus==='Cancelled' " style="color: red;">{{settlement.paymentStatus}}</td>
                      <td *ngIf="settlement.paymentStatus!=null && settlement.paymentStatus==='Initiated' " style="color: blue;">{{settlement.paymentStatus}}</td>
                      <td *ngIf="settlement.bankAccountHolderName!=null"align="center" class="v-align-middle">{{settlement.bankAccountHolderName}}</td>
                      <td *ngIf="settlement.bankAccountHolderName==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="settlement.accountNumber!=null"align="center" class="v-align-middle">{{settlement.accountNumber}}</td>
                      <td *ngIf="settlement.accountNumber==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="settlement.ifsc!=null"align="center" class="v-align-middle">{{settlement.ifsc}}</td>
                      <td *ngIf="settlement.ifsc==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="settlement.paymentDatetime!=null"align="left" class="v-align-middle">{{settlement.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="settlement.paymentDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td class="v-align-middle">
                        <button align="center" class="label label-info" (click)="view(content,settlement.id)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
</div>
</div>
</div>
</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h6>View Settlement Details</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <h5 style="margin-left: 15px; text-align: left; color: grey;">Order Details</h5>
  <div class="container"
    fxLayout
  >
  <div class="item item-1">
    <p class="card-title"><b>OrderID</b><br>
      <span>{{settlementInfoModel.response.orderId}}</span>
    </p>
  </div>
  <div class="item item-2" style="margin-left: 40px;">
    <p class="card-title"><b>Customer Info</b><br>
      <span>
      Name : {{settlementInfoModel.response.customerId.firstName}}<br>
      Mobile No: {{settlementInfoModel.response.customerId.mobileNumber}}
    </span>
  </p>
  </div>
  <div class="item item-3" style="margin-left: 40px;">
    <p class="card-title"><b>Partner Info </b>  <br><span>
      Name : {{settlementInfoModel.response.vehicleId.userId.firstName}}<br>
      Mobile No: {{settlementInfoModel.response.vehicleId.userId.mobileNumber}}
    </span>
  </p>
  </div>
  <div class="item item-4" style="margin-left: 40px;">
    <p class="card-title"><b> Transaction ID </b> <br>
      <span *ngIf="settlementInfoModel.response.transactionId!=null">{{settlementInfoModel.response.transactionId}}</span>
      <span *ngIf="settlementInfoModel.response.transactionId==null">N/A</span>
    </p>
  </div>
  <div class="item item-5" style="margin-left: 40px;">
    <p class="card-title"><b>Account Holder</b> <br>
      <span>{{settlementInfoModel.response.bankAccountHolderName}}</span>
    </p>
  </div>
  <div class="item item-6" style="margin-left: 40px;">
    <p class="card-title"><b>Account No.</b><br>
      <span>{{settlementInfoModel.response.accountNumber}}</span>
    </p>
  </div>
  <div class="item item-7" style="margin-left: 40px;">
    <p class="card-title"><b>IFSC Code</b><br>
      <span>{{settlementInfoModel.response.ifsc}}</span>
    </p> 
  </div>
  </div>
  <br>
  <h5 style="margin-left: 15px; text-align: left; color: grey;">Payment Description</h5>
  <div class="container"
  fxLayout
>
<div class="item item-1">
  <p class="card-title"><b>Fuel</b> 
    <br><span>{{settlementInfoModel.response.fuelType}}</span></p>
</div>
<div class="item item-2" style="margin-left: 10px;">
  <p class="card-title"><b>Quantity in Ltrs</b>
    <br>
    <span>{{settlementInfoModel.response.quantity}}</span> 
   </p>
</div>
<div class="item item-3" style="margin-left: 10px;">
  <p class="card-title"><b>Total Fuel Cost</b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{settlementInfoModel.response.totalfuelcost | number : '1.2-2' }}</span></p>
</div>
<div class="item item-4" style="margin-left: 10px;">
  <p class="card-title"><b>Delivery Charge </b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{settlementInfoModel.response.deliveryCharges | number : '1.2-2'}}</span></p>
</div>
<div class="item item-5" style="margin-left: 10px;">
  <p class="card-title"><b>SGST (Delivery Charge) </b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{settlementInfoModel.response.sgst | number : '1.2-2'}}</span></p>  
</div>
<div class="item item-5" style="margin-left: 10px;">
  <p class="card-title"><b>CGST (Delivery Charge) </b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{settlementInfoModel.response.cgst | number : '1.2-2'}}</span></p>  
</div>
<div class="item item-6" style="margin-left: 10px;">
  <p class="card-title"><b>Total Paid Amount </b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{settlementInfoModel.response.totalPayableAmount | number : '1.2-2' }}</span></p>
</div>
<div class="item item-7" style="margin-left: 10px;">
  <p class="card-title"><b>Status</b>  <br>
    <span *ngIf="settlementInfoModel.response.paymentStatus!=null && settlementInfoModel.response.paymentStatus==='Success' "class="v-align-middle ht-active">{{settlementInfoModel.response.paymentStatus}}</span>
    <span *ngIf="settlementInfoModel.response.paymentStatus!=null && settlementInfoModel.response.paymentStatus==='Failed' " style="color: red;">{{settlementInfoModel.response.paymentStatus}}</span>
    <span *ngIf="settlementInfoModel.response.paymentStatus!=null && settlementInfoModel.response.paymentStatus==='Cancelled' " style="color: red;">{{settlementInfoModel.response.paymentStatus}}</span>
    <span *ngIf="settlementInfoModel.response.paymentStatus!=null && settlementInfoModel.response.paymentStatus==='Initiated' " style="color: blue;">{{settlementInfoModel.response.paymentStatus}}</span>
  </p>
</div>
<div class="item item-8" style="margin-left: 15px;">
  <p class="card-title"><b>Transaction Date & Time </b> <br><span>{{settlementInfoModel.response.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span></p>
</div>
  </div>

  <!-- <div class="modal-body"> -->
    <!-- <div class="wrapper"> -->
    	<!-- <div class="flex extradiv">
    		<h5>Order Details</h5>
    		<p class="card-title">OrderID  <br><span><b>{{settlementInfoModel.response.orderId}}</b></span></p>
    		<p class="card-title">Customer Info  <br><span>
          <b>Name : {{settlementInfoModel.response.customerId.firstName}}</b> <br>
          <b>Mobile No: {{settlementInfoModel.response.customerId.mobileNumber}}</b>
        </span></p>
    		<p class="card-title">Partner Info   <br><span>
          <b>Name : {{settlementInfoModel.response.vehicleId.userId.firstName}}</b> <br>
          <b>Mobile No: {{settlementInfoModel.response.vehicleId.userId.mobileNumber}}</b>
        </span></p>
        <p class="card-title">Transaction ID  <br><span><b>{{settlementInfoModel.response.transactionId}}</b></span></p>
        <p class="card-title">Account Holder <br><span><b>{{settlementInfoModel.response.bankAccountHolderName}}</b></span></p>
        <p class="card-title">Account No.<br><span><b>{{settlementInfoModel.response.accountNumber}}</b></span></p>
        <p class="card-title">IFSC Code<br><span><b>{{settlementInfoModel.response.ifsc}}</b></span></p> 
    	</div> -->
    	<!-- <div class="flex extradiv">
    		<h5>Payment Description</h5>
    		<p class="card-title">Fuel 
 <br><span><b>{{settlementInfoModel.response.fuelType}}</b></span></p>
 <p class="card-title">Quantity in Ltrs
  <br>
  <span><b>{{settlementInfoModel.response.quantity}}</b> </span> 
 </p>
    		<p class="card-title">Total Fuel Cost (In Rs.) <br><span><b><i class="fa fa-inr" aria-hidden="true"></i> {{settlementInfoModel.response.fuelPrice}}</b></span></p>
        <p class="card-title">Delivery Charge(In Rs.)  <br><span><b><i class="fa fa-inr" aria-hidden="true"></i> {{settlementInfoModel.response.deliveryCharges | number : '1.2-2'}}</b></span></p>
        <p class="card-title">SGST (Delivery Charge)  <br><span><b><i class="fa fa-inr" aria-hidden="true"></i> {{settlementInfoModel.response.sgstPercent | number : '1.2-2'}}</b></span></p>
        <p class="card-title">CGST (Delivery Charge)  <br><span><b><i class="fa fa-inr" aria-hidden="true"></i>{{settlementInfoModel.response.cgstPercent | number : '1.2-2'}}</b></span></p>
        <p class="card-title">Total Paid Amount (In Rs.)  <br><span><b><i class="fa fa-inr" aria-hidden="true"></i> {{settlementInfoModel.response.totalPayableAmount | number : '1.2-2' }}</b></span></p>
        <p class="card-title">Status  <br>
          <span *ngIf="settlementInfoModel.response.paymentStatus!=null && settlementInfoModel.response.paymentStatus==='Success' "class="v-align-middle ht-active"><b>{{settlementInfoModel.response.paymentStatus}}</b></span>
          <span *ngIf="settlementInfoModel.response.paymentStatus!=null && settlementInfoModel.response.paymentStatus==='Failed' " style="color: red;"><b>{{settlementInfoModel.response.paymentStatus}}</b></span>
          <span *ngIf="settlementInfoModel.response.paymentStatus!=null && settlementInfoModel.response.paymentStatus==='Cancelled' " style="color: red;"><b>{{settlementInfoModel.response.paymentStatus}}</b></span>
          <span *ngIf="settlementInfoModel.response.paymentStatus!=null && settlementInfoModel.response.paymentStatus==='Initiated' " style="color: blue;"><b>{{settlementInfoModel.response.paymentStatus}}</b></span>
        </p>
        <p class="card-title">Transaction Date & Time  <br><span><b>{{settlementInfoModel.response.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</b></span></p>
    	</div> -->
    <!-- </div> -->
  <!-- </div> -->
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
  </div>
</ng-template>