   <div class="login-wrapper ">
      <!-- START Login Background Pic Wrapper-->
      <div class="bg-pic">
        <!-- START Background Caption-->
        <div class="bg-caption pull-bottom sm-pull-bottom text-white p-l-20 m-b-20" style="display: none;">
         
        </div>
        <!-- END Background Caption-->
      </div>
      <!-- END Login Background Pic Wrapper-->
      <!-- START Login Right Container-->
      <div class="login-container bg-white">
        <div class="p-l-50 p-r-50 p-t-50 m-t-30 sm-p-l-15 sm-p-r-15 sm-p-t-40">
          <img src="assets/img/cinch_fuel_logo.jpg" alt="logo" data-src="assets/img/cinch_fuel_logo.jpg" data-src-retina="assets/img/cinch_fuel_logo.jpg" width="150">
          <h2 class="p-t-25">Sign in <br/> to your account</h2>
          <p class="mw-80 m-t-5"> </p>
          <!-- START Login Form -->
          <form (ngSubmit)="onSubmit()" id="form-login" class="p-t-15" role="form" action="index.html">

            <div *ngIf="isError">
              <div class="error-style">{{errormessage}}</div>
            </div>

            
            <!-- START Form Control-->
            <div class="form-group form-group-default">
              <label>Email Address</label>
              <div class="controls">
                <input type="text" id="emailaddress" name="emailaddress" placeholder="Email Address" class="form-control" required>
              </div>
            </div>
            <!-- END Form Control-->
            <!-- START Form Control-->
            <div class="form-group form-group-default">
              <label>Password</label>
              <div class="input-group">
                <input [type]="fieldTextType ? 'text' : 'password'" class="form-control" id="password" name="password" placeholder="Password" required>
                <div class="input-group-append">
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-eye-slash': !fieldTextType,
                        'fa-eye': fieldTextType
                      }"
                      (click)="toggleFieldTextType()"
                    ></i>
                </div>
              </div>
            </div>
            <!-- START Form Control-->
            <div class="row">
              <div class="col-md-6 no-padding sm-p-l-10">
                <div class="form-check">
                  <input type="checkbox" value="1" id="checkbox1">
                  <!-- <label for="checkbox1">Remember me</label> -->
                </div>
              </div>
              <div class="col-md-6 d-flex align-items-center justify-content-end">
                <button aria-label="" class="btn btn-primary btn-lg m-t-10"  type="submit" >Sign in</button>
                
              </div>
              
            </div>
            <div class="m-b-5 m-t-30">
              <a  routerLink="/forgot-password" class="normal" >Lost your password?</a>
            </div>
           
            <!-- END Form Control-->
          </form>
          <!--END Login Form-->
          <div class="pull-bottom sm-pull-bottom">
            <div class="m-b-30 p-r-80 sm-m-t-20 sm-p-r-15 sm-p-b-20 clearfix">
              <div class="col-sm-9 no-padding m-t-10">
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END Login Right Container-->
    </div>
    <!-- START OVERLAY -->
