
<app-header></app-header>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
  </ngx-spinner>
<!-- START PAGE-CONTAINER -->
<div class="page-container "> 
  <!-- START PAGE CONTENT WRAPPER -->
  <div class="page-content-wrapper "> 
    <!-- START PAGE CONTENT -->
    <div class="content sm-gutter"> 
      <!-- START BREADCRUMBS -->
      <div class="bg-white">
        <div class="container">
          <ol class="breadcrumb breadcrumb-alt">
            <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Home</a></li>
            <li class="breadcrumb-item active">PLATFORM INVOICE </li>
            <div class="btn-section-wrapper">
              <button *ngIf="excelexportflag" title="Excel Export" title="Excel Export" (click)="excelexport()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
            </div>
          </ol>
        </div>
      </div>
      <!-- END BREADCRUMBS -->
      
      <!-- START CONTAINER FLUID -->
      
      <div class=" no-padding    container-fixed-lg bg-white">
        <!-- <div class="container"> 
          <div class="card card-transparent"> -->
            <div *ngIf="defaultCustomer" class="container"> 
              <div class="card card-transparent">
               <div  class="card-body">
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Order ID </th>
                      <th style="text-align: left;">Customer Name</th>
                      <th style="text-align: left;">Customer Mobile</th>
                      <th style="text-align: left;">Vehicles Number</th>
                      <!-- <th style="text-align: left;">Delivery agent Name</th> -->
                      <th style="text-align: left;">Fuel</th>
                      <!-- <th style="text-align: center;">Quantity  in Ltrs</th> -->
                      <th style="text-align: left;">Booked  Date Time </th>
                      <th style="text-align: left;">Delivered  Date Time </th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: center; width: 100px;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let order of orderDetail.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="order.orderId!=null"align="left" class="v-align-middle">{{order.orderId}}</td>
                      <td *ngIf="order.orderId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.customerId.firstName!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.customerId.firstName}} {{order.customerId.lastName}}</td>
                      <td *ngIf="order.customerId.firstName==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.customerId.mobileNumber!=null" align="left" class="v-align-middle">{{order.customerId.mobileNumber}}</td>
                      <td *ngIf="order.customerId.mobileNumber==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.vehicleId.vehicleNumber!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
                      <td *ngIf="order.vehicleId.vehicleNumber==null" align="left" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
                      <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td> -->
                      <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">
                        <span style="font-size: 10px;">{{order.fuelType}}</span>  <br>
                      <span style="font-size: 10px;"><b>Qtn : </b> {{order.quantity}}</span>
                        </td>
                      <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
                      <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td> -->
                      <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                      <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                      <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                      <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                      <td *ngIf="order.status==='Waiting for agent'" align="center" class="v-align-middle ht-inactive">{{order.status}}</td>
                      <td align="center" style="width: 30px;" class="v-align-middle">
                        <button title="View" class="label label-info" (click)="open(content,order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                        <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                        <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.cancelOrder===1"class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                        <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="invoice(order.id,order.platformFilename,content15)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

            <!-- ---- Pending Orders ----  -->
            <div *ngIf="penCustomer" class="container"> 
              <div class="card card-transparent">
            <div  class="card-body">
              <div style="display: none;">{{pencustomerOrder.responseMessage}}</div>
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center; width: 30px;">Sl.No.</th>
                      <th style="text-align: left;">Order ID </th>
                      <th style="text-align: left;">Customer Name</th>
                      <th style="text-align: left;">Customer Mobile</th>
                      <th style="text-align: left;">Vehicle Number</th>
                      <!-- <th style="text-align: left;">Delivery Agent Name</th> -->
                      <th style="text-align: left;">Fuel</th>
                      <!-- <th style="text-align: center;">Quantity  in Ltrs</th> -->
                      <th style="text-align: left;">Booked  Date Time </th>
                      <th style="text-align: left;">Delivered  Date Time </th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: center; width: 100px;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let order of pencustomerOrder.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="order.orderId!=null"align="left" class="v-align-middle">{{order.orderId}}</td>
                      <td *ngIf="order.orderId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.customerId.firstName!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.customerId.firstName}}{{order.customerId.lastName}}</td>
                      <td *ngIf="order.customerId.firstName==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.customerId.mobileNumber!=null" align="left" class="v-align-middle">{{order.customerId.mobileNumber}}</td>
                      <td *ngIf="order.customerId.mobileNumber==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.vehicleId.vehicleNumber!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
                      <td *ngIf="order.vehicleId.vehicleNumber==null" align="left" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
                      <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td> -->
                      <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">
                      <span style="font-size: 10px;">{{order.fuelType}}</span>  <br>
                      <span style="font-size: 10px;"><b>Qtn : </b> {{order.quantity}}</span>
                      </td>
                      <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
                      <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td> -->
                      <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                      <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                      <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                      <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                      <td *ngIf="order.status==='Waiting for agent'" align="center" class="v-align-middle ht-inactive">{{order.status}}</td>
                      <td align="center" style="width: 30px;" class="v-align-middle">
                        <button title="View" class="label label-info" (click)="open(content,order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                        <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                        <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.cancelOrder===1"class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            </div>
            </div>
            
            <!-- --- Inprogress Order -----  -->
            <div *ngIf="inpCustomer" class="container"> 
              <div class="card card-transparent">
            <div  class="card-body">
              <div style="display: none;">{{inpcustomerOrder.responseMessage}}</div>
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Order ID </th>
                      <th style="text-align: left;">Customer Name</th>
                      <th style="text-align: left;">Customer Mobile</th>
                      <th style="text-align: left;">Vehicle Number</th>
                      <!-- <th style="text-align: left;">Delivery Agent Name</th> -->
                      <th style="text-align: left;">Fuel</th>
                      <!-- <th style="text-align: center;">Quantity  in Ltrs</th> -->
                      <th style="text-align: left;">Booked  Date Time </th>
                      <th style="text-align: left;">
                        Delivered  Date Time </th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: center; width: 100px;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let order of inpcustomerOrder.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="order.orderId!=null"align="left" class="v-align-middle">{{order.orderId}}</td>
                      <td *ngIf="order.orderId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.customerId.firstName!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.customerId.firstName}}{{order.customerId.lastName}}</td>
                      <td *ngIf="order.customerId.firstName==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.customerId.mobileNumber!=null" align="left" class="v-align-middle">{{order.customerId.mobileNumber}}</td>
                      <td *ngIf="order.customerId.mobileNumber==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.vehicleId.vehicleNumber!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
                      <td *ngIf="order.vehicleId.vehicleNumber==null" align="left" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
                      <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td> -->
                      <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">
                        <span style="font-size: 10px;">{{order.fuelType}}</span>  <br>
                      <span style="font-size: 10px;"><b>Qtn : </b> {{order.quantity}}</span>
                        </td>
                      <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
                      <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td> -->
                      <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                      <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                      <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                      <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                      <td *ngIf="order.status==='Waiting for agent'" align="center" class="v-align-middle ht-inactive">{{order.status}}</td>
                      <td align="center" style="width: 30px;" class="v-align-middle">
                        <button title="View" class="label label-info" (click)="open(content,order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                        <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                        <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.cancelOrder===1"class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>  
            </div>
            </div>

            <!-- ---- Completed Orders----- -->
            <div *ngIf="comCustomer" class="container"> 
              <div class="card card-transparent">
            <div  class="card-body">
              <div style="display: none;">{{comcustomerOrder.responseMessage}}</div>
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Order ID </th>
                      <th style="text-align: left;">Customer Name</th>
                      <th style="text-align: left;">Customer Mobile</th>
                      <th style="text-align: left;">Vehicle Number</th>
                      <!-- <th style="text-align: left;">Delivery Agent Name</th> -->
                      <th style="text-align: left;">Fuel</th>
                      <!-- <th style="text-align: center;">Quantity  in Ltrs</th> -->
                      <th style="text-align: left;">Booked  Date Time </th>
                      <th style="text-align: left;">
                        Delivered  Date Time </th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: center; width: 100px;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let order of comcustomerOrder.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="order.orderId!=null"align="left" class="v-align-middle">{{order.orderId}}</td>
                      <td *ngIf="order.orderId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.customerId.firstName!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.customerId.firstName}} {{order.customerId.lastName}}</td>
                      <td *ngIf="order.customerId.firstName==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.customerId.mobileNumber!=null" align="left" class="v-align-middle">{{order.customerId.mobileNumber}}</td>
                      <td *ngIf="order.customerId.mobileNumber==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.vehicleId.vehicleNumber!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
                      <td *ngIf="order.vehicleId.vehicleNumber==null" align="left" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
                      <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td> -->
                      <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">
                        <span style="font-size: 10px;">{{order.fuelType}}</span>  <br>
                      <span style="font-size: 10px;"><b>Qtn : </b> {{order.quantity}}</span>
                        </td>
                      <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
                      <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td> -->
                      <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                      <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                      <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                      <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                      <td *ngIf="order.status==='Waiting for agent'" align="center" class="v-align-middle ht-inactive">{{order.status}}</td>
                      <td align="center" style="width: 30px;" class="v-align-middle">
                        <button title="View" class="label label-info" (click)="open(content,order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                        <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                        <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.cancelOrder===1"class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                        <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.platformFilename!=null"  (click)="invoice(order.id,order.platformFilename,content15)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
                        &nbsp;<button *ngIf="order.platformFilename!=null" title="Download Invoice" class="label label-info" (click)="download(order.id,order.platformFilename)" ><i class="fa fa-download" aria-hidden="true"></i></button>
                        &nbsp;<button *ngIf="order.platformFilename!=null" title="Send Invoice" class="label label-info" (click)="sendMail(order.id)" ><i class="fa fa-envelope" aria-hidden="true"></i></button>

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>  
            </div>
            </div>

            <!-- ---- Cancelled Orders----- -->
            <div *ngIf="canCustomer" class="container"> 
              <div class="card card-transparent">
            <div  class="card-body">
              <div style="display: none;">{{cancustomerOrder.responseMessage}}</div>
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Order ID </th>
                      <th style="text-align: left;">Customer Name</th>
                      <th style="text-align: left;">Customer Mobile</th>
                      <th style="text-align: left;">Vehicle Number</th>
                      <!-- <th style="text-align: left;">Delivery Agent Name</th> -->
                      <th style="text-align: left;">Fuel</th>
                      <!-- <th style="text-align: center;">Quantity  in Ltrs</th> -->
                      <th style="text-align: left;">Booked  Date Time </th>
                      <th style="text-align: left;">
                        Delivered  Date Time </th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: center; width: 100px;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let order of cancustomerOrder.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="order.orderId!=null"align="left" class="v-align-middle">{{order.orderId}}</td>
                      <td *ngIf="order.orderId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.customerId.firstName!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.customerId.firstName}} {{order.customerId.lastName}}</td>
                      <td *ngIf="order.customerId.firstName==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.customerId.mobileNumber!=null" align="left" class="v-align-middle">{{order.customerId.mobileNumber}}</td>
                      <td *ngIf="order.customerId.mobileNumber==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.vehicleId.vehicleNumber!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
                      <td *ngIf="order.vehicleId.vehicleNumber==null" align="left" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
                      <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td> -->
                      <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">
                        <span style="font-size: 10px;">{{order.fuelType}}</span>  <br>
                      <span style="font-size: 10px;"><b>Qtn : </b> {{order.quantity}}</span>
                        </td>
                      <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
                      <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td> -->
                      <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                      <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                      <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                      <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                      <td *ngIf="order.status==='Waiting for agent'" align="center" class="v-align-middle ht-inactive">{{order.status}}</td>
                      <td align="center" style="width: 30px;" class="v-align-middle">
                        <button title="View" class="label label-info" (click)="open(content,order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                        <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                        <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.cancelOrder===1"class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>  
            </div>
            </div>

            <!-- ---- All Orders----- -->
            <div *ngIf="allCustomer" class="container"> 
              <div class="card card-transparent">
            <div  class="card-body">
              <div style="display: none;">{{allcustomerOrder.responseMessage}}</div>
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Order ID </th>
                      <th style="text-align: left;">Customer Name</th>
                      <th style="text-align: left;">Customer Mobile</th>
                      <th style="text-align: left;">Vehicle Number</th>
                      <!-- <th style="text-align: left;">Delivery Agent Name</th> -->
                      <th style="text-align: left;">Fuel</th>
                      <!-- <th style="text-align: center;">Quantity  in Ltrs</th> -->
                      <th style="text-align: left;">Booked  Date Time </th>
                      <th style="text-align: left;">
                        Delivered  Date Time </th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: center; width: 100px;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let order of allcustomerOrder.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="order.orderId!=null"align="left" class="v-align-middle">{{order.orderId}}</td>
                      <td *ngIf="order.orderId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.customerId.firstName!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.customerId.firstName}} {{order.customerId.lastName}}</td>
                      <td *ngIf="order.customerId.firstName==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.customerId.mobileNumber!=null" align="left" class="v-align-middle">{{order.customerId.mobileNumber}}</td>
                      <td *ngIf="order.customerId.mobileNumber==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.vehicleId.vehicleNumber!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
                      <td *ngIf="order.vehicleId.vehicleNumber==null" align="left" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
                      <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td> -->
                      <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">
                        <span style="font-size: 10px;">{{order.fuelType}}</span>  <br>
                      <span style="font-size: 10px;"><b>Qtn : </b> {{order.quantity}}</span>
                        </td>
                      <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
                      <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td> -->
                      <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                      <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                      <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                      <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                      <td *ngIf="order.status==='Waiting for agent'" align="center" class="v-align-middle ht-inactive">{{order.status}}</td>
                      <td align="center" style="width: 30px;" class="v-align-middle">
                        <button title="View" class="label label-info" (click)="open(content,order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                        <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                        <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.cancelOrder===1"class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                        <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'"  (click)="invoice(order.id,order.platformFilename,content15)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>  
            </div>
            </div>

              <!-- --- Filter Two Start---- -->
<!-- ---- Pending Orders ----  -->
<div *ngIf="penCustomerfilter" class="container"> 
  <div class="card card-transparent">
<div  class="card-body">
  <div style="display: none;">{{pencustomerOrderfilter.responseMessage}}</div>
  <div class="table-responsive">
    <table datatable class="ch-table">
      <thead>
        <tr> 
          <th style="text-align: center; width: 30px;">Sl.No.</th>
          <th style="text-align: left;">Order ID </th>
          <th style="text-align: left;">Customer Name</th>
          <th style="text-align: left;">Customer Mobile</th>
          <th style="text-align: left;">Vehicle Number</th>
          <!-- <th style="text-align: left;">Delivery Agent Name</th> -->
          <th style="text-align: left;">Fuel</th>
          <!-- <th style="text-align: center;">Quantity  in Ltrs</th> -->
          <th style="text-align: left;">Booked  Date Time </th>
          <th style="text-align: left;">
            Delivered  Date Time </th>
          <th style="text-align: center;">Status</th>
          <th style="text-align: center; width: 100px;">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let order of pencustomerOrderfilter.response; let i=index">
          <td align="center" class="v-align-middle ">{{i + 1}}</td>
          <td *ngIf="order.orderId!=null"align="left" class="v-align-middle">{{order.orderId}}</td>
          <td *ngIf="order.orderId==null"align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.customerId.firstName!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.customerId.firstName}}{{order.customerId.lastName}}</td>
          <td *ngIf="order.customerId.firstName==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.customerId.mobileNumber!=null" align="left" class="v-align-middle">{{order.customerId.mobileNumber}}</td>
          <td *ngIf="order.customerId.mobileNumber==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.vehicleId.vehicleNumber!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
          <td *ngIf="order.vehicleId.vehicleNumber==null" align="left" class="v-align-middle">N/A</td>
          <!-- <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
          <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td> -->
          <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">
            <span style="font-size: 10px;">{{order.fuelType}}</span>  <br>
                      <span style="font-size: 10px;"><b>Qtn : </b> {{order.quantity}}</span>
            </td>
          <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
          <!-- <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
          <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td> -->
          <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
          <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
          <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
          <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
          <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
          <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
          <td *ngIf="order.status==='Waiting for agent'" align="center" class="v-align-middle ht-inactive">{{order.status}}</td>
          <td align="center" style="width: 30px;" class="v-align-middle">
            <button title="View" class="label label-info" (click)="open(content,order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
            <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
            <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.cancelOrder===1"class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</div>
</div>

<!-- --- Inprogress Order -----  -->
<div *ngIf="inpCustomerfilter" class="container"> 
  <div class="card card-transparent">
<div  class="card-body">
  <div style="display: none;">{{inpcustomerOrderfilter.responseMessage}}</div>
  <div class="table-responsive">
    <table datatable class="ch-table">
      <thead>
        <tr> 
          <th style="text-align: center;">Sl.No.</th>
          <th style="text-align: left;">Order ID </th>
          <th style="text-align: left;">Customer Name</th>
          <th style="text-align: left;">Customer Mobile</th>
          <th style="text-align: left;">Vehicle Number</th>
          <!-- <th style="text-align: left;">Delivery Agent Name</th> -->
          <th style="text-align: left;">Fuel</th>
          <!-- <th style="text-align: center;">Quantity  in Ltrs</th> -->
          <th style="text-align: left;">Booked  Date Time </th>
          <th style="text-align: left;">
            Delivered  Date Time </th>
          <th style="text-align: center;">Status</th>
          <th style="text-align: center; width: 100px;">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let order of inpcustomerOrderfilter.response; let i=index">
          <td align="center" class="v-align-middle ">{{i + 1}}</td>
          <td *ngIf="order.orderId!=null"align="left" class="v-align-middle">{{order.orderId}}</td>
          <td *ngIf="order.orderId==null"align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.customerId.firstName!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.customerId.firstName}}{{order.customerId.lastName}}</td>
          <td *ngIf="order.customerId.firstName==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.customerId.mobileNumber!=null" align="left" class="v-align-middle">{{order.customerId.mobileNumber}}</td>
          <td *ngIf="order.customerId.mobileNumber==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.vehicleId.vehicleNumber!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
          <td *ngIf="order.vehicleId.vehicleNumber==null" align="left" class="v-align-middle">N/A</td>
          <!-- <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
          <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td> -->
          <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">
            <span style="font-size: 10px;">{{order.fuelType}}</span>  <br>
                      <span style="font-size: 10px;"><b>Qtn : </b> {{order.quantity}}</span>
            </td>
          <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
          <!-- <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
          <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td> -->
          <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
          <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
          <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
          <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
          <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
          <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
          <td *ngIf="order.status==='Waiting for agent'" align="center" class="v-align-middle ht-inactive">{{order.status}}</td>
          <td align="center" style="width: 30px;" class="v-align-middle">
            <button title="View" class="label label-info" (click)="open(content,order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
            <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
            <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.cancelOrder===1"class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>  
</div>
</div>

<!-- ---- Completed Orders----- -->
<div *ngIf="comCustomerfilter" class="container"> 
  <div class="card card-transparent">
<div  class="card-body">
  <div style="display: none;">{{comcustomerOrderfilter.responseMessage}}</div>
  <div class="table-responsive">
    <table datatable class="ch-table">
      <thead>
        <tr> 
          <th style="text-align: center;">Sl.No.</th>
          <th style="text-align: left;">Order ID </th>
          <th style="text-align: left;">Customer Name</th>
          <th style="text-align: left;">Customer Mobile</th>
          <th style="text-align: left;">Vehicle Number</th>
          <!-- <th style="text-align: left;">Delivery Agent Name</th> -->
          <th style="text-align: left;">Fuel</th>
          <!-- <th style="text-align: center;">Quantity  in Ltrs</th> -->
          <th style="text-align: left;">Booked  Date Time </th>
          <th style="text-align: left;">
            Delivered  Date Time </th>
          <th style="text-align: center;">Status</th>
          <th style="text-align: center; width: 100px;">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let order of comcustomerOrderfilter.response; let i=index">
          <td align="center" class="v-align-middle ">{{i + 1}}</td>
          <td *ngIf="order.orderId!=null"align="left" class="v-align-middle">{{order.orderId}}</td>
          <td *ngIf="order.orderId==null"align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.customerId.firstName!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.customerId.firstName}} {{order.customerId.lastName}}</td>
          <td *ngIf="order.customerId.firstName==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.customerId.mobileNumber!=null" align="left" class="v-align-middle">{{order.customerId.mobileNumber}}</td>
          <td *ngIf="order.customerId.mobileNumber==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.vehicleId.vehicleNumber!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
          <td *ngIf="order.vehicleId.vehicleNumber==null" align="left" class="v-align-middle">N/A</td>
          <!-- <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
          <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td> -->
          <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">
            <span style="font-size: 10px;">{{order.fuelType}}</span>  <br>
                      <span style="font-size: 10px;"><b>Qtn : </b> {{order.quantity}}</span>
            </td>
          <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
          <!-- <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
          <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td> -->
          <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
          <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
          <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
          <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
          <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
          <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
          <td *ngIf="order.status==='Waiting for agent'" align="center" class="v-align-middle ht-inactive">{{order.status}}</td>
          <td align="center" style="width: 30px;" class="v-align-middle">
            <button title="View" class="label label-info" (click)="open(content,order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
            <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
            <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.cancelOrder===1"class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
            <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="invoice(order.id,order.platformFilename,content15)"  class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>  
</div>
</div>

<!-- ---- Cancelled Orders----- -->
<div *ngIf="canCustomerfilter" class="container"> 
  <div class="card card-transparent">
<div  class="card-body">
  <div style="display: none;">{{cancustomerOrderfilter.responseMessage}}</div>
  <div class="table-responsive">
    <table datatable class="ch-table">
      <thead>
        <tr> 
          <th style="text-align: center;">Sl.No.</th>
          <th style="text-align: left;">Order ID </th>
          <th style="text-align: left;">Customer Name</th>
          <th style="text-align: left;">Customer Mobile</th>
          <th style="text-align: left;">Vehicle Number</th>
          <!-- <th style="text-align: left;">Delivery Agent Name</th> -->
          <th style="text-align: left;">Fuel</th>
          <!-- <th style="text-align: center;">Quantity  in Ltrs</th> -->
          <th style="text-align: left;">Booked  Date Time </th>
          <th style="text-align: left;">
            Delivered  Date Time </th>
          <th style="text-align: center;">Status</th>
          <th style="text-align: center; width: 100px;">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let order of cancustomerOrderfilter.response; let i=index">
          <td align="center" class="v-align-middle ">{{i + 1}}</td>
          <td *ngIf="order.orderId!=null"align="left" class="v-align-middle">{{order.orderId}}</td>
          <td *ngIf="order.orderId==null"align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.customerId.firstName!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.customerId.firstName}} {{order.customerId.lastName}}</td>
          <td *ngIf="order.customerId.firstName==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.customerId.mobileNumber!=null" align="left" class="v-align-middle">{{order.customerId.mobileNumber}}</td>
          <td *ngIf="order.customerId.mobileNumber==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.vehicleId.vehicleNumber!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
          <td *ngIf="order.vehicleId.vehicleNumber==null" align="left" class="v-align-middle">N/A</td>
          <!-- <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
          <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td> -->
          <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">
            <span style="font-size: 10px;">{{order.fuelType}}</span>  <br>
                      <span style="font-size: 10px;"><b>Qtn : </b> {{order.quantity}}</span>
            </td>
          <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
          <!-- <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
          <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td> -->
          <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
          <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
          <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
          <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
          <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
          <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
          <td *ngIf="order.status==='Waiting for agent'" align="center" class="v-align-middle ht-inactive">{{order.status}}</td>
          <td align="center" style="width: 30px;" class="v-align-middle">
            <button title="View" class="label label-info" (click)="open(content,order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
            <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
            <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.cancelOrder===1"class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>  
</div>
</div>

<!-- ---- All Orders----- -->
<div *ngIf="allCustomerfilter" class="container"> 
  <div class="card card-transparent">
<div  class="card-body">
  <div style="display: none;">{{allcustomerOrderfilter.responseMessage}}</div>
  <div class="table-responsive">
    <table datatable class="ch-table">
      <thead>
        <tr> 
          <th style="text-align: center;">Sl.No.</th>
          <th style="text-align: left;">Order ID </th>
          <th style="text-align: left;">Customer Name</th>
          <th style="text-align: left;">Customer Mobile</th>
          <th style="text-align: left;">Vehicle Number</th>
          <!-- <th style="text-align: left;">Delivery Agent Name</th> -->
          <th style="text-align: left;">Fuel</th>
          <!-- <th style="text-align: center;">Quantity  in Ltrs</th> -->
          <th style="text-align: left;">Booked  Date Time </th>
          <th style="text-align: left;">
            Delivered  Date Time </th>
          <th style="text-align: center;">Status</th>
          <th style="text-align: center; width: 100px;">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let order of allcustomerOrderfilter.response; let i=index">
          <td align="center" class="v-align-middle ">{{i + 1}}</td>
          <td *ngIf="order.orderId!=null"align="left" class="v-align-middle">{{order.orderId}}</td>
          <td *ngIf="order.orderId==null"align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.customerId.firstName!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.customerId.firstName}} {{order.customerId.lastName}}</td>
          <td *ngIf="order.customerId.firstName==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.customerId.mobileNumber!=null" align="left" class="v-align-middle">{{order.customerId.mobileNumber}}</td>
          <td *ngIf="order.customerId.mobileNumber==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.vehicleId.vehicleNumber!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
          <td *ngIf="order.vehicleId.vehicleNumber==null" align="left" class="v-align-middle">N/A</td>
          <!-- <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
          <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td> -->
          <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">
            <span style="font-size: 10px;">{{order.fuelType}}</span>  <br>
                      <span style="font-size: 10px;"><b>Qtn : </b> {{order.quantity}}</span>
            </td>
          <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
          <!-- <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
          <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td> -->
          <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
          <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
          <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
          <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
          <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
          <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
          <td *ngIf="order.status==='Waiting for agent'" align="center" class="v-align-middle ht-inactive">{{order.status}}</td>
          <td align="center" style="width: 30px;" class="v-align-middle">
            <button title="View" class="label label-info" (click)="open(content,order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
            <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
            <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.cancelOrder===1"class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
            <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'"  (click)="invoice(order.id,order.platformFilename,content15)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>  
</div>
</div>


              <!-- ----- Filter Two End ----  -->

              <div *ngIf="onboardedfilter1" class="container"> 
                <div class="card card-transparent">
              <div  class="card-body">
                <div style="display: none;">{{onboardedcustomerOrderfilter1.responseMessage}}</div>
                <div class="table-responsive">
                  <table datatable class="ch-table">
                    <thead>
                      <tr> 
                        <th style="text-align: center;">Sl.No.</th>
                        <th style="text-align: left;">Order ID </th>
                        <th style="text-align: left;">Customer Name</th>
                        <th style="text-align: left;">Customer Mobile</th>
                        <th style="text-align: left;">Vehicle Number</th>
                        <!-- <th style="text-align: left;">Delivery Agent Name</th> -->
                        <th style="text-align: left;">Fuel</th>
                        <!-- <th style="text-align: center;">Quantity  in Ltrs</th> -->
                        <th style="text-align: left;">Booked  Date Time </th>
                        <th style="text-align: left;">
                          Delivered  Date Time </th>
                        <th style="text-align: center;">Status</th>
                        <th style="text-align: center; width: 100px;">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let order of onboardedcustomerOrderfilter1.response; let i=index">
                        <td align="center" class="v-align-middle ">{{i + 1}}</td>
                        <td *ngIf="order.orderId!=null"align="left" class="v-align-middle">{{order.orderId}}</td>
                        <td *ngIf="order.orderId==null"align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.customerId.firstName!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.customerId.firstName}} {{order.customerId.lastName}}</td>
                        <td *ngIf="order.customerId.firstName==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.customerId.mobileNumber!=null" align="left" class="v-align-middle">{{order.customerId.mobileNumber}}</td>
                        <td *ngIf="order.customerId.mobileNumber==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.vehicleId.vehicleNumber!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
                        <td *ngIf="order.vehicleId.vehicleNumber==null" align="left" class="v-align-middle">N/A</td>
                        <!-- <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
                        <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td> -->
                        <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">
                          <span style="font-size: 10px;">{{order.fuelType}}</span>  <br>
                      <span style="font-size: 10px;"><b>Qtn : </b> {{order.quantity}}</span>
                          </td>
                        <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                        <!-- <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
                        <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td> -->
                        <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                        <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                        <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                        <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                        <td *ngIf="order.status==='Waiting for agent'" align="center" class="v-align-middle ht-inactive">{{order.status}}</td>
                        <td align="center" style="width: 30px;" class="v-align-middle">
                          <button title="View" class="label label-info" (click)="open(content,order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                          <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                          <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.cancelOrder===1"class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                          <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'"  (click)="invoice(order.id,order.platformFilename,content15)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>  
              </div>
              </div>

              <div *ngIf="onboardedfilter2" class="container"> 
                <div class="card card-transparent">
              <div  class="card-body">
                <div style="display: none;">{{onboardedcustomerOrderfilter2.responseMessage}}</div>
                <div class="table-responsive">
                  <table datatable class="ch-table">
                    <thead>
                      <tr> 
                        <th style="text-align: center;">Sl.No.</th>
                        <th style="text-align: left;">Order ID </th>
                        <th style="text-align: left;">Customer Name</th>
                        <th style="text-align: left;">Customer Mobile</th>
                        <th style="text-align: left;">Vehicle Number</th>
                        <th style="text-align: left;">Fuel</th>
                        <!-- <th style="text-align: center;">Quantity  in Ltrs</th> -->
                        <th style="text-align: left;">Booked  Date Time </th>
                        <th style="text-align: left;">
                          Delivered  Date Time </th>
                        <th style="text-align: center;">Status</th>
                        <th style="text-align: center; width: 100px;">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let order of onboardedcustomerOrderfilter2.response; let i=index">
                        <td align="center" class="v-align-middle ">{{i + 1}}</td>
                        <td *ngIf="order.orderId!=null"align="left" class="v-align-middle">{{order.orderId}}</td>
                        <td *ngIf="order.orderId==null"align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.customerId.firstName!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.customerId.firstName}} {{order.customerId.lastName}}</td>
                        <td *ngIf="order.customerId.firstName==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.customerId.mobileNumber!=null" align="left" class="v-align-middle">{{order.customerId.mobileNumber}}</td>
                        <td *ngIf="order.customerId.mobileNumber==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.vehicleId.vehicleNumber!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
                        <td *ngIf="order.vehicleId.vehicleNumber==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">
                          <span style="font-size: 10px;">{{order.fuelType}}</span>  <br>
                      <span style="font-size: 10px;"><b>Qtn : </b> {{order.quantity}}</span>
                          </td>
                        <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                        <!-- <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
                        <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td> -->
                        <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                        <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                        <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                        <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                        <td *ngIf="order.status==='Waiting for agent'" align="center" class="v-align-middle ht-inactive">{{order.status}}</td>
                        <td align="center" style="width: 30px;" class="v-align-middle">
                          <button title="View" class="label label-info" (click)="open(content,order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                          <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                          <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.cancelOrder===1"class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                          <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'"  (click)="invoice(order.id,order.platformFilename,content15)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>  
              </div>
              </div>

          <!-- </div> -->
          <!-- END card --> 
        <!-- </div> -->
      </div>
      <!-- END CONTAINER FLUID --> 
    </div>
  </div>
  <!-- END PAGE CONTENT WRAPPER --> 
</div>
<!-- END PAGE CONTAINER --> 

<ng-template #content let-modal>
  <div class="modal-header">
    <h6>Order View Page</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <h5 style="margin-left: 15px; text-align: left; color: grey;">Order Details</h5>
  <div class="container"
  fxLayout
>
<div class="item item-1">
<p><b>Order ID</b></p>
<span>{{orderDetails.response.orderId}}</span>
</div>
<div class="item item-2" style="margin-left: 30px;">
  <p><b>Customer Name</b></p>
  <span *ngIf="orderDetails.response.customerId!=null" style="word-wrap: break-word;">{{orderDetails.response.customerId.firstName}} {{orderDetails.response.customerId.lastName}}</span>
      <span *ngIf="orderDetails.response.customerId==null">N/A</span>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p><b>Customer Mobile</b></p>
  <span *ngIf="orderDetails.response.customerId!=null">{{orderDetails.response.customerId.mobileNumber}}</span>
      <span *ngIf="orderDetails.response.customerId==null">N/A</span>
</div>
<div class="item item-4" style="margin-left: 30px;">
  <p><b>Booking Date & Time</b></p>
  <span *ngIf="orderDetails.response.bookedDate!=null">{{orderDetails.response.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span>
      <span *ngIf="orderDetails.response.bookedDate==null">N/A</span>
</div>
<div class="item item-5" style="margin-left: 30px;">
  <p><b>Quantity in Ltrs</b></p>
  <span *ngIf="orderDetails.response.quantity!=null">{{orderDetails.response.quantity}}</span>
      <span *ngIf="orderDetails.response.quantity==null">N/A</span>
</div>
<div class="item item-7" style="margin-left: 30px;">
  <p><b>Order Type</b></p>
<span *ngIf="orderDetails.response.bookLaterTime===''; else ifNotShow">
  Immediate
</span>
<ng-template #ifNotShow>
  <span>Scheduled</span>
</ng-template>
</div>
<div *ngIf="orderDetails.response.status==='Cancelled'" class="item item-8" style="margin-left: 30px;">
  <p><b>Cancelled Date & Time</b></p>
  <span >{{orderDetails.response.orderCancellationDatetime |date:'dd/MM/yyyy hh:mm:ss a'}}</span>
</div>
<div *ngIf="orderDetails.response.status==='Cancelled'" class="item item-9" style="margin-left: 30px;">
  <p><b>Cancelled By</b></p>
  <span *ngIf="orderDetails.response.cancelledBy!=null">
    <span style="font-size: 10px;">Name :{{orderDetails.response.cancelledBy.firstName}} {{orderDetails.response.cancelledBy.lastName}}</span><br>
    <span style="font-size: 10px;">Mobile :{{orderDetails.response.cancelledBy.mobileNumber}}</span>
  </span>
  <span *ngIf="orderDetails.response.cancelledBy==null">N/A</span>
</div>
<div *ngIf="orderDetails.response.status==='Cancelled'" class="item item-9" style="margin-left: 30px;">
  <p><b>Reason for Cancelling</b></p>
  <span *ngIf="orderDetails.response.cancellationId!=null" style="word-wrap: break-word;">{{orderDetails.response.cancellationId.cancellationMessage}}</span>
  <span *ngIf="orderDetails.response.cancellationId==null">N/A</span>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p><b>Status</b></p>
  <span *ngIf="orderDetails.response.status==='Pending'"><span class="ht-Pending"><b>{{orderDetails.response.status}}</b></span></span>
      <span *ngIf="orderDetails.response.status==='Cancelled'"><span style="color: red;"><b>{{orderDetails.response.status}}</b></span></span>
      <span *ngIf="orderDetails.response.status==='Inprogress'"><span style="color: blue;"><b>{{orderDetails.response.status}}</b></span></span>
      <span *ngIf="orderDetails.response.status==='Delivered'"><span class="ht-active"><b>{{orderDetails.response.status}}</b></span></span>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p><b>Coupon Code</b></p>
  <span *ngIf="orderDetails.response.couponCode!=null">{{orderDetails.response.couponCode}}</span>
  <span *ngIf="orderDetails.response.couponCode==null">N/A</span>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p><b>Coupon Discount Price</b></p>
  <span *ngIf="orderDetails.response.couponCode!=null">
  <span *ngIf="orderDetails.response.couponDiscountFlat==1">
    <span>{{orderDetails.response.couponDiscountPrice}} % </span>
  </span>
  <span *ngIf="orderDetails.response.couponDiscountFlat==2">
    <span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.couponDiscountPrice | number : '1.2-2'}}</span>
  </span>
  </span>
  <span *ngIf="orderDetails.response.couponCode==null">N/A</span>
</div>
  </div>
<br>
  <h5 style="margin-left: 15px; text-align: left; color: grey;">Delivery Agent Info</h5>
  <div class="container"
  fxLayout
>
<div class="item item-1">
<p><b>Delivery Agent Name</b></p>
<span *ngIf="orderDetails.response.orderAcceptedBy!=null" style="word-wrap: break-word;">{{orderDetails.response.orderAcceptedBy.firstName}} {{orderDetails.response.orderAcceptedBy.lastName}}</span>
      <span *ngIf="orderDetails.response.orderAcceptedBy==null">N/A</span>
</div>
<div class="item item-2" style="margin-left: 40px;">
  <p><b>Delivery Agent Number</b></p>
  <span *ngIf="orderDetails.response.orderAcceptedBy!=null">{{orderDetails.response.orderAcceptedBy.mobileNumber}}</span>
      <span *ngIf="orderDetails.response.orderAcceptedBy==null">N/A</span>
</div>
<div class="item item-4" style="margin-left: 40px;">
  <p><b>Vehicle Number</b></p>
  <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.vehicleNumber}}</span>
      <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
</div>
<div class="item item-5" style="margin-left: 40px;">
  <p><b>Fuel</b></p>
  <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.fuelType.fuelTypeName}}</span>
      <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
</div>
<div *ngIf="orderDetails.response.status==='Completed'" class="item item-6" style="margin-left: 40px;">
  <p><b>Delivered  Date Time</b></p>
  <span >{{orderDetails.response.orderDeliveredDateTime |date:'dd/MM/yyyy hh:mm:ss a' }}</span>
</div>
<div class="item item-7" style="margin-left: 40px;">
  <p><b>Delivery location</b></p>
  <span *ngIf="orderDetails.response.deliveryAddress!=null" style="word-wrap: break-word;">{{orderDetails.response.deliveryAddress}}</span>
      <span *ngIf="orderDetails.response.deliveryAddress==null">N/A</span>
</div>
  </div>
  <br>
  <div class="container"
  fxLayout
>
<div class="item item-1" >
  <p><b>Order Placed location</b></p>
  <span *ngIf="orderDetails.response.reqAddress!=null">{{orderDetails.response.reqAddress}}</span>
      <span *ngIf="orderDetails.response.reqAddress==null">N/A</span>
</div>
<div class="item item-1" style="margin-left: 20px;">
  <p><b>Delivery Date & Time</b></p>
  <span *ngIf="orderDetails.response.orderDeliveredDateTime!=null">{{orderDetails.response.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a' }}</span>
      <span *ngIf="orderDetails.response.orderDeliveredDateTime==null">N/A</span>
</div>
<div class="item item-1" style="margin-left: 20px;">
  <p><b>Delivered Ltrs</b></p>
  <span *ngIf="orderDetails.response.orderDeliveredLt!=null">{{orderDetails.response.orderDeliveredLt}}</span>
      <span *ngIf="orderDetails.response.orderDeliveredLt==null">N/A</span>
</div>
</div>
  <br>
  <hr class="beautiful-line">
  <h5 style="margin-left: 15px; text-align: left; color: grey;">Transaction Details</h5>
  <div class="container"
  fxLayout
>

<div class="item item-3">
  <p class="card-title"><b>Total Fuel Cost (In Rs.)</b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.totalfuelcost | number : '1.2-2'}}</span></p>
</div>
<div class="item item-6" style="margin-left: 40px;">
  <p class="card-title"><b>Coupon Info </b> <br>
    <span *ngIf="orderDetails.response.couponCode!=null">Coupon Code : {{orderDetails.response.couponCode}}</span>
    <span *ngIf="orderDetails.response.couponCode==null">Coupon Code : N/A</span><br>
    <span *ngIf="orderDetails.response.couponDiscountFlat!=null && orderDetails.response.couponCode!=null">
      <span *ngIf="orderDetails.response.couponDiscountFlat==1">
        <span>Discount Percentage : {{orderDetails.response.couponDiscountPrice}} % </span>
      </span>
      <span *ngIf="orderDetails.response.couponDiscountFlat==2">
        Discount Prise :  <i class="fa fa-inr" aria-hidden="true"></i>{{orderDetails.response.couponDiscountPrice | number : '1.2-2' }}
      </span>
    </span>
    <span *ngIf="orderDetails.response.couponCode==null">DisCount : N/A</span>
  </p>
</div>
<div *ngIf="orderDetails.response.couponCode!=null" class="item item-6" style="margin-left: 40px;">
  <p class="card-title"><b>Final Fuel Cost </b> <br>
    <span><i class="fa fa-inr" aria-hidden="true"></i>{{orderDetails.response.finaltotalfuelCost | number : '1.2-2' }}</span>
</div>
<div class="item item-4" style="margin-left: 20px;">
  <p class="card-title"><b>Delivery Charge(In Rs.) </b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.deliveryCharges | number : '1.2-2'}}</span></p>
</div>
<div class="item item-5" style="margin-left: 20px;">
  <p class="card-title"><b>SGST (9%) </b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.sgst | number : '1.2-2'}}</span></p>  
</div>
<div class="item item-5" style="margin-left: 20px;">
  <p class="card-title"><b>CGST (9%) </b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.cgst | number : '1.2-2'}}</span></p>  
</div>
<div class="item item-6" style="margin-left: 20px;">
  <p class="card-title"><b>Platform Charge (In Rs.) </b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.internetHandlingFee | number : '1.2-2' }}</span></p>
</div>
<div class="item item-6" style="margin-left: 20px;">
  <p class="card-title"><b>Total Paid Amount (In Rs.) </b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.totalPayableAmount | number : '1.2-2' }}</span></p>
</div>
  </div>
  <br>
  <hr class="beautiful-line">
  <h5 style="margin-left: 15px; text-align: left; color: grey;">Assets Info</h5>
  <br>
  <div class="container" fxLayout>
    <div class="item item-1">
      <p><b>Authorized Person</b></p>
     <span *ngIf="asstesInfo.response!=null">
      <span *ngIf="asstesInfo.response.assetId!=null">{{asstesInfo.response.assetId.authorizedPersonName}}</span>
      <span *ngIf="asstesInfo.response.assetId==null">N/A</span>
     </span> 
     <span *ngIf="asstesInfo.response==null">N/A</span>
    </div>
    <div class="item item-1" style="margin-left: 20px;">
      <p><b>DGKVA</b></p>
     <span *ngIf="asstesInfo.response!=null">
      <span *ngIf="asstesInfo.response.assetId!=null">{{asstesInfo.response.assetId.dgKVA}}</span>
      <span *ngIf="asstesInfo.response.assetId==null">N/A</span>
     </span> 
     <span *ngIf="asstesInfo.response==null">N/A</span>
    </div>
    <div class="item item-1" style="margin-left: 20px;">
      <p><b>Asset Make</b></p>
     <span *ngIf="asstesInfo.response!=null">
      <span *ngIf="asstesInfo.response.assetId!=null">{{asstesInfo.response.assetId.assestMake}}</span>
      <span *ngIf="asstesInfo.response.assetId==null">N/A</span>
     </span> 
     <span *ngIf="asstesInfo.response==null">N/A</span>
    </div>
    <div class="item item-1" style="margin-left: 20px;">
      <p><b>Asset Model</b></p>
     <span *ngIf="asstesInfo.response!=null">
      <span *ngIf="asstesInfo.response.assetId!=null">{{asstesInfo.response.assetId.assestModel}}</span>
      <span *ngIf="asstesInfo.response.assetId==null">N/A</span>
     </span> 
     <span *ngIf="asstesInfo.response==null">N/A</span>
    </div>
    <div class="item item-1" style="margin-left: 20px;">
      <p><b>Contact Mobile Number</b></p>
     <span *ngIf="asstesInfo.response!=null">
      <span *ngIf="asstesInfo.response.assetId!=null">{{asstesInfo.response.assetId.contactMobileNumber}}</span>
      <span *ngIf="asstesInfo.response.assetId==null">N/A</span>
     </span> 
     <span *ngIf="asstesInfo.response==null">N/A</span>
    </div>
    <div class="item item-1" style="margin-left: 20px;">
      <p><b>Fuel Type</b></p>
     <span *ngIf="asstesInfo.response!=null">
      <span *ngIf="asstesInfo.response.assetId!=null">
        <span *ngIf="asstesInfo.response.assetId.fuelType!=null">
          {{asstesInfo.response.assetId.fuelType.fuelTypeName}}
        </span>
        <span *ngIf="asstesInfo.response.assetId.fuelType==null">N/A</span>
        </span>
      <span *ngIf="asstesInfo.response.assetId==null">N/A</span>
     </span> 
     <span *ngIf="asstesInfo.response==null">N/A</span>
    </div>
    <div class="item item-1" style="margin-left: 20px;">
      <p><b>Tank Capacity</b></p>
     <span *ngIf="asstesInfo.response!=null">
      <span *ngIf="asstesInfo.response.assetId!=null">{{asstesInfo.response.assetId.tankCapacity}}</span>
      <span *ngIf="asstesInfo.response.assetId==null">N/A</span>
     </span> 
     <span *ngIf="asstesInfo.response==null">N/A</span>
    </div>
</div>
<hr class="beautiful-line">
<h5 style="margin-left: 15px; text-align: left; color: grey;">Refund Info</h5>
  <br>
  <div class="container" fxLayout>
    <div class="item item-1">
      <p><b>Quantity in Ltrs Ordered</b></p>
      <span *ngIf="orderDetails.response.refundFlag==1">
        <span *ngIf="orderDetails.response.quantity!=null">{{orderDetails.response.quantity}}</span>
        <span *ngIf="orderDetails.response.quantity==null">N/A</span>
      </span>
      <span *ngIf="orderDetails.response.refundFlag==0">
        N/A
      </span>
    </div>

    <div class="item item-1" style="margin-left: 30px;">
      <p><b>Amount is Rs. / Ltr</b></p>
      <span *ngIf="orderDetails.response.refundFlag==1">
        <span *ngIf="orderDetails.response.fuelPrice!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.fuelPrice | number : '1.2-2' }}</span>
        <span *ngIf="orderDetails.response.fuelPrice==null">N/A</span>
      </span>
      <span *ngIf="orderDetails.response.refundFlag==0">
        N/A
      </span>
    </div>

    <div class="item item-1" style="margin-left: 30px;">
      <p><b>Quantity in Ltrs Delivered</b></p>
      <span *ngIf="orderDetails.response.refundFlag==1">
        <span *ngIf="orderDetails.response.orderDeliveredLt!=null">{{orderDetails.response.orderDeliveredLt}}</span>
        <span *ngIf="orderDetails.response.orderDeliveredLt==null">N/A</span>
      </span>
      <span *ngIf="orderDetails.response.refundFlag==0">
        N/A
      </span>
    </div>

    <div class="item item-1" style="margin-left: 30px;">
      <p><b>Total Paid Amount in Rs.</b></p>
      <span *ngIf="orderDetails.response.refundFlag==1">
        <span *ngIf="orderDetails.response.totalPayableAmount!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.totalPayableAmount | number : '1.2-2' }}</span>
        <span *ngIf="orderDetails.response.totalPayableAmount==null">N/A</span>
      </span>
      <span *ngIf="orderDetails.response.refundFlag==0">
        N/A
      </span>
    </div>

    <div class="item item-1" style="margin-left: 30px;">
      <p><b>Amount in Rs. refunded</b></p>
      <span *ngIf="orderDetails.response.refundFlag==1">
        <span *ngIf="orderDetails.response.refundAmount!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.refundAmount | number : '1.2-2' }}</span>
        <span *ngIf="orderDetails.response.refundAmount==null">N/A</span>
      </span>
      <span *ngIf="orderDetails.response.refundFlag==0">
        N/A
      </span>
    </div>

    <div class="item item-1" style="margin-left: 30px;">
      <p><b>Refund Status</b></p>
      <span *ngIf="orderDetails.response.refundFlag==1">
        <span *ngIf="orderDetails.response.refundStatus==20" style="color: orange;"> Initiated</span>
        <span *ngIf="orderDetails.response.refundStatus==21" style="color: green;"> Completed</span>
      </span>
      <span *ngIf="orderDetails.response.refundFlag==0">
        N/A
      </span>
    </div>

    <div class="item item-1" style="margin-left: 30px;">
      <p><b>Refund Submitted Date & Time</b></p>
      <span *ngIf="orderDetails.response.refundFlag==1">
        <span *ngIf="orderDetails.response.refundSubmittedDateTime!=null">
          {{orderDetails.response.refundSubmittedDateTime |date:'dd/MM/yyyy hh:mm:ss a'}}
        </span>
        <span *ngIf="orderDetails.response.refundSubmittedDateTime==null">
          N/A
        </span>
      </span>
      <span *ngIf="orderDetails.response.refundFlag==0">
        N/A
      </span>
    </div>
  </div>
  <br>
  <div class="container" fxLayout>
    <div class="item item-1">
      <p><b>Refunded Completed Date & Time</b></p>
      <span *ngIf="orderDetails.response.refundFlag==1">
        <span *ngIf="orderDetails.response.refundDateTime!=null">
          {{orderDetails.response.refundDateTime |date:'dd/MM/yyyy hh:mm:ss a'}}
        </span>
        <span *ngIf="orderDetails.response.refundDateTime==null">
          N/A
        </span>
      </span>
      <span *ngIf="orderDetails.response.refundFlag==0">
        N/A
      </span>
    </div>
    </div>

<hr *ngIf="orderDetails.response.status=='Cancelled'" class="beautiful-line">
<h5 *ngIf="orderDetails.response.status=='Cancelled'" style="margin-left: 15px; text-align: left; color: grey;">Order Track Info</h5>
<br>
<div *ngIf="orderDetails.response.status=='Cancelled'"class="container"
  fxLayout
>
<div class="item item-1" style="margin-left: 20px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderPlaced==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderPlaced==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Placed</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderPlacedDateTime!=null" style="text-align: center;">
    {{orderTrackDetails.response.orderPlacedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderPlacedDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-2" style="margin-left: -80px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-3" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderAccepted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderAccepted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>    
  </span>
  <span style="margin-bottom: 10px;"><b>Agent Accepted</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderAcceptedDateTime!=null">
    {{orderTrackDetails.response.orderAcceptedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span style="color: red;" *ngIf="orderTrackDetails.response.orderAcceptedDateTime==null">
    Order Cancelled
  </span>
</div>
<div class="item item-4" style="margin-left: -50px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-5" style="margin-left: 15px;">
  <img *ngIf="orderTrackDetails.response.paymentCompleted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.paymentCompleted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>    
    <span style="margin-bottom: 10px; width: 30px;"><b>Payment Completed</b></span><br>
    <span style="color: red;">
      Order Cancelled
    </span>    
</div>
<div class="item item-6" style="margin-left: -60px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-7" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderInitiated==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderInitiated==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Initiated</b></span><br>
  <span style="color: red;">
    Order Cancelled
  </span>  
</div>
<div class="item item-8" style="margin-left: -40px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-9" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.refillOtp==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.refillOtp==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Refill OTP Verified</b></span><br>
  <span style="color: red;">
    Order Cancelled
  </span>  
</div>
<div class="item item-10" style="margin-left: -60px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-11" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.endOrderOtp==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.endOrderOtp==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>END Order OTP Verified</b></span><br>
  <span style="color: red;">
    Order Cancelled
  </span>  
</div>
<div class="item item-12" style="margin-left: -80px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-13" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderCompleted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderCompleted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Completed</b></span><br>
  <span style="color: red;">
    Order Cancelled
  </span>  
</div>
<div class="item item-14" style="margin-left: -40px;">
  <b><hr width="60px" color="pink"></b>
</div>
<div class="item item-14" style="margin-left: 15px;">
  <span>
    <img src="assets/img/remove.svg" alt="logo" data-src="assets/img/remove.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Cancelled</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderPlacedDateTime!=null" style="text-align: center;">
    {{orderTrackDetails.response.orderCancelledDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>  
</div>
  </div>


  <hr *ngIf="orderDetails.response.status!=='Cancelled'" class="beautiful-line">
  <h5 *ngIf="orderDetails.response.status!=='Cancelled'" style="margin-left: 15px; text-align: left; color: grey;">Order Track Info</h5>
  <br>
  <div *ngIf="orderDetails.response.status!=='Cancelled'"class="container"
  fxLayout
>
<div class="item item-1" style="margin-left: 20px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderPlaced==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderPlaced==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Placed</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderPlacedDateTime!=null" style="text-align: center;">
    {{orderTrackDetails.response.orderPlacedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderPlacedDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-2" style="margin-left: -80px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-3" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderAccepted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderAccepted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>    
  </span>
  <span style="margin-bottom: 10px;"><b>Agent Accepted</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderAcceptedDateTime!=null">
    {{orderTrackDetails.response.orderAcceptedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderAcceptedDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-4" style="margin-left: -50px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-5" style="margin-left: 15px;">
  <img *ngIf="orderTrackDetails.response.paymentCompleted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.paymentCompleted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>    
    <span style="margin-bottom: 10px;"><b>Payment Completed</b></span><br>
    <span *ngIf="orderTrackDetails.response.paymentCompletedDateTime!=null">
      {{orderTrackDetails.response.paymentCompletedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
    </span>
    <span *ngIf="orderTrackDetails.response.paymentCompletedDateTime==null">
      Will be Updated
    </span>    
</div>
<div class="item item-6" style="margin-left: -60px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-7" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderInitiated==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderInitiated==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Initiated</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderInitiatedDateTime!=null">
    {{orderTrackDetails.response.orderInitiatedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderInitiatedDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-8" style="margin-left: -40px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-9" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.refillOtp==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.refillOtp==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Refill OTP Verified</b></span><br>
  <span *ngIf="orderTrackDetails.response.refillDateTime!=null">
    {{orderTrackDetails.response.refillDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.refillDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-10" style="margin-left: -60px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-11" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.endOrderOtp==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.endOrderOtp==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>END Order OTP Verified</b></span><br>
  <span *ngIf="orderTrackDetails.response.endOrderDateTime!=null">
    {{orderTrackDetails.response.endOrderDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.endOrderDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-12" style="margin-left: -80px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-13" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderCompleted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderCompleted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Completed</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderCompletedDateTime!=null">
    {{orderTrackDetails.response.orderCompletedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderCompletedDateTime==null">
    Will be Updated
  </span>
</div>
  </div> 
  <br>
  <div *ngIf="orderDetails.response.status!=='Cancelled'"class="container"
  fxLayout
>
<div *ngIf="orderTrackDetails.response.refundFlag==1"class="item item-1" style="margin-left: 20px;">
  <span>
    <img *ngIf="orderTrackDetails.response.refundstatus==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.refundstatus==20" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.refundstatus==21" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <br>
  </span>
  <span style="margin-bottom: 10px;"><b>Refund Submitted Date & Time</b></span><br>
  <span *ngIf="orderTrackDetails.response.refundsubmittedDateTime!=null" style="text-align: center;">
    {{orderTrackDetails.response.refundsubmittedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.refundsubmittedDateTime==null">
    Will be Updated
  </span>
</div>
</div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
  </div>
</ng-template>

<ng-template #content1 let-modal>
  <div class="modal-header">
    <h5>Assign Vehicle</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="container"
  fxLayout
>

<div class="item item-1">
  <p><b>Order ID</b></p>
  <span>{{orderDetails.response.orderId}}</span>
</div>
<div class="item item-2" style="margin-left: 25px;">
  <p><b>Customer Name</b></p>
  <span style="word-wrap: break-word;">{{orderDetails.response.customerId.firstName}} {{orderDetails.response.customerId.lastName}}</span>
</div>
<div class="item item-3" style="margin-left: 25px;">
  <p><b>Customer Mobile</b></p>
  <span>{{orderDetails.response.customerId.mobileNumber}}</span>
</div>
<div class="item item-4" style="margin-left: 25px;">
  <p><b>Status</b></p>
  <span *ngIf="orderDetails.response.status==='Pending'"><span class="ht-Pending"><b>{{orderDetails.response.status}}</b></span></span>
  <span *ngIf="orderDetails.response.status==='Cancelled'"><span style="color: red;"><b>{{orderDetails.response.status}}</b></span></span>
  <span *ngIf="orderDetails.response.status==='Inprogress'"><span style="color: blue;"><b>{{orderDetails.response.status}}</b></span></span>
  <span *ngIf="orderDetails.response.status==='Completed'"><span class="ht-active"><b>{{orderDetails.response.status}}</b></span></span>
</div>
<div class="item item-5" style="margin-left: 25px;">
  <p><b>Booking Date & Time</b></p>
  <span>{{orderDetails.response.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span>
</div>
<div class="item item-6" style="margin-left: 25px;">
  <p><b>Delivery Date & Time</b></p>
  <span>N/A</span>
</div>
<div class="item item-7" style="margin-left: 25px;">
  <p><b>Fuel</b></p>
  <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.fuelType.fuelTypeName}}</span>
      <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
</div>
<div class="item item-8" style="margin-left: 25px;">
  <p><b>Quantity in Ltrs</b></p>
  <span >{{orderDetails.response.quantity}}</span>
</div>
<br>
  </div>
  <br>
  <div class="container"
  fxLayout
>
  <div class="item item-1">
    <p><b>Vehicle Number</b></p>
    <div class="ch-form-group">
    <select id="countryFormControl" value="countryFormControl" class="form-control" #mySelect (change)='onOptionsSelected(mySelect.value)'>
      <option *ngFor="let vehicle of activeVehicle.response" value={{vehicle.vehicleId}}>{{vehicle.vehicleNumber}} ({{vehicle.userId.firstName}} {{vehicle.userId.lastName}})</option>
    </select>
  </div>
  </div>
  <div class="item item-2" style="margin-left: 25px;">
    <p><b>Delivery Agent Mobile</b></p>
    <span *ngIf="check==null">
      <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.userId.firstName}} {{orderDetails.response.vehicleId.userId.lastName}}</span>
      <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
    </span>
    <span *ngIf="check!=null">{{vehicle.userId.mobileNumber}}</span>
  </div>
  <div class="item item-3" style="margin-left: 25px;">
    <p><b>Delivery Agent Email</b></p>
    <span *ngIf="check==null">
      <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.userId.mobileNumber}}</span>
          <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
    </span>
    <span *ngIf="check!=null">{{vehicle.userId.emailAddress}}</span>
  </div>
  <div class="item item-4" style="margin-left: 25px;">
    <p><b>Vehicle Number</b></p>
    <span *ngIf="check==null">{{orderDetails.response.vehicleId.vehicleNumber}}</span>
    <span *ngIf="check!=null">{{vehicle.vehicleNumber}}</span>
  </div>
  <div class="item item-5" style="margin-left: 25px;">
    <p><b>Delivery location</b></p>
    <span>{{orderDetails.response.deliveryAddress}}</span>
  </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="assign()" (click)="modal.close('Save click')">Assign</button>
  </div>
</ng-template>

<ng-template #content5 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Reason for cancellation of Order</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
  <form>
      <br>
      <div class="ch-form-group">
        <div class="dropdown" style="width: 450px; margin-left: -3px;">
          <select id="cancelmessage"  name="cancelmessage" class="form-control">
            <option *ngFor="let cancel of cancelMessageModel.response" value={{cancel.id}}>{{cancel.cancellationMessage}}</option>
          </select></div>
      </div>	
      <br>
      <div class="ch-form-group">
           <textarea placeholder="Should not exceed more than 150 characters" class="form-control textarea" name="cancelremarks" maxlength="150" id="cancelremarks" rows="3"></textarea>
      </div>
      <br>
    <b>Note</b> : <span style="color: grey;">Should not exceed more than 150 characters</span> 
      </form>
      <br>
      <div *ngIf="errorFlag1" style="color: red;">{{errorMessage}}</div>
    </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" style="color: green;" (click)="cancelOrder()">Submit</button>
  </div>
</ng-template>


<router-outlet></router-outlet>
