import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GenericResponse } from '../model/genricmodelresponse';
import { GenericResponseModel } from '../model/genricresponse';
import { RoleModel } from '../model/role';
import { RoleService } from '../services/role.service';
import { RoleMenuMappingService } from '../services/rolemenumapping.service';
import { UserManagementService } from '../services/usermanagement.service';

@Component({
  selector: 'app-role-menu-mapping-create',
  templateUrl: './role-menu-mapping-create.component.html',
  styleUrls: ['./role-menu-mapping-create.component.css']
})
export class RoleMenuMappingCreateComponent implements OnInit {
  // roleDetails:GenericResponseModel;
  roleDetails:RoleModel[];
  menuDetails:GenericResponse;
  resultText=[];
  values:string;  
 count:number=0; 
  constructor(private usermanagementservice:UserManagementService,private rolemasterservice:RoleService,private rolemenuservice:RoleMenuMappingService,private router: Router,) { }

  ngOnInit(): void {
    // this.rolemasterservice.getAllRoles().subscribe(data=>{
    //   this.roleDetails = data;
    // });

    this.usermanagementservice.getRoles().subscribe(data=>{
      this.roleDetails = data;
    });

    this.rolemenuservice.getMenusAndRoleList().subscribe(data=>{
      this.menuDetails = data;
    });
  }

  onChange(id:Number,event) {  
     const checked = event.target.checked;  
      if (checked) {  
        this.resultText.push(id);  
         } else {  
           const index = this.resultText.indexOf(id);  
           this.resultText.splice(index, 1);  
       }  
       this.values=this.resultText.toString();  
       const count=this.resultText.length;  
       this.count=count;  
    }  

    assignMenu(){
      if(this.resultText.length>0){
        let role = (<HTMLInputElement>document.getElementById('role')).value;
        if(role!=null&& role!==''){

          let formData= {
            roleId:Number(role),
            menuIds:this.values,
            userName:sessionStorage.getItem("username")
          }

          this.rolemenuservice.addmenu(formData).subscribe(data=>{
            if(data.response.flag==1){
              sessionStorage.setItem("menustatus",'1');
              sessionStorage.setItem("menumessage","Menu has been mapped against role");
              this.router.navigate(['/role-menu-mapping']);
            }
          },err => {
            if(err.error.statusCode==406){
              this.router.navigate(['/login']);
            }else if(err.error.statusCode==500){
              this.router.navigate(['/page-error']);
            }else if(err.error.statusCode==404){
              this.router.navigate(['/pagenotfound']);
            }
         });
        }else{

        }
      }else{

      }
    }
}
