import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { GenericResponseModel} from '../model/genricresponse';
import { environment } from 'src/environments/environment';
import { FuelRefillHistoryModel } from '../model/fuelrefill';
import { GenericResponse } from '../model/genricmodelresponse';
import { VehicleAttachModel } from '../model/vehicleModel';
import { RestResponseModel } from '../model/response';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: "root"
  })
  export class VehicleFuelService {

    constructor(private httpClient:HttpClient) {}

  private baseUrl = environment.devurl;
  private auth = environment.headers;

  public getVehicleList(start,end){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponse>(this.baseUrl+"/admins/getvehicle"+"/"+start+"/"+end,{headers});
  }

  public getVehicleLists(){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
   return this.httpClient.get<GenericResponse>(this.baseUrl+"/admins/getvehicleList",{headers});
 }

  public getVehicleByPayment(id){
      // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/admins/vehicleByPayment"+"/"+id,{headers});
  }

  public getVehicleByPaymentnew(id){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponse>(this.baseUrl+"/admins/vehicleByPayment"+"/"+id,{headers});
  }

  public getMarketPrice(){
      // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/admins/getMarketPrice",{headers});
  }

  public checkActivation(id){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponse>(this.baseUrl+"/admins/checkActivation"+"/"+id,{headers});
  }

  public getVehicleById(id){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<VehicleAttachModel>(this.baseUrl+"/admins/vehicleById"+"/"+id,{headers})
  }

  public activateVehicle(vehicle){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.post<GenericResponse>(this.baseUrl+"/vehicleactivation/add",vehicle,{headers})
  }

  public getVehicleApiKey(id){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<RestResponseModel>(this.baseUrl+"/vehicleactivation/getVehicleApiKey"+"/"+id,{headers})
  }

  public getVehicleImage(id,flag): Observable<Blob>{
      // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<any>(this.baseUrl+"/admins/vehicleImage"+"/"+id+"/"+flag,{headers,responseType: 'Blob' as 'json'});
  }

  public getvehicleActive(){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/admins/getvehicleActive",{headers});
  }

  public getvehicleInactive(){
      // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/admins/getvehicleInactive",{headers});
  }

  public getDSA(){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/admins/getdsa",{headers});
  }

  public assinDSA(vehicleId,dsaId){
      // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/admins/assigndsa"+"/"+vehicleId+"/"+dsaId,{headers});
  }

  public vehicleonoffstatus(id,status,submittedBy){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/admins/vehicleonoffstatus"+"/"+id+"/"+status+"/"+submittedBy,{headers});
  }

  public vehicleactiveinactivestatus(id,status,submittedBy){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
   return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/admins/vehicleactiveinactivestatus"+"/"+id+"/"+status+"/"+submittedBy,{headers});
 }


  public getOrderByVehicle(id,flag){
      // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/admins/orderbyvehicle"+"/"+id+"/"+flag,{headers});
  }


}