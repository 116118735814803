import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GenericResponseModel } from '../model/genricresponse';

@Injectable({
    providedIn: "root"
  })
  export class BankSettlementService {

    constructor(private httpClient:HttpClient) {}

  private baseUrl = environment.devurl;
  private auth = environment.headers;

    public getAllCustomerBankSettlement(){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/bankApproval/customersettlement",{headers});
  }

  public getAllPartnersettlement(){
   // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/bankApproval/partnersettlement",{headers});
}

    public bankapproval(id,bank){
      // const headers = this.auth;
      let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
      const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.put<GenericResponseModel>(this.baseUrl+"/bankApproval/approve"+"/"+id,bank,{headers});
    }

    public getCustomerandPartner(role,status){
      // const headers = this.auth;
      let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
      const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/bankApproval/customerpartnerfilter"+"/"+role+"/"+status,{headers});
  } 

  public getCustomerandPartnerDateRange(role,start,end){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/bankApproval/customerpartnerfilterdaterange"+"/"+role+"/"+start+"/"+end,{headers});
} 

  }