import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericResponse } from '../model/genricmodelresponse';
import { GenericResponseModel } from '../model/genricresponse';
import { RoleMenuMappingService } from '../services/rolemenumapping.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  imageToShow: any;
  name : string;
  userId : Number;
  isImageLoading: boolean;
  roleId:Number;
  adminmenurole:GenericResponseModel;
  addUser:boolean = false;
  rolemenu:boolean = false;
  customer:boolean = false;
  partner:boolean = false;
  agent:boolean = false;
  vehiclekyc:boolean = false;
  vehiclebank:boolean = false;
  activatevehicle:boolean = false;
  partnerbank:boolean = false;
  customerbank:boolean = false;
  partnersettlement:boolean = false;
  readytosettle:boolean = false;
  reveneureport:boolean = false;
  settledreport:boolean = false;
  stokreport:boolean = false;
  refundreport:boolean = false;
  kycdocument:boolean = false;
  bankmaster:boolean = false;
  fueltypemaster:boolean = false;
  fuelprisemaster:boolean = false;
  charges:boolean = false;
  coupon:boolean = false;
  promotions:boolean = false;
  hsncode:boolean = false;
  constructor(private route: ActivatedRoute,private router : Router,private userservice:UserService,private rolemenuservice:RoleMenuMappingService) { }

  ngOnInit(): void {
    this.name = sessionStorage.getItem('username');
    this.userId = Number(sessionStorage.getItem('id'));

    let adminrole = Number(sessionStorage.getItem("adminrole"));
    this.roleId = adminrole;

    this.rolemenuservice.getAssignedMenuByRoleAdmin(this.roleId).subscribe(data=>{
      this.adminmenurole = data;
      data.response.forEach(element => {
        let menuId = element['menuId'];
        let menuname = menuId['menuName'];
        if(menuname!=null && menuname!==''){
          if(menuname=='Add User'){
            this.addUser = true;
          }else if(menuname=='Role Menu Mapping'){
            this.rolemenu = true;
          }else if(menuname=='Customers'){
            this.customer = true;
          }else if(menuname=='Partners'){
            this.partner = true;
          }else if(menuname=='Agents'){
            this.agent = true;
          }else if(menuname=='Vehicle Kyc'){
            this.vehiclekyc = true;
          }else if(menuname=='Vehicle Bank'){
            this.vehiclebank = true;
          }else if(menuname=='Activate Vehicle'){
            this.activatevehicle = true;
          }else if(menuname=='Partner Bank'){
            this.partnerbank = true;
          }else if(menuname=='Customer Bank'){
            this.customerbank = true;
          }else if(menuname=='Partner Settlements'){
            this.partnersettlement = true;
          }else if(menuname=='Ready To Settle'){
            this.readytosettle = true;
          }else if(menuname=='Revenue Report'){
            this.reveneureport = true;
          }else if(menuname=='Settled Report'){
            this.settledreport = true;
          }else if(menuname=='Stock Report'){
            this.stokreport = true;
          }else if(menuname=='Refund Report'){
            this.refundreport = true;
          }else if(menuname=='Kyc Document Master'){
            this.kycdocument = true;
          }else if(menuname=='Bank Master'){
            this.bankmaster = true;
          }else if(menuname=='Fuel Type Master'){
            this.fueltypemaster = true;
          }else if(menuname=='Fuel Price Reset History'){
            this.fuelprisemaster = true;
          }else if(menuname=='Charges Configuration'){
            this.charges = true;
          }else if(menuname=='Coupons'){
            this.coupon = true;
          }else if(menuname=='Promotions'){
            this.promotions = true;
          }else if(menuname=='HSN Code Master'){
            this.hsncode = true;
          }
        }
    });
    });
  }

  logout(){
    localStorage.setItem('isLoggedIn', "false");
    sessionStorage.removeItem("jwttoken");
    this.router.navigate(['/login']);
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
       this.imageToShow = reader.result;
    }, false);
  
    if (image) {
       reader.readAsDataURL(image);
    }
  }

}
