<app-header></app-header>
<ol class="breadcrumb breadcrumb-alt" style="margin-top:100px; width: auto;" >
  <li class="breadcrumb-item" style="margin-left: 15px;"><a href="#" routerLink="/dashboard">Home</a></li>
  <li class="breadcrumb-item active">VEHICLE KYC</li>
</ol>
<div class="container  container-top-dashboard-section">
  <!-- <a href="#" routerLink="/partners-kyc" class="back_page"><span class="label label-info"><i class="back_page"></i>Back</span></a> -->
   <br>
   <div class="alert alert-danger" role="alert"
  *ngIf="poppupalert">Please Enter comments!</div>
  <div class="alert alert-success" role="alert"
  *ngIf="kycalert">{{kycresponseString}}</div>
   <!-- <div class="card mb-3" style="margin-top: 20px; " #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
   <div class="flex">
      <div class="col1 flex">
         <div class=" profile images flex">
            <img class="modal-content"  [src]="imageToShowPartner">
         </div>
         <div class="profile-info flex">
            <div class="card-body profile">
              <p class="card-title">Partners Name <br><span><b>
                {{usermodel.firstName}} {{usermodel.lastName}}
              </b></span></p>
               
            </div>
             <div class="card-body profile">
               <p class="card-title"><i class="fa fa-mobile" aria-hidden="true"></i> Mobile
                  <br><span><b>
                    {{usermodel.mobileNumber}}
                  </b></span>
               </p>
               <p class="card-title"><i class="fa fa-envelope" aria-hidden="true"></i> Email
                  <br><span><b>
                    {{usermodel.emailAddress}}
                  </b></span>
               </p>
            </div>

            <div class="card-body profile">
				<p class="card-title">Onboarded Date & Time  <br><span><b>
          {{usermodel.createdDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}
        </b></span></p>

        <p class="card-title">Submitted Date & Time  <br><span><b>
          {{usermodel.submittedDate | date:'dd/MM/yyyy hh:mm:ss a'}}
        </b></span></p>
            </div>
         </div>
      </div>
   </div>
</div> -->

<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
  <!-- <div class="btn-section-wrapper">
    <button type="button" class="btn btn-success" (click)="open(content)">Approve</button>
    <button type="button" class="btn btn-danger" (click)="open1(reject)">Reject</button>
    <button type="button" class="btn btn-icon-link" (click)="upload(content3)">Upload Documents</button>
</div> -->
  <li [ngbNavItem]="1" >
    <!-- <a ngbNavLink>Vehicles</a>  -->
    <div *ngIf="!filterflag"style="display: none;">{{vehicleResponse.responseMessage}}</div>
    <div *ngIf="filterflag && !filteronesearch"style="display: none;">{{vehicleResponseFilter.responseMessage}}</div>
    <div *ngIf="filterflag && filteronesearch"style="display: none;">{{vehicleResponseFilterNoRecordFound.responseMessage}}</div>
    <ng-template ngbNavContent>
      <br>
      <select class="select-form-style" style="margin-left: 20px;" id="searchBy">  
        <option value="2">Search By Date Range</option>  
    </select> 
    <input type="date" style="margin-left: 10px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
    <input type="date" style="margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
  <button style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
  <button *ngIf="refreshFlag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
  <div *ngIf="errorFlag" style="color: red; margin-left: 20px;">{{errorMessage}}</div>
      <div *ngIf="allkyc" class="card-body">
              <div *ngIf="!filterflag"class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr>
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Vehicle Number</th>
                      <th style="text-align: left;">Partner Name</th>
                      <th style="text-align: left;">Owner Name</th>
                      <th style="text-align: left;">Model Name</th>
                      <th style="text-align: left;">Fuel</th>
                      <th style="text-align: center;">Tank Capacity in Ltrs</th>
                      <th style="text-align: center;">Available Fuel in litre</th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: left;">Created Date & Time</th>
                      <th style="text-align: left;">KYC Submitted Date & Time</th>
                      <th style="text-align: center;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let vehicle of vehicleResponse.response.vehicleList; let i=index">
                      <td align="center" class="v-align-middle">{{i + 1}}</td>
                      <td *ngIf="vehicle.vehicleNumber!=null"align="left" class="v-align-middle">{{vehicle.vehicleNumber}}</td>
                      <td *ngIf="vehicle.vehicleNumber==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.userId!=null"align="left" style="word-wrap: break-word;" class="v-align-middle">{{vehicle.userId.firstName}} {{vehicle.userId.lastName}}</td>
                      <td *ngIf="vehicle.userId==null"align="left" class="v-align-middle">N/A</td>
                     
                      <td *ngIf="vehicle.vehicleOwnerName!=null"align="left" style="word-wrap: break-word;" class="v-align-middle">{{vehicle.vehicleOwnerName}} </td>
                      <td *ngIf="vehicle.vehicleOwnerName==null"align="left" class="v-align-middle">N/A</td>


                      <td *ngIf="vehicle.vehicleName!=null"align="left" class="v-align-middle">{{vehicle.vehicleName}}</td>
                      <td *ngIf="vehicle.vehicleName==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.fuelType.fuelTypeName!=null"align="left" class="v-align-middle">{{vehicle.fuelType.fuelTypeName}}</td>
                      <td *ngIf="vehicle.fuelType==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.tankCapacity!=null"align="center" class="v-align-middle ">{{vehicle.tankCapacity}}</td>
                      <td *ngIf="vehicle.tankCapacity==null"align="center" class="v-align-middle ">N/A</td>
                      <td *ngIf="vehicle.fuelStock!=null"align="center" class="v-align-middle ">{{vehicle.fuelStock}}</td>
                      <td *ngIf="vehicle.fuelStock==null"align="center" class="v-align-middle ">N/A</td>
                      <td *ngIf="vehicle.status!=null && vehicle.status=='Pending'"  class="v-align-middle ht-Pending">{{vehicle.status}}</td>
                      <td *ngIf="vehicle.status!=null && vehicle.status=='Approved'"  class="v-align-middle ht-active">{{vehicle.status}}</td>
                      <td *ngIf="vehicle.status!=null && vehicle.status=='Rejected'"  style="color: red;">{{vehicle.status}}</td>
                      <td *ngIf="vehicle.status==null">N/A</td>
                      <td *ngIf="vehicle.submittedDatetime!=null"align="left" class="v-align-middle">{{vehicle.submittedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="vehicle.submittedDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="vehicle.kycSubmittedDate!=null"align="left" class="v-align-middle">{{vehicle.kycSubmittedDate | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="vehicle.kycSubmittedDate==null"align="left" class="v-align-middle">N/A</td>
                      <td  align="center" class="v-align-middle"><button title="View" class="label label-info" (click)="view(vehicle.vehicleId)"><i class="fa fa-eye" aria-hidden="true"></i></button></td>
                    </tr>
                  </tbody>
                </table>
          </div>
          <div *ngIf="filterflag && !filteronesearch"class="table-responsive">
            <table datatable class="ch-table">
              <thead>
                <tr>
                  <th style="text-align: center;">Sl.No.</th>
                  <th style="text-align: left;">Vehicle Number</th>
                  <th style="text-align: left;">Partner Name</th>
                  <th style="text-align: left;">Owner Name</th>
                  <th style="text-align: left;">Model Name</th>
                  <th style="text-align: left;">Fuel</th>
                  <th style="text-align: center;">Tank Capacity in Ltrs</th>
                  <th style="text-align: center;">Available Fuel in litre</th>
                  <th style="text-align: center;">Status</th>
                  <th style="text-align: left;">Created Date & Time</th>
                  <th style="text-align: left;">KYC Submitted Date & Time</th>
                  <th style="text-align: center;">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let vehicle of vehicleResponseFilter.response.vehicleList; let i=index">
                  <td align="center" class="v-align-middle">{{i + 1}}</td>
                  <td *ngIf="vehicle.vehicleNumber!=null"align="left" class="v-align-middle">{{vehicle.vehicleNumber}}</td>
                  <td *ngIf="vehicle.vehicleNumber==null"align="left" class="v-align-middle">N/A</td>
                  <td *ngIf="vehicle.userId!=null"align="left" style="word-wrap: break-word;" class="v-align-middle">{{vehicle.userId.firstName}} {{vehicle.userId.lastName}}</td>
                  <td *ngIf="vehicle.userId==null"align="left" class="v-align-middle">N/A</td>
                  <td *ngIf="vehicle.vehicleOwnerName!=null"align="left" style="word-wrap: break-word;" class="v-align-middle">{{vehicle.vehicleOwnerName}} </td>
                  <td *ngIf="vehicle.vehicleOwnerName==null"align="left" class="v-align-middle">N/A</td>

                  <td *ngIf="vehicle.vehicleName!=null"align="left" class="v-align-middle">{{vehicle.vehicleName}}</td>
                  <td *ngIf="vehicle.vehicleName==null"align="left" class="v-align-middle">N/A</td>
                  <td *ngIf="vehicle.fuelType.fuelTypeName!=null"align="left" class="v-align-middle">{{vehicle.fuelType.fuelTypeName}}</td>
                  <td *ngIf="vehicle.fuelType==null"align="left" class="v-align-middle">N/A</td>
                  <td *ngIf="vehicle.tankCapacity!=null"align="center" class="v-align-middle ">{{vehicle.tankCapacity}}</td>
                  <td *ngIf="vehicle.tankCapacity==null"align="center" class="v-align-middle ">N/A</td>
                  <td *ngIf="vehicle.fuelStock!=null"align="center" class="v-align-middle ">{{vehicle.fuelStock}}</td>
                  <td *ngIf="vehicle.fuelStock==null"align="center" class="v-align-middle ">N/A</td>
                  <td *ngIf="vehicle.status!=null && vehicle.status=='Pending'"  class="v-align-middle ht-Pending">{{vehicle.status}}</td>
                  <td *ngIf="vehicle.status!=null && vehicle.status=='Approved'"  class="v-align-middle ht-active">{{vehicle.status}}</td>
                  <td *ngIf="vehicle.status!=null && vehicle.status=='Rejected'"  style="color: red;">{{vehicle.status}}</td>
                  <td *ngIf="vehicle.status==null">N/A</td>
                  <td *ngIf="vehicle.submittedDatetime!=null"align="left" class="v-align-middle">{{vehicle.submittedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                  <td *ngIf="vehicle.submittedDatetime==null"align="left" class="v-align-middle">N/A</td>
                  <td *ngIf="vehicle.kycSubmittedDate!=null"align="left" class="v-align-middle">{{vehicle.kycSubmittedDate | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                  <td *ngIf="vehicle.kycSubmittedDate==null"align="left" class="v-align-middle">N/A</td>
                  <td  align="center" class="v-align-middle"><button title="View" class="label label-info" (click)="view(vehicle.vehicleId)"><i class="fa fa-eye" aria-hidden="true"></i></button></td>
                </tr>
              </tbody>
            </table>
      </div>
      <div *ngIf="filterflag && filteronesearch"class="table-responsive">
        <table datatable class="ch-table">
          <thead>
            <tr>
              <th style="text-align: center;">Sl.No.</th>
              <th style="text-align: left;">Vehicle Number</th>
              <th style="text-align: left;">Partner Name</th>
              <th style="text-align: left;">Owner Name</th>
              <th style="text-align: left;">Model Name</th>
              <th style="text-align: left;">Fuel</th>
              <th style="text-align: center;">Tank Capacity in Ltrs</th>
              <th style="text-align: center;">Available Fuel in litre</th>
              <th style="text-align: center;">Status</th>
              <th style="text-align: left;">Created Date & Time</th>
              <th style="text-align: left;">KYC Submitted Date & Time</th>
              <th style="text-align: center;">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let vehicle of vehicleResponseFilterNoRecordFound.response.vehicleList; let i=index">
              <td align="center" class="v-align-middle">{{i + 1}}</td>
              <td *ngIf="vehicle.vehicleNumber!=null"align="left" class="v-align-middle">{{vehicle.vehicleNumber}}</td>
              <td *ngIf="vehicle.vehicleNumber==null"align="left" class="v-align-middle">N/A</td>
              <td *ngIf="vehicle.userId!=null"align="left" style="word-wrap: break-word;" class="v-align-middle">{{vehicle.userId.firstName}} {{vehicle.userId.lastName}}</td>
              <td *ngIf="vehicle.userId==null"align="left" class="v-align-middle">N/A</td>
              <td *ngIf="vehicle.vehicleOwnerName!=null"align="left" style="word-wrap: break-word;" class="v-align-middle">{{vehicle.vehicleOwnerName}} </td>
              <td *ngIf="vehicle.vehicleOwnerName==null"align="left" class="v-align-middle">N/A</td>

              <td *ngIf="vehicle.vehicleName!=null"align="left" class="v-align-middle">{{vehicle.vehicleName}}</td>
              <td *ngIf="vehicle.vehicleName==null"align="left" class="v-align-middle">N/A</td>
              <td *ngIf="vehicle.fuelType.fuelTypeName!=null"align="left" class="v-align-middle">{{vehicle.fuelType.fuelTypeName}}</td>
              <td *ngIf="vehicle.fuelType==null"align="left" class="v-align-middle">N/A</td>
              <td *ngIf="vehicle.tankCapacity!=null"align="center" class="v-align-middle ">{{vehicle.tankCapacity}}</td>
              <td *ngIf="vehicle.tankCapacity==null"align="center" class="v-align-middle ">N/A</td>
              <td *ngIf="vehicle.fuelStock!=null"align="center" class="v-align-middle ">{{vehicle.fuelStock}}</td>
              <td *ngIf="vehicle.fuelStock==null"align="center" class="v-align-middle ">N/A</td>
              <td *ngIf="vehicle.status!=null && vehicle.status=='Pending'"  class="v-align-middle ht-Pending">{{vehicle.status}}</td>
              <td *ngIf="vehicle.status!=null && vehicle.status=='Approved'"  class="v-align-middle ht-active">{{vehicle.status}}</td>
              <td *ngIf="vehicle.status!=null && vehicle.status=='Rejected'"  style="color: red;">{{vehicle.status}}</td>
              <td *ngIf="vehicle.status==null">N/A</td>
              <td *ngIf="vehicle.submittedDatetime!=null"align="left" class="v-align-middle">{{vehicle.submittedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
              <td *ngIf="vehicle.submittedDatetime==null"align="left" class="v-align-middle">N/A</td>
              <td *ngIf="vehicle.kycSubmittedDate!=null"align="left" class="v-align-middle">{{vehicle.kycSubmittedDate | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
              <td *ngIf="vehicle.kycSubmittedDate==null"align="left" class="v-align-middle">N/A</td>
              <td  align="center" class="v-align-middle"><button title="View" class="label label-info" (click)="view(vehicle.vehicleId)"><i class="fa fa-eye" aria-hidden="true"></i></button></td>
            </tr>
          </tbody>
        </table>
  </div>
        </div>

        <!-- --- Pending Kyc ----  -->
        <div *ngIf="pendingkyc" class="card-body">
          <div style="display: none;">{{kycdocumentmodelPending.responseMessage}}</div>
          <div class="table-responsive">
            <table datatable class="ch-table">
              <thead>
                <tr>
                  <th align="center">
                    <!-- <input type="checkbox" id="checkUncheckAll" name="checkUncheckAll" (change)="onSelectAll($event)"/>  -->
                    <!-- <input type="checkbox" id="checkUncheckAll" (click)="CheckUncheckAll($event)"/> -->
                    </th> 
                  <th align="center">Sl.No.</th>
                  <th align="center">Vehicle Number</th>
                  <th align="center" >KYC Name</th>
                  <th align="center" >Approval Status</th>
                  <th align="center" >Approved By</th>
                  <th align="center" >Comments</th>
                  <th align="center" >PDF / Images</th>
                  <th align="center" >Location</th>
                  <th align="center" ><span>Submitted</span> Date Time</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let kyc of kycdocumentmodelPending.response; let i=index">
                  <td class="v-align-middle "><span>
                    <input *ngIf="kyc.approvalStatus==10" type="checkbox" value=" {{kyc.id}}" (change)="onChange(kyc.id,$event)">
                  </span></td>
                  <td align="center" class="v-align-middle">{{i + 1}}</td>
                  <td *ngIf="kyc.vehicleId!=null && kyc.vehicleId.vehicleNumber!=null" align="center" class="v-align-middle">{{kyc.vehicleId.vehicleNumber}}</td>
                  <td *ngIf="kyc.vehicleId==null" align="center" class="v-align-middle">N/A</td>
                  <td align="center" class="v-align-middle">{{kyc.kycId.kycName}}</td>
                  <td align="center" *ngIf="kyc.approvalStatus==10" class="v-align-middle ht-Pending">Pending</td>
                  <td align="center" *ngIf="kyc.approvalStatus==11" class="v-align-middle ht-active">Approved</td>
                  <td align="center" *ngIf="kyc.approvalStatus==12" class="v-align-middle ht-inactive">Rejected</td>
                  <td *ngIf="kyc.approvalBy!=null"align="center" class="v-align-middle">{{kyc.approvalBy}}</td>
                  <td *ngIf="kyc.approvalBy==null"align="center" class="v-align-middle">N/A</td>
                  <td *ngIf="kyc.approvalComments!=null"align="center" class="v-align-middle">{{kyc.approvalComments}}</td>
                  <td *ngIf="kyc.approvalComments==null"align="center" class="v-align-middle">N/A</td>
                  <td align="center" class="v-align-middle">
                    <button type="button" class="fa fa-file-pdf-o" (click)="open2(content1)" (click)="kycdocument(kyc.id)">
                      <span aria-hidden="true"></span>
                    </button>
                  </td>
                  <td align="center" class="v-align-middle">
                    <button type="button" (click)="location(kyc.latitude,kyc.longitude)" (click)="open3(content2)">
                      <span aria-hidden="true"></span>
                      location
                    </button>
                  </td>
                  <td align="center" class="v-align-middle"><span class="v-align-middle ">{{kyc.createdDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span></td>
                </tr>
              </tbody>
            </table>
      </div>
    </div>

                <!-- --- Approve Kyc ----  -->
        <div *ngIf="approvekyc" class="card-body">
          <div style="display: none;">{{kycdocumentmodelApprove.responseMessage}}</div>
          <div class="table-responsive">
            <table datatable class="ch-table">
              <thead>
                <tr>
                  <th align="center">
                    <!-- <input type="checkbox" id="checkUncheckAll" name="checkUncheckAll" (change)="onSelectAll($event)"/>  -->
                    <!-- <input type="checkbox" id="checkUncheckAll" (click)="CheckUncheckAll($event)"/> -->
                    </th> 
                  <th align="center">Sl.No.</th>
                  <th align="center">Vehicle Number</th>
                  <th align="center" >KYC Name</th>
                  <th align="center" >Approval Status</th>
                  <th align="center" >Approved By</th>
                  <th align="center" >Comments</th>
                  <th align="center" >PDF / Images</th>
                  <th align="center" >Location</th>
                  <th align="center" ><span>Submitted</span> Date Time</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let kyc of kycdocumentmodelApprove.response; let i=index">
                  <td class="v-align-middle "><span>
                    <input *ngIf="kyc.approvalStatus==10" type="checkbox" value=" {{kyc.id}}" (change)="onChange(kyc.id,$event)">
                  </span></td>
                  <td align="center" class="v-align-middle">{{i + 1}}</td>
                  <td *ngIf="kyc.vehicleId!=null && kyc.vehicleId.vehicleNumber!=null" align="center" class="v-align-middle">{{kyc.vehicleId.vehicleNumber}}</td>
                  <td *ngIf="kyc.vehicleId==null" align="center" class="v-align-middle">N/A</td>
                  <td align="center" class="v-align-middle">{{kyc.kycId.kycName}}</td>
                  <td align="center" *ngIf="kyc.approvalStatus==10" class="v-align-middle ht-Pending">Pending</td>
                  <td align="center" *ngIf="kyc.approvalStatus==11" class="v-align-middle ht-active">Approved</td>
                  <td align="center" *ngIf="kyc.approvalStatus==12" class="v-align-middle ht-inactive">Rejected</td>
                  <td *ngIf="kyc.approvalBy!=null"align="center" class="v-align-middle">{{kyc.approvalBy}}</td>
                  <td *ngIf="kyc.approvalBy==null"align="center" class="v-align-middle">N/A</td>
                  <td *ngIf="kyc.approvalComments!=null"align="center" class="v-align-middle">{{kyc.approvalComments}}</td>
                  <td *ngIf="kyc.approvalComments==null"align="center" class="v-align-middle">N/A</td>
                  <td align="center" class="v-align-middle">
                    <button type="button" class="fa fa-file-pdf-o" (click)="open2(content1)" (click)="kycdocument(kyc.id)">
                      <span aria-hidden="true"></span>
                    </button>
                  </td>
                  <td align="center" class="v-align-middle">
                    <button type="button" (click)="location(kyc.latitude,kyc.longitude)" (click)="open3(content2)">
                      <span aria-hidden="true"></span>
                      location
                    </button>
                  </td>
                  <td align="center" class="v-align-middle"><span class="v-align-middle ">{{kyc.createdDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span></td>
                </tr>
              </tbody>
            </table>
      </div>
    </div>

        <!-- --- Reject Kyc ----  -->
        <div *ngIf="rejectkyc" class="card-body">
          <div style="display: none;">{{kycdocumentmodelReject.responseMessage}}</div>
          <div class="table-responsive">
            <table datatable class="ch-table">
              <thead>
                <tr>
                  <th align="center">
                    <!-- <input type="checkbox" id="checkUncheckAll" name="checkUncheckAll" (change)="onSelectAll($event)"/>  -->
                    <!-- <input type="checkbox" id="checkUncheckAll" (click)="CheckUncheckAll($event)"/> -->
                    </th> 
                  <th align="center">Sl.No.</th>
                  <th align="center">Vehicle Number</th>
                  <th align="center" >KYC Name</th>
                  <th align="center" >Approval Status</th>
                  <th align="center" >Approved By</th>
                  <th align="center" >Comments</th>
                  <th align="center" >PDF / Images</th>
                  <th align="center" >Location</th>
                  <th align="center" ><span>Submitted</span> Date Time</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let kyc of kycdocumentmodelReject.response; let i=index">
                  <td class="v-align-middle "><span>
                    <input *ngIf="kyc.approvalStatus==10" type="checkbox" value=" {{kyc.id}}" (change)="onChange(kyc.id,$event)">
                  </span></td>
                  <td align="center" class="v-align-middle">{{i + 1}}</td>
                  <td *ngIf="kyc.vehicleId!=null && kyc.vehicleId.vehicleNumber!=null" align="center" class="v-align-middle">{{kyc.vehicleId.vehicleNumber}}</td>
                  <td *ngIf="kyc.vehicleId==null" align="center" class="v-align-middle">N/A</td>
                  <td align="center" class="v-align-middle">{{kyc.kycId.kycName}}</td>
                  <td align="center" *ngIf="kyc.approvalStatus==10" class="v-align-middle ht-Pending">Pending</td>
                  <td align="center" *ngIf="kyc.approvalStatus==11" class="v-align-middle ht-active">Approved</td>
                  <td align="center" *ngIf="kyc.approvalStatus==12" class="v-align-middle ht-inactive">Rejected</td>
                  <td *ngIf="kyc.approvalBy!=null"align="center" class="v-align-middle">{{kyc.approvalBy}}</td>
                  <td *ngIf="kyc.approvalBy==null"align="center" class="v-align-middle">N/A</td>
                  <td *ngIf="kyc.approvalComments!=null"align="center" class="v-align-middle">{{kyc.approvalComments}}</td>
                  <td *ngIf="kyc.approvalComments==null"align="center" class="v-align-middle">N/A</td>
                  <td align="center" class="v-align-middle">
                    <button type="button" class="fa fa-file-pdf-o" (click)="open2(content1)" (click)="kycdocument(kyc.id)">
                      <span aria-hidden="true"></span>
                    </button>
                  </td>
                  <td align="center" class="v-align-middle">
                    <button type="button" (click)="location(kyc.latitude,kyc.longitude)" (click)="open3(content2)">
                      <span aria-hidden="true"></span>
                      location
                    </button>
                  </td>
                  <td align="center" class="v-align-middle"><span class="v-align-middle ">{{kyc.createdDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span></td>
                </tr>
              </tbody>
            </table>
      </div>
    </div>
       
    </ng-template>
  </li>
  <!-- <li [ngbNavItem]="2" >
    <a ngbNavLink>Products</a>
    <ng-template ngbNavContent>
      <div class="card-body">
              <div class="table-responsive">
                <table class="table table-hover" id="basicTable">
                  <thead>
                    <tr> 
                      <th align="center" style="width:80px">Sl.No.</th>
                      <th align="left"  style="width:100px 
">Account Holder Name</th>
                      <th align="left" style="width:220"><span style="width:100px 
">Account</span> Number</th>
                      <th align="center" style="width:250"><span style="width:100px 
">Account</span> Type</th>
                      <th align="center" style="width:150">Bank name</th>
                      <th align="center" style="width:150 text-align:center;">Branch Name</th>
                      <th align="center" style="">IFSC Code</th>
                      <th align="center" style="width:180"><span style="width:20%">Approved </span> Date &amp; Time</th>
                      <th align="center" style="150">Starus</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td align="center" class="v-align-middle "><p>1</p></td>
                      <td align="left" class="v-align-middle"><p>Anbazhagan B </p></td>
                      <td class="v-align-middle"><p>87938750237530</p></td>
                      <td class="v-align-middle">Saving</td>
                      <td align="center" class="v-align-middle">HDFC Bank</td>
                      <td align="center" class="v-align-middle">Thambaram</td>
                      <td class="v-align-middle">HDFC548</td>
                      <td class="v-align-middle">29/09/2020<br> 01:00 pm</td>
                      <td class="v-align-middle ht-Success">Approved</td>
                    </tr>
                  </tbody>
                </table>
          </div>
        </div>
    </ng-template>
  </li> -->
  
  
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Do you want approve the Documents?</label>
           <textarea placeholder="comments" class="form-control textarea" name="kyctextarea" id="kyctextarea" rows="3"></textarea>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="approve(1)" (click)="modal.close('Save click')">yes</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">no</button>
  </div>
</ng-template>

<ng-template #reject let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Do you want reject the Documents?</label>
           <textarea placeholder="comments" class="form-control textarea" name="kyctextrejectarea" id="kyctextrejectarea" rows="3"></textarea>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="approve(2)" (click)="modal.close('Save click')">yes</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">no</button>
  </div>
</ng-template>

<ng-template #content1 let-modal>
  <img class="modal-content"  [src]="imageToShow" width="auto" height="auto" alt="Place image title" *ngIf="!isImageLoading; else noImageFound">
  <img class="modal-content"  [src]="imageToShowkyc" width="auto" height="auto" alt="Place image title" *ngIf="!isImageLoading1; else noImageFound">
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">close</button>
  </div>
</ng-template>

<ng-template #content2 let-modal>
  <agm-map [latitude]="lat" [longitude]="lan" style="height: 300px">
    <agm-marker [latitude]="lat" [longitude]="lan"></agm-marker>
  </agm-map>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">close</button>
  </div>
</ng-template>

<ng-template #content3 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Upload KYC</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">&times;</span> </button>
  </div>
  <div class="modal-body master-body">
  <form [formGroup]="myForm">
      <div class="ch-form-group">
        <label><b>KYC Document</b></label>
        <span class="required-asterisk"> *</span>
        <div class="dropdown">
          <select id="kycdocument"  name="kycdocument" class="form-control">
            <option *ngFor="let kyc of kycpendinglist.response" value={{kyc.kycId}}>{{kyc.kycName}}</option>
          </select>
</div>
      </div>
      <br>
      <div class="form-group">
        <input #imageInput
        id="kycfile"
        name="kycfile"
         type="file"
         accept=".pdf,.jpeg,.jpg"
         data-max-size="2048"
         (change)="selectFile($event)">
    </div>
    <b>Note</b> : Files can be .Jpeg and .pdf format which should not exceed more than 2 MB
      </form>
      <br>
      <div *ngIf="emptyCheck" style="color: red;">{{emptyCheckResponse}}</div>
    </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" style="color: green;" (click)="uploadKyc()">Submit</button>
    <button type="button" class="btn btn-outline-dark" style="color: red;" (click)="modal.close('Save click')">Cancel</button>
  </div>
</ng-template>
