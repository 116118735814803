import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { data } from 'jquery';
import { UserModel } from '../model/user';
import { UserService } from '../services/user.service';


@Component({
  selector: 'app-user-management-view',
  templateUrl: './user-management-view.component.html',
  styleUrls: ['./user-management-view.component.css']
})
export class UserManagementViewComponent  {

  public isCollapsed = false;
  active = 1;
  userModel:UserModel;
  isImageLoading: boolean;
  imageToShow: any;

  constructor(private userservice:UserService,private router:Router) { }

  ngOnInit(): void {
    let userId = Number(sessionStorage.getItem('id'));

    this.userservice.getUserImage(userId).subscribe(data=>{
      this.isImageLoading = true;
      this.createImageFromBlob(data);
      this.isImageLoading = false;
    },err => {
      console.log(err.error.statusCode);
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }
   });  

    this.userservice.getUserInfor(userId).subscribe(data=>{
      this.userModel = data;
    });

  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
       this.imageToShow = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
   }
  }

}
