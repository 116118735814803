import {Injectable, Type} from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { UserModel } from '../model/user';
import { environment } from 'src/environments/environment';
import { RestResponseModel } from '../model/response';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: "root"
  })
  export class UserService {

    constructor(private httpClient:HttpClient) {}

  private baseUrl = environment.devurl;

  private auth = environment.headers;

  public login(login){
    // const headers = this.auth;
    const headers= new HttpHeaders().set("Authorization",'Basic ' + btoa("cinchfuel" + ':' + "cinch@2020"));
    return this.httpClient.post<UserModel>(this.baseUrl+"/adminlogin/web", login, {headers});
    // return this.httpClient.post<UserModel>(this.baseUrl+"/adminlogin/web", login);
  }

  public activeOrInactive(id,status,username){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<RestResponseModel>(this.baseUrl+"/adminlogin/accountstatus"+"/"+id+"/"+status+"/"+username,{headers});
  }

  public getUserImage(id): Observable<Blob>{
      // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<any>(this.baseUrl+"/profile/profilephoto"+"/"+id,{headers,responseType: 'Blob' as 'json'});
  }

  public getUserInfor(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<UserModel>(this.baseUrl+"/profile/viewUserInfo"+"/"+id,{headers});
  }

  }