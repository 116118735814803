

<div class="mainbody container">
  <div class="row m-0">
    <div class="col-12">
      <div class="titleofthepage">Frequently Asked Question
</div>
    </div>
    </div>
    <div class="row m-0">

      <div class="text">
        <p>Here you can find answers to the most frequently asked questions. If you cannot find the answers youare looking for, fill out a form and someone will contact you.If you are not satisfied with the current resolution, please contact the below between 9AM to 6PM on business days. </p>
        <ul id="bulletlist">
         
          <li class="liststyle"> Level 1: Support Desk - Email ID:<a href="#"> support@tychepayment.com</a></li>
          <li class="liststyle">Level 2: Officer Desk - Email ID: <a href="#"> escalation@tychepayment.com</a> <a href="pradeep@tychepayment.com"> pradeep@tychepayment.com</a>  Phone:</li>
          <li class="liststyle">Level 3: Business Head - Email ID: <a href="#"> saravanan@tychepayment.com</a></li>
        </ul>

        <ul id="bulletlist1">
          <li class="liststyle"> Sign-up</li>
          <li class="liststyle">Activate My Account</li>
          <li class="liststyle">Settlements</li>
          <li class="liststyle">Refunds</li>
          <li class="liststyle">Accepting International Payments</li>
          <li class="liststyle">Support</li>
          <li class="liststyle">Fraud &amp;Chargeback</li>
          <li class="liststyle">Protect my business</li>
          <li class="liststyle">Rejected Business </li>
          <li class="liststyle">Deactivate my account</li>
         
        </ul>
      </div>
  



<div class="accordion" id="accordionEx11" role="tablist" aria-multiselectable="true">

  <!-- Accordion card -->
  <div class="card">

    <!-- Card header -->
    <div class="card-header" role="tab" id="headingOne1">
      <a data-toggle="collapse" data-parent="#accordionEx11" href="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1" class="">
        <h5 class="mb-0 headingdecorate">
        How do I register my business
        </h5>
      </a>
    </div>

    <!-- Card body -->
    <div id="collapseOne1" class="collapse show" role="tabpanel" aria-labelledby="headingOne1" data-parent="#accordionEx11" style="">
      <div class="card-body">
       <ul id="">
         <li class="liststyle">
          If you are new to Tyche Payment Solutions, click on the link to register.
         </li>
         <li class="liststyle">
          If you already have an account with us, click here to login and start using our features.
         </li>
       </ul>
      </div>
    </div>

  </div>
  <!-- Accordion card -->

  <!-- Accordion card -->
  <div class="card">

    <!-- Card header -->
    <div class="card-header" role="tab" id="headingTwo2">
      <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx11" href="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo2">
        <h5 class="mb-0 headingdecorate">
           My account is not yet active 
        </h5>
      </a>
    </div>

    <!-- Card body -->
    <div id="collapseTwo2" class="collapse" role="tabpanel" aria-labelledby="headingTwo2" data-parent="#accordionEx11" style="">
      <div class="card-body">
        <ul id="">
          <li class="liststyle">
            Please submit the following details once you are registered in our site.
          </li>
          <li class="liststyle">
            Once the details are filled, we will go through them and approve the account
          </li>
        </ul>
      </div>
    </div>

  </div>
  <!-- Accordion card -->

  <!-- Accordion card -->
  <div class="card">

    <!-- Card header -->
    <div class="card-header" role="tab" id="headingThree3">
      <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx11" href="#collapseThree3" aria-expanded="false" aria-controls="collapseThree3">
        <h5 class="mb-0 headingdecorate">
        Documents Upload
        </h5>
      </a>
    </div>

    <!-- Card body -->
    <div id="collapseThree3" class="collapse" role="tabpanel" aria-labelledby="headingThree3" data-parent="#accordionEx11">
      <div class="card-body">
       <ul id="">
         <li class="liststyle">
           Upload the required documents for your organization. 
         </li>
         <li class="liststyle">
          We recommend uploading a good quality documents. We only take documents which are necessary for approval and good quality scans will work perfectly well here.
         </li>
       </ul>
      </div>
    </div>

  </div>
  <!-- Accordion card -->

</div>

<div class="accordion md-accordion accordionalign" id="accordionEx12" role="tablist" aria-multiselectable="true">

    <!-- Accordion card -->
    <div class="card">
  
      <!-- Card header -->
      <div class="card-header" role="tab" id="headingOne15">
        <a data-toggle="collapse" data-parent="#accordionEx12" href="#collapseOne15" aria-expanded="false" aria-controls="collapseOne15">
          <h5 class="mb-0 headingdecorate">
            What is the settlement process at Tyche Payment Solutions?
          </h5>
        </a>
      </div>
  
      <!-- Card body -->
      <div id="collapseOne15" class="collapse show" role="tabpanel" aria-labelledby="headingOne15" data-parent="#accordionEx12">
        <div class="card-body">
         <ul id="">
           <li class="liststyle">
              Once the payment is made by a customer, it is settled to the merchant's account according to the schedule 
           </li>
           <li class="liststyle">
              that will be shared with the merchant at the time of registration.
           </li>
         </ul>
        </div>
      </div>
  
    </div>
    
    <!-- Accordion card -->
    

    <!-- Accordion card -->
    <div class="card">
  
      <!-- Card header -->
      <div class="card-header" role="tab" id="headingTwo25">
        <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx12" href="#collapseTwo25" aria-expanded="false" aria-controls="collapseTwo25">
          <h5 class="mb-0 headingdecorate">
            How long will the settlement take to appear in my bank account?
          </h5>
        </a>
      </div>
  
      <!-- Card body -->
      <div id="collapseTwo25" class="collapse" role="tabpanel" aria-labelledby="headingTwo25" data-parent="#accordionEx1">
        <div class="card-body">
          <ul id="">
            <li class="liststyle">
                Every day all the payments available for settlement for a given Merchant account are sent to the bank. 
            </li>
            <li class="liststyle">
                The funds are transferred into the merchant's bank account on T+1 or T+2 schedule based on the payment 
            </li>
            <li class="liststyle">
                instrument or as per the agreement with the merchant. 
            </li>
          </ul>
        </div>
      </div>
  
    </div>
    <!-- Accordion card -->
  
    
  </div>



  </div>
</div>

   