import {Injectable, Type} from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GenericResponseModel } from '../model/genricresponse';
import { GenericResponse } from '../model/genricmodelresponse';

@Injectable({
    providedIn: "root"
  })

  export class PartnerPaymentModeService {
  constructor(private httpClient:HttpClient) {}
  private baseUrl = environment.devurl;
  private auth = environment.headers;

  public view(){    
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/partner/view", {headers});
  }

  public partnerpaymentmode(id){ 
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/partner/viewpaymentmode"+"/"+id, {headers});
  }

  public partners(){ 
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/partnerinfo/activepartner", {headers});
  }

  public paymentmode(){ 
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/paymentmode/page", {headers});
  }

  public add(menu){
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.post<GenericResponse>(this.baseUrl+"/partner/create",menu,{headers});
}

public get(partnerId){ 
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
  return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/partner/edit/"+partnerId, {headers});
}

public update(menu){
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
  return this.httpClient.post<GenericResponse>(this.baseUrl+"/partner/update",menu,{headers});
}

public defaultMethod(id){    
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
 return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/partner/defaultpaymentmode/"+id, {headers});
}

}