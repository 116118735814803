<app-header></app-header>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
</ngx-spinner>
<ol class="breadcrumb breadcrumb-alt" style="margin-top:100px; width: auto;" >
  <li class="breadcrumb-item" style="margin-left: 15px;"><a href="#" routerLink="/dashboard">Home</a></li>
  <li class="breadcrumb-item active">FUEL PRICE RESET HISTORY</li>
  <div class="btn-section-wrapper">
      <button type="button" class="label label-info" (click)="open(content)">
        Update Fuel Rate</button>
</div>
</ol>
<div class="container  container-top-dashboard-section">
  <a href="#" routerLink="/master-fuel-rate-list" class="back_page"><span class="label label-info"><i class="back_page"></i>Back</span></a>
  <br>
<div class="card mb-3" style="margin-top: 20px; background-color: #ffb6c1;" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
  <!-- <div class="alert alert-success" role="alert"
  *ngIf="successflag">{{message}}</div> -->
  <br>
  <h5 style="margin-left: 15px; text-align: left;">Current Fuel Rate </h5>
  <br>
   <div class="flex">
      <!-- <div class="col1 flex"> -->
        <div class="container"
        fxLayout
      >
      <div class="item item-1" >
        <p lass="card-title"><b><span>Vehicle Number</span></b><br>
          <span *ngIf="marketPrizeModel.vehicleId!=null"><b>{{marketPrizeModel.vehicleId.vehicleNumber}}</b></span>
          <span *ngIf="marketPrizeModel.vehicleId==null"><b>N/A</b></span>
        </p>
       </div>
       <div class="item item-2" style="margin-left: 70px;">
        <p class="card-title"><b><span >Running PIN Code</span></b><br>
          <span *ngIf="marketPrizeModel.vehicleId!=null"><b>{{marketPrizeModel.vehicleId.vehicleRunningPincode}}</b></span>
          <span *ngIf="marketPrizeModel.vehicleId==null"><b>N/A</b></span>
          </p>
       </div>
       <div class="item item-3" style="margin-left: 70px;">
        <p class="card-title"><b><span >Fuel</span></b><br>
          <span *ngIf="marketPrizeModel.fuelType!=null"><b>{{marketPrizeModel.fuelType.fuelTypeName}}</b></span>
          <span *ngIf="marketPrizeModel.fuelType==null"><b>N/A</b></span>
        </p>
       </div>
       <div class="item item-4" style="margin-left: 70px;">
        <p class="card-title"><b><span >Rate Per Ltr</span></b>
          <br>
        <span *ngIf="marketPrizeModel.price!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{marketPrizeModel.price | number : '1.2-2'}}</span> 
        <span *ngIf="marketPrizeModel.price==null"><i class="fa fa-inr" aria-hidden="true"></i> N/A</span> 
        </p>
       </div>
       <div class="item item-5" style="margin-left: 70px;">
        <p class="card-title"><b><span >Updated By</span></b><br>
          <span *ngIf="marketPrizeModel.submittedBy!=null"><b>{{marketPrizeModel.submittedBy}}</b></span>
          <span *ngIf="marketPrizeModel.submittedBy==null"><b>N/A</b></span>
        </p>
       </div>
       <div class="item item-6" style="margin-left: 70px;">
        <p *ngIf="marketPrizeModel.currentDatetime!=null"class="card-title"><b><span >Effective Date And time</span></b><br><span><b>{{marketPrizeModel.currentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</b></span></p>
        <p *ngIf="marketPrizeModel.currentDatetime==null"class="card-title"><b><span >Effective Date And time</span></b><br><span><b>N/A</b></span></p>
       </div>
       <div class="item item-6" style="margin-left: 70px;">
        <p *ngIf="marketPrizeModel.submittedDatetime!=null"class="card-title"><b><span >Updated Date And time</span></b><br><span><b>{{marketPrizeModel.submittedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</b></span></p>
        <p *ngIf="marketPrizeModel.submittedDatetime==null"class="card-title"><b><span >Updated Date And time</span></b><br><span><b>N/A</b></span></p>
       </div>
        </div>
        <!--  <div class="profile-info flex">
            <div class="card-body profile">
               <p class="card-title"><b><span>Vehicle Number</span></b><br>
              <span><b>{{marketPrizeModel.vehicleId.vehicleNumber}}</b></span></p>
               <p class="card-title"><span >Running PIN Code</span><br>
               <span><b>{{marketPrizeModel.vehicleId.vehicleRunningPincode}}</b></span>
               </p>
               <p class="card-title">&nbsp;</p>
            </div>
            <div class="card-body profile">
               <p class="card-title"><span >Fuel</span><br>
                 <span><b>{{marketPrizeModel.fuelType.fuelTypeName}}</b></span></p>
<p class="card-title"><span >Rate Per Ltr</span>
  <br>
  <i class="fa fa-inr" aria-hidden="true"></i> {{marketPrizeModel.price | number : '1.2-2'}}</p>

             
               
               
            </div>
            <div class="card-body profile">
               <p class="card-title"><span >Updated By</span><br>
               <span><b>{{marketPrizeModel.submittedBy}}</b></span></p>
             
               
               
            </div>
            <div class="card-body profile">
               <p *ngIf="marketPrizeModel.modifiedDatetime!=null"class="card-title"><span >Updated Date And time</span><br><span><b>{{marketPrizeModel.modifiedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</b></span></p>
               <p *ngIf="marketPrizeModel.modifiedDatetime==null"class="card-title"><span >Updated Date And time</span><br><span><b>N/A</b></span></p>
             
               
               
            </div>
            
         </div>-->
      <!-- </div>  -->

   </div>
</div>



   
<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
   <!-- <div class="btn-section-wrapper">
     <button type="button" class="btn btn-success" (click)="open(content)">
 Update Fuel Rate</button>
</div> -->
<div style="display: none;">{{marketPrizeDetails.responseMessage}}</div>
  <li [ngbNavItem]="1">
    <a ngbNavLink>Updated History</a>
    <ng-template ngbNavContent>
      <div class="card-body">
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Vehicle Number</th>
                      <th style="text-align: left;">Running PIN Code</th>
                      <th style="text-align: left;">Fuel</th>
                      <th style="text-align: right;">Rate Per Ltr</th>
                      <th style="text-align: left;">Created By</th>
                      <th style="text-align: left;">Effective Date time</th>
                      <th style="text-align: left;">Updated Date time</th>
                      <!-- <th style="text-align: center;">Status</th> -->
                      <!-- <th align="center" >Action</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let market of marketPrizeDetails.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td align="left" class="v-align-middle">{{market.vehicleId.vehicleNumber}}</td>
                      <td align="left" class="v-align-middle">{{market.vehicleId.vehicleRunningPincode}}</td>
                      <td align="left" class="v-align-middle">{{market.fuelType.fuelTypeName}}</td>
                      <td align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{market.price | number : '1.2-2'}}</td>
                      <td align="left" class="v-align-middle">{{market.submittedBy}}</td>
                      <td *ngIf="market.currentDatetime!=null" align="left" class="v-align-middle">{{market.currentDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                      <td *ngIf="market.currentDatetime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="market.submittedDatetime!=null" align="left" class="v-align-middle">{{market.submittedDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                      <td *ngIf="market.submittedDatetime==null" align="left" class="v-align-middle">N/A</td>
                      <!-- <td align="center" class="v-align-middle">
                        <button title="delete"class="label label-danger" (click)="open1(reject,market.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
          </div>
            </div>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>


</div>



<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Fuel Rate</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <div class="modal-body master-body">
    <form>
      
  <div class="form-group">
        <label for="dateOfBirth">Vehicle Number</label>
        <br>
        <span *ngIf="marketPrizeModel.vehicleId!=null">
          {{marketPrizeModel.vehicleId.vehicleNumber}}
        </span>
        <span *ngIf="marketPrizeModel.vehicleId==null">N/A</span>
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Running Pin Code</label>
        <br>
        <span *ngIf="marketPrizeModel.vehicleId!=null">
          {{marketPrizeModel.vehicleId.vehicleRunningPincode}}
        </span>
        <span *ngIf="marketPrizeModel.vehicleId==null">N/A</span>
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Fuel Type</label>
        <br>
        <span *ngIf="marketPrizeModel.vehicleId!=null">
          {{marketPrizeModel.vehicleId.fuelType.fuelTypeName}}
        </span>
        <span *ngIf="marketPrizeModel.vehicleId==null">N/A</span>
      </div>
        <!-- <div class="form-group">
        <label for="dateOfBirth">Fuel type</label>
        <div class="dropdown">
  <select id="fuelType" name="fuelType" class="form-control" placeholder="Fuel Type">
    <option *ngFor="let fuel of fuelTypesModel" value={{fuel.id}}>{{fuel.fuelTypeName}}</option>
</select>
</div>
      </div> -->
    <div class="form-group">
        <label for="dateOfBirth">Price Per Ltrs</label>
        <input type="text" class="form-control" id="price" name="price" (keypress)="allowNumericDigitsOnlyOnKeyUp($event)" [(ngModel)]="marketPrizeModel.price" placeholder="Price Per Ltrs">
      </div>
      <!-- <div class="form-group">
        <label for="dateOfBirth">Status</label>
        <div class="dropdown">
  <select id="vehicleStatus" name="vehicleStatus" [(ngModel)]="marketPrizeModel.vehicleId.vehicleStatus" class="form-control">
    <option value=3>Active</option>
    <option value=4>Inactive</option>
</select>
</div>
      </div> -->
      <div class="form-group">
        <label for="exampleInputEmail1">Effective Date & Time</label>
        <input type="datetime-local" class="form-control" id="effedate" name="effedate" aria-describedby="emailHelp" placeholder="Select Role" required>
      </div>
      <div *ngIf="errorFlag">
        <div class="error-style">{{errorMessage}}</div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="addMarket()" >Upadte Fuel Rate</button>
  </div>
</ng-template>


<ng-template #reject let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Do you want delete the fuel price?</label>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="delete()" (click)="modal.close('Save click')">yes</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">no</button>
  </div>
</ng-template>