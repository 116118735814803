import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { CustomerOrderModel } from '../model/customerorder';
import { GenericResponse } from '../model/genricmodelresponse';
import { GenericResponseModel } from '../model/genricresponse';
import { UserModel } from '../model/user';
import { CustomerService } from '../services/customer.service';
import { OrderTransactionService } from '../services/ordertran.service';
import { UserService } from '../services/user.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { VehicleFuelService } from '../services/vehivle.service';
import { VehicleAttachModel } from '../model/vehicleModel';
import { OrderAssignModel } from '../model/orderassign';
import { PartnersService } from '../services/partners.service';
import {NgxSpinnerService} from "ngx-spinner";
import * as moment from 'moment';
import { ChargesModel } from '../model/charges';
import { ChargesService } from '../services/charges.service';
import { PartnerPaymentModeService } from '../services/partnerpaymentmode.service';
@Component({
  selector: 'app-customers-view',
  templateUrl: './customers-view.component.html',
  styleUrls: ['./customers-view.component.css']
})
export class CustomersViewComponent  {
  lat:Number;
  lan:Number;
   public isCollapsed = false;
   userModel : UserModel;
   partnerModel : any;
   customerOrder:GenericResponseModel;
   pencustomerOrder:GenericResponseModel;
   inpcustomerOrder:GenericResponseModel;
   comcustomerOrder:GenericResponseModel;
   cancustomerOrder:GenericResponseModel;
   allcustomerOrder:GenericResponseModel;
   filtercustomerOrder:GenericResponseModel;
   filtercustomerOrderNorecord:GenericResponseModel;
   filtertransaction:GenericResponseModel;
   filtertransactionNorecord:GenericResponseModel;
   customerTransanction:GenericResponseModel;
  orderModel:CustomerOrderModel;
  active = 1;
  message : String;
  successflag:boolean;
  isImageLoading: boolean;
  isImageLoading1: boolean;
  imageToShow: any;
  imageToShow1: any;
  defaultCustomer:boolean = false;
  allCustomer:boolean = true;
  penCustomer:boolean = false;
  inpCustomer:boolean = false;
  comCustomer:boolean = false;
  canCustomer:boolean = false;
  viewCusOrder:boolean = false;
  partnerCredit:boolean=false;
  editUserId:Number;
closeResult = '';
    defaultTransaction:boolean = false;
    allTransaction:boolean;
    successTransaction:boolean;
    failureTransaction:boolean;
    initiatedTransaction:boolean = true;
    cancelledTransaction:boolean;
    imageasset: any;
    succagentTransanction:GenericResponseModel;
    failagentTransanction:GenericResponseModel;
    canceagentTransanction:GenericResponseModel;
    allagentTransanction:GenericResponseModel;
    iniagentTransanction:GenericResponseModel;
    assetsResponseModel:GenericResponseModel;
    chargesModel:GenericResponseModel;
    customerBankInfo:GenericResponseModel;
    ratingStar:Number;
    orderDetails:GenericResponseModel;
    orderTrackDetails:GenericResponse;
    cancelMessageModel:GenericResponseModel;
    errorFlag:boolean = false;
    errorFlag1:boolean = false;
    errorMessage:String;
    orderID:Number;
    activeVehicle:GenericResponseModel;
    activevehi:Object;
vehicleModel : CustomerOrderModel;
check:String = null;
vehicle:VehicleAttachModel;
startDate:String = null;
endDate:String = null;
refreshFlag:boolean = false;
  filterflag:boolean= false;
  filteronesearch:boolean= false;
  filterflag1:boolean= false;
  filteronesearch1:boolean= false;
  failedStatus:String;
  failedCount:Number;
  currentCharges:ChargesModel;
  vehicleFee:DoubleRange;
  igst:DoubleRange;
  cgst:DoubleRange;
  sgst:DoubleRange;
  internet:DoubleRange;
  amc:DoubleRange;
  mmc:DoubleRange;
  minquan:DoubleRange;
  maxquan:DoubleRange;
  deliverless:DoubleRange;
  deliverlessamt:DoubleRange;
  delivergrea:DoubleRange;
  delivergreaamt:DoubleRange;
  deliveryChargeFlag:Number;
  deliveryFixedFee:DoubleRange;
  deliveryPerLtrCost:DoubleRange;
  deliveryFlag:Number;
  assetSetupFee:DoubleRange;
  assetSubscriptionFee:DoubleRange;
  resultText=[];
  values:string;
  assignedPartnerModel:any;
  paymentMode:any;
  assignedPaymentMode:any;
  resultPaymentText:[];
  constructor(private modalService: NgbModal,private ordertransactionservice: OrderTransactionService,
    private route: ActivatedRoute,private customerService: CustomerService,private router: Router,
    private userservice:UserService,private vehicleservice:VehicleFuelService,
    private partnerservice:PartnersService,private spinnerService:NgxSpinnerService,
    private chargesservice:ChargesService,private paymentModeServices:PartnerPaymentModeService) {}
  ngOnInit() : void {
      this.spinnerService.show();
    this.route.queryParams.subscribe(params => {
      let value = params.success;
      if(value){
        this.successflag=true;
        this.message = params.message;
      }else{
      }

      setTimeout(function() {
        this.successflag = false;
    }.bind(this), 5000);


    let id = Number(sessionStorage.getItem("cusId"));
    
    this.customerService.getCustomerOrders(id).subscribe(data =>{
      this.allcustomerOrder=data;
    });

     this.customerService.getCustomerPendingOrders(id).subscribe(data =>{
      this.pencustomerOrder=data;
    });

      this.customerService.getCustomerByID(id)
      .subscribe(data => {
       
          let rating = String(data.ratings);
          if(data.ratings==0){
            this.ratingStar = 0;
          }
          else if(rating.match('1')){
            if(rating.match('1.5')||rating.match('1.6')||rating.match('1.7')||rating.match('1.8')||rating.match('1.9')){
              this.ratingStar = 1.5;
            }else{
              this.ratingStar = 1;
            }
            
          }else if(rating.match('2')){
            if(rating.match('2.5')||rating.match('2.6')||rating.match('2.7')||rating.match('2.8')||rating.match('2.9')){
              this.ratingStar = 2.5;
            }else{
              this.ratingStar = 2;
            }
          }else if(rating.match('3')){
            if(rating.match('3.5')||rating.match('3.6') || rating.match('3.7')||rating.match('3.8')||rating.match('3.9')){
              this.ratingStar = 3.5;
            }else{
              this.ratingStar = 3;
            }
          }else if(rating.match('4')){
            if(rating.match('4.5')||rating.match('4.6')||rating.match('4.7')||rating.match('4.8')||rating.match('4.9')){
              this.ratingStar = 4.5;
            }else{
              this.ratingStar = 4;
            }
          }else if(rating.match('5')){
            this.ratingStar = 5;
          }
        this.userModel = data;
        this.failedCount = this.userModel.failedCount;
          if(this.userModel.failedCount==6){
            this.failedStatus = "Block" ;
          }else{
            this.failedStatus = "Not Block" ;
          }
      }, error => console.log(error));

      this.userservice.getUserImage(id).subscribe(data=>{
        if(data.type==='text/xml'){
          this.isImageLoading = false;
        }else{
          this.isImageLoading = true;
        }
        this.createImageFromBlob(data);
      });

      this.customerService.getCustomerOrders(id).subscribe(data =>{
        this.customerOrder=data;
        this.spinnerService.hide();
      });

      this.customerService.getCustomerTransanctions(id).subscribe(data =>{
        this.customerTransanction=data;
      });

      this.customerService.getInitiatedTransactions(id).subscribe(data =>{
        this.iniagentTransanction=data;
        this.spinnerService.hide();
      });

      this.customerService.banksettlement(id).subscribe(data=>{
        this.customerBankInfo = data;
       
      })

      this.customerService.getAssetsByCustomer(id).subscribe(data=>{
        this.assetsResponseModel = data;
      });

      this.customerService.getChargesByCustomer(id).subscribe(data=>{
        this.chargesModel=data;
      });

      this.customerService.getCurrentChargesByCustomer(id).subscribe(data=>{
        this.currentCharges=data;
        if(data.id==null){
          this.chargesservice.getCurrentCharges().subscribe(data=>{
            this.currentCharges=data;
           });
        }
      });

      this.customerService.getAssetsByCustomer(id).subscribe(data=>{
        this.assetsResponseModel = data;
      });

      this.customerService.viewAssignedPartner(id).subscribe(data=>{
        this.assignedPartnerModel=data;
      });

      this.customerService.viewPaymentMode(id).subscribe(data=>{
        console.log(data);
        this.assignedPaymentMode=data;
      });

      this.spinnerService.hide();
    });
  }
  open(content,order:CustomerOrderModel) {
    this.orderID = order.id;
    this.ordertransactionservice.getOrderById(order.id).subscribe(data=>{
      this.orderDetails=data;
    });
    
    this.ordertransactionservice.tracking(order.id).subscribe(data=>{
      this.orderTrackDetails = data;
    });

    
    this.customerService.getOrderById(order.id).subscribe
    (data=>{
      this.orderModel = data;
    });
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  cancelPopup(content5,id){
    this.orderID = id;
    this.ordertransactionservice.cancellmessage().subscribe(data=>{
      this.cancelMessageModel = data;
    });
    this.modalService.open(content5, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open1(content1,id) {
    this.orderID = id;
    this.ordertransactionservice.getOrderById(id).subscribe(data=>{
      this.orderDetails=data;
    });

    this.ordertransactionservice.getActivatevehicle().subscribe(data=>{
      this.activeVehicle = data;
      this.activevehi = this.activeVehicle.response[0];
    });

    this.partnerservice.getOrderById(id).subscribe(data=>{
      this.vehicleModel = data;
    })

 

  this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  open2(content9,id) {
     this.customerService.getAssetsImage(id).subscribe(data=>{
      this.isImageLoading1 = true;
        this.createImageFromBlob6(data);
        this.isImageLoading1 = false;
  }, error => {
    this.isImageLoading1 = false;
  });

  this.modalService.open(content9, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
  }

  callActiveOrInactive(status){
    let id = Number(sessionStorage.getItem("cusId"));
    let username = sessionStorage.getItem("username");
    this.userservice.activeOrInactive(id,status,username).subscribe(data=>{
      if(data.flag===1){
        this.router.navigate(['/customers-view'],{ queryParams: { success: true ,message:data.message}});
      }
    })
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
       this.imageToShow = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
   }
  }

  createImageFromBlob1(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
       this.imageToShow1 = reader.result;
       console.log(image);
    }, false);

    if (image) {
      reader.readAsDataURL(image);
   }
  }

  createImageFromBlob6(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
       this.imageasset = reader.result;
    }, false);
  
    if (image) {
      reader.readAsDataURL(image);
   }
  }

  pending(){
    this.allCustomer = false;
    this.penCustomer = true;
    this.inpCustomer = false;
    this.comCustomer = false;
    this.canCustomer = false;
    this.defaultCustomer = false;
    let id = Number(sessionStorage.getItem("cusId"));
    this.customerService.getCustomerPendingOrders(id).subscribe(data =>{
      this.pencustomerOrder=data;
    });
  }

  inprogress(){
    this.allCustomer = false;
    this.penCustomer = false;
    this.inpCustomer = true;
    this.comCustomer = false;
    this.canCustomer = false;
    this.defaultCustomer = false;
    let id = Number(sessionStorage.getItem("cusId"));
    this.customerService.getCustomerInprogressOrders(id).subscribe(data =>{
      this.inpcustomerOrder=data;
    });
  }

  completed(){
    this.allCustomer = false;
    this.penCustomer = false;
    this.inpCustomer = false;
    this.comCustomer = true;
    this.canCustomer = false;
    this.defaultCustomer = false;
    let id = Number(sessionStorage.getItem("cusId"));
    this.customerService.getCustomerCompletedsOrders(id).subscribe(data =>{
      this.comcustomerOrder=data;
    });
  }

  cancelled(){
    this.allCustomer = false;
    this.penCustomer = false;
    this.inpCustomer = false;
    this.comCustomer = false;
    this.canCustomer = true;
    this.defaultCustomer = false;
    let id = Number(sessionStorage.getItem("cusId"));
    this.customerService.getCustomerCancelledOrders(id).subscribe(data =>{
      this.cancustomerOrder=data;
    });
  }

  all(){
    this.allCustomer = true;
    this.penCustomer = false;
    this.inpCustomer = false;
    this.comCustomer = false;
    this.canCustomer = false;
    this.defaultCustomer = false;
    let id = Number(sessionStorage.getItem("cusId"));
    this.customerService.getCustomerOrders(id).subscribe(data =>{
      this.allcustomerOrder=data;
    });
  }

  successtra(){
    let id = Number(sessionStorage.getItem("cusId"));
    this.defaultTransaction = false;
    this.allTransaction = false;
    this.successTransaction = true;
    this.failureTransaction = false;
    this.initiatedTransaction = false;
    this.cancelledTransaction = false;
    this.customerService.getSuccessTransactions(id).subscribe(data =>{
      this.succagentTransanction=data;
    });
  }

  failuretra(){
    let id = Number(sessionStorage.getItem("cusId"));
    this.defaultTransaction = false;
    this.allTransaction = false;
    this.successTransaction = false;
    this.failureTransaction = true;
    this.initiatedTransaction = false;
    this.cancelledTransaction = false;
    this.customerService.getFailedTransactions(id).subscribe(data =>{
      this.failagentTransanction=data;
    });
  }

  cancelledtra(){
    let id = Number(sessionStorage.getItem("cusId"));
    this.defaultTransaction = false;
    this.allTransaction = false;
    this.successTransaction = false;
    this.failureTransaction = false;
    this.initiatedTransaction = false;
    this.cancelledTransaction = true;
    this.customerService.getCancelledTransactions(id).subscribe(data =>{
      this.canceagentTransanction=data;
    });
  }

  initiatedtra(){
    let id = Number(sessionStorage.getItem("cusId"));
    this.defaultTransaction = false;
    this.allTransaction = false;
    this.successTransaction = false;
    this.failureTransaction = false;
    this.initiatedTransaction = true;
    this.cancelledTransaction = false;
    this.customerService.getInitiatedTransactions(id).subscribe(data =>{
      this.iniagentTransanction=data;
    });
  }

  alltra(){
    let id = Number(sessionStorage.getItem("cusId"));
    this.defaultTransaction = false;
    this.allTransaction = true;
    this.successTransaction = false;
    this.failureTransaction = false;
    this.initiatedTransaction = false;
    this.cancelledTransaction = false;
    this.customerService.getCustomerTransanctions(id).subscribe(data =>{
      this.allagentTransanction=data;
    });
  }

  cancelOrder(){
    let desc = (<HTMLInputElement>document.getElementById('cancelremarks')).value;
    let cancelmessage = (<HTMLInputElement>document.getElementById('cancelmessage')).value;
    if(desc!=null && desc!==''){
      this.errorFlag1 = false;
      let formData= {
        userId:Number(sessionStorage.getItem('id')),
        cancellationId:cancelmessage,
        orderRemarks:desc,
      }
      this.ordertransactionservice.cancelorder(this.orderID,formData).subscribe(data=>{
        if(data.statusCode==200){
          Swal.fire({
            html: '<pre>' + data.responseMessage + '</pre>',
            customClass: {
              popup: 'format-pre'
            }
          }).then((value) => {
            window.location.reload();
          });; ; 
        }else{
          Swal.fire({
            html: '<pre>' + data.responseMessage + '</pre>',
            customClass: {
              popup: 'format-pre'
            }
          }).then((value) => {
            window.location.reload();
          });; ; 
        }
      });
      this.modalService.dismissAll();
    }else{
      this.errorFlag1 = true;
      this.errorMessage = "Please enter remarks!";
      setTimeout(function() {
        this.errorFlag1 = false;
    }.bind(this), 3000);

    }
  }

  invoice(id){
    this.ordertransactionservice.download(id,2).subscribe(data => {
      
      var file = new Blob([data], {type: 'application/pdf'});
       var fileURL = URL.createObjectURL(file);
       window.open(fileURL);

  }, error => {

  });
 
  }

  download(id,filename){
    this.ordertransactionservice.download(id,2).subscribe(result => {
      let dataType = 'application/pdf';
      let binaryData = [];
      binaryData.push(result);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
      downloadLink.setAttribute('download', filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();

  });
  }

  sendMail(id){
    this.ordertransactionservice.sendMail(id,2).subscribe(data => {
      
      if(data.statusCode==200){
        Swal.fire({
          html: '<pre>' + data.responseMessage + '</pre>',
          customClass: {
            popup: 'format-pre'
          }
        }).then((value) => {
          window.location.reload();
        });; ;
      }
    },err => {
      if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });
 
  }
  onOptionsSelected(value:Number){
    this.vehicleservice.getVehicleById(value).subscribe(data=>{
      this.vehicle = data;
      this.check = data.vehicleName;
    });
  }

  assign(){
    let id = (<HTMLInputElement>document.getElementById('countryFormControl')).value;
    let latitude = this.vehicleModel.vehicleId.vehicleRegLatitude;
    let langitude = this.vehicleModel.vehicleId.vehicleRegLongtitude;
    let assign = new OrderAssignModel(Number(id),sessionStorage.getItem('username'),latitude,langitude);
    this.ordertransactionservice.assignto(this.orderID,assign).subscribe(data=>{
      Swal.fire({
        html: '<pre>' + data.responseMessage + '</pre>',
        customClass: {
          popup: 'format-pre'
        }
      }).then((value) => {
        window.location.reload();
      });; ; 
    });
  }

  open3(content2) {
    this.modalService.open(content2, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
}

location(lat,lan){
  this.lat = Number(lat);
  this.lan = Number(lan);
}

search(){
  this.refreshFlag = true;
  let searchBy = (<HTMLInputElement>document.getElementById('searchBy')).value;
  let status = (<HTMLInputElement>document.getElementById('status')).value;
  let fromdate = (<HTMLInputElement>document.getElementById('fromdate')).value;
  let todate = (<HTMLInputElement>document.getElementById('todate')).value;
  if(searchBy=='1'){
    this.filterflag = false;
    if(status=='1'){
      this.allCustomer = true;
      this.penCustomer = false;
      this.inpCustomer = false;
      this.comCustomer = false;
      this.canCustomer = false;
      this.defaultCustomer = false;
      let id = Number(sessionStorage.getItem("cusId"));
      this.customerService.getCustomerOrders(id).subscribe(data =>{
        this.allcustomerOrder=data;
      });
    }else if(status=='2'){
      this.allCustomer = false;
      this.penCustomer = true;
      this.inpCustomer = false;
      this.comCustomer = false;
      this.canCustomer = false;
      this.defaultCustomer = false;
      let id = Number(sessionStorage.getItem("cusId"));
      this.customerService.getCustomerPendingOrders(id).subscribe(data =>{
        this.pencustomerOrder=data;
      });
    }else if(status=='3'){
      this.allCustomer = false;
      this.penCustomer = false;
      this.inpCustomer = true;
      this.comCustomer = false;
      this.canCustomer = false;
      this.defaultCustomer = false;
      let id = Number(sessionStorage.getItem("cusId"));
      this.customerService.getCustomerInprogressOrders(id).subscribe(data =>{
        this.inpcustomerOrder=data;
      });
    }else if(status=='4'){
      this.allCustomer = false;
      this.penCustomer = false;
      this.inpCustomer = false;
      this.comCustomer = true;
      this.canCustomer = false;
      this.defaultCustomer = false;
      let id = Number(sessionStorage.getItem("cusId"));
      this.customerService.getCustomerCompletedsOrders(id).subscribe(data =>{
        this.comcustomerOrder=data;
      });
    }else if(status=='5'){
      this.allCustomer = false;
      this.penCustomer = false;
      this.inpCustomer = false;
      this.comCustomer = false;
      this.canCustomer = true;
      this.defaultCustomer = false;
      let id = Number(sessionStorage.getItem("cusId"));
      this.customerService.getCustomerCancelledOrders(id).subscribe(data =>{
        this.cancustomerOrder=data;
      });
    }
  }else if(searchBy=='2'){
    this.filterflag = true;
    this.allCustomer = false;
    this.penCustomer = false;
    this.inpCustomer = false;
    this.comCustomer = false;
    this.canCustomer = false;
    this.defaultCustomer = false;
    if(fromdate==''||todate==''){
        this.errorFlag = true;
        this.errorMessage = "Please select Dates!";
      }else if(todate < fromdate){
        this.errorFlag = true;
        this.errorMessage = "End Date should be greater than Start Date!";
      }else{
        let id = Number(sessionStorage.getItem("cusId"));
        this.customerService.getCustomerOrdersdaterange(fromdate,todate,id).subscribe(data=>{
          if(data.statusCode==200){
            this.filteronesearch = false;
            this.filtercustomerOrder = data;
          }else{
            this.filteronesearch = true;
            this.filtercustomerOrderNorecord = data;
          }
        });
      }
  }
}

refresh(){
  this.refreshFlag = false;
  window.location.reload();
}

callsearch(event){
  if(event.target.value==1){
    $('#status').show();
    $('#fromdate').hide();
    $('#todate').hide();
  }else if(event.target.value==2){
    var nowdate = new Date();
    var monthStartDay = new Date(nowdate.getFullYear(), nowdate.getMonth(), 1);
    var monthEndDay = new Date(nowdate.getFullYear(), nowdate.getMonth() + 1, 0);
    let startDate = moment(new Date(monthStartDay)).format("yyyy-MM-DD").toString();
    let endDate = moment(new Date(monthEndDay)).format("yyyy-MM-DD").toString();
    this.startDate = startDate;
    this.endDate = endDate;
    $('#status').hide();
    $('#fromdate').show();
    $('#todate').show();
  }
}

open10(reject,viewId){
  this.editUserId = viewId;
  this.modalService.open(reject, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

unblock(){
  this.spinnerService.show();
  let userId = this.editUserId;
  this.customerService.unblock(userId).subscribe(data=>{
    if(data.statusCode==200){
      this.spinnerService.hide();
      Swal.fire({
        html: '<pre>' + data.responseMessage + '</pre>',
        customClass: {
          popup: 'format-pre'
        }
      }).then((value) => {
        window.location.reload();
      });; ;
    }
  },err => {
    if(err.error.statusCode==500){
      this.router.navigate(['/page-error']);
    }else if(err.error.statusCode==404){
      this.router.navigate(['/pagenotfound']);
    }
 });
}

searchtran(){
  this.refreshFlag = true;
  let searchBy = (<HTMLInputElement>document.getElementById('searchBy')).value;
  let status = (<HTMLInputElement>document.getElementById('status')).value;
  let fromdate = (<HTMLInputElement>document.getElementById('fromdate')).value;
  let todate = (<HTMLInputElement>document.getElementById('todate')).value;
  if(searchBy=='1'){
    this.filterflag1 = false;
    if(status=='1'){
      let id = Number(sessionStorage.getItem("cusId"));
      this.defaultTransaction = false;
      this.allTransaction = true;
      this.successTransaction = false;
      this.failureTransaction = false;
      this.initiatedTransaction = false;
      this.cancelledTransaction = false;
      this.customerService.getCustomerTransanctions(id).subscribe(data =>{
        this.allagentTransanction=data;
      });
    }else if(status=='2'){
      let id = Number(sessionStorage.getItem("cusId"));
      this.defaultTransaction = false;
      this.allTransaction = false;
      this.successTransaction = false;
      this.failureTransaction = false;
      this.initiatedTransaction = true;
      this.cancelledTransaction = false;
      this.customerService.getInitiatedTransactions(id).subscribe(data =>{
        this.iniagentTransanction=data;
      });
    }else if(status=='3'){
      let id = Number(sessionStorage.getItem("cusId"));
      this.defaultTransaction = false;
      this.allTransaction = false;
      this.successTransaction = true;
      this.failureTransaction = false;
      this.initiatedTransaction = false;
      this.cancelledTransaction = false;
      this.customerService.getSuccessTransactions(id).subscribe(data =>{
        this.succagentTransanction=data;
      });
    }else if(status=='4'){
      let id = Number(sessionStorage.getItem("cusId"));
      this.defaultTransaction = false;
      this.allTransaction = false;
      this.successTransaction = false;
      this.failureTransaction = true;
      this.initiatedTransaction = false;
      this.cancelledTransaction = false;
      this.customerService.getFailedTransactions(id).subscribe(data =>{
        this.failagentTransanction=data;
      });
    }else if(status=='5'){
      let id = Number(sessionStorage.getItem("cusId"));
      this.defaultTransaction = false;
      this.allTransaction = false;
      this.successTransaction = false;
      this.failureTransaction = false;
      this.initiatedTransaction = false;
      this.cancelledTransaction = true;
      this.customerService.getCancelledTransactions(id).subscribe(data =>{
        this.canceagentTransanction=data;
      });
    }
  }else if(searchBy=='2'){
    if(fromdate==''||todate==''){
      this.errorFlag = true;
      this.errorMessage = "Please select Dates!";
    }else if(todate < fromdate){
      this.errorFlag = true;
      this.errorMessage = "End Date should be greater than Start Date!";
    }else{
    this.defaultTransaction = false;
    this.allTransaction = false;
    this.successTransaction = false;
    this.failureTransaction = false;
    this.initiatedTransaction = false;
    this.cancelledTransaction = false;
    this.filterflag1 = true;
    let id = Number(sessionStorage.getItem("cusId"));
    this.customerService.getCustomerTransactionsdaterange(fromdate,todate,id).subscribe(data=>{
      if(data.statusCode==200){
        this.filteronesearch1 = false;
        this.filtertransaction = data;
      }else{
        this.filteronesearch1 = true;
        this.filtertransactionNorecord = data;
      }
    })
  }
  }

}

openCharge(content) {
  let id = Number(sessionStorage.getItem("cusId"));
  let val = null;
  this.customerService.getCurrentChargesByCustomer(id).subscribe(data=>{
    val = data.id;
    this.currentCharges=data;
   });

if(val==null){
  this.chargesservice.getCurrentCharges().subscribe(data=>{
   this.currentCharges=data;
  });
}
 
  if(this.currentCharges!=undefined){
    this.vehicleFee = this.currentCharges.vehicleAttachmentFee;
    this.igst = this.currentCharges.igstPercentage;
    this.cgst = this.currentCharges.cgstPercentage;
    this.sgst = this.currentCharges.sgstPercentage;
    this.internet = this.currentCharges.internetHandlingFee;
    this.deliverless = this.currentCharges.deliveryLessEqualKms;
    this.deliverlessamt = this.currentCharges.deliveryLessEqualCharges;
    this.delivergrea = this.currentCharges.deliveryGreaterEqualKms;
    this.delivergreaamt = this.currentCharges.deliveryGreaterEqualCharges;
    this.amc=this.currentCharges.amcFee;
    this.mmc=this.currentCharges.mmcFee;
    this.minquan=this.currentCharges.minOrderQuantity;
    this.maxquan=this.currentCharges.maxOrderQuantity;
    this.deliveryChargeFlag=this.currentCharges.deliveryChargeFlag;
    this.deliveryFixedFee=this.currentCharges.deliveryFixedFee;
    this.deliveryPerLtrCost=this.currentCharges.deliveryPerLtrCost;
  
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
}
allowNumericDigitsOnlyOnKeyUpWithoutdot(e) {		
  const charCode = e.which ? e.which : e.keyCode;
  if(charCode > 31 && (charCode < 48 || charCode > 57)){
      e.preventDefault();
  }else{
    return true;
  }
}
allowNumericDigitsOnlyOnKeyUp(e) {		
  const charCode = e.which ? e.which : e.keyCode;
  if(charCode==46){
    return true;
  }else if(charCode > 31 && (charCode < 48 || charCode > 57)){
    if(charCode==46){
      return true;
    }else{
      e.preventDefault();
    }
  }else{
    return true;
  }
}

allowNumericDigitsOnlyOnKeyUpwithoutzero(e) {		
  const charCode = e.which ? e.which : e.keyCode;
  if(charCode==48){
    e.preventDefault();
  }else if(charCode > 31 && (charCode < 49 || charCode > 57)){
      e.preventDefault();
  }else{
    if(charCode==48){
      e.preventDefault();
    }else{
      return true;
    }
  }
}

eventCheck(event){
  if(event.target.checked){
    this.deliveryFlag = event.target.value;
  }
 }
addCharges(){
  let id = Number(sessionStorage.getItem("cusId"));
  let vehicle = (<HTMLInputElement>document.getElementById('vehicle')).value;
  let inte = (<HTMLInputElement>document.getElementById('inte')).value;
  let sgst = (<HTMLInputElement>document.getElementById('sgst')).value;
  let cgst = (<HTMLInputElement>document.getElementById('cgst')).value;
  let igst = (<HTMLInputElement>document.getElementById('igst')).value;
  let effective = (<HTMLInputElement>document.getElementById('effedate')).value;
  let amcfe = (<HTMLInputElement>document.getElementById('amcfe')).value;
  let mmcfe = (<HTMLInputElement>document.getElementById('mmcfe')).value;
  let maxquan = (<HTMLInputElement>document.getElementById('maxquan')).value;
  let minquan = (<HTMLInputElement>document.getElementById('minquan')).value;
  let assetSubscriptionFee = (<HTMLInputElement>document.getElementById('assetSubscriptionFee')).value;
  let assetSetupFee = (<HTMLInputElement>document.getElementById('assetSetupFee')).value;
  
  let deliveryChargeFlag = $("input[type='radio'][name='deliveryChargeFlag']:checked").val();
  
  let deliveryLess = '0';
  let deliverylech = '0';
  let deliveryGreater = '0';
  let deliveryGrch = '0';
  let deliveryFixedFee = '0';
  let deliveryPerLtrCost = '0';
  if(deliveryChargeFlag=='1'){
     deliveryLess = (<HTMLInputElement>document.getElementById('deliveryLess')).value;
     deliverylech = (<HTMLInputElement>document.getElementById('deliverylech')).value;
     deliveryGreater = (<HTMLInputElement>document.getElementById('deliveryGreater')).value;
     deliveryGrch = (<HTMLInputElement>document.getElementById('deliveryGrch')).value;
  }else if(deliveryChargeFlag=='2'){
     deliveryFixedFee = (<HTMLInputElement>document.getElementById('deliveryFixedFee')).value;
  }else if(deliveryChargeFlag=='3'){
     deliveryPerLtrCost = (<HTMLInputElement>document.getElementById('deliveryPerLtrCost')).value;
  }
 
  if(amcfe=='' || mmcfe==''){
    this.errorFlag = true;
    this.errorMessage = "AMC | MMC quantity should be Mandatory!";
    setTimeout(function() {
      this.errorFlag = false;
  }.bind(this), 5000);
  }else if(maxquan=='' || minquan==''){
    this.errorFlag = true;
    this.errorMessage = "Minimum | Maximum quantity should be Mandatory!";
    setTimeout(function() {
      this.errorFlag = false;
  }.bind(this), 5000);
  }else if(maxquan=='0' || minquan=='0'){
    this.errorFlag = true;
    this.errorMessage = "Minimum | Maximum quantity should not be zero!";
    setTimeout(function() {
      this.errorFlag = false;
  }.bind(this), 5000);
  }else if(Number(minquan)>Number(maxquan)){
    this.errorFlag = true;
    this.errorMessage = "Maximum quantity should be greater than minimum quantity!";
    setTimeout(function() {
      this.errorFlag = false;
  }.bind(this), 5000);
  }else if(effective!=null && effective!==''){
    let d = effective.concat(":00");
    let currentDate=new Date(d);
    let formData= {
      vehicleAttachmentFee:vehicle,
      customerId:id,
      internetHandlingFee:inte,
      sgstPercentage:sgst,
      modifiedBy:sessionStorage.getItem("username"),
      deliveryLessEqualKms:deliveryLess,
      deliveryLessEqualCharges:deliverylech,
      deliveryGreaterEqualKms:deliveryGreater,
      deliveryGreaterEqualCharges:deliveryGrch,
      cgstPercentage:cgst,
      igstPercentage:igst,
      effectiveDate:currentDate.getTime(),
      amcFee:amcfe,
      mmcFee:mmcfe,
      minOrderQuantity:minquan,
      maxOrderQuantity:maxquan,
      deliveryFixedFee:deliveryFixedFee,
      deliveryPerLtrCost:deliveryPerLtrCost,
      deliveryChargeFlag:deliveryChargeFlag,
      assetSubscriptionFee:assetSubscriptionFee,
      assetSetupFee:assetSetupFee
    }
    this.customerService.addCharges(formData).subscribe(data=>{
      if(data.flag==1){
        this.modalService.dismissAll();
        Swal.fire({
          html: '<pre>' + data.message + '</pre>',
          customClass: {
            popup: 'format-pre'
          }
        }).then((value) => {
          window.location.reload();
        });; ; 
      }else{

      }
    },err => {
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }else if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });
  }else{
    this.errorFlag = true;
    this.errorMessage = "Please select Effective Date!";

    setTimeout(function() {
      this.errorFlag = false;
  }.bind(this), 5000);
  }
}

assignPartner(id:Number,event) {  
  const checked = event.target.checked;  
   if (checked) {  
     this.resultText.push(id);  
    } else {  
        const index = this.resultText.indexOf(id);  
        this.resultText.splice(index, 1);  
    }  
    this.values=this.resultText.toString();  
    const count=this.resultText.length;  
  }  

  assignMode(id:Number,event) {  
    const checked = event.target.checked;  
     if (checked) {  
       this.resultText.push(id);  
       if(id==4){
        this.partnerCredit = true;
       }
      } else {  
        if(id==4){
          this.partnerCredit = false;
         }
          const index = this.resultText.indexOf(id);  
          this.resultText.splice(index, 1);  
      }  
      this.values=this.resultText.toString();  
      const count=this.resultText.length;  
    }  

openPartner(content,id){
 this.editUserId=id;

 this.partnerservice.getAllPartners().subscribe(data=>{
  if(data.statusCode==200){
    this.partnerModel=data;
  }else{
  }
},err => {
  if(err.error.statusCode==406){
    this.router.navigate(['/login']);
  }else if(err.error.statusCode==500){
    this.router.navigate(['/page-error']);
  }else if(err.error.statusCode==404){
    this.router.navigate(['/pagenotfound']);
  }
});
  this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}
submit(){
     
  if(this.resultText.length>0){
   
      let formData= {
        customerId:this.editUserId,
        partnerId:this.values,
        createdBy:sessionStorage.getItem("username")
      }

      this.customerService.assignPartner(formData).subscribe(data=>{
        if(data.statusCode==200){
          this.modalService.dismissAll();
          Swal.fire({
            html: '<pre>' + 'Partner has been assigned successfully' + '</pre>',
            customClass: {
              popup: 'format-pre'
            }
          }).then((value) => {
            window.location.reload();
          });; 
     // this.transactionDetail = data;
      setTimeout(function() {
    }.bind(this), 3000);
    }
  },err => {
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }else if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
  });
    }else{
      this.errorMessage="Please select atleast one partner!";
    }
 
}
viewPartner(content,model,id){
 // console.log("list:"+model.response);
  //this.assignedPartnerModel=model;
  this.editUserId=id;
  this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

editContent(content,model,id){
  this.customerService.assignedPartner(id).subscribe((data) =>{
    this.partnerModel=data;

   for (let key in data.response) {
    if(key=='list'){
      let list = data.response[key];
      for (let val in list) {
        if(list[val].flag==1){
            this.resultText.push(list[val].userId);  
             this.values=this.resultText.toString();  
             const count=this.resultText.length;  
        }
        
      }
    }
  }
   
  });

  this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}
updatepartner(id){
  
  if(this.resultText.length>0){
   
    let formData= {
      customerId:id,
      partnerId:this.values,
      createdBy:sessionStorage.getItem("username")
    }

    this.customerService.updatePartner(formData).subscribe(data=>{
      if(data.statusCode==200){
        this.modalService.dismissAll();
        Swal.fire({
          html: '<pre>' + 'Partner has been updated successfully' + '</pre>',
          customClass: {
            popup: 'format-pre'
          }
        }).then((value) => {
          window.location.reload();
        });; 
   // this.transactionDetail = data;
    setTimeout(function() {
  }.bind(this), 3000);
  }
},err => {
    if(err.error.statusCode==406){
      this.router.navigate(['/login']);
    }else if(err.error.statusCode==500){
      this.router.navigate(['/page-error']);
    }else if(err.error.statusCode==404){
      this.router.navigate(['/pagenotfound']);
    }
});
  }else{
    this.errorMessage="Please select atleast one partner!";
  }
}

openPaymentMode(content,id){
  this.editUserId=id;
 
  this.paymentModeServices.paymentmode().subscribe(data=>{
    this.paymentMode = data;      
  });
  
   this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
     this.closeResult = `Closed with: ${result}`;
   }, (reason) => {
     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
   });
 }

 viewPaymentMode(content,model,id){
  this.editUserId=id;
  this.assignedPaymentMode=model;
   this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
     this.closeResult = `Closed with: ${result}`;
   }, (reason) => {
     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
   });
 }

 
 addPaymentMode(id){
     
  if(this.resultText.length>0){
    let payLaterDays='0' ;
    let maxCreditLimits ='0';

    if(this.partnerCredit){
       payLaterDays = (<HTMLInputElement>document.getElementById('payLaterDays')).value;
       maxCreditLimits = (<HTMLInputElement>document.getElementById('maxCreditLimits')).value;
     }
      let formData= {
        customerId:id,
        paymentModeIds:this.values,
        payLaterDays:payLaterDays,
        maxCreditLimits:maxCreditLimits,
        createdBy:sessionStorage.getItem("username")
      }

      this.customerService.addPaymentMode(formData).subscribe(data=>{
        if(data.statusCode==200){
          this.modalService.dismissAll();
          Swal.fire({
            html: '<pre>' + 'Payment mode has been assigned successfully' + '</pre>',
            customClass: {
              popup: 'format-pre'
            }
          }).then((value) => {
            window.location.reload();
          });; 
     // this.transactionDetail = data;
      setTimeout(function() {
    }.bind(this), 3000);
    }
  },err => {
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }else if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
  });
    }else{
      this.errorMessage="Please select atleast one payment mode!";
    }
 
}


editPaymentMode(content,model,id){
  this.customerService.assignedPaymentMode(id).subscribe(data =>{
    this.paymentMode=data;
    for (let key in data.response) {
         
        let list = data.response[key];

        console.log(list['status']);
        console.log(list['id']);

        if(list['status']==1){
              this.resultText.push(list['id']);  
               this.values=this.resultText.toString();  
               const count=this.resultText.length;  
        }
       
    }
    
  });
  this.assignedPaymentMode=model;
  
  this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

updatePaymentMode(id){
 
  if(this.resultText.length>0){
    let payLaterDays = (<HTMLInputElement>document.getElementById('payLaterDays')).value;
    let maxCreditLimits = (<HTMLInputElement>document.getElementById('maxCreditLimits')).value;

      let formData= {
        customerId:id,
        paymentModeIds:this.values,
        payLaterDays:payLaterDays,
        maxCreditLimits:maxCreditLimits,
        createdBy:sessionStorage.getItem("username")
      }

      this.customerService.updatePaymentMode(formData).subscribe(data=>{
        if(data.statusCode==200){
          this.modalService.dismissAll();
          Swal.fire({
            html: '<pre>' + 'Payment mode has been assigned successfully' + '</pre>',
            customClass: {
              popup: 'format-pre'
            }
          }).then((value) => {
            window.location.reload();
          });; 
     // this.transactionDetail = data;
      setTimeout(function() {
    }.bind(this), 3000);
    }
  },err => {
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }else if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
  });
    }else{
      this.errorMessage="Please select atleast one payment mode!";
    }
}


}
