import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { FuelTypeModel } from '../model/fueltype';
import { RestResponseModel } from '../model/response';
import { BankMasterModel } from '../model/bankmaster';
import { environment } from 'src/environments/environment';
import { GenericResponseModel } from '../model/genricresponse';

@Injectable({
    providedIn: "root"
  })
  export class BankMasterService {

    constructor(private httpClient:HttpClient) {}

  private baseUrl = environment.devurl;
  private auth = environment.headers;

    public getBankMaster(){
      // const headers = this.auth;
      let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
      const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/api/getBankMaster",{headers});
  }

  public deleteBankMaster(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.delete<RestResponseModel>(this.baseUrl+"/api/deleteBankMaster"+"/"+id,{headers});
  }

  public addBankMaster(bank){
   // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.post<RestResponseModel>(this.baseUrl+"/api/addBankMaster",bank,{headers});
  }

  public getBankMasterById(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<BankMasterModel>(this.baseUrl+"/api/getBankMasterById"+"/"+id,{headers});
  }

  public updateBankMaster(bank){
   // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.put<RestResponseModel>(this.baseUrl+"/api/updateBankMaster",bank,{headers});
  }

  }