import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { FuelTypeModel } from '../model/fueltype';
import { RestResponseModel } from '../model/response';
import { environment } from 'src/environments/environment';
import { GenericResponseModel } from '../model/genricresponse';

@Injectable({
    providedIn: "root"
  })
  export class FuelTypeService {

    constructor(private httpClient:HttpClient) {}

  private baseUrl = environment.devurl;
  private auth = environment.headers;

    public getFuelTypes(){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<GenericResponseModel>(this.baseUrl+"/api/getFuelTypes",{headers});
  }

  public deleteFuelType(id){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.delete<RestResponseModel>(this.baseUrl+"/api/deletefuelType"+"/"+id,{headers});
  }

  public addFuelType(fuel){
   // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.post<RestResponseModel>(this.baseUrl+"/api/addFuelType",fuel,{headers});
  }

  public getFuelTypeById(id){
   // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<FuelTypeModel>(this.baseUrl+"/api/getFuelTypeById"+"/"+id,{headers});
  }

  public updateFuelType(fuel){
   // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.put<RestResponseModel>(this.baseUrl+"/api/updateFuelType",fuel,{headers});
  }

  }