<app-header></app-header>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
</ngx-spinner>
<div class="bg-white">
  <div class="container">
    <ol class="breadcrumb breadcrumb-alt">
      <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Home</a></li>
      <li class="breadcrumb-item active"><span class="font-montserrat fs-11 all-caps">Partners</span></li>
    </ol>
  </div>
</div>
<div class="container">
  <!-- START BREADCRUMBS -->
 
  <!-- END BREADCRUMBS -->
<div style="display: none;">{{fuelMarkerHistory.responseMessage}}</div>
<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
  <li [ngbNavItem]="1">
    <a ngbNavLink>Fuel price reset history</a>
    <ng-template ngbNavContent>
      <div class="card-body">
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th align="center" >Sl.No.</th>
                      <th align="left"  >Partner Name</th>
                      <th align="center" >Vehicle Number</th>
                      <th align="center" >Model Name</th>
                      <th align="center" >Fuel</th>
                      <th align="center" >Pincode</th>
                      <th align="center" >Prize per litre</th>
                      <th align="center" >Updated By</th>
                      <th align="center" >Updated Date</th>
                      <!-- <th align="center" >Action</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let fuelprize of fuelMarkerHistory.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td align="left" class="v-align-middle">{{fuelprize.vehicleId.partnerName}}</td>
                      <td align="left" class="v-align-middle" >{{fuelprize.vehicleId.vehicleNumber}}</td>
                      <td align="left" class="v-align-middle">{{fuelprize.vehicleId.vehicleName}}</td>
                      <td align="center" class="v-align-middle">{{fuelprize.fuelType.fuelTypeName}}</td>
                      <td align="center" class="v-align-middle">{{fuelprize.vehicleId.vehicleRunningPincode}}</td>
                      <td align="center" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{fuelprize.price | number : '1.2-2'}}</td>
                      <td align="left" class="v-align-middle">{{fuelprize.submittedBy}}</td>
                      <td align="left" class="v-align-middle">{{fuelprize.currentDatetime | date:'dd/MM/yyyy'}}</td>
                      <!-- <td class="v-align-middle"><button _ngcontent-hir-c109="" routerlink="#" class="btn btn-link" tabindex="0" ng-reflect-router-link="#"><i _ngcontent-hir-c109="" aria-hidden="true" class="fa fa-eye"></i>&nbsp;View </button></td> -->
                    </tr>
                  </tbody>
                </table>
          </div>
            </div>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>