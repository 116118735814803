import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { UserModel } from '../model/user';
import { RestResponseModel } from '../model/response';
import { LoginModel } from '../model/login';
import { ChangePasswordModel } from '../model/changepassword';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: "root"
  })
  export class ForgotPasswordService {

    constructor(private httpClient:HttpClient) {}

  private baseUrl = environment.devurl;
  private auth = environment.headers;

  public verifyEmail(login:LoginModel){
   // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.post<RestResponseModel>(this.baseUrl+"/forgotpasswordweb/verifyemail",login,{headers});
  }

  public verifyOTP(login:LoginModel){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.post<RestResponseModel>(this.baseUrl+"/forgotpasswordweb/verifyotp",login,{headers});
  }

  public resendOtp(login:LoginModel){
   // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.post<RestResponseModel>(this.baseUrl+"/forgotpasswordweb/resendotp",login,{headers});
  }

  public changePassword(changepassword:ChangePasswordModel){
   // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.post<RestResponseModel>(this.baseUrl+"/forgotpasswordweb/change",changepassword,{headers});
  }

  }