<app-header></app-header>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
</ngx-spinner>
<ol class="breadcrumb breadcrumb-alt" style="margin-top:100px; width: auto;" >
  <li class="breadcrumb-item" style="margin-left: 15px;"><a href="#" routerLink="/dashboard">Home</a></li>
  <li class="breadcrumb-item active">Vehicle View</li>
  <div class="btn-section-wrapper">
    <a href="#" routerLink="/vehicles-fuels" class="back_page"><span class="label label-info"><i class="back_page"></i>Back</span></a>
</div>
</ol>
<div class="container  container-top-dashboard-section">
  <!-- <a href="#" routerLink="/vehicles-fuels" class="back_page"><span class="label label-info"><i class="back_page"></i>Back</span></a> -->
   <div class="alert alert-success box-msg" role="alert"
            *ngIf="successflag">{{message}}</div>
   <div class="card mb-3" style="margin-top: 20px; " #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
   <div class="flex">
      <div class="col1 flex">
         <div class=" profile images flex">
            <img src="assets/img/profile.jpg" data-src="assets/img/profile.jpg" data-src-retina="assets/img/profile.jpg" width="150px" >
         </div>
         <div class="profile-info flex">
          <div class="card-body profile">
            <p class="card-title">Partner Name<br>
              <span *ngIf="vehiclePaymentModel.response.id!=null">{{vehiclePaymentModel.response.vehicleId.partnerName}}</span>
              <span *ngIf="vehiclePaymentModel.response.id==null">{{vehiclePaymentModel.response.partnerName}}</span>
            </p>
            <p class="card-title">Status<br>
              <span *ngIf="vehiclePaymentModel.response.id!=null">
                <span *ngIf="vehiclePaymentModel.response.vehicleId.vehicleStatus===3" class="v-align-middle ht-active">Active</span>
                     <span *ngIf="vehiclePaymentModel.response.vehicleId.vehicleStatus===4" class="v-align-middle ht-inactive">InActive</span>
              </span>
              <span *ngIf="vehiclePaymentModel.response.id==null">
                <span *ngIf="vehiclePaymentModel.response.vehicleStatus===3" class="v-align-middle ht-active">Active</span>
                      <span *ngIf="vehiclePaymentModel.response.vehicleStatus===4" class="v-align-middle ht-inactive">InActive</span>
              </span>
            </p>
            <p class="card-title">Added Date & Time<br>
              <span *ngIf="vehiclePaymentModel.response.id!=null">{{vehiclePaymentModel.response.vehicleId.submittedDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</span>
              <span *ngIf="vehiclePaymentModel.response.id==null">{{vehiclePaymentModel.response.submittedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span>
            </p> 
          </div>
          <div class="card-body profile">
            <p class="card-title">Vehicle Number<br>
              <span *ngIf="vehiclePaymentModel.response.id!=null">{{vehiclePaymentModel.response.vehicleId.vehicleNumber}}</span>
              <span *ngIf="vehiclePaymentModel.response.id==null">{{vehiclePaymentModel.response.vehicleNumber}}</span>
          </p>
          <p class="card-title">AMC/MMC Type<br>
            <span *ngIf="vehiclePaymentModel.response.id!=null">
              <span *ngIf="vehiclePaymentModel.response.vehicleId.amcMmcType!=null">{{vehiclePaymentModel.response.vehicleId.amcMmcType}}</span>
              <span *ngIf="vehiclePaymentModel.response.vehicleId.amcMmcType==null">N/A</span>
            </span>
            <span *ngIf="vehiclePaymentModel.response.id==null">
              <span *ngIf="vehiclePaymentModel.response.amcMmcType!=null">{{vehiclePaymentModel.response.amcMmcType}}</span>
              <span *ngIf="vehiclePaymentModel.response.amcMmcType==null">N/A</span>
            </span>
          </p> 
          <p class="card-title">Model Name<br>
            <span *ngIf="vehiclePaymentModel.response.id!=null">{{vehiclePaymentModel.response.vehicleId.vehicleName}}</span>
            <span *ngIf="vehiclePaymentModel.response.id==null">{{vehiclePaymentModel.response.vehicleName}}</span>
        </p>
          </div>

          <div class="card-body profile">
            <p class="card-title">DSA Name<br>
              <span *ngIf="vehiclePaymentModel.response.id!=null">
                <span *ngIf="vehiclePaymentModel.response.vehicleId.assignDSA!=null">{{vehiclePaymentModel.response.vehicleId.assignDSA.firstName}} {{vehiclePaymentModel.response.vehicleId.assignDSA.lastName}}</span>
                <span *ngIf="vehiclePaymentModel.response.vehicleId.assignDSA==null">N/A</span>
              </span>
              <span *ngIf="vehiclePaymentModel.response.id==null">
                <span *ngIf="vehiclePaymentModel.response.assignDSA!=null">{{vehiclePaymentModel.response.assignDSA.firstName}} {{vehiclePaymentModel.response.assignDSA.lastName}}</span>
                <span *ngIf="vehiclePaymentModel.response.assignDSA==null">N/A</span>
              </span>
            </p> 
            <p class="card-title">Fuel<br>
              <span *ngIf="vehiclePaymentModel.response.id!=null">{{vehiclePaymentModel.response.vehicleId.fuelType.fuelTypeName}}</span>
              <span *ngIf="vehiclePaymentModel.response.id==null">{{vehiclePaymentModel.response.fuelType.fuelTypeName}}</span>
            </p>
            <p class="card-title">Activation Date<br>
              <span *ngIf="vehiclePaymentModel.response.id!=null">
                <span *ngIf="vehiclePaymentModel.response.vehicleId.activationDate!=null">{{vehiclePaymentModel.response.vehicleId.activationDate | date:'dd/MM/yyyy'}}</span>
                <span *ngIf="vehiclePaymentModel.response.vehicleId.activationDate==null">N/A</span>
              </span>
              <span *ngIf="vehiclePaymentModel.response.id==null">
                <span *ngIf="vehiclePaymentModel.response.activationDate!=null">{{vehiclePaymentModel.response.activationDate | date:'dd/MM/yyyy'}}</span>
                <span *ngIf="vehiclePaymentModel.response.activationDate==null">N/A</span>
              </span>
            </p> 
          </div>
          <div class="card-body profile">
            <p class="card-title">Tank Capacity in litre<br>
              <span *ngIf="vehiclePaymentModel.response.id!=null">{{vehiclePaymentModel.response.vehicleId.tankCapacity}}</span>
              <span *ngIf="vehiclePaymentModel.response.id==null">{{vehiclePaymentModel.response.tankCapacity}}</span>
            </p>
            <p class="card-title">Available Fuel in litre<br>
              <span *ngIf="vehiclePaymentModel.response.id!=null">{{vehiclePaymentModel.response.vehicleId.fuelStock}}</span>
              <span *ngIf="vehiclePaymentModel.response.id==null">{{vehiclePaymentModel.response.fuelStock}}</span>
            </p>
            <p class="card-title">Vehicle On/Off Status<br>
              <span *ngIf="vehiclePaymentModel.response.id!=null">
                <span *ngIf="vehiclePaymentModel.response.vehicleId.vehicleonoffStatus!=null">
                  <span *ngIf="vehiclePaymentModel.response.vehicleId.vehicleonoffStatus==1">On</span>
                  <span *ngIf="vehiclePaymentModel.response.vehicleId.vehicleonoffStatus==0">Off</span>
                </span>
                <span *ngIf="vehiclePaymentModel.response.vehicleId.vehicleonoffStatus==null">N/A</span>
              </span>
              <span *ngIf="vehiclePaymentModel.response.id==null">
                <span *ngIf="vehiclePaymentModel.response.vehicleonoffStatus!=null">
                  <span *ngIf="vehiclePaymentModel.response.vehicleonoffStatus==1">On</span>
                  <span *ngIf="vehiclePaymentModel.response.vehicleonoffStatus==0">Off</span>
                </span>
                <span *ngIf="vehiclePaymentModel.response.vehicleonoffStatus==null">N/A</span>
              </span>
            </p> 
          </div>
          <div class="card-body profile">
            <p class="card-title">Vehicle ID<br>
              <span *ngIf="vehiclePaymentModel.response.id!=null">{{vehiclePaymentModel.response.vehicleId.vehicleCode}}</span>
              <span *ngIf="vehiclePaymentModel.response.id==null">{{vehiclePaymentModel.response.vehicleCode}}</span>
          </p>
          </div>
         </div>
      </div>
   </div>
</div>
<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
  <li [ngbNavItem]="1">
    <div style="display: none;">{{customerOrder.responseMessage}}</div>
    <a ngbNavLink>Orders</a>
    <ng-template ngbNavContent>
      <br>
        <button style="margin-left: 300px;"  (click)="filterStatus(1)" class="btn-transaction0">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios" id="penradio" value="option2" checked>
            <label class="form-check-label" for="penradio">
              Pending
            </label>
          </div>
          </button>  
          <button (click)="filterStatus(2)" class="btn-transaction1">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="exampleRadios" id="inpradio" value="option3">
              <label class="form-check-label" for="inpradio">
                Inprogress
              </label>
            </div>
            </button>  
            <button (click)="filterStatus(3)" class="btn-transaction2">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="exampleRadios" id="comradio" value="option4">
                <label class="form-check-label" for="comradio">
                  Delivered
                </label>
              </div>
              </button>   
      <button (click)="filterStatus(4)" class="btn-transaction3">
        <div class="form-check">
          <input class="form-check-input" type="radio" name="exampleRadios" id="canradio" value="option5">
          <label class="form-check-label" for="canradio">
            Cancelled
          </label>
        </div>
        </button>
        <button (click)="filterStatus(0)" class="btn-transaction">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios" id="allradio" value="option1">
            <label class="form-check-label" for="allradio">
              All
            </label>
          </div>
          </button>
          
                
                     
      <!-- <button (click)="cancelled()" class="pen-style3">Cancelled</button>
      <button (click)="completed()" class="pen-style2">Completed</button>
      <button (click)="inprogress()" class="pen-style1">Inprogress</button>
      <button (click)="pending()" class="pen-style">Pending</button>
      <button (click)="all()" class="pen-style3">All</button> -->
      <!-- </div> -->
      
      <br>
      <div *ngIf="defaultCustomer" class="card-body">
        <div style="display: none;">{{customerOrder.responseMessage}}</div>
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th align="left" >Order ID </th>
                      <th align="left" >Delivery Agent Name</th>
                      <th align="left" >Vehicle number</th>
                      <th align="center" >Fuel</th>
                      <th style="text-align: right;">Quantity in Ltrs</th>
                      <th align="left" >Booked Date Time </th>
                      <th align="left" >DeliveryDate Time</th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: center;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let order of customerOrder.response; let i=index">
                        <td align="center" class="v-align-middle ">{{i + 1}}</td>
                        <td align="left" class="v-align-middle">{{order.orderId}}</td>
                        <td *ngIf="order.orderAcceptedBy!=null" align="left"style="word-wrap: break-word;"  class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
                      <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.vehicleId!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
                        <td *ngIf="order.vehicleId==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">{{order.fuelType}}</td>
                        <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                        <td *ngIf="order.quantity!=null" align="right" class="v-align-middle">{{order.quantity}}</td>
                        <td *ngIf="order.quantity==null" align="right" class="v-align-middle">N/A</td>
                        <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                        <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                        <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                        <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                        <td align="left" class="v-align-middle">
                          <button title="View" class="label label-info" (click)="open(content,order)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                          <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                          <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.status==='Pending' || order.status==='Inprogress'" class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                          <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="invoice(order.id)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
                        </td>
                      </tr>
                  </tbody>
                </table>
          </div>
        </div>

        <!-- -----All ----- -->
        <div *ngIf="allCustomer" class="card-body">
          <div style="display: none;">{{allcustomerOrder.responseMessage}}</div>
          <div class="table-responsive">
            <table datatable class="ch-table">
              <thead>
                <tr> 
                  <th style="text-align: center;">Sl.No.</th>
                  <th align="left" >Order ID </th>
                  <th align="left" >Delivery Agent Name</th>
                  <th align="left" >Vehicle number</th>
                  <th align="center" >Fuel</th>
                  <th style="text-align: right;">Quantity in Ltrs</th>
                  <th align="left" >Booked Date Time </th>
                  <th align="left" >DeliveryDate Time</th>
                  <th style="text-align: center;">Status</th>
                  <th style="text-align: center;">Action</th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let order of allcustomerOrder.response; let i=index">
                    <td align="center" class="v-align-middle ">{{i + 1}}</td>
                    <td align="left" class="v-align-middle">{{order.orderId}}</td>
                    <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
                      <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td>
                    <td *ngIf="order.vehicleId==null" class="v-align-middle">
                      N/A
                    </td>
                    <td *ngIf="order.vehicleId!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
                    <td *ngIf="order.vehicleId==null" align="left" class="v-align-middle">N/A</td>
                    <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">{{order.fuelType}}</td>
                    <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                    <td *ngIf="order.quantity!=null" align="right" class="v-align-middle">{{order.quantity}}</td>
                    <td *ngIf="order.quantity==null" align="right" class="v-align-middle">N/A</td>
                    <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                    <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                    <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                    <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                    <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                    <td align="left" class="v-align-middle">
                      <button title="View" class="label label-info" (click)="open(content,order)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                      <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                      <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.status==='Pending' || order.status==='Inprogress'" class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                      <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="invoice(order.id)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
                    </td>
                  </tr>
              </tbody>
            </table>
      </div>
    </div>

        <!-- -----Pending ----- -->
        <div *ngIf="penCustomer" class="card-body">
          <div style="display: none;">{{pencustomerOrder.responseMessage}}</div>
          <div class="table-responsive">
            <table datatable class="ch-table">
              <thead>
                <tr> 
                  <th style="text-align: center;">Sl.No.</th>
                  <th align="left" >Order ID </th>
                  <th align="left" >Delivery Agent Name</th>
                  <th align="left" >Vehicle number</th>
                  <th align="center" >Fuel</th>
                  <th style="text-align: right;">Quantity in Ltrs</th>
                  <th align="left" >Booked Date Time </th>
                  <th align="left" >DeliveryDate Time</th>
                  <th style="text-align: center;">Status</th>
                  <th style="text-align: center;">Action</th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let order of pencustomerOrder.response; let i=index">
                    <td align="center" class="v-align-middle ">{{i + 1}}</td>
                    <td align="left" class="v-align-middle">{{order.orderId}}</td>
                    <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
                    <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td>
                    <td *ngIf="order.vehicleId!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
                    <td *ngIf="order.vehicleId==null" align="left" class="v-align-middle">N/A</td>
                    <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">{{order.fuelType}}</td>
                    <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                    <td *ngIf="order.quantity!=null" align="right" class="v-align-middle">{{order.quantity}}</td>
                    <td *ngIf="order.quantity==null" align="right" class="v-align-middle">N/A</td>
                    <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                    <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                    <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                    <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                    <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                    <td align="left" class="v-align-middle">
                      <button title="View" class="label label-info" (click)="open(content,order)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                      <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                      <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.status==='Pending' || order.status==='Inprogress'" class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                      <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="invoice(order.id)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
                    </td>
                  </tr>
              </tbody>
            </table>
      </div>
    </div>

    <!-- ------ inprogress------------ -->

    <div *ngIf="inpCustomer" class="card-body">
      <div style="display: none;">{{inpcustomerOrder.responseMessage}}</div>
      <div class="table-responsive">
        <table datatable class="ch-table">
          <thead>
            <tr> 
              <th style="text-align: center;">Sl.No.</th>
              <th align="left" >Order ID </th>
              <th align="left" >Delivery Agent Name</th>
              <th align="left" >Vehicle number</th>
              <th align="center" >Fuel</th>
              <th style="text-align: right;">Quantity in Ltrs</th>
              <th align="left" >Booked Date Time </th>
              <th align="left" >DeliveryDate Time</th>
              <th style="text-align: center;">Status</th>
              <th style="text-align: center;">Action</th>
            </tr>
          </thead>
          <tbody>
              <tr *ngFor="let order of inpcustomerOrder.response; let i=index">
                <td align="center" class="v-align-middle ">{{i + 1}}</td>
                <td align="left" class="v-align-middle">{{order.orderId}}</td>
                <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
                      <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td>
                <td *ngIf="order.vehicleId!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
                <td *ngIf="order.vehicleId==null" align="left" class="v-align-middle">N/A</td>
                <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">{{order.fuelType}}</td>
                <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                <td *ngIf="order.quantity!=null" align="right" class="v-align-middle">{{order.quantity}}</td>
                <td *ngIf="order.quantity==null" align="right" class="v-align-middle">N/A</td>
                <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                <td align="left" class="v-align-middle">
                  <button title="View" class="label label-info" (click)="open(content,order)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                  <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                  <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.status==='Pending' || order.status==='Inprogress'" class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                  <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="invoice(order.id)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
                </td>
              </tr>
          </tbody>
        </table>
  </div>
</div>

     <!-- ----completed----- -->
     <div *ngIf="comCustomer" class="card-body">
      <div style="display: none;">{{comcustomerOrder.responseMessage}}</div>
      <div class="table-responsive">
        <table datatable class="ch-table">
          <thead>
            <tr> 
              <th style="text-align: center;">Sl.No.</th>
              <th align="left" >Order ID </th>
              <th align="left" >Delivery Agent Name</th>
              <th align="left" >Vehicle number</th>
              <th align="center" >Fuel</th>
              <th style="text-align: right;">Quantity in Ltrs</th>
              <th align="left" >Booked Date Time </th>
              <th align="left" >DeliveryDate Time</th>
              <th style="text-align: center;">Status</th>
              <th style="text-align: center;">Action</th>
            </tr>
          </thead>
          <tbody>
              <tr *ngFor="let order of comcustomerOrder.response; let i=index">
                <td align="center" class="v-align-middle ">{{i + 1}}</td>
                <td align="left" class="v-align-middle">{{order.orderId}}</td>
                <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
                <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td>
                <td *ngIf="order.vehicleId!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
                <td *ngIf="order.vehicleId==null" align="left" class="v-align-middle">N/A</td>
                <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">{{order.fuelType}}</td>
                <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                <td *ngIf="order.quantity!=null" align="right" class="v-align-middle">{{order.quantity}}</td>
                <td *ngIf="order.quantity==null" align="right" class="v-align-middle">N/A</td>
                <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                <td align="left" class="v-align-middle">
                  <button title="View" class="label label-info" (click)="open(content,order)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                  <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                  <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.status==='Pending' || order.status==='Inprogress'" class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                  <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="invoice(order.id)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
                </td>
              </tr>
          </tbody>
        </table>
  </div>
</div>

<!-- -----Cancelled----- -->
<div *ngIf="canCustomer" class="card-body">
  <div style="display: none;">{{cancustomerOrder.responseMessage}}</div>
  <div class="table-responsive">
    <table datatable class="ch-table">
      <thead>
        <tr> 
          <th style="text-align: center;">Sl.No.</th>
          <th align="left" >Order ID </th>
          <th align="left" >Delivery Agent Name</th>
          <th align="left" >Vehicle number</th>
          <th align="center" >Fuel</th>
          <th style="text-align: right;" >Quantity in Ltrs</th>
          <th align="left" >Booked Date Time </th>
          <th align="left" >DeliveryDate Time</th>
          <th style="text-align: center;">Status</th>
          <th style="text-align: center;">Action</th>
        </tr>
      </thead>
      <tbody>
          <tr *ngFor="let order of cancustomerOrder.response; let i=index">
            <td align="center" class="v-align-middle ">{{i + 1}}</td>
            <td align="left" class="v-align-middle">{{order.orderId}}</td>
            <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
            <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td>
            <td *ngIf="order.vehicleId!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
            <td *ngIf="order.vehicleId==null" align="left" class="v-align-middle">N/A</td>
            <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">{{order.fuelType}}</td>
            <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
            <td *ngIf="order.quantity!=null" align="right" class="v-align-middle">{{order.quantity}}</td>
            <td *ngIf="order.quantity==null" align="right" class="v-align-middle">N/A</td>
            <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
            <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
            <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
            <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
            <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
            <td align="left" class="v-align-middle">
              <button title="View" class="label label-info" (click)="open(content,order)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
              <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
              <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.status==='Pending' || order.status==='Inprogress'" class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
              <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="invoice(order.id)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
            </td>
          </tr>
      </tbody>
    </table>
</div>
</div>
    </ng-template>
  </li>

  
  <li [ngbNavItem]="2">
    <a ngbNavLink> GPS Info </a>
    <ng-template ngbNavContent>
      <br>
      <div >
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">GPS Vehicle Number </th>
                      <th style="text-align: left;">GPS Serial Number</th>
                      <th style="text-align: left;">GPS SIM Number</th>
                      <th style="text-align: left;">GPS Unique ID</th>
                      <th style="text-align: left;">GPS Client ID</th>
                      <th style="text-align: center;">GPS Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr >
                      <td align="center" class="v-align-middle ">1</td>
                      <td align="left" class="v-align-middle">
                        <span *ngIf="vehiclePaymentModel.response.id!=null">
                          <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsVehicleNumber==''">N/A</span>
                          <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsVehicleNumber!=null">{{vehiclePaymentModel.response.vehicleId.gpsVehicleNumber}}</span>
                          <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsVehicleNumber==null">N/A</span>
                        </span>
                        <span *ngIf="vehiclePaymentModel.response.id==null">
                          <span *ngIf="vehiclePaymentModel.response.gpsVehicleNumber==''">N/A</span>
                          <span *ngIf="vehiclePaymentModel.response.gpsVehicleNumber!=null">{{vehiclePaymentModel.response.gpsVehicleNumber}}</span>
                          <span *ngIf="vehiclePaymentModel.response.gpsVehicleNumber==null">N/A</span>
                        </span>
                      </td>
                      <td class="v-align-middle">
                        <span *ngIf="vehiclePaymentModel.response.id!=null">
                          <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsSerialNumber==''">N/A</span>
                          <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsSerialNumber!=null">{{vehiclePaymentModel.response.vehicleId.gpsSerialNumber}}</span>
                          <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsSerialNumber==null">N/A</span>
                        </span>
                        <span *ngIf="vehiclePaymentModel.response.id==null">
                          <span *ngIf="vehiclePaymentModel.response.gpsSerialNumber==''">N/A</span>
                          <span *ngIf="vehiclePaymentModel.response.gpsSerialNumber!=null">{{vehiclePaymentModel.response.gpsSerialNumber}}</span>
                          <span *ngIf="vehiclePaymentModel.response.gpsSerialNumber==null">N/A</span>
                          </span>
                      </td>
                      <td align="left" class="v-align-middle">
                        <span *ngIf="vehiclePaymentModel.response.id!=null">
                          <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsSimNumber==''">N/A</span>
                          <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsSimNumber!=null">{{vehiclePaymentModel.response.vehicleId.gpsSimNumber}}</span>
                          <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsSimNumber==null">N/A</span>
                        </span>
                        <span *ngIf="vehiclePaymentModel.response.id==null">
                          <span *ngIf="vehiclePaymentModel.response.gpsSimNumber==''">N/A</span>
                          <span *ngIf="vehiclePaymentModel.response.gpsSimNumber!=null">{{vehiclePaymentModel.response.gpsSimNumber}}</span>
                          <span *ngIf="vehiclePaymentModel.response.gpsSimNumber==null">N/A</span>
                        </span>    
                      </td>
                      <td align="left" class="v-align-middle">
                        <span *ngIf="vehiclePaymentModel.response.id!=null">
                          <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsUniqueId==''">N/A</span>
                          <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsUniqueId!=null"> {{vehiclePaymentModel.response.vehicleId.gpsUniqueId}}</span>
                          <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsUniqueId==null"> N/A</span>
                        </span>
                        <span *ngIf="vehiclePaymentModel.response.id==null">
                          <span *ngIf="vehiclePaymentModel.response.gpsUniqueId==''">N/A</span>
                          <span *ngIf="vehiclePaymentModel.response.gpsUniqueId!=null">{{vehiclePaymentModel.response.gpsUniqueId}}</span>
                          <span *ngIf="vehiclePaymentModel.response.gpsUniqueId==null">N/A</span>
                        </span>
                      </td>
                      <td align="left" class="v-align-middle">
                        <span *ngIf="vehiclePaymentModel.response.id!=null">
                          <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsClientId==''">N/A</span>
                          <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsClientId!=null">
                            {{vehiclePaymentModel.response.vehicleId.gpsClientId}}
                          </span>
                          <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsClientId==null">N/A</span>
                        </span>
                        <span *ngIf="vehiclePaymentModel.response.id==null">
                          <span *ngIf="vehiclePaymentModel.response.gpsClientId==''">N/A</span>
                          <span *ngIf="vehiclePaymentModel.response.gpsClientId!=null">{{vehiclePaymentModel.response.gpsClientId}}</span>
                          <span *ngIf="vehiclePaymentModel.response.gpsClientId==null">N/A</span>
                        </span>
                      </td>
                      <td align="center" class="v-align-middle">
                        <span *ngIf="vehiclePaymentModel.response.id!=null">
                          <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsStatus!=null">
                          <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsStatus==11" class="ht-Success">Active</span>
                          <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsStatus==12" class="ht-Pending">InActive</span>
                          <span *ngIf="vehiclePaymentModel.response.vehicleId.gpsStatus==0">N/A</span>
                        </span>
                        <span  *ngIf="vehiclePaymentModel.response.vehicleId.gpsStatus==null">N/A</span>
                        </span>
                        <span *ngIf="vehiclePaymentModel.response.id==null">
                          <span *ngIf="vehiclePaymentModel.response.gpsStatus!=null">
                          <span *ngIf="vehiclePaymentModel.response.gpsStatus==11" class="ht-Success" >Active</span>
                          <span *ngIf="vehiclePaymentModel.response.gpsStatus==12" class="ht-Pending" >InActive</span>
                          <span *ngIf="vehiclePaymentModel.response.gpsStatus==0">N/A</span>
                        </span>
                        <span  *ngIf="vehiclePaymentModel.response.gpsStatus==null">N/A</span>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
          </div>
        </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="3">
    <a ngbNavLink> Setup Fee Payment Info </a>
    <ng-template ngbNavContent>
      <br>
      <div >
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Payment Amount</th>
                      <th style="text-align: left;">Transaction ID</th>
                      <th style="text-align: center;">Payment Status</th>
                      <th style="text-align: left;">Transaction Date & Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr >
                      <td align="center" class="v-align-middle ">1</td>
                      <td align="left" class="v-align-middle">
                        <span *ngIf="vehiclePaymentModel.response.id!=null">
                          <span><i class="fa fa-inr" aria-hidden="true"></i>{{vehiclePaymentModel.response.totalPayableAmount | number : '1.2-2'}}</span>
                        </span>
                        <span *ngIf="vehiclePaymentModel.response.id==null">
                          N/A
                        </span>
                      </td>
                      <td class="v-align-middle">
                        <span *ngIf="vehiclePaymentModel.response.id!=null">
                          <span *ngIf="vehiclePaymentModel.response.paymentStatus==='cancelled'; else ifNotShow" >N/A</span>
                          <ng-template #ifNotShow>
                            <span *ngIf="vehiclePaymentModel.response.paymentId==='null'">N/A</span>
                            <span *ngIf="vehiclePaymentModel.response.paymentId!=null">{{vehiclePaymentModel.response.paymentId}}</span>
                            <span *ngIf="vehiclePaymentModel.response.paymentId==null">N/A</span>
                          </ng-template>
                        </span>
                        <span *ngIf="vehiclePaymentModel.response.id==null">
                          N/A
                        </span>
                      </td>
                      <td align="center" class="v-align-middle">
                        <span *ngIf="vehiclePaymentModel.response.id!=null">
                          <span class="v-align-middle ht-Success" *ngIf="vehiclePaymentModel.response.id!=null">
                            <span *ngIf="vehiclePaymentModel.response.paymentStatus!=null">
                              <span *ngIf="vehiclePaymentModel.response.paymentStatus==='Success' "class="v-align-middle ht-active">{{vehiclePaymentModel.response.paymentStatus}}</span>
                              <span *ngIf="vehiclePaymentModel.response.paymentStatus==='Failure' " style="color: red;">Failed</span>
                              <span *ngIf="vehiclePaymentModel.response.paymentStatus==='Initiated' "class="v-align-middle ht-Pending">{{vehiclePaymentModel.response.paymentStatus}}</span>
                              <span *ngIf="vehiclePaymentModel.response.paymentStatus==='Pending' "class="v-align-middle ht-Pending">{{vehiclePaymentModel.response.paymentStatus}}</span>
                              <span *ngIf="vehiclePaymentModel.response.paymentStatus==='cancelled' " style="color: red;">{{vehiclePaymentModel.response.paymentStatus}}</span>
                            </span>
                            <span *ngIf="vehiclePaymentModel.response.paymentStatus==null">N/A</span>        
                          </span>
                        </span>
                        <span *ngIf="vehiclePaymentModel.response.id==null">
                          N/A
                        </span>
                      </td>
                      <td align="left" class="v-align-middle">
                        <span *ngIf="vehiclePaymentModel.response.id!=null">
                          <span *ngIf="vehiclePaymentModel.response.transactionDatetime!=null">{{vehiclePaymentModel.response.transactionDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span>
                          <span *ngIf="vehiclePaymentModel.response.transactionDatetime==null">N/A</span>
                        </span>
                        <span *ngIf="vehiclePaymentModel.response.id==null">N/A</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
          </div>
        </div>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>


<ng-template #content let-modal>
  <div class="modal-header">
    <h6>Order View Page</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <h5 style="margin-left: 15px; text-align: left; color: grey;">Order Details</h5>
  <div class="container"
  fxLayout
>
<div class="item item-1">
<p><b>Order ID</b></p>
<span>{{orderDetails.response.orderId}}</span>
</div>
<div class="item item-2" style="margin-left: 30px;">
  <p><b>Customer Name</b></p>
  <span *ngIf="orderDetails.response.customerId!=null" style="word-wrap: break-word;">{{orderDetails.response.customerId.firstName}} {{orderDetails.response.customerId.lastName}}</span>
      <span *ngIf="orderDetails.response.customerId==null">N/A</span>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p><b>Customer Mobile</b></p>
  <span *ngIf="orderDetails.response.customerId!=null">{{orderDetails.response.customerId.mobileNumber}}</span>
      <span *ngIf="orderDetails.response.customerId==null">N/A</span>
</div>
<div class="item item-4" style="margin-left: 30px;">
  <p><b>Booking Date & Time</b></p>
  <span *ngIf="orderDetails.response.bookedDate!=null">{{orderDetails.response.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span>
      <span *ngIf="orderDetails.response.bookedDate==null">N/A</span>
</div>
<div class="item item-5" style="margin-left: 30px;">
  <p><b>Quantity in Ltrs</b></p>
  <span *ngIf="orderDetails.response.quantity!=null">{{orderDetails.response.quantity}}</span>
      <span *ngIf="orderDetails.response.quantity==null">N/A</span>
</div>
<div class="item item-7" style="margin-left: 30px;">
  <p><b>Order Type</b></p>
<span *ngIf="orderDetails.response.bookLaterTime===''; else ifNotShow">
  Immediate
</span>
<ng-template #ifNotShow>
  <span>Scheduled</span>
</ng-template>
</div>
<div *ngIf="orderDetails.response.status==='Cancelled'" class="item item-8" style="margin-left: 30px;">
  <p><b>Cancelled Date & Time</b></p>
  <span >{{orderDetails.response.orderCancellationDatetime |date:'dd/MM/yyyy hh:mm:ss a'}}</span>
</div>
<div *ngIf="orderDetails.response.status==='Cancelled'" class="item item-9" style="margin-left: 30px;">
  <p><b>Cancelled By</b></p>
  <span *ngIf="orderDetails.response.cancelledBy!=null">
    <span style="font-size: 10px;">Name :{{orderDetails.response.cancelledBy.firstName}} {{orderDetails.response.cancelledBy.lastName}}</span><br>
    <span style="font-size: 10px;">Mobile :{{orderDetails.response.cancelledBy.mobileNumber}}</span>
  </span>
  <span *ngIf="orderDetails.response.cancelledBy==null">N/A</span>
</div>
<div *ngIf="orderDetails.response.status==='Cancelled'" class="item item-9" style="margin-left: 30px;">
  <p><b>Reason for Cancelling</b></p>
  <span *ngIf="orderDetails.response.cancellationId!=null" style="word-wrap: break-word;">{{orderDetails.response.cancellationId.cancellationMessage}}</span>
  <span *ngIf="orderDetails.response.cancellationId==null">N/A</span>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p><b>Status</b></p>
  <span *ngIf="orderDetails.response.status==='Pending'"><span class="ht-Pending"><b>{{orderDetails.response.status}}</b></span></span>
      <span *ngIf="orderDetails.response.status==='Cancelled'"><span style="color: red;"><b>{{orderDetails.response.status}}</b></span></span>
      <span *ngIf="orderDetails.response.status==='Inprogress'"><span style="color: blue;"><b>{{orderDetails.response.status}}</b></span></span>
      <span *ngIf="orderDetails.response.status==='Delivered'"><span class="ht-active"><b>{{orderDetails.response.status}}</b></span></span>
</div>
  </div>
<br>
  <h5 style="margin-left: 15px; text-align: left; color: grey;">Delivery Agent Info</h5>
  <div class="container"
  fxLayout
>
<div class="item item-1">
<p><b>Delivery Agent Name</b></p>
<span *ngIf="orderDetails.response.orderAcceptedBy!=null" style="word-wrap: break-word;">{{orderDetails.response.orderAcceptedBy.firstName}} {{orderDetails.response.orderAcceptedBy.lastName}}</span>
      <span *ngIf="orderDetails.response.orderAcceptedBy==null">N/A</span>
</div>
<div class="item item-2" style="margin-left: 40px;">
  <p><b>Delivery Agent Number</b></p>
  <span *ngIf="orderDetails.response.orderAcceptedBy!=null">{{orderDetails.response.orderAcceptedBy.mobileNumber}}</span>
      <span *ngIf="orderDetails.response.orderAcceptedBy==null">N/A</span>
</div>
<div class="item item-4" style="margin-left: 40px;">
  <p><b>Vehicle Number</b></p>
  <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.vehicleNumber}}</span>
      <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
</div>
<div class="item item-5" style="margin-left: 40px;">
  <p><b>Fuel</b></p>
  <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.fuelType.fuelTypeName}}</span>
      <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
</div>
<div *ngIf="orderDetails.response.status==='Completed'" class="item item-6" style="margin-left: 40px;">
  <p><b>Delivered  Date Time</b></p>
  <span >{{orderDetails.response.orderDeliveredDateTime |date:'dd/MM/yyyy hh:mm:ss a' }}</span>
</div>
<div class="item item-7" style="margin-left: 40px;">
  <p><b>Delivery location</b></p>
  <span *ngIf="orderDetails.response.deliveryAddress!=null">{{orderDetails.response.deliveryAddress}}</span>
      <span *ngIf="orderDetails.response.deliveryAddress==null">N/A</span>
</div>
  </div>

  <br>
  <h5 *ngIf="orderDetails.response.status==='Completed'" style="margin-left: 15px; text-align: left; color: grey;">Transaction Details</h5>
  <div *ngIf="orderDetails.response.status==='Completed'" class="container"
  fxLayout
>
<div class="item item-1">
  <p *ngIf="orderDetails.response.totalfuelcost!=null"class="card-title"><b>Total Fuel Cost</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.totalfuelcost | number : '1.2-2'}}</span></p>
  <p *ngIf="orderDetails.response.totalfuelcost==null"class="card-title"><b>Total Fuel Cost</b><br><span>N/A</span></p>
</div>
<div class="item item-1" style="margin-left: 30px;">
  <p *ngIf="orderDetails.response.deliveryCharges!=null"class="card-title"><b>Delivery Charges</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.deliveryCharges | number : '1.2-2'}}</span></p>
          <p *ngIf="orderDetails.response.deliveryCharges==null"class="card-title"><b>Delivery Charges</b><br><span>N/A</span></p>
  </div>
  <div class="item item-1" style="margin-left: 30px;">
    <p *ngIf="orderDetails.response.sgst!=null"class="card-title"><b>SGST</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.sgst | number : '1.2-2'}}</span></p>
            <p *ngIf="orderDetails.response.sgst==null"class="card-title"><b>SGST</b><br><span>N/A</span></p>
    </div>
    <div class="item item-1" style="margin-left: 30px;">
      <p *ngIf="orderDetails.response.cgst!=null"class="card-title"><b>CGST</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.cgst | number : '1.2-2'}}</span></p>
              <p *ngIf="orderDetails.response.cgst==null"class="card-title"><b>CGST</b><br><span>N/A</span></p>
      </div>
      <div class="item item-1" style="margin-left: 30px;">
        <p *ngIf="orderDetails.response.internetHandlingFee!=null"class="card-title"><b>Platform Charges</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.internetHandlingFee | number : '1.2-2'}}</span></p>
                <p *ngIf="orderDetails.response.internetHandlingFee==null"class="card-title"><b>Platform Charges</b><br><span>N/A</span></p>
        </div>    
        <div class="item item-1" style="margin-left: 30px;">
                  <p *ngIf="orderDetails.response.totalPayableAmount!=null"class="card-title"><b>Total Paid Amount</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.totalPayableAmount | number : '1.2-2'}}</span></p>
                  <p *ngIf="orderDetails.response.totalPayableAmount==null"class="card-title"><b>Total Paid Amount</b><br><span>N/A</span></p>

          </div>     
<div class="item item-2" style="margin-left: 30px;">
  <p *ngIf="orderDetails.response.transactionId!=null"class="card-title"><b>Transaction ID</b><br><span>{{orderDetails.response.transactionId}}</span></p>
          <p *ngIf="orderDetails.response.transactionId==null"class="card-title"><b>Transaction ID</b><br><span>N/A</span></p>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p *ngIf="orderDetails.response.paymentStatus==='Success'" class="card-title"><b>Payment Status </b><br><span class="ht-Success"><b>{{orderDetails.response.paymentStatus}}</b></span></p>
        <p *ngIf="orderDetails.response.paymentStatus==='Pending'" class="card-title"><b>Payment Status </b><br><span class="ht-Pending"><b>{{orderDetails.response.paymentStatus}}</b></span></p>
        <p *ngIf="orderDetails.response.paymentStatus==='Failure'" class="card-title"><b>Payment Status </b><br><span style="color: red;"><b>Failed</b></span></p>
        <p *ngIf="orderDetails.response.paymentStatus==='Initiated'" class="card-title"><b>Payment Status</b> <br><span class="ht-Pending"><b>Pending</b></span></p>
</div>
<div class="item item-4" style="margin-left: 30px;">
  <p class="card-title"><b>Transaction Date & Time</b> <br><span>{{orderDetails.response.initiatedDatetime |date:'dd/MM/yyyy hh:mm:ss a'}}</span></p>
</div>
  </div>
  <hr *ngIf="orderDetails.response.status=='Cancelled'" class="beautiful-line">
<h5 *ngIf="orderDetails.response.status=='Cancelled'" style="margin-left: 15px; text-align: left; color: grey;">Order Track Info</h5>
<br>
<div *ngIf="orderDetails.response.status=='Cancelled'"class="container"
  fxLayout
>
<div class="item item-1" style="margin-left: 20px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderPlaced==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderPlaced==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Placed</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderPlacedDateTime!=null" style="text-align: center;">
    {{orderTrackDetails.response.orderPlacedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderPlacedDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-2" style="margin-left: -80px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-3" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderAccepted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderAccepted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>    
  </span>
  <span style="margin-bottom: 10px;"><b>Agent Accepted</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderAcceptedDateTime!=null">
    {{orderTrackDetails.response.orderAcceptedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span style="color: red;" *ngIf="orderTrackDetails.response.orderAcceptedDateTime==null">
    Order Cancelled
  </span>
</div>
<div class="item item-4" style="margin-left: -50px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-5" style="margin-left: 15px;">
  <img *ngIf="orderTrackDetails.response.paymentCompleted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.paymentCompleted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>    
    <span style="margin-bottom: 10px; width: 30px;"><b>Payment Completed</b></span><br>
    <span style="color: red;">
      Order Cancelled
    </span>    
</div>
<div class="item item-6" style="margin-left: -60px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-7" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderInitiated==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderInitiated==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Initiated</b></span><br>
  <span style="color: red;">
    Order Cancelled
  </span>  
</div>
<div class="item item-8" style="margin-left: -40px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-9" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.refillOtp==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.refillOtp==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Refill OTP Verified</b></span><br>
  <span style="color: red;">
    Order Cancelled
  </span>  
</div>
<div class="item item-10" style="margin-left: -60px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-11" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.endOrderOtp==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.endOrderOtp==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>END Order OTP Verified</b></span><br>
  <span style="color: red;">
    Order Cancelled
  </span>  
</div>
<div class="item item-12" style="margin-left: -80px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-13" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderCompleted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderCompleted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Completed</b></span><br>
  <span style="color: red;">
    Order Cancelled
  </span>  
</div>
<div class="item item-14" style="margin-left: -40px;">
  <b><hr width="60px" color="pink"></b>
</div>
<div class="item item-14" style="margin-left: 15px;">
  <span>
    <img src="assets/img/remove.svg" alt="logo" data-src="assets/img/remove.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Cancelled</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderPlacedDateTime!=null" style="text-align: center;">
    {{orderTrackDetails.response.orderCancelledDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>  
</div>
  </div>
  <hr *ngIf="orderDetails.response.status!=='Cancelled'" class="beautiful-line">
  <div *ngIf="orderDetails.response.status!=='Cancelled'"class="container"
  fxLayout
>
<div class="item item-1" style="margin-left: 20px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderPlaced==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderPlaced==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Placed</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderPlacedDateTime!=null" style="text-align: center;">
    {{orderTrackDetails.response.orderPlacedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderPlacedDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-2" style="margin-left: -80px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-3" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderAccepted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderAccepted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>    
  </span>
  <span style="margin-bottom: 10px;"><b>Agent Accepted</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderAcceptedDateTime!=null">
    {{orderTrackDetails.response.orderAcceptedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderAcceptedDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-4" style="margin-left: -50px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-5" style="margin-left: 15px;">
  <img *ngIf="orderTrackDetails.response.paymentCompleted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.paymentCompleted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>    
    <span style="margin-bottom: 10px;"><b>Payment Completed</b></span><br>
    <span *ngIf="orderTrackDetails.response.paymentCompletedDateTime!=null">
      {{orderTrackDetails.response.paymentCompletedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
    </span>
    <span *ngIf="orderTrackDetails.response.paymentCompletedDateTime==null">
      Will be Updated
    </span>    
</div>
<div class="item item-6" style="margin-left: -60px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-7" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderInitiated==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderInitiated==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Initiated</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderInitiatedDateTime!=null">
    {{orderTrackDetails.response.orderInitiatedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderInitiatedDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-8" style="margin-left: -40px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-9" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.refillOtp==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.refillOtp==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Refill OTP Verified</b></span><br>
  <span *ngIf="orderTrackDetails.response.refillDateTime!=null">
    {{orderTrackDetails.response.refillDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.refillDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-10" style="margin-left: -60px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-11" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.endOrderOtp==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.endOrderOtp==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>END Order OTP Verified</b></span><br>
  <span *ngIf="orderTrackDetails.response.endOrderDateTime!=null">
    {{orderTrackDetails.response.endOrderDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.endOrderDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-12" style="margin-left: -80px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-13" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderCompleted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderCompleted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Completed</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderCompletedDateTime!=null">
    {{orderTrackDetails.response.orderCompletedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderCompletedDateTime==null">
    Will be Updated
  </span>
</div>
  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
  </div>
</ng-template>

<ng-template #content1 let-modal>
  <div class="modal-header">
    <h5>Assign Vehicle</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="container"
  fxLayout
>

<div class="item item-1">
  <p><b>Order ID</b></p>
  <span>{{orderDetails.response.orderId}}</span>
</div>
<div class="item item-2" style="margin-left: 25px;">
  <p><b>Customer Name</b></p>
  <span style="word-wrap: break-word;">{{orderDetails.response.customerId.firstName}} {{orderDetails.response.customerId.lastName}}</span>
</div>
<div class="item item-3" style="margin-left: 25px;">
  <p><b>Customer Mobile</b></p>
  <span>{{orderDetails.response.customerId.mobileNumber}}</span>
</div>
<div class="item item-4" style="margin-left: 25px;">
  <p><b>Status</b></p>
  <span *ngIf="orderDetails.response.status==='Pending'"><span class="ht-Pending"><b>{{orderDetails.response.status}}</b></span></span>
  <span *ngIf="orderDetails.response.status==='Cancelled'"><span style="color: red;"><b>{{orderDetails.response.status}}</b></span></span>
  <span *ngIf="orderDetails.response.status==='Inprogress'"><span style="color: blue;"><b>{{orderDetails.response.status}}</b></span></span>
  <span *ngIf="orderDetails.response.status==='Completed'"><span class="ht-active"><b>{{orderDetails.response.status}}</b></span></span>
</div>
<div class="item item-5" style="margin-left: 25px;">
  <p><b>Booking Date & Time</b></p>
  <span>{{orderDetails.response.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span>
</div>
<div class="item item-6" style="margin-left: 25px;">
  <p><b>Delivery Date & Time</b></p>
  <span>N/A</span>
</div>
<div class="item item-7" style="margin-left: 25px;">
  <p><b>Fuel</b></p>
  <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.fuelType.fuelTypeName}}</span>
      <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
</div>
<div class="item item-8" style="margin-left: 25px;">
  <p><b>Quantity in Ltrs</b></p>
  <span >{{orderDetails.response.quantity}}</span>
</div>
<br>
  </div>
  <br>
  <div class="container"
  fxLayout
>
  <div class="item item-1">
    <p><b>Vehicle Name</b></p>
    <div class="ch-form-group">
      <select id="countryFormControl" value="countryFormControl" class="form-control" #mySelect (change)='onOptionsSelected(mySelect.value)'>
        <option *ngFor="let vehicle of activeVehicle.response" value={{vehicle.vehicleId}}>{{vehicle.vehicleNumber}} ({{vehicle.userId.firstName}} {{vehicle.userId.lastName}})</option>
      </select>
  </div>
  </div>
  <div class="item item-2" style="margin-left: 25px;">
    <p><b>Delivery Agent Mobile</b></p>
    <span *ngIf="check==null">
      <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.userId.firstName}} {{orderDetails.response.vehicleId.userId.lastName}}</span>
      <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
    </span>
    <span *ngIf="check!=null">{{vehicle.userId.mobileNumber}}</span>
  </div>
  <div class="item item-3" style="margin-left: 25px;">
    <p><b>Delivery Agent Email</b></p>
    <span *ngIf="check==null">
      <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.userId.mobileNumber}}</span>
          <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
    </span>
    <span *ngIf="check!=null">{{vehicle.userId.emailAddress}}</span>
  </div>
  <div class="item item-4" style="margin-left: 25px;">
    <p><b>Vehicle Number</b></p>
    <span *ngIf="check==null">{{orderDetails.response.vehicleId.vehicleNumber}}</span>
    <span *ngIf="check!=null">{{vehicle.vehicleNumber}}</span>
  </div>
  <div class="item item-5" style="margin-left: 25px;">
    <p><b>Delivery location</b></p>
    <span>{{orderDetails.response.deliveryAddress}}</span>
  </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="assign()" (click)="modal.close('Save click')">Assign</button>
  </div>
</ng-template>

<ng-template #content5 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Reason for cancellation of Order</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
  <form>
      <br>
      <div class="ch-form-group">
          <select id="cancelmessage"  name="cancelmessage" class="form-control" style="width: 100%;">
            <option *ngFor="let cancel of cancelMessageModel.response" value={{cancel.id}}>{{cancel.cancellationMessage}}</option>
          </select>
      </div>	
      <br>
      <div class="ch-form-group">
           <textarea placeholder="Should not exceed more than 150 characters" class="form-control textarea" name="cancelremarks" maxlength="150" id="cancelremarks" rows="3"></textarea>
      </div>
      <br>
    <b>Note</b> : <span style="color: grey;">Should not exceed more than 150 characters</span> 
      </form>
      <br>
      <div *ngIf="errorFlag1" style="color: red;">{{errorMessage}}</div>
    </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" style="color: green;" (click)="cancelOrder()">Submit</button>
  </div>
</ng-template>

<ng-template #content9 let-modal>
  <div class="modal-header">
    <h6>Assets View Image</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
    <img class="modal-content1"  [src]="imageasset" height="auto" width="auto">
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
    </div>
</ng-template>

<ng-template #content2 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">View Location</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
    </div>
    <hr class="beautiful-line">
  <agm-map [latitude]="lat" [longitude]="lan" style="height: 300px">
    <agm-marker [latitude]="lat" [longitude]="lan"></agm-marker>
  </agm-map>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">close</button>
  </div>
</ng-template>
