
<app-header></app-header>

<!-- START PAGE-CONTAINER -->

<div class="page-container "> 
  <!-- START PAGE CONTENT WRAPPER -->
  <div class="page-content-wrapper "> 
    <!-- START PAGE CONTENT -->
    <div class="content sm-gutter"> 
      <!-- START BREADCRUMBS -->
      <div class="bg-white">
        <div class="container">
          <ol class="breadcrumb breadcrumb-alt">
            <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Home</a></li>
            <li class="breadcrumb-item active">Role Menu Mapping </li>
          </ol>
        </div>
      </div>
      <!-- END BREADCRUMBS -->
      
      
       
         </div>
      
      
      <!-- START CONTAINER FLUID --> 
      
      <!-- START CONTAINER FLUID -->
      
      <!-- END CONTAINER FLUID --> 
      
    </div>
</div>

<div class="container">
	<div class="card role-menu-mapping-edit">
		<div class="group-element" style="margin-top: 10px;">
			<label><b>Select Role</b></label> 
		</div>
	
      <div class="ch-form-group" style="margin-left: 10px;">
          <select id="role"  name="role" class="form-control">
            <option *ngFor="let roles of roleDetails" value={{roles.roleId}}>{{roles.roleName}}</option>
          </select>
          </div>
      </div>

	<div class="card menu-selection">
    <h5>Menus selection</h5>
    <br>
     <div class="btn-section-wrapper"><button type="button" (click)="assignMenu()" class="btn btn-success"><i aria-hidden="true" class="fa fa-save"></i>&nbsp; Submit</button></div>
<br>
     <div *ngFor="let menu of menuDetails.response.menu;">
      <ul class="menu">
        <p *ngIf="menu.parentMenu==1"><li>
          <input  type="checkbox" value=" {{menu.menuId}}" (change)="onChange(menu.menuId,$event)">
<label for="tall">{{menu.menuName}}</label></li></p>
<div *ngIf="menu.parentMenu==2">
  <ul class="sub-menu">
    <li>
    <input type="checkbox" value=" {{menu.menuId}}" (change)="onChange(menu.menuId,$event)">
  <label for="tall-1">{{menu.menuName}}
  </label>
  </li>
  </ul>
</div>
</ul>
     </div>
<!-- <ul class="menu">
<li>
<input type="checkbox" name="tall" id="tall">
<label for="tall">User Management </label>
<ul class="sub-menu">
<li>
<input type="checkbox" name="tall-1" id="tall-1">
<label for="tall-1">Role Menu Mapping
</label>
</li>
<li>
<input type="checkbox" name="tall-2" id="tall-2">
<label for="tall-2">User Management
</label>
</li>
</ul>
</li>
<li>
	<input type="checkbox" name="short" id="short">
<label for="short">Customers</label>

</li>
<li>
<input type="checkbox" name="short" id="short">
<label for="short">Partners</label>

<ul>
<li>
<input type="checkbox" name="short-1" id="short-1">
<label for="short-1">View Partners</label>
</li>
<li>
<input type="checkbox" name="short-2" id="short-2">
<label for="short-2">Partners KYC</label>
</li>
<li>
<input type="checkbox" name="short-3" id="short-3">
<label for="short-3">Bank Info</label>
</li>
</ul>
</li>
<li>
	<input type="checkbox" name="short" id="short">
<label for="short">Vehicles & Fuel</label>

</li>
<li>
	<input type="checkbox" name="short" id="short">
<label for="short">Orders</label>

</li>
<li>
	<input type="checkbox" name="short" id="short">
<label for="short">Transactions</label>

</li>
<li>
<input type="checkbox" name="short" id="short">
<label for="short">Settings</label>

<ul> -->
<!-- <li>
<input type="checkbox" name="short-1" id="short-1">
<label for="short-1">Department Master</label>
</li>
<li>
<input type="checkbox" name="short-2" id="short-2">
<label for="short-2">Role Master</label>
</li>
<li>
<input type="checkbox" name="short-3" id="short-3">
<label for="short-3">Bank Master</label>
</li>
<li>
<input type="checkbox" name="short-3" id="short-3">
<label for="short-3">Fuel Type</label>
</li>
<li>
<input type="checkbox" name="short-3" id="short-3">
<label for="short-3">Fuel Rate Master</label>
</li>
</ul>
</li>
<li>
<input type="checkbox" name="short-3" id="short-3">
<label for="short-3">Report</label>
<ul>
	<li>
<input type="checkbox" name="short-3" id="short-3">
<label for="short-3">Order Report</label>
</li>
</ul>
</li>


</ul> -->
	</div>
</div>


   

<router-outlet></router-outlet>
