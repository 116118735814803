import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from '../auth/token-storage.service';
import { LoginModel } from '../model/login';
import { UserModel } from '../model/user';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.css']
})
export class PageNotFoundComponent implements OnInit {

  hide = true;
  fieldTextType: boolean;
  isError = false;
  errormessage : String;
  userModel : UserModel;
  constructor(private router: Router,
  private userservice: UserService,private tokenStorage:TokenStorageService) { }

  ngOnInit(): void {
    setTimeout(function() {
      this.router.navigate(['/login']);
  }.bind(this), 6000);

  }

  backpage(){
    sessionStorage.removeItem("jwttoken");
    this.router.navigate(['/login']);
  }
}
