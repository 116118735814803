<app-header></app-header>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
</ngx-spinner> 
<!-- START PAGE-CONTAINER -->
<div class="page-container "> 
  <!-- START PAGE CONTENT WRAPPER -->
  <div class="page-content-wrapper "> 
    <!-- START PAGE CONTENT -->
    <div class="content sm-gutter"> 
      <!-- START BREADCRUMBS -->
      <div class="bg-white">
        <div class="container">
          <ol class="breadcrumb breadcrumb-alt">
            <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Home</a></li>
            <li class="breadcrumb-item active"><span class="font-montserrat fs-11 all-caps">Ratings & Review</span></li>
          </ol>
        </div>
      </div>
      <!-- END BREADCRUMBS -->
  <tr style="display: none;" *ngFor="let customer of customerRating.response; let i=index">
    <td class="v-align-middle ">{{i + 1}}</td>
    <td *ngIf="customer.customerId!=null"class="v-align-middle">{{customer.customerId.firstName}}</td>
    </tr>
  <div *ngIf="div1"> 
    
    <!-- START CONTAINER FLUID --> 
    
    <!-- START CONTAINER FLUID -->
    <div class=" no-padding    container-fixed-lg bg-white">
      <div *ngIf="div1">
        <button class="btn-transaction" (click)="div1Function()" style="margin-left: 500px; margin-top: 10px;">
          <div class="form-check">
          <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked>
          <label class="form-check-label" for="exampleRadios1"> Customer </label>
        </div>
        </button>
        <button class="btn-transaction" (click)="div2Function()">
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2">
        <label class="form-check-label" for="exampleRadios2"> Delivery Agent </label>
      </div>
      </button>
      </div>
      <div *ngIf="div2">
        <button class="btn-transaction" (click)="div1Function()" style="margin-left: 500px; margin-top: 10px;">
          <div class="form-check">
          <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" >
          <label class="form-check-label" for="exampleRadios1"> Customer </label>
        </div>
        </button>
        <button class="btn-transaction" (click)="div2Function()">
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" checked>
        <label class="form-check-label" for="exampleRadios2"> Delivery Agent </label>
      </div>
      </button>
      </div>
      
      <div class="container"> 
        <!-- START card -->
        <div class="card card-transparent">
          <div class="card-body">
            <div class="table-responsive">
              <table datatable class="ch-table">
                <thead>
                  <tr> 
                    <th style="text-align: center;">Sl.No.</th>
                    <th >Order ID</th>
                    <th >Customer Name</th>
                    <th >Customer Mobile</th>
                    <th >Delivery Agent Name</th>
                    <th >Delivery Agent Mobile</th>
                    <th >Booked Date Time</th>
                    <th >Delivered Date Time</th>
                    <th style="text-align: center;">Ratting</th>
                    <th style="width: auto; text-align: center;">Review</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let customer of customerRating.response; let i=index">
                    <td align="center" class="v-align-middle ">{{i + 1}}</td>
                    <td *ngIf="customer.orderId!=null"class="v-align-middle">{{customer.orderId.orderId}}</td>
                    <td *ngIf="customer.orderId==null"class="v-align-middle">N/A</td>
                    <td *ngIf="customer.customerId!=null"class="v-align-middle">{{customer.customerId.firstName}} {{customer.customerId.lastName}}</td>
                    <td *ngIf="customer.customerId==null"class="v-align-middle">N/A</td>
                    <td *ngIf="customer.customerId!=null"class="v-align-middle">{{customer.customerId.mobileNumber}}</td>
                    <td *ngIf="customer.customerId==null"class="v-align-middle">N/A</td>
                    <td *ngIf="customer.partnerId!=null"class="v-align-middle">{{customer.partnerId.firstName}} {{customer.partnerId.lastName}}</td>
                    <td *ngIf="customer.partnerId==null"class="v-align-middle">N/A</td>
                    <td *ngIf="customer.partnerId!=null" class="v-align-middle">{{customer.partnerId.mobileNumber}}</td>
                    <td *ngIf="customer.partnerId==null"class="v-align-middle">N/A</td>
                    <td *ngIf="customer.orderId!=null"class="v-align-middle ">{{customer.orderId.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                    <td *ngIf="customer.orderId==null"class="v-align-middle ">N/A</td>
                    <td *ngIf="customer.orderId.customerDeliveryDate!=null"class="v-align-middle ">{{customer.orderId.customerDeliveryDate | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                    <td *ngIf="customer.orderId.customerDeliveryDate==null"class="v-align-middle ">N/A</td>
                    <td align="center" style="width: 10%;" *ngIf="customer.ratings===1.0" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></td>
                    <td align="center" style="width: 10%;" *ngIf="customer.ratings===1.5" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-half-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></td>
                    <td align="center" style="width: 10%;" *ngIf="customer.ratings===2.0" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></td>
                    <td align="center" style="width: 10%;" *ngIf="customer.ratings===2.5" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-half-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></td>
                    <td align="center" style="width: 10%;" *ngIf="customer.ratings===3.0" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></td>
                    <td align="center" style="width: 10%;" *ngIf="customer.ratings===3.5" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-half-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></td>
                    <td align="center" style="width: 10%;" *ngIf="customer.ratings===4.0" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></td>
                    <td align="center" style="width: 10%;" *ngIf="customer.ratings===4.5" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-half-o" aria-hidden="true"></i> </span></td>
                    <td align="center" style="width: 10%;" *ngIf="customer.ratings===5.0" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span></td>
                    <td align="center" *ngIf="customer.comments!=null && customer.comments!==''"class="v-align-middle"><button title="View" class="label label-info" (click)="open(content,customer.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button></td>
                    <td align="center" *ngIf="customer.comments==null || customer.comments===''"class="v-align-middle">N/A</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- END card --> 
      </div>
    </div>
    <!-- END CONTAINER FLUID --> 
  </div>
</div>
<!-- END PAGE CONTENT WRAPPER -->

<tr style="display: none;" *ngFor="let partner of partnerRating.response; let i=index">
  <td class="v-align-middle ">{{i + 1}}</td>
  <td *ngIf="partner.userId!=null"class="v-align-middle">{{partner.userId.firstName}}</td>
  </tr>
<div *ngIf="div2"> 
  
  <!-- START CONTAINER FLUID --> 
  
  <!-- START CONTAINER FLUID -->
  <div class=" no-padding    container-fixed-lg bg-white">
    <div *ngIf="div1">
      <button class="btn-transaction" (click)="div1Function()" style="margin-left: 500px; margin-top: 10px;">
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked>
        <label class="form-check-label" for="exampleRadios1"> Customer </label>
      </div>
      </button>
      <button class="btn-transaction" (click)="div2Function()">
      <div class="form-check">
      <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2">
      <label class="form-check-label" for="exampleRadios2"> Delivery Agent </label>
    </div>
    </button>
    </div>
    <div *ngIf="div2">
      <button class="btn-transaction" (click)="div1Function()" style="margin-left: 500px; margin-top: 10px;">
        <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" >
        <label class="form-check-label" for="exampleRadios1"> Customer </label>
      </div>
      </button>
      <button class="btn-transaction" (click)="div2Function()">
      <div class="form-check">
      <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" checked>
      <label class="form-check-label" for="exampleRadios2"> Delivery Agent </label>
    </div>
    </button>
    </div>
    <div class="container"> 
      <!-- START card -->
      <div class="card card-transparent">
        <div class="card-body">
          <div class="table-responsive">
             <table datatable class="ch-table">
                <thead>
                  <tr> 
                    <th style="text-align: center;">Sl.No.</th>
                    <th >Order ID</th>
                    <th >Delivery Agent Name</th>
                    <th >Delivery Agent Mobile</th>
                    <th >Customer Name</th>
                    <th >Customer Mobile</th>
                    <th >Booked Date Time</th>
                    <th >Delivered Date Time</th>
                    <th style="text-align: center;">Ratting</th>
                    <th style="text-align: center;">Review</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let partner of partnerRating.response; let i=index">
                    <td align="center" class="v-align-middle ">{{i + 1}}</td>
                    <td *ngIf="partner.orderId!=null"class="v-align-middle">{{partner.orderId.orderId}}</td>
                    <td *ngIf="partner.orderId==null"class="v-align-middle">N/A</td>
                    <td *ngIf="partner.userId!=null"class="v-align-middle">{{partner.userId.firstName}}  {{partner.userId.lastName}}</td>
                    <td *ngIf="partner.userId==null"class="v-align-middle">N/A</td>
                    <td *ngIf="partner.userId!=null"class="v-align-middle">{{partner.userId.mobileNumber}}</td>
                    <td *ngIf="partner.userId==null"class="v-align-middle">N/A</td>
                    <td *ngIf="partner.customerId!=null"class="v-align-middle">{{partner.customerId.firstName}} {{partner.customerId.lastName}}</td>
                    <td *ngIf="partner.customerId==null"class="v-align-middle">N/A</td>
                    <td *ngIf="partner.customerId!=null"class="v-align-middle">{{partner.customerId.mobileNumber}}</td>
                    <td *ngIf="partner.customerId==null"class="v-align-middle">N/A</td>
                    <td *ngIf="partner.orderId.orderDatetime!=null"class="v-align-middle ">{{partner.orderId.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                    <td *ngIf="partner.orderId.orderDatetime==null"class="v-align-middle ">N/A</td>
                    <td *ngIf="partner.orderId.customerDeliveryDate!=null"class="v-align-middle ">{{partner.orderId.customerDeliveryDate | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                    <td *ngIf="partner.orderId.customerDeliveryDate==null"class="v-align-middle ">N/A</td>
                    <td align="center" style="width: 10%;" *ngIf="partner.ratings===1.0" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></td>
                    <td align="center" style="width: 10%;" *ngIf="partner.ratings===1.5" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-half-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></td>
                    <td align="center" style="width: 10%;" *ngIf="partner.ratings===2.0" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></td>
                    <td align="center" style="width: 10%;" *ngIf="partner.ratings===2.5" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-half-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></td>
                    <td align="center" style="width: 10%;" *ngIf="partner.ratings===3.0" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></td>
                    <td align="center" style="width: 10%;" *ngIf="partner.ratings===3.5" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-half-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></td>
                    <td align="center" style="width: 10%;" *ngIf="partner.ratings===4.0" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></td>
                    <td align="center" style="width: 10%;" *ngIf="partner.ratings===4.5" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-half-o" aria-hidden="true"></i> </span></td>
                    <td align="center" style="width: 10%;" *ngIf="partner.ratings===5.0" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span></td>
                    <td align="center" *ngIf="partner.comments!=null && partner.comments!==''"class="v-align-middle"><button title="View" class="label label-info" (click)="open1(content1,partner.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button></td>
                    <td align="center" *ngIf="partner.comments==null || partner.comments===''"class="v-align-middle">N/A</td>
                  </tr>
                </tbody>
              </table>
          </div>
        </div>
      </div>
      <!-- END card --> 
    </div>
  </div>
  <!-- END CONTAINER FLUID --> 
</div>
<router-outlet></router-outlet>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Review Comments</h4>
    <hr class="beautiful-line">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <div class="modal-body">
    <form>
      <!-- <div class="form-group">
        <label for="dateOfBirth">Comments</label>
      </div> -->
      <div class="form-group">
        <div *ngIf="customerratingmodel.comments!=null">{{customerratingmodel.comments}}</div>
        <div *ngIf="customerratingmodel.comments==null">N/A</div>
      </div>
      <!-- <textarea class="review" row="5" style="width: 100%; ">
      </textarea> -->
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>

<ng-template #content1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Review Comments</h4>
    <hr class="beautiful-line">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <div class="modal-body">
    <form>
      <!-- <div class="form-group">
        <label for="dateOfBirth">Comments</label>
      </div> -->
      <div *ngIf="partnerratingmodel.comments!=null">{{partnerratingmodel.comments}}</div>
      <div *ngIf="partnerratingmodel.comments==null">N/A</div>
      <!-- <textarea class="review" row="5" style="width: 100%; ">{{partnerratingmodel.comments}}
      </textarea> -->
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>
