<app-header></app-header>
<div class="container  container-top-dashboard-section" style="margin-top: 110px">
  <a href="#" routerLink="/agents" class="back_page"><span class="label label-info"><i class="back_page"></i>Back</span></a>
   <!-- <div class="profile-nav">
      <button type="button" class="btn btn-outline-primary" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseExample" id="collapse">
      Profile
      </button> 
   </div> -->
   <br>
   <div class="alert alert-success box-msg" role="alert"
            *ngIf="successflag">{{message}}</div>
   <div class="card mb-3" style="margin-top: 20px; " #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
   <div class="flex">
      <div class="col1 flex">
        <div class=" profile images flex">
          <img *ngIf="!isImageLoading" src="assets/img/profile.jpg" data-src="assets/img/profile.jpg" data-src-retina="assets/img/profile.jpg" width="150px" >
          <img *ngIf="isImageLoading" class="modal-content"  [src]="imageToShow">
       </div>
         <div class="profile-info flex">
            <div class="card-body profile">
               <p class="card-title">Agent  Name <br><span style="word-wrap: break-word;"><b>{{agentModel.firstName}} {{agentModel.lastName}}</b></span></p>
              <p class="card-title">Partner Name <br><span style="word-wrap: break-word;"><b>{{agentModel.partnerName}}</b></span></p>
              <p class="card-title">Rating<br>
                <span *ngIf="ratingStar===0" class="v-align-middle ht-ratting"><span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></span>
                <span *ngIf="ratingStar===1" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></span>
                <span *ngIf="ratingStar===1.5" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-half-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></span>
                <span *ngIf="ratingStar===2" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></span>
                <span *ngIf="ratingStar===2.5" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-half-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></span>
                <span *ngIf="ratingStar===3" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></span>
                <span *ngIf="ratingStar===3.5" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-half-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></span>
                <span *ngIf="ratingStar===4" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></span>
                <span *ngIf="ratingStar===4.5" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-half-o" aria-hidden="true"></i> </span></span>
                <span *ngIf="ratingStar===5" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span></span>
              </p>
              <p class="card-title">Failed Status<br>
                <span><b>{{failedStatus}}</b></span>
              </p>
            </div>
             <div class="card-body profile">
               <p class="card-title"><i class="fa fa-mobile" aria-hidden="true"></i> Mobile
                  <br><span><b>{{agentModel.mobileNumber}}</b></span>
               </p>
               <p class="card-title"><i class="fa fa-envelope" aria-hidden="true"></i> Email
                  <br><span><b>{{agentModel.emailAddress}}</b></span>
               </p>
<p class="card-title"><i class="fa fa-location-arrow" aria-hidden="true"></i>
Address for Communication
                  <br>
                  <span *ngIf="agentModel.address!=null" style="word-wrap: break-word;"><b>{{agentModel.address}}</b></span>
                  <span *ngIf="agentModel.address==null"><b>N/A</b></span>
               </p>
            </div>

            <div class="card-body profile">
              <p class="card-title">Onboarded Date & Time  <br><span><b>{{agentModel.createdDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</b></span></p>
               <p class="card-title">OTP Verification Status<br>
                <span *ngIf="agentModel.otpVerificationStatus===1" class="v-align-middle ht-Success"><b>Success</b></span>
                <span *ngIf="agentModel.otpVerificationStatus===0" class="v-align-middle ht-Pending"><b>Pending</b></span>
              </p>
              <p class="card-title">
                Badge Number
                  <br>
                  <span *ngIf="agentModel.badgeNumber!=null"><b>{{agentModel.badgeNumber}}</b></span>
                  <span *ngIf="agentModel.badgeNumber==null"><b>N/A</b></span>
               </p>
               <p class="card-title">Failed Count<br>
                <span>{{failedCount}}</span>
                <span style="margin-left: 10px;" *ngIf="failedCount==6 || failedCount>=6">
                  <button title="Unblock" class="label label-info" (click)="open10(reject,agentModel.userId)" ><i class="fa fa-unlock" aria-hidden="true"></i></button>
                </span>
              </p>
            </div>
            <div class="card-body profile">
               <!-- <p class="card-title">Partner ID<br>
               <span class="ht-Success"><b>CRPRID74548</b></span></p> -->
               <p>Account Status</p>
               <div *ngIf="agentModel.accountStatus===1">
                <div class="form-check no-pad-left">
                  <input class="form-check-input" type="radio" name="act" id="act" (click)="callActiveOrInactive(1)" value="acti1" checked>
                  <label class="form-check-label" for="act">
                  Active
                  </label>
               </div>
               <div class="form-check no-pad-left">
                  <input class="form-check-input" type="radio" name="inact" id="inact" (click)="callActiveOrInactive(2)" value="ina">
                  <label class="form-check-label" for="inact">
                  Inactive
                  </label>
               </div>
               </div>
               <div *ngIf="agentModel.accountStatus===2">
                <div class="form-check no-pad-left">
                  <input class="form-check-input" type="radio" name="act" id="act" (click)="callActiveOrInactive(1)" value="acti1">
                  <label class="form-check-label" for="act">
                  Active
                  </label>
               </div>
               <div class="form-check no-pad-left">
                  <input class="form-check-input" type="radio" name="inact" id="inact" (click)="callActiveOrInactive(2)" value="ina" checked>
                  <label class="form-check-label" for="inact">
                  Inactive
                  </label>
               </div>
               </div>
               <p class="card-title">
                Badge Expiry Date
                  <br>
                  <span *ngIf="agentModel.badgeExpiryDate!=null"><b>{{agentModel.badgeExpiryDate | date:'dd/MM/yyyy'}}</b></span>
                  <span *ngIf="agentModel.badgeExpiryDate==null"><b>N/A</b></span>
               </p>
            </div>
            <div class="card-body profile">
              <p class="card-title">PAN Number  <br>
                <span *ngIf="agentModel.pannumber!=null"><b>{{agentModel.pannumber}}</b></span>
                <span *ngIf="agentModel.pannumber==null"><b>N/A</b></span>
              </p>
               <p class="card-title">License Expiry Date<br>
                <span *ngIf="agentModel.licenseExpiryDate!=null" class="v-align-middle"><b>{{agentModel.licenseExpiryDate | date:'dd/MM/yyyy'}}</b></span>
                <span *ngIf="agentModel.licenseExpiryDate==null" class="v-align-middle"><b>N/A</b></span>
              </p>
              <p class="card-title">
                License Number
                  <br>
                  <span *ngIf="agentModel.licensenumber!=null"><b>{{agentModel.licensenumber}}</b></span>
                  <span *ngIf="agentModel.licensenumber==null"><b>N/A</b></span>
               </p>
            </div>
            <div class="card-body profile">
              <p class="card-title">Aadhar Number  <br>
                <span *ngIf="agentModel.aatharnumber!=null"><b>{{agentModel.aatharnumber}}</b></span>
                <span *ngIf="agentModel.aatharnumber==null"><b>N/A</b></span>
              </p>
              <p class="card-title" *ngIf="agentModel.gstNumber!=null">GST Number <br><span style="word-wrap: break-word;"><b>{{agentModel.gstNumber}}</b></span></p>
              <p class="card-title" *ngIf="agentModel.gstNumber==null">GST Number <br><span style="word-wrap: break-word;"><b >N/A</b></span></p>
              <p class="card-title" *ngIf="agentModel.userCode!=null">Agent ID <br><span style="word-wrap: break-word;"><b>{{agentModel.userCode}}</b></span></p>
              <p class="card-title" *ngIf="agentModel.userCode==null">Agent ID <br><span style="word-wrap: break-word;"><b >N/A</b></span></p>
            </div>
         </div>
      </div>
   </div>
</div>

<tr style="display: none;" *ngFor="let order of agentOrder; let i=index">
  <td align="center" class="v-align-middle ">{{i + 1}}</td>
  <td align="left" class="v-align-middle">{{order.orderId}}</td>
  </tr>
<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
  <div style="display: none;">{{agentOrder.responseMessage}}</div>
  <li [ngbNavItem]="1">
    <a ngbNavLink>Orders</a>
    <ng-template ngbNavContent>
      <br>
        <button style="margin-left: 350px;" (click)="pending()" class="btn-transaction">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios" id="penradio" value="opt1" checked>
            <label class="form-check-label" for="penradio">
              Pending
            </label>
          </div>
          </button>
          <button (click)="inprogress()" class="btn-transaction">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="exampleRadios" id="inpradio" value="opt2">
              <label class="form-check-label" for="inpradio">
                Inprogress
              </label>
            </div>
            </button> 
            <button (click)="completed()" class="btn-transaction">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="exampleRadios" id="comradio" value="opt3">
                <label class="form-check-label" for="comradio">
                  Delivered
                </label>
              </div>
              </button> 
              <button (click)="cancelled()" class="btn-transaction">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="exampleRadios" id="canradio" value="opt4">
                  <label class="form-check-label" for="canradio">
                    Cancelled
                  </label>
                </div>
                </button>   
                <button (click)="all()" class="btn-transaction" >
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="exampleRadios" id="allradio" value="opt">
                    <label class="form-check-label" for="allradio">
                      All
                    </label>
                  </div>
                  </button>         
      <!-- <button (click)="cancelled()" class="pen-style3">Cancelled</button>
      <button (click)="completed()" class="pen-style2">Completed</button>
      <button (click)="inprogress()" class="pen-style1">Inprogress</button>
      <button (click)="all()" class="pen-style3">All</button> -->
      <br>
      <div *ngIf="defaultAgent"class="card-body">
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th align="center" >Sl.No.</th>
                      <th align="left"  >Order ID </th>
                      <th align="left" >Customer name</th>
                      <th align="center" >Mobile Number</th>
                      <th align="center" >Fuel</th>
                      <th align="center" >Quantity in Ltrs</th>
                      <th align="center" >Booked Date Time </th>
                      <th align="center" >Delivered Date Time</th>
                      <th align="center" >Status</th>
                      <th align="center" >Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let order of agentOrder.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td align="left" class="v-align-middle">{{order.orderId}}</td>
                      <td *ngIf="order.customerId!=null" style="word-wrap: break-word;" class="v-align-middle">{{order.customerId.firstName}} {{order.customerId.lastName}}</td>
                      <td *ngIf="order.customerId==null" class="v-align-middle">N/A</td>
                      <td *ngIf="order.customerId!=null" align="left" class="v-align-middle">{{order.customerId.mobileNumber}}</td>
                      <td *ngIf="order.customerId==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">{{order.fuelType}}</td>
                      <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
                      <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                      <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                      <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                      <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                      <td align="left" class="v-align-middle">
                        <button title="View" class="label label-info" (click)="open(content,order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                        <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                        <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.status==='Pending' || order.status==='Inprogress'" class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                        <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="invoice(order.id)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
          </div>
        </div>
    
         <!-- ----- All Agent Order -----  -->

         <div *ngIf="allAgent"class="card-body">
           <div style="display: none;">{{allAgentOrder.responseMessage}}</div>
          <div class="table-responsive">
            <table datatable class="ch-table">
              <thead>
                <tr> 
                  <th align="center" >Sl.No.</th>
                  <th align="left"  >Order ID </th>
                  <th align="left" >Customer name</th>
                  <th align="center" >Mobile Number</th>
                  <th align="center" >Fuel</th>
                  <th align="center" >Quantity in Ltrs</th>
                  <th align="center" >Booked Date Time </th>
                  <th align="center" >Delivered Date Time</th>
                  <th align="center" >Status</th>
                  <th align="center" >Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let order of allAgentOrder.response; let i=index">
                  <td align="center" class="v-align-middle ">{{i + 1}}</td>
                  <td align="left" class="v-align-middle">{{order.orderId}}</td>
                  <td *ngIf="order.customerId!=null" style="word-wrap: break-word;" class="v-align-middle">{{order.customerId.firstName}} {{order.customerId.lastName}}</td>
                      <td *ngIf="order.customerId==null" class="v-align-middle">N/A</td>
                      <td *ngIf="order.customerId!=null" align="left" class="v-align-middle">{{order.customerId.mobileNumber}}</td>
                      <td *ngIf="order.customerId==null" align="left" class="v-align-middle">N/A</td>
                  <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">{{order.fuelType}}</td>
                  <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                  <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
                  <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td>
                  <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                  <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                  <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                      <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                  <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                  <td align="left" class="v-align-middle">
                    <button title="View" class="label label-info" (click)="open(content,order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                        <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                        <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.status==='Pending' || order.status==='Inprogress'" class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                        <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="invoice(order.id)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
      </div>
    </div>

    <!-- ----- Pending Agent Order -----  -->

    <div *ngIf="pendingAgent"class="card-body">
      <div style="display: none;">{{agentPendingOrder.responseMessage}}</div>
     <div class="table-responsive">
       <table datatable class="ch-table">
         <thead>
           <tr> 
             <th align="center" >Sl.No.</th>
             <th align="left"  >Order ID </th>
             <th align="left" >Customer name</th>
             <th align="center" >Mobile Number</th>
             <th align="center" >Fuel</th>
             <th align="center" >Quantity in Ltrs</th>
             <th align="center" >Booked Date Time </th>
             <th align="center" >Delivered Date Time</th>
             <th align="center" >Status</th>
             <th align="center" >Action</th>
           </tr>
         </thead>
         <tbody>
           <tr *ngFor="let order of agentPendingOrder.response; let i=index">
             <td align="center" class="v-align-middle ">{{i + 1}}</td>
             <td align="left" class="v-align-middle">{{order.orderId}}</td>
             <td *ngIf="order.customerId!=null" style="word-wrap: break-word;" class="v-align-middle">{{order.customerId.firstName}} {{order.customerId.lastName}}</td>
                  <td *ngIf="order.customerId==null" class="v-align-middle">N/A</td>
                  <td *ngIf="order.customerId!=null" align="left" class="v-align-middle">{{order.customerId.mobileNumber}}</td>
                  <td *ngIf="order.customerId==null" align="left" class="v-align-middle">N/A</td>
             <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">{{order.fuelType}}</td>
             <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
             <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
             <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td>
             <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                  <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                  <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                  <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
             <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
             <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                      <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
             <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
             <td align="left" class="v-align-middle">
              <button title="View" class="label label-info" (click)="open(content,order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                        <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                        <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.status==='Pending' || order.status==='Inprogress'" class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                        <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="invoice(order.id)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
            </td>
           </tr>
         </tbody>
       </table>
 </div>
</div>

         <!-- ----- Inprogress Agent Order -----  -->

         <div *ngIf="inprogressAgent"class="card-body">
          <div style="display: none;">{{agentInproOrder.responseMessage}}</div>
         <div class="table-responsive">
           <table datatable class="ch-table">
             <thead>
               <tr> 
                 <th align="center" >Sl.No.</th>
                 <th align="left"  >Order ID </th>
                 <th align="left" >Customer name</th>
                 <th align="center" >Mobile Number</th>
                 <th align="center" >Fuel</th>
                 <th align="center" >Quantity in Ltrs</th>
                 <th align="center" >Booked Date Time </th>
                 <th align="center" >Delivered Date Time</th>
                 <th align="center" >Status</th>
                 <th align="center" >Action</th>
               </tr>
             </thead>
             <tbody>
               <tr *ngFor="let order of agentInproOrder.response; let i=index">
                 <td align="center" class="v-align-middle ">{{i + 1}}</td>
                 <td align="left" class="v-align-middle">{{order.orderId}}</td>
                 <td *ngIf="order.customerId!=null" style="word-wrap: break-word;" class="v-align-middle">{{order.customerId.firstName}} {{order.customerId.lastName}}</td>
                      <td *ngIf="order.customerId==null" class="v-align-middle">N/A</td>
                      <td *ngIf="order.customerId!=null" align="left" class="v-align-middle">{{order.customerId.mobileNumber}}</td>
                      <td *ngIf="order.customerId==null" align="left" class="v-align-middle">N/A</td>
                 <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">{{order.fuelType}}</td>
                 <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                 <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
                 <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td>
                 <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                 <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                 <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                      <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                 <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                 <td align="left" class="v-align-middle">
                  <button title="View" class="label label-info" (click)="open(content,order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                        <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                        <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.status==='Pending' || order.status==='Inprogress'" class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                        <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="invoice(order.id)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
                </td>
               </tr>
             </tbody>
           </table>
     </div>
   </div>

         <!-- ----- Completed Agent Order -----  -->

         <div *ngIf="completedAgent"class="card-body">
          <div style="display: none;">{{agentCompleOrder.responseMessage}}</div>
         <div class="table-responsive">
           <table datatable class="ch-table">
             <thead>
               <tr> 
                 <th align="center" >Sl.No.</th>
                 <th align="left"  >Order ID </th>
                 <th align="left" >Customer name</th>
                 <th align="center" >Mobile Number</th>
                 <th align="center" >Fuel</th>
                 <th align="center" >Quantity in Ltrs</th>
                 <th align="center" >Booked Date Time </th>
                 <th align="center" >Delivered Date Time</th>
                 <th align="center" >Status</th>
                 <th align="center" >Action</th>
               </tr>
             </thead>
             <tbody>
               <tr *ngFor="let order of agentCompleOrder.response; let i=index">
                 <td align="center" class="v-align-middle ">{{i + 1}}</td>
                 <td align="left" class="v-align-middle">{{order.orderId}}</td>
                 <td *ngIf="order.customerId!=null" style="word-wrap: break-word;"  class="v-align-middle">{{order.customerId.firstName}} {{order.customerId.lastName}}</td>
                      <td *ngIf="order.customerId==null" class="v-align-middle">N/A</td>
                      <td *ngIf="order.customerId!=null" align="left" class="v-align-middle">{{order.customerId.mobileNumber}}</td>
                      <td *ngIf="order.customerId==null" align="left" class="v-align-middle">N/A</td>
                 <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">{{order.fuelType}}</td>
                 <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                 <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
                 <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td>
                 <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                 <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                 <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                      <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                 <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                 <td align="left" class="v-align-middle">
                  <button title="View" class="label label-info" (click)="open(content,order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                        <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                        <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.status==='Pending' || order.status==='Inprogress'" class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                        <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="invoice(order.id)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
                </td>
               </tr>
             </tbody>
           </table>
     </div>
   </div>

         <!-- ----- Cancelled Agent Order -----  -->

         <div *ngIf="cancelledAgent"class="card-body">
          <div style="display: none;">{{agentCancellOrder.responseMessage}}</div>
         <div class="table-responsive">
           <table datatable class="ch-table">
             <thead>
               <tr> 
                 <th align="center" >Sl.No.</th>
                 <th align="left"  >Order ID </th>
                 <th align="left" >Customer name</th>
                 <th align="center" >Mobile Number</th>
                 <th align="center" >Fuel</th>
                 <th align="center" >Quantity in Ltrs</th>
                 <th align="center" >Booked Date Time </th>
                 <th align="center" >Delivered Date Time</th>
                 <th align="center" >Status</th>
                 <th align="center" >Action</th>
               </tr>
             </thead>
             <tbody>
               <tr *ngFor="let order of agentCancellOrder.response; let i=index">
                 <td align="center" class="v-align-middle ">{{i + 1}}</td>
                 <td align="left" class="v-align-middle">{{order.orderId}}</td>
                 <td *ngIf="order.customerId!=null" style="word-wrap: break-word;" class="v-align-middle">{{order.customerId.firstName}} {{order.customerId.lastName}}</td>
                      <td *ngIf="order.customerId==null" class="v-align-middle">N/A</td>
                      <td *ngIf="order.customerId!=null" align="left" class="v-align-middle">{{order.customerId.mobileNumber}}</td>
                      <td *ngIf="order.customerId==null" align="left" class="v-align-middle">N/A</td>
                 <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">{{order.fuelType}}</td>
                 <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                 <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
                 <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td>
                 <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                 <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                 <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                      <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                 <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                 <td align="left" class="v-align-middle">
                  <button title="View" class="label label-info" (click)="open(content,order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                        <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                        <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.status==='Pending' || order.status==='Inprogress'" class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                        <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="invoice(order.id)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
                </td>
               </tr>
             </tbody>
           </table>
     </div>
   </div>

    </ng-template>
  </li>

  <tr style="display: none;" *ngFor="let transaction of agentTransanction; let i=index">
    <td align="center" class="v-align-middle ">{{i + 1}}</td>
    <td *ngIf="transaction.orderId!=null" align="left" class="v-align-middle">{{transaction.orderId}}</td>
    </tr>
<div style="display: none;">{{agentTransanction.responseMessage}}</div>
  <li [ngbNavItem]="2">
    <a ngbNavLink> Transaction History </a>
    <ng-template ngbNavContent>
      <br>
        <button style="margin-left: 350px;" (click)="initiatedtra()" class="btn-transaction">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios" id="iniradio" value="opt1" checked>
            <label class="form-check-label" for="iniradio">
              Initiated
            </label>
          </div>
          </button> 
          <button (click)="successtra()" class="btn-transaction">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="exampleRadios" id="sucradio" value="opt2">
              <label class="form-check-label" for="sucradio">
                Success
              </label>
            </div>
            </button>    
            <button (click)="failuretra()" class="btn-transaction">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="exampleRadios" id="fairadio" value="opt3">
                <label class="form-check-label" for="fairadio">
                  Failed
                </label>
              </div>
              </button>    
              <button (click)="cancelledtra()" class="btn-transaction">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="exampleRadios" id="canradio" value="opt4">
                  <label class="form-check-label" for="canradio">
                    Cancelled
                  </label>
                </div>
                </button>     
                <button (click)="alltra()" class="btn-transaction" >
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="exampleRadios" id="allradio" value="opt">
                    <label class="form-check-label" for="allradio">
                      All
                    </label>
                  </div>
                  </button>    
      <!-- <button (click)="cancelledtra()" class="pen-style3">Cancelled</button>
      <button (click)="failuretra()" class="pen-style2">Failed</button>
      <button (click)="successtra()" class="pen-style1">Success</button>
      <button (click)="initiatedtra()" class="pen-style3">Initiated</button>
      <button (click)="alltra()" class="pen-style3">All</button> -->
      <br>
      <div *ngIf="defaultTransaction"class="card-body">
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Order ID </th>
                      <th style="text-align: left;">Transaction Id</th>
                      <th style="text-align: left;">Fuel</th>
                      <th style="text-align: center;">Quantity in Ltrs</th>
                      <th style="text-align: right;">Paid Amount</th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: left;">Transaction Date Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let transaction of agentTransanction.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="transaction.orderId!=null" align="left" class="v-align-middle">{{transaction.orderId}}</td>
                      <td *ngIf="transaction.orderId==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.trackId!=null"align="left" class="v-align-middle">{{transaction.paymentId}}</td>
                      <td *ngIf="transaction.trackId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.fuelType!=null" align="left" class="v-align-middle">{{transaction.fuelType}}</td>
                      <td *ngIf="transaction.fuelType==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.quantity!=null"align="center" class="v-align-middle">{{transaction.quantity}}</td>
                      <td *ngIf="transaction.quantity==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.totalPayableAmount!=null"align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i>{{transaction.totalPayableAmount | number : '1.2-2'}}</td>
                      <td *ngIf="transaction.totalPayableAmount==null"align="right" class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' " style="color: red;" >Failed</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' "class="v-align-middle ht-Pending">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-Pending">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' "style="color: red;">{{transaction.paymentStatus}}</td>
                      <td *ngIf="transaction.initiatedDatetime!=null"align="left" class="v-align-middle">{{transaction.initiatedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.initiatedDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <!-- <td class="v-align-middle"><button class="btn btn-link"><i class="fa fa-share" aria-hidden="true"></i>
&nbsp;share</button></td> -->
                    </tr>
                  </tbody>
                </table>
          </div>
        </div>

        <!-- --------All Transaction --------- -->
        <div *ngIf="allTransaction"class="card-body">
          <div style="display: none;">{{allagentTransanction.responseMessage}}</div>
          <div class="table-responsive">
            <table datatable class="ch-table">
              <thead>
                <tr> 
                  <th style="text-align: center;">Sl.No.</th>
                  <th style="text-align: left;">Order ID </th>
                  <th style="text-align: left;">Transaction Id</th>
                  <th style="text-align: left;">Fuel</th>
                  <th style="text-align: center;">Quantity in Ltrs</th>
                  <th style="text-align: right;">Paid Amount</th>
                  <th style="text-align: center;">Status</th>
                  <th style="text-align: left;">Transaction Date Time</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let transaction of allagentTransanction.response; let i=index">
                  <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="transaction.orderId!=null" align="left" class="v-align-middle">{{transaction.orderId}}</td>
                      <td *ngIf="transaction.orderId==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.trackId!=null"align="left" class="v-align-middle">{{transaction.paymentId}}</td>
                      <td *ngIf="transaction.trackId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.fuelType!=null" align="left" class="v-align-middle">{{transaction.fuelType}}</td>
                      <td *ngIf="transaction.fuelType==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.quantity!=null"align="center" class="v-align-middle">{{transaction.quantity}}</td>
                      <td *ngIf="transaction.quantity==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.totalPayableAmount!=null"align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i>{{transaction.totalPayableAmount | number : '1.2-2'}}</td>
                      <td *ngIf="transaction.totalPayableAmount==null"align="right" class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' " style="color: red;" >Failed</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' "class="v-align-middle ht-Pending">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-Pending">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' "style="color: red;">{{transaction.paymentStatus}}</td>
                      <td *ngIf="transaction.initiatedDatetime!=null"align="left" class="v-align-middle">{{transaction.initiatedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.initiatedDatetime==null"align="left" class="v-align-middle">N/A</td>
                  <!-- <td class="v-align-middle"><button class="btn btn-link"><i class="fa fa-share" aria-hidden="true"></i>
&nbsp;share</button></td> -->
                </tr>
              </tbody>
            </table>
      </div>
    </div>

     <!-- -----------Initiated--------- -->
     <div *ngIf="initiatedTransaction"class="card-body">
      <div style="display: none;">{{iniagentTransanction.responseMessage}}</div>
      <div class="table-responsive">
        <table datatable class="ch-table">
          <thead>
            <tr> 
              <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Order ID </th>
                      <th style="text-align: left;">Transaction Id</th>
                      <th style="text-align: left;">Fuel</th>
                      <th style="text-align: center;">Quantity in Ltrs</th>
                      <th style="text-align: right;">Paid Amount</th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: left;">Transaction Date Time</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let transaction of iniagentTransanction.response; let i=index">
              <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="transaction.orderId!=null" align="left" class="v-align-middle">{{transaction.orderId}}</td>
                      <td *ngIf="transaction.orderId==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.trackId!=null"align="left" class="v-align-middle">{{transaction.paymentId}}</td>
                      <td *ngIf="transaction.trackId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.fuelType!=null" align="left" class="v-align-middle">{{transaction.fuelType}}</td>
                      <td *ngIf="transaction.fuelType==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.quantity!=null"align="center" class="v-align-middle">{{transaction.quantity}}</td>
                      <td *ngIf="transaction.quantity==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.totalPayableAmount!=null"align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i>{{transaction.totalPayableAmount | number : '1.2-2'}}</td>
                      <td *ngIf="transaction.totalPayableAmount==null"align="right" class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' " style="color: red;" >Failed</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' "class="v-align-middle ht-Pending">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-Pending">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' "style="color: red;">{{transaction.paymentStatus}}</td>
                      <td *ngIf="transaction.initiatedDatetime!=null"align="left" class="v-align-middle">{{transaction.initiatedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.initiatedDatetime==null"align="left" class="v-align-middle">N/A</td>
              <!-- <td class="v-align-middle"><button class="btn btn-link"><i class="fa fa-share" aria-hidden="true"></i>
&nbsp;share</button></td> -->
            </tr>
          </tbody>
        </table>
  </div>
</div>

<!-- -----------Successs--------- -->
<div *ngIf="successTransaction"class="card-body">
  <div style="display: none;">{{succagentTransanction.responseMessage}}</div>
  <div class="table-responsive">
    <table datatable class="ch-table">
      <thead>
        <tr> 
          <th style="text-align: center;">Sl.No.</th>
          <th style="text-align: left;">Order ID </th>
          <th style="text-align: left;">Transaction Id</th>
          <th style="text-align: left;">Fuel</th>
          <th style="text-align: center;">Quantity in Ltrs</th>
          <th style="text-align: right;">Paid Amount</th>
          <th style="text-align: center;">Status</th>
          <th style="text-align: left;">Transaction Date Time</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let transaction of succagentTransanction.response; let i=index">
          <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="transaction.orderId!=null" align="left" class="v-align-middle">{{transaction.orderId}}</td>
                      <td *ngIf="transaction.orderId==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.trackId!=null"align="left" class="v-align-middle">{{transaction.paymentId}}</td>
                      <td *ngIf="transaction.trackId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.fuelType!=null" align="left" class="v-align-middle">{{transaction.fuelType}}</td>
                      <td *ngIf="transaction.fuelType==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.quantity!=null"align="center" class="v-align-middle">{{transaction.quantity}}</td>
                      <td *ngIf="transaction.quantity==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.totalPayableAmount!=null"align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i>{{transaction.totalPayableAmount | number : '1.2-2'}}</td>
                      <td *ngIf="transaction.totalPayableAmount==null"align="right" class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' " style="color: red;" >Failed</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' "class="v-align-middle ht-Pending">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-Pending">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' "style="color: red;">{{transaction.paymentStatus}}</td>
                      <td *ngIf="transaction.initiatedDatetime!=null"align="left" class="v-align-middle">{{transaction.initiatedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.initiatedDatetime==null"align="left" class="v-align-middle">N/A</td>
          <!-- <td class="v-align-middle"><button class="btn btn-link"><i class="fa fa-share" aria-hidden="true"></i>
&nbsp;share</button></td> -->
        </tr>
      </tbody>
    </table>
</div>
</div>

<!-- -----------Failure--------- -->
<div *ngIf="failureTransaction"class="card-body">
  <div style="display: none;">{{failagentTransanction.responseMessage}}</div>
  <div class="table-responsive">
    <table datatable class="ch-table">
      <thead>
        <tr> 
          <th style="text-align: center;">Sl.No.</th>
          <th style="text-align: left;">Order ID </th>
          <th style="text-align: left;">Transaction Id</th>
          <th style="text-align: left;">Fuel</th>
          <th style="text-align: center;">Quantity in Ltrs</th>
          <th style="text-align: right;">Paid Amount</th>
          <th style="text-align: center;">Status</th>
          <th style="text-align: left;">Transaction Date Time</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let transaction of failagentTransanction.response; let i=index">
          <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="transaction.orderId!=null" align="left" class="v-align-middle">{{transaction.orderId}}</td>
                      <td *ngIf="transaction.orderId==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.trackId!=null"align="left" class="v-align-middle">{{transaction.paymentId}}</td>
                      <td *ngIf="transaction.trackId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.fuelType!=null" align="left" class="v-align-middle">{{transaction.fuelType}}</td>
                      <td *ngIf="transaction.fuelType==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.quantity!=null"align="center" class="v-align-middle">{{transaction.quantity}}</td>
                      <td *ngIf="transaction.quantity==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.totalPayableAmount!=null"align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i>{{transaction.totalPayableAmount | number : '1.2-2'}}</td>
                      <td *ngIf="transaction.totalPayableAmount==null"align="right" class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' " style="color: red;" >Failed</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' "class="v-align-middle ht-Pending">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-Pending">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' "style="color: red;">{{transaction.paymentStatus}}</td>
                      <td *ngIf="transaction.initiatedDatetime!=null"align="left" class="v-align-middle">{{transaction.initiatedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.initiatedDatetime==null"align="left" class="v-align-middle">N/A</td>
          <!-- <td class="v-align-middle"><button class="btn btn-link"><i class="fa fa-share" aria-hidden="true"></i>
&nbsp;share</button></td> -->
        </tr>
      </tbody>
    </table>
</div>
</div>

<!-- -----------cancelledTransaction--------- -->
<div *ngIf="cancelledTransaction"class="card-body">
  <div style="display: none;">{{canceagentTransanction.responseMessage}}</div>
  <div class="table-responsive">
    <table datatable class="ch-table">
      <thead>
        <tr> 
          <th style="text-align: center;">Sl.No.</th>
          <th style="text-align: left;">Order ID </th>
          <th style="text-align: left;">Transaction Id</th>
          <th style="text-align: left;">Fuel</th>
          <th style="text-align: center;">Quantity in Ltrs</th>
          <th style="text-align: right;">Paid Amount</th>
          <th style="text-align: center;">Status</th>
          <th style="text-align: left;">Transaction Date Time</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let transaction of canceagentTransanction.response; let i=index">
          <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="transaction.orderId!=null" align="left" class="v-align-middle">{{transaction.orderId}}</td>
                      <td *ngIf="transaction.orderId==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.trackId!=null"align="left" class="v-align-middle">{{transaction.paymentId}}</td>
                      <td *ngIf="transaction.trackId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.fuelType!=null" align="left" class="v-align-middle">{{transaction.fuelType}}</td>
                      <td *ngIf="transaction.fuelType==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.quantity!=null"align="center" class="v-align-middle">{{transaction.quantity}}</td>
                      <td *ngIf="transaction.quantity==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.totalPayableAmount!=null"align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i>{{transaction.totalPayableAmount | number : '1.2-2'}}</td>
                      <td *ngIf="transaction.totalPayableAmount==null"align="right" class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' " style="color: red;" >Failed</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' "class="v-align-middle ht-Pending">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-Pending">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' "style="color: red;">{{transaction.paymentStatus}}</td>
                      <td *ngIf="transaction.initiatedDatetime!=null"align="left" class="v-align-middle">{{transaction.initiatedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.initiatedDatetime==null"align="left" class="v-align-middle">N/A</td>
          <!-- <td class="v-align-middle"><button class="btn btn-link"><i class="fa fa-share" aria-hidden="true"></i>
&nbsp;share</button></td> -->
        </tr>
      </tbody>
    </table>
</div>
</div>
      
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h6>Order View Page</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <h5 style="margin-left: 15px; text-align: left; color: grey;">Order Details</h5>
  <div class="container"
  fxLayout
>
<div class="item item-1">
<p><b>Order ID</b></p>
<span>{{orderDetails.response.orderId}}</span>
</div>
<div class="item item-2" style="margin-left: 30px;">
  <p><b>Customer Name</b></p>
  <span *ngIf="orderDetails.response.customerId!=null" style="word-wrap: break-word;">{{orderDetails.response.customerId.firstName}} {{orderDetails.response.customerId.lastName}}</span>
      <span *ngIf="orderDetails.response.customerId==null">N/A</span>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p><b>Customer Mobile</b></p>
  <span *ngIf="orderDetails.response.customerId!=null">{{orderDetails.response.customerId.mobileNumber}}</span>
      <span *ngIf="orderDetails.response.customerId==null">N/A</span>
</div>
<div class="item item-4" style="margin-left: 30px;">
  <p><b>Booking Date & Time</b></p>
  <span *ngIf="orderDetails.response.bookedDate!=null">{{orderDetails.response.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span>
      <span *ngIf="orderDetails.response.bookedDate==null">N/A</span>
</div>
<div class="item item-5" style="margin-left: 30px;">
  <p><b>Quantity in Ltrs</b></p>
  <span *ngIf="orderDetails.response.quantity!=null">{{orderDetails.response.quantity}}</span>
      <span *ngIf="orderDetails.response.quantity==null">N/A</span>
</div>
<div class="item item-7" style="margin-left: 30px;">
  <p><b>Order Type</b></p>
<span *ngIf="orderDetails.response.bookLaterTime===''; else ifNotShow">
  Immediate
</span>
<ng-template #ifNotShow>
  <span>Scheduled</span>
</ng-template>
</div>
<div *ngIf="orderDetails.response.status==='Cancelled'" class="item item-8" style="margin-left: 30px;">
  <p><b>Cancelled Date & Time</b></p>
  <span >{{orderDetails.response.orderCancellationDatetime |date:'dd/MM/yyyy hh:mm:ss a'}}</span>
</div>
<div *ngIf="orderDetails.response.status==='Cancelled'" class="item item-9" style="margin-left: 30px;">
  <p><b>Cancelled By</b></p>
  <span *ngIf="orderDetails.response.cancelledBy!=null">
    <span style="font-size: 10px;">Name :{{orderDetails.response.cancelledBy.firstName}} {{orderDetails.response.cancelledBy.lastName}}</span><br>
    <span style="font-size: 10px;">Mobile :{{orderDetails.response.cancelledBy.mobileNumber}}</span>
  </span>
  <span *ngIf="orderDetails.response.cancelledBy==null">N/A</span>
</div>
<div *ngIf="orderDetails.response.status==='Cancelled'" class="item item-9" style="margin-left: 30px;">
  <p><b>Reason for Cancelling</b></p>
  <span *ngIf="orderDetails.response.cancellationId!=null" style="word-wrap: break-word;">{{orderDetails.response.cancellationId.cancellationMessage}}</span>
  <span *ngIf="orderDetails.response.cancellationId==null">N/A</span>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p><b>Status</b></p>
  <span *ngIf="orderDetails.response.status==='Pending'"><span class="ht-Pending"><b>{{orderDetails.response.status}}</b></span></span>
      <span *ngIf="orderDetails.response.status==='Cancelled'"><span style="color: red;"><b>{{orderDetails.response.status}}</b></span></span>
      <span *ngIf="orderDetails.response.status==='Inprogress'"><span style="color: blue;"><b>{{orderDetails.response.status}}</b></span></span>
      <span *ngIf="orderDetails.response.status==='Delivered'"><span class="ht-active"><b>{{orderDetails.response.status}}</b></span></span>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p><b>Coupon Code</b></p>
  <span *ngIf="orderDetails.response.couponCode!=null">{{orderDetails.response.couponCode}}</span>
  <span *ngIf="orderDetails.response.couponCode==null">N/A</span>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p><b>Coupon Discount Price</b></p>
  <span *ngIf="orderDetails.response.couponCode!=null">
  <span *ngIf="orderDetails.response.couponDiscountFlat==1">
    <span>{{orderDetails.response.couponDiscountPrice}} % </span>
  </span>
  <span *ngIf="orderDetails.response.couponDiscountFlat==2">
    <span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.couponDiscountPrice | number : '1.2-2'}}</span>
  </span>
  </span>
  <span *ngIf="orderDetails.response.couponCode==null">N/A</span>
</div>
  </div>
<br>
  <h5 style="margin-left: 15px; text-align: left; color: grey;">Delivery Agent Info</h5>
  <div class="container"
  fxLayout
>
<div class="item item-1">
<p><b>Delivery Agent Name</b></p>
<span *ngIf="orderDetails.response.orderAcceptedBy!=null" style="word-wrap: break-word;">{{orderDetails.response.orderAcceptedBy.firstName}} {{orderDetails.response.orderAcceptedBy.lastName}}</span>
      <span *ngIf="orderDetails.response.orderAcceptedBy==null">N/A</span>
</div>
<div class="item item-2" style="margin-left: 40px;">
  <p><b>Delivery Agent Number</b></p>
  <span *ngIf="orderDetails.response.orderAcceptedBy!=null">{{orderDetails.response.orderAcceptedBy.mobileNumber}}</span>
      <span *ngIf="orderDetails.response.orderAcceptedBy==null">N/A</span>
</div>
<div class="item item-4" style="margin-left: 40px;">
  <p><b>Vehicle Number</b></p>
  <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.vehicleNumber}}</span>
      <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
</div>
<div class="item item-5" style="margin-left: 40px;">
  <p><b>Fuel</b></p>
  <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.fuelType.fuelTypeName}}</span>
      <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
</div>
<div *ngIf="orderDetails.response.status==='Completed'" class="item item-6" style="margin-left: 40px;">
  <p><b>Delivered  Date Time</b></p>
  <span >{{orderDetails.response.orderDeliveredDateTime |date:'dd/MM/yyyy hh:mm:ss a' }}</span>
</div>
<div class="item item-7" style="margin-left: 40px;">
  <p><b>Delivery location</b></p>
  <span *ngIf="orderDetails.response.deliveryAddress!=null">{{orderDetails.response.deliveryAddress}}</span>
      <span *ngIf="orderDetails.response.deliveryAddress==null">N/A</span>
</div>
  </div>

  <br>
  <h5 *ngIf="orderDetails.response.status==='Completed'" style="margin-left: 15px; text-align: left; color: grey;">Transaction Details</h5>
  <div *ngIf="orderDetails.response.status==='Completed'" class="container"
  fxLayout
>
<div class="item item-1">
  <p *ngIf="orderDetails.response.totalfuelcost!=null"class="card-title"><b>Total Fuel Cost</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.totalfuelcost | number : '1.2-2'}}</span></p>
  <p *ngIf="orderDetails.response.totalfuelcost==null"class="card-title"><b>Total Fuel Cost</b><br><span>N/A</span></p>
</div>
<div class="item item-1" style="margin-left: 30px;">
  <p *ngIf="orderDetails.response.deliveryCharges!=null"class="card-title"><b>Delivery Charges</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.deliveryCharges | number : '1.2-2'}}</span></p>
          <p *ngIf="orderDetails.response.deliveryCharges==null"class="card-title"><b>Delivery Charges</b><br><span>N/A</span></p>
  </div>
  <div class="item item-1" style="margin-left: 30px;">
    <p *ngIf="orderDetails.response.sgst!=null"class="card-title"><b>SGST</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.sgst | number : '1.2-2'}}</span></p>
            <p *ngIf="orderDetails.response.sgst==null"class="card-title"><b>SGST</b><br><span>N/A</span></p>
    </div>
    <div class="item item-1" style="margin-left: 30px;">
      <p *ngIf="orderDetails.response.cgst!=null"class="card-title"><b>CGST</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.cgst | number : '1.2-2'}}</span></p>
              <p *ngIf="orderDetails.response.cgst==null"class="card-title"><b>CGST</b><br><span>N/A</span></p>
      </div>
      <div class="item item-1" style="margin-left: 30px;">
        <p *ngIf="orderDetails.response.internetHandlingFee!=null"class="card-title"><b>Platform Charges</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.internetHandlingFee | number : '1.2-2'}}</span></p>
                <p *ngIf="orderDetails.response.internetHandlingFee==null"class="card-title"><b>Platform Charges</b><br><span>N/A</span></p>
        </div>    
        <div class="item item-1" style="margin-left: 30px;">
                  <p *ngIf="orderDetails.response.totalPayableAmount!=null"class="card-title"><b>Total Paid Amount</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.totalPayableAmount | number : '1.2-2'}}</span></p>
                  <p *ngIf="orderDetails.response.totalPayableAmount==null"class="card-title"><b>Total Paid Amount</b><br><span>N/A</span></p>

          </div>     
<div class="item item-2" style="margin-left: 30px;">
  <p *ngIf="orderDetails.response.transactionId!=null"class="card-title"><b>Transaction ID</b><br><span>{{orderDetails.response.transactionId}}</span></p>
          <p *ngIf="orderDetails.response.transactionId==null"class="card-title"><b>Transaction ID</b><br><span>N/A</span></p>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p *ngIf="orderDetails.response.paymentStatus==='Success'" class="card-title"><b>Payment Status </b><br><span class="ht-Success"><b>{{orderDetails.response.paymentStatus}}</b></span></p>
        <p *ngIf="orderDetails.response.paymentStatus==='Pending'" class="card-title"><b>Payment Status </b><br><span class="ht-Pending"><b>{{orderDetails.response.paymentStatus}}</b></span></p>
        <p *ngIf="orderDetails.response.paymentStatus==='Failure'" class="card-title"><b>Payment Status </b><br><span style="color: red;"><b>Failed</b></span></p>
        <p *ngIf="orderDetails.response.paymentStatus==='Initiated'" class="card-title"><b>Payment Status</b> <br><span class="ht-Pending"><b>Pending</b></span></p>
</div>
<div class="item item-4" style="margin-left: 30px;">
  <p class="card-title"><b>Transaction Date & Time</b> <br><span>{{orderDetails.response.initiatedDatetime |date:'dd/MM/yyyy hh:mm:ss a'}}</span></p>
</div>
  </div>
  <hr *ngIf="orderDetails.response.status=='Cancelled'" class="beautiful-line">
  <h5 *ngIf="orderDetails.response.status=='Cancelled'" style="margin-left: 15px; text-align: left; color: grey;">Order Track Info</h5>
  <br>
  <div *ngIf="orderDetails.response.status=='Cancelled'"class="container"
    fxLayout
  >
  <div class="item item-1" style="margin-left: 20px;">
    <span>
      <img *ngIf="orderTrackDetails.response.orderPlaced==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
      <img *ngIf="orderTrackDetails.response.orderPlaced==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
      <br>
    </span>
    <span style="margin-bottom: 10px;"><b>Order Placed</b></span><br>
    <span *ngIf="orderTrackDetails.response.orderPlacedDateTime!=null" style="text-align: center;">
      {{orderTrackDetails.response.orderPlacedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
    </span>
    <span *ngIf="orderTrackDetails.response.orderPlacedDateTime==null">
      Will be Updated
    </span>
  </div>
  <div class="item item-2" style="margin-left: -80px;">
    <b><hr width="80px" color="pink"></b>
  </div>
  <div class="item item-3" style="margin-left: 15px;">
    <span>
      <img *ngIf="orderTrackDetails.response.orderAccepted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
      <img *ngIf="orderTrackDetails.response.orderAccepted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>    
    </span>
    <span style="margin-bottom: 10px;"><b>Agent Accepted</b></span><br>
    <span *ngIf="orderTrackDetails.response.orderAcceptedDateTime!=null">
      {{orderTrackDetails.response.orderAcceptedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
    </span>
    <span style="color: red;" *ngIf="orderTrackDetails.response.orderAcceptedDateTime==null">
      Order Cancelled
    </span>
  </div>
  <div class="item item-4" style="margin-left: -50px;">
    <b><hr width="80px" color="pink"></b>
  </div>
  <div class="item item-5" style="margin-left: 15px;">
    <img *ngIf="orderTrackDetails.response.paymentCompleted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
      <img *ngIf="orderTrackDetails.response.paymentCompleted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>    
      <span style="margin-bottom: 10px; width: 30px;"><b>Payment Completed</b></span><br>
      <span style="color: red;">
        Order Cancelled
      </span>    
  </div>
  <div class="item item-6" style="margin-left: -60px;">
    <b><hr width="80px" color="pink"></b>
  </div>
  <div class="item item-7" style="margin-left: 15px;">
    <span>
      <img *ngIf="orderTrackDetails.response.orderInitiated==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
      <img *ngIf="orderTrackDetails.response.orderInitiated==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
    </span>
    <span style="margin-bottom: 10px;"><b>Order Initiated</b></span><br>
    <span style="color: red;">
      Order Cancelled
    </span>  
  </div>
  <div class="item item-8" style="margin-left: -40px;">
    <b><hr width="80px" color="pink"></b>
  </div>
  <div class="item item-9" style="margin-left: 15px;">
    <span>
      <img *ngIf="orderTrackDetails.response.refillOtp==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
      <img *ngIf="orderTrackDetails.response.refillOtp==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
    </span>
    <span style="margin-bottom: 10px;"><b>Refill OTP Verified</b></span><br>
    <span style="color: red;">
      Order Cancelled
    </span>  
  </div>
  <div class="item item-10" style="margin-left: -60px;">
    <b><hr width="80px" color="pink"></b>
  </div>
  <div class="item item-11" style="margin-left: 15px;">
    <span>
      <img *ngIf="orderTrackDetails.response.endOrderOtp==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
      <img *ngIf="orderTrackDetails.response.endOrderOtp==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
    </span>
    <span style="margin-bottom: 10px;"><b>END Order OTP Verified</b></span><br>
    <span style="color: red;">
      Order Cancelled
    </span>  
  </div>
  <div class="item item-12" style="margin-left: -80px;">
    <b><hr width="80px" color="pink"></b>
  </div>
  <div class="item item-13" style="margin-left: 15px;">
    <span>
      <img *ngIf="orderTrackDetails.response.orderCompleted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
      <img *ngIf="orderTrackDetails.response.orderCompleted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
    </span>
    <span style="margin-bottom: 10px;"><b>Order Completed</b></span><br>
    <span style="color: red;">
      Order Cancelled
    </span>  
  </div>
  <div class="item item-14" style="margin-left: -40px;">
    <b><hr width="60px" color="pink"></b>
  </div>
  <div class="item item-14" style="margin-left: 15px;">
    <span>
      <img src="assets/img/remove.svg" alt="logo" data-src="assets/img/remove.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
    </span>
    <span style="margin-bottom: 10px;"><b>Order Cancelled</b></span><br>
    <span *ngIf="orderTrackDetails.response.orderPlacedDateTime!=null" style="text-align: center;">
      {{orderTrackDetails.response.orderCancelledDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
    </span>  
  </div>
    </div> 
  <hr *ngIf="orderDetails.response.status!=='Cancelled'" class="beautiful-line">
  <div *ngIf="orderDetails.response.status!=='Cancelled'"class="container"
  fxLayout
>
<div class="item item-1" style="margin-left: 20px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderPlaced==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderPlaced==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Placed</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderPlacedDateTime!=null" style="text-align: center;">
    {{orderTrackDetails.response.orderPlacedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderPlacedDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-2" style="margin-left: -80px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-3" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderAccepted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderAccepted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>    
  </span>
  <span style="margin-bottom: 10px;"><b>Agent Accepted</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderAcceptedDateTime!=null">
    {{orderTrackDetails.response.orderAcceptedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderAcceptedDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-4" style="margin-left: -50px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-5" style="margin-left: 15px;">
  <img *ngIf="orderTrackDetails.response.paymentCompleted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.paymentCompleted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>    
    <span style="margin-bottom: 10px;"><b>Payment Completed</b></span><br>
    <span *ngIf="orderTrackDetails.response.paymentCompletedDateTime!=null">
      {{orderTrackDetails.response.paymentCompletedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
    </span>
    <span *ngIf="orderTrackDetails.response.paymentCompletedDateTime==null">
      Will be Updated
    </span>    
</div>
<div class="item item-6" style="margin-left: -60px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-7" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderInitiated==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderInitiated==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Initiated</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderInitiatedDateTime!=null">
    {{orderTrackDetails.response.orderInitiatedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderInitiatedDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-8" style="margin-left: -40px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-9" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.refillOtp==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.refillOtp==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Refill OTP Verified</b></span><br>
  <span *ngIf="orderTrackDetails.response.refillDateTime!=null">
    {{orderTrackDetails.response.refillDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.refillDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-10" style="margin-left: -60px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-11" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.endOrderOtp==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.endOrderOtp==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>END Order OTP Verified</b></span><br>
  <span *ngIf="orderTrackDetails.response.endOrderDateTime!=null">
    {{orderTrackDetails.response.endOrderDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.endOrderDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-12" style="margin-left: -80px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-13" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderCompleted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderCompleted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Completed</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderCompletedDateTime!=null">
    {{orderTrackDetails.response.orderCompletedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderCompletedDateTime==null">
    Will be Updated
  </span>
</div>
  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
  </div>
</ng-template>

<ng-template #content1 let-modal>
  <div class="modal-header">
    <h5>Assign Vehicle</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="container"
  fxLayout
>

<div class="item item-1">
  <p><b>Order ID</b></p>
  <span>{{orderDetails.response.orderId}}</span>
</div>
<div class="item item-2" style="margin-left: 25px;">
  <p><b>Customer Name</b></p>
  <span style="word-wrap: break-word;">{{orderDetails.response.customerId.firstName}} {{orderDetails.response.customerId.lastName}}</span>
</div>
<div class="item item-3" style="margin-left: 25px;">
  <p><b>Customer Mobile</b></p>
  <span>{{orderDetails.response.customerId.mobileNumber}}</span>
</div>
<div class="item item-4" style="margin-left: 25px;">
  <p><b>Status</b></p>
  <span *ngIf="orderDetails.response.status==='Pending'"><span class="ht-Pending"><b>{{orderDetails.response.status}}</b></span></span>
  <span *ngIf="orderDetails.response.status==='Cancelled'"><span style="color: red;"><b>{{orderDetails.response.status}}</b></span></span>
  <span *ngIf="orderDetails.response.status==='Inprogress'"><span style="color: blue;"><b>{{orderDetails.response.status}}</b></span></span>
  <span *ngIf="orderDetails.response.status==='Completed'"><span class="ht-active"><b>{{orderDetails.response.status}}</b></span></span>
</div>
<div class="item item-5" style="margin-left: 25px;">
  <p><b>Booking Date & Time</b></p>
  <span>{{orderDetails.response.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span>
</div>
<div class="item item-6" style="margin-left: 25px;">
  <p><b>Delivery Date & Time</b></p>
  <span>N/A</span>
</div>
<div class="item item-7" style="margin-left: 25px;">
  <p><b>Fuel</b></p>
  <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.fuelType.fuelTypeName}}</span>
      <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
</div>
<div class="item item-8" style="margin-left: 25px;">
  <p><b>Quantity in Ltrs</b></p>
  <span >{{orderDetails.response.quantity}}</span>
</div>
<br>
  </div>
  <br>
  <div class="container"
  fxLayout
>
  <div class="item item-1">
    <p><b>Vehicle Name</b></p>
    <div class="ch-form-group">
      <select id="countryFormControl" value="countryFormControl" class="form-control" #mySelect (change)='onOptionsSelected(mySelect.value)'>
        <option *ngFor="let vehicle of activeVehicle.response" value={{vehicle.vehicleId}}>{{vehicle.vehicleNumber}} ({{vehicle.userId.firstName}} {{vehicle.userId.lastName}})</option>
      </select>
  </div>
  </div>
  <div class="item item-2" style="margin-left: 25px;">
    <p><b>Delivery Agent Mobile</b></p>
    <span *ngIf="check==null">
      <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.userId.firstName}} {{orderDetails.response.vehicleId.userId.lastName}}</span>
      <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
    </span>
    <span *ngIf="check!=null">{{vehicle.userId.mobileNumber}}</span>
  </div>
  <div class="item item-3" style="margin-left: 25px;">
    <p><b>Delivery Agent Email</b></p>
    <span *ngIf="check==null">
      <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.userId.mobileNumber}}</span>
          <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
    </span>
    <span *ngIf="check!=null">{{vehicle.userId.emailAddress}}</span>
  </div>
  <div class="item item-4" style="margin-left: 25px;">
    <p><b>Vehicle Number</b></p>
    <span *ngIf="check==null">{{orderDetails.response.vehicleId.vehicleNumber}}</span>
    <span *ngIf="check!=null">{{vehicle.vehicleNumber}}</span>
  </div>
  <div class="item item-5" style="margin-left: 25px;">
    <p><b>Delivery location</b></p>
    <span>{{orderDetails.response.deliveryAddress}}</span>
  </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="assign()" (click)="modal.close('Save click')">Assign</button>
  </div>
</ng-template>

<ng-template #content5 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Reason for cancellation of Order</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
  <form>
      <br>
      <div class="ch-form-group">
          <select id="cancelmessage"  name="cancelmessage" class="form-control" style="width: 100%;">
            <option *ngFor="let cancel of cancelMessageModel.response" value={{cancel.id}}>{{cancel.cancellationMessage}}</option>
          </select>
      </div>	
      <br>
      <div class="ch-form-group">
           <textarea placeholder="Should not exceed more than 150 characters" class="form-control textarea" name="cancelremarks" maxlength="150" id="cancelremarks" rows="3"></textarea>
      </div>
      <br>
    <b>Note</b> : <span style="color: grey;">Should not exceed more than 150 characters</span> 
      </form>
      <br>
      <div *ngIf="errorFlag1" style="color: red;">{{errorMessage}}</div>
    </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" style="color: green;" (click)="cancelOrder()">Submit</button>
  </div>
</ng-template>

<ng-template #reject let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Do you want unblock the user?</label>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="unblock()" (click)="modal.close('Save click')">yes</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">no</button>
  </div>
</ng-template>