import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { CustomerOrderModel } from '../model/customerorder';
import { OrderAssignModel } from '../model/orderassign';
import { GenericResponseModel } from '../model/genricresponse';
import { OrderTransactionService } from '../services/ordertran.service';
import { PartnersService } from '../services/partners.service';
import { UserService } from '../services/user.service';
import { UserModel } from '../model/user';
import { GenericResponse } from '../model/genricmodelresponse';
import * as moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { VehicleFuelService } from '../services/vehivle.service';
import { VehicleAttachModel } from '../model/vehicleModel';
import * as FileSaver from 'file-saver';
import { Workbook } from 'exceljs';

@Component({
  selector: 'app-report-platform-invoice',
  templateUrl: './report-platform-invoice.component.html',
  styleUrls: ['./report-platform-invoice.component.css']
})
export class ReportPlatformInvoiceComponent  {

   public isCollapsed = false;
  active = 1;
closeResult = '';
orderDetail:GenericResponseModel;
activePartner:GenericResponseModel;
activeVehicle:GenericResponseModel;
vehicleModel : CustomerOrderModel;
orderId:Number;
pencustomerOrder:GenericResponseModel;
   inpcustomerOrder:GenericResponseModel;
   comcustomerOrder:GenericResponseModel;
   cancustomerOrder:GenericResponseModel;
   allcustomerOrder:GenericResponseModel;
  allCustomer:boolean = false;
  penCustomer:boolean = false;
  inpCustomer:boolean = false;
  comCustomer:boolean = true;
  canCustomer:boolean = false;

  allCustomerfilter:boolean = false;
  penCustomerfilter:boolean = false;
  inpCustomerfilter:boolean = false;
  comCustomerfilter:boolean = false;
  canCustomerfilter:boolean = false;

  onboardedfilter1:boolean = false;
  onboardedfilter2:boolean = false;

  pencustomerOrderfilter:GenericResponseModel;
  inpcustomerOrderfilter:GenericResponseModel;
  comcustomerOrderfilter:GenericResponseModel;
  cancustomerOrderfilter:GenericResponseModel;
  allcustomerOrderfilter:GenericResponseModel;

  onboardedcustomerOrderfilter1:GenericResponseModel;
  onboardedcustomerOrderfilter2:GenericResponseModel;

  defaultCustomer:boolean = false;
  activevehi:Object;
  activeparmodel:UserModel;
  activemobile:Number;
  activeemila:String;
  errorFlag:boolean = false;
  errorFlag1:boolean = false;
  errorMessage:String;
  cancelMessageModel:GenericResponseModel;
orderDetails:GenericResponseModel;
orderTrackDetails:GenericResponse;
asstesInfo:GenericResponseModel;
orderID:Number;
successalert:boolean=false;
successmessage:String;
startDate:String = null;
endDate:String = null;
refreshFlag:boolean = false;

sDate:Date;
eDate:Date;
check:String = null;
vehicle:VehicleAttachModel;
excelexportflag:boolean;
vehicleRegLatitude:any;
vehicleRegLongtitude:any;
  responseDataListnew = [];
 
  constructor(private modalService: NgbModal,private ordertransactionservice: OrderTransactionService,private partnerservice:PartnersService,private route: ActivatedRoute,private router: Router,private userservice:UserService,private vehicleservice:VehicleFuelService) {}

  open(content,id) {
  this.ordertransactionservice.getOrderById(id).subscribe(data=>{
  this.orderDetails=data;
},err => {
  if(err.error.statusCode==406){
    this.router.navigate(['/login']);
  }else if(err.error.statusCode==500){
    this.router.navigate(['/page-error']);
  }else if(err.error.statusCode==404){
    this.router.navigate(['/pagenotfound']);
  }
});

  this.ordertransactionservice.tracking(id).subscribe(data=>{
    this.orderTrackDetails = data;
  });

  this.ordertransactionservice.getAssets(id).subscribe(data=>{
    this.asstesInfo = data;
  });

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open1(content1,id) {
    this.orderId = id;

    this.ordertransactionservice.getActivatevehicle().subscribe(data=>{
      this.activeVehicle = data;
      this.activevehi = this.activeVehicle.response[0];
    });

    this.partnerservice.getAllActivepartner().subscribe(data=>{
      this.activePartner = data;
    })

    this.partnerservice.getOrderById(id).subscribe(data=>{
      this.vehicleModel = data;
      this.vehicleRegLatitude = this.vehicleModel.vehicleId.vehicleRegLatitude;
      this.vehicleRegLongtitude = this.vehicleModel.vehicleId.vehicleRegLongtitude;
    });

    this.ordertransactionservice.getOrderById(id).subscribe(data=>{
      this.orderDetails=data;
    })
    this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
      
      cancelPopup(content5,id){
        this.orderID = id;
        this.ordertransactionservice.cancellmessage().subscribe(data=>{
          this.cancelMessageModel = data;
        },err => {
          if(err.error.statusCode==406){
            this.router.navigate(['/login']);
          }else if(err.error.statusCode==500){
            this.router.navigate(['/page-error']);
          }else if(err.error.statusCode==404){
            this.router.navigate(['/pagenotfound']);
          }
       });
        this.modalService.open(content5, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }

      assign(){
        let id = (<HTMLInputElement>document.getElementById('countryFormControl')).value;
        let latitude = this.vehicleRegLatitude;
        let langitude = this.vehicleRegLongtitude;
        let assign = new OrderAssignModel(Number(id),sessionStorage.getItem('username'),latitude,langitude);
        this.ordertransactionservice.assignto(this.orderId,assign).subscribe(data=>{
          Swal.fire({
            html: '<pre>' + data.responseMessage + '</pre>',
            customClass: {
              popup: 'format-pre'
            }
          }).then((value) => {
            window.location.reload();
          });; ; 
        },err => {
          if(err.error.statusCode==406){
            this.router.navigate(['/login']);
          }else if(err.error.statusCode==500){
            this.router.navigate(['/page-error']);
          }else if(err.error.statusCode==404){
            this.router.navigate(['/pagenotfound']);
          }
       });
      }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {

    this.ordertransactionservice.getAllCompletion(null,null).subscribe(data =>{
      this.comcustomerOrder=data;
    });

    this.ordertransactionservice.getAllOrders(this.startDate,this.endDate).subscribe(data=>{
      this.orderDetail=data;
    });

    this.ordertransactionservice.getAllOrders(this.startDate,this.endDate).subscribe(data =>{
      if(data.statusCode==200){
        this.excelexportflag = true;
      }else{
        this.excelexportflag = false;
      }
      this.allcustomerOrder=data;
    });

    this.ordertransactionservice.getAllPending(this.startDate,this.endDate).subscribe(data =>{
      if(data.statusCode==200){
        this.excelexportflag = true;
      }else{
        this.excelexportflag = false;
      }
      this.pencustomerOrder=data;
    });
  }

  pending(){
    this.allCustomer = false;
    this.penCustomer = true;
    this.inpCustomer = false;
    this.comCustomer = false;
    this.canCustomer = false;
    this.defaultCustomer = false;
    this.ordertransactionservice.getAllPending(this.startDate,this.endDate).subscribe(data =>{
      this.pencustomerOrder=data;
    });
  }

  inprogress(){
    this.allCustomer = false;
    this.penCustomer = false;
    this.inpCustomer = true;
    this.comCustomer = false;
    this.canCustomer = false;
    this.defaultCustomer = false;
    this.ordertransactionservice.getAllInprogress(this.startDate,this.endDate).subscribe(data =>{
      this.inpcustomerOrder=data;
    });
  }

  completed(){
    this.allCustomer = false;
    this.penCustomer = false;
    this.inpCustomer = false;
    this.comCustomer = true;
    this.canCustomer = false;
    this.defaultCustomer = false;
    this.ordertransactionservice.getAllCompletion(this.startDate,this.endDate).subscribe(data =>{
      this.comcustomerOrder=data;
    });
  }

  cancelled(){
    this.allCustomer = false;
    this.penCustomer = false;
    this.inpCustomer = false;
    this.comCustomer = false;
    this.canCustomer = true;
    this.defaultCustomer = false;
    this.ordertransactionservice.getAllCancellation(this.startDate,this.endDate).subscribe(data =>{
      this.cancustomerOrder=data;
    });
  }

  all(){
    this.allCustomer = true;
    this.penCustomer = false;
    this.inpCustomer = false;
    this.comCustomer = false;
    this.canCustomer = false;
    this.defaultCustomer = false;
    this.ordertransactionservice.getAllOrders(this.startDate,this.endDate).subscribe(data =>{
      this.allcustomerOrder=data;
    });
  }

  onOptionsSelected(value:Number){
    this.vehicleservice.getVehicleById(value).subscribe(data=>{
      this.vehicle = data;
      this.check = data.vehicleName;
    });
  }
  cancelOrder(){
    let desc = (<HTMLInputElement>document.getElementById('cancelremarks')).value;
    let cancelmessage = (<HTMLInputElement>document.getElementById('cancelmessage')).value;
    if(desc!=null && desc!==''){
      this.errorFlag1 = false;
      let formData= {
        userId:Number(sessionStorage.getItem('id')),
        cancellationId:cancelmessage,
        orderRemarks:desc,
      }
      this.orderID
      this.ordertransactionservice.cancelorder(this.orderID,formData).subscribe(data=>{
        if(data.statusCode==200){
          Swal.fire({
            html: '<pre>' + data.responseMessage + '</pre>',
            customClass: {
              popup: 'format-pre'
            }
          }).then((value) => {
            window.location.reload();
          });; ; 
        }else{
          Swal.fire({
            html: '<pre>' + data.responseMessage + '</pre>',
            customClass: {
              popup: 'format-pre'
            }
          }).then((value) => {
            window.location.reload();
          });; ; 
        }
      },err => {
        if(err.error.statusCode==406){
          this.router.navigate(['/login']);
        }else if(err.error.statusCode==500){
          this.router.navigate(['/page-error']);
        }else if(err.error.statusCode==404){
          this.router.navigate(['/pagenotfound']);
        }
     });
      this.modalService.dismissAll();
    }else{
      this.errorFlag1 = true;
      this.errorMessage = "Please enter remarks!";
      setTimeout(function() {
        this.errorFlag1 = false;
    }.bind(this), 3000);

    }
  }

  callsearch(event){
    if(event.target.value==1){
      $('#status').show();
      $('#fromdate').hide();
      $('#todate').hide();
      $('#onboarded').hide();
    }else if(event.target.value==2){
      var nowdate = new Date();
      var monthStartDay = new Date(nowdate.getFullYear(), nowdate.getMonth(), 1);
      var monthEndDay = new Date(nowdate.getFullYear(), nowdate.getMonth() + 1, 0);
      let startDate = moment(new Date(monthStartDay)).format("yyyy-MM-DD").toString();
      let endDate = moment(new Date(monthEndDay)).format("yyyy-MM-DD").toString();
      this.startDate = startDate;
      this.endDate = endDate;
      $('#status').show();
      $('#fromdate').show();
      $('#todate').show();
      $('#onboarded').hide();
    }else if(event.target.value==3){
      $('#status').hide();
      $('#fromdate').hide();
      $('#todate').hide();
      $('#onboarded').show();
    }
  }

  search(){
    this.refreshFlag = true;
    let searchBy = (<HTMLInputElement>document.getElementById('searchBy')).value;
    let status = (<HTMLInputElement>document.getElementById('status')).value;
    let fromdate = (<HTMLInputElement>document.getElementById('fromdate')).value;
    let todate = (<HTMLInputElement>document.getElementById('todate')).value;
    let onboarded = (<HTMLInputElement>document.getElementById('onboarded')).value;
    if(searchBy=='1'){
          this.allCustomerfilter = false;
          this.penCustomerfilter = false;
          this.inpCustomerfilter = false;
          this.comCustomerfilter = false;
          this.canCustomerfilter = false;
          this.defaultCustomer = false;
          this.onboardedfilter1 = false;
          this.onboardedfilter2 =  false;
      if(status==''){
        this.errorFlag = true;
        this.errorMessage = "Please select Status!";
      }else{
        this.errorFlag = false;
        if(status=='1'){
          this.allCustomer = true;
          this.penCustomer = false;
          this.inpCustomer = false;
          this.comCustomer = false;
          this.canCustomer = false;
          this.defaultCustomer = false;
          this.ordertransactionservice.getAllOrders(null,null).subscribe(data =>{
            if(data.response.length>0){
              this.excelexportflag = true;
            }else{
              this.excelexportflag = false;
            }
            this.allcustomerOrder=data;
          });
            }else if(status=='2'){
              this.allCustomer = false;
              this.penCustomer = true;
              this.inpCustomer = false;
              this.comCustomer = false;
              this.canCustomer = false;
              this.defaultCustomer = false;
              this.ordertransactionservice.getAllPending(null,null).subscribe(data =>{
                if(data.response.length>0){
                  this.excelexportflag = true;
                }else{
                  this.excelexportflag = false;
                }
                this.pencustomerOrder=data;
              });
            }else if(status=='3'){
              this.allCustomer = false;
              this.penCustomer = false;
              this.inpCustomer = true;
              this.comCustomer = false;
              this.canCustomer = false;
              this.defaultCustomer = false;
              this.ordertransactionservice.getAllInprogress(null,null).subscribe(data =>{
                if(data.response.length>0){
                  this.excelexportflag = true;
                }else{
                  this.excelexportflag = false;
                }
                this.inpcustomerOrder=data;
              });
            }else if(status=='4'){
              this.allCustomer = false;
              this.penCustomer = false;
              this.inpCustomer = false;
              this.comCustomer = true;
              this.canCustomer = false;
              this.defaultCustomer = false;
              this.ordertransactionservice.getAllCompletion(null,null).subscribe(data =>{
                if(data.response.length>0){
                  this.excelexportflag = true;
                }else{
                  this.excelexportflag = false;
                }
                this.comcustomerOrder=data;
              });
            }else if(status=='5'){
              this.allCustomer = false;
              this.penCustomer = false;
              this.inpCustomer = false;
              this.comCustomer = false;
              this.canCustomer = true;
              this.defaultCustomer = false;
              this.ordertransactionservice.getAllCancellation(null,null).subscribe(data =>{
                if(data.response.length>0){
                  this.excelexportflag = true;
                }else{
                  this.excelexportflag = false;
                }
                this.cancustomerOrder=data;
              });
            }
      }
    }else if(searchBy=='2'){
      this.onboardedfilter1 = false;
      this.onboardedfilter2 =  false;
      this.allCustomer = false;
      this.penCustomer = false;
      this.inpCustomer = false;
      this.comCustomer =false;
      this.canCustomer = false;
      if(status==''){
        this.errorFlag = true;
        this.errorMessage = "Please select Status!";
      }else if(fromdate==''||todate==''){
        this.errorFlag = true;
        this.errorMessage = "Please select Dates!";
      }else if(todate < fromdate){
        this.errorFlag = true;
        this.errorMessage = "End Date should be greater than Start Date!";
      }else{
        this.errorFlag = false;
        if(status=='1'){
          this.allCustomerfilter = true;
          this.penCustomerfilter = false;
          this.inpCustomerfilter = false;
          this.comCustomerfilter = false;
          this.canCustomerfilter = false;
          this.defaultCustomer = false;
          this.ordertransactionservice.getAllOrders(fromdate,todate).subscribe(data =>{
            if(data.response.length>0){
              this.excelexportflag = true;
            }else{
              this.excelexportflag = false;
            }
            this.allcustomerOrderfilter=data;
          });
            }else if(status=='2'){
              this.allCustomerfilter = false;
              this.penCustomerfilter = true;
              this.inpCustomerfilter = false;
              this.comCustomerfilter = false;
              this.canCustomerfilter = false;
              this.defaultCustomer = false;
              this.ordertransactionservice.getAllPending(fromdate,todate).subscribe(data =>{
                if(data.response.length>0){
                  this.excelexportflag = true;
                }else{
                  this.excelexportflag = false;
                }
                this.pencustomerOrderfilter=data;
              });
            }else if(status=='3'){
              this.allCustomerfilter = false;
              this.penCustomerfilter = false;
              this.inpCustomerfilter = true;
              this.comCustomerfilter = false;
              this.canCustomerfilter = false;
              this.defaultCustomer = false;
              this.ordertransactionservice.getAllInprogress(fromdate,todate).subscribe(data =>{
                if(data.response.length>0){
                  this.excelexportflag = true;
                }else{
                  this.excelexportflag = false;
                }
                this.inpcustomerOrderfilter=data;
              });
            }else if(status=='4'){
              this.allCustomerfilter = false;
              this.penCustomerfilter = false;
              this.inpCustomerfilter = false;
              this.comCustomerfilter = true;
              this.canCustomerfilter = false;
              this.defaultCustomer = false;
              this.ordertransactionservice.getAllCompletion(fromdate,todate).subscribe(data =>{
                if(data.response.length>0){
                  this.excelexportflag = true;
                }else{
                  this.excelexportflag = false;
                }
                this.comcustomerOrderfilter=data;
              });
            }else if(status=='5'){
              this.allCustomerfilter = false;
              this.penCustomerfilter = false;
              this.inpCustomerfilter = false;
              this.comCustomerfilter = false;
              this.canCustomerfilter = true;
              this.defaultCustomer = false;
              this.ordertransactionservice.getAllCancellation(fromdate,todate).subscribe(data =>{
                if(data.response.length>0){
                  this.excelexportflag = true;
                }else{
                  this.excelexportflag = false;
                }
                this.cancustomerOrderfilter=data;
              });
            }
    }
    }else if(searchBy=='3'){
      this.allCustomer = false;
      this.penCustomer = false;
      this.inpCustomer = false;
      this.comCustomer =false;
      this.canCustomer = false;
      this.allCustomerfilter = false;
      this.penCustomerfilter = false;
      this.inpCustomerfilter = false;
      this.comCustomerfilter = false;
      this.canCustomerfilter = false;
      this.defaultCustomer = false;

      if(onboarded=='1'){
        this.onboardedfilter1 = true;
        this.onboardedfilter2 =  false;
        this.ordertransactionservice.getOrdersByOnboardedBy(Number(onboarded)).subscribe(data=>{
          this.onboardedcustomerOrderfilter1 = data;
          if(data.response.length>0){
            this.excelexportflag = true;
          }else{
            this.excelexportflag = false;
          }
        });
      }else if(onboarded=='2'){
        this.onboardedfilter1 = false;
        this.onboardedfilter2 =  true;
        this.ordertransactionservice.getOrdersByOnboardedBy(Number(onboarded)).subscribe(data=>{
          this.onboardedcustomerOrderfilter2 = data;
          if(data.response.length>0){
            this.excelexportflag = true;
          }else{
            this.excelexportflag = false;
          }
        });
      }
    }
  }

  refresh(){
    this.refreshFlag = false;
    window.location.reload();
  }

  invoice(id,filename,content){

    this.ordertransactionservice.download(id,1).subscribe(data => {
      
      var file = new Blob([data], {type: 'application/pdf'});
       var fileURL = URL.createObjectURL(file);
       window.open(fileURL);

  }, error => {

  });
 
  }

  download(id,filename){
    this.ordertransactionservice.download(id,1).subscribe(result => {
      let dataType = 'application/pdf';
      let binaryData = [];
      binaryData.push(result);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
      downloadLink.setAttribute('download', filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();

  });
  
  }
  
  sendMail(id){
    this.ordertransactionservice.sendMail(id,1).subscribe(data => {
      
      if(data.statusCode==200){
        Swal.fire({
          html: '<pre>' + data.responseMessage + '</pre>',
          customClass: {
            popup: 'format-pre'
          }
        }).then((value) => {
          window.location.reload();
        });; ;
      }
    },err => {
      if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });
 
  }
  excelexport(){
    let sno = 1;
    this.responseDataListnew = [];
    if(this.penCustomer){
      this.responseDataListnew = [];
      this.pencustomerOrder.response.forEach(element => {
        let vehicle = element['vehicleId'];
        let customer = element['customerId'];
        let date = element['orderDatetime'];
        let convertedDate;
        if(date==null || date==='null'){
          convertedDate = "N/A";
        }else{
          let dates = new Date(element['orderDatetime']);
          convertedDate = moment(new Date(dates)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }
  
        let ordelidat = element['orderDeliveredDateTime'];
        let orderdeliveryDate;
        if(ordelidat==null || ordelidat==='null'){
          orderdeliveryDate = "N/A";
        }else{
          let ordelidate = new Date(element['orderDeliveredDateTime']);
          orderdeliveryDate = moment(new Date(ordelidate)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }
        var response = [];
        response.push(sno);
        response.push(element['orderId']);
        response.push(customer['firstName'] +' '+ customer['lastName']);
        response.push(customer['mobileNumber']);
        response.push(vehicle['vehicleNumber']);
        response.push(element['fuelType']);
        response.push(element['quantity']);
        response.push(convertedDate);
        response.push(orderdeliveryDate);
        response.push(element['status']);
        this.responseDataListnew.push(response);
        sno++;
      });
    }else if(this.inpCustomer){
      this.responseDataListnew = [];
      this.inpcustomerOrder.response.forEach(element => {
        let vehicle = element['vehicleId'];
        let customer = element['customerId'];
        let date = element['orderDatetime'];
        let convertedDate;
        if(date==null || date==='null'){
          convertedDate = "N/A";
        }else{
          let dates = new Date(element['orderDatetime']);
          convertedDate = moment(new Date(dates)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }
  
        let ordelidat = element['orderDeliveredDateTime'];
        let orderdeliveryDate;
        if(ordelidat==null || ordelidat==='null'){
          orderdeliveryDate = "N/A";
        }else{
          let ordelidate = new Date(element['orderDeliveredDateTime']);
          orderdeliveryDate = moment(new Date(ordelidate)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }
        var response = [];
        response.push(sno);
        response.push(element['orderId']);
        response.push(customer['firstName'] +' '+ customer['lastName']);
        response.push(customer['mobileNumber']);
        response.push(vehicle['vehicleNumber']);
        response.push(element['fuelType']);
        response.push(element['quantity']);
        response.push(convertedDate);
        response.push(orderdeliveryDate);
        response.push(element['status']);
        this.responseDataListnew.push(response);
        sno++;
      });
    }else if(this.comCustomer){
      this.responseDataListnew = [];
      this.comcustomerOrder.response.forEach(element => {
        let vehicle = element['vehicleId'];
        let customer = element['customerId'];
        let date = element['orderDatetime'];
        let convertedDate;
        if(date==null || date==='null'){
          convertedDate = "N/A";
        }else{
          let dates = new Date(element['orderDatetime']);
          convertedDate = moment(new Date(dates)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }
  
        let ordelidat = element['orderDeliveredDateTime'];
        let orderdeliveryDate;
        if(ordelidat==null || ordelidat==='null'){
          orderdeliveryDate = "N/A";
        }else{
          let ordelidate = new Date(element['orderDeliveredDateTime']);
          orderdeliveryDate = moment(new Date(ordelidate)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }
        var response = [];
        response.push(sno);
        response.push(element['orderId']);
        response.push(customer['firstName'] +' '+ customer['lastName']);
        response.push(customer['mobileNumber']);
        response.push(vehicle['vehicleNumber']);
        response.push(element['fuelType']);
        response.push(element['quantity']);
        response.push(convertedDate);
        response.push(orderdeliveryDate);
        response.push(element['status']);
        this.responseDataListnew.push(response);
        sno++;
      });
    }else if(this.canCustomer){
      this.responseDataListnew = [];
      this.cancustomerOrder.response.forEach(element => {
        let vehicle = element['vehicleId'];
        let customer = element['customerId'];
        let date = element['orderDatetime'];
        let convertedDate;
        if(date==null || date==='null'){
          convertedDate = "N/A";
        }else{
          let dates = new Date(element['orderDatetime']);
          convertedDate = moment(new Date(dates)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }
  
        let ordelidat = element['orderDeliveredDateTime'];
        let orderdeliveryDate;
        if(ordelidat==null || ordelidat==='null'){
          orderdeliveryDate = "N/A";
        }else{
          let ordelidate = new Date(element['orderDeliveredDateTime']);
          orderdeliveryDate = moment(new Date(ordelidate)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }
        var response = [];
        response.push(sno);
        response.push(element['orderId']);
        response.push(customer['firstName'] +' '+ customer['lastName']);
        response.push(customer['mobileNumber']);
        response.push(vehicle['vehicleNumber']);
        response.push(element['fuelType']);
        response.push(element['quantity']);
        response.push(convertedDate);
        response.push(orderdeliveryDate);
        response.push(element['status']);
        this.responseDataListnew.push(response);
        sno++;
      });
    }else if(this.allCustomer){
      this.responseDataListnew = [];
      this.allcustomerOrder.response.forEach(element => {
        let vehicle = element['vehicleId'];
        let customer = element['customerId'];
        let date = element['orderDatetime'];
        let convertedDate;
        if(date==null || date==='null'){
          convertedDate = "N/A";
        }else{
          let dates = new Date(element['orderDatetime']);
          convertedDate = moment(new Date(dates)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }
  
        let ordelidat = element['orderDeliveredDateTime'];
        let orderdeliveryDate;
        if(ordelidat==null || ordelidat==='null'){
          orderdeliveryDate = "N/A";
        }else{
          let ordelidate = new Date(element['orderDeliveredDateTime']);
          orderdeliveryDate = moment(new Date(ordelidate)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }
        var response = [];
        response.push(sno);
        response.push(element['orderId']);
        response.push(customer['firstName'] +' '+ customer['lastName']);
        response.push(customer['mobileNumber']);
        response.push(vehicle['vehicleNumber']);
        response.push(element['fuelType']);
        response.push(element['quantity']);
        response.push(convertedDate);
        response.push(orderdeliveryDate);
        response.push(element['status']);
        this.responseDataListnew.push(response);
        sno++;
      });
    }else if(this.penCustomerfilter){
      this.responseDataListnew = [];
      this.pencustomerOrderfilter.response.forEach(element => {
        let vehicle = element['vehicleId'];
        let customer = element['customerId'];
        let date = element['orderDatetime'];
        let convertedDate;
        if(date==null || date==='null'){
          convertedDate = "N/A";
        }else{
          let dates = new Date(element['orderDatetime']);
          convertedDate = moment(new Date(dates)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }
  
        let ordelidat = element['orderDeliveredDateTime'];
        let orderdeliveryDate;
        if(ordelidat==null || ordelidat==='null'){
          orderdeliveryDate = "N/A";
        }else{
          let ordelidate = new Date(element['orderDeliveredDateTime']);
          orderdeliveryDate = moment(new Date(ordelidate)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }
        var response = [];
        response.push(sno);
        response.push(element['orderId']);
        response.push(customer['firstName'] +' '+ customer['lastName']);
        response.push(customer['mobileNumber']);
        response.push(vehicle['vehicleNumber']);
        response.push(element['fuelType']);
        response.push(element['quantity']);
        response.push(convertedDate);
        response.push(orderdeliveryDate);
        response.push(element['status']);
        this.responseDataListnew.push(response);
        sno++;
      });
    }else if(this.inpCustomerfilter){
      this.responseDataListnew = [];
      this.inpcustomerOrderfilter.response.forEach(element => {
        let vehicle = element['vehicleId'];
        let customer = element['customerId'];
        let date = element['orderDatetime'];
        let convertedDate;
        if(date==null || date==='null'){
          convertedDate = "N/A";
        }else{
          let dates = new Date(element['orderDatetime']);
          convertedDate = moment(new Date(dates)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }
  
        let ordelidat = element['orderDeliveredDateTime'];
        let orderdeliveryDate;
        if(ordelidat==null || ordelidat==='null'){
          orderdeliveryDate = "N/A";
        }else{
          let ordelidate = new Date(element['orderDeliveredDateTime']);
          orderdeliveryDate = moment(new Date(ordelidate)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }
        var response = [];
        response.push(sno);
        response.push(element['orderId']);
        response.push(customer['firstName'] +' '+ customer['lastName']);
        response.push(customer['mobileNumber']);
        response.push(vehicle['vehicleNumber']);
        response.push(element['fuelType']);
        response.push(element['quantity']);
        response.push(convertedDate);
        response.push(orderdeliveryDate);
        response.push(element['status']);
        this.responseDataListnew.push(response);
        sno++;
      });
    }else if(this.comCustomerfilter){
      this.responseDataListnew = [];
      this.comcustomerOrderfilter.response.forEach(element => {
        let vehicle = element['vehicleId'];
        let customer = element['customerId'];
        let date = element['orderDatetime'];
        let convertedDate;
        if(date==null || date==='null'){
          convertedDate = "N/A";
        }else{
          let dates = new Date(element['orderDatetime']);
          convertedDate = moment(new Date(dates)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }
  
        let ordelidat = element['orderDeliveredDateTime'];
        let orderdeliveryDate;
        if(ordelidat==null || ordelidat==='null'){
          orderdeliveryDate = "N/A";
        }else{
          let ordelidate = new Date(element['orderDeliveredDateTime']);
          orderdeliveryDate = moment(new Date(ordelidate)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }
        var response = [];
        response.push(sno);
        response.push(element['orderId']);
        response.push(customer['firstName'] +' '+ customer['lastName']);
        response.push(customer['mobileNumber']);
        response.push(vehicle['vehicleNumber']);
        response.push(element['fuelType']);
        response.push(element['quantity']);
        response.push(convertedDate);
        response.push(orderdeliveryDate);
        response.push(element['status']);
        this.responseDataListnew.push(response);
        sno++;
      });
    }else if(this.canCustomerfilter){
      this.responseDataListnew = [];
      this.cancustomerOrderfilter.response.forEach(element => {
        let vehicle = element['vehicleId'];
        let customer = element['customerId'];
        let date = element['orderDatetime'];
        let convertedDate;
        if(date==null || date==='null'){
          convertedDate = "N/A";
        }else{
          let dates = new Date(element['orderDatetime']);
          convertedDate = moment(new Date(dates)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }
  
        let ordelidat = element['orderDeliveredDateTime'];
        let orderdeliveryDate;
        if(ordelidat==null || ordelidat==='null'){
          orderdeliveryDate = "N/A";
        }else{
          let ordelidate = new Date(element['orderDeliveredDateTime']);
          orderdeliveryDate = moment(new Date(ordelidate)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }
        var response = [];
        response.push(sno);
        response.push(element['orderId']);
        response.push(customer['firstName'] +' '+ customer['lastName']);
        response.push(customer['mobileNumber']);
        response.push(vehicle['vehicleNumber']);
        response.push(element['fuelType']);
        response.push(element['quantity']);
        response.push(convertedDate);
        response.push(orderdeliveryDate);
        response.push(element['status']);
        this.responseDataListnew.push(response);
        sno++;
      });
    }else if(this.allCustomerfilter){
      this.responseDataListnew = [];
      this.allcustomerOrderfilter.response.forEach(element => {
        let vehicle = element['vehicleId'];
        let customer = element['customerId'];
        let date = element['orderDatetime'];
        let convertedDate;
        if(date==null || date==='null'){
          convertedDate = "N/A";
        }else{
          let dates = new Date(element['orderDatetime']);
          convertedDate = moment(new Date(dates)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }
  
        let ordelidat = element['orderDeliveredDateTime'];
        let orderdeliveryDate;
        if(ordelidat==null || ordelidat==='null'){
          orderdeliveryDate = "N/A";
        }else{
          let ordelidate = new Date(element['orderDeliveredDateTime']);
          orderdeliveryDate = moment(new Date(ordelidate)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }
        var response = [];
        response.push(sno);
        response.push(element['orderId']);
        response.push(customer['firstName'] +' '+ customer['lastName']);
        response.push(customer['mobileNumber']);
        response.push(vehicle['vehicleNumber']);
        response.push(element['fuelType']);
        response.push(element['quantity']);
        response.push(convertedDate);
        response.push(orderdeliveryDate);
        response.push(element['status']);
        this.responseDataListnew.push(response);
        sno++;
      });
    }else if(this.onboardedfilter1){
      this.responseDataListnew = [];
      this.onboardedcustomerOrderfilter1.response.forEach(element => {
        let vehicle = element['vehicleId'];
        let customer = element['customerId'];
        let date = element['orderDatetime'];
        let convertedDate;
        if(date==null || date==='null'){
          convertedDate = "N/A";
        }else{
          let dates = new Date(element['orderDatetime']);
          convertedDate = moment(new Date(dates)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }
  
        let ordelidat = element['orderDeliveredDateTime'];
        let orderdeliveryDate;
        if(ordelidat==null || ordelidat==='null'){
          orderdeliveryDate = "N/A";
        }else{
          let ordelidate = new Date(element['orderDeliveredDateTime']);
          orderdeliveryDate = moment(new Date(ordelidate)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }
        var response = [];
        response.push(sno);
        response.push(element['orderId']);
        response.push(customer['firstName'] +' '+ customer['lastName']);
        response.push(customer['mobileNumber']);
        response.push(vehicle['vehicleNumber']);
        response.push(element['fuelType']);
        response.push(element['quantity']);
        response.push(convertedDate);
        response.push(orderdeliveryDate);
        response.push(element['status']);
        this.responseDataListnew.push(response);
        sno++;
      });
    }else if(this.onboardedfilter2){
      this.responseDataListnew = [];
      this.onboardedcustomerOrderfilter2.response.forEach(element => {
        let vehicle = element['vehicleId'];
        let customer = element['customerId'];
        let date = element['orderDatetime'];
        let convertedDate;
        if(date==null || date==='null'){
          convertedDate = "N/A";
        }else{
          let dates = new Date(element['orderDatetime']);
          convertedDate = moment(new Date(dates)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }
  
        let ordelidat = element['orderDeliveredDateTime'];
        let orderdeliveryDate;
        if(ordelidat==null || ordelidat==='null'){
          orderdeliveryDate = "N/A";
        }else{
          let ordelidate = new Date(element['orderDeliveredDateTime']);
          orderdeliveryDate = moment(new Date(ordelidate)).format("DD-MM-yyyy hh:mm:ss a").toString();
        }
        var response = [];
        response.push(sno);
        response.push(element['orderId']);
        response.push(customer['firstName'] +' '+ customer['lastName']);
        response.push(customer['mobileNumber']);
        response.push(vehicle['vehicleNumber']);
        response.push(element['fuelType']);
        response.push(element['quantity']);
        response.push(convertedDate);
        response.push(orderdeliveryDate);
        response.push(element['status']);
        this.responseDataListnew.push(response);
        sno++;
      });
    }
    this.excelexportOrders();
}

  excelexportOrders(){
    const title = 'Platform Invoice Report';
    const header = ["S.No","Order ID", "Customer Name", "Customer Mobile","Vehicle Number" , "Fuel","Quantity in Ltrs","Booked Date Time","Delivered Date Time","Status"]
    const data = this.responseDataListnew;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Platform Invoice Report');
  // Add new row
  let titleRow = worksheet.addRow([title]);
  // Set font, size and style in title row.
  titleRow.font = { name: 'Times New Roman', family: 4, size: 16, bold: true };
  
  // Blank Row
  worksheet.addRow([]);
  
  //Add row with current date
  // let subTitleRow = worksheet.addRow(['Date : ' + this.datePipe.transform(new Date(), 'medium')]);
  //Add Header Row
  let headerRow = worksheet.addRow(header);
  headerRow.font = {bold: true };
  // Cell Style : Fill and Border
  headerRow.eachCell((cell, number) => {
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFFFF' },
    bgColor: { argb: 'FF0000FF' },
  }
  cell.border = {  top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  });
  
  data.forEach(d => {
  let row = worksheet.addRow(d);
  let qty = row.getCell(1);
  let qty1 = row.getCell(2);
  let qty2 = row.getCell(3);
  let qty3 = row.getCell(4);
  let qty4 = row.getCell(5);
  let qty5 = row.getCell(6);
  let qty6 = row.getCell(7);
  let qty7 = row.getCell(8);
  let qty8 = row.getCell(9);
  let qty9 = row.getCell(10);
  qty.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty1.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty2.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty3.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty4.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty5.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty6.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty8.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty9.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  }
  );
  workbook.xlsx.writeBuffer().then((data) => {
  let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  FileSaver.saveAs(blob, 'Platform Invoice Report.xlsx');
  });
   }
}
