<app-header></app-header>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
</ngx-spinner>
<div class="container  container-top-dashboard-section" style="margin-top: 110px">
  <a href="#" routerLink="/customers" class="back_page"><span class="label label-info"><i class="back_page"></i>Back</span></a>
  
   <br>
   <div class="alert alert-success box-msg" role="alert"
            *ngIf="successflag">{{message}}</div>
   <div class="card mb-3" style="margin-top: 20px; " #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
   <div class="flex">
      <div class="col1 flex">
         <div class=" profile images flex">
            <img *ngIf="!isImageLoading" src="assets/img/profile.jpg" data-src="assets/img/profile.jpg" data-src-retina="assets/img/profile.jpg" width="150px" >
            <img *ngIf="isImageLoading" class="modal-content"  [src]="imageToShow">
         </div>
         <div class="profile-info flex">
            <div class="card-body profile">
              <p class="card-title" style="word-wrap: break-word;">Customer Name <br><span><b>{{userModel.firstName}} {{userModel.lastName}}</b></span></p>
              <p class="card-title">Rating<br>
                <span *ngIf="ratingStar===0" class="v-align-middle ht-ratting"><span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></span>
                <span *ngIf="ratingStar===1" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></span>
                <span *ngIf="ratingStar===1.5" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-half-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></span>
                <span *ngIf="ratingStar===2" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></span>
                <span *ngIf="ratingStar===2.5" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-half-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></span>
                <span *ngIf="ratingStar===3" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></span>
                <span *ngIf="ratingStar===3.5" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-half-o" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></span>
                <span *ngIf="ratingStar===4" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-o" aria-hidden="true"></i> </span></span>
                <span *ngIf="ratingStar===4.5" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star-half-o" aria-hidden="true"></i> </span></span>
                <span *ngIf="ratingStar===5" class="v-align-middle ht-ratting"><span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span> <span><i class="fa fa-star" aria-hidden="true"></i> </span></span>
              </p>
              <p class="card-title">Customer Type  <br>
                <span *ngIf="userModel.customerTypeId!=null"><b>{{userModel.customerTypeId.typesOfCustomers}}</b></span>
                <span *ngIf="userModel.customerTypeId==null"><b>N/A</b></span>
              </p>
              <p class="card-title">Customer ID  <br>
                <span *ngIf="userModel.userCode!=null"><b>{{userModel.userCode}}</b></span>
                <span *ngIf="userModel.userCode==null"><b>N/A</b></span>
              </p>
            </div>
             <div class="card-body profile">
               <p class="card-title"><i class="fa fa-mobile" aria-hidden="true"></i> Mobile
                  <br><span><b>{{userModel.mobileNumber}}</b></span>
               </p>
               <p class="card-title"><i class="fa fa-envelope" aria-hidden="true"></i> Email
                  <br><span ><b>{{userModel.emailAddress}}</b></span>
               </p>
<p class="card-title"><i class="fa fa-location-arrow" aria-hidden="true"></i>
Address for Communication
                  <br>
                  <span *ngIf="userModel.address!=null" style="word-wrap: break-word;"><b>{{userModel.address}}</b></span>
                  <span *ngIf="userModel.address==null"><b>N/A</b></span>
               </p>
            </div>

            <div class="card-body profile">
               <p class="card-title">Onboarded Date & Time  <br><span><b>{{userModel.createdDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</b></span></p>
               <p class="card-title">OTP Verification <br>
                <span *ngIf="userModel.otpVerificationStatus===1" class="v-align-middle ht-Success"><b>Success</b></span>
                <span *ngIf="userModel.otpVerificationStatus===0" class="v-align-middle ht-Pending"><b>Pending</b></span>
              </p>
              <p class="card-title">Fire Base Enable   <br>
                <span *ngIf="userModel.firebaseId!=null"><i class="fa fa-check" aria-hidden="true"></i></span>
                <span *ngIf="userModel.firebaseId==null"><i class="fa fa-times" aria-hidden="true"></i></span>
              </p>
            </div>
            <div class="card-body profile">
               <p>Account Status</p>
               <div *ngIf="userModel.accountStatus===1">
                <div class="form-check no-pad-left">
                  <input class="form-check-input" type="radio" name="active" id="active" (click)="callActiveOrInactive(1)" value="option1" checked>
                  <label class="form-check-label" for="active">
                  Active
                  </label>
               </div>
               <div class="form-check no-pad-left">
                  <input class="form-check-input" type="radio" name="inactive" id="inactive" (click)="callActiveOrInactive(2)" value="option2">
                  <label class="form-check-label" for="inactive">
                  Inactive
                  </label>
               </div>
               </div>
               <div *ngIf="userModel.accountStatus===2">
                <div class="form-check no-pad-left">
                  <input class="form-check-input" type="radio" name="active" id="active" (click)="callActiveOrInactive(1)" value="option1">
                  <label class="form-check-label" for="active">
                  Active
                  </label>
               </div>
               <div class="form-check no-pad-left">
                  <input class="form-check-input" type="radio" name="inactive" id="inactive" (click)="callActiveOrInactive(2)" value="option2" checked>
                  <label class="form-check-label" for="inactive">
                  Inactive
                  </label>
               </div>
               </div>
               <br>
               <p class="card-title">IP Address <br>
                <span *ngIf="userModel.useripaddress!=null"><b>{{userModel.useripaddress}}</b></span>
                <span *ngIf="userModel.useripaddress==null"><b>N/A</b></span>
              </p>
            </div>
            <div class="card-body profile">
               <p class="card-title">Current IP Address <br>
                <span *ngIf="userModel.currentIpaddress!=null"><b>{{userModel.currentIpaddress}}</b></span>
                <span *ngIf="userModel.currentIpaddress==null"><b>N/A</b></span>
              </p>
              <p class="card-title">Failed Status<br>
                <span><b>{{failedStatus}}</b></span>
              </p>
              <p class="card-title">Failed Count<br>
                <span>{{failedCount}}</span>
                <span style="margin-left: 10px;" *ngIf="failedCount==6 || failedCount>=6">
                  <button title="Unblock" class="label label-info" (click)="open10(reject,userModel.userId)" ><i class="fa fa-unlock" aria-hidden="true"></i></button>
                </span>
              </p>
            </div>
            <div class="card-body profile" >

              <button title="Assign Partner" *ngIf="assignedPartnerModel.response.flag==2"  style="margin-left: 5px;" (click)="openPartner(content10,userModel.userId)" class="label label-success" >Assign Partner</button>
              <button title="View Partner" *ngIf="assignedPartnerModel.response.flag==1" style="margin-left: 5px;" (click)="viewPartner(content11,assignedPartnerModel.response,userModel.userId)" class="label label-success" >View Partner</button>
              <button title="Assign Payment Mode" style="margin-left: 10px;" *ngIf="assignedPaymentMode.response==null" (click)="openPaymentMode(content13,userModel.userId)" class="label label-success" >Assign Payment Mode</button>
              <button title="Assign Payment Mode" style="margin-left: 10px;" *ngIf="assignedPaymentMode.response!=null" (click)="viewPaymentMode(content14,assignedPaymentMode,userModel.userId)" class="label label-success" >View Payment Mode</button>


           </div> 
         

      
         </div>
      </div>
   </div>
</div>
<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
  <li [ngbNavItem]="1">
    <div style="display: none;">{{customerOrder.responseMessage}}</div>
    <a ngbNavLink>Orders</a>
    <ng-template ngbNavContent>
      <br>
      <select class="select-form-style" style="margin-left: 20px;" id="searchBy" (change)="callsearch($event)">  
        <option value="1">Search By Status</option>  
        <option value="2">Search By Date Range</option>  
    </select>  
    <select class="select-form-style" style="margin-left: 10px;" id="status">
      <option value="1" selected>All</option>  
        <option value="2">Pending</option>  
        <option value="3">Inprogress</option>  
        <option value="4">Delivered</option>  
        <option value="5">Cancelled</option>  
  </select>  
  <input type="date" style="display: none; margin-left: 10px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
    <input type="date" style="display: none; margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
     
  <button style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
  <button *ngIf="refreshFlag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
  <div *ngIf="errorFlag" style="color: red; margin-left: 20px;">{{errorMessage}}</div>
      
        
        <!-- <button style="margin-left: 300px;"  (click)="pending()" class="btn-transaction0">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios" id="penradio" value="option2" checked>
            <label class="form-check-label" for="penradio">
              Pending
            </label>
          </div>
          </button>   -->
          <!-- <button (click)="inprogress()" class="btn-transaction1">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="exampleRadios" id="inpradio" value="option3">
              <label class="form-check-label" for="inpradio">
                Inprogress
              </label>
            </div>
            </button>   -->
            <!-- <button (click)="completed()" class="btn-transaction2">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="exampleRadios" id="comradio" value="option4">
                <label class="form-check-label" for="comradio">
                  Delivered
                </label>
              </div>
              </button>    -->
      <!-- <button (click)="cancelled()" class="btn-transaction3">
        <div class="form-check">
          <input class="form-check-input" type="radio" name="exampleRadios" id="canradio" value="option5">
          <label class="form-check-label" for="canradio">
            Cancelled
          </label>
        </div>
        </button> -->
        <!-- <button (click)="all()" class="btn-transaction">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios" id="allradio" value="option1">
            <label class="form-check-label" for="allradio">
              All
            </label>
          </div>
          </button> -->
          
                
                     
      <!-- <button (click)="cancelled()" class="pen-style3">Cancelled</button>
      <button (click)="completed()" class="pen-style2">Completed</button>
      <button (click)="inprogress()" class="pen-style1">Inprogress</button>
      <button (click)="pending()" class="pen-style">Pending</button>
      <button (click)="all()" class="pen-style3">All</button> -->
      <!-- </div> -->
      
      <br>
      <div *ngIf="defaultCustomer" class="card-body">
        <div style="display: none;">{{customerOrder.responseMessage}}</div>
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th align="left" >Order ID  </th>
                      <th align="left" >Delivery Agent Name</th>
                      <th align="left" >Vehicle number</th>
                      <th align="center" >Fuel</th>
                      <th style="text-align: right;">Quantity in Ltrs</th>
                      <th align="left" >Booked Date Time </th>
                      <th align="left" >DeliveryDate Time</th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: center;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let order of customerOrder.response; let i=index">
                        <td align="center" class="v-align-middle ">{{i + 1}}</td>
                        <td align="left" class="v-align-middle">{{order.orderId}}</td>
                        <td *ngIf="order.orderAcceptedBy!=null" align="left"style="word-wrap: break-word;"  class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
                      <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.vehicleId!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
                        <td *ngIf="order.vehicleId==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">{{order.fuelType}}</td>
                        <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                        <td *ngIf="order.quantity!=null" align="right" class="v-align-middle">{{order.quantity}}</td>
                        <td *ngIf="order.quantity==null" align="right" class="v-align-middle">N/A</td>
                        <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                        <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                        <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                        <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                        <td align="left" class="v-align-middle">
                          <button title="View" class="label label-info" (click)="open(content,order)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                          <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                          <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.status==='Pending' || order.status==='Inprogress'" class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                          <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="invoice(order.id)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
                          <button title="Download Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="download(order.id,order.pdfFilename)" class="label label-success" ><i class="fa fa-download" aria-hidden="true"></i></button>

                        </td>
                      </tr>
                  </tbody>
                </table>
          </div>
        </div>

        <!-- -----All ----- -->
        <div *ngIf="allCustomer" class="card-body">
          <div style="display: none;">{{allcustomerOrder.responseMessage}}</div>
          <div class="table-responsive">
            <table datatable class="ch-table">
              <thead>
                <tr> 
                  <th style="text-align: center;">Sl.No.</th>
                  <th align="left" >Order ID </th>
                  <th align="left" >Delivery Agent Name</th>
                  <th align="left" >Vehicle number</th>
                  <th align="center" >Fuel</th>
                  <th style="text-align: right;">Quantity in Ltrs</th>
                  <th align="left" >Booked Date Time </th>
                  <th align="left" >DeliveryDate Time</th>
                  <th style="text-align: center;">Status</th>
                  <th style="text-align: center;">Action</th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let order of allcustomerOrder.response; let i=index">
                    <td align="center" class="v-align-middle ">{{i + 1}}</td>
                    <td align="left" class="v-align-middle">{{order.orderId}}</td>
                    <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
                      <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td>
                    <td *ngIf="order.vehicleId==null" class="v-align-middle">
                      N/A
                    </td>
                    <td *ngIf="order.vehicleId!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
                    <td *ngIf="order.vehicleId==null" align="left" class="v-align-middle">N/A</td>
                    <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">{{order.fuelType}}</td>
                    <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                    <td *ngIf="order.quantity!=null" align="right" class="v-align-middle">{{order.quantity}}</td>
                    <td *ngIf="order.quantity==null" align="right" class="v-align-middle">N/A</td>
                    <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                    <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                    <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                    <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                    <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                    <td align="left" class="v-align-middle">
                      <button title="View" class="label label-info" (click)="open(content,order)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                      <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                      <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.status==='Pending' || order.status==='Inprogress'" class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                      <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="invoice(order.id)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
                      <button title="Download Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="download(order.id,order.pdfFilename)" class="label label-success" ><i class="fa fa-download" aria-hidden="true"></i></button>
                      <button title="Send Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="sendMail(order.id)" class="label label-success" ><i class="fa fa-envelope" aria-hidden="true"></i></button>

                    </td>
                  </tr>
              </tbody>
            </table>
      </div>
    </div>

        <!-- -----Pending ----- -->
        <div *ngIf="penCustomer" class="card-body">
          <div style="display: none;">{{pencustomerOrder.responseMessage}}</div>
          <div class="table-responsive">
            <table datatable class="ch-table">
              <thead>
                <tr> 
                  <th style="text-align: center;">Sl.No.</th>
                  <th align="left" >Order ID </th>
                  <th align="left" >Delivery Agent Name</th>
                  <th align="left" >Vehicle number</th>
                  <th align="center" >Fuel</th>
                  <th style="text-align: right;">Quantity in Ltrs</th>
                  <th align="left" >Booked Date Time </th>
                  <th align="left" >DeliveryDate Time</th>
                  <th style="text-align: center;">Status</th>
                  <th style="text-align: center;">Action</th>
                </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let order of pencustomerOrder.response; let i=index">
                    <td align="center" class="v-align-middle ">{{i + 1}}</td>
                    <td align="left" class="v-align-middle">{{order.orderId}}</td>
                    <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
                    <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td>
                    <td *ngIf="order.vehicleId!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
                    <td *ngIf="order.vehicleId==null" align="left" class="v-align-middle">N/A</td>
                    <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">{{order.fuelType}}</td>
                    <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                    <td *ngIf="order.quantity!=null" align="right" class="v-align-middle">{{order.quantity}}</td>
                    <td *ngIf="order.quantity==null" align="right" class="v-align-middle">N/A</td>
                    <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                    <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                    <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                    <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                    <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                    <td align="left" class="v-align-middle">
                      <button title="View" class="label label-info" (click)="open(content,order)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                      <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                      <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.status==='Pending' || order.status==='Inprogress'" class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                      <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="invoice(order.id)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
                    </td>
                  </tr>
              </tbody>
            </table>
      </div>
    </div>

    <!-- ------ inprogress------------ -->

    <div *ngIf="inpCustomer" class="card-body">
      <div style="display: none;">{{inpcustomerOrder.responseMessage}}</div>
      <div class="table-responsive">
        <table datatable class="ch-table">
          <thead>
            <tr> 
              <th style="text-align: center;">Sl.No.</th>
              <th align="left" >Order ID </th>
              <th align="left" >Delivery Agent Name</th>
              <th align="left" >Vehicle number</th>
              <th align="center" >Fuel</th>
              <th style="text-align: right;">Quantity in Ltrs</th>
              <th align="left" >Booked Date Time </th>
              <th align="left" >DeliveryDate Time</th>
              <th style="text-align: center;">Status</th>
              <th style="text-align: center;">Action</th>
            </tr>
          </thead>
          <tbody>
              <tr *ngFor="let order of inpcustomerOrder.response; let i=index">
                <td align="center" class="v-align-middle ">{{i + 1}}</td>
                <td align="left" class="v-align-middle">{{order.orderId}}  <b>manju :</b> {{order.status}}</td>
                <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
                      <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td>
                <td *ngIf="order.vehicleId!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
                <td *ngIf="order.vehicleId==null" align="left" class="v-align-middle">N/A</td>
                <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">{{order.fuelType}}</td>
                <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                <td *ngIf="order.quantity!=null" align="right" class="v-align-middle">{{order.quantity}}</td>
                <td *ngIf="order.quantity==null" align="right" class="v-align-middle">N/A</td>
                <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">   {{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                <td  *ngIf="order.status===''"  align="center" class="v-align-middle ht-active">N/A</td>
                <td align="left" class="v-align-middle">
                  <button title="View" class="label label-info" (click)="open(content,order)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                  <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                  <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.status==='Pending' || order.status==='Inprogress'" class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                  <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="invoice(order.id)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
                </td>
              </tr>
          </tbody>
        </table>
  </div>
</div>

     <!-- ----completed----- -->
     <div *ngIf="comCustomer" class="card-body">
      <div style="display: none;">{{comcustomerOrder.responseMessage}}</div>
      <div class="table-responsive">
        <table datatable class="ch-table">
          <thead>
            <tr> 
              <th style="text-align: center;">Sl.No.</th>
              <th align="left" >Order ID </th>
              <th align="left" >Delivery Agent Name</th>
              <th align="left" >Vehicle number</th>
              <th align="center" >Fuel</th>
              <th style="text-align: right;">Quantity in Ltrs</th>
              <th align="left" >Booked Date Time </th>
              <th align="left" >DeliveryDate Time</th>
              <th style="text-align: center;">Status</th>
              <th style="text-align: center;">Action</th>
            </tr>
          </thead>
          <tbody>
              <tr *ngFor="let order of comcustomerOrder.response; let i=index">
                <td align="center" class="v-align-middle ">{{i + 1}}</td>
                <td align="left" class="v-align-middle">{{order.orderId}}</td>
                <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
                <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td>
                <td *ngIf="order.vehicleId!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
                <td *ngIf="order.vehicleId==null" align="left" class="v-align-middle">N/A</td>
                <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">{{order.fuelType}}</td>
                <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                <td *ngIf="order.quantity!=null" align="right" class="v-align-middle">{{order.quantity}}</td>
                <td *ngIf="order.quantity==null" align="right" class="v-align-middle">N/A</td>
                <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                <td align="left" class="v-align-middle">
                  <button title="View" class="label label-info" (click)="open(content,order)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                  <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                  <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.status==='Pending' || order.status==='Inprogress'" class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                  <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="invoice(order.id)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
                </td>
              </tr>
          </tbody>
        </table>
  </div>
</div>

<!-- -----Cancelled----- -->
<div *ngIf="canCustomer" class="card-body">
  <div style="display: none;">{{cancustomerOrder.responseMessage}}</div>
  <div class="table-responsive">
    <table datatable class="ch-table">
      <thead>
        <tr> 
          <th style="text-align: center;">Sl.No.</th>
          <th align="left" >Order ID </th>
          <th align="left" >Delivery Agent Name</th>
          <th align="left" >Vehicle number</th>
          <th align="center" >Fuel</th>
          <th style="text-align: right;" >Quantity in Ltrs</th>
          <th align="left" >Booked Date Time </th>
          <th align="left" >DeliveryDate Time</th>
          <th style="text-align: center;">Status</th>
          <th style="text-align: center;">Action</th>
        </tr>
      </thead>
      <tbody>
          <tr *ngFor="let order of cancustomerOrder.response; let i=index">
            <td align="center" class="v-align-middle ">{{i + 1}}</td>
            <td align="left" class="v-align-middle">{{order.orderId}}</td>
            <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
            <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td>
            <td *ngIf="order.vehicleId!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
            <td *ngIf="order.vehicleId==null" align="left" class="v-align-middle">N/A</td>
            <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">{{order.fuelType}}</td>
            <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
            <td *ngIf="order.quantity!=null" align="right" class="v-align-middle">{{order.quantity}}</td>
            <td *ngIf="order.quantity==null" align="right" class="v-align-middle">N/A</td>
            <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
            <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
            <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
            <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
            <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
            <td align="left" class="v-align-middle">
              <button title="View" class="label label-info" (click)="open(content,order)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
              <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
              <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.status==='Pending' || order.status==='Inprogress'" class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
              <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="invoice(order.id)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
            </td>
          </tr>
      </tbody>
    </table>
</div>
</div>

<div *ngIf="filterflag && !filteronesearch" class="card-body">
  <div style="display: none;">{{filtercustomerOrder.responseMessage}}</div>
  <div class="table-responsive">
    <table datatable class="ch-table">
      <thead>
        <tr> 
          <th style="text-align: center;">Sl.No.</th>
          <th align="left" >Order ID </th>
          <th align="left" >Delivery Agent Name</th>
          <th align="left" >Vehicle number</th>
          <th align="center" >Fuel</th>
          <th style="text-align: right;" >Quantity in Ltrs</th>
          <th align="left" >Booked Date Time </th>
          <th align="left" >DeliveryDate Time</th>
          <th style="text-align: center;">Status</th>
          <th style="text-align: center;">Action</th>
        </tr>
      </thead>
      <tbody>
          <tr *ngFor="let order of filtercustomerOrder.response; let i=index">
            <td align="center" class="v-align-middle ">{{i + 1}}</td>
            <td align="left" class="v-align-middle">{{order.orderId}}</td>
            <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
            <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td>
            <td *ngIf="order.vehicleId!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
            <td *ngIf="order.vehicleId==null" align="left" class="v-align-middle">N/A</td>
            <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">{{order.fuelType}}</td>
            <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
            <td *ngIf="order.quantity!=null" align="right" class="v-align-middle">{{order.quantity}}</td>
            <td *ngIf="order.quantity==null" align="right" class="v-align-middle">N/A</td>
            <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
            <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
            <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
            <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
            <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
            <td align="left" class="v-align-middle">
              <button title="View" class="label label-info" (click)="open(content,order)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
              <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
              <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.status==='Pending' || order.status==='Inprogress'" class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
              <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="invoice(order.id)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
            </td>
          </tr>
      </tbody>
    </table>
</div>
</div>

<div *ngIf="filterflag && filteronesearch" class="card-body">
  <div style="display: none;">{{filtercustomerOrderNorecord.responseMessage}}</div>
  <div class="table-responsive">
    <table datatable class="ch-table">
      <thead>
        <tr> 
          <th style="text-align: center;">Sl.No.</th>
          <th align="left" >Order ID </th>
          <th align="left" >Delivery Agent Name</th>
          <th align="left" >Vehicle number</th>
          <th align="center" >Fuel</th>
          <th style="text-align: right;" >Quantity in Ltrs</th>
          <th align="left" >Booked Date Time </th>
          <th align="left" >DeliveryDate Time</th>
          <th style="text-align: center;">Status</th>
          <th style="text-align: center;">Action</th>
        </tr>
      </thead>
      <tbody>
          <tr *ngFor="let order of filtercustomerOrder.response; let i=index">
            <td align="center" class="v-align-middle ">{{i + 1}}</td>
            <td align="left" class="v-align-middle">{{order.orderId}}</td>
            <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
            <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td>
            <td *ngIf="order.vehicleId!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
            <td *ngIf="order.vehicleId==null" align="left" class="v-align-middle">N/A</td>
            <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">{{order.fuelType}}</td>
            <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
            <td *ngIf="order.quantity!=null" align="right" class="v-align-middle">{{order.quantity}}</td>
            <td *ngIf="order.quantity==null" align="right" class="v-align-middle">N/A</td>
            <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
            <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
            <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
            <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
            <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
            <td align="left" class="v-align-middle">
              <button title="View" class="label label-info" (click)="open(content,order)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
              <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
              <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.status==='Pending' || order.status==='Inprogress'" class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
              <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="invoice(order.id)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
            </td>
          </tr>
      </tbody>
    </table>
</div>
</div>

    </ng-template>
  </li>

  
  <li [ngbNavItem]="2">
    <a ngbNavLink> Transaction History </a>
    <ng-template ngbNavContent>
      <tr style="display: none;" *ngFor="let transaction of customerTransanction; let i=index">
        <td align="center" class="v-align-middle ">{{i + 1}}</td>
        <td *ngIf="transaction.orderId!=null" align="left" class="v-align-middle">{{transaction.orderId}}</td>
      </tr>
      <br>
      <select class="select-form-style" style="margin-left: 20px;" id="searchBy" (change)="callsearch($event)">  
        <option value="1">Search By Status</option>  
        <option value="2">Search By Date Range</option>  
    </select>  
    <select class="select-form-style" style="margin-left: 10px;" id="status">
      <option value="1">All</option>  
        <option value="2" selected>Initiated</option>  
        <option value="3">Success</option>  
        <option value="4">Failed</option>  
        <option value="5">Cancelled</option>  
  </select>  
  <input type="date" style="display: none; margin-left: 10px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
    <input type="date" style="display: none; margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
     
  <button style="margin-left: 10px;" class="but-style" (click)="searchtran()" type="button" >Go</button> 
  <button *ngIf="refreshFlag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
  <div *ngIf="errorFlag" style="color: red; margin-left: 20px;">{{errorMessage}}</div>
        <!-- <button style="margin-left: 300px;"  (click)="initiatedtra()" class="btn-transaction0">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios" id="penradio1" value="option2" checked>
            <label class="form-check-label" for="penradio1">
              Initiated
            </label>
          </div>
          </button>   -->
          <!-- <button (click)="successtra()" class="btn-transaction1">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="exampleRadios" id="inpradio1" value="option3">
              <label class="form-check-label" for="inpradio1">
                Success
              </label>
            </div>
            </button>   -->
            <!-- <button (click)="failuretra()" class="btn-transaction2">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="exampleRadios" id="comradio1" value="option4">
                <label class="form-check-label" for="comradio1">
                  Failed
                </label>
              </div>
              </button>    -->
      <!-- <button (click)="cancelledtra()" class="btn-transaction3">
        <div class="form-check">
          <input class="form-check-input" type="radio" name="exampleRadios" id="canradio1" value="option5">
          <label class="form-check-label" for="canradio1">
            Cancelled
          </label>
        </div>
        </button> -->
        <!-- <button (click)="alltra()" class="btn-transaction">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios" id="allradio1" value="option1">
            <label class="form-check-label" for="allradio1">
              All
            </label>
          </div>
          </button>  -->
      <!-- <button (click)="cancelledtra()" class="pen-style3">Cancelled</button>
      <button (click)="failuretra()" class="pen-style2">Failed</button>
      <button (click)="successtra()" class="pen-style1">Success</button>
      <button (click)="initiatedtra()" class="pen-style3">Initiated</button>
      <button (click)="alltra()" class="pen-style3">All</button> -->
      <br>
      <div *ngIf="defaultTransaction" class="card-body">
        <div style="display: none;">{{customerTransanction.responseMessage}}</div>
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Order ID </th>
                      <th style="text-align: left;">Transaction Id</th>
                      <th style="text-align: left;">Payment Mode</th>
                      <th style="text-align: left;">Fuel</th>
                      <th style="text-align: center;">Quantity in Ltrs</th>
                      <th style="text-align: right;">Paid Amount</th>
                      <th style="text-align: center;"><span >Status</span></th>
                      <th style="text-align: left;">Transaction Date Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let transaction of customerTransanction.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="transaction.orderId!=null" align="left" class="v-align-middle">{{transaction.orderId}}</td>
                      <td *ngIf="transaction.orderId==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paymentId!=null"align="left" class="v-align-middle">{{transaction.paymentId}}</td>
                      <td *ngIf="transaction.paymentId==null"align="left" class="v-align-middle">N/A</td>

                      <td *ngIf="transaction.paymentMode!=null" align="left" class="v-align-middle">{{transaction.paymentMode}}</td>
                      <td *ngIf="transaction.paymentMode==null" align="left" class="v-align-middle">N/A</td>

                      <td *ngIf="transaction.fuelType!=null" align="left" class="v-align-middle">{{transaction.fuelType}}</td>
                      <td *ngIf="transaction.fuelType==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.quantity!==null"align="center" class="v-align-middle">{{transaction.quantity}}</td>
                      <td *ngIf="transaction.quantity==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paidAmount!=null"align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.paidAmount | number : '1.2-2'}}</td>
                      <td *ngIf="transaction.paidAmount==null"align="right" class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' "style="color: red;">Failed</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' "class="v-align-middle ht-Pending">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-Pending">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</td>
                      <td *ngIf="transaction.paymentDatetime!=null"align="left" class="v-align-middle">{{transaction.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.paymentDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <!-- <td class="v-align-middle"><button class="btn btn-link"><i class="fa fa-share" aria-hidden="true"></i>
&nbsp;share</button></td> -->
                    </tr>
                  </tbody>
                </table>
          </div>
        </div>

        <!-- ---- All Transactions -----  -->
        <div *ngIf="allTransaction" class="card-body">
          <div style="display: none;">{{allagentTransanction.responseMessage}}</div>
          <div class="table-responsive">
            <table datatable class="ch-table">
              <thead>
                <tr> 
                  <th align="center" >Sl.No.</th>
                  <th align="left" >Order ID </th>
                  <th align="left" >Transaction Id</th>
                  <th  align="left" >Payment Mode</th>
                  <th align="center" >Fuel</th>
                  <th align="center" >Quantity in Ltrs</th>
                  <th align="center" >Paid Amount</th>
                  <th align="center" ><span >Status</span></th>
                  <!-- <th align="center" style="width:180">Payment Mode</th> -->
                  <th align="left" >Transaction Date Time</th>
                  <!-- <th align="center" style="150">Action</th> -->
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let transaction of allagentTransanction.response; let i=index">
                  <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="transaction.orderId!=null" align="left" class="v-align-middle">{{transaction.orderId}}</td>
                      <td *ngIf="transaction.orderId==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paymentId!=null"align="left" class="v-align-middle">{{transaction.paymentId}}</td>
                      <td *ngIf="transaction.paymentId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paymentMode!=null" align="left" class="v-align-middle">{{transaction.paymentMode}}</td>
                      <td *ngIf="transaction.paymentMode==null" align="left" class="v-align-middle">N/A</td>

                     
                      <td *ngIf="transaction.fuelType!=null" align="left" class="v-align-middle">{{transaction.fuelType}}</td>
                      <td *ngIf="transaction.fuelType==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.quantity!==null"align="center" class="v-align-middle">{{transaction.quantity}}</td>
                      <td *ngIf="transaction.quantity==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paidAmount!=null"align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.paidAmount | number : '1.2-2'}}</td>
                      <td *ngIf="transaction.paidAmount==null"align="right" class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' "style="color: red;">Failed</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' "class="v-align-middle ht-Pending">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-Pending">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</td>
                      <td *ngIf="transaction.paymentDatetime!=null"align="left" class="v-align-middle">{{transaction.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.paymentDatetime==null"align="left" class="v-align-middle">N/A</td>
                </tr>
              </tbody>
            </table>
      </div>
    </div>

    <!-- ----Success Transactions -----  -->
    <div *ngIf="successTransaction" class="card-body">
      <div style="display: none;">{{succagentTransanction.responseMessage}}</div>
      <div class="table-responsive">
        <table datatable class="ch-table">
          <thead>
            <tr> 
              <th align="center" >Sl.No.</th>
              <th align="left" >Order ID </th>
              <th align="left" >Transaction Id</th>
              <th align="left" >Payment Mode</th>
              <th align="center" >Fuel</th>
              <th align="center" >Quantity in Ltrs</th>
              <th align="center" >Paid Amount</th>
              <th align="center" ><span >Status</span></th>
              <!-- <th align="center" style="width:180">Payment Mode</th> -->
              <th align="left" >Transaction Date Time</th>
              <!-- <th align="center" style="150">Action</th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let transaction of succagentTransanction.response; let i=index">
              <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="transaction.orderId!=null" align="left" class="v-align-middle">{{transaction.orderId}}</td>
                      <td *ngIf="transaction.orderId==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paymentId!=null"align="left" class="v-align-middle">{{transaction.paymentId}}</td>
                      <td *ngIf="transaction.paymentId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paymentMode!=null" align="left" class="v-align-middle">{{transaction.paymentMode}}</td>
                      <td *ngIf="transaction.paymentMode==null" align="left" class="v-align-middle">N/A</td>

                     
                      <td *ngIf="transaction.fuelType!=null" align="left" class="v-align-middle">{{transaction.fuelType}}</td>
                      <td *ngIf="transaction.fuelType==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.quantity!==null"align="center" class="v-align-middle">{{transaction.quantity}}</td>
                      <td *ngIf="transaction.quantity==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paidAmount!=null"align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.paidAmount | number : '1.2-2'}}</td>
                      <td *ngIf="transaction.paidAmount==null"align="right" class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' "style="color: red;">Failed</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' "class="v-align-middle ht-Pending">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-Pending">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</td>
                      <td *ngIf="transaction.paymentDatetime!=null"align="left" class="v-align-middle">{{transaction.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.paymentDatetime==null"align="left" class="v-align-middle">N/A</td>
            </tr>
          </tbody>
        </table>
  </div>
</div>

<!-- ----Failure Transactions -----  -->
<div *ngIf="failureTransaction" class="card-body">
  <div style="display: none;">{{failagentTransanction.responseMessage}}</div>
  <div class="table-responsive">
    <table datatable class="ch-table">
      <thead>
        <tr> 
          <th align="center" >Sl.No.</th>
          <th align="left" >Order ID </th>
          <th align="left" >Transaction Id</th>
          <th align="left" >Payment Mode</th>
          <th align="center" >Fuel</th>
          <th align="center" >Quantity in Ltrs</th>
          <th align="center" >Paid Amount</th>
          <th align="center" ><span >Status</span></th>
          <th align="left" >Transaction Date Time</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let transaction of failagentTransanction.response; let i=index">
          <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="transaction.orderId!=null" align="left" class="v-align-middle">{{transaction.orderId}}</td>
                      <td *ngIf="transaction.orderId==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paymentId!=null"align="left" class="v-align-middle">{{transaction.paymentId}}</td>
                      <td *ngIf="transaction.paymentId==null"align="left" class="v-align-middle">N/A</td>
                    
                      <td *ngIf="transaction.paymentMode!=null" align="left" class="v-align-middle">{{transaction.paymentMode}}</td>
                      <td *ngIf="transaction.paymentMode==null" align="left" class="v-align-middle">N/A</td>

                                         
                      <td *ngIf="transaction.fuelType!=null" align="left" class="v-align-middle">{{transaction.fuelType}}</td>
                      <td *ngIf="transaction.fuelType==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.quantity!==null"align="center" class="v-align-middle">{{transaction.quantity}}</td>
                      <td *ngIf="transaction.quantity==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paidAmount!=null"align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.paidAmount | number : '1.2-2'}}</td>
                      <td *ngIf="transaction.paidAmount==null"align="right" class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' "style="color: red;">Failed</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' "class="v-align-middle ht-Pending">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-Pending">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</td>
                      <td *ngIf="transaction.paymentDatetime!=null"align="left" class="v-align-middle">{{transaction.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.paymentDatetime==null"align="left" class="v-align-middle">N/A</td>
        </tr>
      </tbody>
    </table>
</div>
</div>
                 <!-- ----Initiated Transactions -----  -->
<div *ngIf="initiatedTransaction" class="card-body">
  <div style="display: none;">{{iniagentTransanction.responseMessage}}</div>
  <div class="table-responsive">
    <table datatable class="ch-table">
      <thead>
        <tr> 
          <th align="center" >Sl.No.</th>
          <th align="left" >Order ID </th>
          <th align="left" >Transaction Id</th>
          <th align="left" >Payment Modex</th>

          <th align="center" >Fuel</th>
          <th align="center" >Quantity in Ltrs</th>
          <th align="center" >Paid Amount</th>
          <th align="center" ><span >Status</span></th>
          <th align="left" >Transaction Date Time</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let transaction of iniagentTransanction.response; let i=index">
          <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="transaction.orderId!=null" align="left" class="v-align-middle">{{transaction.orderId}} </td>
                      <td *ngIf="transaction.orderId==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paymentId!=null"align="left" class="v-align-middle">{{transaction.paymentId}}</td>
                      <td *ngIf="transaction.paymentId==null"align="left" class="v-align-middle">N/A</td>
                    
                      <td *ngIf="transaction.paymentMode!=null" align="left" class="v-align-middle">{{transaction.paymentMode}}</td>
                      <td *ngIf="transaction.paymentMode==null" align="left" class="v-align-middle">N/A</td>

                    
                      <td *ngIf="transaction.fuelType!=null" align="left" class="v-align-middle">{{transaction.fuelType}}</td>
                      <td *ngIf="transaction.fuelType==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.quantity!==null"align="center" class="v-align-middle">{{transaction.quantity}}</td>
                      <td *ngIf="transaction.quantity==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paidAmount!=null"align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.paidAmount | number : '1.2-2'}}</td>
                      <td *ngIf="transaction.paidAmount==null"align="right" class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' "style="color: red;">Failed</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' "class="v-align-middle ht-Pending">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-Pending">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</td>
                      <td *ngIf="transaction.paymentDatetime!=null"align="left" class="v-align-middle">{{transaction.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.paymentDatetime==null"align="left" class="v-align-middle">N/A</td>
        </tr>
      </tbody>
    </table>
</div>
</div>

<!-- ----cancelledTransaction  -----  -->
<div *ngIf="cancelledTransaction" class="card-body">
  <div style="display: none;">{{canceagentTransanction.responseMessage}}</div>
  <div class="table-responsive">
    <table datatable class="ch-table">
      <thead>
        <tr> 
          <th align="center" >Sl.No.</th>
          <th align="left" >Order ID </th>
          <th align="left" >Transaction Id</th>
          <th align="left" >Payment Mode</th>
          <th align="center" >Fuel</th>
          <th align="center" >Quantity in Ltrs</th>
          <th align="center" >Paid Amount</th>
          <th align="center" ><span >Status</span></th>
          <th align="left" >Transaction Date Time</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let transaction of canceagentTransanction.response; let i=index">
          <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="transaction.orderId!=null" align="left" class="v-align-middle">{{transaction.orderId}}</td>
                      <td *ngIf="transaction.orderId==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paymentId!=null"align="left" class="v-align-middle">{{transaction.paymentId}}</td>
                      <td *ngIf="transaction.paymentId==null"align="left" class="v-align-middle">N/A</td>

                      <td *ngIf="transaction.paymentMode!=null" align="left" class="v-align-middle">{{transaction.paymentMode}}</td>
                      <td *ngIf="transaction.paymentMode==null" align="left" class="v-align-middle">N/A</td>

                     
                      <td *ngIf="transaction.fuelType!=null" align="left" class="v-align-middle">{{transaction.fuelType}}</td>
                      <td *ngIf="transaction.fuelType==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.quantity!==null"align="center" class="v-align-middle">{{transaction.quantity}}</td>
                      <td *ngIf="transaction.quantity==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paidAmount!=null"align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.paidAmount | number : '1.2-2'}}</td>
                      <td *ngIf="transaction.paidAmount==null"align="right" class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' "style="color: red;">Failed</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' "class="v-align-middle ht-Pending">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-Pending">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</td>
                      <td *ngIf="transaction.paymentDatetime!=null"align="left" class="v-align-middle">{{transaction.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.paymentDatetime==null"align="left" class="v-align-middle">N/A</td>
        </tr>
      </tbody>
    </table>
</div>
</div>


<div *ngIf="filterflag1 && !filteronesearch1" class="card-body">
  <div style="display: none;">{{filtertransaction.responseMessage}}</div>
  <div class="table-responsive">
    <table datatable class="ch-table">
      <thead>
        <tr> 
          <th align="center" >Sl.No.</th>
          <th align="left" >Order ID </th>
          <th align="left" >Transaction Id</th>
          <th align="left" >Payment Mode</th>
          <th align="center" >Fuel</th>
          <th align="center" >Quantity in Ltrs</th>
          <th align="center" >Paid Amount</th>
          <th align="center" ><span >Status</span></th>
          <th align="left" >Transaction Date Time</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let transaction of filtertransaction.response; let i=index">
          <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="transaction.orderId!=null" align="left" class="v-align-middle">{{transaction.orderId}}</td>
                      <td *ngIf="transaction.orderId==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paymentId!=null"align="left" class="v-align-middle">{{transaction.paymentId}}</td>
                      <td *ngIf="transaction.paymentId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paymentMode!=null" align="left" class="v-align-middle">{{transaction}}</td>
                      <td *ngIf="transaction.paymentMode==null" align="left" class="v-align-middle">N/A</td>

                     
                      <td *ngIf="transaction.fuelType!=null" align="left" class="v-align-middle">{{transaction.fuelType}}</td>
                      <td *ngIf="transaction.fuelType==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.quantity!==null"align="center" class="v-align-middle">{{transaction.quantity}}</td>
                      <td *ngIf="transaction.quantity==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paidAmount!=null"align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.paidAmount | number : '1.2-2'}}</td>
                      <td *ngIf="transaction.paidAmount==null"align="right" class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' "style="color: red;">Failed</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' "class="v-align-middle ht-Pending">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-Pending">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</td>
                      <td *ngIf="transaction.paymentDatetime!=null"align="left" class="v-align-middle">{{transaction.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.paymentDatetime==null"align="left" class="v-align-middle">N/A</td>
        </tr>
      </tbody>
    </table>
</div>
</div>

<div *ngIf="filterflag1 && filteronesearch1" class="card-body">
  <div style="display: none;">{{filtertransactionNorecord.responseMessage}}</div>
  <div class="table-responsive">
    <table datatable class="ch-table">
      <thead>
        <tr> 
          <th align="center" >Sl.No.</th>
          <th align="left" >Order ID </th>
          <th align="left" >Transaction Id</th>
          <th align="left" >Payment Mode</th>

          <th align="center" >Fuel</th>
          <th align="center" >Quantity in Ltrs</th>
          <th align="center" >Paid Amount</th>
          <th align="center" ><span >Status</span></th>
          <th align="left" >Transaction Date Time</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let transaction of filtertransactionNorecord.response; let i=index">
          <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="transaction.orderId!=null" align="left" class="v-align-middle">{{transaction.orderId}}</td>
                      <td *ngIf="transaction.orderId==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paymentId!=null"align="left" class="v-align-middle">{{transaction.paymentId}}</td>
                      <td *ngIf="transaction.paymentId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paymentMode!=null" align="left" class="v-align-middle">{{transaction.paymentMode}}</td>
                      <td *ngIf="transaction.paymentMode==null" align="left" class="v-align-middle">N/A</td>

                     
                      <td *ngIf="transaction.fuelType!=null" align="left" class="v-align-middle">{{transaction.fuelType}}</td>
                      <td *ngIf="transaction.fuelType==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.quantity!==null"align="center" class="v-align-middle">{{transaction.quantity}}</td>
                      <td *ngIf="transaction.quantity==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paidAmount!=null"align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.paidAmount | number : '1.2-2'}}</td>
                      <td *ngIf="transaction.paidAmount==null"align="right" class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' "style="color: red;">Failed</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' "class="v-align-middle ht-Pending">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-Pending">{{transaction.paymentStatus}}</td>
                      <td align="center" *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</td>
                      <td *ngIf="transaction.paymentDatetime!=null"align="left" class="v-align-middle">{{transaction.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.paymentDatetime==null"align="left" class="v-align-middle">N/A</td>
        </tr>
      </tbody>
    </table>
</div>
</div>
    </ng-template>
  </li>
  <li [ngbNavItem]="3" >
    <a ngbNavLink>Customer Settlement Bank Info</a>
    <div style="display: none;">{{customerBankInfo.responseMessage}}</div>
    <ng-template ngbNavContent>
      <div class="card-body">
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Account Holder Name</th>
                      <th style="text-align: left;">Account Number</th>
                      <th style="text-align: left;">Account Type</th>
                      <th style="text-align: left;" >Bank name</th>
                      <th style="text-align: left;" >Branch Name</th>
                      <th style="text-align: left;">IFSC Code</th>
                      <th style="text-align: left;">Approved Date Time</th>
                      <th style="text-align: center;">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let bank of customerBankInfo.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="bank.beneficiaryName!=null"align="left" class="v-align-middle">{{bank.beneficiaryName}}</td>
                      <td *ngIf="bank.beneficiaryName==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.accountNumber!=null"align="left" class="v-align-middle">{{bank.accountNumber}}</td>
                      <td *ngIf="bank.accountNumber==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.accountType!=null"align="left" class="v-align-middle">{{bank.accountType}}</td>
                      <td *ngIf="bank.accountType==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.bankId!=null && bank.bankId.bankName!=null"align="left" class="v-align-middle">{{bank.bankId.bankName}}</td>
                      <td *ngIf="bank.bankId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.branchName!=null"align="left" class="v-align-middle">{{bank.branchName}}</td>
                      <td *ngIf="bank.branchName==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="bank.ifscCode!=null"align="left" class="v-align-middle">{{bank.ifscCode}}</td>
                      <td *ngIf="bank.ifscCode==null"align="left" class="v-align-middle">N/A</td>
                      <td align="left" *ngIf="bank.approvalStatus==10" class="v-align-middle">N/A</td>
                      <td align="left" *ngIf="bank.approvalStatus==11" class="v-align-middle">{{bank.approvedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td align="left" *ngIf="bank.approvalStatus==12" class="v-align-middle">{{bank.approvedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td align="center" *ngIf="bank.approvalStatus==11" class="v-align-middle ht-active">Approved</td>
                      <td align="center" *ngIf="bank.approvalStatus==12" style="color: red;">Rejected</td>
                      <td align="center" *ngIf="bank.approvalStatus==10" class="v-align-middle ht-Pending">Pending</td>
                    </tr>
                  </tbody>
                </table>
          </div>
        </div>
    </ng-template>
  </li>
  
  <li [ngbNavItem]="4">
    <a ngbNavLink> Assets </a>
    <ng-template ngbNavContent>
      <div class="card-body">
        <div style="display: none;">{{assetsResponseModel.responseMessage}}</div>
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th align="center" >Sl.No.</th>
                      <th align="left" >Authorized Person </th>
                      <th align="left" >DG KVA</th>
                      <th align="center" >Make</th>
                      <th align="center" >Contact Number</th>
                      <th align="center" >Model</th>
                      <th align="center" >Fuel Type</th>
                      <th align="center" >Fuel Tank Capaticy</th>
                      <th align="center" >Image</th>
                      <th align="center" >Location</th>
                      <th align="center" >Onboarded by</th>
                      <th align="center" >Onboarded Date & Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let asset of assetsResponseModel.response; let i=index">
                      <td align="left" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="asset.authorizedPersonName!=null" align="left" class="v-align-middle">{{asset.authorizedPersonName}}</td>
                      <td *ngIf="asset.authorizedPersonName==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="asset.dgKVA!=null"align="left" class="v-align-middle">{{asset.dgKVA}}</td>
                      <td *ngIf="asset.dgKVA==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="asset.assestMake!=null" align="left" class="v-align-middle">{{asset.assestMake}}</td>
                      <td *ngIf="asset.assestMake==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="asset.contactMobileNumber!=null" align="left" class="v-align-middle">{{asset.contactMobileNumber}}</td>
                      <td *ngIf="asset.contactMobileNumber==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="asset.assestModel!=null" align="left" class="v-align-middle">{{asset.assestModel}}</td>
                      <td *ngIf="asset.assestModel==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="asset.fuelType!=null" align="left" class="v-align-middle">{{asset.fuelType.fuelTypeName}}</td>
                      <td *ngIf="asset.fuelType==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="asset.tankCapacity!=null" align="left" class="v-align-middle">{{asset.tankCapacity}}</td>
                      <td *ngIf="asset.tankCapacity==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="asset.assestImage!=null" align="left" class="v-align-middle"><button class="label label-success" (click)="open2(content9,asset.id)">
                        <span aria-hidden="true"></span>
                          <i class="fa fa-image"></i>
                      </button></td>
                      <td align="center" class="v-align-middle">
                        <button type="button" title="Location" class="label label-info" (click)="location(asset.latitude,asset.longitude)" (click)="open3(content2)">
                          <span aria-hidden="true"></span>
                          <i class="fa fa-location-arrow"></i>
                        </button>
                      </td>
                      <td *ngIf="asset.assestImage==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="asset.createdBy!=null" align="left" class="v-align-middle">{{asset.createdBy}}</td>
                      <td *ngIf="asset.createdBy==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="asset.createdDatetime!=null" align="left" class="v-align-middle">{{asset.createdDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="asset.createdDatetime==null" align="left" class="v-align-middle">N/A</td>
                    </tr>
                  </tbody>
                </table>
          </div>
        </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="5">
    <a ngbNavLink>Device Information</a>
    <ng-template ngbNavContent>
      <div class="card-body">
        <div class="table-responsive">
          <table datatable class="ch-table">
            <thead>
              <tr> 
                <th style="text-align: center;">Sl.No.</th>
                <th style="text-align: left;">Device Name</th>
                <th style="text-align: left;">Device Number</th>
                <th style="text-align: left;">Device Type</th>
                <th style="text-align: left;" >Device Model</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td align="center" class="v-align-middle ">1</td>
                <td align="left" class="v-align-middle">{{userModel.userdevicename}}</td>
                <td align="left" class="v-align-middle">{{userModel.userdevicenumber}}</td>
                <td align="left" class="v-align-middle">{{userModel.userdevicetype}}</td>
                <td align="left" class="v-align-middle">{{userModel.userdevicemodel}}</td>
              </tr>
            </tbody>
          </table>
    </div>
  </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="6" >
    <a ngbNavLink>Charges Configuration</a>
    <div style="display: none;">{{customerBankInfo.responseMessage}}</div>
    
    <ng-template ngbNavContent>
      
      <div class="card-body">
        <button type="button" style="float:right;" class="label label-info" (click)="openCharge(chargecontent)">Update Charges</button>

              <div class="table-responsive">
           
                <div class="flex">
                 <div class="container" style="background-color: rgb(255, 182, 193);border: 1px solid black;
                 padding: 4px;"
                 fxLayout
               ><br>
               <div class="item item-1">
                 <p class="card-title"><b><span > Set-up Fee </span></b><br>
                   <span><i class="fa fa-inr" aria-hidden="true"></i> {{currentCharges.vehicleAttachmentFee | number : '1.2-2'}}</span></p>
                </div>
                <div class="item item-2" style="margin-left: 30px;">
                 <p class="card-title"><b><span >Delivery Charges </span></b><br>
                   <span *ngIf="currentCharges.deliveryChargeFlag===1">
                     <span >Based on km</span>
                     <span >{{currentCharges.deliveryLessEqualKms}} - <i class="fa fa-inr" aria-hidden="true"></i> {{currentCharges.deliveryLessEqualCharges | number : '1.2-2'}} </span><br>
                     <span >{{currentCharges.deliveryGreaterEqualKms}} - <i class="fa fa-inr" aria-hidden="true"></i> {{currentCharges.deliveryGreaterEqualCharges | number : '1.2-2'}} / KM</span>
                   </span>
             
                   <span *ngIf="currentCharges.deliveryChargeFlag===2">
                    
                     <span ><b>Fixed Fee</b> - <i class="fa fa-inr" aria-hidden="true"></i> {{currentCharges.deliveryFixedFee | number : '1.2-2'}} </span><br>
                   </span>
                   <span *ngIf="currentCharges.deliveryChargeFlag===3">
                    
                     <span ><b>Per Ltr Cost</b> - <i class="fa fa-inr" aria-hidden="true"></i> {{currentCharges.deliveryPerLtrCost | number : '1.2-2'}} </span><br>
                   </span>
                   
                 </p>
                </div>
                <div class="item item-3" style="margin-left: 30px;">
                  <p class="card-title"><b><span >Asset Charge</span></b><br>
                    <span>Setup: <b><i class="fa fa-inr" aria-hidden="true"></i>{{currentCharges.assetSetupFee | number : '1.2-2'}}</b></span><br/>
                    <span>Subscription:<b><i class="fa fa-inr" aria-hidden="true"></i> {{currentCharges.assetSubscriptionFee | number : '1.2-2'}}</b></span>
                  
                  </p>
                 </div>
                <div class="item item-3" style="margin-left: 30px;">
                 <p class="card-title"><b><span >Platform Charge</span></b><br>
                   <span><i class="fa fa-inr" aria-hidden="true"></i> {{currentCharges.internetHandlingFee | number : '1.2-2'}}</span></p>
                </div>
                <div class="item item-4" style="margin-left: 30px;">
                 <p class="card-title"><b><span >AMC Fee</span></b><br>
                   <span><i class="fa fa-inr" aria-hidden="true"></i> {{currentCharges.amcFee | number : '1.2-2'}}</span></p>
                </div>
                <div class="item item-5" style="margin-left: 30px;">
                 <p class="card-title"><b><span >MMC Fee</span></b><br>
                   <span><i class="fa fa-inr" aria-hidden="true"></i> {{currentCharges.mmcFee | number : '1.2-2'}}</span></p>
                </div>
                <div class="item item-6" style="margin-left: 30px;">
                 <p class="card-title"><b><span >SGST</span></b><br>
                   <span>{{currentCharges.sgstPercentage}} <i class="fa fa-percent" aria-hidden="true"></i></span></p>
                </div>
                <div class="item item-7" style="margin-left: 30px;">
                 <p class="card-title"><b><span >CGST</span></b><br>
                   <span>{{currentCharges.cgstPercentage}} <i class="fa fa-percent" aria-hidden="true"></i></span></p>
                </div>
                <div class="item item-8" style="margin-left: 30px;">
                 <p class="card-title"><b><span >IGST</span></b><br>
                   <span>{{currentCharges.igstPercentage}} <i class="fa fa-percent" aria-hidden="true"></i></span></p>
                </div>
                <div class="item item-9" style="margin-left: 30px;">
                 <p class="card-title"><b><span >Effective from </span></b><br>
                   <span>{{currentCharges.effectiveFrom | date:'dd/MM/yyyy hh:mm:ss a'}}</span></p>
                </div>
                <div class="item item-10" style="margin-left: 30px;">
                 <p class="card-title"><b><span >Created Date And time</span></b><br>
                   <span>{{currentCharges.modifiedDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</span></p>
                </div>
                 <div class="item item-11" style="margin-left: 25px;">
                 <p class="card-title"><b><span >Min Quantity</span></b><br>
                   <span>{{currentCharges.minOrderQuantity}}</span></p>
                </div>
               <div class="item item-12" style="margin-left: 25px;">
                 <p class="card-title"><b><span >Max Quantity</span></b><br>
                   <span>{{currentCharges.maxOrderQuantity}}</span></p>
                </div>
                 </div>
                 
                </div>
                <br/>
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th>Sl.No.</th>
                      <th >vehicle Attachment Fee</th>
                      <th >Delivery Charges</th>
                      <th >Platform Charge</th>
                      <th >SGST</th>
                      <th >CGST</th>
                      <th >IGST</th>
                      <th >Effective From</th>
                      <th >Created Date time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let charges of chargesModel; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td align="left" class="v-align-middle">{{charges.vehicleAttachmentFee}}</td>
                      <td align="left" class="v-align-middle">
                        <span *ngIf="charges.deliveryChargeFlag===1">
                          <span >Based on km</span>
                          <span >{{charges.deliveryLessEqualKms}} - <i class="fa fa-inr" aria-hidden="true"></i> {{charges.deliveryLessEqualCharges | number : '1.2-2'}} </span><br>
                          <span >{{charges.deliveryGreaterEqualKms}} - <i class="fa fa-inr" aria-hidden="true"></i> {{charges.deliveryGreaterEqualCharges | number : '1.2-2'}} / KM</span>
                        </span>
                  
                        <span *ngIf="charges.deliveryChargeFlag===2">
                         
                          <span ><b>Fixed Fee</b> - <i class="fa fa-inr" aria-hidden="true"></i> {{charges.deliveryFixedFee | number : '1.2-2'}} </span><br>
                        </span>
                        <span *ngIf="charges.deliveryChargeFlag===3">
                         
                          <span ><b>Per Ltr Cost</b> - <i class="fa fa-inr" aria-hidden="true"></i> {{charges.deliveryPerLtrCost | number : '1.2-2'}} </span><br>
                        </span>


                      </td>
                      <td align="left" class="v-align-middle">{{charges.internetHandlingFee}}</td>
                      <td align="center" class="v-align-middle">{{charges.sgstPercentage}}</td>
                      <td align="center" class="v-align-middle">{{charges.cgstPercentage}}</td>
                      <td align="center" class="v-align-middle">{{charges.igstPercentage}}</td>
                      <td align="left" class="v-align-middle">{{charges.effectiveFrom | date:'dd/MM/yyyy'}}</td>
                      <td align="left" class="v-align-middle">{{charges.modifiedDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                    </tr>
                  </tbody>
                </table>
          </div>
        </div>
    </ng-template>
  </li>
  
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>


<ng-template #content let-modal>
  <div class="modal-header">
    <h6>Order View Page</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <h5 style="margin-left: 15px; text-align: left; color: grey;">Order Details</h5>
  <div class="container"
  fxLayout
>
<div class="item item-1">
<p><b>Order ID</b></p>
<span>{{orderDetails.response.orderId}}</span>
</div>
<div class="item item-2" style="margin-left: 30px;">
  <p><b>Customer Name</b></p>
  <span *ngIf="orderDetails.response.customerId!=null" style="word-wrap: break-word;">{{orderDetails.response.customerId.firstName}} {{orderDetails.response.customerId.lastName}}</span>
      <span *ngIf="orderDetails.response.customerId==null">N/A</span>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p><b>Customer Mobile</b></p>
  <span *ngIf="orderDetails.response.customerId!=null">{{orderDetails.response.customerId.mobileNumber}}</span>
      <span *ngIf="orderDetails.response.customerId==null">N/A</span>
</div>
<div class="item item-4" style="margin-left: 30px;">
  <p><b>Booking Date & Time</b></p>
  <span *ngIf="orderDetails.response.bookedDate!=null">{{orderDetails.response.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span>
      <span *ngIf="orderDetails.response.bookedDate==null">N/A</span>
</div>
<div class="item item-5" style="margin-left: 30px;">
  <p><b>Quantity in Ltrs</b></p>
  <span *ngIf="orderDetails.response.quantity!=null">{{orderDetails.response.quantity}}</span>
      <span *ngIf="orderDetails.response.quantity==null">N/A</span>
</div>
<div class="item item-7" style="margin-left: 30px;">
  <p><b>Order Type</b></p>
<span *ngIf="orderDetails.response.bookLaterTime===''; else ifNotShow">
  Immediate
</span>
<ng-template #ifNotShow>
  <span>Scheduled</span>
</ng-template>
</div>

<div class="item item-5" style="margin-left: 30px;" >
  <p><b>Payment Mode</b></p>
  <span *ngIf="orderDetails.response.paymentModeId!=null">{{orderDetails.response.paymentModeId.paymentMode}}</span>
      <span *ngIf="orderDetails.response.paymentModeId==null">N/A</span>
</div>

<div *ngIf="orderDetails.response.status==='Cancelled'" class="item item-8" style="margin-left: 30px;">
  <p><b>Cancelled Date & Time</b></p>
  <span >{{orderDetails.response.orderCancellationDatetime |date:'dd/MM/yyyy hh:mm:ss a'}}</span>
</div>
<div *ngIf="orderDetails.response.status==='Cancelled'" class="item item-9" style="margin-left: 30px;">
  <p><b>Cancelled By</b></p>
  <span *ngIf="orderDetails.response.cancelledBy!=null">
    <span style="font-size: 10px;">Name :{{orderDetails.response.cancelledBy.firstName}} {{orderDetails.response.cancelledBy.lastName}}</span><br>
    <span style="font-size: 10px;">Mobile :{{orderDetails.response.cancelledBy.mobileNumber}}</span>
  </span>
  <span *ngIf="orderDetails.response.cancelledBy==null">N/A</span>
</div>
<div *ngIf="orderDetails.response.status==='Cancelled'" class="item item-9" style="margin-left: 30px;">
  <p><b>Reason for Cancelling</b></p>
  <span *ngIf="orderDetails.response.cancellationId!=null" style="word-wrap: break-word;">{{orderDetails.response.cancellationId.cancellationMessage}}</span>
  <span *ngIf="orderDetails.response.cancellationId==null">N/A</span>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p><b>Status</b></p>
  <span *ngIf="orderDetails.response.status==='Pending'"><span class="ht-Pending"><b>{{orderDetails.response.status}}</b></span></span>
      <span *ngIf="orderDetails.response.status==='Cancelled'"><span style="color: red;"><b>{{orderDetails.response.status}}</b></span></span>
      <span *ngIf="orderDetails.response.status==='Inprogress'"><span style="color: blue;"><b>{{orderDetails.response.status}}</b></span></span>
      <span *ngIf="orderDetails.response.status==='Delivered'"><span class="ht-active"><b>{{orderDetails.response.status}}</b></span></span>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <span *ngIf="orderDetails.response.status==='Delivered' && chargesFlag===0">
      <button title="Add Additional Charges" style="margin-left: 5px;" (click)="openCharges(content3,orderDetails.response.id)" class="label label-success" >Add Additional Charges</button>
    </span>
    <span *ngIf="chargesFlag==1">
      <button title="View Additional Charges" style="margin-left: 5px;" (click)="viewCharges(content6,orderDetails.response.id)" class="label label-success" >View Additional Charges</button>
    </span>
</div>

  </div>
  <br>
  <div class="container"
  fxLayout
>
<div class="item item-7"  *ngIf="orderDetails.response.paymentModeId.id==1">
  <p class="card-title"><b>Payment Status</b>  <br>
    <span *ngIf="orderDetails.response.paymentStatus!=null && orderDetails.response.paymentStatus==='Success' "class="v-align-middle ht-active">{{orderDetails.response.paymentStatus}}</span>
    <span *ngIf="orderDetails.response.paymentStatus!=null && orderDetails.response.paymentStatus==='Failure' " style="color: red;">Failed</span>
    <span *ngIf="orderDetails.response.paymentStatus!=null && orderDetails.response.paymentStatus==='Cancelled' " style="color: red;">{{orderDetails.response.paymentStatus}}</span>
    <span *ngIf="orderDetails.response.paymentStatus!=null && orderDetails.response.paymentStatus==='cancelled' " style="color: red;">{{orderDetails.response.paymentStatus}}</span>
    <span *ngIf="orderDetails.response.paymentStatus!=null && orderDetails.response.paymentStatus==='Initiated' " style="color: blue;">{{orderDetails.response.paymentStatus}}</span>
  </p>
</div>
<div class="item item-7" *ngIf="orderDetails.response.paymentModeId.id!=1">
  <p class="card-title"><b>Payment Status</b>  <br>
    <span *ngIf="orderDetails.response.approvalStatus==='Pending' "class="v-align-middle ht-inactive">{{orderDetails.response.approvalStatus}}</span> 
    <span *ngIf="orderDetails.response.approvalStatus==='Success' || orderDetails.response.approvalStatus==='Approved' || orderDetails.response.approvalStatus==='Submitted' "class="v-align-middle ht-active">{{orderDetails.response.approvalStatus}}</span> 
    <span *ngIf="orderDetails.response.approvalStatus==='Failed' " style="color: red;">Failed</span> 
    <span *ngIfElse="orderDetails.response.approvalStatus!=null " style="color: orange;">{{orderDetails.response.approvalStatus}}</span> 

  </p>
</div>
<div class="item item-2"  style="margin-left: 40px;" *ngIf="orderDetails.response.paymentModeId.id==3 || orderDetails.response.paymentModeId.id==5">
  <p class="card-title"><b> Reference Number</b>
    <br>
    <span *ngIf="orderDetails.response.paymentReferenceNo!=null">
        {{orderDetails.response.paymentReferenceNo}}
       
      </span> 
      <span *ngIf="orderDetails.response.paymentReferenceNo==null">N/A</span>
   </p>
</div>
<div class="item item-2" style="margin-left: 40px;" *ngIf="orderDetails.response.paymentModeId.id==2">
  <p class="card-title"><b>Received Amount</b>
    <br>
    <span>{{orderDetails.response.receivedAmount | number : '1.2-2'}}</span> 
   </p>
</div>

<div class="item item-2" style="margin-left: 40px;" *ngIf="orderDetails.response.paymentModeId.id==5">
  <p class="card-title"><b>Cheque Claim Status</b>
    <br>
    <span *ngIf="orderDetails.response.chequeClaimStatus!=null">{{orderDetails.response.chequeClaimStatus}}</span> 
    <span *ngIf="orderDetails.response.chequeClaimStatus==null">N/A</span>
  </p>
</div>



<div class="item item-3" style="margin-left: 40px;">
  <p><b>Coupon Code</b></p>
  <span *ngIf="orderDetails.response.couponCode!=null">{{orderDetails.response.couponCode}}</span>
  <span *ngIf="orderDetails.response.couponCode==null">N/A</span>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p><b>Coupon Discount Price</b></p>
  <span *ngIf="orderDetails.response.couponCode!=null">
  <span *ngIf="orderDetails.response.couponDiscountFlat==1">
    <span>{{orderDetails.response.couponDiscountPrice}} % </span>
  </span>
  <span *ngIf="orderDetails.response.couponDiscountFlat==2">
    <span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.couponDiscountPrice | number : '1.2-2'}}</span>
  </span>
  </span>
  <span *ngIf="orderDetails.response.couponCode==null">N/A</span>
</div>
</div>
  <hr class="beautiful-line">

<br>
  <h5 style="margin-left: 15px; text-align: left; color: grey;">Delivery Agent Info</h5>
  <div class="container"
  fxLayout
>
<div class="item item-1">
<p><b>Delivery Agent Name</b></p>
<span *ngIf="orderDetails.response.orderAcceptedBy!=null" style="word-wrap: break-word;">{{orderDetails.response.orderAcceptedBy.firstName}} {{orderDetails.response.orderAcceptedBy.lastName}}</span>
      <span *ngIf="orderDetails.response.orderAcceptedBy==null">N/A</span>
</div>
<div class="item item-2" style="margin-left: 40px;">
  <p><b>Delivery Agent Number</b></p>
  <span *ngIf="orderDetails.response.orderAcceptedBy!=null">{{orderDetails.response.orderAcceptedBy.mobileNumber}}</span>
      <span *ngIf="orderDetails.response.orderAcceptedBy==null">N/A</span>
</div>
<div class="item item-4" style="margin-left: 40px;">
  <p><b>Vehicle Number</b></p>
  <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.vehicleNumber}}</span>
      <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
</div>
<div class="item item-5" style="margin-left: 40px;">
  <p><b>Fuel</b></p>
  <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.fuelType.fuelTypeName}}</span>
      <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
</div>
<div *ngIf="orderDetails.response.status==='Completed'" class="item item-6" style="margin-left: 40px;">
  <p><b>Delivered  Date Time</b></p>
  <span >{{orderDetails.response.orderDeliveredDateTime |date:'dd/MM/yyyy hh:mm:ss a' }}</span>
</div>
<div class="item item-7" style="margin-left: 40px;">
  <p><b>Delivery location</b></p>
  <span *ngIf="orderDetails.response.deliveryAddress!=null">{{orderDetails.response.deliveryAddress}}</span>
      <span *ngIf="orderDetails.response.deliveryAddress==null">N/A</span>
</div>
  </div>

  <hr class="beautiful-line">
  <h5 style="margin-left: 15px; text-align: left; color: grey;">Transaction Details</h5>
  <div class="container"
  fxLayout
>

<div class="item item-3">
  <p class="card-title"><b>Total Fuel Cost (In Rs.)</b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.totalfuelcost | number : '1.2-2'}}</span></p>
</div>
<div class="item item-6" style="margin-left: 40px;">
  <p class="card-title"><b>Coupon Info </b> <br>
    <span *ngIf="orderDetails.response.couponCode!=null">Coupon Code : {{orderDetails.response.couponCode}}</span>
    <span *ngIf="orderDetails.response.couponCode==null">Coupon Code : N/A</span><br>
    <span *ngIf="orderDetails.response.couponDiscountFlat!=null && orderDetails.response.couponCode!=null">
      <span *ngIf="orderDetails.response.couponDiscountFlat==1">
        <span>Discount Percentage : {{orderDetails.response.couponDiscountPrice}} % </span>
      </span>
      <span *ngIf="orderDetails.response.couponDiscountFlat==2">
        Discount Prise :  <i class="fa fa-inr" aria-hidden="true"></i>{{orderDetails.response.couponDiscountPrice | number : '1.2-2' }}
      </span>
    </span>
    <span *ngIf="orderDetails.response.couponCode==null">DisCount : N/A</span>
  </p>
</div>
<div *ngIf="orderDetails.response.couponCode!=null" class="item item-6" style="margin-left: 40px;">
  <p class="card-title"><b>Final Fuel Cost </b> <br>
    <span><i class="fa fa-inr" aria-hidden="true"></i>{{orderDetails.response.finaltotalfuelCost | number : '1.2-2' }}</span>
</div>
<div class="item item-4" style="margin-left: 20px;">
  <p class="card-title"><b>Delivery Charge(In Rs.) </b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.deliveryCharges | number : '1.2-2'}}</span></p>
</div>
<div class="item item-5" style="margin-left: 20px;">
  <p class="card-title"><b>SGST (9%) </b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.sgst | number : '1.2-2'}}</span></p>  
</div>
<div class="item item-5" style="margin-left: 20px;">
  <p class="card-title"><b>CGST (9%) </b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.cgst | number : '1.2-2'}}</span></p>  
</div>
<div class="item item-6" style="margin-left: 20px;">
  <p class="card-title"><b>Platform Charge (In Rs.) </b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.internetHandlingFee | number : '1.2-2' }}</span></p>
</div>
<div class="item item-6" *ngIf="orderDetails.response.additionalChargesAmount!=0" style="margin-left: 20px;">
  <p class="card-title"><b>Additional Charges </b>
    <br><span> {{orderDetails.response.additionalChargesTitle}}</span>
     <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.additionalChargesAmount | number : '1.2-2' }}</span></p>
</div> 

<div class="item item-6" style="margin-left: 20px;">
  <p class="card-title"><b>Total Paid Amount (In Rs.) </b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.totalPayableAmount | number : '1.2-2' }}</span></p>
</div>
  </div>
  
  <br>
  <h5 *ngIf="orderDetails.response.status==='Completed'" style="margin-left: 15px; text-align: left; color: grey;">Transaction Details</h5>
  <div *ngIf="orderDetails.response.status==='Completed'" class="container"
  fxLayout
>
<div class="item item-1">
  <p *ngIf="orderDetails.response.totalfuelcost!=null"class="card-title"><b>Total Fuel Cost</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.totalfuelcost | number : '1.2-2'}}</span></p>
  <p *ngIf="orderDetails.response.totalfuelcost==null"class="card-title"><b>Total Fuel Cost</b><br><span>N/A</span></p>
</div>
<div class="item item-1" style="margin-left: 30px;">
  <p *ngIf="orderDetails.response.deliveryCharges!=null"class="card-title"><b>Delivery Charges</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.deliveryCharges | number : '1.2-2'}}</span></p>
          <p *ngIf="orderDetails.response.deliveryCharges==null"class="card-title"><b>Delivery Charges</b><br><span>N/A</span></p>
  </div>
  <div class="item item-1" style="margin-left: 30px;">
    <p *ngIf="orderDetails.response.sgst!=null"class="card-title"><b>SGST</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.sgst | number : '1.2-2'}}</span></p>
            <p *ngIf="orderDetails.response.sgst==null"class="card-title"><b>SGST</b><br><span>N/A</span></p>
    </div>
    <div class="item item-1" style="margin-left: 30px;">
      <p *ngIf="orderDetails.response.cgst!=null"class="card-title"><b>CGST</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.cgst | number : '1.2-2'}}</span></p>
              <p *ngIf="orderDetails.response.cgst==null"class="card-title"><b>CGST</b><br><span>N/A</span></p>
      </div>
      <div class="item item-1" style="margin-left: 30px;">
        <p *ngIf="orderDetails.response.internetHandlingFee!=null"class="card-title"><b>Platform Charges</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.internetHandlingFee | number : '1.2-2'}}</span></p>
                <p *ngIf="orderDetails.response.internetHandlingFee==null"class="card-title"><b>Platform Charges</b><br><span>N/A</span></p>
        </div>    
        <div class="item item-1" style="margin-left: 30px;">
                  <p *ngIf="orderDetails.response.totalPayableAmount!=null"class="card-title"><b>Total Paid Amount</b><br><span><i class="fa fa-inr" aria-hidden="true"></i> {{orderDetails.response.totalPayableAmount | number : '1.2-2'}}</span></p>
                  <p *ngIf="orderDetails.response.totalPayableAmount==null"class="card-title"><b>Total Paid Amount</b><br><span>N/A</span></p>

          </div>     
<div class="item item-2" style="margin-left: 30px;">
  <p *ngIf="orderDetails.response.transactionId!=null"class="card-title"><b>Transaction ID</b><br><span>{{orderDetails.response.transactionId}}</span></p>
          <p *ngIf="orderDetails.response.transactionId==null"class="card-title"><b>Transaction ID</b><br><span>N/A</span></p>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p *ngIf="orderDetails.response.paymentStatus==='Success'" class="card-title"><b>Payment Status </b><br><span class="ht-Success"><b>{{orderDetails.response.paymentStatus}}</b></span></p>
        <p *ngIf="orderDetails.response.paymentStatus==='Pending'" class="card-title"><b>Payment Status </b><br><span class="ht-Pending"><b>{{orderDetails.response.paymentStatus}}</b></span></p>
        <p *ngIf="orderDetails.response.paymentStatus==='Failure'" class="card-title"><b>Payment Status </b><br><span style="color: red;"><b>Failed</b></span></p>
        <p *ngIf="orderDetails.response.paymentStatus==='Initiated'" class="card-title"><b>Payment Status</b> <br><span class="ht-Pending"><b>Pending</b></span></p>
</div>
<div class="item item-4" style="margin-left: 30px;">
  <p class="card-title"><b>Transaction Date & Time</b> <br><span>{{orderDetails.response.initiatedDatetime |date:'dd/MM/yyyy hh:mm:ss a'}}</span></p>
</div>
  </div>
  <hr *ngIf="orderDetails.response.status=='Cancelled'" class="beautiful-line">
  <h5 *ngIf="orderDetails.response.status=='Cancelled'" style="margin-left: 15px; text-align: left; color: grey;">Order Track Info</h5>
  <br>
  <div *ngIf="orderDetails.response.status=='Cancelled'"class="container"
    fxLayout
  >
  <div class="item item-1" style="margin-left: 20px;">
    <span>
      <img *ngIf="orderTrackDetails.response.orderPlaced==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
      <img *ngIf="orderTrackDetails.response.orderPlaced==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
      <br>
    </span>
    <span style="margin-bottom: 10px;"><b>Order Placed</b></span><br>
    <span *ngIf="orderTrackDetails.response.orderPlacedDateTime!=null" style="text-align: center;">
      {{orderTrackDetails.response.orderPlacedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
    </span>
    <span *ngIf="orderTrackDetails.response.orderPlacedDateTime==null">
      Will be Updated
    </span>
  </div>
  <div class="item item-2" style="margin-left: -80px;">
    <b><hr width="80px" color="pink"></b>
  </div>
  <div class="item item-3" style="margin-left: 15px;">
    <span>
      <img *ngIf="orderTrackDetails.response.orderAccepted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
      <img *ngIf="orderTrackDetails.response.orderAccepted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>    
    </span>
    <span style="margin-bottom: 10px;"><b>Agent Accepted</b></span><br>
    <span *ngIf="orderTrackDetails.response.orderAcceptedDateTime!=null">
      {{orderTrackDetails.response.orderAcceptedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
    </span>
    <span style="color: red;" *ngIf="orderTrackDetails.response.orderAcceptedDateTime==null">
      Order Cancelled
    </span>
  </div>
  <div class="item item-4" style="margin-left: -50px;">
    <b><hr width="80px" color="pink"></b>
  </div>
  <div class="item item-5" style="margin-left: 15px;">
    <img *ngIf="orderTrackDetails.response.paymentCompleted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
      <img *ngIf="orderTrackDetails.response.paymentCompleted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>    
      <span style="margin-bottom: 10px; width: 30px;"><b>Payment Completed</b></span><br>
      <span style="color: red;">
        Order Cancelled
      </span>    
  </div>
  <div class="item item-6" style="margin-left: -60px;">
    <b><hr width="80px" color="pink"></b>
  </div>
  <div class="item item-7" style="margin-left: 15px;">
    <span>
      <img *ngIf="orderTrackDetails.response.orderInitiated==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
      <img *ngIf="orderTrackDetails.response.orderInitiated==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
    </span>
    <span style="margin-bottom: 10px;"><b>Order Initiated</b></span><br>
    <span style="color: red;">
      Order Cancelled
    </span>  
  </div>
  <div class="item item-8" style="margin-left: -40px;">
    <b><hr width="80px" color="pink"></b>
  </div>
  <div class="item item-9" style="margin-left: 15px;">
    <span>
      <img *ngIf="orderTrackDetails.response.refillOtp==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
      <img *ngIf="orderTrackDetails.response.refillOtp==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
    </span>
    <span style="margin-bottom: 10px;"><b>Refill OTP Verified</b></span><br>
    <span style="color: red;">
      Order Cancelled
    </span>  
  </div>
  <div class="item item-10" style="margin-left: -60px;">
    <b><hr width="80px" color="pink"></b>
  </div>
  <div class="item item-11" style="margin-left: 15px;">
    <span>
      <img *ngIf="orderTrackDetails.response.endOrderOtp==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
      <img *ngIf="orderTrackDetails.response.endOrderOtp==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
    </span>
    <span style="margin-bottom: 10px;"><b>END Order OTP Verified</b></span><br>
    <span style="color: red;">
      Order Cancelled
    </span>  
  </div>
  <div class="item item-12" style="margin-left: -80px;">
    <b><hr width="80px" color="pink"></b>
  </div>
  <div class="item item-13" style="margin-left: 15px;">
    <span>
      <img *ngIf="orderTrackDetails.response.orderCompleted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
      <img *ngIf="orderTrackDetails.response.orderCompleted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
    </span>
    <span style="margin-bottom: 10px;"><b>Order Completed</b></span><br>
    <span style="color: red;">
      Order Cancelled
    </span>  
  </div>
  <div class="item item-14" style="margin-left: -40px;">
    <b><hr width="60px" color="pink"></b>
  </div>
  <div class="item item-14" style="margin-left: 15px;">
    <span>
      <img src="assets/img/remove.svg" alt="logo" data-src="assets/img/remove.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
    </span>
    <span style="margin-bottom: 10px;"><b>Order Cancelled</b></span><br>
    <span *ngIf="orderTrackDetails.response.orderPlacedDateTime!=null" style="text-align: center;">
      {{orderTrackDetails.response.orderCancelledDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
    </span>  
  </div>
    </div>
  
  <hr *ngIf="orderDetails.response.status!=='Cancelled'" class="beautiful-line">
  <div *ngIf="orderDetails.response.status!=='Cancelled'"class="container"
  fxLayout
>
<div class="item item-1" style="margin-left: 20px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderPlaced==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderPlaced==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Placed</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderPlacedDateTime!=null" style="text-align: center;">
    {{orderTrackDetails.response.orderPlacedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderPlacedDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-2" style="margin-left: -80px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-3" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderAccepted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderAccepted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>    
  </span>
  <span style="margin-bottom: 10px;"><b>Agent Accepted</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderAcceptedDateTime!=null">
    {{orderTrackDetails.response.orderAcceptedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderAcceptedDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-4" style="margin-left: -50px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-5" style="margin-left: 15px;">
  <img *ngIf="orderTrackDetails.response.paymentCompleted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.paymentCompleted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>    
    <span style="margin-bottom: 10px;"><b>Payment Completed</b></span><br>
    <span *ngIf="orderTrackDetails.response.paymentCompletedDateTime!=null">
      {{orderTrackDetails.response.paymentCompletedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
    </span>
    <span *ngIf="orderTrackDetails.response.paymentCompletedDateTime==null">
      Will be Updated
    </span>    
</div>
<div class="item item-6" style="margin-left: -60px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-7" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderInitiated==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderInitiated==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Initiated</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderInitiatedDateTime!=null">
    {{orderTrackDetails.response.orderInitiatedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderInitiatedDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-8" style="margin-left: -40px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-9" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.refillOtp==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.refillOtp==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Refill OTP Verified</b></span><br>
  <span *ngIf="orderTrackDetails.response.refillDateTime!=null">
    {{orderTrackDetails.response.refillDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.refillDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-10" style="margin-left: -60px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-11" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.endOrderOtp==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.endOrderOtp==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>END Order OTP Verified</b></span><br>
  <span *ngIf="orderTrackDetails.response.endOrderDateTime!=null">
    {{orderTrackDetails.response.endOrderDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.endOrderDateTime==null">
    Will be Updated
  </span>
</div>
<div class="item item-12" style="margin-left: -80px;">
  <b><hr width="80px" color="pink"></b>
</div>
<div class="item item-13" style="margin-left: 15px;">
  <span>
    <img *ngIf="orderTrackDetails.response.orderCompleted==0" src="assets/img/information.svg" alt="logo" data-src="assets/img/information.svg" data-src-retina="assets/img/information.svg" style="width: 30px;height: 30px; margin-bottom: 10px;">
    <img *ngIf="orderTrackDetails.response.orderCompleted==1" src="assets/img/check.svg" alt="logo" data-src="assets/img/check.svg" data-src-retina="assets/img/check.svg" style="width: 30px;height: 30px; margin-bottom: 10px;"><br>
  </span>
  <span style="margin-bottom: 10px;"><b>Order Completed</b></span><br>
  <span *ngIf="orderTrackDetails.response.orderCompletedDateTime!=null">
    {{orderTrackDetails.response.orderCompletedDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}
  </span>
  <span *ngIf="orderTrackDetails.response.orderCompletedDateTime==null">
    Will be Updated
  </span>
</div>
  </div> 
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
  </div>
</ng-template>

<ng-template #content1 let-modal>
  <div class="modal-header">
    <h5>Assign Vehicle</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="container"
  fxLayout
>

<div class="item item-1">
  <p><b>Order ID</b></p>
  <span>{{orderDetails.response.orderId}}</span>
</div>
<div class="item item-2" style="margin-left: 25px;">
  <p><b>Customer Name</b></p>
  <span style="word-wrap: break-word;">{{orderDetails.response.customerId.firstName}} {{orderDetails.response.customerId.lastName}}</span>
</div>
<div class="item item-3" style="margin-left: 25px;">
  <p><b>Customer Mobile</b></p>
  <span>{{orderDetails.response.customerId.mobileNumber}}</span>
</div>
<div class="item item-4" style="margin-left: 25px;">
  <p><b>Status</b></p>
  <span *ngIf="orderDetails.response.status==='Pending'"><span class="ht-Pending"><b>{{orderDetails.response.status}}</b></span></span>
  <span *ngIf="orderDetails.response.status==='Cancelled'"><span style="color: red;"><b>{{orderDetails.response.status}}</b></span></span>
  <span *ngIf="orderDetails.response.status==='Inprogress'"><span style="color: blue;"><b>{{orderDetails.response.status}}</b></span></span>
  <span *ngIf="orderDetails.response.status==='Completed'"><span class="ht-active"><b>{{orderDetails.response.status}}</b></span></span>
</div>
<div class="item item-5" style="margin-left: 25px;">
  <p><b>Booking Date & Time</b></p>
  <span>{{orderDetails.response.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span>
</div>
<div class="item item-6" style="margin-left: 25px;">
  <p><b>Delivery Date & Time</b></p>
  <span>N/A</span>
</div>
<div class="item item-7" style="margin-left: 25px;">
  <p><b>Fuel</b></p>
  <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.fuelType.fuelTypeName}}</span>
      <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
</div>
<div class="item item-8" style="margin-left: 25px;">
  <p><b>Quantity in Ltrs</b></p>
  <span >{{orderDetails.response.quantity}}</span>
</div>
<br>
  </div>
  <br>
  <div class="container"
  fxLayout
>
  <div class="item item-1">
    <p><b>Vehicle Name</b></p>
    <div class="ch-form-group">
      <select id="countryFormControl" value="countryFormControl" class="form-control" #mySelect (change)='onOptionsSelected(mySelect.value)'>
        <option *ngFor="let vehicle of activeVehicle.response" value={{vehicle.vehicleId}}>{{vehicle.vehicleNumber}} ({{vehicle.userId.firstName}} {{vehicle.userId.lastName}})</option>
      </select>
  </div>
  </div>
  <div class="item item-2" style="margin-left: 25px;">
    <p><b>Delivery Agent Mobile</b></p>
    <span *ngIf="check==null">
      <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.userId.firstName}} {{orderDetails.response.vehicleId.userId.lastName}}</span>
      <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
    </span>
    <span *ngIf="check!=null">{{vehicle.userId.mobileNumber}}</span>
  </div>
  <div class="item item-3" style="margin-left: 25px;">
    <p><b>Delivery Agent Email</b></p>
    <span *ngIf="check==null">
      <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.userId.mobileNumber}}</span>
          <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
    </span>
    <span *ngIf="check!=null">{{vehicle.userId.emailAddress}}</span>
  </div>
  <div class="item item-4" style="margin-left: 25px;">
    <p><b>Vehicle Number</b></p>
    <span *ngIf="check==null">{{orderDetails.response.vehicleId.vehicleNumber}}</span>
    <span *ngIf="check!=null">{{vehicle.vehicleNumber}}</span>
  </div>
  <div class="item item-5" style="margin-left: 25px;">
    <p><b>Delivery location</b></p>
    <span>{{orderDetails.response.deliveryAddress}}</span>
  </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="assign()" (click)="modal.close('Save click')">Assign</button>
  </div>
</ng-template>

<ng-template #content5 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Reason for cancellation of Order</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
  <form>
      <br>
      <div class="ch-form-group">
          <select id="cancelmessage"  name="cancelmessage" class="form-control" style="width: 100%;">
            <option *ngFor="let cancel of cancelMessageModel.response" value={{cancel.id}}>{{cancel.cancellationMessage}}</option>
          </select>
      </div>	
      <br>
      <div class="ch-form-group">
           <textarea placeholder="Should not exceed more than 150 characters" class="form-control textarea" name="cancelremarks" maxlength="150" id="cancelremarks" rows="3"></textarea>
      </div>
      <br>
    <b>Note</b> : <span style="color: grey;">Should not exceed more than 150 characters</span> 
      </form>
      <br>
      <div *ngIf="errorFlag1" style="color: red;">{{errorMessage}}</div>
    </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" style="color: green;" (click)="cancelOrder()">Submit</button>
  </div>
</ng-template>

<ng-template #content9 let-modal>
  <div class="modal-header">
    <h6>Assets View Image</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
    <img class="modal-content1"  [src]="imageasset" height="auto" width="auto">
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
    </div>
</ng-template>

<ng-template #content2 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">View Location</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
    </div>
    <hr class="beautiful-line">
  <agm-map [latitude]="lat" [longitude]="lan" style="height: 300px">
    <agm-marker [latitude]="lat" [longitude]="lan"></agm-marker>
  </agm-map>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">close</button>
  </div>
</ng-template>

<ng-template #reject let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Do you want unblock the user?</label>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="unblock()" (click)="modal.close('Save click')">yes</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">no</button>
  </div>
</ng-template>
<ng-template #chargecontent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Charges</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <div class="modal-body master-body">
    <form>
      
	<div class="form-group">
        <label for="dateOfBirth">Set-up Fee</label>
        <input type="text" class="form-control" id="vehicle" name="vehicle" (keypress)="allowNumericDigitsOnlyOnKeyUp($event)"  [(ngModel)]="vehicleFee" placeholder="Vehicle Attachment Fee">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Delivery Charges </label><br/>

        <span *ngIf="deliveryChargeFlag==1"><input type="radio"  name="deliveryChargeFlag" id="deliveryChargeFlag" value="1"   (click)="eventCheck($event)" checked> Based on Km </span> 
        <span *ngIf="deliveryChargeFlag!=1"> <input type="radio" name="deliveryChargeFlag" id="deliveryChargeFlag" value="1"   (click)="eventCheck($event)" [(ngModel)]="deliveryChargeFlag"> Based on Km </span>
 
        <span *ngIf="deliveryChargeFlag==2"><input type="radio" name="deliveryChargeFlag" id="deliveryChargeFlag" value="2" (click)="eventCheck($event)" checked> Fixed Fee</span>
         <span *ngIf="deliveryChargeFlag!=2"><input type="radio"  name="deliveryChargeFlag" id="deliveryChargeFlag" value="2" (click)="eventCheck($event)" [(ngModel)]="deliveryChargeFlag"> Fixed Fee</span>
 
         <span *ngIf="deliveryChargeFlag==3"><input type="radio" name="deliveryChargeFlag" id="deliveryChargeFlag" value="3" (click)="eventCheck($event)" checked> Per Ltr Cost</span> 
         <span *ngIf="deliveryChargeFlag!=3"><input type="radio"  name="deliveryChargeFlag" id="deliveryChargeFlag" value="3" (click)="eventCheck($event)" [(ngModel)]="deliveryChargeFlag"> Per Ltr Cost</span> 

         
      </div>
      <div *ngIf="deliveryChargeFlag==1" class="form-group">
        <label for="dateOfBirth">Delivery Charges Per Km</label>
        <br>
        <!-- <input type="text" class="form-control"  id="deliveryChargePerkm" name="deliveryChargePerkm" [(ngModel)]="currentCharges.deliveryChargePerkm" placeholder="Delivery Charges Per KM"> -->
        Delivery charge for Less than or Equal to <input type="text" (keypress)="allowNumericDigitsOnlyOnKeyUpWithoutdot($event)" class="delivery-box" (change)="updateValue()" id="deliveryLess" name="deliveryLess" [(ngModel)]="deliverless" >    KM will be Rs. <input type="text" (keypress)="allowNumericDigitsOnlyOnKeyUpWithoutdot($event)" class="delivery-box" id="deliverylech" name="deliverylech" [(ngModel)]="deliverlessamt">  <br><br>
        Delivery charge Greater than or Equal to <input type="text" (keypress)="allowNumericDigitsOnlyOnKeyUpWithoutdot($event)" class="delivery-box" id="deliveryGreater" name="deliveryGreater" [(ngModel)]="delivergrea">   KM will be Rs. <input type="text" class="delivery-box" (keypress)="allowNumericDigitsOnlyOnKeyUpWithoutdot($event)" id="deliveryGrch" name="deliveryGrch" [(ngModel)]="delivergreaamt" >   per KM 
      </div>
     
      <div class="form-group" *ngIf="deliveryChargeFlag==2">
        <label for="dateOfBirth">Fixed Fee</label>
        <input type="text" class="form-control" id="deliveryFixedFee"  (keypress)="allowNumericDigitsOnlyOnKeyUp($event)" name="deliveryFixedFee" [(ngModel)]="deliveryFixedFee" placeholder="Fixed Fee">
      </div>

      <div class="form-group" *ngIf="deliveryChargeFlag==3">
        <label for="dateOfBirth">Per Ltr Cost</label>
        <input type="text" class="form-control" id="deliveryPerLtrCost"  (keypress)="allowNumericDigitsOnlyOnKeyUp($event)" name="deliveryPerLtrCost" [(ngModel)]="deliveryPerLtrCost" placeholder="Per Ltr Cost">
      </div>
      
		<div class="form-group">
        <label for="dateOfBirth">Platform Charge</label>
        <input type="text" class="form-control" id="inte"  name="inte" (keypress)="allowNumericDigitsOnlyOnKeyUp($event)" [(ngModel)]="internet" placeholder="Platform Charge">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">MMC Fee</label>
        <input type="text" class="form-control" id="mmcfe"  (keypress)="allowNumericDigitsOnlyOnKeyUp($event)" name="mmcfe" [(ngModel)]="mmc" placeholder="MMC fee">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">AMC Fee</label>
        <input type="text" class="form-control" id="amcfe" (keypress)="allowNumericDigitsOnlyOnKeyUp($event)"  name="amcfe" [(ngModel)]="amc" placeholder="AMC fee">
      </div>
		<div class="form-group">
        <label for="dateOfBirth">SGST%</label>
        <input type="text" class="form-control" id="sgst" name="sgst" (keypress)="allowNumericDigitsOnlyOnKeyUpWithoutdot($event)" [(ngModel)]="sgst"  placeholder="SGST%">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">CGST%</label>
        <input type="text" class="form-control" id="cgst" name="cgst"(keypress)="allowNumericDigitsOnlyOnKeyUpWithoutdot($event)"  [(ngModel)]="cgst"  placeholder="CGST%">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">IGST%</label>
        <input type="text" class="form-control" id="igst" name="igst" (keypress)="allowNumericDigitsOnlyOnKeyUpWithoutdot($event)" [(ngModel)]="igst" placeholder="IGST%">
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Effective From</label>
        <input type="datetime-local" class="form-control" id="effedate" name="effedate" aria-describedby="emailHelp" placeholder="Select Role" required>
      </div>
      <br>
      <label for="exampleInputEmail1">Settings</label>
      <div class="form-group">
        <label for="dateOfBirth">Minimum Quantity</label>
        <input type="text" class="form-control" id="minquan" name="minquan" (keypress)="allowNumericDigitsOnlyOnKeyUpWithoutdot($event)" [(ngModel)]="minquan"  placeholder="Minimum Quantity">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Maximum Quantity</label>
        <input type="text" class="form-control" id="maxquan" name="maxquan" (keypress)="allowNumericDigitsOnlyOnKeyUpWithoutdot($event)" [(ngModel)]="maxquan" placeholder="Maximum Quantity">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Asset Subscription Fee</label>
        <input type="text" class="form-control" id="assetSubscriptionFee" name="assetSubscriptionFee" (keypress)="allowNumericDigitsOnlyOnKeyUp($event)" [(ngModel)]="assetSubscriptionFee" placeholder="Asset Subscription Fee">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Asset Setup Fee</label>
        <input type="text" class="form-control" id="assetSetupFee" name="assetSetupFee" (keypress)="allowNumericDigitsOnlyOnKeyUp($event)" [(ngModel)]="assetSetupFee" placeholder="Asset Setup Fee">
      </div>
      <br>
      <div *ngIf="errorFlag">
        <div class="error-style">{{errorMessage}}</div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="addCharges()">Update Charges</button>
    <!-- <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">no</button> -->
  </div>
</ng-template>

<ng-template #content10 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Assign Partner</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
  <form>
      <br>
      <div class="form-group" *ngFor="let user of partnerModel.response; let i=index">
        <input  type="checkbox" value=" {{user.userId}}" (change)="assignPartner(user.userId,$event)">
        {{user.firstName}} {{user.lastName}}
      </div>
    
      </form>
      <br>
      <div style="color: red;">{{errorMessage}}</div>
    </div>
    <hr class="beautiful-line">
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" style="color: green;" (click)="submit()">Submit</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">close</button>
  </div>
</ng-template>
<ng-template #content11 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">View Assigned Partners</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body" >
  <form>
      <div class="form-group" *ngFor="let partner of assignedPartnerModel.response.list; let i=index">
        {{i + 1}}. &nbsp;{{partner.partnerId.firstName}} {{partner.partnerId.lastName}} [{{partner.partnerId.userCode}}]
      </div>
      <br>
  
      </form>
      <br>
    
    </div>
    <hr class="beautiful-line">
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" style="color: green;" (click)="editContent(content12,assignedPartnerModel,editUserId)">Edit</button>
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">close</button>
    </div>
</ng-template>
<ng-template #content12 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Partner</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
  <form>
      <br>
      <div class="form-group" *ngFor="let user of partnerModel.response.list; let i=index">
        <span  *ngIf="user.flag==1">
          <input  type="checkbox" value=" {{user.userId}}" (change)="assignPartner(user.userId,$event)" checked="checked">
        </span>
        <span  *ngIf="user.flag==0">
          <input  type="checkbox" value=" {{user.userId}}" (change)="assignPartner(user.userId,$event)" >
        </span>
        {{user.firstName}} {{user.lastName}}
      </div>
    
      </form>
      <br>
      <div style="color: red;">{{errorMessage}}</div>
    </div>
    <hr class="beautiful-line">
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" style="color: green;" (click)="updatepartner(editUserId)">Update</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">close</button>
  </div>
</ng-template>

<ng-template #content13 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Payment Mode</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
  <form>
      <br>
      <label >Payment Mode</label>
      <div class="form-group" *ngFor="let paymentmode of paymentMode.response; let i=index">
        <span  *ngIf="paymentmode.flag==1">
          <input  type="checkbox" value="{{paymentmode.id}}" (change)="assignMode(paymentmode.id,$event)" checked="checked">
        </span>
        <span >
          <input  type="checkbox" value=" {{paymentmode.id}}" (change)="assignMode(paymentmode.id,$event)" >
        </span>
        {{paymentmode.paymentMode}}
      </div>
      <div class="form-group" *ngIf="partnerCredit">
        <label for="dateOfBirth">Pay Later Days</label>
        <input type="text" class="form-control" id="payLaterDays" name="payLaterDays" (keypress)="allowNumericDigitsOnlyOnKeyUpWithoutdot($event)" [(ngModel)]="payLaterDays"  placeholder="Pay Later Days">
      </div>
      <div class="form-group" *ngIf="partnerCredit">
        <label for="dateOfBirth">Maximum Credit Limits</label>
        <input type="text" class="form-control" id="maxCreditLimits" name="maxCreditLimits" (keypress)="allowNumericDigitsOnlyOnKeyUpWithoutdot($event)" [(ngModel)]="maxCreditLimits"  placeholder="Maximum Credit Limits">
      </div>
      </form>
      <br>
      <div style="color: red;">{{errorMessage}}</div>
    </div>
    <hr class="beautiful-line">
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" style="color: green;" (click)="addPaymentMode(editUserId)">Update</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">close</button>
  </div>
</ng-template>

<ng-template #content14 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Assigned Payment Mode</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
    <form>
      <div class="form-group" *ngFor="let mode of assignedPaymentMode.response; let i=index">
        {{i + 1}}. &nbsp;{{mode.paymentModeName}} 
        <span style="font-size:10px;" *ngIf="mode.paymentModeId==4">
         <br/> <b style="margin-left:4%;">Pay Later Days: </b>{{mode.payLaterDays}}
          <br/><b  style="margin-left:4%;"> Max Credit Limits:</b>{{mode.maxCreditLimits}}
         </span>
      </div>
      <br>
  
      </form>
     
    </div>
    <hr class="beautiful-line">
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" style="color: green;" (click)="editPaymentMode(content15,assignedPaymentMode,editUserId)">Edit</button>

    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">close</button>
  </div>
</ng-template>

<ng-template #content15 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Payment Mode</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
  <form>
      <br>
      <label >Payment Mode</label>
      <div class="form-group" *ngFor="let paymentmode of paymentMode.response; let i=index">
        <span  *ngIf="paymentmode.status==1">
          <input  type="checkbox" value="{{paymentmode.id}}" (change)="assignMode(paymentmode.id,$event)" checked="checked">
        </span>
        <span  *ngIf="paymentmode.status==0">
          <input  type="checkbox" value=" {{paymentmode.id}}" (change)="assignMode(paymentmode.id,$event)" >
        </span>
        {{paymentmode.paymentMode}}
      </div>
      <div *ngIf="partnerCredit==false" >
          <div *ngFor="let mode of assignedPaymentMode.response; let i=index">
            <div class="form-group" *ngIf="mode.paymentModeId==4 " >
              <label for="dateOfBirth">Pay Later Days </label>
              <input type="text" class="form-control" id="payLaterDays" name="payLaterDays" (keypress)="allowNumericDigitsOnlyOnKeyUpWithoutdot($event)" value="{{mode.payLaterDays}}"  placeholder="Pay Later Days">
            </div>
            <div class="form-group" *ngIf="mode.paymentModeId==4" >
              <label for="dateOfBirth">Maximum Credit Limits </label>
              <input type="text" class="form-control" id="maxCreditLimits" name="maxCreditLimits" (keypress)="allowNumericDigitsOnlyOnKeyUpWithoutdot($event)" [(ngModel)]="mode.maxCreditLimits"  placeholder="Maximum Credit Limits">
            </div>
        </div>
      </div>
    <div class="form-group" *ngIf="partnerCredit" >
      <label for="dateOfBirth">Pay Later Days </label>
      <input type="text" class="form-control" id="payLaterDays" name="payLaterDays" (keypress)="allowNumericDigitsOnlyOnKeyUpWithoutdot($event)" value="{{mode.payLaterDays}}"  placeholder="Pay Later Days">
    </div>
    <div class="form-group" *ngIf="partnerCredit" >
      <label for="dateOfBirth">Maximum Credit Limits </label>
      <input type="text" class="form-control" id="maxCreditLimits" name="maxCreditLimits" (keypress)="allowNumericDigitsOnlyOnKeyUpWithoutdot($event)" [(ngModel)]="mode.maxCreditLimits"  placeholder="Maximum Credit Limits">
    </div>
   
      </form>
      <br>
      <div style="color: red;">{{errorMessage}}</div>
    </div>
    <hr class="beautiful-line">
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" style="color: green;" (click)="updatePaymentMode(editUserId)">Update</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">close</button>
  </div>
</ng-template>
