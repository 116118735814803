import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { RestResponseModel } from '../model/response';
import { BankMasterModel } from '../model/bankmaster';
import { environment } from 'src/environments/environment';
import { ChargesModel } from '../model/charges';
import { GenericResponseModel } from '../model/genricresponse';

@Injectable({
    providedIn: "root"
  })
  export class ChargesService {

    constructor(private httpClient:HttpClient) {}

  private baseUrl = environment.devurl;
  private auth = environment.headers;

    public getAllCharges(){
      // const headers = this.auth;
      let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
      const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.get<ChargesModel[]>(this.baseUrl+"/api/getAllCharges",{headers});
  }

  public deleteCharges(id){
  // const headers = this.auth;
  let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
  const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.delete<RestResponseModel>(this.baseUrl+"/api/deleteCharges"+"/"+id,{headers});
  }

  public getCurrentCharges(){
   // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<ChargesModel>(this.baseUrl+"/api/currentcharges",{headers});
}

  public addCharges(charges){
    // const headers = this.auth;
    let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
    const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
      return this.httpClient.post<RestResponseModel>(this.baseUrl+"/api/addCharges",charges,{headers});
  }
  

  }