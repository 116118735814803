import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-Declaration',
  templateUrl: './complaint-policy.component.html',
  styleUrls: ['./complaint-policy.component.css']
})



export class ComplaintPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
