import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GenericResponseModel } from '../model/genricresponse';
import { UserModel } from '../model/user';
import { PartnersService } from '../services/partners.service';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { Workbook } from 'exceljs';


@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css']
})
export class PartnersComponent implements OnInit {

  partnersResponseModel:GenericResponseModel;
  excelexportflag:boolean;
  responseDataListnew = [];
  constructor(private router: Router,
    private partners: PartnersService) { }

  ngOnInit(): void {
    this.partners.getAllPartners().subscribe(data=>{
      if(data.statusCode==200){
        this.excelexportflag  = true;
      }else{
        this.excelexportflag  = false;
      }
      this.partnersResponseModel=data;
    },err => {
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }else if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });
  }

  viewPartners(user:UserModel){
    sessionStorage.setItem("patId",String(user.userId));
    this.router.navigate(['/partners-view-page']);
  }

  excelexport(){
    let sno = 1;
    this.responseDataListnew = [];
    this.partnersResponseModel.response.forEach(element => {
      let date = element['createdDatetime'];
      let convertedDate;
      if(date==null || date==='null'){
        convertedDate = "N/A";
      }else{
        let dates = new Date(element['createdDatetime']);
        convertedDate = moment(new Date(dates)).format("DD-MM-yyyy hh:mm:ss a").toString();
      }
      var response = [];
      response.push(sno);
      response.push(element['firstName'] +' '+ element['lastName']);
      response.push(element['mobileNumber']);
      response.push(element['emailAddress']);
      let otpstatus = element['otpVerificationStatus'];
      if(otpstatus==1){
        response.push('Success');
      }else if(otpstatus==0){
        response.push('Pending');
      }
      let activestatus = element['accountStatus'];
      if(activestatus==1){
        response.push('Active');
      }else if(activestatus==2){
        response.push('InActive');
      }
      response.push(convertedDate);
      this.responseDataListnew.push(response);
      sno++;
    });
    this.excelexportPartner();
}

excelexportPartner(){
  const title = 'Partners Report';
  const header = ["S.No","Partner Name", "Mobile Number", "Email Id","OTP Verification Status","Account Status","Onboarded Date Time"]
  const data = this.responseDataListnew;
  let workbook = new Workbook();
  let worksheet = workbook.addWorksheet('Partners Report');
// Add new row
let titleRow = worksheet.addRow([title]);
// Set font, size and style in title row.
titleRow.font = { name: 'Times New Roman', family: 4, size: 16, bold: true };

// Blank Row
worksheet.addRow([]);

//Add row with current date
// let subTitleRow = worksheet.addRow(['Date : ' + this.datePipe.transform(new Date(), 'medium')]);
//Add Header Row
let headerRow = worksheet.addRow(header);
headerRow.font = {bold: true };
// Cell Style : Fill and Border
headerRow.eachCell((cell, number) => {
cell.fill = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: 'FFFFFFFF' },
  bgColor: { argb: 'FF0000FF' },
}
cell.border = {  top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
});

data.forEach(d => {
let row = worksheet.addRow(d);
let qty = row.getCell(1);
let qty1 = row.getCell(2);
let qty2 = row.getCell(3);
let qty3 = row.getCell(4);
let qty4 = row.getCell(5);
let qty5 = row.getCell(6);
let qty6 = row.getCell(7);
qty.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
qty1.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
qty2.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
qty3.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
qty4.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
qty5.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
qty6.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
}
);
workbook.xlsx.writeBuffer().then((data) => {
let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
FileSaver.saveAs(blob, 'Partners Report.xlsx');
});
 }

}
