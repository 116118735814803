<app-header></app-header>

<div class="container top-mar main-argin-adj">
  <a href="#" routerLink="/user-management" class="back_page"><span class="label label-info"><i class="back_page"></i>Back</span></a>
  <br>
  <br>
<div class="card">
  <div class="card-header">
    Add User
  </div>
  
  <div class="card-body">
    <div class="alert alert-danger"
                                        *ngIf="mandetoryflag">{{message}}</div>
    <form [formGroup]="userForm">
      <div class="ch-colthree">
      <!-- <div class="ch-form-group">
        <label for="exampleInputEmail1">Select Department Cinch</label>
        <select class="custom-select">
          <option>Select Department</option>
        </select>
      </div> -->
      
      <div class="ch-form-group">
        <label for="exampleInputEmail1">Select Role</label>
        <span class="required-asterisk"> *</span>
        <div class="dropdown">
          <select id="role"  name="role" class="form-control">
            <option *ngFor="let roles of roleDetails" value={{roles.roleId}}>{{roles.roleName}}</option>
          </select>
          </div>
      </div>
      <div class="ch-form-group">
        <label for="exampleInputEmail1">User Name</label>
        <span class="required-asterisk"> *</span>
        <input type="text" class="form-control" id="username" formControlName="username" name="username" maxlength="15" aria-describedby="emailHelp" placeholder="Enter user name" required>
      </div>
      <!-- <div class="ch-form-group">
        <label for="exampleInputEmail1">Employee ID</label>
          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email address">
      </div> -->
      <div class="ch-form-group">
        <label for="exampleInputEmail1">Email address</label>
        <span class="required-asterisk"> *</span>
        <input type="email" class="form-control" id="email" formControlName="email" maxlength="60" name="email" aria-describedby="emailHelp" placeholder="Enter email address" required>
      </div>
      <div class="ch-form-group">
        <label for="exampleInputEmail1">Mobile Number</label>
        <span class="required-asterisk"> *</span>
       <input type="text" class="form-control" (keypress)="allowNumericDigitsOnlyOnKeyUp($event)" formControlName="mobile" id="mobile" maxlength="10" name="mobile" autocomplete="off"  aria-describedby="emailHelp" placeholder="Enter Mobile Number" required>
      </div>
      <div class="ch-form-group">
        <label for="exampleInputEmail1">Date of Joining</label>
        <span class="required-asterisk"> *</span>
        <input type="date" class="form-control" id="date" formControlName="date" name="date" aria-describedby="emailHelp" placeholder="Select Role" required>
      </div>
    </div>

      <div class="card-footer  top-margin-20">
        <div class="btn-wraper">
        <button type="submit" (click)="addUser()" class="btn save-btn">Save</button> 
        <button type="submit" (click)="clear()" class="btn reset-btn">Reset</button> 
        <button type="submit" routerLink="/user-management" class="btn cancel-btn">Cancel</button>
        </div>
  </div>
     
    </form>
  </div>
</div>
</div>