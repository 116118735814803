<app-header></app-header>

<!-- START PAGE-CONTAINER -->
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
  </ngx-spinner> 
<div class="page-container "> 
  <!-- START PAGE CONTENT WRAPPER -->
  <div class="page-content-wrapper "> 
    <!-- START PAGE CONTENT -->
    <div class="content sm-gutter"> 
      <!-- START BREADCRUMBS -->
      <div class="bg-white">
        <div class="container">
          <ol class="breadcrumb breadcrumb-alt">
            <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Home</a></li>
            <li class="breadcrumb-item active"><span class="font-montserrat fs-11 all-caps">Revenue Report</span></li>
          </ol>
        </div>
      </div>

      <!-- START CONTAINER FLUID -->
      <div *ngIf="normalrevenue" class=" no-padding    container-fixed-lg bg-white">
        <div class="container"> 
          <label ><b>Search By Settled Date & Time</b></label>  
        <input type="date" style="margin-left: 10px; margin-top: 5px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
        <input type="date" style="margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
      <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
      <button title="Refresh" *ngIf="refreshflag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
      <span *ngIf="errorFlag" style="color: red;">{{errorMessage}}</span>
      <button title="Excel Export" *ngIf="excelexportcheckrevenue" (click)="exportToExcelRevenue()" style="float: right; margin-top: 5px;" title="Excel Export" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
      <!-- <div *ngIf="errorFlag" style="color: red; margin-left: 20px;">{{errorMessage}}</div> -->
          <!-- START card -->
          <div class="card card-transparent">
            
            <div class="card-body">
                <div style="display: none;">{{revenuereportModel.responseMessage}}</div>
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th >Sl.No.</th>
                      <th>Order ID</th>
                      <th style="width: 150px;">Customer Info</th>
                      <th style="width: 150px;">Partner Info</th>
                      <th >Fuel</th>
                      <th >Quantity</th>
                      <th style="width: 100px;">Fuel Price</th>
                      <th style="width: 100px;">Delivery Charge</th>
                      <th style="width: 100px;">Tax</th>
                      <th style="width: 100px;">Platform Charge</th>
                      <th style="width: 100px;">Total Charge</th>
                      <th style="width: 135px;">Settled Date & Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let revenue of revenuereportModel.response.revenuelist; let i=index">
                      <td align="center" class="v-align-middle "><p>{{i + 1}}</p></td>
                      <td *ngIf="revenue.orderId!=null"align="left" class="v-align-middle"><p>{{revenue.orderId}}</p></td>
                      <td *ngIf="revenue.orderId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                      <td *ngIf="revenue.customerId!=null"align="left" class="v-align-middle">
                          <p style="font-size: 12px;"><b>Name :</b> {{revenue.customerId.firstName}} {{revenue.customerId.lastName}} </p>
                          <p style="font-size: 12px;"><b>Mobile :</b> {{revenue.customerId.mobileNumber}}</p>
                        </td>
                      <td *ngIf="revenue.customerId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                      <td *ngIf="revenue.vehicleId!=null"align="left" class="v-align-middle">
                          <p style="font-size: 12px;"><b>Name :</b> {{revenue.vehicleId.userId.firstName}} {{revenue.vehicleId.userId.lastName}}</p>
                          <p style="font-size: 12px;"><b>Mobile :</b> {{revenue.vehicleId.userId.mobileNumber}}</p>
                        </td>
                      <td *ngIf="revenue.vehicleId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                      <td *ngIf="revenue.vehicleId!=null"align="left" class="v-align-middle">{{revenue.vehicleId.fuelType.fuelTypeName}}</td>
                      <td *ngIf="revenue.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="revenue.quantity!=null"align="center" class="v-align-middle">{{revenue.quantity}}</td>
                      <td *ngIf="revenue.quantity==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="revenue.totalfuelcost!=null"align="center" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{revenue.totalfuelcost | number : '1.2-2'}}</td>
                      <td *ngIf="revenue.totalfuelcost==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="revenue.deliveryCharges!=null" align="left" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{revenue.deliveryCharges | number : '1.2-2' }}</td>
                      <td *ngIf="revenue.deliveryCharges==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="revenue.tax!=null" align="left" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{revenue.tax | number : '1.2-2'}}</td>
                      <td *ngIf="revenue.tax==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="revenue.internetHandlingFee!=null"class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{revenue.internetHandlingFee | number : '1.2-2'}}</td>
                      <td *ngIf="revenue.internetHandlingFee==null"class="v-align-middle">N/A</td>
                      <td *ngIf="revenue.totalPayableAmount!=null "class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{revenue.totalPayableAmount | number : '1.2-2' }}</td>
                      <td *ngIf="revenue.totalPayableAmount==null" class="v-align-middle">N/A</td>
                      <td *ngIf="revenue.settledDatetime!=null "class="v-align-middle">{{revenue.settledDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="revenue.settledDatetime==null "class="v-align-middle">N/A</td>
                    </tr>
                  </tbody>
                  <tfoot *ngIf="revenuereportModel.response.statusCode==200">
                    <tr>
                      <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total </td>
                        <td>
                          <span *ngIf="revenuereportModel.response.totalSumFuelPrize!=null" style="width: 100px;"><i class="fa fa-inr" aria-hidden="true"></i> {{revenuereportModel.response.totalSumFuelPrize | number : '1.2-2'}}</span>
                          <span *ngIf="revenuereportModel.response.totalSumFuelPrize==null"><i class="fa fa-inr" aria-hidden="true"></i> 0.00</span>
                        </td>
                        <td>
                          <span *ngIf="revenuereportModel.response.totalSumDeliveryCharge!=null" style="width: 100px;"><i class="fa fa-inr" aria-hidden="true"></i> {{revenuereportModel.response.totalSumDeliveryCharge | number : '1.2-2'}}</span>
                          <span *ngIf="revenuereportModel.response.totalSumDeliveryCharge==null"><i class="fa fa-inr" aria-hidden="true"></i> 0.00</span>
                        </td>
                        <td>
                          <span *ngIf="revenuereportModel.response.totalSumTax!=null" style="width: 100px;"><i class="fa fa-inr" aria-hidden="true"></i> {{revenuereportModel.response.totalSumTax | number : '1.2-2'}}</span>
                          <span *ngIf="revenuereportModel.response.totalSumTax==null"><i class="fa fa-inr" aria-hidden="true"></i> 0.00</span>
                        </td>
                        <td>
                          <span *ngIf="revenuereportModel.response.totalSumPlatformCharge!=null" style="width: 100px;"><i class="fa fa-inr" aria-hidden="true"></i> {{revenuereportModel.response.totalSumPlatformCharge | number : '1.2-2'}}</span>
                          <span *ngIf="revenuereportModel.response.totalSumPlatformCharge==null"><i class="fa fa-inr" aria-hidden="true"></i> 0.00}</span>
                        </td>
                        <td>
                          <span *ngIf="revenuereportModel.response.totalSumPayoutAmount!=null" style="width: 100px;"><i class="fa fa-inr" aria-hidden="true"></i> {{revenuereportModel.response.totalSumPayoutAmount | number : '1.2-2'}}</span>
                          <span *ngIf="revenuereportModel.response.totalSumPayoutAmount==null"><i class="fa fa-inr" aria-hidden="true"></i> 0.00</span>
                        </td>
                        <td></td>
                    </tr>
                </tfoot>
                </table>
              </div>
            </div>
          </div>
          <!-- END card --> 
        </div>
      </div>

      <!-- START CONTAINER FLUID -->
      <div *ngIf="filterrevenue" class=" no-padding    container-fixed-lg bg-white">
        <div *ngIf="filteronesearch">
          <div style="display: none;">{{revenuefilterreportModel.responseMessage}}</div>
          <div class="container"> 
            <label ><b>Search By Settled Date & Time</b></label>  
          <input type="date" style="margin-left: 10px; margin-top: 5px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
          <input type="date" style="margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
        <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
        <button title="Refresh" *ngIf="refreshflag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
        <span *ngIf="errorFlag" style="color: red;">{{errorMessage}}</span>
        <button title="Excel Export" *ngIf="excelexportcheckrevenue" (click)="exportToExcelFilterRevenue()" style="float: right; margin-top: 5px;" title="Excel Export" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
            <!-- START card -->
            <div class="card card-transparent">
               
              <div class="card-body">
                <div class="table-responsive">
                  <table datatable class="ch-table">
                    <thead>
                      <tr> 
                        <th >Sl.No.</th>
                        <th>Order ID</th>
                        <th style="width: 150px;">Customer Info</th>
                        <th style="width: 150px;">Partner Info</th>
                        <th >Fuel</th>
                        <th >Quantity</th>
                        <th style="width: 100px;">Fuel Price</th>
                        <th style="width: 100px;">Delivery Charge</th>
                        <th style="width: 100px;">Tax</th>
                        <th style="width: 100px;">Platform Charge</th>
                        <th style="width: 100px;">Total Charge</th>
                        <th style="width: 135px;">Settled Date & Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let revenue of revenuefilterreportModel.response.revenuelist; let i=index">
                        <td align="center" class="v-align-middle "><p>{{i + 1}}</p></td>
                        <td *ngIf="revenue.orderId!=null"align="left" class="v-align-middle"><p>{{revenue.orderId}}</p></td>
                        <td *ngIf="revenue.orderId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                        <td *ngIf="revenue.customerId!=null"align="left" class="v-align-middle">
                            <p style="font-size: 12px;"><b>Name :</b> {{revenue.customerId.firstName}} {{revenue.customerId.lastName}} </p>
                            <p style="font-size: 12px;"><b>Mobile :</b> {{revenue.customerId.mobileNumber}}</p>
                          </td>
                        <td *ngIf="revenue.customerId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                        <td *ngIf="revenue.vehicleId!=null"align="left" class="v-align-middle">
                            <p style="font-size: 12px;"><b>Name :</b> {{revenue.vehicleId.userId.firstName}} {{revenue.vehicleId.userId.lastName}}</p>
                            <p style="font-size: 12px;"><b>Mobile :</b> {{revenue.vehicleId.userId.mobileNumber}}</p>
                          </td>
                        <td *ngIf="revenue.vehicleId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                        <td *ngIf="revenue.vehicleId!=null"align="left" class="v-align-middle">{{revenue.vehicleId.fuelType.fuelTypeName}}</td>
                        <td *ngIf="revenue.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="revenue.quantity!=null"align="center" class="v-align-middle">{{revenue.quantity}}</td>
                        <td *ngIf="revenue.quantity==null"align="center" class="v-align-middle">N/A</td>
                        <td *ngIf="revenue.totalfuelcost!=null"align="center" class="v-align-middle">
                          <span><i class="fa fa-inr" aria-hidden="true"></i> {{revenue.totalfuelcost | number : '1.2-2'}}</span>
                        </td>
                        <td *ngIf="revenue.totalfuelcost==null"align="center" class="v-align-middle">N/A</td>
                        <td *ngIf="revenue.deliveryCharges!=null" align="left" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{revenue.deliveryCharges | number : '1.2-2' }}</td>
                        <td *ngIf="revenue.deliveryCharges==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="revenue.tax!=null" align="left" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{revenue.tax | number : '1.2-2'}}</td>
                        <td *ngIf="revenue.tax==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="revenue.internetHandlingFee!=null"class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{revenue.internetHandlingFee | number : '1.2-2'}}</td>
                        <td *ngIf="revenue.internetHandlingFee==null"class="v-align-middle">N/A</td>
                        <td *ngIf="revenue.totalPayableAmount!=null "class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{revenue.totalPayableAmount | number : '1.2-2' }}</td>
                        <td *ngIf="revenue.totalPayableAmount==null" class="v-align-middle">N/A</td>
                        <td *ngIf="revenue.settledDatetime!=null "class="v-align-middle">{{revenue.settledDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="revenue.settledDatetime==null "class="v-align-middle">N/A</td>
                      </tr>
                    </tbody>
                    <tfoot *ngIf="revenuefilterreportModel.response.statusCode==200">
                      <tr>
                        <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>Total </td>
                          <td>
                            <span *ngIf="revenuefilterreportModel.response.totalSumFuelPrize!=null" style="width: 100px;"><i class="fa fa-inr" aria-hidden="true"></i> {{revenuefilterreportModel.response.totalSumFuelPrize | number : '1.2-2'}}</span>
                            <span *ngIf="revenuefilterreportModel.response.totalSumFuelPrize==null"><i class="fa fa-inr" aria-hidden="true"></i> 0.00</span>
                          </td>
                          <td>
                            <span *ngIf="revenuefilterreportModel.response.totalSumDeliveryCharge!=null" style="width: 100px;"><i class="fa fa-inr" aria-hidden="true"></i> {{revenuefilterreportModel.response.totalSumDeliveryCharge | number : '1.2-2'}}</span>
                            <span *ngIf="revenuefilterreportModel.response.totalSumDeliveryCharge==null"><i class="fa fa-inr" aria-hidden="true"></i> 0.00</span>
                          </td>
                          <td>
                            <span *ngIf="revenuefilterreportModel.response.totalSumTax!=null" style="width: 100px;"><i class="fa fa-inr" aria-hidden="true"></i> {{revenuefilterreportModel.response.totalSumTax | number : '1.2-2'}}</span>
                            <span *ngIf="revenuefilterreportModel.response.totalSumTax==null"><i class="fa fa-inr" aria-hidden="true"></i> 0.00</span>
                          </td>
                          <td>
                            <span *ngIf="revenuefilterreportModel.response.totalSumPlatformCharge!=null" style="width: 100px;"><i class="fa fa-inr" aria-hidden="true"></i> {{revenuefilterreportModel.response.totalSumPlatformCharge | number : '1.2-2'}}</span>
                            <span *ngIf="revenuefilterreportModel.response.totalSumPlatformCharge==null"><i class="fa fa-inr" aria-hidden="true"></i> 0.00}</span>
                          </td>
                          <td>
                            <span *ngIf="revenuefilterreportModel.response.totalSumPayoutAmount!=null" style="width: 100px;"><i class="fa fa-inr" aria-hidden="true"></i> {{revenuefilterreportModel.response.totalSumPayoutAmount | number : '1.2-2'}}</span>
                            <span *ngIf="revenuefilterreportModel.response.totalSumPayoutAmount==null"><i class="fa fa-inr" aria-hidden="true"></i> 0.00</span>
                          </td>
                          <td></td>
                      </tr>
                  </tfoot>
                  </table>
                </div>
              </div>
            </div>
            <!-- END card --> 
          </div>
        </div>
        <div *ngIf="!filteronesearch">
          <div style="display: none;">{{revenueFilterNoRecordModel.responseMessage}</div>
          <div class="container"> 
            <label ><b>Search By Settled Date & Time</b></label>  
          <input type="date" style="margin-left: 10px; margin-top: 5px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
          <input type="date" style="margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
        <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
        <button title="Refresh" *ngIf="refreshflag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
        <span *ngIf="errorFlag" style="color: red;">{{errorMessage}}</span>
        <button title="Excel Export" *ngIf="excelexportcheckrevenue" (click)="exportToExcelFilterRevenue()" style="float: right; margin-top: 5px;" title="Excel Export" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
            <!-- START card -->
            <div class="card card-transparent">
               
              <div class="card-body">
                <div class="table-responsive">
                  <table datatable class="ch-table">
                    <thead>
                      <tr> 
                        <th >Sl.No.</th>
                        <th>Order ID</th>
                        <th style="width: 150px;">Customer Info</th>
                        <th style="width: 150px;">Partner Info</th>
                        <th >Fuel</th>
                        <th >Quantity</th>
                        <th style="width: 100px;">Fuel Price</th>
                        <th style="width: 100px;">Delivery Charge</th>
                        <th style="width: 100px;">Tax</th>
                        <th style="width: 100px;">Platform Charge</th>
                        <th style="width: 100px;">Total Charge</th>
                        <th style="width: 135px;">Settled Date & Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let revenue of revenueFilterNoRecordModel.response.revenuelist; let i=index">
                        <td align="center" class="v-align-middle "><p>{{i + 1}}</p></td>
                        <td *ngIf="revenue.orderId!=null"align="left" class="v-align-middle"><p>{{revenue.orderId}}</p></td>
                        <td *ngIf="revenue.orderId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                        <td *ngIf="revenue.customerId!=null"align="left" class="v-align-middle">
                            <p style="font-size: 12px;"><b>Name :</b> {{revenue.customerId.firstName}} {{revenue.customerId.lastName}} </p>
                            <p style="font-size: 12px;"><b>Mobile :</b> {{revenue.customerId.mobileNumber}}</p>
                          </td>
                        <td *ngIf="revenue.customerId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                        <td *ngIf="revenue.vehicleId!=null"align="left" class="v-align-middle">
                            <p style="font-size: 12px;"><b>Name :</b> {{revenue.vehicleId.userId.firstName}} {{revenue.vehicleId.userId.lastName}}</p>
                            <p style="font-size: 12px;"><b>Mobile :</b> {{revenue.vehicleId.userId.mobileNumber}}</p>
                          </td>
                        <td *ngIf="revenue.vehicleId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                        <td *ngIf="revenue.vehicleId!=null"align="left" class="v-align-middle">{{revenue.vehicleId.fuelType.fuelTypeName}}</td>
                        <td *ngIf="revenue.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="revenue.quantity!=null"align="center" class="v-align-middle">{{revenue.quantity}}</td>
                        <td *ngIf="revenue.quantity==null"align="center" class="v-align-middle">N/A</td>
                        <td *ngIf="revenue.totalfuelcost!=null"align="center" class="v-align-middle">
                          <span><i class="fa fa-inr" aria-hidden="true"></i> {{revenue.totalfuelcost | number : '1.2-2'}}</span>
                        </td>
                        <td *ngIf="revenue.totalfuelcost==null"align="center" class="v-align-middle">N/A</td>
                        <td *ngIf="revenue.deliveryCharges!=null" align="left" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{revenue.deliveryCharges | number : '1.2-2' }}</td>
                        <td *ngIf="revenue.deliveryCharges==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="revenue.tax!=null" align="left" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{revenue.tax | number : '1.2-2'}}</td>
                        <td *ngIf="revenue.tax==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="revenue.internetHandlingFee!=null"class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{revenue.internetHandlingFee | number : '1.2-2'}}</td>
                        <td *ngIf="revenue.internetHandlingFee==null"class="v-align-middle">N/A</td>
                        <td *ngIf="revenue.totalPayableAmount!=null "class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{revenue.totalPayableAmount | number : '1.2-2' }}</td>
                        <td *ngIf="revenue.totalPayableAmount==null" class="v-align-middle">N/A</td>
                        <td *ngIf="revenue.settledDatetime!=null "class="v-align-middle">{{revenue.settledDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="revenue.settledDatetime==null "class="v-align-middle">N/A</td>
                      </tr>
                    </tbody>
                    <tfoot *ngIf="revenueFilterNoRecordModel.response.statusCode==200">
                      <tr>
                        <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>Total </td>
                          <td>
                            <span *ngIf="revenueFilterNoRecordModel.response.totalSumFuelPrize!=null" style="width: 100px;"><i class="fa fa-inr" aria-hidden="true"></i> {{revenueFilterNoRecordModel.response.totalSumFuelPrize | number : '1.2-2'}}</span>
                            <span *ngIf="revenueFilterNoRecordModel.response.totalSumFuelPrize==null"><i class="fa fa-inr" aria-hidden="true"></i> 0.00</span>
                          </td>
                          <td>
                            <span *ngIf="revenueFilterNoRecordModel.response.totalSumDeliveryCharge!=null" style="width: 100px;"><i class="fa fa-inr" aria-hidden="true"></i> {{revenueFilterNoRecordModel.response.totalSumDeliveryCharge | number : '1.2-2'}}</span>
                            <span *ngIf="revenueFilterNoRecordModel.response.totalSumDeliveryCharge==null"><i class="fa fa-inr" aria-hidden="true"></i> 0.00</span>
                          </td>
                          <td>
                            <span *ngIf="revenueFilterNoRecordModel.response.totalSumTax!=null" style="width: 100px;"><i class="fa fa-inr" aria-hidden="true"></i> {{revenueFilterNoRecordModel.response.totalSumTax | number : '1.2-2'}}</span>
                            <span *ngIf="revenueFilterNoRecordModel.response.totalSumTax==null"><i class="fa fa-inr" aria-hidden="true"></i> 0.00</span>
                          </td>
                          <td>
                            <span *ngIf="revenueFilterNoRecordModel.response.totalSumPlatformCharge!=null" style="width: 100px;"><i class="fa fa-inr" aria-hidden="true"></i> {{revenueFilterNoRecordModel.response.totalSumPlatformCharge | number : '1.2-2'}}</span>
                            <span *ngIf="revenueFilterNoRecordModel.response.totalSumPlatformCharge==null"><i class="fa fa-inr" aria-hidden="true"></i> 0.00}</span>
                          </td>
                          <td>
                            <span *ngIf="revenueFilterNoRecordModel.response.totalSumPayoutAmount!=null" style="width: 100px;"><i class="fa fa-inr" aria-hidden="true"></i> {{revenueFilterNoRecordModel.response.totalSumPayoutAmount | number : '1.2-2'}}</span>
                            <span *ngIf="revenueFilterNoRecordModel.response.totalSumPayoutAmount==null"><i class="fa fa-inr" aria-hidden="true"></i> 0.00</span>
                          </td>
                          <td></td>
                      </tr>
                  </tfoot>
                  </table>
                </div>
              </div>
            </div>
            <!-- END card --> 
          </div>
        </div>
        
      </div>