import { Component, OnDestroy, OnInit ,ViewChild,ElementRef} from '@angular/core';
import { Router } from '@angular/router';

import { GenericResponseModel } from '../model/genricresponse';
import { Assettracking } from '../services/assettracking.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { empty, EMPTY, Subject } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-assettracking',
  templateUrl: './assettracking.component.html',
  styleUrls: ['./assettracking.component.css']
})
export class AssettrackingComponent implements OnInit {
  @ViewChild('epltable', { static: false }) epltable: ElementRef;
  assetList :GenericResponseModel;
  id:Number;
  closeResult = '';
  assetModel : GenericResponseModel;
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  poppupalert:boolean = false;
  progress = 0;
  currentFile: File;
  selectedFiles: FileList;
  imageUpload:boolean=true;
  pdfUpload:boolean=false;
  fuelType:String[];
  constructor(private assettracking: Assettracking,private router: Router,private modalService: NgbModal) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true
    };

    let form = {
      "requestStatus":"1"
    }
    this.assettracking.getPage(form)
        .subscribe( data => {
            if(data.statusCode==200){
               this.dtTrigger.next();
               this.assetList  = data;
             }
                
         
        },err => {
          if(err.error.statusCode==406){
            this.router.navigate(['/login']);
          }else if(err.error.statusCode==500){
            this.router.navigate(['/page-error']);
          }else if(err.error.statusCode==404){
            this.router.navigate(['/pagenotfound']);
          }
       });

  }

  
  selectFile(event) {
    this.selectedFiles = event.target.files;
  }
  view(content,model){
    this.assetModel = model;

    this.assettracking.getFuelType()
    .subscribe( data => {
      this.fuelType  = data;
       
    },err => {
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }else if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  device(content,id){
    this.id=id;
  
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  updateDevice(id){
    
    
    let deviceId = (<HTMLInputElement>document.getElementById('deviceId')).value;
    if(deviceId==''){
      this.poppupalert = true;
     
    } else{
      this.poppupalert = false;
      let form={
        deviceId:deviceId
       
      }
   
    
    this.assettracking.updateDevice(this.id,form).subscribe(data=>{
      this.modalService.dismissAll();
      Swal.fire({
        html: '<pre>' + 'Device ID has been updated successfully' + '</pre>',
        customClass: {
          popup: 'format-pre'
        }
      }).then((value) => {
        window.location.reload();
      });; ; 
     // this.transactionDetail = data;
      setTimeout(function() {
    }.bind(this), 3000);
    },err => {
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }else if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });
    }
   

}


upload(id,latitude,longitude){
  
  let assetFile = (<HTMLInputElement>document.getElementById('file')).value;
  let authorizedPersonName = (<HTMLInputElement>document.getElementById('authorizedPersonName')).value;
  let assetName = (<HTMLInputElement>document.getElementById('assetName')).value;
  let dgKVA = (<HTMLInputElement>document.getElementById('dgKVA')).value;
  let assestMake = (<HTMLInputElement>document.getElementById('assestMake')).value;
  let assestModel = (<HTMLInputElement>document.getElementById('assestModel')).value;
  let contactMobileNumber = (<HTMLInputElement>document.getElementById('contactMobileNumber')).value;
  let fuelType = (<HTMLInputElement>document.getElementById('fuelType')).value;
  let tankCapacity = (<HTMLInputElement>document.getElementById('tankCapacity')).value;
  let location = (<HTMLInputElement>document.getElementById('location')).value;

  
  
  let file='';
    
    if(assetFile!==null && assetFile!=='' && authorizedPersonName!='' && assetName!='' 
    && dgKVA!='' && assestMake!='' && assestModel!='' && contactMobileNumber!='' && fuelType!='' && tankCapacity!='' && location!=''){
      this.progress = 0;
     
      this.currentFile = this.selectedFiles.item(0);
      file = this.currentFile.type;
      let arr =  file.split("/");
      let fileType = arr[1];
      fileType = 'Image';
    
      let input = new FormData();
    
      if(this.imageUpload){
        let kycfile1 = (<HTMLInputElement>document.getElementById('file')).value;
      
        if(kycfile1!=null && kycfile1!==''){
          input.append('assestImage',this.selectedFiles.item(0));
        }else{
          input.append('assestImage',this.currentFile);
        }
     
      }else if(this.pdfUpload){
        let kycFile = this.currentFile;
        input.append('uploadCheque',this.currentFile);
      }
      
      input.append('id',id);
      input.append('authorizedPersonName',authorizedPersonName);
      input.append('assetName',assetName);
      input.append('dgKVA',dgKVA);

      input.append('assestMake',assestMake);
      input.append('assestModel',assestModel);
      input.append('contactMobileNumber',contactMobileNumber);
      input.append('fuelType',fuelType);
      input.append('tankCapacity',tankCapacity);
      input.append('latitude',latitude);
      input.append('longitude',longitude);
      input.append('location',location);
      input.append('userName',sessionStorage.getItem("username"));
      
      
      this.assettracking.upload(input).subscribe(data=>{
          if(data.response.flag==1){
            this.modalService.dismissAll();
            Swal.fire({
              html: '<pre>' + data.responseMessage + '</pre>',
              customClass: {
                popup: 'format-pre'
              }
            }).then((value) => {
              window.location.reload();
            });; ; 
            setTimeout(function() {
          }.bind(this), 3000);
          }else{
            
            setTimeout(function() {
              this.kycresponseString = data.responseMessage;
              this.kycalert = false;
              window.location.reload();
          }.bind(this), 3000);
          }
      },err => {
        if(err.error.statusCode==406){
          this.router.navigate(['/login']);
        }else if(err.error.statusCode==500){
          this.router.navigate(['/page-error']);
        }else if(err.error.statusCode==404){
          this.router.navigate(['/pagenotfound']);
        }
     });

    }else{
     
      this.poppupalert = true;
     // this.emptyCheckResponse = "Please select file!";
      setTimeout(function() {
        this.emptyCheck = false;
    }.bind(this), 3000);

    }
}
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  search(){
    let requestStatus = (<HTMLInputElement>document.getElementById('requestStatus')).value;
    let form = {
      "requestStatus":requestStatus
    }
    this.assettracking.getPage(form)
        .subscribe( data => {
            if(data.statusCode==200){
               this.dtTrigger.next();
               this.assetList  = data;
             }
                
         
        },err => {
          if(err.error.statusCode==406){
            this.router.navigate(['/login']);
          }else if(err.error.statusCode==500){
            this.router.navigate(['/page-error']);
          }else if(err.error.statusCode==404){
            this.router.navigate(['/pagenotfound']);
          }
       });

  }
}
