import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { GenericResponse } from '../model/genricmodelresponse';
import { GenericResponseModel } from '../model/genricresponse';
import { RoleService } from '../services/role.service';
import { RoleMenuMappingService } from '../services/rolemenumapping.service';
import { UserManagementService } from '../services/usermanagement.service';




@Component({
  selector: 'app-role-menu-mapping-edit',
  templateUrl: './role-menu-mapping-edit.component.html',
  styleUrls: ['./role-menu-mapping-edit.component.css']
})
export class RoleMenuMappingEditComponent implements OnInit {
  roleDetails:GenericResponseModel;
  menuDetails:GenericResponse;
  editmenuDetails:GenericResponseModel;
  resultText=[];
  values:string;  
 count:number=0; 
 roleName:String;
 role:Number;
  constructor(private usermanagementservice:UserManagementService,private rolemasterservice:RoleService,private rolemenuservice:RoleMenuMappingService,private router: Router) { }

  ngOnInit(): void {
    let roleId = Number(sessionStorage.getItem("adminroleid"));
    this.role = roleId;
    let adminrolename = sessionStorage.getItem("adminrolename");
    this.roleName = adminrolename;
    this.rolemenuservice.editmenu(roleId).subscribe(data=>{
      this.editmenuDetails = data;
      data.response.forEach(element => {
        let menuId = element['menuId'];
        let checkflag = element['mappingFlag'];
        if(checkflag==1){
          this.resultText.push(Number(menuId));
        }
      });
      console.log(this.editmenuDetails);
    });

    this.rolemasterservice.getAllRoles().subscribe(data=>{
      this.roleDetails = data;
    });

    this.rolemenuservice.getMenusAndRoleList().subscribe(data=>{
      this.menuDetails = data;
    });
  }

  onChange(id:Number,event) {
    const checked = event.target.checked;
     if (checked) {  
       this.resultText.push(id);  
        } else {  
          const index = this.resultText.indexOf(id);  
          this.resultText.splice(index, 1);  
      }  
      this.values=this.resultText.toString();  
      const count=this.resultText.length;  
      this.count=count;  
   }  

   updatemenu(){
    if(this.resultText.length>0){
      let formData= {
        roleId:this.role,
        menuIds:this.values,
        userName:sessionStorage.getItem("username")
      }
     this.rolemenuservice.update(formData).subscribe(data=>{
      if(data.response.flag==1){
        sessionStorage.setItem("menustatus",'1');
        sessionStorage.setItem("menumessage","Menu has been mapped against role");
        this.router.navigate(['/role-menu-mapping']);
      }
     },err => {
      if(err.error.statusCode==406){
        this.router.navigate(['/login']);
      }else if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });
    }
   }
}
