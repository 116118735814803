import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RoleMenuMappingComponent } from './role-menu-mapping/role-menu-mapping.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { CustomersComponent } from './customers/customers.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { PartnersComponent } from './partners/partners.component';
import { PartnersViewPageComponent } from './partners-view-page/partners-view-page.component';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import {MatDialogModule} from '@angular/material/dialog';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddUserComponent } from './add-user/add-user.component';
import { UserManagementViewComponent } from './user-management-view/user-management-view.component';
import { RoleMenuMappingEditComponent } from './role-menu-mapping-edit/role-menu-mapping-edit.component';
import { CustomersViewComponent } from './customers-view/customers-view.component';
import { AgentsComponent } from './agents/agents.component';
import { AgentsViewComponent } from './agents-view/agents-view.component';
import { PartnersKycComponent } from './partners-kyc/partners-kyc.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordOtpVerificationComponent } from './forgot-password-otp-verification/forgot-password-otp-verification.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { RouterModule} from '@angular/router';
import { PartnersKycViewComponent } from './partners-kyc-view/partners-kyc-view.component';
import { PartnersBankComponent } from './partners-bank/partners-bank.component';
import { VehiclesFuelsComponent } from './vehicles-fuels/vehicles-fuels.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule ,ReactiveFormsModule} from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { FuelPriceResetHistoryComponent } from './fuel-price-reset-history/fuel-price-reset-history.component';
import { TransactionComponent } from './transaction/transaction.component';
import { SettlementComponent } from './settlement/settlement.component';
import { OrderComponent } from './order/order.component';
import { MasterDepartmentComponent } from './master-department/master-department.component';
import { MasterRoleComponent } from './master-role/master-role.component';
import { MasterBankComponent } from './master-bank/master-bank.component';
import { MasterFuelTypeComponent } from './master-fuel-type/master-fuel-type.component';
import { MasterFuelRateComponent } from './master-fuel-rate/master-fuel-rate.component';
import { MasterKycDocumentComponent } from './master-kyc-document/master-kyc-document.component';
import { MasterCreditComponent } from './master-credit/master-credit.component';
import { MasterChargesConfigurationComponent } from './master-charges-configuration/master-charges-configuration.component';
import { ReportOrderComponent } from './report-order/report-order.component';
import { ReportFuelRefillBalanceComponent } from './report-fuel-refill-balance/report-fuel-refill-balance.component';
import { ReportSmsEmailCreditsComponent } from './report-sms-email-credits/report-sms-email-credits.component';
import { ReportRevenueComponent } from './report-revenue/report-revenue.component';
import { ReportCustomerCreditComponent } from './report-customer-credit/report-customer-credit.component';
import { HelpSupportComponent } from './help-support/help-support.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { ComplaintPolicyComponent } from './complaint-policy/complaint-policy.component';
import { FaqQrComponent } from './faq-qr/faq-qr.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { ProfileChangePasswordComponent } from './profile-change-password/profile-change-password.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { RoleMenuMappingCreateComponent } from './role-menu-mapping-create/role-menu-mapping-create.component';
import { ReviewRatingComponent } from './review-rating/review-rating.component';
import { MasterFuelRateListComponent } from './master-fuel-rate-list/master-fuel-rate-list.component';
import { AgmCoreModule } from '@agm/core';
import { EditUserComponent } from './edit-user/edit-user.component';
import { BnNgIdleService } from 'bn-ng-idle'; // import bn-ng-idle service
import { PartnersKycVehicleViewComponent } from './partners-kyc-vehicle-view/partners-kyc-vehicle-view.component';
import { FlexLayoutModule } from "@angular/flex-layout";
import { PartnersKycBankComponent } from './partners-kyc-bank/partners-kyc-bank.component';
import { CustomersKycBankComponent } from './customers-kyc-bank/customers-kyc-bank.component';
import { ReportStockComponent } from './report-stock/report-stock.component';
import { ReportRefundComponent } from './report-refund/report-refund.component';
import { ReportPartnerSettlementsComponent } from './report-partner-settlements/report-partner-settlements.component';
import { ReportReadytoSettlementsComponent } from './report-readyto-settle/report-readyto-settlements.component';
import { InvoiceGenerationComponent } from './invoice-generation/invoice-generation.component';
import {DecimalPipe} from '@angular/common';
import { HighchartsChartModule } from 'highcharts-angular';
import { MasterCouponComponent } from './master-coupon/master-coupon.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor, httpInterceptorProviders } from './auth/auth-interceptor';
import { MasterPromotionsComponent } from './master-promotions/master-promotions.component';
import { VehiclesViewComponent } from './vehicles-view/vehicles-view.component';
import { PageNotFoundComponent } from './page-not-found/pagenotfound.component';
import { PageErrorComponent } from './page-error/pageerror.component';
import { MasterHsnCodeComponent } from './master-hsncode/master-hsncode.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { InvoiceGenerationPartnerComponent } from './invoice-generation-partner/invoice-generation-partner.component';
import { ReportPartnerMonthlyInvoiceComponent } from './report-partner-monthly-invoice/report-partner-monthly-invoice.component';
import { InvoiceGenerationPartnerMonthlyComponent } from './invoice-generation-partner-monthly/invoice-generation-partner-monthly.component';
import { InvoiceGenerationRefundComponent } from './invoice-generation-refund/invoice-generation-refund.component';
import { ReportPlatformInvoiceComponent } from './report-platform-invoice/report-platform-invoice.component';
import { InvoiceGenerationPlatformComponent } from './invoice-generation-platform/invoice-generation-platform.component';
import { PartnerPaymentModeComponent } from './paymentmode-partner-mapping/paymentmode-partner-mapping.component';
import { PaymentmodeComponent } from './paymentmode/paymentmode.component';
import { AssettrackingComponent } from './assettracking/assettracking.component';
import { OrderdetailsComponent } from './orderdetails/orderdetails.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    RoleMenuMappingComponent,
    UserManagementComponent,
    CustomersComponent,
    HeaderComponent,
    FooterComponent,
    PartnersComponent,
    PartnersViewPageComponent,
    AddUserComponent,
    UserManagementViewComponent,
    RoleMenuMappingEditComponent,
    CustomersViewComponent,
    AgentsComponent,
    AgentsViewComponent,
    PartnersKycComponent,
    ForgotPasswordComponent,
    ForgotPasswordOtpVerificationComponent,
    ChangePasswordComponent,
    PartnersKycViewComponent,
    PartnersBankComponent,
    VehiclesFuelsComponent,
    FuelPriceResetHistoryComponent,
    TransactionComponent,
    SettlementComponent,
    OrderComponent,
    MasterDepartmentComponent,
    MasterRoleComponent,
    MasterBankComponent,
    MasterFuelTypeComponent,
    MasterFuelRateComponent,
    MasterKycDocumentComponent,
    MasterCreditComponent,
    MasterChargesConfigurationComponent,
    ReportOrderComponent,
    ReportFuelRefillBalanceComponent,
    ReportSmsEmailCreditsComponent,
    ReportRevenueComponent,
    ReportCustomerCreditComponent,
    HelpSupportComponent,
    TermsConditionsComponent,
    ComplaintPolicyComponent,
    FaqQrComponent,
    DisclaimerComponent,
    ProfileChangePasswordComponent,
    RoleMenuMappingCreateComponent,
    ReviewRatingComponent,
    MasterFuelRateListComponent,
    EditUserComponent,
    PartnersKycVehicleViewComponent,
    PartnersKycBankComponent,
    CustomersKycBankComponent,
    ReportStockComponent,
    ReportRefundComponent,
    ReportPartnerSettlementsComponent,
    ReportReadytoSettlementsComponent,
    InvoiceGenerationComponent,
    MasterCouponComponent,
    MasterPromotionsComponent,
    VehiclesViewComponent,
    PageNotFoundComponent,
    PageErrorComponent,
    MasterHsnCodeComponent,
    RefundPolicyComponent,
    InvoiceGenerationPartnerComponent,
    ReportPartnerMonthlyInvoiceComponent,
    InvoiceGenerationPartnerMonthlyComponent,
    InvoiceGenerationRefundComponent,
    ReportPlatformInvoiceComponent,
    InvoiceGenerationPlatformComponent,
    PaymentmodeComponent,
    PartnerPaymentModeComponent,
    AssettrackingComponent,
    OrderdetailsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // BrowserAnimationsModule,
    // MatDialogModule,
    NgbModule,
    NgOtpInputModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    DataTablesModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    FlexLayoutModule,
    HighchartsChartModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD83YvrzVM1BHwh9iwFA_wsoZlcdGxaoI8'
    }),
  ],
  // providers: [BnNgIdleService,DecimalPipe],
  providers: [{
    provide: HTTP_INTERCEPTORS, 
    useClass: AuthInterceptor, 
    multi: true
  },BnNgIdleService,DecimalPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }

