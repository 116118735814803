import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderTransactionService } from '../services/ordertran.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { GenericResponse } from '../model/genricmodelresponse';
import { GenericResponseModel } from '../model/genricresponse';
import * as FileSaver from 'file-saver';
import Swal from 'sweetalert2/dist/sweetalert2.js';


@Component({
  selector: 'app-orderdetails',
  templateUrl: './orderdetails.component.html',
  styleUrls: ['./orderdetails.component.css']
})
export class OrderdetailsComponent implements OnInit {
  orderId:Number;
  obj:any;
  message:String;
  active = 1;
  public isCollapsed = false;
  asstesInfo:GenericResponseModel;
  orderTrackDetails:GenericResponse;
  bakImage:boolean = false;
  imageToShowProof: any;
  closeResult = '';

  constructor(private modalService: NgbModal,private ordertransactionservice: OrderTransactionService,private router: Router) { }

  ngOnInit(): void {

    let id = sessionStorage.getItem('orderId');
    this.ordertransactionservice.getOrderById(id).subscribe(data=>{
      this.obj=data;
    });

    this.ordertransactionservice.tracking(id).subscribe(data=>{
      this.orderTrackDetails = data;
    });
    
  this.ordertransactionservice.getAssets(id).subscribe(data=>{
    this.asstesInfo = data;
  });
  }

  viewReceipts(id,filename,contentproof){
  
      let dataType ='';
      this.ordertransactionservice.getReceipt(id).subscribe(data=>{
        this.bakImage = true;
        if(data.type ==='application/pdf'){
          FileSaver.saveAs(data, filename);
        }else{
          dataType= data.type;
          this.createImageFromBlob(data);
        }
      }, error => {
          console.log(error);
      });
  
      this.modalService.open(contentproof, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
      
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  
    }

    createImageFromBlob(image: Blob) {
      let reader = new FileReader();
      reader.addEventListener("load", () => {
        this.imageToShowProof = reader.result
       }, false);
  
      if (image) {
        reader.readAsDataURL(image);
     }else{
     }
    }

    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return `with: ${reason}`;
      }
    }

    invoice(id){
      this.ordertransactionservice.download(id,4).subscribe(data => {
        
        var file = new Blob([data], {type: 'application/pdf'});
         var fileURL = URL.createObjectURL(file);
         window.open(fileURL);
  
    }, error => {
  
    });
   
    }
  
    download(id,filename){
      this.ordertransactionservice.download(id,4).subscribe(result => {
        let dataType = 'application/pdf';
        let binaryData = [];
        binaryData.push(result);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
  
    });
    }
  
    sendMail(id){
      this.ordertransactionservice.sendMail(id,4).subscribe(data => {
        
        if(data.statusCode==200){
          Swal.fire({
            html: '<pre>' + data.responseMessage + '</pre>',
            customClass: {
              popup: 'format-pre'
            }
          }).then((value) => {
            window.location.reload();
          });; ;
        }
      },err => {
        if(err.error.statusCode==500){
          this.router.navigate(['/page-error']);
        }else if(err.error.statusCode==404){
          this.router.navigate(['/pagenotfound']);
        }
     });
   
    }
  
}
