<app-header></app-header>

<!-- START PAGE-CONTAINER -->

<div class="page-container "> 
  <!-- START PAGE CONTENT WRAPPER -->
  <div class="page-content-wrapper "> 
    <!-- START PAGE CONTENT -->
    <div class="content sm-gutter"> 
      <!-- START BREADCRUMBS -->
      <div class="bg-white">
        <div class="container">
          <ol class="breadcrumb breadcrumb-alt">
            <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Home</a></li>
            <li class="breadcrumb-item active">CUSTOMERS </li>
            <div class="btn-section-wrapper">
            <button *ngIf="excelexportflag" title="Excel Export" title="Excel Export" (click)="excelexport()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
          </div>
          </ol>
        </div>
      </div>
      <!-- END BREADCRUMBS -->
      
      <div class="container Customers-top-notification">
        <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 420px; margin-left: 5px;">
          <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                  <div _ngcontent-tgd-c17="" class="card-title1"><span _ngcontent-tgd-c17="" class="font-montserrat fs-11 all-caps">Total No. of Customers<i _ngcontent-tgd-c17="" class="fa fa-chevron-right"></i></span></div>
                  <div _ngcontent-tgd-c17="" class="card-controls">
                    <ul _ngcontent-tgd-c17="">
                      <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                  <h3 *ngIf="customerResponseModel.totalCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{customerResponseModel.totalCount}}</h3>
                  <h3 *ngIf="customerResponseModel.totalCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                  <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% </span> -->
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 430px; margin-left: 20px;">
          <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                  <div _ngcontent-tgd-c17="" class="card-title1"><span _ngcontent-tgd-c17="" class="font-montserrat fs-11 all-caps">Total No. of Verified Customers<i _ngcontent-tgd-c17="" class="fa fa-chevron-right"></i></span></div>
                  <div _ngcontent-tgd-c17="" class="card-controls">
                    <ul _ngcontent-tgd-c17="">
                      <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                  <h3 *ngIf="customerResponseModel.activeCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{customerResponseModel.activeCount}}</h3>
                  <h3 *ngIf="customerResponseModel.activeCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                  <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71%</span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 440px; margin-left: 45px;">
          <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                  <div _ngcontent-tgd-c17="" class="card-title1"><span _ngcontent-tgd-c17="" class="font-montserrat fs-11 all-caps">Total No. of Not Verified Customers<i _ngcontent-tgd-c17="" class="fa fa-chevron-right"></i></span></div>
                  <div _ngcontent-tgd-c17="" class="card-controls">
                    <ul _ngcontent-tgd-c17="">
                      <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                  <h3 *ngIf="customerResponseModel.inactiveCount!=null" _ngcontent-tgd-c17="" class="no-margin p-b-5">{{customerResponseModel.inactiveCount}}</h3>
                  <h3 *ngIf="customerResponseModel.inactiveCount==null" _ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                  <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
       
        
      </div>
       <!-- <div class="container search-section">

       	<div class="Customers-search-section">
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Search By Cust.ID
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="#">Search By Cust. Name</a>
              <a class="dropdown-item" href="#">Search By Cust. Mobile</a>
              <a class="dropdown-item" href="#">Search By Cust. Email</a>
              <a class="dropdown-item" href="#">Account Status</a>
              <a class="dropdown-item" href="#">OTP Verification Status</a>
            </div>
          </div> -->
       	<!-- <div class="form-group">
           <input type="email" class="form-control" placeholder="Search By Cust.ID" required="">
         </div> -->
         <!-- <div class="form-group">
           <input type="email" class="form-control" placeholder="Search By Cust. Name" required="">
         </div>
         <div class="form-group">
           <input type="email" class="form-control" placeholder="Search By Cust. Mobile" required="">
         </div>
         <div class="form-group">
           <input type="email" class="form-control" placeholder="Search By Cust. Email " required="">
         </div>
         <div class="form-group">
           <input type="email" class="form-control" placeholder="Account Status " required="">
         </div>
         <div class="form-group">
           <input type="email" class="form-control" placeholder="OTP Verification Status" required="">
         </div> -->
<!-- <div class="search-btn-wrraper">
         <button class="btn btn-primary btn-cons">Search</button></div>
       </div>
         </div> -->
			
      
      <!-- START CONTAINER FLUID --> 
      
      <!-- START CONTAINER FLUID -->
      <div class=" no-padding    container-fixed-lg bg-white">
        <div class="container"> 
          <!-- START card -->
          <div class="card card-transparent">
            <!-- <div class="card-header ">
            </div> -->
            <div class="card-body">
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Customer ID</th>
                      <th style="text-align: left; word-wrap: break-word;">Customer Name</th>
                      <th style="text-align: left;">Mobile No</th>
                      <th style="text-align: left;">Email ID</th>
                      <th style="text-align: center;">OTP Verification</th>
                      <th style="text-align: center;">Account Status</th>
                      <th style="text-align: left;">Onboarded  Date Time </th>
                      <th style="text-align: center;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let user of customerResponseModel.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="user.userCode!=null"align="left" class="v-align-middle" >{{user.userCode}}</td>
                      <td *ngIf="user.userCode==null"align="left" class="v-align-middle" >N/A</td>
                      <td align="left" class="v-align-middle">{{user.firstName}} {{user.lastName}} </td>
                      <td align="left" class="v-align-middle">{{user.mobileNumber}}</td>
                      <td align="left" class="v-align-middle">{{user.emailAddress}}</td>
                      <td align="center" *ngIf="user.otpVerificationStatus===1" class="v-align-middle ht-Success">Success</td>
                      <td align="center" *ngIf="user.otpVerificationStatus===0" class="v-align-middle ht-Pending">Pending</td>
                      <td align="center" *ngIf="user.accountStatus===1" class="v-align-middle ht-active">Active</td>
                      <td align="center" *ngIf="user.accountStatus===2" class="v-align-middle ht-inactive">InActive</td>
                      <td align="left" class="v-align-middle">{{user.createdDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td align="center" class="v-align-middle">
                        <button title="View" class="label label-info" (click)="view(user)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="user.otpVerificationStatus===0" title="delete"class="label label-danger" (click)="open(reject,user.userId)"><i class="fa fa-trash" aria-hidden="true"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- END card --> 
        </div>
      </div>
      <!-- END CONTAINER FLUID --> 
      
    </div>
    <!-- END PAGE CONTENT --> 
    <!-- START COPYRIGHT --> 
    <!-- START CONTAINER FLUID --> 
    <!-- START CONTAINER FLUID -->
  <!--   <div class=" container   container-fixed-lg footer">
      <div class="copyright sm-text-center">
        <p class="small-text no-margin pull-left sm-pull-reset"> ©2014-2020 All Rights Reserved. Pages® and/or its subsidiaries or affiliates are registered trademark of Revox Ltd. <span class="hint-text m-l-15">Pages v05.23 20201105.r.190</span> </p>
        <p class="small no-margin pull-right sm-pull-reset"> Hand-crafted <span class="hint-text">&amp; made with Love</span> </p>
        <div class="clearfix"></div>
      </div>
    </div> -->
    <!-- END COPYRIGHT --> 
  </div>
  <!-- END PAGE CONTENT WRAPPER --> 
</div>
<!-- END PAGE CONTAINER --> 
<!--START QUICKVIEW -->

<ng-template #reject let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Do you want delete the user?</label>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="delete()" (click)="modal.close('Save click')">yes</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">no</button>
  </div>
</ng-template>

<router-outlet></router-outlet>
