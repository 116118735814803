
<app-header></app-header>

<!-- START PAGE-CONTAINER -->

<div class="page-container "> 
  <!-- START PAGE CONTENT WRAPPER -->
  <div class="page-content-wrapper "> 
    <!-- START PAGE CONTENT -->
    <div class="content sm-gutter"> 
      <!-- START BREADCRUMBS -->
      <div class="bg-white">
        <div class="container">
          <ol class="breadcrumb breadcrumb-alt">
            <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Home</a></li>
            <li class="breadcrumb-item active">Change Password </li>
          </ol>
        </div>
      </div>
      <!-- END BREADCRUMBS -->
      
			
      
      <!-- START CONTAINER FLUID --> 
      
      <!-- START CONTAINER FLUID -->
      <div class=" no-padding    container-fixed-lg bg-white">
        <div class="container"> 
          <!-- START card -->
           <div class="col-md-6 offset-md-3">
                    <!-- form card change password -->
                    <div class="card card-outline-secondary">
                        <div class="card-header">
                            <h3 class="mb-0">Change Password</h3>
                        </div>
                        <div class="card-body">
                          <form (ngSubmit)="onSubmit()" id="form-login" class="p-t-15" role="form" action="index.html">
                            <div class="alert alert-success"
                                        *ngIf="successflag">{{errorMessage}}</div>
                            <div class="alert alert-danger"
                                        *ngIf="errorflag">{{errorMessage}}</div>
                                        
                                <div class="form-group">
                                    <label for="inputPasswordOld">Current Password
                                      <span class="required-asterisk">*</span>
                                    </label>
                                    <input [type]="fieldTextType ? 'text' : 'password'" class="form-control" required  id="currentpassowrd" name="currentpassowrd" minlength="6" maxlength="20" (keydown.space)="$event.preventDefault()" ng-required="true">
                                    <div class="input-group-append">
                                    <!-- <i
                                        class="fa"
                                        [ngClass]="{
                                          'fa-eye-slash': !fieldTextType,
                                          'fa-eye': fieldTextType
                                        }"
                                        (click)="toggleFieldTextType()"
                                      ></i> -->
                                    </div>
                              </div>
                                <div class="form-group">
                                    <label for="inputPasswordNew">New Password
                                      <span class="required-asterisk">*</span>
                                    </label>
                                    <input type="password" class="form-control" id="newpassword" name="newpassword" (keydown.space)="$event.preventDefault()" minlength="6" maxlength="20" ng-required="true">
                                    <span class="form-text small text-muted">
                                            The password must be 6-20 characters, and must <em>not</em> contain spaces.
                                        </span>
                                </div>
                                <div class="form-group">
                                    <label for="inputPasswordNewVerify">Confirm New Password
                                      <span class="required-asterisk">*</span>
                                    </label>
                                    <input type="password" class="form-control" id="confirmpassword" name="confirpassword" (keydown.space)="$event.preventDefault()" minlength="6" maxlength="20" ng-required="true">
                                    <span class="form-text small text-muted">
                                            To confirm, type the new password again.
                                        </span>
                                        <div class="alert alert-danger"
                                        *ngIf="check">New Password and Confirm Password Not match</div>
                                </div>
                                <div class="form-group">
                                    <button aria-label="" type="submit" class="btn btn-success btn-lg float-right">Change Password</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- /form card change password -->

                </div>
          <!-- END card --> 
        </div>
      </div>
      <!-- END CONTAINER FLUID --> 
      
    </div>
    <!-- END PAGE CONTENT --> 
    <!-- START COPYRIGHT --> 
    <!-- START CONTAINER FLUID --> 
    <!-- START CONTAINER FLUID -->
  <!--   <div class=" container   container-fixed-lg footer">
      <div class="copyright sm-text-center">
        <p class="small-text no-margin pull-left sm-pull-reset"> ©2014-2020 All Rights Reserved. Pages® and/or its subsidiaries or affiliates are registered trademark of Revox Ltd. <span class="hint-text m-l-15">Pages v05.23 20201105.r.190</span> </p>
        <p class="small no-margin pull-right sm-pull-reset"> Hand-crafted <span class="hint-text">&amp; made with Love</span> </p>
        <div class="clearfix"></div>
      </div>
    </div> -->
    <!-- END COPYRIGHT --> 
  </div>
  <!-- END PAGE CONTENT WRAPPER --> 
</div>
<!-- END PAGE CONTAINER --> 
<!--START QUICKVIEW -->

<!-- END QUICKVIEW--> 
<!-- START OVERLAY -->

<!-- END OVERLAY -->



<ng-template #content let-modal>
  <div class="modal-header">
    <h6>More Info</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- <h6>Menu list</h6> -->
    <div class="wrapper">
    	<div class="flex extradiv">
    		<h5>Order Details</h5>
    		<p class="card-title">Fuel <br><span><b>Diesel</b></span></p>
    		<p class="card-title">Booking Date & Time  <br><span><b>21/09/2020 & 09:00 am</b></span></p>
    		<p class="card-title">Quantity in Ltrs   <br><span><b>222</b></span></p>
    		<p class="card-title">Delivery Date & Time  <br><span><b>21/09/2020 & 09:00 am</b></span></p>
    		<p class="card-title">Delivery Location   <br><span><b>No.294, Mount Road, Opposite LIC, <br> Nandhanam, Chennai 600 002</b></span></p>
    	</div>
    	<div class="flex extradiv">
    		<h5>Delivery Agent Info</h5>
    		<button class="order cancel">cancel order</button>
    		<div class="km">5.3 Km</div>
    		<p class="card-title">Agent Name <span class="ratting"><i class="fa fa-star" aria-hidden="true"></i>
3.5</span> <br><span><b>Kannan Y</b> </span> </p>
    		<p class="card-title">Vehicle Number <br><span><b>TN-09 BS 2545 </b></span></p>
    		<p class="card-title">Quantity in Ltrs   <br><span><b>222</b></span></p>
    		<p class="card-title">Mobile  <br><span><b>+91 987895478</b></span></p>
    		<p class="card-title">Delivery Time   <br><span><b>Apprx Delivery Time • 00:45 Min.s</b></span></p>
    	</div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
  </div>
</ng-template><ng-template #content let-modal>
  <div class="modal-header">
    <h6>More Info</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- <h6>Menu list</h6> -->
    <div class="wrapper">
    	<div class="flex extradiv">
    		<h5>Vehicle View Page </h5>
   		  <p class="card-title">Partner Name<br><span><b>AnandhRajB </b></span></p>
   		  <p class="card-title">Vehicle Number   <br><span><b>TN-09-R9332 </b></span></p>
   		  <p class="card-title">Model Number  <br>
   		    <span><b>Maxi Tmck </b></span></p>
   		  <p class="card-title">Fuel  <br><span><b>Diesel</b></span></p>
   		  <p class="card-title">Tank Capacity in litrs <br>
   		    <span><b>180</b></span></p>
			<p class="card-title">Available Fuel in litrs <br>
   		    <span><b>180</b></span></p>
			<p class="card-title">Added Date & Time <br>
   		    <span><b>21-09-2020 & 10:02am</b></span></p>
			<p class="card-title">Status <br>
   		    <span><b>InActive</b></span></p>
    	</div>
    	<div class="flex extradiv">
    		<h5>Payment Details </h5>
    		
   		  <p class="card-title">Payment Type  <br><span><b>Vechical registration Fee </b></span></p>
    		<p class="card-title">Payment Amount   <br><span><b>Rs. 5000</b></span></p>
    		<p class="card-title">Transaction ID  <br><span><b>CFTID934S230</b></span></p>
    		<p class="card-title">Payment Status   <br><span><b>Success</b></span></p> 
			<p class="card-title">Transacdon Date & Time   <br>
		    <b>21-09-2020 & 10:02am</b></p>
    	</div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>

<router-outlet></router-outlet>



