
<app-header></app-header>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
  </ngx-spinner>
<!-- START PAGE-CONTAINER -->
<div class="page-container "> 
  <!-- START PAGE CONTENT WRAPPER -->
  <div class="page-content-wrapper "> 
    <!-- START PAGE CONTENT -->
    <div class="content sm-gutter"> 
      <!-- START BREADCRUMBS -->
      <div class="bg-white">
        <div class="container">
          <ol class="breadcrumb breadcrumb-alt">
            <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Home</a></li>
            <li class="breadcrumb-item active">ORDER </li>
            <div class="btn-section-wrapper">
              <button *ngIf="excelexportflag" title="Excel Export" title="Excel Export" (click)="excelexport()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
            </div>
          </ol>
        </div>
      </div>
      <!-- END BREADCRUMBS -->
      
      <div class="container Customers-top-notification card4view">
        <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 250px; margin-left: 5px;">
          <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                  <div _ngcontent-tgd-c17="" class="card-title1"><span _ngcontent-tgd-c17="" class="font-montserrat fs-11 all-caps">Total No. of Orders</span></div>
                  <div _ngcontent-tgd-c17="" class="card-controls">
                    <ul _ngcontent-tgd-c17="">
                      <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                  <h3 *ngIf="orderDetail.totalCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{orderDetail.totalCount}}</h3>
                  <h3 *ngIf="orderDetail.totalCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                  <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% </span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 280px; margin-left: -80px;">
          <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                  <div _ngcontent-tgd-c17="" class="card-title1"><span _ngcontent-tgd-c17="" class="font-montserrat fs-11 all-caps">Pending Orders</span></div>
                  <div _ngcontent-tgd-c17="" class="card-controls">
                    <ul _ngcontent-tgd-c17="">
                      <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                  <h3 *ngIf="orderDetail.pendingCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{orderDetail.pendingCount}}</h3>
                  <h3 *ngIf="orderDetail.pendingCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                  <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71%</span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 260px; margin-left: -140px;">
          <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                  <div _ngcontent-tgd-c17="" class="card-title1"><span _ngcontent-tgd-c17="" class="font-montserrat fs-11 all-caps">In Progress Orders</span></div>
                  <div _ngcontent-tgd-c17="" class="card-controls">
                    <ul _ngcontent-tgd-c17="">
                      <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                  <h3 *ngIf="orderDetail.inactiveCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{orderDetail.inactiveCount}}</h3>
                  <h3 *ngIf="orderDetail.inactiveCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                  <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 260px; margin-left: -220px;">
          <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                  <div _ngcontent-tgd-c17="" class="card-title1"><span _ngcontent-tgd-c17="" class="font-montserrat fs-11 all-caps">Completed Orders</span></div>
                  <div _ngcontent-tgd-c17="" class="card-controls">
                    <ul _ngcontent-tgd-c17="">
                      <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                  <h3 *ngIf="orderDetail.activeCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{orderDetail.activeCount}}</h3>
                  <h3 *ngIf="orderDetail.activeCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                  <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 260px; margin-left: -300px;">
          <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                  <div _ngcontent-tgd-c17="" class="card-title1"><span _ngcontent-tgd-c17="" class="font-montserrat fs-11 all-caps"> Cancelled Orders</span></div>
                  <div _ngcontent-tgd-c17="" class="card-controls">
                    <ul _ngcontent-tgd-c17="">
                      <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                  <h3 *ngIf="orderDetail.cancellationCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{orderDetail.cancellationCount}}</h3>
                  <h3 *ngIf="orderDetail.cancellationCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                  <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
			
      
      <!-- START CONTAINER FLUID --> 
      
      <!-- START CONTAINER FLUID -->
      <div *ngIf="successalert" style="height: 50px; margin-left: 30px;" class="alert-success">
        <span style="text-align: center; margin-left: 10px;">{{successmessage}}</span>
      </div>
      <div class=" no-padding    container-fixed-lg bg-white">
        <br>
        <!-- <label style="margin-left: 20px;">Search By Status</label>
        <br> -->
        <select class="select-form-style" style="margin-left: 20px;" id="searchBy" (change)="callsearch($event)">  
          <option value="1">Search By Status</option>  
          <option value="2">Search By Date Range</option>  
          <option value="3">Search By Onboarded By</option>  
      </select>  
      <select class="select-form-style" style="margin-left: 10px;" id="status">
        <option value="1" selected>All</option>  
        <option value="2" >Pending</option>  
        <option value="3">Inprogress</option>  
        <option value="4">Delivered</option>  
        <option value="5">Cancelled</option>  
    </select>  
      <input type="date" style="display: none; margin-left: 10px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
      <input type="date" style="display: none; margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >

      <select class="select-form-style" style="margin-left: 10px; display: none;" id="onboarded">
        <option value="1">CinchFuel</option>  
        <option value="2">Others</option>  
    </select>      
    <button style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
    <button *ngIf="refreshFlag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
    <div *ngIf="errorFlag" style="color: red; margin-left: 20px;">{{errorMessage}}</div>
        <!-- <button (click)="pending()" class="btn-transaction" style="margin-left: 350px;">
          <div class="form-check">
   <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked>
   <label class="form-check-label" for="exampleRadios1">
     Pending
   </label>
 </div>
 </button>
 <button (click)="inprogress()" class="btn-transaction">
 <div class="form-check">
   <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2">
   <label class="form-check-label" for="exampleRadios2">
    Inprogress
   </label>
 </div>
 </button>
 <button (click)="completed()" class="btn-transaction">
  <div class="form-check">
    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="option3">
    <label class="form-check-label" for="exampleRadios3">
     Completed
    </label>
  </div>
  </button>
  <button (click)="cancelled()" class="btn-transaction">
    <div class="form-check">
      <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios4" value="option4">
      <label class="form-check-label" for="exampleRadios4">
       Cancelled
      </label>
    </div>
    </button>
    <button (click)="all()" class="btn-transaction">
      <div class="form-check">
        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios5" value="option5">
        <label class="form-check-label" for="exampleRadios5">
         All
        </label>
      </div>
      </button> -->
    
        <!-- <div class="container"> 
          <div class="card card-transparent"> -->
            <div *ngIf="defaultCustomer" class="container"> 
              <div class="card card-transparent">
               <div  class="card-body">
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Order ID </th>
                      <th style="text-align: left;">Customer Info</th>
                      <th style="text-align: left;">Partner Info</th>
                      <th style="text-align: left;">Vehicles Number</th>
                      <!-- <th style="text-align: left;">Delivery agent Name</th> -->
                      <th style="text-align: left;">Fuel</th>
                      <!-- <th style="text-align: center;">Quantity  in Ltrs</th> -->
                      <th style="text-align: left;">Booked  Date Time </th>
                      <th style="text-align: left;">Delivered  Date Time </th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: center; width: 100px;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let order of orderDetail.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="order.orderId!=null"align="left" class="v-align-middle">{{order.orderId}}</td>
                      <td *ngIf="order.orderId==null"align="left" class="v-align-middle">N/A</td>

                      <td style="word-wrap: break-word;" align="left" class="v-align-middle">
                        {{order.customerId.firstName}} {{order.customerId.lastName}}<br/>
                        {{order.customerId.mobileNumber}}
                      </td>
                      <td style="word-wrap: break-word;" align="left" class="v-align-middle">
                        {{order.vehicleId.userId.firstName}} {{order.vehicleId.userId.lastName}}<br/>
                        {{order.vehicleId.userId.mobileNumber}}
                      </td>
                   
                      <td *ngIf="order.vehicleId.vehicleNumber!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
                      <td *ngIf="order.vehicleId.vehicleNumber==null" align="left" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
                      <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td> -->
                      <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">
                        <span style="font-size: 10px;">{{order.fuelType}}</span>  <br>
                      <span style="font-size: 10px;"><b>Qtn : </b> {{order.quantity}}</span>
                        </td>
                      <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
                      <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td> -->
                      <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                      <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                      <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                      <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                      <td *ngIf="order.status==='Waiting for agent'" align="center" class="v-align-middle ht-inactive">{{order.status}}</td>
                      <td align="center" style="width: 11%;" class="v-align-middle">
                        <button title="View" class="label label-info" (click)="view(order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                        <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                        <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.cancelOrder===1"class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                        <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="invoice(order.id)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
                       
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

            <!-- ---- Pending Orders ----  -->
            <div *ngIf="penCustomer" class="container"> 
              <div class="card card-transparent">
            <div  class="card-body">
              <div style="display: none;">{{pencustomerOrder.responseMessage}}</div>
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center; width: 30px;">Sl.No.</th>
                      <th style="text-align: left;">Order ID </th>
                      <th style="text-align: left;">Customer Info</th>
                      <th style="text-align: left;">Partner Info</th>
                      <th style="text-align: left;">Vehicle Number</th>
                      <!-- <th style="text-align: left;">Delivery Agent Name</th> -->
                      <th style="text-align: left;">Fuel</th>
                      <!-- <th style="text-align: center;">Quantity  in Ltrs</th> -->
                      <th style="text-align: left;">Booked  Date Time </th>
                      <th style="text-align: left;">Delivered  Date Time </th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: center; width: 100px;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let order of pencustomerOrder.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="order.orderId!=null"align="left" class="v-align-middle">{{order.orderId}}</td>
                      <td *ngIf="order.orderId==null"align="left" class="v-align-middle">N/A</td>
                     
                      <td style="word-wrap: break-word;" align="left" class="v-align-middle">
                        {{order.customerId.firstName}} {{order.customerId.lastName}}<br/>
                        {{order.customerId.mobileNumber}}
                    </td>
                    <td style="word-wrap: break-word;" align="left" class="v-align-middle">
                         {{order.vehicleId.userId.firstName}} {{order.vehicleId.userId.lastName}}<br/>
                         {{order.vehicleId.userId.mobileNumber}}
                     </td>

                      <td *ngIf="order.vehicleId.vehicleNumber!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
                      <td *ngIf="order.vehicleId.vehicleNumber==null" align="left" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
                      <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td> -->
                      <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">
                      <span style="font-size: 10px;">{{order.fuelType}}</span>  <br>
                      <span style="font-size: 10px;"><b>Qtn : </b> {{order.quantity}}</span>
                      </td>
                      <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
                      <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td> -->
                      <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                      <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                      <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                      <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                      <td *ngIf="order.status==='Waiting for agent'" align="center" class="v-align-middle ht-inactive">{{order.status}}</td>
                      <td align="center" style="width: 30px;" class="v-align-middle">
                        <button title="View" class="label label-info" (click)="view(order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                        <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                        <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.cancelOrder===1"class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            </div>
            </div>
            
            <!-- --- Inprogress Order -----  -->
            <div *ngIf="inpCustomer" class="container"> 
              <div class="card card-transparent">
            <div  class="card-body">
              <div style="display: none;">{{inpcustomerOrder.responseMessage}}</div>
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Order ID </th>
                      <th style="text-align: left;">Customer Info</th>
                      <th style="text-align: left;">Partner Info</th>
                      <th style="text-align: left;">Vehicle Number</th>
                      <!-- <th style="text-align: left;">Delivery Agent Name</th> -->
                      <th style="text-align: left;">Fuel</th>
                      <!-- <th style="text-align: center;">Quantity  in Ltrs</th> -->
                      <th style="text-align: left;">Booked  Date Time </th>
                      <th style="text-align: left;">
                        Delivered  Date Time </th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: center; width: 100px;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let order of inpcustomerOrder.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="order.orderId!=null"align="left" class="v-align-middle">{{order.orderId}}</td>
                      <td *ngIf="order.orderId==null"align="left" class="v-align-middle">N/A</td>
                      <td style="word-wrap: break-word;" align="left" class="v-align-middle">
                        {{order.customerId.firstName}} {{order.customerId.lastName}}<br/>
                        {{order.customerId.mobileNumber}}
                    </td>
                    <td style="word-wrap: break-word;" align="left" class="v-align-middle">
                         {{order.vehicleId.userId.firstName}} {{order.vehicleId.userId.lastName}}<br/>
                         {{order.vehicleId.userId.mobileNumber}}
                     </td>
                     
                      <td *ngIf="order.vehicleId.vehicleNumber!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
                      <td *ngIf="order.vehicleId.vehicleNumber==null" align="left" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
                      <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td> -->
                      <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">
                        <span style="font-size: 10px;">{{order.fuelType}}</span>  <br>
                      <span style="font-size: 10px;"><b>Qtn : </b> {{order.quantity}}</span>
                        </td>
                      <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
                      <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td> -->
                      <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                      <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                      <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                      <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                      <td *ngIf="order.status==='Waiting for agent'" align="center" class="v-align-middle ht-inactive">{{order.status}}</td>
                      <td align="center" style="width: 30px;" class="v-align-middle">
                        <button title="View" class="label label-info" (click)="view(order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                        <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                        <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.cancelOrder===1"class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>  
            </div>
            </div>

            <!-- ---- Completed Orders----- -->
            <div *ngIf="comCustomer" class="container"> 
              <div class="card card-transparent">
            <div  class="card-body">
              <div style="display: none;">{{comcustomerOrder.responseMessage}}</div>
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Order ID </th>
                      <th style="text-align: left;">Customer Info</th>
                      <th style="text-align: left;">Partner Info</th>
                      <th style="text-align: left;">Vehicle Number</th>
                      <!-- <th style="text-align: left;">Delivery Agent Name</th> -->
                      <th style="text-align: left;">Fuel</th>
                      <!-- <th style="text-align: center;">Quantity  in Ltrs</th> -->
                      <th style="text-align: left;">Booked  Date Time </th>
                      <th style="text-align: left;">
                        Delivered  Date Time </th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: center; width: 100px;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let order of comcustomerOrder.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="order.orderId!=null"align="left" class="v-align-middle">{{order.orderId}}</td>
                      <td *ngIf="order.orderId==null"align="left" class="v-align-middle">N/A</td>
                      <td style="word-wrap: break-word;" align="left" class="v-align-middle">
                          {{order.customerId.firstName}} {{order.customerId.lastName}}<br/>
                          {{order.customerId.mobileNumber}}
                      </td>
                      <td style="word-wrap: break-word;" align="left" class="v-align-middle">
                          {{order.vehicleId.userId.firstName}} {{order.vehicleId.userId.lastName}}<br/>
                          {{order.vehicleId.userId.mobileNumber}}
                      </td>
                      <td *ngIf="order.vehicleId.vehicleNumber!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
                      <td *ngIf="order.vehicleId.vehicleNumber==null" align="left" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
                      <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td> -->
                      <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">
                        <span style="font-size: 10px;">{{order.fuelType}}</span>  <br>
                      <span style="font-size: 10px;"><b>Qtn : </b> {{order.quantity}}</span>
                        </td>
                      <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
                      <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td> -->
                      <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                      <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                      <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                      <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                      <td *ngIf="order.status==='Waiting for agent'" align="center" class="v-align-middle ht-inactive">{{order.status}}</td>
                      <td align="center" style="width: 11%;" class="v-align-middle">
                        <button title="View" class="label label-info" (click)="view(order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                        <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                        <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.cancelOrder===1"class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                        <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="invoice(order.id)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
                        <button title="Download Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="download(order.id,order.pdfFilename)" class="label label-success" ><i class="fa fa-download" aria-hidden="true"></i></button>
                        <button title="Send Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="sendMail(order.id)" class="label label-success" ><i class="fa fa-envelope" aria-hidden="true"></i></button>
  
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>  
            </div>
            </div>

            <!-- ---- Cancelled Orders----- -->
            <div *ngIf="canCustomer" class="container"> 
              <div class="card card-transparent">
            <div  class="card-body">
              <div style="display: none;">{{cancustomerOrder.responseMessage}}</div>
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Order ID </th>
                      <th style="text-align: left;">Customer Info</th>
                      <th style="text-align: left;">Partner Info</th>
                      <th style="text-align: left;">Vehicle Number</th>
                      <!-- <th style="text-align: left;">Delivery Agent Name</th> -->
                      <th style="text-align: left;">Fuel</th>
                      <!-- <th style="text-align: center;">Quantity  in Ltrs</th> -->
                      <th style="text-align: left;">Booked  Date Time </th>
                      <th style="text-align: left;">
                        Delivered  Date Time </th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: center; width: 100px;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let order of cancustomerOrder.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="order.orderId!=null"align="left" class="v-align-middle">{{order.orderId}}</td>
                      <td *ngIf="order.orderId==null"align="left" class="v-align-middle">N/A</td>
                      <td style="word-wrap: break-word;" align="left" class="v-align-middle">
                          {{order.customerId.firstName}} {{order.customerId.lastName}}<br/>
                          {{order.customerId.mobileNumber}}
                      </td>
                      <td style="word-wrap: break-word;" align="left" class="v-align-middle">
                          {{order.vehicleId.userId.firstName}} {{order.vehicleId.userId.lastName}}<br/>
                          {{order.vehicleId.userId.mobileNumber}}
                      </td>
                    
                      <td *ngIf="order.vehicleId.vehicleNumber!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
                      <td *ngIf="order.vehicleId.vehicleNumber==null" align="left" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
                      <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td> -->
                      <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">
                        <span style="font-size: 10px;">{{order.fuelType}}</span>  <br>
                      <span style="font-size: 10px;"><b>Qtn : </b> {{order.quantity}}</span>
                        </td>
                      <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
                      <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td> -->
                      <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                      <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                      <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                      <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                      <td *ngIf="order.status==='Waiting for agent'" align="center" class="v-align-middle ht-inactive">{{order.status}}</td>
                      <td align="center" style="width: 30px;" class="v-align-middle">
                        <button title="View" class="label label-info" (click)="view(order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                        <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                        <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.cancelOrder===1"class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>  
            </div>
            </div>

            <!-- ---- All Orders----- -->
            <div *ngIf="allCustomer" class="container"> 
              <div class="card card-transparent">
            <div  class="card-body">
              <div style="display: none;">{{allcustomerOrder.responseMessage}}</div>
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Order ID </th>
                      <th style="text-align: left;">Customer Info</th>
                      <th style="text-align: left;">Partner Info</th>
                      <th style="text-align: left;">Vehicle Number</th>
                      <!-- <th style="text-align: left;">Delivery Agent Name</th> -->
                      <th style="text-align: left;">Fuel</th>
                      <!-- <th style="text-align: center;">Quantity  in Ltrs</th> -->
                      <th style="text-align: left;">Booked  Date Time </th>
                      <th style="text-align: left;">
                        Delivered  Date Time </th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: center; width: 100px;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let order of allcustomerOrder.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="order.orderId!=null"align="left" class="v-align-middle">{{order.orderId}}</td>
                      <td *ngIf="order.orderId==null"align="left" class="v-align-middle">N/A</td>
                    
                      <td style="word-wrap: break-word;" align="left" class="v-align-middle">
                        {{order.customerId.firstName}} {{order.customerId.lastName}}<br/>
                        {{order.customerId.mobileNumber}}
                    </td>
                    <td style="word-wrap: break-word;" align="left" class="v-align-middle">
                        {{order.vehicleId.userId.firstName}} {{order.vehicleId.userId.lastName}}<br/>
                        {{order.vehicleId.userId.mobileNumber}}
                    </td>
                      <td *ngIf="order.vehicleId.vehicleNumber!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
                      <td *ngIf="order.vehicleId.vehicleNumber==null" align="left" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
                      <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td> -->
                      <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">
                        <span style="font-size: 10px;">{{order.fuelType}}</span>  <br>
                      <span style="font-size: 10px;"><b>Qtn : </b> {{order.quantity}}</span>
                        </td>
                      <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
                      <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td> -->
                      <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                      <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                      <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                      <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                      <td *ngIf="order.status==='Waiting for agent'" align="center" class="v-align-middle ht-inactive">{{order.status}}</td>
                      <td align="center" style="width: 11%;" class="v-align-middle">
                        <button title="View" class="label label-info" (click)="view(order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                        <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                        <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.cancelOrder===1"class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                        <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="invoice(order.id)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
                        <button title="Download Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="download(order.id,order.pdfFilename)" class="label label-success" ><i class="fa fa-download" aria-hidden="true"></i></button>
                        <button title="Send Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="sendMail(order.id)" class="label label-success" ><i class="fa fa-envelope" aria-hidden="true"></i></button>
  
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>  
            </div>
            </div>

              <!-- --- Filter Two Start---- -->
<!-- ---- Pending Orders ----  -->
<div *ngIf="penCustomerfilter" class="container"> 
  <div class="card card-transparent">
<div  class="card-body">
  <div style="display: none;">{{pencustomerOrderfilter.responseMessage}}</div>
  <div class="table-responsive">
    <table datatable class="ch-table">
      <thead>
        <tr> 
          <th style="text-align: center; width: 30px;">Sl.No.</th>
          <th style="text-align: left;">Order ID </th>
          <th style="text-align: left;">Customer Info</th>
          <th style="text-align: left;">Partner Info</th>
          <th style="text-align: left;">Vehicle Number</th>
          <!-- <th style="text-align: left;">Delivery Agent Name</th> -->
          <th style="text-align: left;">Fuel</th>
          <!-- <th style="text-align: center;">Quantity  in Ltrs</th> -->
          <th style="text-align: left;">Booked  Date Time </th>
          <th style="text-align: left;">
            Delivered  Date Time </th>
          <th style="text-align: center;">Status</th>
          <th style="text-align: center; width: 100px;">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let order of pencustomerOrderfilter.response; let i=index">
          <td align="center" class="v-align-middle ">{{i + 1}}</td>
          <td *ngIf="order.orderId!=null"align="left" class="v-align-middle">{{order.orderId}}</td>
          <td *ngIf="order.orderId==null"align="left" class="v-align-middle">N/A</td>
            <td style="word-wrap: break-word;" align="left" class="v-align-middle">
              {{order.customerId.firstName}} {{order.customerId.lastName}}<br/>
              {{order.customerId.mobileNumber}}
          </td>
          <td style="word-wrap: break-word;" align="left" class="v-align-middle">
              {{order.vehicleId.userId.firstName}} {{order.vehicleId.userId.lastName}}<br/>
              {{order.vehicleId.userId.mobileNumber}}
          </td>
          <td *ngIf="order.vehicleId.vehicleNumber!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
          <td *ngIf="order.vehicleId.vehicleNumber==null" align="left" class="v-align-middle">N/A</td>
          <!-- <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
          <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td> -->
          <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">
            <span style="font-size: 10px;">{{order.fuelType}}</span>  <br>
                      <span style="font-size: 10px;"><b>Qtn : </b> {{order.quantity}}</span>
            </td>
          <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
          <!-- <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
          <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td> -->
          <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
          <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
          <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
          <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
          <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
          <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
          <td *ngIf="order.status==='Waiting for agent'" align="center" class="v-align-middle ht-inactive">{{order.status}}</td>
          <td align="center" style="width: 30px;" class="v-align-middle">
            <button title="View" class="label label-info" (click)="view(order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
            <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
            <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.cancelOrder===1"class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</div>
</div>

<!-- --- Inprogress Order -----  -->
<div *ngIf="inpCustomerfilter" class="container"> 
  <div class="card card-transparent">
<div  class="card-body">
  <div style="display: none;">{{inpcustomerOrderfilter.responseMessage}}</div>
  <div class="table-responsive">
    <table datatable class="ch-table">
      <thead>
        <tr> 
          <th style="text-align: center;">Sl.No.</th>
          <th style="text-align: left;">Order ID </th>
          <th style="text-align: left;">Customer Info</th>
          <th style="text-align: left;">Partner Info</th>
          <th style="text-align: left;">Vehicle Number</th>
          <!-- <th style="text-align: left;">Delivery Agent Name</th> -->
          <th style="text-align: left;">Fuel</th>
          <!-- <th style="text-align: center;">Quantity  in Ltrs</th> -->
          <th style="text-align: left;">Booked  Date Time </th>
          <th style="text-align: left;">
            Delivered  Date Time </th>
          <th style="text-align: center;">Status</th>
          <th style="text-align: center; width: 100px;">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let order of inpcustomerOrderfilter.response; let i=index">
          <td align="center" class="v-align-middle ">{{i + 1}}</td>
          <td *ngIf="order.orderId!=null"align="left" class="v-align-middle">{{order.orderId}}</td>
          <td *ngIf="order.orderId==null"align="left" class="v-align-middle">N/A</td>
          <td style="word-wrap: break-word;" align="left" class="v-align-middle">
            {{order.customerId.firstName}} {{order.customerId.lastName}}<br/>
            {{order.customerId.mobileNumber}}
        </td>
        <td style="word-wrap: break-word;" align="left" class="v-align-middle">
            {{order.vehicleId.userId.firstName}} {{order.vehicleId.userId.lastName}}<br/>
            {{order.vehicleId.userId.mobileNumber}}
        </td>
          <td *ngIf="order.vehicleId.vehicleNumber!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
          <td *ngIf="order.vehicleId.vehicleNumber==null" align="left" class="v-align-middle">N/A</td>
          <!-- <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
          <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td> -->
          <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">
            <span style="font-size: 10px;">{{order.fuelType}}</span>  <br>
                      <span style="font-size: 10px;"><b>Qtn : </b> {{order.quantity}}</span>
            </td>
          <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
          <!-- <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
          <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td> -->
          <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
          <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
          <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
          <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
          <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
          <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
          <td *ngIf="order.status==='Waiting for agent'" align="center" class="v-align-middle ht-inactive">{{order.status}}</td>
          <td align="center" style="width: 30px;" class="v-align-middle">
            <button title="View" class="label label-info" (click)="view(order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
            <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
            <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.cancelOrder===1"class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>  
</div>
</div>

<!-- ---- Completed Orders----- -->
<div *ngIf="comCustomerfilter" class="container"> 
  <div class="card card-transparent">
<div  class="card-body">
  <div style="display: none;">{{comcustomerOrderfilter.responseMessage}}</div>
  <div class="table-responsive">
    <table datatable class="ch-table">
      <thead>
        <tr> 
          <th style="text-align: center;">Sl.No.</th>
          <th style="text-align: left;">Order ID </th>
          <th style="text-align: left;">Customer Info</th>
          <th style="text-align: left;">Partner Info</th>
          <th style="text-align: left;">Vehicle Number</th>
          <!-- <th style="text-align: left;">Delivery Agent Name</th> -->
          <th style="text-align: left;">Fuel</th>
          <!-- <th style="text-align: center;">Quantity  in Ltrs</th> -->
          <th style="text-align: left;">Booked  Date Time </th>
          <th style="text-align: left;">
            Delivered  Date Time </th>
          <th style="text-align: center;">Status</th>
          <th style="text-align: center; width: 100px;">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let order of comcustomerOrderfilter.response; let i=index">
          <td align="center" class="v-align-middle ">{{i + 1}}</td>
          <td *ngIf="order.orderId!=null"align="left" class="v-align-middle">{{order.orderId}}</td>
          <td *ngIf="order.orderId==null"align="left" class="v-align-middle">N/A</td>
          <td style="word-wrap: break-word;" align="left" class="v-align-middle">
            {{order.customerId.firstName}} {{order.customerId.lastName}}<br/>
            {{order.customerId.mobileNumber}}
        </td>
        <td style="word-wrap: break-word;" align="left" class="v-align-middle">
            {{order.vehicleId.userId.firstName}} {{order.vehicleId.userId.lastName}}<br/>
            {{order.vehicleId.userId.mobileNumber}}
        </td>
          <td *ngIf="order.vehicleId.vehicleNumber!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
          <td *ngIf="order.vehicleId.vehicleNumber==null" align="left" class="v-align-middle">N/A</td>
          <!-- <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
          <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td> -->
          <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">
            <span style="font-size: 10px;">{{order.fuelType}}</span>  <br>
                      <span style="font-size: 10px;"><b>Qtn : </b> {{order.quantity}}</span>
            </td>
          <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
          <!-- <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
          <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td> -->
          <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
          <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
          <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
          <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
          <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
          <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
          <td *ngIf="order.status==='Waiting for agent'" align="center" class="v-align-middle ht-inactive">{{order.status}}</td>
          <td align="center" style="width: 11%;" class="v-align-middle">
            <button title="View" class="label label-info" (click)="view(order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
            <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
            <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.cancelOrder===1"class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
            <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="invoice(order.id)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
            <button title="Download Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="download(order.id,order.pdfFilename)" class="label label-success" ><i class="fa fa-download" aria-hidden="true"></i></button>
            <button title="Send Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="sendMail(order.id)" class="label label-success" ><i class="fa fa-envelope" aria-hidden="true"></i></button>

          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>  
</div>
</div>

<!-- ---- Cancelled Orders----- -->
<div *ngIf="canCustomerfilter" class="container"> 
  <div class="card card-transparent">
<div  class="card-body">
  <div style="display: none;">{{cancustomerOrderfilter.responseMessage}}</div>
  <div class="table-responsive">
    <table datatable class="ch-table">
      <thead>
        <tr> 
          <th style="text-align: center;">Sl.No.</th>
          <th style="text-align: left;">Order ID </th>
          <th style="text-align: left;">Customer Info</th>
          <th style="text-align: left;">Partner Info</th>
          <th style="text-align: left;">Vehicle Number</th>
          <!-- <th style="text-align: left;">Delivery Agent Name</th> -->
          <th style="text-align: left;">Fuel</th>
          <!-- <th style="text-align: center;">Quantity  in Ltrs</th> -->
          <th style="text-align: left;">Booked  Date Time </th>
          <th style="text-align: left;">
            Delivered  Date Time </th>
          <th style="text-align: center;">Status</th>
          <th style="text-align: center; width: 100px;">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let order of cancustomerOrderfilter.response; let i=index">
          <td align="center" class="v-align-middle ">{{i + 1}}</td>
          <td *ngIf="order.orderId!=null"align="left" class="v-align-middle">{{order.orderId}}</td>
          <td *ngIf="order.orderId==null"align="left" class="v-align-middle">N/A</td>
          <td style="word-wrap: break-word;" align="left" class="v-align-middle">
            {{order.customerId.firstName}} {{order.customerId.lastName}}<br/>
            {{order.customerId.mobileNumber}}
          </td>
          <td style="word-wrap: break-word;" align="left" class="v-align-middle">
              {{order.vehicleId.userId.firstName}} {{order.vehicleId.userId.lastName}}<br/>
              {{order.vehicleId.userId.mobileNumber}}
          </td>
          <td *ngIf="order.vehicleId.vehicleNumber!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
          <td *ngIf="order.vehicleId.vehicleNumber==null" align="left" class="v-align-middle">N/A</td>
          <!-- <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
          <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td> -->
          <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">
            <span style="font-size: 10px;">{{order.fuelType}}</span>  <br>
                      <span style="font-size: 10px;"><b>Qtn : </b> {{order.quantity}}</span>
            </td>
          <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
          <!-- <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
          <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td> -->
          <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
          <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
          <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
          <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
          <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
          <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
          <td *ngIf="order.status==='Waiting for agent'" align="center" class="v-align-middle ht-inactive">{{order.status}}</td>
          <td align="center" style="width: 30px;" class="v-align-middle">
            <button title="View" class="label label-info" (click)="view(order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
            <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
            <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.cancelOrder===1"class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>  
</div>
</div>

<!-- ---- All Orders----- -->
<div *ngIf="allCustomerfilter" class="container"> 
  <div class="card card-transparent">
<div  class="card-body">
  <div style="display: none;">{{allcustomerOrderfilter.responseMessage}}</div>
  <div class="table-responsive">
    <table datatable class="ch-table">
      <thead>
        <tr> 
          <th style="text-align: center;">Sl.No.</th>
          <th style="text-align: left;">Order ID </th>
          <th style="text-align: left;">Customer Info</th>
          <th style="text-align: left;">Partner Info</th>
          <th style="text-align: left;">Vehicle Number</th>
          <!-- <th style="text-align: left;">Delivery Agent Name</th> -->
          <th style="text-align: left;">Fuel</th>
          <!-- <th style="text-align: center;">Quantity  in Ltrs</th> -->
          <th style="text-align: left;">Booked  Date Time </th>
          <th style="text-align: left;">
            Delivered  Date Time </th>
          <th style="text-align: center;">Status</th>
          <th style="text-align: center; width: 100px;">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let order of allcustomerOrderfilter.response; let i=index">
          <td align="center" class="v-align-middle ">{{i + 1}}</td>
          <td *ngIf="order.orderId!=null"align="left" class="v-align-middle">{{order.orderId}}</td>
          <td *ngIf="order.orderId==null"align="left" class="v-align-middle">N/A</td>
          <td style="word-wrap: break-word;" align="left" class="v-align-middle">
            {{order.customerId.firstName}} {{order.customerId.lastName}}<br/>
            {{order.customerId.mobileNumber}}
        </td>
        <td style="word-wrap: break-word;" align="left" class="v-align-middle">
            {{order.vehicleId.userId.firstName}} {{order.vehicleId.userId.lastName}}<br/>
            {{order.vehicleId.userId.mobileNumber}}
        </td>
          <td *ngIf="order.vehicleId.vehicleNumber!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
          <td *ngIf="order.vehicleId.vehicleNumber==null" align="left" class="v-align-middle">N/A</td>
          <!-- <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
          <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td> -->
          <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">
            <span style="font-size: 10px;">{{order.fuelType}}</span>  <br>
                      <span style="font-size: 10px;"><b>Qtn : </b> {{order.quantity}}</span>
            </td>
          <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
          <!-- <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
          <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td> -->
          <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
          <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
          <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
          <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
          <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
          <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
          <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
          <td *ngIf="order.status==='Waiting for agent'" align="center" class="v-align-middle ht-inactive">{{order.status}}</td>
          <td align="center" style="width: 11%;" class="v-align-middle">
            <button title="View" class="label label-info" (click)="view(order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
            <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
            <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.cancelOrder===1"class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
            <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="invoice(order.id)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
            <button title="Download Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="download(order.id,order.pdfFilename)" class="label label-success" ><i class="fa fa-download" aria-hidden="true"></i></button>
            <button title="Send Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="sendMail(order.id)" class="label label-success" ><i class="fa fa-envelope" aria-hidden="true"></i></button>

          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>  
</div>
</div>


              <!-- ----- Filter Two End ----  -->

              <div *ngIf="onboardedfilter1" class="container"> 
                <div class="card card-transparent">
              <div  class="card-body">
                <div style="display: none;">{{onboardedcustomerOrderfilter1.responseMessage}}</div>
                <div class="table-responsive">
                  <table datatable class="ch-table">
                    <thead>
                      <tr> 
                        <th style="text-align: center;">Sl.No.</th>
                        <th style="text-align: left;">Order ID </th>
                        <th style="text-align: left;">Customer Info</th>
                        <th style="text-align: left;">Partner Info</th>
                        <th style="text-align: left;">Vehicle Number</th>
                        <!-- <th style="text-align: left;">Delivery Agent Name</th> -->
                        <th style="text-align: left;">Fuel</th>
                        <!-- <th style="text-align: center;">Quantity  in Ltrs</th> -->
                        <th style="text-align: left;">Booked  Date Time </th>
                        <th style="text-align: left;">
                          Delivered  Date Time </th>
                        <th style="text-align: center;">Status</th>
                        <th style="text-align: center; width: 100px;">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let order of onboardedcustomerOrderfilter1.response; let i=index">
                        <td align="center" class="v-align-middle ">{{i + 1}}</td>
                        <td *ngIf="order.orderId!=null"align="left" class="v-align-middle">{{order.orderId}}</td>
                        <td *ngIf="order.orderId==null"align="left" class="v-align-middle">N/A</td>
                        <td style="word-wrap: break-word;" align="left" class="v-align-middle">
                            {{order.customerId.firstName}} {{order.customerId.lastName}}<br/>
                            {{order.customerId.mobileNumber}}
                        </td>
                        <td style="word-wrap: break-word;" align="left" class="v-align-middle">
                            {{order.vehicleId.userId.firstName}} {{order.vehicleId.userId.lastName}}<br/>
                            {{order.vehicleId.userId.mobileNumber}}
                        </td>
                        <td *ngIf="order.vehicleId.vehicleNumber!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
                        <td *ngIf="order.vehicleId.vehicleNumber==null" align="left" class="v-align-middle">N/A</td>
                        <!-- <td *ngIf="order.orderAcceptedBy!=null" style="word-wrap: break-word;" align="left" class="v-align-middle">{{order.orderAcceptedBy.firstName}} {{order.orderAcceptedBy.lastName}}</td>
                        <td *ngIf="order.orderAcceptedBy==null" align="left" class="v-align-middle">N/A</td> -->
                        <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">
                          <span style="font-size: 10px;">{{order.fuelType}}</span>  <br>
                      <span style="font-size: 10px;"><b>Qtn : </b> {{order.quantity}}</span>
                          </td>
                        <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                        <!-- <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
                        <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td> -->
                        <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                        <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                        <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                        <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                        <td *ngIf="order.status==='Waiting for agent'" align="center" class="v-align-middle ht-inactive">{{order.status}}</td>
                        <td align="center" style="width: 11%;" class="v-align-middle">
                          <button title="View" class="label label-info" (click)="view(order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                          <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                          <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.cancelOrder===1"class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                          <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="invoice(order.id)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
                          <button title="Download Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="download(order.id,order.pdfFilename)" class="label label-success" ><i class="fa fa-download" aria-hidden="true"></i></button>
                          <button title="Send Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="sendMail(order.id)" class="label label-success" ><i class="fa fa-envelope" aria-hidden="true"></i></button>
                            </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>  
              </div>
              </div>

              <div *ngIf="onboardedfilter2" class="container"> 
                <div class="card card-transparent">
              <div  class="card-body">
                <div style="display: none;">{{onboardedcustomerOrderfilter2.responseMessage}}</div>
                <div class="table-responsive">
                  <table datatable class="ch-table">
                    <thead>
                      <tr> 
                        <th style="text-align: center;">Sl.No.</th>
                        <th style="text-align: left;">Order ID </th>
                        <th style="text-align: left;">Customer Info</th>
                        <th style="text-align: left;">Partner Info</th>
                        <th style="text-align: left;">Vehicle Number</th>
                        <th style="text-align: left;">Fuel</th>
                        <!-- <th style="text-align: center;">Quantity  in Ltrs</th> -->
                        <th style="text-align: left;">Booked  Date Time </th>
                        <th style="text-align: left;">
                          Delivered  Date Time </th>
                        <th style="text-align: center;">Status</th>
                        <th style="text-align: center; width: 100px;">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let order of onboardedcustomerOrderfilter2.response; let i=index">
                        <td align="center" class="v-align-middle ">{{i + 1}}</td>
                        <td *ngIf="order.orderId!=null"align="left" class="v-align-middle">{{order.orderId}}</td>
                        <td *ngIf="order.orderId==null"align="left" class="v-align-middle">N/A</td>
                        <td style="word-wrap: break-word;" align="left" class="v-align-middle">
                          {{order.customerId.firstName}} {{order.customerId.lastName}}<br/>
                          {{order.customerId.mobileNumber}}
                      </td>
                      <td style="word-wrap: break-word;" align="left" class="v-align-middle">
                          {{order.vehicleId.userId.firstName}} {{order.vehicleId.userId.lastName}}<br/>
                          {{order.vehicleId.userId.mobileNumber}}
                      </td>
                        <td *ngIf="order.vehicleId.vehicleNumber!=null" align="left" class="v-align-middle">{{order.vehicleId.vehicleNumber}}</td>
                        <td *ngIf="order.vehicleId.vehicleNumber==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.fuelType!=null" align="center" class="v-align-middle">
                          <span style="font-size: 10px;">{{order.fuelType}}</span>  <br>
                      <span style="font-size: 10px;"><b>Qtn : </b> {{order.quantity}}</span>
                          </td>
                        <td *ngIf="order.fuelType==null" align="center" class="v-align-middle">N/A</td>
                        <!-- <td *ngIf="order.quantity!=null" align="center" class="v-align-middle">{{order.quantity}}</td>
                        <td *ngIf="order.quantity==null" align="center" class="v-align-middle">N/A</td> -->
                        <td *ngIf="order.orderDatetime!=null" align="left" class="v-align-middle">{{order.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDatetime==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.orderDeliveredDateTime!=null" align="left" class="v-align-middle">{{order.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                        <td *ngIf="order.orderDeliveredDateTime==null" align="left" class="v-align-middle">N/A</td>
                        <td *ngIf="order.status==='Pending'" align="center" class="v-align-middle ht-Pending">{{order.status}}</td>
                        <td *ngIf="order.status==='Cancelled'" align="center" style="color: red;">{{order.status}}</td>
                        <td *ngIf="order.status==='Inprogress'" align="center" style="color: blue;">{{order.status}}</td>
                        <td *ngIf="order.status==='Delivered'" align="center" class="v-align-middle ht-active">{{order.status}}</td>
                        <td *ngIf="order.status==='Waiting for agent'" align="center" class="v-align-middle ht-inactive">{{order.status}}</td>
                        <td align="center" style="width: 11%" class="v-align-middle">
                          <button title="View" class="label label-info" (click)="view(order.id)" ><i class="fa fa-eye" aria-hidden="true"></i></button>
                          <button title="Assign Vehicle" style="margin-left: 5px;" *ngIf="order.orderStatus==='Pending' "class="label label-info" (click)="open1(content1,order.id)" ><i class="fa fa-user" aria-hidden="true"></i></button>
                          <button title="Cancel Order" style="margin-left: 5px;" *ngIf="order.cancelOrder===1"class="label label-info" (click)="cancelPopup(content5,order.id)" ><i class="fa fa-times" aria-hidden="true"></i></button>
                          <button title="View Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="invoice(order.id)" class="label label-success" ><i class="fa fa-sticky-note-o" aria-hidden="true"></i></button>
                          <button title="Download Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="download(order.id,order.pdfFilename)" class="label label-success" ><i class="fa fa-download" aria-hidden="true"></i></button>
                          <button title="Send Invoice" style="margin-left: 5px;" *ngIf="order.status==='Delivered'" (click)="sendMail(order.id)" class="label label-success" ><i class="fa fa-envelope" aria-hidden="true"></i></button>
    
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>  
              </div>
              </div>

          <!-- </div> -->
          <!-- END card --> 
        <!-- </div> -->
      </div>
      <!-- END CONTAINER FLUID --> 
    </div>
  </div>
  <!-- END PAGE CONTENT WRAPPER --> 
</div>
<!-- END PAGE CONTAINER --> 


<ng-template #content1 let-modal>
  <div class="modal-header">
    <h5>Assign Vehicle</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="container"
  fxLayout
>

<div class="item item-1">
  <p><b>Order ID</b></p>
  <span>{{orderDetails.response.orderId}}</span>
</div>
<div class="item item-2" style="margin-left: 25px;">
  <p><b>Customer Name</b></p>
  <span style="word-wrap: break-word;">{{orderDetails.response.customerId.firstName}} {{orderDetails.response.customerId.lastName}}</span>
</div>
<div class="item item-3" style="margin-left: 25px;">
  <p><b>Customer Mobile</b></p>
  <span>{{orderDetails.response.customerId.mobileNumber}}</span>
</div>
<div class="item item-4" style="margin-left: 25px;">
  <p><b>Status</b></p>
  <span *ngIf="orderDetails.response.status==='Pending'"><span class="ht-Pending"><b>{{orderDetails.response.status}}</b></span></span>
  <span *ngIf="orderDetails.response.status==='Cancelled'"><span style="color: red;"><b>{{orderDetails.response.status}}</b></span></span>
  <span *ngIf="orderDetails.response.status==='Inprogress'"><span style="color: blue;"><b>{{orderDetails.response.status}}</b></span></span>
  <span *ngIf="orderDetails.response.status==='Completed'"><span class="ht-active"><b>{{orderDetails.response.status}}</b></span></span>
</div>
<div class="item item-5" style="margin-left: 25px;">
  <p><b>Booking Date & Time</b></p>
  <span>{{orderDetails.response.orderDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span>
</div>
<div class="item item-6" style="margin-left: 25px;">
  <p><b>Delivery Date & Time</b></p>
  <span>N/A</span>
</div>
<div class="item item-7" style="margin-left: 25px;">
  <p><b>Fuel</b></p>
  <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.fuelType.fuelTypeName}}</span>
      <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
</div>
<div class="item item-8" style="margin-left: 25px;">
  <p><b>Quantity in Ltrs</b></p>
  <span >{{orderDetails.response.quantity}}</span>
</div>
<br>
  </div>
  <br>
  <div class="container"
  fxLayout
>
  <div class="item item-1">
    <p><b>Vehicle Number</b></p>
    <div class="ch-form-group">
    <select id="countryFormControl" value="countryFormControl" class="form-control" #mySelect (change)='onOptionsSelected(mySelect.value)'>
      <option *ngFor="let vehicle of activeVehicle.response" value={{vehicle.vehicleId}}>{{vehicle.vehicleNumber}} ({{vehicle.userId.firstName}} {{vehicle.userId.lastName}})</option>
    </select>
  </div>
  </div>
  <div class="item item-2" style="margin-left: 25px;">
    <p><b>Delivery Agent Mobile</b></p>
    <span *ngIf="check==null">
      <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.userId.firstName}} {{orderDetails.response.vehicleId.userId.lastName}}</span>
      <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
    </span>
    <span *ngIf="check!=null">{{vehicle.userId.mobileNumber}}</span>
  </div>
  <div class="item item-3" style="margin-left: 25px;">
    <p><b>Delivery Agent Email</b></p>
    <span *ngIf="check==null">
      <span *ngIf="orderDetails.response.vehicleId!=null">{{orderDetails.response.vehicleId.userId.mobileNumber}}</span>
          <span *ngIf="orderDetails.response.vehicleId==null">N/A</span>
    </span>
    <span *ngIf="check!=null">{{vehicle.userId.emailAddress}}</span>
  </div>
  <div class="item item-4" style="margin-left: 25px;">
    <p><b>Vehicle Number</b></p>
    <span *ngIf="check==null">{{orderDetails.response.vehicleId.vehicleNumber}}</span>
    <span *ngIf="check!=null">{{vehicle.vehicleNumber}}</span>
  </div>
  <div class="item item-5" style="margin-left: 25px;">
    <p><b>Delivery location</b></p>
    <span>{{orderDetails.response.deliveryAddress}}</span>
  </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="assign()" (click)="modal.close('Save click')">Assign</button>
  </div>
</ng-template>

<ng-template #content5 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Reason for cancellation of Order</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
  <form>
      <br>
      <div class="ch-form-group">
        <div class="dropdown" style="width: 450px; margin-left: -3px;">
          <select id="cancelmessage"  name="cancelmessage" class="form-control">
            <option *ngFor="let cancel of cancelMessageModel.response" value={{cancel.id}}>{{cancel.cancellationMessage}}</option>
          </select></div>
      </div>	
      <br>
      <div class="ch-form-group">
           <textarea placeholder="Should not exceed more than 150 characters" class="form-control textarea" name="cancelremarks" maxlength="150" id="cancelremarks" rows="3"></textarea>
      </div>
      <br>
    <b>Note</b> : <span style="color: grey;">Should not exceed more than 150 characters</span> 
      </form>
      <br>
      <div *ngIf="errorFlag1" style="color: red;">{{errorMessage}}</div>
    </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" style="color: green;" (click)="cancelOrder()">Submit</button>
  </div>
</ng-template>

<ng-template #content3 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Additional Charges</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
  <form>
      <br>
      <div class="form-group" >
        <label for="dateOfBirth">Charges Title *</label>
            <input type="text" class="select-form-style" style="margin-left: 10px; " name="chargesTitle" id="chargesTitle" />
      </div>
      <br>
      <div class="form-group" >
        <label for="dateOfBirth">Additional Charges *</label>
            <input type="text" class="select-form-style" style="margin-left: 10px; " name="additionalCharges" id="additionalCharges" (keypress)="allowNumericDigitsOnlyOnKeyUp($event)"/>
      </div>
    
      </form>
      <br>
      <div *ngIf="errorFlag1" style="color: red;">{{errorMessage}}</div>
    </div>
    <hr class="beautiful-line">
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" style="color: green;" (click)="addCharges()">Submit</button>
  </div>
</ng-template>
<ng-template #content6 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">View Additional Charges</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body" *ngFor="let charges of chargesResponse.response;">
  <form>
      <br>
      <div class="form-group" >
        <label for="dateOfBirth">Charges Title : </label>
        {{charges.chargesTitle}}
      </div>
      <br>
      <div class="form-group" >
        <label for="dateOfBirth">Additional Charges : </label>
        {{charges.additionalCharges}}
      </div>
    
      </form>
      <br>
    
    </div>
    <hr class="beautiful-line">
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" style="color: green;" (click)="openUpdateContent(content7,chargesResponse.response[0].chargesTitle,chargesResponse.response[0].additionalCharges,chargesResponse.response[0].id)">Update</button>
      <button type="button" class="btn btn-outline-dark" style="color: red;" (click)="delete(chargesResponse.response[0].id)">Delete</button>
    </div>
</ng-template>
<ng-template #content7 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Additional Charges</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
  <form>
      <br>
      <div class="form-group" >
        <label for="dateOfBirth">Charges Title *</label>
            <input type="text" class="select-form-style" style="margin-left: 10px; " name="chargesTitle" id="chargesTitle" [(ngModel)]="chargesTitle"/>
      </div>
      <br>
      <div class="form-group" >
        <label for="dateOfBirth">Additional Charges *</label>
            <input type="text" class="select-form-style" style="margin-left: 10px; " name="additionalCharges" id="additionalCharges" [(ngModel)]="additionalCharges" (keypress)="allowNumericDigitsOnlyOnKeyUp($event)"/>
      </div>
    
      </form>
      <br>
      <div *ngIf="errorFlag1" style="color: red;">{{errorMessage}}</div>
    </div>
    <hr class="beautiful-line">
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" style="color: green;" (click)="updateCharges(chargesId)">Submit</button>
  </div>
</ng-template>
<router-outlet></router-outlet>
