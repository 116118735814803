import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RoleMenuMappingComponent } from './role-menu-mapping/role-menu-mapping.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { CustomersComponent } from './customers/customers.component';
import { PartnersComponent } from './partners/partners.component';
import { PartnersViewPageComponent } from './partners-view-page/partners-view-page.component';
import { AddUserComponent } from './add-user/add-user.component';
import { UserManagementViewComponent } from './user-management-view/user-management-view.component';
import { RoleMenuMappingEditComponent } from './role-menu-mapping-edit/role-menu-mapping-edit.component';
import { CustomersViewComponent } from './customers-view/customers-view.component';
import { AgentsComponent } from './agents/agents.component';
import { AgentsViewComponent } from './agents-view/agents-view.component';
import { PartnersKycComponent } from './partners-kyc/partners-kyc.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordOtpVerificationComponent } from './forgot-password-otp-verification/forgot-password-otp-verification.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { PartnersKycViewComponent } from './partners-kyc-view/partners-kyc-view.component';
import { PartnersBankComponent } from './partners-bank/partners-bank.component';
import { VehiclesFuelsComponent } from './vehicles-fuels/vehicles-fuels.component';
import { FuelPriceResetHistoryComponent } from './fuel-price-reset-history/fuel-price-reset-history.component';
import { TransactionComponent } from './transaction/transaction.component';
import { SettlementComponent } from './settlement/settlement.component';
import { OrderComponent } from './order/order.component';
import { MasterBankComponent } from './master-bank/master-bank.component';
import { MasterDepartmentComponent } from './master-department/master-department.component';
import { MasterRoleComponent } from './master-role/master-role.component';
import { MasterFuelRateComponent } from './master-fuel-rate/master-fuel-rate.component';
import { MasterFuelTypeComponent } from './master-fuel-type/master-fuel-type.component';
import { MasterKycDocumentComponent } from './master-kyc-document/master-kyc-document.component';
import { MasterChargesConfigurationComponent } from './master-charges-configuration/master-charges-configuration.component';
import { MasterCreditComponent } from './master-credit/master-credit.component';
import { ReportCustomerCreditComponent } from './report-customer-credit/report-customer-credit.component';
import { ReportFuelRefillBalanceComponent } from './report-fuel-refill-balance/report-fuel-refill-balance.component';
import { ReportOrderComponent } from './report-order/report-order.component';
import { ReportRevenueComponent } from './report-revenue/report-revenue.component';
import { ReportSmsEmailCreditsComponent } from './report-sms-email-credits/report-sms-email-credits.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { ComplaintPolicyComponent } from './complaint-policy/complaint-policy.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { FaqQrComponent } from './faq-qr/faq-qr.component';
import { HelpSupportComponent } from './help-support/help-support.component';
import { ProfileChangePasswordComponent } from './profile-change-password/profile-change-password.component';
import { AuthGuard } from './auth/authguard';
import { RoleMenuMappingCreateComponent } from './role-menu-mapping-create/role-menu-mapping-create.component';
import { ReviewRatingComponent } from './review-rating/review-rating.component';
import { MasterFuelRateListComponent } from './master-fuel-rate-list/master-fuel-rate-list.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { PartnersKycVehicleViewComponent } from './partners-kyc-vehicle-view/partners-kyc-vehicle-view.component';
import { PartnersKycBankComponent } from './partners-kyc-bank/partners-kyc-bank.component';
import { CustomersKycBankComponent } from './customers-kyc-bank/customers-kyc-bank.component';
import { ReportStockComponent } from './report-stock/report-stock.component';
import { ReportRefundComponent } from './report-refund/report-refund.component';
import { ReportPartnerSettlementsComponent } from './report-partner-settlements/report-partner-settlements.component';
import { ReportReadytoSettlementsComponent } from './report-readyto-settle/report-readyto-settlements.component';
import { InvoiceGenerationComponent } from './invoice-generation/invoice-generation.component';
import { MasterCouponComponent } from './master-coupon/master-coupon.component';
import { MasterPromotionsComponent } from './master-promotions/master-promotions.component';
import { VehiclesViewComponent } from './vehicles-view/vehicles-view.component';
import { PageNotFoundComponent } from './page-not-found/pagenotfound.component';
import { PageErrorComponent } from './page-error/pageerror.component';
import { MasterHsnCodeComponent } from './master-hsncode/master-hsncode.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { InvoiceGenerationPartnerComponent } from './invoice-generation-partner/invoice-generation-partner.component';
import { ReportPartnerMonthlyInvoiceComponent } from './report-partner-monthly-invoice/report-partner-monthly-invoice.component';
import { InvoiceGenerationPartnerMonthlyComponent } from './invoice-generation-partner-monthly/invoice-generation-partner-monthly.component';
import { InvoiceGenerationRefundComponent } from './invoice-generation-refund/invoice-generation-refund.component';
import { ReportPlatformInvoiceComponent } from './report-platform-invoice/report-platform-invoice.component';
import { InvoiceGenerationPlatformComponent } from './invoice-generation-platform/invoice-generation-platform.component';
import { PartnerPaymentModeComponent } from './paymentmode-partner-mapping/paymentmode-partner-mapping.component';
import { PaymentmodeComponent } from './paymentmode/paymentmode.component';
import { AssettrackingComponent } from './assettracking/assettracking.component';
import { OrderdetailsComponent } from './orderdetails/orderdetails.component';

const routes: Routes = [
  {path: '', redirectTo:'/login', pathMatch: 'full'},
  { path: 'header', component: HeaderComponent},
  { path: 'footer', component: FooterComponent},
  { path: 'login', component: LoginComponent},
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'role-menu-mapping', component: RoleMenuMappingComponent ,canActivate: [AuthGuard]},
  { path: 'user-management', component: UserManagementComponent,canActivate: [AuthGuard] },
  { path: 'customers', component: CustomersComponent,canActivate: [AuthGuard] },
  { path: 'partners', component: PartnersComponent ,canActivate: [AuthGuard]},
  { path: 'partners-view-page', component: PartnersViewPageComponent ,canActivate: [AuthGuard]},
  { path: 'add-user', component: AddUserComponent ,canActivate: [AuthGuard]},
  { path: 'user-management-view', component: UserManagementViewComponent ,canActivate: [AuthGuard]},
  { path: 'role-menu-mapping-edit', component: RoleMenuMappingEditComponent ,canActivate: [AuthGuard]},
  { path: 'customers-view', component: CustomersViewComponent,canActivate: [AuthGuard] },
  { path: 'agents', component: AgentsComponent,canActivate: [AuthGuard] },
  { path: 'agents-view', component: AgentsViewComponent,canActivate: [AuthGuard] },
  { path: 'partners-kyc', component: PartnersKycComponent,canActivate: [AuthGuard] },
  { path: 'forgot-password', component: ForgotPasswordComponent},
  { path: 'forgot-password-otp-verification', component: ForgotPasswordOtpVerificationComponent},
  { path: 'change-password', component: ChangePasswordComponent},
  { path: 'partners-kyc-view', component: PartnersKycViewComponent,canActivate: [AuthGuard] },
  { path: 'partners-bank', component: PartnersBankComponent,canActivate: [AuthGuard] },
  { path: 'vehicles-fuels', component: VehiclesFuelsComponent,canActivate: [AuthGuard] },
  { path: 'fuel-price-reset-history', component: FuelPriceResetHistoryComponent,canActivate: [AuthGuard] },
  { path: 'transaction', component: TransactionComponent ,canActivate: [AuthGuard]},
  { path: 'settlement', component: SettlementComponent ,canActivate: [AuthGuard]},
  { path: 'order', component: OrderComponent ,canActivate: [AuthGuard]},
  { path: 'master-bank', component: MasterBankComponent ,canActivate: [AuthGuard]},
  { path: 'master-department', component: MasterDepartmentComponent ,canActivate: [AuthGuard]},
  { path: 'master-role', component: MasterRoleComponent ,canActivate: [AuthGuard]},
  { path: 'master-fuel-rate', component: MasterFuelRateComponent,canActivate: [AuthGuard] },
  { path: 'master-fuel-type', component: MasterFuelTypeComponent ,canActivate: [AuthGuard]},
  { path: 'master-kyc-document', component: MasterKycDocumentComponent,canActivate: [AuthGuard] },
  { path: 'master-charges-configuration', component: MasterChargesConfigurationComponent ,canActivate: [AuthGuard]},
  { path: 'master-credit', component: MasterCreditComponent ,canActivate: [AuthGuard]},
  { path: 'report-customer-credit', component: ReportCustomerCreditComponent ,canActivate: [AuthGuard]},
  { path: 'view-settled-report', component: ReportFuelRefillBalanceComponent ,canActivate: [AuthGuard]},
  { path: 'settled-report', component: ReportOrderComponent ,canActivate: [AuthGuard]},
  { path: 'report-revenue', component: ReportRevenueComponent,canActivate: [AuthGuard] },
  { path: 'report-sms-email-credits', component: ReportSmsEmailCreditsComponent,canActivate: [AuthGuard] },
  { path: 'terms-conditions', component: TermsConditionsComponent ,canActivate: [AuthGuard]},
  { path: 'customer-Declaration', component: ComplaintPolicyComponent},
  { path: 'partner-Declaration', component: DisclaimerComponent},
  { path: 'faq-qr', component: FaqQrComponent ,canActivate: [AuthGuard]},
  { path: 'help-support', component: HelpSupportComponent,canActivate: [AuthGuard] },
  { path: 'profile-change-password', component: ProfileChangePasswordComponent ,canActivate: [AuthGuard]},
  { path: 'role-menu-mapping-create', component: RoleMenuMappingCreateComponent,canActivate: [AuthGuard] },
  { path: 'review-rating', component: ReviewRatingComponent,canActivate: [AuthGuard] },
  { path: 'master-fuel-rate-list', component: MasterFuelRateListComponent,canActivate: [AuthGuard] },
  { path: 'edit-user', component: EditUserComponent ,canActivate: [AuthGuard]},
  { path: 'partners-kyc-vehicle-view', component: PartnersKycVehicleViewComponent ,canActivate: [AuthGuard]},
  { path: 'partners-kyc-bank', component: PartnersKycBankComponent ,canActivate: [AuthGuard]},
  { path: 'customers-kyc-bank', component: CustomersKycBankComponent ,canActivate: [AuthGuard]},
  { path: 'report-stock', component: ReportStockComponent ,canActivate: [AuthGuard]},
  { path: 'report-refund', component: ReportRefundComponent ,canActivate: [AuthGuard]},
  { path: 'report-partner-settlements', component: ReportPartnerSettlementsComponent ,canActivate: [AuthGuard]},
  { path: 'report-readyto-settlements', component: ReportReadytoSettlementsComponent ,canActivate: [AuthGuard]},
  { path: 'invoice-generation', component: InvoiceGenerationComponent ,canActivate: [AuthGuard]},
  { path: 'master-coupon', component: MasterCouponComponent ,canActivate: [AuthGuard]},
  { path: 'master-promotions', component: MasterPromotionsComponent ,canActivate: [AuthGuard]},
  { path: 'vehicles-view', component: VehiclesViewComponent ,canActivate: [AuthGuard]},
  { path: 'pagenotfound', component: PageNotFoundComponent},
  { path: 'page-error', component: PageErrorComponent},
  { path: 'master-hsncode', component: MasterHsnCodeComponent,canActivate: [AuthGuard]},
  { path: 'refund-policy', component: RefundPolicyComponent},
  { path: 'invoice-generation-partner', component: InvoiceGenerationPartnerComponent ,canActivate: [AuthGuard]},
  { path: 'report-partner-monthly-invoice', component: ReportPartnerMonthlyInvoiceComponent ,canActivate: [AuthGuard]},
  { path: 'invoice-generation-partner-monthly', component: InvoiceGenerationPartnerMonthlyComponent ,canActivate: [AuthGuard]},
  { path: 'invoice-generation-refund', component: InvoiceGenerationRefundComponent ,canActivate: [AuthGuard]},
  { path: 'report-platform-invoice', component: ReportPlatformInvoiceComponent ,canActivate: [AuthGuard]},
  { path: 'invoice-generation-platform', component: InvoiceGenerationPlatformComponent ,canActivate: [AuthGuard]},
  { path: 'paymentmode-partners', component: PartnerPaymentModeComponent ,canActivate: [AuthGuard]},
  { path: 'paymentmode', component: PaymentmodeComponent ,canActivate: [AuthGuard]},
  { path: 'assettracking', component: AssettrackingComponent ,canActivate: [AuthGuard]},
  { path: 'orderdetails', component: OrderdetailsComponent ,canActivate: [AuthGuard]},


  {path: '**', redirectTo: '/pagenotfound'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers:[
    AuthGuard
],

  
})
export class AppRoutingModule { }
