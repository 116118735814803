
<app-header></app-header>

<!-- START PAGE-CONTAINER -->

<div class="page-container "> 
  <!-- START PAGE CONTENT WRAPPER -->
  <div class="page-content-wrapper "> 
    <!-- START PAGE CONTENT -->
    <div class="content sm-gutter"> 
      <!-- START BREADCRUMBS -->
      <div class="bg-white">
        <div class="container">
          <ol class="breadcrumb breadcrumb-alt">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item active">Customers </li>
          </ol>
        </div>
      </div>
      <!-- END BREADCRUMBS -->
      
      <div class="container Customers-top-notification">
        
        
        <div class="Customers-search-section" style="display: none;">
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Search By Cust.ID
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="#">Search By Cust. Name</a>
              <a class="dropdown-item" href="#">Search By Cust. Mobile</a>
              <a class="dropdown-item" href="#">Search By Cust. Email</a>
              <a class="dropdown-item" href="#">Account Status</a>
              <a class="dropdown-item" href="#">OTP Verification Status</a>
            </div>
          </div>
       	<div class="form-group">
           <input type="email" class="form-control" placeholder="Search By Cust.ID" required="">
         </div>
         <!-- <div class="form-group">
           <input type="email" class="form-control" placeholder="Search By Cust. Name" required="">
         </div>
         <div class="form-group">
           <input type="email" class="form-control" placeholder="Search By Cust. Mobile" required="">
         </div>
         <div class="form-group">
           <input type="email" class="form-control" placeholder="Search By Cust. Email " required="">
         </div>
         <div class="form-group">
           <input type="email" class="form-control" placeholder="Account Status " required="">
         </div>
         <div class="form-group">
           <input type="email" class="form-control" placeholder="OTP Verification Status" required="">
         </div> -->
<div class="search-btn-wrraper">
         <button class="btn btn-primary btn-cons">Search</button></div>
       </div>
       
        
      </div>
      
			
      
      <!-- START CONTAINER FLUID --> 
      
      <!-- START CONTAINER FLUID -->
      <div class=" no-padding    container-fixed-lg bg-white">
        <div class="container"> 
          <!-- START card -->
          <div class="card card-transparent">
            <div class="card-header ">
              
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-hover" id="basicTable">
                  <thead>
                    <tr> 
                      <!-- NOTE * : Inline Style Width For Table Cell is Required as it may differ from user to user
    										Comman Practice Followed
    										-->
                      <th width="40" style="width:15px">Sl.No.</th>
                      <th width="129" style="width:200px">Customer Id</th>
                      <th width="129" style="width:200px">Customer Names</th>
                      <th width="96" style="width:150px;"><span style="width:200px">Customer</span> Mobile</th>
                      <th width="129" style="width:150px;"><span style="width:15%">Fuel</span></th>
                      <th width="56" style="width:100px">Total No. Of Order Placed</th>
                      <th width="56" style="width:100px">Total No. Of Order Completed</th>
                      <th width="56" style="width:220px"><span style="width:100px">Total Earned Credied</span></th>
                      <th width="56" style="width:220px">Last Order<br>
                      Booked  Date &amp; Time </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="v-align-middle "><p>1</p></td>
                      <td class="v-align-middle">OID00993</td>
                      <td class="v-align-middle"><p>Anand Raj</p></td>
                      <td class="v-align-middle">9876543210</td>
                      <td class="v-align-middle">Diesel</td>
                      <td class="v-align-middle ">20</td>
                      <td class="v-align-middle ">18</td>
                      <td class="v-align-middle ">180</td>
                      <td class="v-align-middle "><span class="v-align-middle">21/09/2020 &amp; 09:00 am</span></td>
                    </tr>
                    <tr>
                      <td class="v-align-middle "><p>2</p></td>
                      <td class="v-align-middle">OID00993</td>
                      <td class="v-align-middle"><p>Babu</p></td>
                      <td class="v-align-middle">9876543210</td>
                      <td class="v-align-middle">Diesel</td>
                      <td class="v-align-middle ">30</td>
                      <td class="v-align-middle "><span class="v-align-middle ">18</span></td>
                      <td class="v-align-middle ">200</td>
                      <td class="v-align-middle "><span class="v-align-middle">21/09/2020 &amp; 09:00 am</span></td>
                    </tr>
                    <tr>
                      <td class="v-align-middle "><p>3</p></td>
                      <td class="v-align-middle">OID00993</td>
                      <td class="v-align-middle"><p>Ram</p></td>
                      <td class="v-align-middle">9876543210</td>
                      <td class="v-align-middle">Diesel</td>
                      <td class="v-align-middle">39</td>
                      <td class="v-align-middle"><span class="v-align-middle ">18</span></td>
                      <td class="v-align-middle">390</td>
                      <td class="v-align-middle">21/09/2020 &amp; 09:00 am</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- END card --> 
        </div>
      </div>
      <!-- END CONTAINER FLUID --> 
      
    </div>
    <!-- END PAGE CONTENT --> 
    <!-- START COPYRIGHT --> 
    <!-- START CONTAINER FLUID --> 
    <!-- START CONTAINER FLUID -->
  <!--   <div class=" container   container-fixed-lg footer">
      <div class="copyright sm-text-center">
        <p class="small-text no-margin pull-left sm-pull-reset"> ©2014-2020 All Rights Reserved. Pages® and/or its subsidiaries or affiliates are registered trademark of Revox Ltd. <span class="hint-text m-l-15">Pages v05.23 20201105.r.190</span> </p>
        <p class="small no-margin pull-right sm-pull-reset"> Hand-crafted <span class="hint-text">&amp; made with Love</span> </p>
        <div class="clearfix"></div>
      </div>
    </div> -->
    <!-- END COPYRIGHT --> 
  </div>
  <!-- END PAGE CONTENT WRAPPER --> 
</div>
<!-- END PAGE CONTAINER --> 
<!--START QUICKVIEW -->
<div id="quickview" class="quickview-wrapper" data-pages="quickview"> 
  <!-- Nav tabs -->
  <ul class="nav nav-tabs" role="tablist">
    <li class=""> <a href="#quickview-notes" data-target="#quickview-notes" data-toggle="tab" role="tab">Notes</a> </li>
    <li> <a href="#quickview-alerts" data-target="#quickview-alerts" data-toggle="tab" role="tab">Alerts</a> </li>
    <li class=""> <a class="active" href="#quickview-chat" data-toggle="tab" role="tab">Chat</a> </li>
  </ul>
  <a class="btn-icon-link invert quickview-toggle" data-toggle-element="#quickview" data-toggle="quickview"><i class="pg-icon">close</i></a> 
  <!-- Tab panes -->
  <div class="tab-content"> 
    <!-- BEGIN Notes !-->
    <div class="tab-pane no-padding" id="quickview-notes">
      <div class="view-port clearfix quickview-notes" id="note-views"> 
        <!-- BEGIN Note List !-->
        <div class="view list" id="quick-note-list">
          <div class="toolbar clearfix">
            <ul class="pull-right ">
              <li> <a href="#" class="delete-note-link"><i class="pg-icon">trash_alt</i></a> </li>
              <li> <a href="#" class="new-note-link" data-navigate="view" data-view-port="#note-views" data-view-animation="push"><i class="pg-icon">add</i></a> </li>
            </ul>
            <button aria-label="" class="btn-remove-notes btn btn-xs btn-block hide"><i class="pg-icon">close</i>Delete</button>
          </div>
          <ul>
            <!-- BEGIN Note Item !-->
            <li data-noteid="1" class="d-flex justify-space-between">
              <div class="left"> 
                <!-- BEGIN Note Action !-->
                <div class="form-check warning no-margin">
                  <input id="qncheckbox1" type="checkbox" value="1">
                  <label for="qncheckbox1"></label>
                </div>
                <!-- END Note Action !--> 
                <!-- BEGIN Note Preview Text !-->
                <p class="note-preview">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                <!-- BEGIN Note Preview Text !--> 
              </div>
              <!-- BEGIN Note Details !-->
              <div class="d-flex right justify-content-end"> 
                <!-- BEGIN Note Date !--> 
                <span class="date">12/12/20</span> <a href="#" class="d-flex align-items-center" data-navigate="view" data-view-port="#note-views" data-view-animation="push"> <i class="pg-icon">chevron_right</i> </a> 
                <!-- END Note Date !--> 
              </div>
              <!-- END Note Details !--> 
            </li>
            <!-- END Note List !--> 
            <!-- BEGIN Note Item !-->
            <li data-noteid="2" class="d-flex justify-space-between">
              <div class="left"> 
                <!-- BEGIN Note Action !-->
                <div class="form-check warning no-margin">
                  <input id="qncheckbox2" type="checkbox" value="1">
                  <label for="qncheckbox2"></label>
                </div>
                <!-- END Note Action !--> 
                <!-- BEGIN Note Preview Text !-->
                <p class="note-preview">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                <!-- BEGIN Note Preview Text !--> 
              </div>
              <!-- BEGIN Note Details !-->
              <div class="d-flex right justify-content-end"> 
                <!-- BEGIN Note Date !--> 
                <span class="date">12/12/20</span> <a href="#" class="d-flex align-items-center" data-navigate="view" data-view-port="#note-views" data-view-animation="push"><i class="pg-icon">chevron_right</i></a> 
                <!-- END Note Date !--> 
              </div>
              <!-- END Note Details !--> 
            </li>
            <!-- END Note List !--> 
            <!-- BEGIN Note Item !-->
            <li data-noteid="2" class="d-flex justify-space-between">
              <div class="left"> 
                <!-- BEGIN Note Action !-->
                <div class="form-check warning no-margin">
                  <input id="qncheckbox3" type="checkbox" value="1">
                  <label for="qncheckbox3"></label>
                </div>
                <!-- END Note Action !--> 
                <!-- BEGIN Note Preview Text !-->
                <p class="note-preview">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                <!-- BEGIN Note Preview Text !--> 
              </div>
              <!-- BEGIN Note Details !-->
              <div class="d-flex right justify-content-end"> 
                <!-- BEGIN Note Date !--> 
                <span class="date">12/12/20</span> <a href="#" class="d-flex align-items-center" data-navigate="view" data-view-port="#note-views" data-view-animation="push"><i class="pg-icon">chevron_right</i></a> 
                <!-- END Note Date !--> 
              </div>
              <!-- END Note Details !--> 
            </li>
            <!-- END Note List !--> 
            <!-- BEGIN Note Item !-->
            <li data-noteid="3" class="d-flex justify-space-between">
              <div class="left"> 
                <!-- BEGIN Note Action !-->
                <div class="form-check warning no-margin">
                  <input id="qncheckbox4" type="checkbox" value="1">
                  <label for="qncheckbox4"></label>
                </div>
                <!-- END Note Action !--> 
                <!-- BEGIN Note Preview Text !-->
                <p class="note-preview">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                <!-- BEGIN Note Preview Text !--> 
              </div>
              <!-- BEGIN Note Details !-->
              <div class="d-flex right justify-content-end"> 
                <!-- BEGIN Note Date !--> 
                <span class="date">12/12/20</span> <a href="#" class="d-flex align-items-center" data-navigate="view" data-view-port="#note-views" data-view-animation="push"><i class="pg-icon">chevron_right</i></a> 
                <!-- END Note Date !--> 
              </div>
              <!-- END Note Details !--> 
            </li>
            <!-- END Note List !--> 
            <!-- BEGIN Note Item !-->
            <li data-noteid="4" class="d-flex justify-space-between">
              <div class="left"> 
                <!-- BEGIN Note Action !-->
                <div class="form-check warning no-margin">
                  <input id="qncheckbox5" type="checkbox" value="1">
                  <label for="qncheckbox5"></label>
                </div>
                <!-- END Note Action !--> 
                <!-- BEGIN Note Preview Text !-->
                <p class="note-preview">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                <!-- BEGIN Note Preview Text !--> 
              </div>
              <!-- BEGIN Note Details !-->
              <div class="d-flex right justify-content-end"> 
                <!-- BEGIN Note Date !--> 
                <span class="date">12/12/20</span> <a href="#" class="d-flex align-items-center" data-navigate="view" data-view-port="#note-views" data-view-animation="push"><i class="pg-icon">chevron_right</i></a> 
                <!-- END Note Date !--> 
              </div>
              <!-- END Note Details !--> 
            </li>
            <!-- END Note List !-->
          </ul>
        </div>
        <!-- END Note List !-->
        <div class="view note" id="quick-note">
          <div>
            <ul class="toolbar">
              <li><a href="#" class="close-note-link"><i class="pg-icon">chevron_left</i></a> </li>
              <li><a href="#" data-action="Bold" class="fs-12"><i class="pg-icon">format_bold</i></a> </li>
              <li><a href="#" data-action="Italic" class="fs-12"><i class="pg-icon">format_italics</i></a> </li>
              <li><a href="#" class="fs-12"><i class="pg-icon">link</i></a> </li>
            </ul>
            <div class="body">
              <div>
                <div class="top"> <span>21st april 2020 2:13am</span> </div>
                <div class="content">
                  <div class="quick-note-editor full-width full-height js-input" contenteditable="true"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END Notes !--> 
    <!-- BEGIN Alerts !-->
    <div class="tab-pane no-padding" id="quickview-alerts">
      <div class="view-port clearfix" id="alerts"> 
        <!-- BEGIN Alerts View !-->
        <div class="view bg-white"> 
          <!-- BEGIN View Header !-->
          <div class="navbar navbar-default navbar-sm">
            <div class="navbar-inner"> 
              <!-- BEGIN Header Controler !--> 
              <a href="javascript:;" class="action p-l-10 link text-color" data-navigate="view" data-view-port="#chat" data-view-animation="push-parrallax"> <i class="pg-icon">more_horizontal</i> </a> 
              <!-- END Header Controler !-->
              <div class="view-heading"> Notications </div>
              <!-- BEGIN Header Controler !--> 
              <a href="#" class="action p-r-10 pull-right link text-color"> <i class="pg-icon">search</i> </a> 
              <!-- END Header Controler !--> 
            </div>
          </div>
          <!-- END View Header !--> 
          <!-- BEGIN Alert List !-->
          <div data-init-list-view="ioslist" class="list-view boreded no-top-border"> 
            <!-- BEGIN List Group !-->
            <div class="list-view-group-container"> 
              <!-- BEGIN List Group Header!-->
              <div class="list-view-group-header text-uppercase"> Calendar </div>
              <!-- END List Group Header!-->
              <ul>
                <!-- BEGIN List Group Item!-->
                <li class="alert-list"> 
                  <!-- BEGIN Alert Item Set Animation using data-view-animation !--> 
                  <a href="javascript:;" class="align-items-center" data-navigate="view" data-view-port="#chat" data-view-animation="push-parrallax">
                  <p class=""> <span class="text-warning fs-10"><i class="pg-icon">circle_fill</i></span> </p>
                  <p class="p-l-10 overflow-ellipsis fs-12"> <span class="text-color">David Nester Birthday</span> </p>
                  <p class="p-r-10 ml-auto fs-12 text-right"> <span class="text-warning">Today <br>
                    </span> <span class="text-color">All Day</span> </p>
                  </a> 
                  <!-- END Alert Item!--> 
                  <!-- BEGIN List Group Item!--> 
                </li>
                <!-- END List Group Item!--> 
                <!-- BEGIN List Group Item!-->
                <li class="alert-list"> 
                  <!-- BEGIN Alert Item Set Animation using data-view-animation !--> 
                  <a href="#" class="align-items-center" data-navigate="view" data-view-port="#chat" data-view-animation="push-parrallax">
                  <p class=""> <span class="text-warning fs-10"><i class="pg-icon">circle_fill</i></span> </p>
                  <p class="p-l-10 overflow-ellipsis fs-12"> <span class="text-color">Meeting at 2:30</span> </p>
                  <p class="p-r-10 ml-auto fs-12 text-right"> <span class="text-warning">Today</span> </p>
                  </a> 
                  <!-- END Alert Item!--> 
                </li>
                <!-- END List Group Item!-->
              </ul>
            </div>
            <!-- END List Group !-->
            <div class="list-view-group-container"> 
              <!-- BEGIN List Group Header!-->
              <div class="list-view-group-header text-uppercase"> Social </div>
              <!-- END List Group Header!-->
              <ul>
                <!-- BEGIN List Group Item!-->
                <li class="alert-list"> 
                  <!-- BEGIN Alert Item Set Animation using data-view-animation !--> 
                  <a href="javascript:;" class="p-t-10 p-b-10 align-items-center" data-navigate="view" data-view-port="#chat" data-view-animation="push-parrallax">
                  <p class=""> <span class="text-complete fs-10"><i class="pg-icon">circle_fill</i></span> </p>
                  <p class="col overflow-ellipsis fs-12 p-l-10"> <span class="text-color link">Jame Smith commented on your status<br>
                    </span> <span class="text-color">“Perfection Simplified - Company Revox"</span> </p>
                  </a> 
                  <!-- END Alert Item!--> 
                </li>
                <!-- END List Group Item!--> 
                <!-- BEGIN List Group Item!-->
                <li class="alert-list"> 
                  <!-- BEGIN Alert Item Set Animation using data-view-animation !--> 
                  <a href="javascript:;" class="p-t-10 p-b-10 align-items-center" data-navigate="view" data-view-port="#chat" data-view-animation="push-parrallax">
                  <p class=""> <span class="text-complete fs-10"><i class="pg-icon">circle_fill</i></span> </p>
                  <p class="col overflow-ellipsis fs-12 p-l-10"> <span class="text-color link">Jame Smith commented on your status<br>
                    </span> <span class="text-color">“Perfection Simplified - Company Revox"</span> </p>
                  </a> 
                  <!-- END Alert Item!--> 
                </li>
                <!-- END List Group Item!-->
              </ul>
            </div>
            <div class="list-view-group-container"> 
              <!-- BEGIN List Group Header!-->
              <div class="list-view-group-header text-uppercase"> Sever Status </div>
              <!-- END List Group Header!-->
              <ul>
                <!-- BEGIN List Group Item!-->
                <li class="alert-list"> 
                  <!-- BEGIN Alert Item Set Animation using data-view-animation !--> 
                  <a href="#" class="p-t-10 p-b-10 align-items-center" data-navigate="view" data-view-port="#chat" data-view-animation="push-parrallax">
                  <p class=""> <span class="text-danger fs-10"><i class="pg-icon">circle_fill</i></span> </p>
                  <p class="col overflow-ellipsis fs-12 p-l-10"> <span class="text-color link">12:13AM GTM, 10230, ID:WR174s<br>
                    </span> <span class="text-color">Server Load Exceeted. Take action</span> </p>
                  </a> 
                  <!-- END Alert Item!--> 
                </li>
                <!-- END List Group Item!-->
              </ul>
            </div>
          </div>
          <!-- END Alert List !--> 
        </div>
        <!-- EEND Alerts View !--> 
      </div>
    </div>
    <!-- END Alerts !-->
    <div class="tab-pane active no-padding" id="quickview-chat">
      <div class="view-port clearfix" id="chat">
        <div class="view bg-white"> 
          <!-- BEGIN View Header !-->
          <div class="navbar navbar-default">
            <div class="navbar-inner"> 
              <!-- BEGIN Header Controler !--> 
              <a href="javascript:;" class="action p-l-10 link text-color" data-navigate="view" data-view-port="#chat" data-view-animation="push-parrallax"> <i class="pg-icon">add</i> </a> 
              <!-- END Header Controler !-->
              <div class="view-heading"> Chat List
                <div class="fs-11">Show All</div>
              </div>
              <!-- BEGIN Header Controler !--> 
              <a href="#" class="action p-r-10 pull-right link text-color"> <i class="pg-icon">more_horizontal</i> </a> 
              <!-- END Header Controler !--> 
            </div>
          </div>
          <!-- END View Header !-->
          <div data-init-list-view="ioslist" class="list-view boreded no-top-border">
            <div class="list-view-group-container">
              <div class="list-view-group-header text-uppercase"> a</div>
              <ul>
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/1x.jpg" data-src="assets/img/profiles/1.jpg" src="assets/img/profiles/1x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">ava flores</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !-->
              </ul>
            </div>
            <div class="list-view-group-container">
              <div class="list-view-group-header text-uppercase">b</div>
              <ul>
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/2x.jpg" data-src="assets/img/profiles/2.jpg" src="assets/img/profiles/2x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">bella mccoy</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !--> 
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/3x.jpg" data-src="assets/img/profiles/3.jpg" src="assets/img/profiles/3x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">bob stephens</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !-->
              </ul>
            </div>
            <div class="list-view-group-container">
              <div class="list-view-group-header text-uppercase">c</div>
              <ul>
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/4x.jpg" data-src="assets/img/profiles/4.jpg" src="assets/img/profiles/4x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">carole roberts</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !--> 
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/5x.jpg" data-src="assets/img/profiles/5.jpg" src="assets/img/profiles/5x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">christopher perez</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !-->
              </ul>
            </div>
            <div class="list-view-group-container">
              <div class="list-view-group-header text-uppercase">d</div>
              <ul>
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/6x.jpg" data-src="assets/img/profiles/6.jpg" src="assets/img/profiles/6x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">danielle fletcher</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !--> 
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/7x.jpg" data-src="assets/img/profiles/7.jpg" src="assets/img/profiles/7x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">david sutton</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !-->
              </ul>
            </div>
            <div class="list-view-group-container">
              <div class="list-view-group-header text-uppercase">e</div>
              <ul>
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/8x.jpg" data-src="assets/img/profiles/8.jpg" src="assets/img/profiles/8x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">earl hamilton</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !--> 
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/9x.jpg" data-src="assets/img/profiles/9.jpg" src="assets/img/profiles/9x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">elaine lawrence</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !--> 
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/1x.jpg" data-src="assets/img/profiles/1.jpg" src="assets/img/profiles/1x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">ellen grant</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !--> 
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/2x.jpg" data-src="assets/img/profiles/2.jpg" src="assets/img/profiles/2x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">erik taylor</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !--> 
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/3x.jpg" data-src="assets/img/profiles/3.jpg" src="assets/img/profiles/3x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">everett wagner</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !-->
              </ul>
            </div>
            <div class="list-view-group-container">
              <div class="list-view-group-header text-uppercase">f</div>
              <ul>
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/4x.jpg" data-src="assets/img/profiles/4.jpg" src="assets/img/profiles/4x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">freddie gomez</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !-->
              </ul>
            </div>
            <div class="list-view-group-container">
              <div class="list-view-group-header text-uppercase">g</div>
              <ul>
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/5x.jpg" data-src="assets/img/profiles/5.jpg" src="assets/img/profiles/5x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">glen jensen</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !--> 
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/6x.jpg" data-src="assets/img/profiles/6.jpg" src="assets/img/profiles/6x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">gwendolyn walker</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !-->
              </ul>
            </div>
            <div class="list-view-group-container">
              <div class="list-view-group-header text-uppercase">j</div>
              <ul>
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/7x.jpg" data-src="assets/img/profiles/7.jpg" src="assets/img/profiles/7x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">janet romero</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !-->
              </ul>
            </div>
            <div class="list-view-group-container">
              <div class="list-view-group-header text-uppercase">k</div>
              <ul>
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/8x.jpg" data-src="assets/img/profiles/8.jpg" src="assets/img/profiles/8x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">kim martinez</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !-->
              </ul>
            </div>
            <div class="list-view-group-container">
              <div class="list-view-group-header text-uppercase">l</div>
              <ul>
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/9x.jpg" data-src="assets/img/profiles/9.jpg" src="assets/img/profiles/9x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">lawrence white</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !--> 
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/1x.jpg" data-src="assets/img/profiles/1.jpg" src="assets/img/profiles/1x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">leroy bell</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !--> 
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/2x.jpg" data-src="assets/img/profiles/2.jpg" src="assets/img/profiles/2x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">letitia carr</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !--> 
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/3x.jpg" data-src="assets/img/profiles/3.jpg" src="assets/img/profiles/3x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">lucy castro</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !-->
              </ul>
            </div>
            <div class="list-view-group-container">
              <div class="list-view-group-header text-uppercase">m</div>
              <ul>
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/4x.jpg" data-src="assets/img/profiles/4.jpg" src="assets/img/profiles/4x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">mae hayes</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !--> 
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/5x.jpg" data-src="assets/img/profiles/5.jpg" src="assets/img/profiles/5x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">marilyn owens</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !--> 
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/6x.jpg" data-src="assets/img/profiles/6.jpg" src="assets/img/profiles/6x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">marlene cole</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !--> 
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/7x.jpg" data-src="assets/img/profiles/7.jpg" src="assets/img/profiles/7x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">marsha warren</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !--> 
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/8x.jpg" data-src="assets/img/profiles/8.jpg" src="assets/img/profiles/8x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">marsha dean</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !--> 
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/9x.jpg" data-src="assets/img/profiles/9.jpg" src="assets/img/profiles/9x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">mia diaz</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !-->
              </ul>
            </div>
            <div class="list-view-group-container">
              <div class="list-view-group-header text-uppercase">n</div>
              <ul>
                <!-- BEGIN Chat User List Item  !--> 
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/1x.jpg" data-src="assets/img/profiles/1.jpg" src="assets/img/profiles/1x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">noah elliott</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !-->
              </ul>
            </div>
            <div class="list-view-group-container">
              <div class="list-view-group-header text-uppercase">p</div>
              <ul>
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/2x.jpg" data-src="assets/img/profiles/2.jpg" src="assets/img/profiles/2x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">phyllis hamilton</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !-->
              </ul>
            </div>
            <div class="list-view-group-container">
              <div class="list-view-group-header text-uppercase">r</div>
              <ul>
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/3x.jpg" data-src="assets/img/profiles/3.jpg" src="assets/img/profiles/3x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">raul rodriquez</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !--> 
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/4x.jpg" data-src="assets/img/profiles/4.jpg" src="assets/img/profiles/4x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">rhonda barnett</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !--> 
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/5x.jpg" data-src="assets/img/profiles/5.jpg" src="assets/img/profiles/5x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">roberta king</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !-->
              </ul>
            </div>
            <div class="list-view-group-container">
              <div class="list-view-group-header text-uppercase">s</div>
              <ul>
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/6x.jpg" data-src="assets/img/profiles/6.jpg" src="assets/img/profiles/6x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">scott armstrong</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !--> 
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/7x.jpg" data-src="assets/img/profiles/7.jpg" src="assets/img/profiles/7x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">sebastian austin</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !--> 
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/8x.jpg" data-src="assets/img/profiles/8.jpg" src="assets/img/profiles/8x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">sofia davis</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !-->
              </ul>
            </div>
            <div class="list-view-group-container">
              <div class="list-view-group-header text-uppercase">t</div>
              <ul>
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/9x.jpg" data-src="assets/img/profiles/9.jpg" src="assets/img/profiles/9x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">terrance young</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !--> 
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/1x.jpg" data-src="assets/img/profiles/1.jpg" src="assets/img/profiles/1x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">theodore woods</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !--> 
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/2x.jpg" data-src="assets/img/profiles/2.jpg" src="assets/img/profiles/2x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">todd wood</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !--> 
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/3x.jpg" data-src="assets/img/profiles/3.jpg" src="assets/img/profiles/3x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">tommy jenkins</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !-->
              </ul>
            </div>
            <div class="list-view-group-container">
              <div class="list-view-group-header text-uppercase">w</div>
              <ul>
                <!-- BEGIN Chat User List Item  !-->
                <li class="chat-user-list clearfix"> <a data-view-animation="push-parrallax" data-view-port="#chat" data-navigate="view" class="" href="#"> <span class="thumbnail-wrapper d32 circular bg-success"> <img width="34" height="34" alt="" data-src-retina="assets/img/profiles/4x.jpg" data-src="assets/img/profiles/4.jpg" src="assets/img/profiles/4x.jpg" class="col-top"> </span>
                  <p class="p-l-10 "> <span class="text-color">wilma hicks</span> <span class="block text-color hint-text fs-12">Hello there</span> </p>
                  </a> </li>
                <!-- END Chat User List Item  !-->
              </ul>
            </div>
          </div>
        </div>
        <!-- BEGIN Conversation View  !-->
        <div class="view chat-view bg-white clearfix"> 
          <!-- BEGIN Header  !-->
          <div class="navbar navbar-default">
            <div class="navbar-inner"> <a href="javascript:;" class="link text-color action p-l-10 p-r-10" data-navigate="view" data-view-port="#chat" data-view-animation="push-parrallax"> <i class="pg-icon">chevron_left</i> </a>
              <div class="view-heading"> John Smith
                <div class="fs-11 hint-text">Online</div>
              </div>
              <a href="#" class="link text-color action p-r-10 pull-right "> <i class="pg-icon">more_horizontal</i> </a> </div>
          </div>
          <!-- END Header  !--> 
          <!-- BEGIN Conversation  !-->
          <div class="chat-inner" id="my-conversation"> 
            <!-- BEGIN From Me Message  !-->
            <div class="message clearfix">
              <div class="chat-bubble from-me"> Hello there </div>
            </div>
            <!-- END From Me Message  !--> 
            <!-- BEGIN From Them Message  !-->
            <div class="message clearfix">
              <div class="profile-img-wrapper m-t-5 inline"> <img class="col-top" width="30" height="30" src="assets/img/profiles/avatar_small.jpg" alt="" data-src="assets/img/profiles/avatar_small.jpg" data-src-retina="assets/img/profiles/avatar_small2x.jpg"> </div>
              <div class="chat-bubble from-them"> Hey </div>
            </div>
            <!-- END From Them Message  !--> 
            <!-- BEGIN From Me Message  !-->
            <div class="message clearfix">
              <div class="chat-bubble from-me"> Did you check out Pages framework ? </div>
            </div>
            <!-- END From Me Message  !--> 
            <!-- BEGIN From Me Message  !-->
            <div class="message clearfix">
              <div class="chat-bubble from-me"> Its an awesome chat </div>
            </div>
            <!-- END From Me Message  !--> 
            <!-- BEGIN From Them Message  !-->
            <div class="message clearfix">
              <div class="profile-img-wrapper m-t-5 inline"> <img class="col-top" width="30" height="30" src="assets/img/profiles/avatar_small.jpg" alt="" data-src="assets/img/profiles/avatar_small.jpg" data-src-retina="assets/img/profiles/avatar_small2x.jpg"> </div>
              <div class="chat-bubble from-them"> Yea </div>
            </div>
            <!-- END From Them Message  !--> 
          </div>
          <!-- BEGIN Conversation  !--> 
          <!-- BEGIN Chat Input  !-->
          <div class="b-t b-grey bg-white clearfix p-l-10 p-r-10">
            <div class="row">
              <div class="col-1 p-t-15"> <a href="#" class="link text-color"><i class="pg-icon">add</i></a> </div>
              <div class="col-8 no-padding">
                <label class="d-none">Reply</label>
                <input type="text" class="form-control chat-input" data-chat-input="" data-chat-conversation="#my-conversation" placeholder="Say something">
              </div>
              <div class="col-2 link text-color m-l-10 m-t-15 p-l-10 b-l b-grey col-top"> <a href="#" class="link text-color"><i class="pg-icon">camera</i></a> </div>
            </div>
          </div>
          <!-- END Chat Input  !--> 
        </div>
        <!-- END Conversation View  !--> 
      </div>
    </div>
  </div>
</div>
<!-- END QUICKVIEW--> 
<!-- START OVERLAY -->
<div class="overlay hide" data-pages="search"> 
  <!-- BEGIN Overlay Content !-->
  <div class="overlay-content has-results m-t-20"> 
    <!-- BEGIN Overlay Header !-->
    <div class="container-fluid"> 
      <!-- BEGIN Overlay Logo !--> 
      <img class="overlay-brand" src="assets/img/logo.png" alt="logo" data-src="assets/img/logo.png" data-src-retina="assets/img/logo_2x.png" width="78" height="22"> 
      <!-- END Overlay Logo !--> 
      <!-- BEGIN Overlay Close !--> 
      <a href="#" class="close-icon-light btn-link btn-rounded  overlay-close text-black"> <i class="pg-icon">close</i> </a> 
      <!-- END Overlay Close !--> 
    </div>
    <!-- END Overlay Header !-->
    <div class="container-fluid"> 
      <!-- BEGIN Overlay Controls !-->
      <input id="overlay-search" class="no-border overlay-search bg-transparent" placeholder="Search..." autocomplete="off" spellcheck="false">
      <br>
      <div class="d-flex align-items-center">
        <div class="form-check right m-b-0">
          <input id="checkboxn" type="checkbox" value="1">
          <label for="checkboxn">Search within page</label>
        </div>
        <p class="fs-13 hint-text m-l-10 m-b-0">Press enter to search</p>
      </div>
      <!-- END Overlay Controls !--> 
    </div>
    <!-- BEGIN Overlay Search Results, This part is for demo purpose, you can add anything you like !-->
    <div class="container-fluid p-t-20"> <span class="hint-text"> suggestions : </span> <span class="overlay-suggestions"></span> <br>
      <div class="search-results m-t-30">
        <p class="bold">Pages Search Results: <span class="overlay-suggestions"></span></p>
        <div class="row">
          <div class="col-md-6"> 
            <!-- BEGIN Search Result Item !-->
            <div class="d-flex m-t-15"> 
              <!-- BEGIN Search Result Item Thumbnail !-->
              <div class="thumbnail-wrapper d48 circular bg-success text-white "> <img width="36" height="36" src="assets/img/profiles/avatar.jpg" data-src="assets/img/profiles/avatar.jpg" data-src-retina="assets/img/profiles/avatar2x.jpg" alt=""> </div>
              <!-- END Search Result Item Thumbnail !-->
              <div class="p-l-10">
                <h5 class="no-margin "><span class="semi-bold result-name">ice cream</span> on pages</h5>
                <p class="small-text hint-text">via john smith</p>
              </div>
            </div>
            <!-- END Search Result Item !--> 
            <!-- BEGIN Search Result Item !-->
            <div class="d-flex m-t-15"> 
              <!-- BEGIN Search Result Item Thumbnail !-->
              <div class="thumbnail-wrapper d48 circular bg-success text-white ">
                <div>T</div>
              </div>
              <!-- END Search Result Item Thumbnail !-->
              <div class="p-l-10">
                <h5 class="no-margin "><span class="semi-bold result-name">ice cream</span> related topics</h5>
                <p class="small-text hint-text">via pages</p>
              </div>
            </div>
            <!-- END Search Result Item !--> 
            <!-- BEGIN Search Result Item !-->
            <div class="d-flex m-t-15"> 
              <!-- BEGIN Search Result Item Thumbnail !-->
              <div class="thumbnail-wrapper d48 circular bg-success text-white ">
                <div>M </div>
              </div>
              <!-- END Search Result Item Thumbnail !-->
              <div class="p-l-10">
                <h5 class="no-margin "><span class="semi-bold result-name">ice cream</span> music</h5>
                <p class="small-text hint-text">via pagesmix</p>
              </div>
            </div>
            <!-- END Search Result Item !--> 
          </div>
          <div class="col-md-6"> 
            <!-- BEGIN Search Result Item !-->
            <div class="d-flex m-t-15"> 
              <!-- BEGIN Search Result Item Thumbnail !-->
              <div class="thumbnail-wrapper d48 circular bg-info text-white d-flex align-items-center"> <i class="pg-icon">facebook</i> </div>
              <!-- END Search Result Item Thumbnail !-->
              <div class="p-l-10">
                <h5 class="no-margin "><span class="semi-bold result-name">ice cream</span> on facebook</h5>
                <p class="small-text hint-text">via facebook</p>
              </div>
            </div>
            <!-- END Search Result Item !--> 
            <!-- BEGIN Search Result Item !-->
            <div class="d-flex m-t-15"> 
              <!-- BEGIN Search Result Item Thumbnail !-->
              <div class="thumbnail-wrapper d48 circular bg-complete text-white d-flex align-items-center"> <i class="pg-icon">twitter</i> </div>
              <!-- END Search Result Item Thumbnail !-->
              <div class="p-l-10">
                <h5 class="no-margin ">Tweats on<span class="semi-bold result-name"> ice cream</span></h5>
                <p class="small-text hint-text">via twitter</p>
              </div>
            </div>
            <!-- END Search Result Item !--> 
            <!-- BEGIN Search Result Item !-->
            <div class="d-flex m-t-15"> 
              <!-- BEGIN Search Result Item Thumbnail !-->
              <div class="thumbnail-wrapper d48 circular text-white bg-danger d-flex align-items-center"> <i class="pg-icon">google_plus</i> </div>
              <!-- END Search Result Item Thumbnail !-->
              <div class="p-l-10">
                <h5 class="no-margin ">Circles on<span class="semi-bold result-name"> ice cream</span></h5>
                <p class="small-text hint-text">via google plus</p>
              </div>
            </div>
            <!-- END Search Result Item !--> 
          </div>
        </div>
      </div>
    </div>
    <!-- END Overlay Search Results !--> 
  </div>
  <!-- END Overlay Content !--> 
</div>
<!-- END OVERLAY -->



<ng-template #content let-modal>
  <div class="modal-header">
    <h6>More Info</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- <h6>Menu list</h6> -->
    <div class="wrapper">
    	<div class="flex extradiv">
    		<h5>Order Details</h5>
    		<p class="card-title">Fuel <br><span><b>Diesel</b></span></p>
    		<p class="card-title">Booking Date & Time  <br><span><b>21/09/2020 & 09:00 am</b></span></p>
    		<p class="card-title">Quantity in Ltrs   <br><span><b>222</b></span></p>
    		<p class="card-title">Delivery Date & Time  <br><span><b>21/09/2020 & 09:00 am</b></span></p>
    		<p class="card-title">Delivery Location   <br><span><b>No.294, Mount Road, Opposite LIC, <br> Nandhanam, Chennai 600 002</b></span></p>
    	</div>
    	<div class="flex extradiv">
    		<h5>Delivery Agent Info</h5>
    		<button class="order cancel">cancel order</button>
    		<div class="km">5.3 Km</div>
    		<p class="card-title">Agent Name <span class="ratting"><i class="fa fa-star" aria-hidden="true"></i>
3.5</span> <br><span><b>Kannan Y</b> </span> </p>
    		<p class="card-title">Vehicle Number <br><span><b>TN-09 BS 2545 </b></span></p>
    		<p class="card-title">Quantity in Ltrs   <br><span><b>222</b></span></p>
    		<p class="card-title">Mobile  <br><span><b>+91 987895478</b></span></p>
    		<p class="card-title">Delivery Time   <br><span><b>Apprx Delivery Time • 00:45 Min.s</b></span></p>
    	</div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
  </div>
</ng-template><ng-template #content let-modal>
  <div class="modal-header">
    <h6>More Info</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- <h6>Menu list</h6> -->
    <div class="wrapper">
    	<div class="flex extradiv">
    		<h5>Vehicle View Page </h5>
   		  <p class="card-title">Partner Name<br><span><b>AnandhRajB </b></span></p>
   		  <p class="card-title">Vehicle Number   <br><span><b>TN-09-R9332 </b></span></p>
   		  <p class="card-title">Model Number  <br>
   		    <span><b>Maxi Tmck </b></span></p>
   		  <p class="card-title">Fuel  <br><span><b>Diesel</b></span></p>
   		  <p class="card-title">Tank Capacity in litrs <br>
   		    <span><b>180</b></span></p>
			<p class="card-title">Available Fuel in litrs <br>
   		    <span><b>180</b></span></p>
			<p class="card-title">Added Date & Time <br>
   		    <span><b>21-09-2020 & 10:02am</b></span></p>
			<p class="card-title">Status <br>
   		    <span><b>InActive</b></span></p>
    	</div>
    	<div class="flex extradiv">
    		<h5>Payment Details </h5>
    		
   		  <p class="card-title">Payment Type  <br><span><b>Vechical registration Fee </b></span></p>
    		<p class="card-title">Payment Amount   <br><span><b>Rs. 5000</b></span></p>
    		<p class="card-title">Transaction ID  <br><span><b>CFTID934S230</b></span></p>
    		<p class="card-title">Payment Status   <br><span><b>Success</b></span></p> 
			<p class="card-title">Transacdon Date & Time   <br>
		    <b>21-09-2020 & 10:02am</b></p>
    	</div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>

<router-outlet></router-outlet>



<table class="table table-striped">
  <thead>
  <tr>
    <th scope="col">#</th>
    <th scope="col">Country</th>
    <th scope="col">Area</th>
    <th scope="col">Population</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let country of countries; index as i">
    <th scope="row">{{ i + 1 }}</th>
    <td>
      <img [src]="'https://upload.wikimedia.org/wikipedia/commons/' + country.flag" class="mr-2" style="width: 20px">
      {{ country.name }}
    </td>
    <td>{{ country.area | number }}</td>
    <td>{{ country.population | number }}</td>
  </tr>
  </tbody>
</table>