<app-header></app-header>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
</ngx-spinner>
<ol class="breadcrumb breadcrumb-alt" style="margin-top:100px; width: auto;" >
  <li class="breadcrumb-item" style="margin-left: 15px;"><a href="#" routerLink="/dashboard">Home</a></li>
  <li class="breadcrumb-item active">VEHICLE KYC VIEW</li>
  <div class="btn-section-wrapper">
    <button type="button" class="label label-success" (click)="open(content)">Approve</button>
    <button type="button"class="label label-danger" (click)="open1(reject)">Reject</button>
    <button *ngIf="pendingcheck" type="button" class="label label-info" (click)="upload(content3)">Upload Documents</button>
    <button *ngIf="!pendingcheck" type="button" class="label label-info" (click)="upload(content3)" disabled>Upload Documents</button>
    <a href="#" routerLink="/partners-kyc-vehicle-view" class="back_page"><span class="label label-info"><i class="back_page"></i>Back</span></a>
  </div>
</ol>
<div class="container  container-top-dashboard-section">
   <!-- <div class="profile-nav">
      <button type="button" class="btn btn-outline-primary" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseExample" id="collapse">
      Profile
      </button> 
   </div> -->
  <!-- <div class="alert alert-success" role="alert"
  *ngIf="kycalert">{{kycresponseString}}</div> -->
   <div class="card mb-3" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
   <div class="flex">
      <div class="col1 flex">
         <div class=" profile images flex">
            <img *ngIf="!isImageLoadingPartner" src="assets/img/profile.jpg" alt="logo" data-src="assets/img/profile.jpg" data-src-retina="assets/img/profile.jpg" width="150px" >
            <img *ngIf="isImageLoadingPartner" class="modal-content1"  [src]="imageToShowPartner">
         </div>
         <div class="profile-info flex">
            <div class="card-body profile">
              <p class="card-title">Partners Name <br><span style="word-wrap: break-word;"><b>
                {{vehicleModel.userId.firstName}} {{vehicleModel.userId.lastName}}
              </b></span></p>
              <p class="card-title">Vehicle Number <br><span><b>
                {{vehicleModel.vehicleNumber}}
              </b></span></p>
            </div>
             <div class="card-body profile">
               <p class="card-title"><i class="fa fa-mobile" aria-hidden="true"></i> Mobile
                  <br><span><b>
                    {{vehicleModel.userId.mobileNumber}}
                  </b></span>
               </p>
               <p class="card-title"><i class="fa fa-envelope" aria-hidden="true"></i> Email
                  <br><span><b>
                    {{vehicleModel.userId.emailAddress}}
                  </b></span>
               </p>
            </div>

            <div class="card-body profile">
				<p class="card-title">Onboarded Date & Time  <br><span><b>
          {{vehicleModel.userId.createdDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}
        </b></span></p>

        <p class="card-title">Submitted Date & Time  <br><span><b>
          {{vehicleModel.submittedDate | date:'dd/MM/yyyy hh:mm:ss a'}}
        </b></span></p>
            </div>
         </div>
      </div>
   </div>
</div>

<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
  <div class="btn-section-wrapper">
    <!-- <button type="button" class="btn btn-success" (click)="open(content)">Approve</button>
    <button type="button" class="btn btn-danger" (click)="open1(reject)">Reject</button>
    <button *ngIf="pendingcheck" type="button" class="btn btn-outline-primary" (click)="upload(content3)">Upload Documents</button>
    <button *ngIf="!pendingcheck" type="button" class="btn btn-outline-primary" (click)="upload(content3)" disabled>Upload Documents</button> -->
</div>
  <li [ngbNavItem]="1" >
    <a ngbNavLink>Document</a> 
    <div style="display: none;">{{kycdocumentmodel.responseMessage}}</div>
    <ng-template ngbNavContent>
      <br>
      <button style="margin-left: 450px;" (click)="pending()" class="btn-transaction">
        <div class="form-check">
          <input class="form-check-input" type="radio" name="exampleRadios" id="penradio" value="option1" checked>
          <label class="form-check-label" for="penradio">
            Pending
          </label>
        </div>
        </button>
        <button (click)="approveS()" class="btn-transaction">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios" id="appradio" value="option1">
            <label class="form-check-label" for="appradio">
              Approved
            </label>
          </div>
          </button>
          <button (click)="rejectkycclick()" class="btn-transaction">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="exampleRadios" id="rejradio" value="option1">
              <label class="form-check-label" for="rejradio">
                Rejected
              </label>
            </div>
            </button>
      <!-- <button  (click)="rejectkycclick()" class="pen-style2">Rejected</button>
      <button (click)="approveS()" class="pen-style1">Approved</button>
      <button (click)="pending()"  class="pen-style">Pending</button> -->
      <br>
      <div *ngIf="allkyc" class="card-body">
        <div style="display: none;">{{kycdocumentmodel.statusCode}}</div>
        <div style="display: none;">{{kycdocumentmodel.responseMessage}}</div>
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr>
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: center;">&nbsp;</th> 
                      <th style="text-align: left;">Vehicle Number</th>
                      <th style="text-align: left;">Document Name</th>
                      <th style="text-align: center;">KYC</th>
                      <th style="text-align: left;">Submitted By</th>
                      <th style="text-align: left;">Submitted Date & Time</th>
                      <th style="text-align: center;">Approval Status</th>
                      <th style="text-align: left;">Comments</th>
                      <th style="text-align: left;">Expiry Date</th>
                      <th style="text-align: center;">Location</th>
                      <th style="text-align: left;">Approved By</th>
                      <th style="text-align: left;">Approved Date Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let kyc of kycdocumentmodel.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td align="center" *ngIf="kyc.approvalStatus==11">&nbsp;</td>
                      <td align="center" *ngIf="kyc.approvalStatus==12">&nbsp;</td>
                      <td align="center" *ngIf="kyc.approvalStatus==10" class="v-align-middle "><span>
                        <input type="checkbox" value=" {{kyc.id}}" (change)="onChange(kyc.id,$event)">
                      </span></td>
                      <td align="left">{{vehicleModel.vehicleNumber}}</td>
                      <td align="left" class="v-align-middle">{{kyc.kycId.kycName}}</td>
                      <td align="center" class="v-align-middle">
                        <button type="button" title="Image" class="label label-success" (click)="kycdocument(kyc.id,content1,kyc.kycId.kycName,kyc.fileType)">
                          <span aria-hidden="true"></span>
                          <i class="fa fa-image"></i>
                        </button>
                      </td>
                      <td align="left" *ngIf="kyc.createdBy!=null" class="v-align-middle">{{kyc.createdBy}}</td>
                      <td align="left" *ngIf="kyc.createdBy==null" class="v-align-middle">N/A</td>
                      <td align="left" *ngIf="kyc.createdDatetime!=null" class="v-align-middle">{{kyc.createdDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td align="left" *ngIf="kyc.createdDatetime==null" class="v-align-middle">N/A</td>
                      <td *ngIf="kyc.approvalStatus==10" align="center"  class="v-align-middle ht-Pending">Pending</td>
                      <td *ngIf="kyc.approvalStatus==11" align="center"  class="v-align-middle ht-active">Approved</td>
                      <td *ngIf="kyc.approvalStatus==12" align="center" style="color: red;">Rejected 
                        <button type="button" title="Reupload" (click)="reupload(content4,kyc.id)" class="label label-info">
                          <span aria-hidden="true"></span>
                          <i class="fa fa-upload" aria-hidden="true"></i>
                        </button>
                      </td>
                      <td *ngIf="kyc.approvalComments!=null"align="left" class="v-align-middle">{{kyc.approvalComments}}</td>
                      <td *ngIf="kyc.approvalComments==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="kyc.expiryDate!=null"align="left" class="v-align-middle">{{kyc.expiryDate | date:'dd/MM/yyyy' }}</td>
                      <td *ngIf="kyc.expiryDate==null"align="left" class="v-align-middle">N/A</td>
                      <td align="center" class="v-align-middle">
                        <button type="button" title="Location" class="label label-info" (click)="location(kyc.latitude,kyc.longitude)" (click)="open3(content2)">
                          <span aria-hidden="true"></span>
                          <i class="fa fa-location-arrow"></i>
                        </button>
                      </td>
                      <td *ngIf="kyc.approvalBy!=null"align="left" class="v-align-middle">{{kyc.approvalBy}}</td>
                      <td *ngIf="kyc.approvalBy==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="kyc.approvalDatetime!=null"align="left"  class="v-align-middle"><span class="v-align-middle ">{{kyc.approvalDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span></td>
                      <td *ngIf="kyc.approvalDatetime==null"align="left"  class="v-align-middle"><span class="v-align-middle ">N/A</span></td>
                    </tr>
                  </tbody>
                </table>
          </div>
        </div>

        <!-- --- Pending Kyc ----  -->
        <div *ngIf="pendingkyc" class="card-body">
          <div style="display: none;">{{kycdocumentmodelPending.responseMessage}}</div>
          <div style="display: none;">{{kycdocumentmodelPending.statusCode}</div>
          <div class="table-responsive">
            <table datatable class="ch-table">
              <thead>
                <tr>
                  <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: center;">&nbsp;</th> 
                      <th style="text-align: left;">Vehicle Number</th>
                      <th style="text-align: left;">Document Name</th>
                      <th style="text-align: center;">KYC</th>
                      <th style="text-align: left;">Submitted By</th>
                      <th style="text-align: left;">Submitted Date & Time</th>
                      <th style="text-align: center;">Approval Status</th>
                      <th style="text-align: left;">Comments</th>
                      <th style="text-align: left;">Expiry Date</th>
                      <th style="text-align: center;">Location</th>
                      <th style="text-align: left;">Approved By</th>
                      <th style="text-align: left;">Approved Date Time</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let kyc of kycdocumentmodelPending.response; let i=index">
                  <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td align="center" *ngIf="kyc.approvalStatus==11">&nbsp;</td>
                      <td align="center" *ngIf="kyc.approvalStatus==12">&nbsp;</td>
                      <td align="center" *ngIf="kyc.approvalStatus==10" class="v-align-middle "><span>
                        <input type="checkbox" value=" {{kyc.id}}" (change)="onChange(kyc.id,$event)">
                      </span></td>
                      <td align="left">{{vehicleModel.vehicleNumber}}</td>
                      <td align="left" class="v-align-middle">{{kyc.kycId.kycName}}</td>
                      <td align="center" class="v-align-middle">
                        <button type="button" title="Image" class="label label-success" (click)="kycdocument(kyc.id,content1,kyc.kycId.kycName,kyc.fileType)">
                          <span aria-hidden="true"></span>
                          <i class="fa fa-image"></i>
                        </button>
                      </td>
                      <td align="left" *ngIf="kyc.createdBy!=null" class="v-align-middle">{{kyc.createdBy}}</td>
                      <td align="left" *ngIf="kyc.createdBy==null" class="v-align-middle">N/A</td>
                      <td align="left" *ngIf="kyc.createdDatetime!=null" class="v-align-middle">{{kyc.createdDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td align="left" *ngIf="kyc.createdDatetime==null" class="v-align-middle">N/A</td>
                      <td *ngIf="kyc.approvalStatus==10" align="center"  class="v-align-middle ht-Pending">Pending</td>
                      <td *ngIf="kyc.approvalStatus==11" align="center"  class="v-align-middle ht-active">Approved</td>
                      <td *ngIf="kyc.approvalStatus==12" align="center" style="color: red;">Rejected 
                        <button type="button" title="Reupload" (click)="reupload(content4,kyc.id)" class="label label-info">
                          <span aria-hidden="true"></span>
                          <i class="fa fa-upload" aria-hidden="true"></i>
                        </button>
                      </td>
                      <td *ngIf="kyc.approvalComments!=null"align="left" class="v-align-middle">{{kyc.approvalComments}}</td>
                      <td *ngIf="kyc.approvalComments==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="kyc.expiryDate!=null"align="left" class="v-align-middle">{{kyc.expiryDate | date:'dd/MM/yyyy' }}</td>
                      <td *ngIf="kyc.expiryDate==null"align="left" class="v-align-middle">N/A</td>
                      <td align="center" class="v-align-middle">
                        <button type="button" title="Location" class="label label-info" (click)="location(kyc.latitude,kyc.longitude)" (click)="open3(content2)">
                          <span aria-hidden="true"></span>
                          <i class="fa fa-location-arrow"></i>
                        </button>
                      </td>
                      <td *ngIf="kyc.approvalBy!=null"align="left" class="v-align-middle">{{kyc.approvalBy}}</td>
                      <td *ngIf="kyc.approvalBy==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="kyc.approvalDatetime!=null"align="left"  class="v-align-middle"><span class="v-align-middle ">{{kyc.approvalDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span></td>
                      <td *ngIf="kyc.approvalDatetime==null"align="left"  class="v-align-middle"><span class="v-align-middle ">N/A</span></td>
                </tr>
              </tbody>
            </table>
      </div>
    </div>

                <!-- --- Approve Kyc ----  -->
        <div *ngIf="approvekyc" class="card-body">
          <div style="display: none;">{{kycdocumentmodelApprove.responseMessage}}</div>
          <div style="display: none;">{{kycdocumentmodelApprove.statusCode}}</div>
          <div class="table-responsive">
            <table datatable class="ch-table">
              <thead>
                <tr>
                  <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: center;">&nbsp;</th> 
                      <th style="text-align: left;">Vehicle Number</th>
                      <th style="text-align: left;">Document Name</th>
                      <th style="text-align: center;">KYC</th>
                      <th style="text-align: left;">Submitted By</th>
                      <th style="text-align: left;">Submitted Date & Time</th>
                      <th style="text-align: center;">Approval Status</th>
                      <th style="text-align: left;">Comments</th>
                      <th style="text-align: left;">Expiry Date</th>
                      <th style="text-align: center;">Location</th>
                      <th style="text-align: left;">Approved By</th>
                      <th style="text-align: left;">Approved Date Time</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let kyc of kycdocumentmodelApprove.response; let i=index">
                  <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td align="center" *ngIf="kyc.approvalStatus==11">&nbsp;</td>
                      <td align="center" *ngIf="kyc.approvalStatus==12">&nbsp;</td>
                      <td align="center" *ngIf="kyc.approvalStatus==10" class="v-align-middle "><span>
                        <input type="checkbox" value=" {{kyc.id}}" (change)="onChange(kyc.id,$event)">
                      </span></td>
                      <td align="left">{{vehicleModel.vehicleNumber}}</td>
                      <td align="left" class="v-align-middle">{{kyc.kycId.kycName}}</td>
                      <td align="center" class="v-align-middle">
                        <button type="button" title="Image" class="label label-success" (click)="kycdocument(kyc.id,content1,kyc.kycId.kycName,kyc.fileType)">
                          <span aria-hidden="true"></span>
                          <i class="fa fa-image"></i>
                        </button>
                      </td>
                      <td align="left" *ngIf="kyc.createdBy!=null" class="v-align-middle">{{kyc.createdBy}}</td>
                      <td align="left" *ngIf="kyc.createdBy==null" class="v-align-middle">N/A</td>
                      <td align="left" *ngIf="kyc.createdDatetime!=null" class="v-align-middle">{{kyc.createdDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td align="left" *ngIf="kyc.createdDatetime==null" class="v-align-middle">N/A</td>
                      <td *ngIf="kyc.approvalStatus==10" align="center"  class="v-align-middle ht-Pending">Pending</td>
                      <td *ngIf="kyc.approvalStatus==11" align="center"  class="v-align-middle ht-active">Approved</td>
                      <td *ngIf="kyc.approvalStatus==12" align="center" style="color: red;">Rejected 
                        <button type="button" title="Reupload" (click)="reupload(content4,kyc.id)" class="label label-info">
                          <span aria-hidden="true"></span>
                          <i class="fa fa-upload" aria-hidden="true"></i>
                        </button>
                      </td>
                      <td *ngIf="kyc.approvalComments!=null"align="left" class="v-align-middle">{{kyc.approvalComments}}</td>
                      <td *ngIf="kyc.approvalComments==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="kyc.expiryDate!=null"align="left" class="v-align-middle">{{kyc.expiryDate | date:'dd/MM/yyyy' }}</td>
                      <td *ngIf="kyc.expiryDate==null"align="left" class="v-align-middle">N/A</td>
                      <td align="center" class="v-align-middle">
                        <button type="button" title="Location" class="label label-info" (click)="location(kyc.latitude,kyc.longitude)" (click)="open3(content2)">
                          <span aria-hidden="true"></span>
                          <i class="fa fa-location-arrow"></i>
                        </button>
                      </td>
                      <td *ngIf="kyc.approvalBy!=null"align="left" class="v-align-middle">{{kyc.approvalBy}}</td>
                      <td *ngIf="kyc.approvalBy==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="kyc.approvalDatetime!=null"align="left"  class="v-align-middle"><span class="v-align-middle ">{{kyc.approvalDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span></td>
                      <td *ngIf="kyc.approvalDatetime==null"align="left"  class="v-align-middle"><span class="v-align-middle ">N/A</span></td>
                </tr>
              </tbody>
            </table>
      </div>
    </div>

        <!-- --- Reject Kyc ----  -->
        <div *ngIf="rejectkyc" class="card-body">
          <div style="display: none;">{{kycdocumentmodelReject.responseMessage}}</div>
          <div style="display: none;">{{kycdocumentmodelReject.statusCode}}</div>
          <div class="table-responsive">
            <table datatable class="ch-table">
              <thead>
                <tr>
                  <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: center;">&nbsp;</th> 
                      <th style="text-align: left;">Vehicle Number</th>
                      <th style="text-align: left;">Document Name</th>
                      <th style="text-align: center;">KYC</th>
                      <th style="text-align: left;">Submitted By</th>
                      <th style="text-align: left;">Submitted Date & Time</th>
                      <th style="text-align: center;">Approval Status</th>
                      <th style="text-align: left;">Comments</th>
                      <th style="text-align: left;">Expiry Date</th>
                      <th style="text-align: center;">Location</th>
                      <th style="text-align: left;">Approved By</th>
                      <th style="text-align: left;">Approved Date Time</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let kyc of kycdocumentmodelReject.response; let i=index">
                  <td align="left" class="v-align-middle ">{{i + 1}}</td>
                      <td *ngIf="kyc.approvalStatus==11">&nbsp;</td>
                      <td *ngIf="kyc.approvalStatus==12">&nbsp;</td>
                      <td *ngIf="kyc.approvalStatus==10" class="v-align-middle "><span>
                        <input type="checkbox" value=" {{kyc.id}}" (change)="onChange(kyc.id,$event)">
                      </span></td>
                      <td align="left">{{vehicleModel.vehicleNumber}}</td>
                      <td align="center" class="v-align-middle">{{kyc.kycId.kycName}}</td>
                      <td align="center" class="v-align-middle">
                        <button type="button" title="Image" class="label label-success" (click)="kycdocument(kyc.id,content1,kyc.kycId.kycName,kyc.fileType)">
                          <span aria-hidden="true"></span>
                          <i class="fa fa-image"></i>
                        </button>
                      </td>
                      <td align="center" *ngIf="kyc.createdBy!=null" class="v-align-middle">{{kyc.createdBy}}</td>
                      <td align="center" *ngIf="kyc.createdBy==null" class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="kyc.createdDatetime!=null" class="v-align-middle">{{kyc.createdDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td align="center" *ngIf="kyc.createdDatetime==null" class="v-align-middle">N/A</td>
                      <td *ngIf="kyc.approvalStatus==10" align="center"  class="v-align-middle ht-Pending">Pending</td>
                      <td *ngIf="kyc.approvalStatus==11" align="center"  class="v-align-middle ht-active">Approved</td>
                      <td *ngIf="kyc.approvalStatus==12" align="center" style="color: red;">Rejected 
                        <button type="button" title="Reupload" (click)="reupload(content4,kyc.id)" class="label label-info">
                          <span aria-hidden="true"></span>
                          <i class="fa fa-upload" aria-hidden="true"></i>
                        </button>
                      </td>
                      <td *ngIf="kyc.approvalComments!=null"align="center" class="v-align-middle">{{kyc.approvalComments}}</td>
                      <td *ngIf="kyc.approvalComments==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="kyc.expiryDate!=null"align="center" class="v-align-middle">{{kyc.expiryDate | date:'dd/MM/yyyy' }}</td>
                      <td *ngIf="kyc.expiryDate==null"align="center" class="v-align-middle">N/A</td>
                      <td align="center" class="v-align-middle">
                        <button type="button" title="Location" class="label label-info" (click)="location(kyc.latitude,kyc.longitude)" (click)="open3(content2)">
                          <span aria-hidden="true"></span>
                          <i class="fa fa-location-arrow"></i>
                        </button>
                      </td>
                      <td *ngIf="kyc.approvalBy!=null"align="center" class="v-align-middle">{{kyc.approvalBy}}</td>
                      <td *ngIf="kyc.approvalBy==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="kyc.approvalDatetime!=null"align="center"  class="v-align-middle"><span class="v-align-middle ">{{kyc.approvalDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span></td>
                      <td *ngIf="kyc.approvalDatetime==null"align="center"  class="v-align-middle"><span class="v-align-middle ">N/A</span></td>
                </tr>
              </tbody>
            </table>
      </div>
    </div>
       
    </ng-template>
  </li>
  <!-- <li [ngbNavItem]="2" >
    <a ngbNavLink>Products</a>
    <ng-template ngbNavContent>
      <div class="card-body">
              <div class="table-responsive">
                <table class="table table-hover" id="basicTable">
                  <thead>
                    <tr> 
                      <th align="center" style="width:80px">Sl.No.</th>
                      <th align="left"  style="width:100px 
">Account Holder Name</th>
                      <th align="left" style="width:220"><span style="width:100px 
">Account</span> Number</th>
                      <th align="center" style="width:250"><span style="width:100px 
">Account</span> Type</th>
                      <th align="center" style="width:150">Bank name</th>
                      <th align="center" style="width:150 text-align:center;">Branch Name</th>
                      <th align="center" style="">IFSC Code</th>
                      <th align="center" style="width:180"><span style="width:20%">Approved </span> Date &amp; Time</th>
                      <th align="center" style="150">Starus</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td align="center" class="v-align-middle "><p>1</p></td>
                      <td align="left" class="v-align-middle"><p>Anbazhagan B </p></td>
                      <td class="v-align-middle"><p>87938750237530</p></td>
                      <td class="v-align-middle">Saving</td>
                      <td align="center" class="v-align-middle">HDFC Bank</td>
                      <td align="center" class="v-align-middle">Thambaram</td>
                      <td class="v-align-middle">HDFC548</td>
                      <td class="v-align-middle">29/09/2020<br> 01:00 pm</td>
                      <td class="v-align-middle ht-Success">Approved</td>
                    </tr>
                  </tbody>
                </table>
          </div>
        </div>
    </ng-template>
  </li> -->
  
  
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">KYC</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
    <form [formGroup]="userForm">
      <div class="form-group">
        <label for="dateOfBirth">Remarks *</label>
           <textarea placeholder="comments" class="form-control textarea" name="kyctextarea" id="kyctextarea" rows="3"></textarea>
      </div>
      <div class="ch-form-group">
        <label >Document Expiry Date</label>
        <input type="date" class="form-control" formControlName="date" id="date" name="date" aria-describedby="emailHelp" required>
      </div>
    </form>
    <br>
    <div *ngIf="poppupalert">
      <div style="color: red;">Please Enter comments!</div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="approve(1)">yes</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">no</button>
  </div>
</ng-template>

<ng-template #reject let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Do you want reject the Documents?</label>
           <textarea placeholder="comments" class="form-control textarea" name="kyctextrejectarea" id="kyctextrejectarea" rows="3"></textarea>
      </div>
    </form>
    <br>
    <div *ngIf="poppupalert">
      <div style="color: red;">Please Enter comments!</div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="approve(2)">yes</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">no</button>
  </div>
</ng-template>

  <ng-template #content1 let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">View KYC Image</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
      </div>
      <hr class="beautiful-line">
      <div>
        <h6>Front Image</h6>
        <img class="modal-content"  [src]="imageToShow" width="auto" height="auto">
      </div>
      <div *ngIf="bakImage">
        <br>
        <h6>Back Image</h6>
        <img class="modal-content"  [src]="imageToShowkyc" width="auto" height="auto">
      </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">close</button>
    </div>
  </ng-template>
<ng-template #content2 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">View Location</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
    </div>
    <hr class="beautiful-line">
  <agm-map [latitude]="lat" [longitude]="lan" style="height: 300px">
    <agm-marker [latitude]="lat" [longitude]="lan"></agm-marker>
  </agm-map>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">close</button>
  </div>
</ng-template>

<ng-template #content3 let-modal>
  <div *ngIf="!documentCheck">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Upload KYC</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
    </div>
    <div class="modal-body master-body">
    <form [formGroup]="myForm">
        <div class="ch-form-group">
          <label><b>KYC Document</b></label>
          <span class="required-asterisk"> *</span>
          <div class="dropdown">
            <select id="kycdocument"  name="kycdocument" class="form-control">
              <option *ngFor="let kyc of kycpendinglist.response" value={{kyc.kycId}}>{{kyc.kycName}}</option>
            </select>
  </div>
        </div>
        <br>
        <button style="margin-left: -20px;" class="btn-transaction">
          <div class="form-check">
            <input class="form-check-input" (click)="checkupload(1)" type="radio" name="exampleRadios" id="imgradio" value="option1" checked>
            <label class="form-check-label" for="imgradio">
              Image
            </label>
          </div>
          </button>
          <button class="btn-transaction">
            <div class="form-check">
              <input class="form-check-input" (click)="checkupload(2)" type="radio" name="exampleRadios" id="pdfradio" value="option2">
              <label class="form-check-label" for="pdfradio">
                PDF
              </label>
            </div>
            </button>  
        <br>
        <div *ngIf="imageUpload" class="form-group">
          <label style="color: black;">Front</label>
          <input style="margin-left: 10px;" #imageInput
          id="kycfile"
          name="kycfile"
           type="file"
           accept=".jpeg,.jpg"
           data-max-size="2048"
           placeholder="Front"
           (change)="selectFile($event)">
          <br>
          <br>
          <label style="color: black;"> Back</label>
           <input style="margin-left: 10px;" #imageInput
          id="kycfile1"
          name="kycfile1"
           type="file"
           accept=".jpeg,.jpg"
           data-max-size="2048"
          placeholder="Back"
           (change)="selectFile1($event)">
      </div>

        <div *ngIf="pdfUpload" class="form-group">
          <input #imageInput
          id="kycfile"
          name="kycfile"
           type="file"
           accept=".pdf,.jpeg,.jpg"
           data-max-size="2048"
           (change)="selectFile($event)">
      </div>
      <b>Note</b> : Files can be .Jpeg and .pdf format which should not exceed more than 2 MB
        </form>
        <br>
        <div *ngIf="emptyCheck" style="color: red;">{{emptyCheckResponse}}</div>
      </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" style="color: green;" (click)="uploadKyc()">Submit</button>
      <button type="button" class="btn btn-outline-dark" style="color: red;" (click)="modal.close('Save click')">Cancel</button>
    </div>
  </div>
  <div *ngIf="documentCheck">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Upload KYC</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
    </div>
    <div class="modal-body master-body">
      <p>You have already upload all documents</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
    </div>
  </div>
</ng-template>

<ng-template #content4 let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Upload KYC</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
    </div>
    <div class="modal-body master-body">
      <button style="margin-left: -20px;" class="btn-transaction">
        <div class="form-check">
          <input class="form-check-input" (click)="checkupload(1)" type="radio" name="exampleRadios" id="imgradio" value="option1" checked>
          <label class="form-check-label" for="imgradio">
            Image
          </label>
        </div>
        </button>
        <button class="btn-transaction">
          <div class="form-check">
            <input class="form-check-input" (click)="checkupload(2)" type="radio" name="exampleRadios" id="pdfradio" value="option2">
            <label class="form-check-label" for="pdfradio">
              PDF
            </label>
          </div>
          </button>  
      
    <form [formGroup]="myForm">
      <br> 
      <div *ngIf="imageUpload" class="form-group">
        <label style="color: black;">Front</label>
        <input style="margin-left: 10px;" #imageInput
        id="kycfile"
        name="kycfile"
         type="file"
         accept=".jpeg,.jpg"
         data-max-size="2048"
         placeholder="Front"
         (change)="selectFile($event)">
        <br>
        <br>
        <label style="color: black;"> Back</label>
         <input style="margin-left: 10px;" #imageInput
        id="kycfile1"
        name="kycfile1"
         type="file"
         accept=".jpeg,.jpg"
         data-max-size="2048"
        placeholder="Back"
         (change)="selectFile1($event)">
    </div>

      <div *ngIf="pdfUpload" class="form-group">
        <input #imageInput
        id="kycfile"
        name="kycfile"
         type="file"
         accept=".pdf,.jpeg,.jpg"
         data-max-size="2048"
         (change)="selectFile($event)">
    </div>
      <b>Note</b> : Files can be .Jpeg and .pdf format which should not exceed more than 2 MB
        </form>
        <br>
        <div *ngIf="emptyCheck" style="color: red;">{{emptyCheckResponse}}</div>
      </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" style="color: green;" (click)="reuploadKyc()">Submit</button>
      <button type="button" class="btn btn-outline-dark" style="color: red;" (click)="modal.close('Save click')">Cancel</button>
    </div>
</ng-template>
