<app-header></app-header>

<!-- START PAGE-CONTAINER -->
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
</ngx-spinner> 
<div class="page-container "> 
  <!-- START PAGE CONTENT WRAPPER -->
  <div class="page-content-wrapper "> 
    <!-- START PAGE CONTENT -->
    <div class="content sm-gutter"> 
      <!-- START BREADCRUMBS -->
      <div class="bg-white">
        <div class="container">
          <ol class="breadcrumb breadcrumb-alt">
            <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Home</a></li>
            <li class="breadcrumb-item active"><span class="font-montserrat fs-11 all-caps">Transactions</span></li>
          </ol>
          <!-- <ol *ngIf="div2"class="breadcrumb breadcrumb-alt">
            <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Home</a></li>
            <li class="breadcrumb-item active"><span class="font-montserrat fs-11 all-caps">Settlements</span></li>
          </ol> -->
        </div>
      </div>
      <!-- END BREADCRUMBS -->
      <!-- <div class="container search-section flex"> -->
    <!-- <button style="margin-left:500px;" class="btn-transaction" (click)="div1Function()">
         <div class="form-check">
  <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked>
  <label class="form-check-label" for="exampleRadios1">
    Transaction
  </label>
</div>
</button>
<button class="btn-transaction" (click)="div2Function()">
<div class="form-check">
  <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2">
  <label class="form-check-label" for="exampleRadios2">
   Settlement
  </label>
</div>
</button> -->
         <!-- </div> -->
<div *ngIf="defaultTransaction">
<div *ngIf="accountStatus==1">
  <div style="display: none;">{{transactionDetail.responseMessage}}</div>
        <div class="container Customers-top-notification card4view">
        <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 315px; margin-left: 5px;">
          <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                  <div _ngcontent-tgd-c17="" class="card-title">Total Transaction Amount (Success) </div>
                  <div _ngcontent-tgd-c17="" class="card-controls">
                    <ul _ngcontent-tgd-c17="">
                      <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                  <h3 *ngIf="transactionDetail.amount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i> {{transactionDetail.amount | number : '1.2-2' }}</h3>
                  <h3 *ngIf="transactionDetail.amount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i>0</h3>
                  <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% </span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
          <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                  <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Success) </div>
                  <div _ngcontent-tgd-c17="" class="card-controls">
                    <ul _ngcontent-tgd-c17="">
                      <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                  <h3 *ngIf="transactionDetail.activeCount!=null" _ngcontent-tgd-c17="" class="no-margin p-b-5">
                    {{transactionDetail.activeCount}}</h3>
                  <h3 *ngIf="transactionDetail.activeCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">
                    0</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
          <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                  <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Failed) </div>
                  <div _ngcontent-tgd-c17="" class="card-controls">
                    <ul _ngcontent-tgd-c17="">
                      <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                  <h3 *ngIf="transactionDetail.inactiveCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionDetail.inactiveCount}}</h3>
                  <h3 *ngIf="transactionDetail.inactiveCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                  <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71%</span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 300px; margin-left: -20px;">
          <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                  <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Initiated)</div>
                  <div _ngcontent-tgd-c17="" class="card-controls">
                    <ul _ngcontent-tgd-c17="">
                      <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                  <h3 *ngIf="transactionDetail.initiatedCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionDetail.initiatedCount}}</h3>
                  <h3 *ngIf="transactionDetail.initiatedCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                  <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
       
        
      </div>
       
      
      
      <!-- START CONTAINER FLUID --> 
      
      <!-- START CONTAINER FLUID -->
      <div class=" no-padding    container-fixed-lg bg-white">
        <div class="container">
          <!-- START card -->
          <select class="select-form-style" style="margin-left: 20px; margin-top: 10px;" id="searchBy" (change)="callsearch($event)">  
            <option value="1">Search By Initiated Date & Time</option>  
            <option value="2">Search By Transaction Date & Time</option>  
            <option value="3">Search By All</option>  
            <option value="4">Search By Onboarded By</option>  
        </select>  
        <input type="date" style="margin-left: 10px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
        <input type="date" style="margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
        <select class="select-form-style" style="margin-left: 10px;"  id="accountstatus">
          <option value="1" selected>Customer</option>  
          <option value="2" >Partner</option>  
      </select>
      <select class="select-form-style" style="margin-left: 10px; display: none;" id="onboarded">
        <option value="1">CinchFuel</option>  
        <option value="2">Others</option>  
    </select>    
      <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
      <button title="Refresh" *ngIf="refreshflag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
      <button title="Excel Export" *ngIf="excelexportchecktransaction" (click)="exportToExcelTransaction()" title="Excel Export" style="margin-left: 10px; margin-top: 5px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
    <div *ngIf="errorFlag" style="color: red; margin-left: 20px;">{{errorMessage}}</div>
          <div class="card card-transparent">
            <div class="card-body">
              <div class="table-responsive">
                <table id="excel-table" datatable  class="ch-table" >
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Order ID</th>
                      <!-- <th style="text-align: left;">Transaction ID</th> -->
                      <th style="text-align: left;">Customer Info</th>
                      <th style="text-align: left;">Delivery Agent Info</th>
                      <th style="text-align: left;">Fuel</th>
                      <!-- <th style="text-align: center;">Quantity in Ltrs</th> -->
                      <th style="text-align: right;">Transaction Amount</th>
                      <th style="text-align: center;">Payment Status</th>
                      <th style="text-align: center;">Payment Mode</th>
                      <th style="text-align: left;">Initiated Date Time</th>
                      <th style="text-align: left;">Transaction Date Time</th>
                      <th style="text-align: center; width: 100px;" >Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let transaction of transactionDetail.response; let i=index">
                      <td align="center" class="v-align-middle "><p>{{i + 1}}</p></td>
                      <td *ngIf="transaction.orderId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 10px;" ><b>{{transaction.orderId}}</b></span><br>
                        <span *ngIf="transaction.transactionId!=null" style="font-size: 10px;"><b>Txn Id :</b>{{transaction.transactionId}}</span>
                        <span *ngIf="transaction.transactionId==null" style="font-size: 10px;"><b>Txn Id :</b>N/A</span>
                      </td>
                      <td *ngIf="transaction.orderId==null"align="left" class="v-align-middle"><p>N/A</p></td>

                      <!-- <td *ngIf="transaction.transactionId!=null"align="left" class="v-align-middle"><p>{{transaction.transactionId}}</p></td>
                      <td *ngIf="transaction.transactionId==null"align="left" class="v-align-middle"><p>N/A</p></td> -->

                      <td *ngIf="transaction.customerId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 12px;">{{transaction.customerId.firstName}} {{transaction.customerId.lastName}}</span><br>
                        <span style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.customerId.mobileNumber}}</span>
                        <!-- <p style="font-size: 12px;">{{transaction.customerId.firstName}} {{transaction.customerId.lastName}} </p>
                        <p style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.customerId.mobileNumber}}</p> -->
                      </td>
                      <td *ngIf="transaction.customerId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                      <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 12px;">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</span><br>
                        <span style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.vehicleId.userId.mobileNumber}}</span>
                          <!-- <p style="font-size: 12px;">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</p>
                          <p style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.vehicleId.userId.mobileNumber}}</p> -->
                        </td>
                        <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                        <td *ngIf="transaction.fuelType!=null" align="left" class="v-align-middle">
                          <span style="font-size: 10px;">{{transaction.fuelType}}</span><br>
                          <span style="font-size: 10px;"><b>Qtn : </b> {{transaction.quantity}}</span>
                          <!-- <p style="font-size: 10px;">{{transaction.fuelType}}</p>  
                          <p style="font-size: 10px;"><b>Qtn : </b> {{transaction.quantity}}</p> -->
                          </td>
                      <td *ngIf="transaction.fuelType==null"align="left" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="transaction.quantity!=null"align="center" class="v-align-middle">{{transaction.quantity}}</td>
                      <td *ngIf="transaction.quantity==null"align="center" class="v-align-middle">N/A</td> -->
                      <td align="right" *ngIf="transaction.totalPayableAmount!=null"class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.totalPayableAmount | number : '1.2-2'}}</td>
                      <td align="right" *ngIf="transaction.totalPayableAmount==null"class="v-align-middle">N/A</td>
                      
                      <td align="center" *ngIf="transaction.paymentModeId.id==1">
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-inactive">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' " style="color: red;">Failed</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                        <span  *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' " style="color: blue;">{{transaction.paymentStatus}}</span> 
                      </td>
                      <td align="center" *ngIf="transaction.paymentModeId.id!=1">
                         <span *ngIf="transaction.approvalStatus==='Pending' || transaction.approvalStatus==null" class="v-align-middle ht-inactive">Pending</span> 
                        <span *ngIf="transaction.approvalStatus==='Success' || transaction.approvalStatus==='Approved'" class="v-align-middle ht-active">Success</span> 

                        <span *ngIf="transaction.approvalStatus==='Submitted' "class="v-align-middle ht-active">
                          <span *ngIf="transaction.chequeClaimStatus=='Success'">Success</span> 
                          <span *ngIf="transaction.chequeClaimStatus!='Success'"> {{transaction.chequeClaimStatus}}</span> 
                        </span> 

                        <span *ngIf="transaction.approvalStatus==='Failed' " style="color: red;">Failed</span> 
                        <span *ngIfElse="transaction.approvalStatus!=null " style="color: orange;">{{transaction.approvalStatus}}</span> 
                      </td>
                    
                    <td> {{transaction.paymentModeId.paymentMode}} </td>
                    
                      <td *ngIf="transaction.initiatedDatetime!=null"align="left" class="v-align-middle">{{transaction.initiatedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.initiatedDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paymentDatetime!=null"align="left" class="v-align-middle">{{transaction.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.paymentDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td align="left">
                        <button title="view" class="label label-info" (click)="view(content,transaction.id)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                        <span *ngIf="transaction.paymentModeId.id==5"> 
                          <button title="view Cheque and DD" class="label label-info" (click)="viewproof(transaction.id,transaction.orderProof,contentproof,1)"><i class="fa fa-money" aria-hidden="true"></i></button>&nbsp;
                         </span>

                         <span *ngIf="transaction.orderReceipt!=null"> 
                          <button title="view Receipt" class="label label-info" (click)="viewproof(transaction.id,transaction.orderReceipt,contentproof,2)"><i class="fa fa-list" aria-hidden="true"></i></button>&nbsp;
                         </span>

                         <button *ngIf="transaction.paymentModeId.id!=1 && (transaction.approvalStatus=='Pending' || transaction.approvalStatus=='Failed')" title="Update Approval Status" class="label label-info" (click)="update(content7,transaction.id,transaction.paymentModeId.id,transaction.approvalStatus,transaction.receivedAmount,transaction.paymentReferenceNo,transaction.totalPayableAmount)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;
                        
                         <button *ngIf="transaction.paymentModeId.id==5 && transaction.approvalStatus=='Submitted' && transaction.chequeClaimStatus!='Success'" title="Update Claim Status" class="label label-info" (click)="updateClaim(content9,transaction.id,transaction.paymentReferenceNo,transaction.approvalStatus,transaction.chequeClaimStatus)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;

                         <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='Cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='Failure'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- END card --> 
        </div>
      </div>
      <!-- END CONTAINER FLUID --> 
    </div>

    <div *ngIf="accountStatus==2">
      <div style="display: none;">{{transactionDetail.responseMessage}}</div>
            <div class="container Customers-top-notification card4view">
            <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 315px; margin-left: 5px;">
              <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                <div _ngcontent-tgd-c17="" class="row-xs-height">
                  <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                    <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                      <div _ngcontent-tgd-c17="" class="card-title">Total Transaction Amount (Success) </div>
                      <div _ngcontent-tgd-c17="" class="card-controls">
                        <ul _ngcontent-tgd-c17="">
                          <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="row-xs-height">
                  <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                    <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                      <h3 *ngIf="transactionDetail.amount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i> {{transactionDetail.amount | number : '1.2-2'}}</h3>
                      <h3 *ngIf="transactionDetail.amount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i>0</h3>
                      <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% </span> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
              <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                <div _ngcontent-tgd-c17="" class="row-xs-height">
                  <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                    <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                      <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Success) </div>
                      <div _ngcontent-tgd-c17="" class="card-controls">
                        <ul _ngcontent-tgd-c17="">
                          <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="row-xs-height">
                  <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                    <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                      <h3 *ngIf="transactionDetail.activeCount!=null" _ngcontent-tgd-c17="" class="no-margin p-b-5">
                        {{transactionDetail.activeCount}}</h3>
                      <h3 *ngIf="transactionDetail.activeCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">
                        0</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
              <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                <div _ngcontent-tgd-c17="" class="row-xs-height">
                  <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                    <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                      <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Failed) </div>
                      <div _ngcontent-tgd-c17="" class="card-controls">
                        <ul _ngcontent-tgd-c17="">
                          <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="row-xs-height">
                  <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                    <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                      <h3 *ngIf="transactionDetail.inactiveCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionDetail.inactiveCount}}</h3>
                      <h3 *ngIf="transactionDetail.inactiveCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                      <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71%</span> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 300px; margin-left: -20px;">
              <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                <div _ngcontent-tgd-c17="" class="row-xs-height">
                  <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                    <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                      <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Initiated)</div>
                      <div _ngcontent-tgd-c17="" class="card-controls">
                        <ul _ngcontent-tgd-c17="">
                          <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="row-xs-height">
                  <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                    <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                      <h3 *ngIf="transactionDetail.initiatedCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionDetail.initiatedCount}}</h3>
                      <h3 *ngIf="transactionDetail.initiatedCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                      <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
            
          </div>
          <!-- START CONTAINER FLUID --> 
          
          <!-- START CONTAINER FLUID -->
          <div class=" no-padding    container-fixed-lg bg-white">
            <div class="container">
              <select class="select-form-style" style="margin-left: 20px; margin-top: 10px;" id="searchBy" (change)="callsearch($event)">  
                <option value="1">Search By Initiated Date & Time</option>  
                <option value="2">Search By Transaction Date & Time</option>  
                <option value="3">Search By All</option>
                <option value="4">Search By Onboarded By</option>  
            </select>  
            <input type="date" style="margin-left: 10px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
            <input type="date" style="margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
            <select class="select-form-style" style="margin-left: 10px;" id="accountstatus">
              <option value="1" selected>Customer</option>  
              <option value="2" >Partner</option>  
          </select>
          <select class="select-form-style" style="margin-left: 10px; display: none;" id="onboarded">
            <option value="1">CinchFuel</option>  
            <option value="2">Others</option>  
        </select>
          <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
          <button title="Refresh" *ngIf="refreshflag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
          <button title="Excel Export" *ngIf="excelexportchecktransaction" (click)="exportToExcelTransaction()" title="Excel Export" style="margin-left: 10px; margin-top: 5px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
        <div *ngIf="errorFlag" style="color: red; margin-left: 20px;">{{errorMessage}}</div>
              <!-- START card -->
              <div class="card card-transparent">
                <!-- <div class="card-header "> -->
                <div class="card-body">
                  <div class="table-responsive">
                    <table id="excel-table" datatable class="ch-table" >
                      <thead>
                        <tr> 
                          <th style="text-align: center;">Sl.No.</th>
                          <th style="text-align: left;">Transaction ID</th>
                          <th style="text-align: left;">Partner Name</th>
                          <th style="text-align: left;">Partner Mobile</th>
                          <th style="text-align: right;">Transaction Amount</th>
                          <th style="text-align: center;">Payment Status</th>
                          <th style="text-align: center;">Payment Mode</th>
                          <th style="text-align: left;">Initiated Date Time</th>
                          <th style="text-align: left;">Transaction Date Time</th>
                          <th style="text-align: center; width: 100px;" >Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let transaction of transactionDetail.response; let i=index">
                          <td align="center" class="v-align-middle ">{{i + 1}}</td>
                          <td *ngIf="transaction.paymentId!=null"align="left" class="v-align-middle">{{transaction.paymentId}}</td>
                          <td *ngIf="transaction.paymentId==null"align="left" class="v-align-middle">N/A</td>
                          <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</td>
                          <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                          <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">{{transaction.vehicleId.userId.mobileNumber}}</td>
                          <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                          <td align="right" *ngIf="transaction.totalPayableAmount!=null"class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.totalPayableAmount | number : '1.2-2'}}</td>
                          <td align="right" *ngIf="transaction.totalPayableAmount==null"class="v-align-middle">N/A</td>
                          <td align="center" *ngIf="transaction.paymentModeId.id==1">
                            <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-inactive">{{transaction.paymentStatus}}</span> 
                            <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</span> 
                            <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' " style="color: red;">Failed</span> 
                            <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                            <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                            <span  *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' " style="color: blue;">{{transaction.paymentStatus}}</span> 
                          </td>
                         
                          <td align="center" *ngIf="transaction.paymentModeId.id!=1">
                            <span *ngIf="transaction.approvalStatus==='Pending' || transaction.approvalStatus==null" class="v-align-middle ht-inactive">Pending</span> 
                           <span *ngIf="transaction.approvalStatus==='Success' || transaction.approvalStatus==='Approved'" class="v-align-middle ht-active">Success</span> 
   
                           <span *ngIf="transaction.approvalStatus==='Submitted' "class="v-align-middle ht-active">
                             <span *ngIf="transaction.chequeClaimStatus=='Success'">Success</span> 
                             <span *ngIf="transaction.chequeClaimStatus!='Success'"> {{transaction.chequeClaimStatus}}</span> 
                           </span> 
   
                           <span *ngIf="transaction.approvalStatus==='Failed' " style="color: red;">Failed</span> 
                           <span *ngIfElse="transaction.approvalStatus!=null " style="color: orange;">{{transaction.approvalStatus}}</span> 
                         </td>
                       
                         
                          <td>{{transaction.paymentModeId.paymentMode}}</td>
                          <td *ngIf="transaction.initiateDatetime!=null"align="left" class="v-align-middle">{{transaction.initiateDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                          <td *ngIf="transaction.initiateDatetime==null"align="left" class="v-align-middle">N/A</td>
                          <td *ngIf="transaction.transactionDatetime!=null"align="left" class="v-align-middle">{{transaction.transactionDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                          <td *ngIf="transaction.transactionDatetime==null"align="left" class="v-align-middle">N/A</td>
                          <td align="left">
                            <button title="view" class="label label-info" (click)="view(content,transaction.id)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                            <span *ngIf="transaction.paymentModeId.id==5"> 
                              <button title="view Cheque and DD" class="label label-info" (click)="viewproof(transaction.id,transaction.orderProof,contentproof,1)"><i class="fa fa-money" aria-hidden="true"></i></button>&nbsp;
                             </span>
    
                             <span *ngIf="transaction.orderReceipt!=null"> 
                              <button title="view Receipt" class="label label-info" (click)="viewproof(transaction.id,transaction.orderReceipt,contentproof,2)"><i class="fa fa-list" aria-hidden="true"></i></button>&nbsp;
                             </span>
                             <button *ngIf="transaction.paymentModeId.id==5 && transaction.approvalStatus=='Submitted' && transaction.chequeClaimStatus!='Success'" title="Update Claim Status" class="label label-info" (click)="updateClaim(content9,transaction.id,transaction.paymentReferenceNo,transaction.approvalStatus,transaction.chequeClaimStatus)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;

                             <button *ngIf="transaction.paymentModeId.id!=1 && (transaction.approvalStatus=='Pending' || transaction.approvalStatus=='Failed')" title="Update Approval Status" class="label label-info" (click)="update(content7,transaction.id,transaction.paymentModeId.id,transaction.approvalStatus,transaction.receivedAmount,transaction.paymentReferenceNo,transaction.totalPayableAmount)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;

                             <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                            <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                            <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                            <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                            <button *ngIf="transaction.paymentStatus==='cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                            <button *ngIf="transaction.paymentStatus==='Cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                            <button *ngIf="transaction.paymentStatus==='Failure'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- END card --> 
            </div>
          </div>
          <!-- END CONTAINER FLUID --> 
        </div>   
</div>

<!-- Filter 1 -->
<div *ngIf="filterTransaction1">  
  <div *ngIf="filteronesearch">
    <div *ngIf="accountStatus==1">
      <div style="display: none;">{{transactionfilter1Detail.responseMessage}}</div>
            <div class="container Customers-top-notification card4view">
            <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 315px; margin-left: 5px;">
              <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                <div _ngcontent-tgd-c17="" class="row-xs-height">
                  <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                    <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                      <div _ngcontent-tgd-c17="" class="card-title">Total Transaction Amount (Success) </div>
                      <div _ngcontent-tgd-c17="" class="card-controls">
                        <ul _ngcontent-tgd-c17="">
                          <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="row-xs-height">
                  <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                    <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                      <h3 *ngIf="transactionfilter1Detail.amount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i> {{transactionfilter1Detail.amount | number : '1.2-2'}}</h3>
                      <h3 *ngIf="transactionfilter1Detail.amount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i>0</h3>
                      <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% </span> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
              <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                <div _ngcontent-tgd-c17="" class="row-xs-height">
                  <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                    <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                      <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Success) </div>
                      <div _ngcontent-tgd-c17="" class="card-controls">
                        <ul _ngcontent-tgd-c17="">
                          <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="row-xs-height">
                  <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                    <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                      <h3 *ngIf="transactionfilter1Detail.activeCount!=null" _ngcontent-tgd-c17="" class="no-margin p-b-5">
                        {{transactionfilter1Detail.activeCount}}</h3>
                      <h3 *ngIf="transactionfilter1Detail.activeCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">
                        0</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
              <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                <div _ngcontent-tgd-c17="" class="row-xs-height">
                  <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                    <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                      <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Failed) </div>
                      <div _ngcontent-tgd-c17="" class="card-controls">
                        <ul _ngcontent-tgd-c17="">
                          <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="row-xs-height">
                  <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                    <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                      <h3 *ngIf="transactionfilter1Detail.inactiveCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter1Detail.inactiveCount}}</h3>
                      <h3 *ngIf="transactionfilter1Detail.inactiveCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                      <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71%</span> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 300px; margin-left: -20px;">
              <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                <div _ngcontent-tgd-c17="" class="row-xs-height">
                  <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                    <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                      <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Initiated)</div>
                      <div _ngcontent-tgd-c17="" class="card-controls">
                        <ul _ngcontent-tgd-c17="">
                          <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="row-xs-height">
                  <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                    <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                      <h3 *ngIf="transactionfilter1Detail.initiatedCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter1Detail.initiatedCount}}</h3>
                      <h3 *ngIf="transactionfilter1Detail.initiatedCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                      <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
            
          </div>
           
          
          
          <!-- START CONTAINER FLUID --> 
          
          <!-- START CONTAINER FLUID -->
          <div class=" no-padding    container-fixed-lg bg-white">
            <div class="container">
              <!-- START card -->
              <select class="select-form-style" style="margin-left: 20px; margin-top: 10px;" [(ngModel)]="searchStatus" id="searchBy" (change)="callsearch($event)">  
                <option value="1">Search By Initiated Date & Time</option>  
                <option value="2">Search By Transaction Date & Time</option>  
                <option value="3">Search By All</option>  
                <option value="4">Search By Onboarded By</option> 
            </select>  
            <input *ngIf="searchStatus!=3"type="date" style="margin-left: 10px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
            <input *ngIf="searchStatus!=3"type="date" style="margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
  
            <input *ngIf="searchStatus==3" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
            <input *ngIf="searchStatus==3" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
            <select class="select-form-style"  style="margin-left: 10px;" [(ngModel)]="searchByCusorPart" id="accountstatus">
              <option value="1" >Customer</option>  
              <option value="2" >Partner</option>  
          </select>
          <select class="select-form-style" style="margin-left: 10px; display: none;" id="onboarded">
            <option value="1">CinchFuel</option>  
            <option value="2">Others</option>  
        </select>
          <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
          <button title="Refresh" *ngIf="refreshflag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
          <button title="Excel Export" *ngIf="excelexportchecktransaction" (click)="exportToExcelTransaction()" title="Excel Export" style="margin-left: 10px; margin-top: 5px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
        <div *ngIf="errorFlag" style="color: red; margin-left: 20px;">{{errorMessage}}</div>
              <div class="card card-transparent">
                <div class="card-body">
                  <div class="table-responsive" >
                    <table id="excel-table" datatable class="ch-table" >
                      <thead>
                        <tr> 
                          <th style="text-align: center;">Sl.No.</th>
                          <th style="text-align: left;">Order ID</th>
                          <!-- <th style="text-align: left;">Transaction ID</th> -->
                          <th style="text-align: left;">Customer Info</th>
                          <th style="text-align: left;">Delivery Agent Info</th>
                          <th style="text-align: left;">Fuel</th>
                          <!-- <th style="text-align: center;">Quantity in Ltrs</th> -->
                          <th style="text-align: right;">Transaction Amount</th>
                          <th style="text-align: center;">Payment Status</th>
                          <th style="text-align: center;">Payment Mode</th>
                          <th style="text-align: left;">Initiated Date Time</th>
                          <th style="text-align: left;">Transaction Date Time</th>
                          <th style="text-align: center; width: 100px;" >Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let transaction of transactionfilter1Detail.response; let i=index">
                          <td align="center" class="v-align-middle "><p>{{i + 1}}</p></td>
                      <td *ngIf="transaction.orderId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 10px;" ><b>{{transaction.orderId}}</b></span><br>
                        <span *ngIf="transaction.transactionId!=null" style="font-size: 10px;"><b>Txn Id :</b>{{transaction.transactionId}}</span>
                        <span *ngIf="transaction.transactionId==null" style="font-size: 10px;"><b>Txn Id :</b>N/A</span>
                      </td>
                      <td *ngIf="transaction.orderId==null"align="left" class="v-align-middle"><p>N/A</p></td>

                      <!-- <td *ngIf="transaction.transactionId!=null"align="left" class="v-align-middle"><p>{{transaction.transactionId}}</p></td>
                      <td *ngIf="transaction.transactionId==null"align="left" class="v-align-middle"><p>N/A</p></td> -->

                      <td *ngIf="transaction.customerId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 12px;">{{transaction.customerId.firstName}} {{transaction.customerId.lastName}}</span><br>
                        <span style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.customerId.mobileNumber}}</span>
                        <!-- <p style="font-size: 12px;">{{transaction.customerId.firstName}} {{transaction.customerId.lastName}} </p>
                        <p style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.customerId.mobileNumber}}</p> -->
                      </td>
                      <td *ngIf="transaction.customerId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                      <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 12px;">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</span><br>
                        <span style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.vehicleId.userId.mobileNumber}}</span>
                          <!-- <p style="font-size: 12px;">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</p>
                          <p style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.vehicleId.userId.mobileNumber}}</p> -->
                        </td>
                        <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                        <td *ngIf="transaction.fuelType!=null" align="left" class="v-align-middle">
                          <span style="font-size: 10px;">{{transaction.fuelType}}</span><br>
                          <span style="font-size: 10px;"><b>Qtn : </b> {{transaction.quantity}}</span>
                          <!-- <p style="font-size: 10px;">{{transaction.fuelType}}</p>  
                          <p style="font-size: 10px;"><b>Qtn : </b> {{transaction.quantity}}</p> -->
                          </td>
                      <td *ngIf="transaction.fuelType==null"align="left" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="transaction.quantity!=null"align="center" class="v-align-middle">{{transaction.quantity}}</td>
                      <td *ngIf="transaction.quantity==null"align="center" class="v-align-middle">N/A</td> -->
                      <td align="right" *ngIf="transaction.totalPayableAmount!=null"class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.totalPayableAmount | number : '1.2-2'}}</td>
                      <td align="right" *ngIf="transaction.totalPayableAmount==null"class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="transaction.paymentModeId.id==1">
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-inactive">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' " style="color: red;">Failed</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                        <span  *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' " style="color: blue;">{{transaction.paymentStatus}}</span> 
                      </td>
                      <td align="center" *ngIf="transaction.paymentModeId.id!=1">
                        <span *ngIf="transaction.approvalStatus==='Pending' || transaction.approvalStatus==null" class="v-align-middle ht-inactive">Pending</span> 
                       <span *ngIf="transaction.approvalStatus==='Success' || transaction.approvalStatus==='Approved'" class="v-align-middle ht-active">Success</span> 

                       <span *ngIf="transaction.approvalStatus==='Submitted' "class="v-align-middle ht-active">
                         <span *ngIf="transaction.chequeClaimStatus=='Success'">Success</span> 
                         <span *ngIf="transaction.chequeClaimStatus!='Success'"> {{transaction.chequeClaimStatus}}</span> 
                       </span> 

                       <span *ngIf="transaction.approvalStatus==='Failed' " style="color: red;">Failed</span> 
                       <span *ngIfElse="transaction.approvalStatus!=null " style="color: orange;">{{transaction.approvalStatus}}</span> 
                     </td>
                   
                      <td> {{transaction.paymentModeId.paymentMode}} </td>
                      <td *ngIf="transaction.initiatedDatetime!=null"align="left" class="v-align-middle">{{transaction.initiatedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.initiatedDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paymentDatetime!=null"align="left" class="v-align-middle">{{transaction.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.paymentDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td align="left">
                        <button title="view" class="label label-info" (click)="view(content,transaction.id)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                        <span *ngIf="transaction.paymentModeId.id==5"> 
                          <button title="view Cheque and DD" class="label label-info" (click)="viewproof(transaction.id,transaction.orderProof,contentproof,1)"><i class="fa fa-money" aria-hidden="true"></i></button>&nbsp;
                         </span>

                         <span *ngIf="transaction.orderReceipt!=null"> 
                          <button title="view Receipt" class="label label-info" (click)="viewproof(transaction.id,transaction.orderReceipt,contentproof,2)"><i class="fa fa-list" aria-hidden="true"></i></button>&nbsp;
                         </span>

                         <button *ngIf="transaction.paymentModeId.id!=1 && (transaction.approvalStatus=='Pending' || transaction.approvalStatus=='Failed')" title="Update Approval Status" class="label label-info" (click)="update(content7,transaction.id,transaction.paymentModeId.id,transaction.approvalStatus,transaction.receivedAmount,transaction.paymentReferenceNo,transaction.totalPayableAmount)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;

                         <button *ngIf="transaction.paymentModeId.id==5 && transaction.approvalStatus=='Submitted' && transaction.chequeClaimStatus!='Success'" title="Update Claim Status" class="label label-info" (click)="updateClaim(content9,transaction.id,transaction.paymentReferenceNo,transaction.approvalStatus,transaction.chequeClaimStatus)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;

                         <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='Cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='Failure'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                      </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- END card --> 
            </div>
          </div>
          <!-- END CONTAINER FLUID --> 
        </div>
    
        <div *ngIf="accountStatus==2">
          <div style="display: none;">{{transactionfilter1Detail.responseMessage}}</div>
                <div class="container Customers-top-notification card4view">
                <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 315px; margin-left: 5px;">
                  <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                          <div _ngcontent-tgd-c17="" class="card-title">Total Transaction Amount (Success) </div>
                          <div _ngcontent-tgd-c17="" class="card-controls">
                            <ul _ngcontent-tgd-c17="">
                              <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="transactionfilter1Detail.amount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i> {{transactionfilter1Detail.amount | number : '1.2-2'}}</h3>
                          <h3 *ngIf="transactionfilter1Detail.amount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i>0</h3>
                          <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% </span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                  <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                          <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Success) </div>
                          <div _ngcontent-tgd-c17="" class="card-controls">
                            <ul _ngcontent-tgd-c17="">
                              <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="transactionfilter1Detail.activeCount!=null" _ngcontent-tgd-c17="" class="no-margin p-b-5">
                            {{transactionfilter1Detail.activeCount}}</h3>
                          <h3 *ngIf="transactionfilter1Detail.activeCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">
                            0</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                  <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                          <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Failed) </div>
                          <div _ngcontent-tgd-c17="" class="card-controls">
                            <ul _ngcontent-tgd-c17="">
                              <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="transactionfilter1Detail.inactiveCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter1Detail.inactiveCount}}</h3>
                          <h3 *ngIf="transactionfilter1Detail.inactiveCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                          <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71%</span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 300px; margin-left: -20px;">
                  <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                          <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Initiated)</div>
                          <div _ngcontent-tgd-c17="" class="card-controls">
                            <ul _ngcontent-tgd-c17="">
                              <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="transactionfilter1Detail.initiatedCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter1Detail.initiatedCount}}</h3>
                          <h3 *ngIf="transactionfilter1Detail.initiatedCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                          <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               
                
              </div>
               
              
              
              <!-- START CONTAINER FLUID --> 
              
              <!-- START CONTAINER FLUID -->
              <div class=" no-padding    container-fixed-lg bg-white">
                <div class="container">
                  <select class="select-form-style" style="margin-left: 20px; margin-top: 10px;" [(ngModel)]="searchStatus" id="searchBy" (change)="callsearch($event)">  
                    <option value="1">Search By Initiated Date & Time</option>  
                    <option value="2">Search By Transaction Date & Time</option>  
                    <option value="3">Search By All</option>  
                    <option value="4">Search By Onboarded By</option> 
                </select>  
                <input *ngIf="searchStatus!=3"type="date" style="margin-left: 10px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
            <input *ngIf="searchStatus!=3"type="date" style="margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
  
            <input *ngIf="searchStatus==3" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
            <input *ngIf="searchStatus==3" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
                <select class="select-form-style" style="margin-left: 10px;" [(ngModel)]="searchByCusorPart" id="accountstatus">
                  <option value="1" >Customer</option>  
                  <option value="2" >Partner</option>  
              </select>
              <select class="select-form-style" style="margin-left: 10px; display: none;" id="onboarded">
                <option value="1">CinchFuel</option>  
                <option value="2">Others</option>  
            </select>
              <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
              <button title="Refresh" *ngIf="refreshflag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
              <button title="Excel Export" *ngIf="excelexportchecktransaction" (click)="exportToExcelTransaction()" title="Excel Export" style="margin-left: 10px; margin-top: 5px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
            <div *ngIf="errorFlag" style="color: red; margin-left: 20px;">{{errorMessage}}</div>
                  <!-- START card -->
                  <div class="card card-transparent">
                    <!-- <div class="card-header "> -->
                    <div class="card-body">
                      <div class="table-responsive">
                        <table id="excel-table" datatable  class="ch-table" >
                          <thead>
                            <tr> 
                              <th style="text-align: center;">Sl.No.</th>
                              <th style="text-align: left;">Transaction ID</th>
                              <th style="text-align: left;">Partner Name</th>
                              <th style="text-align: left;">Partner Mobile</th>
                              <th style="text-align: right;">Transaction Amount</th>
                              <th style="text-align: center;">Payment Status</th>
                              <th style="text-align: center;">Payment Mode</th>
                              <th style="text-align: left;">Initiated Date Time</th>
                              <th style="text-align: left;">Transaction Date Time</th>
                              <th style="text-align: center; width: 100px;" >Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let transaction of transactionfilter1Detail.response; let i=index">
                              <td align="center" class="v-align-middle ">{{i + 1}}</td>
                              <td *ngIf="transaction.paymentId!=null"align="left" class="v-align-middle">{{transaction.paymentId}}</td>
                              <td *ngIf="transaction.paymentId==null"align="left" class="v-align-middle">N/A</td>
                              <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</td>
                              <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                              <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">{{transaction.vehicleId.userId.mobileNumber}}</td>
                              <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                              <td align="right" *ngIf="transaction.totalPayableAmount!=null"class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.totalPayableAmount | number : '1.2-2'}}</td>
                              <td align="right" *ngIf="transaction.totalPayableAmount==null"class="v-align-middle">N/A</td>
                              <td align="center" *ngIf="transaction.paymentModeId.id==1">
                                <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-inactive">{{transaction.paymentStatus}}</span> 
                                <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</span> 
                                <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' " style="color: red;">Failed</span> 
                                <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                                <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                                <span  *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' " style="color: blue;">{{transaction.paymentStatus}}</span> 
                              </td>
                              <td align="center" *ngIf="transaction.paymentModeId.id!=1">
                                <span *ngIf="transaction.approvalStatus==='Pending' || transaction.approvalStatus==null" class="v-align-middle ht-inactive">Pending</span> 
                               <span *ngIf="transaction.approvalStatus==='Success' || transaction.approvalStatus==='Approved'" class="v-align-middle ht-active">Success</span> 
       
                               <span *ngIf="transaction.approvalStatus==='Submitted' "class="v-align-middle ht-active">
                                 <span *ngIf="transaction.chequeClaimStatus=='Success'">Success</span> 
                                 <span *ngIf="transaction.chequeClaimStatus!='Success'"> {{transaction.chequeClaimStatus}}</span> 
                               </span> 
       
                               <span *ngIf="transaction.approvalStatus==='Failed' " style="color: red;">Failed</span> 
                               <span *ngIfElse="transaction.approvalStatus!=null " style="color: orange;">{{transaction.approvalStatus}}</span> 
                             </td>
                           
                              <td> {{transaction.paymentModeId.paymentMode}} </td>
                              <td *ngIf="transaction.initiateDatetime!=null"align="left" class="v-align-middle">{{transaction.initiateDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                          <td *ngIf="transaction.initiateDatetime==null"align="left" class="v-align-middle">N/A</td>
                              <td *ngIf="transaction.transactionDatetime!=null"align="left" class="v-align-middle">{{transaction.transactionDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                              <td *ngIf="transaction.transactionDatetime==null"align="left" class="v-align-middle">N/A</td>
                              <td align="left">
                                <button title="view" class="label label-info" (click)="view(content,transaction.id)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                                <span *ngIf="transaction.paymentModeId.id==5"> 
                                  <button title="view Cheque and DD" class="label label-info" (click)="viewproof(transaction.id,transaction.orderProof,contentproof,1)"><i class="fa fa-money" aria-hidden="true"></i></button>&nbsp;
                                 </span>
        
                                 <span *ngIf="transaction.orderReceipt!=null"> 
                                  <button title="view Receipt" class="label label-info" (click)="viewproof(transaction.id,transaction.orderReceipt,contentproof,2)"><i class="fa fa-list" aria-hidden="true"></i></button>&nbsp;
                                 </span>
        
                                 <button *ngIf="transaction.paymentModeId.id!=1 && (transaction.approvalStatus=='Pending' || transaction.approvalStatus=='Failed')" title="Update Approval Status" class="label label-info" (click)="update(content7,transaction.id,transaction.paymentModeId.id,transaction.approvalStatus,transaction.receivedAmount,transaction.paymentReferenceNo,transaction.totalPayableAmount)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;
                                 <button *ngIf="transaction.paymentModeId.id==5 && transaction.approvalStatus=='Submitted' && transaction.chequeClaimStatus!='Success'" title="Update Claim Status" class="label label-info" (click)="updateClaim(content9,transaction.id,transaction.paymentReferenceNo,transaction.approvalStatus,transaction.chequeClaimStatus)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;

                                 <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                <button *ngIf="transaction.paymentStatus==='cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                                <button *ngIf="transaction.paymentStatus==='Cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                                <button *ngIf="transaction.paymentStatus==='Failure'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!-- END card --> 
                </div>
              </div>
              <!-- END CONTAINER FLUID --> 
            </div> 
  </div>   
  <div *ngIf="!filteronesearch">
    <div *ngIf="accountStatus==1">
      <div style="display: none;">{{transactionfilter1DetailNoRecord.responseMessage}}</div>
            <div class="container Customers-top-notification card4view">
            <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 315px; margin-left: 5px;">
              <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                <div _ngcontent-tgd-c17="" class="row-xs-height">
                  <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                    <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                      <div _ngcontent-tgd-c17="" class="card-title">Total Transaction Amount (Success) </div>
                      <div _ngcontent-tgd-c17="" class="card-controls">
                        <ul _ngcontent-tgd-c17="">
                          <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="row-xs-height">
                  <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                    <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                      <h3 *ngIf="transactionfilter1DetailNoRecord.amount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i> {{transactionfilter1DetailNoRecord.amount | number : '1.2-2'}}</h3>
                      <h3 *ngIf="transactionfilter1DetailNoRecord.amount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i>0</h3>
                      <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% </span> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
              <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                <div _ngcontent-tgd-c17="" class="row-xs-height">
                  <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                    <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                      <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Success) </div>
                      <div _ngcontent-tgd-c17="" class="card-controls">
                        <ul _ngcontent-tgd-c17="">
                          <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="row-xs-height">
                  <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                    <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                      <h3 *ngIf="transactionfilter1DetailNoRecord.activeCount!=null" _ngcontent-tgd-c17="" class="no-margin p-b-5">
                        {{transactionfilter1DetailNoRecord.activeCount}}</h3>
                      <h3 *ngIf="transactionfilter1DetailNoRecord.activeCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">
                        0</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
              <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                <div _ngcontent-tgd-c17="" class="row-xs-height">
                  <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                    <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                      <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Failed) </div>
                      <div _ngcontent-tgd-c17="" class="card-controls">
                        <ul _ngcontent-tgd-c17="">
                          <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="row-xs-height">
                  <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                    <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                      <h3 *ngIf="transactionfilter1DetailNoRecord.inactiveCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter1DetailNoRecord.inactiveCount}}</h3>
                      <h3 *ngIf="transactionfilter1DetailNoRecord.inactiveCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                      <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71%</span> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 300px; margin-left: -20px;">
              <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                <div _ngcontent-tgd-c17="" class="row-xs-height">
                  <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                    <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                      <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Initiated)</div>
                      <div _ngcontent-tgd-c17="" class="card-controls">
                        <ul _ngcontent-tgd-c17="">
                          <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="row-xs-height">
                  <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                    <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                      <h3 *ngIf="transactionfilter1DetailNoRecord.initiatedCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter1DetailNoRecord.initiatedCount}}</h3>
                      <h3 *ngIf="transactionfilter1DetailNoRecord.initiatedCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                      <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
            
          </div>
           
          
          
          <!-- START CONTAINER FLUID --> 
          
          <!-- START CONTAINER FLUID -->
          <div class=" no-padding    container-fixed-lg bg-white">
            <div class="container">
              <!-- START card -->
              <select class="select-form-style" style="margin-left: 20px; margin-top: 10px;" [(ngModel)]="searchStatus" id="searchBy" (change)="callsearch($event)">  
                <option value="1">Search By Initiated Date & Time</option>  
                <option value="2">Search By Transaction Date & Time</option>  
                <option value="3">Search By All</option>  
                <option value="4">Search By Onboarded By</option> 
            </select>  
            <input *ngIf="searchStatus!=3"type="date" style="margin-left: 10px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
            <input *ngIf="searchStatus!=3"type="date" style="margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
  
            <input *ngIf="searchStatus==3" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
            <input *ngIf="searchStatus==3" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
            <select class="select-form-style"  style="margin-left: 10px;" [(ngModel)]="searchByCusorPart" id="accountstatus">
              <option value="1" >Customer</option>  
              <option value="2" >Partner</option>  
          </select>
          <select class="select-form-style" style="margin-left: 10px; display: none;" id="onboarded">
            <option value="1">CinchFuel</option>  
            <option value="2">Others</option>  
        </select>
          <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
          <button title="Refresh" *ngIf="refreshflag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
          <button title="Excel Export" *ngIf="excelexportchecktransaction" (click)="exportToExcelTransaction()" title="Excel Export" style="margin-left: 10px; margin-top: 5px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
        <div *ngIf="errorFlag" style="color: red; margin-left: 20px;">{{errorMessage}}</div>
              <div class="card card-transparent">
                <div class="card-body">
                  <div class="table-responsive" >
                    <table id="excel-table" datatable class="ch-table" >
                      <thead>
                        <tr> 
                          <th style="text-align: center;">Sl.No.</th>
                          <th style="text-align: left;">Order ID</th>
                          <!-- <th style="text-align: left;">Transaction ID</th> -->
                          <th style="text-align: left;">Customer Info</th>
                          <th style="text-align: left;">Delivery Agent Info</th>
                          <th style="text-align: left;">Fuel</th>
                          <!-- <th style="text-align: center;">Quantity in Ltrs</th> -->
                          <th style="text-align: right;">Transaction Amount</th>
                          <th style="text-align: center;">Payment Status</th>
                          <th style="text-align: center;">Payment Mode</th>
                          <th style="text-align: left;">Initiated Date Time</th>
                          <th style="text-align: left;">Transaction Date Time</th>
                          <th style="text-align: center; width: 100px;" >Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let transaction of transactionfilter1DetailNoRecord.response; let i=index">
                          <td align="center" class="v-align-middle "><p>{{i + 1}}</p></td>
                      <td *ngIf="transaction.orderId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 10px;" ><b>{{transaction.orderId}}</b></span><br>
                        <span *ngIf="transaction.transactionId!=null" style="font-size: 10px;"><b>Txn Id :</b>{{transaction.transactionId}}</span>
                        <span *ngIf="transaction.transactionId==null" style="font-size: 10px;"><b>Txn Id :</b>N/A</span>
                      </td>
                      <td *ngIf="transaction.orderId==null"align="left" class="v-align-middle"><p>N/A</p></td>

                      <!-- <td *ngIf="transaction.transactionId!=null"align="left" class="v-align-middle"><p>{{transaction.transactionId}}</p></td>
                      <td *ngIf="transaction.transactionId==null"align="left" class="v-align-middle"><p>N/A</p></td> -->

                      <td *ngIf="transaction.customerId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 12px;">{{transaction.customerId.firstName}} {{transaction.customerId.lastName}}</span><br>
                        <span style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.customerId.mobileNumber}}</span>
                        <!-- <p style="font-size: 12px;">{{transaction.customerId.firstName}} {{transaction.customerId.lastName}} </p>
                        <p style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.customerId.mobileNumber}}</p> -->
                      </td>
                      <td *ngIf="transaction.customerId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                      <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 12px;">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</span><br>
                        <span style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.vehicleId.userId.mobileNumber}}</span>
                          <!-- <p style="font-size: 12px;">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</p>
                          <p style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.vehicleId.userId.mobileNumber}}</p> -->
                        </td>
                        <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                        <td *ngIf="transaction.fuelType!=null" align="left" class="v-align-middle">
                          <span style="font-size: 10px;">{{transaction.fuelType}}</span><br>
                          <span style="font-size: 10px;"><b>Qtn : </b> {{transaction.quantity}}</span>
                          <!-- <p style="font-size: 10px;">{{transaction.fuelType}}</p>  
                          <p style="font-size: 10px;"><b>Qtn : </b> {{transaction.quantity}}</p> -->
                          </td>
                      <td *ngIf="transaction.fuelType==null"align="left" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="transaction.quantity!=null"align="center" class="v-align-middle">{{transaction.quantity}}</td>
                      <td *ngIf="transaction.quantity==null"align="center" class="v-align-middle">N/A</td> -->
                      <td align="right" *ngIf="transaction.totalPayableAmount!=null"class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.totalPayableAmount | number : '1.2-2'}}</td>
                      <td align="right" *ngIf="transaction.totalPayableAmount==null"class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="transaction.paymentModeId.id==1">
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-inactive">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' " style="color: red;">Failed</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                        <span  *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' " style="color: blue;">{{transaction.paymentStatus}}</span> 
                      </td>
                      <td align="center" *ngIf="transaction.paymentModeId.id!=1">
                        <span *ngIf="transaction.approvalStatus==='Pending' || transaction.approvalStatus==null" class="v-align-middle ht-inactive">Pending</span> 
                       <span *ngIf="transaction.approvalStatus==='Success' || transaction.approvalStatus==='Approved'" class="v-align-middle ht-active">Success</span> 

                       <span *ngIf="transaction.approvalStatus==='Submitted' "class="v-align-middle ht-active">
                         <span *ngIf="transaction.chequeClaimStatus=='Success'">Success</span> 
                         <span *ngIf="transaction.chequeClaimStatus!='Success'"> {{transaction.chequeClaimStatus}}</span> 
                       </span> 

                       <span *ngIf="transaction.approvalStatus==='Failed' " style="color: red;">Failed</span> 
                       <span *ngIfElse="transaction.approvalStatus!=null " style="color: orange;">{{transaction.approvalStatus}}</span> 
                     </td>
                   
                      <td> {{transaction.paymentModeId.paymentMode}} </td>
                      <td *ngIf="transaction.initiatedDatetime!=null"align="left" class="v-align-middle">{{transaction.initiatedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.initiatedDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paymentDatetime!=null"align="left" class="v-align-middle">{{transaction.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.paymentDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td align="left">
                        <button title="view" class="label label-info" (click)="view(content,transaction.id)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                        <span *ngIf="transaction.paymentModeId.id==5"> 
                          <button title="view Cheque and DD" class="label label-info" (click)="viewproof(transaction.id,transaction.orderProof,contentproof,1)"><i class="fa fa-money" aria-hidden="true"></i></button>&nbsp;
                         </span>

                         <span *ngIf="transaction.orderReceipt!=null"> 
                          <button title="view Receipt" class="label label-info" (click)="viewproof(transaction.id,transaction.orderReceipt,contentproof,2)"><i class="fa fa-list" aria-hidden="true"></i></button>&nbsp;
                         </span>

                         <button *ngIf="transaction.paymentModeId.id!=1 && (transaction.approvalStatus=='Pending' || transaction.approvalStatus=='Failed')" title="Update Approval Status" class="label label-info" (click)="update(content7,transaction.id,transaction.paymentModeId.id,transaction.approvalStatus,transaction.receivedAmount,transaction.paymentReferenceNo,transaction.totalPayableAmount)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;
                         <button *ngIf="transaction.paymentModeId.id==5 && transaction.approvalStatus=='Submitted' && transaction.chequeClaimStatus!='Success'" title="Update Claim Status" class="label label-info" (click)="updateClaim(content9,transaction.id,transaction.paymentReferenceNo,transaction.approvalStatus,transaction.chequeClaimStatus)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;

                         <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='Cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='Failure'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                      </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- END card --> 
            </div>
          </div>
          <!-- END CONTAINER FLUID --> 
        </div>
    
        <div *ngIf="accountStatus==2">
          <div style="display: none;">{{transactionfilter1DetailNoRecord.responseMessage}}</div>
                <div class="container Customers-top-notification card4view">
                <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 315px; margin-left: 5px;">
                  <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                          <div _ngcontent-tgd-c17="" class="card-title">Total Transaction Amount (Success) </div>
                          <div _ngcontent-tgd-c17="" class="card-controls">
                            <ul _ngcontent-tgd-c17="">
                              <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="transactionfilter1DetailNoRecord.amount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i> {{transactionfilter1DetailNoRecord.amount | number : '1.2-2'}}</h3>
                          <h3 *ngIf="transactionfilter1DetailNoRecord.amount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i>0</h3>
                          <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% </span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                  <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                          <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Success) </div>
                          <div _ngcontent-tgd-c17="" class="card-controls">
                            <ul _ngcontent-tgd-c17="">
                              <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="transactionfilter1DetailNoRecord.activeCount!=null" _ngcontent-tgd-c17="" class="no-margin p-b-5">
                            {{transactionfilter1DetailNoRecord.activeCount}}</h3>
                          <h3 *ngIf="transactionfilter1DetailNoRecord.activeCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">
                            0</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                  <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                          <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Failed) </div>
                          <div _ngcontent-tgd-c17="" class="card-controls">
                            <ul _ngcontent-tgd-c17="">
                              <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="transactionfilter1DetailNoRecord.inactiveCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter1DetailNoRecord.inactiveCount}}</h3>
                          <h3 *ngIf="transactionfilter1DetailNoRecord.inactiveCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                          <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71%</span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 300px; margin-left: -20px;">
                  <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                          <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Initiated)</div>
                          <div _ngcontent-tgd-c17="" class="card-controls">
                            <ul _ngcontent-tgd-c17="">
                              <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="transactionfilter1DetailNoRecord.initiatedCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter1DetailNoRecord.initiatedCount}}</h3>
                          <h3 *ngIf="transactionfilter1DetailNoRecord.initiatedCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                          <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               
                
              </div>
               
              
              
              <!-- START CONTAINER FLUID --> 
              
              <!-- START CONTAINER FLUID -->
              <div class=" no-padding    container-fixed-lg bg-white">
                <div class="container">
                  <select class="select-form-style" style="margin-left: 20px; margin-top: 10px;" [(ngModel)]="searchStatus" id="searchBy" (change)="callsearch($event)">  
                    <option value="1">Search By Initiated Date & Time</option>  
                    <option value="2">Search By Transaction Date & Time</option>  
                    <option value="3">Search By All</option>  
                    <option value="4">Search By Onboarded By</option> 
                </select>  
                <input *ngIf="searchStatus!=3"type="date" style="margin-left: 10px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
            <input *ngIf="searchStatus!=3"type="date" style="margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
  
            <input *ngIf="searchStatus==3" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
            <input *ngIf="searchStatus==3" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
                <select class="select-form-style" style="margin-left: 10px;" [(ngModel)]="searchByCusorPart" id="accountstatus">
                  <option value="1" >Customer</option>  
                  <option value="2" >Partner</option>  
              </select>
              <select class="select-form-style" style="margin-left: 10px; display: none;" id="onboarded">
                <option value="1">CinchFuel</option>  
                <option value="2">Others</option>  
            </select>
              <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
              <button title="Refresh" *ngIf="refreshflag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
              <button title="Excel Export" *ngIf="excelexportchecktransaction" (click)="exportToExcelTransaction()" title="Excel Export" style="margin-left: 10px; margin-top: 5px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
            <div *ngIf="errorFlag" style="color: red; margin-left: 20px;">{{errorMessage}}</div>
                  <!-- START card -->
                  <div class="card card-transparent">
                    <!-- <div class="card-header "> -->
                    <div class="card-body">
                      <div class="table-responsive">
                        <table id="excel-table" datatable  class="ch-table" >
                          <thead>
                            <tr> 
                              <th style="text-align: center;">Sl.No.</th>
                              <th style="text-align: left;">Transaction ID</th>
                              <th style="text-align: left;">Partner Name</th>
                              <th style="text-align: left;">Partner Mobile</th>
                              <th style="text-align: right;">Transaction Amount</th>
                              <th style="text-align: center;">Payment Status</th>
                              <th style="text-align: center;">Payment Mode</th>
                              <th style="text-align: left;">Initiated Date Time</th>
                              <th style="text-align: left;">Transaction Date Time</th>
                              <th style="text-align: center; width: 100px;" >Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let transaction of transactionfilter1DetailNoRecord.response; let i=index">
                              <td align="center" class="v-align-middle ">{{i + 1}}</td>
                              <td *ngIf="transaction.paymentId!=null"align="left" class="v-align-middle">{{transaction.paymentId}}</td>
                              <td *ngIf="transaction.paymentId==null"align="left" class="v-align-middle">N/A</td>
                              <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</td>
                              <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                              <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">{{transaction.vehicleId.userId.mobileNumber}}</td>
                              <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                              <td align="right" *ngIf="transaction.totalPayableAmount!=null"class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.totalPayableAmount | number : '1.2-2'}}</td>
                              <td align="right" *ngIf="transaction.totalPayableAmount==null"class="v-align-middle">N/A</td>
                              <td align="center" *ngIf="transaction.paymentModeId.id==1">
                                <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-inactive">{{transaction.paymentStatus}}</span> 
                                <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</span> 
                                <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' " style="color: red;">Failed</span> 
                                <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                                <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                                <span  *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' " style="color: blue;">{{transaction.paymentStatus}}</span> 
                              </td>
                              <td align="center" *ngIf="transaction.paymentModeId.id!=1">
                                <span *ngIf="transaction.approvalStatus==='Pending' || transaction.approvalStatus==null" class="v-align-middle ht-inactive">Pending</span> 
                               <span *ngIf="transaction.approvalStatus==='Success' || transaction.approvalStatus==='Approved'" class="v-align-middle ht-active">Success</span> 
       
                               <span *ngIf="transaction.approvalStatus==='Submitted' "class="v-align-middle ht-active">
                                 <span *ngIf="transaction.chequeClaimStatus=='Success'">Success</span> 
                                 <span *ngIf="transaction.chequeClaimStatus!='Success'"> {{transaction.chequeClaimStatus}}</span> 
                               </span> 
       
                               <span *ngIf="transaction.approvalStatus==='Failed' " style="color: red;">Failed</span> 
                               <span *ngIfElse="transaction.approvalStatus!=null " style="color: orange;">{{transaction.approvalStatus}}</span> 
                             </td>
                           
                              <td> {{transaction.paymentModeId.paymentMode}} </td>
                              <td *ngIf="transaction.initiateDatetime!=null"align="left" class="v-align-middle">{{transaction.initiateDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                              <td *ngIf="transaction.initiateDatetime==null"align="left" class="v-align-middle">N/A</td>
                              <td *ngIf="transaction.transactionDatetime!=null"align="left" class="v-align-middle">{{transaction.transactionDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                              <td *ngIf="transaction.transactionDatetime==null"align="left" class="v-align-middle">N/A</td>
                              <td align="left">
                                <button title="view" class="label label-info" (click)="view(content,transaction.id)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                                <span *ngIf="transaction.paymentModeId.id==5"> 
                                  <button title="view Cheque and DD" class="label label-info" (click)="viewproof(transaction.id,transaction.orderProof,contentproof,1)"><i class="fa fa-money" aria-hidden="true"></i></button>&nbsp;
                                 </span>
        
                                 <span *ngIf="transaction.orderReceipt!=null"> 
                                  <button title="view Receipt" class="label label-info" (click)="viewproof(transaction.id,transaction.orderReceipt,contentproof,2)"><i class="fa fa-list" aria-hidden="true"></i></button>&nbsp;
                                 </span>
        
                                 <button *ngIf="transaction.paymentModeId.id!=1 && (transaction.approvalStatus=='Pending' || transaction.approvalStatus=='Failed')" title="Update Approval Status" class="label label-info" (click)="update(content7,transaction.id,transaction.paymentModeId.id,transaction.approvalStatus,transaction.receivedAmount,transaction.paymentReferenceNo,transaction.totalPayableAmount)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;
                                 <button *ngIf="transaction.paymentModeId.id==5 && transaction.approvalStatus=='Submitted' && transaction.chequeClaimStatus!='Success'" title="Update Claim Status" class="label label-info" (click)="updateClaim(content9,transaction.id,transaction.paymentReferenceNo,transaction.approvalStatus,transaction.chequeClaimStatus)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;

                                 <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                <button *ngIf="transaction.paymentStatus==='cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                                <button *ngIf="transaction.paymentStatus==='Cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                                <button *ngIf="transaction.paymentStatus==='Failure'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!-- END card --> 
                </div>
              </div>
              <!-- END CONTAINER FLUID --> 
            </div> 
  </div>
    
  </div>

  <!-- Filter 2  -->
  <div *ngIf="filterTransaction2">     
    <div *ngIf="filtertwosearch">
      <div *ngIf="accountStatus==1">
        <div style="display: none;">{{transactionfilter2Detail.responseMessage}}</div>
              <div class="container Customers-top-notification card4view">
              <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 315px; margin-left: 5px;">
                <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                        <div _ngcontent-tgd-c17="" class="card-title">Total Transaction Amount (Success) </div>
                        <div _ngcontent-tgd-c17="" class="card-controls">
                          <ul _ngcontent-tgd-c17="">
                            <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                        <h3 *ngIf="transactionfilter2Detail.amount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i> {{transactionfilter2Detail.amount| number : '1.2-2'}}</h3>
                        <h3 *ngIf="transactionfilter2Detail.amount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i>0</h3>
                        <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% </span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                        <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Success) </div>
                        <div _ngcontent-tgd-c17="" class="card-controls">
                          <ul _ngcontent-tgd-c17="">
                            <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                        <h3 *ngIf="transactionfilter2Detail.activeCount!=null" _ngcontent-tgd-c17="" class="no-margin p-b-5">
                          {{transactionfilter2Detail.activeCount}}</h3>
                        <h3 *ngIf="transactionfilter2Detail.activeCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">
                          0</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                        <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Failed) </div>
                        <div _ngcontent-tgd-c17="" class="card-controls">
                          <ul _ngcontent-tgd-c17="">
                            <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                        <h3 *ngIf="transactionfilter2Detail.inactiveCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter2Detail.inactiveCount}}</h3>
                        <h3 *ngIf="transactionfilter2Detail.inactiveCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                        <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71%</span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 300px; margin-left: -20px;">
                <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                        <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Initiated)</div>
                        <div _ngcontent-tgd-c17="" class="card-controls">
                          <ul _ngcontent-tgd-c17="">
                            <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                        <h3 *ngIf="transactionfilter2Detail.initiatedCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter2Detail.initiatedCount}}</h3>
                        <h3 *ngIf="transactionfilter2Detail.initiatedCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                        <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             
              
            </div>
             
            
            
            <!-- START CONTAINER FLUID --> 
            
            <!-- START CONTAINER FLUID -->
            <div class=" no-padding    container-fixed-lg bg-white">
              <div class="container">
                <!-- START card -->
                <select class="select-form-style" style="margin-left: 20px; margin-top: 10px;" [(ngModel)]="searchStatus" id="searchBy" (change)="callsearch($event)">  
                  <option value="1">Search By Initiated Date & Time</option>  
                  <option value="2">Search By Transaction Date & Time</option> 
                  <option value="3">Search By All</option>   
                  <option value="4">Search By Onboarded By</option> 
              </select>  
              <input *ngIf="searchStatus!=3"type="date" style="margin-left: 10px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
            <input *ngIf="searchStatus!=3"type="date" style="margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
  
            <input *ngIf="searchStatus==3" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
            <input *ngIf="searchStatus==3" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
              <select class="select-form-style"  style="margin-left: 10px;" [(ngModel)]="searchByCusorPart" id="accountstatus">
                <option value="1">Customer</option>  
                <option value="2" >Partner</option>  
            </select>
            <select class="select-form-style" style="margin-left: 10px; display: none;" id="onboarded">
              <option value="1">CinchFuel</option>  
              <option value="2">Others</option>  
          </select>
            <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
            <button title="Refresh" *ngIf="refreshflag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
            <button title="Excel Export" *ngIf="excelexportchecktransaction" (click)="exportToExcelTransaction()" title="Excel Export" style="margin-left: 10px; margin-top: 5px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
          <div *ngIf="errorFlag" style="color: red; margin-left: 20px;">{{errorMessage}}</div>
                <div class="card card-transparent">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table id="excel-table" datatable  class="ch-table" >
                        <thead>
                          <tr> 
                            <th style="text-align: center;">Sl.No.</th>
                            <th style="text-align: left;">Order ID</th>
                            <!-- <th style="text-align: left;">Transaction ID</th> -->
                            <th style="text-align: left;">Customer Info</th>
                            <th style="text-align: left;">Delivery Agent Info</th>
                            <th style="text-align: left;">Fuel</th>
                            <!-- <th style="text-align: center;">Quantity in Ltrs</th> -->
                            <th style="text-align: right;">Transaction Amount</th>
                            <th style="text-align: center;">Payment Status</th>
                            <th style="text-align: center;">Payment Mode</th>
                            <th style="text-align: left;">Initiated Date Time</th>
                            <th style="text-align: left;">Transaction Date Time</th>
                            <th style="text-align: center; width: 100px;" >Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let transaction of transactionfilter2Detail.response; let i=index">
                            <td align="center" class="v-align-middle "><p>{{i + 1}}</p></td>
                      <td *ngIf="transaction.orderId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 10px;" ><b>{{transaction.orderId}}</b></span><br>
                        <span *ngIf="transaction.transactionId!=null" style="font-size: 10px;"><b>Txn Id :</b>{{transaction.transactionId}}</span>
                        <span *ngIf="transaction.transactionId==null" style="font-size: 10px;"><b>Txn Id :</b>N/A</span>
                      </td>
                      <td *ngIf="transaction.orderId==null"align="left" class="v-align-middle"><p>N/A</p></td>

                      <!-- <td *ngIf="transaction.transactionId!=null"align="left" class="v-align-middle"><p>{{transaction.transactionId}}</p></td>
                      <td *ngIf="transaction.transactionId==null"align="left" class="v-align-middle"><p>N/A</p></td> -->

                      <td *ngIf="transaction.customerId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 12px;">{{transaction.customerId.firstName}} {{transaction.customerId.lastName}}</span><br>
                        <span style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.customerId.mobileNumber}}</span>
                        <!-- <p style="font-size: 12px;">{{transaction.customerId.firstName}} {{transaction.customerId.lastName}} </p>
                        <p style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.customerId.mobileNumber}}</p> -->
                      </td>
                      <td *ngIf="transaction.customerId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                      <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 12px;">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</span><br>
                        <span style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.vehicleId.userId.mobileNumber}}</span>
                          <!-- <p style="font-size: 12px;">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</p>
                          <p style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.vehicleId.userId.mobileNumber}}</p> -->
                        </td>
                        <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                        <td *ngIf="transaction.fuelType!=null" align="left" class="v-align-middle">
                          <span style="font-size: 10px;">{{transaction.fuelType}}</span><br>
                          <span style="font-size: 10px;"><b>Qtn : </b> {{transaction.quantity}}</span>
                          <!-- <p style="font-size: 10px;">{{transaction.fuelType}}</p>  
                          <p style="font-size: 10px;"><b>Qtn : </b> {{transaction.quantity}}</p> -->
                          </td>
                      <td *ngIf="transaction.fuelType==null"align="left" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="transaction.quantity!=null"align="center" class="v-align-middle">{{transaction.quantity}}</td>
                      <td *ngIf="transaction.quantity==null"align="center" class="v-align-middle">N/A</td> -->
                      <td align="right" *ngIf="transaction.totalPayableAmount!=null"class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.totalPayableAmount | number : '1.2-2'}}</td>
                      <td align="right" *ngIf="transaction.totalPayableAmount==null"class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="transaction.paymentModeId.id==1">
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-inactive">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' " style="color: red;">Failed</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                        <span  *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' " style="color: blue;">{{transaction.paymentStatus}}</span> 
                      </td>
                      <td align="center" *ngIf="transaction.paymentModeId.id!=1">
                        <span *ngIf="transaction.approvalStatus==='Pending' || transaction.approvalStatus==null" class="v-align-middle ht-inactive">Pending</span> 
                       <span *ngIf="transaction.approvalStatus==='Success' || transaction.approvalStatus==='Approved'" class="v-align-middle ht-active">Success</span> 

                       <span *ngIf="transaction.approvalStatus==='Submitted' "class="v-align-middle ht-active">
                         <span *ngIf="transaction.chequeClaimStatus=='Success'">Success</span> 
                         <span *ngIf="transaction.chequeClaimStatus!='Success'"> {{transaction.chequeClaimStatus}}</span> 
                       </span> 

                       <span *ngIf="transaction.approvalStatus==='Failed' " style="color: red;">Failed</span> 
                       <span *ngIfElse="transaction.approvalStatus!=null " style="color: orange;">{{transaction.approvalStatus}}</span> 
                     </td>
                    
                      <td> {{transaction.paymentModeId.paymentMode}} </td>
                      <td *ngIf="transaction.initiatedDatetime!=null"align="left" class="v-align-middle">{{transaction.initiatedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.initiatedDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paymentDatetime!=null"align="left" class="v-align-middle">{{transaction.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.paymentDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td align="left">
                        <button title="view" class="label label-info" (click)="view(content,transaction.id)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                        <span *ngIf="transaction.paymentModeId.id==5"> 
                          <button title="view Cheque and DD" class="label label-info" (click)="viewproof(transaction.id,transaction.orderProof,contentproof,1)"><i class="fa fa-money" aria-hidden="true"></i></button>&nbsp;
                         </span>

                         <span *ngIf="transaction.orderReceipt!=null"> 
                          <button title="view Receipt" class="label label-info" (click)="viewproof(transaction.id,transaction.orderReceipt,contentproof,2)"><i class="fa fa-list" aria-hidden="true"></i></button>&nbsp;
                         </span>

                         <button *ngIf="transaction.paymentModeId.id!=1 && (transaction.approvalStatus=='Pending' || transaction.approvalStatus=='Failed')" title="Update Approval Status" class="label label-info" (click)="update(content7,transaction.id,transaction.paymentModeId.id,transaction.approvalStatus,transaction.receivedAmount,transaction.paymentReferenceNo,transaction.totalPayableAmount)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;
                         <button *ngIf="transaction.paymentModeId.id==5 && transaction.approvalStatus=='Submitted' && transaction.chequeClaimStatus!='Success'" title="Update Claim Status" class="label label-info" (click)="updateClaim(content9,transaction.id,transaction.paymentReferenceNo,transaction.approvalStatus,transaction.chequeClaimStatus)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;

                         <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='Cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='Failure'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                      </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- END card --> 
              </div>
            </div>
            <!-- END CONTAINER FLUID --> 
          </div>
      
          <div *ngIf="accountStatus==2">
            <div style="display: none;">{{transactionfilter2Detail.responseMessage}}</div>
                  <div class="container Customers-top-notification card4view">
                  <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 315px; margin-left: 5px;">
                    <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                            <div _ngcontent-tgd-c17="" class="card-title">Total Transaction Amount (Success) </div>
                            <div _ngcontent-tgd-c17="" class="card-controls">
                              <ul _ngcontent-tgd-c17="">
                                <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                            <h3 *ngIf="transactionfilter2Detail.amount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i> {{transactionfilter2Detail.amount | number : '1.2-2'}}</h3>
                            <h3 *ngIf="transactionfilter2Detail.amount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i>0</h3>
                            <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% </span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                    <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                            <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Success) </div>
                            <div _ngcontent-tgd-c17="" class="card-controls">
                              <ul _ngcontent-tgd-c17="">
                                <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                            <h3 *ngIf="transactionfilter2Detail.activeCount!=null" _ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter2Detail.activeCount}}</h3>
                            <h3 *ngIf="transactionfilter2Detail.activeCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">
                              0</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                    <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                            <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Failed) </div>
                            <div _ngcontent-tgd-c17="" class="card-controls">
                              <ul _ngcontent-tgd-c17="">
                                <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                            <h3 *ngIf="transactionfilter2Detail.inactiveCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter2Detail.inactiveCount}}</h3>
                            <h3 *ngIf="transactionfilter2Detail.inactiveCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                            <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71%</span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 300px; margin-left: -20px;">
                    <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                            <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Initiated)</div>
                            <div _ngcontent-tgd-c17="" class="card-controls">
                              <ul _ngcontent-tgd-c17="">
                                <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                            <h3 *ngIf="transactionfilter2Detail.initiatedCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter2Detail.initiatedCount}}</h3>
                            <h3 *ngIf="transactionfilter2Detail.initiatedCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                            <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- START CONTAINER FLUID -->
                <div class=" no-padding    container-fixed-lg bg-white">
                  <div class="container">
                    <select class="select-form-style" style="margin-left: 20px; margin-top: 10px;" [(ngModel)]="searchStatus" id="searchBy" (change)="callsearch($event)">  
                      <option value="1">Search By Initiated Date & Time</option>  
                      <option value="2">Search By Transaction Date & Time</option>  
                      <option value="3">Search By All</option>  
                      <option value="4">Search By Onboarded By</option> 
                  </select>  
                  <input *ngIf="searchStatus!=3"type="date" style="margin-left: 10px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
            <input *ngIf="searchStatus!=3"type="date" style="margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
  
            <input *ngIf="searchStatus==3" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
            <input *ngIf="searchStatus==3" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
                  <select class="select-form-style" style="margin-left: 10px;" [(ngModel)]="searchByCusorPart" id="accountstatus">
                    <option value="1" >Customer</option>  
                    <option value="2" >Partner</option>  
                </select>
                <select class="select-form-style" style="margin-left: 10px; display: none;" id="onboarded">
                  <option value="1">CinchFuel</option>  
                  <option value="2">Others</option>  
              </select>
                <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
                <button title="Refresh" *ngIf="refreshflag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
                <button title="Excel Export" *ngIf="excelexportchecktransaction" (click)="exportToExcelTransaction()" title="Excel Export" style="margin-left: 10px; margin-top: 5px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
              <div *ngIf="errorFlag" style="color: red; margin-left: 20px;">{{errorMessage}}</div>
                    <!-- START card -->
                    <div class="card card-transparent">
                      <!-- <div class="card-header "> -->
                      <div class="card-body">
                        <div class="table-responsive">
                          <table id="excel-table" datatable  class="ch-table" >
                            <thead>
                              <tr> 
                                <th style="text-align: center;">Sl.No.</th>
                                <th style="text-align: left;">Transaction ID</th>
                                <th style="text-align: left;">Partner Name</th>
                                <th style="text-align: left;">Partner Mobile</th>
                                <th style="text-align: right;">Transaction Amount</th>
                                <th style="text-align: center;">Payment Status</th>
                                <th style="text-align: center;">Payment Mode</th>
                                <th style="text-align: left;">Initiated Date Time</th>
                                <th style="text-align: left;">Transaction Date Time</th>
                                <th style="text-align: center; width: 100px;" >Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let transaction of transactionfilter2Detail.response; let i=index">
                                <td align="center" class="v-align-middle ">{{i + 1}}</td>
                                <td *ngIf="transaction.paymentId!=null"align="left" class="v-align-middle">{{transaction.paymentId}}</td>
                                <td *ngIf="transaction.paymentId==null"align="left" class="v-align-middle">N/A</td>
                                <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</td>
                                <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                                <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">{{transaction.vehicleId.userId.mobileNumber}}</td>
                                <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                                <td align="right" *ngIf="transaction.totalPayableAmount!=null"class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.totalPayableAmount | number : '1.2-2'}}</td>
                                <td align="right" *ngIf="transaction.totalPayableAmount==null"class="v-align-middle">N/A</td>
                                <td align="center" *ngIf="transaction.paymentModeId.id==1">
                                  <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-inactive">{{transaction.paymentStatus}}</span> 
                                  <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</span> 
                                  <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' " style="color: red;">Failed</span> 
                                  <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                                  <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                                  <span  *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' " style="color: blue;">{{transaction.paymentStatus}}</span> 
                                </td>
                                <td align="center" *ngIf="transaction.paymentModeId.id!=1">
                                  <span *ngIf="transaction.approvalStatus==='Pending' || transaction.approvalStatus==null" class="v-align-middle ht-inactive">Pending</span> 
                                 <span *ngIf="transaction.approvalStatus==='Success' || transaction.approvalStatus==='Approved'" class="v-align-middle ht-active">Success</span> 
         
                                 <span *ngIf="transaction.approvalStatus==='Submitted' "class="v-align-middle ht-active">
                                   <span *ngIf="transaction.chequeClaimStatus=='Success'">Success</span> 
                                   <span *ngIf="transaction.chequeClaimStatus!='Success'"> {{transaction.chequeClaimStatus}}</span> 
                                 </span> 
         
                                 <span *ngIf="transaction.approvalStatus==='Failed' " style="color: red;">Failed</span> 
                                 <span *ngIfElse="transaction.approvalStatus!=null " style="color: orange;">{{transaction.approvalStatus}}</span> 
                               </td>
                                <td> {{transaction.paymentModeId.paymentMode}} </td>
                                <td *ngIf="transaction.initiateDatetime!=null"align="left" class="v-align-middle">{{transaction.initiateDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                                <td *ngIf="transaction.initiateDatetime==null"align="left" class="v-align-middle">N/A</td>
                                <td *ngIf="transaction.transactionDatetime!=null"align="left" class="v-align-middle">{{transaction.transactionDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                                <td *ngIf="transaction.transactionDatetime==null"align="left" class="v-align-middle">N/A</td>
                                <td align="left">
                                  <button title="view" class="label label-info" (click)="view(content,transaction.id)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                                  <span *ngIf="transaction.paymentModeId.id==5"> 
                                    <button title="view Cheque and DD" class="label label-info" (click)="viewproof(transaction.id,transaction.orderProof,contentproof,1)"><i class="fa fa-money" aria-hidden="true"></i></button>&nbsp;
                                   </span>
          
                                   <span *ngIf="transaction.orderReceipt!=null"> 
                                    <button title="view Receipt" class="label label-info" (click)="viewproof(transaction.id,transaction.orderReceipt,contentproof,2)"><i class="fa fa-list" aria-hidden="true"></i></button>&nbsp;
                                   </span>
          
                                   <button *ngIf="transaction.paymentModeId.id!=1 && (transaction.approvalStatus=='Pending' || transaction.approvalStatus=='Failed')" title="Update Approval Status" class="label label-info" (click)="update(content7,transaction.id,transaction.paymentModeId.id,transaction.approvalStatus,transaction.receivedAmount,transaction.paymentReferenceNo,transaction.totalPayableAmount)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;
                                   <button *ngIf="transaction.paymentModeId.id==5 && transaction.approvalStatus=='Submitted' && transaction.chequeClaimStatus!='Success'" title="Update Claim Status" class="label label-info" (click)="updateClaim(content9,transaction.id,transaction.paymentReferenceNo,transaction.approvalStatus,transaction.chequeClaimStatus)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;

                                   <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                  <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                  <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                  <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                  <button *ngIf="transaction.paymentStatus==='cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                                  <button *ngIf="transaction.paymentStatus==='Cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                                  <button *ngIf="transaction.paymentStatus==='Failure'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <!-- END card --> 
                  </div>
                </div>
                <!-- END CONTAINER FLUID --> 
              </div>   
    </div>
    <div *ngIf="!filtertwosearch">
      <div *ngIf="accountStatus==1">
        <div style="display: none;">{{transactionfilter2DetailNoRecord.responseMessage}}</div>
              <div class="container Customers-top-notification card4view">
              <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 315px; margin-left: 5px;">
                <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                        <div _ngcontent-tgd-c17="" class="card-title">Total Transaction Amount (Success) </div>
                        <div _ngcontent-tgd-c17="" class="card-controls">
                          <ul _ngcontent-tgd-c17="">
                            <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                        <h3 *ngIf="transactionfilter2DetailNoRecord.amount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i> {{transactionfilter2DetailNoRecord.amount| number : '1.2-2'}}</h3>
                        <h3 *ngIf="transactionfilter2DetailNoRecord.amount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i>0</h3>
                        <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% </span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                        <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Success) </div>
                        <div _ngcontent-tgd-c17="" class="card-controls">
                          <ul _ngcontent-tgd-c17="">
                            <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                        <h3 *ngIf="transactionfilter2DetailNoRecord.activeCount!=null" _ngcontent-tgd-c17="" class="no-margin p-b-5">
                          {{transactionfilter2DetailNoRecord.activeCount}}</h3>
                        <h3 *ngIf="transactionfilter2DetailNoRecord.activeCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">
                          0</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                        <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Failed) </div>
                        <div _ngcontent-tgd-c17="" class="card-controls">
                          <ul _ngcontent-tgd-c17="">
                            <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                        <h3 *ngIf="transactionfilter2DetailNoRecord.inactiveCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter2DetailNoRecord.inactiveCount}}</h3>
                        <h3 *ngIf="transactionfilter2DetailNoRecord.inactiveCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                        <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71%</span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 300px; margin-left: -20px;">
                <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                        <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Initiated)</div>
                        <div _ngcontent-tgd-c17="" class="card-controls">
                          <ul _ngcontent-tgd-c17="">
                            <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                        <h3 *ngIf="transactionfilter2DetailNoRecord.initiatedCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter2DetailNoRecord.initiatedCount}}</h3>
                        <h3 *ngIf="transactionfilter2DetailNoRecord.initiatedCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                        <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             
              
            </div>
             
            
            
            <!-- START CONTAINER FLUID --> 
            
            <!-- START CONTAINER FLUID -->
            <div class=" no-padding    container-fixed-lg bg-white">
              <div class="container">
                <!-- START card -->
                <select class="select-form-style" style="margin-left: 20px; margin-top: 10px;" [(ngModel)]="searchStatus" id="searchBy" (change)="callsearch($event)">  
                  <option value="1">Search By Initiated Date & Time</option>  
                  <option value="2">Search By Transaction Date & Time</option> 
                  <option value="3">Search By All</option>   
                  <option value="4">Search By Onboarded By</option> 
              </select>  
              <input *ngIf="searchStatus!=3"type="date" style="margin-left: 10px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
            <input *ngIf="searchStatus!=3"type="date" style="margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
  
            <input *ngIf="searchStatus==3" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
            <input *ngIf="searchStatus==3" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
              <select class="select-form-style"  style="margin-left: 10px;" [(ngModel)]="searchByCusorPart" id="accountstatus">
                <option value="1">Customer</option>  
                <option value="2" >Partner</option>  
            </select>
            <select class="select-form-style" style="margin-left: 10px; display: none;" id="onboarded">
              <option value="1">CinchFuel</option>  
              <option value="2">Others</option>  
          </select>
            <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
            <button title="Refresh" *ngIf="refreshflag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
            <button title="Excel Export" *ngIf="excelexportchecktransaction" (click)="exportToExcelTransaction()" title="Excel Export" style="margin-left: 10px; margin-top: 5px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
          <div *ngIf="errorFlag" style="color: red; margin-left: 20px;">{{errorMessage}}</div>
                <div class="card card-transparent">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table id="excel-table" datatable  class="ch-table" >
                        <thead>
                          <tr> 
                            <th style="text-align: center;">Sl.No.</th>
                            <th style="text-align: left;">Order ID</th>
                            <!-- <th style="text-align: left;">Transaction ID</th> -->
                            <th style="text-align: left;">Customer Info</th>
                            <th style="text-align: left;">Delivery Agent Info</th>
                            <th style="text-align: left;">Fuel</th>
                            <!-- <th style="text-align: center;">Quantity in Ltrs</th> -->
                            <th style="text-align: right;">Transaction Amount</th>
                            <th style="text-align: center;">Payment Status</th>
                            <th style="text-align: center;">Payment Mode</th>
                            <th style="text-align: left;">Initiated Date Time</th>
                            <th style="text-align: left;">Transaction Date Time</th>
                            <th style="text-align: center; width: 100px;" >Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let transaction of transactionfilter2DetailNoRecord.response; let i=index">
                            <td align="center" class="v-align-middle "><p>{{i + 1}}</p></td>
                      <td *ngIf="transaction.orderId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 10px;" ><b>{{transaction.orderId}}</b></span><br>
                        <span *ngIf="transaction.transactionId!=null" style="font-size: 10px;"><b>Txn Id :</b>{{transaction.transactionId}}</span>
                        <span *ngIf="transaction.transactionId==null" style="font-size: 10px;"><b>Txn Id :</b>N/A</span>
                      </td>
                      <td *ngIf="transaction.orderId==null"align="left" class="v-align-middle"><p>N/A</p></td>

                      <!-- <td *ngIf="transaction.transactionId!=null"align="left" class="v-align-middle"><p>{{transaction.transactionId}}</p></td>
                      <td *ngIf="transaction.transactionId==null"align="left" class="v-align-middle"><p>N/A</p></td> -->

                      <td *ngIf="transaction.customerId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 12px;">{{transaction.customerId.firstName}} {{transaction.customerId.lastName}}</span><br>
                        <span style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.customerId.mobileNumber}}</span>
                        <!-- <p style="font-size: 12px;">{{transaction.customerId.firstName}} {{transaction.customerId.lastName}} </p>
                        <p style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.customerId.mobileNumber}}</p> -->
                      </td>
                      <td *ngIf="transaction.customerId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                      <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 12px;">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</span><br>
                        <span style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.vehicleId.userId.mobileNumber}}</span>
                          <!-- <p style="font-size: 12px;">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</p>
                          <p style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.vehicleId.userId.mobileNumber}}</p> -->
                        </td>
                        <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                        <td *ngIf="transaction.fuelType!=null" align="left" class="v-align-middle">
                          <span style="font-size: 10px;">{{transaction.fuelType}}</span><br>
                          <span style="font-size: 10px;"><b>Qtn : </b> {{transaction.quantity}}</span>
                          <!-- <p style="font-size: 10px;">{{transaction.fuelType}}</p>  
                          <p style="font-size: 10px;"><b>Qtn : </b> {{transaction.quantity}}</p> -->
                          </td>
                      <td *ngIf="transaction.fuelType==null"align="left" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="transaction.quantity!=null"align="center" class="v-align-middle">{{transaction.quantity}}</td>
                      <td *ngIf="transaction.quantity==null"align="center" class="v-align-middle">N/A</td> -->
                      <td align="right" *ngIf="transaction.totalPayableAmount!=null"class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.totalPayableAmount | number : '1.2-2'}}</td>
                      <td align="right" *ngIf="transaction.totalPayableAmount==null"class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="transaction.paymentModeId.id==1">
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-inactive">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' " style="color: red;">Failed</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                        <span  *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' " style="color: blue;">{{transaction.paymentStatus}}</span> 
                      </td>
                      <td align="center" *ngIf="transaction.paymentModeId.id!=1">
                        <span *ngIf="transaction.approvalStatus==='Pending' || transaction.approvalStatus==null" class="v-align-middle ht-inactive">Pending</span> 
                       <span *ngIf="transaction.approvalStatus==='Success' || transaction.approvalStatus==='Approved'" class="v-align-middle ht-active">Success</span> 

                       <span *ngIf="transaction.approvalStatus==='Submitted' "class="v-align-middle ht-active">
                         <span *ngIf="transaction.chequeClaimStatus=='Success'">Success</span> 
                         <span *ngIf="transaction.chequeClaimStatus!='Success'"> {{transaction.chequeClaimStatus}}</span> 
                       </span> 

                       <span *ngIf="transaction.approvalStatus==='Failed' " style="color: red;">Failed</span> 
                       <span *ngIfElse="transaction.approvalStatus!=null " style="color: orange;">{{transaction.approvalStatus}}</span> 
                     </td>
                     
                      <td> {{transaction.paymentModeId.paymentMode}} </td>
                      <td *ngIf="transaction.initiatedDatetime!=null"align="left" class="v-align-middle">{{transaction.initiatedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.initiatedDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paymentDatetime!=null"align="left" class="v-align-middle">{{transaction.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.paymentDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td align="left">
                        <button title="view" class="label label-info" (click)="view(content,transaction.id)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                        <span *ngIf="transaction.paymentModeId.id==5"> 
                          <button title="view Cheque and DD" class="label label-info" (click)="viewproof(transaction.id,transaction.orderProof,contentproof,1)"><i class="fa fa-money" aria-hidden="true"></i></button>&nbsp;
                         </span>

                         <span *ngIf="transaction.orderReceipt!=null"> 
                          <button title="view Receipt" class="label label-info" (click)="viewproof(transaction.id,transaction.orderReceipt,contentproof,2)"><i class="fa fa-list" aria-hidden="true"></i></button>&nbsp;
                         </span>

                         <button *ngIf="transaction.paymentModeId.id!=1 && (transaction.approvalStatus=='Pending' || transaction.approvalStatus=='Failed')" title="Update Approval Status" class="label label-info" (click)="update(content7,transaction.id,transaction.paymentModeId.id,transaction.approvalStatus,transaction.receivedAmount,transaction.paymentReferenceNo,transaction.totalPayableAmount)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;
                         <button *ngIf="transaction.paymentModeId.id==5 && transaction.approvalStatus=='Submitted' && transaction.chequeClaimStatus!='Success'" title="Update Claim Status" class="label label-info" (click)="updateClaim(content9,transaction.id,transaction.paymentReferenceNo,transaction.approvalStatus,transaction.chequeClaimStatus)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;

                         <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='Cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='Failure'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                      </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- END card --> 
              </div>
            </div>
            <!-- END CONTAINER FLUID --> 
          </div>
      
          <div *ngIf="accountStatus==2">
            <div style="display: none;">{{transactionfilter2DetailNoRecord.responseMessage}}</div>
                  <div class="container Customers-top-notification card4view">
                  <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 315px; margin-left: 5px;">
                    <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                            <div _ngcontent-tgd-c17="" class="card-title">Total Transaction Amount (Success) </div>
                            <div _ngcontent-tgd-c17="" class="card-controls">
                              <ul _ngcontent-tgd-c17="">
                                <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                            <h3 *ngIf="transactionfilter2DetailNoRecord.amount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i> {{transactionfilter2DetailNoRecord.amount | number : '1.2-2'}}</h3>
                            <h3 *ngIf="transactionfilter2DetailNoRecord.amount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i>0</h3>
                            <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% </span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                    <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                            <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Success) </div>
                            <div _ngcontent-tgd-c17="" class="card-controls">
                              <ul _ngcontent-tgd-c17="">
                                <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                            <h3 *ngIf="transactionfilter2DetailNoRecord.activeCount!=null" _ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter2DetailNoRecord.activeCount}}</h3>
                            <h3 *ngIf="transactionfilter2DetailNoRecord.activeCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">
                              0</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                    <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                            <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Failed) </div>
                            <div _ngcontent-tgd-c17="" class="card-controls">
                              <ul _ngcontent-tgd-c17="">
                                <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                            <h3 *ngIf="transactionfilter2DetailNoRecord.inactiveCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter2DetailNoRecord.inactiveCount}}</h3>
                            <h3 *ngIf="transactionfilter2DetailNoRecord.inactiveCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                            <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71%</span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 300px; margin-left: -20px;">
                    <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                            <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Initiated)</div>
                            <div _ngcontent-tgd-c17="" class="card-controls">
                              <ul _ngcontent-tgd-c17="">
                                <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                            <h3 *ngIf="transactionfilter2DetailNoRecord.initiatedCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter2DetailNoRecord.initiatedCount}}</h3>
                            <h3 *ngIf="transactionfilter2DetailNoRecord.initiatedCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                            <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- START CONTAINER FLUID -->
                <div class=" no-padding    container-fixed-lg bg-white">
                  <div class="container">
                    <select class="select-form-style" style="margin-left: 20px; margin-top: 10px;" [(ngModel)]="searchStatus" id="searchBy" (change)="callsearch($event)">  
                      <option value="1">Search By Initiated Date & Time</option>  
                      <option value="2">Search By Transaction Date & Time</option>  
                      <option value="3">Search By All</option>  
                      <option value="4">Search By Onboarded By</option> 
                  </select>  
                  <input *ngIf="searchStatus!=3"type="date" style="margin-left: 10px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
            <input *ngIf="searchStatus!=3"type="date" style="margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
  
            <input *ngIf="searchStatus==3" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
            <input *ngIf="searchStatus==3" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
                  <select class="select-form-style" style="margin-left: 10px;" [(ngModel)]="searchByCusorPart" id="accountstatus">
                    <option value="1" >Customer</option>  
                    <option value="2" >Partner</option>  
                </select>
                <select class="select-form-style" style="margin-left: 10px; display: none;" id="onboarded">
                  <option value="1">CinchFuel</option>  
                  <option value="2">Others</option>  
              </select>
                <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
                <button title="Refresh" *ngIf="refreshflag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
                <button title="Excel Export" *ngIf="excelexportchecktransaction" (click)="exportToExcelTransaction()" title="Excel Export" style="margin-left: 10px; margin-top: 5px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
              <div *ngIf="errorFlag" style="color: red; margin-left: 20px;">{{errorMessage}}</div>
                    <!-- START card -->
                    <div class="card card-transparent">
                      <!-- <div class="card-header "> -->
                      <div class="card-body">
                        <div class="table-responsive">
                          <table id="excel-table" datatable  class="ch-table" >
                            <thead>
                              <tr> 
                                <th style="text-align: center;">Sl.No.</th>
                                <th style="text-align: left;">Transaction ID</th>
                                <th style="text-align: left;">Partner Name</th>
                                <th style="text-align: left;">Partner Mobile</th>
                                <th style="text-align: right;">Transaction Amount</th>
                                <th style="text-align: center;">Payment Status</th>
                                <th style="text-align: center;">Payment Mode</th>

                                <th style="text-align: left;">Initiated Date Time</th>
                                <th style="text-align: left;">Transaction Date Time</th>
                                <th style="text-align: center; width: 100px;" >Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let transaction of transactionfilter2DetailNoRecord.response; let i=index">
                                <td align="center" class="v-align-middle ">{{i + 1}}</td>
                                <td *ngIf="transaction.paymentId!=null"align="left" class="v-align-middle">{{transaction.paymentId}}</td>
                                <td *ngIf="transaction.paymentId==null"align="left" class="v-align-middle">N/A</td>
                                <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</td>
                                <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                                <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">{{transaction.vehicleId.userId.mobileNumber}}</td>
                                <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                                <td align="right" *ngIf="transaction.totalPayableAmount!=null"class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.totalPayableAmount | number : '1.2-2'}}</td>
                                <td align="right" *ngIf="transaction.totalPayableAmount==null"class="v-align-middle">N/A</td>
                                <td align="center" *ngIf="transaction.paymentModeId.id==1">
                                  <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-inactive">{{transaction.paymentStatus}}</span> 
                                  <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</span> 
                                  <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' " style="color: red;">Failed</span> 
                                  <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                                  <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                                  <span  *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' " style="color: blue;">{{transaction.paymentStatus}}</span> 
                                </td>
                                <td align="center" *ngIf="transaction.paymentModeId.id!=1">
                                  <span *ngIf="transaction.approvalStatus==='Pending' || transaction.approvalStatus==null" class="v-align-middle ht-inactive">Pending</span> 
                                 <span *ngIf="transaction.approvalStatus==='Success' || transaction.approvalStatus==='Approved'" class="v-align-middle ht-active">Success</span> 
         
                                 <span *ngIf="transaction.approvalStatus==='Submitted' "class="v-align-middle ht-active">
                                   <span *ngIf="transaction.chequeClaimStatus=='Success'">Success</span> 
                                   <span *ngIf="transaction.chequeClaimStatus!='Success'"> {{transaction.chequeClaimStatus}}</span> 
                                 </span> 
         
                                 <span *ngIf="transaction.approvalStatus==='Failed' " style="color: red;">Failed</span> 
                                 <span *ngIfElse="transaction.approvalStatus!=null " style="color: orange;">{{transaction.approvalStatus}}</span> 
                               </td>
                                <td> {{transaction.paymentModeId.paymentMode}} </td>
                                <td *ngIf="transaction.initiateDatetime!=null"align="left" class="v-align-middle">{{transaction.initiateDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                                <td *ngIf="transaction.initiateDatetime==null"align="left" class="v-align-middle">N/A</td>
                                <td *ngIf="transaction.transactionDatetime!=null"align="left" class="v-align-middle">{{transaction.transactionDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                                <td *ngIf="transaction.transactionDatetime==null"align="left" class="v-align-middle">N/A</td>
                                <td align="left">
                                  <button title="view" class="label label-info" (click)="view(content,transaction.id)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                                  <span *ngIf="transaction.paymentModeId.id==5"> 
                                    <button title="view Cheque and DD" class="label label-info" (click)="viewproof(transaction.id,transaction.orderProof,contentproof,1)"><i class="fa fa-money" aria-hidden="true"></i></button>&nbsp;
                                   </span>
          
                                   <span *ngIf="transaction.orderReceipt!=null"> 
                                    <button title="view Receipt" class="label label-info" (click)="viewproof(transaction.id,transaction.orderReceipt,contentproof,2)"><i class="fa fa-list" aria-hidden="true"></i></button>&nbsp;
                                   </span>
          
                                   <button *ngIf="transaction.paymentModeId.id!=1 && (transaction.approvalStatus=='Pending' || transaction.approvalStatus=='Failed')" title="Update Approval Status" class="label label-info" (click)="update(content7,transaction.id,transaction.paymentModeId.id,transaction.approvalStatus,transaction.receivedAmount,transaction.paymentReferenceNo,transaction.totalPayableAmount)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;
                                   <button *ngIf="transaction.paymentModeId.id==5 && transaction.approvalStatus=='Submitted' && transaction.chequeClaimStatus!='Success'" title="Update Claim Status" class="label label-info" (click)="updateClaim(content9,transaction.id,transaction.paymentReferenceNo,transaction.approvalStatus,transaction.chequeClaimStatus)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;

                                   <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                  <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                  <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                  <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                  <button *ngIf="transaction.paymentStatus==='cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                                  <button *ngIf="transaction.paymentStatus==='Cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                                  <button *ngIf="transaction.paymentStatus==='Failure'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <!-- END card --> 
                  </div>
                </div>
                <!-- END CONTAINER FLUID --> 
              </div>   
    </div>
    
    </div>

    <!-- Filter 3  -->
  <div *ngIf="filterTransaction3">   
    <div *ngIf="filterthreesearch">
      <div *ngIf="accountStatus==1">
        <div style="display: none;">{{transactionfilter3Detail.responseMessage}}</div>
              <div class="container Customers-top-notification card4view">
              <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 315px; margin-left: 5px;">
                <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                        <div _ngcontent-tgd-c17="" class="card-title">Total Transaction Amount (Success) </div>
                        <div _ngcontent-tgd-c17="" class="card-controls">
                          <ul _ngcontent-tgd-c17="">
                            <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                        <h3 *ngIf="transactionfilter3Detail.amount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i> {{transactionfilter3Detail.amount| number : '1.2-2'}}</h3>
                        <h3 *ngIf="transactionfilter3Detail.amount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i>0</h3>
                        <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% </span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                        <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Success) </div>
                        <div _ngcontent-tgd-c17="" class="card-controls">
                          <ul _ngcontent-tgd-c17="">
                            <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                        <h3 *ngIf="transactionfilter3Detail.activeCount!=null" _ngcontent-tgd-c17="" class="no-margin p-b-5">
                          {{transactionfilter3Detail.activeCount}}</h3>
                        <h3 *ngIf="transactionfilter3Detail.activeCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">
                          0</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                        <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Failed) </div>
                        <div _ngcontent-tgd-c17="" class="card-controls">
                          <ul _ngcontent-tgd-c17="">
                            <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                        <h3 *ngIf="transactionfilter3Detail.inactiveCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter3Detail.inactiveCount}}</h3>
                        <h3 *ngIf="transactionfilter3Detail.inactiveCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                        <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71%</span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 300px; margin-left: -20px;">
                <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                        <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Initiated)</div>
                        <div _ngcontent-tgd-c17="" class="card-controls">
                          <ul _ngcontent-tgd-c17="">
                            <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                        <h3 *ngIf="transactionfilter3Detail.initiatedCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter3Detail.initiatedCount}}</h3>
                        <h3 *ngIf="transactionfilter3Detail.initiatedCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                        <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             
              
            </div>
             
            
            
            <!-- START CONTAINER FLUID --> 
            
            <!-- START CONTAINER FLUID -->
            <div class=" no-padding    container-fixed-lg bg-white">
              <div class="container">
                <!-- START card -->
                <select class="select-form-style" style="margin-left: 20px; margin-top: 10px;" [(ngModel)]="searchStatus" id="searchBy" (change)="callsearch($event)">  
                  <option value="1">Search By Initiated Date & Time</option>  
                  <option value="2">Search By Transaction Date & Time</option>  
                  <option value="3">Search By All</option>  
                  <option value="4">Search By Onboarded By</option> 
              </select>  
              <input *ngIf="searchStatus!=3"type="date" style="margin-left: 10px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
            <input *ngIf="searchStatus!=3"type="date" style="margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
  
            <input *ngIf="searchStatus==3" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
            <input *ngIf="searchStatus==3" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
              <select class="select-form-style"  style="margin-left: 10px;" [(ngModel)]="searchByCusorPart" id="accountstatus">
                <option value="1">Customer</option>  
                <option value="2" >Partner</option>  
            </select>
            <select class="select-form-style" style="margin-left: 10px; display: none;" id="onboarded">
              <option value="1">CinchFuel</option>  
              <option value="2">Others</option>  
          </select>
            <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
            <button title="Refresh" *ngIf="refreshflag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
            <button title="Excel Export" *ngIf="excelexportchecktransaction" (click)="exportToExcelTransaction()" title="Excel Export" style="margin-left: 10px; margin-top: 5px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
          <div *ngIf="errorFlag" style="color: red; margin-left: 20px;">{{errorMessage}}</div>
                <div class="card card-transparent">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table id="excel-table" datatable  class="ch-table" >
                        <thead>
                          <tr> 
                            <th style="text-align: center;">Sl.No.</th>
                            <th style="text-align: left;">Order ID</th>
                            <!-- <th style="text-align: left;">Transaction ID</th> -->
                            <th style="text-align: left;">Customer Info</th>
                            <th style="text-align: left;">Delivery Agent Info</th>
                            <th style="text-align: left;">Fuel</th>
                            <!-- <th style="text-align: center;">Quantity in Ltrs</th> -->
                            <th style="text-align: right;">Transaction Amount</th>
                            <th style="text-align: center;">Payment Status</th>
                            <th style="text-align: center;">Payment Mode</th>

                            <th style="text-align: left;">Initiated Date Time</th>
                            <th style="text-align: left;">Transaction Date Time</th>
                            <th style="text-align: center; width: 100px;" >Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let transaction of transactionfilter3Detail.response; let i=index">
                            <td align="center" class="v-align-middle "><p>{{i + 1}}</p></td>
                      <td *ngIf="transaction.orderId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 10px;" ><b>{{transaction.orderId}}</b></span><br>
                        <span *ngIf="transaction.transactionId!=null" style="font-size: 10px;"><b>Txn Id :</b>{{transaction.transactionId}}</span>
                        <span *ngIf="transaction.transactionId==null" style="font-size: 10px;"><b>Txn Id :</b>N/A</span>
                      </td>
                      <td *ngIf="transaction.orderId==null"align="left" class="v-align-middle"><p>N/A</p></td>

                      <!-- <td *ngIf="transaction.transactionId!=null"align="left" class="v-align-middle"><p>{{transaction.transactionId}}</p></td>
                      <td *ngIf="transaction.transactionId==null"align="left" class="v-align-middle"><p>N/A</p></td> -->

                      <td *ngIf="transaction.customerId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 12px;">{{transaction.customerId.firstName}} {{transaction.customerId.lastName}}</span><br>
                        <span style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.customerId.mobileNumber}}</span>
                        <!-- <p style="font-size: 12px;">{{transaction.customerId.firstName}} {{transaction.customerId.lastName}} </p>
                        <p style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.customerId.mobileNumber}}</p> -->
                      </td>
                      <td *ngIf="transaction.customerId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                      <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 12px;">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</span><br>
                        <span style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.vehicleId.userId.mobileNumber}}</span>
                          <!-- <p style="font-size: 12px;">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</p>
                          <p style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.vehicleId.userId.mobileNumber}}</p> -->
                        </td>
                        <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                        <td *ngIf="transaction.fuelType!=null" align="left" class="v-align-middle">
                          <span style="font-size: 10px;">{{transaction.fuelType}}</span><br>
                          <span style="font-size: 10px;"><b>Qtn : </b> {{transaction.quantity}}</span>
                          <!-- <p style="font-size: 10px;">{{transaction.fuelType}}</p>  
                          <p style="font-size: 10px;"><b>Qtn : </b> {{transaction.quantity}}</p> -->
                          </td>
                      <td *ngIf="transaction.fuelType==null"align="left" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="transaction.quantity!=null"align="center" class="v-align-middle">{{transaction.quantity}}</td>
                      <td *ngIf="transaction.quantity==null"align="center" class="v-align-middle">N/A</td> -->
                      <td align="right" *ngIf="transaction.totalPayableAmount!=null"class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.totalPayableAmount | number : '1.2-2'}}</td>
                      <td align="right" *ngIf="transaction.totalPayableAmount==null"class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="transaction.paymentModeId.id==1">
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-inactive">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' " style="color: red;">Failed</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                        <span  *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' " style="color: blue;">{{transaction.paymentStatus}}</span> 
                      </td>
                      <td align="center" *ngIf="transaction.paymentModeId.id!=1">
                        <span *ngIf="transaction.approvalStatus==='Pending' || transaction.approvalStatus==null" class="v-align-middle ht-inactive">Pending</span> 
                       <span *ngIf="transaction.approvalStatus==='Success' || transaction.approvalStatus==='Approved'" class="v-align-middle ht-active">Success</span> 

                       <span *ngIf="transaction.approvalStatus==='Submitted' "class="v-align-middle ht-active">
                         <span *ngIf="transaction.chequeClaimStatus=='Success'">Success</span> 
                         <span *ngIf="transaction.chequeClaimStatus!='Success'"> {{transaction.chequeClaimStatus}}</span> 
                       </span> 

                       <span *ngIf="transaction.approvalStatus==='Failed' " style="color: red;">Failed</span> 
                       <span *ngIfElse="transaction.approvalStatus!=null " style="color: orange;">{{transaction.approvalStatus}}</span> 
                     </td>
                      <td> {{transaction.paymentModeId.paymentMode}} </td>
                      <td *ngIf="transaction.initiatedDatetime!=null"align="left" class="v-align-middle">{{transaction.initiatedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.initiatedDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paymentDatetime!=null"align="left" class="v-align-middle">{{transaction.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.paymentDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td align="left">
                        <button title="view" class="label label-info" (click)="view(content,transaction.id)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                        <span *ngIf="transaction.paymentModeId.id==5"> 
                          <button title="view Cheque and DD" class="label label-info" (click)="viewproof(transaction.id,transaction.orderProof,contentproof,1)"><i class="fa fa-money" aria-hidden="true"></i></button>&nbsp;
                         </span>

                         <span *ngIf="transaction.orderReceipt!=null"> 
                          <button title="view Receipt" class="label label-info" (click)="viewproof(transaction.id,transaction.orderReceipt,contentproof,2)"><i class="fa fa-list" aria-hidden="true"></i></button>&nbsp;
                         </span>

                         <button *ngIf="transaction.paymentModeId.id!=1 && (transaction.approvalStatus=='Pending' || transaction.approvalStatus=='Failed')" title="Update Approval Status" class="label label-info" (click)="update(content7,transaction.id,transaction.paymentModeId.id,transaction.approvalStatus,transaction.receivedAmount,transaction.paymentReferenceNo,transaction.totalPayableAmount)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;
                         <button *ngIf="transaction.paymentModeId.id==5 && transaction.approvalStatus=='Submitted' && transaction.chequeClaimStatus!='Success'" title="Update Claim Status" class="label label-info" (click)="updateClaim(content9,transaction.id,transaction.paymentReferenceNo,transaction.approvalStatus,transaction.chequeClaimStatus)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;

                         <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='Cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='Failure'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                      </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- END card --> 
              </div>
            </div>
            <!-- END CONTAINER FLUID --> 
          </div>
      
          <div *ngIf="accountStatus==2">
            <div style="display: none;">{{transactionfilter3Detail.responseMessage}}</div>
                  <div class="container Customers-top-notification card4view">
                  <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 315px; margin-left: 5px;">
                    <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                            <div _ngcontent-tgd-c17="" class="card-title">Total Transaction Amount (Success) </div>
                            <div _ngcontent-tgd-c17="" class="card-controls">
                              <ul _ngcontent-tgd-c17="">
                                <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                            <h3 *ngIf="transactionfilter3Detail.amount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i> {{transactionfilter3Detail.amount | number : '1.2-2'}}</h3>
                            <h3 *ngIf="transactionfilter3Detail.amount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i>0</h3>
                            <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% </span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                    <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                            <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Success) </div>
                            <div _ngcontent-tgd-c17="" class="card-controls">
                              <ul _ngcontent-tgd-c17="">
                                <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                            <h3 *ngIf="transactionfilter3Detail.activeCount!=null" _ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter3Detail.activeCount}}</h3>
                            <h3 *ngIf="transactionfilter3Detail.activeCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">
                              0</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                    <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                            <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Failed) </div>
                            <div _ngcontent-tgd-c17="" class="card-controls">
                              <ul _ngcontent-tgd-c17="">
                                <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                            <h3 *ngIf="transactionfilter3Detail.inactiveCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter3Detail.inactiveCount}}</h3>
                            <h3 *ngIf="transactionfilter3Detail.inactiveCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                            <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71%</span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 300px; margin-left: -20px;">
                    <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                            <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Initiated)</div>
                            <div _ngcontent-tgd-c17="" class="card-controls">
                              <ul _ngcontent-tgd-c17="">
                                <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                            <h3 *ngIf="transactionfilter3Detail.initiatedCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter3Detail.initiatedCount}}</h3>
                            <h3 *ngIf="transactionfilter3Detail.initiatedCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                            <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- START CONTAINER FLUID -->
                <div class=" no-padding    container-fixed-lg bg-white">
                  <div class="container">
                    <select class="select-form-style" style="margin-left: 20px; margin-top: 10px;" [(ngModel)]="searchStatus" id="searchBy" (change)="callsearch($event)">  
                      <option value="1">Search By Initiated Date & Time</option>  
                      <option value="2">Search By Transaction Date & Time</option>  
                      <option value="3">Search By All</option>  
                      <option value="4">Search By Onboarded By</option> 
                  </select>  
                  <input *ngIf="searchStatus!=3"type="date" style="margin-left: 10px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
            <input *ngIf="searchStatus!=3"type="date" style="margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
  
            <input *ngIf="searchStatus==3" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
            <input *ngIf="searchStatus==3" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
                  <select class="select-form-style" style="margin-left: 10px;" [(ngModel)]="searchByCusorPart" id="accountstatus">
                    <option value="1" >Customer</option>  
                    <option value="2" >Partner</option>  
                </select>
                <select class="select-form-style" style="margin-left: 10px; display: none;" id="onboarded">
                  <option value="1">CinchFuel</option>  
                  <option value="2">Others</option>  
              </select>
                <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
                <button title="Refresh" *ngIf="refreshflag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
                <button title="Excel Export" *ngIf="excelexportchecktransaction" (click)="exportToExcelTransaction()" title="Excel Export" style="margin-left: 10px; margin-top: 5px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
              <div *ngIf="errorFlag" style="color: red; margin-left: 20px;">{{errorMessage}}</div>
                    <!-- START card -->
                    <div class="card card-transparent">
                      <!-- <div class="card-header "> -->
                      <div class="card-body">
                        <div class="table-responsive">
                          <table id="excel-table" datatable  class="ch-table" >
                            <thead>
                              <tr> 
                                <th style="text-align: center;">Sl.No.</th>
                                <th style="text-align: left;">Transaction ID</th>
                                <th style="text-align: left;">Partner Name</th>
                                <th style="text-align: left;">Partner Mobile</th>
                                <th style="text-align: right;">Transaction Amount</th>
                                <th style="text-align: center;">Payment Status</th>
                                <th style="text-align: center;">Payment Mode</th>
                                <th style="text-align: left;">Initiated Date Time</th>
                                <th style="text-align: left;">Transaction Date Time</th>
                                <th style="text-align: center; width: 100px;" >Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let transaction of transactionfilter3Detail.response; let i=index">
                                <td align="center" class="v-align-middle ">{{i + 1}}</td>
                                <td *ngIf="transaction.paymentId!=null"align="left" class="v-align-middle">{{transaction.paymentId}}</td>
                                <td *ngIf="transaction.paymentId==null"align="left" class="v-align-middle">N/A</td>
                                <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</td>
                                <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                                <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">{{transaction.vehicleId.userId.mobileNumber}}</td>
                                <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                                <td align="right" *ngIf="transaction.totalPayableAmount!=null"class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.totalPayableAmount | number : '1.2-2'}}</td>
                                <td align="right" *ngIf="transaction.totalPayableAmount==null"class="v-align-middle">N/A</td>
                                <td align="center" *ngIf="transaction.paymentModeId.id==1">
                                  <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-inactive">{{transaction.paymentStatus}}</span> 
                                  <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</span> 
                                  <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' " style="color: red;">Failed</span> 
                                  <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                                  <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                                  <span  *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' " style="color: blue;">{{transaction.paymentStatus}}</span> 
                                </td>
                                <td align="center" *ngIf="transaction.paymentModeId.id!=1">
                                  <span *ngIf="transaction.approvalStatus==='Pending' || transaction.approvalStatus==null" class="v-align-middle ht-inactive">Pending</span> 
                                 <span *ngIf="transaction.approvalStatus==='Success' || transaction.approvalStatus==='Approved'" class="v-align-middle ht-active">Success</span> 
         
                                 <span *ngIf="transaction.approvalStatus==='Submitted' "class="v-align-middle ht-active">
                                   <span *ngIf="transaction.chequeClaimStatus=='Success'">Success</span> 
                                   <span *ngIf="transaction.chequeClaimStatus!='Success'"> {{transaction.chequeClaimStatus}}</span> 
                                 </span> 
         
                                 <span *ngIf="transaction.approvalStatus==='Failed' " style="color: red;">Failed</span> 
                                 <span *ngIfElse="transaction.approvalStatus!=null " style="color: orange;">{{transaction.approvalStatus}}</span> 
                               </td>
                                <td> {{transaction.paymentModeId.paymentMode}} </td>
                                <td *ngIf="transaction.initiateDatetime!=null"align="left" class="v-align-middle">{{transaction.initiateDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                                <td *ngIf="transaction.initiateDatetime==null"align="left" class="v-align-middle">N/A</td>
                                <td *ngIf="transaction.transactionDatetime!=null"align="left" class="v-align-middle">{{transaction.transactionDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                                <td *ngIf="transaction.transactionDatetime==null"align="left" class="v-align-middle">N/A</td>
                                <td align="left">
                                  <button title="view" class="label label-info" (click)="view(content,transaction.id)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                                  <span *ngIf="transaction.paymentModeId.id==5"> 
                                    <button title="view Cheque and DD" class="label label-info" (click)="viewproof(transaction.id,transaction.orderProof,contentproof,1)"><i class="fa fa-money" aria-hidden="true"></i></button>&nbsp;
                                   </span>
          
                                   <span *ngIf="transaction.orderReceipt!=null"> 
                                    <button title="view Receipt" class="label label-info" (click)="viewproof(transaction.id,transaction.orderReceipt,contentproof,2)"><i class="fa fa-list" aria-hidden="true"></i></button>&nbsp;
                                   </span>
          
                                   <button *ngIf="transaction.paymentModeId.id!=1 && (transaction.approvalStatus=='Pending' || transaction.approvalStatus=='Failed')" title="Update Approval Status" class="label label-info" (click)="update(content7,transaction.id,transaction.paymentModeId.id,transaction.approvalStatus,transaction.receivedAmount,transaction.paymentReferenceNo,transaction.totalPayableAmount)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;
                                   <button *ngIf="transaction.paymentModeId.id==5 && transaction.approvalStatus=='Submitted' && transaction.chequeClaimStatus!='Success'" title="Update Claim Status" class="label label-info" (click)="updateClaim(content9,transaction.id,transaction.paymentReferenceNo,transaction.approvalStatus,transaction.chequeClaimStatus)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;

                                   <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                  <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                  <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                  <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                  <button *ngIf="transaction.paymentStatus==='cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                                  <button *ngIf="transaction.paymentStatus==='Cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                                  <button *ngIf="transaction.paymentStatus==='Failure'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <!-- END card --> 
                  </div>
                </div>
                <!-- END CONTAINER FLUID --> 
              </div>   
    </div>  
    <div *ngIf="!filterthreesearch">
      <div *ngIf="accountStatus==1">
        <div style="display: none;">{{transactionfilter3DetailNoRecord.responseMessage}}</div>
              <div class="container Customers-top-notification card4view">
              <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 315px; margin-left: 5px;">
                <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                        <div _ngcontent-tgd-c17="" class="card-title">Total Transaction Amount (Success) </div>
                        <div _ngcontent-tgd-c17="" class="card-controls">
                          <ul _ngcontent-tgd-c17="">
                            <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                        <h3 *ngIf="transactionfilter3DetailNoRecord.amount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i> {{transactionfilter3DetailNoRecord.amount| number : '1.2-2'}}</h3>
                        <h3 *ngIf="transactionfilter3DetailNoRecord.amount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i>0</h3>
                        <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% </span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                        <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Success) </div>
                        <div _ngcontent-tgd-c17="" class="card-controls">
                          <ul _ngcontent-tgd-c17="">
                            <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                        <h3 *ngIf="transactionfilter3DetailNoRecord.activeCount!=null" _ngcontent-tgd-c17="" class="no-margin p-b-5">
                          {{transactionfilter3DetailNoRecord.activeCount}}</h3>
                        <h3 *ngIf="transactionfilter3DetailNoRecord.activeCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">
                          0</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                        <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Failed) </div>
                        <div _ngcontent-tgd-c17="" class="card-controls">
                          <ul _ngcontent-tgd-c17="">
                            <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                        <h3 *ngIf="transactionfilter3DetailNoRecord.inactiveCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter3DetailNoRecord.inactiveCount}}</h3>
                        <h3 *ngIf="transactionfilter3DetailNoRecord.inactiveCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                        <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71%</span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 300px; margin-left: -20px;">
                <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                        <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Initiated)</div>
                        <div _ngcontent-tgd-c17="" class="card-controls">
                          <ul _ngcontent-tgd-c17="">
                            <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="row-xs-height">
                    <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                      <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                        <h3 *ngIf="transactionfilter3DetailNoRecord.initiatedCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter3DetailNoRecord.initiatedCount}}</h3>
                        <h3 *ngIf="transactionfilter3DetailNoRecord.initiatedCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                        <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             
              
            </div>
             
            
            
            <!-- START CONTAINER FLUID --> 
            
            <!-- START CONTAINER FLUID -->
            <div class=" no-padding    container-fixed-lg bg-white">
              <div class="container">
                <!-- START card -->
                <select class="select-form-style" style="margin-left: 20px; margin-top: 10px;" [(ngModel)]="searchStatus" id="searchBy" (change)="callsearch($event)">  
                  <option value="1">Search By Initiated Date & Time</option>  
                  <option value="2">Search By Transaction Date & Time</option>  
                  <option value="3">Search By All</option>  
                  <option value="4">Search By Onboarded By</option> 
              </select>  
              <input *ngIf="searchStatus!=3"type="date" style="margin-left: 10px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
            <input *ngIf="searchStatus!=3"type="date" style="margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
  
            <input *ngIf="searchStatus==3" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
            <input *ngIf="searchStatus==3" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
              <select class="select-form-style"  style="margin-left: 10px;" [(ngModel)]="searchByCusorPart" id="accountstatus">
                <option value="1">Customer</option>  
                <option value="2" >Partner</option>  
            </select>
            <select class="select-form-style" style="margin-left: 10px; display: none;" id="onboarded">
              <option value="1">CinchFuel</option>  
              <option value="2">Others</option>  
          </select>
            <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
            <button title="Refresh" *ngIf="refreshflag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
            <button title="Excel Export" *ngIf="excelexportchecktransaction" (click)="exportToExcelTransaction()" title="Excel Export" style="margin-left: 10px; margin-top: 5px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
          <div *ngIf="errorFlag" style="color: red; margin-left: 20px;">{{errorMessage}}</div>
                <div class="card card-transparent">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table id="excel-table" datatable  class="ch-table" >
                        <thead>
                          <tr> 
                            <th style="text-align: center;">Sl.No.</th>
                            <th style="text-align: left;">Order ID</th>
                            <!-- <th style="text-align: left;">Transaction ID</th> -->
                            <th style="text-align: left;">Customer Info</th>
                            <th style="text-align: left;">Delivery Agent Info</th>
                            <th style="text-align: left;">Fuel</th>
                            <!-- <th style="text-align: center;">Quantity in Ltrs</th> -->
                            <th style="text-align: right;">Transaction Amount</th>
                            <th style="text-align: center;">Payment Status</th>
                            <th style="text-align: center;">Payment Mode</th>
                            <th style="text-align: left;">Initiated Date Time</th>
                            <th style="text-align: left;">Transaction Date Time</th>
                            <th style="text-align: center; width: 100px;" >Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let transaction of transactionfilter3DetailNoRecord.response; let i=index">
                            <td align="center" class="v-align-middle "><p>{{i + 1}}</p></td>
                      <td *ngIf="transaction.orderId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 10px;" ><b>{{transaction.orderId}}</b></span><br>
                        <span *ngIf="transaction.transactionId!=null" style="font-size: 10px;"><b>Txn Id :</b>{{transaction.transactionId}}</span>
                        <span *ngIf="transaction.transactionId==null" style="font-size: 10px;"><b>Txn Id :</b>N/A</span>
                      </td>
                      <td *ngIf="transaction.orderId==null"align="left" class="v-align-middle"><p>N/A</p></td>

                      <!-- <td *ngIf="transaction.transactionId!=null"align="left" class="v-align-middle"><p>{{transaction.transactionId}}</p></td>
                      <td *ngIf="transaction.transactionId==null"align="left" class="v-align-middle"><p>N/A</p></td> -->

                      <td *ngIf="transaction.customerId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 12px;">{{transaction.customerId.firstName}} {{transaction.customerId.lastName}}</span><br>
                        <span style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.customerId.mobileNumber}}</span>
                        <!-- <p style="font-size: 12px;">{{transaction.customerId.firstName}} {{transaction.customerId.lastName}} </p>
                        <p style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.customerId.mobileNumber}}</p> -->
                      </td>
                      <td *ngIf="transaction.customerId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                      <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 12px;">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</span><br>
                        <span style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.vehicleId.userId.mobileNumber}}</span>
                          <!-- <p style="font-size: 12px;">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</p>
                          <p style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.vehicleId.userId.mobileNumber}}</p> -->
                        </td>
                        <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                        <td *ngIf="transaction.fuelType!=null" align="left" class="v-align-middle">
                          <span style="font-size: 10px;">{{transaction.fuelType}}</span><br>
                          <span style="font-size: 10px;"><b>Qtn : </b> {{transaction.quantity}}</span>
                          <!-- <p style="font-size: 10px;">{{transaction.fuelType}}</p>  
                          <p style="font-size: 10px;"><b>Qtn : </b> {{transaction.quantity}}</p> -->
                          </td>
                      <td *ngIf="transaction.fuelType==null"align="left" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="transaction.quantity!=null"align="center" class="v-align-middle">{{transaction.quantity}}</td>
                      <td *ngIf="transaction.quantity==null"align="center" class="v-align-middle">N/A</td> -->
                      <td align="right" *ngIf="transaction.totalPayableAmount!=null"class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.totalPayableAmount | number : '1.2-2'}}</td>
                      <td align="right" *ngIf="transaction.totalPayableAmount==null"class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="transaction.paymentModeId.id==1">
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-inactive">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' " style="color: red;">Failed</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                        <span  *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' " style="color: blue;">{{transaction.paymentStatus}}</span> 
                      </td>
                      <td align="center" *ngIf="transaction.paymentModeId.id!=1">
                        <span *ngIf="transaction.approvalStatus==='Pending' || transaction.approvalStatus==null" class="v-align-middle ht-inactive">Pending</span> 
                       <span *ngIf="transaction.approvalStatus==='Success' || transaction.approvalStatus==='Approved'" class="v-align-middle ht-active">Success</span> 

                       <span *ngIf="transaction.approvalStatus==='Submitted' "class="v-align-middle ht-active">
                         <span *ngIf="transaction.chequeClaimStatus=='Success'">Success</span> 
                         <span *ngIf="transaction.chequeClaimStatus!='Success'"> {{transaction.chequeClaimStatus}}</span> 
                       </span> 

                       <span *ngIf="transaction.approvalStatus==='Failed' " style="color: red;">Failed</span> 
                       <span *ngIfElse="transaction.approvalStatus!=null " style="color: orange;">{{transaction.approvalStatus}}</span> 
                     </td>
                      <td> {{transaction.paymentModeId.paymentMode}} </td>
                      <td *ngIf="transaction.initiatedDatetime!=null"align="left" class="v-align-middle">{{transaction.initiatedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.initiatedDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paymentDatetime!=null"align="left" class="v-align-middle">{{transaction.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.paymentDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td align="left">
                        <button title="view" class="label label-info" (click)="view(content,transaction.id)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                        <span *ngIf="transaction.paymentModeId.id==5"> 
                          <button title="view Cheque and DD" class="label label-info" (click)="viewproof(transaction.id,transaction.orderProof,contentproof,1)"><i class="fa fa-money" aria-hidden="true"></i></button>&nbsp;
                         </span>

                         <span *ngIf="transaction.orderReceipt!=null"> 
                          <button title="view Receipt" class="label label-info" (click)="viewproof(transaction.id,transaction.orderReceipt,contentproof,2)"><i class="fa fa-list" aria-hidden="true"></i></button>&nbsp;
                         </span>

                         <button *ngIf="transaction.paymentModeId.id!=1 && (transaction.approvalStatus=='Pending' || transaction.approvalStatus=='Failed')" title="Update Approval Status" class="label label-info" (click)="update(content7,transaction.id,transaction.paymentModeId.id,transaction.approvalStatus,transaction.receivedAmount,transaction.paymentReferenceNo,transaction.totalPayableAmount)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;
                         <button *ngIf="transaction.paymentModeId.id==5 && transaction.approvalStatus=='Submitted' && transaction.chequeClaimStatus!='Success'" title="Update Claim Status" class="label label-info" (click)="updateClaim(content9,transaction.id,transaction.paymentReferenceNo,transaction.approvalStatus,transaction.chequeClaimStatus)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;

                         <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='Cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='Failure'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                      </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- END card --> 
              </div>
            </div>
            <!-- END CONTAINER FLUID --> 
          </div>
      
          <div *ngIf="accountStatus==2">
            <div style="display: none;">{{transactionfilter3DetailNoRecord.responseMessage}}</div>
                  <div class="container Customers-top-notification card4view">
                  <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 315px; margin-left: 5px;">
                    <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                            <div _ngcontent-tgd-c17="" class="card-title">Total Transaction Amount (Success) </div>
                            <div _ngcontent-tgd-c17="" class="card-controls">
                              <ul _ngcontent-tgd-c17="">
                                <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                            <h3 *ngIf="transactionfilter3DetailNoRecord.amount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i> {{transactionfilter3DetailNoRecord.amount | number : '1.2-2'}}</h3>
                            <h3 *ngIf="transactionfilter3DetailNoRecord.amount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i>0</h3>
                            <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% </span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                    <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                            <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Success) </div>
                            <div _ngcontent-tgd-c17="" class="card-controls">
                              <ul _ngcontent-tgd-c17="">
                                <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                            <h3 *ngIf="transactionfilter3DetailNoRecord.activeCount!=null" _ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter3DetailNoRecord.activeCount}}</h3>
                            <h3 *ngIf="transactionfilter3DetailNoRecord.activeCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">
                              0</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                    <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                            <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Failed) </div>
                            <div _ngcontent-tgd-c17="" class="card-controls">
                              <ul _ngcontent-tgd-c17="">
                                <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                            <h3 *ngIf="transactionfilter3DetailNoRecord.inactiveCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter3DetailNoRecord.inactiveCount}}</h3>
                            <h3 *ngIf="transactionfilter3DetailNoRecord.inactiveCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                            <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71%</span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 300px; margin-left: -20px;">
                    <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                            <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Initiated)</div>
                            <div _ngcontent-tgd-c17="" class="card-controls">
                              <ul _ngcontent-tgd-c17="">
                                <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div _ngcontent-tgd-c17="" class="row-xs-height">
                        <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                          <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                            <h3 *ngIf="transactionfilter3DetailNoRecord.initiatedCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter3DetailNoRecord.initiatedCount}}</h3>
                            <h3 *ngIf="transactionfilter3DetailNoRecord.initiatedCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                            <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- START CONTAINER FLUID -->
                <div class=" no-padding    container-fixed-lg bg-white">
                  <div class="container">
                    <select class="select-form-style" style="margin-left: 20px; margin-top: 10px;" [(ngModel)]="searchStatus" id="searchBy" (change)="callsearch($event)">  
                      <option value="1">Search By Initiated Date & Time</option>  
                      <option value="2">Search By Transaction Date & Time</option>  
                      <option value="3">Search By All</option>  
                      <option value="4">Search By Onboarded By</option> 
                  </select>  
                  <input *ngIf="searchStatus!=3"type="date" style="margin-left: 10px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
            <input *ngIf="searchStatus!=3"type="date" style="margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
  
            <input *ngIf="searchStatus==3" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
            <input *ngIf="searchStatus==3" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
                  <select class="select-form-style" style="margin-left: 10px;" [(ngModel)]="searchByCusorPart" id="accountstatus">
                    <option value="1" >Customer</option>  
                    <option value="2" >Partner</option>  
                </select>
                <select class="select-form-style" style="margin-left: 10px; display: none;" id="onboarded">
                  <option value="1">CinchFuel</option>  
                  <option value="2">Others</option>  
              </select>
                <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
                <button title="Refresh" *ngIf="refreshflag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
                <button title="Excel Export" *ngIf="excelexportchecktransaction" (click)="exportToExcelTransaction()" title="Excel Export" style="margin-left: 10px; margin-top: 5px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
              <div *ngIf="errorFlag" style="color: red; margin-left: 20px;">{{errorMessage}}</div>
                    <!-- START card -->
                    <div class="card card-transparent">
                      <!-- <div class="card-header "> -->
                      <div class="card-body">
                        <div class="table-responsive">
                          <table id="excel-table" datatable  class="ch-table" >
                            <thead>
                              <tr> 
                                <th style="text-align: center;">Sl.No.</th>
                                <th style="text-align: left;">Transaction ID</th>
                                <th style="text-align: left;">Partner Name</th>
                                <th style="text-align: left;">Partner Mobile</th>
                                <th style="text-align: right;">Transaction Amount</th>
                                <th style="text-align: center;">Payment Status</th>
                                <th style="text-align: center;">Payment Mode</th>

                                <th style="text-align: left;">Initiated Date Time</th>
                                <th style="text-align: left;">Transaction Date Time</th>
                                <th style="text-align: center; width: 100px;" >Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let transaction of transactionfilter3DetailNoRecord.response; let i=index">
                                <td align="center" class="v-align-middle ">{{i + 1}}</td>
                                <td *ngIf="transaction.paymentId!=null"align="left" class="v-align-middle">{{transaction.paymentId}}</td>
                                <td *ngIf="transaction.paymentId==null"align="left" class="v-align-middle">N/A</td>
                                <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</td>
                                <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                                <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">{{transaction.vehicleId.userId.mobileNumber}}</td>
                                <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                                <td align="right" *ngIf="transaction.totalPayableAmount!=null"class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.totalPayableAmount | number : '1.2-2'}}</td>
                                <td align="right" *ngIf="transaction.totalPayableAmount==null"class="v-align-middle">N/A</td>
                                <td align="center" *ngIf="transaction.paymentModeId.id==1">
                                  <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-inactive">{{transaction.paymentStatus}}</span> 
                                  <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</span> 
                                  <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' " style="color: red;">Failed</span> 
                                  <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                                  <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                                  <span  *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' " style="color: blue;">{{transaction.paymentStatus}}</span> 
                                </td>
                                <td align="center" *ngIf="transaction.paymentModeId.id!=1">
                                  <span *ngIf="transaction.approvalStatus==='Pending' || transaction.approvalStatus==null" class="v-align-middle ht-inactive">Pending</span> 
                                 <span *ngIf="transaction.approvalStatus==='Success' || transaction.approvalStatus==='Approved'" class="v-align-middle ht-active">Success</span> 
         
                                 <span *ngIf="transaction.approvalStatus==='Submitted' "class="v-align-middle ht-active">
                                   <span *ngIf="transaction.chequeClaimStatus=='Success'">Success</span> 
                                   <span *ngIf="transaction.chequeClaimStatus!='Success'"> {{transaction.chequeClaimStatus}}</span> 
                                 </span> 
         
                                 <span *ngIf="transaction.approvalStatus==='Failed' " style="color: red;">Failed</span> 
                                 <span *ngIfElse="transaction.approvalStatus!=null " style="color: orange;">{{transaction.approvalStatus}}</span> 
                               </td>
                                <td> {{transaction.paymentModeId.paymentMode}} </td>
                                <td *ngIf="transaction.initiateDatetime!=null"align="left" class="v-align-middle">{{transaction.initiateDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                                <td *ngIf="transaction.initiateDatetime==null"align="left" class="v-align-middle">N/A</td>
                                <td *ngIf="transaction.transactionDatetime!=null"align="left" class="v-align-middle">{{transaction.transactionDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                                <td *ngIf="transaction.transactionDatetime==null"align="left" class="v-align-middle">N/A</td>
                                <td align="left">
                                  <button title="view" class="label label-info" (click)="view(content,transaction.id)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                                  <span *ngIf="transaction.paymentModeId.id==5"> 
                                    <button title="view Cheque and DD" class="label label-info" (click)="viewproof(transaction.id,transaction.orderProof,contentproof,1)"><i class="fa fa-money" aria-hidden="true"></i></button>&nbsp;
                                   </span>
          
                                   <span *ngIf="transaction.orderReceipt!=null"> 
                                    <button title="view Receipt" class="label label-info" (click)="viewproof(transaction.id,transaction.orderReceipt,contentproof,2)"><i class="fa fa-list" aria-hidden="true"></i></button>&nbsp;
                                   </span>
          
                                   <button *ngIf="transaction.paymentModeId.id!=1 && (transaction.approvalStatus=='Pending' || transaction.approvalStatus=='Failed')" title="Update Approval Status" class="label label-info" (click)="update(content7,transaction.id,transaction.paymentModeId.id,transaction.approvalStatus,transaction.receivedAmount,transaction.paymentReferenceNo,transaction.totalPayableAmount)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;
                                   <button *ngIf="transaction.paymentModeId.id==5 && transaction.approvalStatus=='Submitted' && transaction.chequeClaimStatus!='Success'" title="Update Claim Status" class="label label-info" (click)="updateClaim(content9,transaction.id,transaction.paymentReferenceNo,transaction.approvalStatus,transaction.chequeClaimStatus)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;

                                   <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                  <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                  <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                  <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                  <button *ngIf="transaction.paymentStatus==='cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                                  <button *ngIf="transaction.paymentStatus==='Cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                                  <button *ngIf="transaction.paymentStatus==='Failure'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <!-- END card --> 
                  </div>
                </div>
                <!-- END CONTAINER FLUID --> 
              </div>   
    </div>
    </div>

    <!-- Filter 4  -->
    <div *ngIf="filterTransaction4">   
      <div *ngIf="filterfoursearch">
        <div *ngIf="accountStatus==1">
          <div style="display: none;">{{transactionfilter4Detail.responseMessage}}</div>
                <div class="container Customers-top-notification card4view">
                <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 315px; margin-left: 5px;">
                  <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                          <div _ngcontent-tgd-c17="" class="card-title">Total Transaction Amount (Success) </div>
                          <div _ngcontent-tgd-c17="" class="card-controls">
                            <ul _ngcontent-tgd-c17="">
                              <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="transactionfilter4Detail.amount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i> {{transactionfilter4Detail.amount| number : '1.2-2'}}</h3>
                          <h3 *ngIf="transactionfilter4Detail.amount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i>0</h3>
                          <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% </span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                  <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                          <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Success) </div>
                          <div _ngcontent-tgd-c17="" class="card-controls">
                            <ul _ngcontent-tgd-c17="">
                              <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="transactionfilter4Detail.activeCount!=null" _ngcontent-tgd-c17="" class="no-margin p-b-5">
                            {{transactionfilter4Detail.activeCount}}</h3>
                          <h3 *ngIf="transactionfilter4Detail.activeCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">
                            0</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                  <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                          <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Failed) </div>
                          <div _ngcontent-tgd-c17="" class="card-controls">
                            <ul _ngcontent-tgd-c17="">
                              <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="transactionfilter4Detail.inactiveCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter4Detail.inactiveCount}}</h3>
                          <h3 *ngIf="transactionfilter4Detail.inactiveCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                          <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71%</span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 300px; margin-left: -20px;">
                  <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                          <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Initiated)</div>
                          <div _ngcontent-tgd-c17="" class="card-controls">
                            <ul _ngcontent-tgd-c17="">
                              <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="transactionfilter4Detail.initiatedCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter4Detail.initiatedCount}}</h3>
                          <h3 *ngIf="transactionfilter4Detail.initiatedCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                          <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               
                
              </div>
               
              
              
              <!-- START CONTAINER FLUID --> 
              
              <!-- START CONTAINER FLUID -->
              <div class=" no-padding    container-fixed-lg bg-white">
                <div class="container">
                  <!-- START card -->
                  <select class="select-form-style" style="margin-left: 20px; margin-top: 10px;" [(ngModel)]="searchStatus" id="searchBy" (change)="callsearch($event)">  
                    <option value="1">Search By Initiated Date & Time</option>  
                    <option value="2">Search By Transaction Date & Time</option>  
                    <option value="3">Search By All</option>  
                    <option value="4">Search By Onboarded By</option> 
                </select>  
                <input *ngIf="searchStatus!=4"type="date" style="margin-left: 10px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
              <input *ngIf="searchStatus!=4"type="date" style="margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
    
              <input *ngIf="searchStatus==4" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
              <input *ngIf="searchStatus==4" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
                <select class="select-form-style"  style="margin-left: 10px;" [(ngModel)]="searchByCusorPart" id="accountstatus">
                  <option value="1">Customer</option>  
                  <option value="2" >Partner</option>  
              </select>
              <select class="select-form-style" [(ngModel)]="searchByonboardedby" style="margin-left: 10px;" id="onboarded">
                <option value="1">CinchFuel</option>  
                <option value="2">Others</option>  
            </select>
              <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
              <button title="Refresh" *ngIf="refreshflag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
              <button title="Excel Export" *ngIf="excelexportchecktransaction" (click)="exportToExcelTransaction()" title="Excel Export" style="margin-left: 10px; margin-top: 5px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
            <div *ngIf="errorFlag" style="color: red; margin-left: 20px;">{{errorMessage}}</div>
                  <div class="card card-transparent">
                    <div class="card-body">
                      <div class="table-responsive">
                        <table id="excel-table" datatable  class="ch-table" >
                          <thead>
                            <tr> 
                              <th style="text-align: center;">Sl.No.</th>
                              <th style="text-align: left;">Order ID</th>
                              <!-- <th style="text-align: left;">Transaction ID</th> -->
                              <th style="text-align: left;">Customer Info</th>
                              <th style="text-align: left;">Delivery Agent Info</th>
                              <th style="text-align: left;">Fuel</th>
                              <!-- <th style="text-align: center;">Quantity in Ltrs</th> -->
                              <th style="text-align: right;">Transaction Amount</th>
                              <th style="text-align: center;">Payment Status</th>
                              <th style="text-align: center;">Payment Mode</th>

                              <th style="text-align: left;">Initiated Date Time</th>
                              <th style="text-align: left;">Transaction Date Time</th>
                              <th style="text-align: center; width: 100px;" >Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let transaction of transactionfilter4Detail.response; let i=index">
                              <td align="center" class="v-align-middle "><p>{{i + 1}}</p></td>
                      <td *ngIf="transaction.orderId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 10px;" ><b>{{transaction.orderId}}</b></span><br>
                        <span *ngIf="transaction.transactionId!=null" style="font-size: 10px;"><b>Txn Id :</b>{{transaction.transactionId}}</span>
                        <span *ngIf="transaction.transactionId==null" style="font-size: 10px;"><b>Txn Id :</b>N/A</span>
                      </td>
                      <td *ngIf="transaction.orderId==null"align="left" class="v-align-middle"><p>N/A</p></td>

                      <!-- <td *ngIf="transaction.transactionId!=null"align="left" class="v-align-middle"><p>{{transaction.transactionId}}</p></td>
                      <td *ngIf="transaction.transactionId==null"align="left" class="v-align-middle"><p>N/A</p></td> -->

                      <td *ngIf="transaction.customerId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 12px;">{{transaction.customerId.firstName}} {{transaction.customerId.lastName}}</span><br>
                        <span style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.customerId.mobileNumber}}</span>
                        <!-- <p style="font-size: 12px;">{{transaction.customerId.firstName}} {{transaction.customerId.lastName}} </p>
                        <p style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.customerId.mobileNumber}}</p> -->
                      </td>
                      <td *ngIf="transaction.customerId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                      <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 12px;">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</span><br>
                        <span style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.vehicleId.userId.mobileNumber}}</span>
                          <!-- <p style="font-size: 12px;">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</p>
                          <p style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.vehicleId.userId.mobileNumber}}</p> -->
                        </td>
                        <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                        <td *ngIf="transaction.fuelType!=null" align="left" class="v-align-middle">
                          <span style="font-size: 10px;">{{transaction.fuelType}}</span><br>
                          <span style="font-size: 10px;"><b>Qtn : </b> {{transaction.quantity}}</span>
                          <!-- <p style="font-size: 10px;">{{transaction.fuelType}}</p>  
                          <p style="font-size: 10px;"><b>Qtn : </b> {{transaction.quantity}}</p> -->
                          </td>
                      <td *ngIf="transaction.fuelType==null"align="left" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="transaction.quantity!=null"align="center" class="v-align-middle">{{transaction.quantity}}</td>
                      <td *ngIf="transaction.quantity==null"align="center" class="v-align-middle">N/A</td> -->
                      <td align="right" *ngIf="transaction.totalPayableAmount!=null"class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.totalPayableAmount | number : '1.2-2'}}</td>
                      <td align="right" *ngIf="transaction.totalPayableAmount==null"class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="transaction.paymentModeId.id==1">
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-inactive">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' " style="color: red;">Failed</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                        <span  *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' " style="color: blue;">{{transaction.paymentStatus}}</span> 
                      </td>
                      <td align="center" *ngIf="transaction.paymentModeId.id!=1">
                        <span *ngIf="transaction.approvalStatus==='Pending' || transaction.approvalStatus==null" class="v-align-middle ht-inactive">Pending</span> 
                       <span *ngIf="transaction.approvalStatus==='Success' || transaction.approvalStatus==='Approved'" class="v-align-middle ht-active">Success</span> 

                       <span *ngIf="transaction.approvalStatus==='Submitted' "class="v-align-middle ht-active">
                         <span *ngIf="transaction.chequeClaimStatus=='Success'">Success</span> 
                         <span *ngIf="transaction.chequeClaimStatus!='Success'"> {{transaction.chequeClaimStatus}}</span> 
                       </span> 

                       <span *ngIf="transaction.approvalStatus==='Failed' " style="color: red;">Failed</span> 
                       <span *ngIfElse="transaction.approvalStatus!=null " style="color: orange;">{{transaction.approvalStatus}}</span> 
                     </td>
                      <td> {{transaction.paymentModeId.paymentMode}} </td>
                      <td *ngIf="transaction.initiatedDatetime!=null"align="left" class="v-align-middle">{{transaction.initiatedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.initiatedDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paymentDatetime!=null"align="left" class="v-align-middle">{{transaction.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.paymentDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td align="left">
                        <button title="view" class="label label-info" (click)="view(content,transaction.id)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                        <span *ngIf="transaction.paymentModeId.id==5"> 
                          <button title="view Cheque and DD" class="label label-info" (click)="viewproof(transaction.id,transaction.orderProof,contentproof,1)"><i class="fa fa-money" aria-hidden="true"></i></button>&nbsp;
                         </span>

                         <span *ngIf="transaction.orderReceipt!=null"> 
                          <button title="view Receipt" class="label label-info" (click)="viewproof(transaction.id,transaction.orderReceipt,contentproof,2)"><i class="fa fa-list" aria-hidden="true"></i></button>&nbsp;
                         </span>

                         <button *ngIf="transaction.paymentModeId.id!=1 && (transaction.approvalStatus=='Pending' || transaction.approvalStatus=='Failed')" title="Update Approval Status" class="label label-info" (click)="update(content7,transaction.id,transaction.paymentModeId.id,transaction.approvalStatus,transaction.receivedAmount,transaction.paymentReferenceNo,transaction.totalPayableAmount)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;
                         <button *ngIf="transaction.paymentModeId.id==5 && transaction.approvalStatus=='Submitted' && transaction.chequeClaimStatus!='Success'" title="Update Claim Status" class="label label-info" (click)="updateClaim(content9,transaction.id,transaction.paymentReferenceNo,transaction.approvalStatus,transaction.chequeClaimStatus)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;

                         <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='Cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='Failure'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                      </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!-- END card --> 
                </div>
              </div>
              <!-- END CONTAINER FLUID --> 
            </div>
        
            <div *ngIf="accountStatus==2">
              <div style="display: none;">{{transactionfilter4Detail.responseMessage}}</div>
                    <div class="container Customers-top-notification card4view">
                    <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 315px; margin-left: 5px;">
                      <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                              <div _ngcontent-tgd-c17="" class="card-title">Total Transaction Amount (Success) </div>
                              <div _ngcontent-tgd-c17="" class="card-controls">
                                <ul _ngcontent-tgd-c17="">
                                  <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                              <h3 *ngIf="transactionfilter4Detail.amount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i> {{transactionfilter4Detail.amount | number : '1.2-2'}}</h3>
                              <h3 *ngIf="transactionfilter4Detail.amount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i>0</h3>
                              <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% </span> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                      <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                              <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Success) </div>
                              <div _ngcontent-tgd-c17="" class="card-controls">
                                <ul _ngcontent-tgd-c17="">
                                  <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                              <h3 *ngIf="transactionfilter4Detail.activeCount!=null" _ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter4Detail.activeCount}}</h3>
                              <h3 *ngIf="transactionfilter4Detail.activeCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">
                                0</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                      <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                              <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Failed) </div>
                              <div _ngcontent-tgd-c17="" class="card-controls">
                                <ul _ngcontent-tgd-c17="">
                                  <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                              <h3 *ngIf="transactionfilter4Detail.inactiveCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter4Detail.inactiveCount}}</h3>
                              <h3 *ngIf="transactionfilter4Detail.inactiveCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                              <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71%</span> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 300px; margin-left: -20px;">
                      <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                              <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Initiated)</div>
                              <div _ngcontent-tgd-c17="" class="card-controls">
                                <ul _ngcontent-tgd-c17="">
                                  <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                              <h3 *ngIf="transactionfilter4Detail.initiatedCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter4Detail.initiatedCount}}</h3>
                              <h3 *ngIf="transactionfilter4Detail.initiatedCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                              <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- START CONTAINER FLUID -->
                  <div class=" no-padding    container-fixed-lg bg-white">
                    <div class="container">
                      <select class="select-form-style" style="margin-left: 20px; margin-top: 10px;" [(ngModel)]="searchStatus" id="searchBy" (change)="callsearch($event)">  
                        <option value="1">Search By Initiated Date & Time</option>  
                        <option value="2">Search By Transaction Date & Time</option>  
                        <option value="3">Search By All</option>  
                        <option value="4">Search By Onboarded By</option> 
                    </select>  
                    <input *ngIf="searchStatus!=4"type="date" style="margin-left: 10px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
              <input *ngIf="searchStatus!=4"type="date" style="margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
    
              <input *ngIf="searchStatus==4" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
              <input *ngIf="searchStatus==4" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
                    <select class="select-form-style" style="margin-left: 10px;" [(ngModel)]="searchByCusorPart" id="accountstatus">
                      <option value="1" >Customer</option>  
                      <option value="2" >Partner</option>  
                  </select>
                  <select class="select-form-style" [(ngModel)]="searchByonboardedby" style="margin-left: 10px;" id="onboarded">
                    <option value="1">CinchFuel</option>  
                    <option value="2">Others</option>  
                </select>
                  <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
                  <button title="Refresh" *ngIf="refreshflag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
                  <button title="Excel Export" *ngIf="excelexportchecktransaction" (click)="exportToExcelTransaction()" title="Excel Export" style="margin-left: 10px; margin-top: 5px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
                <div *ngIf="errorFlag" style="color: red; margin-left: 20px;">{{errorMessage}}</div>
                      <!-- START card -->
                      <div class="card card-transparent">
                        <!-- <div class="card-header "> -->
                        <div class="card-body">
                          <div class="table-responsive">
                            <table id="excel-table" datatable  class="ch-table" >
                              <thead>
                                <tr> 
                                  <th style="text-align: center;">Sl.No.</th>
                                  <th style="text-align: left;">Transaction ID</th>
                                  <th style="text-align: left;">Partner Name</th>
                                  <th style="text-align: left;">Partner Mobile</th>
                                  <th style="text-align: right;">Transaction Amount</th>
                                  <th style="text-align: center;">Payment Status</th>
                                  <th style="text-align: center;">Payment Mode</th>
                                  <th style="text-align: left;">Initiated Date Time</th>
                                  <th style="text-align: left;">Transaction Date Time</th>
                                  <th style="text-align: center; width: 100px;" >Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let transaction of transactionfilter4Detail.response; let i=index">
                                  <td align="center" class="v-align-middle ">{{i + 1}}</td>
                                  <td *ngIf="transaction.paymentId!=null"align="left" class="v-align-middle">{{transaction.paymentId}}</td>
                                  <td *ngIf="transaction.paymentId==null"align="left" class="v-align-middle">N/A</td>
                                  <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</td>
                                  <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                                  <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">{{transaction.vehicleId.userId.mobileNumber}}</td>
                                  <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                                  <td align="right" *ngIf="transaction.totalPayableAmount!=null"class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.totalPayableAmount | number : '1.2-2'}}</td>
                                  <td align="right" *ngIf="transaction.totalPayableAmount==null"class="v-align-middle">N/A</td>
                                  <td align="center" *ngIf="transaction.paymentModeId.id==1">
                                    <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-inactive">{{transaction.paymentStatus}}</span> 
                                    <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</span> 
                                    <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' " style="color: red;">Failed</span> 
                                    <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                                    <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                                    <span  *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' " style="color: blue;">{{transaction.paymentStatus}}</span> 
                                  </td>
                                  <td align="center" *ngIf="transaction.paymentModeId.id!=1">
                                    <span *ngIf="transaction.approvalStatus==='Pending' || transaction.approvalStatus==null" class="v-align-middle ht-inactive">Pending</span> 
                                   <span *ngIf="transaction.approvalStatus==='Success' || transaction.approvalStatus==='Approved'" class="v-align-middle ht-active">Success</span> 
           
                                   <span *ngIf="transaction.approvalStatus==='Submitted' "class="v-align-middle ht-active">
                                     <span *ngIf="transaction.chequeClaimStatus=='Success'">Success</span> 
                                     <span *ngIf="transaction.chequeClaimStatus!='Success'"> {{transaction.chequeClaimStatus}}</span> 
                                   </span> 
           
                                   <span *ngIf="transaction.approvalStatus==='Failed' " style="color: red;">Failed</span> 
                                   <span *ngIfElse="transaction.approvalStatus!=null " style="color: orange;">{{transaction.approvalStatus}}</span> 
                                 </td>
                                  <td> {{transaction.paymentModeId.paymentMode}} </td>
                                  <td *ngIf="transaction.initiateDatetime!=null"align="left" class="v-align-middle">{{transaction.initiateDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                                  <td *ngIf="transaction.initiateDatetime==null"align="left" class="v-align-middle">N/A</td>
                                  <td *ngIf="transaction.transactionDatetime!=null"align="left" class="v-align-middle">{{transaction.transactionDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                                  <td *ngIf="transaction.transactionDatetime==null"align="left" class="v-align-middle">N/A</td>
                                  <td align="left">
                                    <button title="view" class="label label-info" (click)="view(content,transaction.id)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                                    <span *ngIf="transaction.paymentModeId.id==5"> 
                                      <button title="view Cheque and DD" class="label label-info" (click)="viewproof(transaction.id,transaction.orderProof,contentproof,1)"><i class="fa fa-money" aria-hidden="true"></i></button>&nbsp;
                                     </span>
            
                                     <span *ngIf="transaction.orderReceipt!=null"> 
                                      <button title="view Receipt" class="label label-info" (click)="viewproof(transaction.id,transaction.orderReceipt,contentproof,2)"><i class="fa fa-list" aria-hidden="true"></i></button>&nbsp;
                                     </span>
            
                                     <button *ngIf="transaction.paymentModeId.id!=1 && (transaction.approvalStatus=='Pending' || transaction.approvalStatus=='Failed')" title="Update Approval Status" class="label label-info" (click)="update(content7,transaction.id,transaction.paymentModeId.id,transaction.approvalStatus,transaction.receivedAmount,transaction.paymentReferenceNo,transaction.totalPayableAmount)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;
                                     <button *ngIf="transaction.paymentModeId.id==5 && transaction.approvalStatus=='Submitted' && transaction.chequeClaimStatus!='Success'" title="Update Claim Status" class="label label-info" (click)="updateClaim(content9,transaction.id,transaction.paymentReferenceNo,transaction.approvalStatus,transaction.chequeClaimStatus)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;

                                     <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                    <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                    <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                    <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                    <button *ngIf="transaction.paymentStatus==='cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                                    <button *ngIf="transaction.paymentStatus==='Cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                                    <button *ngIf="transaction.paymentStatus==='Failure'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <!-- END card --> 
                    </div>
                  </div>
                  <!-- END CONTAINER FLUID --> 
                </div>   
      </div>  
      <div *ngIf="!filterfoursearch">
        <div *ngIf="accountStatus==1">
          <div style="display: none;">{{transactionfilter4DetailNoRecord.responseMessage}}</div>
                <div class="container Customers-top-notification card4view">
                <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 315px; margin-left: 5px;">
                  <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                          <div _ngcontent-tgd-c17="" class="card-title">Total Transaction Amount (Success) </div>
                          <div _ngcontent-tgd-c17="" class="card-controls">
                            <ul _ngcontent-tgd-c17="">
                              <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="transactionfilter4DetailNoRecord.amount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i> {{transactionfilter4DetailNoRecord.amount| number : '1.2-2'}}</h3>
                          <h3 *ngIf="transactionfilter4DetailNoRecord.amount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i>0</h3>
                          <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% </span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                  <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                          <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Success) </div>
                          <div _ngcontent-tgd-c17="" class="card-controls">
                            <ul _ngcontent-tgd-c17="">
                              <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="transactionfilter4DetailNoRecord.activeCount!=null" _ngcontent-tgd-c17="" class="no-margin p-b-5">
                            {{transactionfilter4DetailNoRecord.activeCount}}</h3>
                          <h3 *ngIf="transactionfilter4DetailNoRecord.activeCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">
                            0</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                  <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                          <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Failed) </div>
                          <div _ngcontent-tgd-c17="" class="card-controls">
                            <ul _ngcontent-tgd-c17="">
                              <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="transactionfilter4DetailNoRecord.inactiveCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter4DetailNoRecord.inactiveCount}}</h3>
                          <h3 *ngIf="transactionfilter4DetailNoRecord.inactiveCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                          <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71%</span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 300px; margin-left: -20px;">
                  <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                          <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Initiated)</div>
                          <div _ngcontent-tgd-c17="" class="card-controls">
                            <ul _ngcontent-tgd-c17="">
                              <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="transactionfilter4DetailNoRecord.initiatedCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter4DetailNoRecord.initiatedCount}}</h3>
                          <h3 *ngIf="transactionfilter4DetailNoRecord.initiatedCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                          <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               
                
              </div>
               
              
              
              <!-- START CONTAINER FLUID --> 
              
              <!-- START CONTAINER FLUID -->
              <div class=" no-padding    container-fixed-lg bg-white">
                <div class="container">
                  <!-- START card -->
                  <select class="select-form-style" style="margin-left: 20px; margin-top: 10px;" [(ngModel)]="searchStatus" id="searchBy" (change)="callsearch($event)">  
                    <option value="1">Search By Initiated Date & Time</option>  
                    <option value="2">Search By Transaction Date & Time</option>  
                    <option value="3">Search By All</option>  
                    <option value="4">Search By Onboarded By</option> 
                </select>  
                <input *ngIf="searchStatus!=4"type="date" style="margin-left: 10px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
              <input *ngIf="searchStatus!=4"type="date" style="margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
    
              <input *ngIf="searchStatus==4" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
              <input *ngIf="searchStatus==4" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
                <select class="select-form-style"  style="margin-left: 10px;" [(ngModel)]="searchByCusorPart" id="accountstatus">
                  <option value="1">Customer</option>  
                  <option value="2" >Partner</option>  
              </select>
              <select class="select-form-style" [(ngModel)]="searchByonboardedby" style="margin-left: 10px;" id="onboarded">
                <option value="1">CinchFuel</option>  
                <option value="2">Others</option>  
            </select>
              <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
              <button title="Refresh" *ngIf="refreshflag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
              <button title="Excel Export" *ngIf="excelexportchecktransaction" (click)="exportToExcelTransaction()" title="Excel Export" style="margin-left: 10px; margin-top: 5px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
            <div *ngIf="errorFlag" style="color: red; margin-left: 20px;">{{errorMessage}}</div>
                  <div class="card card-transparent">
                    <div class="card-body">
                      <div class="table-responsive">
                        <table id="excel-table" datatable  class="ch-table" >
                          <thead>
                            <tr> 
                              <th style="text-align: center;">Sl.No.</th>
                              <th style="text-align: left;">Order ID</th>
                              <!-- <th style="text-align: left;">Transaction ID</th> -->
                              <th style="text-align: left;">Customer Info</th>
                              <th style="text-align: left;">Delivery Agent Info</th>
                              <th style="text-align: left;">Fuel</th>
                              <!-- <th style="text-align: center;">Quantity in Ltrs</th> -->
                              <th style="text-align: right;">Transaction Amount</th>
                              <th style="text-align: center;">Payment Status</th>
                              <th style="text-align: center;">Payment Mode</th>
                              <th style="text-align: left;">Initiated Date Time</th>
                              <th style="text-align: left;">Transaction Date Time</th>
                              <th style="text-align: center; width: 100px;" >Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let transaction of transactionfilter4DetailNoRecord.response; let i=index">
                              <td align="center" class="v-align-middle "><p>{{i + 1}}</p></td>
                      <td *ngIf="transaction.orderId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 10px;" ><b>{{transaction.orderId}}</b></span><br>
                        <span *ngIf="transaction.transactionId!=null" style="font-size: 10px;"><b>Txn Id :</b>{{transaction.transactionId}}</span>
                        <span *ngIf="transaction.transactionId==null" style="font-size: 10px;"><b>Txn Id :</b>N/A</span>
                      </td>
                      <td *ngIf="transaction.orderId==null"align="left" class="v-align-middle"><p>N/A</p></td>

                      <!-- <td *ngIf="transaction.transactionId!=null"align="left" class="v-align-middle"><p>{{transaction.transactionId}}</p></td>
                      <td *ngIf="transaction.transactionId==null"align="left" class="v-align-middle"><p>N/A</p></td> -->

                      <td *ngIf="transaction.customerId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 12px;">{{transaction.customerId.firstName}} {{transaction.customerId.lastName}}</span><br>
                        <span style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.customerId.mobileNumber}}</span>
                        <!-- <p style="font-size: 12px;">{{transaction.customerId.firstName}} {{transaction.customerId.lastName}} </p>
                        <p style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.customerId.mobileNumber}}</p> -->
                      </td>
                      <td *ngIf="transaction.customerId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                      <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 12px;">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</span><br>
                        <span style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.vehicleId.userId.mobileNumber}}</span>
                          <!-- <p style="font-size: 12px;">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</p>
                          <p style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.vehicleId.userId.mobileNumber}}</p> -->
                        </td>
                        <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                        <td *ngIf="transaction.fuelType!=null" align="left" class="v-align-middle">
                          <span style="font-size: 10px;">{{transaction.fuelType}}</span><br>
                          <span style="font-size: 10px;"><b>Qtn : </b> {{transaction.quantity}}</span>
                          <!-- <p style="font-size: 10px;">{{transaction.fuelType}}</p>  
                          <p style="font-size: 10px;"><b>Qtn : </b> {{transaction.quantity}}</p> -->
                          </td>
                      <td *ngIf="transaction.fuelType==null"align="left" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="transaction.quantity!=null"align="center" class="v-align-middle">{{transaction.quantity}}</td>
                      <td *ngIf="transaction.quantity==null"align="center" class="v-align-middle">N/A</td> -->
                      <td align="right" *ngIf="transaction.totalPayableAmount!=null"class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.totalPayableAmount | number : '1.2-2'}}</td>
                      <td align="right" *ngIf="transaction.totalPayableAmount==null"class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="transaction.paymentModeId.id==1">
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-inactive">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' " style="color: red;">Failed</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                        <span  *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' " style="color: blue;">{{transaction.paymentStatus}}</span> 
                      </td>
                      <td align="center" *ngIf="transaction.paymentModeId.id!=1">
                        <span *ngIf="transaction.approvalStatus==='Pending' || transaction.approvalStatus==null" class="v-align-middle ht-inactive">Pending</span> 
                       <span *ngIf="transaction.approvalStatus==='Success' || transaction.approvalStatus==='Approved'" class="v-align-middle ht-active">Success</span> 

                       <span *ngIf="transaction.approvalStatus==='Submitted' "class="v-align-middle ht-active">
                         <span *ngIf="transaction.chequeClaimStatus=='Success'">Success</span> 
                         <span *ngIf="transaction.chequeClaimStatus!='Success'"> {{transaction.chequeClaimStatus}}</span> 
                       </span> 

                       <span *ngIf="transaction.approvalStatus==='Failed' " style="color: red;">Failed</span> 
                       <span *ngIfElse="transaction.approvalStatus!=null " style="color: orange;">{{transaction.approvalStatus}}</span> 
                     </td>

                      <td> {{transaction.paymentModeId.paymentMode}} </td>
                      <td *ngIf="transaction.initiatedDatetime!=null"align="left" class="v-align-middle">{{transaction.initiatedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.initiatedDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paymentDatetime!=null"align="left" class="v-align-middle">{{transaction.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.paymentDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td align="left">
                        <button title="view" class="label label-info" (click)="view(content,transaction.id)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                        <span *ngIf="transaction.paymentModeId.id==5"> 
                          <button title="view Cheque and DD" class="label label-info" (click)="viewproof(transaction.id,transaction.orderProof,contentproof,1)"><i class="fa fa-money" aria-hidden="true"></i></button>&nbsp;
                         </span>

                         <span *ngIf="transaction.orderReceipt!=null"> 
                          <button title="view Receipt" class="label label-info" (click)="viewproof(transaction.id,transaction.orderReceipt,contentproof,2)"><i class="fa fa-list" aria-hidden="true"></i></button>&nbsp;
                         </span>

                         <button *ngIf="transaction.paymentModeId.id!=1 && (transaction.approvalStatus=='Pending' || transaction.approvalStatus=='Failed')" title="Update Approval Status" class="label label-info" (click)="update(content7,transaction.id,transaction.paymentModeId.id,transaction.approvalStatus,transaction.receivedAmount,transaction.paymentReferenceNo,transaction.totalPayableAmount)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;
                         <button *ngIf="transaction.paymentModeId.id==5 && transaction.approvalStatus=='Submitted' && transaction.chequeClaimStatus!='Success'" title="Update Claim Status" class="label label-info" (click)="updateClaim(content9,transaction.id,transaction.paymentReferenceNo,transaction.approvalStatus,transaction.chequeClaimStatus)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;

                         <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='Cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='Failure'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                      </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!-- END card --> 
                </div>
              </div>
              <!-- END CONTAINER FLUID --> 
            </div>
        
            <div *ngIf="accountStatus==2">
              <div style="display: none;">{{transactionfilter4DetailNoRecord.responseMessage}}</div>
                    <div class="container Customers-top-notification card4view">
                    <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 315px; margin-left: 5px;">
                      <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                              <div _ngcontent-tgd-c17="" class="card-title">Total Transaction Amount (Success) </div>
                              <div _ngcontent-tgd-c17="" class="card-controls">
                                <ul _ngcontent-tgd-c17="">
                                  <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                              <h3 *ngIf="transactionfilter4DetailNoRecord.amount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i> {{transactionfilter4DetailNoRecord.amount | number : '1.2-2'}}</h3>
                              <h3 *ngIf="transactionfilter4DetailNoRecord.amount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i>0</h3>
                              <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% </span> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                      <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                              <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Success) </div>
                              <div _ngcontent-tgd-c17="" class="card-controls">
                                <ul _ngcontent-tgd-c17="">
                                  <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                              <h3 *ngIf="transactionfilter4DetailNoRecord.activeCount!=null" _ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter4DetailNoRecord.activeCount}}</h3>
                              <h3 *ngIf="transactionfilter4DetailNoRecord.activeCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">
                                0</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                      <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                              <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Failed) </div>
                              <div _ngcontent-tgd-c17="" class="card-controls">
                                <ul _ngcontent-tgd-c17="">
                                  <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                              <h3 *ngIf="transactionfilter4DetailNoRecord.inactiveCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter4DetailNoRecord.inactiveCount}}</h3>
                              <h3 *ngIf="transactionfilter4DetailNoRecord.inactiveCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                              <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71%</span> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 300px; margin-left: -20px;">
                      <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                              <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Initiated)</div>
                              <div _ngcontent-tgd-c17="" class="card-controls">
                                <ul _ngcontent-tgd-c17="">
                                  <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                              <h3 *ngIf="transactionfilter4DetailNoRecord.initiatedCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter4DetailNoRecord.initiatedCount}}</h3>
                              <h3 *ngIf="transactionfilter4DetailNoRecord.initiatedCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                              <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- START CONTAINER FLUID -->
                  <div class=" no-padding    container-fixed-lg bg-white">
                    <div class="container">
                      <select class="select-form-style" style="margin-left: 20px; margin-top: 10px;" [(ngModel)]="searchStatus" id="searchBy" (change)="callsearch($event)">  
                        <option value="1">Search By Initiated Date & Time</option>  
                        <option value="2">Search By Transaction Date & Time</option>  
                        <option value="3">Search By All</option>  
                        <option value="4">Search By Onboarded By</option> 
                    </select>  
                    <input *ngIf="searchStatus!=4"type="date" style="margin-left: 10px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
              <input *ngIf="searchStatus!=4"type="date" style="margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
    
              <input *ngIf="searchStatus==4" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
              <input *ngIf="searchStatus==4" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
                    <select class="select-form-style" style="margin-left: 10px;" [(ngModel)]="searchByCusorPart" id="accountstatus">
                      <option value="1" >Customer</option>  
                      <option value="2" >Partner</option>  
                  </select>
                  <select class="select-form-style" [(ngModel)]="searchByonboardedby" style="margin-left: 10px;" id="onboarded">
                    <option value="1">CinchFuel</option>  
                    <option value="2">Others</option>  
                </select>
                  <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
                  <button title="Refresh" *ngIf="refreshflag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
                  <button title="Excel Export" *ngIf="excelexportchecktransaction" (click)="exportToExcelTransaction()" title="Excel Export" style="margin-left: 10px; margin-top: 5px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
                <div *ngIf="errorFlag" style="color: red; margin-left: 20px;">{{errorMessage}}</div>
                      <!-- START card -->
                      <div class="card card-transparent">
                        <!-- <div class="card-header "> -->
                        <div class="card-body">
                          <div class="table-responsive">
                            <table id="excel-table" datatable  class="ch-table" >
                              <thead>
                                <tr> 
                                  <th style="text-align: center;">Sl.No.</th>
                                  <th style="text-align: left;">Transaction ID</th>
                                  <th style="text-align: left;">Partner Name</th>
                                  <th style="text-align: left;">Partner Mobile</th>
                                  <th style="text-align: right;">Transaction Amount</th>
                                  <th style="text-align: center;">Payment Status</th>
                                  <th style="text-align: center;">Payment Mode</th>
                                  <th style="text-align: left;">Initiated Date Time</th>
                                  <th style="text-align: left;">Transaction Date Time</th>
                                  <th style="text-align: center; width: 100px;" >Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let transaction of transactionfilter4DetailNoRecord.response; let i=index">
                                  <td align="center" class="v-align-middle ">{{i + 1}}</td>
                                  <td *ngIf="transaction.paymentId!=null"align="left" class="v-align-middle">{{transaction.paymentId}}</td>
                                  <td *ngIf="transaction.paymentId==null"align="left" class="v-align-middle">N/A</td>
                                  <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</td>
                                  <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                                  <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">{{transaction.vehicleId.userId.mobileNumber}}</td>
                                  <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                                  <td align="right" *ngIf="transaction.totalPayableAmount!=null"class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.totalPayableAmount | number : '1.2-2'}}</td>
                                  <td align="right" *ngIf="transaction.totalPayableAmount==null"class="v-align-middle">N/A</td>
                                  <td align="center" *ngIf="transaction.paymentModeId.id==1">
                                    <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-inactive">{{transaction.paymentStatus}}</span> 
                                    <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</span> 
                                    <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' " style="color: red;">Failed</span> 
                                    <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                                    <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                                    <span  *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' " style="color: blue;">{{transaction.paymentStatus}}</span> 
                                  </td>
                                  <td align="center" *ngIf="transaction.paymentModeId.id!=1">
                                    <span *ngIf="transaction.approvalStatus==='Pending' || transaction.approvalStatus==null" class="v-align-middle ht-inactive">Pending</span> 
                                   <span *ngIf="transaction.approvalStatus==='Success' || transaction.approvalStatus==='Approved'" class="v-align-middle ht-active">Success</span> 
           
                                   <span *ngIf="transaction.approvalStatus==='Submitted' "class="v-align-middle ht-active">
                                     <span *ngIf="transaction.chequeClaimStatus=='Success'">Success</span> 
                                     <span *ngIf="transaction.chequeClaimStatus!='Success'"> {{transaction.chequeClaimStatus}}</span> 
                                   </span> 
           
                                   <span *ngIf="transaction.approvalStatus==='Failed' " style="color: red;">Failed</span> 
                                   <span *ngIfElse="transaction.approvalStatus!=null " style="color: orange;">{{transaction.approvalStatus}}</span> 
                                 </td>
                                  <td> {{transaction.paymentModeId.paymentMode}} </td>
                                  <td *ngIf="transaction.initiateDatetime!=null"align="left" class="v-align-middle">{{transaction.initiateDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                                  <td *ngIf="transaction.initiateDatetime==null"align="left" class="v-align-middle">N/A</td>
                                  <td *ngIf="transaction.transactionDatetime!=null"align="left" class="v-align-middle">{{transaction.transactionDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                                  <td *ngIf="transaction.transactionDatetime==null"align="left" class="v-align-middle">N/A</td>
                                  <td align="left">
                                    <button title="view" class="label label-info" (click)="view(content,transaction.id)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                                    <span *ngIf="transaction.paymentModeId.id==5"> 
                                      <button title="view Cheque and DD" class="label label-info" (click)="viewproof(transaction.id,transaction.orderProof,contentproof,1)"><i class="fa fa-money" aria-hidden="true"></i></button>&nbsp;
                                     </span>
            
                                     <span *ngIf="transaction.orderReceipt!=null"> 
                                      <button title="view Receipt" class="label label-info" (click)="viewproof(transaction.id,transaction.orderReceipt,contentproof,2)"><i class="fa fa-list" aria-hidden="true"></i></button>&nbsp;
                                     </span>
            
                                     <button *ngIf="transaction.paymentModeId.id!=1 && (transaction.approvalStatus=='Pending' || transaction.approvalStatus=='Failed')" title="Update Approval Status" class="label label-info" (click)="update(content7,transaction.id,transaction.paymentModeId.id,transaction.approvalStatus,transaction.receivedAmount,transaction.paymentReferenceNo,transaction.totalPayableAmount)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;
                                     <button *ngIf="transaction.paymentModeId.id==5 && transaction.approvalStatus=='Submitted' && transaction.chequeClaimStatus!='Success'" title="Update Claim Status" class="label label-info" (click)="updateClaim(content9,transaction.id,transaction.paymentReferenceNo,transaction.approvalStatus,transaction.chequeClaimStatus)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;

                                     <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                    <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                    <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                    <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                    <button *ngIf="transaction.paymentStatus==='cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                                    <button *ngIf="transaction.paymentStatus==='Cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                                    <button *ngIf="transaction.paymentStatus==='Failure'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <!-- END card --> 
                    </div>
                  </div>
                  <!-- END CONTAINER FLUID --> 
                </div>   
      </div>
      </div>

      <!-- Filter 5  -->
    <div *ngIf="filterTransaction5">   
      <div *ngIf="filterfivesearch">
        <div *ngIf="accountStatus==1">
          <div style="display: none;">{{transactionfilter5Detail.responseMessage}}</div>
                <div class="container Customers-top-notification card4view">
                <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 315px; margin-left: 5px;">
                  <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                          <div _ngcontent-tgd-c17="" class="card-title">Total Transaction Amount (Success) </div>
                          <div _ngcontent-tgd-c17="" class="card-controls">
                            <ul _ngcontent-tgd-c17="">
                              <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="transactionfilter5Detail.amount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i> {{transactionfilter5Detail.amount| number : '1.2-2'}}</h3>
                          <h3 *ngIf="transactionfilter5Detail.amount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i>0</h3>
                          <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% </span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                  <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                          <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Success) </div>
                          <div _ngcontent-tgd-c17="" class="card-controls">
                            <ul _ngcontent-tgd-c17="">
                              <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="transactionfilter5Detail.activeCount!=null" _ngcontent-tgd-c17="" class="no-margin p-b-5">
                            {{transactionfilter5Detail.activeCount}}</h3>
                          <h3 *ngIf="transactionfilter5Detail.activeCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">
                            0</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                  <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                          <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Failed) </div>
                          <div _ngcontent-tgd-c17="" class="card-controls">
                            <ul _ngcontent-tgd-c17="">
                              <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="transactionfilter5Detail.inactiveCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter5Detail.inactiveCount}}</h3>
                          <h3 *ngIf="transactionfilter5Detail.inactiveCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                          <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71%</span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 300px; margin-left: -20px;">
                  <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                          <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Initiated)</div>
                          <div _ngcontent-tgd-c17="" class="card-controls">
                            <ul _ngcontent-tgd-c17="">
                              <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="transactionfilter5Detail.initiatedCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter5Detail.initiatedCount}}</h3>
                          <h3 *ngIf="transactionfilter5Detail.initiatedCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                          <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               
                
              </div>
               
              
              
              <!-- START CONTAINER FLUID --> 
              
              <!-- START CONTAINER FLUID -->
              <div class=" no-padding    container-fixed-lg bg-white">
                <div class="container">
                  <!-- START card -->
                  <select class="select-form-style" style="margin-left: 20px; margin-top: 10px;" [(ngModel)]="searchStatus" id="searchBy" (change)="callsearch($event)">  
                    <option value="1">Search By Initiated Date & Time</option>  
                    <option value="2">Search By Transaction Date & Time</option>  
                    <option value="3">Search By All</option>  
                    <option value="4">Search By Onboarded By</option> 
                </select>  
                <input *ngIf="searchStatus!=4"type="date" style="margin-left: 10px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
              <input *ngIf="searchStatus!=4"type="date" style="margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
    
              <input *ngIf="searchStatus==4" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
              <input *ngIf="searchStatus==4" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
                <select class="select-form-style"  style="margin-left: 10px;" [(ngModel)]="searchByCusorPart" id="accountstatus">
                  <option value="1">Customer</option>  
                  <option value="2" >Partner</option>  
              </select>
              <select class="select-form-style" [(ngModel)]="searchByonboardedby" style="margin-left: 10px;" id="onboarded">
                <option value="1">CinchFuel</option>  
                <option value="2">Others</option>  
            </select>
              <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
              <button title="Refresh" *ngIf="refreshflag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
              <button title="Excel Export" *ngIf="excelexportchecktransaction" (click)="exportToExcelTransaction()" title="Excel Export" style="margin-left: 10px; margin-top: 5px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
            <div *ngIf="errorFlag" style="color: red; margin-left: 20px;">{{errorMessage}}</div>
                  <div class="card card-transparent">
                    <div class="card-body">
                      <div class="table-responsive">
                        <table id="excel-table" datatable  class="ch-table" >
                          <thead>
                            <tr> 
                              <th style="text-align: center;">Sl.No.</th>
                              <th style="text-align: left;">Order ID</th>
                              <!-- <th style="text-align: left;">Transaction ID</th> -->
                              <th style="text-align: left;">Customer Info</th>
                              <th style="text-align: left;">Delivery Agent Info</th>
                              <th style="text-align: left;">Fuel</th>
                              <!-- <th style="text-align: center;">Quantity in Ltrs</th> -->
                              <th style="text-align: right;">Transaction Amount</th>
                              <th style="text-align: center;">Payment Status</th>
                              <th style="text-align: center;">Payment Mode</th>
                              <th style="text-align: left;">Initiated Date Time</th>
                              <th style="text-align: left;">Transaction Date Time</th>
                              <th style="text-align: center; width: 100px;" >Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let transaction of transactionfilter5Detail.response; let i=index">
                              <td align="center" class="v-align-middle "><p>{{i + 1}}</p></td>
                      <td *ngIf="transaction.orderId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 10px;" ><b>{{transaction.orderId}}</b></span><br>
                        <span *ngIf="transaction.transactionId!=null" style="font-size: 10px;"><b>Txn Id :</b>{{transaction.transactionId}}</span>
                        <span *ngIf="transaction.transactionId==null" style="font-size: 10px;"><b>Txn Id :</b>N/A</span>
                      </td>
                      <td *ngIf="transaction.orderId==null"align="left" class="v-align-middle"><p>N/A</p></td>

                      <!-- <td *ngIf="transaction.transactionId!=null"align="left" class="v-align-middle"><p>{{transaction.transactionId}}</p></td>
                      <td *ngIf="transaction.transactionId==null"align="left" class="v-align-middle"><p>N/A</p></td> -->

                      <td *ngIf="transaction.customerId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 12px;">{{transaction.customerId.firstName}} {{transaction.customerId.lastName}}</span><br>
                        <span style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.customerId.mobileNumber}}</span>
                        <!-- <p style="font-size: 12px;">{{transaction.customerId.firstName}} {{transaction.customerId.lastName}} </p>
                        <p style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.customerId.mobileNumber}}</p> -->
                      </td>
                      <td *ngIf="transaction.customerId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                      <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 12px;">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</span><br>
                        <span style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.vehicleId.userId.mobileNumber}}</span>
                          <!-- <p style="font-size: 12px;">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</p>
                          <p style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.vehicleId.userId.mobileNumber}}</p> -->
                        </td>
                        <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                        <td *ngIf="transaction.fuelType!=null" align="left" class="v-align-middle">
                          <span style="font-size: 10px;">{{transaction.fuelType}}</span><br>
                          <span style="font-size: 10px;"><b>Qtn : </b> {{transaction.quantity}}</span>
                          <!-- <p style="font-size: 10px;">{{transaction.fuelType}}</p>  
                          <p style="font-size: 10px;"><b>Qtn : </b> {{transaction.quantity}}</p> -->
                          </td>
                      <td *ngIf="transaction.fuelType==null"align="left" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="transaction.quantity!=null"align="center" class="v-align-middle">{{transaction.quantity}}</td>
                      <td *ngIf="transaction.quantity==null"align="center" class="v-align-middle">N/A</td> -->
                      <td align="right" *ngIf="transaction.totalPayableAmount!=null"class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.totalPayableAmount | number : '1.2-2'}}</td>
                      <td align="right" *ngIf="transaction.totalPayableAmount==null"class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="transaction.paymentModeId.id==1">
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-inactive">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' " style="color: red;">Failed</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                        <span  *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' " style="color: blue;">{{transaction.paymentStatus}}</span> 
                      </td>
                      <td align="center" *ngIf="transaction.paymentModeId.id!=1">
                        <span *ngIf="transaction.approvalStatus==='Pending' || transaction.approvalStatus==null" class="v-align-middle ht-inactive">Pending</span> 
                       <span *ngIf="transaction.approvalStatus==='Success' || transaction.approvalStatus==='Approved'" class="v-align-middle ht-active">Success</span> 

                       <span *ngIf="transaction.approvalStatus==='Submitted' "class="v-align-middle ht-active">
                         <span *ngIf="transaction.chequeClaimStatus=='Success'">Success</span> 
                         <span *ngIf="transaction.chequeClaimStatus!='Success'"> {{transaction.chequeClaimStatus}}</span> 
                       </span> 

                       <span *ngIf="transaction.approvalStatus==='Failed' " style="color: red;">Failed</span> 
                       <span *ngIfElse="transaction.approvalStatus!=null " style="color: orange;">{{transaction.approvalStatus}}</span> 
                     </td>
                      <td> {{transaction.paymentModeId.paymentMode}} </td>
                      <td *ngIf="transaction.initiatedDatetime!=null"align="left" class="v-align-middle">{{transaction.initiatedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.initiatedDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paymentDatetime!=null"align="left" class="v-align-middle">{{transaction.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.paymentDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td align="left">
                        <button title="view" class="label label-info" (click)="view(content,transaction.id)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                        <span *ngIf="transaction.paymentModeId.id==5"> 
                          <button title="view Cheque and DD" class="label label-info" (click)="viewproof(transaction.id,transaction.orderProof,contentproof,1)"><i class="fa fa-money" aria-hidden="true"></i></button>&nbsp;
                         </span>

                         <span *ngIf="transaction.orderReceipt!=null"> 
                          <button title="view Receipt" class="label label-info" (click)="viewproof(transaction.id,transaction.orderReceipt,contentproof,2)"><i class="fa fa-list" aria-hidden="true"></i></button>&nbsp;
                         </span>

                         <button *ngIf="transaction.paymentModeId.id!=1 && (transaction.approvalStatus=='Pending' || transaction.approvalStatus=='Failed')" title="Update Approval Status" class="label label-info" (click)="update(content7,transaction.id,transaction.paymentModeId.id,transaction.approvalStatus,transaction.receivedAmount,transaction.paymentReferenceNo,transaction.totalPayableAmount)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;
                         <button *ngIf="transaction.paymentModeId.id==5 && transaction.approvalStatus=='Submitted' && transaction.chequeClaimStatus!='Success'" title="Update Claim Status" class="label label-info" (click)="updateClaim(content9,transaction.id,transaction.paymentReferenceNo,transaction.approvalStatus,transaction.chequeClaimStatus)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;

                         <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='Cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='Failure'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                      </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!-- END card --> 
                </div>
              </div>
              <!-- END CONTAINER FLUID --> 
            </div>
        
            <div *ngIf="accountStatus==2">
              <div style="display: none;">{{transactionfilter5Detail.responseMessage}}</div>
                    <div class="container Customers-top-notification card4view">
                    <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 315px; margin-left: 5px;">
                      <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                              <div _ngcontent-tgd-c17="" class="card-title">Total Transaction Amount (Success) </div>
                              <div _ngcontent-tgd-c17="" class="card-controls">
                                <ul _ngcontent-tgd-c17="">
                                  <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                              <h3 *ngIf="transactionfilter5Detail.amount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i> {{transactionfilter5Detail.amount | number : '1.2-2'}}</h3>
                              <h3 *ngIf="transactionfilter5Detail.amount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i>0</h3>
                              <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% </span> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                      <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                              <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Success) </div>
                              <div _ngcontent-tgd-c17="" class="card-controls">
                                <ul _ngcontent-tgd-c17="">
                                  <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                              <h3 *ngIf="transactionfilter5Detail.activeCount!=null" _ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter5Detail.activeCount}}</h3>
                              <h3 *ngIf="transactionfilter5Detail.activeCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">
                                0</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                      <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                              <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Failed) </div>
                              <div _ngcontent-tgd-c17="" class="card-controls">
                                <ul _ngcontent-tgd-c17="">
                                  <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                              <h3 *ngIf="transactionfilter5Detail.inactiveCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter5Detail.inactiveCount}}</h3>
                              <h3 *ngIf="transactionfilter5Detail.inactiveCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                              <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71%</span> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 300px; margin-left: -20px;">
                      <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                              <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Initiated)</div>
                              <div _ngcontent-tgd-c17="" class="card-controls">
                                <ul _ngcontent-tgd-c17="">
                                  <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                              <h3 *ngIf="transactionfilter5Detail.initiatedCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter5Detail.initiatedCount}}</h3>
                              <h3 *ngIf="transactionfilter5Detail.initiatedCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                              <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- START CONTAINER FLUID -->
                  <div class=" no-padding    container-fixed-lg bg-white">
                    <div class="container">
                      <select class="select-form-style" style="margin-left: 20px; margin-top: 10px;" [(ngModel)]="searchStatus" id="searchBy" (change)="callsearch($event)">  
                        <option value="1">Search By Initiated Date & Time</option>  
                        <option value="2">Search By Transaction Date & Time</option>  
                        <option value="3">Search By All</option>  
                        <option value="4">Search By Onboarded By</option> 
                    </select>  
                    <input *ngIf="searchStatus!=4"type="date" style="margin-left: 10px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
              <input *ngIf="searchStatus!=4"type="date" style="margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
    
              <input *ngIf="searchStatus==4" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
              <input *ngIf="searchStatus==4" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
                    <select class="select-form-style" style="margin-left: 10px;" [(ngModel)]="searchByCusorPart" id="accountstatus">
                      <option value="1" >Customer</option>  
                      <option value="2" >Partner</option>  
                  </select>
                  <select class="select-form-style" [(ngModel)]="searchByonboardedby" style="margin-left: 10px;" id="onboarded">
                    <option value="1">CinchFuel</option>  
                    <option value="2">Others</option>  
                </select>
                  <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
                  <button title="Refresh" *ngIf="refreshflag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
                  <button title="Excel Export" *ngIf="excelexportchecktransaction" (click)="exportToExcelTransaction()" title="Excel Export" style="margin-left: 10px; margin-top: 5px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
                <div *ngIf="errorFlag" style="color: red; margin-left: 20px;">{{errorMessage}}</div>
                      <!-- START card -->
                      <div class="card card-transparent">
                        <!-- <div class="card-header "> -->
                        <div class="card-body">
                          <div class="table-responsive">
                            <table id="excel-table" datatable  class="ch-table" >
                              <thead>
                                <tr> 
                                  <th style="text-align: center;">Sl.No.</th>
                                  <th style="text-align: left;">Transaction ID</th>
                                  <th style="text-align: left;">Partner Name</th>
                                  <th style="text-align: left;">Partner Mobile</th>
                                  <th style="text-align: right;">Transaction Amount</th>
                                  <th style="text-align: center;">Payment Status</th>
                                  <th style="text-align: center;">Payment Mode</th>
                                  <th style="text-align: left;">Initiated Date Time</th>
                                  <th style="text-align: left;">Transaction Date Time</th>
                                  <th style="text-align: center; width: 100px;" >Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let transaction of transactionfilter5Detail.response; let i=index">
                                  <td align="center" class="v-align-middle ">{{i + 1}}</td>
                                  <td *ngIf="transaction.paymentId!=null"align="left" class="v-align-middle">{{transaction.paymentId}}</td>
                                  <td *ngIf="transaction.paymentId==null"align="left" class="v-align-middle">N/A</td>
                                  <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</td>
                                  <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                                  <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">{{transaction.vehicleId.userId.mobileNumber}}</td>
                                  <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                                  <td align="right" *ngIf="transaction.totalPayableAmount!=null"class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.totalPayableAmount | number : '1.2-2'}}</td>
                                  <td align="right" *ngIf="transaction.totalPayableAmount==null"class="v-align-middle">N/A</td>
                                  <td align="center" *ngIf="transaction.paymentModeId.id==1">
                                    <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-inactive">{{transaction.paymentStatus}}</span> 
                                    <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</span> 
                                    <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' " style="color: red;">Failed</span> 
                                    <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                                    <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                                    <span  *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' " style="color: blue;">{{transaction.paymentStatus}}</span> 
                                  </td>
                                  <td align="center" *ngIf="transaction.paymentModeId.id!=1">
                                    <span *ngIf="transaction.approvalStatus==='Pending' || transaction.approvalStatus==null" class="v-align-middle ht-inactive">Pending</span> 
                                   <span *ngIf="transaction.approvalStatus==='Success' || transaction.approvalStatus==='Approved'" class="v-align-middle ht-active">Success</span> 
           
                                   <span *ngIf="transaction.approvalStatus==='Submitted' "class="v-align-middle ht-active">
                                     <span *ngIf="transaction.chequeClaimStatus=='Success'">Success</span> 
                                     <span *ngIf="transaction.chequeClaimStatus!='Success'"> {{transaction.chequeClaimStatus}}</span> 
                                   </span> 
           
                                   <span *ngIf="transaction.approvalStatus==='Failed' " style="color: red;">Failed</span> 
                                   <span *ngIfElse="transaction.approvalStatus!=null " style="color: orange;">{{transaction.approvalStatus}}</span> 
                                 </td>
                                  <td> {{transaction.paymentModeId.paymentMode}} </td>
                                  <td *ngIf="transaction.initiateDatetime!=null"align="left" class="v-align-middle">{{transaction.initiateDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                                  <td *ngIf="transaction.initiateDatetime==null"align="left" class="v-align-middle">N/A</td>
                                  <td *ngIf="transaction.transactionDatetime!=null"align="left" class="v-align-middle">{{transaction.transactionDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                                  <td *ngIf="transaction.transactionDatetime==null"align="left" class="v-align-middle">N/A</td>
                                  <td align="left">
                                    <button title="view" class="label label-info" (click)="view(content,transaction.id)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                                    <span *ngIf="transaction.paymentModeId.id==5"> 
                                      <button title="view Cheque and DD" class="label label-info" (click)="viewproof(transaction.id,transaction.orderProof,contentproof,1)"><i class="fa fa-money" aria-hidden="true"></i></button>&nbsp;
                                     </span>
            
                                     <span *ngIf="transaction.orderReceipt!=null"> 
                                      <button title="view Receipt" class="label label-info" (click)="viewproof(transaction.id,transaction.orderReceipt,contentproof,2)"><i class="fa fa-list" aria-hidden="true"></i></button>&nbsp;
                                     </span>
            
                                     <button *ngIf="transaction.paymentModeId.id!=1 && (transaction.approvalStatus=='Pending' || transaction.approvalStatus=='Failed')" title="Update Approval Status" class="label label-info" (click)="update(content7,transaction.id,transaction.paymentModeId.id,transaction.approvalStatus,transaction.receivedAmount,transaction.paymentReferenceNo,transaction.totalPayableAmount)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;
                                     <button *ngIf="transaction.paymentModeId.id==5 && transaction.approvalStatus=='Submitted' && transaction.chequeClaimStatus!='Success'" title="Update Claim Status" class="label label-info" (click)="updateClaim(content9,transaction.id,transaction.paymentReferenceNo,transaction.approvalStatus,transaction.chequeClaimStatus)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;

                                     <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                    <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                    <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                    <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                    <button *ngIf="transaction.paymentStatus==='cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                                    <button *ngIf="transaction.paymentStatus==='Cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                                    <button *ngIf="transaction.paymentStatus==='Failure'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <!-- END card --> 
                    </div>
                  </div>
                  <!-- END CONTAINER FLUID --> 
                </div>   
      </div>  
      <div *ngIf="!filterfivesearch">
        <div *ngIf="accountStatus==1">
          <div style="display: none;">{{transactionfilter5DetailNoRecord.responseMessage}}</div>
                <div class="container Customers-top-notification card4view">
                <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 315px; margin-left: 5px;">
                  <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                          <div _ngcontent-tgd-c17="" class="card-title">Total Transaction Amount (Success) </div>
                          <div _ngcontent-tgd-c17="" class="card-controls">
                            <ul _ngcontent-tgd-c17="">
                              <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="transactionfilter5DetailNoRecord.amount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i> {{transactionfilter5DetailNoRecord.amount| number : '1.2-2'}}</h3>
                          <h3 *ngIf="transactionfilter5DetailNoRecord.amount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i>0</h3>
                          <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% </span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                  <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                          <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Success) </div>
                          <div _ngcontent-tgd-c17="" class="card-controls">
                            <ul _ngcontent-tgd-c17="">
                              <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="transactionfilter5DetailNoRecord.activeCount!=null" _ngcontent-tgd-c17="" class="no-margin p-b-5">
                            {{transactionfilter5DetailNoRecord.activeCount}}</h3>
                          <h3 *ngIf="transactionfilter5DetailNoRecord.activeCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">
                            0</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                  <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                          <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Failed) </div>
                          <div _ngcontent-tgd-c17="" class="card-controls">
                            <ul _ngcontent-tgd-c17="">
                              <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="transactionfilter5DetailNoRecord.inactiveCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter5DetailNoRecord.inactiveCount}}</h3>
                          <h3 *ngIf="transactionfilter5DetailNoRecord.inactiveCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                          <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71%</span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 300px; margin-left: -20px;">
                  <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                          <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Initiated)</div>
                          <div _ngcontent-tgd-c17="" class="card-controls">
                            <ul _ngcontent-tgd-c17="">
                              <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="row-xs-height">
                      <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                        <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                          <h3 *ngIf="transactionfilter5DetailNoRecord.initiatedCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter5DetailNoRecord.initiatedCount}}</h3>
                          <h3 *ngIf="transactionfilter5DetailNoRecord.initiatedCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                          <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               
                
              </div>
               
              
              
              <!-- START CONTAINER FLUID --> 
              
              <!-- START CONTAINER FLUID -->
              <div class=" no-padding    container-fixed-lg bg-white">
                <div class="container">
                  <!-- START card -->
                  <select class="select-form-style" style="margin-left: 20px; margin-top: 10px;" [(ngModel)]="searchStatus" id="searchBy" (change)="callsearch($event)">  
                    <option value="1">Search By Initiated Date & Time</option>  
                    <option value="2">Search By Transaction Date & Time</option>  
                    <option value="3">Search By All</option>  
                    <option value="4">Search By Onboarded By</option> 
                </select>  
                <input *ngIf="searchStatus!=4"type="date" style="margin-left: 10px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
              <input *ngIf="searchStatus!=4"type="date" style="margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
    
              <input *ngIf="searchStatus==4" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
              <input *ngIf="searchStatus==4" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
                <select class="select-form-style"  style="margin-left: 10px;" [(ngModel)]="searchByCusorPart" id="accountstatus">
                  <option value="1">Customer</option>  
                  <option value="2" >Partner</option>  
              </select>
              <select class="select-form-style" [(ngModel)]="searchByonboardedby" style="margin-left: 10px;" id="onboarded">
                <option value="1">CinchFuel</option>  
                <option value="2">Others</option>  
            </select>
              <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
              <button title="Refresh" *ngIf="refreshflag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
              <button title="Excel Export" *ngIf="excelexportchecktransaction" (click)="exportToExcelTransaction()" title="Excel Export" style="margin-left: 10px; margin-top: 5px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
            <div *ngIf="errorFlag" style="color: red; margin-left: 20px;">{{errorMessage}}</div>
                  <div class="card card-transparent">
                    <div class="card-body">
                      <div class="table-responsive">
                        <table id="excel-table" datatable  class="ch-table" >
                          <thead>
                            <tr> 
                              <th style="text-align: center;">Sl.No.</th>
                              <th style="text-align: left;">Order ID</th>
                              <!-- <th style="text-align: left;">Transaction ID</th> -->
                              <th style="text-align: left;">Customer Info</th>
                              <th style="text-align: left;">Delivery Agent Info</th>
                              <th style="text-align: left;">Fuel</th>
                              <!-- <th style="text-align: center;">Quantity in Ltrs</th> -->
                              <th style="text-align: right;">Transaction Amount</th>
                              <th style="text-align: center;">Payment Status</th>
                              <th style="text-align: center;">Payment Mode</th>
                              <th style="text-align: left;">Initiated Date Time</th>
                              <th style="text-align: left;">Transaction Date Time</th>
                              <th style="text-align: center; width: 100px;" >Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let transaction of transactionfilter5DetailNoRecord.response; let i=index">
                              <td align="center" class="v-align-middle "><p>{{i + 1}}</p></td>
                      <td *ngIf="transaction.orderId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 10px;" ><b>{{transaction.orderId}}</b></span><br>
                        <span *ngIf="transaction.transactionId!=null" style="font-size: 10px;"><b>Txn Id :</b>{{transaction.transactionId}}</span>
                        <span *ngIf="transaction.transactionId==null" style="font-size: 10px;"><b>Txn Id :</b>N/A</span>
                      </td>
                      <td *ngIf="transaction.orderId==null"align="left" class="v-align-middle"><p>N/A</p></td>

                      <!-- <td *ngIf="transaction.transactionId!=null"align="left" class="v-align-middle"><p>{{transaction.transactionId}}</p></td>
                      <td *ngIf="transaction.transactionId==null"align="left" class="v-align-middle"><p>N/A</p></td> -->

                      <td *ngIf="transaction.customerId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 12px;">{{transaction.customerId.firstName}} {{transaction.customerId.lastName}}</span><br>
                        <span style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.customerId.mobileNumber}}</span>
                        <!-- <p style="font-size: 12px;">{{transaction.customerId.firstName}} {{transaction.customerId.lastName}} </p>
                        <p style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.customerId.mobileNumber}}</p> -->
                      </td>
                      <td *ngIf="transaction.customerId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                      <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">
                        <span style="font-size: 12px;">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</span><br>
                        <span style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.vehicleId.userId.mobileNumber}}</span>
                          <!-- <p style="font-size: 12px;">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</p>
                          <p style="font-size: 12px;"><b><i class="fa fa-mobile" aria-hidden="true"></i></b> {{transaction.vehicleId.userId.mobileNumber}}</p> -->
                        </td>
                        <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                        <td *ngIf="transaction.fuelType!=null" align="left" class="v-align-middle">
                          <span style="font-size: 10px;">{{transaction.fuelType}}</span><br>
                          <span style="font-size: 10px;"><b>Qtn : </b> {{transaction.quantity}}</span>
                          <!-- <p style="font-size: 10px;">{{transaction.fuelType}}</p>  
                          <p style="font-size: 10px;"><b>Qtn : </b> {{transaction.quantity}}</p> -->
                          </td>
                      <td *ngIf="transaction.fuelType==null"align="left" class="v-align-middle">N/A</td>
                      <!-- <td *ngIf="transaction.quantity!=null"align="center" class="v-align-middle">{{transaction.quantity}}</td>
                      <td *ngIf="transaction.quantity==null"align="center" class="v-align-middle">N/A</td> -->
                      <td align="right" *ngIf="transaction.totalPayableAmount!=null"class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.totalPayableAmount | number : '1.2-2'}}</td>
                      <td align="right" *ngIf="transaction.totalPayableAmount==null"class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="transaction.paymentModeId.id==1">
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-inactive">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' " style="color: red;">Failed</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                        <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                        <span  *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' " style="color: blue;">{{transaction.paymentStatus}}</span> 
                      </td>
                      <td align="center" *ngIf="transaction.paymentModeId.id!=1">
                        <span *ngIf="transaction.approvalStatus==='Pending' || transaction.approvalStatus==null" class="v-align-middle ht-inactive">Pending</span> 
                       <span *ngIf="transaction.approvalStatus==='Success' || transaction.approvalStatus==='Approved'" class="v-align-middle ht-active">Success</span> 

                       <span *ngIf="transaction.approvalStatus==='Submitted' "class="v-align-middle ht-active">
                         <span *ngIf="transaction.chequeClaimStatus=='Success'">Success</span> 
                         <span *ngIf="transaction.chequeClaimStatus!='Success'"> {{transaction.chequeClaimStatus}}</span> 
                       </span> 

                       <span *ngIf="transaction.approvalStatus==='Failed' " style="color: red;">Failed</span> 
                       <span *ngIfElse="transaction.approvalStatus!=null " style="color: orange;">{{transaction.approvalStatus}}</span> 
                     </td>
                      <td> {{transaction.paymentModeId.paymentMode}} </td>
                      <td *ngIf="transaction.initiatedDatetime!=null"align="left" class="v-align-middle">{{transaction.initiatedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.initiatedDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="transaction.paymentDatetime!=null"align="left" class="v-align-middle">{{transaction.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td *ngIf="transaction.paymentDatetime==null"align="left" class="v-align-middle">N/A</td>
                      <td align="left">
                        <button title="view" class="label label-info" (click)="view(content,transaction.id)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                        <span *ngIf="transaction.paymentModeId.id==5"> 
                          <button title="view Cheque and DD" class="label label-info" (click)="viewproof(transaction.id,transaction.orderProof,contentproof,1)"><i class="fa fa-money" aria-hidden="true"></i></button>&nbsp;
                         </span>

                         <span *ngIf="transaction.orderReceipt!=null"> 
                          <button title="view Receipt" class="label label-info" (click)="viewproof(transaction.id,transaction.orderReceipt,contentproof,2)"><i class="fa fa-list" aria-hidden="true"></i></button>&nbsp;
                         </span>

                         <button *ngIf="transaction.paymentModeId.id!=1 && (transaction.approvalStatus=='Pending' || transaction.approvalStatus=='Failed')" title="Update Approval Status" class="label label-info" (click)="update(content7,transaction.id,transaction.paymentModeId.id,transaction.approvalStatus,transaction.receivedAmount,transaction.paymentReferenceNo,transaction.totalPayableAmount)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;
                         <button *ngIf="transaction.paymentModeId.id==5 && transaction.approvalStatus=='Submitted' && transaction.chequeClaimStatus!='Success'" title="Update Claim Status" class="label label-info" (click)="updateClaim(content9,transaction.id,transaction.paymentReferenceNo,transaction.approvalStatus,transaction.chequeClaimStatus)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;

                         <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='Cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                        <button *ngIf="transaction.paymentStatus==='Failure'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                      </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!-- END card --> 
                </div>
              </div>
              <!-- END CONTAINER FLUID --> 
            </div>
        
            <div *ngIf="accountStatus==2">
              <div style="display: none;">{{transactionfilter5DetailNoRecord.responseMessage}}</div>
                    <div class="container Customers-top-notification card4view">
                    <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 315px; margin-left: 5px;">
                      <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                              <div _ngcontent-tgd-c17="" class="card-title">Total Transaction Amount (Success) </div>
                              <div _ngcontent-tgd-c17="" class="card-controls">
                                <ul _ngcontent-tgd-c17="">
                                  <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                              <h3 *ngIf="transactionfilter5DetailNoRecord.amount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i> {{transactionfilter5DetailNoRecord.amount | number : '1.2-2'}}</h3>
                              <h3 *ngIf="transactionfilter5DetailNoRecord.amount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5"><i class="fa fa-inr" aria-hidden="true"></i>0</h3>
                              <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% </span> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                      <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                              <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Success) </div>
                              <div _ngcontent-tgd-c17="" class="card-controls">
                                <ul _ngcontent-tgd-c17="">
                                  <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                              <h3 *ngIf="transactionfilter5DetailNoRecord.activeCount!=null" _ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter5DetailNoRecord.activeCount}}</h3>
                              <h3 *ngIf="transactionfilter5DetailNoRecord.activeCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">
                                0</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 340px; margin-left: -20px;">
                      <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                              <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Failed) </div>
                              <div _ngcontent-tgd-c17="" class="card-controls">
                                <ul _ngcontent-tgd-c17="">
                                  <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                              <h3 *ngIf="transactionfilter5DetailNoRecord.inactiveCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter5DetailNoRecord.inactiveCount}}</h3>
                              <h3 *ngIf="transactionfilter5DetailNoRecord.inactiveCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                              <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71%</span> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div _ngcontent-tgd-c17="" class="card no-border" style="height: 90px; width: 300px; margin-left: -20px;">
                      <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                              <div _ngcontent-tgd-c17="" class="card-title">Total No. Of Transactions  (Initiated)</div>
                              <div _ngcontent-tgd-c17="" class="card-controls">
                                <ul _ngcontent-tgd-c17="">
                                  <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div _ngcontent-tgd-c17="" class="row-xs-height">
                          <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                            <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                              <h3 *ngIf="transactionfilter5DetailNoRecord.initiatedCount!=null"_ngcontent-tgd-c17="" class="no-margin p-b-5">{{transactionfilter5DetailNoRecord.initiatedCount}}</h3>
                              <h3 *ngIf="transactionfilter5DetailNoRecord.initiatedCount==null"_ngcontent-tgd-c17="" class="no-margin p-b-5">0</h3>
                              <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- START CONTAINER FLUID -->
                  <div class=" no-padding    container-fixed-lg bg-white">
                    <div class="container">
                      <select class="select-form-style" style="margin-left: 20px; margin-top: 10px;" [(ngModel)]="searchStatus" id="searchBy" (change)="callsearch($event)">  
                        <option value="1">Search By Initiated Date & Time</option>  
                        <option value="2">Search By Transaction Date & Time</option>  
                        <option value="3">Search By All</option>  
                        <option value="4">Search By Onboarded By</option> 
                    </select>  
                    <input *ngIf="searchStatus!=4"type="date" style="margin-left: 10px;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
              <input *ngIf="searchStatus!=4"type="date" style="margin-left: 10px;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
    
              <input *ngIf="searchStatus==4" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="startDate" class="select-form-style"  id="fromdate" name="fromdate" >
              <input *ngIf="searchStatus==4" type="date" style="margin-left: 10px; display: none;" [(ngModel)]="endDate" class="select-form-style" id="todate" name="todate" >
                    <select class="select-form-style" style="margin-left: 10px;" [(ngModel)]="searchByCusorPart" id="accountstatus">
                      <option value="1" >Customer</option>  
                      <option value="2" >Partner</option>  
                  </select>
                  <select class="select-form-style" [(ngModel)]="searchByonboardedby" style="margin-left: 10px;" id="onboarded">
                    <option value="1">CinchFuel</option>  
                    <option value="2">Others</option>  
                </select>
                  <button title="Search" style="margin-left: 10px;" class="but-style" (click)="search()" type="button" >Go</button> 
                  <button title="Refresh" *ngIf="refreshflag" (click)="refresh()" style="margin-left: 10px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-refresh" aria-hidden="true"></i></button>
                  <button title="Excel Export" *ngIf="excelexportchecktransaction" (click)="exportToExcelTransaction()" title="Excel Export" style="margin-left: 10px; margin-top: 5px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
                <div *ngIf="errorFlag" style="color: red; margin-left: 20px;">{{errorMessage}}</div>
                      <!-- START card -->
                      <div class="card card-transparent">
                        <!-- <div class="card-header "> -->
                        <div class="card-body">
                          <div class="table-responsive">
                            <table id="excel-table" datatable  class="ch-table" >
                              <thead>
                                <tr> 
                                  <th style="text-align: center;">Sl.No.</th>
                                  <th style="text-align: left;">Transaction ID</th>
                                  <th style="text-align: left;">Partner Name</th>
                                  <th style="text-align: left;">Partner Mobile</th>
                                  <th style="text-align: right;">Transaction Amount</th>
                                  <th style="text-align: center;">Payment Status</th>
                                  <th style="text-align: center;">Payment Mode</th>
                                  <th style="text-align: left;">Initiated Date Time</th>
                                  <th style="text-align: left;">Transaction Date Time</th>
                                  <th style="text-align: center; width: 100px;" >Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let transaction of transactionfilter5DetailNoRecord.response; let i=index">
                                  <td align="center" class="v-align-middle ">{{i + 1}}</td>
                                  <td *ngIf="transaction.paymentId!=null"align="left" class="v-align-middle">{{transaction.paymentId}}</td>
                                  <td *ngIf="transaction.paymentId==null"align="left" class="v-align-middle">N/A</td>
                                  <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">{{transaction.vehicleId.userId.firstName}} {{transaction.vehicleId.userId.lastName}}</td>
                                  <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                                  <td *ngIf="transaction.vehicleId!=null"align="left" class="v-align-middle">{{transaction.vehicleId.userId.mobileNumber}}</td>
                                  <td *ngIf="transaction.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                                  <td align="right" *ngIf="transaction.totalPayableAmount!=null"class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{transaction.totalPayableAmount | number : '1.2-2'}}</td>
                                  <td align="right" *ngIf="transaction.totalPayableAmount==null"class="v-align-middle">N/A</td>
                                  <td align="center" *ngIf="transaction.paymentModeId.id==1">
                                  <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Pending' "class="v-align-middle ht-inactive">{{transaction.paymentStatus}}</span> 
                                  <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Success' "class="v-align-middle ht-active">{{transaction.paymentStatus}}</span> 
                                  <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure' " style="color: red;">Failed</span> 
                                  <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                                  <span *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled' " style="color: red;">{{transaction.paymentStatus}}</span> 
                                  <span  *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated' " style="color: blue;">{{transaction.paymentStatus}}</span> 
                                </td>
                                <td align="center" *ngIf="transaction.paymentModeId.id!=1">
                                  <span *ngIf="transaction.approvalStatus==='Pending' || transaction.approvalStatus==null" class="v-align-middle ht-inactive">Pending</span> 
                                 <span *ngIf="transaction.approvalStatus==='Success' || transaction.approvalStatus==='Approved'" class="v-align-middle ht-active">Success</span> 
         
                                 <span *ngIf="transaction.approvalStatus==='Submitted' "class="v-align-middle ht-active">
                                   <span *ngIf="transaction.chequeClaimStatus=='Success'">Success</span> 
                                   <span *ngIf="transaction.chequeClaimStatus!='Success'"> {{transaction.chequeClaimStatus}}</span> 
                                 </span> 
         
                                 <span *ngIf="transaction.approvalStatus==='Failed' " style="color: red;">Failed</span> 
                                 <span *ngIfElse="transaction.approvalStatus!=null " style="color: orange;">{{transaction.approvalStatus}}</span> 
                               </td>
                                  <td> {{transaction.paymentModeId.paymentMode}} </td>
                                  <td *ngIf="transaction.initiateDatetime!=null"align="left" class="v-align-middle">{{transaction.initiateDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                                  <td *ngIf="transaction.initiateDatetime==null"align="left" class="v-align-middle">N/A</td>
                                  <td *ngIf="transaction.transactionDatetime!=null"align="left" class="v-align-middle">{{transaction.transactionDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                                  <td *ngIf="transaction.transactionDatetime==null"align="left" class="v-align-middle">N/A</td>
                                  <td align="left">
                                    <button title="view" class="label label-info" (click)="view(content,transaction.id)"><i class="fa fa-eye" aria-hidden="true"></i></button>&nbsp;
                                    <span *ngIf="transaction.paymentModeId.id==5"> 
                                      <button title="view Cheque and DD" class="label label-info" (click)="viewproof(transaction.id,transaction.orderProof,contentproof,1)"><i class="fa fa-money" aria-hidden="true"></i></button>&nbsp;
                                     </span>
            
                                     <span *ngIf="transaction.orderReceipt!=null"> 
                                      <button title="view Receipt" class="label label-info" (click)="viewproof(transaction.id,transaction.orderReceipt,contentproof,2)"><i class="fa fa-list" aria-hidden="true"></i></button>&nbsp;
                                     </span>
            
                                     <button *ngIf="transaction.paymentModeId.id!=1 && (transaction.approvalStatus=='Pending' || transaction.approvalStatus=='Failed')" title="Update Approval Status" class="label label-info" (click)="update(content7,transaction.id,transaction.paymentModeId.id,transaction.approvalStatus,transaction.receivedAmount,transaction.paymentReferenceNo,transaction.totalPayableAmount)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;
                                     <button *ngIf="transaction.paymentModeId.id==5 && transaction.approvalStatus=='Submitted' && transaction.chequeClaimStatus!='Success'" title="Update Claim Status" class="label label-info" (click)="updateClaim(content9,transaction.id,transaction.paymentReferenceNo,transaction.approvalStatus,transaction.chequeClaimStatus)"><i class="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;

                                     <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Failure'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                    <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Initiated'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                    <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                    <button *ngIf="transaction.paymentStatus!=null && transaction.paymentStatus==='Cancelled'" title="Payment Status" (click)="open2(content5,transaction.transactionprimaryId,transaction.id)" class="label label-info" ><i class="fa fa-sticky-note" aria-hidden="true"></i></button>&nbsp;
                                    <button *ngIf="transaction.paymentStatus==='cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                                    <button *ngIf="transaction.paymentStatus==='Cancelled'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                                    <button *ngIf="transaction.paymentStatus==='Failure'" title="delete"class="label label-danger" (click)="open1(reject,transaction.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <!-- END card --> 
                    </div>
                  </div>
                  <!-- END CONTAINER FLUID --> 
                </div>   
      </div>
      </div>

  </div>
  <!-- END PAGE CONTENT WRAPPER --> 
</div>
</div>

<router-outlet></router-outlet>

<ng-template #content let-modal>
  <div class="modal-header">
    <h6>View Transaction Details</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <h5 style="margin-left: 15px; text-align: left; color: grey;">Order Details</h5>
  <div class="container"
    fxLayout
  >
  <div class="item item-1">
    <p class="card-title"><b>OrderID</b><br>
      <span>{{settlementInfoModel.response.orderId}}</span>
    </p>
  </div>
  <div class="item item-2" style="margin-left: 40px;">
    <p class="card-title"><b>Customer Info</b><br>
      <span>
      Name : {{settlementInfoModel.response.customerId.firstName}}<br>
      Mobile No: {{settlementInfoModel.response.customerId.mobileNumber}}
    </span>
  </p>
  </div>
  <div class="item item-3" style="margin-left: 40px;">
    <p class="card-title"><b>Partner Info </b>  <br><span>
      Name : {{settlementInfoModel.response.vehicleId.userId.firstName}}<br>
      Mobile No: {{settlementInfoModel.response.vehicleId.userId.mobileNumber}}
    </span>
  </p>
  </div>
  <div class="item item-4" style="margin-left: 40px;">
    <p class="card-title"><b> Transaction ID </b> <br>
      <span *ngIf="settlementInfoModel.response.transactionId!=null">{{settlementInfoModel.response.transactionId}}</span>
      <span *ngIf="settlementInfoModel.response.transactionId==null">N/A</span>
    </p>
  </div>
  <div class="item item-1" style="margin-left: 40px;">
    <p class="card-title"><b>Fuel</b> 
      <br><span>{{settlementInfoModel.response.fuelType}}</span></p>
  </div>
  <div class="item item-2" style="margin-left: 40px;">
    <p class="card-title"><b>Quantity in Ltrs</b>
      <br>
      <span>{{settlementInfoModel.response.quantity}}</span> 
     </p>
  </div>
  <div class="item item-2" style="margin-left: 40px;">
    <p class="card-title"><b>Payment Mode</b>
      <br>
      <span>{{settlementInfoModel.response.paymentModeId.paymentMode}}
      

      </span> 
     </p>
  </div>
  </div>
  <br>
  <h5 style="margin-left: 15px; text-align: left; color: grey;"></h5>
  <div class="container" fxLayout>
    <div class="item item-7"  *ngIf="settlementInfoModel.response.paymentModeId.id==1">
      <p class="card-title"><b>Status</b>  <br>
        <span *ngIf="settlementInfoModel.response.paymentStatus!=null && settlementInfoModel.response.paymentStatus==='Success' "class="v-align-middle ht-active">{{settlementInfoModel.response.paymentStatus}}</span>
        <span *ngIf="settlementInfoModel.response.paymentStatus!=null && settlementInfoModel.response.paymentStatus==='Failure' " style="color: red;">Failed</span>
        <span *ngIf="settlementInfoModel.response.paymentStatus!=null && settlementInfoModel.response.paymentStatus==='Cancelled' " style="color: red;">{{settlementInfoModel.response.paymentStatus}}</span>
        <span *ngIf="settlementInfoModel.response.paymentStatus!=null && settlementInfoModel.response.paymentStatus==='cancelled' " style="color: red;">{{settlementInfoModel.response.paymentStatus}}</span>
        <span *ngIf="settlementInfoModel.response.paymentStatus!=null && settlementInfoModel.response.paymentStatus==='Initiated' " style="color: blue;">{{settlementInfoModel.response.paymentStatus}}</span>
      </p>
    </div>
    <div class="item item-7" *ngIf="settlementInfoModel.response.paymentModeId.id!=1">
      <p class="card-title"><b>Status</b>  <br>
        <span *ngIf="settlementInfoModel.response.approvalStatus==='Pending' "class="v-align-middle ht-inactive">{{settlementInfoModel.response.approvalStatus}}</span> 
        <span *ngIf="settlementInfoModel.response.approvalStatus==='Submitted' "class="v-align-middle ht-active">
          <span *ngIf="settlementInfoModel.response.chequeClaimStatus=='Success'">Success</span> 
          <span *ngIf="settlementInfoModel.response.chequeClaimStatus!='Success'"> {{settlementInfoModel.response.approvalStatus}}</span> 
         
        </span> 

        <span *ngIf="settlementInfoModel.response.approvalStatus==='Success' || settlementInfoModel.response.approvalStatus==='Approved'"class="v-align-middle ht-active">Success</span> 
        <span *ngIf="settlementInfoModel.response.approvalStatus==='Failed' " style="color: red;">Failed</span> 
        <span *ngIfElse="settlementInfoModel.response.approvalStatus!=null " style="color: orange;">{{settlementInfoModel.response.approvalStatus}}</span> 

      </p>
    </div>
    <div class="item item-2"  style="margin-left: 40px;" *ngIf="settlementInfoModel.response.paymentModeId.id==3 || settlementInfoModel.response.paymentModeId.id==5">
      <p class="card-title"><b> Reference Number</b>
        <br>
        <span *ngIf="settlementInfoModel.response.paymentReferenceNo!=null">
            {{settlementInfoModel.response.paymentReferenceNo}}
           
          </span> 
          <span *ngIf="settlementInfoModel.response.paymentReferenceNo==null">N/A</span>
       </p>
    </div>
    <div class="item item-2" style="margin-left: 40px;" *ngIf="settlementInfoModel.response.paymentModeId.id==2">
      <p class="card-title"><b>Received Amount</b>
        <br>
        <span>{{settlementInfoModel.response.receivedAmount | number : '1.2-2'}}</span> 
       </p>
    </div>

    <div class="item item-2" style="margin-left: 40px;" *ngIf="settlementInfoModel.response.paymentModeId.id==5">
      <p class="card-title"><b>Cheque Claim Status</b>
        <br>
        <span *ngIf="settlementInfoModel.response.chequeClaimStatus!=null">{{settlementInfoModel.response.chequeClaimStatus}}</span> 
        <span *ngIf="settlementInfoModel.response.chequeClaimStatus==null">N/A</span>
      </p>
    </div>
    <div class="item item-2" style="margin-left: 40px;" *ngIf="settlementInfoModel.response.chequeClaimStatus=='Bounced'">
      <p class="card-title"><b>Cheque Bounced Charges</b>
        <br>
        <span *ngIf="settlementInfoModel.response.chequeBounceCharges!=null">{{settlementInfoModel.response.chequeBounceCharges}}</span> 
        <span *ngIf="settlementInfoModel.response.chequeBounceCharges==null">N/A</span>
      </p>
    </div>
    <div class="item item-2" style="margin-left: 40px;" *ngIf="settlementInfoModel.response.chequeClaimStatus=='Bounced'">
      <p class="card-title"><b>Cheque Bounced Date</b>
        <br>
        <span *ngIf="settlementInfoModel.response.chequeBounceDateTime!=null">{{settlementInfoModel.response.chequeBounceDateTime | date:'dd/MM/yyyy'}}</span> 
        <span *ngIf="settlementInfoModel.response.chequeBounceDateTime==null">N/A</span>
      </p>
    </div>
  
    <div class="item item-8" style="margin-left: 40px;"  *ngIf="settlementInfoModel.response.paymentModeId.id==1">
      <p class="card-title"><b>Transaction Date & Time </b> <br><span>{{settlementInfoModel.response.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span></p>
    </div>
    <div class="item item-8" style="margin-left: 40px;"  *ngIf="settlementInfoModel.response.paymentModeId.id!=1">
      <p class="card-title"><b>Remarks </b> <br><span>{{settlementInfoModel.response.approvalRemarks}}</span></p>
    </div>
    <div class="item item-8" style="margin-left: 40px;"  *ngIf="settlementInfoModel.response.paymentModeId.id!=1">
      <p class="card-title"><b>Payment Date & Time </b> <br><span>{{settlementInfoModel.response.approvalDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</span></p>
    </div>


  </div>
  


 
  <hr class="beautiful-line">
  <br>
  <h5 style="margin-left: 15px; text-align: left; color: grey;">Payment Description</h5>
  <div class="container"
  fxLayout
>

<div class="item item-3">
  <p class="card-title"><b>Total Fuel Cost (In Rs.)</b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{settlementInfoModel.response.totalfuelcost | number : '1.2-2'}}</span></p>
</div>
<div class="item item-6" style="margin-left: 40px;">
  <p class="card-title"><b>Coupon Info </b> <br>
    <span *ngIf="settlementInfoModel.response.couponCode!=null">Coupon Code : {{settlementInfoModel.response.couponCode}}</span>
    <span *ngIf="settlementInfoModel.response.couponCode==null">Coupon Code : N/A</span><br>
    <span *ngIf="settlementInfoModel.response.couponDiscountFlat!=null && settlementInfoModel.response.couponCode!=null">
      <span *ngIf="settlementInfoModel.response.couponDiscountFlat==1">
        <span>Discount Percentage : {{settlementInfoModel.response.couponDiscountPrice}} % </span>
      </span>
      <span *ngIf="settlementInfoModel.response.couponDiscountFlat==2">
        Discount Price :  <i class="fa fa-inr" aria-hidden="true"></i>{{settlementInfoModel.response.couponDiscountPrice | number : '1.2-2' }}
      </span>
    </span>
    <span *ngIf="settlementInfoModel.response.couponCode==null">DisCount : N/A</span>
  </p>
</div>
<div *ngIf="settlementInfoModel.response.couponCode!=null" class="item item-6" style="margin-left: 40px;">
  <p class="card-title"><b>Final Fuel Cost </b> <br>
    <span><i class="fa fa-inr" aria-hidden="true"></i>{{settlementInfoModel.response.finaltotalfuelCost | number : '1.2-2' }}</span>
</div>
<div class="item item-4" style="margin-left: 20px;">
  <p class="card-title"><b>Delivery Charge(In Rs.) </b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{settlementInfoModel.response.deliveryCharges | number : '1.2-2'}}</span></p>
</div>
<div class="item item-5" style="margin-left: 20px;">
  <p class="card-title"><b>SGST (9%) </b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{settlementInfoModel.response.sgst | number : '1.2-2'}}</span></p>  
</div>
<div class="item item-5" style="margin-left: 20px;">
  <p class="card-title"><b>CGST (9%) </b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{settlementInfoModel.response.cgst | number : '1.2-2'}}</span></p>  
</div>
<div class="item item-6" style="margin-left: 20px;">
  <p class="card-title"><b>Platform Charge (In Rs.) </b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{settlementInfoModel.response.internetHandlingFee | number : '1.2-2' }}</span></p>
</div>
<div class="item item-6" *ngIf="settlementInfoModel.response.additionalChargesAmount!=0" style="margin-left: 20px;">
  <p class="card-title"><b>Additional Charges </b>
    <br><span> {{settlementInfoModel.response.additionalChargesTitle}}</span>
     <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{settlementInfoModel.response.additionalChargesAmount | number : '1.2-2' }}</span></p>
</div> 
<div class="item item-6" style="margin-left: 20px;">
  <p class="card-title"><b>Total Paid Amount (In Rs.) </b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{settlementInfoModel.response.totalPayableAmount | number : '1.2-2' }}</span></p>
</div>
  </div>
  <hr class="beautiful-line">
  <br>
  <h5 style="margin-left: 15px; text-align: left; color: grey;">Transaction Payment Response</h5>
  <div class="container"
  fxLayout
>
<div class="item item-3" >
  <p class="card-title"><b>Name</b> <br>
    <span *ngIf="settlementInfoModel.response.jsonname!=null">{{settlementInfoModel.response.jsonname}}</span>
    <span *ngIf="settlementInfoModel.response.jsonname==null">N/A</span>
  </p>
</div>
<div class="item item-3" style="margin-left: 45px;">
  <p class="card-title"><b>Email</b> <br>
    <span *ngIf="settlementInfoModel.response.jsonemail!=null">{{settlementInfoModel.response.jsonemail}}</span>
    <span *ngIf="settlementInfoModel.response.jsonemail==null">N/A</span>
  </p>
</div>
<div class="item item-3" style="margin-left: 45px;">
  <p class="card-title"><b>Amount</b> <br>
    <span *ngIf="settlementInfoModel.response.jsonamount!=null"><i class="fa fa-inr" aria-hidden="true"></i> {{settlementInfoModel.response.jsonamount | number : '1.2-2' }}</span>
    <span *ngIf="settlementInfoModel.response.jsonamount==null">N/A</span>
  </p>
</div>
<div class="item item-3" style="margin-left: 45px;">
  <p class="card-title"><b>City</b> <br>
    <span *ngIf="settlementInfoModel.response.jsoncity!=null">{{settlementInfoModel.response.jsoncity}}</span>
    <span *ngIf="settlementInfoModel.response.jsoncity==null">N/A</span>
  </p>
</div>
<div class="item item-3" style="margin-left: 45px;">
  <p class="card-title"><b>State</b> <br>
    <span *ngIf="settlementInfoModel.response.jsonstate!=null">{{settlementInfoModel.response.jsonstate}}</span>
    <span *ngIf="settlementInfoModel.response.jsonstate==null">N/A</span>
  </p>
</div>
<div class="item item-3" style="margin-left: 45px;">
  <p class="card-title"><b>Country</b> <br>
    <span *ngIf="settlementInfoModel.response.jsoncountry!=null">{{settlementInfoModel.response.jsoncountry}}</span>
    <span *ngIf="settlementInfoModel.response.jsoncountry==null">N/A</span>
  </p>
</div>
<div class="item item-3" style="margin-left: 45px;">
  <p class="card-title"><b>Description</b> <br>
    <span *ngIf="settlementInfoModel.response.jsondescription!=null">{{settlementInfoModel.response.jsondescription}}</span>
    <span *ngIf="settlementInfoModel.response.jsondescription==null">N/A</span>
  </p>
</div>
</div>
<hr class="beautiful-line">
<br>
<div class="container"
  fxLayout
>

<div class="item item-3">
  <p class="card-title"><b>Payment Mode</b> <br>
    <span *ngIf="settlementInfoModel.response.jsonpaymentmode!=null">{{settlementInfoModel.response.jsonpaymentmode}}</span>
    <span *ngIf="settlementInfoModel.response.jsonpaymentmode==null">N/A</span>
  </p>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p class="card-title"><b>Error Description</b> <br>
    <span *ngIf="settlementInfoModel.response.jsonerrordesc!=null">{{settlementInfoModel.response.jsonerrordesc}}</span>
    <span *ngIf="settlementInfoModel.response.jsonerrordesc==null">N/A</span>
  </p>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p class="card-title"><b>UDF 1</b> <br>
    <span *ngIf="settlementInfoModel.response.jsonudf1!=null">{{settlementInfoModel.response.jsonudf1}}</span>
    <span *ngIf="settlementInfoModel.response.jsonudf1==null">N/A</span>
  </p>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p class="card-title"><b>UDF 2</b> <br>
    <span *ngIf="settlementInfoModel.response.jsonudf2!=null">{{settlementInfoModel.response.jsonudf2}}</span>
    <span *ngIf="settlementInfoModel.response.jsonudf2==null">N/A</span>
  </p>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p class="card-title"><b>UDF 3</b> <br>
    <span *ngIf="settlementInfoModel.response.jsonudf3!=null">{{settlementInfoModel.response.jsonudf3}}</span>
    <span *ngIf="settlementInfoModel.response.jsonudf3==null">N/A</span>
  </p>
</div>
<div class="item item-3" style="margin-left: 30px;">
  <p class="card-title"><b>Address 1</b> <br>
    <span *ngIf="settlementInfoModel.response.jsonaddress1!=null" style="word-wrap: break-word;">{{settlementInfoModel.response.jsonaddress1}}</span>
    <span *ngIf="settlementInfoModel.response.jsonaddress1==null">N/A</span>
  </p>
</div>

</div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
  </div>
</ng-template>


<ng-template #content1 let-modal>
  <div class="modal-header">
    <h6>View Transaction Details</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <h5 style="margin-left: 15px; text-align: left; color: grey;">Order Details</h5>
  <div class="container"
    fxLayout
  >
  <div class="item item-3">
    <p class="card-title"><b>Partner Info </b>  <br><span>
      Name : {{vehiclePaymentInfoModel.response.vehicleId.userId.firstName}}<br>
      Mobile No: {{vehiclePaymentInfoModel.response.vehicleId.userId.mobileNumber}}
    </span>
  </p>
  </div>
  <div class="item item-4" style="margin-left: 40px;">
    <p class="card-title"><b> Transaction ID </b> <br>
      <span *ngIf="vehiclePaymentInfoModel.response.paymentId!=null">{{vehiclePaymentInfoModel.response.paymentId}}</span>
      <span *ngIf="vehiclePaymentInfoModel.response.paymentId==null">N/A</span>
    </p>
  </div>
  <div class="item item-7" style="margin-left: 40px;">
    <p class="card-title"><b>Status</b>  <br>
      <span *ngIf="vehiclePaymentInfoModel.response.paymentStatus!=null && vehiclePaymentInfoModel.response.paymentStatus==='Success' "class="v-align-middle ht-active">{{vehiclePaymentInfoModel.response.paymentStatus}}</span>
      <span *ngIf="vehiclePaymentInfoModel.response.paymentStatus!=null && vehiclePaymentInfoModel.response.paymentStatus==='Failure' " style="color: red;">Failed</span>
      <span *ngIf="vehiclePaymentInfoModel.response.paymentStatus!=null && vehiclePaymentInfoModel.response.paymentStatus==='Cancelled' " style="color: red;">{{vehiclePaymentInfoModel.response.paymentStatus}}</span>
      <span *ngIf="vehiclePaymentInfoModel.response.paymentStatus!=null && vehiclePaymentInfoModel.response.paymentStatus==='cancelled' " style="color: red;">{{vehiclePaymentInfoModel.response.paymentStatus}}</span>
      <span *ngIf="vehiclePaymentInfoModel.response.paymentStatus!=null && vehiclePaymentInfoModel.response.paymentStatus==='Initiated' " style="color: blue;">{{vehiclePaymentInfoModel.response.paymentStatus}}</span>
    </p>
  </div>
  <div class="item item-8" style="margin-left: 40px;">
    <p class="card-title"><b>Transaction Date & Time </b> <br><span>{{vehiclePaymentInfoModel.response.transactionDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span></p>
  </div>
  </div>
  <br>
  <h5 style="margin-left: 15px; text-align: left; color: grey;">Payment Description</h5>
  <div class="container"
  fxLayout
>
<div class="item item-5" >
  <p class="card-title"><b>Vehicle Amount (In Rs.)</b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{vehiclePaymentInfoModel.response.vehicleAmount | number : '1.2-2'}}</span></p>  
</div>
<div class="item item-5" style="margin-left: 40px;">
  <p class="card-title"><b>SGST (9%) </b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{vehiclePaymentInfoModel.response.sgst | number : '1.2-2'}}</span></p>  
</div>
<div class="item item-5" style="margin-left: 40px;">
  <p class="card-title"><b>CGST (9%) </b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{vehiclePaymentInfoModel.response.cgst | number : '1.2-2'}}</span></p>  
</div>
<div class="item item-6" style="margin-left: 40px;">
  <p class="card-title"><b>Total Paid Amount (In Rs.) </b> <br><span><i class="fa fa-inr" aria-hidden="true"></i> {{vehiclePaymentInfoModel.response.totalPayableAmount | number : '1.2-2' }}</span></p>
</div>
<!-- <div class="item item-6" style="margin-left: 40px;">
  <p class="card-title"><b>Coupon Code </b> <br>
    <span *ngIf="vehiclePaymentInfoModel.response.couponCode!=null">{{vehiclePaymentInfoModel.response.couponCode}}</span>
    <span *ngIf="vehiclePaymentInfoModel.response.couponCode==null">N/A</span>
  </p>
</div>
<div class="item item-6" style="margin-left: 40px;">
  <p class="card-title"><b>Coupon Discount Price</b> <br>
    <span *ngIf="vehiclePaymentInfoModel.response.couponDiscountFlat!=null && vehiclePaymentInfoModel.response.couponCode!=null">
      <span *ngIf="vehiclePaymentInfoModel.response.couponDiscountFlat==1">
        <span>{{vehiclePaymentInfoModel.response.couponDiscountPrice}} % </span>
      </span>
      <span *ngIf="vehiclePaymentInfoModel.response.couponDiscountFlat==2">
        <i class="fa fa-inr" aria-hidden="true"></i> {{vehiclePaymentInfoModel.response.couponDiscountPrice | number : '1.2-2' }}
      </span>
    </span>
    <span *ngIf="vehiclePaymentInfoModel.response.couponCode==null">N/A</span>
  </p>
</div> -->
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
  </div>
</ng-template>


<ng-template #reject let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Do you want delete the transaction?</label>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="delete()" (click)="modal.close('Save click')">yes</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">no</button>
  </div>
</ng-template>

<ng-template #content5 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Customer Payment Status</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">{{customerPaymentStatus.responseMessage}}</label>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>

<ng-template #content6 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Partner Payment Status</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">{{partnerPaymentStatus.responseMessage}}</label>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>
<ng-template #contentproof let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{title}} </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
    </div>
    <hr class="beautiful-line">
   
    <div *ngIf="bakImage">
      <br>
          <img class="modal-content"  [src]="imageToShowProof" width="auto" height="auto">
    </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">close</button>
  </div>
</ng-template>

<ng-template #content7 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Payment Status</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
    <form>
      <div class="form-group" *ngIf="paymentModeId==2 || paymentModeId==3 || paymentModeId==4 || paymentModeId==8">
        <label for="dateOfBirth">Approval Status * </label>
          <select  (change)="approval($event)" class="select-form-style" style="margin-left: 10px; " id="approvalStatus" name="approvalStatus" [(ngModel)]="approvalStatus" >
            <option value="Pending">Pending</option>  
            <option value="Success">Success</option>  
        </select>
      </div>
   
      <div class="form-group" *ngIf="paymentModeId==5">
        <label for="dateOfBirth">Approval Status *</label>
          <select class="select-form-style" style="margin-left: 10px; " id="approvalStatus"  name="approvalStatus" [(ngModel)]="approvalStatus">
            <option value="Pending">Pending</option>  
            <option value="Submitted">Submitted</option>  
        </select>
      </div>
      <div class="form-group" *ngIf="paymentModeId==2 ">
        <label for="dateOfBirth">Received Amount *</label>
            <input type="text" class="select-form-style" style="margin-left: 10px; " name="receivedAmount" id="receivedAmount" [(ngModel)]="receivedAmount"/>
      </div>
      <div class="form-group" *ngIf="paymentModeId==5 || paymentModeId==3 || paymentModeId==8">
        <label for="dateOfBirth">Payment Reference Number *</label>
            <input type="text" class="select-form-style" style="margin-left: 10px; " name="paymentReferenceNo" id="paymentReferenceNo" [(ngModel)]="paymentReferenceNo"/>
      </div>
      <div class="form-group" *ngIf="successStatus">
        <label for="dateOfBirth">Payment Date *</label>
        <input type="datetime-local" class="select-form-style" id="paymentDate" name="paymentDate" aria-describedby="emailHelp" placeholder="Select Role" required>
      </div>

      <div class="form-group" *ngIf="successStatus">
        <label for="dateOfBirth">Remarks *</label>
            <textarea class="select-form-style" style="margin-left: 10px; " name="remarks" id="remarks" [(ngModel)]="remarks"></textarea>
      </div>
     
      <div class="form-group" *ngIf="paymentModeId==5 ">
        <label for="dateOfBirth">Upload Cheque & DD *</label>
        <div *ngIf="imageUpload" class="form-group">
          <input style="margin-left: 10px;" #imageInput
          id="file"
          name="file"
           type="file"
           accept=".jpeg,.jpg"
           data-max-size="2048"
           (change)="selectFile($event)">
          <br>
          
      </div>
  
        <div *ngIf="pdfUpload" class="form-group">
          <input #imageInput
          id="file"
          name="file"
           type="file"
           accept=".pdf,.jpeg,.jpg"
           data-max-size="2048"
           (change)="selectFile($event)">
      </div>

      </div>
      

    </form>
    <br>
    <div *ngIf="poppupalert">
      <div style="color: red;">Mandatoy fields are required!</div>
    </div>
  </div>
  <hr class="beautiful-line">
  <div class="modal-footer">
    <button type="button" *ngIf="paymentModeId!=5 " class="btn btn-outline-dark" (click)="approve(paymentModeId)">Submit</button>
    <button type="button" *ngIf="paymentModeId==5 " class="btn btn-outline-dark" (click)="uploadCheque(id)">Submit</button>

    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
  </div>
</ng-template>

<ng-template #content9 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Claim Status</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
    <form>
      
      <div class="form-group">
        <label for="dateOfBirth">Cheque Claim Status *</label>
        <select (change)="bounce($event)"  class="select-form-style" style="margin-left: 10px; " id="chequeClaimStatus" name="chequeClaimStatus" [(ngModel)]="chequeClaimStatus">
          <option value="Pending">Pending</option>  
          <option value="Success">Success</option>  
          <option value="Bounced">Bounced</option>  
      </select>
      </div>
      <div class="form-group" *ngIf="bounceStatus">
        <label for="dateOfBirth">Bounce Charges *</label>
            <input type="text" class="select-form-style" style="margin-left: 10px; " name="bounceCharges" id="bounceCharges"/>
      </div>
     
      <div class="form-group" *ngIf="bounceStatus">
        <label for="dateOfBirth">Bounce Date *</label>
        <input type="date" class="select-form-style" id="bounceDate" name="bounceDate" aria-describedby="emailHelp" placeholder="Bounce Date" required>
      </div>

      

    </form>
    <br>
    <div *ngIf="poppupalert">
      <div style="color: red;">Please Select Claim Status!</div>
    </div>
  </div>
  <hr class="beautiful-line">
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="claimApprove()">Submit</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
  </div>
</ng-template>