import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { GenericResponseModel } from '../model/genricresponse';
import { SettlementModel } from '../model/settlement';
import { ReportServices } from '../services/reports.service';

@Component({
  selector: 'app-view-settled-report',
  templateUrl: './report-fuel-refill-balance.component.html',
  styleUrls: ['./report-fuel-refill-balance.component.css']
})
export class ReportFuelRefillBalanceComponent implements OnInit {
  public isCollapsed = false;
  settledreportmodel:GenericResponseModel;
  settledreport:SettlementModel;
  constructor(private modalService: NgbModal,private reportservice:ReportServices) { }

  ngOnInit(): void {

    let settledid = Number(sessionStorage.getItem("settledId"));

    this.reportservice.settledreportById(settledid).subscribe(data=>{
      this.settledreport = data;

    });

    let formData= {
      settlementId:settledid,
      userName:sessionStorage.getItem("username")
    }
    this.reportservice.getSettlementAndTransactionReport(formData).subscribe(data=>{
      this.settledreportmodel = data;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
