import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { GenericResponseModel } from '../model/genricresponse';
import { OrderTransactionService } from '../services/ordertran.service';
import { ReportServices } from '../services/reports.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { Workbook } from 'exceljs';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-settled-report',
  templateUrl: './report-order.component.html',
  styleUrls: ['./report-order.component.css']
})
export class ReportOrderComponent {
 
 
  symbole:String; 
  public isCollapsed = false;
  active = 1;
  transactionDetail:GenericResponseModel;
  transactionfilterDetail:GenericResponseModel;
  settlementDetail:GenericResponseModel;
  settlementViewDetail:GenericResponseModel;
 closeResult = '';
   errorFlag:boolean;
   errorMessage:String;
   refreshflag:boolean;
   responseDataList = [];
   responseDataListnew = [];
   settledreportModel:GenericResponseModel;
   settledfilterreportModel:GenericResponseModel;
   filtersettled:boolean=false;
   normalsettled:boolean=true;
   excelexportchecksettled : boolean = false;
   constructor(private route: ActivatedRoute,private router: Router,private reportservice:ReportServices,private spinnerService:NgxSpinnerService) { }
 
   ngOnInit(): void {
    this.spinnerService.show();

  //   setTimeout(function() {
  //     this.spinnerService.hide();
  // }.bind(this), 6000);

    // this.excelexport();
     let formData= {
      vehicleId:null,
      fromDate:null,
      toDate:null,
     }
 
      this.reportservice.getSettledReport(formData).subscribe(data=>{
       this.settledreportModel = data;
       if(data.statusCode==200){
        this.excelexportchecksettled = true;
       }else{
        this.excelexportchecksettled = false;
       }
       this.spinnerService.hide();
      });   
   }
 
   search(){
    this.spinnerService.show();
     this.refreshflag = true;
     let fromdate = (<HTMLInputElement>document.getElementById('fromdate')).value;
     let todate = (<HTMLInputElement>document.getElementById('todate')).value;
     if(todate < fromdate){
       this.errorFlag = true;
       this.errorMessage = "End Date should be greater than Start Date!";
     }else if(fromdate!=null && fromdate!=='' && todate!=null && todate!==''){
       this.errorFlag = false;
 
       let formData= {
         orderId:null,
         fromDate:fromdate,
         toDate:todate,
       }
       this.reportservice.getSettledReport(formData).subscribe(data=>{
        this.settledfilterreportModel = data;
        if(data.statusCode==200){
          this.excelexportchecksettled = true;
         }else{
          this.excelexportchecksettled = false;
         }
        this.filtersettled = true;
        this.normalsettled = false;
        this.spinnerService.hide();
       });   
     }else{
       this.errorFlag = true;
       this.errorMessage = "Please select Dates!";
       this.spinnerService.hide();
     }
   }
 
   refresh(){
     window.location.reload();
   }
   view(id){
    sessionStorage.setItem("settledId",String(id));
    this.router.navigate(['/view-settled-report']);
   }

   exportToExcelSettled(){
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    let sno = 1;
    this.settledreportModel.response.forEach(element => {
      let vehicle = element['vehicleId'];
      let partner = vehicle['userId'];
      let dates = new Date(element['settlementDatetime'])
      let convertedDate = moment(new Date(dates)).format("yyyy-MM-DD HH:mm:ss a").toString();
      var response = [];
      response.push(sno);
      response.push(element['settlementApi']);
      response.push(partner['firstName'] + partner['lastName']);
      response.push(partner['mobileNumber']);
      response.push(element['accountName']);
      response.push(element['accountNumber']);
      response.push(element['ifscCode']);
      response.push(element['bankReference']);
      response.push(element['payoutAmount']);
      response.push(convertedDate);
      this.responseDataListnew.push(response);
      sno++;
      var  responseData =
        {
          "Settlement ID": element['settlementApi'],
          "Partner Name": partner['firstName'] + partner['lastName'],
          "Partner Mobile": partner['mobileNumber'],
          "Account Holder Name": element['accountName'],
          "Account Number": element['accountNumber'],
          "IFSC Code": element['ifscCode'],
          "Bank Reference Number": element['bankReference'],
          "Payout Amount": element['payoutAmount'],
          "Settlement Date & Time": convertedDate,
        };
        this.responseDataList.push(responseData);
    });
      this.excelexport();
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.responseDataList);
    const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], {
      type: EXCEL_TYPE
    });
    // FileSaver.saveAs(data, 'Settled Report' + '_exported'+ EXCEL_EXTENSION);
   }

   exportToExcelFilterSettled(){
    let sno = 1;
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    this.settledfilterreportModel.response.forEach(element => {
      let vehicle = element['vehicleId'];
      let partner = vehicle['userId'];
      let dates = new Date(element['settlementDatetime'])
      let convertedDate = moment(new Date(dates)).format("yyyy-MM-DD HH:mm:ss a").toString();
      var response = [];
      response.push(sno);
      response.push(element['settlementApi']);
      response.push(partner['firstName'] + partner['lastName']);
      response.push(partner['mobileNumber']);
      response.push(element['accountName']);
      response.push(element['accountNumber']);
      response.push(element['ifscCode']);
      response.push(element['bankReference']);
      response.push(element['payoutAmount']);
      response.push(convertedDate);
      this.responseDataListnew.push(response);
      sno++;
      var  responseData =
        {
          "Settlement ID": element['settlementApi'],
          "Partner Name": partner['firstName'] + partner['lastName'],
          "Partner Mobile": partner['mobileNumber'],
          "Account Holder Name": element['accountName'],
          "Account Number": element['accountNumber'],
          "IFSC Code": element['ifscCode'],
          "Bank Reference Number": element['bankReference'],
          "Payout Amount": element['payoutAmount'],
          "Settlement Date & Time": convertedDate,
        };
        this.responseDataList.push(responseData);
    });
    this.excelexport();
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.responseDataList);
    const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array', });
    const data: Blob = new Blob([excelBuffer], {
      type: EXCEL_TYPE
    });
    // FileSaver.saveAs(data, 'Settled Report' + '_exported'+ EXCEL_EXTENSION);
   }

   excelexport(){
    const title = 'Settled Report';
    const header = ["S.No","Settlement ID", "Partner Name", "Partner Mobile", "Account Holder Name", "Account Number", "IFSC Code","Bank Reference Number","Payout Amount","Settlement Date & Time"]
    const data = this.responseDataListnew;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Settled Report');
// Add new row
let titleRow = worksheet.addRow([title]);
// Set font, size and style in title row.
titleRow.font = { name: 'Times New Roman', family: 4, size: 16, bold: true };

// Blank Row
worksheet.addRow([]);

//Add row with current date
// let subTitleRow = worksheet.addRow(['Date : ' + this.datePipe.transform(new Date(), 'medium')]);
//Add Header Row
let headerRow = worksheet.addRow(header);
headerRow.font = {bold: true };
// Cell Style : Fill and Border
headerRow.eachCell((cell, number) => {
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFFFFFF' },
    bgColor: { argb: 'FF0000FF' },
  }
  cell.border = {  top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
});

data.forEach(d => {
  let row = worksheet.addRow(d);
  let qty = row.getCell(1);
  let qty1 = row.getCell(2);
  let qty2 = row.getCell(3);
  let qty3 = row.getCell(4);
  let qty4 = row.getCell(5);
  let qty5 = row.getCell(6);
  let qty6 = row.getCell(7);
  let qty7 = row.getCell(8);
  let qty8 = row.getCell(9);
  let qty9 = row.getCell(10);
  qty.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty1.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty2.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty3.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty4.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty5.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty6.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty7.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty8.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
  qty9.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
}
);
workbook.xlsx.writeBuffer().then((data) => {
  let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  FileSaver.saveAs(blob, 'Settled Report.xlsx');
});
   }
}
