<app-header></app-header>

<!-- START PAGE-CONTAINER -->
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
  </ngx-spinner> 
<div class="page-container "> 
  <!-- START PAGE CONTENT WRAPPER -->
  <div class="page-content-wrapper "> 
    <!-- START PAGE CONTENT -->
    <div class="content sm-gutter"> 
      <!-- START BREADCRUMBS -->
      <div class="bg-white">
        <div class="container">
          <ol class="breadcrumb breadcrumb-alt">
            <li class="breadcrumb-item"><a href="#" routerLink="/dashboard">Home</a></li>
            <li class="breadcrumb-item active"><span class="font-montserrat fs-11 all-caps">Stock Report</span></li>
          </ol>
        </div>
      </div>

      <!-- START CONTAINER FLUID -->
      <div class=" no-padding    container-fixed-lg bg-white">
        <div class="container"> 
          <!-- START card -->
          <div class="card card-transparent">
            <div class="btn-section-wrapper">
              <button *ngIf="excelexportcheck" (click)="exportToExcel()" title="Excel Export" style="margin-left: 10px; margin-top: 5px;" id="refresh" class="label label-success tm tm-suc"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
            </div>
            <div class="card-body">
                <div style="display: none;">{{vehicleFuelStockModel.responseMessage}}</div>
              <div class="table-responsive" style="margin-top: 20px;">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: left;">Vehicle Number</th>
                      <th style="width: 150px; text-align: left;">Partner Info</th>
                      <th style="text-align: left;">Model/Name</th>
                      <th style="text-align: left;">Fuel</th>
                      <th style="text-align: center;">Tank Capacity<br> (in Ltrs)</th>
                      <th style="text-align: center;">Total Refill<br> (in Ltrs)</th>
                      <th style="text-align: center;">Hold <br>(in Ltrs)</th>
                      <th style="text-align: center;">Delivered <br>(in Ltrs)</th>
                      <th style="text-align: center;">Available <br>(in Ltrs)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let fuel of vehicleFuelStockModel.response; let i=index">
                      <td align="center" class="v-align-middle "><p>{{i + 1}}</p></td>
                      <td *ngIf="fuel.vehicleId!=null"align="left" class="v-align-middle"><p>{{fuel.vehicleId.vehicleNumber}}</p></td>
                      <td *ngIf="fuel.vehicleId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                      <td *ngIf="fuel.vehicleId!=null"align="left" class="v-align-middle">
                          <p style="font-size: 12px;">
                            <b>Name :</b> {{fuel.vehicleId.userId.firstName}} {{fuel.vehicleId.userId.lastName}} <br>
                            <b>Mobile :</b> {{fuel.vehicleId.userId.mobileNumber}}
                          </p>
                        </td>
                      <td *ngIf="fuel.vehicleId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                      <td *ngIf="fuel.vehicleId!=null"align="left" class="v-align-middle">{{fuel.vehicleId.vehicleName}}</td>
                      <td *ngIf="fuel.vehicleId==null"align="left" class="v-align-middle"><p>N/A</p></td>
                      <td *ngIf="fuel.vehicleId!=null"align="left" class="v-align-middle">{{fuel.vehicleId.fuelType.fuelTypeName}}</td>
                      <td *ngIf="fuel.vehicleId==null"align="left" class="v-align-middle">N/A</td>
                      <td *ngIf="fuel.vehicleId!=null"align="center" class="v-align-middle">{{fuel.vehicleId.tankCapacity}}</td>
                      <td *ngIf="fuel.vehicleId==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="fuel.fuelStock!=null"align="center" class="v-align-middle">{{fuel.fuelStock}}</td>
                      <td *ngIf="fuel.fuelStock==null"align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="fuel.stockHold!=null" align="center" class="v-align-middle">{{fuel.stockHold}}</td>
                      <td *ngIf="fuel.stockHold==null" align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="fuel.dispenseFuel!=null" align="center" class="v-align-middle">{{fuel.dispenseFuel}}</td>
                      <td *ngIf="fuel.dispenseFuel==null" align="center" class="v-align-middle">N/A</td>
                      <td *ngIf="fuel.availableltrs!=null" align="center" class="v-align-middle">{{fuel.availableltrs}}</td>
                      <td *ngIf="fuel.availableltrs==null" align="center" class="v-align-middle">N/A</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- END card --> 
        </div>
      </div>
