import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {


    constructor(private router : Router){}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let url: string = state.url;  
        return this.verifyLogin(url);
        
    }

    verifyLogin(url) : boolean{
        const expirationDuration = 60 * 60 * 1000 //  30 min
        const prevAccepted = Number(localStorage.getItem("accepted"));
        const currentTime = new Date().getTime();
        const notAccepted = prevAccepted == undefined;
        const prevAcceptedExpired = prevAccepted != undefined && currentTime - prevAccepted > expirationDuration;
        if ((notAccepted || prevAcceptedExpired) ) {
            this.router.navigate(['/login']);
            sessionStorage.removeItem("jwttoken");
            return false;
        }else{
            return true;
        }

        // if(!this.isLoggedIn()){
        //     this.router.navigate(['/login']);
        //     return false;
        // }
        // else if(this.isLoggedIn()){
        //     return true;
        // }
    }
    public isLoggedIn(): boolean{
        let status = false;
        if( localStorage.getItem('isLoggedIn') == "true"){
          status = true;
        }
        else{
          status = false;
        }
        return status;
    }
}