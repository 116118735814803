<app-header></app-header>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
  </ngx-spinner> 
<ol class="breadcrumb breadcrumb-alt" style="margin-top:100px; width: auto;" >
  <li class="breadcrumb-item" style="margin-left: 15px;"><a href="#" routerLink="/dashboard">Home</a></li>
  <li class="breadcrumb-item active">CHARGES CONFIGURATION </li>
  <div class="btn-section-wrapper">
      <button type="button" class="label label-info" (click)="open(content)">
        Update Charges</button>
</div>
</ol>
<div class="container  container-top-dashboard-section">
<div class="card mb-3" style="margin-top: 20px; background-color: #ffb6c1;" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">

  <br>
	<h5 style="margin-left: 15px; text-align: left;">Current Charges </h5>
   <div class="flex">
    <div class="container"
    fxLayout
  >
  <div class="item item-1">
    <p class="card-title"><b><span > Set-up Fee </span></b><br>
      <span><b><i class="fa fa-inr" aria-hidden="true"></i> {{charges.vehicleAttachmentFee | number : '1.2-2'}}</b></span></p>
   </div>
   <div class="item item-2" style="margin-left: 30px;">
    <p class="card-title"><b><span >Delivery Charges </span></b><br>
      <span *ngIf="currentCharges.deliveryChargeFlag===1">
        <span >Based on km</span>
        <span ><b>{{currentCharges.deliveryLessEqualKms}}</b> - <i class="fa fa-inr" aria-hidden="true"></i> {{currentCharges.deliveryLessEqualCharges | number : '1.2-2'}} </span><br>
        <span ><b>{{currentCharges.deliveryGreaterEqualKms}}</b> - <i class="fa fa-inr" aria-hidden="true"></i> {{currentCharges.deliveryGreaterEqualCharges | number : '1.2-2'}} / KM</span>
      </span>

      <span *ngIf="currentCharges.deliveryChargeFlag===2">
       
        <span ><b>Fixed Fee</b> - <i class="fa fa-inr" aria-hidden="true"></i> {{currentCharges.deliveryFixedFee | number : '1.2-2'}} </span><br>
      </span>
      <span *ngIf="currentCharges.deliveryChargeFlag===3">
       
        <span ><b>Per Ltr Cost</b> - <i class="fa fa-inr" aria-hidden="true"></i> {{currentCharges.deliveryPerLtrCost | number : '1.2-2'}} </span><br>
      </span>
      
    </p>
   </div>
   <div class="item item-3" style="margin-left: 30px;">
    <p class="card-title"><b><span >Asset Charge</span></b><br>
      <span>Setup: <b><i class="fa fa-inr" aria-hidden="true"></i>{{currentCharges.assetSetupFee | number : '1.2-2'}}</b></span><br/>
      <span>Subscription:<b><i class="fa fa-inr" aria-hidden="true"></i> {{currentCharges.assetSubscriptionFee | number : '1.2-2'}}</b></span>
    
    </p>
   </div>
   <div class="item item-3" style="margin-left: 30px;">
    <p class="card-title"><b><span >Platform Charge</span></b><br>
      <span><b><i class="fa fa-inr" aria-hidden="true"></i> {{currentCharges.internetHandlingFee | number : '1.2-2'}}</b></span></p>
   </div>
   <div class="item item-4" style="margin-left: 30px;">
    <p class="card-title"><b><span >AMC Fee</span></b><br>
      <span><b><i class="fa fa-inr" aria-hidden="true"></i> {{currentCharges.amcFee | number : '1.2-2'}}</b></span></p>
   </div>
   <div class="item item-5" style="margin-left: 30px;">
    <p class="card-title"><b><span >MMC Fee</span></b><br>
      <span><b><i class="fa fa-inr" aria-hidden="true"></i> {{currentCharges.mmcFee | number : '1.2-2'}}</b></span></p>
   </div>
   <div class="item item-6" style="margin-left: 30px;">
    <p class="card-title"><b><span >SGST</span></b><br>
      <span><b>{{currentCharges.sgstPercentage}} <i class="fa fa-percent" aria-hidden="true"></i></b></span></p>
   </div>
   <div class="item item-7" style="margin-left: 30px;">
    <p class="card-title"><b><span >CGST</span></b><br>
      <span><b>{{currentCharges.cgstPercentage}} <i class="fa fa-percent" aria-hidden="true"></i></b></span></p>
   </div>
   <div class="item item-8" style="margin-left: 30px;">
    <p class="card-title"><b><span >IGST</span></b><br>
      <span><b>{{currentCharges.igstPercentage}} <i class="fa fa-percent" aria-hidden="true"></i></b></span></p>
   </div>

   <div class="item item-9" style="margin-left: 30px;">
    <p class="card-title"><b><span >Effective from </span></b><br>
      <span><b>{{currentCharges.effectiveFrom | date:'dd/MM/yyyy hh:mm:ss a'}}</b></span></p>
   </div>
   <div class="item item-10" style="margin-left: 30px;">
    <p class="card-title"><b><span >Created Date And time</span></b><br>
      <span><b>{{currentCharges.modifiedDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</b></span></p>
   </div>
    <div class="item item-11" style="margin-left: 25px;">
    <p class="card-title"><b><span >Min Quantity</span></b><br>
      <span><b>{{currentCharges.minOrderQuantity}}</b></span></p>
   </div>
  <div class="item item-12" style="margin-left: 25px;">
    <p class="card-title"><b><span >Max Quantity</span></b><br>
      <span><b>{{currentCharges.maxOrderQuantity}}</b></span></p>
   </div>
    </div>
      <!-- <div class="col1 flex">
         <div class="profile-info flex">
            <div class="card-body profile">
               <p class="card-title"><span >Set-up Fee </span><br><span><b><i class="fa fa-inr" aria-hidden="true"></i> {{charges.vehicleAttachmentFee | number : '1.2-2'}}</b></span></p>
               <p class="card-title"><span >Delivery Charges</span><br>

               <span><b>{{currentCharges.deliveryLessEqualKms}}</b> - <i class="fa fa-inr" aria-hidden="true"></i> {{currentCharges.deliveryLessEqualCharges | number : '1.2-2'}} </span><br>
               <span><b>{{currentCharges.deliveryGreaterEqualKms}}</b> - <i class="fa fa-inr" aria-hidden="true"></i> {{currentCharges.deliveryGreaterEqualCharges | number : '1.2-2'}} / KM</span>
               </p>
               <p class="card-title"><span >Platform Charge</span><br><span><b><i class="fa fa-inr" aria-hidden="true"></i> {{currentCharges.internetHandlingFee | number : '1.2-2'}}</b></span></p>
               <p class="card-title"><span >AMC Fee</span><br><span><b><i class="fa fa-inr" aria-hidden="true"></i> {{currentCharges.amcFee | number : '1.2-2'}}</b></span></p>
            </div>
            <div class="card-body profile">
               <p class="card-title"><span >SGST</span><br><span><b>{{currentCharges.sgstPercentage}} <i class="fa fa-percent" aria-hidden="true"></i></b></span></p>
<p class="card-title"><span >CGST</span><br><span><b>{{currentCharges.cgstPercentage}} <i class="fa fa-percent" aria-hidden="true"></i></b></span></p>
<br>
 <p class="card-title"><span >IGST</span><br>
 <span><b>{{currentCharges.igstPercentage}} <i class="fa fa-percent" aria-hidden="true"></i></b></span></p>
 <p class="card-title"><span >MMC Fee</span><br><span><b><i class="fa fa-inr" aria-hidden="true"></i> {{currentCharges.mmcFee | number : '1.2-2'}}</b></span></p>
            </div>
            <div class="card-body profile">
               <p class="card-title"><span >Effective from </span><br><span><b>{{currentCharges.effectiveFrom}}</b></span></p>
               <p class="card-title"><span >Created Date And time</span><br><span><b>{{currentCharges.modifiedDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</b></span></p>
               <br>
               <p class="card-title"><span >Minimum Quantity</span><br><span><b>{{currentCharges.minOrderQuantity}}</b></span></p>
               <p class="card-title"><span >Maximum Quantity</span><br><span><b>{{currentCharges.maxOrderQuantity}}</b></span></p>
            </div>
            <div class="card-body profile">
            </div>
         </div>
      </div> -->
   </div>
</div>



   
<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
	 <!-- <div class="btn-section-wrapper">
	   <button type="button" class="btn btn-success" (click)="open(content)">
 Update Charges</button>
  	

</div> -->
  <li [ngbNavItem]="1">
    <a ngbNavLink>Reset History</a>
    <ng-template ngbNavContent>
      <div class="card-body">
        <div style="display: none;">{{chargesDetails.responseMessage}}</div>
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th style="text-align: right;">Set-up Fee</th>
                      <th style="text-align: right;">Delivery Charges</th>
                      <th style="text-align: right;">Platform Charge</th>
                      <th style="text-align: right;">AMC Fee</th>
                      <th style="text-align: right;">MMC Fee</th>
                      <th >Minimum Quantity</th>
                      <th >Maximum Quantity</th>
                      <th >SGST</th>
                      <th >CGST</th>
                      <th >IGST</th>
                      <th >Effective From</th>
                      <th >Created Date time</th>
                      <!-- <th >Action</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let charges of chargesDetails; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{charges.vehicleAttachmentFee | number : '1.2-2' }}</td>
                      <td align="right" class="v-align-middle">
                        <span><b>{{charges.deliveryLessEqualKms}}</b> - <i class="fa fa-inr" aria-hidden="true"></i> {{charges.deliveryLessEqualCharges | number : '1.2-2'}} </span><br>
               <span><b>{{charges.deliveryGreaterEqualKms}}</b> - <i class="fa fa-inr" aria-hidden="true"></i> {{charges.deliveryGreaterEqualCharges | number : '1.2-2'}} / KM</span>
                      </td>
                      <td align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{charges.internetHandlingFee | number : '1.2-2'}}</td>
                      <td align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{charges.amcFee | number : '1.2-2'}}</td>
                      <td align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{charges.mmcFee | number : '1.2-2'}}</td>
                      <td align="left" class="v-align-middle">{{charges.minOrderQuantity}}</td>
                      <td align="left" class="v-align-middle">{{charges.maxOrderQuantity}}</td>
                      <td align="center" class="v-align-middle">{{charges.sgstPercentage}} <i class="fa fa-percent" aria-hidden="true"></i></td>
                      <td align="center" class="v-align-middle">{{charges.cgstPercentage}} <i class="fa fa-percent" aria-hidden="true"></i></td>
                      <td align="center" class="v-align-middle">{{charges.igstPercentage}} <i class="fa fa-percent" aria-hidden="true"></i></td>
                      <td align="left" class="v-align-middle">{{charges.effectiveFrom | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td align="left" class="v-align-middle">{{charges.modifiedDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                      <!-- <td align="center" class="v-align-middle">
                        <button title="delete"class="label label-danger" (click)="open2(reject,charges.id)"><i class="fa fa-trash" aria-hidden="true"></i></button>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
          </div>
            </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="2" style="display: none;">
    <a ngbNavLink>Two</a>
    <ng-template ngbNavContent>
      <p>Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan four loko
        farm-to-table craft beer twee. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts
        ullamco ad vinyl cillum PBR. Homo nostrud organic, assumenda labore aesthetic magna delectus mollit. Keytar
        helvetica VHS salvia yr, vero magna velit sapiente labore stumptown. Vegan fanny pack odio cillum wes anderson
        8-bit, sustainable jean shorts beard ut DIY ethical culpa terry richardson biodiesel. Art party scenester
        stumptown, tumblr butcher vero sint qui sapiente accusamus tattooed echo park.</p>
    </ng-template>
  </li>
  <li [ngbNavItem]="3" style="display: none;">
    <a ngbNavLink>Three</a>
    <ng-template ngbNavContent>
      <p>Sed commodo, leo at suscipit dictum, quam est porttitor sapien, eget sodales nibh elit id diam. Nulla facilisi.
        Donec egestas ligula vitae odio interdum aliquet. Duis lectus turpis, luctus eget tincidunt eu, congue et odio.
        Duis pharetra et nisl at faucibus. Quisque luctus pulvinar arcu, et molestie lectus ultrices et. Sed diam urna,
        egestas ut ipsum vel, volutpat volutpat neque. Praesent fringilla tortor arcu. Vivamus faucibus nisl enim, nec
        tristique ipsum euismod facilisis. Morbi ut bibendum est, eu tincidunt odio. Orci varius natoque penatibus et
        magnis dis parturient montes, nascetur ridiculus mus. Mauris aliquet odio ac lorem aliquet ultricies in eget
        neque. Phasellus nec tortor vel tellus pulvinar feugiat.</p>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>


</div>



<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Charges</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <div class="modal-body master-body">
    <form>
      
	<div class="form-group">
        <label for="dateOfBirth">Set-up Fee</label>
        <input type="text" class="form-control" id="vehicle" name="vehicle" (keypress)="allowNumericDigitsOnlyOnKeyUp($event)"  [(ngModel)]="vehicleFee" placeholder="Vehicle Attachment Fee">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Delivery Charges</label><br/>

       <span *ngIf="deliveryChargeFlag==1"><input type="radio"  name="deliveryChargeFlag" id="deliveryChargeFlag" value="1"   (click)="eventCheck($event)" checked> Based on Km </span> 
       <span *ngIf="deliveryChargeFlag!=1"> <input type="radio" name="deliveryChargeFlag" id="deliveryChargeFlag" value="1"   (click)="eventCheck($event)" [(ngModel)]="deliveryChargeFlag"> Based on Km </span>

       <span *ngIf="deliveryChargeFlag==2"><input type="radio" name="deliveryChargeFlag" id="deliveryChargeFlag" value="2" (click)="eventCheck($event)" checked> Fixed Fee</span>
        <span *ngIf="deliveryChargeFlag!=2"><input type="radio"  name="deliveryChargeFlag" id="deliveryChargeFlag" value="2" (click)="eventCheck($event)" [(ngModel)]="deliveryChargeFlag"> Fixed Fee</span>

        <span *ngIf="deliveryChargeFlag==3"><input type="radio" name="deliveryChargeFlag" id="deliveryChargeFlag" value="3" (click)="eventCheck($event)" checked> Per Ltr Cost</span> 
        <span *ngIf="deliveryChargeFlag!=3"><input type="radio"  name="deliveryChargeFlag" id="deliveryChargeFlag" value="3" (click)="eventCheck($event)" [(ngModel)]="deliveryChargeFlag"> Per Ltr Cost</span> 

      </div>
      <div *ngIf="deliveryChargeFlag==1" class="form-group">
        <label for="dateOfBirth">Delivery Charges Per Km</label>
        <br>
        <!-- <input type="text" class="form-control"  id="deliveryChargePerkm" name="deliveryChargePerkm" [(ngModel)]="currentCharges.deliveryChargePerkm" placeholder="Delivery Charges Per KM"> -->
        Delivery charge for Less than or Equal to <input type="text" (keypress)="allowNumericDigitsOnlyOnKeyUpWithoutdot($event)" class="delivery-box" (change)="updateValue()" id="deliveryLess" name="deliveryLess" [(ngModel)]="deliverless" >    KM will be Rs. <input type="text" (keypress)="allowNumericDigitsOnlyOnKeyUpWithoutdot($event)" class="delivery-box" id="deliverylech" name="deliverylech" [(ngModel)]="deliverlessamt">  <br><br>
        Delivery charge Greater than or Equal to <input type="text" (keypress)="allowNumericDigitsOnlyOnKeyUpWithoutdot($event)" class="delivery-box" id="deliveryGreater" name="deliveryGreater" [(ngModel)]="delivergrea">   KM will be Rs. <input type="text" class="delivery-box" (keypress)="allowNumericDigitsOnlyOnKeyUpWithoutdot($event)" id="deliveryGrch" name="deliveryGrch" [(ngModel)]="delivergreaamt" >   per KM 
      </div>
     
      <div class="form-group" *ngIf="deliveryChargeFlag==2">
        <label for="dateOfBirth">Fixed Fee</label>
        <input type="text" class="form-control" id="deliveryFixedFee"  (keypress)="allowNumericDigitsOnlyOnKeyUp($event)" name="deliveryFixedFee" [(ngModel)]="deliveryFixedFee" placeholder="Fixed Fee">
      </div>

      <div class="form-group" *ngIf="deliveryChargeFlag==3">
        <label for="dateOfBirth">Per Ltr Cost</label>
        <input type="text" class="form-control" id="deliveryPerLtrCost"  (keypress)="allowNumericDigitsOnlyOnKeyUp($event)" name="deliveryPerLtrCost" [(ngModel)]="deliveryPerLtrCost" placeholder="Per Ltr Cost">
      </div>
      
		<div class="form-group">
        <label for="dateOfBirth">Platform Charge</label>
        <input type="text" class="form-control" id="inte"  name="inte" (keypress)="allowNumericDigitsOnlyOnKeyUp($event)" [(ngModel)]="internet" placeholder="Platform Charge">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">MMC Fee</label>
        <input type="text" class="form-control" id="mmcfe"  (keypress)="allowNumericDigitsOnlyOnKeyUp($event)" name="mmcfe" [(ngModel)]="mmc" placeholder="MMC fee">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">AMC Fee</label>
        <input type="text" class="form-control" id="amcfe" (keypress)="allowNumericDigitsOnlyOnKeyUp($event)"  name="amcfe" [(ngModel)]="amc" placeholder="AMC fee">
      </div>
		<div class="form-group">
        <label for="dateOfBirth">SGST%</label>
        <input type="text" class="form-control" id="sgst" name="sgst" (keypress)="allowNumericDigitsOnlyOnKeyUpWithoutdot($event)" [(ngModel)]="sgst"  placeholder="SGST%">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">CGST%</label>
        <input type="text" class="form-control" id="cgst" name="cgst"(keypress)="allowNumericDigitsOnlyOnKeyUpWithoutdot($event)"  [(ngModel)]="cgst"  placeholder="CGST%">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">IGST%</label>
        <input type="text" class="form-control" id="igst" name="igst" (keypress)="allowNumericDigitsOnlyOnKeyUpWithoutdot($event)" [(ngModel)]="igst" placeholder="IGST%">
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">Effective From</label>
        <input type="datetime-local" class="form-control" id="effedate" name="effedate" aria-describedby="emailHelp" placeholder="Select Role" required>
      </div>
      <br>
      <label for="exampleInputEmail1">Settings</label>
      <div class="form-group">
        <label for="dateOfBirth">Minimum Quantity</label>
        <input type="text" class="form-control" id="minquan" name="minquan" (keypress)="allowNumericDigitsOnlyOnKeyUpWithoutdot($event)" [(ngModel)]="minquan"  placeholder="Minimum Quantity">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Maximum Quantity</label>
        <input type="text" class="form-control" id="maxquan" name="maxquan" (keypress)="allowNumericDigitsOnlyOnKeyUpWithoutdot($event)" [(ngModel)]="maxquan" placeholder="Maximum Quantity">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Asset Subscription Fee</label>
        <input type="text" class="form-control" id="assetSubscriptionFee" name="assetSubscriptionFee" (keypress)="allowNumericDigitsOnlyOnKeyUp($event)" [(ngModel)]="assetSubscriptionFee" placeholder="Asset Subscription Fee">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Asset Setup Fee</label>
        <input type="text" class="form-control" id="assetSetupFee" name="assetSetupFee" (keypress)="allowNumericDigitsOnlyOnKeyUp($event)" [(ngModel)]="assetSetupFee" placeholder="Asset Setup Fee">
      </div>
      <br>
      <div *ngIf="errorFlag">
        <div class="error-style">{{errorMessage}}</div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="addCredit()">Update Charges</button>
    <!-- <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">no</button> -->
  </div>
</ng-template>


<div class="page-container " style="display: none;"> 
  <!-- START PAGE CONTENT WRAPPER -->
  <div class="page-content-wrapper "> 
    <!-- START PAGE CONTENT -->
    <div class="content sm-gutter"> 
      <!-- START BREADCRUMBS -->
      <div class="bg-white">
        <div class="container">
          <ol class="breadcrumb breadcrumb-alt">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item active">Customers </li>
          </ol>
        </div>
      </div>
      <!-- END BREADCRUMBS -->
      
      <div class="container Customers-top-notification">
        <div _ngcontent-tgd-c17="" class="card no-border widget-loader-bar m-b-10">
          <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                  <div _ngcontent-tgd-c17="" class="card-title"><span _ngcontent-tgd-c17="" class="font-montserrat fs-11 all-caps">Total No. of Customer<i _ngcontent-tgd-c17="" class="fa fa-chevron-right"></i></span></div>
                  <div _ngcontent-tgd-c17="" class="card-controls">
                    <ul _ngcontent-tgd-c17="">
                      <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                  <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% </span> -->
                  </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-bottom">
                <div _ngcontent-tgd-c17="" class="progress progress-small m-b-0">
                  <div _ngcontent-tgd-c17="" class="progress-bar progress-bar-primary" style="width: 71%;"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div _ngcontent-tgd-c17="" class="card no-border widget-loader-bar m-b-10">
          <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                  <div _ngcontent-tgd-c17="" class="card-title"><span _ngcontent-tgd-c17="" class="font-montserrat fs-11 all-caps">Total No. of Verified Customer<i _ngcontent-tgd-c17="" class="fa fa-chevron-right"></i></span></div>
                  <div _ngcontent-tgd-c17="" class="card-controls">
                    <ul _ngcontent-tgd-c17="">
                      <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                  <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71%</span> -->
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-bottom">
                <div _ngcontent-tgd-c17="" class="progress progress-small m-b-0">
                  <div _ngcontent-tgd-c17="" class="progress-bar progress-bar-primary" style="width: 71%;"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div _ngcontent-tgd-c17="" class="card no-border widget-loader-bar m-b-10">
          <div _ngcontent-tgd-c17="" class="container-xs-height full-height">
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="card-header top-left top-right">
                  <div _ngcontent-tgd-c17="" class="card-title"><span _ngcontent-tgd-c17="" class="font-montserrat fs-11 all-caps">Total No. of Not Verified Customer<i _ngcontent-tgd-c17="" class="fa fa-chevron-right"></i></span></div>
                  <div _ngcontent-tgd-c17="" class="card-controls">
                    <ul _ngcontent-tgd-c17="">
                      <li _ngcontent-tgd-c17=""><a _ngcontent-tgd-c17="" href="#" data-toggle="refresh" class="portlet-refresh text-black"><i _ngcontent-tgd-c17="" class="portlet-icon portlet-icon-refresh"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-top">
                <div _ngcontent-tgd-c17="" class="p-l-20 p-t-50 p-b-40 p-r-20">
                  <!-- <span _ngcontent-tgd-c17="" class="small hint-text pull-left">71% of total goal</span> -->
                </div>
              </div>
            </div>
            <div _ngcontent-tgd-c17="" class="row-xs-height">
              <div _ngcontent-tgd-c17="" class="col-xs-height col-bottom">
                <div _ngcontent-tgd-c17="" class="progress progress-small m-b-0">
                  <div _ngcontent-tgd-c17="" class="progress-bar progress-bar-primary" style="width: 71%;"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
        
      </div>
       <!-- <div class="container search-section">

       	<div class="Customers-search-section">
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Search By Cust.ID
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="#">Search By Cust. Name</a>
              <a class="dropdown-item" href="#">Search By Cust. Mobile</a>
              <a class="dropdown-item" href="#">Search By Cust. Email</a>
              <a class="dropdown-item" href="#">Account Status</a>
              <a class="dropdown-item" href="#">OTP Verification Status</a>
            </div>
          </div> -->
       	<!-- <div class="form-group">
           <input type="email" class="form-control" placeholder="Search By Cust.ID" required="">
         </div> -->
         <!-- <div class="form-group">
           <input type="email" class="form-control" placeholder="Search By Cust. Name" required="">
         </div>
         <div class="form-group">
           <input type="email" class="form-control" placeholder="Search By Cust. Mobile" required="">
         </div>
         <div class="form-group">
           <input type="email" class="form-control" placeholder="Search By Cust. Email " required="">
         </div>
         <div class="form-group">
           <input type="email" class="form-control" placeholder="Account Status " required="">
         </div>
         <div class="form-group">
           <input type="email" class="form-control" placeholder="OTP Verification Status" required="">
         </div> -->
<!-- <div class="search-btn-wrraper">
         <button class="btn btn-primary btn-cons">Search</button></div>
       </div>
         </div> -->
			
      
      <!-- START CONTAINER FLUID --> 
      
      <!-- START CONTAINER FLUID -->
      <div class=" no-padding    container-fixed-lg bg-white">
        <div class="container"> 
          <!-- START card -->
          <div class="card card-transparent">
            <div class="card-header ">
              <!-- <div class="card-title">Role Menu Mapping </div> -->
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th>Sl.No.</th>
                      <th >vehicle Attachment Fee</th>
                      <th >Delivery Charges Per Km</th>
                      <th >Platform Charge</th>
                      <th >SGST</th>
                      <th >CGST</th>
                      <th >IGST</th>
                      <th >Effective From</th>
                      <th >Created Date time</th>
                      <th >Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let charges of chargesDetails; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td align="left" class="v-align-middle">{{charges.vehicleAttachmentFee}}</td>
                      <td align="left" class="v-align-middle">{{charges.deliveryChargePerkm}}</td>
                      <td align="left" class="v-align-middle">{{charges.internetHandlingFee}}</td>
                      <td align="center" class="v-align-middle">{{charges.sgstPercentage}}</td>
                      <td align="center" class="v-align-middle">{{charges.cgstPercentage}}</td>
                      <td align="center" class="v-align-middle">{{charges.igstPercentage}}</td>
                      <td align="left" class="v-align-middle">{{charges.effectiveFrom | date:'dd/MM/yyyy'}}</td>
                      <td align="left" class="v-align-middle">{{charges.modifiedDatetime | date:'dd/MM/yyyy hh:mm:ss a' }}</td>
                      <td align="center" class="v-align-middle"><button (click)="deleteCharges(charges.id)" class="btn btn-danger"><i class="fa fa-trash-o" aria-hidden="true"></i></button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- END card --> 
        </div>
      </div>
      <!-- END CONTAINER FLUID --> 
      
    </div>
    <!-- END PAGE CONTENT --> 
    <!-- START COPYRIGHT --> 
    <!-- START CONTAINER FLUID --> 
    <!-- START CONTAINER FLUID -->
  <!--   <div class=" container   container-fixed-lg footer">
      <div class="copyright sm-text-center">
        <p class="small-text no-margin pull-left sm-pull-reset"> ©2014-2020 All Rights Reserved. Pages® and/or its subsidiaries or affiliates are registered trademark of Revox Ltd. <span class="hint-text m-l-15">Pages v05.23 20201105.r.190</span> </p>
        <p class="small no-margin pull-right sm-pull-reset"> Hand-crafted <span class="hint-text">&amp; made with Love</span> </p>
        <div class="clearfix"></div>
      </div>
    </div> -->
    <!-- END COPYRIGHT --> 
  </div>
  <!-- END PAGE CONTENT WRAPPER --> 
</div>
<!-- END PAGE CONTAINER --> 


<ng-template #reject let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Do you want delete the charges?</label>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="delete()" (click)="modal.close('Save click')">yes</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">no</button>
  </div>
</ng-template>
<router-outlet></router-outlet>


