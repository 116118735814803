<!-- <!doctype html>
<html>
<head>
    <meta charset="utf-8">
    <title>Cinch Fuel - Purchase Invoice</title>
    
    <style>
    .invoice-box {
        max-width: 800px;
        margin: auto;
        padding: 30px;
        border: 1px solid #eee;
        box-shadow: 0 0 10px rgba(0, 0, 0, .15);
        font-size: 16px;
        line-height: 24px;
        font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
        color: #555;
    }
    
    .invoice-box table {
        width: 100%;
        line-height: inherit;
        text-align: left;
    }
    
    .invoice-box table td {
        padding: 5px;
        vertical-align: top;
    }
    
    .invoice-box table tr td:nth-child(2) {
        text-align: right;
    }
    
    .invoice-box table tr.top table td {
        padding-bottom: 20px;
    }
    
    .invoice-box table tr.top table td.title {
        font-size: 45px;
        line-height: 45px;
        color: #333;
    }
    
    .invoice-box table tr.information table td {
        padding-bottom: 40px;
    }
    
    .invoice-box table tr.heading td {
        background: #eee;
        border-bottom: 1px solid #ddd;
        font-weight: bold;
    }
    
    .invoice-box table tr.details td {
        padding-bottom: 20px;
    }
    
    .invoice-box table tr.item td{
        border-bottom: 1px solid #eee;
    }
    
    .invoice-box table tr.item.last td {
        border-bottom: none;
    }
    
    .invoice-box table tr.total td:nth-child(2) {
        border-top: 2px solid #eee;
        font-weight: bold;
    }
    
    @media only screen and (max-width: 600px) {
        .invoice-box table tr.top table td {
            width: 100%;
            display: block;
            text-align: center;
        }
        
        .invoice-box table tr.information table td {
            width: 100%;
            display: block;
            text-align: center;
        }
    }
    
    /** RTL **/
    .rtl {
        direction: rtl;
        font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    }
    
    .rtl table {
        text-align: right;
    }
    
    .rtl table tr td:nth-child(2) {
        text-align: left;
    }
    </style>
</head>

<body>
    <div class="invoice-box" id="component1">
        <table cellpadding="0" cellspacing="0">
            <tr class="top">
                <td colspan="2">
                    <table>
                        <tr>
                            <td class="title">
                                <img src="http://cinchfuel.in/assets/img/cinch_fuel_logo.jpg" style="width:60%; max-width:200px;">
                            </td>
                            
                            <td>
                              <button class="tm-tag tm-tag-info m-t-20" (click)="printComponent()">Print</button>
                              <button class="tm-tag tm-tag-info m-t-20" style="color: #FFF;
                              background-color: #f8c969;" (click)="email()">Email</button><br>
                                Invoice #: {{invoiceModel.response.invoice.orderId}}<br>
                                {{invoiceModel.response.invoice.orderDeliveredDateTime | date: 'dd-MM-yyyy'}}<br>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            
            <tr class="information">
                <td colspan="2">
                    <table>
                        <tr>
                            <td>
                                <b>CINCH RETAIL PRIVATE LIMITED</b><br>
                                <span innerHTML={{invoiceModel.response.officeAddress.officeAddress}} style="word-wrap: break-word;
                                overflow-wrap: break-word;">{{invoiceModel.response.officeAddress.officeAddress}}</span><br>
                                Email - {{invoiceModel.response.officeAddress.emailAddress}}<br>
                            </td>

                            <td>
                              <b>Customer Info</b><br>
                                {{invoiceModel.response.invoice.customerId.firstName}} {{invoiceModel.response.invoice.customerId.lastName}}<br>
                                <span *ngIf="length==2">
                                    <span>{{address}}</span>,
                                    <span>{{address1}}</span><br>
                                   </span>
                                <span *ngIf="length==3">
                                    <span>{{address}}</span>,
                                    <span>{{address1}}</span>,<br>
                                    <span>{{address2}}</span><br>
                                   </span>
                                <span *ngIf="length==4">
                                    <span>{{address}}</span>,
                                    <span>{{address1}}</span>,<br>
                                    <span>{{address2}}</span>,<br>
                                    <span>{{address3}}</span><br>
                                   </span> 
                                <span *ngIf="length==5">
                                    <span>{{address}}</span>,
                                    <span>{{address1}}</span>,<br>
                                    <span>{{address2}}</span>,<br>
                                    <span>{{address3}}</span>,<br>
                                    <span>{{address4}}</span><br>
                                   </span> 
                                <span *ngIf="length==6">
                                    <span>{{address}}</span>,
                                    <span>{{address1}}</span>,<br>
                                    <span>{{address2}}</span>,<br>
                                    <span>{{address3}}</span>,<br>
                                    <span>{{address4}}</span>,<br>
                                    <span>{{address5}}</span><br>
                                   </span> 
                               <span *ngIf="length==7">
                                <span>{{address}}</span>,
                                <span>{{address1}}</span>,<br>
                                <span>{{address2}}</span>,<br>
                                <span>{{address3}}</span>,
                                <span>{{address4}}</span>,<br>
                                <span>{{address5}}</span>,<br>
                                <span>{{address6}}</span><br>
                               </span> 
                               <span *ngIf="length==8">
                                <span>{{address}}</span>,
                                <span>{{address1}}</span>,
                                <span>{{address2}}</span>,<br>
                                <span>{{address3}}</span>,
                                <span>{{address4}}</span>,<br>
                                <span>{{address5}}</span>,<br>
                                <span>{{address6}}</span>,<br>
                                <span>{{address7}}</span><br>
                               </span>
                               <span *ngIf="length==9">
                                <span>{{address}}</span>,
                                <span>{{address1}}</span>,
                                <span>{{address2}}</span>,<br>
                                <span>{{address3}}</span>,
                                <span>{{address4}}</span>,<br>
                                <span>{{address5}}</span>,<br>
                                <span>{{address6}}</span>,<br>
                                <span>{{address7}}</span>,<br>
                                <span>{{address8}}</span><br>
                               </span>
                               <span *ngIf="length==10">
                                <span>{{address}}</span>,
                                <span>{{address1}}</span>,
                                <span>{{address2}}</span>,<br>
                                <span>{{address3}}</span>,
                                <span>{{address4}}</span>,<br>
                                <span>{{address5}}</span>,<br>
                                <span>{{address6}}</span>,<br>
                                <span>{{address7}}</span>,<br>
                                <span>{{address8}}</span>,<br>
                                <span>{{address9}}</span><br>
                               </span>
                               <span *ngIf="length==11">
                                <span>{{address}}</span>,
                                <span>{{address1}}</span>,
                                <span>{{address2}}</span>,<br>
                                <span>{{address3}}</span>,
                                <span>{{address4}}</span>,<br>
                                <span>{{address5}}</span>,<br>
                                <span>{{address6}}</span>,<br>
                                <span>{{address7}}</span>,<br>
                                <span>{{address8}}</span>,<br>
                                <span>{{address9}}</span>,<br>
                                <span>{{address10}}</span><br>
                               </span>
                                <span *ngIf="invoiceModel.response.invoice.customerGstNo!=null">GST Number - {{invoiceModel.response.invoice.customerGstNo}}</span>
                                <span *ngIf="invoiceModel.response.invoice.customerGstNo==null">GST Number - N/A</span><br>
                                <span *ngIf="invoiceModel.response.invoice.orderDeliveredDateTime!=null">Order Completed Date - {{invoiceModel.response.invoice.orderDeliveredDateTime | date:'dd/MM/yyyy' }}</span>
                                <span *ngIf="invoiceModel.response.invoice.orderDeliveredDateTime==null">Order Completed Date - N/A</span>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
                       
            <tr class="heading">
                <td>
                    Description
                </td>
                
                <td>
                    Details
                </td>
            </tr>

            <tr class="item">
              <td>
                Total Quantity Ordered ( {{invoiceModel.response.invoice.fuelType}} - {{invoiceModel.response.invoice.quantity}} Ltr) (<i class="fa fa-inr" aria-hidden="true"></i> {{invoiceModel.response.invoice.fuelPrice | number : '1.2-2'}}/ Ltr) 
                <span *ngIf="invoiceModel.response.invoice.couponCode!=null">, Coupon - {{invoiceModel.response.invoice.couponCode}},<i class="fa fa-inr" aria-hidden="true"></i> {{invoiceModel.response.invoice.couponDiscountPrice | number : '1.2-2'}}</span>
              </td>
              
              <td>
                <i class="fa fa-inr" aria-hidden="true"></i> {{invoiceModel.response.invoice.totalfuelcost | number : '1.2-2'}}
              </td>
          </tr>

           <tr class="item">
              <td>
                  Delivery Charge (for {{invoiceModel.response.invoice.orderKm}} Kms)
              </td>
              
              <td>
                <i class="fa fa-inr" aria-hidden="true"></i> {{invoiceModel.response.invoice.deliveryCharges | number : '1.2-2'}}
              </td>
          </tr>
          
           <tr class="item">
              <td>
                SGST (9%)
                <br>
                <span style="font-size: 12px;">(for delivery charge)</span>
                </td>
                <td>
                  <i class="fa fa-inr" aria-hidden="true"></i> {{invoiceModel.response.invoice.sgst | number : '1.2-2'}}
              </td>
          </tr>

           <tr class="item">
            <td>
              CGST (9%)<br>
              <span style="font-size: 12px;">(for delivery charge)</span>
              </td><td>
                <i class="fa fa-inr" aria-hidden="true"></i> {{invoiceModel.response.invoice.cgst | number : '1.2-2'}}
            </td>
        </tr>
          <tr class="item">
            <td>
              Platform charges
            </td>
            
            <td>
              <i class="fa fa-inr" aria-hidden="true"></i> {{invoiceModel.response.invoice.internetHandlingFee | number : '1.2-2'}}
            </td>
        </tr>

            <tr class="total">
                <td></td>
                
                <td>
                   Total Paid Amount: <i class="fa fa-inr" aria-hidden="true"></i> {{invoiceModel.response.invoice.totalPayableAmount | number : '1.2-2'}}
                </td>
            </tr>
            <tr >
                <td style="font-size: 12px;">
                   <span style="padding-left: 250px;"> CINCH RETAIL PRIVATE LIMITED </span>
                </td>
            </tr>
        </table>
    </div>
</body>
</html> -->


<!doctype html>
<html>
<head>
    <meta charset="utf-8">
    <title>Cinch Fuel - Purchase Invoice</title>
    <style>
    
    .invoice-box {
        max-width: 800px;
        margin: auto;
        padding: 30px;
        border: 1px solid #eee;
        box-shadow: 0 0 10px rgba(0, 0, 0, .15);
        font-size: 18px;
        line-height: 24px;
        font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
        color: #555;
    }
    
    .invoice-box table {
        width: 100%;
        line-height: inherit;
        text-align: left;
    }
    
    .invoice-box table td {
        padding: 5px;
        vertical-align: top;
    }
    
    .invoice-box table tr td:nth-child(2) {
        text-align: right;
    }
    
    .invoice-box table tr.top table td {
        padding-bottom: 20px;
    }
    
    .invoice-box table tr.top table td.title {
        font-size: 45px;
        line-height: 45px;
        color: #333;
    }
    
    .invoice-box table tr.information table td {
        padding-bottom: 40px;
    }
    
    .invoice-box table tr.heading td {
        background: #eee;
        border-bottom: 1px solid #ddd;
        font-weight: bold;
    }
    
    .invoice-box table tr.details td {
        padding-bottom: 20px;
    }
    
    .invoice-box table tr.item td{
        border-bottom: 1px solid #eee;
    }
    
    .invoice-box table tr.item.last td {
        border-bottom: none;
    }
    
    .invoice-box table tr.total td:nth-child(2) {
        border-top: 2px solid #eee;
        font-weight: bold;
    }
    
    @media only screen and (max-width: 600px) {
        .invoice-box table tr.top table td {
            width: 100%;
            display: block;
            text-align: center;
        }
        
        .invoice-box table tr.information table td {
            width: 100%;
            display: block;
            text-align: center;
        }
    }
    
    
    </style>
    <head>
    <style>
        #example1 {
          border-radius: 25px;
          padding: 15px;
          background: rgb(49, 57, 100);
          width: 350px;
          height: 190px;  
        }
    </style>
</head>
<head>
    <style>
        #example2 {
          border-radius: 25px;
          padding: 15px;
          background: rgb(49, 57, 100);
          width: 350px;
          height: 120px;  
        }
    </style>
</head>





</head>
<body>
<div style=" background:url(https://i.im.ge/2021/08/11/9bVu9.png); background-repeat:no-repeat;
  background-position:center;
  background-attachment: fixed;
  background-size: 60% 100%;">
  
  
    <div class="invoice-box" >
	
    <table cellpadding="0" cellspacing="0">
            <tr class="top">
                <td colspan="2">
                    <table>
                        <tr>
                            <!-- <p style="color: orange; font-size: 12px;"><b>Powered With</b>  -->
                            <td class="title">
                                <img src="https://cinchfuel.com/assets/images/home/logo-red.png" style="width:60%; max-width:200px;">
                            </td>
                                                   
                            <td>
                                <span *ngIf="invoiceModel.response.invoice.vehicleId!=null">Partner ID: {{invoiceModel.response.invoice.vehicleId.userId.userCode}}</span>
                                <span *ngIf="invoiceModel.response.invoice.vehicleId==null">Partner ID: N/A</span>
                                <br>
                                <span *ngIf="invoiceModel.response.invoice.invoiceNumber!=null">
                                    Invoice #: {{invoiceModel.response.invoice.invoiceNumber}}
                                </span>
                                <span *ngIf="invoiceModel.response.invoice.invoiceNumber==null">Invoice #: N/A</span>
                                <br>
                                <span *ngIf="invoiceModel.response.invoice.orderDeliveredDateTime!=null">
                                    {{invoiceModel.response.invoice.orderDeliveredDateTime | date: 'dd-MM-yyyy'}}
                                </span>
                                <span *ngIf="invoiceModel.response.invoice.orderDeliveredDateTime==null">
                                    N/A
                                </span>
                                <br>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <td colspan="2">
                <p align='center'
                style="color: rgba(4, 4, 71, 0.644); font-size: 20px; "><b>ORIGINAL PLATFORM INVOICE</b></p> 
            </td>              
            <tr class="information">
                <td colspan="2">
                    <table>
                        <tr>
                            <td>
                                <span><b>Cinch Retail Private Limited</b></span><br>
                                <span>9/11, Ground Floor,</span><br>
                                <span>Palayakaran Street, Kalaimagal Nagar,</span><br>
                                <span>Ekkatuthangal,</span><br>
                                <span> Chennai – 600 032, India,</span><br>
                                <span>GST Number–33AAJCC6291P1Z2</span><br>
                                <span>Email – care@cinchfuel.com</span><br>
                              </td>
                            <td>
                              <b>Customer Info</b><br>
                                {{invoiceModel.response.invoice.customerId.firstName}} {{invoiceModel.response.invoice.customerId.lastName}} <br>
                                <span style="word-wrap: break-word;">
                                        {{invoiceModel.response.invoice.customerId.address}}
                                </span><br>
                                <span *ngIf="invoiceModel.response.invoice.customerGstNo!=null">GST Number - {{invoiceModel.response.invoice.customerGstNo}}</span>
                                <span *ngIf="invoiceModel.response.invoice.customerGstNo==null">GST Number - N/A</span><br>
                                <span>Contact no - {{invoiceModel.response.invoice.customerId.mobileNumber}}</span>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
                       
            <tr class="heading">
                <td>
                    Description
                </td>
                
                <td>
                    Details
                </td>
            </tr>
          <tr class="item">
            <td>
                Platform charges
              </td>
              
              <td>
                <i class="fa fa-inr" aria-hidden="true"></i> {{invoiceModel.response.invoice.internetHandlingFee | number : '1.2-2'}}
              </td>
          </tr>
          
           <tr class="item">
            <td>
                SGST (9%)
                
                </td>
                <td>
                  <i class="fa fa-inr" aria-hidden="true"></i> {{invoiceModel.response.invoice.platformSgst | number : '1.2-2'}}
              </td>
          </tr>

           <tr class="item">
            <td>
                CGST (9%)<br>
                </td><td>
                  <i class="fa fa-inr" aria-hidden="true"></i> {{invoiceModel.response.invoice.platformCgst | number : '1.2-2'}}
              </td>
        </tr>
          <!-- <tr class="item last">
            <td>
              Round off
            </td>
            
            <td>
                <i class="fa fa-inr" aria-hidden="true"></i> {{roundoffamount | number : '1.2-2'}}
            </td>
        </tr> -->
            
            <tr class="total">
                <td></td>
                
                <td>
                    Amount: <i class="fa fa-inr" aria-hidden="true"></i> {{invoiceModel.response.invoice.platformTotal | number : '1.2-2'}}
                 </td>
            </tr>
        </table>
        <tr class="information">
            <td colspan="2">
                <table>
                    <tr>
                        <td>
                            <div id="example1">
                            <p style="color: #ddd;"> 
                            <b>Order & Delivery Info</b><br>
                            <span style="font-size: small; color: #ddd;"> 
                            <span *ngIf="invoiceModel.response.invoice.orderId!=null">ID - {{invoiceModel.response.invoice.orderId}}</span>
                            <span *ngIf="invoiceModel.response.invoice.orderId==null">ID - N/A</span><br>
                            <span *ngIf="invoiceModel.response.invoice.bookedDate!=null"> Order Placed Date Time - {{invoiceModel.response.invoice.bookedDate | date:'dd/MM/yyyy hh:mm:ss a'}}</span>
                            <span *ngIf="invoiceModel.response.invoice.bookedDate==null">Order Placed Date Time - N/A</span><br>
                            <span *ngIf="invoiceModel.response.invoice.orderDeliveredDateTime!=null"> Order Delivered Date Time - {{invoiceModel.response.invoice.orderDeliveredDateTime | date:'dd/MM/yyyy hh:mm:ss a'}}</span>
                            <span *ngIf="invoiceModel.response.invoice.orderDeliveredDateTime==null">Order Delivered Date Time - N/A</span><br>
                            <span *ngIf="invoiceModel.response.invoice.vehicleId!=null">
                                Delivery Agent - {{invoiceModel.response.invoice.vehicleId.userId.firstName}} {{invoiceModel.response.invoice.vehicleId.userId.lastName}} ({{invoiceModel.response.invoice.vehicleId.vehicleNumber}})
                            </span><br>
                            <span>
                                Delivery Details - {{invoiceModel.response.invoice.quantity}}Ltrs (Rs. {{invoiceModel.response.invoice.fuelPrice | number : '1.2-2' }}/Ltr)    
                            </span><br>
                            <span>Vehicle No. – {{invoiceModel.response.invoice.vehicleId.vehicleNumber}}</span>
                        </span>
                        </p>
                            </div>
                        </td>

                        <td>
                            <div id="example1">
                            <p  style="text-align:left; color:#ddd"> 
                            <b>Transaction Info</b><br>
                            <span style="font-size: small; text-align: left; color:#ddd">
                                <span *ngIf="invoiceModel.response.invoice.transactionId!=null">ID - {{invoiceModel.response.invoice.transactionId}}</span>
                                <span *ngIf="invoiceModel.response.invoice.transactionId==null">ID - N/A</span><br>
                             <span *ngIf="invoiceModel.response.invoice.paymentStatus!=null">Transaction Status - {{invoiceModel.response.invoice.paymentStatus}}</span>   
                             <span *ngIf="invoiceModel.response.invoice.paymentStatus==null">Transaction Status - N/A</span><br>
                             <span *ngIf="invoiceModel.response.invoice.paymentDatetime!=null"> Transaction Date & Time - {{invoiceModel.response.invoice.paymentDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</span>
                            <span *ngIf="invoiceModel.response.invoice.paymentDatetime==null">Transaction Date & Time - N/A</span><br>
                            <span *ngIf="invoiceModel.response.invoice.totalPayableAmount!=null">Transaction Amount - <i class="fa fa-inr" aria-hidden="true"></i> {{invoiceModel.response.invoice.totalPayableAmount | number : '1.2-2'}} </span>
                            <span *ngIf="invoiceModel.response.invoice.totalPayableAmount==null">Transaction Amount - N/A</span><br>
                            <!-- Payment Mode - UPI<br> -->
                        </span>
                        </p>
                            </div>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <p align="center"><b>Notes: 1. Any changes in invoice to be intimated within 72 hours from the date of issuance of Invoice.<br> 
            2. Subject to Chennai Jurisdiction only.</b></p> 
        <p align="center"><b>This is a system generated invoice and requires no signature</b></p>
        <footer>
        <p style="font-size: small; color: rgb(115, 117, 117);" >Copyright © 2021 Cinch Retail Private Limited. All Rights Reserved. www.cinchfuel.com; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{invoiceModel.response.invoice.vehicleId.userId.userCode}} {{invoiceModel.response.invoice.invoiceNumber}}</p>
        </footer>
    </div>
</div>  
</body>
</html>