import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { CustomerOrderModel } from '../model/customerorder';
import { GenericResponse } from '../model/genricmodelresponse';
import { GenericResponseModel } from '../model/genricresponse';
import { UserModel } from '../model/user';
import { CustomerService } from '../services/customer.service';
import { OrderTransactionService } from '../services/ordertran.service';
import { UserService } from '../services/user.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { VehicleFuelService } from '../services/vehivle.service';
import { VehicleAttachModel } from '../model/vehicleModel';
import { OrderAssignModel } from '../model/orderassign';
import { PartnersService } from '../services/partners.service';
import {NgxSpinnerService} from "ngx-spinner";
@Component({
  selector: 'app-vehicles-view',
  templateUrl: './vehicles-view.component.html',
  styleUrls: ['./vehicles-view.component.css']
})
export class VehiclesViewComponent  {
  lat:Number;
  lan:Number;
   public isCollapsed = false;
   userModel : UserModel;
   customerOrder:GenericResponseModel;
   pencustomerOrder:GenericResponseModel;
   inpcustomerOrder:GenericResponseModel;
   comcustomerOrder:GenericResponseModel;
   cancustomerOrder:GenericResponseModel;
   allcustomerOrder:GenericResponseModel;
   customerTransanction:GenericResponseModel;
  orderModel:CustomerOrderModel;
  active = 1;
  message : String;
  successflag:boolean;
  isImageLoading: boolean;
  isImageLoading1: boolean;
  imageToShow: any;
  imageToShow1: any;
  defaultCustomer:boolean = false;
  allCustomer:boolean = false;
  penCustomer:boolean = true;
  inpCustomer:boolean = false;
  comCustomer:boolean = false;
  canCustomer:boolean = false;
  viewCusOrder:boolean = false;
closeResult = '';
    defaultTransaction:boolean = false;
    allTransaction:boolean;
    successTransaction:boolean;
    failureTransaction:boolean;
    initiatedTransaction:boolean = true;
    cancelledTransaction:boolean;
    imageasset: any;
    succagentTransanction:GenericResponseModel;
    failagentTransanction:GenericResponseModel;
    canceagentTransanction:GenericResponseModel;
    allagentTransanction:GenericResponseModel;
    iniagentTransanction:GenericResponseModel;
    assetsResponseModel:GenericResponseModel;
    customerBankInfo:GenericResponseModel;
    ratingStar:Number;
    orderDetails:GenericResponseModel;
    orderTrackDetails:GenericResponse;
    cancelMessageModel:GenericResponseModel;
    errorFlag:boolean = false;
    errorFlag1:boolean = false;
    errorMessage:String;
    orderID:Number;
    activeVehicle:GenericResponseModel;
    activevehi:Object;
vehicleModel : CustomerOrderModel;
check:String = null;
vehicle:VehicleAttachModel;
vehiclePaymentModel:GenericResponse;
  constructor(private modalService: NgbModal,private ordertransactionservice: OrderTransactionService,private route: ActivatedRoute,private customerService: CustomerService,private router: Router,private userservice:UserService,private vehicleservice:VehicleFuelService,private partnerservice:PartnersService,private spinnerService:NgxSpinnerService,private vehicleService:VehicleFuelService) {}
  ngOnInit() : void {

    this.route.queryParams.subscribe(params => {
      let value = params.success;
      if(value){
        this.successflag=true;
        this.message = params.message;
      }else{
      }

      setTimeout(function() {
        this.successflag = false;
    }.bind(this), 5000);

     let vehicleid = Number(sessionStorage.getItem("vehicleid"));
     let id = Number(sessionStorage.getItem("cusId"));
     this.customerService.getCustomerOrders(id).subscribe(data =>{
      this.customerOrder=data;
    });


     this.vehicleService.getVehicleByPaymentnew(vehicleid).subscribe(data=>{
      this.vehiclePaymentModel= data;
    });

    this.vehicleservice.getOrderByVehicle(vehicleid,1).subscribe(data=>{
      this.pencustomerOrder = data;
    });

    });
  }
  open(content,order:CustomerOrderModel) {
    this.orderID = order.id;
    this.ordertransactionservice.getOrderById(order.id).subscribe(data=>{
      this.orderDetails=data;
    });
    
    this.ordertransactionservice.tracking(order.id).subscribe(data=>{
      this.orderTrackDetails = data;
    });

    
    this.customerService.getOrderById(order.id).subscribe
    (data=>{
      this.orderModel = data;
    });
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  cancelPopup(content5,id){
    this.orderID = id;
    this.ordertransactionservice.cancellmessage().subscribe(data=>{
      this.cancelMessageModel = data;
    });
    this.modalService.open(content5, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open1(content1,id) {
    this.orderID = id;
    this.ordertransactionservice.getOrderById(id).subscribe(data=>{
      this.orderDetails=data;
    });

    this.ordertransactionservice.getActivatevehicle().subscribe(data=>{
      this.activeVehicle = data;
      this.activevehi = this.activeVehicle.response[0];
    });

    this.partnerservice.getOrderById(id).subscribe(data=>{
      this.vehicleModel = data;
    })

 

  this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  open2(content9,id) {
     this.customerService.getAssetsImage(id).subscribe(data=>{
      this.isImageLoading1 = true;
        this.createImageFromBlob6(data);
        this.isImageLoading1 = false;
  }, error => {
    this.isImageLoading1 = false;
  });

  this.modalService.open(content9, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
  }

  callActiveOrInactive(status){
    let id = Number(sessionStorage.getItem("cusId"));
    let username = sessionStorage.getItem("username");
    this.userservice.activeOrInactive(id,status,username).subscribe(data=>{
      if(data.flag===1){
        this.router.navigate(['/customers-view'],{ queryParams: { success: true ,message:data.message}});
      }
    })
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
       this.imageToShow = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
   }
  }

  createImageFromBlob1(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
       this.imageToShow1 = reader.result;
       console.log(image);
    }, false);

    if (image) {
      reader.readAsDataURL(image);
   }
  }

  createImageFromBlob6(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
       this.imageasset = reader.result;
    }, false);
  
    if (image) {
      reader.readAsDataURL(image);
   }
  }

  filterStatus(id){
    if(id==1){
      this.allCustomer = false;
      this.penCustomer = true;
      this.inpCustomer = false;
      this.comCustomer = false;
      this.canCustomer = false;
      this.defaultCustomer = false;
      let vehicleid = Number(sessionStorage.getItem("vehicleid"));
      this.vehicleservice.getOrderByVehicle(vehicleid,id).subscribe(data=>{
        this.pencustomerOrder = data;
      });

    }else if(id==2){
      this.allCustomer = false;
      this.penCustomer = false;
      this.inpCustomer = true;
      this.comCustomer = false;
      this.canCustomer = false;
      this.defaultCustomer = false;
      let vehicleid = Number(sessionStorage.getItem("vehicleid"));
      this.vehicleservice.getOrderByVehicle(vehicleid,id).subscribe(data=>{
        this.inpcustomerOrder = data;
      });
    }else if(id==3){
      this.allCustomer = false;
      this.penCustomer = false;
      this.inpCustomer = false;
      this.comCustomer = true;
      this.canCustomer = false;
      this.defaultCustomer = false;
      let vehicleid = Number(sessionStorage.getItem("vehicleid"));
      this.vehicleservice.getOrderByVehicle(vehicleid,id).subscribe(data=>{
        this.comcustomerOrder = data;
      });
    }else if(id==4){
      this.allCustomer = false;
      this.penCustomer = false;
      this.inpCustomer = false;
      this.comCustomer = false;
      this.canCustomer = true;
      this.defaultCustomer = false;
      let vehicleid = Number(sessionStorage.getItem("vehicleid"));
      this.vehicleservice.getOrderByVehicle(vehicleid,id).subscribe(data=>{
        this.cancustomerOrder = data;
      });
    }else if(id==0){
      this.allCustomer = true;
      this.penCustomer = false;
      this.inpCustomer = false;
      this.comCustomer = false;
      this.canCustomer = false;
      this.defaultCustomer = false;
      let vehicleid = Number(sessionStorage.getItem("vehicleid"));
      this.vehicleservice.getOrderByVehicle(vehicleid,id).subscribe(data=>{
        this.allcustomerOrder = data;
      });
    }
  }

  cancelOrder(){
    let desc = (<HTMLInputElement>document.getElementById('cancelremarks')).value;
    let cancelmessage = (<HTMLInputElement>document.getElementById('cancelmessage')).value;
    if(desc!=null && desc!==''){
      this.errorFlag1 = false;
      let formData= {
        userId:Number(sessionStorage.getItem('id')),
        cancellationId:cancelmessage,
        orderRemarks:desc,
      }
      this.ordertransactionservice.cancelorder(this.orderID,formData).subscribe(data=>{
        if(data.statusCode==200){
          Swal.fire({
            html: '<pre>' + data.responseMessage + '</pre>',
            customClass: {
              popup: 'format-pre'
            }
          }).then((value) => {
            window.location.reload();
          });; ; 
        }else{
          Swal.fire({
            html: '<pre>' + data.responseMessage + '</pre>',
            customClass: {
              popup: 'format-pre'
            }
          }).then((value) => {
            window.location.reload();
          });; ; 
        }
      });
      this.modalService.dismissAll();
    }else{
      this.errorFlag1 = true;
      this.errorMessage = "Please enter remarks!";
      setTimeout(function() {
        this.errorFlag1 = false;
    }.bind(this), 3000);

    }
  }

  invoice(id){
    sessionStorage.setItem('invoice',id);
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['/invoice-generation'])
      );
      window.open(url, '_blank');
  }

  onOptionsSelected(value:Number){
    this.vehicleservice.getVehicleById(value).subscribe(data=>{
      this.vehicle = data;
      this.check = data.vehicleName;
    });
  }

  assign(){
    let id = (<HTMLInputElement>document.getElementById('countryFormControl')).value;
    let latitude = this.vehicleModel.vehicleId.vehicleRegLatitude;
    let langitude = this.vehicleModel.vehicleId.vehicleRegLongtitude;
    let assign = new OrderAssignModel(Number(id),sessionStorage.getItem('username'),latitude,langitude);
    this.ordertransactionservice.assignto(this.orderID,assign).subscribe(data=>{
      Swal.fire({
        html: '<pre>' + data.responseMessage + '</pre>',
        customClass: {
          popup: 'format-pre'
        }
      }).then((value) => {
        window.location.reload();
      });; ; 
    });
  }

  open3(content2) {
    this.modalService.open(content2, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
}

location(lat,lan){
  this.lat = Number(lat);
  this.lan = Number(lan);
}
}
