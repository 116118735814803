
export class ChangePasswordModel{
    userPassword: String;
    newPassword:String;
    deviceType:String;
    ipaddress:String;
    modifiedBy:String;
    emailAddress:String;
    constructor(userPassword:String, newPassword: String,deviceType:String, ipaddress:String,modifiedBy:String,emailAddress:String){
        this.userPassword = userPassword;
        this.newPassword = newPassword;
        this.deviceType= deviceType;
        this.modifiedBy = modifiedBy;
        this.emailAddress = emailAddress;
        }
    }