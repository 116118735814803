import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { GenericResponseModel } from '../model/genricresponse';
import { RoleMenuMappingService } from '../services/rolemenumapping.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router } from '@angular/router';

@Component({
  selector: 'app-role-menu-mapping',
  templateUrl: './role-menu-mapping.component.html',
  styleUrls: ['./role-menu-mapping.component.css']
})
export class RoleMenuMappingComponent {

  closeResult = '';

  roleMenuModel:GenericResponseModel;
  assignedMenuModel:GenericResponseModel;

  constructor(private modalService: NgbModal,private rolemenuservice:RoleMenuMappingService,private router:Router) {}

  ngOnInit(): void {

    let status = sessionStorage.getItem("menustatus");
    if(Number(status)==1){
      sessionStorage.setItem("menustatus",'0');
      Swal.fire({
        html: '<pre>' + sessionStorage.getItem("menumessage") + '</pre>',
        customClass: {
          popup: 'format-pre'
        }
      }).then((value) => {
        window.location.reload();
      });; ;
    }

      this.rolemenuservice.view().subscribe(data=>{
        this.roleMenuModel = data;
      },err => {
        if(err.error.statusCode==406){
          this.router.navigate(['/login']);
        }else if(err.error.statusCode==500){
          this.router.navigate(['/page-error']);
        }else if(err.error.statusCode==404){
          this.router.navigate(['/pagenotfound']);
        }
     });
  }

  open(content,id) {
    this.rolemenuservice.assignedmenu(id).subscribe(data=>{
      this.assignedMenuModel = data;
      console.log(this.assignedMenuModel);
    });

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  edit(roleid,rolename){
    sessionStorage.setItem("adminroleid",roleid);
    sessionStorage.setItem("adminrolename",rolename);
    this.router.navigate(['/role-menu-mapping-edit']);
  }

}
