<app-header></app-header>
<ol class="breadcrumb breadcrumb-alt" style="margin-top:100px; width: auto;" >
  <li class="breadcrumb-item" style="margin-left: 15px;"><a href="#" routerLink="/dashboard">Home</a></li>
  <li class="breadcrumb-item active">FUEL PRICE RESET HISTORY</li>
</ol>
<div class="container  container-top-dashboard-section">
   
<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
   <div class="btn-section-wrapper">
</div>
<div style="display: none;" >{{marketPrizeDetails.responseMessage}}</div>

  <li [ngbNavItem]="1">
    <!-- <a ngbNavLink>Fuel Rate Master</a> -->
    <ng-template ngbNavContent>
      <div class="card-body">
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="text-align: center;">Sl.No.</th>
                      <th align="left">Vehicle Number</th>
                      <th align="left">Running PIN</th>
                      <th align="left">Fuel</th>
                      <th style="text-align: right;">Rate Per Ltr</th>
                      <th align="center" >Created By</th>
                      <th align="center" >Changed Date</th>
                      <th style="text-align: center;">Status</th>
                      <th style="text-align: center;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let market of marketPrizeDetails.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td align="left" class="v-align-middle">{{market.vehicleId.vehicleNumber}}</td>
                      <td align="left" class="v-align-middle">{{market.vehicleId.vehicleRunningPincode}}</td>
                      <td align="left" class="v-align-middle">{{market.fuelType.fuelTypeName}}</td>
                      <td align="right" class="v-align-middle"><i class="fa fa-inr" aria-hidden="true"></i> {{market.price | number : '1.2-2' }}</td>
                      <td align="left" class="v-align-middle">{{market.submittedBy}}</td>
                      <td *ngIf="market.currentDatetime!=null" align="left" class="v-align-middle">{{market.currentDatetime | date:'dd/MM/yyyy' }}</td>
                      <td *ngIf="market.currentDatetime==null" align="left" class="v-align-middle">N/A</td>
                      <td align="center" *ngIf="market.vehicleId.vehicleStatus===3" class="v-align-middle ht-active">Active</td>
                      <td align="center" *ngIf="market.vehicleId.vehicleStatus===4" class="v-align-middle ht-inactive">InActive</td>
                      <td  align="center" class="v-align-middle"><button title="View" class="label label-info" (click)="viewVehicle(market.vehicleId.vehicleId)"><i class="fa fa-eye" aria-hidden="true"></i></button></td>
                    </tr>
                  </tbody>
                </table>
          </div>
            </div>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>


</div>



<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Charges</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body master-body">
    <form>
      
  <div class="form-group">
        <label for="dateOfBirth">Vehicle Number</label>
        <input type="text" class="form-control"  placeholder="Vehicle Number">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Running Pin</label>
        <input type="text" class="form-control"  placeholder="Running Pin">
      </div>
        <div class="form-group">
        <label for="dateOfBirth">Fuel type</label>
        <div class="dropdown">
  <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
Fuel type  </button>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <a class="dropdown-item" href="#">Active</a>
    <a class="dropdown-item" href="#">Inactive</a>
    
  </div>
</div>
      </div>
    <div class="form-group">
        <label for="dateOfBirth">Price Per Ltrs</label>
        <input type="text" class="form-control"  placeholder="Price Per Ltrs">
      </div>
 
      <div class="form-group">
        <label for="dateOfBirth">Status</label>
        <div class="dropdown">
  <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
Status  </button>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <a class="dropdown-item" href="#">Active</a>
    <a class="dropdown-item" href="#">Inactive</a>
    
  </div>
</div>
      </div>
      


     

    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Upadte Cahnges</button>
    <!-- <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">no</button> -->
  </div>
</ng-template>