<div class="mainbody">
  <div class="row m-0">
    <div class="col-12">
      <div class="titleofthepage">Customer Declaration</div>
	  </div>
	  </div>
	  <div class="row m-0">
	  <div class="col-sm-12 m-auto">
      <div class="detaildescription">
        <div align="justify" class="content">
          Hereby confirm that all information's given/ giving to 'Cinch Fuel Private Limited' under the brand name 'Cinch Fuel' in this form is correct and accurate and wish to have an exclusive relationship. I/ we agree and accept that 'Cinch Fuel Private Limited' shall at its sole discretion, may reject or accept the application at any processing stage. In case cancellation of a paid order, necessary charges will be deducted. I/ We understand and agree that use of 'Cinch Fuel Private Limited' shall be deemed to be unconditional and irrevocable by acceptance of this terms and conditions mentioned in the agreement. Terms and conditions may be changed at any time without prior notice.
      </div>
        <!-- <div class="content">
            A clear description of the complaint and details about the outcome that the customer believes it would be the correct one; Attach any documents that may aid you in the process of sorting out the possible issue.
        </div> -->
      </div>
    </div>
  </div>
</div>

   