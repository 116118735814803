import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { BankModel } from '../model/approvebank';
import { GenericResponseModel } from '../model/genricresponse';
import { PartnersService } from '../services/partners.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { BankSettlementService } from '../services/banksettlement.service';
import * as moment from 'moment';

@Component({
  selector: 'app-customers-kyc-bank',
  templateUrl: './customers-kyc-bank.component.html',
  styleUrls: ['./customers-kyc-bank.component.css']
})


export class CustomersKycBankComponent  {



customerbankmodel:GenericResponseModel;
viewbankmodel:GenericResponseModel;
pendingbankModel:GenericResponseModel;
approvebankModel:GenericResponseModel;
rejectbankModel:GenericResponseModel;
closeResult = '';
// checked:boolean;
bankId:Number;
bankresponse:GenericResponseModel;
approve:boolean;
message:String;
poppup:boolean;
poppupalert:boolean;
resultText=[];  
  values:string;  
 count:number=0;  
 companyName:string;
selectedAll: any;
allBank:boolean=false;
pendingba:boolean=true;
approveba:boolean;
rejectba:boolean;
descalert:boolean;
startDate:String = null;
endDate:String = null;
refreshFlag:boolean = false;
errorFlag:boolean = false;y
  errorFlag1:boolean = false;
  errorMessage:String;
  filterflag:boolean= false;
  filteronesearch:boolean= false;
  filterpartnerbankModel:GenericResponseModel;
filterpartnerbankModelNorecord:GenericResponseModel;
  constructor(private modalService: NgbModal,private route: ActivatedRoute,private partnerservice: PartnersService,private router: Router,private spinnerService:NgxSpinnerService,private banksettlementservice:BankSettlementService) {}

  open(content,id) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open1(content0) {
    if(this.resultText.length>0){
      this.modalService.open(content0, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  open2(content1) {
    if(this.resultText.length>0){
      this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  onChange(id:Number,event) {  

    $('input[type="checkbox"]').on('change', function() {
      $('input[type="checkbox"]').not(this).prop('checked', false);
   });
   const checked = event.target.checked;  
   if (checked) {  
   this.values = String(id);
   }
      if (checked) {  
        this.resultText.push(id);  
         } else {  
           const index = this.resultText.indexOf(id);  
           this.resultText.splice(index, 1);  
       }  
      //  this.values=this.resultText.toString();  
       const count=this.resultText.length;  
       this.count=count;  
    }

  ngOnInit() : void {

    this.banksettlementservice.getAllCustomerBankSettlement().subscribe(data=>{
      this.customerbankmodel = data;
    });

    this.banksettlementservice.getCustomerandPartner(3,10).subscribe(data=>{
      this.pendingbankModel = data;
    });
  }

  approveBank(id){
        if(id===1){
          let desc = (<HTMLInputElement>document.getElementById('kyctextarea')).value;
          let bank =new BankModel(11,desc,sessionStorage.getItem('username'),null,null);
          if(desc!=null && desc!==''){
            this.descalert = false;
             this.banksettlementservice.bankapproval(Number(this.values),bank) .subscribe(data=>{
              this.modalService.dismissAll();
              Swal.fire({
                html: '<pre>' + data.responseMessage + '</pre>',
                customClass: {
                  popup: 'format-pre'
                }
              }).then((value) => {
                window.location.reload();
              });; ; 
             });
          }else{
            this.descalert = true;
            setTimeout(function() {
              this.descalert = false;
          }.bind(this), 3000);
          }
      }else if(id===2){
        let desc = (<HTMLInputElement>document.getElementById('kyctextrejectarea')).value;
        let bank =new BankModel(12,desc,sessionStorage.getItem('username'),null,null);
        if(desc!=null && desc!==''){
          this.descalert = false;
          this.banksettlementservice.bankapproval(Number(this.values),bank) .subscribe(data=>{
            this.modalService.dismissAll();
            Swal.fire({
              html: '<pre>' + data.responseMessage + '</pre>',
              customClass: {
                popup: 'format-pre'
              }
            }).then((value) => {
              window.location.reload();
            });; ; 
           });
        }else{
          this.descalert = true;
          setTimeout(function() {
            this.descalert = false;
        }.bind(this), 3000);
        }
      }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
 public isCollapsed = false;
  active = 1;

  pendingbank(){
    this.allBank = false;
    this.pendingba = true;
    this.approveba = false;
    this.rejectba = false;

    this.banksettlementservice.getCustomerandPartner(3,10).subscribe(data=>{
      this.pendingbankModel = data;
    });
  }

  approvebank(){
    this.allBank = false;
    this.pendingba = false;
    this.approveba = true;
    this.rejectba = false;
    this.banksettlementservice.getCustomerandPartner(3,11).subscribe(data=>{
      this.approvebankModel = data;
    });
  }

  rejectbank(){
    this.allBank = false;
    this.pendingba = false;
    this.approveba = false;
    this.rejectba = true;
    this.banksettlementservice.getCustomerandPartner(3,12).subscribe(data=>{
      this.rejectbankModel = data;
    });
  }

  search(){
    this.refreshFlag = true;
    let searchBy = (<HTMLInputElement>document.getElementById('searchBy')).value;
    let status = (<HTMLInputElement>document.getElementById('status')).value;
    let fromdate = (<HTMLInputElement>document.getElementById('fromdate')).value;
    let todate = (<HTMLInputElement>document.getElementById('todate')).value;
    if(searchBy=='1'){
      this.filterflag = false;
      if(status=='1'){
        this.allBank = true;
        this.pendingba = false;
        this.approveba = false;
        this.rejectba = false;
        this.banksettlementservice.getAllCustomerBankSettlement().subscribe(data=>{
          this.customerbankmodel = data;
        });
      }else if(status=='2'){
        this.allBank = false;
    this.pendingba = true;
    this.approveba = false;
    this.rejectba = false;
    this.banksettlementservice.getCustomerandPartner(3,10).subscribe(data=>{
      this.pendingbankModel = data;
    });
      }else if(status=='3'){
        this.allBank = false;
    this.pendingba = false;
    this.approveba = true;
    this.rejectba = false;
    this.banksettlementservice.getCustomerandPartner(3,11).subscribe(data=>{
      this.approvebankModel = data;
    });
      }else if(status=='4'){
        this.allBank = false;
        this.pendingba = false;
        this.approveba = false;
        this.rejectba = true;
        this.banksettlementservice.getCustomerandPartner(3,12).subscribe(data=>{
          this.rejectbankModel = data;
        });
      }
    }else if(searchBy=='2'){
      if(fromdate==''||todate==''){
        this.errorFlag = true;
        this.errorMessage = "Please select Dates!";
      }else if(todate < fromdate){
        this.errorFlag = true;
        this.errorMessage = "End Date should be greater than Start Date!";
      }else{
        this.errorFlag = false;
        this.filterflag = true;
        this.allBank = false;
        this.pendingba = false;
        this.approveba = false;
        this.rejectba = false;
        this.banksettlementservice.getCustomerandPartnerDateRange(3,fromdate,todate).subscribe(data=>{
          if(data.statusCode==200){
            this.filteronesearch = false;
            this.filterpartnerbankModel = data;
          }else{
            this.filteronesearch = true;
            this.filterpartnerbankModelNorecord = data;
          }
        });

      }
    }
  }

  refresh(){
    this.refreshFlag = false;
    window.location.reload();
  }

  callsearch(event){
    if(event.target.value==1){
      $('#status').show();
      $('#fromdate').hide();
      $('#todate').hide();
    }else if(event.target.value==2){
      var nowdate = new Date();
      var monthStartDay = new Date(nowdate.getFullYear(), nowdate.getMonth(), 1);
      var monthEndDay = new Date(nowdate.getFullYear(), nowdate.getMonth() + 1, 0);
      let startDate = moment(new Date(monthStartDay)).format("yyyy-MM-DD").toString();
      let endDate = moment(new Date(monthEndDay)).format("yyyy-MM-DD").toString();
      this.startDate = startDate;
      this.endDate = endDate;
      $('#status').hide();
      $('#fromdate').show();
      $('#todate').show();
    }
  }

}


