import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { CustomerOrderModel } from '../model/customerorder';
import { GenericResponse } from '../model/genricmodelresponse';
import { GenericResponseModel } from '../model/genricresponse';
import { OrderAssignModel } from '../model/orderassign';
import { UserModel } from '../model/user';
import { VehicleAttachModel } from '../model/vehicleModel';
import { AgentService } from '../services/agent.service';
import { CustomerService } from '../services/customer.service';
import { OrderTransactionService } from '../services/ordertran.service';
import { PartnersService } from '../services/partners.service';
import { UserService } from '../services/user.service';
import { VehicleFuelService } from '../services/vehivle.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-agents-view',
  templateUrl: './agents-view.component.html',
  styleUrls: ['./agents-view.component.css']
})
export class AgentsViewComponent  {
   public isCollapsed = false;
  active = 1;
closeResult = '';
 agentModel:UserModel;
 message : String;
  successflag:boolean;
  agentOrder:GenericResponseModel;
   agentTransanction:GenericResponseModel;
   orderModel:CustomerOrderModel; 
   isImageLoading: boolean;
  imageToShow: any; 
  allAgent:boolean;
  defaultAgent:boolean = false;
  pendingAgent:boolean = true;
  inprogressAgent:boolean;
  completedAgent:boolean;
  cancelledAgent:boolean;
  agentPendingOrder:GenericResponseModel;
  agentInproOrder:GenericResponseModel;
  agentCancellOrder:GenericResponseModel;
  agentCompleOrder:GenericResponseModel;
  allAgentOrder:GenericResponseModel;

  defaultTransaction:boolean=false;
  allTransaction:boolean;
  successTransaction:boolean;
  failureTransaction:boolean;
  initiatedTransaction:boolean = true;
  cancelledTransaction:boolean;
  allagentTransanction:GenericResponseModel;
  succagentTransanction:GenericResponseModel;
  canceagentTransanction:GenericResponseModel;
  failagentTransanction:GenericResponseModel;
  iniagentTransanction:GenericResponseModel;
  ratingStar:Number;
  orderDetails:GenericResponseModel;
  orderTrackDetails:GenericResponse;
  cancelMessageModel:GenericResponseModel;
  errorFlag:boolean = false;
  errorFlag1:boolean = false;
  errorMessage:String;
  orderID:Number;
  activeVehicle:GenericResponseModel;
  activevehi:Object;
vehicleModel : CustomerOrderModel;
check:String = null;
vehicle:VehicleAttachModel;
failedStatus:String;
  failedCount:Number;
  editUserId:Number;

  constructor(private modalService: NgbModal,private agantservice:AgentService,private userservice:UserService,private router:Router,private route: ActivatedRoute,private customerService: CustomerService,private ordertransactionservice:OrderTransactionService,private partnerservice:PartnersService,private vehicleservice:VehicleFuelService) {}

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
    let value = params.success;
      if(value){
        this.successflag=true;
        this.message = params.message;
      }
      setTimeout(function() {
        this.successflag = false;
        console.log(this.successflag);
    }.bind(this), 5000);
  });

    let id = Number(sessionStorage.getItem("agentId"));

    this.userservice.getUserImage(id).subscribe(data=>{
      if(data.type==='text/xml'){
        this.isImageLoading = false;
      }else{
        this.isImageLoading = true;
      }
      this.createImageFromBlob(data);
    });  
    
    this.agantservice.viewAgentById(id).subscribe(data=>{
      this.agentModel = data;
      this.failedCount = this.agentModel.failedCount;
          if(this.agentModel.failedCount==6){
            this.failedStatus = "Block" ;
          }else{
            this.failedStatus = "Not Block" ;
          }
      let rating = String(data.ratings);
        if(data.ratings==0){
          this.ratingStar = 0;
        }
        else if(rating.match('1')){
          if(rating.match('1.5')||rating.match('1.6')||rating.match('1.7')||rating.match('1.8')||rating.match('1.9')){
            this.ratingStar = 1.5;
          }else{
            this.ratingStar = 1;
          }
          
        }else if(rating.match('2')){
          if(rating.match('2.5')||rating.match('2.6')||rating.match('2.7')||rating.match('2.8')||rating.match('2.9')){
            this.ratingStar = 2.5;
          }else{
            this.ratingStar = 2;
          }
        }else if(rating.match('3')){
          if(rating.match('3.5')||rating.match('3.6') || rating.match('3.7')||rating.match('3.8')||rating.match('3.9')){
            this.ratingStar = 3.5;
          }else{
            this.ratingStar = 3;
          }
        }else if(rating.match('4')){
          if(rating.match('4.5')||rating.match('4.6')||rating.match('4.7')||rating.match('4.8')||rating.match('4.9')){
            this.ratingStar = 4.5;
          }else{
            this.ratingStar = 4;
          }
        }else if(rating.match('5')){
          this.ratingStar = 5;
        }
    });

    this.agantservice.getOrders(id).subscribe(data =>{
      this.agentOrder=data;
    });

    this.agantservice.getPendingOrders(id).subscribe(data=>{
      this.agentPendingOrder = data;
    });

    this.agantservice.getTransactions(id).subscribe(data =>{
      this.agentTransanction=data;
    });

    this.agantservice.getInitiatedTransaction(id).subscribe(data=>{
      this.iniagentTransanction = data;
    });
  }

  open(content,id) {
    this.orderID = id;
    this.ordertransactionservice.getOrderById(id).subscribe(data=>{
      this.orderDetails=data;
      console.log(this.orderDetails);
    });
    
    this.ordertransactionservice.tracking(id).subscribe(data=>{
      this.orderTrackDetails = data;
    });

    this.customerService.getOrderById(id).subscribe
    (data=>{
      this.orderModel = data;
    });
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open10(reject,viewId){
    this.editUserId = viewId;
    this.modalService.open(reject, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  unblock(){
    let userId = this.editUserId;
    this.customerService.unblock(userId).subscribe(data=>{
      if(data.statusCode==200){
        Swal.fire({
          html: '<pre>' + data.responseMessage + '</pre>',
          customClass: {
            popup: 'format-pre'
          }
        }).then((value) => {
          window.location.reload();
        });; ;
      }
    },err => {
      if(err.error.statusCode==500){
        this.router.navigate(['/page-error']);
      }else if(err.error.statusCode==404){
        this.router.navigate(['/pagenotfound']);
      }
   });
  }

  cancelPopup(content5,id){
    this.orderID = id;
    this.ordertransactionservice.cancellmessage().subscribe(data=>{
      this.cancelMessageModel = data;
    });
    this.modalService.open(content5, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open1(content1,id) {
    this.orderID = id;
    this.ordertransactionservice.getOrderById(id).subscribe(data=>{
      this.orderDetails=data;
    });

    this.ordertransactionservice.getActivatevehicle().subscribe(data=>{
      this.activeVehicle = data;
      this.activevehi = this.activeVehicle.response[0];
    });

    this.partnerservice.getOrderById(id).subscribe(data=>{
      this.vehicleModel = data;
    })

  this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass: 'my-class'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  callActiveOrInactive(status){
    let id = Number(sessionStorage.getItem("agentId"));
    let username = sessionStorage.getItem("username");
    this.userservice.activeOrInactive(id,status,username).subscribe(data=>{
      if(data.flag===1){
        this.router.navigate(['/agents-view'],{ queryParams: { success: true ,message:data.message}});
      }
    })
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
       this.imageToShow = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
   }
  }

  pending(){
    let id = Number(sessionStorage.getItem("agentId"));
    this.pendingAgent = true;
    this.allAgent = false;
    this.inprogressAgent = false;
    this.completedAgent = false;
    this.cancelledAgent = false;
    this.defaultAgent = false;
    this.agantservice.getPendingOrders(id).subscribe(data=>{
      this.agentPendingOrder = data;
    });
  }

  inprogress(){
    let id = Number(sessionStorage.getItem("agentId"));
    this.pendingAgent = false;
    this.allAgent = false;
    this.inprogressAgent = true;
    this.completedAgent = false;
    this.cancelledAgent = false;
    this.defaultAgent = false;
    this.agantservice.getInprogrssOrders(id).subscribe(data=>{
      this.agentInproOrder = data;
    });
  }

  completed(){
    let id = Number(sessionStorage.getItem("agentId"));
    this.allAgent = false;
    this.pendingAgent = false;
    this.inprogressAgent = false;
    this.completedAgent = true;
    this.cancelledAgent = false;
    this.defaultAgent = false;
    this.agantservice.getCompletedOrders(id).subscribe(data=>{
      this.agentCompleOrder = data;
    });
  }
  
  cancelled(){
    let id = Number(sessionStorage.getItem("agentId"));
    this.allAgent = false;
    this.pendingAgent = false;
    this.inprogressAgent = false;
    this.completedAgent = false;
    this.cancelledAgent = true;
    this.defaultAgent = false;
    this.agantservice.getCancelledOrders(id).subscribe(data=>{
      this.agentCancellOrder = data;
    });
  }

  all(){
    let id = Number(sessionStorage.getItem("agentId"));
    this.allAgent = true;
    this.pendingAgent = false;
    this.inprogressAgent = false;
    this.completedAgent = false;
    this.cancelledAgent = false;
    this.defaultAgent = false;
    this.agantservice.getOrders(id).subscribe(data =>{
      this.allAgentOrder=data;
    });
  }

  successtra(){
    let id = Number(sessionStorage.getItem("agentId"));
    this.defaultTransaction = false;
    this.allTransaction = false;
    this.successTransaction = true;
    this.failureTransaction = false;
    this.initiatedTransaction = false;
    this.cancelledTransaction = false;

    this.agantservice.getSuccessTransaction(id).subscribe(data=>{
      this.succagentTransanction = data;
    });
  }

  failuretra(){
    let id = Number(sessionStorage.getItem("agentId"));
    this.defaultTransaction = false;
    this.allTransaction = false;
    this.successTransaction = false;
    this.failureTransaction = true;
    this.initiatedTransaction = false;
    this.cancelledTransaction = false;

    this.agantservice.getFailedTransaction(id).subscribe(data=>{
      this.failagentTransanction = data;
    });
  }

  cancelledtra(){
    let id = Number(sessionStorage.getItem("agentId"));
    this.defaultTransaction = false;
    this.allTransaction = false;
    this.successTransaction = false;
    this.failureTransaction = false;
    this.initiatedTransaction = false;
    this.cancelledTransaction = true;

    this.agantservice.getCancelledTransaction(id).subscribe(data=>{
      this.canceagentTransanction = data;
    });

  }

  initiatedtra(){
    let id = Number(sessionStorage.getItem("agentId"));
    this.defaultTransaction = false;
    this.allTransaction = false;
    this.successTransaction = false;
    this.failureTransaction = false;
    this.initiatedTransaction = true;
    this.cancelledTransaction = false;

    this.agantservice.getInitiatedTransaction(id).subscribe(data=>{
      this.iniagentTransanction = data;
    });
  }

  alltra(){
    let id = Number(sessionStorage.getItem("agentId"));
    this.defaultTransaction = false;
    this.allTransaction = true;
    this.successTransaction = false;
    this.failureTransaction = false;
    this.initiatedTransaction = false;
    this.cancelledTransaction = false;
    this.agantservice.getTransactions(id).subscribe(data =>{
      this.allagentTransanction=data;
    });
  }

  cancelOrder(){
    let desc = (<HTMLInputElement>document.getElementById('cancelremarks')).value;
    let cancelmessage = (<HTMLInputElement>document.getElementById('cancelmessage')).value;
    if(desc!=null && desc!==''){
      this.errorFlag1 = false;
      let formData= {
        userId:Number(sessionStorage.getItem('id')),
        cancellationId:cancelmessage,
        orderRemarks:desc,
      }
      this.ordertransactionservice.cancelorder(this.orderID,formData).subscribe(data=>{
        if(data.statusCode==200){
          Swal.fire({
            html: '<pre>' + data.responseMessage + '</pre>',
            customClass: {
              popup: 'format-pre'
            }
          }).then((value) => {
            window.location.reload();
          });; ; 
        }else{
          Swal.fire({
            html: '<pre>' + data.responseMessage + '</pre>',
            customClass: {
              popup: 'format-pre'
            }
          }).then((value) => {
            window.location.reload();
          });; ; 
        }
      });
      this.modalService.dismissAll();
    }else{
      this.errorFlag1 = true;
      this.errorMessage = "Please enter remarks!";
      setTimeout(function() {
        this.errorFlag1 = false;
    }.bind(this), 3000);

    }
  }

  invoice(id){
    sessionStorage.setItem('invoice',id);
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['/invoice-generation'])
      );
      window.open(url, '_blank');
  }

  onOptionsSelected(value:Number){
    this.vehicleservice.getVehicleById(value).subscribe(data=>{
      this.vehicle = data;
      this.check = data.vehicleName;
    });
  }

  assign(){
    let id = (<HTMLInputElement>document.getElementById('countryFormControl')).value;
    let latitude = this.vehicleModel.vehicleId.vehicleRegLatitude;
    let langitude = this.vehicleModel.vehicleId.vehicleRegLongtitude;
    let assign = new OrderAssignModel(Number(id),sessionStorage.getItem('username'),latitude,langitude);
    this.ordertransactionservice.assignto(this.orderID,assign).subscribe(data=>{
      Swal.fire({
        html: '<pre>' + data.responseMessage + '</pre>',
        customClass: {
          popup: 'format-pre'
        }
      }).then((value) => {
        window.location.reload();
      });; ; 
    });
  }

  open3(content2) {
    this.modalService.open(content2, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
}
}
