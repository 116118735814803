<!-- <app-header></app-header> -->

 <!-- <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple">
  
</ngx-spinner> -->


<router-outlet></router-outlet>
