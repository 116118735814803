<app-header></app-header>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
</ngx-spinner>
<ol class="breadcrumb breadcrumb-alt" style="margin-top:100px; width: auto;" >
  <li class="breadcrumb-item" style="margin-left: 15px;"><a href="#" routerLink="/dashboard">Home</a></li>
  <li class="breadcrumb-item active">BANK MASTER</li>
      <div class="btn-section-wrapper"><button type="button" class="label label-info" (click)="open(content)"><i class="fa fa-plus" aria-hidden="true"></i>
        Add Bank</button>
</div>
</ol>
<div class="container  container-top-dashboard-section">
<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
	 <!-- <div class="btn-section-wrapper"><button type="button" class="btn btn-success" (click)="open(content)"><i class="fa fa-plus" aria-hidden="true"></i>
 Add Bank</button>
</div> -->

<div style="display: none;" >{{bankmaster.responseMessage}}</div>

  <li [ngbNavItem]="1" style="margin-top: 40px;">
    <!-- <a ngbNavLink>Bank Master</a> -->
    <ng-template ngbNavContent>
      <div class="card-body">
              <div class="table-responsive">
                <table datatable class="ch-table">
                  <thead>
                    <tr> 
                      <th style="width: 40px; text-align: center;">Sl.No.</th>
                      <th align="center">Bank Name</th>
                      <th align="center">Bank Code</th>
                      <th align="center">Created By</th>
                      <th align="center">Updated Date &amp; Time</th>
                      <th style="text-align: center;">Status</th>
                      <th style="width: 60px; text-align: center;">Action</th>
                      <!-- <th align="center">Edit</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let banks of bankmaster.response; let i=index">
                      <td align="center" class="v-align-middle ">{{i + 1}}</td>
                      <td align="left" class="v-align-middle">{{banks.bankName}}</td>
                      <td align="left" class="v-align-middle">{{banks.bankCode}}</td>
                      <td align="left" class="v-align-middle">{{banks.modifiedBy}}</td>
                      <td align="left" class="v-align-middle">{{banks.modifiedDatetime | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                      <td align="center" *ngIf="banks.bankStatus===1" class="v-align-middle ht-active">Active</td>
                      <td align="center" *ngIf="banks.bankStatus===0" class="v-align-middle ht-inactive">InActive</td>
                      <td align="center"  class="v-align-middle">
                        <button title="Edit" (click)="open1(content1,banks.id)" class="label label-success"><i class="fa fa-pencil" aria-hidden="true"></i> </button>&nbsp;
                        <!-- <button title="delete"class="label label-danger" (click)="open2(reject,banks.id)"><i class="fa fa-trash" aria-hidden="true"></i></button> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
          </div>
            </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="2" style="display: none;">
    <a ngbNavLink>Two</a>
    <ng-template ngbNavContent>
      <p>Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan four loko
        farm-to-table craft beer twee. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts
        ullamco ad vinyl cillum PBR. Homo nostrud organic, assumenda labore aesthetic magna delectus mollit. Keytar
        helvetica VHS salvia yr, vero magna velit sapiente labore stumptown. Vegan fanny pack odio cillum wes anderson
        8-bit, sustainable jean shorts beard ut DIY ethical culpa terry richardson biodiesel. Art party scenester
        stumptown, tumblr butcher vero sint qui sapiente accusamus tattooed echo park.</p>
    </ng-template>
  </li>
  <li [ngbNavItem]="3" style="display: none;">
    <a ngbNavLink>Three</a>
    <ng-template ngbNavContent>
      <p>Sed commodo, leo at suscipit dictum, quam est porttitor sapien, eget sodales nibh elit id diam. Nulla facilisi.
        Donec egestas ligula vitae odio interdum aliquet. Duis lectus turpis, luctus eget tincidunt eu, congue et odio.
        Duis pharetra et nisl at faucibus. Quisque luctus pulvinar arcu, et molestie lectus ultrices et. Sed diam urna,
        egestas ut ipsum vel, volutpat volutpat neque. Praesent fringilla tortor arcu. Vivamus faucibus nisl enim, nec
        tristique ipsum euismod facilisis. Morbi ut bibendum est, eu tincidunt odio. Orci varius natoque penatibus et
        magnis dis parturient montes, nascetur ridiculus mus. Mauris aliquet odio ac lorem aliquet ultricies in eget
        neque. Phasellus nec tortor vel tellus pulvinar feugiat.</p>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>


</div>



<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Bank</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <div class="modal-body master-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Bank Name</label>
        <span class="required-asterisk">*</span>
        <input type="text" id="bankName" name="bankName" class="form-control"  placeholder="">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Bank Code</label>
        <span class="required-asterisk">*</span>
        <input type="text" id="bankCode" name="bankCode" class="form-control"  placeholder="">
      </div>

      <div class="form-group">
        <label for="dateOfBirth">Status</label>
        <span class="required-asterisk">*</span>
        <div class="dropdown">
    <select id="bankStatus" name="bankStatus" value="bankStatus" class="form-control">
      <option value=1>Active</option>
      <option value=0>Inactive</option>
  </select>
</div>
      </div>
      <div *ngIf="errorFlag">
        <div class="error-style">{{errorMessage}}</div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="addBank()">Add Bank</button>
  </div>
</ng-template>

<ng-template #content1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update Bank</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <div class="modal-body master-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Bank Name</label>
        <span class="required-asterisk">*</span>
        <input type="text" id="bankName" name="bankName" [(ngModel)]="bankMasterModel.bankName" class="form-control"  placeholder="">
      </div>
      <div class="form-group">
        <label for="dateOfBirth">Bank Code</label>
        <span class="required-asterisk">*</span>
        <input type="text" id="bankCode" name="bankCode" [(ngModel)]="bankMasterModel.bankCode" class="form-control"  placeholder="">
      </div>

      <div class="form-group">
        <label for="dateOfBirth">Status</label>
        <span class="required-asterisk">*</span>
        <div class="dropdown">
    <select id="bankStatus" name="bankStatus" [(ngModel)]="bankMasterModel.bankStatus" class="form-control">
      <option value=1>Active</option>
      <option value=0>Inactive</option>
  </select>
</div>
      </div>
    </form>
    <div *ngIf="errorFlag1">
      <div class="error-style">{{errorMessage}}</div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="updateBank()" >Update Bank</button>
  </div>
</ng-template>

<ng-template #reject let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <i class="fa fa-window-close" aria-hidden="true"></i> </button>
  </div>
  <hr class="beautiful-line">
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Do you want delete the bank?</label>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="delete()" (click)="modal.close('Save click')">yes</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">no</button>
  </div>
</ng-template>