import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RoleModel } from '../model/role';
import { UserManagementService } from '../services/usermanagement.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
  number=10;
  roleDetails:RoleModel[];
  mandetoryflag:boolean;
  message:String;
  constructor(private modalService: NgbModal,private usermanagementservice:UserManagementService,private router:Router) {
   }

  userForm = new FormGroup({
    role: new FormControl(),
    username: new FormControl(),
    email: new FormControl(),
    mobile: new FormControl(),
    date: new FormControl(),
}); 

  ngOnInit(): void {
    (<HTMLInputElement>document.getElementById('date')).max = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0];
    this.usermanagementservice.getRoles().subscribe(data=>{
      this.roleDetails = data;
    })
  }

  addUser(){
    let date = (<HTMLInputElement>document.getElementById('date')).value;
    let role = (<HTMLInputElement>document.getElementById('role')).value;
    let name = (<HTMLInputElement>document.getElementById('username')).value;
    let email = (<HTMLInputElement>document.getElementById('email')).value;
    let mobile = (<HTMLInputElement>document.getElementById('mobile')).value;

    if(date!=="" && role!=="" && name!=="" && email!=="" && mobile!=="" && date!=null && role!=null && name!=null && email!=null && mobile!=null){
      this.mandetoryflag = false;
      let currentDate=new Date(date);
      let formData= {
        roleId:Number(role),
        deviceType:"Web",
        dateofjoin:currentDate.getTime(),
        mobileNo:Number(mobile),
        firstName:name,
        emailAddress:email,
        modifiedBy:sessionStorage.getItem("username")
      }

      this.usermanagementservice.add(formData).subscribe(data=>{
        if(data.flag==1){
          this.mandetoryflag = false;
          sessionStorage.setItem("message",data.message);
          this.router.navigate(['/user-management']);
          sessionStorage.setItem("add","1");
        }else{
          this.mandetoryflag = true;
          this.message = data.message;

          setTimeout(function() {
            this.mandetoryflag = false;
        }.bind(this), 5000);

        }
      },err => {
        if(err.error.statusCode==406){
          this.router.navigate(['/login']);
        }else if(err.error.statusCode==500){
          this.router.navigate(['/page-error']);
        }else if(err.error.statusCode==404){
          this.router.navigate(['/pagenotfound']);
        }
     });

    }else{
      this.mandetoryflag = true;
      this.message = "Mandatory fields are required!";

      setTimeout(function() {
        this.mandetoryflag = false;
    }.bind(this), 5000);
    }
  }

  clear(){
    this.userForm.reset();
  }

  allowNumericDigitsOnlyOnKeyUp(e) {		
		const charCode = e.which ? e.which : e.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			e.preventDefault();
		}else{
      return true;
    }
	}

}
