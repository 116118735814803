import {Injectable, Type} from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { UserModel } from '../model/user';
import { environment } from 'src/environments/environment';
import { RestResponseModel } from '../model/response';
import { GenericResponseModel } from '../model/genricresponse';
import { GenericResponse } from '../model/genricmodelresponse';
import { RoleModel } from '../model/role';

@Injectable({
    providedIn: "root"
  })
  export class UserManagementService {

  constructor(private httpClient:HttpClient) {}

  private baseUrl = environment.devurl;

  private auth = environment.headers;

  public getUsers(){
     // const headers = this.auth;
     let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
     const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<GenericResponse>(this.baseUrl+"/usermanagement/view",{headers});
  }

  public viewUserById(id){
      // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<UserModel>(this.baseUrl+"/usermanagement/viewUserById"+"/"+id,{headers});
  }

  public delete(id){
      // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.delete<RestResponseModel>(this.baseUrl+"/usermanagement/delete"+"/"+id,{headers});
  }

  public add(user){
      // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.post<RestResponseModel>(this.baseUrl+"/usermanagement/add", user, {headers});
  }

  public update(id,user){
      // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.put<RestResponseModel>(this.baseUrl+"/usermanagement/update"+"/"+id, user, {headers});
  }

  public activeOrInactive(id,status,username){
      // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<RestResponseModel>(this.baseUrl+"/adminlogin/accountstatus"+"/"+id+"/"+status+"/"+username,{headers});
  }

  public getRoles(){
      // const headers = this.auth;
   let tokenStr = 'Bearer '+sessionStorage.getItem("jwttoken");
   const headers= new HttpHeaders().set("X_ACCESS_TOKEN",tokenStr);
    return this.httpClient.get<RoleModel[]>(this.baseUrl+"/usermanagement/getRoles",{headers});
  }

  }